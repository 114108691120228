import React, { useState, SyntheticEvent, useEffect } from "react";
import { Title } from "../../../../stories/Title/Title";
import Home from "../Home/Index";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../../../styles/Accordion";
import { Button } from "../../../../stories/Button/Button";
import {
  Autocomplete,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Label } from "../../../../stories/Label/Label";
import { AntSwitch } from "../../../../pages/Switch";
import Input from "../../../../stories/Input/Input";
import { useNavigate, useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import {
  GetSwConfigReferenceDataByKey,
  GetSwConfigReferenceData,
  GetSwConfigVariables,
  GetTimeZone,
} from "../../../../queries/institution/configuration/query/SoftwreConfig";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GlobalPageConfigData,
  SoftwareConfigList,
  SoftwareVars,
  SoftwareConfigTypeList,
  SoftwareConfigTypeVar,
  GetSwConfigVariablesData,
  GetSwConfigVariablesVars,
} from "../../../../Types/configtypes";
import {
  UpdateInstTimeZoneAndBdayMsgTime,
  UpdateSwConfigVariables,
} from "../../../../queries/institution/configuration/mutation";
import { Select } from "../../../../stories/Select/Select";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { msgType, optionsType } from "../../../../utils/Form.types";
import { Direction, Operation, SortBy } from "../../../../utils/Enum.types";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import { SwConfigQueryType } from "../../../HR/enums/Enum.types";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import DownArrow from "../../../../images/DownArrow.svg";
import Time from "../../../../components/common/TimeZone";
import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";
import { InstDetailsByNodeId } from "../../../../queries/institution/list/byId";
import { PageInfo } from "../../../Channel/Types";
import { userDetails } from "../../../../Types/Accounting/other";

interface Props {
  config_Type: "EDUATE" | "INST";
}

interface MsgSwTimeZoneEdge {
  node: {
    id: number;
    timezone: string;
  };
  cursor: string;
}

interface GetTimeZoneVars {
  token: string;
  after: string | null;
  first: number;
  orderBy: {
    direction: Direction;
    field: SortBy;
  };
  name: string;
}

interface GetTimeZoneData {
  GetTimeZone: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: MsgSwTimeZoneEdge[];
  };
}

export interface UpdateInstTimeZoneAndBdayMsgTimeData {
  UpdateInstTimeZoneAndBdayMsgTime: boolean;
}

export interface UpdateInstTimeZoneAndBdayMsgTimeVars {
  token: string;
  inst_id: string;
  user_details: userDetails;
  input: {
    inst_time_zone: string;
    bd_msg_hours: number;
    bd_msg_mins: number;
  };
}

const InstitutionCompleteConfiguration = ({ config_Type }: Props) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();

  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();

  const [mins, setMins] = useState("00");

  const [hrs, setHrs] = useState("00");

  const [timeZones, setTimeZones] = useState<MsgSwTimeZoneEdge[]>([]);

  const timeZoneOptions = timeZones.map((time_zone) => time_zone.node.timezone);

  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [hasNextPage, setHasNextPage] = useState(true);

  const [endCursor, setEndCursor] = useState("");

  const [searchTimeZone, setSearchTimeZone] = useState("");

  const [expanded, setExpanded] = useState<string | false>("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [pageSwDetails, setPageSwConfigList] = useState<GlobalPageConfigData[]>(
    []
  );

  const [institutionWorkingtypeData, setInstitutionWorkingTypeData] = useState<
    optionsType[]
  >([]);

  const [UpdateTimeZone] = useMutation<
    UpdateInstTimeZoneAndBdayMsgTimeData,
    UpdateInstTimeZoneAndBdayMsgTimeVars
  >(UpdateInstTimeZoneAndBdayMsgTime, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [GetTimeZones, { data, loading: timeZoneLoading, fetchMore, error }] =
    useLazyQuery<GetTimeZoneData, GetTimeZoneVars>(GetTimeZone, {
      variables: {
        after: null,
        first: ROWS_PER_PAGE,
        orderBy: {
          direction: Direction.ASC,
          field: SortBy.TIME_ZONE,
        },
        token,
        name: searchTimeZone,
      },
    });

  useEffect(() => {
    if (token) GetTimeZones();
  }, [token, GetTimeZones]);

  useEffect(() => {
    if (data && !timeZoneLoading) {
      const newData = data.GetTimeZone.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = timeZones.find(
            ({ node }) => node.id && node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setTimeZones(updatedNewData);
      } else {
        setTimeZones(newData);
      }
      setEndCursor(data.GetTimeZone.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, timeZoneLoading]);

  const { InstDetails } = useInstDetails(1);
  const [GetInstitutionWorkingType] = useLazyQuery<
    SoftwareConfigList,
    SoftwareVars
  >(GetSwConfigReferenceDataByKey);
  const [GetConfigData, { data: ConfigReferenceData, loading: configLoading }] =
    useLazyQuery<SoftwareConfigTypeList, SoftwareConfigTypeVar>(
      GetSwConfigReferenceData,
      { variables: { token } }
    );
  const { user_details } = useLoggedInUserDetails();

  const [UpdateInstConfig, { loading: updationLoading }] = useMutation(
    UpdateSwConfigVariables,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [GetSwModuleDetails, { loading }] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const instTypeData = (type: string) => {
    const typeData = ConfigReferenceData?.GetSwConfigReferenceData?.find(
      (data) => data.list_item_key === type
    );
    return typeData?.list_item_list
      .split(";")
      .map((d) => ({
        label: d.split(":")[0],
        value: d.split(":")[1],
      }))
      .filter(({ value }) => value !== undefined);
  };
  const handleUpdateSwConfig = () => {
    UpdateInstConfig({
      variables: {
        token,
        input: pageSwDetails.map((pageSwDetail) => ({
          id: pageSwDetail.id,
          config_key: pageSwDetail.config_key,
          config_boolean_value: pageSwDetail.config_boolean_value,
          config_integer_value: pageSwDetail.config_integer_value,
          config_double_value: pageSwDetail.config_double_value,
          config_string_value: pageSwDetail.config_string_value,
          config_string_value_2: pageSwDetail.config_string_value_2,
          inst_id: InstId,
        })),
        user_details,
      },
      refetchQueries: [
        {
          query: GetSwConfigVariables,
          variables: {
            token,
            inst_id: InstId!,
            input: {
              config_query_type:
                config_Type === "INST"
                  ? SwConfigQueryType.INST_COMPLETE_CONFIG
                  : SwConfigQueryType.EDUATE_COMPLETE_CONFIG,
              str_value: EMPTY_STRING,
              int_value: 0,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Global Configuration Saved Successfully",
          operation: Operation.CREATE,
        });
      }
      handleClear();
    });
  };
  const handleClear = () => {
    setExpanded(false);
  };

  const handleChangeValue = (
    key: string,
    type:
      | "BOOL"
      | "INT"
      | "primary_list_key"
      | "secondary_list_key"
      | "VARCHAR"
      | "VARCHAR2",
    switchValue?: boolean | null,
    selectedValue?: string | null
  ) => {
    if (type === "BOOL") {
      const newState =
        pageSwDetails &&
        // eslint-disable-next-line
        pageSwDetails.map((obj) =>
          obj.config_key === key
            ? { ...obj, config_boolean_value: switchValue! }
            : obj
        );
      setPageSwConfigList(newState);
    }

    if (type === "INT") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_integer_value: Number(selectedValue)! }
          : obj
      );
      setPageSwConfigList(newState);
    }
    if (type === "primary_list_key") {
      const config_key = key + "_" + selectedValue!;
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? {
              ...obj,
              config_secondary_list_key: config_key,
              config_string_value: selectedValue!,
            }
          : obj
      );
      setPageSwConfigList(newState);
      GetInstitutionWorkingType({
        variables: { config_key, token },
      }).then(({ data }) => {
        if (data?.GetSwConfigReferenceDataByKey) {
          setInstitutionWorkingTypeData(
            data.GetSwConfigReferenceDataByKey.list_item_list
              .split(";")
              ?.map((d) => ({
                label: d?.split(":")[0],
                value: d?.split(":")[1],
              }))
          );
        }
      });
    }

    if (type === "secondary_list_key") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value_2: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }

    if (type === "VARCHAR") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }

    if (type === "VARCHAR2") {
      const newState = pageSwDetails.map((obj) =>
        obj.config_key === key
          ? { ...obj, config_string_value_2: selectedValue! }
          : obj
      );
      setPageSwConfigList(newState);
    }
  };
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const groupedPageSwDetails: { [key: string]: GlobalPageConfigData[] } =
    pageSwDetails.reduce((groups, item) => {
      const { config_key_used_for_module } = item;
      if (!groups[config_key_used_for_module]) {
        groups[config_key_used_for_module] = [];
      }
      groups[config_key_used_for_module].push(item);
      return groups;
    }, {} as { [key: string]: GlobalPageConfigData[] });
  useEffect(() => {
    if (token) {
      GetSwModuleDetails({
        variables: {
          token,
          inst_id: InstId!,
          input: {
            config_query_type:
              config_Type === "INST"
                ? SwConfigQueryType.INST_COMPLETE_CONFIG
                : SwConfigQueryType.EDUATE_COMPLETE_CONFIG,
            str_value: "",
            int_value: 0,
          },
        },
      }).then(({ data }) => {
        if (data) {
          setPageSwConfigList(data.GetSwConfigVariables);
        }
      });
    }
  }, [token, InstId, config_Type, GetSwModuleDetails]);
  useEffect(() => {
    if (token) {
      GetConfigData();
    }
  }, [token, GetConfigData]);

  const handleTimeZone = () => {
    UpdateTimeZone({
      variables: {
        input: {
          bd_msg_hours: Number(hrs),
          bd_msg_mins: Number(mins),
          inst_time_zone: selectedTimeZone
            ? selectedTimeZone
            : InstDetails.data && InstDetails.data.nodes.length
            ? InstDetails.data.nodes[0].inst_time_zone
            : "",
        },
        inst_id: InstId!,
        token,
        user_details,
      },
      refetchQueries: [
        {
          query: InstDetailsByNodeId,
          variables: {
            ids: InstId,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.UpdateInstTimeZoneAndBdayMsgTime) {
        setMessage({
          flag: true,
          message: "Time zone added sucessfully",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !timeZoneLoading) {
        fetchMore({
          variables: {
            first: ROWS_PER_PAGE,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetTimeZone.edges;
            const pageInfo = fetchMoreResult.GetTimeZone.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetTimeZone.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetTimeZone: {
                edges: [...timeZones, ...newEdges],
                pageInfo,
                totalCount: data ? data.GetTimeZone.totalCount! : 0,
              },
            };
          },
        });
      }
    }
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {config_Type === "INST"
          ? `Institution Complete Configuration`
          : "Eduate Per Inst Config"}
      </Title>
      <div className="global-configuration">
        <div className="global-configuration__block">
          <div className="global-configuration__block--details">
            {Object.keys(groupedPageSwDetails).map(
              (label: string, index: number) => {
                const moduleItems = groupedPageSwDetails[label];

                return (
                  <React.Fragment key={index}>
                    <Accordion
                      expanded={expanded === label}
                      onChange={handleChange(label)}
                      key={index}
                    >
                      <AccordionSummary>
                        &nbsp;
                        <Typography component={"span"} variant={"body2"}>
                          {label}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography component={"span"} variant={"body2"}>
                          {moduleItems.map((list, index) => (
                            <div
                              className="global-configuration__data--parameters"
                              key={index}
                            >
                              <Label>
                                {index + 1} &nbsp;
                                {list.config_form_label_caption}
                              </Label>

                              {list.config_key_data_storage_type ===
                              "BOOLEAN+MULTI-VARCHAR" ? (
                                <>
                                  <div>
                                    <FormGroup>
                                      <FormControlLabel
                                        label=""
                                        labelPlacement="start"
                                        control={
                                          <AntSwitch
                                            checked={list.config_boolean_value}
                                            onClick={() => {
                                              const newConfigValue =
                                                !list.config_boolean_value;
                                              handleChangeValue(
                                                list.config_key,
                                                "BOOL",
                                                newConfigValue,
                                                null
                                              );
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </div>
                                  {list.config_boolean_value ? (
                                    <div>
                                      <div className="label-grid">
                                        <Label>Line 1</Label>
                                        <Input
                                          value={list.config_string_value}
                                          onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                          ) =>
                                            handleChangeValue(
                                              list.config_key,
                                              "VARCHAR",
                                              null,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="label-grid">
                                        <Label>Line 2</Label>
                                        <Input
                                          value={list.config_string_value_2}
                                          onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                          ) =>
                                            handleChangeValue(
                                              list.config_key,
                                              "VARCHAR2",
                                              null,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </>
                              ) : null}
                              {list.config_depends_on_primary_list_key &&
                              !list.config_depends_on_secondary_list_key ? (
                                <div>
                                  <Select
                                    value={list.config_string_value}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      handleChangeValue(
                                        list.config_key,
                                        "primary_list_key",
                                        null,
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <option>Select</option>
                                    {instTypeData(
                                      list.config_primary_list_key
                                    )?.map(({ label, value }, index) => (
                                      <React.Fragment key={index}>
                                        <option value={value}>{label}</option>
                                      </React.Fragment>
                                    ))}
                                  </Select>
                                </div>
                              ) : null}

                              {list.config_depends_on_primary_list_key &&
                              list.config_depends_on_secondary_list_key ? (
                                <div>
                                  <div>
                                    <Select
                                      value={list.config_string_value}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        handleChangeValue(
                                          list.config_key,
                                          "primary_list_key",
                                          null,
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option>Select</option>
                                      {instTypeData(
                                        list.config_primary_list_key
                                      )?.map(({ label, value }) => (
                                        <React.Fragment key={value}>
                                          <option value={value}>{label}</option>
                                        </React.Fragment>
                                      ))}
                                    </Select>
                                  </div>
                                  <div>
                                    <Label>
                                      {list.config_secondary_list_caption}
                                    </Label>
                                    <Select
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        handleChangeValue(
                                          list.config_key,
                                          "secondary_list_key",
                                          null,
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option>Select</option>
                                      {institutionWorkingtypeData.map(
                                        ({ label, value }) => (
                                          <React.Fragment key={value}>
                                            <option value={value}>
                                              {label}
                                            </option>
                                          </React.Fragment>
                                        )
                                      )}
                                    </Select>
                                  </div>
                                </div>
                              ) : null}

                              {list.config_key_data_storage_type ===
                              "BOOLEAN" ? (
                                <FormGroup>
                                  <FormControlLabel
                                    label=""
                                    labelPlacement="start"
                                    control={
                                      <AntSwitch
                                        checked={list.config_boolean_value}
                                        onClick={() => {
                                          const newConfigValue =
                                            !list.config_boolean_value;
                                          handleChangeValue(
                                            list.config_key,
                                            "BOOL",
                                            newConfigValue,
                                            null
                                          );
                                        }}
                                      />
                                    }
                                  />
                                </FormGroup>
                              ) : null}
                              {list.config_key_data_storage_type ===
                              "BOOLEAN+VARCHAR" ? (
                                <FormGroup>
                                  <FormControlLabel
                                    label=""
                                    labelPlacement="start"
                                    control={
                                      <AntSwitch
                                        checked={list.config_boolean_value}
                                        onClick={() => {
                                          const newConfigValue =
                                            !list.config_boolean_value;
                                          handleChangeValue(
                                            list.config_key,
                                            "BOOL",
                                            newConfigValue,
                                            null
                                          );
                                        }}
                                      />
                                    }
                                  />
                                  {list.config_boolean_value && (
                                    <Input
                                      placeholder=""
                                      value={list.config_string_value}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) =>
                                        handleChangeValue(
                                          list.config_key,
                                          "VARCHAR",
                                          null,
                                          e.target.value
                                        )
                                      }
                                    />
                                  )}
                                </FormGroup>
                              ) : null}
                              {list.config_depends_on_primary_list_key ===
                                false &&
                              list.config_key_data_storage_type ===
                                "VARCHAR" ? (
                                <Input
                                  value={list.config_string_value}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    handleChangeValue(
                                      list.config_key,
                                      "VARCHAR",
                                      null,
                                      e.target.value
                                    )
                                  }
                                />
                              ) : null}
                              {list.config_key_data_storage_type === "INT" ? (
                                <Input
                                  value={list.config_integer_value}
                                  type="number"
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    handleChangeValue(
                                      list.config_key,
                                      "INT",
                                      null,
                                      e.target.value
                                    )
                                  }
                                />
                              ) : null}
                            </div>
                          ))}
                          <Button mode="save" onClick={handleUpdateSwConfig} />
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </React.Fragment>
                );
              }
            )}
            <Accordion
              expanded={expanded === "timezone"}
              onChange={handleChange("timezone")}
            >
              <AccordionSummary>
                &nbsp;
                <Typography component={"span"} variant={"body2"}>
                  INSTITUTION TIME ZONE
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component={"span"} variant={"body2"}>
                  <div className="label-grid">
                    <Label>Preset Time Zone</Label>
                    <Input
                      value={
                        InstDetails.data && InstDetails.data.nodes.length
                          ? InstDetails.data.nodes[0].inst_time_zone
                          : ""
                      }
                      disabled
                    />
                  </div>
                  <div className="label-grid">
                    <Label>Time Zone</Label>
                    <Autocomplete
                      classes={classes}
                      options={timeZoneOptions}
                      value={selectedTimeZone}
                      isOptionEqualToValue={(option) => {
                        return (
                          option === selectedTimeZone || selectedTimeZone === ""
                        );
                      }}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setSelectedTimeZone(newValue);
                        } else {
                          setSelectedTimeZone("");
                        }
                      }}
                      ListboxProps={{
                        onScroll: handleScroll,
                      }}
                      // openOnFocus
                      popupIcon={<img src={DownArrow} alt="/" />}
                      forcePopupIcon
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          required
                          onChange={(e) => {
                            setSearchTimeZone(e.target.value);
                          }}
                          classes={{
                            root: textClasses.formControlRoot,
                          }}
                        />
                      )}
                    />
                  </div>

                  <br />

                  <div className="label-grid">
                    <Label>Time to send Birthday Wishes (24hrs) :</Label>
                    <Time
                      hrs={hrs}
                      mins={mins}
                      onChangeHrs={setHrs}
                      onChangeMins={setMins}
                    />
                  </div>
                  <Button mode="save" onClick={handleTimeZone} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={updationLoading || configLoading || loading} />
    </>
  );
};

export default InstitutionCompleteConfiguration;
