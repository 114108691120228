import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import {
  handleFormEvent,
  removeMoreSpace,
} from "../../../../utils/UtilFunctions";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getPredefinedDataByType,
  getPredefinedDataByTypes,
} from "../../../../queries/preDefinedData/byType";

import Modal from "react-modal";
import Home from "../../Home/Index";
import Input from "../../../../components/common/Input/Index";
import {
  AddPredefinedDataType,
  AddPrefinedDataForm,
} from "../../../../queries/preDefinedData/new";
import { msgType, PredefinedDataInput } from "../../../../utils/Form.types";
import { PredefineDataInputValidate } from "../../../../utils/validationRules";
import { Form, Formik } from "formik";
import {
  PredefinedDataByTypeData,
  PredefinedDataByTypevars,
} from "../../../../Types/Settings";
import { TableHeaderProps } from "../../../../Types/Tables";
import { SettingsTitleProps } from "../../../../Types/Titles";
import { InstitutionCustomStyles } from "../../../../styles/ModalStyles";

import LoadingModal from "../../../../pages/LoadingModal";
import Edit from "../../../../images/EditProfile.svg";
import DeleteImg from "../../../../images/Delete.svg";

import DownArrow from "../../../../images/DownArrow.svg";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import useToken from "../../../../customhooks/useToken";
import { LicenseTypes, Operation } from "../../../../utils/Enum.types";
import MessageModal from "../../../../pages/MessageModal";
import DeleteModal from "../../../../pages/DeleteModal";
import { Label } from "../../../../stories/Label/Label";
import { PredefinedDataNode } from "../../../../queries/common";
import { UpdatePredefinedDataById } from "../../../../queries/preDefinedData/update";
import { DeletePredefinedDataById } from "../../../../queries/preDefinedData/delete";
import { EMPTY_STRING } from "../../../../utils/constants";
import { Keys } from "../../../../utils/Enum.keys";
import { useNavigate } from "react-router-dom";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { Settings } = require("../../../../json/title.json");
const { PreDefinedData } = require("../../../../json/config.json");

const { Settings_Table } = require("../../../../json/table.json");

interface ConfigDataType {
  LabelName: string;
  inputName: string;
  dataType?: string;
}
const List = () => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const { user_details } = useLoggedInUserDetails();
  const { token } = useToken();
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState("");
  const [predefinedModal, setPredefinedModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [predefinedId, setPredefinedId] = useState(0);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [operationType, setOperationType] = useState("");

  const [formData, setFormData] = useState<PredefinedDataInput>({
    type: "",
    id: 0,
    indexno: 0,
    value1: "",
    value2: "",
    value3: "",
    value4: "",
  });

  const [type, setType] = useState("");
  const [PredefinedDataByType, { data }] = useLazyQuery<
    PredefinedDataByTypeData,
    PredefinedDataByTypevars
  >(getPredefinedDataByType);

  const [GetPredefindedData, PredefinedDataByTypes] = useLazyQuery(
    getPredefinedDataByTypes,
    {
      variables: {
        token,
      },
    }
  );

  const [predefinedData, { data: definedData, loading }] =
    useLazyQuery(PredefinedDataNode);

  useEffect(() => {
    if (token) {
      PredefinedDataByType({
        variables: {
          first: null,
          token,
          type: selectedType,
          Lname: "",
        },
      });
    }
  }, [selectedType, PredefinedDataByType, token]);
  const [AddPredefinedData, { loading: predefinedDataCreationLoading }] =
    useMutation(AddPrefinedDataForm, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  const [
    AddPredefinedDataTypeOnly,
    { loading: predefinedDataTypeCreationLoading },
  ] = useMutation(AddPredefinedDataType, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [updatePredefined] = useMutation(UpdatePredefinedDataById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [DeletePredefined] = useMutation(DeletePredefinedDataById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const HandleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues: PredefinedDataInput) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const HandleSubmit = async () => {
    if (operationType === Operation.CREATE) {
      await AddPredefinedData({
        variables: {
          token,
          type: removeMoreSpace(formData.type),
          index: formData.indexno,
          value1: removeMoreSpace(formData.value1),
          value2: removeMoreSpace(formData.value2),
          value3: removeMoreSpace(formData.value3),
          value4: removeMoreSpace(formData.value4),
          user_details,
        },
        refetchQueries: [
          {
            query: getPredefinedDataByType,
            variables: {
              type: formData.type,
              first: null,
              Lname: "",
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setSelectedType(formData.type);
          setMessage({
            message: "Pre-Defined Data Added Successfully",
            flag: true,
            operation: Operation.CREATE,
          });
          setPredefinedModal(!predefinedModal);
        }
      });
    } else {
      updatePredefined({
        variables: {
          token,

          type: removeMoreSpace(formData.type),
          id: predefinedId,
          index: formData.indexno,
          value1: removeMoreSpace(formData.value1),
          value2: removeMoreSpace(formData.value2),
          value3: removeMoreSpace(formData.value3),
          value4: removeMoreSpace(formData.value4),
          user_details,
        },
        refetchQueries: [
          {
            query: getPredefinedDataByType,
            variables: {
              token,
              type: formData.type,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setSelectedType(formData.type);
          setMessage({
            message: "Pre-Defined Data Updated successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
          setPredefinedModal(!predefinedModal);
        }
      });
    }
  };

  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (token) {
      GetPredefindedData();
    }
  }, [token, GetPredefindedData]);

  const preDefinedDropdown =
    PredefinedDataByTypes?.data &&
    PredefinedDataByTypes?.data?.GetPredefinedDataTypes.map(
      (option: string) => {
        return option;
      }
    );

  const handleAddPredefinedDataType = async () => {
    await AddPredefinedDataTypeOnly({
      variables: {
        token,
        data_type: type,
        user_details,
      },
      refetchQueries: [
        {
          query: getPredefinedDataByTypes,
          variables: {
            type,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Pred-Defined Data Added Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };

  const HandleDelete = () => {
    DeletePredefined({
      variables: {
        token,
        id: predefinedId,
        user_details,
      },
      refetchQueries: [
        {
          query: getPredefinedDataByTypes,
          variables: {
            token,
            type,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setDeleteModal(!deleteModal);
        setMessage({
          message: "Pre-Defined Data Deleted successfully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
  };

  const handleClear = () => {
    setFormData({
      type: "",
      id: 0,
      indexno: 0,
      value1: "",
      value2: "",
      value3: "",
      value4: "",
    });
  };

  useEffect(() => {
    if (!loading && definedData && operationType === Operation.UPDATE) {
      const { type, id, index, value1, value2, value3, value4 } =
        definedData.nodes[0] || EMPTY_STRING;

      setFormData({
        type,
        id,
        indexno: index,
        value1,
        value2,
        value3,
        value4,
      });
    }
  }, [definedData, loading, predefinedModal, operationType]);

  return (
    <>
      <Home
        DashBoardRequired={false}
        NavType={LicenseTypes.EDUATE_CONFIGURATION}
      />

      <Title>
        {Settings.PredefinedList.map(
          (title: SettingsTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.List}</React.Fragment>;
          }
        )}
      </Title>

      <div className="row g-0">
        <div className="col-3 button-left">
          <Autocomplete
            classes={classes}
            options={preDefinedDropdown! ?? [""]}
            openOnFocus
            value={selectedType}
            onChange={(e, newValue) => {
              if (newValue) {
                setSelectedType(newValue!);
              } else {
                setSelectedType(EMPTY_STRING);
              }
            }}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === Keys.BACKSPACE) {
                setSelectedType("");
              }
            }}
            forcePopupIcon
            freeSolo
            popupIcon={<img src={DownArrow} alt="/" />}
            renderInput={(params) => (
              <TextField
                onChange={(e) => {
                  setType(e.target.value);
                }}
                {...params}
                fullWidth
                classes={{ root: textClasses.formControlRoot }}
              />
            )}
          />
        </div>
        <div className="col-3 button-left">
          <Button
            mode="predefined-type"
            onClick={() => {
              handleAddPredefinedDataType();
              setMessage({
                message: "",
                flag: false,
                operation: Operation.NONE,
              });
            }}
          />
        </div>

        <div className="col-2">
          <Button
            mode="addnew"
            type="button"
            autoFocus
            onClick={() => {
              setOperationType(Operation.CREATE);
              setMessage({
                message: "",
                flag: false,
                operation: Operation.NONE,
              });
              handleClear();
              setPredefinedModal(!predefinedModal);
            }}
          />
        </div>
      </div>

      <div className="predefined__tableblock">
        <TableContainer className="predefined__table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Settings_Table.PredefinedList.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        <TableCell>{th.labelName}</TableCell>
                      </React.Fragment>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.GetPredefinedDataByType.edges.map(
                  (data, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell>{data.node.id}</TableCell>
                          <TableCell>{data.node.index}</TableCell>
                          <TableCell>{data.node.type}</TableCell>
                          <TableCell>{data.node.value1}</TableCell>
                          <TableCell>{data.node.value2}</TableCell>
                          <TableCell>{data.node.value3}</TableCell>
                          <TableCell>{data.node.value4}</TableCell>
                          <TableCell>
                            <img
                              src={Edit}
                              alt="/"
                              onClick={() => {
                                setPredefinedId(Number(data.node.id));

                                setOperationType(Operation.UPDATE);
                                predefinedData({
                                  variables: {
                                    ids: [data.node.id]! ?? "",
                                    token,
                                  },
                                });
                                setPredefinedModal(!predefinedModal);
                              }}
                            />
                            <img
                              src={DeleteImg}
                              alt="/"
                              onClick={() => {
                                setPredefinedId(Number(data.node.id));
                                setDeleteModal(!deleteModal);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  }
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="button-left">
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={predefinedModal}
        style={InstitutionCustomStyles}
        ariaHideApp={false}
      >
        <Title>
          {Settings.PredefinedList.map(
            (title: SettingsTitleProps, index: React.Key) => {
              return (
                <React.Fragment key={index}>
                  {/* {title.Registration} */}
                  {operationType === Operation.CREATE
                    ? title.Registration
                    : title.Update}
                </React.Fragment>
              );
            }
          )}
        </Title>
        <Formik
          initialValues={formData}
          validationSchema={PredefineDataInputValidate}
          onSubmit={HandleSubmit}
        >
          {(meta) => {
            return (
              <Form>
                {PreDefinedData.SelectLabel.map(
                  (label: ConfigDataType, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        {operationType === Operation.CREATE ? (
                          <div className="label-grid">
                            <Label>{label.LabelName}</Label>

                            <Autocomplete
                              classes={classes}
                              options={preDefinedDropdown!}
                              openOnFocus
                              value={formData.type}
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValue) => ({
                                    ...prevValue,
                                    type: newValue!,
                                  }));
                                } else {
                                  setFormData((prevValue) => ({
                                    ...prevValue,
                                    type: EMPTY_STRING,
                                  }));
                                }
                              }}
                              onKeyDown={(e: React.KeyboardEvent) => {
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValue) => ({
                                    ...prevValue,
                                    type: EMPTY_STRING,
                                  }));
                                }
                              }}
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  name="type"
                                  required
                                  classes={{
                                    root: textClasses.formControlRoot,
                                  }}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <Input
                            type="text"
                            LabelName="Selected Type"
                            values={formData.type}
                            disabled
                          />
                        )}
                      </React.Fragment>
                    );
                  }
                )}

                {PreDefinedData.Labels.map(
                  (label: ConfigDataType, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        <Input
                          LabelName={label.LabelName}
                          name={label.inputName}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            HandleValueChange(e);
                            meta.handleChange(e);
                          }}
                          onKeyDown={handleFormEvent}
                          values={formData[label.inputName]}
                          type={label.dataType}
                        />
                      </React.Fragment>
                    );
                  }
                )}
                <Button mode="save" type="submit" />

                <Button
                  mode="cancel"
                  type="button"
                  onClick={() => {
                    setPredefinedModal(!predefinedModal);
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal>
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={predefinedId}
      />
      <LoadingModal
        flag={
          predefinedDataCreationLoading ?? predefinedDataTypeCreationLoading
        }
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default List;
