import {
  Autocomplete,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Input from "../../../../components/common/Input/Index";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import { LabelNameProps } from "../../../../Types/Labels";
import { HRTitleProps } from "../../../../Types/Titles";
import DownArrow from "../../../../images/DownArrow.svg";
import { AntSwitch } from "../../../../pages/Switch";
import { Button } from "../../../../stories/Button/Button";
import { Form, Formik } from "formik";
import {
  LeaveLedgerForm,
  msgType,
  optionsType,
} from "../../../../utils/Form.types";
import { LeaveLedgerValidation } from "../../../../utils/validationRules";
import {
  Direction,
  LedgersForType,
  Operation,
  PredefinedDataTypes,
  SortBy,
} from "../../../../utils/Enum.types";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import {
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { Keys } from "../../../../utils/Enum.keys";
import { useLazyQuery, useMutation } from "@apollo/client";

import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import MessageModal from "../../../../pages/MessageModal";

import {
  GetEduateLeaveLedgerById,
  GetLeaveById,
  GetPayRollLeaveLdgrsByInstId,
  GetSwLeaveLdgrs,
} from "../../queries/leaveledger/list";
import {
  AddPayRollLeaveLdgr,
  AddSwLeaveLdgr,
  UpdatePayRollLeaveLdgr,
  UpdateSwLeaveLdgr,
} from "../../queries/leaveledger/mutation";
import { GetPayRollLeaveLdgrsByInstIdEdges } from "../../Types/paginationTypes";
import { removeMoreSpace } from "../../../../utils/UtilFunctions";
import { leave_desc } from "../../constants";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { HRTitles } = require("../../json/title.json");
const { HRFormLabels } = require("../../json/form.json");
interface Props {
  operation: Operation;
  modalFlag: boolean;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  eduateOrHr: LedgersForType;
  id?: number;
  setId?: React.Dispatch<React.SetStateAction<number>>;
}

interface GetEmployeedByIdVars {
  token: string;
  id: number;
}
const NewLeaveLedger = ({
  operation,
  modalFlag,
  setModalFlag,
  eduateOrHr,
  id,
  setId,
}: Props) => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  //Refs
  const radioRefs = useRef<HTMLButtonElement[]>([]);
  const saveRef = useRef<HTMLButtonElement>(null);
  //States
  const [leaveType, setLeaveType] = useState(EMPTY_OPTIONSTYPE_OBJECT);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [formData, setFormData] = useState<LeaveLedgerForm>({
    leave_desc: "",
    leave_short_code: "",
    leave_only_for_women: false,
    leave_is_credited: false,
    leave_carry_forwarded: false,
    leave_is_earned: false,
  });
  const leaveDescRef = document.getElementsByName(leave_desc)[0];
  const [GetLeaveLedger] = useLazyQuery<
    GetPayRollLeaveLdgrsByInstIdEdges,
    GetEmployeedByIdVars
  >(GetLeaveById);
  const [GetEduateLeaveLedger] = useLazyQuery(GetEduateLeaveLedgerById);
  //Mutations
  const [AddHRLeave] = useMutation(AddPayRollLeaveLdgr, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [AddEduateLeave] = useMutation(AddSwLeaveLdgr, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateHRLeave] = useMutation(UpdatePayRollLeaveLdgr, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateEduateLeave] = useMutation(UpdateSwLeaveLdgr, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  //Custom Hooks
  const {
    PredefinedData: { dropDown: leaveTypes },
  } = usePredefinedDataByType(PredefinedDataTypes.LEAVE_TYPE, EMPTY_STRING);
  const handleClear = () => {
    setFormData({
      leave_desc: "",
      leave_short_code: "",
      leave_idx: 0,
      leave_only_for_women: false,
      leave_is_credited: false,
      leave_carry_forwarded: false,
      leave_is_earned: false,
    });
    setLeaveType(EMPTY_OPTIONSTYPE_OBJECT);
    leaveDescRef.focus();
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setModalFlag(!modalFlag);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const addRefs = (el: HTMLButtonElement) => {
    if (el && !radioRefs?.current.includes(el)) {
      radioRefs.current.push(el);
    }
  };
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));

  const handleSubmit = () => {
    if (eduateOrHr === LedgersForType.EDUATE) {
      if (operation === Operation.CREATE) {
        AddEduateLeave({
          variables: {
            token,
            input: {
              ...formData,
              leave_desc: removeMoreSpace(formData.leave_desc),
              leave_type: leaveType.value,
            },
            user_details,
          },
          refetchQueries: [
            {
              query: GetSwLeaveLdgrs,
              variables: {
                token,
              },
              fetchPolicy: "network-only",
            },
          ],
        }).then(({ data }) => {
          if (data)
            setMessage({
              flag: true,
              message: "Eaduate Leave Ledger added successfully",
              operation: Operation.CREATE,
            });
        });
      } else
        UpdateEduateLeave({
          variables: {
            token,
            id: id!,
            input: {
              ...formData,
              leave_desc: removeMoreSpace(formData.leave_desc),
              leave_type: leaveType.value,
            },
            user_details,
          },
          refetchQueries: [
            {
              query: GetEduateLeaveLedgerById,
              variables: {
                token,
                id,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setId?.(0);
            setMessage({
              flag: true,
              message: "Eduate Leave Ledger updated successfully",
              operation: Operation.UPDATE,
            });
          }
        });
    } else {
      if (operation === Operation.CREATE) {
        AddHRLeave({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            input: {
              ...formData,
              leave_desc: removeMoreSpace(formData.leave_desc),
              leave_type: leaveType.value,
            },
          },
          refetchQueries: [
            {
              query: GetPayRollLeaveLdgrsByInstId,
              variables: {
                token,
                inst_id: InstId!,
                first: ROWS_PER_PAGE,
                after: null,
                direction: Direction.ASC,
                sortBy: SortBy.LEAVE_DESC,
                name: EMPTY_STRING,
              },
              fetchPolicy: "network-only",
            },
          ],
        }).then(({ data }) => {
          if (data)
            setMessage({
              flag: true,
              message: "Leave Ledger added successfully",
              operation: Operation.CREATE,
            });
        });
      } else
        UpdateHRLeave({
          variables: {
            token,
            id: id!,
            inst_id: InstId,
            user_details,
            input: {
              ...formData,
              leave_desc: removeMoreSpace(formData.leave_desc),
              leave_type: leaveType.value,
            },
          },
          refetchQueries: [
            {
              query: GetLeaveById,
              variables: {
                token,
                id: id!,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setId?.(0);
            setMessage({
              flag: true,
              message: "Leave Ledger updated  successfully",
              operation: Operation.UPDATE,
            });
          }
        });
    }
  };

  useEffect(() => {
    if (id! > 0 && operation === Operation.UPDATE && token) {
      if (eduateOrHr === LedgersForType.HR) {
        GetLeaveLedger({
          variables: {
            token,
            id: id!,
          },
        }).then(({ data }) => {
          if (data) {
            setFormData({
              leave_desc: data.node.leave_desc,
              leave_short_code: data.node.leave_short_code,
              leave_only_for_women: data.node.leave_only_for_women,
              leave_is_credited: data.node.leave_is_credited,
              leave_carry_forwarded: data.node.leave_carry_forwarded,
              leave_is_earned: data.node.leave_is_earned,
            });
            setLeaveType(
              leaveTypes.find(
                (leave) => leave.value === data.node.leave_type
              ) ?? EMPTY_OPTIONSTYPE_OBJECT
            );
          }
        });
      } else {
        GetEduateLeaveLedger({
          variables: {
            token,
            id,
          },
        }).then(({ data }) => {
          if (data) {
            setFormData({
              leave_desc: data.node.leave_desc,
              leave_short_code: data.node.leave_short_code,
              leave_only_for_women: data.node.leave_only_for_women,
              leave_is_credited: data.node.leave_is_credited,
              leave_carry_forwarded: data.node.leave_carry_forwarded,
              leave_is_earned: data.node.leave_is_earned,
            });
            setLeaveType(
              leaveTypes.find(
                (leave) => leave.value === data.node.leave_type
              ) ?? EMPTY_OPTIONSTYPE_OBJECT
            );
          }
        });
      }
    }
  }, [
    id,
    operation,
    GetLeaveLedger,
    GetEduateLeaveLedger,
    token,
    leaveTypes,
    eduateOrHr,
  ]);

  return (
    <>
      <Title>
        {HRTitles.MasterData.LeaveLedger.Titles.map(
          (title: HRTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {operation === Operation.CREATE ? title.Add : title.Update}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <Formik
        initialValues={formData}
        validationSchema={LeaveLedgerValidation}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className="payroll-masterdata__leave-ledger--add">
              {HRFormLabels.MasterData.LeaveLedger.InputType.map(
                (label: LabelNameProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      <Input
                        LabelName={label.LabelName}
                        name={label.inputName}
                        values={formData[label.inputName]}
                        required={label.required}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          meta.handleChange(e);
                          handleValueChange(e);
                        }}
                        autoFocus={label.autoFocus}
                      />
                    </React.Fragment>
                  );
                }
              )}
              {HRFormLabels.MasterData.LeaveLedger.SelectType.map(
                (label: LabelNameProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="label-grid">
                        <Label>{label.LabelName}</Label>
                        <Autocomplete
                          classes={classes}
                          options={leaveTypes}
                          value={leaveType}
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setLeaveType(newValue as optionsType);
                            } else {
                              setLeaveType(EMPTY_OPTIONSTYPE_OBJECT);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === Keys.BACKSPACE) {
                              setLeaveType(EMPTY_OPTIONSTYPE_OBJECT);
                            }
                            if (e.key === Keys.ENTER) {
                              (
                                radioRefs?.current[0]
                                  ?.childNodes[0] as HTMLInputElement
                              )?.focus();
                            }
                          }}
                          openOnFocus
                          freeSolo
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => {}}
                              fullWidth
                              required
                              classes={{
                                root: textClasses.formControlRoot,
                              }}
                            />
                          )}
                        />
                      </div>
                    </React.Fragment>
                  );
                }
              )}

              {HRFormLabels.MasterData.LeaveLedger.CheckBoxType2.map(
                (label: LabelNameProps, index: number) => {
                  return (
                    <FormGroup key={index}>
                      <FormControlLabel
                        className="payroll-masterdata__block--form-labels"
                        label={label.LabelName}
                        control={
                          <AntSwitch
                            checked={formData[label.inputName] ? true : false}
                            ref={addRefs}
                            name={label.inputName}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (radioRefs?.current[index + 1]) {
                                  (
                                    radioRefs?.current[index + 1]
                                      ?.childNodes[0] as HTMLInputElement
                                  )?.focus();
                                } else saveRef?.current?.focus();
                              }
                            }}
                            onClick={(e: React.MouseEvent) => {
                              const { name, checked } =
                                e.target as HTMLInputElement;
                              setFormData({
                                ...formData,
                                [name]: checked,
                              });
                            }}
                          />
                        }
                        labelPlacement="start"
                      />
                    </FormGroup>
                  );
                }
              )}
              <Button mode="save" type="submit" buttonref={saveRef!} />
              <Button mode="clear" type="button" onClick={handleClear} />
              <Button
                mode="cancel"
                type="button"
                onClick={() => {
                  handleClear();
                  setModalFlag(!modalFlag);
                }}
              />
            </Form>
          );
        }}
      </Formik>
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
    </>
  );
};

export default NewLeaveLedger;
