import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { Step, StepLabel, Stepper } from "@mui/material";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import Input from "../../../../components/common/Input/Index";
import { LabelNameProps } from "../../../../Types/Labels";
import { HRTitleProps } from "../../../../Types/Titles";
import { Operation, PredefinedDataTypes } from "../../../../utils/Enum.types";
import DownArrow from "../../../../images/DownArrow.svg";
import EmployeeDetails from "./EmployeeDetails";
import { useNavigate, useParams } from "react-router-dom";
import Home from "../../Home/Index";
import { useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import LoadingModal from "../../../../pages/LoadingModal";
import { UpdatePayRollEmpPersonalDetails } from "../../queries/employee/mutation";
import { EmpFormPersonalDetails } from "../../Types/formTypes";
import { Form, Formik } from "formik";
import { EmpPersonalDetailsSchema } from "../../../../utils/payrole";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import {
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_STRING,
} from "../../../../utils/constants";
import { optionsType } from "../../../../utils/Form.types";
import { Keys } from "../../../../utils/Enum.keys";
import useEmpDetailsById from "../../hooks/useEmpDetailsById";

import { handleMUISelectEvent } from "../../../../utils/UtilFunctions";
import { stepHeader } from "../../constants";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { HRTitles } = require("../../json/title.json");
const { HRFormLabels } = require("../../json/form.json");

interface props {
  step: number;
  nextStep?: () => void;
  prevStep?: () => void;
  type?: Operation;
}

const PersonalDetails = ({ nextStep, prevStep, type, step }: props) => {
  const classes = formAutoCompleteStyles();
  const { token } = useToken();
  const { InstId, empId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const textClasses = formAutoCompleteTextStyles();
  const stepsHeader = stepHeader();
  const navigate = useNavigate();
  const [UpdateEmpPersonalData, { loading }] = useMutation(
    UpdatePayRollEmpPersonalDetails
  );

  const [searchReligion, setSearchReligion] = useState("");
  const [searchCaste, setSearchCaste] = useState("");
  const [searchNationality, setSearchNationality] = useState("");
  const [searchBloodGroup, setSearchBloodGroup] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchDistrict, setSearchDistrict] = useState("");
  const [searchState, setSearchState] = useState("");
  const [searchCorrCity, setSearchCorrCity] = useState("");
  const [searchCorrDistrict, setSearchCorrDistrict] = useState("");
  const [searchCorrState, setSearchCorrState] = useState("");
  //useRefs
  const saveRef = useRef<HTMLButtonElement>(null);
  const {
    PredefinedData: { dropDown: MARRIED_UNMARRIED },
  } = usePredefinedDataByType(PredefinedDataTypes.MARTIAL_STATUS, EMPTY_STRING);

  const {
    PredefinedData: { dropDown: RELIGION_OPTIONS },
  } = usePredefinedDataByType(PredefinedDataTypes.RELIGION, searchReligion);
  const {
    PredefinedData: { dropDown: CASTE_OPTIONS },
  } = usePredefinedDataByType(PredefinedDataTypes.CASTE, searchCaste);
  const {
    PredefinedData: { dropDown: NATIONALITY_OPTIONS },
  } = usePredefinedDataByType(
    PredefinedDataTypes.NATIONALITY,
    searchNationality
  );
  const {
    PredefinedData: { dropDown: BLOOD_GROUP_OPTIONS },
  } = usePredefinedDataByType(PredefinedDataTypes.BLOODGROUP, searchBloodGroup);

  const {
    PredefinedData: { dropDown: CITY_OPTIONS },
  } = usePredefinedDataByType(PredefinedDataTypes.CITY, searchCity);

  const {
    PredefinedData: { dropDown: DISTRICT_OPTIONS },
  } = usePredefinedDataByType(PredefinedDataTypes.DISTRICT, searchDistrict);

  const {
    PredefinedData: { dropDown: STATE_OPTIONS },
  } = usePredefinedDataByType(PredefinedDataTypes.STATE, searchState);

  const {
    PredefinedData: { dropDown: CORR_CITY_OPTIONS },
  } = usePredefinedDataByType(PredefinedDataTypes.CITY, searchCorrCity);

  const {
    PredefinedData: { dropDown: CORR_DISTRICT },
  } = usePredefinedDataByType(PredefinedDataTypes.DISTRICT, searchCorrDistrict);

  const {
    PredefinedData: { dropDown: CORR_STATE },
  } = usePredefinedDataByType(PredefinedDataTypes.STATE, searchCorrState);

  const [martial_status, setmartial_status] = useState(
    EMPTY_OPTIONSTYPE_OBJECT
  );
  const [religion, set_religion] = useState<optionsType>(
    EMPTY_OPTIONSTYPE_OBJECT
  );
  const [caste, set_caste] = useState<optionsType>(EMPTY_OPTIONSTYPE_OBJECT);
  const [nationality, set_nationality] = useState<optionsType>(
    EMPTY_OPTIONSTYPE_OBJECT
  );
  const [blood_group, setblood_group] = useState<optionsType>(
    EMPTY_OPTIONSTYPE_OBJECT
  );
  const [city, set_city] = useState<optionsType>(EMPTY_OPTIONSTYPE_OBJECT);
  const [district, set_district] = useState<optionsType>(
    EMPTY_OPTIONSTYPE_OBJECT
  );
  const [state, set_state] = useState<optionsType>(EMPTY_OPTIONSTYPE_OBJECT);
  const [correspondance_city, setcorrespondance_city] = useState<optionsType>(
    EMPTY_OPTIONSTYPE_OBJECT
  );
  const [correspondance_district, setcorrespondance_district] =
    useState<optionsType>(EMPTY_OPTIONSTYPE_OBJECT);
  const [correspondance_state, setcorrespondance_state] = useState<optionsType>(
    EMPTY_OPTIONSTYPE_OBJECT
  );

  // eslint-disable-next-line
  const [formData, setFormData] = useState<EmpFormPersonalDetails>({
    emp_father_name: EMPTY_STRING,
    emp_mother_name: EMPTY_STRING,
    emp_spouse_name: EMPTY_STRING,
    emp_tel: EMPTY_STRING,
    emp_corr_address: EMPTY_STRING,
    emp_corr_pin: EMPTY_STRING,
    emp_perm_address: EMPTY_STRING,
    emp_perm_pin: EMPTY_STRING,
  });
  const { empolyeeData } = useEmpDetailsById();
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));

  const handleUpdatePerosnalDetails = () =>
    UpdateEmpPersonalData({
      variables: {
        token,
        id: empId,

        inst_id: InstId,
        user_details,

        input: {
          ...formData,
          emp_blood_grp: blood_group.value,
          emp_religion: religion.value,
          emp_caste: caste.value,
          emp_corr_city: correspondance_city.value,
          emp_corr_district: correspondance_district.value,
          emp_corr_state: correspondance_state.value,
          emp_perm_city: city.value,
          emp_perm_district: district.value,
          emp_perm_state: state.value,
          emp_nationality: nationality.value,
          emp_martial: martial_status.value,
        },
      },
    }).then(({ data }) => {
      if (data) {
        if (type === Operation.CREATE) {
          navigate(`/${InstId}/hr/employee/3/${empId}/academicContribution`);
        } else {
          navigate(`/${InstId}/hr/employee/${empId}/preview/personaldetails`);
        }
      }
    });

  const handleClear = () => {
    setFormData({
      emp_father_name: EMPTY_STRING,
      emp_mother_name: EMPTY_STRING,
      emp_spouse_name: EMPTY_STRING,
      emp_tel: EMPTY_STRING,
      emp_corr_address: EMPTY_STRING,
      emp_corr_pin: EMPTY_STRING,
      emp_perm_address: EMPTY_STRING,
      emp_perm_pin: EMPTY_STRING,
    });
    setmartial_status(EMPTY_OPTIONSTYPE_OBJECT);
    set_religion(EMPTY_OPTIONSTYPE_OBJECT);
    set_caste(EMPTY_OPTIONSTYPE_OBJECT);
    set_nationality(EMPTY_OPTIONSTYPE_OBJECT);
    setblood_group(EMPTY_OPTIONSTYPE_OBJECT);
    set_city(EMPTY_OPTIONSTYPE_OBJECT);
    set_district(EMPTY_OPTIONSTYPE_OBJECT);
    set_state(EMPTY_OPTIONSTYPE_OBJECT);
    setcorrespondance_city(EMPTY_OPTIONSTYPE_OBJECT);
    setcorrespondance_district(EMPTY_OPTIONSTYPE_OBJECT);
    setcorrespondance_state(EMPTY_OPTIONSTYPE_OBJECT);
  };

  // const options = useMemo(
  //   () => ({
  //     MARRIED_UNMARRIED,
  //     RELIGION_OPTIONS,
  //     CASTE_OPTIONS,
  //     NATIONALITY_OPTIONS,
  //     BLOOD_GROUP_OPTIONS,
  //     CITY_OPTIONS,
  //     DISTRICT_OPTIONS,
  //     STATE_OPTIONS,
  //     CORR_CITY_OPTIONS,
  //     CORR_DISTRICT,
  //     CORR_STATE,
  //   }),
  //   [
  //     MARRIED_UNMARRIED,
  //     RELIGION_OPTIONS,
  //     CASTE_OPTIONS,
  //     NATIONALITY_OPTIONS,
  //     BLOOD_GROUP_OPTIONS,
  //     CITY_OPTIONS,
  //     DISTRICT_OPTIONS,
  //     STATE_OPTIONS,
  //     CORR_CITY_OPTIONS,
  //     CORR_DISTRICT,
  //     CORR_STATE,
  //   ]
  // );

  const findOptionAndSetState = useCallback(
    (
      options: optionsType[],
      value: string,
      setState: React.Dispatch<React.SetStateAction<optionsType>>
    ) => {
      const option =
        options.find(({ value: optionValue }) => optionValue === value) ??
        EMPTY_OPTIONSTYPE_OBJECT;
      setState(option);
    },
    []
  );

  useEffect(() => {
    if (empolyeeData.data && !empolyeeData.loading) {
      const { personal_details } = empolyeeData.data.node;
      setFormData({
        emp_father_name: personal_details.emp_father_name,
        emp_mother_name: personal_details.emp_mother_name,
        emp_spouse_name: personal_details.emp_spouse_name,
        emp_tel: personal_details.emp_tel,
        emp_corr_address: personal_details.emp_corr_address,
        emp_corr_pin: personal_details.emp_corr_pin,
        emp_perm_address: personal_details.emp_perm_address,
        emp_perm_pin: personal_details.emp_perm_pin,
      });

      findOptionAndSetState(
        MARRIED_UNMARRIED,
        personal_details.emp_martial,
        setmartial_status
      );
      findOptionAndSetState(
        RELIGION_OPTIONS,
        personal_details.emp_religion,
        set_religion
      );
      findOptionAndSetState(
        CASTE_OPTIONS,
        personal_details.emp_caste,
        set_caste
      );
      findOptionAndSetState(
        NATIONALITY_OPTIONS,
        personal_details.emp_nationality,
        set_nationality
      );
      findOptionAndSetState(
        BLOOD_GROUP_OPTIONS,
        personal_details.emp_blood_grp,
        setblood_group
      );
      findOptionAndSetState(
        CITY_OPTIONS,
        personal_details.emp_perm_city,
        set_city
      );
      findOptionAndSetState(
        DISTRICT_OPTIONS,
        personal_details.emp_perm_district,
        set_district
      );
      findOptionAndSetState(
        STATE_OPTIONS,
        personal_details.emp_perm_state,
        set_state
      );
      findOptionAndSetState(
        CORR_CITY_OPTIONS,
        personal_details.emp_corr_city,
        setcorrespondance_city
      );
      findOptionAndSetState(
        CORR_DISTRICT,
        personal_details.emp_corr_district,
        setcorrespondance_district
      );
      findOptionAndSetState(
        CORR_STATE,
        personal_details.emp_corr_state,
        setcorrespondance_state
      );
    }
    // eslint-disable-next-line
  }, [empolyeeData.data, empolyeeData.loading, findOptionAndSetState]);

  const sameAsPermanentAddress = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { checked } = e.target as HTMLInputElement;

    if (checked) {
      setFormData({
        ...formData,
        emp_corr_address: formData.emp_perm_address,
        emp_corr_pin: formData.emp_perm_pin,
      });
      setcorrespondance_city(city);
      setcorrespondance_district(district);
      setcorrespondance_state(state);
    } else {
      setFormData({
        ...formData,
        emp_corr_address: EMPTY_STRING,
        emp_corr_pin: EMPTY_STRING,
      });
      setcorrespondance_city(EMPTY_OPTIONSTYPE_OBJECT);
      setcorrespondance_district(EMPTY_OPTIONSTYPE_OBJECT);
      setcorrespondance_state(EMPTY_OPTIONSTYPE_OBJECT);
    }
  };

  const checkSameAdd = () => {
    const checkEquality =
      formData.emp_corr_address === formData.emp_perm_address &&
      formData.emp_corr_pin === formData.emp_perm_pin &&
      city.value === correspondance_city.value &&
      district.value === correspondance_district.value &&
      state.value === correspondance_state.value;

    return checkEquality;
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="employee-details">
        <Stepper alternativeLabel activeStep={step - 1}>
          {stepsHeader.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <EmployeeDetails />
        <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={EmpPersonalDetailsSchema}
          onSubmit={handleUpdatePerosnalDetails}
        >
          {(meta) => {
            return (
              <Form className="employee-details__basic-details--frame">
                <div className="employee-details__basic-details--block">
                  <div className="details">
                    <h4>
                      {HRTitles.Employee_Registration.Personal_Details.map(
                        (title: HRTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </h4>
                  </div>
                  <div className="employee-details__basic-details--block--frame">
                    {HRFormLabels.Employee.PersonalDetails.InputType1.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              values={formData[label.inputName]}
                              LabelName={label.LabelName}
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              type={label.dataType}
                              required={label.required ? true : false}
                              autoFocus={label.autoFocus}
                              maxLength={label.maxLength}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    <div className="label-grid">
                      <Label>Martial Status</Label>
                      <Autocomplete
                        classes={classes}
                        options={MARRIED_UNMARRIED}
                        value={martial_status}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setmartial_status(newValue as optionsType);
                          } else {
                            setmartial_status(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            setmartial_status(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                          if (e.key === Keys.ENTER) {
                            handleMUISelectEvent(e);
                          }
                        }}
                        openOnFocus
                        freeSolo
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>
                    {HRFormLabels.Employee.PersonalDetails.InputType2.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              values={formData[label.inputName]}
                              LabelName={label.LabelName}
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              type={label.dataType}
                              required={label.required ? true : false}
                              autoFocus={label.autoFocus}
                              maxLength={label.maxLength}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    <div className="label-grid">
                      <Label>Religion</Label>
                      <Autocomplete
                        classes={classes}
                        options={RELIGION_OPTIONS}
                        value={religion}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            set_religion(newValue as optionsType);
                          } else {
                            set_religion(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            set_religion(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                          if (e.key === Keys.ENTER) {
                            handleMUISelectEvent(e);
                          }
                        }}
                        openOnFocus
                        freeSolo
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            onChange={(e) => setSearchReligion(e.target.value)}
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>

                    <div className="label-grid">
                      <Label>Caste</Label>
                      <Autocomplete
                        classes={classes}
                        options={CASTE_OPTIONS}
                        value={caste}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            set_caste(newValue as optionsType);
                          } else {
                            set_caste(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            set_caste(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                          if (e.key === Keys.ENTER) {
                            handleMUISelectEvent(e);
                          }
                        }}
                        openOnFocus
                        freeSolo
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            onChange={(e) => setSearchCaste(e.target.value)}
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>

                    <div className="label-grid">
                      <Label>Nationality</Label>
                      <Autocomplete
                        classes={classes}
                        options={NATIONALITY_OPTIONS}
                        value={nationality}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            set_nationality(newValue as optionsType);
                          } else {
                            set_nationality(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            set_nationality(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                          if (e.key === Keys.ENTER) {
                            handleMUISelectEvent(e);
                          }
                        }}
                        openOnFocus
                        freeSolo
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            onChange={(e) =>
                              setSearchNationality(e.target.value)
                            }
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="label-grid">
                      <Label>Blood Group</Label>
                      <Autocomplete
                        classes={classes}
                        options={BLOOD_GROUP_OPTIONS}
                        value={blood_group}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setblood_group(newValue as optionsType);
                          } else {
                            setblood_group(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            setblood_group(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                          if (e.key === Keys.ENTER) {
                            handleMUISelectEvent(e);
                          }
                        }}
                        openOnFocus
                        freeSolo
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            onChange={(e) =>
                              setSearchBloodGroup(e.target.value)
                            }
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>

                    {HRFormLabels.Employee.PersonalDetails.InputType3.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              values={formData[label.inputName]}
                              LabelName={label.LabelName}
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              type={label.dataType}
                              required={label.required ? true : false}
                              autoFocus={label.autoFocus}
                              maxLength={label.maxLength}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    <div className="label-grid">
                      <Label>City</Label>
                      <Autocomplete
                        classes={classes}
                        options={CITY_OPTIONS}
                        value={city}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            set_city(newValue as optionsType);
                          } else {
                            set_city(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            set_city(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                          if (e.key === Keys.ENTER) {
                            handleMUISelectEvent(e);
                          }
                        }}
                        openOnFocus
                        freeSolo
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => setSearchCity(e.target.value)}
                            fullWidth
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>
                    {HRFormLabels.Employee.PersonalDetails.InputType4.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              values={formData[label.inputName]}
                              LabelName={label.LabelName}
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              type={label.dataType}
                              required={label.required ? true : false}
                              autoFocus={label.autoFocus}
                              maxLength={label.maxLength}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    <div className="label-grid">
                      <Label>District</Label>
                      <Autocomplete
                        classes={classes}
                        options={DISTRICT_OPTIONS}
                        value={district}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            set_district(newValue as optionsType);
                          } else {
                            set_district(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            set_district(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                          if (e.key === Keys.ENTER) {
                            handleMUISelectEvent(e);
                          }
                        }}
                        openOnFocus
                        freeSolo
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            onChange={(e) => setSearchDistrict(e.target.value)}
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>

                    <div className="label-grid">
                      <Label>State</Label>
                      <Autocomplete
                        classes={classes}
                        options={STATE_OPTIONS}
                        value={state}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            set_state(newValue as optionsType);
                          } else {
                            set_state(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            set_state(EMPTY_OPTIONSTYPE_OBJECT);
                          }
                          if (e.key === Keys.ENTER) {
                            handleMUISelectEvent(e);
                          }
                        }}
                        openOnFocus
                        freeSolo
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            onChange={(e) => setSearchState(e.target.value)}
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="details">
                    <h4>
                      {HRTitles.Employee_Registration.Personal_Details.map(
                        (title: HRTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {title.Correspondance}
                            </React.Fragment>
                          );
                        }
                      )}
                    </h4>
                  </div>
                  <div className="row g-0 employee-details__correspondance-details">
                    <div className="col">
                      {HRFormLabels.Employee.CorrespondanceAddress.CheckBoxType.map(
                        (label: LabelNameProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              <Checkbox
                                onClick={sameAsPermanentAddress}
                                checked={checkSameAdd()}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    if (checkSameAdd()) {
                                      saveRef.current?.focus();
                                    } else {
                                      handleMUISelectEvent(e);
                                    }
                                  }
                                }}
                              />
                              <Label>{label.LabelName}</Label>
                            </React.Fragment>
                          );
                        }
                      )}
                    </div>
                    <div className="col">
                      {HRFormLabels.Employee.CorrespondanceAddress.InputType1.map(
                        (label: LabelNameProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              <Input
                                values={formData[label.inputName]}
                                LabelName={label.LabelName}
                                name={label.inputName}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleValueChange(e);
                                  meta.handleChange(e);
                                }}
                                disabled={checkSameAdd()}
                                type={label.dataType}
                                required={label.required ? true : false}
                                autoFocus={label.autoFocus}
                                maxLength={label.maxLength}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                      <div className="label-grid">
                        <Label>City</Label>
                        <Autocomplete
                          classes={classes}
                          options={CORR_CITY_OPTIONS}
                          value={correspondance_city}
                          disabled={checkSameAdd()}
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setcorrespondance_city(newValue as optionsType);
                            } else {
                              setcorrespondance_city(EMPTY_OPTIONSTYPE_OBJECT);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === Keys.BACKSPACE) {
                              setcorrespondance_city(EMPTY_OPTIONSTYPE_OBJECT);
                            }
                            if (e.key === Keys.ENTER) {
                              handleMUISelectEvent(e);
                            }
                          }}
                          openOnFocus
                          freeSolo
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              onChange={(e) =>
                                setSearchCorrCity(e.target.value)
                              }
                              classes={{
                                root: textClasses.formControlRoot,
                              }}
                            />
                          )}
                        />
                      </div>

                      {HRFormLabels.Employee.CorrespondanceAddress.InputType2.map(
                        (label: LabelNameProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              <Input
                                values={formData[label.inputName]}
                                LabelName={label.LabelName}
                                name={label.inputName}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleValueChange(e);
                                  meta.handleChange(e);
                                }}
                                disabled={checkSameAdd()}
                                type={label.dataType}
                                required={label.required ? true : false}
                                autoFocus={label.autoFocus}
                                maxLength={label.maxLength}
                              />
                            </React.Fragment>
                          );
                        }
                      )}

                      <div className="label-grid">
                        <Label>District</Label>
                        <Autocomplete
                          classes={classes}
                          options={CORR_DISTRICT}
                          value={correspondance_district}
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setcorrespondance_district(
                                newValue as optionsType
                              );
                            } else {
                              setcorrespondance_district(
                                EMPTY_OPTIONSTYPE_OBJECT
                              );
                            }
                          }}
                          disabled={checkSameAdd()}
                          onKeyDown={(e) => {
                            if (e.key === Keys.BACKSPACE) {
                              setcorrespondance_district(
                                EMPTY_OPTIONSTYPE_OBJECT
                              );
                            }
                            if (e.key === Keys.ENTER) {
                              handleMUISelectEvent(e);
                            }
                          }}
                          openOnFocus
                          freeSolo
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              onChange={(e) =>
                                setSearchCorrDistrict(e.target.value)
                              }
                              classes={{
                                root: textClasses.formControlRoot,
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="label-grid">
                        <Label>State</Label>
                        <Autocomplete
                          classes={classes}
                          options={CORR_STATE}
                          value={correspondance_state}
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setcorrespondance_state(newValue as optionsType);
                            } else {
                              setcorrespondance_state(EMPTY_OPTIONSTYPE_OBJECT);
                            }
                          }}
                          disabled={checkSameAdd()}
                          onKeyDown={(e) => {
                            if (e.key === Keys.BACKSPACE) {
                              setcorrespondance_state(EMPTY_OPTIONSTYPE_OBJECT);
                            }
                            if (e.key === Keys.ENTER) {
                              handleMUISelectEvent(e);
                            }
                          }}
                          openOnFocus
                          freeSolo
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              onChange={(e) =>
                                setSearchCorrState(e.target.value)
                              }
                              classes={{
                                root: textClasses.formControlRoot,
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <Button
                  mode={type === Operation.CREATE ? "save-continue" : "update"}
                  type="submit"
                  buttonref={saveRef}
                />
                <Button mode="clear" type="button" onClick={handleClear} />
                <Button
                  mode="back"
                  type="button"
                  onClick={() => {
                    if (type === Operation.CREATE) {
                      navigate(
                        `/${InstId}/hr/employee/1/${empId}/registration`
                      );
                    } else {
                      navigate(
                        `/${InstId}/hr/employee/${empId}/preview/personaldetails`
                      );
                    }
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>

      <LoadingModal flag={loading} />
    </>
  );
};

export default PersonalDetails;
