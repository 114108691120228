import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";

import { TableHeaderProps } from "../../../../Types/Tables";
import { BankBookTitleProps } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import Collapse from "../../../../images/Collapse.svg";

import Uncollapse from "../../../../images/UnCollapse.svg";
import { useNavigate } from "react-router-dom";
import { EMPTY_STRING } from "../../../../utils/constants";
import useGroupLedgerData from "../../hooks/useGroupLedgerData";
import { AcctGroupLdgrQueryType } from "../../common/QueryTypes";
import useAcctTableJson from "../../json/useAcctTableJson";

const { AccountsTitles } = require("../../json/title.json");

const Index = () => {
  const navigate = useNavigate();
  const { Accounts_Table } = useAcctTableJson();
  const [enableFilterDate, setEnableFilterDate] = useState(false);
  const [enableGroupLedger, setEnableGroupLedger] = useState(false);
  const [enableAccountLedger, setEnableAccountLedger] = useState(false);
  const [collapseFlag, setCollapseFlag] = useState<number[]>([]);
  const [collapseFlag2, setCollapseFlag2] = useState<number[]>([]);
  const {
    groupLedgers: { data },
  } = useGroupLedgerData(
    EMPTY_STRING,
    AcctGroupLdgrQueryType.GROUP_LDGR_BY_INST_ID
  );
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {AccountsTitles.BankBook.TrialBalance.Titles.map(
          (title: BankBookTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>{title.TrialBalance}</React.Fragment>
            );
          }
        )}
      </Title>
      <div className="trial-balance">
        <div className="trial-balance__datefield">
          <div className="trial-balance__datefield--filter-date">
            <Label variant="LabelPrimary">Filter Date</Label>
            <Input
              type="checkbox"
              onChange={() => setEnableFilterDate(!enableFilterDate)}
            />
          </div>

          <TextField
            type="date"
            label="From"
            InputLabelProps={{ shrink: true }}
            defaultValue="2022-07-02"
            disabled={!enableFilterDate}
            className="trial-balance__textfield"
          />
          <TextField
            type="date"
            label="To"
            InputLabelProps={{ shrink: true }}
            defaultValue="2022-07-30"
            disabled={!enableFilterDate}
            className="trial-balance__textfield"
          />
        </div>

        <div className="trial-balance__tableblock">
          <TableContainer className="trial-balance__tableblock--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.Reports.TrialBalance.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.GetAcctGroupLdgrs.edges.map((edge, index) => {
                  return (
                    <>
                      <TableRow
                        key={index}
                        className="trial-balance__tableblock--table--first-row"
                      >
                        <TableCell>
                          {collapseFlag.includes(index) ? (
                            <img
                              src={Collapse}
                              alt="/"
                              onClick={() => {
                                setEnableGroupLedger(!enableGroupLedger);

                                if (!collapseFlag.includes(index)) {
                                  setCollapseFlag((p) => [...p, index]);
                                } else {
                                  collapseFlag.splice(
                                    collapseFlag.indexOf(index),
                                    1
                                  );
                                }
                              }}
                            />
                          ) : (
                            <img
                              src={Uncollapse}
                              alt="/"
                              onClick={() => {
                                if (!collapseFlag.includes(index)) {
                                  setCollapseFlag((p) => [...p, index]);
                                } else {
                                  collapseFlag.splice(
                                    collapseFlag.indexOf(index),
                                    1
                                  );
                                }
                              }}
                            />
                          )}
                          &nbsp;{edge.node.gr_ldgr_desc}
                        </TableCell>
                        <TableCell
                          className="trial-balance__tableblock--table--amount"
                          id="td-right"
                        >
                          {edge.node.gr_ldgr_ob}
                        </TableCell>
                        <TableCell
                          className="trial-balance__tableblock--table--amount"
                          id="td-right"
                        >
                          {edge.node.gr_ldgr_total_db}
                        </TableCell>
                        <TableCell
                          className="trial-balance__tableblock--table--amount"
                          id="td-right"
                        >
                          {edge.node.gr_ldgr_total_cr}
                        </TableCell>
                        <TableCell
                          className="trial-balance__tableblock--table--amount"
                          id="td-right"
                        >
                          {edge.node.gr_ldgr_cb}
                        </TableCell>
                        <TableCell
                          className="trial-balance__tableblock--table--status"
                          id="td-center"
                        >
                          Db/Cr
                        </TableCell>
                      </TableRow>
                      {collapseFlag.includes(index) && (
                        <>
                          {edge.node.acct_ldgr?.map((acct_ldgr, index) => {
                            return (
                              <>
                                <TableRow
                                  key={index}
                                  className="trial-balance__tableblock--table--first-level-row"
                                >
                                  <TableCell className="trial-balance__tableblock--table--first-level-row--name">
                                    {collapseFlag2.includes(index) ? (
                                      <>
                                        <img
                                          src={Collapse}
                                          alt="/"
                                          onClick={() => {
                                            setEnableAccountLedger(
                                              !enableAccountLedger
                                            );

                                            if (
                                              !collapseFlag2.includes(index)
                                            ) {
                                              setCollapseFlag((p) => [
                                                ...p,
                                                index,
                                              ]);
                                            } else {
                                              collapseFlag2.splice(
                                                collapseFlag2.indexOf(index),
                                                1
                                              );
                                            }
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <img
                                        src={Uncollapse}
                                        alt="/"
                                        onClick={() => {
                                          setEnableAccountLedger(
                                            !enableAccountLedger
                                          );
                                          if (!collapseFlag2.includes(index)) {
                                            setCollapseFlag2((q) => [
                                              ...q,
                                              index,
                                            ]);
                                          } else {
                                            collapseFlag2.splice(
                                              collapseFlag2.indexOf(index),
                                              1
                                            );
                                          }
                                        }}
                                      />
                                    )}
                                    &nbsp; {acct_ldgr.ldgr_desc}
                                  </TableCell>
                                  <TableCell
                                    className="trial-balance__tableblock--table--amount"
                                    id="td-right"
                                  >
                                    {acct_ldgr.ldgr_ob}
                                  </TableCell>
                                  <TableCell
                                    className="trial-balance__tableblock--table--amount"
                                    id="td-right"
                                  >
                                    {acct_ldgr.ldgr_total_db}
                                  </TableCell>
                                  <TableCell
                                    className="trial-balance__tableblock--table--amount"
                                    id="td-right"
                                  >
                                    {acct_ldgr.ldgr_total_cr}
                                  </TableCell>
                                  <TableCell
                                    className="trial-balance__tableblock--table--amount"
                                    id="td-right"
                                  >
                                    {acct_ldgr.ldgr_cb}
                                  </TableCell>
                                </TableRow>
                                {/* {collapseFlag2.includes(index) && (
                                    <>
                                      {grpledgerdata.accountLedgerData.map(
                                        (accountledgerdata, index) => {
                                          return (
                                            <TableRow
                                              key={index}
                                              className="trial-balance__tableblock--table--second-level-row"
                                            >
                                              <TableCell className="trial-balance__tableblock--table--second-level-row--name">
                                                {accountledgerdata.title}
                                              </TableCell>
                                              <TableCell
                                                className="trial-balance__tableblock--table--amount"
                                                id="td-right"
                                              >
                                                {accountledgerdata.ob}
                                              </TableCell>
                                              <TableCell
                                                className="trial-balance__tableblock--table--amount"
                                                id="td-right"
                                              >
                                                {accountledgerdata.db}
                                              </TableCell>
                                              <TableCell
                                                className="trial-balance__tableblock--table--amount"
                                                id="td-right"
                                              >
                                                {accountledgerdata.cr}
                                              </TableCell>
                                              <TableCell
                                                className="trial-balance__tableblock--table--amount"
                                                id="td-right"
                                              >
                                                {accountledgerdata.cb}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        }
                                      )}
                                    </>
                                  )} */}
                              </>
                            );
                          })}
                        </>
                      )}
                    </>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell className="total">Closing Balance</TableCell>
                  <TableCell className="totalcount">0.00</TableCell>
                  <TableCell className="totalcount">0.00</TableCell>
                  <TableCell className="totalcount">0.00</TableCell>
                  <TableCell className="totalcount">0.00</TableCell>
                  <TableCell className="totalcount"></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="button-left">
        <Button mode="print" />
        <Button mode="excel">Export to Excel</Button>
        <Button mode="show-summarized" />
        <Button mode="clear" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Index;
