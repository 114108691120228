import React, { useEffect, useState } from "react";
import useAcademicTableJson from "../../../json/useAcademicTable";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../../styles/DataGridTableStyles";
import { TableHeaders } from "../../../../../utils/Enum.types";
import { useNavigate, useParams } from "react-router-dom";
import { TableHeaderProps } from "../../../../../utils/types";
import View from "../../../../../images/EyeWhite.svg";
import { Button } from "../../../../../stories/Button/Button";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../../styles/AutocompleteListStyles";
import Input from "../../../../../stories/Input/Input";
import Home from "../../../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import List from "../List";
import useEmployee, {
  empQueryTypes,
  PayRollEmpEdges,
} from "../../../../HR/hooks/useEmployee";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../../utils/constants";
import { getModifiedScrollHeight } from "../../../../../utils/UtilFunctions";

const TeacherList = () => {
  const { FeedBackTeacherWise } = useAcademicTableJson();
  const navigate = useNavigate();
  const dataClasses = useDataGridStyles();
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const [searchData, setSearchData] = useState("");
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const { InstId, formId } = useParams();

  const {
    empDetails: { data, loading, fetchMore },
  } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.ALL_TEACHERS_BY_FEEDBACK_FORM_ID,
    searchData,
    0,
    0,
    0,
    0,
    0,
    0
  );

  const dynamicHeaders: TableHeaderProps[] =
    FeedBackTeacherWise.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));
  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "actions",
      cellClassName: "td-status ",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        const empId = params.row.user_id;
        return (
          <>
            <button
              className="teacher-wise-allocated-reports__view"
              onClick={() =>
                navigate(
                  `/${InstId}/academics/reports/teacherwise/feedback/${formId}/${empId}/view`
                )
              }
            >
              View <img src={View} alt="" />
            </button>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetPayRollEmp.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = employees.find(
            (employee) => employee.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setEmployees(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            name:
              node.emp_first_name + node.emp_middle_name + node.emp_last_name,
            mobile: node.emp_mobile,
            email: node.emp_email,
            user_type: node.emp_type,
            user_id: node.id,
            designation: node.pr_designation_details.designation_desc,
            emp_id: node.emp_id,
          }))
        );
      } else {
        setEmployees(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            name:
              node.emp_first_name + node.emp_middle_name + node.emp_last_name,
            mobile: node.emp_mobile,
            email: node.emp_email,
            user_type: node.emp_type,
            user_id: node.id,
            designation: node.pr_designation_details.designation_desc,
            emp_id: node.emp_id,
          }))
        );
      }
      setEndCursor(data.GetPayRollEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetPayRollEmp.edges;
              const pageInfo = fetchMoreResult.GetPayRollEmp.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetPayRollEmp.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetPayRollEmp: {
                  edges: [...employees, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetPayRollEmp.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Forms Report</Title>
      <div className="fb-form-report">
        <div className="fb-form-report__left">
          <List />
        </div>
        <div className="fb-form-report__right">
          <div className="fb-teacher-wise-report">
            <div className="fb-teacher-wise-report__select row g-0">
              <div className="col-3">
                <Input
                  id="search"
                  placeholder="Search..."
                  onChange={(e) => setSearchData(e.target.value)}
                />
              </div>
            </div>
            <div
              className={`fb-teacher-wise-report__tableblock ${dataClasses.root}`}
            >
              <DataGridPro
                columns={columns}
                rows={rows}
                rowHeight={TABLE_ROW_HEIGHT}
                disableRowSelectionOnClick
                hideFooter
                slotProps={{
                  columnsPanel: { disableHideAllButton: true },
                }}
              />
            </div>
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherList;
