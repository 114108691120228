import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Eduate from "../../../../images/Eduate_Logo_image.png";

import { Title } from "../../../../stories/Title/Title";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import {
  Direction,
  ExcelAlignment,
  ExcelFont,
  ExcelPageHeader,
  FileUploadParams,
  SortBy,
  StudentReportType,
  TableHeaders,
} from "../../../../utils/Enum.types";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  toStandardCase,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import Home from "../../Home/Index";
import Input from "../../../../stories/Input/Input";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  F4_CELL,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import { studentReservationHeaders } from "../../../Accounts/common/HeaderConsts";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { isOptionEqualToValue } from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import { Label } from "../../../../stories/Label/Label";
import { response, responseType } from "../../../../utils/Form.types";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { AppContext } from "../../../../context/context";
import { FetchStudentStatusClassName } from "../List";
import useAcdStudentsForAdmission, {
  StudentEdges,
} from "../../../Academics/hooks/useAcdStudentsForAdmission";
import useAcdDropDownData from "../../../Academics/hooks/useAcdDropDownData";

const StdCompleteReports = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const navigate = useNavigate();
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { state } = useContext(AppContext);

  const [searchData, setSearchData] = useState("");

  const { InstDetails } = useInstDetails(1);

  const [filters, setFilters] = useState<response[]>([
    { label: "ByAdmNo", value: SortBy.STD_ADM_NO, isChecked: false },
    { label: "ByFirstName", value: SortBy.FIRST_NAME, isChecked: false },
    { label: "ByRegNo", value: SortBy.STD_REG_NO, isChecked: false },
    { label: "BySections", value: SortBy.SECTION_ID, isChecked: false },
  ]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );

  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );

  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [selectedDirection, setSelectedDirection] = useState<Direction>(
    Direction.ASC
  );
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInputRef = branchRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const semRef = useRef<HTMLSelectElement>(null);
  const semInputRef = semRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,

    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    0,
    categorySelected ? categorySelected.value : 0,
    ROWS_PER_PAGE,
    0,
    searchData,
    StudentReportType.ACD_STD_GENERAL,
    EMPTY_STRING,
    [],
    filters
      .filter((filter) => filter.isChecked)
      .map((filter) => ({
        direction: selectedDirection,
        field: filter.value.toString(),
      }))
  );

  const stdData = AcademicsStudentData.data
    ? AcademicsStudentData.data.GetAcdStudents.edges.map((data, index) => ({
        SlNo: index + 1,
        AdmNo: data.node.std_adm_no,
        Name:
          data.node.first_name +
          " " +
          data.node.middle_name +
          " " +
          data.node.last_name,
        Email: data.node.std_email,
        Mobile: data.node.std_mobile,
        Father: data.node.std_father_name,
        Status: data.node.std_status,
      }))
    : [];
  const handleDirectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedDirection(event.target.value as Direction);
  };

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const { categoryDropDown } = useInstMasterDataByInstId();

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !AcademicsStudentData.loading) {
        AcademicsStudentData.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );
            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount:
                  AcademicsStudentData.data?.GetAcdStudents.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Reserved Students");

    const dataRows = stdData
      ? stdData?.map((item) => [
          item.SlNo,
          item.AdmNo,
          item.Name,
          item.Email,
          item.Mobile,
          item.Father,
          item.Status,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 25;
    worksheet.getColumn(7).width = 20;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "G1:G3");
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");
                  worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:G2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:G3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "Reserved Students";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:E4");
                  const mergedDate: Cell = worksheet.getCell(F4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("F4:G4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < studentReservationHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = studentReservationHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                      cell.font = { name: ExcelFont.COURIER_NEW, size: 9 };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(
                      DOWNLOAD,
                      InstDetails.data?.nodes[0]?.inst_name!
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(ROWS_PER_PAGE);
                });
              });
          });
        });
      });
  };

  const handleSelectionOfUnmappedClass = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;

    let tempClass = filters.map((user) =>
      user.value === name ? { ...user, isChecked: checked } : user
    );
    setFilters(tempClass);
  };

  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !AcademicsStudentData.loading)
      downloadExcel();
    // eslint-disable-next-line
  }, [excelFlag, rowsPerPage, AcademicsStudentData.loading]);
  useEffect(() => {
    if (
      AcademicsStudentData.data &&
      !AcademicsStudentData.loading &&
      excelFlag === false
    ) {
      const newData = AcademicsStudentData.data
        ? AcademicsStudentData?.data.GetAcdStudents.edges.map((edge) => ({
            ...edge,
            node: {
              ...edge.node,
              isChecked: true,
            },
          }))
        : [];

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(
        AcademicsStudentData?.data?.GetAcdStudents.pageInfo.endCursor!
      );
    } // eslint-disable-next-line
  }, [AcademicsStudentData.data, AcademicsStudentData.loading, excelFlag]);

  const { branchLabel, classLabel, departmentLabel, semesterLabel,categoryLabel } =
    useInstLabels();
  return (
    <>
      <Home DashBoardRequired={false} />

      <div className="row g-0">
        <div className="col">
          <Title>Students Complete Reports Export</Title>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-1 studentlist__select-flex studentlist__search">
          <Input
            id="search"
            type="text"
            placeholder="Search "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchData(e.target.value);
            }}
            value={searchData}
          />
        </div>
        {USE_DEPARTMENT_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={departmentDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, departmentSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (departmentSelected) {
                    branchInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setDepartmentSelected(null);
                }
              }}
              openOnFocus
              value={departmentSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setDepartmentSelected(newValue);
                  setHasNextPage(true);
                } else {
                  setDepartmentSelected(null);
                }
                setBranchSelected(null);
                setClassSelected(null);
                setSemesterSelected(null);
                setCategorySelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={departmentLabel}
                  InputLabelProps={{ shrink: true }}
                  id="outlined Departments"
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
        {USE_BRANCH_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={branchDropDown}
              ref={branchRef!}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, departmentSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (branchSelected) {
                    classInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(null);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue);
                } else {
                  setBranchSelected(null);
                }
                setClassSelected(null);
                setSemesterSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={branchLabel}
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
        {USE_CLASS_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={classDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, classSelected)
              }
              ref={classRef!}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (classSelected) {
                    semInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassSelected(null);
                }
              }}
              openOnFocus
              value={classSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassSelected(newValue);
                } else {
                  setClassSelected(null);
                }
                setSemesterSelected(null);
                setCategorySelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={classLabel}
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                  fullWidth
                />
              )}
            />
          </div>
        ) : null}
        {USE_SEMESTER_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={semesterDropDown}
              ref={semRef!}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, semesterSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (semesterSelected) {
                    categoryInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSemesterSelected(null);
                }
              }}
              openOnFocus
              value={semesterSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSemesterSelected(newValue);
                } else {
                  setSemesterSelected(null);
                }
                setCategorySelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={semesterLabel}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}

        {USE_CATEGORY_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={categoryDropDown}
              ref={categoryRef!}
              openOnFocus
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, categorySelected)
              }
              value={categorySelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setCategorySelected(newValue);
                } else {
                  setCategorySelected(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setCategorySelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={categoryLabel}
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                  fullWidth
                />
              )}
            />
          </div>
        ) : null}
        <div className="col">
          {filters.map((d) => {
            return (
              <>
                <Label>{d.label}</Label>
                <input
                  checked={d.isChecked}
                  type="checkbox"
                  name={d.value.toString()}
                  onChange={handleSelectionOfUnmappedClass}
                />
              </>
            );
          })}
          {Object.values(Direction).map((direction) => {
            return (
              <label key={direction}>
                <input
                  type="radio"
                  value={direction}
                  checked={selectedDirection === direction}
                  onChange={handleDirectionChange}
                />
                {toStandardCase(direction)}
              </label>
            );
          })}
        </div>
        <div className="col-1"></div>
      </div>

      <div className="std-reservation">
        {!students?.length ? (
          <b className="nodata"> No students Found</b>
        ) : (
          <TableContainer
            className="studentlist__table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    id="td-center"
                    className="studentlist__table--th-slno"
                  >
                    {TableHeaders.SLNO}
                  </TableCell>
                  <TableCell className="studentlist__table--th-admno">
                    {TableHeaders.ADMISSION_NUMBER}
                  </TableCell>
                  <TableCell>{TableHeaders.NAME}</TableCell>
                  <TableCell className="studentlist__table--th-admno">
                    {TableHeaders.EMAIL}
                  </TableCell>
                  <TableCell className="studentlist__table--th-admno">
                    {TableHeaders.MOBILE}
                  </TableCell>
                  <TableCell className="studentlist__table--th-admno">
                    {TableHeaders.FATHER_NAME}
                  </TableCell>
                  <TableCell className="studentlist__table--status">
                    {TableHeaders.STATUS}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students?.map((res, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className="studentlist__table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="studentlist__table--admno">
                        {res.node.std_adm_no}
                      </TableCell>
                      <TableCell>
                        {res.node.first_name +
                          " " +
                          res.node.middle_name +
                          " " +
                          res.node.last_name}
                      </TableCell>
                      <TableCell>{res.node.std_email}</TableCell>
                      <TableCell>{res.node.std_mobile}</TableCell>
                      <TableCell>{res.node.std_father_name}</TableCell>

                      <TableCell
                        className={FetchStudentStatusClassName(
                          res.node.std_status
                        )}
                        id="td-center"
                      >
                        {res.node.std_status}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <div className="studentlist__buttons">
        <div className="button-left">
          <Button
            onClick={() => {
              setRowsPerPage(null);
              setExcelFlag(true);
            }}
            mode="export"
          />

          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
    </>
  );
};
export default StdCompleteReports;
