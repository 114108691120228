import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../utils/constants";
import {
  Direction,
  Operation,
  SortBy,
  TableHeaders,
} from "../../../utils/Enum.types";
import Home from "../Home/Index";
import Input from "../../../stories/Input/Input";
import { msgType } from "../../../utils/Form.types";
import { handleFormEvent } from "../../../utils/UtilFunctions";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import { GetStudents } from "../../../queries/students/list/newApi";
import { studentQueryTypes } from "../../../utils/studentqueryEnum.types";

import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import {
  DataGridPro,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../styles/DataGridTableStyles";
import useAcdInstSessions, {
  GetAcdInstInactiveSessionsData,
  GetAcdInstSessionsList,
} from "../hooks/useAcdInstSessions";
import { UpdateAcdInstSessionActiveStatus } from "../queries/test/mutation";
import { GetAcdInstInactiveSessions } from "../queries/sessions/list";
import { InstIdVars } from "../../HR/Types/masterDataTypes";
enum SessionType {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}
const ActiveSessions = () => {
  
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { InstId } = useParams();
  const { token } = useToken();
  const [searchData, setSearchData] = useState("");
  const [studentDeleteModal, setStudentDeleteModal] = useState(false);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const dataClasses = useDataGridStyles();
  const [sessionType, setSessionType] = useState(SessionType.ACTIVE);
  const [students, setStudents] = useState<GetAcdInstSessionsList[]>([]);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const {
    SessionsQueryResult: { data, loading },
  } = useAcdInstSessions();
  const [GetSessions, { data: InactiveData, loading: InactiveLoading }] =
    useLazyQuery<GetAcdInstInactiveSessionsData, InstIdVars>(
      GetAcdInstInactiveSessions,
      {
        variables: {
          token,
          inst_id: InstId!,
        },
      }
    );

  useEffect(() => {
    if (token) {
      GetSessions();
    }
  }, [token, GetSessions, data]);

  const { user_details } = useLoggedInUserDetails();

  const [UpdateSessionStatus, { loading: updateLoading }] = useMutation(
    UpdateAcdInstSessionActiveStatus,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleCheckboxChange = (id: number) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSubmit = () => {
    UpdateSessionStatus({
      variables: {
        token,
        session_ids: selectedRows,
        inst_id: InstId,
        user_details,
        active_status: sessionType === SessionType.ACTIVE ? false : true,
      },
      refetchQueries: [
        {
          query: GetStudents,
          variables: {
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            input: {
              ids: [Number(InstId)],
              std_query_type: studentQueryTypes.BY_INST_ID,
              str_data: [],
            },
            name: EMPTY_STRING,
            orderBy: [
              {
                direction: Direction.ASC,
                field: SortBy.FIRST_NAME,
              },
            ],
            status: EMPTY_STRING,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Session Updated Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (message.operation !== Operation.NONE && message.flag) {
      setStudentDeleteModal(!studentDeleteModal);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const columns: GridColDef[] = [
    {
      field: "actions",
      cellClassName: "td-actions-print ",
      headerClassName: "td-actions-print-header",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        const sessionId = params.row.session_id || "";

        return (
          <>
            <Checkbox
              name={`select_${params.row.id}`}
              className="select-all--checkbox"
              checked={selectedRows.includes(sessionId)}
              onChange={() => handleCheckboxChange(sessionId)}
            />
          </>
        );
      },
    },
    {
      field: "session_desc",
      headerName: "Session Description",
      headerAlign: HEADER_TEXT_ALIGN,
      flex:1
    },
    {
      field: "start_time",
      headerName: "Start Time",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "end_time",
      headerName: "End Time",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
    },
  ];

  useEffect(() => {
    if (data && !loading && sessionType === SessionType.ACTIVE) {
      const newData = data.GetAcdInstSessions;

      setStudents(newData);
      setRows(
        newData.map((data, index) => ({
          id: index + 1,
          session_desc: data.session_desc,
          start_time: data.start_time,
          end_time: data.end_time,
          session_id: data.id,
        }))
      );
    } // eslint-disable-next-line
  }, [data, loading, sessionType]);
  useEffect(() => {
    if (
      InactiveData &&
      !InactiveLoading &&
      sessionType === SessionType.INACTIVE
    ) {
      const newData = InactiveData.GetAcdInstInactiveSessions;

      setStudents(newData);
      setRows(
        newData.map((data, index) => ({
          id: index + 1,
          session_desc: data.session_desc,
          start_time: data.start_time,
          end_time: data.end_time,
          session_id: data.id,
        }))
      );
    } // eslint-disable-next-line
  }, [data, loading, sessionType]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {sessionType === SessionType.ACTIVE
          ? "Sessions Active To In-Active"
          : "Sessions In-Active To Active"}
      </Title>
      <div className="active-session">
        <form className="active-session__select row g-0">
        <div className="col-2 ">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchData(e.target.value)
              }
              onKeyDown={handleFormEvent}
            />
          </div>

          <div className="col-4">
            <RadioGroup
              row
              name="radio-buttons-group"
              value={sessionType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSessionType(e.target.value as SessionType);
              }}
            >
              <FormControlLabel
                className="userneeds__text"
                value={SessionType.ACTIVE}
                control={<Radio />}
                label="Active To In-Active"
              />
              <FormControlLabel
                className="userneeds__text"
                value={SessionType.INACTIVE}
                control={<Radio />}
                label="In-Active To Active"
              />
            </RadioGroup>
          </div>
        </form>
        <div className={`active-session__tableblock ${dataClasses.root}`}>
        <DataGridPro
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            disableRowSelectionOnClick
            hideFooter
            slotProps={{
              columnsPanel: { disableHideAllButton: true },
            }}
          />
        </div>

        <div className="row g-0">
          <div className="col">
            <Button mode="save" onClick={handleSubmit} />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 delete-student__total"></div>
        </div>
      </div>
     
      <LoadingModal flag={updateLoading || InactiveLoading || loading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};
export default ActiveSessions;
