import React, { useEffect, useState } from "react";
import {
  AcdStudentDetails,
  IdTemplateProps,
  IdTemplates,
  IdTemplatesSides,
} from "../ThemeBased/Template1";
import Avatar from "../../../../../../images/Avatar.svg";
import { Title } from "../../../../../../stories/Title/Title";
import { Label } from "../../../../../../stories/Label/Label";
import useIDCardSwConfig from "../../../hooks/Index";
import { useLazyQuery } from "@apollo/client";
import {
  SoftwareConfigList,
  SoftwareVars,
} from "../../../../../../Types/configtypes";
import Configurations from "../../../../../Configurations/PerModuleConfiguration";

import { GetSwConfigReferenceDataByKey } from "../../../../../../queries/institution/configuration/query/SoftwreConfig";
import MessageModal from "../../../../../../pages/MessageModal";
import useUpdateIdCardTemplate from "../../../hooks/useUpdateIdCardTemplate";
import useSwIdCardTemplates from "../../../hooks/useSwIdCardTemplates";
import useToken from "../../../../../../customhooks/useToken";
import {
  FileUploadParams,
  InstConfigKey,
  ModuleName,
  PageFor,
} from "../../../../../../utils/Enum.types";
import { OptionList } from "../../../../../UserRights/AssignRights/ChatConfiguration";
import { SwConfigQueryType } from "../../../../../HR/enums/Enum.types";
import Close from "../../../../../../images/Close.svg";
import { Tooltip } from "@mui/material";
import { useStyles } from "../../../../../../styles/TooltipStyles";
import useInstDetails from "../../../../../../customhooks/general/useInstDetails";
import useInstLogoDetails from "../../../../../../customhooks/useInstLogoDetails";
import { EMPTY_STRING } from "../../../../../../utils/constants";
export interface colorTypes {
  bgColor: string;
  headerColor: string;
  footerColor: string;
  textColor: string;
}

const Template3 = ({
  templateFor,
  templateName,
  setModal,
  pageType,
  selectedSideForPrint,
  selectedStudents,
  setStudentIdSet,
}: IdTemplateProps) => {
  const [backgroundColor, setBackgroundColor] = useState<OptionList[]>([]);
  const [headerColor, setHeaderColor] = useState<OptionList[]>([]);
  const [textColor, setTextColor] = useState<OptionList[]>([]);
  const [footerColor, setFooterColor] = useState<OptionList[]>([]);
  const { token } = useToken();
  const {
    customerName,
    instName,
    instLogo,
    fatherName,
    admNo,
    className,
    sectionName,
    instAddress,
    studentAddress,
    mobileNo,
    marginLeft,
    marginTop,
    marginRight,
    footerText,
    EnableFooterText,
  } = useIDCardSwConfig();
  const {
    handleUpdate,
    message,
    handleClose,
    formData,
    handleInputChange,
    handleClear,
  } = useUpdateIdCardTemplate(templateName);
  const tooltipClasses = useStyles();

  const { swIdCardDetails } = useSwIdCardTemplates();
  const [GetConfigData] = useLazyQuery<SoftwareConfigList, SoftwareVars>(
    GetSwConfigReferenceDataByKey
  );
  const [GetBgConfigKey] = useLazyQuery<SoftwareConfigList, SoftwareVars>(
    GetSwConfigReferenceDataByKey
  );
  const [GetHeaderConfigKey] = useLazyQuery<SoftwareConfigList, SoftwareVars>(
    GetSwConfigReferenceDataByKey
  );
  const [GetFooterConfigKey] = useLazyQuery<SoftwareConfigList, SoftwareVars>(
    GetSwConfigReferenceDataByKey
  );
  const [GetTextConfigKey] = useLazyQuery<SoftwareConfigList, SoftwareVars>(
    GetSwConfigReferenceDataByKey
  );
  useEffect(() => {
    if (token) {
      GetConfigData({
        variables: { config_key: InstConfigKey.ID_CARD_LIGHT_COLORS, token },
      });
      GetBgConfigKey({
        variables: { config_key: InstConfigKey.ID_CARD_LIGHT_COLORS, token },
      }).then(({ data }) => {
        if (data?.GetSwConfigReferenceDataByKey) {
          const existingOptions =
            data.GetSwConfigReferenceDataByKey.list_item_list
              .split(";")
              ?.map((d) => ({
                label: d?.split(":")[0],
                value: d?.split(":")[1],
              })) || [];
          const newOption = {
            label: "Others",
            value: "OTHERS",
          };
          const updatedOptions = [...existingOptions, newOption];
          setBackgroundColor(updatedOptions);
        }
      });
      GetHeaderConfigKey({
        variables: { config_key: InstConfigKey.ID_CARD_LIGHT_COLORS, token },
      }).then(({ data }) => {
        if (data?.GetSwConfigReferenceDataByKey) {
          const existingOptions =
            data.GetSwConfigReferenceDataByKey.list_item_list
              .split(";")
              ?.map((d) => ({
                label: d?.split(":")[0],
                value: d?.split(":")[1],
              })) || [];
          const newOption = {
            label: "Others",
            value: "OTHERS",
          };
          const updatedOptions = [...existingOptions, newOption];
          setHeaderColor(updatedOptions);
        }
      });
      GetFooterConfigKey({
        variables: { config_key: InstConfigKey.ID_CARD_LIGHT_COLORS, token },
      }).then(({ data }) => {
        if (data?.GetSwConfigReferenceDataByKey) {
          const existingOptions =
            data.GetSwConfigReferenceDataByKey.list_item_list
              .split(";")
              ?.map((d) => ({
                label: d?.split(":")[0],
                value: d?.split(":")[1],
              })) || [];
          const newOption = {
            label: "Others",
            value: "OTHERS",
          };
          const updatedOptions = [...existingOptions, newOption];
          setFooterColor(updatedOptions);
        }
      });
      GetTextConfigKey({
        variables: {
          config_key: InstConfigKey.ID_CARD_DARK_TEXT_COLORS,
          token,
        },
      }).then(({ data }) => {
        if (data?.GetSwConfigReferenceDataByKey) {
          const existingOptions =
            data.GetSwConfigReferenceDataByKey.list_item_list
              .split(";")
              ?.map((d) => ({
                label: d?.split(":")[0],
                value: d?.split(":")[1],
              })) || [];
          const newOption = {
            label: "Others",
            value: "OTHERS",
          };
          const updatedOptions = [...existingOptions, newOption];
          setTextColor(updatedOptions);
        }
      });
    }
  }, [
    token,
    GetConfigData,
    GetBgConfigKey,
    GetHeaderConfigKey,
    GetFooterConfigKey,
    GetTextConfigKey,
  ]);
  const { InstFormData } = useInstDetails(1);
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { LogoOrSign: PrincipalSignature } = useInstLogoDetails({
    filetype: FileUploadParams.PRINCIPAL_SIGNATURE,
  });

  const [studentData, setStudentData] = useState<AcdStudentDetails[]>([]);

  useEffect(() => {
    if (token && selectedStudents) {
      setStudentData(
        selectedStudents.map((std) => ({
          std_adm_no: std.node.std_adm_no,
          first_name: std.node.first_name,
          middle_name: std.node.middle_name,
          last_name: std.node.last_name,
          std_father_name: std.node.std_father_name,
          std_mobile: std.node.std_mobile,
          std_addr: std.node.std_details.std_address,
          std_dob: std.node.std_dob,
          acd_class: std.node.acd_class.class_desc,
          acd_section: std.node.acd_section.section_desc,
          url: std.node.url ? std.node.url : Avatar,
        }))
      );
    } else {
      setStudentData([
        {
          std_adm_no: "XXXXXX",
          first_name: "XXXXXX",
          middle_name: "XXXXXX",
          last_name: "XXXXXX",
          std_father_name: "XXXXXX",
          std_mobile: "XXXXXX",
          std_addr: "XXXXXX",
          std_dob: "XXXXXX",
          acd_class: "XXXXXX",
          acd_section: "XXXXXX",
          url: Avatar,
        },
      ]);
    }
  }, [token, selectedStudents]);
  return (
    <>
      <div
        className={
          templateFor === IdTemplates.DEMO ? "id-card-template__md" : "h-100"
        }
      >
        {studentData.map((res) => (
          <div className="id-template-2">
            <div className="id-template-2__card">
              {selectedSideForPrint === IdTemplatesSides.FRONT ||
              selectedSideForPrint === IdTemplatesSides.BOTH ? (
                <div
                  className={`id-template-2__card--fs${
                    templateFor === IdTemplates.DEMO ? "" : "--li"
                  }`}
                >
                  {pageType === PageFor.PRINT ? null : (
                    <Title variant="subtitle1">
                      {templateFor === IdTemplates.LIST
                        ? swIdCardDetails?.GetSwIdCardTemplates[2].id_tpl_desc
                        : "Page 1"}
                    </Title>
                  )}
                  <div
                    className="id-template-2__card--fs--id"
                    style={{
                      backgroundColor:
                        templateFor === IdTemplates.LIST
                          ? swIdCardDetails &&
                            swIdCardDetails.GetSwIdCardTemplates[2].bg_color
                          : formData.bg_color,
                    }}
                  >
                    <div className="id-template-2__card--fs--id--profile">
                      <div
                        className="id-template-2__card--fs--id--profile--header"
                        style={{
                          background:
                            templateFor === IdTemplates.LIST
                              ? swIdCardDetails &&
                                swIdCardDetails.GetSwIdCardTemplates[2]
                                  .header_color
                              : formData.header_color,
                          paddingTop: `${marginTop}px`,
                          paddingLeft: `${marginLeft}px`,
                          paddingRight: `${marginRight}px`,
                        }}
                      >
                        <div className="id-template-1__card--fs--id--inst-details">
                          {instLogo ? (
                            <div className="id-template-2__card--fs--id--inst-logo">
                              {LogoOrSign.defaultLogo ? (
                                <img src={LogoOrSign.defaultLogo} alt="/" />
                              ) : null}
                            </div>
                          ) : null}
                          <div className="id-template-1__card--fs--id--name">
                            {customerName ? (
                              <span className="id-template-2__card--fs--id--c-name">
                                {InstFormData
                                  ? InstFormData.cust_name
                                  : EMPTY_STRING}
                              </span>
                            ) : null}
                            {instName ? (
                              <b className="id-template-2__card--fs--id--inst-name">
                                {InstFormData
                                  ? InstFormData.inst_name
                                  : EMPTY_STRING}
                              </b>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="id-template-2__card--fs--id--std-image">
                        <img src={Avatar} alt="" />
                      </div>
                      <div
                        className="id-template-2__card--fs--id--grid"
                        style={{
                          paddingLeft: `${marginLeft}px`,
                          paddingRight: `${marginRight}px`,
                        }}
                      >
                        {admNo ? (
                          <>
                            <label>Adm No.</label>
                            <span>:</span>
                            <b
                              style={{
                                color:
                                  templateFor === IdTemplates.LIST
                                    ? swIdCardDetails &&
                                      swIdCardDetails.GetSwIdCardTemplates[2]
                                        .text_color
                                    : formData.text_color,
                              }}
                            >
                              {res.std_adm_no}
                            </b>
                          </>
                        ) : null}
                        <label>Name</label>
                        <span>:</span>
                        <b
                          style={{
                            color:
                              templateFor === IdTemplates.LIST
                                ? swIdCardDetails &&
                                  swIdCardDetails.GetSwIdCardTemplates[2]
                                    .text_color
                                : formData.text_color,
                          }}
                        >
                          {res.first_name + res.middle_name + res.last_name}
                        </b>
                        {className ? (
                          <>
                            <label>Class</label>
                            <span>:</span>
                            <b
                              style={{
                                color:
                                  templateFor === IdTemplates.LIST
                                    ? swIdCardDetails &&
                                      swIdCardDetails.GetSwIdCardTemplates[2]
                                        .text_color
                                    : formData.text_color,
                              }}
                            >
                              {res.acd_class}
                            </b>
                          </>
                        ) : null}

                        {sectionName ? (
                          <>
                            <label>Section</label>
                            <span>:</span>
                            <b
                              style={{
                                color:
                                  templateFor === IdTemplates.LIST
                                    ? swIdCardDetails &&
                                      swIdCardDetails.GetSwIdCardTemplates[2]
                                        .text_color
                                    : formData.text_color,
                              }}
                            >
                              {res.acd_section}
                            </b>
                          </>
                        ) : null}
                        {mobileNo ? (
                          <>
                            <label>Mob No</label>
                            <span>:</span>
                            <b
                              style={{
                                color:
                                  templateFor === IdTemplates.LIST
                                    ? swIdCardDetails &&
                                      swIdCardDetails.GetSwIdCardTemplates[2]
                                        .text_color
                                    : formData.text_color,
                              }}
                            >
                              {res.std_mobile}
                            </b>
                          </>
                        ) : null}

                        {fatherName ? (
                          <>
                            <label>Father</label>
                            <span>:</span>
                            <b
                              style={{
                                color:
                                  templateFor === IdTemplates.LIST
                                    ? swIdCardDetails &&
                                      swIdCardDetails.GetSwIdCardTemplates[2]
                                        .text_color
                                    : formData.text_color,
                              }}
                            >
                              {res.std_father_name}
                            </b>
                          </>
                        ) : null}
                        {studentAddress ? (
                          <>
                            <label>Addr</label>
                            <span>:</span>
                            <span>{res.std_addr}</span>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="id-template-2__card--fs--id--footer"
                      style={{
                        backgroundColor:
                          templateFor === IdTemplates.LIST
                            ? swIdCardDetails &&
                              swIdCardDetails.GetSwIdCardTemplates[2]
                                .footer_color
                            : formData.footer_color,
                      }}
                    >
                      {/* <span>
                        {InstFormData ? InstFormData.inst_email : EMPTY_STRING}
                      </span> */}
                    </div>
                  </div>
                </div>
              ) : null}

              {(templateFor === IdTemplates.DEMO ||
                selectedSideForPrint === IdTemplatesSides.BACK ||
                selectedSideForPrint === IdTemplatesSides.BOTH) && (
                <div className="id-template-2__card--bs">
                  {pageType === PageFor.PRINT ? null : (
                    <Title variant="subtitle1">Page 2</Title>
                  )}
                  <div
                    className="id-template-2__card--bs--id"
                    style={{ backgroundColor: formData.bg_color }}
                  >
                    <div className="id-template-2__card--fs--id--profile">
                      <div
                        className={
                          EnableFooterText
                            ? "id-template-2__card--fs--id--address"
                            : "id-template-2__card--fs--id--address--without-footer"
                        }
                      >
                        <div
                          className="id-template-2__card--fs--id--profile--header"
                          style={{
                            background: formData.header_color,
                            paddingLeft: `${marginLeft}px`,
                            paddingRight: `${marginRight}px`,
                            paddingTop: `${marginTop}px`,
                          }}
                        >
                          <div className="id-template-1__card--fs--id--inst-details">
                            {instLogo ? (
                              <div className="id-template-2__card--fs--id--inst-logo">
                                {LogoOrSign.defaultLogo ? (
                                  <img src={LogoOrSign.defaultLogo} alt="/" />
                                ) : null}
                              </div>
                            ) : null}
                            <div className="id-template-1__card--fs--id--name">
                              {customerName ? (
                                <>
                                  <span className="id-template-2__card--fs--id--c-name">
                                    {InstFormData
                                      ? InstFormData.cust_name
                                      : EMPTY_STRING}
                                  </span>
                                </>
                              ) : null}
                              {instName ? (
                                <>
                                  <b className="id-template-2__card--fs--id--inst-name">
                                    {InstFormData
                                      ? InstFormData.inst_name
                                      : EMPTY_STRING}
                                  </b>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div
                          className="id-template-2__card--fs--id--inst-address"
                          style={{
                            paddingLeft: `${marginLeft}px`,
                            paddingRight: `${marginRight}px`,
                          }}
                        >
                          {instAddress ? (
                            <>
                              <div className="id-template-2__card--fs--id--addr">
                                <div className="id-template-1__card--fs--id--grid">
                                  {instAddress ? (
                                    <>
                                      <label>Addr</label>
                                      <span>:</span>
                                      <span className="id-template-1__card--fs--id--addr">
                                        {InstFormData
                                          ? InstFormData.inst_address
                                          : EMPTY_STRING}
                                      </span>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>

                      <div
                        className="id-template-2__card--fs--id--signature"
                        style={{
                          paddingLeft: `${marginLeft}px`,
                          paddingRight: `${marginRight}px`,
                        }}
                      >
                        {PrincipalSignature.defaultLogo ? (
                          <img src={PrincipalSignature.defaultLogo} alt="/" />
                        ) : null}
                        <span>PRINCIPAL</span>
                      </div>
                      {EnableFooterText ? (
                        <div
                          className="id-template-1__card--fs--id--footer-text"
                          style={{
                            paddingLeft: `${marginLeft}px`,
                            paddingRight: `${marginRight}px`,
                          }}
                        >
                          {footerText}
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="id-template-2__card--fs--id--footer"
                      style={{
                        backgroundColor:
                          templateFor === IdTemplates.LIST
                            ? swIdCardDetails &&
                              swIdCardDetails.GetSwIdCardTemplates[2]
                                .footer_color
                            : templateFor === IdTemplates.DEMO
                            ? formData.footer_color
                            : formData.footer_color,
                      }}
                    >
                      <span>
                        {/* {InstFormData ? InstFormData.inst_email : EMPTY_STRING} */}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {templateFor === IdTemplates.DEMO && (
              <div className="id-template-2__button">
                <button onClick={handleUpdate}>Use This Template</button>
                <Tooltip
                  title="The changes you have made will be reset"
                  arrow
                  placement="top"
                  classes={{
                    tooltip: tooltipClasses.customTooltipGrey,
                    arrow: tooltipClasses.customArrowGrey,
                  }}
                >
                  <img src={Close} alt="" onClick={handleClear} />
                </Tooltip>
              </div>
            )}
          </div>
        ))}
      </div>
      {templateFor === IdTemplates.DEMO && (
        <div className="id-card-template__rs">
          <Title variant="subtitle1">Configurations</Title>
          <div className="id-card-template__rs--grid">
            <Label>Background Color</Label>
            <select
              value={
                templateFor === IdTemplates.DEMO
                  ? formData.bg_color
                  : templateFor === IdTemplates.LIST
                  ? swIdCardDetails &&
                    swIdCardDetails.GetSwIdCardTemplates[2].bg_color
                  : ""
              }
              name="bg_color"
              onChange={handleInputChange}
            >
              {backgroundColor.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  style={{ backgroundColor: option.value }}
                >
                  {option.label}
                </option>
              ))}
            </select>
            <Label>Footer Color</Label>
            <select
              value={
                templateFor === IdTemplates.DEMO
                  ? formData.footer_color
                  : templateFor === IdTemplates.LIST
                  ? swIdCardDetails &&
                    swIdCardDetails.GetSwIdCardTemplates[2].footer_color
                  : ""
              }
              onChange={handleInputChange}
              name="footer_color"
            >
              {footerColor.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <Label>Header Color</Label>
            <select
              value={
                templateFor === IdTemplates.DEMO
                  ? formData.header_color
                  : templateFor === IdTemplates.LIST
                  ? swIdCardDetails &&
                    swIdCardDetails.GetSwIdCardTemplates[2].header_color
                  : ""
              }
              onChange={handleInputChange}
              name="header_color"
            >
              {headerColor.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <Label>Text Color</Label>
            <select
              value={
                templateFor === IdTemplates.DEMO
                  ? formData?.text_color
                  : templateFor === IdTemplates.LIST
                  ? swIdCardDetails?.GetSwIdCardTemplates[1].text_color
                  : ""
              }
              onChange={handleInputChange}
              name="text_color"
            >
              {textColor.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="id-card-template__rs--with-colors">
            <Configurations
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.IDCARD_PRINT}
              int_value={0}
              setModalFlag={setModal}
              pageType={PageFor.GENERAL}
            />
          </div>
        </div>
      )}
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Template3;
