import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { Button } from "../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { EMPTY_STRING } from "../../../utils/constants";
import { Operation, PredefinedDataTypes } from "../../../utils/Enum.types";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toIsoDate,
} from "../../../utils/UtilFunctions";

import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import { Keys } from "../../../utils/Enum.keys";
import { msgType, optionsType } from "../../../utils/Form.types";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import Close from "../../../images/Close.svg";
import Input from "../../../stories/Input/Input";
import { useLazyQuery } from "@apollo/client";
import { GetStudentEnquiryEmpAssociList } from "../../../queries/students/list/newApi";
import useToken from "../../../customhooks/useToken";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import EmpDetailsModal from "../EmpDetails";
import { AppContext } from "../../../context/context";
import useEnquiryTable from "../json/useEnquiryTable";
import { TableHeaderProps } from "../../../utils/types";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../styles/DataGridTableStyles";
interface props {
  startDate: string;
  endDate: string;
}
export interface GetStudentEnquiryEmpAssociantionList {
  pr_emp_id: number;
  emp_id: string;
  emp_title: string;
  emp_first_name: string;
  emp_middle_name: string;
  emp_last_name: string;
  designation_desc: string;
  total_enq_count: number;
  conver_count: number;
}
export interface GetStudentEnquiryEmpAssociListData {
  GetStudentEnquiryEmpAssociList: GetStudentEnquiryEmpAssociantionList[];
}
export interface GetStudentEnquiryEmpAssociListVars {
  token: string;
  inst_id: number | string;
  enq_start_date: string;
  enq_end_date: string;
  today_date: string;
}
const EnquiryReports = ({ startDate, endDate }: props) => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const dataClasses = useDataGridStyles();

  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { EmployeeAssociateList } = useEnquiryTable();
  const [follow_up_status, set_follow_up_status] = useState<optionsType | null>(
    null
  );
  const { dispatch } = useContext(AppContext);
  const [selectedTeacherId, setSelectedTeacherId] = useState("");
  const { serverDate } = useServerDateandTime();
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const [searchEnqStudent, setSearchEnqStudent] = useState("");
  const [GetEmpAllocatedData, { data: EmpList }] = useLazyQuery<
    GetStudentEnquiryEmpAssociListData,
    GetStudentEnquiryEmpAssociListVars
  >(GetStudentEnquiryEmpAssociList, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  useEffect(() => {
    if (token && startDate && endDate && serverDate) {
      GetEmpAllocatedData({
        variables: {
          token,
          inst_id: InstId!,
          enq_start_date: startDate ? toIsoDate(startDate) : EMPTY_STRING,
          enq_end_date: endDate ? toIsoDate(endDate) : EMPTY_STRING,
          today_date: toIsoDate(serverDate),
        },
      });
    }
  }, [token, startDate, endDate, GetEmpAllocatedData, serverDate, InstId]);

  const {
    PredefinedData: { dropDown: ENQ_STATUS_OPTIONS },
  } = usePredefinedDataByType(
    PredefinedDataTypes.ENQ_FOLLOW_UP_STATUS,
    EMPTY_STRING
  );

  const [studentDetailsModal, setStudentDetailsModal] = useState(false);
  const handleClear = () => {
    setSearchEnqStudent("");
    set_follow_up_status(null);
  };
  const dynamicHeaders: TableHeaderProps[] = EmployeeAssociateList.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );
  const columns: GridColDef[] = [...dynamicHeaders];
  const rows: GridValidRowModel[] =
    ((EmpList && EmpList.GetStudentEnquiryEmpAssociList) || []).map(
      (data, index) => {
        return {
          id: index + 1,
          emp_id: data.emp_id,
          name: data.emp_first_name + data.emp_middle_name + data.emp_last_name,
          designation: data.designation_desc,
          no_of_followup: data.total_enq_count,
          no_of_conversion: data.conver_count,
        };
      }
    ) || [];
  return (
    <>
      <div className="std-enquiry-followup">
        {!studentDetailsModal && !selectedTeacherId && (
          <>
            <div className="row g-0 std-enquiry-followup__details">
              <div className="col-2 studentlist__Search">
                <Input
                  id="search"
                  placeholder="Serach..."
                  value={searchEnqStudent}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchEnqStudent(e.target.value);
                  }}
                />
              </div>
              <div className="col-2">
                <Autocomplete
                  classes={classes}
                  options={ENQ_STATUS_OPTIONS}
                  value={follow_up_status}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, follow_up_status)
                  }
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (follow_up_status) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      set_follow_up_status(null);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      set_follow_up_status(newValue);
                    } else {
                      set_follow_up_status(null);
                    }
                  }}
                  openOnFocus
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enquiry Status"
                      // required={follow_up_status ? false : true}
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
              <div className="col"></div>
              <div className="col-1">
                <Button
                  mode="clear"
                  className="std-enquiry-followup__details--clear"
                  onClick={handleClear}
                />
              </div>
            </div>
            <div
              className={`std-enquiry-followup__tableblock ${dataClasses.root}`}
            >
              <DataGridPro
                columns={columns}
                rows={rows}
                hideFooter
                disableRowSelectionOnClick
                disableChildrenSorting
                rowHeight={TABLE_ROW_HEIGHT}
                slotProps={{
                  columnsPanel: { disableHideAllButton: true },
                }}
              />
              {/* <TableCell className="std-enquiry-followup__table--status">
                                <Button
                                  mode="view"
                                  onClick={() => {
                                    setStudentDetailsModal(
                                      !studentDetailsModal
                                    );
                                    dispatch({
                                      type: payloadTypes.SET_EMPLOYEE_ID,
                                      payload: {
                                        employeeId: res.pr_emp_id,
                                      },
                                    });
                                  }}
                                />
                              </TableCell> */}
            </div>

            <div className="row g-0">
              <div className="col">
                <Button mode="back" onClick={() => navigate(-1)} />
              </div>
            </div>
          </>
        )}
        {studentDetailsModal && selectedTeacherId && <></>}
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentDetailsModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <EmpDetailsModal
              setStudentDetailsModal={setStudentDetailsModal}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentDetailsModal(!studentDetailsModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EnquiryReports;
