import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../styles/AutocompleteListStyles";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../context/context";
import { msgType, responseType } from "../../utils/Form.types";
import {
  InstitutionConfigurationTypes,
  Operation,
} from "../../utils/Enum.types";
import Home from "./Home/Index";
import usePayRollMastersConfig from "../HR/hooks/usePayRollMastersConfig";
import Avatar from "../../images/Avatar.svg";
import DataFetch from "../../images/Edit.svg";

import useToken from "../../customhooks/useToken";
import useEmpDetailsById from "../HR/hooks/useEmpDetailsById";
import useSwConfigData from "../../customhooks/useSwConfigData";
import { useMutation } from "@apollo/client";
import { ResetEmpPassword } from "../HR/queries/employee/mutation";
import { Title } from "../../stories/Title/Title";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Keys } from "../../utils/Enum.keys";
import { payloadTypes } from "../../context/reducer";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { Button } from "../../stories/Button/Button";
import LoadingModal from "../../pages/LoadingModal";
import MessageModal from "../../pages/MessageModal";
import useAssignedInstbyEmpId, {
  InstUserAccessQueryType,
} from "./hooks/useAssignedInstbyEmpId";

const UserResetPassword = () => {
  const listClasses = ListAutoCompleteStyles();
  const listTextClasses = ListAutoCompleteTextStyles();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const { MultipleInstitutions } = useAssignedInstbyEmpId(
    InstUserAccessQueryType.USER_ACCESS_BY_INST_ID,
    0
  );
  const [showPassword, setShowPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState("");
  const { dispatch, state } = useContext(AppContext);
  const [empModal, setEmpModal] = useState(false);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { USE_HR_CATEGORY, USE_HR_DEPARTMENT, USE_HR_DESIGNATION } =
    usePayRollMastersConfig();
  const { token } = useToken();

  const { employeeFormData } = useEmpDetailsById();

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.DEFAULT_PASSWORD
  );

  const [passwordReset, { loading: updationLoading }] =
    useMutation(ResetEmpPassword);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const resetForm = async () => {
    await passwordReset({
      variables: {
        token,
        pr_emp_id: state.employeeId,
        inst_id: InstId,
        user_details,
        password: resetPassword,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Password reset Successfull",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      //   handleClear(clearType.CLEAR_ALL);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (configData.data && !configData.loading) {
      setResetPassword(
        configData.data?.GetSwConfigVariables[0].config_string_value
      );
    }
  }, [configData.data, configData.loading]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Reset User Password</Title>

      <div className="student-reset-password">
        <div className="row g-0 student-reset-password__details">
          <div className="col">
            <div className="student-reset-password__details--imagefield">
              <Autocomplete
                classes={listClasses}
                options={MultipleInstitutions.employees}
                openOnFocus
                value={MultipleInstitutions.employees.find(
                  ({ value }) => value === state.employeeId
                )}
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: (newValue as responseType).value },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: 0 },
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: 0 },
                    });
                  }
                }}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User Name"
                    autoFocus
                    // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    //   setSearchEmployee(e.target.value)
                    // }
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: listTextClasses.formControlRoot }}
                  />
                )}
              />
              <img
                className="data-fetch-icon"
                src={DataFetch}
                alt="/"
                onClick={() => setEmpModal(!empModal)}
              />
            </div>
            {USE_HR_DESIGNATION && (
              <TextField
                label="Designation"
                InputLabelProps={{ shrink: true }}
                disabled
                value={employeeFormData?.designation}
                className="emp-assign-leave-ledger__textfield"
              />
            )}
            <TextField
              label="Mobile"
              InputLabelProps={{ shrink: true }}
              disabled
              value={employeeFormData?.emp_mobile}
              className="emp-assign-leave-ledger__textfield"
            />
          </div>
          <div className="col">
            <TextField
              label="Name"
              InputLabelProps={{ shrink: true }}
              disabled
              value={employeeFormData?.emp_name}
              className="emp-assign-leave-ledger__textfield"
            />
            {USE_HR_DEPARTMENT && (
              <TextField
                label="Department"
                InputLabelProps={{ shrink: true }}
                disabled
                value={employeeFormData?.department}
                className="emp-assign-leave-ledger__textfield"
              />
            )}
            <TextField
              label="Designation"
              InputLabelProps={{ shrink: true }}
              disabled
              value={employeeFormData?.designation}
              className="emp-assign-leave-ledger__textfield"
            />
          </div>
          <div className="col">
            {USE_HR_CATEGORY && (
              <TextField
                 label="Category"
                InputLabelProps={{ shrink: true }}
                value={employeeFormData?.category}
                disabled
                className="emp-assign-leave-ledger__textfield"
              />
            )}

            <TextField
              className="emp-assign-leave-ledger__textfield"
              label="Email"
              value={employeeFormData?.emp_email}
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </div>
          <div className="col-1 h-100 emp-assign-leave-ledger__profile">
            <img src={Avatar} alt="/" />
          </div>
        </div>
        <div className="student-reset-password__form">
          <div className="student-reset-password__form--details row g-0">
            <div className="col-4">
              <TextField
                className="student-reset-password__form--details--pwd"
                label="Password"
                value={resetPassword}
                onChange={(e) => setResetPassword(e.target.value)}
                InputLabelProps={{ shrink: true }}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <Button mode="save" onClick={resetForm} />
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
      <LoadingModal flag={updationLoading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default UserResetPassword;
