import React, { Key, useEffect, useRef, useState } from "react";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../components/common/Input/Index";

import { Label } from "../../../stories/Label/Label";

import { Title } from "../../../stories/Title/Title";
import { useLazyQuery, useMutation } from "@apollo/client";
import Modal from "react-modal";

import {
  AccLedgerByNodeId,
  AccountGroupLdgrsTypeByInstId,
  GetGroupLedgerDetailsByNode,
} from "../queries/GroupLedgers/query/ById";
import { useNavigate, useParams } from "react-router-dom";

import {
  BankAccountType,
  BankOrCash,
  DebitOrCredit,
  Direction,
  Operation,
  PageFor,
  PageNumbers,
  PredefinedDataTypes,
  ReturnType,
  SundryCreditororDebitor,
} from "../../../utils/Enum.types";

import {
  AddAcctLdgr,
  UpdateAcctLdgrById,
} from "../queries/Accountingledgers/mutations/Index";
import {
  AccountLedgerTypes,
  msgType,
  optionsType,
  responseType,
} from "../../../utils/Form.types";
import { accountingLedgerValidation } from "../../../utils/validationRules";
import { Form, Formik } from "formik";
import {
  RefsByTagName,
  handleMUISelectEvent,
  isOptionEqualToValue,
  removeMoreSpace,
} from "../../../utils/UtilFunctions";
import { LabelNameProps } from "../../../Types/Labels";
import { LedgerTitles } from "../../../Types/Titles";
import Home from "../Home/Index";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import ConfigurationSettings from "../../Master/configurations/general/Index";
import {
  ConfigurationsModalStyles,
  DeleteLedgerModalStyles,
} from "../../../styles/ModalStyles";

import LoadingModal from "../../../pages/LoadingModal";
import { Autocomplete, TextField } from "@mui/material";
import DownArrow from "../../../images/DownArrow.svg";
import Close from "../../../images/Close.svg";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
  selectAutoCompleteStyles,
} from "../../../styles/AutocompleteStyles";
import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import useToken from "../../../customhooks/useToken";
import MessageModal from "../../../pages/MessageModal";
import useAcctLedgerData from "../hooks/useAcctLedgerData";
import axios from "axios";
import { Keys } from "../../../utils/Enum.keys";
import useGroupLedgerData from "../hooks/useGroupLedgerData";
import { IFS_CODE_URL } from "../../../utils/routeUrls";
import {
  GetAcctGroupLdgrs,
  GetAcctLdgrs,
} from "../queries/FeeLedgers/query/Byid";
import {
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import Delete from "./Delete";
import Reorder from "./Reorder";
import Tree from "../GroupLedger/Tree";
import Settings from "../../../images/Settings.svg";
import {
  AcctGroupLdgrQueryType,
  AcctLedgerQueryType,
  GroupLedgerRPTypes,
} from "../common/QueryTypes";
import EduateAccountLedgerList from "../../Eduate/AccountLedger/EduateAccountLedgerList";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
const { Accounts } = require("../../../json/account.json");
const { AccountsTitles } = require("../json/title.json");

const accountTypes = [
  { label: "Savings", value: BankAccountType.SAVINGS },
  { label: "Current", value: BankAccountType.CURRENT },
  { label: "O/D", value: BankAccountType.OD },
];
interface bankDetailsData {
  BANK: string;
  IFSC: string;
  BRANCH: string;
  ADDRESS: string;
  CONTACT: string;
  CITY: string;
  RTGS: boolean;
  MICR: string | null | undefined;
  UPI: boolean;
  NEFT: boolean;
  IMPS: boolean;
}
interface Props {
  type: string;
  setModalFlag?: (modalFlag: boolean) => void;
}
interface groupLedgersOption extends responseType {
  under: string;
}
/**
 * React component responsible for managing accounting ledgers.
 * Handles the creation, editing, and deletion of ledger entries.
 * Uses various state variables and hooks to manage form data and user interactions.
 * Fetches necessary data from the server using custom hooks and GraphQL queries.
 * Performs validation on form data using a validation schema.
 * Makes GraphQL mutations to create or update accounting ledger entries.
 * Displays success or error messages based on the outcome of the mutations.
 * Handles other actions such as clearing the form, deleting a ledger entry, and reordering entries.
 * Renders the form and other components based on the 'type' prop.
 *
 * @param type - The type of the accounting ledger page ('general' or 'modal').
 * @param setModalFlag - A function to set the modal flag (used only when 'type' is 'modal').
 *
 * @returns React component - The rendered form and other components for managing accounting ledgers.
 */
const AccountingLedger = ({ type, setModalFlag }: Props) => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const selectClasses = selectAutoCompleteStyles();
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const [enableAdd, setEnableAdd] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  //useState for Form Data
  const [groupLedgerId, setGroupLedgerId] = useState<groupLedgersOption | null>(
    null
  );
  const [crOrDrValue, setCrOrDrValue] = useState<optionsType>(
    EMPTY_OPTIONSTYPE_OBJECT
  );
  const [bankAccountType, setBankAccountType] = useState<optionsType>(
    EMPTY_OPTIONSTYPE_OBJECT
  );

  const [groupLedgerOptions, setGroupLedgerOptions] = useState<
    groupLedgersOption[]
  >([]);
  // const [searchData, setSearchData] = useState("");
  const [searchAcctLedger, setSearchAcctldgrs] = useState("");
  const [searchGroupLedgers, setSearchGroupLedgers] = useState("");
  const [reOrderModal, setReOrderModal] = useState(false);
  const [predefinedAcctLdgrModal, setPredefindedAcctLdgrModal] =
    useState(false);
  const [groupledgerDisabled, setGroupLedgerDisabled] = useState(false);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [formData, setFormData] = useState<AccountLedgerTypes>({
    groupname: "",
    groupaliasname: "",
    accountholder: "",
    ifscode: "",
    branchname: "",
    accountnumber: "",
    bankname: "",
    address: "",
    city: "",
    state: "",
    country: "",
    contactperson: "",
    phonenumber: "",
    mobile: "",
    email: "",
    pan: "",
    aadharnumber: "",
    gstnumber: "",
  });

  const [bankDetails, setBankDetails] = useState<bankDetailsData>();

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  //useRefs
  const openingBalanceRef = useRef<HTMLInputElement>(null);
  const ledgerNameRef = useRef<HTMLInputElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);
  const {
    PredefinedData: { dropDown: crOrDr },
  } = usePredefinedDataByType(PredefinedDataTypes.CR_DB, EMPTY_STRING);
  const {
    PredefinedData: { dropDown: City },
  } = usePredefinedDataByType(
    PredefinedDataTypes.CITY,
    formData.city ?? EMPTY_STRING,
    ReturnType.VALUE_SAME_AS_LABEL
  );
  const {
    PredefinedData: { dropDown: States },
  } = usePredefinedDataByType(
    PredefinedDataTypes.STATE,
    formData.state ?? EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: Nationality },
  } = usePredefinedDataByType(
    PredefinedDataTypes.NATIONALITY,
    formData.country ?? EMPTY_STRING
  );

  //refs
  const stateRef = useRef<HTMLSelectElement>(null);
  const stateInputRef = RefsByTagName(stateRef);
  const CountryRef = useRef<HTMLSelectElement>(null);
  const countryEnterRef = RefsByTagName(CountryRef);
  const MobileNoRef = document.getElementsByName(
    "mobile"
  )[0] as HTMLInputElement;

  //configuration modal
  const [configurationModal, setConfigurationModal] = useState(false);

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.ACCOUNTING_LEDGER_PAGE
  );
  //useState for Select field while editing
  const [ledgerId, setLedgerId] = useState<responseType | null>(null);
  //useStates to Enable components of GroupLedger
  const [bank, setBank] = useState(false);
  const [sundaydebtors, setSundayDebtors] = useState(false);
  // eslint-disable-next-line
  const [sundaycreditors, setSundayCreditors] = useState(false);
  // eslint-disable-next-line
  const [capitalAccount, setCashAccount] = useState(false);
  //Condition to show form

  const showForm =
    (!enableEdit && !enableAdd) || (ledgerId && enableEdit) || enableAdd
      ? true
      : false;
  const { user_details } = useLoggedInUserDetails();

  const { groupLedgers } = useGroupLedgerData(
    searchGroupLedgers,
    AcctGroupLdgrQueryType.GROUP_LDGR_BY_INST_ID,
    groupLedgerId ? groupLedgerId.value : 0
  );
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const [GetGroupLedgerDetails, GetGroupLedgerDetailsById] = useLazyQuery(
    GetGroupLedgerDetailsByNode,
    {
      variables: { token, id: groupLedgerId?.value },
    }
  );

  const { InstDetails } = useInstDetails(1);
  const { acctLedgers } = useAcctLedgerData(
    searchAcctLedger,
    AcctLedgerQueryType.ACCT_LDGRS_BY_INST_ID,
    ROWS_PER_PAGE
  );
  const [AddAccountingLedgers, { loading: creationLoading }] = useMutation(
    AddAcctLdgr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateAccountingLedgers, { loading: updationLoading }] = useMutation(
    UpdateAcctLdgrById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetAccountLedger] = useLazyQuery(AccLedgerByNodeId, {
    fetchPolicy: "network-only",
  });

  const handleClear = () => {
    setFormData({
      groupname: "",
      groupaliasname: "",
      accountholder: "",
      ifscode: "",
      branchname: "",
      accountnumber: "",
      bankname: "",
      address: "",
      city: "",
      state: "",
      country: "",
      contactperson: "",
      phonenumber: "",
      mobile: "",
      email: "",
      pan: "",
      aadharnumber: "",
      gstnumber: "",
    });
    setLedgerId(null);
    setOpeningBalance(0);
    setCrOrDrValue(EMPTY_OPTIONSTYPE_OBJECT);
    setGroupLedgerId(null);
    setBankAccountType(EMPTY_OPTIONSTYPE_OBJECT);
    setSearchAcctldgrs("");
    setSearchGroupLedgers("");
    setCashAccount(false);
    setBank(false);
  };
  const ChooseComponent = (e: string) => {
    switch (e) {
      case BankOrCash.CASH:
        setCashAccount(true);
        setBank(false);
        setSundayDebtors(false);
        setSundayCreditors(false);
        break;
      case BankOrCash.BANK:
        setBank(true);
        setSundayDebtors(false);
        setSundayCreditors(false);
        setCashAccount(false);
        break;
      case SundryCreditororDebitor.SUNDRAY_DEBITOR:
        setSundayDebtors(true);
        setBank(false);
        setSundayCreditors(false);
        setCashAccount(false);
        break;
      case SundryCreditororDebitor.SUNDRAY_CREDITOR:
        setSundayCreditors(true);
        setBank(false);
        setSundayDebtors(false);
        setCashAccount(false);
        break;
      default:
        setCashAccount(true);
        setSundayCreditors(false);
        setBank(false);
        setSundayDebtors(false);
    }
  };
  const handleAccountingLedgers = async () => {
    if (enableAdd && groupLedgerId) {
      await AddAccountingLedgers({
        variables: {
          token,
          user_details,
          inst_id: InstId!,

          input: {
            inst_id: InstId!,
            ldgr_desc: removeMoreSpace(formData.groupname),
            ldgr_alias: removeMoreSpace(formData.groupaliasname),
            acct_group_ldgr_id: groupLedgerId.value,
            ldgr_ob: openingBalance,
            ldgr_total_cr: 0,
            ldgr_total_db: 0,
            ldgr_cb: 0,
            ldgr_imprest_ob: 0,
            ldgr_bank_holder: removeMoreSpace(formData.accountholder),
            ldgr_bank_ac_no: removeMoreSpace(formData.accountnumber),
            ldgr_bank_ifsc: removeMoreSpace(formData.ifscode),
            ldgr_cash_bank_other:
              GetGroupLedgerDetailsById?.data?.node?.gr_ldgr_corb ===
              BankOrCash.CASH
                ? BankOrCash.CASH
                : GetGroupLedgerDetailsById?.data?.node?.gr_ldgr_corb ===
                  BankOrCash.BANK
                ? BankOrCash.BANK
                : EMPTY_STRING,
            cr_or_db: crOrDrValue.value,
            ldgr_bank_name: removeMoreSpace(formData.bankname),
            ldgr_bank_city: removeMoreSpace(formData.branchname),
            ldgr_bank_type: bankAccountType.value,
            ldgr_sundry_address: removeMoreSpace(formData.address),
            ldgr_sundry_city: removeMoreSpace(formData.city),
            ldgr_sundry_state: removeMoreSpace(formData.state),
            ldgr_sundry_country: removeMoreSpace(formData.country),
            ldgr_sundry_contact_person: removeMoreSpace(formData.contactperson),
            ldgr_sundry_contact_person_phone: removeMoreSpace(
              formData.phonenumber
            ),
            default_receipt_book_id: 0,
            ldgr_sundry_contact_person_mobile: removeMoreSpace(formData.mobile),
            ldgr_sundry_contact_person_email: removeMoreSpace(formData.email),
            ldgr_sundry_pan_id: removeMoreSpace(formData.pan),
            ldgr_sundry_aadhar_id: removeMoreSpace(formData.aadharnumber),
            ldgr_sundry_gst_no: removeMoreSpace(formData.gstnumber),
          },
        },
        refetchQueries: [
          {
            query: AccountGroupLdgrsTypeByInstId,
            variables: {
              token,
              inst_id: InstId,
            },
          },
          {
            query: GetAcctGroupLdgrs,
            variables: {
              token,
              input: {
                ids: [InstId],
                acct_group_ldgr_query_type:
                  AcctGroupLdgrQueryType.GROUP_LDGR_BY_INST_ID,
              },
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Account Ledger Created Successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      if (groupLedgerId === null && enableAdd) {
        setMessage({
          flag: true,
          message: "Please select Group Ledger",
          operation: Operation.NONE,
        });
      }
    }

    if (enableEdit && ledgerId && groupLedgerId) {
      await UpdateAccountingLedgers({
        variables: {
          token,
          id: ledgerId.value,
          inst_id: InstId!,
          user_details,
          input: {
            inst_id: InstId!,
            ldgr_desc: removeMoreSpace(formData.groupname),
            ldgr_alias: removeMoreSpace(formData.groupaliasname),
            acct_group_ldgr_id: groupLedgerId.value,
            ldgr_ob: openingBalance,
            ldgr_bank_holder: removeMoreSpace(formData.accountholder),
            ldgr_bank_ac_no: removeMoreSpace(formData.accountnumber),
            ldgr_bank_ifsc: removeMoreSpace(formData.ifscode),
            ldgr_bank_name: removeMoreSpace(formData.bankname),
            ldgr_bank_city: removeMoreSpace(formData.branchname),
            ldgr_bank_type: bankAccountType.value,
            ldgr_sundry_address: removeMoreSpace(formData.address),
            ldgr_sundry_city: removeMoreSpace(formData.city),
            ldgr_sundry_state: removeMoreSpace(formData.state),
            ldgr_sundry_country: removeMoreSpace(formData.country),
            ldgr_sundry_contact_person: removeMoreSpace(formData.contactperson),
            ldgr_sundry_contact_person_phone: removeMoreSpace(
              formData.phonenumber
            ),
            cr_or_db: crOrDrValue.value,
            ldgr_sundry_contact_person_mobile: removeMoreSpace(formData.mobile),
            ldgr_sundry_contact_person_email: removeMoreSpace(formData.email),
            ldgr_sundry_pan_id: removeMoreSpace(formData.pan),
            ldgr_sundry_aadhar_id: removeMoreSpace(formData.aadharnumber),
            ldgr_sundry_gst_no: removeMoreSpace(formData.gstnumber),
          },
        },
        refetchQueries: [
          {
            query: GetAcctLdgrs,
            variables: {
              token,
              after: null,
              input: {
                acct_ldgr_query_type: AcctLedgerQueryType.ACCT_LDGRS_BY_INST_ID,
                ids: [InstId!],
              },
              direction: Direction.ASC,
              name: "",
              sortBy: "LDGR_DESC",
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Account Ledger Updated Successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
    } else {
      if ((ledgerId === null || groupLedgerId === null) && enableEdit) {
        setMessage({
          flag: true,
          message: `Please fill ${
            ledgerId === null ? "Ledger" : "Group Ledger"
          }`,
          operation: Operation.NONE,
        });
      }
    }
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      //enableAdd and enableEdit should stay false when you close the success modal
      setEnableAdd(false);
      setEnableEdit(false);
      setSundayDebtors(false);
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handlecreditOrDebit = () => {
    if (groupLedgers) {
      if (
        groupLedgers.data?.GetAcctGroupLdgrs.edges.find(
          (d) => d.node.gr_ldgr_rp === GroupLedgerRPTypes.BankAndCash
        )
      ) {
        setCrOrDrValue({ label: "Credit", value: DebitOrCredit.CREDIT });
      }
      if (
        groupLedgers.data?.GetAcctGroupLdgrs.edges.find(
          (d) => d.node.gr_ldgr_rp === GroupLedgerRPTypes.FixedAsset
        )
      ) {
        setCrOrDrValue({
          label: "Debit",
          value: DebitOrCredit.DEBIT,
        });
      }
      if (
        groupLedgers.data?.GetAcctGroupLdgrs.edges.find(
          (d) => d.node.gr_ldgr_rp === GroupLedgerRPTypes.CurrentAsset
        )
      ) {
        setCrOrDrValue({
          label: "Debit",
          value: DebitOrCredit.DEBIT,
        });
      }
      if (
        groupLedgers.data?.GetAcctGroupLdgrs.edges.find(
          (d) => d.node.gr_ldgr_rp === GroupLedgerRPTypes.Income
        )
      ) {
        setCrOrDrValue({
          label: "Credit",
          value: DebitOrCredit.CREDIT,
        });
        setGroupLedgerDisabled(true);
      }
      if (
        groupLedgers.data?.GetAcctGroupLdgrs.edges.find(
          (d) => d.node.gr_ldgr_rp === GroupLedgerRPTypes.Expense
        )
      ) {
        setCrOrDrValue({
          label: "Debit",
          value: DebitOrCredit.DEBIT,
        });
        setGroupLedgerDisabled(true);
      }
      if (
        groupLedgers.data?.GetAcctGroupLdgrs.edges.find(
          (d) => d.node.gr_ldgr_rp === GroupLedgerRPTypes.Liability
        )
      ) {
        setCrOrDrValue({
          label: "Credit",
          value: DebitOrCredit.CREDIT,
        });
      }
    }
  };
  useEffect(() => {
    if (ledgerId && enableEdit) {
      GetAccountLedger({
        variables: {
          id: ledgerId.value,
          token,
        },
      })
        .then(({ data }) => {
          if (data) {
            setFormData({
              groupname: data.node.ldgr_desc,
              groupaliasname: data.node.ldgr_alias,
              accountholder: data.node.ldgr_bank_holder,
              ifscode: data.node.ldgr_bank_ifsc,
              branchname: data.node.ldgr_bank_city,
              accountnumber: data.node.ldgr_bank_ac_no,
              bankname: data.node.ldgr_bank_name,
              address: data.node.ldgr_sundry_address,
              city: data.node.ldgr_sundry_city,
              state: data.node.ldgr_sundry_state,
              country: data.node.ldgr_sundry_country,
              contactperson: data.node.ldgr_sundry_contact_person,
              phonenumber: data.node.ldgr_sundry_contact_person_phone,
              mobile: data.node.ldgr_sundry_contact_person_mobile,
              email: data.node.ldgr_sundry_contact_person_email,
              pan: data.node.ldgr_sundry_pan_id,
              aadharnumber: data.node.ldgr_sundry_aadhar_id,
              gstnumber: data.node.ldgr_sundry_gst_no,
            });
            const groupLedgerId = groupLedgerOptions.find(
              (d) => d.value === data.node.acct_group_ldgr_id
            );
            let CrDrDropDownValue = crOrDr.find(
              (d) => d.value === data.node.cr_or_db
            );
            const bankAcctType = accountTypes.find(
              (accountType) => accountType.value === data.node.ldgr_bank_type
            );
            setOpeningBalance(Math.abs(data.node.ldgr_ob));
            groupLedgerId
              ? setGroupLedgerId(groupLedgerId)
              : setGroupLedgerId({
                  label: groupLedgers.data?.GetAcctGroupLdgrs.edges.find(
                    (d) => d.node.id === data.node.acct_group_ldgr_id
                  )?.node.gr_ldgr_desc!,
                  value: data.node.acct_group_ldgr_id,
                  under: groupLedgers.data?.GetAcctGroupLdgrs.edges.find(
                    (d) => d.node.id === data.node.acct_group_ldgr_id
                  )?.node.gr_ldgr_rp!,
                });

            CrDrDropDownValue && setCrOrDrValue(CrDrDropDownValue);
            bankAcctType && setBankAccountType(bankAcctType);
          }
        })
        .catch(() => console.log("Error"));
    } // eslint-disable-next-line
  }, [
    ledgerId,
    GetAccountLedger,
    enableEdit,
    groupLedgers.responseType,
    token,
    crOrDr,
  ]);

  useEffect(() => {
    if (GetGroupLedgerDetailsById.data && !GetGroupLedgerDetailsById.loading) {
      GetGroupLedgerDetailsById.data.node.gr_ldgr_corb
        ? ChooseComponent(GetGroupLedgerDetailsById?.data?.node.gr_ldgr_corb)
        : ChooseComponent(GetGroupLedgerDetailsById?.data?.node.gr_ldgr_bs);
    }
  }, [GetGroupLedgerDetailsById.data, GetGroupLedgerDetailsById.loading]);

  useEffect(() => {
    if (
      (bank || sundaydebtors || sundaycreditors) &&
      formData.ifscode.length === 11 &&
      bankDetails
    ) {
      setFormData({
        ...formData,
        accountholder: InstDetails.data?.nodes[0]?.inst_name!,
        bankname: bankDetails?.BANK,
        branchname: bankDetails.BRANCH,
        city: bankDetails.CITY,
        address: bankDetails.ADDRESS,
      });
    } // eslint-disable-next-line
  }, [
    bank,
    sundaydebtors,
    sundaycreditors,
    formData.ifscode.length,
    bankDetails,
  ]);
  useEffect(() => {
    if (formData.ifscode.length === 11) {
      const api = IFS_CODE_URL + formData.ifscode;
      axios.get(api).then(({ data }) => {
        if (data) {
          setBankDetails(data);
        }
      });
    }
  }, [formData.ifscode]);
  useEffect(() => {
    if (groupLedgerId && token) {
      GetGroupLedgerDetails();
    }
  }, [GetGroupLedgerDetails, groupLedgerId, token]);
  useEffect(() => {
    if (groupLedgers.data && !groupLedgers.loading) {
      setGroupLedgerOptions(
        groupLedgers.data.GetAcctGroupLdgrs.edges.map(({ node }) => ({
          label: node.gr_ldgr_desc,
          under: node.gr_ldgr_rp,
          value: node.id,
        }))
      );
    }
  }, [groupLedgers.data, groupLedgers.loading]);

  const disableOb = () => {
    if (groupLedgerId) {
      if (
        groupLedgerId?.under === GroupLedgerRPTypes.Expense ||
        groupLedgerId?.under === GroupLedgerRPTypes.Income
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <>
      {PageFor.GENERAL === type && <Home DashBoardRequired={false} />}

      <div className="row g-0">
        <div className="col">
          <Title>
            {AccountsTitles.AccountLedger.map(
              (title: LedgerTitles, index: Key) => {
                return (
                  <React.Fragment key={index}>
                    {enableAdd
                      ? title.CREATE
                      : enableEdit
                      ? title.UPDATE
                      : title.GENERAL}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>
        {PageFor.GENERAL === type && (
          <div className="configuration-settings">
            {USE_CONFIG_KEY && (
              <img
                src={Settings}
                alt="/"
                id="settings-icon"
                onClick={() => setConfigurationModal(!configurationModal)}
              />
            )}
          </div>
        )}
      </div>

      <Formik
        initialValues={formData}
        validationSchema={accountingLedgerValidation}
        enableReinitialize
        onSubmit={handleAccountingLedgers}
      >
        {(meta) => {
          return (
            <Form
              className={
                type === PageFor.GENERAL
                  ? "accountingledger"
                  : "accountingledger__modal"
              }
            >
              <div className="row g-0 accountingledger__block">
                <div
                  className={
                    type === PageFor.GENERAL
                      ? "col accountingledger__block--frames"
                      : "col  h-100"
                  }
                >
                  <div className="accountingledger__block--frames--data">
                    {enableEdit && (
                      <div className="label-grid">
                        <Label variant="LabelPrimary">
                          <b>Select Ledger</b>
                        </Label>
                        <Autocomplete
                          classes={
                            groupLedgerId?.value ? selectClasses : classes
                          }
                          freeSolo
                          isOptionEqualToValue={(option) =>
                            isOptionEqualToValue(option, ledgerId)
                          }
                          options={acctLedgers.responseType!}
                          openOnFocus
                          value={ledgerId}
                          onChange={(e, newValue) => {
                            if (newValue) {
                              const data = newValue as responseType;
                              setLedgerId({
                                label: data.label,
                                value: data.value,
                                isChecked: true,
                              });
                            } else {
                              setLedgerId(null);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              ledgerNameRef?.current?.focus();
                            }
                            if (e.key === Keys.BACKSPACE) {
                              setLedgerId(null);
                              handleClear();
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              autoFocus
                              {...params}
                              fullWidth
                              placeholder="Please Type to Search Other Data..."
                              onChange={(e) =>
                                setSearchAcctldgrs(e.target.value)
                              }
                              classes={{ root: textClasses.formControlRoot }}
                            />
                          )}
                        />
                      </div>
                    )}
                    {showForm ? (
                      Accounts.AccountingLedgerPage.map(
                        (
                          label: LabelNameProps,
                          AccountingLedger: React.Key
                        ) => {
                          return (
                            <React.Fragment key={AccountingLedger}>
                              <Input
                                LabelName={label.LabelName}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleValueChange(e);
                                  meta.handleChange(e);
                                }}
                                name={label.inputName}
                                values={formData[label.inputName]}
                                inputRef={label.ref && ledgerNameRef!}
                                required={
                                  label.required && !formData[label.inputName]
                                    ? true
                                    : false
                                }
                                autoFocus={
                                  (!enableEdit && label.autoFocus) ||
                                  (ledgerId && label.autoFocus)
                                }
                                disabled={!enableAdd && !enableEdit}
                              />
                            </React.Fragment>
                          );
                        }
                      )
                    ) : (
                      <div className="LedgerEditWarning">
                        Please Select the AccountingLedger to be edited
                      </div>
                    )}
                    {showForm && (
                      <>
                        <div className="label-grid">
                          <Label>Group Ledger</Label>

                          <Autocomplete
                            classes={classes}
                            options={groupLedgerOptions}
                            openOnFocus
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            value={groupLedgerId}
                            onChange={(e, newValue) => {
                              const data = newValue as groupLedgersOption;
                              if (data) {
                                setGroupLedgerId(data);
                                if (
                                  data.under === GroupLedgerRPTypes.Expense ||
                                  data.under === GroupLedgerRPTypes.Income
                                ) {
                                  saveRef.current?.focus();
                                } else {
                                  handlecreditOrDebit();
                                }
                              } else {
                                setGroupLedgerId(null);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                onChange={(e) =>
                                  setSearchGroupLedgers(e.target.value)
                                }
                                required
                                classes={{ root: textClasses.formControlRoot }}
                              />
                            )}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                handleMUISelectEvent(e);
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setGroupLedgerId(null);
                                setCrOrDrValue(EMPTY_OPTIONSTYPE_OBJECT);
                              }
                            }}
                            disabled={!enableAdd && !enableEdit}
                          />
                        </div>
                        <div className="outstandingbalance_oneline">
                          <Label>OB</Label>
                          <Input
                            id="right-align"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setOpeningBalance(Number(e.target.value));
                              meta.handleChange(e);
                            }}
                            inputRef={openingBalanceRef!}
                            values={openingBalance}
                            type="number"
                            name="openingBalance"
                            disabled={
                              (!enableAdd && !enableEdit) || disableOb()
                            }
                          />
                          <Autocomplete
                            classes={classes}
                            options={crOrDr!}
                            openOnFocus
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            value={
                              crOrDr?.find(
                                ({ value }) => value === crOrDrValue.value
                              )! ?? null
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setCrOrDrValue(newValue);
                              } else {
                                setCrOrDrValue(EMPTY_OPTIONSTYPE_OBJECT);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                saveRef?.current?.focus();
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setCrOrDrValue(EMPTY_OPTIONSTYPE_OBJECT);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                classes={{ root: textClasses.formControlRoot }}
                              />
                            )}
                            disabled={
                              (!enableAdd && !enableEdit) || disableOb()
                            }
                          />
                        </div>
                      </>
                    )}
                    <div className="row g-0 accountingledger__options">
                      {bank ? (
                        <>
                          <div className="g-0 details ">
                            <h4 className="g-0">Bank Account</h4>
                          </div>

                          {Accounts.BankDetailLeftFrame.map(
                            (label: LabelNameProps, Bank: React.Key) => {
                              return (
                                <React.Fragment key={Bank}>
                                  <Input
                                    LabelName={label.LabelName}
                                    name={label.inputName}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      handleValueChange(e);
                                      meta.handleChange(e);
                                    }}
                                    values={formData[label.inputName]}
                                    maxLength={label.maxLength}
                                    required={
                                      bank &&
                                      label.required &&
                                      !formData[label.inputName]
                                    }
                                  />
                                </React.Fragment>
                              );
                            }
                          )}

                          {Accounts.BankDetailsRightFrame.map(
                            (label: LabelNameProps, index: React.Key) => {
                              return (
                                <React.Fragment key={index}>
                                  <Input
                                    LabelName={label.LabelName}
                                    name={label.inputName}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      handleValueChange(e);
                                      meta.handleChange(e);
                                    }}
                                    values={formData[label.inputName]}
                                    maxLength={label.maxLength}
                                    required={
                                      bank &&
                                      label.required &&
                                      !formData[label.inputName]
                                    }
                                  />
                                </React.Fragment>
                              );
                            }
                          )}
                          <div className="label-grid">
                            <Label>A\C Type</Label>
                            <Autocomplete
                              classes={classes}
                              options={accountTypes}
                              value={bankAccountType}
                              onKeyDown={(e: React.KeyboardEvent) => {
                                if (e.key === Keys.ENTER) {
                                  if (bankAccountType) {
                                    saveRef.current?.focus();
                                  }
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setBankAccountType(EMPTY_OPTIONSTYPE_OBJECT);
                                }
                              }}
                              openOnFocus
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setBankAccountType(newValue as optionsType);
                                } else {
                                  setBankAccountType(EMPTY_OPTIONSTYPE_OBJECT);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="outlined-uncontrolled"
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  required
                                  classes={{
                                    root: textClasses.formControlRoot,
                                  }}
                                />
                              )}
                            />
                          </div>
                        </>
                      ) : null}
                      {sundaydebtors ? (
                        <>
                          <div className="details">
                            <h4>
                              {sundaydebtors
                                ? "Sundry Debitors"
                                : "Sundry Creditors"}
                            </h4>
                          </div>

                          {Accounts.SundayDebtorsLeftFrame.map(
                            (label: LabelNameProps, SundayDebtors: any) => {
                              return (
                                <React.Fragment key={SundayDebtors}>
                                  <Input
                                    LabelName={label.LabelName}
                                    name={label.inputName}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      handleValueChange(e);
                                      meta.handleChange(e);
                                    }}
                                    values={formData[label.inputName]}
                                    type={label.dataType}
                                    maxLength={label.maxLength}
                                  />
                                </React.Fragment>
                              );
                            }
                          )}
                          <div className="label-grid">
                            <Label>City</Label>
                            <Autocomplete
                              classes={classes}
                              options={City!}
                              value={
                                City?.find(
                                  ({ value }) => value === formData.city
                                )! ?? null
                              }
                              onKeyDown={(e: React.KeyboardEvent) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  stateInputRef?.focus();
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => ({
                                    ...prevValues,
                                    city: "",
                                  }));
                                }
                              }}
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      city: (newValue as optionsType)?.value,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => ({
                                    ...prevValues,
                                    city: "",
                                  }));
                                }
                              }}
                              openOnFocus
                              freeSolo
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        city: e.target.value!,
                                      };
                                    });
                                  }}
                                  classes={{
                                    root: textClasses.formControlRoot,
                                  }}
                                />
                              )}
                            />
                            <Label>State</Label>
                            <Autocomplete
                              classes={classes}
                              options={States!}
                              ref={stateRef!}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  countryEnterRef?.focus();
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      state: "",
                                    };
                                  });
                                }
                              }}
                              value={
                                States?.find(
                                  ({ value }) => value === formData.state
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      state: (newValue as optionsType)?.value,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      state: "",
                                    };
                                  });
                                }
                              }}
                              openOnFocus
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        state: e.target.value!,
                                      };
                                    });
                                  }}
                                  fullWidth
                                  classes={{
                                    root: textClasses.formControlRoot,
                                  }}
                                />
                              )}
                            />
                            <Label>Country</Label>
                            <Autocomplete
                              classes={classes}
                              options={Nationality!}
                              value={
                                Nationality?.find(
                                  ({ value }) =>
                                    value === formData.vendor_country
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      vendor_country: (newValue as optionsType)
                                        ?.value!,
                                    };
                                  });
                                } else {
                                  setFormData((prevValues) => ({
                                    ...prevValues,
                                    vendor_country: "",
                                  }));
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === Keys.ENTER) {
                                  e.preventDefault();
                                  MobileNoRef?.focus();
                                }
                                if (e.key === Keys.BACKSPACE) {
                                  setFormData((prevValues) => ({
                                    ...prevValues,
                                    vendor_country: "",
                                  }));
                                }
                              }}
                              ref={CountryRef!}
                              openOnFocus
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => {
                                    setFormData((prevValues) => {
                                      return {
                                        ...prevValues,
                                        vendor_country: e.target.value!,
                                      };
                                    });
                                  }}
                                  fullWidth
                                  classes={{
                                    root: textClasses.formControlRoot,
                                  }}
                                />
                              )}
                            />
                          </div>

                          {Accounts.SundayDebtorsRightFrame.map(
                            (label: LabelNameProps, index: React.Key) => {
                              return (
                                <React.Fragment key={index}>
                                  <Input
                                    LabelName={label.LabelName}
                                    name={label.inputName}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      handleValueChange(e);
                                      meta.handleChange(e);
                                    }}
                                    required={label.required}
                                    values={formData[label.inputName]}
                                    maxLength={label.maxLength}
                                    className={label.class}
                                    type={label.dataType}
                                    ref={MobileNoRef!}
                                  />
                                </React.Fragment>
                              );
                            }
                          )}

                          <>
                            <div className="g-0 details ">
                              <h4 className="g-0">Bank Account</h4>
                            </div>

                            {Accounts.BankDetailLeftFrame.map(
                              (label: LabelNameProps, Bank: React.Key) => {
                                return (
                                  <React.Fragment key={Bank}>
                                    <Input
                                      LabelName={label.LabelName}
                                      name={label.inputName}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        handleValueChange(e);
                                        meta.handleChange(e);
                                      }}
                                      values={formData[label.inputName]}
                                      maxLength={label.maxLength}
                                      required={
                                        (bank || sundaydebtors) &&
                                        label.required &&
                                        !formData[label.inputName]
                                      }
                                    />
                                  </React.Fragment>
                                );
                              }
                            )}

                            {Accounts.BankDetailsRightFrame.map(
                              (label: LabelNameProps, index: React.Key) => {
                                return (
                                  <React.Fragment key={index}>
                                    <Input
                                      LabelName={label.LabelName}
                                      name={label.inputName}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        handleValueChange(e);
                                        meta.handleChange(e);
                                      }}
                                      values={formData[label.inputName]}
                                      maxLength={label.maxLength}
                                      required={
                                        (bank || sundaydebtors) &&
                                        label.required &&
                                        !formData[label.inputName]
                                      }
                                    />
                                  </React.Fragment>
                                );
                              }
                            )}
                            <div className="label-grid">
                              <Label>A\C Type</Label>
                              <Autocomplete
                                classes={classes}
                                options={accountTypes}
                                value={bankAccountType}
                                onKeyDown={(e: React.KeyboardEvent) => {
                                  if (e.key === Keys.ENTER) {
                                    if (bankAccountType) {
                                      saveRef.current?.focus();
                                    }
                                  }
                                  if (e.key === Keys.BACKSPACE) {
                                    setBankAccountType(
                                      EMPTY_OPTIONSTYPE_OBJECT
                                    );
                                  }
                                }}
                                openOnFocus
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setBankAccountType(newValue as optionsType);
                                  } else {
                                    setBankAccountType(
                                      EMPTY_OPTIONSTYPE_OBJECT
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id="outlined-uncontrolled"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    required
                                    classes={{
                                      root: textClasses.formControlRoot,
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                {type === PageFor.GENERAL && (
                  <div className="col h-100 accountingledger__block--view">
                    <Tree />
                  </div>
                )}
              </div>

              <>
                {enableAdd || enableEdit ? (
                  <>
                    <Button mode="save" buttonref={saveRef} type="submit" />

                    <Button mode="clear" type="button" onClick={handleClear} />

                    <Button
                      mode="back"
                      onClick={() => {
                        handleClear();
                        setEnableAdd(false);
                        setEnableEdit(false);
                        setGroupLedgerId(null);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      mode="addnew"
                      type="button"
                      onClick={() => {
                        setMessage({
                          message: "",
                          flag: false,
                          operation: Operation.NONE,
                        });
                        setEnableAdd(true);
                      }}
                      autoFocus
                    />

                    <Button
                      mode="edit"
                      type="button"
                      onClick={() => {
                        setEnableEdit(true);
                        // changed by akshay need to discuss
                        // GetAcctLedgers({
                        //   variables: {
                        //     token,
                        //     inst_id: InstId,
                        //   },
                        // });
                      }}
                    />
                    <Button
                      mode="addnew"
                      onClick={() =>
                        setPredefindedAcctLdgrModal(!predefinedAcctLdgrModal)
                      }
                    >
                      Predefined Ledger
                    </Button>
                    {type === PageFor.GENERAL ? (
                      <>
                        <Button
                          mode="delete"
                          onClick={() => setDeleteModal(!deleteModal)}
                        />

                        <Button
                          mode="reorder"
                          onClick={() => setReOrderModal(!reOrderModal)}
                        />

                        <Button
                          mode="back"
                          type="button"
                          onClick={() => navigate(-1)}
                        />
                      </>
                    ) : null}
                    {type === PageFor.MODAL && (
                      <Button
                        mode="cancel"
                        type="button"
                        onClick={() => setModalFlag?.(false)}
                      />
                    )}
                  </>
                )}
              </>
            </Form>
          );
        }}
      </Formik>
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.ACCOUNTING_LEDGER_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>

      {/* deleteModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={deleteModal}
        style={DeleteLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Delete setModal={setDeleteModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDeleteModal(!deleteModal)}
            />
          </div>
        </div>
      </Modal>
      {/* ReOrder Modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={reOrderModal}
        style={DeleteLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Reorder setReOrderModal={setReOrderModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setReOrderModal(!reOrderModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={predefinedAcctLdgrModal}
        style={DeleteLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <EduateAccountLedgerList
              pageFor={PageFor.MODAL}
              setModalFlag={setPredefindedAcctLdgrModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() =>
                setPredefindedAcctLdgrModal(!predefinedAcctLdgrModal)
              }
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={creationLoading || updationLoading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default AccountingLedger;
