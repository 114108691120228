import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { Autocomplete, Checkbox, Drawer, TextField } from "@mui/material";
import Input from "../../../../stories/Input/Input";
import {
  Direction,
  InstitutionConfigurationTypes,
  Operation,
  SortBy,
  TableHeaders,
} from "../../../../utils/Enum.types";
import Home from "../../Home/Index";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import useToken from "../../../../customhooks/useToken";
import { Keys } from "../../../../utils/Enum.keys";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { msgType, responseType } from "../../../../utils/Form.types";
import { useNavigate, useParams } from "react-router-dom";
import { sectionAllocationStyles } from "../../../../styles/DrawerStyles";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import StudentTotalCount from "../../../Master/Student/Components/StudentTotalCount";
import useSubjectAllotedForClass from "../../hooks/useSubjectAllotedForClass";
import {
  AcdStudentsvars,
  GetStudentAcdData,
  StudentEdges,
} from "../../hooks/useAcdStudentsData";
import { StudentAcdType } from "../../../../utils/studentAcdEnum.types";
import { GetAcdStudents } from "../../queries/students/Index";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { AddElectiveSubjForStds } from "../../queries/subjects/mutation/Index";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

const StudentAllocation = () => {
  const navigate = useNavigate();
  //Styles
  const classes = ListAutoCompleteStyles();
  const { state } = useContext(AppContext);
  const textClasses = ListAutoCompleteTextStyles();
  //usestates
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          <TableCell>{TableHeaders.SLNO}</TableCell>
          <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
          <TableCell>{TableHeaders.REGISTER_NUMBER}</TableCell>
          <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>
          {USE_BRANCH_KEY ? (
            <TableCell className="studentlist__table--status">
              {branchLabel}
            </TableCell>
          ) : null}
          {USE_CLASS_KEY ? (
            <TableCell className="studentlist__table--status">
              {classLabel}
            </TableCell>
          ) : null}
          {USE_SECTION_KEY ? (
            <TableCell className="studentlist__table--status">
              {sectionLabel}
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
  };
  const EnhancedTableHeadForDrawer = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>{TableHeaders.SLNO}</TableCell>
          <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
          <TableCell>{TableHeaders.REGISTER_NUMBER}</TableCell>
          <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>

          {USE_SECTION_KEY ? (
            <TableCell className="studentlist__table--status">
              {sectionLabel}
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
  };

  //useStates
  const { token } = useToken();
  const { InstId, allotedID } = useParams();
  const drawerClasses = sectionAllocationStyles();
  const [interchangeSection, setInterchangeSection] = useState(false);
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [searchData, setSearchData] = useState("");
  const [electiveSelected, setElectiveSelected] = useState<responseType | null>(
    null
  );

  const [electiveSubjSelected, setElectiveSubjSelected] =
    useState<responseType | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [checkedStudentIds, setCheckedStudentIds] = useState<number[]>([]);

  //useRefs
  const { subjectsForClass } = useSubjectAllotedForClass(Number(allotedID));

  const subjectsDropDown = subjectsForClass
    ? subjectsForClass.data?.GetAcdSubjectAllotedForClass.filter(
        (data) => data.subj_is_elective
      ).map((res) => ({
        label: res.subject_details.subj_desc,
        value: res.subject_details.id ?? 0,
      }))
    : [];

  const electiveSubjDropDown = subjectsForClass.data
    ? subjectsForClass.data.GetAcdSubjectAllotedForClass.filter(
        (data) => data.subj_master_id === electiveSelected?.value
      ).flatMap((res) =>
        res.elective_details.map((subject) => ({
          label: subject.subject_details.subj_desc,
          value: subject.subject_details.id ?? 0,
        }))
      )
    : [];

  const { user_details } = useLoggedInUserDetails();
  //useMutations
  const [AllocateStudents, { loading: updationLoading }] = useMutation(
    AddElectiveSubjForStds,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const { USE_BRANCH_KEY, USE_CLASS_KEY, USE_SECTION_KEY } =
    useInstitutionConfiguration();

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );

  //pagination for leftSection
  const [
    GetStudentsData,
    { data: LeftStudentsData, loading: LeftStudentsDataLoading, fetchMore },
  ] = useLazyQuery<GetStudentAcdData, AcdStudentsvars>(GetAcdStudents, {
    variables: {
      after: null,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      first: ROWS_PER_PAGE,
      name: searchData,
      token,
      orderBy: [
        {
          direction: Direction.ASC,
          field: SortBy.STD_ROLL_NO,
        },
      ],
      input: {
        ids: [
          Number(allotedID)!,
          electiveSelected ? electiveSelected.value : 0,
        ],
        acd_std_query_type:
          StudentAcdType.BY_ENTRY_LEVEL_FOR_MAIN_ELECTIVE_SUBJ,

        str_data: [
          configData.data?.GetSwConfigVariables[0].config_string_value!,
        ],
      },
    },
  });

  useEffect(() => {
    if (
      token &&
      state.ActiveAcdYr &&
      allotedID &&
      configData.data &&
      configData.data.GetSwConfigVariables[0].config_string_value
    ) {
      GetStudentsData();
    }
  }, [GetStudentsData, token, state.ActiveAcdYr, allotedID, configData.data]);

  useEffect(() => {
    if (LeftStudentsData && !LeftStudentsDataLoading && token) {
      const newData = LeftStudentsData.GetAcdStudents.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: false,
        },
      }));

      if (endCursor) {
        const filteredStudents = students.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: false,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(LeftStudentsData.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [LeftStudentsData, LeftStudentsDataLoading, token]);

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setStudents([]);
      setInterchangeSection(!interchangeSection);
      navigate(`/${InstId}/academics/electiveSubjectAllocationToStudents`);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !LeftStudentsDataLoading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const updatedStudents = [...students!];

            newEdges.forEach(({ node }) => {
              const isChecked = checkedStudentIds.includes(node.id);
              const existingStudentIndex = updatedStudents.findIndex(
                (row) => row.node.id === node.id
              );
              if (existingStudentIndex !== -1) {
                updatedStudents[existingStudentIndex].node.isChecked =
                  isChecked;
              } else {
                updatedStudents.push({
                  node: { ...node, isChecked },
                });
              }
            });

            return {
              GetAcdStudents: {
                edges: [...updatedStudents],
                pageInfo,
                totalCount: LeftStudentsData?.GetAcdStudents.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const handleClick = (
    data: StudentEdges,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const isChecked = (e.target as HTMLInputElement).checked;

    const updatedStudents = students.map((row) => {
      if (row.node.id === data.node.id) {
        return {
          ...row,
          node: {
            ...row.node,
            isChecked: isChecked || checkedStudentIds.includes(data.node.id),
          },
        };
      }
      return row;
    });

    setStudents(updatedStudents);

    const updatedCheckedStudentIds = isChecked
      ? [...checkedStudentIds, data.node.id]
      : checkedStudentIds.filter((id) => id !== data.node.id);

    setCheckedStudentIds(updatedCheckedStudentIds);
  };

  const clear = () => {
    setElectiveSelected(null);
    setStudents([]);
    setSearchData("");
    setCheckedStudentIds([]);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    const updatedStudents = (students ?? []).map((row) => ({
      ...row,
      node: {
        ...row.node,
        isChecked,
      },
    }));
    setStudents(updatedStudents);
    const updatedCheckedStudentIds = isChecked
      ? updatedStudents.map(({ node }) => node.id)
      : [];

    setCheckedStudentIds(updatedCheckedStudentIds);
  };

  const handleAllocationOfStudentsToSection = () => {
    AllocateStudents({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
        student_ids: checkedStudentIds ? checkedStudentIds : 0,
        main_subj_master_id: electiveSelected ? electiveSelected.value : 0,
        elective_subj_master_id: electiveSubjSelected
          ? electiveSubjSelected.value
          : 0,
        main_subj_sl:
          subjectsForClass.data &&
          subjectsForClass.data.GetAcdSubjectAllotedForClass.find(
            (res) => res.subject_details.id === electiveSelected?.value
          )?.subj_sl,
        allotted_id: allotedID,
        allotted_level:
          configData.data &&
          configData.data.GetSwConfigVariables[0].config_string_value,
      },
      refetchQueries: [
        {
          query: GetAcdStudents,
          variables: {
            after: null,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            first: ROWS_PER_PAGE,
            name: searchData,
            token,
            orderBy: [
              {
                direction: Direction.ASC,
                field: SortBy.STD_ROLL_NO,
              },
            ],
            input: {
              ids: [
                Number(allotedID)!,
                electiveSelected ? electiveSelected.value : 0,
              ],
              acd_std_query_type:
                StudentAcdType.BY_ENTRY_LEVEL_FOR_MAIN_ELECTIVE_SUBJ,

              str_data: [
                configData.data?.GetSwConfigVariables[0].config_string_value!,
              ],
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Allocated Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
        clear();
      }
    });
  };

  const { branchLabel, classLabel, sectionLabel } = useInstLabels();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Elective Subject Allocation To Students</Title>
      <div className="elective-sub">
        <form className="row g-0 elective-sub__options">
          <div className="col-1 ">
            <Input
              id="search"
              type="text"
              placeholder="Search..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>

          {subjectsDropDown && subjectsDropDown.length > 0 ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={subjectsDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, electiveSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && electiveSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setElectiveSelected(null);
                  }
                }}
                openOnFocus
                autoFocus
                value={electiveSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setElectiveSelected(newValue);
                  } else {
                    setElectiveSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Choose Elective"
                    id="outlined Departments"
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : (
            <b className="nodata">No Elective Subjects has been allocated </b>
          )}
        </form>
        <div className="elective-sub__tableblock">
          {!electiveSelected?.value ? (
            <b className="nodata">Select Subject </b>
          ) : (
            <TableContainer
              className="elective-sub__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <EnhancedTableHead
                  numSelected={
                    students?.filter((data) => data.node.isChecked).length
                  }
                  onSelectAllClick={(event) => handleSelectAllClick(event)}
                  rowCount={students?.length}
                />
                <TableBody>
                  {students?.map((response, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={students[index]?.node.isChecked}
                        tabIndex={-1}
                        key={response.node.id}
                        selected={students[index]?.node.isChecked}
                      >
                        <TableCell
                          padding="checkbox"
                          id="td-center"
                          className="elective-sub__table--slno"
                        >
                          <Checkbox
                            // checked={students[index]?.node.isChecked ?? false}
                            checked={
                              checkedStudentIds[index] ===
                              students[index]?.node.id
                                ? true
                                : false
                            }
                            onClick={(event) => handleClick(response, event)}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          id="td-center"
                          className="elective-sub__table--slno"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className="elective-sub__table--admno">
                          {response.node.std_adm_no}
                        </TableCell>
                        <TableCell className="elective-sub__table--admno">
                          {response.node.std_reg_no}
                        </TableCell>

                        <TableCell id={labelId}>
                          {response.node.first_name +
                            " " +
                            response.node.middle_name +
                            " " +
                            response.node.last_name}
                        </TableCell>
                        {USE_BRANCH_KEY ? (
                          <TableCell className=" elective-sub__table--desc ">
                            {response.node.acd_branch.branch_desc}
                          </TableCell>
                        ) : null}
                        {USE_CLASS_KEY ? (
                          <TableCell className=" elective-sub__table--desc ">
                            {response.node.acd_class.class_desc}
                          </TableCell>
                        ) : null}
                        {USE_SECTION_KEY ? (
                          <TableCell className="elective-sub__table--desc">
                            {response.node.acd_section.section_desc}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        <div className="row g-0">
          <div className="col">
            <Button
              onClick={() => setInterchangeSection(!interchangeSection)}
              disabled={checkedStudentIds.length === 0}
              mode="move-to"
            />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 section-allocation__total">
            <StudentTotalCount
              totalCount={LeftStudentsData?.GetAcdStudents?.totalCount!}
            />
          </div>
        </div>
      </div>

      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={interchangeSection}
        onClose={() => setInterchangeSection(!interchangeSection)}
      >
        <div className="elective-sub__move-to">
          <Title>Select Subject</Title>

          <div className="row g-0 elective-sub__move-to--select">
            <div className="col-3">
              <Autocomplete
                classes={classes}
                options={electiveSubjDropDown!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setElectiveSubjSelected(null);
                  }
                }}
                openOnFocus
                value={electiveSubjSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setElectiveSubjSelected(newValue);
                  } else {
                    setElectiveSubjSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label={electiveSelected?.label}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          </div>

          <div className="elective-sub__move-to--tableblock">
            <TableContainer
              className="elective-sub__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <EnhancedTableHeadForDrawer />
                <TableBody>
                  {students &&
                    students
                      .filter((data) =>
                        checkedStudentIds.includes(data.node.id)
                      )
                      .map((response, index) => {
                        return (
                          <TableRow
                            key={response.node.id}
                            hover
                            aria-checked={response.node.isChecked}
                            tabIndex={-1}
                            selected={response.node.isChecked}
                          >
                            <TableCell
                              id="td-center"
                              className="elective-sub__table--slno"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell className="elective-sub__table--admno">
                              {response.node.std_adm_no}
                            </TableCell>
                            <TableCell className="elective-sub__table--admno">
                              {response.node.std_reg_no}
                            </TableCell>

                            <TableCell>
                              {response.node.first_name +
                                " " +
                                response.node.middle_name +
                                " " +
                                response.node.last_name}
                            </TableCell>

                            {USE_SECTION_KEY ? (
                              <TableCell className="elective-sub__table--desc"></TableCell>
                            ) : null}
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button mode="save" onClick={handleAllocationOfStudentsToSection} />
          <Button
            mode="cancel"
            onClick={() => {
              clear();
              setInterchangeSection(!interchangeSection);
            }}
          />
          <MessageModal
            modalFlag={message.flag!}
            value={message.message!}
            handleClose={handleClose}
            operation={message.operation!}
          />
          <LoadingModal flag={updationLoading} />
        </div>
      </Drawer>

      <LoadingModal flag={LeftStudentsDataLoading} />
    </>
  );
};

export default StudentAllocation;
