import {  TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";

import Modal from "react-modal";
import Close from "../../../../images/Close.svg";
import {
  Operation,
  PageFor,
  PageLabel,
  StudentListFor,
  StudentReportType,
} from "../../../../utils/Enum.types";
import StudentList from "../List";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import { Button } from "../../../../stories/Button/Button";

import Input from "../../../../stories/Input/Input";
import { AppContext } from "../../../../context/context";
import Avatar from "../../../../images/Avatar.svg";
import useStudentAdmissionNumber from "../../../Accounts/hooks/UseStudentAdmissionNumber";
import {
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
} from "../../../../utils/constants";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { msgType, responseType } from "../../../../utils/Form.types";
import { updateStudentById } from "../../../../queries/students/mutations/update";
import { useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import { useNavigate, useParams } from "react-router-dom";
import LoadingModal from "../../../../pages/LoadingModal";
import { Keys } from "../../../../utils/Enum.keys";
import { payloadTypes } from "../../../../context/reducer";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import StudentSelectAutocomplete from "../../../Components/StudentSelectAutocomplete";

const EditAdmissionNumber = () => {

  const { dispatch, state } = useContext(AppContext);
  const { InstDetails } = useInstDetails(1);
  const { studentData, studentFormData } = useStudentDatabyId();
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const admNoRef = useRef<HTMLInputElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);
  const [admNo, setAdmNo] = useState("");

  const [studentModal, setStudentModal] = useState(false);
  const [updateAdmNumber, SetUpdateAdmNumber] = useState("");
  const { user_details } = useLoggedInUserDetails();
  const { studentAddmissionNumber } = useStudentAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentReportType.GENERAL
  );

  const { USE_BRANCH_KEY, USE_CATEGORY_KEY, USE_CLASS_KEY, USE_SEMESTER_KEY } =
    useInstitutionConfiguration();
  const [updateAdmNo, { loading: updationLoading }] = useMutation(
    updateStudentById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [studentDetails, setStudentDetails] = useState({
    std_profile_filename: "",
  });
  const [imageString, setImageString] = useState("");
  const updateForm = () => {
    updateAdmNo({
      variables: {
        token,
        id: state.studentId,
        inst_id: InstId!,
        user_details,
        input: { std_adm_no: updateAdmNumber },
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Student Admission No Updated Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };

  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    handleClear();
  };
  const handleClear = () => {
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0,
      },
    });
    SetUpdateAdmNumber(EMPTY_STRING);
    setImageString(EMPTY_STRING);
  };
  useEffect(() => {
    if (studentDetails.std_profile_filename !== "" && InstDetails.data) {
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;

      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
    if (studentData.data && InstDetails.data) {
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;

      setStudentDetails({
        std_profile_filename: studentProfiePicUrl,
      });
    }
  }, [
    studentDetails.std_profile_filename,
    studentData.data,
    InstDetails.data,
    state.studentId,
  ]);
  const { branchLabel, classLabel, semesterLabel ,categoryLabel} = useInstLabels();
  return (
    <>
      <div className="std-edit-adm-no__block">
        <div className="row g-0 std-edit-adm-no__details">
          <div className="col">
            <div className="std-edit-adm-no__details--image-field">
              <StudentSelectAutocomplete
                queryType={StudentReportType.ACD_STD_GENERAL}
              />
            </div>
            <TextField
              label="Register Number"
              InputLabelProps={{ shrink: true }}
              value={studentFormData.reg_number}
              className="std-edit-adm-no__details--textfield"
              disabled
            />
            {USE_BRANCH_KEY ? (
              <TextField
                label={branchLabel}
                InputLabelProps={{ shrink: true }}
                value={studentFormData.branch}
                className="std-edit-adm-no__details--textfield"
                disabled
              />
            ) : null}
          </div>
          <div className="col">
            <TextField
              label="Name"
              InputLabelProps={{ shrink: true }}
              value={studentFormData.std_name}
              className="std-edit-adm-no__details--textfield"
              disabled
            />
            <TextField
              label="Father Name"
              InputLabelProps={{ shrink: true }}
              value={studentFormData.father_name}
              className="std-edit-adm-no__details--textfield"
              disabled
            />
            {USE_CLASS_KEY ? (
              <TextField
                label={classLabel}
                InputLabelProps={{ shrink: true }}
                value={studentFormData.class}
                className="std-edit-adm-no__details--textfield"
                disabled
              />
            ) : null}
          </div>
          <div className="col">
            {USE_SEMESTER_KEY ? (
              <TextField
                label={semesterLabel}
                InputLabelProps={{ shrink: true }}
                value={studentFormData.semester}
                className="std-edit-adm-no__details--textfield"
                disabled
              />
            ) : null}
            {USE_CATEGORY_KEY ? (
              <TextField
                 label={categoryLabel}
                InputLabelProps={{ shrink: true }}
                value={studentFormData.category}
                className="std-edit-adm-no__details--textfield"
                disabled
              />
            ) : null}
            <div>
              <Input
                id={
                  updateAdmNumber && updateAdmNumber === studentFormData.adm_no
                    ? "bg-red"
                    : "receivedamount"
                }
                value={updateAdmNumber!}
                inputRef={admNoRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (updateAdmNumber) {
                      saveRef?.current?.focus();
                    }
                  }
                }}
                placeholder="Enter New Admission No."
                onChange={(e) => {
                  SetUpdateAdmNumber(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-1 std-edit-adm-no__details--profile h-100">
            <img src={imageString ? imageString : Avatar} alt="/" />
          </div>
        </div>
        <div className="std-edit-adm-no__datablock"></div>
        <Button
          mode="save"
          onClick={updateForm}
          disabled={!updateAdmNumber}
          buttonref={saveRef!}
        />
        <Button mode="clear" onClick={handleClear} />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      {/* student-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACADEMIC}
              queryType={StudentReportType.ELIGIBLE}
              setStudentModal={setStudentModal}
              studentModal={studentModal}
              label={PageLabel.TRUE}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setStudentModal(!studentModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default EditAdmissionNumber;
