import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { Title } from "../../../../../stories/Title/Title";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE_30,
  TODAY_DATE,
} from "../../../../../utils/constants";
import {
  BankOrCash,
  ExcelAlignment,
  ExcelFont,
  ExcelFooterHeader,
  ExcelPageHeader,
  ExcelSheetsNames,
  FileUploadParams,
  InstitutionConfigurationTypes,
  ReceiptTypes,
  studentData,
} from "../../../../../utils/Enum.types";

import {
  DateRange,
  filterVouchersByType,
  formatter,
  getHeaderRowStyle,
  getModifiedScrollHeight,
  toInputStandardDate,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import Close from "../../../../../images/Close.svg";
import Modal from "react-modal";
import Eduate from "../../../../../images/Eduate_Logo_image.png";

import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../../styles/AutocompleteListStyles";
import { nodevars, VouchernodeData } from "../../../../../Types/Accounting";
import { Cell } from "exceljs";
import ExcelJS from "exceljs";

import {
  EnquiryStudentDetailsByMasterVoucherId,
  PassedOutStudentDetailsByMasterVoucherId,
  StudentDetailsByMasterVoucherId,
} from "../../../../../queries/common";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../../customhooks/useToken";
import StudentDemandList from "../../AccountLedgerBook/Modals/StudentVoucherView";
import { StudentModalStyles } from "../../../../../styles/ModalStyles";
import useAcctVoucherDetailsByDates, {
  GetAcctVouchersEdges,
  voucherMasterDetails,
} from "../../../hooks/useAcctVoucherDetailsByDates";
import { AppContext } from "../../../../../context/context";
import { payloadTypes } from "../../../../../context/reducer";
import { Button } from "../../../../../stories/Button/Button";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  DOWNLOAD,
  E4_CELL,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FOOTER_CSS,
  FROZEN_CELLS,
  G4_CELL,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../../../Library/Constants";
import {
  FeeReceiptBookPdf,
  receiptBookHeader,
} from "../../../common/HeaderConsts";
import { optionsType } from "../../../../../utils/Form.types";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import useInstLogoDetails from "../../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import { VoucherQueryTypes } from "../../../common/Enum.types";
import useAcctTableJson from "../../../json/useAcctTableJson";
import {
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../../styles/DataGridTableStyles";
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../../utils/types";
import { GridAlignment } from "../../../../Academics/Reports/CompleteReports/Test/ViewParticularTestReportTable";

interface props {
  queryType: VoucherQueryTypes;
}

export const filterOptions: optionsType[] = [
  {
    label: "Cash Receipts",
    value: BankOrCash.CASH,
  },
  {
    label: "Bank Receipts",
    value: BankOrCash.BANK,
  },
  {
    label: "Adjustment Receipts",
    value: BankOrCash.ADJ,
  },
];
const Index = ({ queryType }: props) => {
  const listClasses = ListAutoCompleteStyles();
  const listTextClasses = ListAutoCompleteTextStyles();
  const { Accounts_Table } = useAcctTableJson();
  let DefaultDate = new Date();

  let totalrcptAmount = 0;
  const dates = DateRange(DefaultDate.toString());
  const dataClasses = useDataGridStyles();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const { format } = formatter;
  const { token } = useToken();
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { InstDetails } = useInstDetails(1);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const [vouchers, setVouchers] = useState<GetAcctVouchersEdges[]>([]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;
  const [studentDetails, setStudentDetails] = useState<voucherMasterDetails[]>(
    []
  );
  const [passedOutStudentDetails, setPassedStudentDetails] = useState<
    voucherMasterDetails[]
  >([]);

  const [enquiryStudentDetails, setEnquiryStudentDetails] = useState<
    voucherMasterDetails[]
  >([]);

  const [filter, setFilter] = useState("");
  const [adjustmentFlag, setAdjustmentFlag] = useState<boolean>(false);
  const [GetStudentDetails, { loading: studentsDataLoading }] = useLazyQuery<
    VouchernodeData,
    nodevars
  >(StudentDetailsByMasterVoucherId);
  const [GetPassedStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    PassedOutStudentDetailsByMasterVoucherId
  );

  const [GetEnqStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    EnquiryStudentDetailsByMasterVoucherId
  );

  const [startDate, setStartDate] = useState(
    toInputStandardDate(dates?.firstDay!)
  );
  const [endDate, setEndDate] = useState(toInputStandardDate(dates?.lastDay!));
  const [studentReceiptModal, setStudentReceiptModal] = useState(false);
  const [pdfFlag, setPdfFlag] = useState(false);
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(
    ROWS_PER_PAGE_30
  );
  const [v_no, setV_no] = useState("");
  const { dispatch, state } = useContext(AppContext);
  const { VoucherDetails } = useAcctVoucherDetailsByDates(
    rowsPerPage,
    endDate,
    startDate,
    queryType,
    v_no,
    adjustmentFlag,
    filter
  );
  const { data, fetchMore, loading } = VoucherDetails;

  const totalCount = data?.GetAcctVouchers?.totalCount;

  // eslint-disable-next-line
  vouchers?.map((edge) => {
    totalrcptAmount += edge.node.v_total_cr_amt;
  });

  const FetchStudentDetails = (
    voucher: voucherMasterDetails,
    type: studentData
  ) => {
    const student = studentDetails?.find(
      (studentDetail) => studentDetail?.id === voucher.id
    );
    const enqStudent = enquiryStudentDetails.find(
      (enqStudentDetails) => enqStudentDetails?.id === voucher.id
    );
    const passedOutStudent = passedOutStudentDetails.find(
      (passedOutStudentDetails) => passedOutStudentDetails?.id === voucher.id
    );

    if (
      student &&
      voucher.student_id &&
      voucher.v_std_enquiry === false &&
      voucher.v_std_passout_receipt === false
    ) {
      switch (type) {
        case studentData.name:
          return (
            student?.mst_student.first_name +
            " " +
            student?.mst_student.middle_name +
            " " +
            student?.mst_student.last_name +
            " " +
            `(${voucher.branch_details.branch_desc} / ${voucher.class_details.class_desc} / ${student?.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return student?.mst_student.std_adm_no!;
        case studentData.parent:
          return student?.mst_student.std_father_name!;
        case studentData.reg:
          return student?.mst_student.std_reg_no!;
        case studentData.id:
          return student?.mst_student.id!;
        default:
          break;
      }
    }
    if (
      enqStudent &&
      voucher.student_id === 0 &&
      voucher.v_std_enquiry &&
      voucher.v_std_passout_receipt === false
    ) {
      switch (type) {
        case studentData.name:
          return (
            enqStudent?.enq_std_details.first_name +
            " " +
            enqStudent?.enq_std_details.middle_name +
            " " +
            enqStudent?.enq_std_details.last_name +
            " " +
            `(${voucher.branch_details.branch_desc} / ${voucher.class_details.class_desc} / ${enqStudent?.enq_std_details.class_details.class_desc} / ${enqStudent?.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return "-";
        case studentData.parent:
          return enqStudent?.enq_std_details.std_father_name!;
        case studentData.reg:
          return "-";
        case studentData.id:
          return enqStudent?.enq_std_details.id!;
        default:
          break;
      }
    }
    if (
      passedOutStudent &&
      voucher.student_id === 0 &&
      voucher.v_std_enquiry === false &&
      voucher.v_std_passout_receipt
    ) {
      switch (type) {
        case studentData.name:
          return (
            passedOutStudent?.passout_std_details.std_name +
            " " +
            `(${voucher.branch_details.branch_desc} / ${voucher.class_details.class_desc} / ${passedOutStudent?.passout_std_details.class_details.class_desc} / ${passedOutStudent?.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return "-";
        case studentData.parent:
          return "-";
        case studentData.reg:
          return "-";
        case studentData.id:
          return passedOutStudent?.passout_std_details.id!;
        default:
          break;
      }
    }
  };
  const receiptData =
    data &&
    !studentsDataLoading &&
    data?.GetAcctVouchers.edges.map((edge, index) => ({
      SlNo: index + 1,
      Date: toStandardDate(edge.node.v_date),
      ReceiptNo: edge.node.v_no,
      AdmNo: FetchStudentDetails(edge.node, studentData.admNo),
      RegNo: FetchStudentDetails(edge.node, studentData.reg),
      Name: FetchStudentDetails(edge.node, studentData.name),
      PaidThrough: edge.node.v_transcation_type,
      ReceiptAmt: format(edge.node.v_total_cr_amt),
    }));
  const filteredStudentsReceipts = filterVouchersByType(
    ReceiptTypes.STUDENT_RECEIPT,
    VoucherDetails.data!
  );

  const filteredPassedStudentReceipts = VoucherDetails.data
    ? VoucherDetails.data.GetAcctVouchers.edges.filter(
        ({ node }) =>
          node.v_type === ReceiptTypes.STUDENT_RECEIPT &&
          node.v_std_passout_receipt
      )
    : [];

  const filteredEnquiryStudentReceipts = VoucherDetails.data
    ? VoucherDetails.data.GetAcctVouchers.edges.filter(
        ({ node }) =>
          node.v_type === ReceiptTypes.STUDENT_RECEIPT && node.v_std_enquiry
      )
    : [];
  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(Eduate)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const doc = new jsPDF("landscape", "mm", "a4");
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(18);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(240, 240, 240);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0);
                  doc.text(
                    `${InstDetails?.data?.nodes[0].inst_name}`,
                    80,
                    startY
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_address}`,
                    120,
                    startY + 7
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_place},${InstDetails?.data?.nodes[0]?.inst_pin}`,
                    120,
                    startY + 12
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `Fin-year -${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    45,
                    startY + 21
                  );
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text(
                    state.receiptBookName
                      ? state.receiptBookName
                      : "Demand Receipt",
                    120,
                    startY + 21
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `From : ${startDate}   To : ${endDate}`,
                    210,
                    startY + 21
                  );
                  doc.addImage(result, "JPEG", 15, 5, 22, 22);
                  if (configLogo) {
                    doc.addImage(result2, "JPEG", 250, 5, 20, 20);
                  }
                }

                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: FeeReceiptBookPdf.map((col) => ({
                    ...col,
                    dataKey: col?.field,
                    styles: { fontSize: 18 },
                  })),

                  body: receiptData ? (receiptData as RowInput[]) : [],
                  foot: [
                    [
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "Total :",
                      `${format(totalrcptAmount)}`,
                    ],
                  ],
                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: function (data) {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);

                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                  columnStyles: {
                    8: { halign: "right" },
                  },
                  footStyles: {
                    fillColor: [144, 238, 144],
                    textColor: [0, 0, 0],
                    fontSize: 10,
                    halign: "right",
                  },
                });

                doc.save(
                  `${InstDetails?.data?.nodes[0]?.inst_name} ${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  } `
                );
              });
            });
        });
      });
    setPdfFlag(false);
    setRowsPerPage(ROWS_PER_PAGE_30);
  };
  const downloadExcel = () => {
    return new Promise<void>((resolve, reject) => {
      const waitForStudentsData = () => {
        if (studentsDataLoading) {
          setTimeout(waitForStudentsData, 1000);
        } else {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet(ExcelPageHeader.FEE_RECIEPT);
          const dataRows = receiptData
            ? receiptData?.map((item) => [
                item.SlNo,
                item.Date,
                item.ReceiptNo,
                item.AdmNo,
                item.RegNo,
                item.Name,
                item.PaidThrough,
                item.ReceiptAmt,
              ])
            : [];
          worksheet.views = FROZEN_CELLS;
          const headerStyle = getHeaderRowStyle();
          worksheet.getRow(1).height = 33;
          worksheet.getRow(2).height = 20;
          worksheet.getRow(3).height = 20;
          worksheet.getRow(4).height = 23;
          worksheet.getColumn(1).width = 6;
          worksheet.getColumn(2).width = 15;
          worksheet.getColumn(3).width = 17;
          worksheet.getColumn(4).width = 15;
          worksheet.getColumn(5).width = 15;
          worksheet.getColumn(6).width = 70;
          worksheet.getColumn(7).width = 30;
          worksheet.getColumn(8).width = 21;
          const getBase64 = (file: any, cb: (a: string) => void) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
              cb(reader.result?.toString()!);
            };
          };
          fetch(Eduate)
            .then((response) => {
              return response.blob();
            })
            .then((blob) => {
              getBase64(blob, (result) => {
                const imageV = workbook.addImage({
                  base64: result,
                  extension: EDUATE_IMG_FORMAT,
                });

                worksheet.addImage(imageV, "H1:H3");

                worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

                const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                mergedCell.value = InstDetails?.data?.nodes[0]?.inst_name;
                mergedCell.fill = HEADER_CSS;
                mergedCell.font = headerStyle[0].font;
                mergedCell.alignment = HEADER_ALIGNMENT;

                const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                mergedAddress.value = InstDetails?.data?.nodes[0]?.inst_address;
                mergedAddress.fill = HEADER_CSS;
                mergedAddress.font = ADDRESS_FONT;
                mergedAddress.alignment = HEADER_ALIGNMENT;
                worksheet.mergeCells("A2:H2");

                const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                mergedPlace.value =
                  InstDetails?.data?.nodes[0]?.inst_place +
                  "-" +
                  InstDetails?.data?.nodes[0]?.inst_pin;

                mergedPlace.fill = HEADER_CSS;
                mergedPlace.font = ADDRESS_FONT;
                mergedPlace.alignment = ADDRESS_ALIGNMENT;
                worksheet.mergeCells("A3:H3");

                const mergedHeader: Cell = worksheet.getCell(E4_CELL);
                mergedHeader.value = ExcelPageHeader.FEE_RECIEPT;
                mergedHeader.fill = FILE_NAME_CSS;
                mergedHeader.font = FILE_NAME_FONT;
                mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                worksheet.mergeCells("E4:F4");
                const mergedDate: Cell = worksheet.getCell(G4_CELL);
                mergedDate.value =
                  ExcelPageHeader.DATE + toStandardDate(serverDate);
                mergedDate.fill = FILE_NAME_CSS;
                mergedDate.font = FIN_YEAR_FONT;
                mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                worksheet.mergeCells("G4:H4");
                const mergedYear: Cell = worksheet.getCell(A4_CELL);
                mergedYear.value = state.ActiveFinYr
                  ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                  : ExcelPageHeader.YEAR;
                mergedYear.fill = FILE_NAME_CSS;
                mergedYear.font = FIN_YEAR_FONT;
                mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                worksheet.mergeCells("A4:D4");

                let Char = FIRST_INDEX;

                for (let i = 0; i < receiptBookHeader.length; i++) {
                  Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                  const rowData: Cell = worksheet.getCell(Char + 5);
                  rowData.value = receiptBookHeader[i];
                  rowData.fill = TABLE_HEADER_CSS;
                  rowData.border = BORDER_DATA;
                  rowData.font = ACC_HEADER_FONT;
                  rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                }

                dataRows!.forEach((rowData) => {
                  const row = worksheet.addRow(rowData);
                  row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    cell.font = { name: ExcelFont.COURIER_NEW, size: 9 };
                    row.getCell(6).font = {
                      name: ExcelFont.CENTURY_GOTHIC,
                      size: 9,
                    };
                    row.getCell(6).alignment = {
                      horizontal: ExcelAlignment.LEFT,
                    };
                    row.getCell(8).alignment = {
                      horizontal: ExcelAlignment.RIGHT,
                    };
                    row.getCell(2).alignment = {
                      horizontal: ExcelAlignment.CENTER,
                    };
                  });
                });

                const footerRow = worksheet.addRow([]);
                footerRow.getCell(7).value = ExcelFooterHeader.TOTAL;
                footerRow.getCell(7).font = {
                  name: ExcelFont.ARIAL,
                  size: 11,
                };
                footerRow.getCell(8).value = format(totalrcptAmount);

                footerRow.getCell(7).alignment = {
                  horizontal: ExcelAlignment.RIGHT,
                };
                footerRow.getCell(8).alignment = {
                  horizontal: ExcelAlignment.RIGHT,
                };

                worksheet.addConditionalFormatting({
                  ref: `H${footerRow.number}`,
                  rules: FOOTER_CSS,
                });

                workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                  const blob = new Blob([buffer], {
                    type: BLOB_TYPE,
                  });
                  const url = window.URL.createObjectURL(blob);
                  const link = document.createElement(ELEMENT);
                  link.href = url;
                  link.setAttribute(DOWNLOAD, ExcelSheetsNames.RECEIPT_BOOK);
                  document.body.appendChild(link);
                  link.click();
                });
                setExcelFlag(false);
                setRowsPerPage(ROWS_PER_PAGE_30);
                resolve();
              });
            });
        }
      };
      waitForStudentsData();
    });
  };

  useEffect(() => {
    if (
      excelFlag &&
      rowsPerPage === null &&
      !loading &&
      data?.GetAcctVouchers.edges.length === data?.GetAcctVouchers.totalCount
    )
      downloadExcel();
    if (
      pdfFlag &&
      rowsPerPage === null &&
      !loading &&
      data?.GetAcctVouchers.edges.length === data?.GetAcctVouchers.totalCount
    )
      downloadPdf();
    // eslint-disable-next-line
  }, [excelFlag, pdfFlag, rowsPerPage, loading]);

  useEffect(() => {
    if (filteredStudentsReceipts?.length) {
      GetStudentDetails({
        variables: {
          token,
          ids: filteredStudentsReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setStudentDetails(data?.nodes!);
      });
    } // eslint-disable-next-line
  }, [GetStudentDetails, filteredStudentsReceipts?.length, token]);
  useEffect(() => {
    if (filteredPassedStudentReceipts?.length) {
      GetPassedStudentDetails({
        variables: {
          token,
          ids: filteredPassedStudentReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setPassedStudentDetails(data.nodes);
      });
    } // eslint-disable-next-line
  }, [GetPassedStudentDetails, filteredPassedStudentReceipts?.length, token]);
  useEffect(() => {
    if (filteredEnquiryStudentReceipts.length) {
      GetEnqStudentDetails({
        variables: {
          token,
          ids: filteredEnquiryStudentReceipts.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setEnquiryStudentDetails(data.nodes);
      });
    } // eslint-disable-next-line
  }, [GetEnqStudentDetails, filteredEnquiryStudentReceipts.length, token]);

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;

      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctVouchers.edges;
                const pageInfo = fetchMoreResult.GetAcctVouchers.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcctVouchers.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcctVouchers: {
                    edges: [...vouchers, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcctVouchers.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };

      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (data && !loading && excelFlag === false && pdfFlag === false) {
      const newData = data.GetAcctVouchers.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = vouchers.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setVouchers(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            date: toStandardDate(node.v_date),
            receipt_no: node.v_no,
            admission_no: FetchStudentDetails(node, studentData.admNo),
            reg_no: FetchStudentDetails(node, studentData.reg),
            name: FetchStudentDetails(node, studentData.name),
            received_from: node.v_transcation_type,
            receipt_amt: format(node.v_total_cr_amt),
            v_std_enquiry: node.v_std_enquiry,
            v_std_passout_receipt: node.v_std_passout_receipt,
            passout_student_id: node.passout_student_id,
            v_std_receipt: node.v_std_receipt,
            student_id: node.student_id,
            voucher_id: node.id,
          }))
        );
      } else {
        setVouchers(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            date: toStandardDate(node.v_date),
            receipt_no: node.v_no,
            admission_no: FetchStudentDetails(node, studentData.admNo),
            reg_no: FetchStudentDetails(node, studentData.reg),
            name: FetchStudentDetails(node, studentData.name),
            received_from: node.v_transcation_type,
            receipt_amt: format(node.v_total_cr_amt),
            v_std_enquiry: node.v_std_enquiry,
            v_std_passout_receipt: node.v_std_passout_receipt,
            passout_student_id: node.passout_student_id,
            v_std_receipt: node.v_std_receipt,
            student_id: node.student_id,
            voucher_id: node.id,
          }))
        );
      }
      setEndCursor(data.GetAcctVouchers.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [
    excelFlag,
    pdfFlag,
    rowsPerPage,
    loading,
    data,
    studentDetails,
    enquiryStudentDetails,
    passedOutStudentDetails,
  ]);

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.Reports.FeeReceiptBook.Table_Headers.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));
  const columns: GridColDef[] = [...dynamicHeaders];
  const getRow = () => {
    if (vouchers.length) {
      return [
        {
          id: [],
          date: [],
          admission_no: [],
          receipt_no: [],
          reg_no: [],
          name: [],
          received_from: "Total:",
          receipt_amt: format(totalrcptAmount),
        },
      ];
    }
    return [];
  };
  const pinnedRows = {
    bottom: getRow(),
  };

  const getCellClassName = (params: GridCellParams) => {
    const pinnedRowIds = pinnedRows.bottom
      ? pinnedRows.bottom.map((row) => row.id)
      : [];

    const isPinnedRow = pinnedRowIds.includes(params.row.id);

    if (isPinnedRow) {
      switch (params.field) {
        case "received_from":
          return "total";

        case "receipt_amt":
          return "totalcount";
        default:
          return "";
      }
    }

    return "";
  };

  return (
    <>
      <div className="row g-0 fee-receipt-book__tableblock--title">
        <div className="col-2 ">
          <TextField
            id="Search"
            label="Receipt Number"
            className="fee-receipt-book__tableblock--title--textfield"
            InputLabelProps={{ shrink: true }}
            placeholder="Search..."
            onChange={(e) => setV_no(e.target.value)}
          />
        </div>
        <div className="col-2">
          <Autocomplete
            classes={listClasses}
            options={filterOptions}
            openOnFocus
            autoHighlight
            onChange={(e, newValue) => {
              if (newValue) {
                const data = newValue as optionsType;
                if (data.value !== BankOrCash.ADJ) {
                  setFilter(data.value);
                  setAdjustmentFlag(false);
                } else {
                  setFilter(EMPTY_STRING);
                  setAdjustmentFlag(true);
                }
              } else {
                setFilter(EMPTY_STRING);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id="outlined-uncontrolled"
                label="Receipt Types"
                autoFocus
                InputLabelProps={{ shrink: true }}
                fullWidth
                classes={{ root: listTextClasses.formControlRoot }}
              />
            )}
          />
        </div>
        <div className="col">
          <Title variant="subtitle1">{state.receiptBookName}</Title>
        </div>
        <div className="col-3 fee-receipt-book__tableblock--title--datefield">
          <TextField
            id="outlined-uncontrolled"
            label="From"
            InputProps={{
              inputProps: {
                min: state.ActiveFinYr
                  ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                  : EMPTY_STRING,
                max: toInputStandardDate(TODAY_DATE),
              },
            }}
            className="fee-receipt-book__tableblock--title--textfield--date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
              setHasNextPage(true);
            }}
          />

          <TextField
            id="outlined-uncontrolled"
            label="To"
            className="fee-receipt-book__tableblock--title--textfield--date"
            type="date"
            InputProps={{
              inputProps: {
                min: state.ActiveFinYr
                  ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                  : EMPTY_STRING,
                max: toInputStandardDate(TODAY_DATE),
              },
            }}
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
              setHasNextPage(true);
            }}
          />
        </div>
      </div>
      <div
        className={`fee-receipt-book__tableblock--table ${dataClasses.root}`}
      >
        {!VoucherDetails.loading && !vouchers?.length ? (
          <b className="nodata">Sorry, no results.</b>
        ) : (
          <>
            <DataGridPro
              columns={columns}
              rows={rows}
              onCellClick={(params) => {
                if (params.field === "name")
                  if (params.row.v_std_enquiry) {
                    dispatch({
                      type: payloadTypes.SET_STD_ENQUIRY_ID,
                      payload: {
                        studentEnquiryId: params.row.enquiry_student_id,
                      },
                    });
                  }
                if (params.row.v_std_passout_receipt) {
                  dispatch({
                    type: payloadTypes.SET_ALUMINI_STUDENT_ID,
                    payload: {
                      aluminiStudentId: params.row.passout_student_id,
                    },
                  });
                }
                if (params.row.v_std_receipt) {
                  dispatch({
                    type: payloadTypes.SET_STUDENT_ID,
                    payload: {
                      studentId: params.row.student_id,
                    },
                  });
                }
                dispatch({
                  type: payloadTypes.SET_RECEPIT_ID,
                  payload: {
                    receiptId: params.row.voucher_id,
                  },
                });

                setStudentReceiptModal(!studentReceiptModal);
              }}
              disableRowSelectionOnClick
              disableChildrenSorting
              rowHeight={TABLE_ROW_HEIGHT}
              hideFooter
              pinnedRows={pinnedRows}
              getCellClassName={getCellClassName}
              slotProps={{
                columnsPanel: { disableHideAllButton: true },
              }}
            />
          </>
        )}
      </div>

      <div className="row g-0">
        <div className="col">
          <Button
            onClick={() => {
              setRowsPerPage(null);
              setPdfFlag(true);
            }}
            mode="pdf"
          />
          <Button
            onClick={() => {
              setRowsPerPage(null);
              setExcelFlag(true);
            }}
            mode="export"
          />
        </div>
        <div className="col fee-receipt-book__footer--total">
          <div className="student-total-count">
            Total Students:&nbsp; <b>{totalCount ? totalCount : 0} </b>
          </div>
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentReceiptModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentDemandList setModalFlag={setStudentReceiptModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentReceiptModal(!studentReceiptModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
