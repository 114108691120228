import React, { useContext, useEffect, useMemo, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import useAcctTableJson from "../../../../Accounts/json/useAcctTableJson";

import FeeBalance from "../../../../../images/FeeBalance.svg";
import PayableAmount from "../../../../../images/PayFeeGreen.svg";
import { studentRecepit } from "../../../../../Types/Accounting";
import {
  GetNoOfDaysDelayed,
  formatter,
} from "../../../../../utils/UtilFunctions";
import { AppContext } from "../../../../../context/context";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import { DebitOrCredit, YesNo } from "../../../../../utils/Enum.types";
import {
  DemandDetailsQueryType,
  LedgerType,
} from "../../../../Accounts/common/QueryTypes";
import useStudentDemandDetails, {
  studentDemandDetailsData,
} from "../../../../Accounts/hooks/useStudentDemandDetails";
import { EMPTY_STRING } from "../../../../../utils/constants";
import useFineSlab from "../../../../Accounts/hooks/useFineSlab";
import { InstitutionAcctConfigurationTypes } from "../../../../Accounts/common/Enum.types";
import { GlobalPageConfigData } from "../../../../../Types/configtypes";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import { useOnlinePayment } from "../../../../Accounts/StudentDetails/FeeReceipt/useOnlinePayment";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
interface tableProps {
  labelName: string;
}

interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const PayByDemand = ({ setModalFlag }: Props) => {
  const { Accounts_Table } = useAcctTableJson();
  const { state } = useContext(AppContext);
  const [items, setItems] = useState<studentRecepit[]>([]);
  const { serverDate } = useServerDateandTime();
  const { studentData } = useStudentDatabyId();

  const [payableAmount, setPayableAmount] = useState(0);
  const [payableAmountDivStyle, setPayableAmountDivStyle] = useState({
    border: "",
    backgroundColor: "",
    color: "",
  });

  const { format } = formatter;

  const { StudentDemandDetails } = useStudentDemandDetails(
    DemandDetailsQueryType.DEMAND_DETAILS_GT_0,
    false,
    EMPTY_STRING
  );
  const { configData: configKeys } = useSwConfigData([
    InstitutionAcctConfigurationTypes.ALLOW_MUL_BILLS_UNDER_ONE,
    InstitutionAcctConfigurationTypes.MULTIPLE_BILL_BOOKS,
    InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE,
    InstitutionAcctConfigurationTypes.HIDE_CAPITATION_FEE,
    InstitutionAcctConfigurationTypes.ENABLE_FEE_FINE,
    InstitutionAcctConfigurationTypes.PAYMENT_GATEWAY,
    InstitutionAcctConfigurationTypes.ENABLE_PARTIAL_STD_RECEIPT_PAYMENT,
    InstitutionAcctConfigurationTypes.STRICTLY_COLLECT_STD_FEE_IN_ORDER,
  ]);
  const { finesData } = useFineSlab();

  const getFine = (noOfDaysDelayed: number) => {
    const { GetAcctFineSlabByInstId } = finesData.data || {};
    if (!GetAcctFineSlabByInstId) return 0;

    const fineSlabs = [
      {
        from: GetAcctFineSlabByInstId.acct_slab1_from,
        to: GetAcctFineSlabByInstId.acct_slab1_to,
        fine: GetAcctFineSlabByInstId.acct_slab1_fine,
      },
      {
        from: GetAcctFineSlabByInstId.acct_slab2_from,
        to: GetAcctFineSlabByInstId.acct_slab2_to,
        fine: GetAcctFineSlabByInstId.acct_slab2_fine,
      },
      {
        from: GetAcctFineSlabByInstId.acct_slab3_from,
        to: GetAcctFineSlabByInstId.acct_slab3_to,
        fine: GetAcctFineSlabByInstId.acct_slab3_fine,
      },
      {
        from: GetAcctFineSlabByInstId.acct_slab4_from,
        to: GetAcctFineSlabByInstId.acct_slab4_to,
        fine: GetAcctFineSlabByInstId.acct_slab4_fine,
      },
      {
        from: GetAcctFineSlabByInstId.acct_slab5_from,
        to: Infinity,
        fine: GetAcctFineSlabByInstId.acct_slab5_fine,
      },
    ];

    for (let i = 0; i < fineSlabs.length; i++) {
      if (
        noOfDaysDelayed >= fineSlabs[i].from &&
        noOfDaysDelayed < fineSlabs[i].to
      ) {
        return fineSlabs[i].fine;
      }
    }
    return 0;
  };
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let allowMultipleBillsUnderOne = false;
    let isMultipleBillook = false;
    let editDate = false;
    let hideCaptationFee = false;
    let enableFeeFine = false;
    let enablePaymentGateway = false;
    let enablePartialStdReceiptPayment = false;
    let strictlyCollectStdFeeInOrder = false;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.ALLOW_MUL_BILLS_UNDER_ONE:
            allowMultipleBillsUnderOne = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.MULTIPLE_BILL_BOOKS:
            isMultipleBillook = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE:
            editDate = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.HIDE_CAPITATION_FEE:
            hideCaptationFee = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.ENABLE_FEE_FINE:
            enableFeeFine = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.PAYMENT_GATEWAY:
            enablePaymentGateway = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.ENABLE_PARTIAL_STD_RECEIPT_PAYMENT:
            enablePartialStdReceiptPayment = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.STRICTLY_COLLECT_STD_FEE_IN_ORDER:
            strictlyCollectStdFeeInOrder = item.config_boolean_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      allowMultipleBillsUnderOne,
      editDate,
      hideCaptationFee,
      isMultipleBillook,
      enableFeeFine,
      enablePaymentGateway,
      enablePartialStdReceiptPayment,
      strictlyCollectStdFeeInOrder,
    };
  };
  const {
    hideCaptationFee,
    enableFeeFine,
    enablePaymentGateway,
    strictlyCollectStdFeeInOrder,
  } = filterDataByConfigKey(configKeys.data?.GetSwConfigVariables!);

  const mapStudentDemandDetails = (
    data: studentDemandDetailsData
  ): { studentFeeData: studentRecepit[] } => {
    const studentFeeData: studentRecepit[] =
      data.GetAcctStdDemandDetails.filter((item) => item !== null).map(
        (item) => {
          return {
            fee_ob: item.fee_ob,
            fee_demand: item.fee_demand,
            fee_concession: item.fee_concession,
            fee_receivable: item.fee_receivable,
            fee_received: item.fee_received,
            fee_refunds: item.fee_refunds,
            fee_bal: item.fee_bal,
            v_no: "",
            fee_due_date: item.fee_due_date,
            acct_ldgr_id: item.acct_ldgr_id,
            id: item.id,
            cr_db: DebitOrCredit.CREDIT,
            recevied_amount: 0,
            newItem: YesNo.NO,
            legederType: LedgerType.GENERAL,
            rcpt_amt: 0,
            acct_ldgr_details: item?.acct_ldgr_details,
            initialBalance: item.fee_bal,
            vo_key: EMPTY_STRING,
            student_id: item.student_id,
            fine_amt:
              new Date(item?.fee_due_date!).getTime() <
              new Date(serverDate).getTime()!
                ? getFine(GetNoOfDaysDelayed(new Date(item.fee_due_date!)))! *
                  GetNoOfDaysDelayed(new Date(item.fee_due_date!))
                : 0,
          };
        }
      );

    return { studentFeeData };
  };

  const studentDemandDetailsmemoizedData = useMemo(() => {
    if (StudentDemandDetails.data && !StudentDemandDetails.loading) {
      const { studentFeeData } = mapStudentDemandDetails(
        StudentDemandDetails.data
      );

      return studentFeeData;
    }
    return items;
    // eslint-disable-next-line
  }, [
    StudentDemandDetails.data,
    StudentDemandDetails.loading,
    studentData.data,
    state.studentId,
    serverDate,
  ]);

  useEffect(() => {
    setItems(state.studentId ? studentDemandDetailsmemoizedData : []);

    if (studentData.data && state.studentId) {
      setPayableAmount(
        studentData.data?.nodes[0].acct_std_demand.std_demand_bal
      );
    }

    // eslint-disable-next-line
  }, [
    studentDemandDetailsmemoizedData,
    // eslint-disable-next-line
    hideCaptationFee,
    studentData.data,
    state.studentId,
  ]);

  //Funtion to update payable grid styles
  const updatePayableAmountDivStyle = () => {
    if (items.filter(({ isChecked }) => isChecked).length === items.length) {
      setPayableAmountDivStyle({
        border: "",
        backgroundColor: "var(--level-1) ",
        color: "var(--level-5)",
      });
    } else if (items.filter(({ isChecked }) => isChecked).length > 0) {
      setPayableAmountDivStyle({
        border: "1px solid var(--level-2)",
        backgroundColor: "var(--bg-color)",
        color: "var(--text-color)",
      });
    } else {
      setPayableAmountDivStyle({
        border: "",
        backgroundColor: "var(--disabled-color)",
        color: "var(--text-color)",
      });
    }
  };

  const totals = items.reduce(
    (acc, item) => {
      acc.totalOutStandingBalance += item.fee_ob;
      acc.totalDemandAmount += item.fee_demand;
      acc.totalconcession += item.fee_concession;
      acc.totalbalance += item.fee_bal;
      acc.totalPayable += item.fee_receivable;
      acc.totalpaidAmount += item.fee_received;
      acc.totalacademicAmount += item.rcpt_amt!;

      return acc;
    },
    {
      totalOutStandingBalance: 0,
      totalDemandAmount: 0,
      totalconcession: 0,
      totalbalance: 0,
      totalPayable: 0,
      totalpaidAmount: 0,
      totalacademicAmount: 0,
    }
  );

  useEffect(
    () => {
      updatePayableAmountDivStyle();
    },
    // eslint-disable-next-line
    []
  );
  // Function to handle checkbox click

  const handleCheckBasedOnIndex = (index: number) => {
    if (
      (strictlyCollectStdFeeInOrder &&
        index !== 0 &&
        index - 1 >= 0 &&
        (items[index - 1].isChecked === false ||
          items[index - 1].isChecked === undefined)) ||
      (strictlyCollectStdFeeInOrder &&
        index + 1 !== items.length &&
        items[index + 1].isChecked)
    ) {
      alert("Sequential payment is mandatory.");
      return;
    }
    items[index].isChecked = !items[index].isChecked;
    items[index].rcpt_amt = items[index].isChecked ? items[index].fee_bal : 0;

    const total = items
      .filter(({ isChecked }) => isChecked)
      .reduce((acc, item) => {
        acc += item.fee_bal;
        return acc;
      }, 0);
    setPayableAmount(total);
    updatePayableAmountDivStyle();
  };

  //Function to handle selectAll
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allChecked = e.target.checked;

    if (allChecked) {
      const updatedItems = items.map((node) => ({
        ...node,
        isChecked: true,
        rcpt_amt: node.fee_bal,
      }));

      const total = updatedItems.reduce((acc, item) => {
        acc += item.fee_bal;
        return acc;
      }, 0);
      setItems(updatedItems);
      setPayableAmount(total);
    } else {
      const updatedItems = items.map((node) => ({
        ...node,
        isChecked: false,
        rcpt_amt: 0,
      }));
      setItems(updatedItems);
      setPayableAmount(0);
    }
    updatePayableAmountDivStyle();
  };

  const [totalFineAmt, setTotalFineAmt] = useState(0);
  const { component } = useOnlinePayment(setModalFlag, items, totalFineAmt);

  const waiveOffAmtMemoized =
    items.length > 0
      ? items.reduce((acc, item) => {
          acc += item.fine_amt;
          return acc;
        }, 0)
      : 0;
  useEffect(() => {
    if (items.length > 0 && state.studentId) {
      setTotalFineAmt(waiveOffAmtMemoized);
    }
  }, [waiveOffAmtMemoized, items, state.studentId]);

  return (
    <>
      <Title>Pay Fee</Title>
      <div className="std-login-fee__pay-online">
        <div className="row g-0 justify-content-center">
          <div className="col-6 std-login-fee__pay-online--cards">
            <div className="std-login-fee__pay-online--grids">
              <span>
                <img src={FeeBalance} alt="" />
                Fee Balance
              </span>
              <b className="font-red">₹ {format(totals.totalbalance)}</b>
            </div>
            <div
              className="std-login-fee__pay-online--grids"
              style={payableAmountDivStyle}
            >
              <span>
                <img src={PayableAmount} alt="" />
                Payable Amount
              </span>
              <b className="font-green">
                ₹
                {items.filter(({ isChecked }) => isChecked).length > 0
                  ? format(payableAmount)!
                  : 0}
              </b>
            </div>
          </div>
        </div>
        <div className="std-login-fee__pay-online--tableblock">
          <TableContainer className="std-login-fee__pay-online--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      onChange={handleSelectAll}
                      checked={
                        items.filter(({ isChecked }) => isChecked).length ===
                        items.length
                          ? true
                          : false
                      }
                    />
                    &nbsp;Sl
                  </TableCell>
                  {Accounts_Table.OnlineFeePayMentInDashboard.map(
                    (th: tableProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((data, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className="std-login-fee__pay-online--table--amount"
                        id="td-center"
                      >
                        <Checkbox
                          onChange={() => handleCheckBasedOnIndex(index)}
                          checked={data.isChecked ? true : false}
                        />
                        &nbsp;
                        {index + 1}
                      </TableCell>
                      <TableCell>{data.acct_ldgr_details.ldgr_desc}</TableCell>
                      <TableCell
                        id="td-right"
                        className="std-login-fee__pay-online--table--amount"
                      >
                        {format(data.fee_ob)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="std-login-fee__pay-online--table--amount"
                      >
                        {format(data.fee_demand)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="std-login-fee__pay-online--table--amount"
                      >
                        {format(data.fee_concession)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="std-login-fee__pay-online--table--amount"
                      >
                        {format(data.fee_received)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="std-login-fee__pay-online--table--amount"
                      >
                        {format(data.fee_bal)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={6} className="total">
                    Academic Fee Total Balance :
                  </TableCell>
                  <TableCell className="balance-count" id="td-right">
                    {format(totals.totalbalance)}{" "}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {enableFeeFine && (
                  <TableRow>
                    <TableCell colSpan={6} className="total">
                      Fine :
                    </TableCell>
                    <TableCell className="balance-count" id="td-right">
                      {format(enableFeeFine ? totalFineAmt : 0)}
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Total :
                  </TableCell>
                  <TableCell className="totalcount">
                    {format(totals.totalOutStandingBalance)}
                  </TableCell>
                  <TableCell className="totalcount">
                    {format(totals.totalDemandAmount)}
                  </TableCell>
                  <TableCell className="totalcount">
                    {format(totals.totalconcession)}
                  </TableCell>
                  <TableCell className="totalcount">
                    {format(totals.totalpaidAmount)}
                  </TableCell>
                  {enableFeeFine ? (
                    <TableCell id="td-right" className="totalcount">
                      {format(
                        totals.totalbalance + (enableFeeFine ? totalFineAmt : 0)
                      )}
                    </TableCell>
                  ) : (
                    <TableCell id="td-right" className="balance-count">
                      {format(totals.totalbalance)}
                    </TableCell>
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        {enablePaymentGateway && state.claims?.STUDENT ? (
          <div>{component}</div>
        ) : null}{" "}
      </div>
    </>
  );
};

export default PayByDemand;
