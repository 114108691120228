import React, { useContext, useEffect, useState } from "react";
import Input from "../../../../stories/Input/Input";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DataGridTableStyles";
import {
  DataGridPro,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { Direction, SortBy, StudentReportType, TableHeaders } from "../../../../utils/Enum.types";
import EyeWhite from "../../../../images/EyeWhite.svg";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import useAcdStudentsData, { AcdStudentsvars, GetStudentAcdData, StudentEdges } from "../../hooks/useAcdStudentsData";
import { GetAcdStudents } from "../../queries/students/Index";
import useInstConfigByEntryId from "../../hooks/useInstConfigByEntryId";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";
import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";
import useToken from "../../../../customhooks/useToken";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { AppContext } from "../../../../context/context";
import { StudentAcdType } from "../../../../utils/studentAcdEnum.types";

const data = [
  {
    admno: 1,
    name: "kavya",
    combination: "Combination 1",
  },
  {
    admno: 2,
    name: "keerthi",
    combination: "Combination 2",
  },
];

const StudentList = () => {
    const navigate=useNavigate();
  const classes = useDataGridStyles();
  const {InstId,entryId}=useParams();
  const {token}=useToken();
  const { entry_level } = useInstitutionConfiguration();
const {state}=useContext(AppContext);
  const [searchData, setSearchData] = useState("");
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "adm_no",
      headerName: TableHeaders.ADMISSION_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "reg_no",
      headerName: TableHeaders.REGISTER_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },

    {
      field: "std_name",
      headerName: TableHeaders.STUDENT_NAME,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-name td-onClick",
      flex:1

    },

    {
      field: "choosen_comb",
      headerName: "Choosen Combination",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-desc",

    },

    {
      field: "action",
      headerName: TableHeaders.ACTION,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-actions-view",
      headerClassName:"td-actions-view",
    align:"center",
      renderCell: (params) => {
        return (
          <button className="combinations-std-list__tableblock--view" onClick={()=>navigate(`/${InstId}/academics/dailyactivities/:entryId/studentlist/view`)}>
            View  <img src={EyeWhite} alt="" />
          </button>
        );
      },
    },
  ];


  const [GetStudentsData, { data, loading, fetchMore }] = useLazyQuery<
    GetStudentAcdData,
    AcdStudentsvars
  >(GetAcdStudents);
  useEffect(() => {
    if (token && entry_level) {
      GetStudentsData({
        variables: {
          after: null,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          first: ROWS_PER_PAGE,
          name: searchData,
          token,
          orderBy: [
            {
              direction: Direction.ASC,
              field: SortBy.STD_ROLL_NO,
            },
          ],
          input: {
            ids: [Number(InstId), Number(entryId), 0],
            acd_std_query_type:
            StudentAcdType.ACD_STDS_BY_ENTRY_LEVEL,
            str_data: [entry_level],
          },
        },
      });
    }
  }, [
    token,
    GetStudentsData,
    InstId,
    entryId,
    state.ActiveAcdYr,
    searchData,
    entry_level,
  ]);

    useEffect(() => {
      if (data && !loading ) {
        const newData = data.GetAcdStudents.edges;
  
        if (endCursor) {
          const updatedNewData = newData.map((newStudent) => {
            const filteredStudent = students.find(
              (student) => student.node.id === newStudent.node.id
            );
            if (filteredStudent) {
              return {
                ...newStudent,
                node: {
                  ...newStudent.node,
                },
              };
            }
            return newStudent;
          });
          setStudents(updatedNewData);
          setRows(
            updatedNewData.map(({ node }, index) => ({
              id: index + 1,
              std_adm_no: node.std_adm_no,
              std_reg_no: node.std_reg_no,
              std_name: node.first_name,
              std_father: node.std_father_name,
              std_mobile: node.std_mobile,
              std_status: node.std_status,
              std_id: node.id,
            }))
          );
        } else {
          setStudents(newData);
          setRows(
            newData.map(({ node }, index) => ({
              id: index + 1,
              std_adm_no: node.std_adm_no,
              std_reg_no: node.std_reg_no,
              std_name: node.first_name,
              std_father: node.std_father_name,
              std_mobile: node.std_mobile,
              std_status: node.std_status,
              std_id: node.id,
            }))
          );
        }
        setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
      } // eslint-disable-next-line
    }, [data, loading]);
  
    useEffect(() => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
  
                const newEdges = fetchMoreResult.GetAcdStudents.edges;
                const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);
  
                const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );
  
                if (duplicateCheck.length > 0) return prevResult;
  
                return {
                  GetAcdStudents: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);
  
      return () => {
        if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
      };
    }, [rows]);
    
  return (
    <>
      <div className="combinations-std-list">
        <div className="row g-0 combinations-std-list__select">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>
        </div>
        <div className={`combinations-std-list__tableblock ${classes.root}`}>
          <DataGridPro
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            hideFooter
            // initialState={{pinnedColumns:{right:['action']}}}
            slotProps={{
              columnsPanel: { disableHideAllButton: true },
            }}
          />
        </div>
        <Button mode="back" onClick={()=>navigate(-1)}/>
      </div>
    </>
  );
};

export default StudentList;
