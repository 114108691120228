import { gql } from "@apollo/client";

//Student Details By Ids

export const GetAcctStdDemandDetails = gql`
  query GetAcctStdDemandDetails(
    $token: String!
    $query_type: AcctStdDemandDetailsQueryType!
    $student_id: ID!
    $is_capitation_fee: Boolean!
    $voucher_book_type: String!
    $fin_yr_id: ID!
  ) {
    GetAcctStdDemandDetails(
      token: $token
      query_type: $query_type
      student_id: $student_id
      is_capitation_fee: $is_capitation_fee
      voucher_book_type: $voucher_book_type
      fin_yr_id: $fin_yr_id
    ) {
      id
      fee_ob
      fee_demand
      fee_concession
      fee_receivable
      fee_received
      fee_refunds
      fee_bal
      fee_due_date
      acct_ldgr_id
      student_id
      acct_ldgr_details {
        id
        ldgr_desc
        ldgr_std_fee_is_deposit_ldgr
        voucher_book_details {
          id
          vo_book_desc
          vo_book_key
          voucher_sub_type
        }
      }
    }
  }
`;

export const GetStdCompleteFeeDetailsByStudentID = gql`
  query GetStdCompleteFeeDetailsByStudentID(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $student_id: ID!
  ) {
    GetStdCompleteFeeDetailsByStudentID(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      student_id: $student_id
    ) {
      std_demand_receipts {
        id
        acct_ldgr_id_cr
        acct_ldgr_id_db
        v_date
        v_std_amt_fine
        v_std_deposit
        v_std_scholarship_deposit
        v_std_demand_receipt
        v_std_amt_receipt
        v_std_amt_total
        v_std_amt_deposit
        v_no
        v_std_deposit_adjusted
        v_transcation_no
        v_transcation_type
      }
      std_demand_details {
        acct_ldgr_id
        fee_ob
        fee_demand
        fee_concession
        fee_receivable
        fee_received
        fee_bal
        fee_refunds
        acct_ldgr_details {
          id
          ldgr_desc
        }
      }
      std_refunds_recepits {
        id
        v_std_refund
        v_date
        v_std_amt_refunded
        v_no
        v_transcation_type
      }
      std_demand {
        std_demand_ob
        std_demand_amt
        std_demand_concession
        std_demand_receivable
        std_demand_received
        std_demand_refunds
        std_demand_bal
        std_deposit_ob
        std_deposit_amt
        std_deposit_total
        std_deposit_adjusted
        std_deposit_refunded
        std_deposit_bal
        std_non_demand_collected
      }
      std_demand_consolidate {
        consol_demand_ob
        consol_demand_amt
        consol_demand_concession
        consol_demand_receivable
        consol_demand_received
        consol_demand_refunds
        consol_demand_bal
        consol_deposit_ob
        consol_deposit_amt
        consol_deposit_total
        consol_deposit_adjusted
        consol_deposit_refunded
        consol_deposit_bal
        consol_non_demand_collected
      }
      std_demand_by_fin_yr {
        id
        std_demand_ob
        std_demand_amt
        std_demand_concession
        std_demand_receivable
        std_demand_received
        std_demand_refunds
        std_demand_bal
        std_deposit_ob
        std_deposit_amt
        std_deposit_total
        std_deposit_adjusted
        std_deposit_refunded
        std_deposit_bal
      }
    }
  }
`;
export const GetAcctDemandMainStudentDemandByStudentId = gql`
  query GetAcctDemandMainStudentDemandByStudentId(
    $token: String!
    $student_id: ID!
  ) {
    GetAcctDemandMainStudentDemandByStudentId(
      token: $token
      student_id: $student_id
    ) {
      id
      first_name
      middle_name
      last_name
      std_adm_no
      std_fresher
      std_curr_acd_yr
      cat_desc
      class_desc
      total_demand_new_std
      total_demand_exs_std
      total_demand_all_std
      acct_demand_main_id
    }
  }
`;

export const GetStudentsPassoutByInstId = gql`
  query GetStudentsPassoutByInstId(
    $name: String
    $first: Int
    $after: Cursor
    $orderBy: MstStudentPassoutOrderField
    $direction: OrderDirection!
    $token: String!
    $inst_id: ID!
    $branch: Int
    $class: Int
  ) {
    GetStudentsPassoutByInstId(
      token: $token
      inst_id: $inst_id
      after: $after
      first: $first
      orderBy: { direction: $direction, field: $orderBy }
      where: {
        or: [{ branchID: $branch, stdNameContainsFold: $name, classID: $class }]
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          std_name
          std_passout_yr
          std_mobile
          std_email
          inst_id
          branch_id
          class_id
          class_details {
            class_desc
            id
          }
          branch_details {
            branch_desc
            id
          }
        }
      }
    }
  }
`;
export const GetStudentsPassoutByStudentId = gql`
  query nodequery($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MstStudentPassout {
        id
        std_name
        std_passout_yr
        std_mobile
        std_email
        inst_id
        branch_id
        class_id
        class_details {
          class_desc
          id
        }
        branch_details {
          branch_desc
          id
        }
      }
    }
  }
`;
export const GetStudentNEActiveByStudentId = gql`
  query GetStudentNEActiveByStudentId($token: String!, $studentId: ID!) {
    GetStudentNEActiveByStudentId(token: $token, student_id: $studentId) {
      id
      std_still_ne
      std_ne_type
      std_ne_reason
      std_ne_date
      std_ne_acd_year
      std_ne_made_by_staff_id
    }
  }
`;
export const GetStudentDocsByStudentId = gql`
  query GetStudentDocsByStudentId($token: String!, $student_id: ID!) {
    GetStudentDocsByStudentId(token: $token, student_id: $student_id) {
      id
      std_doc_type
      std_doc_filename
      std_doc_download
      std_doc_collected
      std_doc_original
      date_of_collection
      date_of_return
      is_doc_returned
      std_doc_remarks
      student_id
      inst_id
    }
  }
`;
export const GetEmpDocs = gql`
  query GetEmpDocs($token: String!, $pr_emp_id: ID!) {
    GetEmpDocs(token: $token, pr_emp_id: $pr_emp_id) {
      id
      emp_doc_type
      emp_doc_filename
      emp_doc_download
      emp_doc_collected
      emp_doc_original
      date_of_collection
      date_of_return
      is_doc_returned
      pr_emp_id
      inst_id
    }
  }
`;

export const GetStudentsNeByInstId = gql`
  query GetStudentsNeByInstId(
    $name: String
    $first: Int
    $after: Cursor
    $query_type: StdNeQueryType!
    $orderBy: MstStudentNeOrderField
    $direction: OrderDirection!
    $token: String!
    $inst_id: ID!
  ) {
    GetStudentsNeByInstId(
      token: $token
      inst_id: $inst_id
      after: $after
      first: $first
      query_type: $query_type
      orderBy: { direction: $direction, field: $orderBy }
      where: {
        or: [
          { stdNeTypeContainsFold: $name }
          {
            hasMstStudentWith: {
              or: [
                { firstNameContainsFold: $name }
                { middleNameContainsFold: $name }
                { lastNameContainsFold: $name }
                { stdAdmNoContainsFold: $name }
              ]
            }
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          std_still_ne
          std_ne_type
          std_ne_reason
          std_ne_date
          std_ne_acd_year
          std_ne_cancel_reason
          std_ne_cancel_date
          std_ne_cancel_acd_year
          std_ne_made_by_staff_id
          std_ne_cancel_made_by_staff_id
          student_id
          inst_id
          mst_student {
            first_name
            middle_name
            last_name
            std_adm_no
            std_reg_no
            dept {
              dept_desc
            }
            branch {
              branch_desc
            }
            class {
              class_desc
            }
          }
        }
        cursor
      }
    }
  }
`;

export const StudentPassoutById = gql`
  query StudentPassoutById($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MstStudentPassout {
        id
        std_name
        std_passout_yr
        std_mobile
        std_email
        inst_id
        branch_id
        class_id
        branch_details {
          branch_desc
        }
        class_details {
          class_desc
        }
      }
    }
  }
`;
