import React, { useEffect, useState } from "react";
import Home from "../Home/Index";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";

import { responseType } from "../../../utils/Form.types";
import { Title } from "../../../stories/Title/Title";
import useDropdownData from "../../../customhooks/useDropdownData";
import { useLazyQuery } from "@apollo/client";
import { ClassesByBranchIds } from "../../../queries/institution/classes/list/byid";
import useToken from "../../../customhooks/useToken";
import { ClassByInstId } from "../../../queries/institution/classes/list/byid";
import {
  ClassListDataByBranchId,
  ClassListDataByInstId,
  ClassListVarsBranchId,
  DepartmentListVarsByInstId,
} from "../../../Types/Student";
import { useNavigate, useParams } from "react-router-dom";
import { Keys } from "../../../utils/Enum.keys";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { Button } from "../../../stories/Button/Button";
import { TableHeaderProps } from "../../../Types/Tables";
import useMasterTableJson from "../../../json/useMasterTableJson";
import useInstLabels from "../../../customhooks/general/useInstLabels";

const Index = () => {
  const navigate = useNavigate();
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { InstId } = useParams();
  const { token } = useToken();
  const { Masters_Table } = useMasterTableJson();

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);

  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const { branchDropDown, departmentDropDown } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    0,
    0
  );
  const [GetClassesByInstId, { data: classesByInstId }] = useLazyQuery<
    ClassListDataByInstId,
    DepartmentListVarsByInstId
  >(ClassByInstId);
  const [GetClassData, { data: ClassesByBranchId }] = useLazyQuery<
    ClassListDataByBranchId,
    ClassListVarsBranchId
  >(ClassesByBranchIds);
  const { USE_DEPARTMENT_KEY } = useInstitutionConfiguration();

  //Students Class Wise Count
  // eslint-disable-next-line
  let totalAdmittedStudents = 0;
  let totalReservedStudents = 0;

  if (branchSelected?.value) {
    // eslint-disable-next-line
    ClassesByBranchId?.GetClassesByBranchIds.map((data) => {
      if (data && data.class_total_adm_count !== null) {
        totalAdmittedStudents += data.class_total_adm_count;
      }
      if (data && data.class_total_Res_adm_count !== null) {
        totalReservedStudents += data.class_total_Res_adm_count;
      }
    });
  } else {
    // eslint-disable-next-line
    classesByInstId?.GetClassesByInstId.map((data) => {
      if (data && data.class_total_adm_count !== null) {
        totalAdmittedStudents += data.class_total_adm_count;
      }
      if (data && data.class_total_Res_adm_count !== null) {
        totalReservedStudents += data.class_total_Res_adm_count;
      }
    });
  }

  useEffect(() => {
    if (token) {
      GetClassesByInstId({
        variables: {
          inst_id: InstId!,
          token,
        },
      });
    }
  }, [token, GetClassesByInstId, InstId]);
  const { branchLabel, departmentLabel, classLabel } = useInstLabels();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Student {classLabel} Wise Admission Count</Title>
      <div className="class-wise-std-count">
        <div className="row g-0 class-wise-std-count__select">
          {USE_DEPARTMENT_KEY && (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={departmentDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue);
                  } else {
                    setDepartmentSelected(null);
                    setBranchSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          )}
          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={branchDropDown}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(null);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue);
                  GetClassData({
                    variables: {
                      branch_ids: [newValue.value],
                      token,
                    },
                  });
                } else {
                  setBranchSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={branchLabel}
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        </div>
        <div className="class-wise-std-count__tableblock">
          <TableContainer className="class-wise-std-count__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Masters_Table.Students.ClassWiseCount.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(branchSelected?.value
                  ? ClassesByBranchId?.GetClassesByBranchIds
                  : classesByInstId?.GetClassesByInstId?.filter(
                      (data) => data !== null
                    )
                )?.map((res, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        id="td-center"
                        className="class-wise-std-count__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>{res?.class_desc!}</TableCell>
                      <TableCell
                        id="td-right"
                        className="class-wise-std-count__table--total"
                      >
                        {res.class_total_adm_count}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="class-wise-std-count__table--total"
                      >
                        {res.class_total_Res_adm_count}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell className="total" colSpan={2}>
                    Total:
                  </TableCell>
                  <TableCell className="totalcount">
                    {totalAdmittedStudents}
                  </TableCell>
                  <TableCell className="totalcount">
                    {totalReservedStudents}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Index;
