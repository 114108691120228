import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
// import { Calendar } from "react-calendar";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import {
  DEFAULT_TIME,
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_RESPONSETYPE_OBJECT,
  TODAY_DATE,
  days,
} from "../../../../utils/constants";
import { optionsType, responseType } from "../../../../utils/Form.types";
import {
  getDateRangeByMonth,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import Modal from "react-modal";
import Home from "../../Home/Index";
import Delete from "../../../../images/Delete.svg";
import Add from "../../../../images/Add.svg";
import Close from "../../../../images/Close.svg";
import ArrowLeft from "../../../../images/CalenderPreviousButton.svg";
import ArrowRight from "../../../../images/CalenderNextButton.svg";

import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import {
  StudentModalStyles,
  EditModalCustomStyles,
} from "../../../../styles/ModalStyles";
import usePayRollAcademicYear, {
  PayRollAcademicYearList,
} from "../../hooks/usePayRollAcademicYears";
import usePayRollGeneralHolidays, {
  HolidayQueryType,
} from "../../hooks/usePayRollGeneralHolidays";
import { HolidayDate } from "../../../Academics/Reports/Attendance/ByStudentId";
import New from "./New";

import DeleteHolidayConfig from "./Delete";
import DateWiseHolidayEntry from "../../../Academics/calendar/HolidayEntry/DateWiseHolidayEntry";
import { LicenseTypes, Operation } from "../../../../utils/Enum.types";
import { ModalType } from "../../../Academics/calendar/HolidayEntry/Index";
import { colors } from "../../../Academics/calendar/HolidayEntry/CustomizedCalendar";
import CalendarLegends, {
  CalendarFor,
} from "../../../../pages/CalendarLegends";
export const holidayQueryTypes: optionsType[] = [
  { label: "Weekend Holidays", value: HolidayQueryType.WEEKEND_HOLIDAYS },
  {
    label: "General and Unexpected Holidays",
    value: HolidayQueryType.GENERAL_AND_UNEXPECTED_HOLIDAYS,
  },
  { label: "General Holidays", value: HolidayQueryType.GENERAL_HOLIDAYS },
  { label: "Unexpected Holidays", value: HolidayQueryType.UNEXPECTED_HOLIDAYS },
  { label: "Vacation Holidays", value: HolidayQueryType.VACATION_HOLIDAYS },
  { label: "All Holidays", value: HolidayQueryType.ALL_HOLIDAYS },
  { label: "Holiday by Date", value: HolidayQueryType.HOLIDAY_BY_DATE },
];
const Index = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();

  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState<Date>();
  const [acdYr, setAcdYr] = useState<responseType>(EMPTY_RESPONSETYPE_OBJECT);
  const [month, setMonth] = useState(-54);
  const [modal, setModal] = useState<ModalType>({
    date: new Date(DEFAULT_TIME),
    flag: false,
    operation: Operation.NONE,
  });
  const [addWeekendsModal, setAddWeekendsModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedHolidayQueryType, setSelectedHolidayQueryType] =
    useState<optionsType>(holidayQueryTypes[1]);

  const { payRollAcademicYearResponse } = usePayRollAcademicYear();

  const filteredPryearDetails: PayRollAcademicYearList =
    payRollAcademicYearResponse.data?.GetPayRollAcdYrsByInstId.find(
      ({ id }) => id === acdYr.value
    )!;

  const { endDate, startDate } = getDateRangeByMonth(
    new Date(TODAY_DATE).getFullYear(),
    month
  );

  const { PayRollGeneralHolidays } = usePayRollGeneralHolidays(
    acdYr.value,
    endDate.toString(),
    startDate.toString(),
    searchText,
    selectedHolidayQueryType.value,
    true
  );
  const [holidayDates, setHolidayDates] = useState<HolidayDate[]>([]);
  useEffect(() => {
    if (PayRollGeneralHolidays.data && !PayRollGeneralHolidays.loading) {
      setHolidayDates(
        PayRollGeneralHolidays.data.GetPayRollGeneralHolidays.edges.map(
          (edge) => ({
            date: new Date(edge.node.holiday_date),
            duration: edge.node.holiday_duration === "FD" ? "full day" : "",
          })
        )
      );
    }
  }, [PayRollGeneralHolidays.data, PayRollGeneralHolidays.loading]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Calendar</Title>
      <div className="holiday-entry">
        <div className="row g-0 holiday-entry__details">
          <div className="col-2">
            <Autocomplete
              classes={classes}
              value={acdYr}
              options={payRollAcademicYearResponse.responseType}
              onChange={(e, newValue) => {
                if (newValue) {
                  setAcdYr(newValue);
                } else {
                  setAcdYr(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  label="Academic Year"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-3">
            <Button mode="copy">Include Default Holidays</Button>
          </div>
        </div>
        <div className="row g-0 holiday-entry__data">
          <div className="col holiday-entry__data--left">
            {acdYr.value ? (
              // <Calendar
              //   defaultView="year"
              //   value={selectedDate}
              //   maxDate={new Date(filteredPryearDetails?.payroll_end_date!)}
              //   minDate={new Date(filteredPryearDetails?.payroll_st_date!)}
              //   onClickMonth={(e: Date) => setMonth(e.getMonth())}
              //   onClickDay={(e: Date) => {
              //     setSelectedDate(e);
              //     setModal({
              //       date: new Date(DEFAULT_TIME),
              //       flag: false,
              //       operation: Operation.NONE,
              //     });
              //   }}
              //   tileClassName={({ date }) =>
              //     getTileClassName(date, holidayDates)
              //   }
              // />
              <>
                <div className="holiday-entry__calendar--month">
                  <img src={ArrowLeft} alt="" />
                  <b>September</b>
                  <img src={ArrowRight} alt="" />
                </div>
                <ul className="holiday-entry__calendar--grid-container">
                  {days.map((day, index) => {
                    return (
                      <li key={index} className="holiday-entry__calendar--day">
                        {day}
                      </li>
                    );
                  })}
                  {Array.from({ length: 31 }).map((_, index) => (
                    // for weekends use className weekends-in-calendar ,for holidays use className holidays-in-calendar,use present-day-in-calendar for present day
                    <li
                      key={index}
                      className={
                        index === 2
                          ? "holiday-entry__calendar--date holidays-in-calendar"
                          : "holiday-entry__calendar--date"
                      }
                    >
                      {/* enable this span for vacations */}
                      {index === 4 ? (
                        <span className="vacations-in-calendar"></span>
                      ) : null}
                      {/* use this for events */}

                      {index + 1}
                      {index === 6 && (
                        <>
                          {Array.from({ length: 5 }).map((_, dotIndex) => {
                            return (
                              <span
                                className="events-in-calendar"
                                style={{
                                  backgroundColor:
                                    dotIndex >= colors.length
                                      ? "#0ea5e9"
                                      : colors[dotIndex],
                                }}
                                key={dotIndex}
                              />
                            );
                          })}
                        </>
                      )}
                    </li>
                  ))}

                  {/* <Calendar
                  onChange={(e: Date) => {
                    const rws = e.toISOString();
                    setholiday_date(toInputStandardDate(rws));
                    setHolidayFormModal({
                      flag: true,
                      operation: Operation.CREATE,
                    });
                  }}
                /> */}
                </ul>
              </>
            ) : null}
          </div>
          <div className="col holiday-entry__data--right">
            <Title variant="subtitle1">List of Holidays</Title>
            <div className="row g-0 holiday-entry__data--right--details">
              <div className="col-4">
                <Input
                  id="search"
                  placeholder="Holiday Name"
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <div className="col"></div>
              <div className="col">
                <Autocomplete
                  classes={classes}
                  options={holidayQueryTypes}
                  getOptionLabel={(option) => option.label}
                  value={selectedHolidayQueryType}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSelectedHolidayQueryType(newValue);
                    } else {
                      setSelectedHolidayQueryType(EMPTY_OPTIONSTYPE_OBJECT);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Holiday Query Type"
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            </div>
            <ul className="holiday-entry__data--holiday-list">
              {PayRollGeneralHolidays.data?.GetPayRollGeneralHolidays.edges.map(
                (edge, index) => {
                  return (
                    <li key={edge.node.id}>
                      <Label>{index + 1}</Label>
                      <div className="holiday-entry__data--holiday-list--events">
                        <div>
                          <span>{toStandardDate(edge.node.holiday_date)}</span>
                          <br />
                          <b>{edge.node.holiday_desc}</b>
                        </div>
                      </div>
                      <img src={Delete} alt="/" />
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              onClick={() => setAddWeekendsModal(!addWeekendsModal)}
              disabled={!acdYr.value}
            >
              <img src={Add} alt="/" /> Add Weekends
            </Button>
            <Button
              mode="delete"
              disabled={!acdYr.value}
              onClick={() => setDeleteModal(!deleteModal)}
            >
              Weekends
            </Button>
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col">
            <CalendarLegends useCalendarIn={CalendarFor.GENERAL} />
          </div>
        </div>
      </div>
      {/* Add-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addWeekendsModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <New
              setModalFlag={setAddWeekendsModal}
              acd_yr={filteredPryearDetails}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddWeekendsModal(!addWeekendsModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={deleteModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <DeleteHolidayConfig
              setModalFlag={setDeleteModal}
              acd_yr={filteredPryearDetails}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDeleteModal(!deleteModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={modal.flag}
        ariaHideApp={false}
        style={EditModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <DateWiseHolidayEntry
              date={selectedDate!}
              setInsertModal={setModal}
              moduleType={LicenseTypes.PAYROLL}
              prAcdYearId={filteredPryearDetails}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() =>
                setModal({
                  date: new Date(DEFAULT_TIME),
                  flag: false,
                  operation: Operation.NONE,
                })
              }
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
