import { TEXT_FONT_FAMILY } from "./DataGridTableStyles";

export const EditModalCustomStyles = {
  content: {
    margin: "auto",
    width: "40%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const LoadingStyles = {
  content: {
    margin: "auto",
    width: "fit-content",
    height: "fit-content",
    borderRadius: "10px",
    padding: "0px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const AssignRouteModalStyles = {
  content: {
    margin: "auto",
    width: "30%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const WaiveOffModalStyles = {
  content: {
    margin: "auto",
    width: "25%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    zIndex: 1300,
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1300,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const PicPreviewModalStyles = {
  content: {
    margin: "auto",
    width: "450px",
    height: "450px",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    zIndex: 1300,
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1300,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const dataPostingModalStyles = {
  content: {
    margin: "auto",
    width: "25%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: "1000 !important",
    overflow: "hidden",
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const setDurationModalStyles = {
  content: {
    margin: "auto",
    width: "15%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const QRCodeGeneratorConfig = {
  content: {
    margin: "auto",
    width: "40%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const PrintQRCodeModalStyles = {
  content: {
    margin: "auto",
    width: "50%",
    height: "75%",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const PredefinedDataStyles = {
  content: {
    margin: "auto",
    width: "30%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const SuccessModalCustomStyles = {
  content: {
    margin: "auto",
    width: "fit-content",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const StudentModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginTop: 50,
    marginLeft: 50,
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const PaymentDueModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const ConsolidatedPayModalStyles = {
  content: {
    margin: "auto",
    width: "25%",
    height: "50%",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const SectionAllocationModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginTop: 75,
    marginLeft: 50,
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1300,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const PrintModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginTop: 10,
    marginLeft: 50,
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1400,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const PrintConfigModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    padding: "10px 20px 10px 20px",
    height: "80%",
    width: "80%",
    top: "10%",
    left: "10%",
    right: "auto",
    bottom: "auto",
  },
  overlay: {
    zIndex: 2000,
    backdropFilter: "blur(0px)",
    backgroundColor: "none !important",
    margin: "auto !important",
  },
};
export const CheckerRequestsStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    padding: "10px 20px 10px 20px",
    height: "70%",
    width: "85%",
    marginTop: "80px",
    top: "10%",
    left: "10%",
    right: "auto",
    bottom: "auto",
  },
  overlay: {
    zIndex: 1000,
    backdropFilter: "blur(1px)",
    backgroundColor: "none !important",
    margin: "auto !important",
  },
};
export const PickSubjectModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    padding: "10px 20px 10px 20px",
    marginTop: " 125px",
    marginLeft: "auto",
    marginRight: "auto",
    /* margin: auto; */
    height: "65%",
    width: " 70%",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(0px)",
  },
};
export const ViewPickedSubjectModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    padding: "10px 20px 10px 20px",
    marginTop: " 100px",
    marginLeft: "auto",
    marginRight: "auto",
    height: "70%",
    width: "40%",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(0px)",
  },
};
export const AttendanceModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginTop: 75,
    margin: "auto",
    width: "40%",
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const FeeModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    margin: "auto",
    width: "40%",
    height: "fit-content",
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    background: "none",
    zIndex: 1000,
  },
};
export const AccountingLedgerModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    margin: "auto",
    width: "50%",
    height: "80%",
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    background: "none",
    zIndex: 1000,
  },
};
export const ViewDetails = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    margin: "auto",
    width: "50%",
    height: "80%",
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    background: "none",
    zIndex: 1000,
  },
};
export const UserRightsModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    margin: "auto",
    width: "25%",
    height: "fit-content",
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    background: "none",
    zIndex: 1000,
  },
};
export const MultipleUserRightsModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    margin: "auto",
    width: "35%",
    height: "70%",
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    background: "none",
    zIndex: 1000,
  },
};
export const columnStyles = {
  content: {
    borderRadius: "10px",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginLeft: "calc(100% - 280px)",
    width: "220px",
    height: "calc(100% - 270px)",
    marginTop: "190px",
    fontFamily: TEXT_FONT_FAMILY,
    padding: "5px 10px",
  },
  overlay: {
    background: "none",
    zIndex: 1400,
  },
};

export const ModalCustomStyles = {
  content: {
    margin: "auto",
    marginTop: 75,
    width: "80%",
    height: "70%",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const InstitutionCustomStyles = {
  content: {
    margin: "auto",
    width: "40%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const FinancialYearModalStyles = {
  content: {
    margin: "auto",
    width: "35%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const AcademicYearModalStyles = {
  content: {
    margin: "auto",
    width: "35%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const MarksEntryModal = {
  content: {
    width: "63%",
    height: "fit-content",
    borderRadius: "10px",
    margin: "240px -20px auto auto",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "none",
  },
};

export const ErrorModalStyles = {
  content: {
    margin: "auto",
    width: "25%",
    height: "30%",
    borderRadius: "10px",
    background: "rgb(255,255,255)",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1300,
    background: "none",
    backdropFilter: "blur(1px)",
  },
};
export const BookDetailsStyles = {
  content: {
    margin: "auto",
    width: "50%",
    height: "fit-content",
    borderRadius: "10px",
    background: "rgb(255,255,255)",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "none",
    backdropFilter: "blur(1px)",
  },
};
export const LoadingModalStyles = {
  content: {
    margin: "auto",
    width: "30%",
    height: "fit-content",
    boxshadow: "none",
    border: "none",
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1000,
    backdropFilter: "blur(1px)",
  },
};

export const SkinsModalStyles = {
  content: {
    left: "90%",
    margintop: "40px",
  },
  overlay: { zIndex: 1000 },
};

export const ConfigurationsModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginTop: "75px",
    marginLeft: "50px",
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const FilterColumnCustomStyles = {
  content: {
    borderRadius: "10px",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginLeft: "75%",
    width: "20%",
    height: "fit-content",
    marginTop: "200px",
    padding: "10px",
    fontFamily: TEXT_FONT_FAMILY,
  },
  overlay: {
    background: "none",
    zIndex: 1000,
  },
};

export const ImageModalStyles = {
  content: {
    border: "none",
    background: "none",
    marginLeft: "20%",
    width: "20%",
    height: "20%",
    padding: "10px",
    marginTop: "150px",
  },
  overlay: {
    background: "none",
    zIndex: 1000,
  },
};

export const LibraryRepotsModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginTop: 75,
    marginLeft: 50,
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const LogOutModalStyles = {
  content: {
    borderRadius: "15px",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginLeft: "76.5%",
    width: "20%",
    height: "fit-content",
    marginTop: "10px",
    padding: "10px",
  },
  overlay: {
    background: "none",
    backdropFilter: "blur(0px)",
    zIndex: 1000,
  },
};

export const AcademicsReportsMenuStyles = {
  content: {
    borderRadius: "5px",
    filter: "drop-shadow( 0px 15px 30px rgb(0 0 0 / 30%)",
    marginLeft: "40px",
    width: "299px",
    height: "fit-content",
    marginTop: 142,
    padding: "5px",
  },
  overlay: {
    background: "none",
    backdropFilter: "blur(0px)",
    zIndex: 1000,
  },
};

export const ParentModalStyles = {
  content: {
    margin: "auto",
    width: "93%",
    marginLeft: "40px",
    padding: "10px 20px 10px 20px",
    marginTop: "270px",
    height: "fit-content",
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "none",
    backdropFilter: "blur(0px)",
  },
};

export const AboutChannelModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    margin: "135px auto auto auto ",
    width: "40%",
    height: "65%",
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1000,
    background: "none",
    backdropFilter: "blur(0px)",
  },
};

export const DamagedBooksCustomStyles = {
  content: {
    margin: "auto",
    width: "40%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const ViewBookDetailsModalStyles = {
  content: {
    margin: "auto",
    width: "60%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const DeleteLedgerModalStyles = {
  content: {
    margin: "auto",
    width: "65%",
    height: "75%",
    borderRadius: "10px",
    marginTop: "75px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const AddLedgerModalStyles = {
  content: {
    margin: "auto",
    width: "40%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const DepositModalCustomStyles = {
  content: {
    margin: "auto",
    width: "30%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const ReConciledOnModalStyles = {
  content: {
    margin: "auto",
    width: "30%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "none",
    backdropFilter: "blur(0px)",
  },
};

export const ParentStudentDashboardStyles = {
  content: {
    marginRight: 280,
    marginLeft: "auto",
    marginTop: 150,
    width: "30%",
    height: "60%",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "None",
  },
};

//Salary Structure ChooseLedger
export const ChooseLedgerModalStyles = {
  content: {
    margin: "auto",
    width: "45%",
    height: "60%",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const EditRuleStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    margin: "auto",
    width: "30%",
    height: "50%",
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const MultipleImagesPreivewStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    margin: "auto",
    width: "400px",
    height: "70%",
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const OrganizationalAddDepartment = {
  content: {
    margin: "auto 20px auto auto",
    width: "25%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1300,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(0px)",
  },
};
export const ApprovedStatusModal = {
  content: {
    margin: "150px auto auto auto",
    width: "35%",
    height: "60%",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1300,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(0px)",
  },
};
