import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import Avatar from "../../../../images/Avatar.svg";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdSubjectForEntryLevelVars,
  GetAcdTeacherSubjectClassAssociationsData,
} from "../../types/subject/Subject";
import { GetAcdTeacherSubjectClassAssociations } from "../../queries/subjects/List.tsx/Index";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import useToken from "../../../../customhooks/useToken";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { AppContext } from "../../../../context/context";
import useCheckAllocationType from "../../hooks/useCheckAllocationType";
import TableView from "../../../../images/TableView.svg";
import DataView from "../../../../images/DataView.svg";
import { useStyles } from "../../../../styles/TooltipStyles";
import Combinationwise from "./Combinationwise";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import StudentList from "./StudentList";
import { EMPTY_STRING } from "../../../../utils/constants";
const { Academics_Table } = require("../../json/table.json");
const Index = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { entryId, InstId } = useParams();
  const [tableViewActive, setTableViewActive] = useState(true);
  const tooltipClasses = useStyles();
  const [viewModal, setViewModal] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(0);
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { entry_level } = useInstitutionConfiguration();
  const { flag } = useCheckAllocationType(Number(entryId));

  const [GetSubjectsTeachersAssociated, { data: Tdata }] = useLazyQuery<
    GetAcdTeacherSubjectClassAssociationsData,
    GetAcdSubjectForEntryLevelVars
  >(GetAcdTeacherSubjectClassAssociations, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_id: Number(entryId)!,
      entry_level: entry_level,
      inst_id: InstId!,
      per_std_subj_allocation: flag,
      token,
    },
  });

  const classTeacherLabel =
    Tdata &&
    Tdata.GetAcdTeacherSubjectClassAssociations.map((data) =>
      data.Teachers.find((res) => res.is_class_teacher === true)
    ).filter((teacher) => teacher !== undefined);

  useEffect(() => {
    if (
      entryId &&
      entry_level &&
      token &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr &&
      flag !== undefined
    ) {
      GetSubjectsTeachersAssociated();
    }
  }, [
    entryId,
    GetSubjectsTeachersAssociated,
    token,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    entry_level,
    state.ActiveAcdYr,
    flag,
  ]);

  return (
    <>
      <div className="sub-details">
        <div className="sub-details__title">
          <Title>Subject Details</Title>
        </div>
        <div className="row g-0">
          <div className="col ">
            <div className="sub-details__classteacher">
              {classTeacherLabel?.length ? (
                <>
                  <div className="sub-details__classteacher--profile">
                    <div className="sub-details__classteacher--profile--image">
                      <img src={Avatar} alt="" />
                    </div>
                    <div className="sub-details__classteacher--profile--details">
                      <b>
                        {classTeacherLabel[0]?.emp_first_name +
                          " " +
                          classTeacherLabel[0]?.emp_middle_name +
                          " " +
                          classTeacherLabel[0]?.emp_last_name}
                      </b>
                      <span>{classTeacherLabel[0]?.emp_id}</span>
                    </div>
                  </div>
                  <span className="sub-details__classteacher--desc">
                    Class Teacher is managing the DailyActivities within the
                    classroom
                  </span>
                </>
              ) : (
                "No Class Teacher has been assigned for this particular Class"
              )}
            </div>
          </div>
          <div className="col-3 sub-details__images">
            {flag ? (
              <Tooltip
                title="Subject wise List"
                placement="top"
                classes={{
                  tooltip: tooltipClasses.customTooltipGrey,
                  arrow: tooltipClasses.customArrowGrey,
                }}
                arrow
              >
                <img
                  src={TableView}
                  className={tableViewActive ? "active-image" : ""}
                  alt=""
                  onClick={() => setTableViewActive(!tableViewActive)}
                />
              </Tooltip>
            ) : null}
            {flag ? (
              <Tooltip
                title="Combinationwise List"
                placement="top"
                classes={{
                  tooltip: tooltipClasses.customTooltipGrey,
                  arrow: tooltipClasses.customArrowGrey,
                }}
                arrow
              >
                <img
                  src={DataView}
                  alt=""
                  onClick={() => setTableViewActive(!tableViewActive)}
                  className={tableViewActive ? "active-image" : ""}
                />
              </Tooltip>
            ) : null}
          </div>
        </div>
        <div className="sub-details__tableblock">
          {tableViewActive ? (
            <>
              <TableContainer className="sub-details__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Academics_Table.DailyActivities.SubjectList.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index}>{th.labelName}</TableCell>
                          );
                        }
                      )}
                      {/* {flag ? (
                        <>
                          <TableCell>Student Count</TableCell>
                          <TableCell>Actions</TableCell>
                        </>
                      ) : null} */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Tdata &&
                      Tdata.GetAcdTeacherSubjectClassAssociations.map(
                        (data, index) => {
                          return (
                            <TableRow>
                              <TableCell
                                id="td-center"
                                className="sub-details__table--slno"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell className="sub-details__table--code">
                                {data.subj_code}
                              </TableCell>
                              <TableCell className="sub-details__table--code">
                                {data.subj_board_code}
                              </TableCell>
                              <TableCell>{data.subj_desc}</TableCell>
                              <TableCell className="sub-details__table--type">
                                {data.subj_is_core_subject
                                  ? "Core"
                                  : data.subj_is_elective
                                  ? "Elective"
                                  : data.subj_is_lab
                                  ? "Lab"
                                  : data.subj_is_lang
                                  ? "Language"
                                  : data.subj_is_non_academic
                                  ? "Non-Academics"
                                  : EMPTY_STRING}
                              </TableCell>
                              <TableCell className="sub-details__table--teacher">
                                {data.Teachers.length > 0 &&
                                  data.Teachers.map((teacher) => (
                                    <div className="sub-details__table--teacher--details">
                                      <img src={Avatar} alt="" />
                                      <div>
                                        <b>
                                          {teacher.emp_first_name +
                                            " " +
                                            teacher.emp_middle_name +
                                            " " +
                                            teacher.emp_last_name}
                                        </b>
                                        <span>{teacher.emp_id}</span>
                                      </div>
                                    </div>
                                  ))}
                              </TableCell>
                              {/* <TableCell id="td-center">0</TableCell>
                              <TableCell
                                className="sub-details__table--actions"
                                id="td-center"
                              >
                                <button
                                  className="combination-sub-list__grid--view"
                                  onClick={() => {
                                    setSelectedSubject(data.subj_master_id);
                                    setViewModal(!viewModal);
                                  }}
                                >
                                  View Students <img src={Eye} alt="" />
                                </button>
                              </TableCell> */}
                            </TableRow>
                          );
                        }
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>{flag ? <Combinationwise /> : null}</>
          )}
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal isOpen={viewModal} ariaHideApp={false} style={StudentModalStyles}>
        <StudentList
          setModalFlag={setViewModal}
          selectedSubjectId={selectedSubject}
        />
      </Modal>
    </>
  );
};

export default Index;
