import { TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/context";
import { useLazyQuery } from "@apollo/client";
import { GetAgentDetailsByNodeId } from "../../queries/Agents/List";
import { AgentNode } from "../List";
import { singleNodeVars } from "../../../../Types/Accounting";
import useToken from "../../../../customhooks/useToken";

import DataFetch from "../../../../images/Edit.svg";

import AgentOptionsAutocomplete from "./AgentOptionsAutocomplete";
import { EMPTY_STRING } from "../../../../utils/constants";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";

const AgentDetails = () => {
  const { token } = useToken();
  const { state } = useContext(AppContext);

  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();

  const [agentList, setAgentList] = useState(false);

  const [GetAgentDetails, { data }] = useLazyQuery<
    { node: AgentNode },
    singleNodeVars
  >(GetAgentDetailsByNodeId);

  useEffect(() => {
    if (token && state.agentId) {
      GetAgentDetails({
        variables: {
          id: state.agentId,
          token,
        },
      });
    }
  }, [state.agentId, GetAgentDetails, token]);

  return (
    <>
      <div className="agent-std-assoc__select row g-0">
        <div className="col">
          <div className="agent-std-assoc__select--flex">
            <AgentOptionsAutocomplete
              styles={{
                classes,
                textClasses,
              }}
              labelRequired
            />
            <img
              className="data-fetch-icon"
              src={DataFetch}
              alt="/"
              onClick={() => {
                setAgentList(!agentList);
              }}
            />
          </div>
          <TextField
            className="agent-std-assoc__select--textfield"
            disabled
            label="PAN no."
            value={
              data && state.agentId ? data.node.agent_pan_no : EMPTY_STRING
            }
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col">
          <TextField
            className="agent-std-assoc__select--textfield"
            disabled
            label="Mobile"
            value={
              data && state.agentId ? data.node.agent_mobile : EMPTY_STRING
            }
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            className="agent-std-assoc__select--textfield"
            disabled
            label="Aadhar No."
            value={
              data && state.agentId ? data.node.agent_aadhar_no : EMPTY_STRING
            }
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col">
          <TextField
            className="agent-std-assoc__select--textfield"
            disabled
            label="Email"
            value={data && state.agentId ? data.node.agent_email : EMPTY_STRING}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
    </>
  );
};

export default AgentDetails;
