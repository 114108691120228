import React, { useContext, useEffect, useState } from "react";
import Home from "../../../../Master/Student/Dashboard/Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import {
  Drawer,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Compare from "../../../../../images/Compare.svg";
import Avatar from "../../../../../images/Avatar.svg";
import { Button } from "../../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Edit from "../../../../../images/EditProfile.svg";
import { attendanceOverViewStyles } from "../../../../../styles/DrawerStyles";
import Close from "../../../../../images/Close.svg";
import { Label } from "../../../../../stories/Label/Label";
import TextArea from "../../../../../stories/TextArea/TextArea";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetAcdFeedbackStdRatingData,
  GetAcdFeedbackStdRatingVars,
} from "../../../../Academics/types/subject/Subject";
import {
  GetAcdFeedbackFormDetails,
  GetAcdFeedbackStdRating,
  GetFormDataByFormId,
} from "../../../../Academics/queries/general";
import { AppContext } from "../../../../../context/context";
import useToken from "../../../../../customhooks/useToken";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import {
  InstitutionConfigurationTypes,
  Operation,
} from "../../../../../utils/Enum.types";
import { EMPTY_STRING, TODAY_DATE } from "../../../../../utils/constants";
import { UpdateAcdFeedbackStdRating } from "../../../../Academics/DailyDiary/queries/mutations";
import { msgType } from "../../../../../utils/Form.types";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import { EmpData } from "../../../../HR/hooks/useEmployeeAttsByMonth";
import { CommonNodeVars } from "../../../../HR/Types/masterDataTypes";
import { PayRollEmpMasterNodeForLogin } from "../../../../HR/queries/employee/query";
import {
  toInputStandardDate,
  toIsoDate,
} from "../../../../../utils/UtilFunctions";
import useAcdSubjectDetails from "../../../../Academics/hooks/useAcdSubjectDetails";
import LoadingModal from "../../../../../pages/LoadingModal";
import useCheckAllocationType from "../../../../Academics/hooks/useCheckAllocationType";

const PreviewForm = () => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { formName, subjectId, InstId, teacherId, formId } = useParams();
  const { allocationId, studentEntryId } = useStudentDatabyId();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const { data: subjectData } = useAcdSubjectDetails(Number(subjectId));

  const { flag } = useCheckAllocationType(studentEntryId);

  const [GetEmpDetailsForLogin, { data: teacherData }] = useLazyQuery<
    EmpData,
    CommonNodeVars
  >(PayRollEmpMasterNodeForLogin, {
    variables: {
      id: Number(teacherId),
      token,
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (teacherId && token) {
      GetEmpDetailsForLogin();
    }
  }, [teacherId, GetEmpDetailsForLogin, token]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [GetFormDataById, { data: formData }] = useLazyQuery(
    GetFormDataByFormId,
    {
      variables: {
        id: Number(formId),
        token,
      },
      fetchPolicy: "network-only",
    }
  );
  useEffect(() => {
    if (formId && token) {
      GetFormDataById();
    }
  }, [formId, GetFormDataById, token]);

  const subjectAllocationLevel =
    configData && configData.data?.GetSwConfigVariables[0].config_string_value;
  const navigate = useNavigate();
  const [drawerStyle, setDrawerStyle] = useState(false);
  const [formDetailId, setFormDetailId] = useState(0);
  const [UpdateFeedBack, { loading: updateLoading }] = useMutation(
    UpdateAcdFeedbackStdRating,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const drawerClasses = attendanceOverViewStyles();
  const [GetAcdFeedbackAnswer, { data: answerData }] = useLazyQuery<
    GetAcdFeedbackStdRatingData,
    GetAcdFeedbackStdRatingVars
  >(GetAcdFeedbackStdRating);
  const [formDetails, setFormDetails] = useState({
    idx: 0,
    id: 0,
  });
  const [ratings, setRatings] = useState<{ [key: number]: number }>({});

  const [remarks, setRemarks] = useState<string>("");
  const { user_details } = useLoggedInUserDetails();

  useEffect(() => {
    if (
      teacherId &&
      formId &&
      subjectAllocationLevel &&
      allocationId &&
      token
    ) {
      GetAcdFeedbackAnswer({
        variables: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          inst_id: InstId!,
          token,
          allotted_level: subjectAllocationLevel!,
          allotted_id: allocationId!,
          query_type: {
            pr_emp_id: Number(teacherId),
            student_id: state.studentId,
            subj_master_id: Number(subjectId),
            idx: 0,
            form_name_id: Number(formId),
          },
          perStdSubjAllocation: flag!,
        },
      });
    }
  }, [
    GetAcdFeedbackAnswer,
    teacherId,
    formId,
    subjectAllocationLevel,
    allocationId,
    token,
    state.studentId,
    InstId,
    state.ActiveAcdYr,
    subjectId,
    flag,
  ]);
  const handleRemarksChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setRemarks(event.target.value);
  };
  const ratingValues: { [key: string]: number } = {
    E: 5,
    G: 4,
    A: 3,
    F: 2,
    P: 1,
  };
  const handleRatingChange = (idx: number, value: number) => {
    setRatings({ ...ratings, [idx]: value });
  };
  const handleUpdateAndNext = async () => {
    UpdateFeedBack({
      variables: {
        token,
        inst_id: InstId!,
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        user_details,
        rating: ratings[formDetails.idx],
        remarks,
        std_rating_id: formDetails.id,
        todays_date: toIsoDate(TODAY_DATE),
      },
      refetchQueries: [
        {
          query: GetAcdFeedbackStdRating,
          variables: {
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            inst_id: InstId!,
            token,
            allotted_level: subjectAllocationLevel!,
            allotted_id: allocationId!,
            query_type: {
              pr_emp_id: Number(teacherId),
              student_id: state.studentId,
              subj_master_id: Number(subjectId),
              idx: 0,
              form_name_id: Number(formId),
            },
          },
        },
        {
          query: GetAcdFeedbackFormDetails,
          variables: {
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            inst_id: InstId!,
            token,
            form_name: formName ? formName : EMPTY_STRING,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setDrawerStyle(!drawerStyle);
      }
    });
  };
  return (
    <>
      <Home />
      <Title>Preview Feedback Form</Title>
      <div className="feedback-preview">
        <div className="feedback-preview__flex">
          <div className="feedback-preview__flex--header">
            <b>{formData && formData.node.form_name}</b> &nbsp;
            <span className="formtype-form__flex--header--acd">
              {formData && formData.node.is_academic
                ? "Academic"
                : "Non-Academic"}
            </span>
          </div>
          <div className="feedback-preview__flex--sub">
            <b>{subjectData && subjectData.node.subj_desc}</b>
            <span>{subjectData && subjectData.node.subj_code}</span>
          </div>
          <div className="feedback-preview__flex--dates">
            <TextField
              className="feedback-preview__flex--textfield"
              label="Start Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              disabled
              value={
                formData && toInputStandardDate(formData.node.form_st_date)
              }
            />
            <img src={Compare} alt="" />
            <TextField
              className="feedback-preview__flex--textfield"
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              disabled
              value={
                formData && toInputStandardDate(formData.node.form_end_date)
              }
            />
          </div>
        </div>
        <div className="feedback-preview__datablock">
          <div className="feedback-preview__datablock--emp">
            <img src={Avatar} alt="" />
            <div className="feedback-preview__datablock--emp--block">
              <b className="feedback-preview__datablock--emp--name">
                {teacherData &&
                  teacherData.node.emp_first_name +
                    " " +
                    teacherData.node.emp_middle_name +
                    " " +
                    teacherData.node.emp_last_name}
              </b>
              <span className="feedback-preview__datablock--emp--id">
                {" "}
                {teacherData && teacherData.node.emp_id}
              </span>
            </div>
          </div>
          <div className="feedback-preview__datablock--list">
            <ul>
              {answerData &&
                answerData.GetAcdFeedbackStdRating.length &&
                answerData.GetAcdFeedbackStdRating.map((res, index) => {
                  return (
                    <li key={res.idx}>
                      <div className="feedback-preview__datablock--list--left">
                        <b className="feedback-preview__datablock--list--ques">
                          {res.idx} : {res.fb_form_details.rating_factor}
                        </b>
                        <div className="feedback-preview__datablock--list--flex">
                          <label>Answer : </label>
                          <b>
                            {res.rating === 5
                              ? "Excellent"
                              : res.rating === 4
                              ? "Good"
                              : res.rating === 3
                              ? "Average"
                              : res.rating === 2
                              ? "Fair"
                              : "Poor"}
                          </b>
                        </div>
                        <div className="feedback-preview__datablock--list--flex">
                          <label>Remark </label>
                          <span className="feedback-preview__datablock--list--flex--remark">
                            {res.remarks}
                          </span>
                        </div>
                      </div>
                      <div className="feedback-preview__datablock--list--edit">
                        <img
                          src={Edit}
                          alt=""
                          onClick={() => {
                            setDrawerStyle(!drawerStyle);
                            setFormDetails({
                              idx: res.idx,
                              id: res.id,
                            });
                            setRemarks(res.remarks);
                            setFormDetailId(res.fb_form_detail_id);
                            setRatings({
                              ...ratings,
                              [res.idx]: res.rating,
                            });
                          }}
                        />
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              mode="submit"
              onClick={() => {
                formData.node.is_academic
                  ? navigate(
                      `/${InstId}/masters/studentlist/${state.studentId}/${formName}/${formId}/academics/feedback/form`
                    )
                  : navigate(
                      `/${InstId}/masters/studentlist/${state.studentId}/academics`
                    );
              }}
            />
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-4 flex-end">
            {/* <Button mode="previous" /> */}
          </div>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={drawerStyle}
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
      >
        <div className="feedback-edit">
          <div className="feedback-edit__title">
            <Title>Edit Feedback</Title>
            <img
              src={Close}
              alt=""
              onClick={() => setDrawerStyle(!drawerStyle)}
            />
          </div>
          <div className="feedback-edit__data">
            <div className="fill-form__datablock--question">
              <b>
                {answerData &&
                  answerData.GetAcdFeedbackStdRating.find(
                    (data) => data.idx === formDetails.idx
                  )?.fb_form_details.rating_factor}
              </b>
            </div>
            <div className="fill-form__datablock--radio">
              <FormControl>
                <RadioGroup
                  row
                  value={
                    Object.keys(ratingValues).find(
                      (key) => ratingValues[key] === ratings[formDetails.idx]
                    ) || ""
                  }
                  onChange={(e) => {
                    handleRatingChange(
                      formDetails.idx,
                      ratingValues[e.target.value]
                    );
                    setFormDetailId(formDetails.id);
                  }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Excellent"
                    value="E"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Good"
                    value="G"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Average"
                    value="A"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Fair"
                    value="F"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Poor"
                    value="P"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <Label>
              Remarks
              <span className="feedback-edit__data--span">(if Any)</span>
            </Label>
            <div className="feedback-edit__data--remarks">
              <TextArea
                rows={10}
                value={remarks}
                onChange={handleRemarksChange}
              />
            </div>
          </div>
          <Button mode="save" onClick={handleUpdateAndNext} />
          <Button mode="cancel" onClick={() => setDrawerStyle(!drawerStyle)} />
        </div>
      </Drawer>
      <LoadingModal flag={updateLoading} />
    </>
  );
};

export default PreviewForm;
