import React from "react";
import Home from "../Master/configurations/Home/Index";
import { Title } from "../../stories/Title/Title";
import Input from "../../stories/Input/Input";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../styles/AutocompleteListStyles";
import Compare from "../../images/Compare.svg";
import EmployeeLogs from "../../images/EmployeeLogs.svg";
import { Button } from "../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Edit from "../../images/EditBlue.svg";
import useLogsTableJson from "./json/useLogsTableJson";
import { TableHeaderProps } from "../../Types/Tables";

const CompleteLogs = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { Logs_Table } = useLogsTableJson();

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Complete Logs</Title>
      <div className="emp-complete-logs">
        <div className="emp-complete-logs__select row g-0">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col-2">
            <Autocomplete
              options={["Accounts", "Admissions"]}
              classes={classes}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Module Type"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col-2">
            <Autocomplete
              options={["Add", "Edit", "Delete"]}
              classes={classes}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter By Activity"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className=" col-3">
            <div className="emp-logs__select--flex">
              <TextField
                className="emp-logs__select--textfield"
                type="date"
                label="Start Date"
                InputLabelProps={{ shrink: true }}
              />
              <img src={Compare} alt="" />
              <TextField
                className="emp-logs__select--textfield"
                type="date"
                label="Start Date"
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>
        </div>
        <div className="emp-complete-logs__tableblock">
          <TableContainer className="emp-complete-logs__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Logs_Table.CompleteLogs.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    className="emp-complete-logs__table--slno"
                    id="td-center">
                    1
                  </TableCell>
                  <TableCell>Fee Receipt</TableCell>
                  <TableCell>Accounts</TableCell>
                  <TableCell
                    className="emp-complete-logs__table--number"
                    id="td-center">
                    EMP001
                  </TableCell>
                  <TableCell>Suraj</TableCell>
                  <TableCell
                    className="emp-complete-logs__table--date"
                    id="td-center">
                    31-10-2023 &nbsp; 12:45
                  </TableCell>
                  <TableCell
                    className="emp-complete-logs__table--actions"
                    id="td-center">
                    <img src={Edit} alt="" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button onClick={() => navigate(`/${InstId}/logs`)}>
          <img src={EmployeeLogs} alt="" />
          Employee Logs
        </Button>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default CompleteLogs;
