import { useLazyQuery } from "@apollo/client";
import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import useToken from "../../../../customhooks/useToken";
import { Title } from "../../../../stories/Title/Title";
import { nodevars, VouchernodeData } from "../../../../Types/Accounting";
import {
  DateRange,
  formatter,
  getModifiedScrollHeight,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import useAcctVoucherDetailsByDates, {
  GetAcctVouchersEdges,
  voucherMasterDetails,
} from "../../hooks/useAcctVoucherDetailsByDates";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";
import Input from "../../../../stories/Input/Input";
import { VoucherQueryTypes } from "../../common/Enum.types";
import Home from "../../Home/Index";
import { PageFor, studentData } from "../../../../utils/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { StudentDetailsByMasterVoucherId } from "../../../../queries/common";
import { GetAcctVoucherSummation } from "../../queries/Vouchers/query";
import useAcctTableJson from "../../json/useAcctTableJson";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import {
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DataGridTableStyles";
import {
  DataGridPro,
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../utils/types";
interface Props {
  pageType: PageFor;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const ViewRefundedList = ({ pageType, setModal }: Props) => {
  const { format } = formatter;
  const navigate = useNavigate();
  let DefaultDate = new Date();
  const { InstId } = useParams();
  const { Accounts_Table } = useAcctTableJson();
  const { token } = useToken();
  const dates = DateRange(DefaultDate.toString());
  const { state } = useContext(AppContext);
  const dataClasses = useDataGridStyles();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [startDate, setStartDate] = useState(dates?.firstDay!);
  const [endDate, setEndDate] = useState(dates?.lastDay);
  const [studentDetails, setStudentDetails] = useState<voucherMasterDetails[]>(
    []
  );
  const [v_no, setv_no] = useState("");
  const [vouchers, setVouchers] = useState<GetAcctVouchersEdges[]>([]);

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const { user_details } = useLoggedInUserDetails();
  const [GetStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    StudentDetailsByMasterVoucherId
  );
  const { VoucherDetails } = useAcctVoucherDetailsByDates(
    ROWS_PER_PAGE,
    endDate!,
    startDate!,
    VoucherQueryTypes.REFUND_VOUCHERS,
    v_no
  );
  const [GetVouchersSummation, { data: SummationData }] = useLazyQuery(
    GetAcctVoucherSummation,
    {
      variables: {
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        inst_id: InstId!,
        token,
        user_details,
        input: {
          voucher_query_type: VoucherQueryTypes.REFUND_VOUCHERS,
          inst_id: InstId!,
          vo_start_date: toIsoDate(startDate),
          vo_end_date: toIsoDate(endDate!),
        },
      },
    }
  );

  const FetchStudentDetails = (
    node: voucherMasterDetails,
    type: studentData.name | studentData.reg
  ) => {
    const student = studentDetails.find((id) => id.id === node.id);

    if (student)
      return type === studentData.name
        ? student.mst_student.first_name +
            " " +
            student.mst_student.middle_name +
            " " +
            student.mst_student.last_name +
            " " +
            `(${node.branch_details.branch_desc} / ${node.class_details.class_desc} / ${student.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
        : student.mst_student.std_adm_no;
    else return EMPTY_STRING;
  };

  useEffect(() => {
    if (VoucherDetails.data) {
      GetStudentDetails({
        variables: {
          token,
          ids: vouchers?.map((data) => data.node.id)!,
        },
      }).then(({ data, error }) => {
        if (data) {
          setStudentDetails(data.nodes);
        }
      });
    } // eslint-disable-next-line
  }, [GetStudentDetails, vouchers?.length, token]);

  useEffect(() => {
    if (VoucherDetails.data && !VoucherDetails.loading && token) {
      const newData = VoucherDetails.data.GetAcctVouchers.edges;

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newVoucher) => {
          const filteredStudent = vouchers.find(
            (voucher) => voucher.node.id === newVoucher.node.id
          );
          if (filteredStudent) {
            return {
              ...newVoucher,
              node: {
                ...newVoucher.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newVoucher;
        });
        setVouchers(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            date: toStandardDate(node.v_date),
            receipt_no: node.v_no,
            admission_no: FetchStudentDetails(node, studentData.reg),
            name: FetchStudentDetails(node, studentData.name),
            amount: format(node.v_std_amt_total),
          }))
        );
      } else {
        setVouchers(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            date: toStandardDate(node.v_date),
            receipt_no: node.v_no,
            admission_no: FetchStudentDetails(node, studentData.reg),
            name: FetchStudentDetails(node, studentData.name),
            amount: format(node.v_std_amt_total),
          }))
        );
      }
      setEndCursor(VoucherDetails.data.GetAcctVouchers.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [VoucherDetails.data, VoucherDetails.loading, token, studentDetails]);
  useEffect(() => {
    if (token && state.ActiveFinYr && startDate && endDate) {
      GetVouchersSummation();
    }
  }, [token, state.ActiveFinYr, GetVouchersSummation, startDate, endDate]);

  const dynamicHeaders: TableHeaderProps[] =
    Accounts_Table.ViewRefundedReceipts.Table_Headers.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));
  const columns: GridColDef[] = [...dynamicHeaders];
  const getRow = () => {
    if (vouchers.length) {
      return [
        {
          id: [],
          date: [],
          admission_no: [],
          receipt_no: [],
          reg_no: [],

          name: "Summation of Refunds:",
          amount: format(
            SummationData && SummationData.GetAcctVoucherSummation.length
              ? SummationData.GetAcctVoucherSummation[0].v_std_amt_total
              : 0
          ),
        },
      ];
    }
    return [];
  };
  const pinnedRows = {
    bottom: getRow(),
  };

  const getCellClassName = (params: GridCellParams) => {
    const pinnedRowIds = pinnedRows.bottom
      ? pinnedRows.bottom.map((row) => row.id)
      : [];

    const isPinnedRow = pinnedRowIds.includes(params.row.id);

    if (isPinnedRow) {
      switch (params.field) {
        case "name":
          return "total";

        case "amount":
          return "totalcount";
        default:
          return "";
      }
    }

    return "";
  };
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;

      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !VoucherDetails.loading) {
            VoucherDetails.fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcctVouchers.edges;
                const pageInfo = fetchMoreResult.GetAcctVouchers.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcctVouchers.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAcctVouchers: {
                    edges: [...vouchers, ...newEdges],
                    pageInfo,
                    totalCount: VoucherDetails.data
                      ? VoucherDetails.data.GetAcctVouchers.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };

      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );
  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title>
        {pageType === PageFor.GENERAL ? (
          <>Refund Receipts</>
        ) : (
          <>View Refund Receipts</>
        )}
      </Title>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "view-concession-report"
            : "view-concession-report__modal"
        }
      >
        <div className="row g-0 refund-academic-fee__view--details">
          <div className="col-2">
            <Input
              onChange={(e) => setv_no(e.target.value)}
              id="search"
              autoFocus
              placeholder="Search..."
            />
          </div>
          <div className="col"></div>
          <div className="col-3 refund-academic-fee__view--details--textfield">
            <TextField
              type="date"
              InputProps={{
                inputProps: {
                  min: state.ActiveFinYr
                    ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                    : EMPTY_STRING,
                  max: toInputStandardDate(TODAY_DATE),
                },
              }}
              value={toInputStandardDate(startDate!)}
              onChange={(e) => setStartDate(e.target.value)}
              label="From"
              InputLabelProps={{ shrink: true }}
              className="refund-academic-fee__view--details--date"
            />
            <TextField
              type="date"
              InputProps={{
                inputProps: {
                  min: state.ActiveFinYr
                    ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                    : EMPTY_STRING,
                  max: toInputStandardDate(TODAY_DATE),
                },
              }}
              value={toInputStandardDate(endDate!)}
              onChange={(e) => setEndDate(e.target.value)}
              label="To"
              InputLabelProps={{ shrink: true }}
              className="refund-academic-fee__view--details--date"
            />
          </div>
        </div>
        <div
          className={`view-concession-report__tableblock ${dataClasses.root}`}
        >
          <DataGridPro
            columns={columns}
            rows={rows}
            disableRowSelectionOnClick
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
            hideFooter
            pinnedRows={pinnedRows}
            getCellClassName={getCellClassName}
            slotProps={{
              columnsPanel: { disableHideAllButton: true },
            }}
          />
        </div>
        <div className="row g-0">
          <div className="col">
            {pageType === PageFor.GENERAL ? (
              <Button mode="back" onClick={() => navigate(-1)} />
            ) : (
              <Button mode="cancel" onClick={() => setModal(false)} />
            )}
          </div>
          <div className="col-2 view-concession-report__total">
            <div className="student-total-count">
              Total Receipts :&nbsp;
              <b>{VoucherDetails.data?.GetAcctVouchers.totalCount!}</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewRefundedList;
