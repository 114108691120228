import React, { useContext, useEffect, useState } from "react";
import Home from "../../../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import { Drawer, TextField } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";

import { attendanceOverViewStyles } from "../../../../../styles/DrawerStyles";
import FormNotFilled from "./View";
import List from "../List";
import { useLazyQuery, useMutation } from "@apollo/client";
import Eye from "../../../../../images/EyeWhite.svg";
import Compare from "../../../../../images/Compare.svg";

import {
  GetAcdFeedbackFormsData,
  GetAcdFeedbackFormsVars,
} from "../../../types/subject/Subject";
import useToken from "../../../../../customhooks/useToken";
import { AppContext } from "../../../../../context/context";
import { EMPTY_STRING, TODAY_DATE } from "../../../../../utils/constants";
import { EmpData } from "../../../../HR/hooks/useEmployee";
import { CommonNodeVars } from "../../../../HR/Types/masterDataTypes";
import { PayRollEmpMasterNodeForLogin } from "../../../../HR/queries/employee/query";
import {
  GetAcdFeedbackForms,
  GetFormDataByFormId,
} from "../../../queries/general";
import { toIsoDate, toStandardDate } from "../../../../../utils/UtilFunctions";
import useActiveAcademicYear from "../../../hooks/useActiveAcademicYear";
import { FinalizeAcdFeedbackForm } from "../../../queries/test/mutation";
import { Operation } from "../../../../../utils/Enum.types";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import { msgType } from "../../../../../utils/Form.types";
import MessageModal from "../../../../../pages/MessageModal";
import LoadingModal from "../../../../../pages/LoadingModal";
import { Button } from "../../../../../stories/Button/Button";

const FormsByEmployee = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { entryId, InstId, formId, empId } = useParams();
  const { state } = useContext(AppContext);
  const drawerClasses = attendanceOverViewStyles();
  const [view, setView] = useState(false);
  const { user_details } = useLoggedInUserDetails();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { activeAcademicYearData } = useActiveAcademicYear();
  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;
  const [GetEmpDetailsForLogin, { data: teacherData }] = useLazyQuery<
    EmpData,
    CommonNodeVars
  >(PayRollEmpMasterNodeForLogin, {
    variables: {
      id: Number(empId),
      token,
    },
    fetchPolicy: "network-only",
  });
  const [FinalizeForm, { loading: finalizeLoading }] = useMutation(
    FinalizeAcdFeedbackForm,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleSubmit = (id: number) => {
    FinalizeForm({
      variables: {
        token,
        inst_id: InstId!,
        acd_yr_id: activeAcdId ? activeAcdId : 0,
        user_details,
        form_name_id: id,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Form has been Finalised Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const [GetFormDataById, { data: formData }] = useLazyQuery(
    GetFormDataByFormId,
    {
      variables: {
        id: Number(formId),
        token,
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (formId && token) {
      GetFormDataById();
    }
  }, [formId, GetFormDataById, token]);
  useEffect(() => {
    if (empId && token) {
      GetEmpDetailsForLogin();
    }
  }, [empId, GetEmpDetailsForLogin, token]);

  //   const {
  //     InstConfigDetails: { data: configdata },
  //   } = useInstConfigByEntryId(entryId!, entry_level);
  const [GetAcdFeedbackFormDropDown, { data: FormDropDown }] = useLazyQuery<
    GetAcdFeedbackFormsData,
    GetAcdFeedbackFormsVars
  >(GetAcdFeedbackForms, {
    variables: {
      acd_yr_id: activeAcdId ? activeAcdId : 0,
      inst_id: InstId!,
      token,
      allotted_level: EMPTY_STRING,
      allotted_id: 0,
      today_date: toIsoDate(TODAY_DATE),
      query_type: "FINALIZED_FORMS_BY_EMP_ID",
      pr_emp_id: Number(empId!),
    },
  });
  useEffect(() => {
    if (token && empId) {
      GetAcdFeedbackFormDropDown();
    }
  }, [token, GetAcdFeedbackFormDropDown, empId]);
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      // navigate(`/${InstId}/academics/${testId}/examplanner/view`);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Forms Report</Title>
      <div className="fb-form-report">
        <div className="fb-form-report__left">
          <List />
        </div>
        <div className="fb-form-report__right">
          <div className="fb-form-report__datablock">
          <div className="fb-form-report__datablock--grids">
          {FormDropDown &&
                FormDropDown.GetAcdFeedbackForms.map((data, index) => {
                  return (
                    <div
                      className={
                        data.is_academic
                          ? "fb-form-report__datablock--grid"
                          : "fb-form-report__datablock--event"
                      }
                    >
                      <b>{data.form_name}</b>
                      <span
                        className={
                          data.is_academic
                            ? "fb-form-report__datablock--grid--acd"
                            : "fb-form-report__datablock--event--acd"
                        }
                      >
                        {data.is_academic ? "Academic" : "Non-Academics"}
                      </span>
                      <div className="fb-form-report__datablock--grid--flex">
                        <TextField
                          className="fb-form-report__datablock--grid--textfield"
                          disabled
                          label="Start Date"
                          InputLabelProps={{ shrink: true }}
                          value={toStandardDate(data.form_st_date)}
                        />
                        <img src={Compare} alt="" />
                        <TextField
                          className="fb-form-report__datablock--grid--textfield"
                          disabled
                          label="End Date"
                          InputLabelProps={{ shrink: true }}
                          value={toStandardDate(data.form_end_date)}
                        />
                      </div>

                      <div className="fb-form-report__datablock--grid--flex">
                        <div className="fb-form-report__datablock--grid--block">
                          {/* <span>Total Students</span> */}
                          {/* <b className="font-blue">{data.total}</b> */}
                        </div>
                        <div className="fb-form-report__datablock--grid--block">
                          {/* <span>Filled Form</span> */}
                          {/* <b className="font-green">{data.filled}</b> */}
                        </div>
                      </div>

                      {data.is_report_generated ? (
                        <button
                          className="fb-form-report__datablock--grid--button"
                          onClick={() =>
                            navigate(
                              `/${InstId}/academics/reports/teacherwise/feedback/${data.id}/${empId}/view`
                            )
                          }
                        >
                          View Report <img src={Eye} alt="" />
                        </button>
                      ) : (
                        <button
                          className="fb-form-report__datablock--grid--button"
                          onClick={() => {
                            handleSubmit(data.id);
                          }}
                        >
                          Finalize <img src={Eye} alt="" />
                        </button>
                      )}
                    </div>
                  );
                })}
          </div>
      
          </div>

     
         
            {/* <Button mode="view" onClick={() => setView(!view)}>
      Not Filled Students
    </Button>
  */}
    <Button mode="back" onClick={() => navigate(-1)} /> 
        </div>
      </div>

      <Drawer
        anchor="right"
        open={view}
        onClose={() => setView(!view)}
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
      >
        <FormNotFilled setModalFlag={setView} />
      </Drawer>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={finalizeLoading} />
    </>
  );
};

export default FormsByEmployee;
