import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Label } from "../../../../stories/Label/Label";
import Avatar from "../../../../images/Avatar.svg";
import { TableHeaderProps } from "../../../../Types/Tables";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import {
  GetTempAcctVouchersDetailsData,
  GetTempAcctVouchersDetailsVars,
  IGetTempAcctVouchersDetails,
  studentRecepit,
} from "../../../../Types/Accounting";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetTempAcctVouchersDetails } from "../../../../queries/common";
import {
  formatter,
  toInputStandardDate,
  toIsoDate,
  toStandardCase,
} from "../../../../utils/UtilFunctions";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { AppContext } from "../../../../context/context";
import {
  DebitOrCredit,
  Operation,
  ReceiptTypes,
  YesNo,
} from "../../../../utils/Enum.types";
import useStudentDemandDetails from "../../../Accounts/hooks/useStudentDemandDetails";
import {
  AcctLedgerQueryType,
  DemandDetailsQueryType,
  LedgerType,
} from "../../../Accounts/common/QueryTypes";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import Modal from "react-modal";
import { msgType, responseType } from "../../../../utils/Form.types";
import { Keys } from "../../../../utils/Enum.keys";
import {
  CheckVouchersTransferStatus,
  GetAcctTransferVoucherDetailsByInstId,
} from "../../../Accounts/queries/Vouchers/query";
import { TransferAcctVoucherMasterAndDetailsAfterBranchChange } from "../../../Accounts/queries/receipts/mutation/Index";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { DepositModalCustomStyles } from "../../../../styles/ModalStyles";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import useAcctLedgerData from "../../../Accounts/hooks/useAcctLedgerData";
import Close from "../../../../images/Close.svg";
import { GetStudentBranchChangeByStdID } from "../../../../queries/students/list/newApi";
import { GetStudentsChangeBranchNode } from "./ViewChangedStudent";
import TextArea from "../../../../stories/TextArea/TextArea";
import useAcctTableJson from "../../../Accounts/json/useAcctTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  handleClear: () => void;
}
export interface GetStudentBranchChangeByStdIDData {
  GetStudentBranchChangeByStdID: GetStudentsChangeBranchNode;
}
export interface GetStudentBranchChangeByStdIDDataVars {
  token: string;
  inst_id: string;
  student_id: number;
  fin_yr_id: number;
}
const ChangeOfBranchVoucherInterchange = ({
  setModalFlag,
  handleClear,
}: Props) => {
  const { format } = formatter;
  const { InstId } = useParams<{ InstId: string }>();
  const { token } = useToken();
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { Accounts_Table } = useAcctTableJson();
  const [depositSelectModal, setDepositSelectModal] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [newReceiptItems, setNewReceiptItems] = useState<studentRecepit[]>([]);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0,
  });
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });

  const [UpdatedReceviedAmount, setUpdatedReceviedAmount] = useState(0);

  const { acctLedgers } = useAcctLedgerData(
    EMPTY_STRING,
    AcctLedgerQueryType.DEPOSIT_FEE_LDGRS,
    ROWS_PER_PAGE
  );
  const { user_details } = useLoggedInUserDetails();

  const { studentData } = useStudentDatabyId();
  const { state } = useContext(AppContext);
  const [GetVoucherDetailsByVoucherId] = useLazyQuery<
    GetTempAcctVouchersDetailsData,
    GetTempAcctVouchersDetailsVars
  >(GetTempAcctVouchersDetails);
  const [oldReceiptData, setOldReceiptData] = useState<
    IGetTempAcctVouchersDetails[]
  >([]);
  const filteredReceiptData = oldReceiptData?.find(
    (data) => data.vo_cr_db === DebitOrCredit.DEBIT
  );

  let totalpaidAmount = 0;
  let totalPayable = 0;
  let totalbalance = 0;
  let totalDemandAmount = 0;
  let totalReceiptAmount = 0;
  let totalChangedFineamount = 0;
  // eslint-disable-next-line
  newReceiptItems.map((item) => {
    totalDemandAmount += item.fee_demand;
    totalbalance += item.fee_bal;
    totalReceiptAmount += item.rcpt_amt;
    totalPayable += item.fee_receivable;
    totalpaidAmount += item.fee_received;
  });

  const [GetStudentDetails, { data }] = useLazyQuery<
    GetStudentBranchChangeByStdIDData,
    GetStudentBranchChangeByStdIDDataVars
  >(GetStudentBranchChangeByStdID);

  const {
    mst_student,
    old_class_details,
    old_branch_details,
    new_branch_details,
    new_class_details,
  } = data?.GetStudentBranchChangeByStdID || {};
  const [GenerateStudentReceipts, { loading: GenerateStudentReceiptsLoading }] =
    useMutation(TransferAcctVoucherMasterAndDetailsAfterBranchChange, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });

  const { StudentDemandDetails } = useStudentDemandDetails(
    DemandDetailsQueryType.DEMAND_DETAILS_TO_EXCHANGE,
    false,
    oldReceiptData[0]?.voucher_master_details.v_book_type!
  );

  const handleItems = (acctId: number) => {
    if (newReceiptItems.find((d) => d.newItem === YesNo.YES)) {
      setNewReceiptItems((prevItems) => {
        const updatedItems = prevItems.map((item) => {
          if (item.newItem === YesNo.YES) {
            return {
              ...item,
              acct_ldgr_details: {
                id: acctId,
                ldgr_cb: 0,
                ldgr_desc:
                  acctLedgers.responseType.find((d) => d.value === acctId)
                    ?.label ?? "",
                ldgr_ob: 0,
                ldgr_total_cr: 0,
                ldgr_total_db: 0,
                ldgr_std_fee_is_deposit_ldgr: false,
              },
              acct_ldgr_id: acctId,
              id: acctId,
            };
          }
          return item;
        });
        return updatedItems;
      });
    } else {
      setNewReceiptItems((prevItems) => {
        const rcptAmt = Math.abs(
          oldReceiptData[0].voucher_master_details.v_total_cr_amt -
            totalReceiptAmount
        );

        return [
          ...prevItems,
          {
            acct_ldgr_details: {
              id: acctId,
              ldgr_cb: 0,
              ldgr_desc:
                acctLedgers.responseType.find((d) => d.value === acctId)
                  ?.label ?? "",
              ldgr_ob: 0,
              ldgr_total_cr: 0,
              ldgr_total_db: 0,
              ldgr_std_fee_is_deposit_ldgr: false,
            },
            acct_ldgr_id: acctId,
            fee_bal: 0,
            fee_concession: 0,
            fee_demand: 0,
            fee_ob: 0,
            fee_receivable: 0,
            fee_received: 0,
            fee_refunds: 0,
            fine_amt: 0,
            newItem: YesNo.YES,
            rcpt_amt: rcptAmt,
            id: acctId,
            initialBalance: 0,
            vo_key: EMPTY_STRING,
            student_id: 0,
            isDepositLedger: false,
          },
        ];
      });
    }
  };
  const HandleEditItem = (sl_number: number) => {
    const updatedData = newReceiptItems.map((item) => {
      if (item.id === sl_number) {
        if (UpdatedReceviedAmount > item.fee_receivable) {
          alert("Please check you're recepit amount");
          return item;
        } else {
          const newData = {
            ...item,
            fee_ob: item.fee_ob,
            fee_demand: item.fee_demand,
            fee_concession: item.fee_concession,
            fee_receivable: item.fee_receivable,
            fee_received: item.fee_received,
            fee_refunds: item.fee_refunds,
            fee_bal: item.fee_receivable - UpdatedReceviedAmount,

            acct_ldgr_id: item.acct_ldgr_id,
            id: item.id,
            cr_db: DebitOrCredit.CREDIT,
            rcpt_amt: UpdatedReceviedAmount,
          };

          return newData;
        }
      }
      return item;
    });
    setNewReceiptItems(updatedData);
  };
  const onEdit = (id: number, updatedRcptAmount: number) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setUpdatedReceviedAmount(updatedRcptAmount);
  };
  const handleSplittingAmount = (receivedAmount: number) => {
    let initalAmount = receivedAmount;

    const rcvd_amt: studentRecepit[] = newReceiptItems.reduce(
      (result: studentRecepit[], element: studentRecepit) => {
        const rcpt_amt = Math.min(
          initalAmount,
          element.fee_receivable - element.fee_received
        );
        initalAmount -= rcpt_amt;
        result.push({
          ...element,
          rcpt_amt,
          fee_bal: element.fee_receivable - element.fee_received - rcpt_amt,
        });
        return result;
      },
      []
    );
    setNewReceiptItems(rcvd_amt);
  };

  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    setModalFlag(false);
  };
  const handleStudentReceipts = () => {
    if (!oldReceiptData) return;
    const { v_no, v_date, v_total_cr_amt, v_total_db_amt, v_book_type } =
      oldReceiptData[0].voucher_master_details;
    GenerateStudentReceipts({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        voucher_master_id: state.receiptId,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        acct_voucher_master: {
          fin_yr: state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING,
          v_type: ReceiptTypes.STUDENT_RECEIPT,
          v_no: v_no,
          v_date: v_date,
          v_total_cr_amt: v_total_cr_amt,
          v_total_db_amt: v_total_db_amt,
          v_std_anonymous_deposit_adjusted: false,
          v_std_receipt_ob: false,
          v_reconciled: false,
          v_reconciled_date: toIsoDate(DEFAULT_TIME),
          v_std_receipt: true,
          v_std_refund: false,
          v_std_deposit_adjusted: false,
          v_std_receipt_anonymous: false,
          v_std_refund_deposit: false,
          v_book_type: v_book_type,

          v_std_deposit: false,
          v_std_demand_receipt: true,
          v_std_scholarship_deposit: false,

          v_std_non_demand_receipt: false,
          student_id: state.studentId,
          class_id: studentData.data?.nodes[0].class.id,
          semester_id: studentData.data?.nodes[0].semester.id,
          v_std_amt_receipt: totalReceiptAmount,
          v_std_amt_deposit: 0,
          v_std_amt_fine: totalChangedFineamount,
          v_std_amt_total: totalReceiptAmount,
          v_std_amt_refunded: 0,
          v_std_passout_receipt: false,
          v_std_amt_adjusted: 0,
          v_std_enquiry: false,
          enquiry_student_id: 0,
          v_transcation_type: filteredReceiptData?.acct_ldgr_details.ldgr_desc,
          v_transcation_cash_or_bank:
            filteredReceiptData?.acct_ldgr_details.ldgr_cash_bank_other,
          v_transcation_no: v_no,
          v_transcation_date: v_date,
          v_transcation_narration: remarks,
          // paid_party_id: "",
          // party_bill_no: "",
          party_bill_date: toIsoDate(DEFAULT_TIME),
          // party_name: "",
          annx_yesno: false,
          // annx_id: Math.random() * 1000,
          is_vouch_multi_entry: false,
          acct_ldgr_id_cr: newReceiptItems.filter((d) => d.rcpt_amt > 0)[0]
            .acct_ldgr_details.id,
          acct_ldgr_id_db: filteredReceiptData?.acct_ldgr_details.id,
        },
        acct_voucher_db:
          filteredReceiptData &&
          [filteredReceiptData].map((receipt, index) => ({
            vo_cr_db: DebitOrCredit.DEBIT,
            vo_sl_no: index + 1,
            vo_cr: 0,
            vo_db: receipt.vo_db,
            vo_cr_total: 0,
            vo_db_total: receipt.vo_db_total,
            acct_ldgr_id: receipt.acct_ldgr_details.id,
          })),
        acct_voucher_cr: newReceiptItems
          .filter((d) => d.rcpt_amt > 0)
          .map((item, index) => ({
            vo_sl_no: index + 1,
            vo_cr_db: DebitOrCredit.CREDIT,
            vo_cr: item.rcpt_amt,
            vo_db: 0,
            vo_cr_total: totalReceiptAmount,
            vo_db_total: 0,
            acct_ldgr_id: item.acct_ldgr_id,
          })),
      },
      refetchQueries: [
        {
          query: GetAcctTransferVoucherDetailsByInstId,
          variables: {
            token,
            inst_id: InstId!,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          },
        },

        {
          query: CheckVouchersTransferStatus,
          variables: {
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            inst_id: InstId!,
            student_id: state.studentId,
            token,
            change_of_branch_id: data?.GetStudentBranchChangeByStdID.id ?? 0,
          },
          fetchPolicy: "network-only",
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Sucessfully interchanged Receipts",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  useEffect(() => {
    if (state.receiptId && state.ActiveFinYr) {
      GetVoucherDetailsByVoucherId({
        variables: {
          token,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          voucher_master_id: state.receiptId,
        },
      }).then(({ data }) => {
        if (data) {
          setOldReceiptData(data.GetTempAcctVouchersDetails);
        }
      });
    }
  }, [state.receiptId, state.ActiveFinYr, GetVoucherDetailsByVoucherId, token]);
  useEffect(() => {
    if (StudentDemandDetails.data && !StudentDemandDetails.loading) {
      // setStudentModal(!studentModal);

      const result = StudentDemandDetails.data?.GetAcctStdDemandDetails.filter(
        (item) => item !== null
      ).map((item) => ({
        legederType: LedgerType.GENERAL,
        fee_ob: item.fee_ob,
        fee_demand: item.fee_demand,
        fee_concession: item.fee_concession,
        fee_receivable: item.fee_receivable,
        fee_received: item.fee_received,
        fee_refunds: item.fee_refunds,
        fee_bal: item.fee_bal,
        acct_ldgr_id: item.acct_ldgr_id,
        id: item.id,
        fine_amt: 0,
        vo_key: EMPTY_STRING,
        cr_db: DebitOrCredit.CREDIT,
        rcpt_amt: 0,
        acct_ldgr_details: item.acct_ldgr_details,
        fee_receiveable: item.fee_receivable,
        initialBalance: item.fee_bal,
        student_id: item.student_id,
      }));

      setNewReceiptItems(result!);
    }
  }, [
    state.studentId,
    token,
    StudentDemandDetails.data,
    StudentDemandDetails.loading,
  ]);

  useEffect(() => {
    if (token && InstId && state.studentId && state.ActiveFinYr) {
      GetStudentDetails({
        variables: {
          inst_id: InstId!,
          student_id: state.studentId,
          token,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        },
      });
    }
  }, [token, InstId, state.studentId, GetStudentDetails, state.ActiveFinYr]);
  const { branchLabel, classLabel } = useInstLabels();

  return (
    <>
      <Title>InterChange Receipts</Title>
      <div className="interchange-fee-receipt--modal">
        <div className="row g-0 interchange-fee-receipt__details">
          <div className="col">
            <TextField
              label="Receipt No."
              className="interchange-fee-receipt__block--textfield"
              InputLabelProps={{ shrink: true }}
              disabled
              value={
                filteredReceiptData && state.receiptId
                  ? oldReceiptData[0].voucher_master_details.v_no
                  : EMPTY_STRING
              }
            />

            <TextField
              label="Remitted to."
              className="interchange-fee-receipt__block--textfield"
              InputLabelProps={{ shrink: true }}
              disabled
              value={
                filteredReceiptData && state.receiptId
                  ? toStandardCase(
                      filteredReceiptData.acct_ldgr_details.ldgr_desc
                    )
                  : EMPTY_STRING
              }
            />
          </div>
          <div className="col interchange-fee-receipt__block--remarks">
            <Label>Remarks</Label>
            <TextArea onChange={(e) => setRemarks(e.target.value)} rows={3} />
          </div>
          <div className="col">
            <div className="interchange-fee-receipt__block--date">
              <TextField
                type="date"
                label="Receipt Date"
                className="interchange-fee-receipt__block--textfield--date"
                InputLabelProps={{ shrink: true }}
                value={
                  toInputStandardDate(
                    oldReceiptData[0]?.voucher_master_details?.v_date!
                  ) || EMPTY_STRING
                }
                disabled
              />
              <Label variant="present-day">Thursday</Label>
            </div>
          </div>
        </div>

        <div className="interchange-fee-receipt__block2">
          <div className="account-frames h-100">
            <div className="row g-0 h-30 interchange-fee-receipt__block2--details">
              <div className="details interchange-fee-receipt__block2--current">
                <h4>Old Receipt Details</h4>
              </div>
              <div className="col-2 interchange-fee-receipt__block2--subblock interchange-fee-receipt__block2--image h-100">
                <img src={Avatar} alt="/" />
              </div>
              <div className="col interchange-fee-receipt__block2--subblock">
                <TextField
                  className="interchange-fee-receipt__block2--textfield"
                  label="Admission No."
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={mst_student?.std_adm_no || EMPTY_STRING}
                />
                <TextField
                  className="interchange-fee-receipt__block2--textfield"
                  label="Name"
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={mst_student?.first_name || EMPTY_STRING}
                />
                <TextField
                  className="interchange-fee-receipt__block2--textfield"
                  label={classLabel}
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={old_class_details?.class_desc || EMPTY_STRING}
                />
              </div>
              <div className="col interchange-fee-receipt__block2--subblock">
                <TextField
                  label="Reg No."
                  className="interchange-fee-receipt__block2--textfield"
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={mst_student?.std_reg_no || EMPTY_STRING}
                />
                <TextField
                  label={branchLabel}
                  className="interchange-fee-receipt__block2--textfield"
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={old_branch_details?.branch_desc || EMPTY_STRING}
                />
              </div>
            </div>
          </div>

          <div className="account-frames h-100">
            <div className="row g-0 h-30 interchange-fee-receipt__block2--details">
              <div className="details interchange-fee-receipt__block2--changed">
                <h4>New Receipt Details</h4>
              </div>

              <div className="col interchange-fee-receipt__block2--subblock">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="interchange-fee-receipt__block2--textfield"
                  label="Admission No."
                  disabled
                  value={mst_student?.std_adm_no || EMPTY_STRING}
                />
                <TextField
                  className="interchange-fee-receipt__block2--textfield"
                  label="Name"
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={mst_student?.first_name || EMPTY_STRING}
                />
                <TextField
                  className="interchange-fee-receipt__block2--textfield"
                  label={classLabel}
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={new_class_details?.class_desc || EMPTY_STRING}
                />
              </div>
              <div className="col interchange-fee-receipt__block2--subblock">
                <TextField
                  label="Reg No."
                  className="interchange-fee-receipt__block2--textfield"
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={mst_student?.std_reg_no || EMPTY_STRING}
                />
                <TextField
                  label={branchLabel}
                  className="interchange-fee-receipt__block2--textfield"
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={new_branch_details?.branch_desc || EMPTY_STRING}
                />
              </div>
              <div className="col-2 interchange-fee-receipt__block2--subblock interchange-fee-receipt__block2--image h-100">
                <img src={Avatar} alt="/" />
              </div>
            </div>
          </div>
        </div>
        <div className="interchange-fee-receipt__tableblock">
          <TableContainer className="interchange-fee-receipt__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.Receipts.InterChangeReceipt.CurrentReceiptDetails.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              {oldReceiptData
                ?.filter((d) => d.vo_cr_db === DebitOrCredit.CREDIT)
                ?.map((receipt, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        id="td-center"
                        className="interchange-fee-receipt__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        {receipt.acct_ldgr_details.ldgr_desc}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="interchange-fee-receipt__table--amount"
                      >
                        {format(receipt.vo_cr)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Fine Amount :
                  </TableCell>
                  <TableCell id="td-right" className="balance-count">
                    0
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Receipt Amount :
                  </TableCell>
                  <TableCell id="td-right" className="balance-count">
                    {format(
                      filteredReceiptData &&
                        state.receiptId &&
                        oldReceiptData[0].voucher_master_details?.v_total_cr_amt
                        ? oldReceiptData[0].voucher_master_details
                            .v_total_cr_amt
                        : 0
                    )}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          <div className="interchange-fee-receipt__block2--interchange-image">
            <Button
              mode="transfer"
              onClick={() =>
                handleSplittingAmount(
                  oldReceiptData[0].voucher_master_details?.v_total_cr_amt
                    ? oldReceiptData[0].voucher_master_details.v_total_cr_amt
                    : 0
                )
              }
            />
          </div>
          <TableContainer className="interchange-fee-receipt__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.Receipts.InterChangeReceipt.ChangedReceiptDetails.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {newReceiptItems.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell
                          className="interchange-fee-receipt__table--slno"
                          align="center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {item.acct_ldgr_details.ldgr_desc}
                        </TableCell>

                        <TableCell
                          id="td-right"
                          className="interchange-fee-receipt__table--amount"
                        >
                          {format(item.fee_demand)}
                        </TableCell>

                        <TableCell
                          id="td-right"
                          className="interchange-fee-receipt__table--amount"
                        >
                          {format(item.fee_receivable)}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="interchange-fee-receipt__table--amount"
                        >
                          {format(item.fee_received)}
                        </TableCell>

                        <TableCell
                          id="td-right"
                          onClick={() => {
                            if (item.rcpt_amt > 0) {
                              onEdit(
                                newReceiptItems[index].id!,
                                newReceiptItems[index].rcpt_amt
                              );
                            }
                            if (item.rcpt_amt === 0) {
                              onEdit(item.id!, 0);
                            }
                          }}
                          className="interchange-fee-receipt__table--amount totalcountcell interchange-fee-receipt__table--input "
                        >
                          {inEditMode.status &&
                          inEditMode.rowKey === item.id ? (
                            <Input
                              id="td-right"
                              size="small"
                              type="number"
                              autoFocus
                              value={UpdatedReceviedAmount}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                setUpdatedReceviedAmount(Number(e.target.value))
                              }
                              onKeyDown={(event: React.KeyboardEvent) => {
                                if (event.key === Keys.ENTER) {
                                  if (
                                    newReceiptItems[index + 1] &&
                                    newReceiptItems[index + 1].id
                                  ) {
                                    onEdit(
                                      newReceiptItems[index + 1].id!,
                                      newReceiptItems[index + 1].rcpt_amt
                                    );
                                    HandleEditItem(item.id!);
                                  } else {
                                    onEdit(
                                      newReceiptItems[index].id!,
                                      newReceiptItems[index].rcpt_amt
                                    );
                                    HandleEditItem(item.id!);

                                    setInEditMode({
                                      rowKey: -805,
                                      status: false,
                                    });
                                  }
                                }
                              }}
                            />
                          ) : (
                            format(item.rcpt_amt)
                          )}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="balance-amount interchange-fee-receipt__table--amount"
                        >
                          {format(item.fee_bal)}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={5} className="total">
                    Fine Amount :
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    0
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Total :
                  </TableCell>

                  <TableCell id="td-right" className="totalcount">
                    {format(totalDemandAmount)}
                  </TableCell>

                  <TableCell id="td-right" className="totalcount">
                    {format(totalPayable)}
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totalpaidAmount)}
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totalReceiptAmount)}
                  </TableCell>
                  <TableCell id="td-right" className="balance-count">
                    {format(totalbalance)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <Button
          mode="save"
          onClick={handleStudentReceipts}
          disabled={
            totalReceiptAmount !==
            oldReceiptData[0]?.voucher_master_details.v_total_cr_amt
          }
        />
        <Button
          mode="transfer"
          disabled={
            (totalReceiptAmount !== 0 &&
              totalReceiptAmount <
                oldReceiptData[0]?.voucher_master_details.v_total_cr_amt) ||
            newReceiptItems.length === 0
              ? false
              : true
          }
          onClick={() => setDepositSelectModal(!depositSelectModal)}
        />
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
      <LoadingModal flag={GenerateStudentReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={depositSelectModal}
        style={DepositModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title variant="subtitle1"> Student Fee Advance List</Title>
            <hr className="dashed" />
            <span>
              <Autocomplete
                classes={classes}
                options={acctLedgers.responseType}
                openOnFocus
                onChange={(e, newValue) => {
                  if (newValue) {
                    handleItems((newValue as responseType).value);
                  }
                }}
                freeSolo
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    classes={{ root: textClasses.formControlRoot }}
                    id="outlined-uncontrolled"
                    label="Admission Number"
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                )}
              />
            </span>
            <hr />
            <Label variant="LabelPrimary">
              Select the fee ledger through which adjustment of fees should be
              done.
            </Label>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDepositSelectModal(!depositSelectModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChangeOfBranchVoucherInterchange;
