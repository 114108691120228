import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Enlarge from "../../../../images/Enlarge.svg";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  toggleFullSceen,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { TableHeaderProps } from "../../../../Types/Tables";
import { EMPTY_STRING, FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";

import { useParams } from "react-router-dom";
import {
  Direction,
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  InstitutionConfigurationTypes,
  PageFor,
  SortBy,
  StdStatusQueryType,
  StudentType,
} from "../../../../utils/Enum.types";

import { GetStudentsNeByInstId } from "../../../../queries/students/list/byId";
import {
  GetStudentsNeByInstIdData,
  GetStudentsNeByInstIdVars,
  MstStudentNeEdge,
} from "../../../../Types/Student/paginationTypes";
import useToken from "../../../../customhooks/useToken";
import { payloadTypes } from "../../../../context/reducer";
import { AppContext } from "../../../../context/context";
import { useLazyQuery } from "@apollo/client";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  D4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  G4_CELL,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";

interface Props {
  pageType: PageFor;
  studentType: StudentType;
  setNotEligibleModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const ViewElligibleList = ({
  studentType,
  pageType,
  setNotEligibleModal,
}: Props) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { InstDetails } = useInstDetails(1);
  const { dispatch } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();
  const { state } = useContext(AppContext);
  const { LogoOrSign } = useInstLogoDetails({filetype:FileUploadParams.INST_LOGO});
  const { Masters_Table } = useMasterTableJson();

  // eslint-disable-next-line
  const [searchText, setSearchText] = useState("");
  // eslint-disable-next-line
  const [sortBy] = useState(SortBy.STD_NE_DATE);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [students, setStudents] = useState<MstStudentNeEdge[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const { branchLabel, classLabel, departmentLabel } = useInstLabels();
  const [searchData, setSearchData] = useState("");
  const [pdfFlag, setPdfFlag] = useState(false);
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const [GetStudentNeData, { data, loading, fetchMore }] = useLazyQuery<
    GetStudentsNeByInstIdData,
    GetStudentsNeByInstIdVars
  >(GetStudentsNeByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      query_type:
        studentType === StudentType.ELIGIBLE
          ? StdStatusQueryType.NE_STATUS_FALSE
          : StdStatusQueryType.NE_STATUS_TRUE,
      name: searchData,
      first: rowsPerPage,
      after: null,
      orderBy: sortBy,
      direction: Direction.ASC,
    },
  });
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;

  const excelPageHeader =
    studentType === StudentType.NOT_ELIGIBLE
      ? "Not Eligible Students List "
      : "Eligible Students List";
  const NotEligibleListHeaders = [
    "SlNo",
    "Admission No",
    "Reg No.",
    "Name",
    branchLabel,
    "NE Date",
    "NE Reason",
    "NE Remarks",
    studentType === StudentType.ELIGIBLE ? "Eligible Date" : null,
    studentType === StudentType.ELIGIBLE ? "NE Cancel Remarks" : null,
  ];

  const stdData = data?.GetStudentsNeByInstId?.edges.map((data, index) => ({
    SlNo: index + 1,
    AdmNo: data.node.mst_student.std_adm_no,
    RegNo: data.node.mst_student.std_reg_no,
    Name:
      data.node.mst_student.first_name +
      " " +
      data.node.mst_student.middle_name +
      " " +
      data.node.mst_student.last_name,
    Branch: data.node.mst_student.branch.branch_desc,
    NEdate: toStandardDate(data.node.std_ne_date),
    NEreason: data.node.std_ne_type,
    remarks: data.node.std_ne_reason,
    EligibleDate: data.node.std_ne_cancel_date,
    CancelRemarks: data.node.std_ne_cancel_reason,
  }));
  const studentCol = [
    { title: "Sl", field: "SlNo" },
    { title: "Adm No", field: "AdmNo" },
    { title: "Reg. No.", field: "RegNo" },
    { title: "Name", field: "Name" },
    { title: branchLabel, field: "Branch" },
    { title: "NE Date", field: "NEdate" },
    { title: "NE Reason", field: "NEreason" },
    { title: "NE Remarks", field: "remarks" },
    studentType === StudentType.ELIGIBLE
      ? { title: "Eligible Date", field: "EligibleDate" }
      : null,
    studentType === StudentType.ELIGIBLE
      ? { title: "NE Cancel Remarks", field: "CancelRemarks" }
      : null,
  ];

  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(Eduate)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const doc = new jsPDF("landscape", "mm", "a4");
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(18);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(240, 240, 240);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0);
                  doc.text(
                    `${InstDetails?.data?.nodes[0].inst_name}`,
                    80,
                    startY
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_address}`,
                    120,
                    startY + 7
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_place},${InstDetails?.data?.nodes[0]?.inst_pin}`,
                    120,
                    startY + 12
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `Fin-year -${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    45,
                    startY + 21
                  );
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text(excelPageHeader, 120, startY + 21);
                  doc.setFontSize(13);
                  doc.text(
                    `As-on-date:${toStandardDate(serverDate)}`,
                    230,
                    startY + 21
                  );
                  doc.addImage(result, "JPEG", 15, 5, 22, 22);
                  if (configLogo) {
                    doc.addImage(result2, "JPEG", 250, 5, 20, 20);
                  }
                }

                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: studentCol.map((col) => ({
                    ...col,
                    dataKey: col?.field,
                    styles: { fontSize: 18 },
                  })),

                  body: stdData,
                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: function (data) {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);

                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                });

                doc.save(
                  `${InstDetails?.data?.nodes[0]?.inst_name} ${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  } `
                );
              });
            });
        });
      });
    setPdfFlag(false);
    setRowsPerPage(ROWS_PER_PAGE);
  };
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(excelPageHeader);

    const dataRows = stdData
      ? stdData?.map((item) => [
          item.SlNo,
          item.AdmNo,
          item.RegNo,
          item.Name,
          item.Branch,
          item.NEdate,
          item.NEreason,
          item.remarks,
          item.EligibleDate,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 35;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });
          if (configLogo) {
            worksheet.addImage(imageV, "G1:G3");
          }
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");
                  worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails?.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails?.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:H2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails?.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails?.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:H3");

                  const mergedHeader: Cell = worksheet.getCell(D4_CELL);
                  mergedHeader.value = excelPageHeader;
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("D4:F4");
                  const mergedDate: Cell = worksheet.getCell(G4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("G4:H4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:C4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < NotEligibleListHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = NotEligibleListHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, "Not Eligible List");
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(ROWS_PER_PAGE);
                });
              });
          });
        });
      });
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetStudentsNeByInstId.edges;
            const pageInfo = fetchMoreResult.GetStudentsNeByInstId.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetStudentsNeByInstId.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetStudentsNeByInstId: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data?.GetStudentsNeByInstId.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const { USE_BRANCH_KEY, USE_DEPARTMENT_KEY, USE_CLASS_KEY } =
    useInstitutionConfiguration();
  const filterInstConfigHeaders = (title: TableHeaderProps) => {
    const departmentCheck =
      title.labelName === departmentLabel && USE_DEPARTMENT_KEY;
    const branchCheck = title.labelName === branchLabel && USE_BRANCH_KEY;
    const classCheck = title.labelName === classLabel && USE_CLASS_KEY;

    const otherThanInstMasters =
      title.labelName !== departmentLabel &&
      title.labelName !== branchLabel &&
      title.labelName !== classLabel &&
      title.labelName !== "Register Number";

    return departmentCheck || branchCheck || classCheck || otherThanInstMasters;
  };
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetStudentsNeByInstId.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data?.GetStudentsNeByInstId.pageInfo.endCursor!);
    } // eslint-disable-next-line
  }, [data, loading]);
  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !loading) downloadExcel();
    if (pdfFlag && rowsPerPage === null && !loading) downloadPdf();
    // eslint-disable-next-line
  }, [excelFlag, pdfFlag, rowsPerPage, loading]);
  useEffect(() => {
    if (token) {
      GetStudentNeData();
    }
  }, [GetStudentNeData, token]);

  return (
    <>
      <div className="row g-0">
        <div className="col">
          <Title>{excelPageHeader}</Title>
        </div>
        <div className="configuration-settings">
          <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
        </div>
      </div>
      <div className="row g-0">
        <div className="col-2">
          <Input
            id="search"
            placeholder="Search...."
            className="student-status__view--search"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchData(e.target.value)
            }
          />
        </div>
      </div>

      <div className="student-status__view--tableblock">
        {!students?.length ? (
          <b className="nodata">Sorry, no results.</b>
        ) : (
          <TableContainer
            className="student-status__view--table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Masters_Table.Students.ViewMadeNotEligibleStudents.Table_Headers.filter(
                    ({ labelName }: TableHeaderProps) =>
                      !(
                        (labelName === "Eligible Date" ||
                          labelName === "NE Cancel Remarks") &&
                        studentType === StudentType.NOT_ELIGIBLE
                      )
                  )
                    .filter((th: TableHeaderProps) =>
                      filterInstConfigHeaders(th)
                    )
                    .map((th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {students?.map((res, index) => {
                  return (
                    <>
                      <TableRow
                        key={res.node.id}
                        onClick={() => {
                          if (pageType === PageFor.MODAL) {
                            dispatch({
                              type: payloadTypes.SET_STUDENT_ID,
                              payload: {
                                studentId: res.node.student_id,
                              },
                            });
                            setNotEligibleModal?.(false);
                          }
                        }}
                      >
                        <TableCell
                          className="student-status__view--table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className="student-status__view--table--admno">
                          {res.node?.mst_student.std_adm_no}
                        </TableCell>
                        <TableCell className="student-status__view--table--admno">
                          {res.node?.mst_student.std_reg_no}
                        </TableCell>
                        <TableCell>
                          {res.node.mst_student.first_name +
                            " " +
                            res.node.mst_student.middle_name +
                            " " +
                            res.node.mst_student.last_name}
                        </TableCell>
                        {USE_DEPARTMENT_KEY && (
                          <TableCell className="student-status__view--table--dept">
                            {res.node.mst_student.dept.dept_desc}
                          </TableCell>
                        )}
                        {USE_BRANCH_KEY && (
                          <TableCell className="student-status__view--table--dept">
                            {res.node.mst_student.branch.branch_desc}
                          </TableCell>
                        )}
                        {USE_CLASS_KEY && (
                          <TableCell className="student-status__view--table--dept">
                            {res.node.mst_student.class.class_desc}
                          </TableCell>
                        )}
                        <TableCell className="student-status__view--table--date">
                          {toStandardDate(res.node.std_ne_date)}
                        </TableCell>
                        <TableCell className="student-status__view--table--year">
                          {res.node.std_ne_type}
                        </TableCell>
                        <TableCell className="student-status__view--table--remarks">
                          {res.node.std_ne_reason}
                        </TableCell>
                        {studentType === StudentType.ELIGIBLE ? (
                          <TableCell className="student-status__view--table--remarks">
                            {toStandardDate(res.node.std_ne_cancel_date)}
                          </TableCell>
                        ) : null}
                        {studentType === StudentType.ELIGIBLE ? (
                          <TableCell className="student-status__view--table--remarks">
                            {res.node.std_ne_cancel_reason}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <div className="row g-0">
        <div className="col">
          <Button
            onClick={() => {
              setRowsPerPage(null);
              setPdfFlag(true);
            }}
            mode="pdf"
          />
          <Button
            onClick={() => {
              setRowsPerPage(null);
              setExcelFlag(true);
            }}
            mode="export"
          />
          <Button mode="cancel" onClick={() => setNotEligibleModal(false)} />
        </div>
        <div className="col-3 student-status__view--total">
          <div className="student-total-count">
            Total Students : <b>{data?.GetStudentsNeByInstId.totalCount}</b>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewElligibleList;
