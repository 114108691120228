import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import {
  Autocomplete,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { Title } from "../../../stories/Title/Title";
import Home from "../Home/Index";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";

import { Button } from "../../../stories/Button/Button";
import { TableHeaderProps } from "../../../Types/Tables";
import {
  DEBOUNCE_TIME_INTERVAL,
  EMPTY_STRING,
  LIBRARY_ROWS_PER_PAGE,
  NOTALLOCATED,
  PAGINATION_ARRAY,
  TABLE_DATA_PER_PAGE,
} from "../../../utils/constants";
import { TablePaginationActions } from "../../../pages/CustomTablePagination";
import {
  debounce,
  defaultLabelDisplayedRows,
} from "../../../utils/UtilFunctions";
import { Label } from "../../../stories/Label/Label";
import ArrowRed from "../../../images/ArrowRed.svg";
import ArrowGreen from "../../../images/ArrowGreen.svg";
import Settings from "../../../images/Settings.svg";
import useBookEntryOptions from "../customHooks/useBookEntryOptions";
import {
  BooksReportType,
  Direction,
  LibBookMasterQueryType,
  MoveDirection,
  Operation,
  PageNumbers,
  SortBy,
  UserType,
} from "../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  UpdateLibBooksDeptDetails,
  UpdateLibBooksRackDetails,
} from "../../../queries/Library/MasterData/Shelf/mutations/update";
import useToken from "../../../customhooks/useToken";
import MessageModal from "../../../pages/MessageModal";
import { bookDetails, msgType } from "../../../utils/Form.types";
import LoadingModal from "../../../pages/LoadingModal";
import { GetLibBookMaster } from "../../../queries/Library/BookEntry/list/byInstId";
import { GetLibBookCountByRackId } from "../../../queries/Library/MasterData/Shelf/list/byId";
import { ConfigurationsModalStyles } from "../../../styles/ModalStyles";
//Modals
import ConfigurationSettings from "../../Master/configurations/general/Index";

import BookDetails from "../Modals/BookDetails/Index";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import Close from "../../../images/Close.svg";
import { Keys } from "../../../utils/Enum.keys";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryTableJson from "../json/useLibraryTableJson";
import {
  GetLibBookDeptsData,
  GetLibBookDeptsVars,
} from "../../../Types/Library/MasterData/Media/paginationTypes";
import { GetLibBookDepts } from "../../../queries/Library/MasterData/Media/list/byInstId";

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  type: MoveDirection;
}
enum TableHeaders {
  Previous = "Previous Shelf",
  Next = "Move to Shelf",
}

export interface bookDetailsModal {
  flag: boolean;
  bookDetails?: bookDetails;
}
const BooksDeptAllocation = () => {
  //Styles
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  //Params
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const navigate = useNavigate();
  //useStates

  const [viewShelfBooksModal, setViewShelfBooksModal] = useState(false);

  const [configurationModal, setConfigurationModal] = useState(false);

  const [bookDetailsFlag, setBookDetailsFlag] = useState<bookDetailsModal>();
  const [LeftDepartmentBooks, setLeftDepartmentBooks] = useState<bookDetails[]>(
    []
  );
  const [searchShelf, setSearchShelf] = useState("");
  const [searchToShelf, setToSearchShelf] = useState("");
  const [RightDeptBooks, setRightDeptBooks] = useState<bookDetails[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  //Pagination states
  const [rowsPerPage, setRowsPerPage] = useState(LIBRARY_ROWS_PER_PAGE);
  const [page, setPage] = useState(0);
  const { Library_Table } = useLibraryTableJson();

  //refs
  const shelfRef = useRef<HTMLSelectElement>(null);
  const shelfInputRef = shelfRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount, type } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell
            padding="checkbox"
            className="submission-details__table--actions"
          >
            {type === MoveDirection.RIGHT ? (
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                disabled={
                  LeftDepartmentBooks?.filter((data) => data.isChecked).length >
                  0
                }
              />
            ) : (
              <Checkbox
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                disabled={
                  RightDeptBooks?.filter((data) => data.isChecked).length > 0
                }
              />
            )}
          </TableCell>

          {Library_Table.BooksShelfAllocation.Table_Headers.filter(
            (th: TableHeaderProps) =>
              (th.labelName !== TableHeaders.Previous &&
                type === MoveDirection.RIGHT) ||
              (th.labelName !== TableHeaders.Next &&
                type === MoveDirection.LEFT)
          ).map((th: TableHeaderProps, index: React.Key) => {
            return (
              <TableCell key={index} className={th.className}>
                {th.labelName}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };
  //useQueries
  const [GetBooksCountByRack, { data }] = useLazyQuery(GetLibBookCountByRackId);
  //useMutations
  const [MoveBooksToDept, { loading: MoveBooksToRackLoading }] = useMutation(
    UpdateLibBooksDeptDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  //customHooks
  const { token } = useToken();
  const [deptId, setDeptId] = useState(0);
  const [toDeptId, setToDeptId] = useState(0);
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.LIB_BOOK_SHELF_ALLOCATION_PAGE
  );
  const {
    bookEntries: {
      queryData: {
        data: booksFromSelectedDept,
        loading: booksFromSelectedShelfLoading,
        fetchMore,
      },
    },
  } = useBookEntryOptions(
    EMPTY_STRING,
    rowsPerPage,
    BooksReportType.BOOKS_BY_DEPT_ID,
    UserType.STUDENT,
    deptId
  );

  const [GetDepartmentData, { data: DeptData, loading }] = useLazyQuery<
    GetLibBookDeptsData,
    GetLibBookDeptsVars
  >(GetLibBookDepts, {
    variables: {
      token,
      inst_id: InstId!,
      first: null,
      sortBy: SortBy.DEPT_NAME,
      direction: Direction.ASC,
      deptName: "",
      after: null,
    },
  });

  const departmentDropdown =
    DeptData &&
    DeptData.GetLibBookDepts.edges
      .filter((data) => data.node.dept_name !== NOTALLOCATED)
      .map((res) => ({
        label: res.node.dept_name,
        value: res.node.id,
      }));

  const handleClick = (
    event: React.MouseEvent<unknown>,
    data: bookDetails,
    direction: MoveDirection
  ) => {
    const { checked } = event.target as HTMLInputElement;
    switch (direction) {
      case MoveDirection.LEFT:
        const mappedDataLeft = LeftDepartmentBooks?.map((row) => {
          if (row.id === data.id) {
            return {
              ...row,
              isChecked: checked ? true : false,
            };
          }
          return row;
        });
        setLeftDepartmentBooks(mappedDataLeft);
        break;
      case MoveDirection.RIGHT:
        const mappedDataRight = RightDeptBooks?.map((row) => {
          if (row.id === data.id) {
            return {
              ...row,
              isChecked: checked ? true : false,
            };
          }
          return row;
        });
        setRightDeptBooks(mappedDataRight);
        break;
      default:
        break;
    }
  };
  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    direction: MoveDirection
  ) => {
    if (direction === MoveDirection.LEFT) {
      const newSelecteds = LeftDepartmentBooks?.map((data) => ({
        ...data,
        isChecked: event.target.checked,
      }));
      setLeftDepartmentBooks([...newSelecteds]);
    } else {
      const newSelecteds = RightDeptBooks?.map((data) => ({
        ...data,
        isChecked: event.target.checked,
      }));
      setRightDeptBooks([...newSelecteds]);
    }
  };

  //pagination
  const totalCount = booksFromSelectedDept?.GetLibBookMaster?.totalCount;
  const endCursor =
    booksFromSelectedDept?.GetLibBookMaster?.pageInfo?.endCursor;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setTimeout(() => {
      setPage(newPage);
      updateDebounceText();
    }, DEBOUNCE_TIME_INTERVAL);
  };
  const updateDebounceText = debounce(() => {
    handleMore();
  });
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleMore = () => {
    fetchMore({
      variables: {
        after: endCursor,
      },

      updateQuery: (prevResult, { fetchMoreResult }) => {
        fetchMoreResult.GetLibBookMaster.edges = [
          ...prevResult.GetLibBookMaster.edges,
          ...fetchMoreResult.GetLibBookMaster.edges,
        ];

        return fetchMoreResult;
      },
    });
  };
  // eslint-disable-next-line
  const handleClear = () => {
    setDeptId(0);
    setToDeptId(0);
    setLeftDepartmentBooks([]);
    setRightDeptBooks([]);
  };
  useEffect(() => {
    if (booksFromSelectedDept && !booksFromSelectedShelfLoading) {
      if (deptId) {
        setLeftDepartmentBooks(
          booksFromSelectedDept?.GetLibBookMaster?.edges
            ?.map(({ node }) => ({
              id: node.id,
              access_no: node.book_access_no,
              publisher: node.classification.publisher.publisher_name,
              volume: node.classification.book_volume,
              subject: node.classification.subject.subject_desc,
              status: node.book_status,
              title: node.classification.classification_desc,
              book_category: node.classification.category.category_desc,
              author: node.classification.author.author_name,
              edition: node.classification.book_edition,
              shelf:
                node?.rack_details?.lib_rack_desc +
                " " +
                node?.rack_details?.lib_rack_no,
              last_shelf: EMPTY_STRING,
              classification_no: node.classification.classification_no,
              price: Number(node.classification.last_purchased_rate),
              no_of_pages: Number(node.classification.book_pages),
              //Checking it was previously checked and checking on every render
              isChecked: LeftDepartmentBooks?.find(
                (data) => data.id === node.id && data.isChecked
              )
                ? true
                : false,
            }))
            //filtering it so that the data moved should not appear again while changing pages
            .filter(
              ({ id }) => !RightDeptBooks?.map(({ id }) => id)?.includes(id)
            )
        );
      } else {
        setLeftDepartmentBooks([]);
      }
    }
    // eslint-disable-next-line
  }, [booksFromSelectedDept, booksFromSelectedShelfLoading, deptId, message]);
  useEffect(() => {
    if (token) {
      GetDepartmentData();
    }
  }, [token, GetDepartmentData]);
  const moveBooks = (direction: MoveDirection) => {
    switch (direction) {
      case MoveDirection.RIGHT:
        setRightDeptBooks((prevValues) => [
          ...LeftDepartmentBooks?.filter((data) => data.isChecked)?.map(
            (data) => ({
              ...data,
              isChecked: false,
            })
          ),
          ...prevValues,
        ]);
        setLeftDepartmentBooks(
          LeftDepartmentBooks?.filter((data) => data.isChecked === false)
        );
        break;
      case MoveDirection.LEFT:
        setLeftDepartmentBooks((prevValues) => [
          ...RightDeptBooks?.filter((data) => data.isChecked)?.map((data) => ({
            ...data,
            isChecked: false,
          })),
          ...prevValues,
        ]);
        setRightDeptBooks(
          RightDeptBooks?.filter((data) => data.isChecked === false)
        );
        break;
      default:
        break;
    }
  };
  const updateRacks = () => {
    MoveBooksToDept({
      variables: {
        token,
        book_ids: RightDeptBooks?.map(({ id }) => id),
        dept_id: toDeptId,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetLibBookMaster,
          variables: {
            token,
            input: {
              book_query_type: LibBookMasterQueryType.BOOKS_BY_DEPT_ID,
              ids: [Number(InstId), deptId],
            },
            first: rowsPerPage,
            sortBy: SortBy.ACCESS_NO,
            direction: Direction.ASC,
            access_no: EMPTY_STRING,
            after: null,
          },
        },
        {
          query: GetLibBookMaster,
          variables: {
            token,
            input: {
              book_query_type: LibBookMasterQueryType.BOOKS_BY_DEPT_ID,
              ids: [Number(InstId), toDeptId],
            },
            first: rowsPerPage,
            sortBy: SortBy.ACCESS_NO,
            direction: Direction.ASC,
            access_no: EMPTY_STRING,
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: `Books moved to department ${
            departmentDropdown?.find((data) => data.value === toDeptId)?.label
          }  Successfully`,
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setDeptId(0);
      setToDeptId(0);
      setLeftDepartmentBooks([]);
      setRightDeptBooks([]);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col">
          <Title>Books Department Allocation</Title>
        </div>
        {USE_CONFIG_KEY && (
          <div className="configuration-settings">
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        )}
      </div>

      <div className="books-allocation">
        <div className="row g-0 books-allocation__details">
          <div className="col-2"></div>
        </div>
        <div className="row g-0 books-allocation__details">
          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={
                departmentDropdown! ?? [
                  {
                    label: "",
                    value: "",
                  },
                ]
              }
              value={
                departmentDropdown?.find((data) => data.value === deptId) ??
                null
              }
              openOnFocus
              onChange={(e, newValue) => {
                if (newValue) {
                  setDeptId(newValue?.value!);
                } else {
                  setDeptId(0);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  shelfInputRef?.focus();
                }
                if (e.key === Keys.BACKSPACE) {
                  setDeptId(0);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select From Department"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  autoFocus
                  onChange={(e) => {
                    setSearchShelf(e.target.value);
                  }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>

          <div className="col-2">
            <Autocomplete
              classes={classes}
              ref={shelfRef!}
              options={
                LeftDepartmentBooks.length > 0
                  ? departmentDropdown?.filter(
                      (data) => data.value !== deptId
                    )! ?? [
                      {
                        label: "",
                        value: "",
                      },
                    ]
                  : []
              }
              value={
                departmentDropdown?.find((data) => data.value === toDeptId) ??
                null
              }
              openOnFocus
              onChange={(e, newValue) => {
                if (newValue) {
                  setToDeptId(newValue?.value! ?? 0);
                } else {
                  setToDeptId(0);
                }
                if (newValue?.value) {
                  GetBooksCountByRack({
                    variables: {
                      token,
                      inst_id: InstId,
                      rack_id: newValue?.value,
                    },
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select to Department"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={(e) => {
                    setToSearchShelf(e.target.value);
                  }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        </div>

        <div className="row g-0 books-allocation__tableblock">
          <div className="col booktype-left h-100">
            <div className="books-allocation__tableblock--books-count">
              <div></div>
              <Title variant="subtitle1">
                Selected&nbsp;
                {/* {shelves
                  .find((data) => data.id === deptId)
                  ?.lib_rack_desc.concat(
                    " ",
                    shelves.find((data) => data.id === deptId)?.lib_rack_no!
                  )} */}
              </Title>
              <div className="books-allocation__tableblock--books-count--total">
                <Label> Total Books Selected :</Label>
                <div className="books-allocation__tableblock--books-count--totalcount">
                  {LeftDepartmentBooks?.filter((data) => data.isChecked).length}
                </div>
              </div>
            </div>

            {!LeftDepartmentBooks.length ? (
              <b className="nodata">Sorry, No Books Found </b>
            ) : (
              <TableContainer className="books-allocation__tableblock--table">
                <Table stickyHeader>
                  <EnhancedTableHead
                    numSelected={
                      LeftDepartmentBooks?.filter((data) => data.isChecked)
                        .length
                    }
                    onSelectAllClick={(e) => {
                      handleSelectAllClick(e, MoveDirection.LEFT);
                    }}
                    rowCount={LeftDepartmentBooks?.length}
                    type={MoveDirection.LEFT}
                  />
                  <TableBody>
                    {LeftDepartmentBooks &&
                      (rowsPerPage > 0 &&
                      LeftDepartmentBooks.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      ).length > 0
                        ? LeftDepartmentBooks.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : LeftDepartmentBooks
                      ).map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={
                              LeftDepartmentBooks[page * rowsPerPage + index]
                                ?.isChecked
                            }
                            tabIndex={-1}
                            key={row.id}
                            selected={
                              LeftDepartmentBooks[page * rowsPerPage + index]
                                ?.isChecked
                            }
                          >
                            <TableCell
                              padding="checkbox"
                              id="td-center"
                              className="books-allocation__tableblock--table--slno"
                            >
                              <Checkbox
                                checked={
                                  LeftDepartmentBooks[
                                    page * rowsPerPage + index
                                  ]?.isChecked ?? false
                                }
                                onClick={(event) =>
                                  handleClick(event, row, MoveDirection.LEFT)
                                }
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                disabled={
                                  RightDeptBooks?.filter(
                                    (data) => data.isChecked
                                  ).length > 0
                                }
                              />
                            </TableCell>
                            <TableCell
                              id="td-center"
                              className="books-allocation__tableblock--table--slno"
                            >
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell className="books-allocation__tableblock--table--accessno">
                              {row.access_no}
                            </TableCell>
                            <TableCell
                              className="books-allocation__tableblock--table--title"
                              onClick={() => {
                                setBookDetailsFlag({
                                  flag: !bookDetailsFlag?.flag,
                                  bookDetails: row,
                                });
                              }}
                            >
                              {row.title}
                            </TableCell>
                            <TableCell>{row.author}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={PAGINATION_ARRAY}
                        count={totalCount ?? 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        labelRowsPerPage={TABLE_DATA_PER_PAGE}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            )}
          </div>
          <div className="books-allocation__tableblock--swap-image">
            <div>
              <img
                src={ArrowGreen}
                alt="/"
                onClick={() => {
                  if (
                    LeftDepartmentBooks?.filter((data) => data.isChecked)
                      .length > 0 &&
                    toDeptId !== 0
                  ) {
                    moveBooks(MoveDirection.RIGHT);
                  } else {
                    shelfInputRef.focus();
                  }
                }}
                className={
                  LeftDepartmentBooks?.filter((data) => data.isChecked).length >
                  0
                    ? ""
                    : "opacity"
                }
              />
            </div>

            <br />
            <div>
              <img
                src={ArrowRed}
                alt="/"
                onClick={() => {
                  moveBooks(MoveDirection.LEFT);
                }}
                className={
                  RightDeptBooks?.filter((data) => data.isChecked).length > 0
                    ? ""
                    : "opacity"
                }
              />
            </div>
          </div>

          <div className="col booktype-right h-100">
            <div className="books-allocation__tableblock--books-count">
              <div></div>
              <Title variant="subtitle1">
                Selected &nbsp;
                {/* {toShelves
                  .find((data) => data.id === toDeptId)
                  ?.lib_rack_desc.concat(
                    " ",
                    toShelves.find((data) => data.id === toDeptId)?.lib_rack_no!
                  )} */}
              </Title>
              <div className="books-allocation__tableblock--books-count--total td-link">
                <Label> Books In Shelf :</Label>
                <div
                  className="books-allocation__tableblock--books-count--totalcount"
                  onClick={() => {
                    toDeptId && setViewShelfBooksModal(!viewShelfBooksModal);
                  }}
                >
                  {data?.GetLibBookCountByRackId
                    ? data?.GetLibBookCountByRackId
                    : 0}
                </div>
              </div>
            </div>
            <TableContainer className="books-allocation__tableblock--table">
              <Table stickyHeader>
                <EnhancedTableHead
                  numSelected={
                    RightDeptBooks?.filter((data) => data.isChecked).length
                  }
                  onSelectAllClick={(e) => {
                    handleSelectAllClick(e, MoveDirection.RIGHT);
                  }}
                  rowCount={RightDeptBooks?.length}
                  type={MoveDirection.RIGHT}
                />
                <TableBody>
                  {RightDeptBooks &&
                    RightDeptBooks.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, row, MoveDirection.RIGHT)
                          }
                          role="checkbox"
                          aria-checked={row.isChecked}
                          tabIndex={-1}
                          key={row.id}
                          selected={row.isChecked}
                        >
                          <TableCell padding="checkbox" id="td-center">
                            <Checkbox
                              checked={row.isChecked}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              disabled={
                                LeftDepartmentBooks?.filter(
                                  (data) => data.isChecked
                                ).length > 0
                              }
                            />
                          </TableCell>
                          <TableCell id="td-center">{index + 1}</TableCell>
                          <TableCell>{row.access_no}</TableCell>
                          <TableCell>{row.title}</TableCell>
                          <TableCell id="td-center">{row.author}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <div className="button-left">
        <Button
          mode="save"
          onClick={updateRacks}
          disabled={RightDeptBooks?.length === 0}
        />
        <Button mode="clear" onClick={handleClear} />
        <Button
          mode="back"
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
      {/* <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewShelfBooksModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ShelfBooks
              deptId={toDeptId}
              shelfName={
                toShelves.find((data) => data.id === toDeptId)?.lib_rack_desc!
              }
              shelves={shelves}
              setSearchShelf={setSearchShelf}
            />
            <Button
              mode="cancel"
              onClick={() => setViewShelfBooksModal(!viewShelfBooksModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setViewShelfBooksModal(!viewShelfBooksModal)}
            />
          </div>
        </div>
      </Modal> */}
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.LIB_BOOK_RETURN_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <BookDetails
        modalFlag={bookDetailsFlag!}
        setModalFlag={setBookDetailsFlag!}
      />
      <LoadingModal
        flag={MoveBooksToRackLoading || booksFromSelectedShelfLoading}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default BooksDeptAllocation;
