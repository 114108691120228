import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import Input from "../../../stories/Input/Input";
import { EMPTY_STRING, FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../utils/constants";
import { AppContext } from "../../../context/context";
import EduateLogo from "../../../images/Eduate_Logo_image.png";
import {
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  InstitutionConfigurationTypes,
  PageFor,
  ParentQueryType,
  ParentType,
  TableHeaders,
} from "../../../utils/Enum.types";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Eduate from "../../../images/Eduate_Logo_image.png";
import { TitleProps } from "../../../Types/Titles";
import Home from "../Home/Index";

import useParentsData, {
  ParentListEdge,
} from "../../../customhooks/useParentsData";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { payloadTypes } from "../../../context/reducer";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  E4_CELL,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../Library/Constants";

import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import { ParentsListHeaders } from "../../Accounts/common/HeaderConsts";
import Eye from "../../../images/Eye.svg";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useMasterTableJson from "../../../json/useMasterTableJson";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../utils/types";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../styles/DataGridTableStyles";
const { Parents } = require("../../../json/title.json");

interface Prop {
  queryType: ParentQueryType;
  pageType: PageFor;
  setParentsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setParentId?: React.Dispatch<React.SetStateAction<idType | undefined>>;
  ParentName?: string;
}
export interface idType {
  relationship: ParentType;
  parent_id: number;
  name: string;
}

const ParentsList = ({
  ParentName,
  queryType,
  pageType,
  setParentId,
  setParentsModal,
}: Prop) => {
  const { InstId } = useParams();
  const { dispatch, state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const [searchData, setSearchData] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [parentsList, setParentsList] = useState<ParentListEdge[]>([]);
  const navigate = useNavigate();
  const { Masters_Table } = useMasterTableJson();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const dataClasses = useDataGridStyles();

  const { InstDetails } = useInstDetails(1);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;
  const [pdfFlag, setPdfFlag] = useState(false);
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const { parentsData } = useParentsData(rowsPerPage, queryType, searchData);

  const studentCol = [
    { title: "Sl", field: "Slno" },
    { title: "Name", field: "Name" },
    { title: "Parent Type", field: "ParentType" },
    { title: "Mobile", field: "Mobile" },
    { title: "Email", field: "Email" },
  ];

  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(EduateLogo)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const pageWidth = 210; // A4 width in mm
                const pageHeight = 297; // A4 height in mm

                const doc = new jsPDF("portrait", "mm", [
                  pageWidth,
                  pageHeight,
                ]);
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(15);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(255, 255, 255);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0);
                  doc.text(
                    `${InstDetails.data?.nodes[0]?.inst_name}`,
                    pageWidth / 2,
                    startY,
                    { align: "center" }
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(11);
                  doc.text(
                    `${InstDetails.data?.nodes[0]?.inst_address}`,
                    pageWidth / 2,
                    startY + 7,
                    { align: "center" }
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(11);
                  doc.text(
                    `${InstDetails.data?.nodes[0]?.inst_place},${InstDetails.data?.nodes[0]?.inst_pin}`,
                    pageWidth / 2,
                    startY + 12,
                    { align: "center" }
                  );
                  doc.setFontSize(12);
                  doc.text(
                    `FY:${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    15,
                    startY + 21
                  );
                  doc.setFontSize(12);
                  doc.setFont("Times New Roman", "normal");
                  doc.text(`Parents List`, pageWidth / 2, startY + 21, {
                    align: "center",
                  });
                  doc.setFontSize(12);
                  doc.text(
                    `As on date:${toStandardDate(serverDate)}`,
                    155,
                    startY + 21
                  );
                  doc.addImage(result, "JPEG", 15, 5, 21, 21);
                  if (configLogo) {
                    doc.addImage(result2, "JPEG", 250, 5, 20, 20);
                  }
                }
                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: studentCol.map((col) => ({
                    ...col,
                    dataKey: col.field,
                    styles: { fontSize: 18 },
                  })),
                  body: parentsData?.data?.GetParents.edges.map(
                    (data, index) => ({
                      Slno: index + 1,
                      Name:
                        data.node.first_name +
                        " " +
                        data.node.middle_name +
                        " " +
                        data.node.last_name,
                      ParentType: data.node.parent_type,
                      Mobile: data.node.parent_mobile,
                      Email: data.node.parent_email,
                    })
                  ),
                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: function (data) {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);

                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                });

                doc.save(
                  `${InstDetails.data?.nodes[0]?.inst_name} ${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  } `
                );
              });
            });
        });
      });
    setPdfFlag(false);
    setRowsPerPage(ROWS_PER_PAGE);
  };
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Parents List");
    const parents = parentsData?.data?.GetParents.edges.map((data, index) => ({
      Slno: index + 1,
      Name:
        data.node.first_name +
        " " +
        data.node.middle_name +
        " " +
        data.node.last_name,
      ParentType: data.node.parent_type,
      Mobile: data.node.parent_mobile,
      Email: data.node.parent_email,
    }));
    const dataRows = parents
      ? parents?.map((item) => [
          item.Slno,
          item.Name,
          item.ParentType,
          item.Email,
          item.Mobile,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 25;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "E1:E3");

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);
                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;
                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:E2");
                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:E3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "Parents List";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:E4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;

                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < ParentsListHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = ParentsListHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = {
                      horizontal: ExcelAlignment.CENTER,
                    };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, "Parents List");
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(ROWS_PER_PAGE);
                });
              });
          });
        });
      });
  };

  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !parentsData.loading)
      downloadExcel();
    if (pdfFlag && rowsPerPage === null && !parentsData.loading) downloadPdf();
    // eslint-disable-next-line
  }, [excelFlag, pdfFlag, rowsPerPage, parentsData.loading]);
  const dynamicHeaders: TableHeaderProps[] =
    Masters_Table.Parents.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "actions",
      cellClassName: "td-status ",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        const parentId = params.row.parent_id;

        return (
          <>
            <img
              src={Eye}
              alt="/"
              onClick={() => {
                setParentId?.(
                  params.row.parent_type === "Father"
                    ? {
                        parent_id: parentId,
                        relationship: ParentType.FATHER,
                        name: params.row.parent_name,
                      }
                    : params.row.parent_type === "Mother"
                    ? {
                        parent_id: parentId,
                        relationship: ParentType.MOTHER,
                        name: params.row.parent_name,
                      }
                    : {
                        parent_id: parentId,
                        relationship: ParentType.GUARDIAN,
                        name: params.row.parent_name,
                      }
                );
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: {
                    studentId: 0,
                  },
                });
                if (pageType === PageFor.GENERAL) {
                  navigate(
                    `/${InstId}/admissions/parents/${params.row.parent_id}/ParentData`
                  );
                }
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (parentsData.data && !parentsData.loading) {
      const newData = parentsData.data.GetParents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = parentsList.find(
            (parent) => parent.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setParentsList(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,

            parent_name: node.first_name + node.middle_name + node.last_name,
            parent_type: node.parent_type,
            parent_mobile: node.parent_mobile,
            parent_id: node.id,

            parent_email: node.parent_email,
          }))
        );
      } else {
        setParentsList(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,

            parent_name: node.first_name + node.middle_name + node.last_name,
            parent_type: node.parent_type,
            parent_mobile: node.parent_mobile,

            parent_email: node.parent_email,
            parent_id: node.id,
          }))
        );
      }
      setEndCursor(parentsData.data.GetParents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [parentsData.data, parentsData.loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !parentsData.loading) {
          parentsData.fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetParents.edges;
              const pageInfo = fetchMoreResult.GetParents.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetParents.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetParents: {
                  edges: [...parentsList, ...newEdges],
                  pageInfo,
                  totalCount: parentsData.data
                    ? parentsData.data.GetParents.totalCount!
                    : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  useEffect(() => {
    if (ParentName) {
      setSearchData(ParentName);
    }
  }, [ParentName]);

  if (parentsData.error) return <>{parentsData.error.message}</>;
  return (
    <>
      {pageType === PageFor.MODAL ? null : <Home DashBoardRequired={false} />}
      <Title>
        {Parents.Titles.map((title: TitleProps, index: React.Key) => {
          return <React.Fragment key={index}>{title.List}</React.Fragment>;
        })}
      </Title>
      <div className="row g-0">
        <div className="col-2 button-left">
          <Input
            id="search"
            type="text"
            placeholder="Search ..."
            value={searchData}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchData(e.target.value)
            }
          />
        </div>
        <div className="col"></div>

        {pageType === PageFor.MODAL ? null : (
          <div className="col-1">
            <Button
              mode="addnew"
              onClick={() => {
                window.sessionStorage.removeItem("parent-id");
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: {
                    studentId: 0,
                  },
                });

                navigate(`/${InstId}/admissions/parents/1/0/addparent`);
              }}
              autoFocus
              className="container__list--addnew"
            />
          </div>
        )}
      </div>
      <div
        className={
          pageType === PageFor.GENERAL ? "container__list" : "parentlist__modal"
        }
      >
        <div className={`h100 ${dataClasses.root}`}>
          <DataGridPro
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            onCellClick={(params) => {
              if (params.field === "parent_name") {
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: {
                    studentId: 0,
                  },
                });
                setParentId?.(
                  params.row.parent_type === "Father"
                    ? {
                        parent_id: params.row.parent_id,
                        relationship: ParentType.FATHER,
                        name: params.row.parent_name,
                      }
                    : params.row.parent_type === "Mother"
                    ? {
                        parent_id: params.row.parent_id,
                        relationship: ParentType.MOTHER,
                        name: params.row.parent_name,
                      }
                    : {
                        parent_id: params.row.parent_id,
                        relationship: ParentType.GUARDIAN,
                        name: params.row.parent_name,
                      }
                );
                if (pageType === PageFor.GENERAL) {
                  navigate(
                    `/${InstId}/admissions/parents/${params.row.parent_id}/ParentData`
                  );
                }
                if (pageType === PageFor.MODAL) {
                  setParentsModal(false);
                }
              }
            }}
            disableRowSelectionOnClick
            hideFooter
            slotProps={{
              columnsPanel: { disableHideAllButton: true },
            }}
          />
        </div>
      </div>
      <div className="row g-0">
        <div className="col">
          {pageType === PageFor.GENERAL ? (
            <div className="button-left">
              <Button
                onClick={() => {
                  setRowsPerPage(null);
                  setPdfFlag(true);
                }}
                mode="pdf"
              />
              <Button
                onClick={() => {
                  setRowsPerPage(null);
                  setExcelFlag(true);
                }}
                mode="export"
              />

              <Button mode="back" onClick={() => navigate(-1)} />
            </div>
          ) : (
            <Button mode="cancel" onClick={() => setParentsModal?.(false)} />
          )}
        </div>
        <div className="col-2 parentlist__total">
          <div className="student-total-count">
            Total Parents : <b>{parentsData.data?.GetParents.totalCount}</b>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParentsList;
