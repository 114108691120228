import React, { useContext, useEffect } from "react";
import FormTypeImage from "../../../../../images/TotalPercentageMarked.svg";
import { Title } from "../../../../../stories/Title/Title";
import { useNavigate, useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import Compare from "../../../../../images/Compare.svg";
import Assign from "../../../../../images/Assign.svg";
import {
  GetAcdFeedbackFormsData,
  GetAcdFeedbackFormsVars,
} from "../../../../Academics/types/subject/Subject";
import { useLazyQuery } from "@apollo/client";
import { GetAcdFeedbackForms } from "../../../../Academics/queries/general";
import useActiveAcademicYear from "../../../../Academics/hooks/useActiveAcademicYear";
import useToken from "../../../../../customhooks/useToken";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import { InstitutionConfigurationTypes } from "../../../../../utils/Enum.types";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import { toIsoDate, toStandardDate } from "../../../../../utils/UtilFunctions";
import { TODAY_DATE } from "../../../../../utils/constants";
import { AppContext } from "../../../../../context/context";
const Index = () => {
  const { token } = useToken();

  const navigate = useNavigate();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const { allocationId } = useStudentDatabyId();

  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;
  const subjectAllocationLevel =
    configData && configData.data?.GetSwConfigVariables[0].config_string_value;
  const [GetAcdFeedbackFormDropDown, { data: FormDropDown }] = useLazyQuery<
    GetAcdFeedbackFormsData,
    GetAcdFeedbackFormsVars
  >(GetAcdFeedbackForms, {
    variables: {
      acd_yr_id: activeAcdId ? activeAcdId : 0,
      inst_id: InstId!,
      token,
      allotted_level: subjectAllocationLevel!,
      allotted_id: allocationId,
      today_date: toIsoDate(TODAY_DATE),
      query_type: "ON_GOING_FORMS",
      pr_emp_id: 0,
    },
  });

  useEffect(() => {
    if (allocationId && subjectAllocationLevel) {
      GetAcdFeedbackFormDropDown();
    }
  }, [allocationId, subjectAllocationLevel, GetAcdFeedbackFormDropDown]);

  return (
    <>
      <div className="form-type">
        <div className="form-type__title">
          <img src={FormTypeImage} alt="" />
          <Title>Feedback Form</Title>
        </div>
        <div className="form-type__datablock">
          <div className="form-type__datablock--grid">
            {FormDropDown &&
              FormDropDown.GetAcdFeedbackForms.length &&
              FormDropDown.GetAcdFeedbackForms.map((data) => (
                <div className="form-type__datablock--form">
                  <b>{data.form_name}</b>
                  <span
                    className={
                      data.is_academic
                        ? "form-type__datablock--form--acd"
                        : "form-type__datablock--event--acd"
                    }
                  >
                    {data.is_academic ? "Academic" : "Non-Academic"}
                  </span>
                  <div className="form-type__datablock--form--flex">
                    <TextField
                      className="form-type__datablock--form--textfield"
                      disabled
                      label="Start Date"
                      InputLabelProps={{ shrink: true }}
                      value={toStandardDate(data.form_st_date)}
                    />
                    <img src={Compare} alt="" />
                    <TextField
                      className="form-type__datablock--form--textfield"
                      disabled
                      label="End Date"
                      InputLabelProps={{ shrink: true }}
                      value={toStandardDate(data.form_end_date)}
                    />
                  </div>

                  <button
                    className="form-type__datablock--form--button"
                    onClick={() => {
                      data.is_academic
                        ? navigate(
                            `/${InstId}/masters/studentlist/${state.studentId}/${data.form_name}/${data.id}/academics/feedback/form`
                          )
                        : navigate(
                            `/${InstId}/masters/studentlist/${
                              state.studentId
                            }/${data.form_name}/${
                              data.id
                            }/${0}/${0}/academics/feedback/fillform`
                          );
                    }}
                  >
                    Fill Form <img src={Assign} alt="" />
                  </button>
                </div>
              ))}
          </div>
        </div>

        {/* <Button mode="back" onClick={() => navigate(-1)} /> */}
      </div>
    </>
  );
};

export default Index;
