import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import { StudentTestReportStyles } from "../../../../styles/StickyTableStyles";
import Home from "../../Home/Index";
import Modal from "react-modal";
import {
  DeleteLedgerModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import ClassWisePerformance from "./Modal/ClassWisePerformance";
import ParticularStudentResults from "./Modal/ParticularStudentResults";
import useStudentTestMarks, {
  AcdStdMarksQueryType,
  TestMarksNode,
} from "../../hooks/useStudentTestMarks";
import { EMPTY_STRING } from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import {
  getHeaderRowStyle,
  toInputStandardDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { payloadTypes } from "../../../../context/reducer";
import useTestDetails from "../../hooks/useTestDetails";
import useTestStatus, {
  AcdTestMarksStatusQueryType,
} from "../../hooks/useTestStatus";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useInstConfigByEntryId from "../../hooks/useInstConfigByEntryId";
import { responseType } from "../../../../utils/Form.types";
import { isOptionEqualToValue } from "../../../../utils/UtilFunctions";
import ParticularStudentResultsDailyAct from "./Modal/ParticularStudentResultsDailyAct";
import ExcelJS from "exceljs";
import {
  ExcelAlignment,
  ExcelPageHeader,
  ExcelSheetsNames,
  FileUploadParams,
} from "../../../../utils/Enum.types";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  F4_CELL,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS_INST,
  HEADER_ALIGNMENT,
  HEADER_CSS,
  K4_CELL,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import { Cell } from "exceljs";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useCheckAllocationType from "../../hooks/useCheckAllocationType";
import { AcdSubjComboQueryType } from "../../../Accounts/common/QueryTypes";
import useCombinationDetails from "../../CustomAllocation/customHooks/useCombinationDetails";
import { GetAcdComboSubjectOrderWithMarksDetails } from "../../../../queries/customallocation/combination/queries";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdComboSubjectOrderWithMarksDetailsData,
  GetAcdComboSubjectOrderWithMarksDetailsVars,
} from "../../../../Types/Combinations/queries";
import useToken from "../../../../customhooks/useToken";

enum TableHeaders {
  Sl = "Sl",
  Reg = "Reg No.",
  Adm = "Adm No",
  Name = "Student Name",
  Total = "Total",
  Status = "Status",
}

export enum ForReportOrDailyActivities {
  DAILY_ACT = "DAILY_ACT",
  REPORTS = "REPORTS",
}
interface Props {
  page_for: ForReportOrDailyActivities;
}

const View = ({ page_for }: Props) => {
  const navigate = useNavigate();
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const tableClasses = StudentTestReportStyles();
  const { testId, entryId, InstId } = useParams();
  const { flag } = useCheckAllocationType();
  const [classWisePerformance, setClassWisePerformance] = useState(false);
  const [particularStudentResults, setParticularStudentResults] =
    useState(false);
  const [testConductId, setTestConductId] = useState<responseType | null>(null);
  const [combinationId, setCombinationId] = useState<responseType | null>(null);
  const [combinationOptions, setCombinationOptions] = useState<responseType[]>(
    []
  );
  const { statusOfSubject } = useTestStatus(
    AcdTestMarksStatusQueryType.ALL_TESTS,
    Number(entryId)
  );
  const { data: combinationData } = useCombinationDetails(
    AcdSubjComboQueryType.COMBO_BY_TEST_CLASS_ID
  );
  const { token } = useToken();
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { InstDetails } = useInstDetails(1);
  const { dispatch, state } = useContext(AppContext);
  const { testMarks } = useStudentTestMarks(
    flag
      ? AcdStdMarksQueryType.TEST_MARKS_BY_COMBO_ID_AND_TEST_CLASS_ID
      : AcdStdMarksQueryType.TEST_MARKS_AT_ENTRY_LEVEL,
    null,
    EMPTY_STRING
  );

  const { testConductDetails } = useTestDetails();
  const { InstConfigDetails } = useInstConfigByEntryId(entryId!);
  const [GetAcdSubjects, { data: comboSubjectsList }] = useLazyQuery<
    GetAcdComboSubjectOrderWithMarksDetailsData,
    GetAcdComboSubjectOrderWithMarksDetailsVars
  >(GetAcdComboSubjectOrderWithMarksDetails, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      acd_test_class_id: testId!,
      acd_subj_combo_id: combinationId ? combinationId.value : 0,
    },
  });

  const {
    acd_dept_desc,
    acd_branch_desc,
    acd_class_desc,
    acd_semester_desc,
    acd_section_desc,
  } =
    InstConfigDetails && InstConfigDetails.data
      ? InstConfigDetails.data.GetAcdInstConfigNames
      : {
          acd_dept_desc: "",
          acd_branch_desc: "",
          acd_class_desc: "",
          acd_semester_desc: "",
          acd_section_desc: "",
        };
  const { data } = testConductDetails;

  const isSubjAcademic =
    data &&
    data.node.class_subject_details[0].subject_master_details
      .subj_is_non_academic;
  const status = statusOfSubject.data?.GetAcdTestMarksStatus?.find(
    (d) => d?.acd_test_class_id === Number(testId) || testConductId?.value
  );

  const {
    USE_SECTION_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_BRANCH_KEY,
    USE_DEPARTMENT_KEY,
  } = useInstitutionConfiguration();

  useEffect(() => {
    if (testId) {
      setTestConductId(
        statusOfSubject.classTests.find(
          ({ value }) => value === Number(testId)
        ) ?? null
      );
    }
  }, [testId, statusOfSubject.classTests]);

  useEffect(() => {
    if (token) {
      GetAcdSubjects();
    }
  }, [InstId, combinationId, state.ActiveAcdYr, token, GetAcdSubjects]);

  useEffect(() => {
    if (combinationData) {
      setCombinationOptions(
        combinationData.GetComboDetails.map((combo) => ({
          label: combo.subj_combo_name,
          value: combo.id,
        }))
      );
    }
  }, [combinationData]);

  const totalMarks = flag
    ? comboSubjectsList &&
      comboSubjectsList.GetAcdComboSubjectOrderWithMarksDetails.reduce(
        (acc, mark) => {
          acc.total_max_marks += mark.subj_total_max_marks;
          acc.total_min_marks += mark.subj_total_min_marks;
          return acc;
        },
        {
          total_max_marks: 0,
          total_min_marks: 0,
        }
      )
    : data &&
      data.node.class_subject_details.reduce(
        (acc, mark) => {
          acc.total_max_marks += mark.subj_total_max_marks;
          acc.total_min_marks += mark.subj_total_min_marks;
          return acc;
        },
        {
          total_max_marks: 0,
          total_min_marks: 0,
        }
      );

  // eslint-disable-next-line
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(
      ExcelPageHeader.STUDENTS_TEST_RESULT
    );
    // const dataRows = studentReport
    //   ? studentReport.map((data) => ({
    //       regNo: data.regNo,
    //       admNo: data.admNo,
    //       name: data.stdName,
    //       ledgers: data.ldgrName.map((ledger, index) => ({
    //         ledgerName: ledger,
    //         amount: data.OB[index],
    //         demandAmount: data.demandamt[index],
    //         concession: data.concession[index],
    //         refund: data.refunds[index],
    //         receivables: data.receivables[index],
    //         received: data.recieved[index],
    //         balance: data.balance[index],
    //       })),
    //       totalOB: data.totalOB,
    //       totalDemand: data.totalAmt,
    //       totalConcession: data.totalConcession,
    //       totalRefund: data.totalRefund,
    //       totalReceivable: data.totalRec,
    //       totalReceived: data.totalReceived,
    //       totalBalance: data.totalBalance,
    //     }))
    //   : [];
    const tableHeaders = ["Reg No.", "Adm No.", "Student Name"];
    worksheet.views = FROZEN_CELLS_INST;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 33;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 20;
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 30;

    worksheet.getColumn(12).width = 20;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "K1:K3");
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");
                  worksheet.mergeCells(1, 1, 1, 12);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:L2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:L3");

                  const mergedHeader: Cell = worksheet.getCell(F4_CELL);
                  mergedHeader.value = ExcelPageHeader.STUDENTS_TEST_RESULT;
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("F4:J4");
                  const mergedDate: Cell = worksheet.getCell(K4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("K4:L4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR + EMPTY_STRING;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:E4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < tableHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = tableHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = {
                      horizontal: ExcelAlignment.CENTER,
                      vertical: "middle",
                    };
                    worksheet.mergeCells(`${Char}5:${Char}7`);
                  }
                  let subHeaderFirstIndex = "C";
                  let totalMaxMarks = 0;
                  let totalMinMarks = 0;
                  data?.node.class_subject_details.forEach((data, index) => {
                    subHeaderFirstIndex = String.fromCharCode(
                      subHeaderFirstIndex.charCodeAt(0) + 1
                    );
                    worksheet.getColumn(4 + index).width = 20;
                    const subDesc: Cell = worksheet.getCell(
                      subHeaderFirstIndex + 5
                    );
                    const subCode: Cell = worksheet.getCell(
                      subHeaderFirstIndex + 6
                    );
                    const maxMinMarks: Cell = worksheet.getCell(
                      subHeaderFirstIndex + 7
                    );
                    subDesc.value = data.subject_master_details.subj_desc;
                    subCode.value = `(${data.subject_master_details.subj_code})`;
                    maxMinMarks.value = `${data.subj_total_max_marks}(${data.subj_total_min_marks})`;
                    subDesc.fill = TABLE_HEADER_CSS;
                    subDesc.border = BORDER_DATA;
                    subDesc.font = ACC_HEADER_FONT;
                    subDesc.alignment = {
                      horizontal: ExcelAlignment.CENTER,
                    };
                    subCode.fill = TABLE_HEADER_CSS;
                    subCode.border = BORDER_DATA;
                    subCode.font = ACC_HEADER_FONT;
                    subCode.alignment = {
                      horizontal: ExcelAlignment.CENTER,
                    };
                    maxMinMarks.fill = TABLE_HEADER_CSS;
                    maxMinMarks.border = BORDER_DATA;
                    maxMinMarks.font = ACC_HEADER_FONT;
                    maxMinMarks.alignment = {
                      horizontal: ExcelAlignment.CENTER,
                    };
                    totalMaxMarks += data.subj_total_max_marks;
                    totalMinMarks += data.subj_total_min_marks;
                  });
                  const totalCellString = String.fromCharCode(
                    subHeaderFirstIndex.charCodeAt(0) + 1
                  );
                  const totalCell = worksheet.getCell(totalCellString + 5);
                  const totalMarksCell = worksheet.getCell(totalCellString + 7);
                  totalCell.value = "Total";
                  totalCell.fill = TABLE_HEADER_CSS;
                  totalCell.border = BORDER_DATA;
                  totalCell.font = ACC_HEADER_FONT;
                  totalCell.alignment = {
                    horizontal: ExcelAlignment.CENTER,
                    vertical: "middle",
                  };
                  worksheet.getColumn(
                    data?.node.class_subject_details.length! + 4
                  ).width = 20;
                  totalMarksCell.value = `${totalMaxMarks}(${totalMinMarks})`;
                  totalMarksCell.fill = TABLE_HEADER_CSS;
                  totalMarksCell.border = BORDER_DATA;
                  totalMarksCell.font = ACC_HEADER_FONT;
                  totalMarksCell.alignment = {
                    horizontal: ExcelAlignment.CENTER,
                  };
                  worksheet.mergeCells(
                    `${totalCellString}5:${totalCellString}6`
                  );
                  let inc_number = 0;
                  testMarks.data?.GetStdTestMarks.edges.forEach(
                    ({ node }, index) => {
                      const regNoCell = worksheet.getCell(
                        "A" + Number(8 + index)
                      );
                      const admNoCell = worksheet.getCell(
                        "B" + Number(8 + index)
                      );
                      const stdNameCell = worksheet.getCell(
                        "C" + Number(8 + index)
                      );
                      let firstIndexSubject = "C";
                      regNoCell.value = node.student_details.std_reg_no;
                      admNoCell.value = node.student_details.std_adm_no;
                      stdNameCell.value = `${node.student_details.first_name} ${node.student_details.middle_name} ${node.student_details.last_name}`;

                      data?.node.class_subject_details.forEach(
                        (subj, index) => {
                          firstIndexSubject = String.fromCharCode(
                            firstIndexSubject.charCodeAt(0) + 1
                          );
                          const fieldName = `subj_marks_scored_total_${subj.subj_sl} `;

                          const statusField = `subj_marks_status_${subj.subj_sl}`;

                          // generate field name based on index
                          const res = node[fieldName as keyof TestMarksNode]; // access field value using bracket notation
                          const marksEntryStatus = status && [
                            // @ts-ignore
                            status[statusField] as boolean,
                          ];
                          const subMarksCell: Cell = worksheet.getCell(
                            firstIndexSubject + Number(8 + inc_number)
                          );
                          subMarksCell.value =
                            marksEntryStatus && marksEntryStatus[0]
                              ? res.toString()
                              : "-";

                          if (
                            index ===
                            data?.node.class_subject_details.length - 1
                          ) {
                            inc_number++;
                          }
                        }
                      );
                      const totalMarksCellNumber = (firstIndexSubject =
                        String.fromCharCode(
                          firstIndexSubject.charCodeAt(0) + 1
                        ));
                      const totalMarksCell = worksheet.getCell(
                        totalMarksCellNumber + Number(8 + index)
                      );
                      totalMarksCell.value = node.total_scored_marks;
                    }
                  );
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(
                      DOWNLOAD,
                      ExcelSheetsNames.STUDENTS_TEST_RESULT_LIST
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Test Report</Title>
      <div className="view-test-report">
        <div className="view-test-report__options row g-0">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-2">
              <TextField
                className="view-test-report__options--textfield"
                label={departmentLabel}
                InputLabelProps={{ shrink: true }}
                value={acd_dept_desc}
                disabled
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-2">
              <TextField
                className="view-test-report__options--textfield"
                label={branchLabel}
                InputLabelProps={{ shrink: true }}
                value={acd_branch_desc}
                disabled
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-2">
              <TextField
                className="view-test-report__options--textfield"
                label={classLabel}
                InputLabelProps={{ shrink: true }}
                value={acd_class_desc}
                disabled
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-2">
              <TextField
                className="view-test-report__options--textfield"
                label={semesterLabel}
                InputLabelProps={{ shrink: true }}
                value={acd_semester_desc}
                disabled
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-2">
              <TextField
                className="view-test-report__options--textfield"
                label={sectionLabel}
                InputLabelProps={{ shrink: true }}
                value={acd_section_desc}
                disabled
              />
            </div>
          ) : null}
        </div>
        <div className="view-test-report__datablock">
          <div className="row g-0">
            <div className="col-3">
              {page_for === ForReportOrDailyActivities.REPORTS ? (
                <>
                  <Autocomplete
                    classes={classes}
                    options={statusOfSubject.classTests}
                    openOnFocus
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option, testConductId)
                    }
                    value={testConductId}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setTestConductId(newValue);
                      } else {
                        setTestConductId(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        value="Third Internals (Test Name)"
                        label="Tests"
                        classes={{ root: textClasses.formControlRoot }}
                      />
                    )}
                  />
                </>
              ) : (
                flag && (
                  <>
                    <Autocomplete
                      classes={classes}
                      options={combinationOptions}
                      openOnFocus
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(option, combinationId)
                      }
                      value={combinationId}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setCombinationId(newValue);
                          dispatch({
                            type: payloadTypes.SET_ACD_COMBO_ID,
                            payload: {
                              acdCombinationId: newValue.value,
                            },
                          });
                        } else {
                          setCombinationId(null);
                          dispatch({
                            type: payloadTypes.SET_ACD_COMBO_ID,
                            payload: {
                              acdCombinationId: 0,
                            },
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Combinations"
                          classes={{ root: textClasses.formControlRoot }}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </>
                )
              )}
            </div>

            <div className="col">
              <Title>
                Test:{" "}
                {
                  statusOfSubject.classTests.find(
                    ({ value }) => value === testConductId?.value
                  )?.label
                }
              </Title>
            </div>
            <div className="col-3 view-test-report__datablock--date">
              <TextField
                className="view-test-report__options--textfield--date"
                label="Test Start Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                disabled
                value={
                  toInputStandardDate(data?.node.test_start_date!) ||
                  EMPTY_STRING
                }
              />
              <TextField
                className="view-test-report__options--textfield--date"
                label="Test End date"
                InputLabelProps={{ shrink: true }}
                disabled
                type="date"
                value={
                  toInputStandardDate(data?.node.test_end_date!) || EMPTY_STRING
                }
              />
            </div>
          </div>

          {/* to be done  */}

          <div className="view-test-report__tableblock row g-0">
            {flag ? (
              combinationId ? (
                <TableContainer className={tableClasses.table}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          rowSpan={2}
                          className={tableClasses.stickyHeaderSl}
                          id="td-center"
                        >
                          {TableHeaders.Sl}
                        </TableCell>

                        <TableCell
                          rowSpan={2}
                          className={tableClasses.stickyHeaderRegNo}
                          id="td-center"
                        >
                          {TableHeaders.Adm}
                        </TableCell>
                        <TableCell
                          rowSpan={2}
                          className={tableClasses.stickyHeaderAdmNo}
                          id="td-center"
                        >
                          {TableHeaders.Reg}
                        </TableCell>
                        <TableCell
                          rowSpan={2}
                          className={tableClasses.stickyHeaderName}
                          id="td-center"
                        >
                          {TableHeaders.Name}
                        </TableCell>
                        {comboSubjectsList &&
                          comboSubjectsList.GetAcdComboSubjectOrderWithMarksDetails.map(
                            (data, index) => {
                              return (
                                <TableCell
                                  key={index}
                                  className={tableClasses.stickyHeader}
                                  id="td-center"
                                >
                                  <div>{data.subj_desc}</div>
                                  <div>({data.subj_code})</div>
                                </TableCell>
                              );
                            }
                          )}
                        <TableCell
                          className={tableClasses.stickyHeaderTotal}
                          id="td-center"
                        >
                          {TableHeaders.Total}
                        </TableCell>
                        <TableCell
                          className={tableClasses.stickyHeaderPercentage}
                          id="td-center"
                          rowSpan={2}
                        >
                          Percentage(%)
                        </TableCell>
                        {data &&
                        data.node.test_name_details.add_test_remarks ? (
                          <TableCell
                            className={tableClasses.stickyHeader}
                            id="td-center"
                            rowSpan={2}
                          >
                            Remarks
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow>
                        {comboSubjectsList &&
                          comboSubjectsList.GetAcdComboSubjectOrderWithMarksDetails.map(
                            (th, index) => {
                              const res =
                                comboSubjectsList.GetAcdComboSubjectOrderWithMarksDetails.find(
                                  (subject) =>
                                    subject.subj_master_id ===
                                    th.subj_master_id!
                                );
                              if (res)
                                return (
                                  <TableCell
                                    key={index}
                                    className={tableClasses.stickySubHeader}
                                    id="td-center"
                                  >
                                    {`${res.subj_total_max_marks!}(${
                                      res.subj_total_min_marks
                                    })`}
                                  </TableCell>
                                );

                              return <></>;
                            }
                          )}
                        <TableCell
                          className={tableClasses.stickySubHeaderTotal}
                          id="td-center"
                        >
                          {`${totalMarks?.total_max_marks}(${totalMarks?.total_min_marks})
                       `}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {testMarks.data &&
                        testMarks.data.GetStdTestMarks.edges.map(
                          (edge, index) => {
                            const { student_details } = edge.node;

                            return (
                              <TableRow key={index}>
                                <TableCell
                                  className={tableClasses.stickyColumnSl}
                                  id="td-center"
                                >
                                  {index + 1}
                                </TableCell>

                                <TableCell
                                  className={tableClasses.stickyColumnRegno}
                                >
                                  {student_details.std_adm_no}
                                </TableCell>
                                <TableCell
                                  className={tableClasses.stickyColumnAdmno}
                                >
                                  {student_details.std_reg_no}
                                </TableCell>
                                <TableCell
                                  className={tableClasses.stickyColumnName}
                                  onClick={() => {
                                    dispatch({
                                      type: payloadTypes.SET_STUDENT_ID,
                                      payload: {
                                        studentId: student_details.id,
                                      },
                                    });
                                    dispatch({
                                      type: payloadTypes.SET_STD_ADM_NO,
                                      payload: {
                                        stdAdmNo: student_details.std_adm_no,
                                      },
                                    });
                                    setParticularStudentResults(
                                      !particularStudentResults
                                    );
                                  }}
                                >
                                  {`${student_details.first_name} ${student_details.middle_name} ${student_details.last_name}`}
                                </TableCell>
                                {comboSubjectsList &&
                                  comboSubjectsList.GetAcdComboSubjectOrderWithMarksDetails.map(
                                    (subj, index) => {
                                      const fieldName = `subj_marks_scored_total_${subj.subj_sl}`;
                                      const statusField = `subj_marks_status_${subj.subj_sl}`;
                                      const subjectId = `elective_subj_id_${subj.subj_sl}`;

                                      const res =
                                        edge.node[
                                          fieldName as keyof TestMarksNode
                                        ];

                                      const resSubjectId =
                                        edge.node[
                                          subjectId as keyof TestMarksNode
                                        ];
                                      const marksEntryStatus = status && [
                                        // @ts-ignore
                                        status[statusField] as boolean,
                                      ];

                                      return (
                                        <>
                                          <TableCell
                                            id="td-center"
                                            key={index}
                                            style={{
                                              color:
                                                Number(res) >
                                                  subj.subj_total_min_marks &&
                                                Number(res) >= 0
                                                  ? ""
                                                  : "red",
                                            }}
                                            className={`${
                                              tableClasses.stickyColumn
                                            } 
                                     ${
                                       subj.subj_master_id === resSubjectId &&
                                       marksEntryStatus &&
                                       marksEntryStatus[0]
                                         ? // @ts-ignore
                                           res
                                         : resSubjectId === 0
                                         ? // @ts-ignore
                                           res
                                         : "bg-grey"
                                     }
                                      `}
                                          >
                                            {subj.subj_master_id ===
                                              resSubjectId &&
                                            marksEntryStatus &&
                                            marksEntryStatus[0]
                                              ? // @ts-ignore
                                                res >= 0
                                                ? Number(res)
                                                : "A"
                                              : resSubjectId === 0
                                              ? // @ts-ignore
                                                res >= 0
                                                ? Number(res)
                                                : "A"
                                              : ""}
                                          </TableCell>
                                        </>
                                      );
                                    }
                                  )}

                                <TableCell
                                  className={tableClasses.stickyColumnTotal}
                                  id="td-center"
                                >
                                  {edge.node.total_scored_marks}
                                </TableCell>
                                <TableCell
                                  className={
                                    tableClasses.stickyColumnPercentage
                                  }
                                  id="td-center"
                                >
                                  {edge.node.total_percent}
                                </TableCell>

                                {data &&
                                data.node.test_name_details.add_test_remarks ? (
                                  <TableCell
                                    id="td-center"
                                    className={tableClasses.stickyColumnName}
                                  >
                                    {edge.node.test_remarks}
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            );
                          }
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <b className="nodata">Please select combination</b>
              )
            ) : (
              <TableContainer className={tableClasses.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        rowSpan={2}
                        className={tableClasses.stickyHeaderSl}
                        id="td-center"
                      >
                        {TableHeaders.Sl}
                      </TableCell>

                      <TableCell
                        rowSpan={2}
                        className={tableClasses.stickyHeaderRegNo}
                        id="td-center"
                      >
                        {TableHeaders.Adm}
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        className={tableClasses.stickyHeaderAdmNo}
                        id="td-center"
                      >
                        {TableHeaders.Reg}
                      </TableCell>
                      <TableCell
                        rowSpan={2}
                        className={tableClasses.stickyHeaderName}
                        id="td-center"
                      >
                        {TableHeaders.Name}
                      </TableCell>
                      {data &&
                        data.node.class_subject_details.map((data, index) => {
                          return (
                            <>
                              <TableCell
                                key={index}
                                className={tableClasses.stickyHeader}
                                id="td-center"
                              >
                                <div>
                                  {data.subject_master_details.subj_desc}
                                </div>
                                <div>
                                  ({data.subject_master_details.subj_code})
                                </div>
                              </TableCell>
                              {isSubjAcademic && (
                                <TableCell
                                  key={index}
                                  className={tableClasses.stickyHeader}
                                  id="td-center"
                                  rowSpan={2}
                                >
                                  Remarks (
                                  {data.subject_master_details.subj_desc})
                                </TableCell>
                              )}
                            </>
                          );
                        })}
                      {!isSubjAcademic && (
                        <>
                          <TableCell
                            className={tableClasses.stickyHeaderTotal}
                            id="td-center"
                          >
                            {TableHeaders.Total}
                          </TableCell>
                          <TableCell
                            className={tableClasses.stickyHeaderPercentage}
                            id="td-center"
                            rowSpan={2}
                          >
                            Percentage(%)
                          </TableCell>
                        </>
                      )}
                      {data && data.node.test_name_details.add_test_remarks ? (
                        <TableCell
                          id="td-center"
                          className={tableClasses.stickyHeader}
                          rowSpan={2}
                        >
                          Remarks
                        </TableCell>
                      ) : null}
                    </TableRow>
                    <TableRow>
                      {data &&
                        !isSubjAcademic &&
                        data.node.class_subject_details.map((th, index) => {
                          const res = data?.node.class_subject_details?.find(
                            (subject) =>
                              subject.subject_master_details.id ===
                              th.subject_master_details.id!
                          );
                          return (
                            <TableCell
                              key={index}
                              className={tableClasses.stickySubHeader}
                              id="td-center"
                            >
                              {`${res?.subj_total_max_marks!}(${res?.subj_total_min_marks!})`}
                            </TableCell>
                          );
                        })}
                      {!isSubjAcademic && (
                        <TableCell
                          className={tableClasses.stickySubHeaderTotal}
                          id="td-center"
                        >
                          {`${totalMarks?.total_max_marks}(${totalMarks?.total_min_marks})
                   `}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {testMarks.data &&
                      testMarks.data.GetStdTestMarks.edges.map(
                        (edge, index) => {
                          const { student_details } = edge.node;

                          return (
                            <TableRow key={index}>
                              <TableCell
                                className={tableClasses.stickyColumnSl}
                                id="td-center"
                              >
                                {index + 1}
                              </TableCell>

                              <TableCell
                                className={tableClasses.stickyColumnRegno}
                              >
                                {student_details.std_adm_no}
                              </TableCell>
                              <TableCell
                                className={tableClasses.stickyColumnAdmno}
                              >
                                {student_details.std_reg_no}
                              </TableCell>
                              <TableCell
                                className={tableClasses.stickyColumnName}
                                onClick={() => {
                                  dispatch({
                                    type: payloadTypes.SET_STUDENT_ID,
                                    payload: {
                                      studentId: student_details.id,
                                    },
                                  });
                                  dispatch({
                                    type: payloadTypes.SET_STD_ADM_NO,
                                    payload: {
                                      stdAdmNo: student_details.std_adm_no,
                                    },
                                  });
                                  setParticularStudentResults(
                                    !particularStudentResults
                                  );
                                }}
                              >
                                {`${student_details.first_name} ${student_details.middle_name} ${student_details.last_name}`}
                              </TableCell>
                              {data &&
                                data.node.class_subject_details.map(
                                  (subj, index) => {
                                    const isNonAcademic =
                                      subj.subject_master_details
                                        .subj_is_non_academic;
                                    const fieldName = isNonAcademic
                                      ? `scored_grade_${subj.subj_sl}`
                                      : `subj_marks_scored_total_${subj.subj_sl}`;
                                    const fieldNonAcd = `teacher_comments_${subj.subj_sl}`;
                                    const resNonAcd = edge.node[
                                      fieldNonAcd as keyof TestMarksNode
                                    ] as string;
                                    const subjectId = `elective_subj_id_${subj.subj_sl}`;

                                    const res = edge.node[
                                      fieldName as keyof TestMarksNode
                                    ] as string;
                                    const resSubjectId =
                                      edge.node[
                                        subjectId as keyof TestMarksNode
                                      ];

                                    return (
                                      <>
                                        {!isNonAcademic && (
                                          <TableCell
                                            id="td-center"
                                            key={index}
                                            style={{
                                              color:
                                                Number(res) >=
                                                  subj.subj_total_min_marks &&
                                                Number(res) >= 0
                                                  ? ""
                                                  : "red",
                                            }}
                                            className={`${
                                              tableClasses.stickyColumn
                                            }
                                           ${
                                             subj.subject_master_details.id ===
                                             resSubjectId
                                               ? // @ts-ignore
                                                 res
                                               : resSubjectId === 0
                                               ? // @ts-ignore
                                                 res
                                               : "bg-grey"
                                           }
                                            `}
                                          >
                                            {subj.subject_master_details.id ===
                                            resSubjectId
                                              ? // @ts-ignore
                                                res >= 0
                                                ? Number(res)
                                                : "A"
                                              : resSubjectId === 0
                                              ? // @ts-ignore
                                                res >= 0
                                                ? Number(res)
                                                : "A"
                                              : ""}
                                          </TableCell>
                                        )}
                                        {isNonAcademic && (
                                          <>
                                            <TableCell
                                              id="td-center"
                                              key={index}
                                              style={{
                                                color:
                                                  res !== EMPTY_STRING
                                                    ? ""
                                                    : "red",
                                              }}
                                              className={`${
                                                tableClasses.stickyColumn
                                              }
                                           ${
                                             subj.subject_master_details.id ===
                                             resSubjectId
                                               ? // @ts-ignore
                                                 res
                                               : resSubjectId === 0
                                               ? // @ts-ignore
                                                 res
                                               : "bg-grey"
                                           }
                                            `}
                                            >
                                              {
                                                // @ts-ignore
                                                res ? res : "Absent"
                                              }
                                            </TableCell>
                                            <TableCell
                                              id="td-center"
                                              key={index}
                                              style={{
                                                color:
                                                  res !== EMPTY_STRING
                                                    ? ""
                                                    : "red",
                                              }}
                                              className={`${
                                                tableClasses.stickyColumn
                                              }
                                           ${
                                             subj.subject_master_details.id ===
                                             resSubjectId
                                               ? // @ts-ignore
                                                 res
                                               : resSubjectId === 0
                                               ? // @ts-ignore
                                                 res
                                               : "bg-grey"
                                           }
                                            `}
                                            >
                                              {
                                                // @ts-ignore
                                                resNonAcd
                                              }
                                            </TableCell>
                                          </>
                                        )}
                                      </>
                                    );
                                  }
                                )}
                              {!isSubjAcademic && (
                                <>
                                  <TableCell
                                    className={tableClasses.stickyColumnTotal}
                                    id="td-center"
                                  >
                                    {edge.node.total_scored_marks}
                                  </TableCell>
                                  <TableCell
                                    className={
                                      tableClasses.stickyColumnPercentage
                                    }
                                    id="td-center"
                                  >
                                    {edge.node.total_percent}
                                  </TableCell>
                                </>
                              )}
                              {data &&
                              data.node.test_name_details.add_test_remarks ? (
                                <TableCell
                                  className={tableClasses.stickyColumnName}
                                  id="td-center"
                                >
                                  {edge.node.test_remarks}
                                </TableCell>
                              ) : null}
                            </TableRow>
                          );
                        }
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
        {/* <Button onClick={() => setClassWisePerformance(!classWisePerformance)}>
          <img src={MarkAttendance} alt="/" />
          Class Wise Performance
        </Button> */}
        {/* <Button mode="pdf" onClick={downloadExcel} /> */}
        <div className="row g-0">
          <div className="col">
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-3 flex-end">
            <div className="student-total-count">
              Test Attended Students :<b>{status?.num_of_std_test_taken}</b>
              <div className="academics-test-report__datablock--grids--cards--status">
                {/* to be implemented later */}
                {/* <span className="academics-test-report__datablock--grids--cards--status--separator">
                  |
                </span>
                <div className="academics-test-report__datablock--grids--cards--status--present">
                  <b>Absent</b>
                  <span className="font-red">05</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={classWisePerformance}
        ariaHideApp={false}
        style={DeleteLedgerModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ClassWisePerformance />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setClassWisePerformance(!classWisePerformance)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={particularStudentResults}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            {page_for === ForReportOrDailyActivities.REPORTS ? (
              <ParticularStudentResults
                setModalFlag={setParticularStudentResults}
                testConductId={testConductId}
              />
            ) : (
              <ParticularStudentResultsDailyAct
                modalFlag={particularStudentResults}
                testDetails={testConductId}
                setModalFlag={setParticularStudentResults}
              />
            )}
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() =>
                setParticularStudentResults(!particularStudentResults)
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default View;
