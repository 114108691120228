import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { TablePaginationActions } from "../../../../pages/CustomTablePagination";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { TableHeaderProps } from "../../../../Types/Tables";
import {
  PAGINATION_ARRAY,
  ROWS_PER_PAGE,
  TABLE_DATA_PER_PAGE,
} from "../../../../utils/constants";
import { PayRollReportsTypes } from "../../../../utils/Enum.types";
import { defaultLabelDisplayedRows } from "../../../../utils/UtilFunctions";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import PaySlipDetails from "./Modals/PaySlipDetails";
import SalaryDetails from "./Modals/SalaryDetails";
import Close from "../../../../images/Close.svg";
import { EmpPaySlipReportTableStyles } from "../../../../styles/StickyTableStyles";

const { HR_Table } = require("../../json/table.json");
interface Props {
  type: PayRollReportsTypes;
}

const tableData = [
  {
    name: "a",
    acno: "2028300508",
    pfcode: "KN?9159/436",
    desn: "lecturer",
    bpay: "0",
    sa: "-",
    gpa: "0",
    gpay: "0",
    pf: "0",
    gip: "0",
    ptax: "0",
    fa: "0",
    gpaloan: "0",
    misc: "0",
    deduction: "0",
    netpay: "0",
  },
];

const enum stickyTableHeaders {
  SL = "Sl",
  Name = "Staff Name",
}
const PaySlip = ({ type }: Props) => {
  const listClasses = ListAutoCompleteStyles();
  const listTextClasses = ListAutoCompleteTextStyles();

  const classes = EmpPaySlipReportTableStyles();

  // const [{ payRollReportName }] = useContext(AppContext);
  const [paySlipModal, setPaySlipModal] = useState(false);
  const [salaryDetails, setSalaryDetails] = useState(false);

  // eslint-disable-next-line
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div className="row g-0 payroll-reports__rightblock--details">
        <div className="col-2">
          <Input id="search" placeholder="Search..." type="text" />
        </div>
        <div className="col-2">
          <Autocomplete
            classes={listClasses}
            options={["a", "b"]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Departments"
                InputLabelProps={{ shrink: true }}
                classes={{ root: listTextClasses.formControlRoot }}
              />
            )}
          />
        </div>
        <div className="col-2">
          <Autocomplete
            classes={listClasses}
            options={["a", "b"]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                InputLabelProps={{ shrink: true }}
                classes={{ root: listTextClasses.formControlRoot }}
              />
            )}
          />
        </div>
        <div className="col-2">
          <Autocomplete
            classes={listClasses}
            options={["a", "b"]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Job Type"
                InputLabelProps={{ shrink: true }}
                classes={{ root: listTextClasses.formControlRoot }}
              />
            )}
          />
        </div>
      </div>
      <div className="payroll-reports__rightblock--data">
        <div className="payroll-reports__rightblock--data--title">
          <Title variant="subtitle1">Payroll Name</Title>
          <div>
            <TextField
              type="month"
              label="Test End Date"
              InputLabelProps={{ shrink: true }}
              value="2022-11"
              className="payroll-reports__rightblock--textfield"
            />
          </div>
        </div>
        <div className="salary-process__prov-salary--table">
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  {HR_Table.SalaryProcess.ProvisionalSalaryDetails.StickyHeaders.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell
                          className={
                            th.labelName === stickyTableHeaders.SL
                              ? classes.stickyHeaderSl
                              : th.labelName === stickyTableHeaders.Name
                              ? classes.stickyHeaderName
                              : classes.stickyHeaderEmpAccNo
                          }
                          key={index}
                          align="center"
                        >
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}

                  {HR_Table.Reports.PaySlip.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell
                          className={classes.stickyHeader}
                          key={index}
                          align="center"
                        >
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((data, index) => {
                  return (
                    <TableRow
                      key={index}
                      onClick={() => {
                        type === PayRollReportsTypes.PAYSLIP
                          ? setPaySlipModal(!paySlipModal)
                          : setSalaryDetails(!salaryDetails);
                      }}
                    >
                      <TableCell
                        id="td-center"
                        className={classes.stickyColumnSl}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className={classes.stickyColumnName}>
                        {data.name}
                      </TableCell>
                      <TableCell className={classes.stickyColumnEmpAccNo}>
                        {data.acno}
                      </TableCell>

                      <TableCell className={classes.stickyColumnDesc}>
                        {data.pf}
                      </TableCell>
                      <TableCell className={classes.stickyColumnDesc}>
                        {data.desn}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={classes.stickyColumnAmount}
                      >
                        {data.bpay}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={classes.stickyColumnAmount}
                      >
                        {data.sa}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={classes.stickyColumnAmount}
                      >
                        {data.gpa}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={classes.stickyColumnAmount}
                      >
                        {data.gpay}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={classes.stickyColumnAmount}
                      >
                        {data.pf}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={classes.stickyColumnAmount}
                      >
                        {data.gip}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={classes.stickyColumnAmount}
                      >
                        {data.ptax}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={classes.stickyColumnAmount}
                      >
                        {data.fa}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={classes.stickyColumnAmount}
                      >
                        {data.gpaloan}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={classes.stickyColumnAmount}
                      >
                        {data.misc}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={classes.stickyColumnAmount}
                      >
                        {data.deduction}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={classes.stickyColumnAmount}
                      >
                        {data.netpay}
                      </TableCell>
                      <TableCell
                        className={classes.stickyColumnAmount}
                      ></TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={PAGINATION_ARRAY}
                    count={totalCount ? totalCount : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={defaultLabelDisplayedRows}
                    labelRowsPerPage={TABLE_DATA_PER_PAGE}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>

      {/* salary-details */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={salaryDetails}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <SalaryDetails />
          </div>
          <div className="modal-flex__image">
            <img
              className="modal-close-icon"
              src={Close}
              alt="/"
              onClick={() => {
                setSalaryDetails(!salaryDetails);
              }}
            />
          </div>
        </div>
      </Modal>
      {/* pay-slip */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={paySlipModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PaySlipDetails />
          </div>
          <div className="modal-flex__image">
            <img
              className="modal-close-icon"
              src={Close}
              alt="/"
              onClick={() => {
                setPaySlipModal(!paySlipModal);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaySlip;
