import Home from "../../Home/Index";
import AccountsHome from "../../../Accounts/Home/Index";

import { Title } from "../../../../stories/Title/Title";
import ReportsList from "./ReportType";

import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import GenerateReport from "./GenerateReport";
import OtherOptions from "./OtherOptions";
import { ExportProps } from "./Index";
import { ExportModuleType } from "../../../../utils/Enum.types";

const MainPage = ({ reportType }: ExportProps) => {
  const navigate = useNavigate();

  const isAccounts = window.location.pathname.includes(
    ExportModuleType.ACCOUNTS
  );
  return (
    <>
      {isAccounts ? (
        <AccountsHome DashBoardRequired={false} />
      ) : (
        <Home DashBoardRequired={false} />
      )}
      <Title>Institution Level Report</Title>
      <div className="inst-level-report">
        <div className="inst-level-report__datablock">
          <div className="inst-level-report__lt">
            <ReportsList reportType={reportType} />
          </div>
          <GenerateReport reportType={reportType} />
          <OtherOptions />
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default MainPage;
