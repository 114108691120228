import React, { useContext, useEffect, useState } from "react";

import Home from "../../Home/Index";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import EditProfile from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import { LibraryLabelNameProps } from "../../../../Types/Labels";
import InputHoc from "../../../../components/common/Input/Index";
import { Form, Formik } from "formik";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  removeMoreSpace,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import Input from "../../../../stories/Input/Input";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { DepartmentType, msgType } from "../../../../utils/Form.types";
import LoadingModal from "../../../../pages/LoadingModal";
import DeleteModal from "../../../../pages/DeleteModal";
import {
  LIBRARY_ROWS_PER_PAGE,
  EMPTY_STRING,
  ROWS_PER_PAGE,
  NOTALLOCATED,
  FETCH_MORE_DATA,
} from "../../../../utils/constants";
import {
  DeptEdge,
  DeptNode,
  GetLibBookDeptsData,
  GetLibBookDeptsVars,
} from "../../../../Types/Library/MasterData/Media/paginationTypes";
import Modal from "react-modal";

import { ByNode as MediaById } from "../../../../queries/Library/Node";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import {
  Direction,
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  SortBy,
  TableHeaders,
} from "../../../../utils/Enum.types";
import Close from "../../../../images/Close.svg";

import MessageModal from "../../../../pages/MessageModal";
//Queries and Mutations
import { AddLibBookDept } from "../../../../queries/Library/MasterData/Media/mutations/new";
import { UpdateLibBookDept } from "../../../../queries/Library/MasterData/Media/mutations/update";
import { DeleteLibBookDept } from "../../../../queries/Library/MasterData/Media/mutations/delete";
import { DepartmentValidation } from "../../../../utils/validationRules";
import { GetLibBookDepts } from "../../../../queries/Library/MasterData/Media/list/byInstId";
import useToken from "../../../../customhooks/useToken";
import LibraryImport from "../../Imports/MasterImport";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  D4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  MEDIA_DESC,
  TABLE_HEADER_CSS,
} from "../../Constants";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useServerDateandTime from "../../customHooks/useServerDateandTime";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryTableJson from "../../json/useLibraryTableJson";
import useLibraryformJson from "../../json/useLibraryformJson";
import { AppContext } from "../../../../context/context";
import { DeptHeader } from "../../../Accounts/common/HeaderConsts";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import { LibraryMasters } from "../../enums/Enum.types";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DataGridTableStyles";
import { TableHeaderProps } from "../../../../utils/types";

interface Props {
  type: string;
  modalFlag?: boolean;
  setModalFlag?: (modalFlag: boolean) => void;
}
export interface MediaByIdData {
  nodes: DeptNode[];
}
export interface MediaByIdVars {
  token: string;
  ids: number[] | number;
}
const Department = ({ type, modalFlag, setModalFlag }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { Library_Table } = useLibraryTableJson();
  const { LibraryFormLabels } = useLibraryformJson();
  const { user_details } = useLoggedInUserDetails();
  const [deleteModal, setDeleteModal] = useState(false);
  const [sortBy] = useState(SortBy.DEPT_NAME);
  const [direction] = useState(Direction.ASC);
  const [searchData, setSearchData] = useState("");
  const [operation, setOperation] = useState(Operation.CREATE);
  const [excelFlag, setExcelFlag] = useState(false);
  const { state } = useContext(AppContext);
  const [importModal, setImportModal] = useState(false);
  const dataClasses = useDataGridStyles();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [deptId, setDeptId] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(
    LIBRARY_ROWS_PER_PAGE
  );

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [departmentList, setDepartmentList] = useState<DeptEdge[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [formData, setFormData] = useState<DepartmentType>({
    dept_name: "",
  });
  const nameRef = document.getElementsByName(MEDIA_DESC)[0] as HTMLInputElement;
  //Queries
  const [GetDepartmentData, { data, loading, error, fetchMore }] = useLazyQuery<
    GetLibBookDeptsData,
    GetLibBookDeptsVars
  >(GetLibBookDepts, {
    variables: {
      token,
      inst_id: InstId!,
      first: ROWS_PER_PAGE,
      sortBy,
      direction,
      deptName: searchData,
      after: null,
    },
  });
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { InstDetails } = useInstDetails(1);
  const { serverDate } = useServerDateandTime();
  const [GetDepartments, { data: MediaByIdData, loading: MediaByIdLoading }] =
    useLazyQuery<MediaByIdData, MediaByIdVars>(MediaById);

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;
  //Mutations
  const [AddDepartment, { loading: creationLoading }] = useMutation(
    AddLibBookDept,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateDepartment, { loading: updationLoading }] = useMutation(
    UpdateLibBookDept,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteDepartment, { loading: deleteLoading }] = useMutation(
    DeleteLibBookDept,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const deptData =
    data &&
    data.GetLibBookDepts.edges
      .filter((data) => data.node.dept_name !== NOTALLOCATED)
      .map((data, index) => ({
        SlNo: index + 1,
        Name: data.node.dept_name,
      }));
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Library Department List");

    const dataRows = deptData
      ? deptData?.map((item) => [item.SlNo, item.Name])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 12;
    worksheet.getColumn(2).width = 35;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 22;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          if (configLogo) {
            worksheet.addImage(imageV, "D1:D3");
          }

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 4);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:D2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:D3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "Library Department List";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("C4:C4");
                  const mergedDate: Cell = worksheet.getCell(D4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("D4:D4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < DeptHeader.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = DeptHeader[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(
                      DOWNLOAD,
                      InstDetails.data?.nodes[0]?.inst_name!
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(LIBRARY_ROWS_PER_PAGE);
                });
              });
          });
        });
      });
  };

  //Methods
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value.toString(),
    }));
  };

  const HandleSubmit = () => {
    const input = {
      dept_name: removeMoreSpace(formData.dept_name),
    };
    if (operation === Operation.CREATE) {
      AddDepartment({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          input,
        },
        refetchQueries: [
          {
            query: GetLibBookDepts,
            variables: {
              token,
              inst_id: InstId!,
              first: ROWS_PER_PAGE,
              sortBy,
              direction,
              deptName: EMPTY_STRING,
              after: null,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          if (type === PageFor.MODAL) {
            setModalFlag?.(!modalFlag);
          }
          setMessage({
            flag: !message.flag,
            message: "Department Added Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }

    if (operation === Operation.UPDATE) {
      UpdateDepartment({
        variables: {
          token,
          id: deptId,
          inst_id: InstId,
          user_details,
          input,
        },
        refetchQueries: [
          {
            query: GetLibBookDepts,
            variables: {
              token,
              inst_id: InstId!,
              first: ROWS_PER_PAGE,
              sortBy,
              direction,
              deptName: EMPTY_STRING,
              after: null,
            },
          },
          {
            query: MediaById,
            variables: {
              token,
              ids: deptId,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: !message.flag,
            message: "Department Updated Successfully",
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };
  const HandleDelete = (id: number) => {
    DeleteDepartment({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetLibBookDepts,
          variables: {
            token,
            inst_id: InstId!,
            first: ROWS_PER_PAGE,
            sortBy,
            direction,
            deptName: EMPTY_STRING,
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: !message.flag,
          message: "Department Deleted Successfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  const handleClear = () => {
    setFormData({
      dept_name: "",
    });
    setOperation(Operation.CREATE);
    nameRef?.focus();
  };
  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setOperation(Operation.CREATE);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (MediaByIdData && !MediaByIdLoading && operation === Operation.UPDATE) {
      const { dept_name } = MediaByIdData.nodes[0];
      setFormData({
        dept_name: dept_name,
      });
    }
  }, [MediaByIdData, MediaByIdLoading, operation]);
  useEffect(() => {
    if (token) {
      GetDepartmentData();
    }
  }, [token, GetDepartmentData]);
  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !loading) {
      downloadExcel();
    }
    // eslint-disable-next-line
  }, [excelFlag, rowsPerPage, loading]);
  const dynamicHeaders: TableHeaderProps[] =
    Library_Table.Department.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));
  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "actions",
      cellClassName: "td-status ",
      headerAlign: "center" as GridAlignment,
      align: "center" as GridAlignment,
      headerName: TableHeaders.ACTION,
      renderCell: (params: GridRenderCellParams) => {
        const deptId = params.row.dept_id;

        return (
          <>
            <img
              src={EditProfile}
              alt="/"
              onClick={() => {
                GetDepartments({
                  variables: {
                    token,
                    ids: deptId,
                  },
                }).then(({ data }) => {
                  //enableSave should always stay true if edit is clicked
                  if (data) {
                    setDeptId(deptId);
                    setOperation(Operation.UPDATE);
                  }
                });
              }}
            />
            &nbsp;
            <img
              src={Delete}
              alt="/"
              onClick={() => {
                setDeptId(deptId);
                setOperation(Operation.DELETE);
                setDeleteModal(!deleteModal);
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (data && !loading && excelFlag === false) {
      const newData = data.GetLibBookDepts.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newMedia) => {
          const filteredStudent = departmentList.find(
            (media) => media.node.id === newMedia.node.id
          );
          if (filteredStudent) {
            return {
              ...newMedia,
              node: {
                ...newMedia.node,
              },
            };
          }
          return newMedia;
        });
        setDepartmentList(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            name: node.dept_name,

            media_id: node.id,
          }))
        );
      } else {
        setDepartmentList(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            name: node.dept_name,

            dept_id: node.id,
          }))
        );
      }
      setEndCursor(data.GetLibBookDepts.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetLibBookDepts.edges;
              const pageInfo = fetchMoreResult.GetLibBookDepts.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetLibBookDepts.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetLibBookDepts: {
                  edges: [...departmentList, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetLibBookDepts.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [rows]);
  if (error) return <>{error}</>;
  return (
    <>
      {type === PageFor.MODAL ? null : <Home DashBoardRequired={false} />}
      <Title>Department</Title>

      <Formik
        initialValues={formData}
        validationSchema={DepartmentValidation}
        onSubmit={HandleSubmit}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form
              className={
                type === PageFor.MODAL
                  ? "library__frame--modal"
                  : "library__frame"
              }
            >
              <div className="library__frame--row">
                <div className="h-100 booktype-left">
                  <div className="library__frame--title">
                    <Title variant="subtitle1">
                      {operation === Operation.UPDATE
                        ? "Update Department"
                        : "Add Department"}
                    </Title>
                  </div>
                  <div className="library__frame--block">
                    {LibraryFormLabels.Department.InputType.map(
                      (label: LibraryLabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <InputHoc
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              inputref={nameRef!}
                              values={
                                formData[label.inputName] === 0
                                  ? EMPTY_STRING
                                  : formData[label.inputName]
                              }
                              type={label.dataType}
                              required={label.required}
                              autoFocus={label.autoFocus}
                              LabelName={label.LabelName}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="h-100 booktype-right">
                  <div className="row g-0 library__frame--title">
                    <div className="col-3"></div>
                    <div className="col">
                      <Title variant="subtitle1">List of Department's</Title>
                    </div>
                    <div className="col-3">
                      <Input
                        id="search"
                        placeholder="Search..."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setSearchData(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={`library__frame--block ${dataClasses.root}`}>
                    {!departmentList?.length ? (
                      <b className="nodata">Sorry, no results.</b>
                    ) : (
                      <DataGridPro
                        columns={columns}
                        rows={rows}
                        rowHeight={TABLE_ROW_HEIGHT}
                        disableRowSelectionOnClick
                        hideFooter
                        slotProps={{
                          columnsPanel: { disableHideAllButton: true },
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="library__buttons">
                <Button mode="save" type="submit" />
                <Button mode="clear" type="button" onClick={handleClear} />
                {type === PageFor.GENERAL ? (
                  operation === Operation.CREATE ? (
                    <>
                      <Button
                        mode="back"
                        type="button"
                        onClick={() => navigate(-1)}
                      />
                      <Button
                        mode="excel"
                        type="button"
                        onClick={() => setImportModal(!importModal)}
                      >
                        Import Library Department
                      </Button>
                      <Button
                        mode="excel"
                        type="button"
                        onClick={() => {
                          setRowsPerPage(null);
                          setExcelFlag(true);
                        }}
                      >
                        Export
                      </Button>
                    </>
                  ) : (
                    <Button
                      mode="back"
                      type="button"
                      onClick={() => setOperation(Operation.CREATE)}
                    />
                  )
                ) : (
                  <Button
                    mode="cancel"
                    type="button"
                    onClick={() => setModalFlag?.(false)}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <LibraryImport MasterType={LibraryMasters.DEPARTMENT} />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setImportModal(!importModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      <LoadingModal
        flag={creationLoading ?? updationLoading ?? deleteLoading}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={deptId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default Department;
