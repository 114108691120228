import React, { useContext, useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { Label } from "../../../stories/Label/Label";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Direction, Operation, SortBy } from "../../../utils/Enum.types";
import { TextField, Autocomplete } from "@mui/material";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import { DeleteParticularFeeFromAcctStdDemandDetails } from "../queries/demands/mutation/index";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import {
  formatter,
  getModifiedScrollHeight,
} from "../../../utils/UtilFunctions";
import { TableHeaderProps } from "../../../Types/Tables";
import Home from "../Home/Index";
import DownArrow from "../../../images/DownArrow.svg";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import useToken from "../../../customhooks/useToken";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { Keys } from "../../../utils/Enum.keys";
import MessageModal from "../../../pages/MessageModal";
import { msgType, responseType } from "../../../utils/Form.types";
import { GetStudentsByAcctLdgrId } from "../../../queries/students/list/newApi";
import {
  GetStudentsByAcctLdgrIdListEdges,
  GetStudentsByAcctLdgrIdVars,
  GetStudentsByLedgerIdData,
} from "../../../Types/Accounting";
import useDropdownData from "../../../customhooks/useDropdownData";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import useAcctLedgerData from "../hooks/useAcctLedgerData";
import LoadingModal from "../../../pages/LoadingModal";
import useNotAlloactedIdFromInst from "../../../customhooks/useNotAlloactedIdFromInst";
import { AcctLedgerQueryType } from "../common/QueryTypes";
import StudentTotalCount from "../../Master/Student/Components/StudentTotalCount";
import useAcctTableJson from "../json/useAcctTableJson";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import {
  DeleteParticularFeeFromAcctStdDemandDetailsData,
  DeleteParticularFeeFromAcctStdDemandDetailsVars,
} from "../../../Types/Accounting/mutations";

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}
interface EnhancedTableToolbarProps {
  numSelected: number;
}

const DeleteParticularFeeInFeeDemand = () => {
  const { Accounts_Table } = useAcctTableJson();
  const { format } = formatter;
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [branchId, setBranchId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [classId, setClassId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [categoryId, setCategoryId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [accountLedgerId, setAccountLedgerId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [searchData, setSearchData] = useState("");
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const accountLedgerRef = useRef<HTMLSelectElement>(null);
  const accountLedgerRefInputRef = accountLedgerRef.current?.childNodes[0]
    .childNodes[0].childNodes[0] as HTMLInputElement;
  const { branchDropDown } = useInstMasterDataByInstId();
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<GetStudentsByAcctLdgrIdListEdges[]>(
    []
  );
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const { user_details } = useLoggedInUserDetails();
  const { NotAllocatedCategoryId } = useNotAlloactedIdFromInst();
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();
  const [GetStudents, { data, fetchMore }] = useLazyQuery<
    GetStudentsByLedgerIdData,
    GetStudentsByAcctLdgrIdVars
  >(GetStudentsByAcctLdgrId, {
    variables: {
      admNo: EMPTY_STRING,
      inst_id: InstId!,
      after: null,
      orderBy: [{ direction: Direction.ASC, field: SortBy.STD_ADM_NO }],
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      first: ROWS_PER_PAGE,
      branch_id: branchId.value,
      class_id: classId.value,
      acct_ldgr_id: accountLedgerId.value,
      category_id: USE_CATEGORY_KEY ? categoryId.value : NotAllocatedCategoryId,
      name: EMPTY_STRING,
      token,
    },
  });
  const { classDropDown } = useDropdownData(0, branchId.value, 0, 0);
  const { categoryDropDown } = useInstMasterDataByInstId();
  //custom hooks
  const { acctLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.STD_FEE_ACCT_LDGRS,
    ROWS_PER_PAGE
  );
  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  //mutation
  const [DeletePartcularFeeRaisedForstudents, { loading }] = useMutation<
    DeleteParticularFeeFromAcctStdDemandDetailsData,
    DeleteParticularFeeFromAcctStdDemandDetailsVars
  >(DeleteParticularFeeFromAcctStdDemandDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data?.GetStudentsByAcctLdgrId.edges.map((n) =>
        n.node.id.toString()
      );
      setSelected(newSelecteds!);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleParticularFeeUpdate = () => {
    if (state.ActiveFinYr === null) {
      setMessage({
        flag: true,
        message: "Financial Year not found",
        operation: Operation.NONE,
      });
      return;
    }
    DeletePartcularFeeRaisedForstudents({
      variables: {
        token,
        acct_ldgr_id: accountLedgerId.value,
        student_ids: selected,
        fin_yr_id: state.ActiveFinYr.id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetStudentsByAcctLdgrId,
          variables: {
            admNo: EMPTY_STRING,
            inst_id: InstId!,
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            branch_id: branchId.value,
            class_id: classId.value,
            acct_ldgr_id: accountLedgerId.value,
            category_id: categoryId.value,
            name: EMPTY_STRING,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data)
        setMessage({
          message: "SucessFully Updated data",
          flag: true,
          operation: Operation.UPDATE,
        });
    });
  };
  const handleClear = () => {
    setBranchId(EMPTY_RESPONSETYPE_OBJECT);
    setClassId(EMPTY_RESPONSETYPE_OBJECT);
    setCategoryId(EMPTY_RESPONSETYPE_OBJECT);
    setAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
    setSelected([]);
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetStudentsByAcctLdgrId.edges;
            const pageInfo = fetchMoreResult.GetStudentsByAcctLdgrId.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetStudentsByAcctLdgrId.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );
            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetStudentsByAcctLdgrId: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data?.GetStudentsByAcctLdgrId.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetStudentsByAcctLdgrId.edges;

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data.GetStudentsByAcctLdgrId.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  useEffect(() => {
    if (token && classId.value && branchId.value && accountLedgerId.value) {
      GetStudents();
    }
  }, [
    token,
    classId.value,
    branchId.value,
    GetStudents,
    accountLedgerId.value,
  ]);

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {Accounts_Table.FeeDemand.DeleteParticularFeeDemand.Table_Headers.map(
            (th: TableHeaderProps, index: React.Key) => {
              return (
                <React.Fragment key={index}>
                  <TableCell className={th.className}>{th.labelName}</TableCell>
                </React.Fragment>
              );
            }
          )}
        </TableRow>
      </TableHead>
    );
  };
  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const { numSelected } = props;

    return (
      <Title variant="subtitle1">
        Selected Students: <b>{numSelected}</b>
      </Title>
    );
  };
  const { branchLabel, classLabel,categoryLabel } = useInstLabels();

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Delete a Fee Ledger from Student Demand</Title>
      <div className="feedemand__delete">
        <div className="row g-0 feedemand__delete--block">
          <div className="col-5 account-frames h-100">
            <div className="label-grid">
              <Label>{branchLabel}</Label>
              <Autocomplete
                classes={classes}
                options={branchDropDown}
                openOnFocus
                value={branchId}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (branchId.value && e.key === Keys.ENTER) {
                    classInputRef?.focus();
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchId(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchId(newValue);
                  } else {
                    setBranchId(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setClassId(EMPTY_RESPONSETYPE_OBJECT);
                  setCategoryId(EMPTY_RESPONSETYPE_OBJECT);
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    autoFocus
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />

              <Label>{classLabel}</Label>
              <Autocomplete
                classes={classes}
                options={classDropDown}
                openOnFocus
                ref={classRef}
                value={classId}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && classId.value) {
                    categoryInputRef?.focus();
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassId(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassId(newValue);
                  } else {
                    setClassId(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />

              {USE_CATEGORY_KEY ? (
                <>
                  <Label>{categoryLabel}</Label>
                  <Autocomplete
                    classes={classes}
                    options={categoryDropDown}
                    openOnFocus
                    ref={categoryRef}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER && categoryId) {
                        accountLedgerRefInputRef?.focus();
                      }
                      if (e.key === Keys.BACKSPACE) {
                        setCategoryId(EMPTY_RESPONSETYPE_OBJECT);
                      }
                    }}
                    value={categoryId}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setCategoryId(newValue);
                      } else {
                        setCategoryId(EMPTY_RESPONSETYPE_OBJECT);
                      }
                    }}
                    popupIcon={<img src={DownArrow} alt="/" />}
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        classes={{ root: textClasses.formControlRoot }}
                      />
                    )}
                  />
                </>
              ) : null}
            </div>
          </div>

          <div className="col account-frames h-100">
            <div className="label-grid">
              <Label>Fee to be deleted from Demand</Label>
              <Autocomplete
                classes={classes}
                options={acctLedgers.responseType}
                openOnFocus
                freeSolo
                value={accountLedgerId}
                onChange={(e, newValue) => {
                  if (newValue) {
                    const data = newValue as responseType;
                    setAccountLedgerId({
                      label: data.label,
                      value: data.value,
                      isChecked: true,
                    });
                  } else {
                    setAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && categoryId) {
                    accountLedgerRefInputRef?.focus();
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                ref={accountLedgerRef}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    onChange={(e) => setSearchData(e.target.value)}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="feedemand__delete--tableblock">
          {branchId && classId && categoryId ? (
            <>
              <div className="feedemand__delete--subtitle">
                <EnhancedTableToolbar numSelected={selected.length} />
              </div>
              <TableContainer
                className="feedemand__delete--table"
                onScroll={handleScroll}
              >
                {students?.length ? (
                  <Table stickyHeader>
                    <EnhancedTableHead
                      numSelected={selected.length}
                      onSelectAllClick={handleSelectAllClick}
                      rowCount={
                        data ? data.GetStudentsByAcctLdgrId.totalCount : 0
                      }
                    />
                    <TableBody>
                      {students.map((edge, index) => {
                        const isItemSelected = isSelected(
                          edge.node.id.toString()
                        );
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              handleClick(event, edge.node.id.toString())
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            key={edge.node.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox" id="td-center">
                              <Checkbox checked={isItemSelected} />
                            </TableCell>
                            <TableCell
                              id="td-center"
                              className="feedemand__delete--table--slno"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell className="feedemand__delete--table--regno">
                              {edge.node.std_adm_no}
                            </TableCell>
                            <TableCell className="feedemand__delete--table--regno">
                              {edge.node.std_reg_no}
                            </TableCell>
                            <TableCell id={labelId}>
                              {edge.node.first_name +
                                " " +
                                edge.node.middle_name +
                                " " +
                                edge.node.last_name}
                            </TableCell>
                            <TableCell className="feedemand__delete--table--desc">
                              {edge.node.category.cat_desc}
                            </TableCell>
                            <TableCell className="feedemand__delete--table--desc">
                              {edge.node.class.class_desc}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="feedemand__delete--table--amt"
                            >
                              {format(
                                edge.node.acct_std_demand_details?.filter(
                                  (detail) =>
                                    detail?.acct_ldgr_details.id ===
                                    accountLedgerId.value
                                )[0]?.fee_demand
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  <b className="nodata">Sorry, No Students Found</b>
                )}
              </TableContainer>
            </>
          ) : (
            <b className="nodata">Please select all the fields</b>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              mode="delete"
              onClick={handleParticularFeeUpdate}
              disabled={
                !selected.length || !branchId || !classId || !categoryId
              }
            />
            {branchId || classId || categoryId || accountLedgerId ? (
              <Button mode="clear" onClick={handleClear} />
            ) : null}
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 feedemand__delete--total">
            <StudentTotalCount
              totalCount={data?.GetStudentsByAcctLdgrId.totalCount!}
            />
          </div>
        </div>
      </div>

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={loading} />
    </>
  );
};

export default DeleteParticularFeeInFeeDemand;
