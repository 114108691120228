import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummaryInLessonPlanner,
} from "../../../styles/Accordion";
import { Drawer, Typography } from "@mui/material";
import Edit from "../../../images/EditProfile.svg";
import Delete from "../../../images/DeleteRed.svg";
import Add from "../../../images/CreateChapter.svg";
import Attachment from "../../../images/Attachment.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Clock from "../../../images/Timer.svg";
import { attendanceOverViewStyles } from "../../../styles/DrawerStyles";
import CreateSubChapter from "./CreateSubChapter";
import Modal from "react-modal";
import { setDurationModalStyles } from "../../../styles/ModalStyles";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import Close from "../../../images/Close.svg";
import New from "./New";
import { Operation } from "../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetAcdLessonPlannerMain } from "../queries/lesson_planner/list";
import {
  GetAcdLessonPlannerMainData,
  GetAcdLessonPlannerMainVars,
} from "../types/lessonplanner/queries";
import useToken from "../../../customhooks/useToken";
import useInstConfigByEntryId from "../hooks/useInstConfigByEntryId";
import { useParams } from "react-router-dom";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import { SubjectFormData, SubjectType } from "../types/subject/Subject";
import Preview from "./Preview";
import {
  DeleteAcdLessonPlannerDetailsById,
  DeleteAcdLessonPlannerMainById,
  UpdateAcdLessonPlannerDetails,
} from "../queries/lesson_planner/mutation";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { EMPTY_STRING, emptyMessageType } from "../../../utils/constants";
import DeleteModal from "../../../pages/DeleteModal";
import MessageModal from "../../../pages/MessageModal";
import { GetAcdSubjectForEntryLevel } from "../queries/subjects/List.tsx/Index";
import {
  DeleteAcdLessonPlannerDetailsByIdData,
  DeleteAcdLessonPlannerDetailsByIdVars,
  DeleteAcdLessonPlannerMainByIdData,
  DeleteAcdLessonPlannerMainByIdVars,
  UpdateAcdLessonPlannerDetailsData,
  UpdateAcdLessonPlannerDetailsVars,
} from "../types/lessonplanner/mutations";
import { convertMinsToHours } from "../../../utils/UtilFunctions";
import { totalType } from "./Create";
import { AppContext } from "../../../context/context";
import Time from "../../../components/common/Time/Index";
import useCheckAllocationType from "../hooks/useCheckAllocationType";

interface Props {
  selectedSubject: SubjectType | null;
  setTotals: React.Dispatch<React.SetStateAction<totalType>>;
}
export interface editDetailsType {
  chapter_name: string;
  id: number;
}

export const defaultEditDetailsType: editDetailsType = {
  chapter_name: EMPTY_STRING,
  id: 0,
};
export interface editSubChapterType {
  lesson_main_id: number;
  flag: boolean;
  sub_topic_id: number;
  operation: Operation;
}
export const defaultEditSubChapter: editSubChapterType = {
  lesson_main_id: 0,
  flag: false,
  sub_topic_id: 0,
  operation: Operation.CREATE,
};

const List = ({ selectedSubject, setTotals }: Props) => {
  const [editMainChapter, setEditMainChapter] = useState(false);

  const [editSubChapter, setEditSubChapter] = useState<editSubChapterType>(
    defaultEditSubChapter
  );
  const drawerClasses = attendanceOverViewStyles();

  const [lesson_main_id, setLessonMainId] = useState(0);
  const { state } = useContext(AppContext);
  const [duration, setDuration] = useState(false);
  const [hrs, setHrs] = useState("");
  const [mins, setMinutes] = useState("");

  const { token } = useToken();
  const { entryId, InstId } = useParams();

  const [editDetails, setEditDetails] = useState<editDetailsType>(
    defaultEditDetailsType
  );

  const [sub_topic_id, setSubTopicId] = useState(0);
  const [deleteId, setDeleteId] = useState(0);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalForSubTopic, setDeleteModalForSubTopic] = useState(false);

  const [message, setMessage] = useState(emptyMessageType);

  const { entry_level } = useInstitutionConfiguration();

  const { activeAcademicYearData } = useActiveAcademicYear();
  const { user_details } = useLoggedInUserDetails();
  const [selectedLessonMainIds, setSelectedLessonMainIds] = useState<number[]>(
    []
  );

  const { flag } = useCheckAllocationType();
  const [DeleteAcdLesson] = useMutation<
    DeleteAcdLessonPlannerMainByIdData,
    DeleteAcdLessonPlannerMainByIdVars
  >(DeleteAcdLessonPlannerMainById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [DeleteAcdLessonPlannerDetails] = useMutation<
    DeleteAcdLessonPlannerDetailsByIdData,
    DeleteAcdLessonPlannerDetailsByIdVars
  >(DeleteAcdLessonPlannerDetailsById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [UpdateAcdLessonDetail] = useMutation<
    UpdateAcdLessonPlannerDetailsData,
    UpdateAcdLessonPlannerDetailsVars
  >(UpdateAcdLessonPlannerDetails);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isSaved, setIsSaved] = useState(true);

  const {
    InstConfigDetails: { alloted },
  } = useInstConfigByEntryId(Number(entryId));

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  const openFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
  };

  const handleSave = () => {
    setIsSaved(true);
  };

  const [GetAcdLesson, { data }] = useLazyQuery<
    GetAcdLessonPlannerMainData,
    GetAcdLessonPlannerMainVars
  >(GetAcdLessonPlannerMain, {
    variables: {
      token,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      allotted_id: alloted.id,
      allotted_level: alloted.level,
      inst_id: InstId!,
      subj_master_id:
        selectedSubject && selectedSubject.id ? selectedSubject.id : 0,
    },
  });

  useEffect(() => {
    if (
      token &&
      activeAcademicYearData.data &&
      alloted.id &&
      alloted.level &&
      selectedSubject
    )
      GetAcdLesson();
  }, [
    GetAcdLesson,
    token,
    activeAcademicYearData.data,
    alloted.id,
    alloted.level,
    selectedSubject,
    data,
    state.ActiveAcdYr,
  ]);

  useEffect(() => {
    if (data) {
      setTotals({
        total_chapters: data.GetAcdLessonPlannerMain.length,
        total_duration: data.GetAcdLessonPlannerMain.reduce((acc, item) => {
          acc += item.total_duration;
          return acc;
        }, 0),
      });
    }
  }, [data]);

  const handleChange =
    (lesson_main_id: number) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      const isExpandIconClicked =
        (event.target as HTMLElement).classList.contains(
          "MuiIconButton-root"
        ) ||
        (event.target as HTMLElement).classList.contains("MuiSvgIcon-root");
      let tempMainIds = selectedLessonMainIds;

      if (isExpandIconClicked) {
        if (tempMainIds.includes(lesson_main_id)) {
          tempMainIds = tempMainIds.filter((res) => res !== lesson_main_id);
        } else {
          tempMainIds = [...tempMainIds, lesson_main_id];
        }
        setSelectedLessonMainIds(tempMainIds);
      }
    };

  const handleDelete = (id: number) => {
    DeleteAcdLesson({
      variables: {
        token,
        lesson_main_id: id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdLessonPlannerMain,
          variables: {
            token,
            acd_yr_id: activeAcademicYearData.data
              ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
              : 0,
            allotted_id: alloted.id,
            allotted_level: alloted.level,
            inst_id: InstId!,
            subj_master_id: selectedSubject ? selectedSubject.id : 0,
          },
        },
        {
          query: GetAcdSubjectForEntryLevel,
          variables: {
            token,
            inst_id: InstId!,
            acd_yr_id: activeAcademicYearData.data
              ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
              : 0,
            entry_id: Number(entryId)!,
            entry_level: entry_level,
            per_std_subj_allocation: flag,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.DeleteAcdLessonPlannerMainById) {
        setDeleteModal(false);
        setDeleteId(0);
        setMessage({
          flag: true,
          message: "Sub Topic deleted successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const handleDeleteDetails = (id: number) => {
    setDeleteModalForSubTopic(false);
    DeleteAcdLessonPlannerDetails({
      variables: {
        token,
        lesson_details_id: id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdLessonPlannerMain,
          variables: {
            token,
            acd_yr_id: activeAcademicYearData.data
              ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
              : 0,
            allotted_id: alloted.id,
            allotted_level: alloted.level,
            inst_id: InstId!,
            subj_master_id: selectedSubject ? selectedSubject.id : 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.DeleteAcdLessonPlannerDetailsById) {
        setDeleteModal(false);
        setDeleteId(0);
        setMessage({
          flag: true,
          message: "Sub Topic deleted successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const updateDuration = () => {
    UpdateAcdLessonDetail({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        lesson_details_id: sub_topic_id,
        input: {
          duration_in_mins:
            Number(hrs) && Number(mins) ? Number(mins) + Number(hrs) * 60 : 0,
        },
      },
      refetchQueries: [
        {
          query: GetAcdLessonPlannerMain,
          variables: {
            token,
            acd_yr_id: activeAcademicYearData.data
              ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
              : 0,
            allotted_id: alloted.id,
            allotted_level: alloted.level,
            inst_id: InstId!,
            subj_master_id: selectedSubject ? selectedSubject.id : 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.UpdateAcdLessonPlannerDetails) {
        setDuration(!duration);
        setMessage({
          flag: true,
          message: "Lesson plan updated successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };

  return (
    <>
      {data && data.GetAcdLessonPlannerMain.length ? (
        <div className="create-lesson-planner__topics">
          {isSaved ? (
            <div className="lesson-planner-list">
              {data
                ? data.GetAcdLessonPlannerMain.map((mainResponse, index) => (
                    <Accordion
                      key={index}
                      expanded={selectedLessonMainIds.includes(mainResponse.id)}
                      onChange={handleChange(mainResponse.id)}
                    >
                      <AccordionSummaryInLessonPlanner
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}d-content`}
                      >
                        <Typography>
                          <div className="lesson-planner-list--header">
                            <div className="lesson-planner-list--title">
                              <div>
                                {index + 1}.&nbsp;
                                {mainResponse.chapter_name}&nbsp;
                                <img
                                  src={Edit}
                                  alt=""
                                  onClick={() => {
                                    setEditDetails({
                                      chapter_name: mainResponse.chapter_name,
                                      id: mainResponse.id,
                                    });
                                    setEditMainChapter(!editMainChapter);
                                  }}
                                />
                              </div>
                              <div className="lesson-planner-list--title--durations">
                                <span>Total Sub topics -</span>
                                <b>{mainResponse.total_sub_topic}</b>
                                <div className="lesson-planner-list--title--separator"></div>
                                <span>Total Duration -</span>
                                <b>
                                  {convertMinsToHours(
                                    mainResponse.total_duration
                                  )}
                                </b>
                              </div>
                            </div>
                            <div>
                              <img
                                src={Delete}
                                alt=""
                                onClick={() => {
                                  setDeleteId(mainResponse.id);
                                  setDeleteModal(true);
                                }}
                              />
                              <img
                                src={Add}
                                alt=""
                                onClick={() =>
                                  setEditSubChapter({
                                    flag: true,
                                    lesson_main_id: mainResponse.id,
                                    operation: Operation.CREATE,
                                    sub_topic_id: 0,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </Typography>
                      </AccordionSummaryInLessonPlanner>
                      <AccordionDetails>
                        <div>
                          {mainResponse.sub_topic_details.length
                            ? mainResponse.sub_topic_details.map(
                                (subResponse, subtitleIndex) => (
                                  <Typography key={subtitleIndex}>
                                    <div className="lesson-planner-list--subtitle">
                                      <div className="lesson-planner-list--subtitle--flex">
                                        <div>
                                          {index + 1}. {subtitleIndex + 1}.{" "}
                                          {subResponse.sub_topic_name}
                                          <img
                                            src={Edit}
                                            alt=""
                                            onClick={() =>
                                              setEditSubChapter({
                                                flag: true,
                                                lesson_main_id: mainResponse.id,
                                                operation: Operation.UPDATE,
                                                sub_topic_id: subResponse.id,
                                              })
                                            }
                                          />
                                        </div>
                                        <div className="lesson-planner-list--subtitle--duration">
                                          <span>Total Duration -</span>
                                          <b>
                                            {convertMinsToHours(
                                              subResponse.duration_in_mins
                                            )}
                                          </b>
                                          <img src={Clock} alt="" />
                                        </div>
                                      </div>
                                      <div className="lesson-planner-list--subtitle--images">
                                        {subResponse.duration_in_mins ? null : (
                                          <button
                                            className="lesson-planner-list--subtitle--set"
                                            onClick={() => {
                                              setSubTopicId(subResponse.id);
                                              setLessonMainId(mainResponse.id);
                                              setDuration(!duration);
                                            }}
                                          >
                                            Set Duration
                                            <img src={Clock} alt="" />
                                          </button>
                                        )}
                                        <img
                                          src={Delete}
                                          alt=""
                                          onClick={() => {
                                            setDeleteId(subResponse.id);
                                            setLessonMainId(mainResponse.id);
                                            setDeleteModalForSubTopic(true);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Typography>
                                )
                              )
                            : null}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))
                : null}
            </div>
          ) : (
            <>
              <div className="create-lesson-planner__topics--first-row">
                <div className="create-lesson-planner__topics--selected-file">
                  {/* <span> {selectedFile.name}</span> */}
                  <img src={Close} alt="" onClick={clearSelectedFile} />
                </div>
                <Button mode="save" onClick={handleSave} />
                <Button mode="clear" onClick={clearSelectedFile} />
              </div>
              <Preview />
            </>
          )}
        </div>
      ) : (
        <div className="create-lesson-planner__attachments">
          <div className="create-lesson-planner__attachments--block ">
            <div
              className="create-lesson-planner__attachments--block--image"
              onClick={openFileInput}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleFileInputChange}
              />
              <img src={Attachment} alt="" />
              <span>Choose File To Upload</span>
            </div>
          </div>

          <div className="create-lesson-planner__attachments--footer">
            Please Upload Excel Template File else Create Chapter by using
            Button on right Corner
          </div>
        </div>
      )}
      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={editSubChapter.flag}
        onClose={() => setEditSubChapter(defaultEditSubChapter)}
      >
        <CreateSubChapter
          setDrawer={setEditSubChapter}
          details={editSubChapter}
          selectedSubject={selectedSubject}
        />
      </Drawer>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={duration}
        ariaHideApp={false}
        style={setDurationModalStyles}
      >
        <div className="set-duration">
          <div className="set-duration__title">
            <Title>Set Duration</Title>
            <img src={Close} alt="" onClick={() => setDuration(!duration)} />
          </div>
          <Time
            onChangeHrs={setHrs}
            onChangeMins={setMinutes}
            hrs={hrs}
            mins={mins}
          />
          <br />
          <Button mode="save" onClick={updateDuration} />
          <Button mode="cancel" onClick={() => setDuration(!duration)} />
        </div>
      </Modal>
      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={editMainChapter}
        onClose={() => setEditMainChapter(!editMainChapter)}
      >
        <New
          setDrawer={setEditMainChapter}
          operation={Operation.UPDATE}
          selectedSubject={selectedSubject}
          editDetails={editDetails}
        />
      </Drawer>

      <DeleteModal
        id={deleteId}
        modalFlag={deleteModalForSubTopic}
        setModalFlag={setDeleteModalForSubTopic}
        handleDelete={handleDeleteDetails}
      />
      <DeleteModal
        id={deleteId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDelete}
      />
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default List;
