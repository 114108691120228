import {
  Autocomplete,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import { EduateUserTitleProps } from "../../../Types/Titles";
import Home from "../Home/Index";
import DownArrow from "../../../images/DownArrow.svg";
import Close from "../../../images/Close.svg";
import { TableHeaderProps } from "../../../Types/Tables";
import { Label } from "../../../stories/Label/Label";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import {
  PAGINATION_ARRAY,
  ROWS_PER_PAGE,
  TABLE_DATA_PER_PAGE,
} from "../../../utils/constants";
import { TablePaginationActions } from "../../../pages/CustomTablePagination";
import { defaultLabelDisplayedRows } from "../../../utils/UtilFunctions";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import AssociatedList from "./AssociatedList";
import { useNavigate } from "react-router-dom";
import { LicenseTypes } from "../../../utils/Enum.types";
import useMasterTableJson from "../../../json/useMasterTableJson";

const { Eduate } = require("../../../json/title.json");
interface EnhancedTableRowProps {
  numParentRowSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  parentRowCount: number;
}

const tableData = {
  Customers: [
    {
      name: "Adichunchunagiri",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Adi",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "giri",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
    {
      name: "Adichunchunagiri Group",
      place: "Chikkaballapur",
      contactperson: "harin",
      Mobile: "Mobile",
      total: "10",
      InstitutionUnderCustomer: [
        {
          name: "Ad",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
        {
          name: "aa",
          place: "Chikkaballapur",
          contactperson: "harin",
          Mobile: "Mobile",
          total: "10",
        },
      ],
    },
  ],
};

const options = ["1", "2"];

const Association = () => {
  const listClasses = ListAutoCompleteStyles();
  const listTextClasses = ListAutoCompleteTextStyles();
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const navigate = useNavigate();
  const { Masters_Table } = useMasterTableJson();

  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [collapseFlag, setCollapseFlag] = useState<number[]>([]);
  const [enableI, setEnableI] = useState(false);
  const [associatedList, setAssociatedList] = useState(false);

  //Customers

  const handleSelectAllCustomers = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelecteds = tableData.Customers.map((data) => data.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const isCustomerSelected = (name: string) => selected.indexOf(name) !== -1;
  const handleCustomers = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // eslint-disable-next-line
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const EnhancedTableHead = (props: EnhancedTableRowProps) => {
    const { onSelectAllClick, numParentRowSelected, parentRowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={
                numParentRowSelected > 0 &&
                numParentRowSelected < parentRowCount
              }
              checked={
                parentRowCount > 0 && numParentRowSelected === parentRowCount
              }
              onChange={onSelectAllClick}
            />
          </TableCell>
          {Masters_Table.Eduate.Association.Table_Headers.map(
            (th: TableHeaderProps, index: React.Key) => {
              return (
                <TableCell key={index} className={th.className}>
                  {th.labelName}
                </TableCell>
              );
            }
          )}
        </TableRow>
      </TableHead>
    );
  };
  return (
    <>
      <Home
        DashBoardRequired={false}
        NavType={LicenseTypes.EDUATE_CONFIGURATION}
      />
      <Title>
        {Eduate.User.Titles.map((title: EduateUserTitleProps) => {
          return (
            <React.Fragment key={title.Association}>
              {title.Association}
            </React.Fragment>
          );
        })}
      </Title>
      <div className="eduate-user-association">
        <div className="row g-0 eduate-user-association__search-options">
          <div className="col-2">
            <Autocomplete
              classes={listClasses}
              options={options}
              openOnFocus
              onChange={(e) => {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search By Customer"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: listTextClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col-2">
            <Autocomplete
              classes={listClasses}
              options={options}
              openOnFocus
              onChange={(e) => {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search By Institution"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: listTextClasses.formControlRoot }}
                />
              )}
            />
          </div>
        </div>
        <div className="eduate-user-association__data">
          <div className="eduate-user-association__data--tableblock">
            <TableContainer className="eduate-user-association__data--tableblock--table">
              <Table stickyHeader>
                <EnhancedTableHead
                  onSelectAllClick={handleSelectAllCustomers}
                  numParentRowSelected={selected.length}
                  parentRowCount={tableData.Customers?.length}
                />
                <TableBody>
                  {tableData.Customers &&
                    tableData.Customers.map((parentdata, index) => {
                      const isItemSelected = isCustomerSelected(
                        parentdata.name
                      );
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <>
                          <TableRow
                            key={index}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            selected={isItemSelected}
                          >
                            <TableCell className="eduate-user-association__data--tableblock--table--icons">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                onClick={(event) =>
                                  handleCustomers(event, parentdata.name)
                                }
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={parentdata.name}
                              />

                              <img
                                src={DownArrow}
                                alt="/"
                                onClick={() => {
                                  setEnableI(!enableI);

                                  if (!collapseFlag.includes(index)) {
                                    setCollapseFlag((p) => [...p, index]);
                                  } else {
                                    collapseFlag.splice(
                                      collapseFlag.indexOf(index),
                                      1
                                    );
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{parentdata.name}</TableCell>
                            <TableCell>{parentdata.place}</TableCell>
                            <TableCell>{parentdata.contactperson}</TableCell>
                            <TableCell>{parentdata.Mobile}</TableCell>
                            <TableCell>{parentdata.total}</TableCell>
                          </TableRow>
                          {collapseFlag.includes(index) && (
                            <>
                              {parentdata.InstitutionUnderCustomer.map(
                                (instdata, index) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      role="checkbox"
                                      aria-checked={isItemSelected}
                                      selected={isItemSelected}
                                    >
                                      <TableCell className="eduate-user-association__data--tableblock--table--subdata-icons">
                                        <Checkbox
                                          checked={isItemSelected}
                                          inputProps={{
                                            "aria-labelledby": labelId,
                                          }}
                                          role="checkbox"
                                          aria-checked={isItemSelected}
                                          tabIndex={-1}
                                          key={instdata.name}
                                        />
                                      </TableCell>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>{instdata.name}</TableCell>
                                      <TableCell>{instdata.place}</TableCell>
                                      <TableCell>
                                        {instdata.contactperson}
                                      </TableCell>
                                      <TableCell>{instdata.Mobile}</TableCell>
                                      <TableCell>{instdata.total}</TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                            </>
                          )}
                        </>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={PAGINATION_ARRAY}
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelDisplayedRows={defaultLabelDisplayedRows}
                      labelRowsPerPage={TABLE_DATA_PER_PAGE}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <div className="row g-0">
              <div className="col-6 label-grid">
                <Label variant="LabelPrimary">Select User to Associate</Label>

                <Autocomplete
                  classes={classes}
                  options={options}
                  openOnFocus
                  popupIcon={<img src={DownArrow} alt="/" />}
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      name="type"
                      required
                      classes={{
                        root: textClasses.formControlRoot,
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="eduate-user-association__data--userblock">
            <div className="details">
              <h4>
                {Eduate.User.Titles.map(
                  (title: EduateUserTitleProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        {title.AssociatedUSer}
                      </React.Fragment>
                    );
                  }
                )}
              </h4>
            </div>
            <ul className="eduate-user-association__data--userblock--users">
              <li onClick={() => setAssociatedList(!associatedList)}>
                <span>Sunil</span>
                <div className="eduate-user-association__data--userblock--users--totalcount">
                  10
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="button-left">
        <Button mode="save" />
        <Button mode="edit" />
        <Button mode="clear" />
        <Button mode="delete" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      {/* associated-user-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={associatedList}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <AssociatedList />
            <Button
              mode="cancel"
              onClick={() => setAssociatedList(!associatedList)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setAssociatedList(!associatedList)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Association;
