import React, { useState } from "react";
import { Title } from "../../../stories/Title/Title";
import Close from "../../../images/Close.svg";
import Input from "../../../stories/Input/Input";
import {
  Autocomplete,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import { TableHeaderProps } from "../../../Types/Tables";
import { Button } from "../../../stories/Button/Button";
import { useMutation } from "@apollo/client";
import { AddOrDeleteAcdTeacherClassAssoci } from "../queries/teacher_association/mutations";
import { Operation } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import {
  GetAcdTeacherClassAssociations,
  GetAcdTeacherSubjectClassAssociations,
} from "../queries/subjects/List.tsx/Index";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../utils/constants";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useEmployee, { empQueryTypes } from "../../HR/hooks/useEmployee";
import { AccountentType } from "../../Accounts/common/Enum.types";
import { AcdTeacherClassQueryType } from "../types/subject/Subject";
import useCheckAllocationType from "../hooks/useCheckAllocationType";

const { Academics_Table } = require("../json/table.json");
export const enum listFor {
  COMPLETE_DETAILS = "COMPLETE_DETAILS",
  PER_SUBJECT = "PER_SUBJECT",
}
export interface teacherDetails {
  is_class_teacher: boolean;
  pr_emp_id: number;
  subj_master_id: number;
}
interface StaffListProps {
  subjectId: number;
  updateOperation: Operation;
  setPerSubjectTeacherList: React.Dispatch<React.SetStateAction<boolean>>;
}
const TeachersList = ({
  subjectId,
  updateOperation,
  setPerSubjectTeacherList,
}: StaffListProps) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { token } = useToken();
  const { InstId, entryId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { entry_level } = useInstitutionConfiguration();
  const [items, setItems] = useState<teacherDetails[]>([]);
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { flag } = useCheckAllocationType();
  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;

  const {
    empDetails: { data: EmpData },
  } = useEmployee(
    ROWS_PER_PAGE,
    updateOperation === Operation.UPDATE
      ? empQueryTypes.TEACHERS_UNASSIGNED_FOR_CLASS_SUBJECT
      : empQueryTypes.TEACHERS_ASSIGNED_FOR_SUBJECT,
    EMPTY_STRING,
    0,
    0,
    0,
    0,
    0,
    0,
    [AccountentType.TEACHER],
    updateOperation === Operation.UPDATE ? entry_level : EMPTY_STRING,
    Number(entryId)
  );
  const [AssociateTeachersWithSubject] = useMutation(
    AddOrDeleteAcdTeacherClassAssoci,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleSubjectAssoci = () => {
    if (flag === undefined) {
      alert("Allocation type not found");
      return;
    }
    AssociateTeachersWithSubject({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcdId,
        entry_level: entry_level,
        entry_id: entryId,
        per_std_subj_allocation: flag,
        input: {
          add_class_teacher: items?.map((teacher) => ({
            is_class_teacher: teacher.is_class_teacher,
            pr_emp_id: teacher.pr_emp_id,
            subj_master_id: teacher.subj_master_id,
          })),
          delete_class_teacher: [],
        },
      },
      refetchQueries: [
        {
          query: GetAcdTeacherSubjectClassAssociations,
          variables: {
            acd_yr_id: activeAcdId,
            entry_id: Number(entryId)!,
            entry_level: entry_level,
            inst_id: InstId!,
            token,
            per_std_subj_allocation: flag,
          },
        },
        {
          query: GetAcdTeacherClassAssociations,
          variables: {
            acd_yr_id: activeAcdId,
            inst_id: InstId!,
            token,
            per_std_subj_allocation: flag,
            input: {
              query_type: AcdTeacherClassQueryType.ALL_TEACHERS_BY_SUBJECT,
              entry_id: Number(entryId)!,
              entry_level: entry_level,
              pr_emp_id: 0,
              subj_master_id: subjectId,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.AddOrDeleteAcdTeacherClassAssoci) {
        setMessage({
          flag: true,
          message: "Successfully Allocated Teacher with Subject",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setPerSubjectTeacherList?.(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <div className="assign-class-teacher__list">
        <div className="assign-class-teacher__list--title">
          <Title>Select Subject Teachers</Title>
          <img
            src={Close}
            alt=""
            onClick={() => setPerSubjectTeacherList?.(false)}
          />
        </div>
        <div className="row g-0 assign-class-teacher__list--filter">
          <div className="col-4">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col"></div>
          <div className="col-4">
            <Autocomplete
              classes={classes}
              options={["A", "B"]}
              openOnFocus
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  classes={{ root: textClasses.formControlRoot }}
                  label="Employee Grade"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
        </div>
        <div className="assign-class-teacher__list--tableblock">
          <TableContainer className="assign-class-teacher__list--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Academics_Table.StaffList.filter(
                    (data: TableHeaderProps) => data.labelName !== "Actions"
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {EmpData?.GetPayRollEmp.edges
                  ? EmpData.GetPayRollEmp.edges.map((teacher, index) => {
                      return (
                        <TableRow>
                          <TableCell
                            className="assign-class-teacher__list--table--slno"
                            id="td-center"
                          >
                            <Checkbox
                              checked={items.some(
                                (item) => item.pr_emp_id === teacher.node.id
                              )}
                              onChange={(e) => {
                                const { checked } = e.target;
                                if (checked) {
                                  setItems((prevItems) => [
                                    ...prevItems,
                                    {
                                      is_class_teacher: false,
                                      pr_emp_id: teacher.node.id,
                                      subj_master_id: subjectId,
                                    },
                                  ]);
                                } else {
                                  setItems((prevItems) =>
                                    prevItems.filter(
                                      (item) =>
                                        item.pr_emp_id !== teacher.node.id
                                    )
                                  );
                                }
                              }}
                            />

                            {index + 1}
                            <img src="" alt="" />
                          </TableCell>
                          <TableCell className="assign-class-teacher__list--table--id">
                            {teacher?.node.emp_id}
                          </TableCell>
                          <TableCell className="assign-class-teacher__list--table--name">
                            {teacher?.node.emp_first_name +
                              " " +
                              teacher?.node.emp_middle_name +
                              " " +
                              teacher?.node.emp_last_name}
                          </TableCell>
                          <TableCell className="assign-class-teacher__list--table--grade">
                            {
                              teacher?.node.pr_designation_details
                                .designation_desc
                            }
                          </TableCell>
                          <TableCell
                            className="assign-class-teacher__list--table--exp"
                            id="td-center"
                          >
                            {teacher?.node.emp_experience}
                          </TableCell>
                          <TableCell
                            className="assign-class-teacher__list--table--grade"
                            id="td-center"
                          >
                            {teacher?.node.pr_grade_details.grade_desc}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button
          mode="save"
          disabled={items.length === 0}
          onClick={handleSubjectAssoci}
        />

        <Button
          mode="cancel"
          onClick={() => setPerSubjectTeacherList?.(false)}
        />
        <MessageModal
          handleClose={handleClose}
          modalFlag={message.flag}
          operation={message.operation}
          value={message.message}
        />
      </div>
    </>
  );
};

export default TeachersList;
