import React from "react";
import { Title } from "../../../../stories/Title/Title";
import { Autocomplete, TextField } from "@mui/material";
import useAcctLedgerData, {
  FeeLedgerEdge,
} from "../../hooks/useAcctLedgerData";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import { AcctLedgerQueryType } from "../../common/QueryTypes";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { Keys } from "../../../../utils/Enum.keys";
import { Button } from "../../../../stories/Button/Button";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDepositLedger: React.Dispatch<
    React.SetStateAction<FeeLedgerEdge | null>
  >;
  selectedDepositLedger: FeeLedgerEdge | null;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
}
const AddDepositLedgersModal = ({
  setModalFlag,
  setSelectedDepositLedger,
  selectedDepositLedger,
  setAmount,
}: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();

  const {
    acctLedgers: { scrolledResponseType },
  } = useAcctLedgerData(
    EMPTY_STRING,
    AcctLedgerQueryType.DEPOSIT_FEE_LDGRS,
    ROWS_PER_PAGE
  );
  return (
    <>
      <Title>Add Deposit</Title>
      <div className="add-deposit-ledgers">
        <Autocomplete
          classes={classes}
          options={scrolledResponseType}
          isOptionEqualToValue={(option) =>
            selectedDepositLedger != null &&
            option.node.id === selectedDepositLedger.node.id
          }
          getOptionLabel={(option) => option.node.ldgr_desc}
          onKeyDown={(e) => {
            if (e.key === Keys.ENTER) {
              e.preventDefault();
              if (selectedDepositLedger) {
                setModalFlag(false);
              }
            }
            if (e.key === Keys.BACKSPACE) {
              setSelectedDepositLedger(null);
            }
          }}
          openOnFocus
          value={selectedDepositLedger}
          onChange={(e, newValue) => {
            if (newValue) {
              setSelectedDepositLedger(newValue);
            } else {
              setSelectedDepositLedger(null);
              setAmount(0);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Deposit Ledger"
              autoFocus
              InputLabelProps={{ shrink: true }}
              classes={{ root: textClasses.formControlRoot }}
            />
          )}
        />

        <div className="add-deposit-ledgers--btns">
          <Button onClick={() => setModalFlag(false)}>Okay</Button>
        </div>
      </div>
    </>
  );
};

export default AddDepositLedgersModal;
