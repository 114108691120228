import React from "react";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { TextField } from "@mui/material";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useInstConfigByEntryId from "../hooks/useInstConfigByEntryId";
import { useParams } from "react-router-dom";
import useAcdLevel from "../../Accounts/hooks/useAcdLevel";
import { EMPTY_STRING } from "../../../utils/constants";

const InstDetails = () => {
  const { entryId } = useParams();

  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  const {
    InstConfigDetails: { data: configdata },
  } = useInstConfigByEntryId(entryId!);

  return (
    <div className="row g-0 daily-activities__select">
      {USE_DEPARTMENT_KEY && (
        <div className="col-2">
          <TextField
            label={departmentLabel}
            value={
              configdata
                ? configdata.GetAcdInstConfigNames.acd_dept_desc
                : EMPTY_STRING
            }
            InputLabelProps={{ shrink: true }}
            disabled
            className="daily-activities__textfield"
          />
        </div>
      )}
      {USE_BRANCH_KEY && (
        <div className="col-2">
          <TextField
            label={branchLabel}
            value={
              configdata
                ? configdata.GetAcdInstConfigNames.acd_branch_desc
                : EMPTY_STRING
            }
            InputLabelProps={{ shrink: true }}
            disabled
            className="daily-activities__textfield"
          />
        </div>
      )}
      {USE_CLASS_KEY &&
      configdata &&
      configdata.GetAcdInstConfigNames.acd_class_desc ? (
        <div className="col-2">
          <TextField
            label={classLabel}
            value={
              configdata
                ? configdata.GetAcdInstConfigNames.acd_class_desc
                : EMPTY_STRING
            }
            InputLabelProps={{ shrink: true }}
            disabled
            className="daily-activities__textfield"
          />
        </div>
      ) : null}
      {USE_SEMESTER_KEY &&
      configdata &&
      configdata.GetAcdInstConfigNames.acd_semester_desc ? (
        <div className="col-2">
          <TextField
            label={semesterLabel}
            value={
              configdata
                ? configdata.GetAcdInstConfigNames.acd_semester_desc
                : EMPTY_STRING
            }
            InputLabelProps={{ shrink: true }}
            disabled
            className="daily-activities__textfield"
          />
        </div>
      ) : null}
      {USE_SECTION_KEY &&
      configdata &&
      configdata.GetAcdInstConfigNames.acd_section_desc ? (
        <div className="col-2">
          <TextField
            label={sectionLabel}
            value={
              configdata
                ? configdata.GetAcdInstConfigNames.acd_section_desc
                : EMPTY_STRING
            }
            InputLabelProps={{ shrink: true }}
            disabled
            className="daily-activities__textfield"
          />
        </div>
      ) : null}
    </div>
  );
};

export default InstDetails;
