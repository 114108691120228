import { gql } from "@apollo/client";

export const GetAcctStdFeeLdgrsByInstIdByType = gql`
  query GetAcctStdFeeLdgrsByInstIdByType($token: String!, $inst_id: ID!) {
    GetAcctStdFeeLdgrsByInstIdByType(token: $token, inst_id: $inst_id) {
      general {
        id
        ldgr_desc
      }

      transport {
        id
        ldgr_desc
      }

      hostel {
        id
        ldgr_desc
      }
    }
  }
`;

export const GetAcctDemandDetailsByDemandMainId = gql`
  query GetAcctDemandDetailsByDemandMainId(
    $token: String!
    $acct_demand_main_id: ID!
  ) {
    GetAcctDemandDetailsByDemandMainId(
      token: $token
      acct_demand_main_id: $acct_demand_main_id
    ) {
      id
      sl_no
      fee_due_date
      fee_for_new_std
      fee_for_exs_std
      fee_for_all
      acct_ldgr_id
      acct_demand_main_id
      acct_ldgr_details {
        id
        ldgr_desc
      }
    }
  }
`;
export const GetAcctDemandDetails = gql`
  query GetAcctDemandDetails(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $branch_id: ID!
    $class_id: ID!
    $category_id: ID!
  ) {
    GetAcctDemandDetails(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      branch_id: $branch_id
      class_id: $class_id
      category_id: $category_id
    ) {
      id
      sl_no
      fee_due_date
      fee_for_new_std
      fee_for_exs_std
      fee_for_all
      acct_ldgr_id
      acct_demand_main_id
      acct_ldgr_details {
        id
        ldgr_desc
      }
    }
  }
`;

export const GetAccountLedgerNameById = gql`
  query GetAcctLdgrNamesByIds($token: String!, $acct_ldgr_ids: [ID!]!) {
    GetAcctLdgrNamesByIds(token: $token, acct_ldgr_ids: $acct_ldgr_ids) {
      id
      ldgr_desc
    }
  }
`;

export const GetAcctLdgrs = gql`
  query GetAcctLdgrs(
    $token: String!
    $input: AcctLdgrQueryData!
    $name: String
    $first: Int
    $after: Cursor
    $id: ID
    $orderBy: [AcctLdgrOrder!]
  ) {
    GetAcctLdgrs(
      token: $token
      first: $first
      after: $after
      input: $input
      orderBy: $orderBy
      where: { or: [{ ldgrDescContainsFold: $name, id: $id }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          ldgr_desc
          ldgr_cb
          ldgr_cash_bank_other
          ldgr_sundry_address
          ldgr_sundry_gst_no
          ldgr_sundry_contact_person_mobile
          voucher_book_details {
            id
            vo_book_desc
            vo_book_key
            voucher_sub_type
          }
          acct_group_ldgr_details {
            gr_ldgr_desc
            id
            gr_ldgr_rp
            gr_ldgr_db_cr
          }
          ldgr_summary {
            id
            ldgr_rp_ob
            ldgr_rp_cr
            ldgr_rp_db
            ldgr_rp_cb
          }
          ldgr_yearly_data {
            fee_ldgr_ob
            fee_ldgr_demand
            fee_ldgr_concession
            fee_ldgr_receivable
            fee_ldgr_received
            fee_ldgr_refunds
            fee_ldgr_bal
            fee_ldgr_dropout_demand
          }
        }
      }
    }
  }
`;

export const GetAcctLdgrsForNonDemand = gql`
  query GetAcctLdgrsForNonDemand($token: String!, $input: AcctLdgrQueryData!) {
    GetAcctLdgrsForNonDemand(token: $token, input: $input) {
      id
      ldgr_desc
      ldgr_cb
      ldgr_std_fee
      ldgr_cash_bank_other
      acct_group_ldgr_details {
        gr_ldgr_desc
        id
        gr_ldgr_rp
      }
      ldgr_summary {
        id
        ldgr_rp_ob
        ldgr_rp_cr
        ldgr_rp_db
        ldgr_rp_cb
      }
      ldgr_yearly_data {
        fee_ldgr_ob
        fee_ldgr_demand
        fee_ldgr_concession
        fee_ldgr_receivable
        fee_ldgr_received
        fee_ldgr_refunds
        fee_ldgr_bal
        fee_ldgr_dropout_demand
      }
      voucher_book_details {
        vo_book_key
        voucher_type
        vo_book_desc
      }
    }
  }
`;
export const GetAcctGroupLdgrs = gql`
  query GetAcctGroupLdgrs(
    $token: String!
    $input: AcctGroupLdgrQueryData!
    $after: Cursor
    $first: Int
    $fin_yr_id: ID!
    $name: String
    $id: ID
    $direction: OrderDirection!
    $sortBy: AcctGroupLdgrOrderField
  ) {
    GetAcctGroupLdgrs(
      token: $token
      input: $input
      after: $after
      fin_yr_id: $fin_yr_id
      first: $first
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ grLdgrDescContainsFold: $name, id: $id }] }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          gr_ldgr_desc
          id
          gr_ldgr_ob
          gr_ldgr_total_cr
          gr_ldgr_total_db
          gr_ldgr_cb
          gr_ldgr_rp
          gr_ldgr_aie
        }
      }
    }
  }
`;
export const GetAcctStdDeposits = gql`
  query GetAcctStdDeposits(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $input: AcctDepositLdgrQuery!
    $after: Cursor
    $first: Int
    $orderBy: AcctStdDepositsOrder
    $deptID: ID
    $branchID: ID
    $classID: ID
    $semesterID: ID
    $sectionID: ID
    $name: String
    $categoryID: ID
    $ldgrDesc: String
  ) {
    GetAcctStdDeposits(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      input: $input
      after: $after
      first: $first
      orderBy: $orderBy

      where: {
        hasAcctLdgrWith: [{ or: [{ ldgrDescContainsFold: $ldgrDesc }] }]
        hasMstStudentWith: [
          {
            firstNameContainsFold: $name
            deptID: $deptID
            branchID: $branchID
            classID: $classID
            semesterID: $semesterID
            sectionID: $sectionID
            categoryID: $categoryID
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          deposit_ob
          deposit_amt
          deposit_total
          deposit_adjusted
          deposit_refunded
          deposit_bal
          is_scholarship
          acct_ldgr_id
          acct_ldgr_details {
            ldgr_desc
            id
          }
          branch {
            branch_desc
          }
          class {
            class_desc
          }
          student_details {
            id
            std_adm_no
            first_name
            middle_name
            last_name
            std_father_name
            std_mother_name
            std_reg_no
            std_mobile
            std_status
            std_fresher
            std_email
            std_mobile
            class {
              class_desc
              id
            }
            branch {
              id
              branch_desc
            }
            dept {
              id
              dept_desc
            }

            semester {
              sem_desc
              id
            }
            section {
              id
              section_desc
            }
            category {
              cat_desc
              id
            }
          }
        }
      }
    }
  }
`;

export const GetAcctLdgrYearlyDataSummation = gql`
  query GetAcctLdgrYearlyDataSummation(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
  ) {
    GetAcctLdgrYearlyDataSummation(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      fee_ldgr_ob
      fee_ldgr_demand
      fee_ldgr_concession
      fee_ldgr_receivable
      fee_ldgr_received
      fee_ldgr_refunds
      fee_ldgr_bal
      fee_ldgr_dropout_demand
    }
  }
`;

export const GetAcctStudentDemandFeeSummary = gql`
  query GetAcctStudentDemandFeeSummary(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
  ) {
    GetAcctStudentDemandFeeSummary(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      id
      fee_ldgr_ob
      fee_ldgr_demand
      fee_ldgr_concession
      fee_ldgr_receivable
      fee_ldgr_received
      fee_ldgr_refunds
      fee_ldgr_bal
      fee_ldgr_dropout_demand
      acct_ldgr_details {
        ldgr_desc
        id
      }
    }
  }
`;
export const GetAcctReceiptsReport = gql`
  query GetAcctReceiptsReport($token: String!, $inst_id: ID!, $fin_yr_id: ID!) {
    GetAcctReceiptsReport(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      group_ldgr_rp_summary {
        gr_rp_ob
        gr_rp_cr
        gr_rp_db
        gr_rp_cb
        gr_rp_rcpt_show
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
      }
      acct_ldgr_rp_summary {
        ldgr_rp_ob
        ldgr_rp_cr
        ldgr_rp_db
        ldgr_rp_cb
        ldgr_rp_rcpt_show
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
        acct_ldgr_details {
          id
          ldgr_desc
        }
      }
    }
  }
`;

export const GetAcctPaymentsReport = gql`
  query GetAcctPaymentsReport($token: String!, $inst_id: ID!, $fin_yr_id: ID!) {
    GetAcctPaymentsReport(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      group_ldgr_rp_summary {
        gr_rp_ob
        gr_rp_cr
        gr_rp_db
        gr_rp_cb
        gr_rp_pymt_show
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
      }
      acct_ldgr_rp_summary {
        ldgr_rp_ob
        ldgr_rp_cr
        ldgr_rp_db
        ldgr_rp_cb
        ldgr_rp_pymt_show
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
        acct_ldgr_details {
          id
          ldgr_desc
        }
      }
    }
  }
`;
export const GetAcctIncomeReport = gql`
  query GetAcctIncomeReport($token: String!, $inst_id: ID!, $fin_yr_id: ID!) {
    GetAcctIncomeReport(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      group_ldgr_ie_summary {
        gr_ie_ob
        gr_ie_cr
        gr_ie_db
        gr_ie_cb
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
      }
      acct_ldgr_ie_summary {
        ldgr_ie_ob
        ldgr_ie_cr
        ldgr_ie_db
        ldgr_ie_cb
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
        acct_ldgr_details {
          ldgr_desc
          id
        }
      }
    }
  }
`;
export const GetAcctExpencesReport = gql`
  query GetAcctExpencesReport($token: String!, $inst_id: ID!, $fin_yr_id: ID!) {
    GetAcctExpencesReport(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      group_ldgr_ie_summary {
        gr_ie_ob
        gr_ie_cr
        gr_ie_db
        gr_ie_cb
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
      }
      acct_ldgr_ie_summary {
        ldgr_ie_ob
        ldgr_ie_cr
        ldgr_ie_db
        ldgr_ie_cb
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
        acct_ldgr_details {
          ldgr_desc
          id
        }
      }
    }
  }
`;
export const GetAcctAssetsReport = gql`
  query GetAcctAssetsReport($token: String!, $inst_id: ID!, $fin_yr_id: ID!) {
    GetAcctAssetsReport(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      group_ldgr_bs_summary {
        gr_bs_ob
        gr_bs_cr
        gr_bs_db
        gr_bs_cb
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
      }
      acct_ldgr_bs_summary {
        ldgr_bs_ob
        ldgr_bs_cr
        ldgr_bs_db
        ldgr_bs_cb
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
        acct_ldgr_details {
          ldgr_desc
          id
        }
      }
    }
  }
`;

export const GetAcctAssetsObReport = gql`
  query GetAcctAssetsObReport($token: String!, $inst_id: ID!, $fin_yr_id: ID!) {
    GetAcctAssetsObReport(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      group_ldgr_bs_summary {
        gr_bs_ob
        gr_bs_cr
        gr_bs_db
        gr_bs_cb
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
      }
      acct_ldgr_bs_summary {
        ldgr_bs_ob
        ldgr_bs_cr
        ldgr_bs_db
        ldgr_bs_cb
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
        acct_ldgr_details {
          ldgr_desc
          id
        }
      }
    }
  }
`;

export const GetAcctProfitAndLossReport = gql`
  query GetAcctProfitAndLossReport(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
  ) {
    GetAcctProfitAndLossReport(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      gr_ie_cb
      acct_group_ldgr_details {
        id
        gr_ldgr_desc
        gr_ldgr_rp
      }
    }
  }
`;
export const GetAcctCorpusFundReport = gql`
  query GetAcctCorpusFundReport(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
  ) {
    GetAcctCorpusFundReport(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      gr_bs_ob
      acct_group_ldgr_details {
        id
        gr_ldgr_desc
        gr_ldgr_rp
      }
    }
  }
`;

export const GetAcctLiabilitiesReport = gql`
  query GetAcctLiabilitiesReport(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
  ) {
    GetAcctLiabilitiesReport(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      group_ldgr_bs_summary {
        gr_bs_ob
        gr_bs_cr
        gr_bs_db
        gr_bs_cb
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
      }
      acct_ldgr_bs_summary {
        ldgr_bs_ob
        ldgr_bs_cr
        ldgr_bs_db
        ldgr_bs_cb
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
        acct_ldgr_details {
          ldgr_desc
          id
        }
      }
    }
  }
`;

export const GetAcctLiabilitiesObReport = gql`
  query GetAcctLiabilitiesObReport(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
  ) {
    GetAcctLiabilitiesObReport(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
    ) {
      group_ldgr_bs_summary {
        gr_bs_ob
        gr_bs_cr
        gr_bs_db
        gr_bs_cb
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
      }
      acct_ldgr_bs_summary {
        ldgr_bs_ob
        ldgr_bs_cr
        ldgr_bs_db
        ldgr_bs_cb
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
        acct_ldgr_details {
          ldgr_desc
          id
        }
      }
    }
  }
`;
export const GetAcctBankReport = gql`
  query GetAcctBankReport($token: String!, $inst_id: ID!, $fin_yr_id: ID!) {
    GetAcctBankReport(token: $token, inst_id: $inst_id, fin_yr_id: $fin_yr_id) {
      group_ldgr_bank_summary {
        gr_bs_cb
        gr_bs_ob
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
      }
      acct_ldgr_bank_summary {
        ldgr_bs_cb
        ldgr_bs_ob

        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
        acct_ldgr_details {
          ldgr_desc
          id
        }
      }
    }
  }
`;

export const GetAcctCashReport = gql`
  query GetAcctCashReport($token: String!, $inst_id: ID!, $fin_yr_id: ID!) {
    GetAcctCashReport(token: $token, inst_id: $inst_id, fin_yr_id: $fin_yr_id) {
      group_ldgr_cash_summary {
        gr_bs_cb
        gr_bs_ob
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
      }
      acct_ldgr_cash_summary {
        ldgr_bs_cb
        ldgr_bs_ob
        acct_group_ldgr_details {
          id
          gr_ldgr_desc
          gr_ldgr_rp
        }
        acct_ldgr_details {
          ldgr_desc
          id
        }
      }
    }
  }
`;
export const GetAcctGroupLdgrType = gql`
  query GetAcctGroupLdgrType($token: String!) {
    GetAcctGroupLdgrType(token: $token) {
      id
      type_desc
    }
  }
`;
export const GetAcctLdgrForDailyFeeCollection = gql`
  query GetAcctLdgrForDailyFeeCollection(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $vo_start_date: Time!
    $vo_end_date: Time!
  ) {
    GetAcctLdgrForDailyFeeCollection(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      vo_start_date: $vo_start_date
      vo_end_date: $vo_end_date
    ) {
      id
      ldgr_desc
    }
  }
`;
export const ValidateAcctLdgrName = gql`
  query ValidateAcctLdgrName(
    $token: String!
    $inst_id: ID!
    $acct_ldgr_name: String!
  ) {
    ValidateAcctLdgrName(
      token: $token
      inst_id: $inst_id
      acct_ldgr_name: $acct_ldgr_name
    )
  }
`;

export const CheckAnyBankLdgrAdded = gql`
  query CheckAnyBankLdgrAdded($token: String!, $inst_id: ID!) {
    CheckAnyBankLdgrAdded(token: $token, inst_id: $inst_id)
  }
`;

export const GetDepositDetailsByNodeId = gql`
  query GetDepositDetailsByNodeId($token: String!, $id: ID!) {
    node(token: $token, id: $id) {
      ... on AcctStdDeposits {
        id
        is_scholarship
        deposit_ob
        deposit_amt
        deposit_total
        deposit_adjusted
        deposit_refunded
        deposit_bal
        acct_ldgr_details {
          id
          ldgr_desc
        }
      }
    }
  }
`;
