import { optionsType } from "../../../utils/Form.types";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import {
  SortByFilterType,
  StudentOrderFields,
} from "../../../utils/Enum.types";
import { Autocomplete, TextField } from "@mui/material";
import { isOptionEqualToValue } from "../../../utils/UtilFunctions";
interface Props {
  sortByType: SortByFilterType;
  sortByValue: string;
  onChange: (newValue: optionsType | null) => void;
  label: string;
}

export const SortByOptions = [
  {
    type: SortByFilterType.STUDENT,
    options: [
      {
        label: "Name",
        value: StudentOrderFields.FIRST_NAME,
      },
      {
        label: "Admission No",
        value: StudentOrderFields.STD_ADM_NO,
      },
      {
        label: "Register No",
        value: StudentOrderFields.STD_REG_NO,
      },
      {
        label: "Roll No",
        value: StudentOrderFields.STD_ROLL_NO,
      },
    ],
  },
];
const SortByAutocomplete = ({
  sortByType,
  sortByValue,
  onChange,
  label,
}: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();

  const withoutLabelClass = formAutoCompleteStyles();
  const withoutLabelTextClass = formAutoCompleteTextStyles();

  const foundOptions = SortByOptions.find(({ type }) => type === sortByType);
  const options = foundOptions ? foundOptions.options : [];

  const foundValue = options.find(({ value }) => value === sortByValue);
  const autoValue = foundValue ? foundValue : null;
  return (
    <Autocomplete
      classes={label ? classes : withoutLabelClass}
      options={options}
      openOnFocus={true}
      {...(sortByValue ? { value: autoValue } : {})}
      onChange={(
        e: React.SyntheticEvent<Element, Event>,
        newValue: optionsType | null
      ) => onChange(newValue)}
      isOptionEqualToValue={(option) => isOptionEqualToValue(option, autoValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || ""}
          fullWidth={true}
          InputLabelProps={{ shrink: label.length > 0 }}
          classes={{
            root: label
              ? textClasses.formControlRoot
              : withoutLabelTextClass.formControlRoot,
          }}
        />
      )}
    />
  );
};

export default SortByAutocomplete;
