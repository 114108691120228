import React, { useContext, useEffect, useState } from "react";
import Home from "../../../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import { Drawer, TextField } from "@mui/material";
import Compare from "../../../../../images/Compare.svg";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "../../../../../images/Avatar.svg";

import { attendanceOverViewStyles } from "../../../../../styles/DrawerStyles";
import FormNotFilled from "./View";
import List from "../List";
import { useLazyQuery } from "@apollo/client";
import { GetAcdFeedbackFormEmpClassWiseRating } from "../../../queries/test/query";
import {
  GetAcdFeedbackFormEmpClassWiseRatingData,
  GetAcdFeedbackFormEmpClassWiseRatingVars,
} from "../../../types/subject/Subject";
import useToken from "../../../../../customhooks/useToken";
import { AppContext } from "../../../../../context/context";
import QuestionWiseGraphClassWise from "./QuestionWiseGraphClassWise";
import useInstConfigByEntryId from "../../../hooks/useInstConfigByEntryId";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { EmpData } from "../../../../HR/hooks/useEmployee";
import { CommonNodeVars } from "../../../../HR/Types/masterDataTypes";
import { PayRollEmpMasterNodeForLogin } from "../../../../HR/queries/employee/query";
import { GetFormDataByFormId } from "../../../queries/general";
import { toInputStandardDate } from "../../../../../utils/UtilFunctions";
import { Button } from "../../../../../stories/Button/Button";

const QuestionWise = () => {
  const { token } = useToken();
const navigate=useNavigate();
  const { entryId, InstId, formId, empId } = useParams();
  const { state } = useContext(AppContext);
  const drawerClasses = attendanceOverViewStyles();
  const [view, setView] = useState(false);

  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
    entry_level,
  } = useInstitutionConfiguration();
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  const [GetEmpDetailsForLogin, { data: teacherData }] = useLazyQuery<
    EmpData,
    CommonNodeVars
  >(PayRollEmpMasterNodeForLogin, {
    variables: {
      id: Number(empId),
      token,
    },
    fetchPolicy: "network-only",
  });

  const [GetFormDataById, { data: formData }] = useLazyQuery(
    GetFormDataByFormId,
    {
      variables: {
        id: Number(formId),
        token,
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (formId && token) {
      GetFormDataById();
    }
  }, [formId, GetFormDataById, token]);
  useEffect(() => {
    if (empId && token) {
      GetEmpDetailsForLogin();
    }
  }, [empId, GetEmpDetailsForLogin, token]);
  const [GetOverAllGraph, { data: OverAllData }] = useLazyQuery<
    GetAcdFeedbackFormEmpClassWiseRatingData,
    GetAcdFeedbackFormEmpClassWiseRatingVars
  >(GetAcdFeedbackFormEmpClassWiseRating);
  useEffect(() => {
    if (token && empId && formId && entryId && entry_level) {
      GetOverAllGraph({
        variables: {
          token,
          inst_id: Number(InstId)!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          pr_emp_id: Number(empId),
          form_name_id: Number(formId),
          entry_level,
          entry_id: Number(entryId),
        },
      });
    }
  }, [token, GetOverAllGraph, empId, formId, entryId, entry_level]);

  const {
    InstConfigDetails: { data: configdata },
  } = useInstConfigByEntryId(entryId!);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Forms Report</Title>
      <div className="fb-form-report">
        <div className="fb-form-report__left">
          <List />
        </div>
        <div className="fb-form-report__right">
          <div className="fb-form-view">
            <div className="row g-0 fb-form-view__detailed--select">
              {USE_DEPARTMENT_KEY && (
                <div className="col-2">
                  <TextField
                    label={departmentLabel}
                    value={
                      configdata
                        ? configdata.GetAcdInstConfigNames.acd_dept_desc
                        : EMPTY_STRING
                    }
                    InputLabelProps={{ shrink: true }}
                    disabled
                    className="daily-activities__textfield"
                  />
                </div>
              )}
              {USE_BRANCH_KEY && (
                <div className="col-2">
                  <TextField
                    label={branchLabel}
                    value={
                      configdata
                        ? configdata.GetAcdInstConfigNames.acd_branch_desc
                        : EMPTY_STRING
                    }
                    InputLabelProps={{ shrink: true }}
                    disabled
                    className="daily-activities__textfield"
                  />
                </div>
              )}
              {USE_CLASS_KEY &&
                configdata?.GetAcdInstConfigNames.acd_class_desc && (
                  <div className="col-2">
                    <TextField
                      label={classLabel}
                      value={configdata.GetAcdInstConfigNames.acd_class_desc}
                      InputLabelProps={{ shrink: true }}
                      disabled
                      className="daily-activities__textfield"
                    />
                  </div>
                )}
              {USE_SEMESTER_KEY &&
                configdata?.GetAcdInstConfigNames.acd_semester_desc && (
                  <div className="col-2">
                    <TextField
                      label={semesterLabel}
                      value={configdata.GetAcdInstConfigNames.acd_semester_desc}
                      InputLabelProps={{ shrink: true }}
                      disabled
                      className="daily-activities__textfield"
                    />
                  </div>
                )}
              {USE_SECTION_KEY &&
                configdata?.GetAcdInstConfigNames.acd_section_desc && (
                  <div className="col-2">
                    <TextField
                      label={sectionLabel}
                      value={configdata.GetAcdInstConfigNames.acd_section_desc}
                      InputLabelProps={{ shrink: true }}
                      disabled
                      className="daily-activities__textfield"
                    />
                  </div>
                )}
            </div>
            <div className="fb-form-view__title row g-0">
              <div
                className={
                  formData && formData.node.is_academic
                    ? "col-5 fb-form-view__title--left"
                    : "fb-form-view__title--non-acd"
                }
              >
                {/* use for nonacademic "fb-form-view__title--non-acd" */}
                <b>{formData && formData.node.form_name}</b>{" "}
                <span className="fb-form-view__title--acd">
                  {formData && formData.node.is_academic
                    ? "Academic"
                    : "Non-Academic"}
                </span>
              </div>
              <div className="col fb-form-view__questionwise--emp">
                <div className="fb-form-view__questionwise--emp--flex">
                  <img src={Avatar} alt="" />
                  <div className="fb-form-view__questionwise--emp--block">
                    <b>
                      {teacherData &&
                        teacherData.node.emp_first_name +
                          " " +
                          teacherData.node.emp_middle_name +
                          " " +
                          teacherData.node.emp_last_name}
                    </b>
                    <span> {teacherData && teacherData.node.emp_id}</span>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="fb-form-view__title--flex">
                  <TextField
                    className="fb-form-report__datablock--grid--textfield"
                    label="Start Date"
                    InputLabelProps={{ shrink: true }}
                    disabled
                    value={
                      formData &&
                      toInputStandardDate(formData.node.form_st_date)
                    }
                  />
                  <img src={Compare} alt="" />
                  <TextField
                    className="fb-form-report__datablock--grid--textfield"
                    label="End Date"
                    InputLabelProps={{ shrink: true }}
                    disabled
                    value={
                      formData &&
                      toInputStandardDate(formData.node.form_end_date)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="fb-form-view__ques-graph">
              {OverAllData?.GetAcdFeedbackFormEmpClassWiseRating?.map(
                (item, index) => (
                  <QuestionWiseGraphClassWise
                    key={index}
                    data={{
                      GetAcdFeedbackFormEmpClassWiseRating: item,
                    }}
                    subject={item.subject_details.subj_desc}
                    subjectId={item.subj_master_id}
                  />
                )
              )}
            </div>

            <Button mode="back" onClick={() => navigate(-1)} /> 
          </div>
        </div>
      </div>

      <Drawer
        anchor="right"
        open={view}
        onClose={() => setView(!view)}
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
      >
        <FormNotFilled setModalFlag={setView} />
      </Drawer>
    </>
  );
};

export default QuestionWise;
