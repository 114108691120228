import { makeStyles } from "@mui/styles";

export const studentDetailsStyles = makeStyles(() => ({
  drawer: {
    width: "25%",
  },
  drawerPaper: {
    top: "auto !important",
    bottom: " 0px !important",
    height: "fit-content !important",
    width: "25%",
    borderRadius: "6px 0px",
  },
}));
export const attendanceOverViewStyles = makeStyles(() => ({
  drawer: {
    width: "25%",
  },
  drawerPaper: {
    bottom: "0px !important",
    width: "40%",
    borderRadius: "6px 0px",
    padding: 10,
    height: "100% !important",
  },
}));
export const perStudentAttendanceStyles = makeStyles(() => ({
  drawer: {
    width: "25%",
  },
  drawerPaper: {
    bottom: "0px !important",
    width: "60%",
    borderRadius: "6px 0px",
    padding: 10,
    height: "100% !important",
  },
}));
export const campusEntryStyles = makeStyles(() => ({
  drawer: {
    width: "25%",
  },
  drawerPaper: {
    top: "110px !important",
    bottom: "0px !important",
    width: "40%",
    borderRadius: "6px 0px",
    padding: "10px !important",
    height: "calc(100% - 110px) !important",
  },
}));
export const AdjustFeeStyles = makeStyles(() => ({
  drawer: {
    width: "25%",
  },
  drawerPaper: {
    top: "110px !important",
    bottom: "0px !important",
    width: "60%",
    borderRadius: "6px 0px",
    padding: "20px",
    height: "calc(100% - 110px) !important",
  },
}));
export const templateViewStyles = makeStyles(() => ({
  drawer: {
    width: "25%",
  },
  drawerPaper: {
    top: "110px !important",
    bottom: "0px !important",
    width: "35%",
    borderRadius: "6px 0px",
    height: "calc(100% - 110px) !important",
    padding: 10,
  },
}));
export const HolidaysListStyles = makeStyles(() => ({
  drawer: {
    width: "25%",
  },
  drawerPaper: {
    top: "110px !important",
    bottom: "0px !important",
    width: "35%",
    borderRadius: "6px 0px",
    height: "calc(100% - 110px) !important",
    padding: 15,
    zIndex: "900 !important",
    backgroundColor: "none !important",
  },
}));
export const sectionAllocationStyles = makeStyles(() => ({
  drawer: {
    width: "25%",
  },
  drawerPaper: {
    top: "110px !important",
    bottom: "0px !important",
    width: "60%",
    borderRadius: "6px 0px",
    height: "calc(100% - 110px) !important",
    zIndex: "100 !important",
  },
}));
export const empAttendanceStatus = makeStyles(() => ({
  drawer: {
    width: "25%",
    height: "fit-content",
  },
  drawerPaper: {
    top: "auto !important",
    bottom: "0px !important",
    width: "30% !important",
    height: "fit-content !important",
    borderRadius: "6px 0px !important",
    padding: 10,
  },
}));

export const addOrganizationalCharts = makeStyles(() => ({
  drawer: {
    width: "30%",
  },
  drawerPaper: {
    top: "auto !important",
    bottom: " 0px !important",
    height: "calc(100% - 110px) !important",
    width: "35%",
    borderRadius: "6px 0px",
  },
}));
export const allocateTeachers = makeStyles(() => ({
  drawer: {
    width: "50%",
  },
  drawerPaper: {
    top: "auto !important",
    bottom: " 0px !important",
    height: "calc(100% - 110px) !important",
    width: "50%",
    borderRadius: "6px 0px",
    padding: 10,
  },
}));
