import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../../../stories/Button/Button";
import { Title } from "../../../../../stories/Title/Title";
import { StudentModalStyles } from "../../../../../styles/ModalStyles";
import { TableHeaderProps } from "../../../../../Types/Tables";
import { BankBookTitleProps } from "../../../../../Types/Titles";
import {
  BankOrCash,
  ExcelAlignment,
  ExcelFont,
  ExcelFooterHeader,
  ExcelPageHeader,
  ExcelSheetsNames,
  PageFor,
} from "../../../../../utils/Enum.types";
import Home from "../../../Home/Index";
import Modal from "react-modal";
import Close from "../../../../../images/Close.svg";
import OpeningBalance from "../../../../../images/OpeningBalance.svg";

import {
  formatter,
  getHeaderRowStyle,
  showNegativeInBrackets,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";

import ReceiptModal from "../../AccountLedgerBook/Index";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { AppContext } from "../../../../../context/context";
import { payloadTypes } from "../../../../../context/reducer";
import {
  acct_ldgr_details,
  GetAcctReceiptsAndPaymentsReportVars,
} from "../R&P/Index";
import { useLazyQuery } from "@apollo/client";
import { Cell } from "exceljs";
import ExcelJS from "exceljs";
import Eduate from "../../../../../images/Eduate_Logo_image.png";

import {
  GetAcctAssetsReport,
  GetAcctBankReport,
  GetAcctCashReport,
  GetAcctCorpusFundReport,
  GetAcctLiabilitiesReport,
  GetAcctProfitAndLossReport,
} from "../../../queries/FeeLedgers/query/Byid";
import useToken from "../../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import LoadingModal from "../../../../../pages/LoadingModal";
import { acct_group_ldgr_details } from "../../../hooks/useAcctLedgerData";
import { GetAcctBankReportData, GetAcctCashReportData } from "../types";
import OpeningBalancrBs from "./OpeningBalanceBs";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  A5_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  A_CELL,
  B4_CELL,
  BLOB_TYPE,
  BORDER_DATA,
  DOWNLOAD,
  D_CELL,
  E5_CELL,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  E_CELL,
  F4_CELL,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FOOTER_CSS,
  FROZEN_CELLS,
  GROUP_LDGR_FONT,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  INCOME_FONT,
  TABLE_CSS,
  TABLE_HEADER_CSS,
  TABLE_HEADER_CSS2,
  TABLE_HEADER_CSS4,
} from "../../../../Library/Constants";
import { RandPheaderData } from "../../../common/HeaderConsts";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import useAcctTableJson from "../../../json/useAcctTableJson";
const { AccountsTitles } = require("../../../json/title.json");
interface gr_ldgr_bs_summary {
  id: number;
  gr_bs_ob: number;
  gr_bs_cr: number;
  gr_bs_db: number;
  gr_bs_cb: number;
  acct_group_ldgr_details: acct_group_ldgr_details;
}
interface acct_ldgr_bs_summary {
  ldgr_bs_ob: number;
  ldgr_bs_cr: number;
  ldgr_bs_db: number;
  ldgr_bs_cb: number;
  acct_group_ldgr_details: acct_group_ldgr_details;
  acct_ldgr_details: acct_ldgr_details;
}
interface GetAcctIncomeReportDetails {
  group_ldgr_bs_summary: gr_ldgr_bs_summary[];
  acct_ldgr_bs_summary: acct_ldgr_bs_summary[];
}
interface GetAcctIncomeReportData {
  GetAcctAssetsReport: GetAcctIncomeReportDetails;
  GetAcctLiabilitiesReport: GetAcctIncomeReportDetails;
  GetAcctBankReport: GetAcctIncomeReportDetails;
}

export interface GetAcctProfitAndLossReportDetails {
  gr_ie_cb: number;
  acct_group_ldgr_details: acct_group_ldgr_details;
}

interface GetAcctCorpusFundReportDetails {
  gr_bs_ob: number;
  acct_group_ldgr_details: acct_group_ldgr_details;
}
export interface GetAcctProfitAndLossReportData {
  GetAcctProfitAndLossReport: GetAcctProfitAndLossReportDetails;
}

interface GetAcctCorpusFundReportData {
  GetAcctCorpusFundReport: GetAcctCorpusFundReportDetails;
}
interface Props {
  pageType: PageFor;
  setModalFlag?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Index = ({ pageType, setModalFlag }: Props) => {
  const { dispatch, state } = useContext(AppContext);

  const [receiptModal, setReceiptModal] = useState(false);
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { serverDate } = useServerDateandTime();
  const [openingBalance, setOpeningBalance] = useState(false);

  let totalLiabality = 0;
  let totalAssests = 0;
  let grandTotalProfitLoss = 0;
  let grandTotalofCorpusAccount = 0;
  let liabilityGrandTotal = 0;
  let assestsGrandTotal = 0;
  let cashAccountGrandTotal = 0;
  let bankAccountGrandTotal = 0;
  let differenceGrandTotal = 0;
  const [GetAssests, { data: Assests, loading, error }] = useLazyQuery<
    GetAcctIncomeReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctAssetsReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });
  const [
    GetLiabilities,
    { data: Liabilities, loading: LiabilitiesLoading, error: LiabilitiesError },
  ] = useLazyQuery<
    GetAcctIncomeReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctLiabilitiesReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });

  const [GetProfitAndLoss, { data: ProfitndLoss }] = useLazyQuery<
    GetAcctProfitAndLossReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctProfitAndLossReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });
  const [GetBankAccount, { data: BankAccount }] = useLazyQuery<
    GetAcctBankReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctBankReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });
  const { InstDetails } = useInstDetails(1);

  const [GetCashAccount, { data: CashAccount }] = useLazyQuery<
    GetAcctCashReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctCashReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });
  const [GetCorpousAccount, { data: CorpusAccount }] = useLazyQuery<
    GetAcctCorpusFundReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctCorpusFundReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });

  grandTotalProfitLoss = ProfitndLoss
    ? ProfitndLoss.GetAcctProfitAndLossReport.gr_ie_cb
    : 0;
  grandTotalofCorpusAccount = CorpusAccount
    ? CorpusAccount.GetAcctCorpusFundReport.gr_bs_ob
    : 0;
  let message =
    grandTotalProfitLoss < 0
      ? "LESS- INCOME OVER EXPENDITURE"
      : "ADD- EXCESS OF INCOME OVER EXPENDITURE";
  const className = grandTotalProfitLoss < 0 ? "danger" : "totalcount";

  // eslint-disable-next-line
  Liabilities?.GetAcctLiabilitiesReport.group_ldgr_bs_summary.map((edge) => {
    totalLiabality += edge.gr_bs_cb;
  });
  //  eslint-disable-next-line
  Assests?.GetAcctAssetsReport?.group_ldgr_bs_summary?.map((edge) => {
    totalAssests += edge.gr_bs_cb;
  });
  //eslint-disable-next-line
  BankAccount?.GetAcctBankReport.group_ldgr_bank_summary.map((edge) => {
    bankAccountGrandTotal += edge.gr_bs_cb;
  });
  //eslint-disable-next-line
  CashAccount?.GetAcctCashReport.group_ldgr_cash_summary.map((edge) => {
    cashAccountGrandTotal += edge.gr_bs_cb;
  });
  liabilityGrandTotal =
    totalLiabality + grandTotalProfitLoss + grandTotalofCorpusAccount;
  assestsGrandTotal =
    totalAssests + cashAccountGrandTotal + bankAccountGrandTotal;
  differenceGrandTotal =
    assestsGrandTotal > liabilityGrandTotal
      ? assestsGrandTotal - liabilityGrandTotal
      : liabilityGrandTotal - assestsGrandTotal;

  const liability_diffGrandTotal =
    liabilityGrandTotal +
    (assestsGrandTotal > liabilityGrandTotal ? differenceGrandTotal : 0);
  const assets_diffGrandTotal =
    assestsGrandTotal +
    (assestsGrandTotal < liabilityGrandTotal ? differenceGrandTotal : 0);
  const groupedData =
    Liabilities?.GetAcctLiabilitiesReport?.group_ldgr_bs_summary?.map(
      (edge) => {
        const accountLedgers =
          Liabilities.GetAcctLiabilitiesReport.acct_ldgr_bs_summary?.filter(
            (f) =>
              f.acct_group_ldgr_details.id === edge.acct_group_ldgr_details.id
          );
        return {
          groupLedgerDescription: edge.acct_group_ldgr_details.gr_ldgr_desc,
          accountLedgers: accountLedgers?.map((ldgr) => ({
            accountLedgerDescription: ldgr.acct_ldgr_details.ldgr_desc,
            ledgerRpPymtShow: ldgr.ldgr_bs_cb,
          })),
          groupRpPymtShow: edge.gr_bs_cb,
        };
      }
    );
  const groupedAssetsData =
    Assests?.GetAcctAssetsReport.group_ldgr_bs_summary?.map((edge) => {
      const accountLedgers =
        Assests.GetAcctAssetsReport.acct_ldgr_bs_summary?.filter(
          (f) =>
            f.acct_group_ldgr_details.id === edge.acct_group_ldgr_details.id
        );
      return {
        groupLedgerDescription: edge.acct_group_ldgr_details.gr_ldgr_desc,
        accountLedgers: accountLedgers?.map((ldgr) => ({
          accountLedgerDescription: ldgr.acct_ldgr_details.ldgr_desc,
          ledgerRpPymtShow: ldgr.ldgr_bs_cb,
        })),
        groupRpPymtShow: edge.gr_bs_cb,
      };
    });
  const groupedCashData =
    CashAccount?.GetAcctCashReport.group_ldgr_cash_summary?.map((edge) => {
      const accountLedgers =
        CashAccount?.GetAcctCashReport.acct_ldgr_cash_summary?.filter(
          (f) =>
            f.acct_group_ldgr_details.id === edge.acct_group_ldgr_details.id
        );
      return {
        groupLedgerDescription: edge.acct_group_ldgr_details.gr_ldgr_desc,
        accountLedgers: accountLedgers?.map((ldgr) => ({
          accountLedgerDescription: ldgr.acct_ldgr_details.ldgr_desc,
          ledgerRpPymtShow: ldgr.ldgr_bs_cb,
        })),
        groupRpPymtShow: edge.gr_bs_cb,
      };
    });
  const groupedBankData =
    BankAccount?.GetAcctBankReport.group_ldgr_bank_summary?.map((edge) => {
      const accountLedgers =
        BankAccount?.GetAcctBankReport.acct_ldgr_bank_summary?.filter(
          (f) =>
            f.acct_group_ldgr_details.id === edge.acct_group_ldgr_details.id
        );
      return {
        groupLedgerDescription: edge.acct_group_ldgr_details.gr_ldgr_desc,
        accountLedgers: accountLedgers?.map((ldgr) => ({
          accountLedgerDescription: ldgr.acct_ldgr_details.ldgr_desc,
          ledgerRpPymtShow: ldgr.ldgr_bs_cb,
        })),
        groupRpPymtShow: edge.gr_bs_cb,
      };
    });
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.BALANCE_SHEET);
    const dataRows = groupedData
      ? groupedData.map((group) => ({
          group: group.groupLedgerDescription,
          list: group.accountLedgers.map((ledger) => ({
            desc: ledger.accountLedgerDescription,
            amount: format(ledger.ledgerRpPymtShow),
            groupRpPymtShow: format(group.groupRpPymtShow),
          })),
        }))
      : [];
    const dataRows2 = groupedAssetsData
      ? groupedAssetsData.map((group) => ({
          group: group.groupLedgerDescription,
          list: group.accountLedgers.map((ledger) => ({
            desc: ledger.accountLedgerDescription,
            amount: format(ledger.ledgerRpPymtShow),
            groupRpPymtShow: format(group.groupRpPymtShow),
          })),
        }))
      : [];
    const dataRows3 = groupedCashData
      ? groupedCashData.map((group) => ({
          group: group.groupLedgerDescription,
          list: group.accountLedgers.map((ledger) => ({
            desc: ledger.accountLedgerDescription,
            amount: format(ledger.ledgerRpPymtShow),
            groupRpPymtShow: format(group.groupRpPymtShow),
          })),
        }))
      : [];
    const dataRows4 = groupedBankData
      ? groupedBankData.map((group) => ({
          group: group.groupLedgerDescription,
          list: group.accountLedgers.map((ledger) => ({
            desc: ledger.accountLedgerDescription,
            amount: format(ledger.ledgerRpPymtShow),
            groupRpPymtShow: format(group.groupRpPymtShow),
          })),
        }))
      : [];

    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 30;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 21;
    worksheet.getRow(5).height = 25;
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 24;
    worksheet.getColumn(4).width = 3;
    worksheet.getColumn(5).width = 35;
    worksheet.getColumn(6).width = 28;
    worksheet.getColumn(7).width = 28;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "M1:M3");
          worksheet.mergeCells(
            1,
            1,
            1,
            RandPheaderData.length + RandPheaderData.length + 1
          );
          const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
          mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
          mergedCell.fill = HEADER_CSS;
          mergedCell.font = headerStyle[0].font;
          mergedCell.alignment = HEADER_ALIGNMENT;

          const mergedAddress: Cell = worksheet.getCell(A2_CELL);
          mergedAddress.value = InstDetails.data?.nodes[0]?.inst_address;
          mergedAddress.fill = HEADER_CSS;
          mergedAddress.font = ADDRESS_FONT;
          mergedAddress.alignment = HEADER_ALIGNMENT;
          worksheet.mergeCells("A2:G2");

          const mergedPlace: Cell = worksheet.getCell(A3_CELL);
          mergedPlace.value =
            InstDetails.data?.nodes[0]?.inst_place +
            "-" +
            InstDetails.data?.nodes[0]?.inst_pin;

          mergedPlace.fill = HEADER_CSS;
          mergedPlace.font = ADDRESS_FONT;
          mergedPlace.alignment = ADDRESS_ALIGNMENT;
          worksheet.mergeCells("A3:G3");

          const mergedHeader: Cell = worksheet.getCell(B4_CELL);
          mergedHeader.value = ExcelPageHeader.BALANCE_SHEET;
          mergedHeader.fill = FILE_NAME_CSS;
          mergedHeader.font = FILE_NAME_FONT;
          mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("B4:E4");
          const mergedDate: Cell = worksheet.getCell(F4_CELL);
          mergedDate.value = ExcelPageHeader.DATE + toStandardDate(serverDate);
          mergedDate.fill = FILE_NAME_CSS;
          mergedDate.font = FIN_YEAR_FONT;
          mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("F4:G4");
          const mergedYear: Cell = worksheet.getCell(A4_CELL);
          mergedYear.value = state.ActiveFinYr
            ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
            : ExcelPageHeader.YEAR;
          mergedYear.fill = FILE_NAME_CSS;
          mergedYear.font = FIN_YEAR_FONT;
          mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet.mergeCells("A4:A4");

          worksheet.mergeCells("A5:C5");
          const mergedCell2: Cell = worksheet.getCell(A5_CELL);
          mergedCell2.value = ExcelPageHeader.LIABILITIES;
          mergedCell2.fill = TABLE_HEADER_CSS2;
          mergedCell2.font = INCOME_FONT;
          mergedCell2.alignment = {
            horizontal: ExcelAlignment.CENTER,
            vertical: ExcelAlignment.MIDDLE,
          };
          worksheet.mergeCells("E5:G5");
          const mergedCell3: Cell = worksheet.getCell(E5_CELL);
          mergedCell3.value = ExcelPageHeader.ASSETS;
          mergedCell3.fill = TABLE_HEADER_CSS2;
          mergedCell3.font = INCOME_FONT;
          mergedCell3.alignment = {
            horizontal: ExcelAlignment.CENTER,
            vertical: ExcelAlignment.MIDDLE,
          };
          let Char = FIRST_INDEX;

          for (let i = 0; i < RandPheaderData.length; i++) {
            Char = String.fromCharCode(Char.charCodeAt(0) + 1);

            const rowData: Cell = worksheet.getCell(Char + 6);
            rowData.value = RandPheaderData[i];
            rowData.fill = TABLE_HEADER_CSS;
            rowData.border = BORDER_DATA;
            rowData.font = ACC_HEADER_FONT;
            rowData.alignment = {
              horizontal: ExcelAlignment.CENTER,
            };
          }

          let Char2 = D_CELL;

          for (let i = 0; i < RandPheaderData.length; i++) {
            Char2 = String.fromCharCode(Char2.charCodeAt(0) + 1);

            const rowData: Cell = worksheet.getCell(Char2 + 6);
            rowData.value = RandPheaderData[i];
            rowData.fill = TABLE_HEADER_CSS;
            rowData.border = BORDER_DATA;
            rowData.font = ACC_HEADER_FONT;
            rowData.alignment = {
              horizontal: ExcelAlignment.CENTER,
            };
          }
          const rowNumber = 7;
          const row = worksheet.getRow(rowNumber);
          row.values = corpusArray;
          row.font = GROUP_LDGR_FONT;
          const corpusRow = 8;
          const rowHeight = 20;
          const CorpusData = worksheet.getRow(corpusRow);
          CorpusData.values = corpusTotal;
          CorpusData.height = rowHeight;
          for (let i = 1; i <= corpusTotal.length; i++) {
            const cell = worksheet.getCell(corpusRow, i);
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: {
                argb: grandTotalProfitLoss < 0 ? "FFFF9999" : "059669",
              },
            };
            cell.font = {
              color: {
                argb: grandTotalProfitLoss < 0 ? "FF000000" : "FFFFFFFF",
              },
            };
            cell.alignment = {
              horizontal: ExcelAlignment.RIGHT,
            };
          }

          let DataStart = A_CELL;
          let DataNmbr = 9;
          // eslint-disable-next-line
          let Increment = 0;
          // eslint-disable-next-line
          let num = 0;

          dataRows!.forEach((rowData, index) => {
            let cell4 = worksheet.getCell(DataStart + DataNmbr);
            cell4.value = rowData.group;
            worksheet.mergeCells(`A${DataNmbr}:C${DataNmbr}`);
            cell4.font = GROUP_LDGR_FONT;
            cell4.alignment = {
              horizontal: ExcelAlignment.LEFT,
            };
            cell4.fill = TABLE_CSS;

            rowData.list.forEach((listItem, i) => {
              let cellA = worksheet.getCell(`A${DataNmbr + i + 1}`);
              let cellB = worksheet.getCell(`B${DataNmbr + i + 1}`);
              let cellC = worksheet.getCell(
                `C${DataNmbr + rowData.list.length}`
              );
              cellA.value = listItem.desc;
              cellB.value = listItem.amount;
              cellC.value = listItem.groupRpPymtShow;
              cellB.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
              cellA.alignment = {
                horizontal: ExcelAlignment.LEFT,
              };
              cellB.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellC.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellC.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
            });
            DataNmbr += rowData.list ? rowData.list.length + 1 : 1;
            Increment += rowData.list ? rowData.list.length + 1 : 1;
            num = 4 + rowData.list.length + index + 1;
          });
          let DataStartNmbr = E_CELL;
          let Dnumber = 7;
          // eslint-disable-next-line
          let Increment2 = 0;
          // eslint-disable-next-line
          let num2 = 0;
          dataRows2.forEach((row, index) => {
            let cellE4 = worksheet.getCell(DataStartNmbr + Dnumber);
            cellE4.value = row.group;
            worksheet.mergeCells(`E${Dnumber}:G${Dnumber}`);
            cellE4.font = GROUP_LDGR_FONT;
            cellE4.alignment = {
              horizontal: ExcelAlignment.LEFT,
            };
            cellE4.fill = TABLE_CSS;
            row.list.forEach((listItem, i) => {
              let cellE = worksheet.getCell(`E${Dnumber + i + 1}`);
              let cellF = worksheet.getCell(`F${Dnumber + i + 1}`);
              let cellG = worksheet.getCell(
                `G${Dnumber + i + row.list.length}`
              );
              cellE.value = listItem.desc;
              cellF.value = listItem.amount;
              cellG.value = listItem.groupRpPymtShow;
              cellF.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
              cellE.alignment = {
                horizontal: ExcelAlignment.LEFT,
              };
              cellF.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellG.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellG.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
            });
            Dnumber += row.list ? row.list.length + 1 : 1;
            Increment2 += row.list ? row.list.length + 1 : 1;
            num2 = 7 + row.list.length + index + 1;
          });

          dataRows3.forEach((row, index) => {
            let cellE4 = worksheet.getCell(DataStartNmbr + Dnumber);
            cellE4.value = row.group;
            worksheet.mergeCells(`E${Dnumber}:G${Dnumber}`);
            cellE4.font = GROUP_LDGR_FONT;
            cellE4.alignment = {
              horizontal: ExcelAlignment.LEFT,
            };
            cellE4.fill = TABLE_CSS;
            row.list.forEach((listItem, i) => {
              let cellE = worksheet.getCell(`E${Dnumber + i + 1}`);
              let cellF = worksheet.getCell(`F${Dnumber + i + 1}`);
              let styleCellG = worksheet.getCell(`G${Dnumber + i + 1}`);
              let cellG = worksheet.getCell(
                `G${Dnumber + i + row.list.length}`
              );
              cellE.value = listItem.desc;
              cellF.value = listItem.amount;
              cellG.value = listItem.groupRpPymtShow;
              styleCellG.fill = TABLE_HEADER_CSS4;
              cellF.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
              cellE.alignment = {
                horizontal: ExcelAlignment.LEFT,
              };
              cellF.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellG.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellG.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
            });
            Dnumber += row.list ? row.list.length + 1 : 1;
            Increment2 += row.list ? row.list.length + 1 : 1;
            num2 = 7 + row.list.length + index + 1;
          });
          dataRows4.forEach((row, index) => {
            let cellE4 = worksheet.getCell(DataStartNmbr + Dnumber);
            cellE4.value = row.group;
            worksheet.mergeCells(`E${Dnumber}:G${Dnumber}`);
            cellE4.font = GROUP_LDGR_FONT;
            cellE4.alignment = {
              horizontal: ExcelAlignment.LEFT,
            };
            cellE4.fill = TABLE_CSS;
            row.list.forEach((listItem, i) => {
              let cellE = worksheet.getCell(`E${Dnumber + i + 1}`);
              let cellF = worksheet.getCell(`F${Dnumber + i + 1}`);
              let styleCellG = worksheet.getCell(`G${Dnumber + i + 1}`);
              let cellG = worksheet.getCell(
                `G${Dnumber + i + row.list.length}`
              );
              cellE.value = listItem.desc;
              cellF.value = listItem.amount;
              cellG.value = listItem.groupRpPymtShow;
              styleCellG.fill = TABLE_HEADER_CSS4;
              cellF.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
              cellE.alignment = {
                horizontal: ExcelAlignment.LEFT,
              };
              cellF.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellG.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellG.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
            });
            Dnumber += row.list ? row.list.length + 1 : 1;
            Increment2 += row.list ? row.list.length + 1 : 1;
            num2 = 7 + row.list.length + index + 1;
          });

          worksheet.addRow([]);
          const footerRow = worksheet.addRow([]);
          footerRow.getCell(2).value = ExcelFooterHeader.GRAND_TOTAL;
          footerRow.getCell(2).font = {
            name: ExcelFont.ARIAL,
            size: 10,
          };
          footerRow.getCell(2).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };
          footerRow.getCell(6).value = ExcelFooterHeader.GRAND_TOTAL;
          footerRow.getCell(6).font = {
            name: ExcelFont.ARIAL,
            size: 10,
          };
          footerRow.getCell(6).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };

          footerRow.getCell(3).value = format(
            assestsGrandTotal > liabilityGrandTotal
              ? liabilityGrandTotal + differenceGrandTotal
              : liabilityGrandTotal
          );
          footerRow.getCell(3).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };
          footerRow.getCell(7).value = format(
            assestsGrandTotal > liabilityGrandTotal
              ? assestsGrandTotal
              : assestsGrandTotal + differenceGrandTotal
          );
          footerRow.getCell(7).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };
          worksheet.addConditionalFormatting({
            ref: `C${footerRow.number}`,
            rules: FOOTER_CSS,
          });
          worksheet.addConditionalFormatting({
            ref: `G${footerRow.number}`,
            rules: FOOTER_CSS,
          });

          workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
            const blob = new Blob([buffer], {
              type: BLOB_TYPE,
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement(ELEMENT);
            link.href = url;
            link.setAttribute(DOWNLOAD, ExcelSheetsNames.BALANCE_SHEET);
            document.body.appendChild(link);
            link.click();
          });
        });
      });
  };
  const corpusArray = [
    CorpusAccount?.GetAcctCorpusFundReport.acct_group_ldgr_details.gr_ldgr_desc,
    grandTotalofCorpusAccount,
  ];
  const corpusTotal = [
    message,
    format(grandTotalProfitLoss),
    format(grandTotalProfitLoss + grandTotalofCorpusAccount),
  ];

  useEffect(() => {
    if (state.ActiveFinYr) {
      GetAssests();
      GetLiabilities();
      GetProfitAndLoss();
      GetBankAccount();
      GetCashAccount();
      GetCorpousAccount();
    }
  }, [
    state.ActiveFinYr,
    GetAssests,
    GetLiabilities,
    GetProfitAndLoss,
    GetBankAccount,
    GetCashAccount,
    GetCorpousAccount,
  ]);

  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}

      <Title>
        {AccountsTitles.BankBook.ReceiptAndPaymentAccount.Titles.map(
          (title: BankBookTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>{title.BalanceSheet}</React.Fragment>
            );
          }
        )}
      </Title>

      <div
        className={
          pageType === PageFor.GENERAL
            ? "balance-sheet"
            : "balance-sheet__modal"
        }
      >
        {pageType === PageFor.MODAL && (
          <div className="row g-0">
            <div className="col"></div>
            <div className="col-2 balance-sheet__modal--year">
              <TextField
                label="Financial Year"
                disabled
                InputLabelProps={{ shrink: true }}
                className="balance-sheet__modal--textfield"
              />
            </div>
          </div>
        )}
        <div
          className={
            pageType === PageFor.GENERAL
              ? "row g-0 balance-sheet__tableblock"
              : "row g-0 balance-sheet__modal--tableblock"
          }
        >
          <div className="col h100">
            {LiabilitiesError ? (
              <b className="nodata">{LiabilitiesError.message}</b>
            ) : (
              <TableContainer className="balance-sheet__tableblock--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="balance-sheet__tableblock--table--groupheader">
                      <TableCell colSpan={2}>Liabilities</TableCell>
                      <TableCell></TableCell>
                    </TableRow>

                    <TableRow className="balance-sheet__tableblock--table--subheader">
                      {Accounts_Table.Reports.ReceiptAndPaymentAccount.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {
                          CorpusAccount?.GetAcctCorpusFundReport
                            .acct_group_ldgr_details.gr_ldgr_desc
                        }
                      </TableCell>
                      <TableCell id="td-right">
                        {format(grandTotalofCorpusAccount)}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow className={className}>
                      <TableCell>{message}</TableCell>
                      <TableCell id="td-right">
                        {grandTotalProfitLoss < 0
                          ? `(${format(Math.abs(grandTotalProfitLoss))})`
                          : format(grandTotalProfitLoss)}
                      </TableCell>
                      <TableCell id="td-right">
                        {grandTotalProfitLoss + grandTotalofCorpusAccount < 0
                          ? `(${format(
                              Math.abs(
                                grandTotalProfitLoss + grandTotalofCorpusAccount
                              )
                            )})`
                          : format(
                              grandTotalProfitLoss + grandTotalofCorpusAccount
                            )}
                      </TableCell>
                    </TableRow>
                    {Liabilities?.GetAcctLiabilitiesReport?.group_ldgr_bs_summary?.map(
                      (edge, index) => {
                        return (
                          <>
                            <TableRow
                              className="balance-sheet__tableblock--table--rowheader"
                              key={index}
                            >
                              <TableCell colSpan={3}>
                                {edge.acct_group_ldgr_details.gr_ldgr_desc}
                              </TableCell>
                            </TableRow>
                            {Liabilities.GetAcctLiabilitiesReport.acct_ldgr_bs_summary
                              ?.filter(
                                (f) =>
                                  f.acct_group_ldgr_details.id ===
                                  edge.acct_group_ldgr_details.id
                              )
                              .map((ldgr, index2) => {
                                return (
                                  <TableRow
                                    key={index2}
                                    onClick={() => {
                                      dispatch({
                                        type: payloadTypes.SET_ACCOUNT_LEDGER_ID,
                                        payload: {
                                          accountLedgerId:
                                            ldgr.acct_ldgr_details.id,
                                        },
                                      });
                                    }}
                                  >
                                    <TableCell>
                                      {ldgr.acct_ldgr_details.ldgr_desc}
                                    </TableCell>
                                    <TableCell
                                      id="td-right"
                                      className="balance-sheet__tableblock--table--amount"
                                    >
                                      {ldgr.ldgr_bs_cb < 0
                                        ? `(${format(
                                            Math.abs(ldgr.ldgr_bs_cb)
                                          )})`
                                        : format(ldgr.ldgr_bs_cb)}
                                    </TableCell>
                                    <TableCell
                                      id="td-right"
                                      className="balance-sheet__tableblock--table--amount font-green "
                                    >
                                      {index2 ===
                                      Liabilities.GetAcctLiabilitiesReport.acct_ldgr_bs_summary.filter(
                                        (f) =>
                                          f.acct_group_ldgr_details.id ===
                                          edge.acct_group_ldgr_details.id
                                      ).length -
                                        1
                                        ? edge.gr_bs_cb < 0
                                          ? `(${format(
                                              Math.abs(edge.gr_bs_cb)
                                            )})`
                                          : format(edge.gr_bs_cb)
                                        : EMPTY_STRING}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
          <div className="col h100">
            {error ? (
              <b className="nodata">{error.message}</b>
            ) : (
              <TableContainer className="balance-sheet__tableblock--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="balance-sheet__tableblock--table--groupheader">
                      <TableCell colSpan={2}>Assests </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow className="balance-sheet__tableblock--table--subheader">
                      {Accounts_Table.Reports.ReceiptAndPaymentAccount.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {Assests?.GetAcctAssetsReport.group_ldgr_bs_summary?.map(
                        (edge, index) => {
                          return (
                            <>
                              <TableRow
                                className="balance-sheet__tableblock--table--rowheader"
                                key={index}
                              >
                                <TableCell colSpan={3}>
                                  {edge.acct_group_ldgr_details.gr_ldgr_desc}
                                </TableCell>
                              </TableRow>
                              {Assests.GetAcctAssetsReport.acct_ldgr_bs_summary
                                .filter(
                                  (f) =>
                                    f.acct_group_ldgr_details.id ===
                                    edge.acct_group_ldgr_details.id
                                )
                                .map((ldgr, index2) => {
                                  return (
                                    <TableRow key={index2}>
                                      <TableCell>
                                        {ldgr.acct_ldgr_details.ldgr_desc}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="balance-sheet__tableblock--table--amount"
                                      >
                                        {ldgr.ldgr_bs_cb < 0
                                          ? `(${format(
                                              Math.abs(ldgr.ldgr_bs_cb)
                                            )})`
                                          : format(ldgr.ldgr_bs_cb)}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="balance-sheet__tableblock--table--amount font-green"
                                      >
                                        {index2 ===
                                        Assests.GetAcctAssetsReport.acct_ldgr_bs_summary.filter(
                                          (f) =>
                                            f.acct_group_ldgr_details.id ===
                                            edge.acct_group_ldgr_details.id
                                        ).length -
                                          1
                                          ? edge.gr_bs_cb < 0
                                            ? `(${format(
                                                Math.abs(edge.gr_bs_cb)
                                              )})`
                                            : format(edge.gr_bs_cb)
                                          : EMPTY_STRING}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </>
                          );
                        }
                      )}
                      {CashAccount?.GetAcctCashReport.group_ldgr_cash_summary?.map(
                        (edge, index) => {
                          return (
                            <>
                              <TableRow
                                className="balance-sheet__tableblock--table--rowheader"
                                key={index}
                              >
                                <TableCell colSpan={3}>
                                  {edge.acct_group_ldgr_details.gr_ldgr_desc}
                                </TableCell>
                              </TableRow>
                              {CashAccount?.GetAcctCashReport.acct_ldgr_cash_summary
                                .filter(
                                  (f) =>
                                    f.acct_group_ldgr_details.id ===
                                    edge.acct_group_ldgr_details.id
                                )
                                .map((ldgr, index2) => {
                                  return (
                                    <TableRow key={index2}>
                                      <TableCell>
                                        {ldgr.acct_ldgr_details.ldgr_desc}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="balance-sheet__tableblock--table--amount"
                                      >
                                        {ldgr.ldgr_bs_cb < 0
                                          ? `(${format(
                                              Math.abs(ldgr.ldgr_bs_cb)
                                            )})`
                                          : format(ldgr.ldgr_bs_cb)}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="balance-sheet__tableblock--table--amount font-green"
                                      >
                                        {index2 ===
                                        CashAccount?.GetAcctCashReport.acct_ldgr_cash_summary.filter(
                                          (f) =>
                                            f.acct_group_ldgr_details.id ===
                                            edge.acct_group_ldgr_details.id
                                        ).length -
                                          1
                                          ? edge.gr_bs_cb < 0
                                            ? `(${format(
                                                Math.abs(edge.gr_bs_cb)
                                              )})`
                                            : format(edge.gr_bs_cb)
                                          : EMPTY_STRING}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </>
                          );
                        }
                      )}
                      {BankAccount?.GetAcctBankReport.group_ldgr_bank_summary?.map(
                        (edge, index) => {
                          return (
                            <>
                              <TableRow
                                className="balance-sheet__tableblock--table--rowheader"
                                key={index}
                              >
                                <TableCell colSpan={3}>
                                  {edge.acct_group_ldgr_details.gr_ldgr_desc}
                                </TableCell>
                              </TableRow>
                              {BankAccount?.GetAcctBankReport.acct_ldgr_bank_summary
                                .filter(
                                  (f) =>
                                    f.acct_group_ldgr_details.id ===
                                    edge.acct_group_ldgr_details.id
                                )
                                .map((ldgr, index2) => {
                                  return (
                                    <TableRow key={index2}>
                                      <TableCell>
                                        {ldgr.acct_ldgr_details.ldgr_desc}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="balance-sheet__tableblock--table--amount"
                                      >
                                        {ldgr.ldgr_bs_cb < 0
                                          ? `(${format(
                                              Math.abs(ldgr.ldgr_bs_cb)
                                            )})`
                                          : format(ldgr.ldgr_bs_cb)}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="balance-sheet__tableblock--table--amount font-green"
                                      >
                                        {index2 ===
                                        BankAccount?.GetAcctBankReport.acct_ldgr_bank_summary.filter(
                                          (f) =>
                                            f.acct_group_ldgr_details.id ===
                                            edge.acct_group_ldgr_details.id
                                        ).length -
                                          1
                                          ? edge.gr_bs_cb < 0
                                            ? `(${format(
                                                Math.abs(edge.gr_bs_cb)
                                              )})`
                                            : format(edge.gr_bs_cb)
                                          : EMPTY_STRING}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </>
                          );
                        }
                      )}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
        <div className="row g-0 balance-sheet__difference-amount--column">
          <div className="col">
            {assestsGrandTotal > liabilityGrandTotal && (
              <div className="balance-sheet__difference-amount">
                <span> Difference In Opening Balance :</span>
                <b>{showNegativeInBrackets(differenceGrandTotal)}</b>
              </div>
            )}
          </div>
          <div className="col">
            {assestsGrandTotal < liabilityGrandTotal && (
              <div className="balance-sheet__difference-amount">
                <span>Difference In Opening Balance :</span>
                <b>{showNegativeInBrackets(differenceGrandTotal)}</b>
              </div>
            )}
          </div>
        </div>

        <div className="balance-sheet__footer row g-0">
          <div className="col balance-sheet__footer--total">
            <div className="balance-sheet__footer--total--text">
              Grand Total :
            </div>
            <div className="balance-sheet__footer--total--count">
              {liability_diffGrandTotal > 0
                ? format(Math.abs(liability_diffGrandTotal))
                : `(${format(Math.abs(liability_diffGrandTotal))})`}
            </div>
          </div>

          <div className="col balance-sheet__footer--total">
            <div className="balance-sheet__footer--total--text">
              Grand Total :
            </div>
            <div className="balance-sheet__footer--total--count">
              {assets_diffGrandTotal > 0
                ? format(Math.abs(assets_diffGrandTotal))
                : `(${format(Math.abs(assets_diffGrandTotal))})`}
            </div>
          </div>
        </div>
      </div>
      {pageType === PageFor.GENERAL ? (
        <div className="button-left">
          <Button mode="export" onClick={downloadExcel} />
          <Button onClick={() => setOpeningBalance(true)}>
            <img src={OpeningBalance} alt="/" /> Opening Balance Statement
          </Button>
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      ) : (
        <Button mode="cancel" onClick={() => setModalFlag?.(false)} />
      )}

      {/* receiptmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={receiptModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ReceiptModal type={BankOrCash.BANK} pageType={PageFor.MODAL} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setReceiptModal(!receiptModal)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={openingBalance}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <OpeningBalancrBs setModal={setOpeningBalance} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setOpeningBalance(!openingBalance)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={loading || LiabilitiesLoading} />
    </>
  );
};

export default Index;
