import React, { useContext, useEffect, useState } from "react";
import List from "./List";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import InstDetails from "../../DailyActivities/InstDetails";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  HEADER_TEXT_ALIGN,
  useDataGridStyles,
} from "../../../../styles/DataGridTableStyles";
import { Operation } from "../../../../utils/Enum.types";
import {
  GetnumberOfDaysInMonth,
  handleDate,
  MonthName,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { EMPTY_STRING } from "../../../../utils/constants";
import Next from "../../../../images/CalenderNextButton.svg";
import Previous from "../../../../images/CalenderPreviousButton.svg";
import Input from "../../../../stories/Input/Input";
import { GetAcdStudentsAttendance } from "../../queries/holidays/list";
import { useLazyQuery } from "@apollo/client";
import { AttendenceQueryType } from "../../hooks/useAcdStudentAttendance";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import {
  GetAcdAttendanceMonthlyForStdsData,
  GetAcdStudentsAttendanceVars,
} from "../../DailyActivities/Attendance/AbsenteesList";
import { msgType } from "../../../../utils/Form.types";
import { INCREMENT_DECREMENT } from "../../../Enquiry/Dashboard/Index";
import dayjs from "dayjs";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { sessionWiseAttendanceStyles } from "../../../../styles/StickyTableStyles";
import MessageModal from "../../../../pages/MessageModal";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
const ViewMonthwise = () => {
  const navigate = useNavigate();
  const tableClasses = sessionWiseAttendanceStyles();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId, entryId } = useParams();
  const dataClasses = useDataGridStyles();
  const [date, setMonthDate] = useState(new Date());
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { serverDate } = useServerDateandTime();

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const numberOfDaysInMonth = GetnumberOfDaysInMonth(date.toString());

  const daysArray = Array.from({ length: numberOfDaysInMonth }, (_, i) => {
    const dayIndex =
      (i + new Date(date.getFullYear(), date.getMonth(), 1).getDay()) % 7;
    return { day: days[dayIndex], date: dayIndex };
  });
  const {
    entry_level,
    USE_SECTION_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
  } = useInstitutionConfiguration();

  const [GetStdDataAttendance, { data: stdAttData }] = useLazyQuery<
    GetAcdAttendanceMonthlyForStdsData,
    GetAcdStudentsAttendanceVars
  >(GetAcdStudentsAttendance);

  useEffect(() => {
    if (token && entryId) {
      GetStdDataAttendance({
        variables: {
          token,
          input: {
            att_query_type: USE_SECTION_KEY
              ? AttendenceQueryType.ATT_STD_BY_SECTION_ID
              : USE_SEMESTER_KEY
              ? AttendenceQueryType.ATT_STD_BY_SEMESTER_ID
              : USE_CLASS_KEY
              ? AttendenceQueryType.ATT_STD_BY_CLASS_ID
              : AttendenceQueryType.ATT_STD_BY_SECTION_ID,
            entry_id: entryId!,
            cal_month: toIsoDate(date.toString()),
            inst_id: InstId!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            date_of_attendance: toIsoDate(date.toString()),
            subj_master_id: 0,
            per_std_subj_allocation: false,
          },
        },
      });
    }
  }, [token, entryId, GetStdDataAttendance, date]);

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Attendance Report</Title>
      <div className="view-overall-att">
        <div className="view-overall-att__left">
          <List />
        </div>
        <div className="view-overall-att__right">
          <InstDetails />
          <div className="view-overall-att__select row g-0">
            <div className="col-2">
              <Input id="search" placeholder="Search..." />
            </div>
            <div className="col">
              {" "}
              <Title variant="subtitle1">
                <img
                  src={Previous}
                  alt="/"
                  onClick={() =>
                    handleDate(
                      date,
                      setMonthDate,
                      state.ActiveAcdYr
                        ? state.ActiveAcdYr.acd_st_date
                        : EMPTY_STRING,
                      state.ActiveAcdYr
                        ? state.ActiveAcdYr.acd_end_date
                        : EMPTY_STRING,
                      setMessage,
                      INCREMENT_DECREMENT.DECREMENT
                    )
                  }
                />
                {MonthName(date.toString())}

                <img
                  src={Next}
                  alt="/"
                  onClick={() => {
                    const nextDate = new Date(
                      date.getFullYear(),
                      date.getMonth() + 1,
                      date.getDate()
                    );
                    const serverDateDayjs = dayjs(serverDate);
                    const selectedDateDayjs = dayjs(nextDate);
                    if (
                      serverDateDayjs.isAfter(selectedDateDayjs) &&
                      !serverDateDayjs.isSame(selectedDateDayjs)
                    ) {
                      handleDate(
                        date,
                        setMonthDate,
                        state.ActiveAcdYr
                          ? state.ActiveAcdYr.acd_st_date
                          : EMPTY_STRING,
                        state.ActiveAcdYr
                          ? state.ActiveAcdYr.acd_end_date
                          : EMPTY_STRING,
                        setMessage,
                        INCREMENT_DECREMENT.INCREMENT
                      );
                    } else {
                      setMessage({
                        flag: true,
                        operation: Operation.NONE,
                        message: "Cannot Access Future Dates",
                      });
                    }
                  }}
                />
              </Title>
            </div>
            <div className="col-2 flex-end">
              <div className="student-total-count">
                Total Student :{" "}
                <b>{stdAttData?.GetAcdStudentsAttendance.length}</b>
              </div>
            </div>
          </div>

          <div className={`view-overall-att__monthly ${dataClasses.root}`}>
            <TableContainer className={tableClasses.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={tableClasses.stickyHeaderSessions}
                      align={HEADER_TEXT_ALIGN}
                    >
                      Sl No.
                    </TableCell>
                    <TableCell
                      className={tableClasses.stickyHeadertime}
                      align={HEADER_TEXT_ALIGN}
                    >
                      Admission No
                    </TableCell>
                    <TableCell
                      className={tableClasses.stickyHeadertime}
                      align={HEADER_TEXT_ALIGN}
                    >
                      Name
                    </TableCell>
                    {daysArray.map((day, index) => (
                      <TableCell
                        key={index}
                        className={tableClasses.stickyHeader}
                        align={HEADER_TEXT_ALIGN}
                      >
                        <span>{index + 1}</span>
                        <br /> {day.day}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {stdAttData &&
                    stdAttData.GetAcdStudentsAttendance.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell className={tableClasses.stickyColumnSession}>
                          {index + 1}
                        </TableCell>
                        <TableCell className={tableClasses.stickyColumnTime}>
                          {data.student_details.std_adm_no}
                        </TableCell>
                        <TableCell className={tableClasses.stickyColumnTime}>
                          {data.student_details.first_name +
                            " " +
                            data.student_details.middle_name +
                            " " +
                            data.student_details.last_name}
                        </TableCell>
                        {daysArray.map((day, dayIndex) => {
                          const dayArray = (
                            data as unknown as Record<string, string>
                          )[`day_${dayIndex + 1}`];
                          return (
                            <TableCell
                              key={dayIndex}
                              id="td-center"
                              className={`${tableClasses.stickyColumn} ${
                                dayArray === "P"
                                  ? "font-green"
                                  : dayArray === "A"
                                  ? "font-red"
                                  : dayArray === "W"
                                  ? "sub-wise-att__weekend"
                                  : dayArray === "H"
                                  ? "sub-wise-att__holiday"
                                  : ""
                              }`}
                            >
                              {dayArray || "-"}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button mode="excel">Export</Button>
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        value={message.message}
        operation={message.operation}
      />
    </>
  );
};

export default ViewMonthwise;
