import React, { ChangeEvent, useContext, useEffect, useState } from "react";

import { Typography } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Clock from "../../../../images/Timeline.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummaryInLessonPlanner,
} from "../../../../styles/Accordion";

import Completed from "../../../../images/Completed.svg";
import InProgress from "../../../../images/InProgress.svg";
import Timer from "../../../../images/Timeline.svg";

import CompletedStatus from "../../../../images/CompletedStatus.svg";
import InProgressStatus from "../../../../images/ProgressStatus.svg";
import ProgressGraph from "./ProgressGraph";
import { SubjectType } from "../../types/subject/Subject";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetAcdChaperWiseStatusData,
  GetAcdChaperWiseStatusVars,
} from "../../types/lessonplanner/queries";
import { GetAcdChaperWiseStatus } from "../../queries/lesson_planner/list";
import useToken from "../../../../customhooks/useToken";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import useInstConfigByEntryId from "../../hooks/useInstConfigByEntryId";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";

import { InstitutionConfigurationTypes } from "../../../../utils/Enum.types";
import { optionsType } from "../../../../utils/Form.types";
import { GetSwConfigReferenceDataByKey } from "../../../../queries/institution/configuration/query/SoftwreConfig";
import {
  SoftwareConfigList,
  SoftwareVars,
} from "../../../../Types/configtypes";
import {
  UpdateLessonPlannerStatusData,
  UpdateLessonPlannerStatusVars,
} from "../../types/lessonplanner/mutations";
import { UpdateLessonPlannerStatus } from "../../queries/lesson_planner/mutation";
import { convertMinsToHours } from "../../../../utils/UtilFunctions";
import { AppContext } from "../../../../context/context";
import useEntryIdByStudent from "../../hooks/useEntryIdByStudent";
import useCheckAllocationType from "../../hooks/useCheckAllocationType";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";

interface Props {
  selectedSubject: SubjectType | null;
}
const List = ({ selectedSubject }: Props) => {
  const { token } = useToken();
  const { entryId, InstId } = useParams();

  const { entry_id } = useEntryIdByStudent();
  const { entry_level } = useInstitutionConfiguration();
  const { state } = useContext(AppContext);
  const { studentEntryId } = useStudentDatabyId();

  const { flag } = useCheckAllocationType(studentEntryId);

  const [statusOptions, setStatusOptions] = useState<optionsType[]>([]);

  const { activeAcademicYearData } = useActiveAcademicYear();

  const [selectedLessonMainIds, setSelectedLessonMainIds] = useState<number[]>(
    []
  );

  const { user_details } = useLoggedInUserDetails();
  const [UpdateAcdLessonDetail] = useMutation<
    UpdateLessonPlannerStatusData,
    UpdateLessonPlannerStatusVars
  >(UpdateLessonPlannerStatus);

  const [GetConfigData, { data: configData }] = useLazyQuery<
    SoftwareConfigList,
    SoftwareVars
  >(GetSwConfigReferenceDataByKey, {
    variables: {
      token,
      config_key: InstitutionConfigurationTypes.LESSON_STATUS_OPTIONS,
      inst_id: InstId!,
      page_number: 0,
    },
  });

  const [GetAcdLessonDetails, { data }] = useLazyQuery<
    GetAcdChaperWiseStatusData,
    GetAcdChaperWiseStatusVars
  >(GetAcdChaperWiseStatus, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_id: state.claims
        ? state.claims.STUDENT
          ? entry_id
          : state.claims.EMPLOYEE
          ? Number(entryId!)
          : 0
        : 0,
      entry_level: entry_level,
      inst_id: InstId!,
      subj_master_id: selectedSubject ? selectedSubject.id! : 0,
      token,
      per_std_subj_allocation: flag ? flag! : false,
    },
  });

  useEffect(() => {
    if (token) {
      GetConfigData().then(({ data }) => {
        if (data && data.GetSwConfigReferenceDataByKey) {
          const { list_item_list } = data.GetSwConfigReferenceDataByKey;
          const separatedItems = list_item_list
            .split(";")
            .filter((item) => item !== "");
          setStatusOptions(
            separatedItems.map((item) => {
              const separateColon = item.split(":");
              return {
                label: separateColon[0],
                value: separateColon[1],
              };
            })
          );
        }
      });
    }
  }, [GetConfigData, configData, InstId, token]);
  const {
    InstConfigDetails: { alloted },
  } = useInstConfigByEntryId(Number(entryId));
  const handleChange =
    (lesson_main_id: number) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      const isExpandIconClicked =
        (event.target as HTMLElement).classList.contains(
          "MuiIconButton-root"
        ) ||
        (event.target as HTMLElement).classList.contains("MuiSvgIcon-root");
      let tempMainIds = selectedLessonMainIds;

      if (isExpandIconClicked) {
        if (tempMainIds.includes(lesson_main_id)) {
          tempMainIds = tempMainIds.filter((res) => res !== lesson_main_id);
        } else {
          tempMainIds = [...tempMainIds, lesson_main_id];
        }
        setSelectedLessonMainIds(tempMainIds);
      }
    };
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  );

  const handleSelectChange = (
    event: ChangeEvent<HTMLSelectElement>,
    lesson_main_id: number,
    lesson_details_id: number
  ) => {
    const selectedOption = event.target.value;
    setSelectedValue(selectedOption);

    UpdateAcdLessonDetail({
      variables: {
        acd_yr_id: activeAcademicYearData.data
          ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
          : 0,
        entry_id: entryId!,
        entry_level,
        user_details,
        inst_id: InstId!,
        token,
        input: [
          {
            subj_master_id:
              selectedSubject && selectedSubject.id ? selectedSubject.id : 0,
            lesson_main_id,
            lesson_details_id,
            status: event.target.value,
          },
        ],
        per_std_subj_allocation: flag!,
      },
      refetchQueries: [
        {
          query: GetAcdChaperWiseStatus,
          variables: {
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            entry_id: state.claims
              ? state.claims.STUDENT
                ? entry_id
                : state.claims.EMPLOYEE
                ? Number(entryId!)
                : 0
              : 0,
            entry_level: entry_level,
            inst_id: InstId!,
            subj_master_id: selectedSubject ? selectedSubject.id! : 0,
            token,
            per_std_subj_allocation: flag ? flag! : false,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.UpdateLessonPlannerStatus) {
      }
    });
  };

  const getBackgroundColor = (status: string): string => {
    switch (status) {
      case "C":
        return "#dcfce7";
      case "P":
        return "#fee2e2";
      default:
        return "#fef3c7";
    }
  };

  const getIcon = (status: string): React.ReactElement => {
    switch (status) {
      case "C":
        return (
          <>
            <img src={Completed} alt="" />
          </>
        );
      case "P":
        return (
          <>
            <img src={Timer} alt="" />
          </>
        );
      default:
        return (
          <>
            <img src={Timer} alt="" />
          </>
        );
    }
  };

  useEffect(() => {
    if (token && activeAcademicYearData.data && selectedSubject)
      GetAcdLessonDetails();
  }, [
    GetAcdLessonDetails,
    token,
    activeAcademicYearData.data,
    alloted.id,
    alloted.level,
    selectedSubject,
    selectedLessonMainIds,
    state.ActiveAcdYr,
    entryId,
    state.claims,
    entry_id,
    flag,
  ]);
  return (
    <>
      <div className="lesson-planner-list">
        <div className="create-lesson-planner__header">
          {selectedSubject ? (
            <div className="create-lesson-planner__header--selected">
              <b>{selectedSubject.subj_desc}</b>
              <span>( {selectedSubject.subj_code} )</span>
            </div>
          ) : null}
          <div className="create-lesson-planner__header--durations">
            <span>Total Chapters -</span>
            <b className="font-blue">
              {data
                ? data.GetAcdChaperWiseStatus.header_data.total_chapters
                : 0}
            </b>
            <div className="create-lesson-planner__header--separator"></div>
            <span>Total Duration -</span>
            <b className="font-blue">
              {data
                ? convertMinsToHours(
                    data.GetAcdChaperWiseStatus.header_data.total_duration
                  )
                : 0}
            </b>
          </div>
          <div className="create-lesson-planner__header--graph">
            <ProgressGraph
              percentage={
                data
                  ? data.GetAcdChaperWiseStatus.header_data.completed_percent
                  : 0
              }
            />
          </div>
        </div>
        <div className="create-lesson-planner__topics">
          {data
            ? data.GetAcdChaperWiseStatus.main_chapter_status.map(
                (mainResponse, index) => {
                  return (
                    <Accordion
                      key={index}
                      expanded={selectedLessonMainIds.includes(
                        mainResponse.lesson_main_id
                      )}
                      onChange={handleChange(mainResponse.lesson_main_id)}
                    >
                      <AccordionSummaryInLessonPlanner
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}d-content`}
                        id={`panel${index}d-header`}
                      >
                        <Typography>
                          <div className="lesson-planner-list--header">
                            <div className="lesson-planner-list--title">
                              <div>
                                {index + 1}.&nbsp;
                                {mainResponse.chapter_name}
                                &nbsp;
                              </div>
                              <div className="lesson-planner-list--title--durations">
                                <span>Total Sub topics -</span>
                                <b>{mainResponse.total_sub_topics}</b>
                                <div className="lesson-planner-list--title--separator"></div>
                                <span>Total Duration -</span>
                                <b>
                                  {" "}
                                  {convertMinsToHours(
                                    mainResponse.total_sub_topic_duration
                                  )}
                                </b>
                              </div>
                            </div>
                            <div className="lesson-planner-list--title--status">
                              <span
                                className={`lesson-planner-list--title--${
                                  mainResponse.status === "P"
                                    ? "pending"
                                    : mainResponse.status === "I"
                                    ? "progress"
                                    : "completed"
                                } `}
                              >
                                {mainResponse.status === "C" ? (
                                  <img src={CompletedStatus} alt="/" />
                                ) : mainResponse.status === "I" ? (
                                  <img src={InProgressStatus} alt="/" />
                                ) : (
                                  <img src={Timer} alt="/" />
                                )}
                                {mainResponse.status === "P"
                                  ? "Pending"
                                  : mainResponse.status === "I"
                                  ? "In Progress"
                                  : "Completed"}
                              </span>
                            </div>
                          </div>
                        </Typography>
                      </AccordionSummaryInLessonPlanner>
                      <AccordionDetails>
                        <div>
                          {mainResponse.sub_topic_status.length
                            ? mainResponse.sub_topic_status.map(
                                (subResponse, subtitleIndex) => (
                                  <Typography key={subtitleIndex}>
                                    <div className="lesson-planner-list--subtitle">
                                      <div className="lesson-planner-list--subtitle--flex">
                                        <div>
                                          {selectedValue === "progress" ? (
                                            <img src={InProgress} alt="" />
                                          ) : selectedValue === "completed" ? (
                                            <img src={Completed} alt="/" />
                                          ) : (
                                            <img src={Timer} alt="" />
                                          )}
                                          {subtitleIndex + 1}.{" "}
                                          {subResponse.sub_topic_name}
                                        </div>
                                        <div className="lesson-planner-list--subtitle--duration">
                                          <span>Total Duration -</span>
                                          <b>
                                            {convertMinsToHours(
                                              subResponse.duration_in_mins
                                            )}
                                          </b>
                                          <img src={Clock} alt="" />
                                        </div>
                                      </div>
                                      <div className="lesson-planner-list--subtitle--images">
                                        <div>
                                          <div
                                            className="lesson-status__select-wrapper"
                                            style={{
                                              background: getBackgroundColor(
                                                subResponse.status
                                              ),
                                            }}
                                          >
                                            {getIcon(subResponse.status)}
                                            <select
                                              name="status"
                                              id="status"
                                              disabled={
                                                state.claims &&
                                                state.claims.STUDENT
                                                  ? true
                                                  : false
                                              }
                                              value={subResponse.status}
                                              onChange={(e) =>
                                                handleSelectChange(
                                                  e,
                                                  mainResponse.lesson_main_id,
                                                  subResponse.lesson_details_id
                                                )
                                              }
                                              className="lesson-status__select"
                                            >
                                              {statusOptions.map(
                                                ({ label, value }) => {
                                                  return (
                                                    <option value={value}>
                                                      {label}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Typography>
                                )
                              )
                            : null}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              )
            : null}
        </div>
      </div>
    </>
  );
};

export default List;
