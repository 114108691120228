import { useContext, useEffect, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import List from "../../CustomAllocation/Combinations/Index";
import {
  AcdSubjectAllocationData,
  Direction,
  InstitutionConfigurationTypes,
  PageFor,
  SortBy,
} from "../../../../utils/Enum.types";
import View from "../../../../images/EyeWhite.svg";
import { Drawer } from "@mui/material";
import { attendanceOverViewStyles } from "../../../../styles/DrawerStyles";
import SelectedStudents from "./ChooseStudent/SelectedStudents";
import InstDetailsAllocationLevel from "../../DailyActivities/InstDetailsAllocationLevel";
import { useLazyQuery } from "@apollo/client";
import { GetAcdSubjectComboStdAssociAtAllotedLevel } from "../../../../queries/customallocation/combination/queries";
import {
  ComboStdAssociData,
  GetAcdSubjectComboStdAssociAtAllotedLevelData,
  GetAcdSubjectComboStdAssociAtAllotedLevelVars,
} from "../../../../Types/Combinations/queries";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import { payloadTypes } from "../../../../context/reducer";
import { GetAcdStudentsForCount } from "../../queries/students/Index";
import {
  AcdStudentsvarsForCount,
  GetStudentAcdData,
} from "../../hooks/useAcdStudentsData";
import useInstConfigByEntryId from "../../hooks/useInstConfigByEntryId";
import { StudentAcdType } from "../../../../utils/studentAcdEnum.types";
const Combinations = () => {
  const navigate = useNavigate();
  const { InstId, allocationId } = useParams();
  const { state, dispatch } = useContext(AppContext);
  const { token } = useToken();

  const [combinationModal, setCombinationModal] = useState(false);
  const [studentList, setStudentList] = useState(false);

  const [searchCombination, setSearchCombination] = useState("");

  const [comboDetails, setComboDetails] = useState<ComboStdAssociData | null>(
    null
  );
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );

  const subjectAllocLevel = configData.data
    ? configData.data.GetSwConfigVariables[0].config_string_value
    : EMPTY_STRING;

  const {
    InstConfigDetails: { data: instDetailsData },
  } = useInstConfigByEntryId(allocationId!);
  const drawerClasses = attendanceOverViewStyles();
  const getAllotedId = () => {
    if (instDetailsData && configData) {
      switch (subjectAllocLevel) {
        case AcdSubjectAllocationData.BRANCH:
          return {
            id: instDetailsData.GetAcdInstConfigNames.acd_branch_id,

            alloted_level_query: StudentAcdType.BY_ACD_BRANCH_ID,
          };
        case AcdSubjectAllocationData.CLASS:
          return {
            id: instDetailsData.GetAcdInstConfigNames.acd_class_id,

            alloted_level_query: StudentAcdType.BY_ACD_CLASS_ID,
          };
        case AcdSubjectAllocationData.SEMESTER:
          return {
            id: instDetailsData.GetAcdInstConfigNames.acd_semester_id,

            alloted_level_query: StudentAcdType.BY_ACD_SEMESTER_ID,
          };
        case AcdSubjectAllocationData.SECTION:
          return {
            id: instDetailsData.GetAcdInstConfigNames.acd_section_id,

            alloted_level_query: StudentAcdType.BY_ACD_SECTION_ID,
          };
        default:
          return {
            id: 0,

            alloted_level_query: EMPTY_STRING,
          };
      }
    } else
      return {
        id: 0,

        alloted_level_query: EMPTY_STRING,
      };
  };

  const [GetCombos, { data }] = useLazyQuery<
    GetAcdSubjectComboStdAssociAtAllotedLevelData,
    GetAcdSubjectComboStdAssociAtAllotedLevelVars
  >(GetAcdSubjectComboStdAssociAtAllotedLevel, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      alloted_level: subjectAllocLevel,
      allotted_id: allocationId!,
      inst_id: InstId!,
      token,
    },
  });
  const { alloted_level_query, id } = getAllotedId();
  const [GetAcdStds, { data: studentCount }] = useLazyQuery<
    GetStudentAcdData,
    AcdStudentsvarsForCount
  >(GetAcdStudentsForCount, {
    variables: {
      token,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      after: null,
      first: ROWS_PER_PAGE,
      input: {
        acd_std_query_type: alloted_level_query,
        ids: [id],
        str_data: [],
      },
      orderBy: [
        {
          direction: Direction.ASC,
          field: SortBy.STD_ROLL_NO,
        },
      ],
    },
  });

  useEffect(() => {
    if (token) {
      GetCombos();
    }
  }, [
    token,
    GetCombos,
    InstId,
    allocationId,
    subjectAllocLevel,
    state.ActiveFinYr,
  ]);

  useEffect(() => {
    if (token && id && alloted_level_query) {
      GetAcdStds();
    }
  }, [GetAcdStds, token, alloted_level_query, id]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Subject allocation Details</Title>
      <div className="sub-allocation-individual">
        <InstDetailsAllocationLevel />
        <b className="sub-allocation-individual--title">Combinations</b>
        <div className="sub-allocation-individual--filters row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search"
              onChange={(e) => setSearchCombination(e.target.value)}
            />
          </div>
          <div className="col"></div>
          <div className="col-2 sub-allocation-individual--filters--total">
            Total Students:
            <b className="sub-allocation-individual--filters--total--number">
              {studentCount ? studentCount.GetAcdStudents.totalCount : 0}
            </b>
          </div>
          <div className="col"></div>
          <div className="col-2 flex-end">
            <Button
              mode="select"
              onClick={() => setCombinationModal(!combinationModal)}
            >
              &nbsp;Combinations
            </Button>
          </div>
        </div>
        <div className="sub-allocation-individual--datablock">
          <div className="sub-allocation-individual--datablock--grids">
            {data
              ? data.GetAcdSubjectComboStdAssociAtAllotedLevel.filter(
                  (level) =>
                    searchCombination
                      .toLowerCase()
                      .includes(level.subj_combo_name.toLowerCase()) ||
                    searchCombination === ""
                ).map((combo) => {
                  return (
                    <div className="sub-allocation-individual--datablock--grid">
                      <b className="sub-allocation-individual--datablock--grid--title">
                        {combo.subj_combo_name}
                      </b>
                      <div className="sub-allocation-individual--datablock--grid--span">
                        <div>
                          <span>Total subjects</span>
                          <b>{combo.subject_count}</b>
                        </div>
                        <div>
                          <span>Assigned Students</span>
                          <b className="font-green">{combo.student_count}</b>
                        </div>
                      </div>
                      <div className="sub-allocation-individual--datablock--grid--button">
                        <button
                          className="sub-allocation-individual--datablock--grid--view"
                          onClick={() => {
                            dispatch({
                              type: payloadTypes.SET_ACD_COMBO_ID,
                              payload: {
                                acdCombinationId: combo.acd_subj_combo_id,
                              },
                            });
                            setComboDetails(combo);
                            setStudentList(!studentList);
                          }}
                        >
                          View Details <img src={View} alt="" />
                        </button>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        isOpen={combinationModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <List setModal={setCombinationModal} pageType={PageFor.MODAL} />
      </Modal>
      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={studentList}
        onClose={() => setStudentList(!studentList)}
      >
        <SelectedStudents
          setDrawer={setStudentList}
          selectedStudentIds={new Set()}
          comboDetails={comboDetails}
        />
      </Drawer>
    </>
  );
};

export default Combinations;
