import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";

import { useLazyQuery, useMutation } from "@apollo/client";

import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";

import Edit from "../../../../images/EditProfile.svg";
import DataFetch from "../../../../images/Edit.svg";
import Delete from "../../../../images/Delete.svg";
import Avatar from "../../../../images/Avatar.svg";
import Close from "../../../../images/Close.svg";

import Home from "../../Home/Index";
import MessageModal from "../../../../pages/MessageModal";

import { TableHeaderProps } from "../../../../Types/Tables";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import { Operation, PageFor } from "../../../../utils/Enum.types";

import useLeaveLedgersByInstId from "../../hooks/useLeaveLedgersByInstId";
import useToken from "../../../../customhooks/useToken";

import { msgType, responseType } from "../../../../utils/Form.types";
import { Keys } from "../../../../utils/Enum.keys";

import {
  AddPayRollEmpDefaultLeaveLdgr,
  DeletePayRollEmpDefaultLeaveLdgr,
  UpdatePayRollEmpDefaultLeaveLdgr,
} from "../../queries/leaveledger/mutation";

import { INPUT, ROWS_PER_PAGE } from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import LoadingModal from "../../../../pages/LoadingModal";
import DeleteModal from "../../../../pages/DeleteModal";
import { Actions, HYPHEN } from "../../constants";
import useLeaveLedgerDetails from "../../hooks/useLeaveLedgerDetails";
import { GetPayRollEmpDefaultLeaveLdgr } from "../../queries/leaveledger/list";
import { PayRollLeaveLedgerNode } from "../../Types/masterDataTypes";
import LeaveLedgerList from "../LeaveLedger/Index";
import { HRLeaveLedgerTitleProps } from "../../../../Types/Titles";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { HR_Table } = require("../../json/table.json");
const { HRTitles } = require("../../json/title.json");

interface GetPayRollEmpDefaultLeaveLdgrList {
  id: number;
  leaves_availed: number;
  pr_leave_ldgr_id: number;
  leave_ldgr_details: PayRollLeaveLedgerNode;
}
interface GetPayRollEmpDefaultLeaveLdgrData {
  GetPayRollEmpDefaultLeaveLdgr: GetPayRollEmpDefaultLeaveLdgrList[];
}
interface GetPayRollEmpDefaultLeaveLdgrVars {
  token: string;
  inst_id: string;
}

const AssignLeaveLedger = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { state, dispatch } = useContext(AppContext);
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { token } = useToken();
  const navigate = useNavigate();
  const [empModal, setEmpModal] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);
  const [deleteModal, setDeleteModal] = useState(false);
  // eslint-disable-next-line
  const [searchLeave, setSearchLeave] = useState("");

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [leaves_availed, set_leaves_availed] = useState(0);
  const [leaveLedgerId, setLeaveLedgerId] = useState(0);
  const [defaultLeaveLedgerId, setDefaultLeaveLedgerId] = useState(0);
  //useRefs
  const leaveLedgerRef = useRef<HTMLSelectElement>(null);
  const leaveLedgerInputRef = leaveLedgerRef.current?.getElementsByTagName(
    INPUT
  )[0] as HTMLInputElement;
  const availedLeavesRef = useRef<HTMLInputElement>(null);
  const availedLeavesInputRef = availedLeavesRef.current?.getElementsByTagName(
    INPUT
  )[0] as HTMLInputElement;
  const saveRef = useRef<HTMLButtonElement>(null);

  const {
    LeaveData: { leaveFormData },
  } = useLeaveLedgerDetails();
  const {
    leaveLedgers: { responseType: leaveLedgerOptions },
  } = useLeaveLedgersByInstId(searchLeave, ROWS_PER_PAGE);
  const { USE_PAYROLL } = useUserRightsByEmpId();

  const [GetLedgers, { data: defaultLedgers }] = useLazyQuery<
    GetPayRollEmpDefaultLeaveLdgrData,
    GetPayRollEmpDefaultLeaveLdgrVars
  >(GetPayRollEmpDefaultLeaveLdgr, {
    variables: {
      token,
      inst_id: InstId!,
    },
    fetchPolicy: "network-only",
  });

  const defaultLeaveLedgerIds = useMemo(
    () =>
      new Set(
        defaultLedgers?.GetPayRollEmpDefaultLeaveLdgr.map(
          ({ leave_ldgr_details }) => leave_ldgr_details.id
        )
      ),
    [defaultLedgers]
  );

  const [AddDefaultLeaveLedger, { loading: additionLoading }] = useMutation(
    AddPayRollEmpDefaultLeaveLdgr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [UpdateDefaultLeaveLedger, { loading: updationLoading }] = useMutation(
    UpdatePayRollEmpDefaultLeaveLdgr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteDefaultLeaveLedger, { loading: deletionLoading }] = useMutation(
    DeletePayRollEmpDefaultLeaveLdgr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setOperation(Operation.CREATE);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const handleEditItem = (item: GetPayRollEmpDefaultLeaveLdgrList) => {
    setDefaultLeaveLedgerId(item.id);
    dispatch({
      type: payloadTypes.SET_LEAVE_LEDGER_ID,
      payload: {
        leaveLedgerId: item.leave_ldgr_details.id,
      },
    });
    set_leaves_availed(item.leaves_availed);
    setOperation(Operation.UPDATE);
    availedLeavesInputRef.focus();
  };

  const handleClear = () => {
    set_leaves_availed(0);
    dispatch({
      type: payloadTypes.SET_LEAVE_LEDGER_ID,
      payload: { leaveLedgerId: 0 },
    });
    leaveLedgerInputRef.focus();
  };
  const handleSubmit = () => {
    if (operation === Operation.CREATE) {
      if (!defaultLeaveLedgerIds.has(state.leaveLedgerId)) {
        AddDefaultLeaveLedger({
          variables: {
            token,
            inst_id: InstId,
            user_details,

            input: {
              leaves_availed: leaves_availed,
              pr_leave_ldgr_id: state.leaveLedgerId,
            },
          },
          refetchQueries: [
            {
              query: GetPayRollEmpDefaultLeaveLdgr,
              variables: {
                token,
                inst_id: InstId!,
              },
              fetchPolicy: "network-only",
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              message: "Leave Ledger Assigned SuccessFully",
              flag: true,
              operation: Operation.CREATE,
            });
          }
        });
      } else {
        handleClear();
        setMessage({
          message: "Selected leave ledger is already present in list.",
          flag: true,
          operation: Operation.NONE,
        });
      }
    } else {
      UpdateDefaultLeaveLedger({
        variables: {
          token,
          id: defaultLeaveLedgerId,

          inst_id: InstId,
          user_details,
          input: {
            leaves_availed: leaves_availed,
            pr_leave_ldgr_id: state.leaveLedgerId,
          },
        },
        refetchQueries: [
          {
            query: GetPayRollEmpDefaultLeaveLdgr,
            variables: {
              token,
              inst_id: InstId!,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Leave Ledger Updated SuccessFully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const handleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteDefaultLeaveLedger({
      variables: {
        token,
        id: leaveLedgerId,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetPayRollEmpDefaultLeaveLdgr,
          variables: {
            token,
            inst_id: InstId!,
          },
          fetchPolicy: "network-only",
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Leave Ledger Deleted SuccessFully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
  };
  useEffect(() => {
    if (token && InstId) {
      GetLedgers();
    }
  }, [token, InstId, GetLedgers]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {HRTitles.MasterData.LeaveLedger.Titles.map(
          (title: HRLeaveLedgerTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Default}</React.Fragment>;
          }
        )}
      </Title>
      <div className="emp-assign-leave-ledger">
        <div className="row g-0 emp-assign-leave-ledger__options">
          <div className="col">
            <div className="emp-assign-leave-ledger__options--imageflex">
              <Autocomplete
                classes={classes}
                options={leaveLedgerOptions.filter(
                  ({ value }) =>
                    !defaultLeaveLedgerIds.has(value) ||
                    operation === Operation.UPDATE
                )}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  option.value === state.leaveLedgerId
                }
                ref={leaveLedgerRef!}
                disabled={operation === Operation.UPDATE}
                value={
                  leaveLedgerOptions.find(
                    ({ value }) => value === state.leaveLedgerId
                  ) ?? null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_LEAVE_LEDGER_ID,
                      payload: {
                        leaveLedgerId: (newValue as responseType).value,
                      },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_LEAVE_LEDGER_ID,
                      payload: { leaveLedgerId: 0 },
                    });
                    setSearchLeave("");
                    handleClear();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_LEAVE_LEDGER_ID,
                      payload: { leaveLedgerId: 0 },
                    });
                  }
                  if (e.key === Keys.ENTER) {
                    availedLeavesInputRef.focus();
                  }
                }}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="outlined-uncontrolled"
                    label="Leave Ledger"
                    autoFocus
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchLeave(e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
              <img
                className="data-fetch-icon"
                src={DataFetch}
                alt="/"
                onClick={() => setEmpModal(!empModal)}
              />
            </div>
            <TextField
              label="Leave Ledger Desc"
              InputLabelProps={{ shrink: true }}
              disabled
              value={leaveFormData.leaveLedgerDesc}
              className="emp-assign-leave-ledger__textfield"
            />
          </div>
          <div className="col">
            <TextField
              label="Ledger Short Code"
              InputLabelProps={{ shrink: true }}
              disabled
              value={leaveFormData.leaveShortCode}
              className="emp-assign-leave-ledger__textfield"
            />
            <TextField
              label="Leave Type"
              InputLabelProps={{ shrink: true }}
              disabled
              value={leaveFormData.leaveType}
              className="emp-assign-leave-ledger__textfield"
            />
          </div>
          <div className="col">
            <TextField
              label="Assign Leaves"
              InputLabelProps={{ shrink: true }}
              type="number"
              value={leaves_availed}
              onFocus={(e) => {
                e.target.select();
              }}
              ref={availedLeavesRef}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                set_leaves_availed(Number(e.target.value));
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  if (leaves_availed) {
                    saveRef.current?.focus();
                  } else {
                    setMessage({
                      message: "Please enter the availed leaves",
                      flag: true,
                      operation: Operation.NONE,
                    });
                  }
                }
              }}
              className="emp-assign-leave-ledger__textfield--editable"
            />
          </div>

          <div className="col-1 h-100 emp-assign-leave-ledger__profile">
            <img src={Avatar} alt="/" />
          </div>
        </div>
        <div className="emp-assign-leave-ledger__tableblock">
          {!defaultLedgers?.GetPayRollEmpDefaultLeaveLdgr.length ? (
            <b className="nodata">Default Leave Ledgers empty</b>
          ) : (
            <TableContainer className="emp-assign-leave-ledger__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {HR_Table.AssignLeaveLedger.Table_Headers.filter(
                      (th: TableHeaderProps) =>
                        (th.labelName !== Actions &&
                          (operation === Operation.DELETE ||
                            operation === Operation.VIEW)) ||
                        operation === Operation.CREATE ||
                        operation === Operation.UPDATE
                    ).map((th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {defaultLedgers &&
                  defaultLedgers.GetPayRollEmpDefaultLeaveLdgr.length > 0
                    ? defaultLedgers.GetPayRollEmpDefaultLeaveLdgr.map(
                        (leave, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                className="emp-assign-leave-ledger__table--slno"
                                id="td-center"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell>
                                {leave.leave_ldgr_details.leave_desc}
                              </TableCell>
                              <TableCell
                                className="emp-assign-leave-ledger__table--number"
                                id="td-center"
                              >
                                {leave.leave_ldgr_details.is_lwp_ldgr
                                  ? HYPHEN
                                  : leave.leaves_availed}
                              </TableCell>

                              <TableCell
                                className="emp-assign-leave-ledger__table--actions"
                                id="td-center"
                              >
                                {!leave.leave_ldgr_details.is_lwp_ldgr ? (
                                  <>
                                    {USE_PAYROLL.details?.edit ? (
                                      <img
                                        src={Edit}
                                        alt="/"
                                        onClick={() => {
                                          handleEditItem(leave);
                                        }}
                                      />
                                    ) : null}
                                    {USE_PAYROLL.details?.delete ? (
                                      <img
                                        src={Delete}
                                        alt="/"
                                        onClick={() => {
                                          setLeaveLedgerId(leave.id);
                                          setDeleteModal(!deleteModal);
                                        }}
                                      />
                                    ) : null}
                                  </>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        {operation !== Operation.VIEW && (
          <Button
            mode="save"
            buttonref={saveRef!}
            onClick={handleSubmit}
            disabled={state.leaveLedgerId === 0}
          />
        )}
        {operation === Operation.CREATE ? (
          <>
            <Button mode="clear" onClick={() => handleClear()} />
          </>
        ) : null}

        <Button
          mode="back"
          onClick={() => {
            if (operation === Operation.CREATE) {
              navigate(-1);
            } else {
              setOperation(Operation.CREATE);
            }
            handleClear();
          }}
        />
      </div>

      {/* Employeemodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={empModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <LeaveLedgerList
              pageType={PageFor.MODAL}
              setModalFlag={setEmpModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEmpModal(!empModal)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        value={message.message}
        operation={message.operation}
        modalFlag={message.flag}
        handleClose={handleClose}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDelete}
        id={state.employeeId}
      />
      <LoadingModal
        flag={additionLoading || updationLoading || deletionLoading}
      />
    </>
  );
};

export default AssignLeaveLedger;
