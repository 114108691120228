import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Input from "../../../../stories/Input/Input";
import { Autocomplete, TextField } from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import { TableHeaderProps } from "../../../../Demo/Types";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import Eye from "../../../../images/EyeWhite.svg";
import Modal from "react-modal";

import Close from "../../../../images/Close.svg";
import {
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DataGridTableStyles";
import useStudentEnquiries from "../../hooks/useStudentEnquires";
import {
  PredefinedDataTypes,
  SortBy,
  StdEnqQueryType,
} from "../../../../utils/Enum.types";
import { DEFAULT_TIME, EMPTY_STRING } from "../../../../utils/constants";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { AppContext } from "../../../../context/context";
import {
  isOptionEqualToValue,
  sixMonthsAgo,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import StudentDetails from "../../FollowUp/StudentDetails";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import { optionsType } from "../../../../utils/Form.types";
import { Keys } from "../../../../utils/Enum.keys";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import { payloadTypes } from "../../../../context/reducer";

const Table = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const tableClasses = useDataGridStyles();
  const { Masters_Table } = useMasterTableJson();
  const { serverDate } = useServerDateandTime();
  const { dispatch, state } = useContext(AppContext);
  const [follow_up_status, set_follow_up_status] = useState<optionsType | null>(
    null
  );
  const [studentDetailsModal, setStudentDetailsModal] = useState(false);

  const [searchEnqStudent, setSearchEnqStudent] = useState("");

  const [startDate, setStartDate] = useState(serverDate);
  const [endDate, setEndDate] = useState(serverDate);
  const {
    EnquiryData: { StudentEnquiryData },
  } = useStudentEnquiries(
    null,
    StdEnqQueryType.STD_ENQ_BY_EMP_ID,
    searchEnqStudent,
    follow_up_status ? follow_up_status.value : EMPTY_STRING,
    null,
    null,
    SortBy.ENQ_DATE,
    endDate,
    startDate,
    0,
    0,
    state.empLoginId ? state.empLoginId : 0
  );
  const dynamicHeaders: TableHeaderProps[] =
    Masters_Table.StudentsEnquiry.TeacherDashboard?.map((header) => ({
      headerName: header.headerName,
      className: header.className,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));
  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      cellClassName: "td-actions",
      renderCell: (params) => {
        return (
          <>
            <button
              className="enquiry-teacher__table--button"
              onClick={() => {
                dispatch({
                  type: payloadTypes.SET_STD_ENQUIRY_ID,
                  payload: { studentEnquiryId: params.row.stdId },
                });
                setStudentDetailsModal(!studentDetailsModal);
              }}
            >
              View <img src={Eye} alt="" />
            </button>
          </>
        );
      },
    },
  ];
  const {
    PredefinedData: { dropDown: ENQ_STATUS_OPTIONS },
  } = usePredefinedDataByType(
    PredefinedDataTypes.ENQ_FOLLOW_UP_STATUS,
    EMPTY_STRING
  );
  const findEnquiry = (data: string) => {
    return (
      ENQ_STATUS_OPTIONS.find(({ value }) => value === data)?.label ??
      EMPTY_STRING
    );
  };
  const rows: GridValidRowModel[] = StudentEnquiryData.data
    ? StudentEnquiryData.data.GetStudentEnquires.edges.map((res, index) => ({
        id: index + 1,
        stdId: res.node.id,
        date: toStandardDate(res.node.enq_date),
        name:
          res.node.first_name +
          " " +
          res.node.middle_name +
          " " +
          res.node.last_name,
        previous_studied: res.node.std_previous_school,
        email: res.node.std_email,
        mobile: res.node.std_mobile,
        last_updated: findEnquiry(res.node.last_follow_up_status),
        follow_up_date:
          toStandardDate(res.node.last_follow_up_made_date) ===
          toStandardDate(DEFAULT_TIME)
            ? "-"
            : toStandardDate(res.node.last_follow_up_made_date),
        next_follow_up_date:
          toStandardDate(res.node.next_follow_up_date) ===
          toStandardDate(DEFAULT_TIME)
            ? "-"
            : toStandardDate(res.node.next_follow_up_date),
        follow_up_count: res.node.follow_up_count,
      }))
    : [];
  useEffect(() => {
    if (serverDate) {
      setEndDate(serverDate);
      setStartDate(sixMonthsAgo(serverDate));
    }
  }, [serverDate]);
  return (
    <>
      <div className="enquiry-teacher__tableblock--title">
        <Title variant="subtitle1">Follow Up Students List</Title>
      </div>
      <div className="enquiry-teacher__tableblock--filters row g-0">
        <div className="col-2">
          <Input
            id="search"
            placeholder="Serach..."
            value={searchEnqStudent}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchEnqStudent(e.target.value);
            }}
          />
        </div>
        <div className="col-2">
          <Autocomplete
            classes={classes}
            options={ENQ_STATUS_OPTIONS}
            value={follow_up_status}
            isOptionEqualToValue={(option) =>
              isOptionEqualToValue(option, follow_up_status)
            }
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === Keys.ENTER) {
                e.preventDefault();
              }
              if (e.key === Keys.BACKSPACE) {
                set_follow_up_status(null);
              }
            }}
            onChange={(e, newValue) => {
              if (newValue) {
                set_follow_up_status(newValue);
              } else {
                set_follow_up_status(null);
              }
            }}
            openOnFocus
            forcePopupIcon
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                classes={{ root: textClasses.formControlRoot }}
                label="Enquiry Status"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </div>
        <div className="col"></div>
        <div className="col-2">
          <div className="student-total-count">
            Total Follow up's : <b>0</b>
          </div>
        </div>
      </div>
      <div className={`enquiry-teacher__table ${tableClasses.root}`}>
        <DataGridPro
          columns={columns}
          rows={rows}
          disableRowSelectionOnClick
          disableChildrenSorting
          rowHeight={TABLE_ROW_HEIGHT}
          slotProps={{
            columnsPanel: { disableHideAllButton: true },
          }}
        />
      </div>
      {/* student-enquiry-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentDetailsModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentDetails setStudentDetailsModal={setStudentDetailsModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentDetailsModal(!studentDetailsModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Table;
