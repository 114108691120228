import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Close from "../../../images/Close.svg";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import Edit from "../../../images/EditProfile.svg";
import Modal from "react-modal";
import { ViewPickedSubjectModalStyles } from "../../../styles/ModalStyles";
import Update from "./Update";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetPayRollEmpDailyDiaryData,
  GetPayRollEmpDailyDiaryVars,
} from "./Index";
import {
  GetPayRollEmpDailyDiary,
  GetPayRollEmpDailyDiaryTodayStatus,
} from "./queries";
import {
  DateRange,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { TODAY_DATE } from "../../../utils/constants";
import { Operation, PrEmpDailyDiaryQueryType } from "../../../utils/Enum.types";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";
import usePayRollActiveAcademicYear from "../../HR/hooks/usePayRollActiveAcademicYear";
import { SubmitEmpDailyDiary } from "./queries/mutations";
import { msgType } from "../../../utils/Form.types";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";

export const enum PageFor {
  SUBMIT = "SUBMIT",
  UPDATE = "UPDATE",
}

interface props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setFillDiary: React.Dispatch<React.SetStateAction<boolean>>;

  pageType: PageFor;
}
const Review = ({ setModal, pageType, setFillDiary }: props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const { firstDay } = DateRange(TODAY_DATE) || {};

  const { payRollActiveAcademicYear } = usePayRollActiveAcademicYear();
  const pr_acd_yr_id =
    payRollActiveAcademicYear.data && !payRollActiveAcademicYear.loading
      ? payRollActiveAcademicYear.data.GetPayRollAcdYrActiveByInstId.id
      : 0;
  const [update, setUpdate] = useState(false);
  const [dairyId, setDairyId] = useState(0);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [GetDairyData, { data: DairyData, loading: DairyLoading }] =
    useLazyQuery<GetPayRollEmpDailyDiaryData, GetPayRollEmpDailyDiaryVars>(
      GetPayRollEmpDailyDiary
    );

  const [SubmitReviewData, { loading }] = useMutation(SubmitEmpDailyDiary, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const HandleAddDairyData = () => {
    SubmitReviewData({
      variables: {
        token,
        inst_id: InstId!,
        pr_acd_yr_id,
        user_details,
        input: {
          pr_emp_id: state.empLoginId,
          date_of_diary: toIsoDate(TODAY_DATE),
          status: true,
        },
      },
      refetchQueries: [
        {
          query: GetPayRollEmpDailyDiary,
          variables: {
            token,
            inst_id: Number(InstId)!,
            pr_acd_yr_id,
            pr_emp_id: state.empLoginId,
            input: {
              query_type: PrEmpDailyDiaryQueryType.DIARY_NOTES,
              date_of_diary: toIsoDate(TODAY_DATE),
              question_idx: 0,
            },
          },
        },
        {
          query: GetPayRollEmpDailyDiaryTodayStatus,
          variables: {
            token,
            date_of_diary: toIsoDate(TODAY_DATE),
            input: {
              cal_month: toIsoDate(firstDay!),
              inst_id: InstId!,
              pr_acd_yr_id,
              pr_emp_id: state.empLoginId,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Dairy Details Submitted Successfully",
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const [GetDairyStatus, { data: DairyStatusData }] = useLazyQuery(
    GetPayRollEmpDailyDiaryTodayStatus
  );
  const dairyStatus =
    DairyStatusData && DairyStatusData.GetPayRollEmpDailyDiaryTodayStatus;
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setModal(false);
      setFillDiary(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (token && pr_acd_yr_id) {
      GetDairyStatus({
        variables: {
          token,
          date_of_diary: toIsoDate(TODAY_DATE),
          input: {
            cal_month: toIsoDate(firstDay!),
            inst_id: InstId!,
            pr_acd_yr_id,
            pr_emp_id: state.empLoginId,
          },
        },
      });
      GetDairyData({
        variables: {
          token,
          inst_id: Number(InstId)!,
          pr_acd_yr_id,
          pr_emp_id: state.empLoginId,
          input: {
            query_type: PrEmpDailyDiaryQueryType.DIARY_NOTES,
            date_of_diary: toIsoDate(TODAY_DATE),
            question_idx: 0,
          },
        },
      });
    } // eslint-disable-next-line
  }, [token, GetDairyStatus, GetDairyData, pr_acd_yr_id]);
  return (
    <>
      <div className="daily-diary-review">
        <div className="daily-diary-review__title">
          <Title> Daily Diary</Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
        <div className="daily-diary-review__select row g-0">
          <div className="col-2">
            <TextField
              disabled
              value={toStandardDate(TODAY_DATE)}
              className="daily-diary-review__select--textfield"
              label="Date"
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        <div className="daily-diary-review__datablock">
          <Title>
            {pageType === PageFor.SUBMIT ? "Review Answers" : "Update Answers"}
          </Title>
          <ul className="daily-diary-review__datablock--ul">
            {DairyData &&
              DairyData.GetPayRollEmpDailyDiary.map((res, index) => {
                return (
                  <li key={index}>
                    <div className="daily-diary-review__datablock--ul--text">
                      <b>{res.question}</b>
                      <span>{res.answer}</span>
                    </div>
                    <div className="daily-diary-review__datablock--ul--image">
                      <img
                        src={Edit}
                        alt=""
                        onClick={() => {
                          setDairyId(res.id);
                          setUpdate(!update);
                        }}
                      />
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="daily-diary__btns">
          <div>
            {!dairyStatus && (
              <Button mode="submit" onClick={HandleAddDairyData} />
            )}
            <Button mode="cancel" onClick={() => setModal(false)} />
          </div>
          {pageType === PageFor.SUBMIT && (
            <div>{/* <Button mode="previous" /> */}</div>
          )}
        </div>
      </div>
      <Modal
        isOpen={update}
        style={ViewPickedSubjectModalStyles}
        ariaHideApp={false}
      >
        <Update setModal={setUpdate} dairyId={dairyId} />
      </Modal>
      <LoadingModal flag={loading || DairyLoading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Review;
