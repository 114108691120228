import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import { ChooseLedgerModalStyles } from "../../../../styles/ModalStyles";
import { TableHeaderProps } from "../../../../Types/Tables";
import { DEFAULT_NUMBER_VALUE } from "../../../../utils/constants";
import { Keys } from "../../../../utils/Enum.keys";
import { optionsType } from "../../../../utils/Form.types";

//Images
import DownArrow from "../../../../images/DownArrow.svg";
import Close from "../../../../images/Close.svg";
import { Button } from "../../../../stories/Button/Button";
import {
  calculationMethodModal,
  IncomeCalculatorType,
} from "../../Types/dataTypes";
import { applyOnHeadsOptions } from "../../utils/Functions";

const { HR_Table } = require("../../json/table.json");

interface Props {
  calculationMethodModal: calculationMethodModal;
  incomeCalculatorItems: IncomeCalculatorType[];
  setIncomeCalculatorItems: React.Dispatch<
    React.SetStateAction<IncomeCalculatorType[]>
  >;
  closeMethod: () => void;
  saveMethod: () => void;
  ledgerDesc: string;
}

enum calculationCellEditType {
  UPTO = "UPTO",
  CALCTYPE = "CT",
  BASIS = "BASIS",
  NONE = "NONE",
}
enum calculationType {
  Percentage = "P",
  Value = "V",
}

const percentageValue: optionsType[] = [
  { label: "Percentage", value: calculationType.Percentage },
  { label: "Value", value: calculationType.Value },
];

const CalculationModal = ({
  calculationMethodModal,
  incomeCalculatorItems,
  setIncomeCalculatorItems,
  closeMethod,
  saveMethod,
  ledgerDesc,
}: Props) => {
  const tableClasses = formAutoCompleteStyles();
  const tableTextClasses = formAutoCompleteTextStyles();

  const [calculationMethodValidateError, setCalculationMethodValidateError] =
    useState("");
  const [calculationMethodIneditMode, setCalculationMethodIneditMode] =
    useState({
      status: false,
      rowKey: 0,
      cellKey: calculationCellEditType.NONE,
    });
  const [upToAmt, setUptoAmt] = useState(0);
  const [calculationTypeValue, setCalculationType] = useState(
    calculationType.Percentage
  );
  const [valueBasis, setValueBasis] = useState(0);
  const MAX = "MAX";
  //useRefs
  const upto_amtRef = useRef<HTMLTableCellElement[]>([]);
  const valuebasisRef = useRef<HTMLTableCellElement[]>([]);
  const valueTypeRefs = useRef<HTMLInputElement[]>([]);
  const calculationMethodSaveRef = useRef<HTMLButtonElement>(null);

  const addValueTypeRefs = (el: HTMLInputElement) => {
    if (
      el &&
      !valueTypeRefs.current.includes(el) &&
      calculationMethodModal?.flag
    ) {
      valueTypeRefs.current.push(el);
    }
  };

  const addValueBasisRefs = (el: HTMLTableCellElement) => {
    if (
      el &&
      !valuebasisRef.current.includes(el) &&
      calculationMethodModal?.flag
    ) {
      valuebasisRef.current.push(el);
    }
  };
  const addUptoRefs = (el: HTMLTableCellElement) => {
    if (
      el &&
      !upto_amtRef.current.includes(el) &&
      calculationMethodModal?.flag
    ) {
      upto_amtRef.current.push(el);
    }
  };
  const calculationEditMode = (
    id: number,
    upto_amt: number | string,
    calculation_type: calculationType,
    value_basis: number | string,
    type: calculationCellEditType
  ) => {
    setCalculationMethodIneditMode({
      rowKey: id,
      status: true,
      cellKey: type,
    });

    setValueBasis(Number(value_basis));
    setCalculationType(calculation_type);
    setUptoAmt(Number(upto_amt === DEFAULT_NUMBER_VALUE ? 0 : upto_amt));
  };
  const handleCalculationEdit = (index: number) => {
    incomeCalculatorItems[index].calculation_type = calculationTypeValue;
    incomeCalculatorItems[index].upto_amt =
      upToAmt === 0 ? DEFAULT_NUMBER_VALUE : upToAmt;
    incomeCalculatorItems[index].income_value_basis = valueBasis;
    if (upToAmt) {
      setIncomeCalculatorItems(incomeCalculatorItems);
    } else {
      setIncomeCalculatorItems(
        incomeCalculatorItems.filter((data, i) => i < index + 1)
      );
    }
  };
  const handleClear = () => {
    upto_amtRef.current = [];
    valueTypeRefs.current = [];
    valuebasisRef.current = [];
    setUptoAmt(0);
    setValueBasis(0);
    setIncomeCalculatorItems([
      {
        calculation_type: calculationType.Percentage,
        from_amt: 0,
        id: 1,
        income_value_basis: 0,
        upto_amt: 0,
      },
    ]);
  };
  useEffect(() => {
    if (calculationMethodModal?.flag) {
      setCalculationMethodIneditMode({
        rowKey: 1,
        status: true,
        cellKey: calculationCellEditType.UPTO,
      });
    }
  }, [calculationMethodModal]);

  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={calculationMethodModal?.flag!}
      style={ChooseLedgerModalStyles}
      ariaHideApp={false}
    >
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">
          <Title>Calculation Method</Title>
          <b className="nodata">{calculationMethodValidateError}</b>
          <Label>
            {calculationMethodModal?.applyOnHeads
              ? applyOnHeadsOptions?.find(
                  ({ value }) => value === calculationMethodModal?.applyOnHeads
                )?.label
              : "On Basic Salary"}
          </Label>
          <Title variant="subtitle1">{ledgerDesc}</Title>
          <div className="salary-structure__additional-payments">
            <TableContainer className="salary-structure__additional-payments--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {HR_Table.SalaryStructure.AdditionalPayments.Table_Headers.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index}>{th.labelName}</TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {incomeCalculatorItems.map((item, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className="salary-structure__additional-payments--table--amount"
                          id="td-right"
                        >
                          {item.from_amt}
                        </TableCell>
                        <TableCell
                          className="salary-structure__additional-payments--table--input"
                          id="td-right"
                          ref={addUptoRefs}
                          onClick={() =>
                            calculationEditMode(
                              item.id,
                              item.upto_amt,
                              item.calculation_type,
                              item.income_value_basis,
                              calculationCellEditType.UPTO
                            )
                          }
                        >
                          {calculationMethodIneditMode.rowKey === item.id &&
                          calculationMethodIneditMode.status &&
                          calculationMethodIneditMode.cellKey ===
                            calculationCellEditType.UPTO ? (
                            <input
                              autoFocus
                              value={upToAmt}
                              type="number"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setUptoAmt(Number(e.target.value));
                                incomeCalculatorItems[index].upto_amt = Number(
                                  e.target.value
                                );
                                if (incomeCalculatorItems[index + 1]) {
                                  incomeCalculatorItems[index + 1].from_amt =
                                    Number(e.target.value) + 1;
                                }
                              }}
                              onFocus={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => e.target?.select()}
                              onKeyDown={(e: React.KeyboardEvent) => {
                                if (e.key === Keys.ENTER) {
                                  if (
                                    upToAmt < item.from_amt &&
                                    upToAmt !== 0
                                  ) {
                                    setCalculationMethodValidateError(
                                      "Given amount is less than from amount"
                                    );
                                  } else {
                                    setCalculationMethodValidateError("");
                                    handleCalculationEdit(index);
                                    calculationEditMode(
                                      item.id,
                                      item.upto_amt,
                                      item.calculation_type,
                                      item.income_value_basis,
                                      calculationCellEditType.CALCTYPE
                                    );
                                    (
                                      valueTypeRefs?.current[
                                        index
                                      ]?.getElementsByClassName(
                                        "MuiInputBase-input"
                                      )[0] as HTMLInputElement
                                    )?.focus();
                                  }
                                }
                              }}
                            />
                          ) : item.upto_amt === DEFAULT_NUMBER_VALUE ? (
                            MAX
                          ) : (
                            item.upto_amt
                          )}
                        </TableCell>
                        <TableCell>
                          <Autocomplete
                            ref={addValueTypeRefs}
                            classes={tableClasses}
                            options={percentageValue}
                            value={percentageValue.find(
                              (d) => d.value === item.calculation_type
                            )}
                            onChange={(e, newValue) => {
                              if (newValue)
                                setCalculationType(
                                  (newValue as optionsType)
                                    .value as calculationType
                                );
                              incomeCalculatorItems[index].calculation_type = (
                                newValue as optionsType
                              ).value as calculationType;
                            }}
                            onKeyDown={(e: React.KeyboardEvent) => {
                              if (e.key === Keys.ENTER) {
                                calculationEditMode(
                                  item.id,
                                  item.upto_amt,
                                  item.calculation_type,
                                  item.income_value_basis,
                                  calculationCellEditType.BASIS
                                );
                                if (
                                  calculationMethodIneditMode.rowKey === item.id
                                )
                                  handleCalculationEdit(index);
                                (
                                  valuebasisRef?.current[index]
                                    ?.childNodes[0] as HTMLInputElement
                                )?.focus();
                              }
                            }}
                            // disabled={item.upto_amt === 0}
                            openOnFocus
                            freeSolo
                            autoHighlight
                            forcePopupIcon
                            popupIcon={<img src={DownArrow} alt="/" />}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                classes={{
                                  root: tableTextClasses.formControlRoot,
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell
                          className="salary-structure__additional-payments--table--input"
                          ref={addValueBasisRefs}
                          onClick={() =>
                            calculationEditMode(
                              item.id,
                              item.upto_amt,
                              item.calculation_type,
                              item.income_value_basis,
                              calculationCellEditType.BASIS
                            )
                          }
                        >
                          {calculationMethodIneditMode.rowKey === item.id &&
                          calculationMethodIneditMode.status ? (
                            <input
                              value={valueBasis}
                              type="number"
                              onChange={(e) => {
                                setValueBasis(Number(e.target.value));
                                incomeCalculatorItems[
                                  index
                                ].income_value_basis = Number(e.target.value);
                              }}
                              onFocus={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => {
                                e.target?.select();
                              }}
                              onKeyDown={(e: React.KeyboardEvent) => {
                                if (e.key === Keys.ENTER) {
                                  const lastItem: IncomeCalculatorType = {
                                    calculation_type:
                                      calculationType.Percentage,
                                    from_amt: upToAmt + 1,
                                    id: Math.random(),
                                    income_value_basis: 0,
                                    upto_amt: 0,
                                  };
                                  if (item.upto_amt <= 0) {
                                    incomeCalculatorItems[
                                      index
                                    ].income_value_basis = valueBasis;

                                    setIncomeCalculatorItems((items) => [
                                      ...items,
                                    ]);
                                    calculationMethodSaveRef?.current?.focus();
                                  } else if (
                                    index + 1 ===
                                    incomeCalculatorItems.length
                                  ) {
                                    incomeCalculatorItems[
                                      index
                                    ].income_value_basis = valueBasis;
                                    setIncomeCalculatorItems((items) => [
                                      ...items,
                                      lastItem,
                                    ]);
                                  } else {
                                    incomeCalculatorItems[
                                      index
                                    ].income_value_basis = valueBasis;

                                    setIncomeCalculatorItems((items) => [
                                      ...items,
                                    ]);
                                  }
                                  if (incomeCalculatorItems[index + 1]) {
                                    calculationEditMode(
                                      incomeCalculatorItems[index + 1].id,
                                      incomeCalculatorItems[index + 1].upto_amt,
                                      incomeCalculatorItems[index + 1]
                                        .calculation_type,
                                      incomeCalculatorItems[index + 1]
                                        .income_value_basis,
                                      calculationCellEditType.UPTO
                                    );
                                  }
                                }
                              }}
                            />
                          ) : (
                            item.income_value_basis
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button
            mode="save"
            buttonref={calculationMethodSaveRef!}
            onClick={() => {
              upto_amtRef.current = [];
              valueTypeRefs.current = [];
              valuebasisRef.current = [];
              const errorFromAndUpto = incomeCalculatorItems?.find(
                ({ from_amt, upto_amt }) =>
                  from_amt > upto_amt && upto_amt !== 0
              );
              if (
                errorFromAndUpto &&
                errorFromAndUpto?.upto_amt !== DEFAULT_NUMBER_VALUE
              )
                setCalculationMethodValidateError(
                  `${
                    Number(incomeCalculatorItems?.indexOf(errorFromAndUpto)) + 1
                  } has an invalid amount`
                );
              else {
                setCalculationMethodValidateError("");
                setUptoAmt(0);
                setCalculationType(calculationType.Percentage);
                saveMethod();
                handleClear();
              }
            }}
          />
          <Button
            mode="clear"
            onClick={() => {
              handleClear();
            }}
          />
          <Button
            mode="cancel"
            onClick={() => {
              handleClear();
              closeMethod();
            }}
          />
        </div>
        <div className="modal-flex__image">
          <img
            src={Close}
            alt="/"
            onClick={() => {
              handleClear();
              closeMethod();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CalculationModal;
