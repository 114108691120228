import React, { useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import CompleteDetails from "./CompleteDetails";
import usePurchaseEntry, {
  purchaseEntryQueryType,
} from "../hooks/usePurchaseEntry";
import {
  DateRange,
  formatter,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { ROWS_PER_PAGE } from "../../../../utils/constants";
import useAcctTableJson from "../../json/useAcctTableJson";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const View = ({ setModalFlag }: Props) => {
  const [completeDetailsModal, setCompleteDetailsModal] = useState(false);

  const { firstDay, lastDay } = DateRange(new Date().toString()) || {};
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();

  const {
    PurchaseEntries: { data },
  } = usePurchaseEntry(
    ROWS_PER_PAGE,
    lastDay!,
    firstDay!,
    purchaseEntryQueryType.ALL_PURCHASES
  );

  let totalTax = 0;
  let totalNet = 0;
  data?.GetAcctPurchases.edges.map((edge) => {
    totalTax += edge.node.pur_tax_amt;
    totalNet += edge.node.pur_amt;
  });
  return (
    <>
      <Title>View Purchase Entry</Title>
      <div className="purchase-entry-view">
        <div className="row g-0 purchase-entry-view__filters">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col"></div>
          <div className="col-4 purchase-entry-view__filters--flex">
            <TextField
              className="purchase-entry-view__filters--textfield"
              type="date"
              label="Start Date"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className="purchase-entry-view__filters--textfield"
              type="date"
              label="End Date"
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        <div className="purchase-entry-view__tableblock">
          <TableContainer className="purchase-entry-view__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.PurchaseEntry.View.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.GetAcctPurchases.edges.map((edge, index) => {
                  return (
                    <TableRow key={edge.node.id}>
                      <TableCell
                        className="purchase-entry-view__table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        className="purchase-entry-view__table--date"
                        id="td-center"
                      >
                        {toStandardDate(edge.node.pur_inv_date)}
                      </TableCell>
                      <TableCell
                        className="purchase-entry-view__table--number"
                        onClick={() =>
                          setCompleteDetailsModal(!completeDetailsModal)
                        }
                      >
                        {edge.node.pur_inv_no}
                      </TableCell>
                      <TableCell className="purchase-entry-view__table--date">
                        {edge.node.pur_order_no}
                      </TableCell>
                      <TableCell
                        className="purchase-entry-view__table--date"
                        id="td-center"
                      >
                        {toStandardDate(edge.node.pur_order_date)}
                      </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell
                        className="purchase-entry-view__table--items"
                        id="td-center"
                      >
                        {edge.node.pur_item_count}
                      </TableCell>

                      <TableCell
                        className="purchase-entry-view__table--items"
                        id="td-right"
                      >
                        {edge.node.pur_item_qty}
                      </TableCell>
                      <TableCell
                        className="purchase-entry-view__table--amount"
                        id="td-right"
                      >
                        {format(edge.node.pur_tax_amt)}
                      </TableCell>
                      <TableCell
                        className="purchase-entry-view__table--amount"
                        id="td-right"
                      >
                        {format(edge.node.pur_amt)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={8} className="total">
                    Total:
                  </TableCell>
                  <TableCell className="totalcount">
                    {format(totalTax)}
                  </TableCell>
                  <TableCell className="totalcount">
                    {format(totalNet)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={completeDetailsModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <CompleteDetails setModalFlag={setCompleteDetailsModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setCompleteDetailsModal(!completeDetailsModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default View;
