import React, { useContext, useMemo, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import TotalClasses from "../../../../images/TotalClasses.svg";
import SubjectsAllocated from "../../../../images/TotalAttendanceMarked.svg";
import SubjectsNotAllocated from "../../../../images/TotalAttendanceNotMarked.svg";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import useAcdDropDownData from "../../hooks/useAcdDropDownData";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { msgType, responseType } from "../../../../utils/Form.types";
import {
  InstitutionConfigurationTypes,
  Operation,
  TableHeaders,
} from "../../../../utils/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { TreeType } from "../../Attendance/ForAdmin";
import { EMPTY_STRING, TODAY_DATE } from "../../../../utils/constants";
import Allocate from "../../../../images/Assign.svg";
import View from "../../../../images/EyeWhite.svg";
import Edit from "../../../../images/EditButton.svg";
import Delete from "../../../../images/DeleteButton.svg";

import Modal from "react-modal";
import {
  EditModalCustomStyles,
  StudentModalStyles,
  WaiveOffModalStyles,
} from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import ViewSubjects from "./View";
import { Keys } from "../../../../utils/Enum.keys";
import useMstInstTreeByAllocatedLevel from "../../../../customhooks/general/useMstInstTreeByAllocatedLevel";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import useDisplayAutoCompleteTag from "../../hooks/useDisplayAutoCompleteTag";
import DeleteModal from "../../../../pages/DeleteModal";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { useMutation } from "@apollo/client";
import { DeleteAcdSubjectClassAllotedDetails } from "../../queries/subjects/mutation/Index";
import useToken from "../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import { GetAcdInstTreeForAllocatedLevel } from "../../../../queries/common";
import { InstTreeQueryType } from "../../../HR/Types/masterDataTypes";
import Common from "../../../../images/TodaytoFollowUp.svg";
import Students from "../../../../images/Boys.svg";
import TypesOfSubjects from "../../../../images/Types_of_Subject_Allocation.gif";
import Warning from "../../../../images/Warning.svg";
import { Label } from "../../../../stories/Label/Label";
import { GlobalPageConfigData } from "../../../../Types/configtypes";
interface AssignComponentProp {
  id: number;
  subjects: number;
  per_std_alloc_flag: boolean;
}
interface CustomModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  contentComponent: React.ReactNode;
}
const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onRequestClose,
  contentComponent,
}) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      style={StudentModalStyles}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
    >
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">{contentComponent}</div>
        <div className="modal-flex__image">
          <img src={Close} alt="Close" onClick={onRequestClose} />
        </div>
      </div>
    </Modal>
  );
};
const Allocation = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;
  const { dispatch, state } = useContext(AppContext);
  const [viewSubjects, setViewSubjects] = useState(false);

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [entryId, setEntryId] = useState(0);

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const { configData } = useSwConfigData([
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL,
    InstitutionConfigurationTypes.ENABLE_PER_STD_SUBJECT_ALLOCATION,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let enablePerStdSubAllocation = false;

    let allotedLevel = "";

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL:
            allotedLevel = item.config_string_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_PER_STD_SUBJECT_ALLOCATION:
            enablePerStdSubAllocation = item.config_boolean_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      enablePerStdSubAllocation,
      allotedLevel,
    };
  };
  const { allotedLevel, enablePerStdSubAllocation } = filterDataByConfigKey(
    configData.data ? configData.data.GetSwConfigVariables : []
  );
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  const [deleteId, setDeleteId] = useState(0);

  const { data } = useMstInstTreeByAllocatedLevel(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    toIsoDate(TODAY_DATE),
    0
  );
  const [DeleteSubjects, { loading }] = useMutation(
    DeleteAcdSubjectClassAllotedDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const { displayClass, displaySection, displaySemester } =
    useDisplayAutoCompleteTag();
  let idsSet = new Set();
  let last_level = useMemo(() => {
    let final = 0;
    if (USE_DEPARTMENT_KEY) {
      final++;
    }
    if (USE_BRANCH_KEY) {
      final++;
    }
    if (USE_CLASS_KEY && displayClass) {
      final++;
    }
    if (USE_SEMESTER_KEY && displaySemester) {
      final++;
    }
    if (USE_SECTION_KEY && displaySection) {
      final++;
    }
    return final + 1;
  }, [
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
    displayClass,
    displaySemester,
    displaySection,
  ]);

  const AssignComponent = ({
    id,
    subjects,
    per_std_alloc_flag,
  }: AssignComponentProp) => {
    return subjects === 0 ? (
      <button
        className="allocate-button"
        onClick={() => {
          if (enablePerStdSubAllocation) {
            setEntryId(id);
            setModal(!modal);
          } else {
            navigate(
              `/${InstId}/academics/subjectallocationdetails/${id}/allocateSubject`
            );
          }
        }}
      >
        Allocate <img src={Allocate} alt="" />
      </button>
    ) : (
      <>
        <button
          className="update-button"
          onClick={() => {
            if (per_std_alloc_flag) {
              navigate(
                `/${InstId}/academics/${id}/subjectallocationdetails/combinations`
              );
            } else {
              navigate(
                `/${InstId}/academics/subjectallocationdetails/${id}/updateAllocatedSubject`
              );
            }
          }}
        >
          Update <img src={Edit} alt="" />
        </button>
        {per_std_alloc_flag === false ? (
          <button
            className="delete-button"
            onClick={() => {
              setDeleteModal(!deleteModal);
              setDeleteId(id);
            }}
          >
            Delete <img src={Delete} alt="" />
          </button>
        ) : null}
      </>
    );
  };

  const modifiedArrayForTable: TreeType[] = data
    ? data.GetAcdInstTreeForAllocatedLevel.flatMap((level1) => {
        if (
          level1.acd_level_2_details &&
          level1.acd_level_2_details.length > 0
        ) {
          return level1.acd_level_2_details.flatMap((level2) => {
            if (
              level2.acd_level_3_details &&
              level2.acd_level_3_details.length > 0
            ) {
              return level2.acd_level_3_details.flatMap((level3) => {
                if (
                  level3.acd_level_4_details &&
                  level3.acd_level_4_details.length > 0
                ) {
                  return level3.acd_level_4_details.flatMap((level4) => {
                    if (
                      level4.acd_level_5_details &&
                      level4.acd_level_5_details.length > 0
                    ) {
                      return level4.acd_level_5_details.flatMap((level5) => {
                        let result = {
                          level1_name: idsSet.has(level1.acd_level_1_id)
                            ? EMPTY_STRING
                            : level1.acd_level_1_desc,
                          level1_id: level1.acd_level_1_id,
                          level1_rowspan: level1.sub_acd_level_total_count
                            ? level1.sub_acd_level_total_count
                            : 1,
                          level2_name: idsSet.has(level2.acd_level_2_id)
                            ? EMPTY_STRING
                            : level2.acd_level_2_desc,
                          level2_rowspan: level2.sub_acd_level_total_count
                            ? level2.sub_acd_level_total_count
                            : 1,
                          level2_id: level2.acd_level_2_id,
                          level3_name: idsSet.has(level3.acd_level_3_id)
                            ? EMPTY_STRING
                            : level3.acd_level_3_desc,
                          level3_span: level3.sub_acd_level_total_count
                            ? level3.sub_acd_level_total_count
                            : 1,
                          level3_id: level3.acd_level_3_id,
                          level4_name: idsSet.has(level4.acd_level_4_id)
                            ? EMPTY_STRING
                            : level4.acd_level_4_desc,
                          level4_span: level4.sub_acd_level_total_count
                            ? level4.sub_acd_level_total_count
                            : 1,
                          level4_id: level4.acd_level_4_id,
                          level5_name: level5.acd_level_5_desc,
                          level5_id: level5.acd_level_5_id,
                          level1_std_level_alloc:
                            level1.per_std_subj_allocation,
                          level2_std_level_alloc:
                            level2.per_std_subj_allocation,
                          level3_std_level_alloc:
                            level3.per_std_subj_allocation,
                          level4_std_level_alloc:
                            level4.per_std_subj_allocation,
                          level5_std_level_alloc:
                            level5.per_std_subj_allocation,
                          filterThis: false,
                          total_subjects: level5.total_subjects,
                        };
                        idsSet = new Set([
                          level1.acd_level_1_id,
                          level2.acd_level_2_id,
                          level3.acd_level_3_id,
                          level4.acd_level_4_id,
                          level5.acd_level_5_id,
                          ...idsSet,
                        ]);
                        return result;
                      });
                    } else {
                      let result = {
                        level1_name: idsSet.has(level1.acd_level_1_id)
                          ? EMPTY_STRING
                          : level1.acd_level_1_desc,
                        level1_rowspan: level1.sub_acd_level_total_count
                          ? level1.sub_acd_level_total_count
                          : 1,
                        level2_name: idsSet.has(level2.acd_level_2_id)
                          ? EMPTY_STRING
                          : level2.acd_level_2_desc,
                        level2_rowspan: level2.sub_acd_level_total_count
                          ? level2.sub_acd_level_total_count
                          : 1,
                        level3_name: idsSet.has(level3.acd_level_3_id)
                          ? EMPTY_STRING
                          : level3.acd_level_3_desc,
                        level3_span: level3.sub_acd_level_total_count
                          ? level3.sub_acd_level_total_count
                          : 1,
                        level4_name: idsSet.has(level4.acd_level_4_id)
                          ? EMPTY_STRING
                          : level4.acd_level_4_desc,
                        level4_span: level4.sub_acd_level_total_count,
                        level5_name: EMPTY_STRING,
                        level1_id: level1.acd_level_1_id,
                        level2_id: level2.acd_level_2_id,
                        level3_id: level3.acd_level_3_id,
                        level4_id: level4.acd_level_4_id,
                        level5_id: 0,
                        level1_std_level_alloc: level1.per_std_subj_allocation,
                        level2_std_level_alloc: level2.per_std_subj_allocation,
                        level3_std_level_alloc: level3.per_std_subj_allocation,
                        level4_std_level_alloc: level4.per_std_subj_allocation,
                        level5_std_level_alloc: false,
                        filterThis: !(last_level <= 5),
                        total_subjects: level4.total_subjects,
                      };
                      idsSet = new Set([
                        level1.acd_level_1_id,
                        level2.acd_level_2_id,
                        level3.acd_level_3_id,
                        level4.acd_level_4_id,
                        ...idsSet,
                      ]);
                      return result;
                    }
                  });
                } else {
                  let result = {
                    level1_name: idsSet.has(level1.acd_level_1_id)
                      ? EMPTY_STRING
                      : level1.acd_level_1_desc,
                    level1_rowspan: level1.sub_acd_level_total_count
                      ? level1.sub_acd_level_total_count
                      : 1,
                    level2_name: idsSet.has(level2.acd_level_2_id)
                      ? EMPTY_STRING
                      : level2.acd_level_2_desc,
                    level2_rowspan: level2.sub_acd_level_total_count
                      ? level2.sub_acd_level_total_count
                      : 1,
                    level3_name: idsSet.has(level3.acd_level_3_id)
                      ? EMPTY_STRING
                      : level3.acd_level_3_desc,
                    level3_span: level3.sub_acd_level_total_count
                      ? level3.sub_acd_level_total_count
                      : 1,
                    level4_name: EMPTY_STRING,
                    level4_span: 1,
                    level5_name: EMPTY_STRING,
                    level1_id: level1.acd_level_1_id,
                    level2_id: level2.acd_level_2_id,
                    level3_id: level3.acd_level_3_id,
                    level4_id: 0,
                    level5_id: 0,
                    level1_std_level_alloc: level1.per_std_subj_allocation,
                    level2_std_level_alloc: level2.per_std_subj_allocation,
                    level3_std_level_alloc: level3.per_std_subj_allocation,
                    level4_std_level_alloc: false,
                    level5_std_level_alloc: false,
                    filterThis: !(last_level <= 4) || !(last_level <= 5),

                    total_subjects: level3.total_subjects,
                  };

                  idsSet = new Set([
                    level1.acd_level_1_id,
                    level2.acd_level_2_id,
                    level3.acd_level_3_id,
                    ...idsSet,
                  ]);
                  return result;
                }
              });
            } else {
              let result = {
                level1_name: idsSet.has(level1.acd_level_1_id)
                  ? EMPTY_STRING
                  : level1.acd_level_1_desc,
                level1_rowspan: level1.sub_acd_level_total_count
                  ? level1.sub_acd_level_total_count
                  : 1,
                level2_name: idsSet.has(level2.acd_level_2_id)
                  ? EMPTY_STRING
                  : level2.acd_level_2_desc,
                level2_rowspan: level2.sub_acd_level_total_count
                  ? level2.sub_acd_level_total_count
                  : 1,
                level3_name: EMPTY_STRING,
                level3_span: 1,
                level4_name: EMPTY_STRING,
                level4_span: 1,
                level5_name: EMPTY_STRING,
                level1_id: level1.acd_level_1_id,
                level2_id: level2.acd_level_2_id,
                level3_id: 0,
                level4_id: 0,
                level5_id: 0,
                level1_std_level_alloc: level1.per_std_subj_allocation,
                level2_std_level_alloc: level2.per_std_subj_allocation,
                level3_std_level_alloc: false,
                level4_std_level_alloc: false,
                level5_std_level_alloc: false,
                filterThis:
                  !(last_level <= 3) ||
                  !(last_level <= 4) ||
                  !(last_level <= 5),
                total_subjects: level2.total_subjects,
              };

              idsSet = new Set([
                level1.acd_level_1_id,
                level2.acd_level_2_id,
                ...idsSet,
              ]);
              return result;
            }
          });
        } else {
          let result = {
            level1_name: idsSet.has(level1.acd_level_1_id)
              ? EMPTY_STRING
              : level1.acd_level_1_desc,
            level1_rowspan: level1.sub_acd_level_total_count,
            level2_name: EMPTY_STRING,
            level2_rowspan: 0,
            level3_name: EMPTY_STRING,
            level3_span: 0,
            level4_name: EMPTY_STRING,
            level4_span: 0,
            level5_name: EMPTY_STRING,
            level1_id: level1.acd_level_1_id,
            level2_id: 0,
            level3_id: 0,
            level4_id: 0,
            level5_id: 0,
            level1_std_level_alloc: level1.per_std_subj_allocation,
            level2_std_level_alloc: false,
            level3_std_level_alloc: false,
            level4_std_level_alloc: false,
            level5_std_level_alloc: false,
            filterThis:
              !(last_level <= 2) ||
              !(last_level <= 3) ||
              !(last_level <= 4) ||
              !(last_level <= 5),
            total_subjects: level1.total_subjects,
          };
          idsSet = new Set([level1.acd_level_1_id, ...idsSet]);
          return result;
        }
      })
    : [];
  const filteredTreeFromEmptyMasterDetails = modifiedArrayForTable.filter(
    ({ filterThis }) => !filterThis
  );
  const HandleDelete = (id: Number) => {
    DeleteSubjects({
      variables: {
        token,

        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcdId,
        alloted_id: id,
        alloted_level: allotedLevel,
      },
      refetchQueries: [
        {
          query: GetAcdInstTreeForAllocatedLevel,
          variables: {
            inst_id: InstId,
            id: 0,
            token,
            query_type: InstTreeQueryType.TREE_BY_INST_ID,
            date_of_attendance: toIsoDate(TODAY_DATE),
            testNameId: 0,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Subjects Deleted Successfully",
          operation: Operation.DELETE,
        });
      }
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setDeleteModal(!deleteModal);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Subject Allocation Details</Title>
      <div className="sub-allocation">
        <div className="sub-allocation__cards row g-0">
          <div className="col sub-allocation__cards--list">
            <div className="sub-allocation__cards--grid">
              <img src={TotalClasses} alt="" />
              <span>Total Classes</span>
              <b className="font-blue">0</b>
            </div>
            <div className="sub-allocation__cards--grid">
              <img src={SubjectsAllocated} alt="" />
              <span>Subjects Allocated</span>
              <b className="font-green">0</b>
            </div>
            <div className="sub-allocation__cards--grid">
              <img src={SubjectsNotAllocated} alt="" />
              <span>Subjects Not Allocated</span>
              <b className="font-red">0</b>
            </div>
          </div>
          <div className="col-3"></div>
        </div>
        <div className="sub-allocation__filters row g-0">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={departmentDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY && displaySemester ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY && displaySection ? (
            <div className="col-2">
              <Autocomplete
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, sectionSelected)
                }
                classes={classes}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (sectionSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue);
                  } else {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="sub-allocation__tableblock">
          <TableContainer className="sub-allocation__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {USE_DEPARTMENT_KEY ? (
                    <TableCell>{departmentLabel}</TableCell>
                  ) : null}
                  {USE_BRANCH_KEY ? <TableCell>{branchLabel}</TableCell> : null}
                  {USE_CLASS_KEY ? <TableCell>{classLabel}</TableCell> : null}
                  {USE_SEMESTER_KEY && displaySemester ? (
                    <TableCell>{semesterLabel}</TableCell>
                  ) : null}
                  {USE_SECTION_KEY && displaySection ? (
                    <TableCell>{sectionLabel}</TableCell>
                  ) : null}

                  <TableCell>Subjects Allocated</TableCell>
                  <TableCell> Allocated Type</TableCell>

                  <TableCell>{TableHeaders.ACTION}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTreeFromEmptyMasterDetails.map((data, i) => {
                  if (data) {
                    return (
                      <TableRow key={i}>
                        {data.level1_name !== EMPTY_STRING ? (
                          <TableCell rowSpan={data.level1_rowspan}>
                            {data.level1_name}
                          </TableCell>
                        ) : null}
                        {data &&
                        (data.level2_name ||
                          data.level2_name !== EMPTY_STRING) ? (
                          <TableCell rowSpan={data.level2_rowspan}>
                            {data.level2_name}
                          </TableCell>
                        ) : null}
                        {data && data.level3_name !== EMPTY_STRING ? (
                          <TableCell rowSpan={data.level3_span}>
                            {data.level3_name}
                          </TableCell>
                        ) : null}
                        {data &&
                        data.level4_name !== EMPTY_STRING &&
                        displaySemester ? (
                          <TableCell rowSpan={data.level4_span}>
                            {data.level4_name}
                          </TableCell>
                        ) : null}
                        {data.level5_name !== EMPTY_STRING && displaySection ? (
                          <TableCell>{data.level5_name}</TableCell>
                        ) : null}

                        <TableCell
                          id="td-center"
                          className="sub-allocation__table--number"
                        >
                          {last_level - 1 === 1 ? (
                            data.level1_std_level_alloc === false ? (
                              <>
                                {data.total_subjects}
                                <br />
                                <button
                                  className="view-button"
                                  onClick={() => {
                                    setViewSubjects(!viewSubjects);
                                    if (
                                      last_level - 1 === 2 &&
                                      data.level2_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level2_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 3 &&
                                      data.level3_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level3_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 4 &&
                                      data.level4_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level4_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 5 &&
                                      data.level5_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level5_id,
                                        },
                                      });
                                    } else {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level3_id,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  View <img src={View} alt="" />
                                </button>
                              </>
                            ) : null
                          ) : last_level - 1 === 2 ? (
                            data.level2_std_level_alloc === false ? (
                              <>
                                {data.total_subjects}
                                <br />
                                <button
                                  className="view-button"
                                  onClick={() => {
                                    setViewSubjects(!viewSubjects);
                                    if (
                                      last_level - 1 === 2 &&
                                      data.level2_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level2_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 3 &&
                                      data.level3_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level3_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 4 &&
                                      data.level4_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level4_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 5 &&
                                      data.level5_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level5_id,
                                        },
                                      });
                                    } else {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level3_id,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  View <img src={View} alt="" />
                                </button>
                              </>
                            ) : null
                          ) : last_level - 1 === 3 ? (
                            data.level3_std_level_alloc === false ? (
                              <>
                                {data.total_subjects}
                                <br />
                                <button
                                  className="view-button"
                                  onClick={() => {
                                    setViewSubjects(!viewSubjects);
                                    if (
                                      last_level - 1 === 2 &&
                                      data.level2_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level2_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 3 &&
                                      data.level3_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level3_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 4 &&
                                      data.level4_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level4_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 5 &&
                                      data.level5_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level5_id,
                                        },
                                      });
                                    } else {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level3_id,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  View <img src={View} alt="" />
                                </button>
                              </>
                            ) : null
                          ) : last_level - 1 === 4 ? (
                            data.level4_std_level_alloc === false ? (
                              <>
                                {data.total_subjects}
                                <br />
                                <button
                                  className="view-button"
                                  onClick={() => {
                                    setViewSubjects(!viewSubjects);
                                    if (
                                      last_level - 1 === 2 &&
                                      data.level2_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level2_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 3 &&
                                      data.level3_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level3_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 4 &&
                                      data.level4_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level4_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 5 &&
                                      data.level5_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level5_id,
                                        },
                                      });
                                    } else {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level3_id,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  View <img src={View} alt="" />
                                </button>
                              </>
                            ) : null
                          ) : last_level - 1 === 5 ? (
                            data.level5_std_level_alloc === false ? (
                              <>
                                {data.total_subjects}
                                <br />
                                <button
                                  className="view-button"
                                  onClick={() => {
                                    setViewSubjects(!viewSubjects);
                                    if (
                                      last_level - 1 === 2 &&
                                      data.level2_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level2_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 3 &&
                                      data.level3_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level3_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 4 &&
                                      data.level4_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level4_id,
                                        },
                                      });
                                    } else if (
                                      last_level - 1 === 5 &&
                                      data.level5_name
                                    ) {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level5_id,
                                        },
                                      });
                                    } else {
                                      dispatch({
                                        type: payloadTypes.SET_ALLOTED_ID,
                                        payload: {
                                          allotedId: data.level3_id,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  View <img src={View} alt="" />
                                </button>
                              </>
                            ) : null
                          ) : (
                            EMPTY_STRING
                          )}
                        </TableCell>
                        <TableCell className="sub-allocation__table--actions">
                          {last_level - 1 === 1
                            ? data.level1_std_level_alloc
                              ? "Student individual allocation"
                              : "Common for Everyone"
                            : last_level - 1 === 2
                            ? data.level2_std_level_alloc
                              ? "Student individual allocation"
                              : "Common for Everyone"
                            : last_level - 1 === 3
                            ? data.level3_std_level_alloc
                              ? "Student individual allocation"
                              : "Common for Everyone"
                            : last_level - 1 === 4
                            ? data.level4_std_level_alloc
                              ? "Student individual allocation"
                              : "Common for Everyone"
                            : last_level - 1 === 5
                            ? data.level5_std_level_alloc
                              ? "Student individual allocation"
                              : "Common for Everyone"
                            : EMPTY_STRING}
                        </TableCell>
                        <TableCell
                          id="td-center"
                          className="sub-allocation__table--actions"
                        >
                          {last_level - 1 === 1 && data.level1_name && (
                            <AssignComponent
                              id={data.level1_id}
                              subjects={data.total_subjects!}
                              per_std_alloc_flag={data.level1_std_level_alloc}
                            />
                          )}
                          {last_level - 1 === 2 && data.level2_name && (
                            <AssignComponent
                              id={data.level2_id}
                              subjects={data.total_subjects!}
                              per_std_alloc_flag={data.level2_std_level_alloc}
                            />
                          )}
                          {last_level - 1 === 3 && data.level3_name && (
                            <AssignComponent
                              id={data.level3_id}
                              subjects={data.total_subjects!}
                              per_std_alloc_flag={data.level3_std_level_alloc}
                            />
                          )}
                          {last_level - 1 === 4 && data.level4_name && (
                            <AssignComponent
                              id={data.level4_id}
                              subjects={data.total_subjects!}
                              per_std_alloc_flag={data.level4_std_level_alloc}
                            />
                          )}
                          {last_level - 1 === 5 && data.level5_name && (
                            <AssignComponent
                              id={data.level5_id}
                              subjects={data.total_subjects!}
                              per_std_alloc_flag={data.level5_std_level_alloc}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <CustomModal
        isOpen={viewSubjects}
        onRequestClose={() => setViewSubjects(!viewSubjects)}
        contentComponent={
          <>
            <ViewSubjects setModal={setViewSubjects} />
          </>
        }
      />
      <DeleteModal
        id={deleteId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
      />
      <LoadingModal flag={loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal isOpen={modal} style={EditModalCustomStyles} ariaHideApp={false}>
        <div className="sub-allocation__types">
          <div className="sub-allocation__types--title">
            <Title>Types of Subject Allocation</Title>
            <img src={Close} alt="" onClick={() => setWarning(false)} />
          </div>
          <div className="sub-allocation__types--img">
            <img src={TypesOfSubjects} alt="" />
          </div>
          <ul className="sub-allocation__types--ul">
            <li
              onClick={() => {
                navigate(
                  `/${InstId}/academics/subjectallocationdetails/${entryId}/allocateSubject`
                );
              }}
            >
              <img src={Common} alt="" /> 1. Apply Common for Everyone{" "}
            </li>
            <li onClick={() => setWarning(!warning)}>
              <img src={Students} alt="" />
              2. Student Individual Allocation
            </li>
          </ul>
          <Button mode="cancel" onClick={() => setModal(!modal)} />
        </div>
      </Modal>
      <Modal isOpen={warning} style={WaiveOffModalStyles} ariaHideApp={false}>
        <div className="sub-allocation__warning">
          <div className="sub-allocation__warning--title">
            <Title variant="subtitle1">
              <img src={Warning} alt="" />
              Types of Subject Allocation
            </Title>
            <div className="sub-allocation__warning--close">
              <img src={Close} alt="" onClick={() => setWarning(false)} />
            </div>
          </div>
          <Label>
            Are you sure you want to continue with Student Individual Allocation
          </Label>
          <Button
            mode="yes"
            onClick={() =>
              navigate(
                `/${InstId}/academics/${entryId}/subjectallocationdetails/combinations`
              )
            }
          />
          <Button mode="cancel" onClick={() => setWarning(false)} />
        </div>
      </Modal>
    </>
  );
};

export default Allocation;
