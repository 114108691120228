import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../../../context/context";

import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import useEnquiryStudentDetails from "../../../../../customhooks/useEnquiryStudentDetails";
import { EMPTY_STRING, TODAY_DATE } from "../../../../../utils/constants";
import { PrintReceiptProps } from "./FeeReceiptPrint";
import usePrintConfig from "./hooks/usePrintConfiguration";
import useInstLogoDetails from "../../../../../customhooks/useInstLogoDetails";
import useStudentReceiptDetails from "../../../../Accounts/hooks/useStudentReceiptDetails";
import {
  BankOrCash,
  DebitOrCredit,
  FileUploadParams,
} from "../../../../../utils/Enum.types";

import {
  formatter,
  toInputStandardDate,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import usePassedOutStudent from "../../../../../customhooks/usePassedOutStudent";
import usePassedOutStudentReceiptDetails from "../../../../Accounts/hooks/usePassedOutStudentReceiptDetails";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import { ToWords } from "to-words";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import useActiveFinancialYear from "../../../../Accounts/hooks/useActiveFinancialYear";
import dayjs from "dayjs";
export interface Data {
  particular: string;
  amount: number;
}

const StandardReceiptTemplate = ({
  tableData,
  v_date,
  v_no,
  transaction_no,
  studentDetails,
}: PrintReceiptProps) => {
  const { state } = useContext(AppContext);
  const { format } = formatter;
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { ActiveFinancicalYear } = useActiveFinancialYear();
  const { studentFormData, studentData } = useStudentDatabyId();
  const toWords = new ToWords();
  const { passedOutStudentFormData } = usePassedOutStudent();
  const { serverDate } = useServerDateandTime();
  const { studentReceiptDetails } = useStudentReceiptDetails();
  const { passedOutStudentReceiptDetails } =
    usePassedOutStudentReceiptDetails();

  const showSection: boolean = ActiveFinancicalYear.data
    ? (dayjs(toInputStandardDate(v_date)).isAfter(
        toInputStandardDate(
          ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_st_date
        )
      ) &&
        dayjs(toInputStandardDate(v_date)).isBefore(
          toInputStandardDate(
            ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_end_date
          )
        )) ||
      dayjs(toInputStandardDate(v_date)).isSame(
        toInputStandardDate(
          ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_end_date
        )
      ) ||
      dayjs(toInputStandardDate(v_date)).isSame(
        toInputStandardDate(
          ActiveFinancicalYear.data.GetFinYrActiveByInstId.fin_st_date
        )
      )
    : false;

  const { InstFormData } = useInstDetails(1);

  const filteredDebitTransactions = state.studentId
    ? studentReceiptDetails?.acct_voucher_details.filter(
        (c) => c.vo_cr_db === DebitOrCredit.DEBIT
      )
    : passedOutStudentReceiptDetails?.acct_voucher_details.filter(
        (c) => c.vo_cr_db === DebitOrCredit.DEBIT
      );
  const {
    enquiryStudentData: { EnquiryQueryDetails },
  } = useEnquiryStudentDetails();
  const { totalacademicAmount } = tableData.reduce(
    (acc, item) => {
      acc.totalacademicAmount += item.amount;
      return acc;
    },
    {
      totalacademicAmount: 0,
    }
  );

  const {
    USE_ADM_NO,
    USE_CASH_DETAILS,
    USE_CLASS_NAME,
    USE_CUSTOMER_NAME,
    USE_FATHER_NAME,
    USE_FEE_BALANCE,
    USE_INST_NAME,
    USE_INST_ADDRESS,
    USE_LOGO,
    USE_REG_NO,
    USE_SECTION_NAME,
    USE_NO_COPIES,
    USE_PRINT_COMMON_TITLE,
    USE_ENABLE_COMMON_TITLE,
    USE_RECEIPT_FOOTER,
    USE_RECEIPT_HEADER,
    USE_NARRATIONS,
    USE_RECEIPT_FOOTER_2,
    USE_TRANSACTION_NUMBER,
  } = usePrintConfig();
  const { classLabel, sectionLabel } = useInstLabels();

  return (
    <div
      className={
        USE_NO_COPIES === 3 || USE_NO_COPIES === 2
          ? "print-receipts__standard--2-3"
          : "print-receipts__standard"
      }
    >
      <div className="print-receipts__standard--header">
        {USE_LOGO ? (
          <div className="print-receipts__standard--header--logo">
            {LogoOrSign.defaultLogo ? (
              <img src={LogoOrSign.defaultLogo} alt="/" />
            ) : null}
          </div>
        ) : null}

        <div className="print-receipts__standard--header--inst-details">
          {USE_RECEIPT_HEADER ? (
            <span className="print-receipts__standard--header--inst-details--address">
              {USE_RECEIPT_HEADER}
            </span>
          ) : null}

          {USE_CUSTOMER_NAME ? (
            <span className="print-receipts__standard--header--inst-details--name">
              {InstFormData.cust_name}
            </span>
          ) : null}
          {USE_INST_NAME ? <b>{InstFormData.inst_name}</b> : null}
          {USE_INST_ADDRESS && (
            <span className="print-receipts__standard--header--inst-details--address">
              {[
                InstFormData.inst_address,
                InstFormData.inst_place,
                InstFormData.inst_pin,
              ]
                .filter(Boolean)
                .join(", ")
                .trim()}
            </span>
          )}
        </div>
        {USE_LOGO && <div className="col-1"></div>}
      </div>
      <b className="print-receipts__standard--receipt">RECEIPT</b>
      <div className="row g-0 print-receipts__standard--receipt--number">
        <div className="col">
          <span>
            Voucher No : <b>{v_no}</b>
          </span>
        </div>

        <div className="col print-receipts__standard--receipt--date">
          <span>Date : {toStandardDate(v_date ?? TODAY_DATE)}</span>
        </div>
      </div>
      <div className="print-receipts__standard--body">
        <div className="print-receipts__standard--body--std-details">
          <div className="print-receipts__standard--body--std-details--grids">
            <div className="print-receipts__standard--body--std-details--data">
              <span>Name</span> <span>:</span>
              <b>
                {studentDetails
                  ? `${studentDetails.first_name} ${studentDetails.middle_name} ${studentDetails.last_name}`
                  : state.studentId || state.stdIdForChallan
                  ? studentFormData.std_name
                  : state.aluminiStudentId || state.passOutStdIdForChallan
                  ? passedOutStudentFormData.std_name
                  : `${EnquiryQueryDetails.data?.node.first_name} ${EnquiryQueryDetails.data?.node.middle_name}  ${EnquiryQueryDetails.data?.node.last_name}`}
              </b>
            </div>
            {USE_FATHER_NAME ? (
              <div className="print-receipts__standard--body--std-details--data">
                <span>Parent</span> <span>:</span>
                <span>
                  {studentDetails
                    ? studentDetails.std_father_name
                    : state.studentId || state.stdIdForChallan
                    ? studentFormData.father_name
                    : EnquiryQueryDetails.data?.node.std_father_name}
                </span>
              </div>
            ) : null}
          </div>
          <div className="print-receipts__standard--body--std-details--grids">
            {USE_ADM_NO ? (
              <div className="print-receipts__standard--body--std-details--data">
                <span>Adm No.</span> <span>:</span>
                <span>
                  {studentDetails
                    ? studentDetails.std_adm_no
                    : state.studentId || state.stdIdForChallan
                    ? studentFormData.adm_no
                    : "-"}
                </span>
              </div>
            ) : null}
            {USE_CLASS_NAME ? (
              <div className="print-receipts__standard--body--std-details--data">
                <span>{classLabel}</span> <span>:</span>
                <span>
                  {studentDetails
                    ? studentReceiptDetails
                      ? studentReceiptDetails.class_details.class_desc
                      : EMPTY_STRING
                    : state.studentId || state.stdIdForChallan
                    ? studentReceiptDetails
                      ? studentReceiptDetails.class_details.class_desc
                      : studentFormData.class
                    : state.aluminiStudentId || state.passOutStdIdForChallan
                    ? passedOutStudentReceiptDetails
                      ? passedOutStudentReceiptDetails.class_details.class_desc
                      : passedOutStudentFormData.class_desc
                    : EnquiryQueryDetails.data?.node.class_details.class_desc}
                </span>
              </div>
            ) : null}
          </div>
          <div className="print-receipts__standard--body--std-details--grids">
            {USE_REG_NO ? (
              <div className="print-receipts__standard--body--std-details--data">
                <span>Reg No.</span> <span>:</span>
                <span>
                  {studentDetails
                    ? studentDetails.std_reg_no
                    : state.studentId || state.stdIdForChallan
                    ? studentFormData.reg_number
                    : "-"}
                </span>
              </div>
            ) : null}
            {USE_SECTION_NAME ? (
              <div className="print-receipts__standard--body--std-details--data">
                {studentDetails && showSection ? (
                  <>
                    <span>{sectionLabel}</span> <span>:</span>
                    <span>{studentDetails.section.section_desc}</span>
                  </>
                ) : state.studentId && showSection ? (
                  <>
                    <span>{sectionLabel}</span> <span>:</span>
                    <span>{studentFormData.section}</span>
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>

        <div className="print-receipts__standard--body--tableblock">
          <TableContainer className="print-receipts__standard--body--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="print-receipts__standard--body--table--th-slno">
                    Sl
                  </TableCell>
                  <TableCell className="print-receipts__standard--body--table--th-fee">
                    Particulars
                  </TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {USE_ENABLE_COMMON_TITLE &&
                (state.studentId || state.stdIdForChallan) ? (
                  <TableRow>
                    <TableCell
                      id="td-center"
                      className="print-receipts__standard--body--table--slno"
                    >
                      1
                    </TableCell>
                    <TableCell className="print-receipts__standard--body--table--fee">
                      {USE_PRINT_COMMON_TITLE}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="print-receipts__standard--body--table--amount"
                    >
                      {format(totalacademicAmount)}
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((data, index) => {
                      return (
                        <TableRow key={index + 1}>
                          <TableCell
                            id="td-center"
                            className="print-receipts__standard--body--table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="print-receipts__standard--body--table--fee">
                            {data.particular}
                          </TableCell>
                          <TableCell
                            id="td-right"
                            className="print-receipts__standard--body--table--amount"
                          >
                            {format(data.amount)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="print-receipts__standard--table-footer-total">
          <div className="print-receipts__standard--table-footer--total">
            <span>Total Paid</span>
          </div>
          <div className="print-receipts__standard--table-footer--amount">
            <span>{format(totalacademicAmount)}</span>
          </div>
        </div>
      </div>

      <div className="print-receipts__standard--footer">
        <span className="print-receipts__standard--footer--amount-in-words">
          ({toWords.convert(totalacademicAmount, { currency: true })})
        </span>
        {USE_FEE_BALANCE && state.studentId ? (
          <div className="print-receipts__standard--table-footer-balance">
            <span>Fee Balance (as on {toStandardDate(serverDate)}) : </span>
            <span className="print-receipts__standard--table-footer-balance-amount">
              {format(
                studentData.data?.nodes[0].acct_std_demand.std_demand_bal!
              )}
            </span>
          </div>
        ) : null}
        <div className="row g-0">
          <div className="col print-receipts__standard--footer--payment-method">
            {USE_CASH_DETAILS ? (
              <b>
                {filteredDebitTransactions &&
                filteredDebitTransactions[0]?.acct_ldgr.ldgr_cash_bank_other ===
                  BankOrCash.CASH
                  ? filteredDebitTransactions[0].acct_ldgr.ldgr_desc
                  : ": " + state.studentId
                  ? studentReceiptDetails?.v_transcation_type
                  : passedOutStudentReceiptDetails?.v_transcation_type +
                    " (" +
                    state.studentId
                  ? studentReceiptDetails?.v_transcation_no
                  : passedOutStudentReceiptDetails?.v_transcation_no + ")"}
              </b>
            ) : null}

            {filteredDebitTransactions &&
            filteredDebitTransactions[0]?.acct_ldgr.ldgr_cash_bank_other ===
              BankOrCash.CASH ? null : (
              <>
                {" "}
                {USE_TRANSACTION_NUMBER && (
                  <div className="col">
                    <span>
                      Transaction No : <b>{transaction_no}</b>
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="col print-receipts__standard--footer--signature">
            <b>Receiver's Signature</b>
          </div>
        </div>
        {USE_NARRATIONS ? (
          <span className="print-receipts__standard--footer--amount-in-words">
            Narration : {studentReceiptDetails?.v_transcation_narration}
          </span>
        ) : null}
        <div className="print-receipts__standard--footer--narration">
          <span>{USE_RECEIPT_FOOTER}</span>
        </div>
        <div className="print-receipts__standard--footer--narration">
          <span>{USE_RECEIPT_FOOTER_2}</span>
        </div>
      </div>
    </div>
  );
};

export default StandardReceiptTemplate;
