import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import Input from "../../../../stories/Input/Input";
import {
  Direction,
  SortBy,
  SortByFilterType,
  StudentOrderFields,
  StudentReportType,
  TableHeaders,
} from "../../../../utils/Enum.types";

import { optionsType, responseType } from "../../../../utils/Form.types";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import {
  getModifiedScrollHeight,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { Keys } from "../../../../utils/Enum.keys";
import StudentTotalCount from "../Components/StudentTotalCount";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useAcdDropDownData from "../../../Academics/hooks/useAcdDropDownData";
import {
  AcdStudentsForAdmissionvars,
  GetStudentAcdForAdmissionData,
  StudentEdges,
} from "../../../Academics/hooks/useAcdStudentsForAdmission";
import { GetAcdStudentsForAdmissions } from "../../../Academics/queries/students/Index";
import { useLazyQuery } from "@apollo/client";
import { AppContext } from "../../../../context/context";
import AcademicsDropDown from "../../../Academics/Components/InstDetailsDropDown/AcademicsDropDown";
import useActiveAcademicYear from "../../../Academics/hooks/useActiveAcademicYear";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import StatusAutocomplete from "../../../../components/common/Autocompletes/StatusAutocomplete";
import SortByAutocomplete from "../../../../components/common/Autocompletes/SortByAutocomplete";

const YearWise = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();

  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const { token } = useToken();
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [searchAdm, setSearchAdm] = useState("");
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [statusSelected, setStatusSelected] = useState<optionsType | null>(
    null
  );
  const [sortBySelected, setSortBySelected] = useState<optionsType | null>(
    null
  );
  const [searchData, setSearchData] = useState("");

  const [entryId, setEntryId] = useState(0);
  const [students, setStudents] = useState<StudentEdges[]>([]);

  const [selectedAcdYrId, setSelectedAcdYrId] = useState<responseType | null>(
    null
  );

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const { activeAcademicYearData } = useActiveAcademicYear();
  const acdYearID =
    activeAcademicYearData.data &&
    activeAcademicYearData.data.GetAcdYrActiveByInstId.id;
  const [GetStudentsData, { data, loading, fetchMore }] = useLazyQuery<
    GetStudentAcdForAdmissionData,
    AcdStudentsForAdmissionvars
  >(GetAcdStudentsForAdmissions, {
    variables: {
      after: null,
      acd_yr_id: selectedAcdYrId
        ? selectedAcdYrId.value
        : state.instIdForCampus && acdYearID
        ? acdYearID
        : state.ActiveAcdYr
        ? state.ActiveAcdYr.id
        : 0,
      status: statusSelected ? statusSelected.value : EMPTY_STRING,
      first: ROWS_PER_PAGE,
      name: searchData,
      token,
      orderBy: [
        {
          direction: Direction.ASC,
          field: sortBySelected
            ? sortBySelected.value
            : StudentOrderFields.FIRST_NAME,
        },
      ],
      input: {
        ids: [
          Number(InstId),
          entryId,
          categorySelected ? categorySelected.value : 0,
        ],
        acd_std_query_type: StudentReportType.STDS_BY_JOINING_YEAR,
        str_data: [],
        int_data: [],
      },
    },
  });

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInputRef = branchRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const semRef = useRef<HTMLSelectElement>(null);
  const semInputRef = semRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const { categoryDropDown } = useInstMasterDataByInstId();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;
            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);
            const duplicateCheck = prevResult.GetAcdStudents
              ? prevResult.GetAcdStudents.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                )
              : [];
            if (duplicateCheck.length > 0) return prevResult;
            return {
              GetAcdStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data ? data.GetAcdStudents.totalCount : 0,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    if (semesterSelected && USE_SEMESTER_KEY) {
      setEntryId(semesterSelected.value);
    } else if (classSelected && USE_CLASS_KEY) {
      setEntryId(classSelected.value);
    } else if (branchSelected && USE_BRANCH_KEY) {
      setEntryId(branchSelected.value);
    } else if (departmentSelected && USE_DEPARTMENT_KEY) {
      setEntryId(departmentSelected.value);
    } else {
      setEntryId(Number(InstId));
    }
  }, [
    semesterSelected,
    classSelected,
    branchSelected,
    departmentSelected,
    InstId,
    USE_SEMESTER_KEY,
    USE_CLASS_KEY,
    USE_BRANCH_KEY,
    USE_DEPARTMENT_KEY,
  ]);

  useEffect(() => {
    if (entryId && token) {
      GetStudentsData();
    }
  }, [
    selectedAcdYrId,
    entryId,
    token,
    GetStudentsData,
    state.instIdForCampus,
    acdYearID,
    state.ActiveAcdYr,
    statusSelected,
  ]);

  const { branchLabel, classLabel, departmentLabel, semesterLabel,categoryLabel } =
    useInstLabels();

  const handleAcdYrId = (newValue: responseType | null) => {
    setSelectedAcdYrId(newValue);
  };
  return (
    <>
      <div className="std-report__agewise">
        <div className="row g-0 std-report__agewise--filters">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={departmentDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected) {
                      branchInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    InputLabelProps={{ shrink: true }}
                    id="outlined Departments"
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_BRANCH_KEY ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={branchDropDown}
                ref={branchRef!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      classInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, classSelected)
                }
                ref={classRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      semInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={semesterDropDown}
                ref={semRef!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      categoryInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue);
                  } else {
                    setSemesterSelected(null);
                  }
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_CATEGORY_KEY ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={categoryDropDown}
                ref={categoryRef!}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, categorySelected)
                }
                value={categorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategorySelected(newValue);
                  } else {
                    setCategorySelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={categoryLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="row g-0 std-report__agewise--search">
          <div className="col-2">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchAdm(e.target.value);
                setSearchData(e.target.value);
              }}
              value={searchData !== "" ? searchData : searchAdm}
            />
          </div>

          <div className="col-2">
            <SortByAutocomplete
              label="Sort By"
              onChange={(newValue) => setSortBySelected(newValue)}
              sortByType={SortByFilterType.STUDENT}
              sortByValue={EMPTY_STRING}
            />
          </div>

          <div className="col-2">
            <StatusAutocomplete
              label="Status"
              onChange={(newValue) => {
                setStatusSelected(newValue);
              }}
              statusValue={statusSelected ? statusSelected.value : EMPTY_STRING}
            />
          </div>
          <div className="col-2">
            <AcademicsDropDown
              valueId={selectedAcdYrId ? selectedAcdYrId.value : 0}
              onChange={handleAcdYrId}
              label="Choose Acd Yr"
            />
          </div>

          <div className="col-2"></div>
        </div>
        <div className="std-report__agewise--tableblock">
          <TableContainer
            className="std-report__agewise--table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{TableHeaders.SLNO}</TableCell>
                  <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
                  <TableCell>{TableHeaders.REGISTER_NUMBER}</TableCell>
                  <TableCell>{TableHeaders.NAME}</TableCell>
                  <TableCell>Year of Joining</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students &&
                  students.map((res, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className="studentlist__table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className="studentlist__table--admno">
                          {res.node.std_adm_no}
                        </TableCell>
                        <TableCell className="studentlist__table--admno">
                          {res.node.std_reg_no}
                        </TableCell>
                        <TableCell>
                          {res.node.first_name +
                            " " +
                            res.node.middle_name +
                            " " +
                            res.node.last_name}
                        </TableCell>
                        <TableCell>{res.node.std_joining_acd_yr}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="row g-0">
          <div className="col"></div>
          <div className="col-4 std-report__agewise--total">
            <StudentTotalCount
              totalCount={data ? data.GetAcdStudents.totalCount : 0}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default YearWise;
