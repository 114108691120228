import { StatesProvider } from "./GlobalStates/StatesProvider";
import MainPage from "./MainPage";
import { ExcelAcctQueryType, ExcelAdmQueryType } from "./useExportMutation";
export interface ExportProps {
  reportType: ExcelAdmQueryType | ExcelAcctQueryType;
}
const Index = ({ reportType }: ExportProps) => {
  return (
    <StatesProvider>
      <MainPage reportType={reportType} />
    </StatesProvider>
  );
};

export default Index;
