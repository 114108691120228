import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import { FileUploadParams } from "../../../../utils/Enum.types";
import useActiveAcademicYear from "../../../Academics/hooks/useActiveAcademicYear";
import useToken from "../../../../customhooks/useToken";
import { useLazyQuery } from "@apollo/client";
import {
  StudentAcademicData,
  StudentAcademicVars,
} from "../../../Academics/hooks/useAcdStudentDetails";
import { GetAcdStudentDetails } from "../../../Academics/queries/student";
import useStudentMarksStatistics, {
  SelectedStudentsProps,
} from "../hooks/useStudentMarksStatistics";
import useReportCardConfig from "../../../Academics/Reports/CompleteReports/ReportCards/hooks/useReportCardConfig";
import { stdMarksType } from "../Template1/FrontSide";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { AppContext } from "../../../../context/context";
import { EMPTY_STRING } from "../../../../utils/constants";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import Avatar from "../../../../images/Avatar.svg";
import { toStandardDate } from "../../../../utils/UtilFunctions";
import EduateLogo from "../../../../images/EduateLogo.svg";
const Template = ({
  selectedStudents,
  setStudentIdSet,
}: SelectedStudentsProps) => {
  const { InstFormData } = useInstDetails(1);
  const { state } = useContext(AppContext);
  const [imageString, setImageString] = useState("");
  const { InstDetails } = useInstDetails(1);
  const instLogoDetails = {
    LogoOrSign: useInstLogoDetails({ filetype: FileUploadParams.INST_LOGO })
      .LogoOrSign,
    PrincipalSign: useInstLogoDetails({
      filetype: FileUploadParams.PRINCIPAL_SIGNATURE,
    }).LogoOrSign,
    Photo1: useInstLogoDetails({ filetype: FileUploadParams.PHOTO_1 })
      .LogoOrSign,
    Photo2: useInstLogoDetails({ filetype: FileUploadParams.PHOTO_2 })
      .LogoOrSign,
  };
  const { LogoOrSign, PrincipalSign, Photo1, Photo2 } = instLogoDetails;

  const { activeAcademicYearData } = useActiveAcademicYear();
  const { token } = useToken();
  const [GetStudentDetailsByIds, { data: studentData }] = useLazyQuery<
    StudentAcademicData,
    StudentAcademicVars
  >(GetAcdStudentDetails, {
    variables: { token, ids: selectedStudents },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (token && selectedStudents) {
      GetStudentDetailsByIds();
    }
  }, [token, GetStudentDetailsByIds, selectedStudents]);
  const {
    instAddress,
    instLogo,
    instName,
    stdName,
    section,
    fatherName,
    motherName,
    rollNo,
    custName,
    parentSign,
    attendanceStats,
    heightWeight,
    gradeForEachSub,
    remarksPerTest,
    classTeacherSign,
    overAllPercentage,
    annualResults,
    overAllGrade,
    overAllRank,
    stdImage,
    header,
    affiliatedTo,
    photo1Config,
    photo2Config,
    dob,
    title
  } = useReportCardConfig({
    selectedStudents,
    setStudentIdSet,
  });

  const [stdMarks, setStdMarks] = useState<stdMarksType[]>([]);

  const { data } = useStudentMarksStatistics({
    selectedStudents,
    setStudentIdSet,
  });

  useEffect(() => {
    if (data) {
      let items: stdMarksType[] = data
        ? (data.GetAcdStudentsMarksStatisticsForReportCard.map((mark) => {
            let tests: string[] = [];
            let subj_max_marks: number[] = [];

            let total_std_marks: number[] = [];
            let total_std_percentage: number[] = [];
            let std_rank: number[] = [];
            let std_remarks: string[] = [];
            let std_working_days: number = 0;
            let std_present_days: number = 0;
            let std_overall_grade: string[] = [];
            let no_acd_tests: string[] = [];
            let no_non_acd_tests: string[] = [];
            let no_non_acd_remarks: string[] = [];
            let acd_subj_grades: string[] = [];

            let subjects: Map<string, { [key: string]: number }> = new Map();
            let no_acd_subs: Map<string, { [key: string]: number }> = new Map();
            let no_non_acd_subs: Map<string, { [key: string]: string }> =
              new Map();
            let no_non_acd_comments: Map<string, { [key: string]: string }> =
              new Map();
            let acd_grades: Map<string, { [key: string]: string }> = new Map();
            std_working_days = mark.cal_days_count;
            std_present_days = mark.std_present_count;
            // std_absent_days = mark.std_absent_count;

            mark.acd_test_marks_details.forEach((test) => {
              if (!test.is_non_academic && test.marks_details.length > 0) {
                subj_max_marks = [
                  ...subj_max_marks,
                  test.marks_details[0].subj_total_max_marks,
                ];
              }

              if (test.is_non_academic === false) {
                test.marks_details.forEach((sub_marks) => {
                  if (subjects.has(sub_marks.subj_desc)) {
                    subjects.set(sub_marks.subj_desc, {
                      ...subjects.get(sub_marks.subj_desc),
                      [test.acd_test_name]: sub_marks.is_std_present
                        ? sub_marks.std_marks_scored
                        : "A",
                    });
                  } else {
                    subjects.set(sub_marks.subj_desc, {
                      [test.acd_test_name]: sub_marks.is_std_present
                        ? sub_marks.std_marks_scored
                        : "A",
                    });
                  }

                  if (acd_grades.has(sub_marks.subj_desc)) {
                    acd_grades.set(sub_marks.subj_desc, {
                      ...acd_grades.get(sub_marks.subj_desc),
                      [test.acd_test_name]: sub_marks.scored_grade,
                    });
                  } else {
                    acd_grades.set(sub_marks.subj_desc, {
                      [test.acd_test_name]: sub_marks.scored_grade,
                    });
                  }
                });
                tests = [...tests, test.acd_test_name];
                total_std_marks = [
                  ...total_std_marks,
                  test.test_std_total_marks_scored,
                ];
                total_std_percentage = [
                  ...total_std_percentage,
                  test.test_std_total_percentage,
                ];
                std_overall_grade = [...std_overall_grade, test.overall_grade];
                std_rank = [...std_rank, test.entry_level_rank];
                std_remarks = [...std_remarks, test.test_remarks];
                acd_subj_grades = [...acd_subj_grades, test.acd_test_name];
              } else if (test.is_non_academic === true) {
                test.marks_details.forEach((sub_marks) => {
                  if (no_non_acd_subs.has(sub_marks.subj_desc)) {
                    no_non_acd_subs.set(sub_marks.subj_desc, {
                      ...no_non_acd_subs.get(sub_marks.subj_desc),
                      [test.acd_test_name]: sub_marks.scored_grade,
                    });
                  } else {
                    no_non_acd_subs.set(sub_marks.subj_desc, {
                      [test.acd_test_name]: sub_marks.scored_grade,
                    });
                  }

                  if (no_non_acd_comments.has(sub_marks.subj_desc)) {
                    no_non_acd_comments.set(sub_marks.subj_desc, {
                      ...no_non_acd_comments.get(sub_marks.subj_desc),
                      [test.acd_test_name]: sub_marks.teacher_comments,
                    });
                  } else {
                    no_non_acd_comments.set(sub_marks.subj_desc, {
                      [test.acd_test_name]: sub_marks.teacher_comments,
                    });
                  }
                });
                no_non_acd_tests = [...no_non_acd_tests, test.acd_test_name];
                no_non_acd_remarks = [
                  ...no_non_acd_remarks,
                  test.acd_test_name,
                ];
              }
            });

            return {
              std_name: mark.student_details,
              std_id: mark.student_id,
              tests: tests,
              std_overall_grade: std_overall_grade,
              total_std_marks: total_std_marks,
              total_std_percentage: total_std_percentage,
              std_rank: std_rank,
              std_remarks: std_remarks,
              std_working_days: std_working_days,
              std_present_days: std_present_days,
              // std_absent_days: std_absent_days,
              sub_marks: subjects,
              no_acd_tests: no_acd_tests,
              no_sub_marks: no_acd_subs,
              no_non_acd_tests: no_non_acd_tests,
              no_non_acd_remarks: no_non_acd_remarks,
              acd_subj_grades: acd_subj_grades,
              no_non_acd_subs: no_non_acd_subs,
              no_non_acd_comments: no_non_acd_comments,
              acd_grades: acd_grades,
              subj_max_marks: subj_max_marks,
            };
          }) as unknown as stdMarksType[])
        : [];

      setStdMarks(items);
    }
  }, [data]);
  const { classLabel, sectionLabel } = useInstLabels();
  useEffect(() => {
    if (studentData) {
      if (
        studentData?.nodes[0].std_profile_filename !== EMPTY_STRING &&
        InstDetails.data
      ) {
        const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;

        getDownloadUrl(studentProfiePicUrl, false, setImageString);
      }
    }
  }, [studentData, InstDetails.data, state.studentId]);
  return (
    <>
      {studentData && stdMarks.length
        ? studentData.nodes.map((std, index) => {
            const tests = stdMarks[index]?.tests;
            const subj_max_marks = stdMarks[index]?.subj_max_marks;
            const std_overall_grade = stdMarks[index]?.std_overall_grade;
            const total_std_marks = stdMarks[index]?.total_std_marks;
            const total_std_percentage = stdMarks[index]?.total_std_percentage;
            const std_rank = stdMarks[index]?.std_rank;
            const std_remarks = stdMarks[index]?.std_remarks;
            const std_working_days = stdMarks[index]?.std_working_days;
            const std_present_days = stdMarks[index]?.std_present_days;
            // const std_absent_days = stdMarks[index]?.std_absent_days;
            const sub_marks = stdMarks[index].sub_marks;
            const nonAcdSubjects = stdMarks[index].no_non_acd_tests;
            const nonAcademicMarks = stdMarks[index].no_non_acd_subs;
            const nonAcademicComments = stdMarks[index].no_non_acd_comments;
            const Academicgrades = stdMarks[index].acd_grades;
            return (
              <>
                <div className="bgs-pn-pr__block--data">
                  <div
                    className="progress-report__inst--logos"
                    style={
                      photo1Config?.config_boolean_value &&
                      photo2Config?.config_boolean_value
                        ? { justifyContent: "space-between" }
                        : photo1Config?.config_boolean_value ||
                          photo2Config?.config_boolean_value
                        ? { justifyContent: "space-between" }
                        : { justifyContent: "center" }
                    }
                  >
                    {photo1Config?.config_boolean_value === true &&
                    photo2Config?.config_boolean_value === false ? (
                      <>
                        {instLogo?.config_boolean_value ? (
                          <div className="progress-report__inst--logo">
                            {LogoOrSign.defaultLogo ? (
                              <img
                                src={LogoOrSign.defaultLogo}
                                alt="/"
                                className="bgs-pn-pr__inst-details--logo"
                              />
                            ) : (
                              <img
                                src={EduateLogo}
                                alt="/"
                                className="bgs-pn-pr__inst-details--logo"
                              />
                            )}
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {photo1Config?.config_boolean_value && (
                          <div className="progress-report__inst--logo">
                            {Photo1.defaultLogo ? (
                              <img
                                src={Photo1.defaultLogo}
                                alt="/"
                                className="bgs-pn-pr__inst-details--logo"
                              />
                            ) : (
                              <img
                                src={EduateLogo}
                                alt="/"
                                className="bgs-pn-pr__inst-details--logo"
                              />
                            )}
                          </div>
                        )}
                      </>
                    )}

                    {photo1Config?.config_boolean_value === false &&
                      photo2Config?.config_boolean_value === true && (
                        <>
                          {instLogo?.config_boolean_value ? (
                            <div className="progress-report__inst--logo">
                              {LogoOrSign.defaultLogo ? (
                                <img
                                  src={LogoOrSign.defaultLogo}
                                  alt="/"
                                  className="bgs-pn-pr__inst-details--logo"
                                />
                              ) : (
                                <img
                                  src={EduateLogo}
                                  alt="/"
                                  className="bgs-pn-pr__inst-details--logo"
                                />
                              )}
                            </div>
                          ) : null}
                        </>
                      )}
                    <div>
                      <div className="progress-report__inst--details">
                        {(photo1Config?.config_boolean_value === false &&
                          photo2Config?.config_boolean_value === true) ||
                        (photo1Config?.config_boolean_value === true &&
                          photo2Config?.config_boolean_value === false) ? (
                          <></>
                        ) : (
                          <>
                            {instLogo?.config_boolean_value ? (
                              <div className="progress-report__inst--logo">
                                {LogoOrSign.defaultLogo ? (
                                  <img
                                    src={LogoOrSign.defaultLogo}
                                    alt="/"
                                    className="bgs-pn-pr__inst-details--logo"
                                  />
                                ) : (
                                  <img
                                    src={EduateLogo}
                                    alt="/"
                                    className="bgs-pn-pr__inst-details--logo"
                                  />
                                )}
                              </div>
                            ) : null}
                          </>
                        )}

                        {header?.config_boolean_value ? (
                          <span className="progress-report__inst--details--cust-name">
                            {header.config_string_value}
                          </span>
                        ) : null}
                                   {custName?.config_boolean_value ? (
                          <span className="progress-report__inst--details--cust-name">
                            {InstFormData.cust_name}{" "}
                          </span>
                        ) : null}
                             {instName?.config_boolean_value ? (
                          <b className="progress-report__inst--details--inst-name">
                            {InstFormData.inst_name_to_print}{" "}
                          </b>
                        ) : null}
                        {affiliatedTo?.config_boolean_value ? (
                          <span className="progress-report__inst--details--cust-name">
                            {affiliatedTo.config_string_value}
                          </span>
                        ) : null}
             
                   
                        {instAddress?.config_boolean_value ? (
                          <span className="progress-report__inst--details--inst-address">
                            {[
                              InstFormData.inst_address,
                              InstFormData.inst_place,
                              InstFormData.inst_pin,
                            ]
                              .filter(Boolean)
                              .join(", ")
                              .trim()}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {photo1Config?.config_boolean_value === true &&
                      photo2Config?.config_boolean_value === false && (
                        <>
                          {photo1Config?.config_boolean_value && (
                            <div className="progress-report__inst--logo">
                              {Photo1.defaultLogo ? (
                                <img
                                  src={Photo1.defaultLogo}
                                  alt="/"
                                  className="bgs-pn-pr__inst-details--logo"
                                />
                              ) : (
                                <img
                                  src={EduateLogo}
                                  alt="/"
                                  className="bgs-pn-pr__inst-details--logo"
                                />
                              )}
                            </div>
                          )}
                        </>
                      )}
                    {photo2Config?.config_boolean_value ? (
                      <div className="progress-report__inst--logo">
                        {Photo2.defaultLogo ? (
                          <img
                            src={Photo2.defaultLogo}
                            alt="/"
                            className="bgs-pn-pr__inst-details--logo"
                          />
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <div className="bgs-pn-pr__std-details">
                    <div style={{ display: "flex", columnGap: 10 }}>
                      <div className="bgs-pn-pr__std-details--image">
                        {stdImage && imageString ? (
                          <img
                            src={imageString}
                            alt=""
                            className="bgs-pn-pr__inst-details--logo"
                          />
                        ) : (
                          <img
                            src={Avatar}
                            alt=""
                            className="bgs-pn-pr__inst-details--logo"
                          />
                        )}
                      </div>
                      <div>
                        {stdName?.config_boolean_value && (
                          <>
                            <div className="bgs-pn-pr__std-details--grid">
                              <label>{stdName.config_string_value}</label>
                              <span>:</span>
                              <input
                                type="text"
                                value={
                                  std.first_name +
                                  " " +
                                  std.middle_name +
                                  " " +
                                  std.last_name
                                }
                              />
                            </div>
                          </>
                        )}

                        {rollNo?.config_boolean_value && (
                          <div className="bgs-pn-pr__std-details--grid">
                            <label>{rollNo.config_string_value}</label>
                            <span>:</span>
                            <input type="text" value={std.std_adm_no} />
                          </div>
                        )}
                        {fatherName?.config_boolean_value && (
                          <div className="bgs-pn-pr__std-details--grid">
                            <label>{fatherName.config_string_value}</label>
                            <span>:</span>
                            <input type="text" value={std.std_father_name} />
                          </div>
                        )}
                        {motherName?.config_boolean_value && (
                          <div className="bgs-pn-pr__std-details--grid">
                            <label>{motherName.config_string_value}</label>
                            <span>:</span>
                            <input type="text" value={std.std_mother_name} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      {section?.config_boolean_value && (
                        <>
                          <div className="bgs-pn-pr__std-details--grid">
                            <label>{classLabel}</label>
                            <span>:</span>
                            <input
                              type="text"
                              value={std.acd_class.class_desc}
                            />
                          </div>
                          <div className="bgs-pn-pr__std-details--grid">
                            <label>{sectionLabel}</label>
                            <span>:</span>
                            <input
                              type="text"
                              value={std.acd_section.section_desc}
                            />
                          </div>
                        </>
                      )}
                      <div className="bgs-pn-pr__std-details--grid">
                        <label>Year</label>
                        <span>:</span>
                        <input
                          type="text"
                          value={
                            activeAcademicYearData.data?.GetAcdYrActiveByInstId
                              .acd_yr
                          }
                        />
                      </div>
                      {dob?.config_boolean_value && (
                        <div className="bgs-pn-pr__std-details--grid">
                          <label>{dob.config_string_value}</label>
                          <span>:</span>
                          <input
                            type="text"
                            value={toStandardDate(std.std_dob)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {title?.config_boolean_value &&  <b className="progress-report__title">{title.config_string_value}</b> }
                 
                  <div className="bgs-pn-pr__acd-details">
                    <TableContainer className="bgs-pn-pr__acd-details--table">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell id="td-center" rowSpan={2}>
                              Subject Name
                            </TableCell>

                            {tests.map((th: string, index: number) => {
                              return (
                                <TableCell
                                  key={index}
                                  id="td-center"
                                  colSpan={
                                    gradeForEachSub?.config_boolean_value
                                      ? 2
                                      : 1
                                  }
                                >
                                  {th}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                          <TableRow>
                            {tests.map((_, index) => (
                              <React.Fragment key={index}>
                                <TableCell align="center">Marks</TableCell>
                                {gradeForEachSub?.config_boolean_value && (
                                  <TableCell align="center"> Grade</TableCell>
                                )}
                              </React.Fragment>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Maximum Marks</TableCell>
                            {subj_max_marks &&
                              subj_max_marks.map((test_name, index) => (
                                <React.Fragment key={index}>
                                  <TableCell id="td-center">
                                    {test_name}
                                  </TableCell>
                                  {gradeForEachSub?.config_boolean_value && (
                                    <TableCell id="td-center"></TableCell>
                                  )}
                                </React.Fragment>
                              ))}
                          </TableRow>

                          {Array.from(sub_marks).map(
                            ([subject, marks], index) => {
                              const grades = Academicgrades.get(subject);

                              return (
                                <TableRow key={index}>
                                  <TableCell>{subject}</TableCell>
                                  {tests &&
                                    tests.map((test_name, index) => (
                                      <>
                                        <TableCell key={index} id="td-center">
                                          {marks[test_name]}
                                        </TableCell>
                                        {gradeForEachSub?.config_boolean_value && (
                                          <TableCell id="td-center">
                                            {grades
                                              ? grades[test_name] ?? "-"
                                              : "-"}{" "}
                                          </TableCell>
                                        )}
                                      </>
                                    ))}
                                </TableRow>
                              );
                            }
                          )}

                          <TableRow>
                            <TableCell>Total</TableCell>
                            {total_std_marks &&
                              total_std_marks.map((test_name, index) => (
                                <>
                                  <TableCell
                                    colSpan={
                                      gradeForEachSub?.config_boolean_value
                                        ? 2
                                        : 1
                                    }
                                    id="td-center"
                                    key={index}
                                  >
                                    {test_name}
                                  </TableCell>
                                </>
                              ))}
                          </TableRow>
                          {overAllPercentage?.config_boolean_value && (
                            <TableRow>
                              <TableCell>
                                {overAllPercentage.config_string_value}
                              </TableCell>
                              {total_std_percentage &&
                                total_std_percentage.map((test_name, index) => (
                                  <>
                                    <TableCell
                                      colSpan={
                                        gradeForEachSub?.config_boolean_value
                                          ? 2
                                          : 1
                                      }
                                      key={index}
                                      id="td-center"
                                    >
                                      {`${test_name} %`}
                                    </TableCell>
                                  </>
                                ))}
                            </TableRow>
                          )}
                          {overAllGrade?.config_boolean_value && (
                            <TableRow>
                              <TableCell>Grade</TableCell>
                              {std_overall_grade &&
                                std_overall_grade.map((test_name, index) => (
                                  <>
                                    <TableCell
                                      colSpan={
                                        gradeForEachSub?.config_boolean_value
                                          ? 2
                                          : 1
                                      }
                                      key={index}
                                      id="td-center"
                                    >
                                      {test_name}
                                    </TableCell>
                                  </>
                                ))}
                            </TableRow>
                          )}

                          {overAllRank?.config_boolean_value && (
                            <TableRow>
                              <TableCell>Rank</TableCell>
                              {std_rank &&
                                std_rank.map((test_name, index) => (
                                  <>
                                    <TableCell
                                      colSpan={
                                        gradeForEachSub?.config_boolean_value
                                          ? 2
                                          : 1
                                      }
                                      key={index}
                                      id="td-center"
                                    >
                                      {test_name}
                                    </TableCell>
                                  </>
                                ))}
                            </TableRow>
                          )}
                          {remarksPerTest?.config_boolean_value && (
                            <TableRow>
                              <TableCell>Remarks</TableCell>
                              {std_remarks &&
                                std_remarks.map((test_name, index) => (
                                  <>
                                    <TableCell
                                      colSpan={
                                        gradeForEachSub?.config_boolean_value
                                          ? 2
                                          : 1
                                      }
                                      key={index}
                                    >
                                      {test_name}
                                    </TableCell>
                                  </>
                                ))}
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <br />
                  <div className="bgs-pn-pr__non-acd">
                    <TableContainer className="bgs-pn-pr__non-acd--table">
                      <Table>
                        <TableHead>
                          <TableRow className="bgs-pn-pr__non-acd--row">
                            {nonAcdSubjects.map((th: string, index: number) => {
                              return (
                                <Fragment key={index}>
                                  <TableCell id="td-center">{th}</TableCell>
                                  <TableCell id="td-center">Grade</TableCell>
                                  <TableCell id="td-center">
                                    Grade Remarks
                                  </TableCell>
                                </Fragment>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.from(nonAcademicMarks).map(
                            ([subject, marks], index) => {
                              const comments = nonAcademicComments.get(subject);
                              return (
                                <TableRow key={index}>
                                  <TableCell>{subject}</TableCell>
                                  {nonAcdSubjects.map((test_name, index) => (
                                    <>
                                      <TableCell
                                        key={`grade-${index}`}
                                        id="td-center"
                                      >
                                        {marks[test_name] ?? "-"}
                                      </TableCell>
                                      <TableCell
                                        key={`remark-${index}`}
                                        id="td-center"
                                      >
                                        {comments
                                          ? comments[test_name] ?? "-"
                                          : "-"}{" "}
                                      </TableCell>
                                    </>
                                  ))}
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>

                  {heightWeight?.config_boolean_value ||
                  attendanceStats?.config_boolean_value ? (
                    <>
                      <br />
                      <div className="bgs-pn-pr__non-acd">
                        <TableContainer className="bgs-pn-pr__non-acd--table">
                          <Table>
                            <TableHead>
                              <TableRow className="bgs-pn-pr__non-acd--row">
                                <TableCell
                                  key={index}
                                  id="td-center"
                                  colSpan={4}
                                  style={{ height: "25px" }}
                                >
                                  Other details
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {heightWeight?.config_boolean_value && (
                                <TableRow>
                                  <TableCell>Height</TableCell>
                                  <TableCell id="td-center">-</TableCell>
                                  <TableCell>Weight</TableCell>
                                  <TableCell id="td-center">-</TableCell>
                                </TableRow>
                              )}

                              {attendanceStats?.config_boolean_value && (
                                <>
                                  <TableRow>
                                    <TableCell rowSpan={2}>
                                      Total Working Days
                                    </TableCell>
                                    <TableCell id="td-center">
                                      {std_working_days}
                                    </TableCell>
                                    <TableCell>No. of Present Days</TableCell>
                                    <TableCell id="td-center">
                                      {std_present_days}
                                    </TableCell>
                                  </TableRow>
                                  {/* <TableRow>
                                    <TableCell>No. of Absent Days</TableCell>
                                    <TableCell id="td-center">
                                      {std_absent_days}
                                    </TableCell>
                                  </TableRow> */}
                                </>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </>
                  ) : null}

                  <br />
                  <div className="bgs-pn-pr__non-acd">
                    <TableContainer className="bgs-pn-pr__non-acd--table">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell key={index} id="td-center">
                              Signature's
                            </TableCell>
                            {tests.map((th: string, index: number) => {
                              return (
                                <>
                                  <TableCell
                                    key={index}
                                    id="td-center"
                                    colSpan={2}
                                  >
                                    {th}
                                  </TableCell>
                                </>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {classTeacherSign?.config_boolean_value && (
                            <TableRow className="bgs-pn-pr__signatures">
                              <TableCell>
                                {classTeacherSign.config_string_value}
                              </TableCell>
                              {tests.map((res) => {
                                return <TableCell colSpan={2}></TableCell>;
                              })}
                            </TableRow>
                          )}

                          {parentSign?.config_boolean_value && (
                            <TableRow className="bgs-pn-pr__signatures">
                              <TableCell>
                                {parentSign?.config_string_value}
                              </TableCell>
                              {tests.map((res) => {
                                return <TableCell colSpan={2}></TableCell>;
                              })}
                            </TableRow>
                          )}

                          <TableRow className="bgs-pn-pr__signatures">
                            <TableCell>Principal</TableCell>
                            {tests.map((res) => {
                              return (
                                <TableCell colSpan={2}>
                                  {PrincipalSign.defaultLogo ? (
                                    <img
                                      src={PrincipalSign.defaultLogo}
                                      alt="/"
                                      className="bgs-pn-pr__signatures--principal"
                                    />
                                  ) : null}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  {annualResults?.config_boolean_value && (
                    <>
                      <div className="bgs-pn-pr__annual-results">
                        <b>Annual Result's</b>
                        <div className="bgs-pn-pr__annual-results--status">
                          Passed and Promoted
                        </div>
                        <div className="bgs-pn-pr__annual-results--to">
                          <label>To {classLabel}</label>
                          <input type="text" value="" />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="page-break"></div>
                </div>
              </>
            );
          })
        : null}
    </>
  );
};

export default Template;
