import { useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import DeleteModal from "../../../pages/DeleteModal";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import Input from "../../../stories/Input/Input";
import { Title } from "../../../stories/Title/Title";
import { TableHeaderProps } from "../../../Types/Tables";
import { CURRENT_PAGE, FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../utils/constants";
import { Direction, Operation, SortBy } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import useAcctLedgerData, { FeeLedgerEdge } from "../hooks/useAcctLedgerData";
import { DeleteAcctLdgrById } from "../queries/Accountingledgers/mutations/Index";
import { GetAcctLdgrs } from "../queries/FeeLedgers/query/Byid";
import DeleteIcon from "../../../images/Delete.svg";
import { AcctLedgerQueryType } from "../common/QueryTypes";
import { Button } from "../../../stories/Button/Button";
import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";
import useAcctTableJson from "../json/useAcctTableJson";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const Delete = ({ setModal }: Props) => {
  const { InstId } = useParams();
  const { Accounts_Table } = useAcctTableJson();
  const [searchData, setSearchData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [acctId, setAcctId] = useState(0);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [ledgers, setLedgers] = useState<FeeLedgerEdge[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const { user_details } = useLoggedInUserDetails();
  const { acctLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGR_USER_DEFINED,
    ROWS_PER_PAGE
  );
  const { data, fetchMore, loading } = acctLedgers;
  const { token } = useToken();

  const [DeleteLedger, { loading: Deleteloading }] = useMutation(
    DeleteAcctLdgrById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const totalCount = acctLedgers.data?.GetAcctLdgrs?.totalCount;
  const indexOfLastrepo = CURRENT_PAGE * totalCount!;
  const indexOfFirstrepo = indexOfLastrepo - totalCount!;
  const currentRepo = acctLedgers.data?.GetAcctLdgrs.edges.slice(
    indexOfFirstrepo,
    indexOfLastrepo
  );

  const HandleDelete = (id: number) => {
    DeleteLedger({
      variables: {
        token,
        id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctLdgrs,
          variables: {
            token,
            after: null,
            input: {
              acct_ldgr_query_type: AcctLedgerQueryType.ACCT_LDGRS_BY_INST_ID,
              ids: [Number(InstId)],
            },
            direction: Direction.ASC,
            first: ROWS_PER_PAGE,
            name: searchData,
            sortBy: SortBy.LDGR_DESC,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Accounting Ledger Deleted Sucessfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setAcctId(0);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop + 1;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctLdgrs.edges;
            const pageInfo = fetchMoreResult.GetAcctLdgrs.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctLdgrs.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctLdgrs: {
                edges: [...ledgers, ...newEdges],
                pageInfo,
                totalCount: data?.GetAcctLdgrs.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  useEffect(() => {
    if (data && !loading && token) {
      const newData = data.GetAcctLdgrs.edges;

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newVoucher) => {
          const filteredStudent = ledgers.find(
            (voucher) => voucher.node.id === newVoucher.node.id
          );
          if (filteredStudent) {
            return {
              ...newVoucher,
              node: {
                ...newVoucher.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newVoucher;
        });
        setLedgers(updatedNewData);
      } else {
        setLedgers(newData);
      }
      setEndCursor(data.GetAcctLdgrs.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, token]);
  return (
    <>
      <Title>Delete Account Ledger</Title>
      <div className="delete-account-ledger">
        <div className="row g-0">
          <div className="col-3">
            <Input
              onChange={(e) => setSearchData(e.target.value)}
              placeholder="Search Acct Ledgers..."
              id="search"
              autoFocus
            />
          </div>
        </div>
        <div className="delete-account-ledger__tableblock">
          {!currentRepo?.length && !acctLedgers.loading ? (
            <b className="nodata">Sorry No Results</b>
          ) : (
            <TableContainer
              className="delete-account-ledger__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Accounts_Table.AccountLedger.Delete.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ledgers.map((response, index: number) => (
                    <TableRow key={response.node.id}>
                      <TableCell
                        id="td-center"
                        className="delete-account-ledger__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        {response.node.ldgr_desc +
                          ` (${response.node.acct_group_ldgr_details.gr_ldgr_desc}) `}
                      </TableCell>

                      <TableCell
                        id="td-center"
                        className="delete-account-ledger__table--actions"
                      >
                        <img
                          src={DeleteIcon}
                          onClick={() => {
                            setAcctId(response.node.id);
                            setDeleteModal(!deleteModal);
                          }}
                          alt="/"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            <Button mode="cancel" onClick={() => setModal(false)} />
          </div>
          <div className="col eduate-account-ledger__total">
            <div className="student-total-count">
              Total Ledgers :&nbsp;<b>{data?.GetAcctLdgrs.totalCount!}</b>
            </div>
          </div>
        </div>
      </div>

      <LoadingModal flag={Deleteloading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={acctId}
      />
    </>
  );
};

export default Delete;
