import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { optionsType } from "../../../utils/Form.types";
import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import { PredefinedDataTypes } from "../../../utils/Enum.types";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import { isOptionEqualToValue } from "../../../utils/UtilFunctions";
interface Props {
  type: PredefinedDataTypes;
  value: string;
  onChange: (newValue: optionsType | null, type: PredefinedDataTypes) => void;
  label: string;
  disabled?: boolean;
}
const PredefinedAutocomplete = ({
  type,
  value,
  onChange,
  label,
  disabled,
}: Props) => {
  const [searchData, setSearchData] = useState(value);

  const [autoValue, setAutoValue] = useState<optionsType | null>(null);

  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();

  const withoutLabelClass = formAutoCompleteStyles();
  const withoutLabelTextClass = formAutoCompleteTextStyles();

  const {
    PredefinedData: { dropDown, data, handleScroll },
  } = usePredefinedDataByType(type, searchData);

  useEffect(() => {
    if (searchData && value) {
      const foundValue = dropDown.find(
        ({ value: optionsValue }) => optionsValue === value
      );

      setAutoValue(foundValue ? foundValue : null);
    }
  }, [searchData, value, data, dropDown]);

  const commonAutocompleteProps = {
    classes: label ? classes : withoutLabelClass,
    options: dropDown,
    openOnFocus: true,
    ListboxProps: {
      onScroll: handleScroll,
    },
    disabled: disabled,
    onChange: (
      e: React.SyntheticEvent<Element, Event>,
      newValue: optionsType | null
    ) => onChange(newValue, type),
  };

  const commonTextFieldProps = {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
      setSearchData(e.target.value),
    label: label || "",
    fullWidth: true,
    InputLabelProps: { shrink: label.length > 0 },
    classes: {
      root: label
        ? textClasses.formControlRoot
        : withoutLabelTextClass.formControlRoot,
    },
  };

  return (
    <Autocomplete
      {...commonAutocompleteProps}
      {...(value ? { value: autoValue } : {})} // Conditional value
      isOptionEqualToValue={(option) => isOptionEqualToValue(option, autoValue)}
      renderInput={(params) => (
        <TextField {...params} {...commonTextFieldProps} />
      )}
    />
  );
};

export default PredefinedAutocomplete;
