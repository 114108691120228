import React, { useEffect, useState } from "react";
// import { Calendar } from "react-calendar";
import { TextField } from "@mui/material";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import { Label } from "../../../stories/Label/Label";
import { Title } from "../../../stories/Title/Title";
import Home from "../Home/Index";
import Delete from "../../../images/Delete.svg";
import {
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddSwGeneralHolidays,
  DeleteSwGeneralHolidaysById,
  UpdateSwGeneralHolidays,
} from "../../../queries/Eduate/mutations/new";
import useToken from "../../../customhooks/useToken";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import { datesFormType, holidayDesc, msgType } from "../../../utils/Form.types";
import { ROWS_PER_PAGE, days } from "../../../utils/constants";
import {
  GetSwGeneralHolidaysData,
  GetSwGeneralHolidaysVars,
} from "../../../customhooks/general/usePredefinedHolidays";
import { GetSwGeneralHolidays } from "../../../queries/Eduate/list";
import {
  Direction,
  LicenseTypes,
  Operation,
  SortBy,
} from "../../../utils/Enum.types";
import Modal from "react-modal";
import { EditModalCustomStyles } from "../../../styles/ModalStyles";
import Close from "../../../images/Close.svg";
import { Formik, Form } from "formik";
import { eduateHolidayValidation } from "../../../utils/payrole";
import { Keys } from "../../../utils/Enum.keys";
import DeleteModal from "../../../pages/DeleteModal";
import ArrowLeft from "../../../images/CalenderPreviousButton.svg";
import ArrowRight from "../../../images/CalenderNextButton.svg";
import CalendarLegends, { CalendarFor } from "../../../pages/CalendarLegends";
import { colors } from "../../Academics/calendar/HolidayEntry/CustomizedCalendar";
import TextArea from "../../../stories/TextArea/TextArea";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
interface IItems {
  date: string;
  holidayDesc: string;
  id: number;
}

export interface formModalType {
  flag: boolean;
  operation: Operation;
}
const Index = () => {
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const [holiday_date, setholiday_date] = useState("");
  const [holidayName, setHolidayName] = useState("");
  const [holidayId, setHolidayId] = useState(0);
  const [holidayFormModal, setHolidayFormModal] = useState<formModalType>();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

  const [formData, setFormData] = useState<holidayDesc>({
    holiday_desc: "",
  });
  const [queryInput, setQueryInput] = useState<datesFormType>({
    start_date: "",
    end_date: "",
    in_between_flag: false,
  });
  const [rowsPerPage] = useState(ROWS_PER_PAGE);
  const [items, setItems] = useState<IItems[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [GetHolidaysData, { data, loading }] = useLazyQuery<
    GetSwGeneralHolidaysData,
    GetSwGeneralHolidaysVars
  >(GetSwGeneralHolidays, {
    variables: {
      after: null,
      direction: Direction.ASC,
      first: rowsPerPage,
      sortBy: SortBy.HOLIDAY_DATE,
      token,
      input: {
        start_date: queryInput.start_date
          ? toIsoDate(queryInput.start_date)
          : null,
        end_date: queryInput.end_date ? toIsoDate(queryInput.end_date) : null,
        in_between_flag: queryInput.in_between_flag,
      },
      holidayName,
    },
  });
  const [UpdateHoliday, { loading: updationLoading }] = useMutation(
    UpdateSwGeneralHolidays,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteHoliday, { loading: deletionLoading }] = useMutation(
    DeleteSwGeneralHolidaysById,
    {
      onError: (e) => {
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        });
        setDeleteConfirmModal(!deleteConfirmModal);
      },
    }
  );
  const [AddHolidays, { loading: creationLoading }] = useMutation(
    AddSwGeneralHolidays,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleHolidays = () => {
    if (holidayFormModal?.operation === Operation.CREATE)
      AddHolidays({
        variables: {
          token,
          input: {
            holiday_desc: formData.holiday_desc,
            holiday_date: toIsoDate(holiday_date),
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetSwGeneralHolidays,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: rowsPerPage,
              sortBy: SortBy.HOLIDAY_DATE,
              input: {
                start_date: queryInput.start_date
                  ? toIsoDate(queryInput.start_date)
                  : null,
                end_date: queryInput.end_date
                  ? toIsoDate(queryInput.end_date)
                  : null,
                in_between_flag: queryInput.in_between_flag,
              },
              token,
              holidayName,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setHolidayFormModal({
            flag: false,
            operation: Operation.NONE,
          });
          setMessage({
            flag: true,
            message: "Added Holidays",
            operation: Operation.CREATE,
          });
        }
      });
    if (holidayFormModal?.operation === Operation.UPDATE)
      UpdateHoliday({
        variables: {
          token,
          id: holidayId,
          input: {
            holiday_desc: formData.holiday_desc,
            holiday_date: toIsoDate(holiday_date),
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetSwGeneralHolidays,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: rowsPerPage,
              input: {
                start_date: queryInput.start_date
                  ? toIsoDate(queryInput.start_date)
                  : null,
                end_date: queryInput.end_date
                  ? toIsoDate(queryInput.end_date)
                  : null,
                in_between_flag: queryInput.in_between_flag,
              },
              sortBy: SortBy.HOLIDAY_DATE,
              token,
              holidayName,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setHolidayFormModal({
            flag: false,
            operation: Operation.NONE,
          });
          setMessage({
            flag: true,
            message: "Updated Holiday",
            operation: Operation.UPDATE,
          });
        }
      });
  };
  const handleDelete = (id: number) => {
    DeleteHoliday({
      variables: {
        token,
        id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetSwGeneralHolidays,
          variables: {
            after: null,
            direction: Direction.ASC,
            first: rowsPerPage,
            input: {
              start_date: queryInput.start_date
                ? toIsoDate(queryInput.start_date)
                : null,
              end_date: queryInput.end_date
                ? toIsoDate(queryInput.end_date)
                : null,
              in_between_flag: queryInput.in_between_flag,
            },
            sortBy: SortBy.HOLIDAY_DATE,
            token,
            holidayName,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Added Holidays",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setItems([]);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (token) {
      GetHolidaysData();
    }
  }, [token, GetHolidaysData]);
  return (
    <>
      <Home
        DashBoardRequired={false}
        NavType={LicenseTypes.EDUATE_CONFIGURATION}
      />
      <Title>Calendar Events</Title>
      <div className="eduate-calendar-events">
        <div className="row g-0 eduate-calendar-events__row">
          <div className="col h-100">
            <div className="eduate-calendar-events__calendar">
              <div className="eduate-calendar-events__calendar--month">
                <img src={ArrowLeft} alt="" />
                <b>September</b>
                <img src={ArrowRight} alt="" />
              </div>
              <ul className="eduate-calendar-events__calendar--grid-container">
                {days.map((day, index) => {
                  return (
                    <li
                      key={index}
                      className="eduate-calendar-events__calendar--day"
                    >
                      {day}
                    </li>
                  );
                })}
                {Array.from({ length: 31 }).map((_, index) => (
                  // for weekends use className weekends-in-calendar ,for holidays use className holidays-in-calendar,use present-day-in-calendar for present day
                  <li
                    key={index}
                    className={
                      index === 2
                        ? "eduate-calendar-events__calendar--date holidays-in-calendar"
                        : "eduate-calendar-events__calendar--date"
                    }
                  >
                    {/* enable this span for vacations */}
                    {index === 4 ? (
                      <span className="vacations-in-calendar"></span>
                    ) : null}
                    {/* use this for events */}

                    {index + 1}
                    {index === 6 && (
                      <>
                        {Array.from({ length: 5 }).map((_, dotIndex) => {
                          return (
                            <span
                              className="events-in-calendar"
                              style={{
                                backgroundColor:
                                  dotIndex >= colors.length
                                    ? "#0ea5e9"
                                    : colors[dotIndex],
                              }}
                              key={dotIndex}
                            />
                          );
                        })}
                      </>
                    )}
                  </li>
                ))}

                {/* <Calendar
                  onChange={(e: Date) => {
                    const rws = e.toISOString();
                    setholiday_date(toInputStandardDate(rws));
                    setHolidayFormModal({
                      flag: true,
                      operation: Operation.CREATE,
                    });
                  }}
                /> */}
              </ul>
            </div>
          </div>
          <div className="col account-frames h-100">
            <Title variant="subtitle1">List of Holidays</Title>
            <div className="row g-0 eduate-calendar-events__details">
              <div className="col-3">
                <Input
                  id="search"
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => setHolidayName(e.target.value)}
                />
              </div>
              <div className="col">
                <TextField
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={queryInput.start_date}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setQueryInput((prevValue) => ({
                      ...prevValue,
                      in_between_flag: true,
                      start_date: e.target.value,
                    }))
                  }
                  label="Start Date"
                  className="eduate-calendar-events__textfield--date"
                />
                <TextField
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={queryInput.end_date}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setQueryInput((prevValue) => ({
                      ...prevValue,
                      end_date: e.target.value,
                    }))
                  }
                  label="End Date"
                  className="eduate-calendar-events__textfield--date"
                />
              </div>
            </div>
            <ul className="eduate-calendar-events__holidays-list">
              {data?.GetSwGeneralHolidays.edges.map((edge, index) => {
                return (
                  <li key={index}>
                    <Label>{index + 1}</Label>
                    <div
                      className="calendar-events__data--list--events"
                      onClick={() => {
                        setFormData({
                          holiday_desc: edge.node.holiday_desc,
                        });
                        setholiday_date(
                          toInputStandardDate(edge.node.holiday_date)
                        );
                        setHolidayId(Number(edge.node.id));
                        setHolidayFormModal({
                          flag: true,
                          operation: Operation.UPDATE,
                        });
                      }}
                    >
                      <div>
                        <span>{toStandardDate(edge.node.holiday_date)}</span>
                        <br />
                        <b>{edge.node.holiday_desc}</b>
                      </div>
                    </div>
                    <img
                      src={Delete}
                      alt="/"
                      onClick={() => {
                        setHolidayId(edge.node.id);
                        setDeleteConfirmModal(!deleteConfirmModal);
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              mode="save"
              onClick={handleHolidays}
              disabled={!items.length}
            />
            <Button mode="clear" />
            <Button mode="back" />
          </div>
          <div className="col">
            <CalendarLegends useCalendarIn={CalendarFor.GENERAL} />
          </div>
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={holidayFormModal?.flag!}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="row">
          <div className="col">
            <div className="modal-flex__image">
              <img
                src={Close}
                alt="/"
                onClick={() =>
                  setHolidayFormModal({
                    flag: false,
                    operation: Operation.NONE,
                  })
                }
              />
            </div>
            <div className="col-6">
              <Formik
                initialValues={formData}
                validationSchema={eduateHolidayValidation}
                onSubmit={handleHolidays}
                enableReinitialize
              >
                {(meta) => {
                  return (
                    <Form className="masterconfig">
                      <Title>
                        {holidayFormModal?.operation === Operation.CREATE
                          ? "Create "
                          : "Update "}
                        Holiday
                      </Title>
                      <div className="masterconfig__buttons">
                        <div className="label-grid">
                          <Label>Holiday Description</Label>
                          <TextArea
                            value={formData.holiday_desc}
                            onChange={(e) => {
                              meta.handleChange(e);
                              setFormData((prevValue) => ({
                                ...prevValue,
                                holiday_desc: e.target.value,
                              }));
                            }}
                            placeholder={meta.errors.holiday_desc}
                            onKeyDown={(e) => {
                              if (e.shiftKey && e.key === Keys.ENTER) {
                                setFormData((prevValue) => ({
                                  ...prevValue,
                                  holiday_desc: formData.holiday_desc + "\n",
                                }));
                              }
                            }}
                            rows={3}
                            autoFocus
                          />

                          <Label>Holiday Date</Label>
                          <TextField
                            type="date"
                            value={toInputStandardDate(holiday_date)}
                            disabled
                            className="institution-calendar-events__textfield--date"
                          />
                        </div>

                        <Button mode="save" type="submit" />
                        <Button
                          mode="cancel"
                          type="button"
                          onClick={() => {
                            setHolidayFormModal({
                              flag: false,
                              operation: Operation.NONE,
                            });
                          }}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>

      <LoadingModal
        flag={loading ?? creationLoading ?? updationLoading ?? deletionLoading}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <DeleteModal
        handleDelete={handleDelete}
        id={holidayId}
        modalFlag={deleteConfirmModal}
        setModalFlag={setDeleteConfirmModal}
      />
    </>
  );
};

export default Index;
