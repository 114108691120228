import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";

import { ActiveAcademicYearData } from "../../../../Types/Accounting";
import { EMPTY_STRING, TODAY_DATE } from "../../../../utils/constants";
import { Operation } from "../../../../utils/Enum.types";
import { msgType, responseType } from "../../../../utils/Form.types";
import {
  getDateRangeByMonth,
  getOrdinalSuffix,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../../hooks/useAcdGeneralHolidays";
import {
  GetAcdInstGeneralHolidayConfiguredWeekends,
  GetAcdInstGeneralHolidays,
  GetAcdInstHolidayConfig,
} from "../../queries/holidays/list";
import { DeleteAcdInstGeneralHolidaysAndConfig } from "../../queries/holidays/mutation";
import {
  GetAcdInstGeneralHolidayConfiguredWeekendData,
  GetAcdInstGeneralHolidayConfiguredWeekendsDetails,
  GetAcdInstGeneralHolidayConfiguredWeekendsVars,
  GetAcdInstHolidayConfigData,
  GetAcdInstMonthlyWorkingCalendarVars,
} from "../../types/attendance";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";

interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  acd_yr: ActiveAcademicYearData;
}

const Delete = ({ setModalFlag, acd_yr }: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [selectedHolidayConfig, setSelectedHolidayConfig] =
    useState<responseType | null>(null);
  const [holidayConfig, setHolidayConfig] = useState<responseType[]>([]);
  const [dates, setDates] = useState<
    GetAcdInstGeneralHolidayConfiguredWeekendsDetails[]
  >([]);
  const { endDate, startDate } = getDateRangeByMonth(
    new Date(TODAY_DATE).getFullYear(),
    new Date(TODAY_DATE).getMonth()
  );
  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    acd_yr.id,
    endDate.toString(),
    startDate.toString(),
    EMPTY_STRING,
    holiday_query_type.WEEKEND_HOLIDAYS
  );
  const [GetHolidayConfig, { data }] = useLazyQuery<
    GetAcdInstHolidayConfigData,
    GetAcdInstMonthlyWorkingCalendarVars
  >(GetAcdInstHolidayConfig, {
    variables: {
      token,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      inst_id: InstId!,
    },
  });

  const { day_idx, week_idx } =
    data?.GetAcdInstHolidayConfig.find(
      (config) => config.id === selectedHolidayConfig?.value
    ) || {};

  const [GetDatesByHolidayConfig] = useLazyQuery<
    GetAcdInstGeneralHolidayConfiguredWeekendData,
    GetAcdInstGeneralHolidayConfiguredWeekendsVars
  >(GetAcdInstGeneralHolidayConfiguredWeekends, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,

      inst_id: InstId!,
      token,
      day_idx: day_idx!,
      week_idx: week_idx!,
    },
  });

  const [DeleteHolidays, { loading }] = useMutation(
    DeleteAcdInstGeneralHolidaysAndConfig,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  // const filterdData
  const handleDelete = () => {
    DeleteHolidays({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: acd_yr.id,
        holiday_ids: dates.map((date) => date.id),
        input: {
          week_day: "",
          day_idx,
          week_idx,
        },
      },
      refetchQueries: [
        {
          query: GetAcdInstGeneralHolidays,
          variables: {
            acd_yr_id: acd_yr?.id,
            input: {
              end_date: toIsoDate(acd_yr.acd_end_date),
              in_between_flag: false,
              start_date: toIsoDate(acd_yr.acd_st_date),
              holiday_query_type: holiday_query_type.ALL_HOLIDAYS,
            },
            inst_id: InstId,
            token,
            holidayDesc: EMPTY_STRING,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Holidays Deleted Successfully",
          operation: Operation.DELETE,
        });
      }
    });
  };

  const handleClear = () => {
    setDates([]);
    setSelectedHolidayConfig(null);
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setModalFlag(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (acd_yr.id) {
      GetHolidayConfig().then(({ data }) => {
        if (data) {
          setHolidayConfig(
            data.GetAcdInstHolidayConfig.map((config) => ({
              label:
                config.week_idx +
                getOrdinalSuffix(config.week_idx) +
                config.week_day,
              value: config.id,
            }))
          );
        }
      });
    }
  }, [GetHolidayConfig, acd_yr, data]);

  useEffect(() => {
    if (day_idx || week_idx) {
      GetDatesByHolidayConfig().then(({ data }) => {
        if (data) {
          setDates(data.GetAcdInstGeneralHolidayConfiguredWeekends);
        }
      });
    }
  }, [day_idx, week_idx, GetDatesByHolidayConfig]);

  return (
    <>
      <Title>Delete Configured Weekends</Title>
      <div className="holiday-entry__new">
        <div className="row g-0 holiday-entry__new--details">
          <div className="col holiday-entry__new--block">
            <Title variant="subtitle1">Selected Weekends</Title>
            <div className="holiday-entry__new--block--list">
              <Autocomplete
                classes={classes}
                options={holidayConfig}
                getOptionLabel={(option) => option.label}
                value={selectedHolidayConfig}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSelectedHolidayConfig(newValue);
                  } else {
                    setSelectedHolidayConfig(null);
                    setDates([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Weekends"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
              {/* <TableContainer className="holiday-entry__new--block--table">
                <Table stickyHeader>
                  {Object.entries(holidayConfig).map(
                    ([key, dates]: [
                      string,
                      GetAcdInstHolidayConfigDetails[]
                    ]) => {
                      return (
                        <React.Fragment>
                          <TableHead>
                            <TableRow>
                              <TableCell>{key}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dates.map((date) => {
                              return (
                                <TableRow>
                                  <TableCell id="td-center">
                                    <Checkbox
                                      checked={date.week_day_status}
                                      disabled={
                                        dates.filter(
                                          (date) => !date.week_day_status
                                        ).length === 1
                                      }
                                      onClick={() => {
                                        setweek_day_idx(date.day_idx);
                                        setweek_idx(date.week_idx);
                                        setWeekDayName(date.week_day);
                                        handleChange(date.id);
                                      }}
                                    />
                                    {date.week_idx}
                                    {getOrdinalSuffix(date.week_idx)}{" "}
                                    {date.week_day}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </React.Fragment>
                      );
                    }
                  )}
                </Table>
              </TableContainer> */}
            </div>
          </div>
          <div className="col holiday-entry__new--block">
            <Title variant="subtitle1">
              Preview List of Dates to be Deleted
            </Title>
            <div className="holiday-entry__new--block--list">
              <TableContainer className="holiday-entry__new--block--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl</TableCell>
                      <TableCell>Holiday Dates</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dates.map((date, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell id="td-center">{index + 1}</TableCell>
                          <TableCell id="td-center">
                            {toStandardDate(date.holiday_date)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <div className="col holiday-entry__new--block">
            <Title variant="subtitle1">List of Weekend Dates</Title>
            <div className="holiday-entry__new--block--list">
              <ul className="holiday-entry__new--block--holiday-list">
                {InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.map(
                  (edge, index) => {
                    return (
                      <li key={index}>
                        <Label>{index + 1}</Label>
                        <div className="holiday-entry__data--holiday-list--events">
                          <div>
                            <span>{edge.node.holiday_desc}</span>
                            <br />
                            <b>{toInputStandardDate(edge.node.holiday_date)}</b>
                          </div>
                        </div>
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="holiday-entry__new--buttons">
          <Button
            mode="delete"
            onClick={handleDelete}
            disabled={!dates.length}
          />
          <Button mode="clear" onClick={handleClear} />
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        </div>
      </div>
      <LoadingModal flag={loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Delete;
