import React, { useState } from "react";
import AddCampus from "./New";
import { Operation } from "../../../utils/Enum.types";
import { Drawer } from "@mui/material";
import { campusEntryStyles } from "../../../styles/DrawerStyles";

interface Props {
  operation: Operation;
  setCampusModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setCampusUpdateModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Index = ({ operation, setCampusModal, setCampusUpdateModal }: Props) => {
  const [primaryInstModal, setPrimaryInstModal] = useState(false);
  const drawerClasses = campusEntryStyles();
  return (
    <>
      <AddCampus
        operation={operation}
        setPrimaryInstModal={setPrimaryInstModal}
        setCampusModal={setCampusModal}
        setCampusUpdateModal={setCampusUpdateModal}
      />

      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={primaryInstModal}
        onClose={() => setPrimaryInstModal(!primaryInstModal)}
      >
        <AddCampus
          operation={Operation.PRIMARY}
          setPrimaryInstModal={setPrimaryInstModal}
        />
      </Drawer>
    </>
  );
};

export default Index;
