import { useContext, useEffect, useState } from "react";
import Avatar from "../../../images/Avatar.svg";
import { Autocomplete, TextField } from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import { Button } from "../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";

import {
  EMPTY_STRING,
  transactionStatusOptions,
} from "../../../utils/constants";
import StudentAutocomplete from "../Components/StudentAutocomplete";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import { toStandardDate } from "../../../utils/UtilFunctions";
import { optionsType } from "../../../utils/Form.types";
import { AppContext } from "../../../context/context";
import TransactionTable from "./TransactionTable";
import { payloadTypes } from "../../../context/reducer";
import { AcctOnlinePaymentQueryType } from "../../../utils/Enum.types";
import { getDownloadUrl } from "../../../utils/DownloadFile";
import useInstDetails from "../../../customhooks/general/useInstDetails";
export const tableJson = [
  {
    t_date: "10-10-2023",

    uref_no: "231457856",
    ref_no: "3ghftrdgko90",
    mid: "32568",
    s_mid: "0874",
    p_mode: "Net Bancking",
    amount: 10000,
    code: "E000",
    message:
      "Received successful confirmation in real time for the transaction. Settlement process isinitiated for the transaction",
    v_no: "Vou123",
    status: "Success",
  },
  {
    t_date: "10-10-2023",

    uref_no: "231457856",
    ref_no: "3ghftrdgko90",
    mid: "32568",
    s_mid: "9087",
    p_mode: "Net Bancking",
    amount: 10000,
    code: "E001",

    message: "Transaction Failed",
    v_no: "Vou123",
    status: "Failed",
  },
];

interface QueryOptionsType extends optionsType {
  generalValue: string;
}
export const All: QueryOptionsType = {
  label: "All",
  value: AcctOnlinePaymentQueryType.ON_PY_RECORDS,
  generalValue: AcctOnlinePaymentQueryType.ON_PY_RECORDS,
};

const Index = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { branchLabel, classLabel,categoryLabel } = useInstLabels();
  const { state } = useContext(AppContext);
  const navigate = useNavigate();

  const { InstDetails } = useInstDetails(1);
  const { studentId } = useParams();

  // const [selectedStudent, setSelectedStudent] = useState<>(null);
  const { dispatch } = useContext(AppContext);

  const [imageString, setImageString] = useState("");

  const [queryType, setQueryType] = useState<optionsType>({
    ...All,
    value: All.generalValue,
  });
  const [transactionDate, setTransactionDate] = useState("");
  const [searchAdmNo, setSearchAdmNo] = useState("");

  const { studentFormData, studentData } = useStudentDatabyId();
  const [statusCode, setStatusCode] = useState("");
  const [searchString, setSearchString] = useState("");
  const [count, setCount] = useState(0);

  const handleClear = () => {
    dispatch({ type: payloadTypes.SET_STUDENT_ID, payload: { studentId: 0 } });

    setSearchString("");
    setQueryType(All);
    setStatusCode("");
    setTransactionDate("");
    setImageString("");
  };
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== EMPTY_STRING &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${
        InstDetails.data?.nodes[0]?.inst_name
      }/students/${
        state.studentId ? state.studentId : studentId
      }/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Online Transaction Details</Title>
      <div className="online-transaction">
        <div className="online-transaction__details row g-0">
          <div className="col">
            <StudentAutocomplete
              handleClear={handleClear}
              searchAdmNo={searchAdmNo}
              setSearchAdmNo={setSearchAdmNo}
            />
            <TextField
              className="online-transaction__details--textfield"
              label="Reg No."
              value={studentFormData.reg_number}
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <TextField
              className="online-transaction__details--textfield"
              label="Date Of Birth"
              value={
                studentFormData.std_dob
                  ? toStandardDate(studentFormData.std_dob)
                  : EMPTY_STRING
              }
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              className="online-transaction__details--textfield"
              label="Full Name"
              value={studentFormData.std_name}
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <TextField
              className="online-transaction__details--textfield"
              label="Father Name"
              value={studentFormData.father_name}
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <TextField
              className="online-transaction__details--textfield"
              label={branchLabel}
              value={studentFormData.branch}
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              className="online-transaction__details--textfield"
              label={classLabel}
              value={studentFormData.class}
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <TextField
              className="online-transaction__details--textfield"
               label={categoryLabel}
              InputLabelProps={{ shrink: true }}
              value={studentFormData.category}
              disabled
            />
            <TextField
              className="online-transaction__details--textfield"
              label="Academic Year"
              InputLabelProps={{ shrink: true }}
              value={studentFormData.acd_yr}
              disabled
            />
          </div>
          <div className="col-1 online-transaction__details--image">
            <img src={imageString ? imageString : Avatar} alt="" />
          </div>
        </div>
        <div className="online-transaction__filters row g-0">
          <div className="col-2">
            <TextField
              className="online-transaction__details--textfield"
              label="Unique Reference No / Voucher No"
              value={searchString}
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={transactionStatusOptions}
              openOnFocus
              forcePopupIcon
              disableClearable
              onChange={(e, newValue) => {
                if (newValue) {
                  setQueryType({
                    label: newValue.label,
                    value: newValue.value,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  classes={{ root: textClasses.formControlRoot }}
                  label="Transaction Status"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-2">
            <TextField
              className="online-transaction__filters--textfield"
              label="Date"
              type="date"
              onChange={(e) => {
                setTransactionDate(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        <TransactionTable
          showLogDataBtn={false}
          queryType={queryType}
          statusCode={statusCode}
          transactionDateContainsFold={transactionDate}
          searchString={searchString}
          setCount={setCount}
        />
        <div className="row g-0">
          <div className="col">
            <Button mode="clear" onClick={handleClear} />
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-4">
            <div className="online-transaction__total-count">
              Total Transactions Done : <b>{count}</b>
            </div>
          </div>
        </div>
      </div>

      {/* print-modal */}
    </>
  );
};

export default Index;
