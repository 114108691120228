import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";
import Modal from "react-modal";
import Close from "../../../../images/Close.svg";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { TableHeaderProps } from "../../../../Types/Tables";
import Home from "../../Home/Index";
import Edit from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { msgType, responseType } from "../../../../utils/Form.types";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { Keys } from "../../../../utils/Enum.keys";
import {
  getModifiedScrollHeight,
  handleFormEvent,
} from "../../../../utils/UtilFunctions";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
  StudentReportType,
  StudentSearchField,
  TransportQueryType,
} from "../../../../utils/Enum.types";
import useAcademicYear from "../../../Academics/hooks/useActiveAcademicYear";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DeleteTransportStudent } from "../../queries/mutations";
import DeleteModal from "../../../../pages/DeleteModal";
import MessageModal from "../../../../pages/MessageModal";
import useToken from "../../../../customhooks/useToken";

import TransportFeeReceipt from "../../../Accounts/StudentDetails/FeeReceipt/TransportStudentReceipt";
import {
  GetStudentTransportByData,
  GetTransportStudentByVars,
} from "../../paginationTypes";
import { GetTransportStd, GetTransportStudent } from "../../queries/list";
import useTransStudentData, {
  StudentEdges,
} from "../../../Academics/hooks/useTransStudentData";
import useDropdownData from "../../../../customhooks/useDropdownData";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useTransportTableJson from "../../json/useTransportTableJson";

interface Props {
  pageType: PageFor;
  setStudentModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const List = ({ pageType, setStudentModal }: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { Transport_Table } = useTransportTableJson();
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const { activeAcademicYearData } = useAcademicYear();

  const [departmentSelected, setDepartmentSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [branchSelected, setBranchSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [classSelected, setClassSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [semesterSelected, setSemesterSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [sectionSelected, setSectionSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [searchData, setSearchData] = useState("");
  const [searchAdm, setSearchAdm] = useState("");
  const [searchType, setSearchType] = useState("");
  const [feeModal, setFeeModal] = useState(false);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [stdId, setStdId] = useState(0);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  //refs
  const departmentRef = useRef<HTMLSelectElement>(null);
  const departmentInputRef = departmentRef?.current?.childNodes[0]
    ?.childNodes[1]?.childNodes[0] as HTMLInputElement;
  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInputRef = branchRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const semRef = useRef<HTMLSelectElement>(null);
  const semInputRef = semRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const sectionRef = useRef<HTMLSelectElement>(null);
  const sectionInputRef = sectionRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  const { TransportStudentData } = useTransStudentData(
    departmentSelected.value,
    branchSelected.value,
    classSelected.value,
    semesterSelected.value,
    sectionSelected.value,
    ROWS_PER_PAGE,
    searchData,
    StudentReportType.TRANSPORT_ASSIGNED
  );
  const [GetTransportStudentData] = useLazyQuery<
    GetStudentTransportByData,
    GetTransportStudentByVars
  >(GetTransportStudent, {
    variables: {
      token,
      student_id: state.studentId,
    },
  });
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected.value,
    branchSelected.value,
    classSelected.value,
    semesterSelected.value
  );

  const [DeleteStudentRoute] = useMutation(DeleteTransportStudent, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const SearchFields = [
    StudentSearchField.ADMISSION_NUMBER,
    StudentSearchField.NAME,
  ];
  const clear = () => {
    setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
    setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
    setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
    setSearchAdm("");
    setSearchData("");
    setSearchType("");
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !TransportStudentData?.loading) {
        TransportStudentData?.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetTransportStd.edges;
            const pageInfo = fetchMoreResult.GetTransportStd.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetTransportStd.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetTransportStd: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount:
                  TransportStudentData?.data?.GetTransportStd.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const HandleDelete = (id: Number) => {
    DeleteStudentRoute({
      variables: {
        token,
        student_id: id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetTransportStd,
          variables: {
            token,
            name: EMPTY_STRING,
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId?.id!,
            first: ROWS_PER_PAGE,
            sortBy: SortBy.STUDENT_ID,
            direction: Direction.ASC,
            after: null,
            input: {
              TransQueryType: TransportQueryType.ROUTE_ASSIGNED_BY_INST_ID,
              ids: InstId,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully Deleted Student Associated Route",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };
  const handleBack = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (state.studentId) {
      GetTransportStudentData({
        variables: {
          token,
          student_id: state.studentId,
        },
      });
    }
  }, [GetTransportStudentData, state.studentId, token]);
  useEffect(() => {
    if (TransportStudentData?.data && !TransportStudentData?.loading) {
      const newData = TransportStudentData?.data.GetTransportStd.edges.map(
        (edge) => ({
          ...edge,
          node: {
            ...edge.node,
            isChecked: true,
          },
        })
      );

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(
        TransportStudentData?.data.GetTransportStd.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [TransportStudentData?.data, TransportStudentData?.loading]);
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <Title>Student List(Assigned Route)</Title>
      <div className="assigned-route-std-list">
        <div className="row g-0 assigned-route-std-list__select">
          <div className="col-1">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (searchType === StudentSearchField.ADMISSION_NUMBER) {
                  setSearchAdm(e.target.value);
                  setSearchData("");
                } else {
                  setSearchData(e.target.value);
                  setSearchAdm("");
                }
              }}
              value={searchData !== "" ? searchData : searchAdm}
              onKeyDown={handleFormEvent}
            />
          </div>
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={SearchFields}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  departmentInputRef?.focus();
                }
                if (e.key === Keys.BACKSPACE) {
                  setSearchType("");
                }
              }}
              openOnFocus
              value={searchType}
              onChange={(e, newValue) => setSearchType(newValue!)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search By"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={departmentDropDown}
                ref={departmentRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected) {
                      branchInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={branchDropDown}
                ref={branchRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      classInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue);
                  } else {
                    setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={classDropDown}
                ref={classRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      semInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue);
                  } else {
                    setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={semesterDropDown}
                ref={semRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      sectionInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue);
                  } else {
                    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={sectionDropDown}
                ref={sectionRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (sectionSelected) {
                      categoryInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue);
                  } else {
                    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col"></div>
          <div className="col-2 assigned-route-std-list__select--button">
            <Button mode="clear" onClick={clear} />
            {pageType === PageFor.GENERAL ? (
              <Button
                mode="addnew"
                onClick={() =>
                  navigate(
                    `/${InstId}/transport/masterdata/assignroutetostudent`
                  )
                }
              />
            ) : null}
          </div>
        </div>
        <div className="assigned-route-std-list__tableblock">
          <TableContainer
            className="assigned-route-std-list__table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Transport_Table.MasterData.RoutesAssignedStudentList.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {students?.map((data, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        id="td-center"
                        className="assigned-route-std-list__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="assigned-route-std-list__table--admno">
                        {data.node.student_details.std_adm_no}
                      </TableCell>
                      <TableCell className="assigned-route-std-list__table--admno">
                        {data.node.student_details.std_reg_no}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          dispatch({
                            type: payloadTypes.SET_STUDENT_ID,
                            payload: {
                              studentId: data.node.student_id,
                            },
                          });
                          setStudentModal?.(false);
                        }}
                      >
                        {data.node.student_details.first_name +
                          " " +
                          data.node.student_details.middle_name +
                          " " +
                          data.node.student_details.last_name}
                      </TableCell>
                      <TableCell className="assigned-route-std-list__table--desc">
                        {data.node.student_details.category.cat_desc}
                      </TableCell>
                      <TableCell className="assigned-route-std-list__table--desc">
                        {
                          data.node.route_details.route_master_details
                            .route_desc
                        }
                      </TableCell>
                      <TableCell className="assigned-route-std-list__table--desc">
                        {data.node.route_details.route_stage_desc}
                      </TableCell>
                      <TableCell className="assigned-route-std-list__table--desc">
                        {data.node.route_details.acct_ldgr_details.ldgr_desc}
                      </TableCell>
                      <TableCell className="assigned-route-std-list__table--desc">
                        {data.node.route_fee_details.route_fee}
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="assigned-route-std-list__table--actions"
                      >
                        {pageType === PageFor.GENERAL ? (
                          <>
                            <img
                              src={Edit}
                              alt="/"
                              onClick={() => {
                                navigate(
                                  `/${InstId}/transport/masterdata/updateroutetostudent`
                                );
                                dispatch({
                                  type: payloadTypes.SET_STUDENT_ID,
                                  payload: {
                                    studentId: data.node.student_id,
                                  },
                                });
                              }}
                            />
                            <img
                              src={Delete}
                              alt="/"
                              onClick={() => {
                                setDeleteModal(!deleteModal);
                                setStdId(data.node.student_id);
                              }}
                            />
                          </>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="row g-0">
          <div className="col">
            {pageType === PageFor.GENERAL ? (
              <Button mode="back" onClick={() => navigate(-1)} />
            ) : null}
          </div>
          <div className="col-2 parentlist__total">
            <div className="student-total-count">
              Total Students :
              <b>{TransportStudentData?.data?.GetTransportStd.totalCount}</b>
            </div>
          </div>
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={feeModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <TransportFeeReceipt
              pageType={PageFor.MODAL}
              setFeeReceiptModal={setFeeModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                setFeeModal(!feeModal);
              }}
            />
          </div>
        </div>
      </Modal>
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={stdId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default List;
