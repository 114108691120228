import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Avatar from "../../../images/Avatar.svg";

import { Title } from "../../../stories/Title/Title";
import { TableHeaderProps } from "../../../Types/Tables";
import { toInputStandardDate, toIsoDate } from "../../../utils/UtilFunctions";
import { EMPTY_STRING } from "../../../utils/constants";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useEmployeeAttsByMonth from "../hooks/useEmployeeAttsByMonth";
import { PrEmpAttQueryType } from "../enums/Enum.types";

const { HR_Table } = require("../json/table.json");
export interface pr_acd_yr_prop {
  pr_acd_yr_id: number;
}
const AbsenteesList = ({ pr_acd_yr_id }: pr_acd_yr_prop) => {
  const { serverDate } = useServerDateandTime();

  const [date, setDate] = useState(serverDate);

  const { empDetails } = useEmployeeAttsByMonth(
    null,
    PrEmpAttQueryType.ALL_EMP_ABSENTEES,
    serverDate,
    EMPTY_STRING,
    pr_acd_yr_id,
    toIsoDate(date)
  );

  useEffect(() => {
    if (serverDate) {
      setDate(serverDate);
    }
  }, [serverDate]);

  return (
    <div className="emp-attendance-overview__absenties-list">
      <div className="row g-0 emp-attendance-overview__absenties-list--title">
        <div className="col">
          <Title variant="subtitle1">Absenties List</Title>
        </div>

        <div className="col-4">
          <TextField
            value={toInputStandardDate(date)}
            className="emp-attendance-overview__absenties-list--textfield"
            InputLabelProps={{ shrink: true }}
            label="Date"
            type="date"
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
      </div>
      <div className="emp-attendance-overview__absenties-list--tableblock">
        <TableContainer className="emp-attendance-overview__absenties-list--table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {HR_Table.Attendance.AbsenteesList.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {empDetails.data?.GetPayRollEmpAttendance.edges.map(
                (edge, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell id="td-center">
                        {index + 1}&nbsp;
                        <img src={Avatar} alt="/" />
                      </TableCell>
                      <TableCell>{edge.node.emp_details.emp_id}</TableCell>
                      <TableCell>
                        {`${edge.node.emp_details.emp_first_name} ${edge.node.emp_details.emp_middle_name} ${edge.node.emp_details.emp_last_name}`}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AbsenteesList;
