import { useLazyQuery } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GetAcctTransferVoucherDetailsByInstId } from "../../queries/Vouchers/query";
import { Title } from "../../../../stories/Title/Title";
import { transferVoucherDetails } from "../../../../Types/Accounting";
import { TableHeaderProps } from "../../../../Types/Tables";
import { StudentFeeReceiptTitleProps } from "../../../../Types/Titles";
import { toStandardDate } from "../../../../utils/UtilFunctions";

import useToken from "../../../../customhooks/useToken";
import { VoucherBookVars } from "../../hooks/useVoucherNumber";
import { Button } from "../../../../stories/Button/Button";
import StudentTotalCount from "../../../Master/Student/Components/StudentTotalCount";
import useAcctTableJson from "../../json/useAcctTableJson";
import { AppContext } from "../../../../context/context";
const { AccountsTitles } = require("../../json/title.json");
interface Props {
  setStudentModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const ViewInterchangeDetails = ({ setStudentModal }: Props) => {
  const { InstId } = useParams<{ InstId: string }>();

  const { state } = useContext(AppContext);

  const { token } = useToken();
  const { Accounts_Table } = useAcctTableJson();
  const [GetAcctTransferVoucher, { data: transferedVoucherDetails }] =
    useLazyQuery<transferVoucherDetails, VoucherBookVars>(
      GetAcctTransferVoucherDetailsByInstId,
      {
        variables: {
          token,
          inst_id: InstId!,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        },
      }
    );
  useEffect(() => {
    if (token) {
      GetAcctTransferVoucher();
    }
  }, [token, GetAcctTransferVoucher, InstId, state.ActiveFinYr]);
  return (
    <>
      <Title>Student Interchange Receipt</Title>
      <div className="view-interchange-fee-receipt__tableblock">
        <TableContainer className="view-interchange-fee-receipt__table">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className="view-interchange-fee-receipt__table--column-group">
                {Accounts_Table.Receipts.InterChangeReceipt.ViewStudentInterChangeReceipt.ColumnGroup.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell align="center" colSpan={5} key={index}>
                        {th.labelName}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
              <TableRow className="view-interchange-fee-receipt__table--sub-header">
                {Accounts_Table.Receipts.InterChangeReceipt.ViewStudentInterChangeReceipt.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {transferedVoucherDetails?.GetAcctTransferVoucherDetailsByInstId.map(
                (data, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        id="td-center"
                        className="view-interchange-fee-receipt__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="view-interchange-fee-receipt__table--date">
                        {toStandardDate(data.voucher_master_details.v_date)}
                      </TableCell>
                      <TableCell className="view-interchange-fee-receipt__table--receipt">
                        {data.voucher_master_details.v_no}
                      </TableCell>
                      <TableCell className="view-interchange-fee-receipt__table--receipt">
                        {data.from_student_details.std_adm_no}
                      </TableCell>
                      <TableCell>
                        {data.from_student_details.first_name +
                          " " +
                          data.from_student_details.middle_name +
                          " " +
                          data.from_student_details.last_name}
                      </TableCell>
                      <TableCell className="view-interchange-fee-receipt__table--class">
                        {data.from_student_details.class_desc}
                      </TableCell>
                      <TableCell className="view-interchange-fee-receipt__table--receipt">
                        {data.to_student_details.std_adm_no}
                      </TableCell>
                      <TableCell>
                        {data.to_student_details.first_name +
                          " " +
                          data.to_student_details.middle_name +
                          " " +
                          data.to_student_details.last_name}
                      </TableCell>
                      <TableCell className="view-interchange-fee-receipt__table--class">
                        {data.to_student_details.class_desc}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="view-interchange-fee-receipt__table--receipt"
                      >
                        {data.voucher_master_details.v_total_cr_amt!}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="row g-0">
        <div className="col">
          <Button mode="cancel" onClick={() => setStudentModal(false)} />
        </div>
        <div className="col-2 view-interchange-fee-receipt__total">
          <StudentTotalCount
            totalCount={
              transferedVoucherDetails?.GetAcctTransferVoucherDetailsByInstId
                .length!
            }
          />
        </div>
      </div>
    </>
  );
};

export default ViewInterchangeDetails;
