import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";

import { useLazyQuery, useMutation } from "@apollo/client";

import {
  Autocomplete,
  Drawer,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";

import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import ReactEcharts from "echarts-for-react";

import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import {
  StudentModalStyles,
  SuccessModalCustomStyles,
} from "../../../../styles/ModalStyles";

import Close from "../../../../images/Close.svg";
import Avatar from "../../../../images/Avatar.svg";

import { TablePaginationActions } from "../../../../pages/CustomTablePagination";
import Home from "../../Home/Index";
import MessageModal from "../../../../pages/MessageModal";
import SalaryProcess from "./SalaryProcess";

import useSalaryMkWorkByMonth from "../../hooks/useSalaryMkWorkByMonth";
import useEmpMasterData from "../../hooks/useEmpMasterData";
import useToken from "../../../../customhooks/useToken";
import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";
import useEmpLeaveMaster from "../../hooks/useEmpLeaveMaster";

import { TableHeaderProps } from "../../../../Types/Tables";
import { SalaryProcessTitleProps } from "../../../../Types/Titles";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../utils/Form.types";
import {
  AttendanceStatus,
  Direction,
  Operation,
  SortBy,
} from "../../../../utils/Enum.types";
import {
  GetPayRollCurrentMonthCalendarData,
  GetPayRollCurrentMonthCalendarVars,
  GetPayRollSalaryMkEmpAttData,
  GetPayRollSalaryMkEmpAttVars,
} from "../../Types/paginationTypes";

import { Keys } from "../../../../utils/Enum.keys";

import {
  CURRENT_PAGE,
  days,
  DEBOUNCE_TIME_INTERVAL,
  EMPTY_STRING,
  NOTALLOCATED,
  PAGINATION_ARRAY,
  ROWS_PER_PAGE,
  TABLE_DATA_PER_PAGE,
} from "../../../../utils/constants";
import { DESIGNATION, HYPHEN } from "../../constants";

import {
  debounce,
  defaultLabelDisplayedRows,
  emptyDaysByWeek,
  formatterDecimal,
  getMonthDateAndDay,
  getWeekByDayNum,
  handleMUISelectEvent,
  isOptionEqualToValue,
  MonthName,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";

import { GetPayRollCurrentMonthCalendar } from "../../queries/holidays/query";
import {
  GetPayRollSalaryMkEmpAtt,
  GetPayRollSalaryMkWorkByMonthId,
} from "../../queries/salary/list";
import {
  UpdatePrSalWorkAttComplete,
  UpdateSalaryMkEmpAtt,
} from "../../queries/salary/mutation";

import { payloadTypes } from "../../../../context/reducer";
import { AppContext } from "../../../../context/context";
import { EmpAttendanceReportTableStyles } from "../../../../styles/StickyTableStyles";

import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { empAttendanceStatus } from "../../../../styles/DrawerStyles";
import { AntSwitch } from "../../../../pages/Switch";
import { GetPayRollEmpAttendanceByEmpId } from "../../queries/attendance/query";
import {
  GetPayRollEmpAttendanceByEmpIdData,
  GetPayRollEmpAttendanceByEmpIdVars,
} from "../../Types/masterDataTypes";
import {
  UpdateMkEmpAttFromAttTableForThisMonth,
  UpdatePayRollAttendanceForEmployee,
} from "../../queries/attendance/mutations";
import { AttEntryMethodEnum, PayRollConfigKeys } from "../../enums/Enum.types";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { TEXT_FONT_FAMILY } from "../../../../styles/DataGridTableStyles";

const { HR_Table } = require("../../json/table.json");
const { HRTitles } = require("../../json/title.json");
const enum stickyTableHeaders {
  SL = "Sl",
  ID = "Employee ID",
  WORKED_DAYS = "Worked Days",
}

interface leavesType {
  id: number;
  leave_details_id: number;
  leaveDesc: string;
  leaves_available: number;
  leaves_used: number;
  leaves_balance: number;
  newLeavesUsed: number;
  CB: number;
}
export interface EditStatus {
  rowKey: number;
  status: boolean;
}
export interface idWithModalType {
  id: number;
  flag: boolean;
}
interface noOfLeavesType {
  id: number;
  leaves: number;
  att_lwp_days: number;
}
export interface idWithModalType {
  id: number;
  flag: boolean;
}

interface monthDaysType {
  day: number;
  leave_short_desc: string;
  date: string;
  week: string;
}
interface totalCount {
  present: number;
  absent: number;
}

interface leaveCount {
  paid_leaves: number;
  lwp_leaves: number;
}

const EmpAttendance = () => {
  const { InstId, monthId } = useParams();
  const drawerClasses = empAttendanceStatus();

  const navigate = useNavigate();
  const { token } = useToken();
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { user_details } = useLoggedInUserDetails();

  const stickyClasses = EmpAttendanceReportTableStyles();
  const { state, dispatch } = useContext(AppContext);
  const { format } = formatterDecimal;
  const [saveModal, setSaveModal] = useState(false);
  const [importExcel, setImportExcel] = useState(false);
  const [attendanceStatus, setAttendanceStatus] = useState(false);
  const [leaveOptions, setLeaveOptions] = useState<optionsType[]>([]);
  const { serverDate } = useServerDateandTime();
  const [leaveEntryModal, setLeaveEntryModal] = useState<idWithModalType>({
    id: 0,
    flag: false,
  });
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [searchEmployee, setSearchEmployee] = useState("");
  const [leaves, setLeaves] = useState<leavesType[]>([]);
  const [noOfleaves, setNoOfLeaves] = useState<noOfLeavesType[]>([]);

  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(0);
  const [category, setCategory] = useState<responseType | null>(null);
  const [department, setDepartment] = useState<responseType | null>(null);
  const [designation, setDesignation] = useState<responseType | null>(null);
  const [grade, setGrade] = useState<responseType | null>(null);
  const [jobtype, setJobType] = useState<responseType | null>(null);
  const [monthDays, setMonthDays] = useState<monthDaysType[]>();
  const [count, setCount] = useState<totalCount>({
    present: 0,
    absent: 0,
  });
  const [leavesCount, setLeaveCount] = useState<leaveCount>({
    lwp_leaves: 0,
    paid_leaves: 0,
  });
  const [formData, setFormData] = useState({
    date: "",
    present: false,
  });
  const [leaveledger, setLeaveLedger] = useState<optionsType | null>(null);
  //Refs
  const saveRef = useRef<HTMLButtonElement>(null);

  const { configData: attTypeConfig } = useSwConfigData(
    PayRollConfigKeys.ATT_ENTRY_METHOD
  );
  const monthlyAttendanceStatus =
    attTypeConfig.data?.GetSwConfigVariables[0].config_string_value;
  const [updatePayRolEmpAttendance] = useMutation(
    UpdatePayRollAttendanceForEmployee,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          flag: true,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateLeaveDetails] = useMutation(
    UpdateMkEmpAttFromAttTableForThisMonth,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          flag: true,
          operation: Operation.NONE,
        }),
    }
  );

  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_GRADE,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();
  const { monthlyData, pr_acd_yr } = useSalaryMkWorkByMonth(monthId!);
  const { sal_wrk_job_completed, sal_wrk_is_sal_str_added } =
    monthlyData.data?.node || {};

  const { EmpLeaveData } = useEmpLeaveMaster(pr_acd_yr?.id!);
  const { sal_wrk_for_month } = monthlyData?.data?.node || {};
  const { year, month } = getMonthDateAndDay(sal_wrk_for_month!);
  const numDaysInMonth = new Date(year, month + 1, 0).getDate();

  const [GetMonthHolidays, { data }] = useLazyQuery<
    GetPayRollCurrentMonthCalendarData,
    GetPayRollCurrentMonthCalendarVars
  >(GetPayRollCurrentMonthCalendar, {
    variables: {
      token,
      pr_acd_yr_id: pr_acd_yr?.id!,
      inst_id: InstId!,
      cal_month: sal_wrk_for_month!,
    },
  });
  const [GetAttendanceByEmpId, { data: empAttByEmpIdData }] = useLazyQuery<
    GetPayRollEmpAttendanceByEmpIdData,
    GetPayRollEmpAttendanceByEmpIdVars
  >(GetPayRollEmpAttendanceByEmpId, {
    variables: {
      token,
      inst_id: InstId!,
      pr_acd_yr_id: pr_acd_yr?.id!,
      cal_month: sal_wrk_for_month!,
      pr_emp_id: state.employeeId,
    },
  });

  const [GetEmployees, { data: employees, error: employeesError, fetchMore }] =
    useLazyQuery<GetPayRollSalaryMkEmpAttData, GetPayRollSalaryMkEmpAttVars>(
      GetPayRollSalaryMkEmpAtt,
      {
        variables: {
          token,
          pr_acd_yr_id: pr_acd_yr?.id!,
          inst_id: InstId!,
          pr_sal_mk_month_id: monthId!,
          first: rowsPerPage,
          after: null,
          direction: Direction.ASC,
          sortBy: SortBy.ATT_SAL_MONTH,
          name: searchEmployee,
          department: department ? department.value : null,
          designation: designation ? designation.value : null,
          jobtype: jobtype ? jobtype.value : null,
          grade: grade ? grade.value : null,
          category: category ? category.value : null,
        },
        fetchPolicy: "network-only",
      }
    );

  const [UpdateEmpAttendance] = useMutation(UpdateSalaryMkEmpAtt, {
    onError: (e) =>
      setMessage({
        operation: Operation.NONE,
        message: e.message,
        flag: true,
      }),
  });

  const [UpdatePrSalWorkComplete] = useMutation(UpdatePrSalWorkAttComplete, {
    onError: (e) =>
      setMessage({
        operation: Operation.NONE,
        message: e.message,
        flag: true,
      }),
    onCompleted: () =>
      navigate(
        `/${InstId}/hr/payroll/salaryprocess/${monthId}/confirmemployeesalary`
      ),
  });
  const {
    no_of_days,
    no_of_weekend_days,
    no_of_general,
    total_no_of_workdays,
  } = data?.GetPayRollCurrentMonthCalendar || {};

  const {
    departmentDropDown,
    designationDropDown,
    jobTypeDropDown,
    gradeDropDown,
    categoryDropDown,
  } = useEmpMasterData();

  //Pagination
  const totalCount = employees?.GetPayRollSalaryMkEmpAtt?.totalCount;
  const endCursor = employees?.GetPayRollSalaryMkEmpAtt?.pageInfo?.endCursor;
  const indexOfLastrepo = CURRENT_PAGE * totalCount!;
  const indexOfFirstrepo = indexOfLastrepo - totalCount!;
  const currentRepo = employees?.GetPayRollSalaryMkEmpAtt.edges.slice(
    indexOfFirstrepo,
    indexOfLastrepo
  );

  const updateDebounceText = debounce(() => {
    handleMore();
  });
  const handleMore = () => {
    fetchMore({
      variables: {
        after: endCursor,
      },

      updateQuery: (prevResult, { fetchMoreResult }) => {
        fetchMoreResult.GetPayRollSalaryMkEmpAtt.edges = [
          ...prevResult.GetPayRollSalaryMkEmpAtt.edges,
          ...fetchMoreResult.GetPayRollSalaryMkEmpAtt.edges,
        ];

        return fetchMoreResult;
      },
    });
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setTimeout(() => {
      setPage(newPage);
      updateDebounceText();
    }, DEBOUNCE_TIME_INTERVAL);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const employeeDetails = employees?.GetPayRollSalaryMkEmpAtt.edges.find(
    ({ node }) => node.emp_details.id === state.employeeId
  );

  const totalLeaves = leaves.reduce(
    (acc, data) => {
      acc.leaves_availed += data.leaves_available;
      acc.leaves_used += data.leaves_used;
      acc.leaves_balance += data.leaves_balance;
      return acc;
    },
    {
      leaves_availed: 0,
      leaves_used: 0,
      leaves_balance: 0,
      lwp: 0,
    }
  );

  useEffect(() => {
    if (pr_acd_yr && sal_wrk_for_month) {
      GetMonthHolidays();
    }
  }, [pr_acd_yr, sal_wrk_for_month, GetMonthHolidays]);
  useEffect(() => {
    if (pr_acd_yr) {
      GetEmployees();
    }
  }, [pr_acd_yr, GetEmployees, department]);

  useEffect(() => {
    if (EmpLeaveData.data && !EmpLeaveData.loading) {
      setLeaves(
        EmpLeaveData.data?.GetPayRollEmpLeaveMasterDetails.leave_details
          .filter(({ leave_ldgr_details }) => !leave_ldgr_details.is_lwp_ldgr)
          .map(
            ({
              leave_ldgr_details,
              leaves_used,
              leaves_availed,
              leaves_balance,
              id,
            }) => ({
              id: leaveEntryModal.id,
              leave_details_id: id,
              leaveDesc: leave_ldgr_details.leave_desc!,
              leaves_available: leaves_availed,
              leaves_used,
              leaves_balance,
              newLeavesUsed: 0,
              att_emp_paid_leave: 0,
              CB: 5,
            })
          )
      );
      if (attendanceStatus) {
        setLeaveOptions(
          EmpLeaveData.data?.GetPayRollEmpLeaveMasterDetails.leave_details.map(
            ({ leave_ldgr_details }) => ({
              label: leave_ldgr_details.leave_desc,
              value: leave_ldgr_details.leave_short_code,
            })
          )
        );
      }
    }
  }, [
    EmpLeaveData.data,
    EmpLeaveData.loading,
    leaveEntryModal.id,
    attendanceStatus,
  ]);
  useEffect(() => {
    if (state.employeeId) {
      GetAttendanceByEmpId().then(({ data }) => {
        if (data) {
          setMonthDays(
            Array.from({ length: numDaysInMonth }, (_, i) => {
              const dayNum = i + 1;
              const dayKey = `day_${dayNum}`;

              const date = `${year}/${month + 1}/${dayNum}`;
              const { week } = getMonthDateAndDay(date);
              const week_string = getWeekByDayNum(week);
              return {
                day: dayNum,
                present:
                  (
                    data.GetPayRollEmpAttendanceByEmpId as Record<
                      string,
                      string
                    >
                  )[dayKey] === EMPTY_STRING,
                leave_short_desc: (
                  data.GetPayRollEmpAttendanceByEmpId as Record<string, string>
                )[dayKey],
                date,
                week: week_string!,
              };
            })
          );

          setCount({
            present: data?.GetPayRollEmpAttendanceByEmpId
              .att_total_days_present as number,
            absent: data?.GetPayRollEmpAttendanceByEmpId
              .att_total_days_absent as number,
          });
        }
      });
    }
  }, [
    state.employeeId,
    GetAttendanceByEmpId,
    numDaysInMonth,
    empAttByEmpIdData,
    month,
    year,
  ]);

  const hanldeEmpAttendance = () => {
    UpdateEmpAttendance({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        pr_acd_yr_id: pr_acd_yr?.id,
        cal_month: sal_wrk_for_month,
        input: {
          pr_emp_id: employeeDetails?.node.emp_details.id,
          att_emp_leaves: leavesCount.lwp_leaves + leavesCount.paid_leaves,
          att_emp_paid_leave: leavesCount.paid_leaves,
          att_lwp_days: leavesCount.lwp_leaves,
        },
      },
      refetchQueries: [
        {
          query: GetPayRollSalaryMkEmpAtt,
          variables: {
            token,
            pr_acd_yr_id: pr_acd_yr?.id!,
            inst_id: InstId!,
            pr_sal_mk_month_id: monthId!,
            first: rowsPerPage,
            after: null,
            direction: Direction.ASC,
            sortBy: SortBy.ATT_SAL_MONTH,
            name: searchEmployee,
            department: department ? department.value : null,
            designation: designation ? designation.value : null,
            jobtype: jobtype ? jobtype.value : null,
            grade: grade ? grade.value : null,
            category: category ? category.value : null,
          },
          fetchPolicy: "network-only",
        },
        {
          query: GetPayRollSalaryMkEmpAtt,
          variables: {
            token,
            pr_acd_yr_id: pr_acd_yr?.id!,
            inst_id: InstId!,
            pr_sal_mk_month_id: monthId!,
            first: rowsPerPage,
            after: null,
            direction: Direction.ASC,
            sortBy: SortBy.ATT_SAL_MONTH,
            name: searchEmployee,
            department: department ? department.value : null,
            designation: designation ? designation.value : null,
            jobtype: jobtype ? jobtype.value : null,
            grade: grade ? grade.value : null,
            category: category ? category.value : null,
          },
          fetchPolicy: "network-only",
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setLeaveCount({
          lwp_leaves: 0,
          paid_leaves: 0,
        });
        setLeaveEntryModal({
          flag: false,
          id: 0,
        });
        setMessage({
          message: "Updated Attendance Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };

  const handleSubmit = () =>
    UpdatePrSalWorkComplete({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        pr_sal_mk_month_id: monthId,
        month_start_date: toIsoDate(serverDate),
      },
      refetchQueries: [
        {
          query: GetPayRollSalaryMkWorkByMonthId,
          variables: {
            token,
            inst_id: InstId!,
            id: Number(monthId),
          },
        },
      ],
    });

  const handleLeaveDetails = () =>
    UpdateLeaveDetails({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        pr_acd_yr_id: pr_acd_yr?.id,
        cal_month: toIsoDate(sal_wrk_for_month!),
      },
      refetchQueries: [
        {
          query: GetPayRollSalaryMkEmpAtt,
          variables: {
            token,
            pr_acd_yr_id: pr_acd_yr?.id!,
            inst_id: InstId!,
            pr_sal_mk_month_id: monthId!,
            first: rowsPerPage,
            after: null,
            direction: Direction.ASC,
            sortBy: SortBy.ATT_SAL_MONTH,
            name: searchEmployee,
            department: department ? department.value : null,
            designation: designation ? designation.value : null,
            jobtype: jobtype ? jobtype.value : null,
            grade: grade ? grade.value : null,
            category: category ? category.value : null,
          },
          fetchPolicy: "network-only",
        },
      ],
    });

  const handleClose = () =>
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });

  const options = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "horizontal",
      bottom: "bottom",
    },
    textStyle: {
      fontSize: 14,
      fontFamily: TEXT_FONT_FAMILY,
      color: "#1e293b",
    },
    series: [
      {
        name: "Employee Attendance Report",
        type: "pie",
        radius: "50%",
        data: [
          { value: count.absent, name: "Absent" },
          { value: count.present, name: "Present" },
        ],
      },
    ],
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: "rgba(0, 0, 0, 0.5)",
      },
    },
    color: ["#ef4444", "#22c55e"],
  };
  const weekCount = () => {
    if (monthDays && monthDays.length > 0) {
      const firstWeek = monthDays[0].week;

      const lastWeek = monthDays[monthDays.length - 1]?.week;
      const startArray = new Array(emptyDaysByWeek(firstWeek!).numberOfEmpty)
        .fill(null)
        .map(() => ({
          day: -1,
          present: false,
          leave_short_desc: "",
          date: "",
          week: "",
        }));
      const endArray = new Array(emptyDaysByWeek(lastWeek!).numberOfEmptyToLast)
        .fill(null)
        .map(() => ({
          day: -1,
          present: false,
          leave_short_desc: "",
          date: "",
          week: "",
        }));

      return [...startArray, ...monthDays, ...endArray];
    } else {
      return [];
    }
  };
  const hanldeEmpAtttendanceForEmployee = () => {
    updatePayRolEmpAttendance({
      variables: {
        token,
        inst_id: InstId,
        user_details,

        pr_acd_yr_id: pr_acd_yr?.id,
        date_of_attendance: toIsoDate(formData.date),
        pr_emp_id: state.employeeId,
        from_status: monthDays?.find(
          (month_day) => month_day.date === formData.date
        )?.leave_short_desc
          ? monthDays?.find((month_day) => month_day.date === formData.date)
              ?.leave_short_desc
          : "",
        to_status: formData.present ? AttendanceStatus.P : leaveledger?.value,
      },
      refetchQueries: [
        {
          query: GetPayRollEmpAttendanceByEmpId,
          variables: {
            token,
            inst_id: InstId!,
            pr_acd_yr_id: pr_acd_yr?.id!,
            cal_month: sal_wrk_for_month!,
            pr_emp_id: state.employeeId,
          },
          fetchPolicy: "network-only",
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Attendance updated successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {HRTitles.SalaryProcess.Titles.map(
          (title: SalaryProcessTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>{title.EmpAttendance}</React.Fragment>
            );
          }
        )}
      </Title>
      <div className="salary-process">
        <div className="salary-process__data">
          <div className="salary-process__data--left-block">
            <SalaryProcess monthDetails={monthlyData.data?.node!} />
          </div>

          <div className="salary-process__data--right-block">
            <div className="row g-0 salary-process__emp-attendance--details">
              <div className="col-2">
                <Input
                  id="search"
                  type="text"
                  placeholder="Search Name..."
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchEmployee(e.target.value)
                  }
                />
              </div>
              {USE_HR_DEPARTMENT ? (
                <div className="col-1">
                  <Autocomplete
                    classes={classes}
                    options={departmentDropDown!}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option, department)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setDepartment(newValue as responseType);
                      } else {
                        setDepartment(null);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === Keys.BACKSPACE) {
                        setDepartment(null);
                      }
                    }}
                    openOnFocus
                    freeSolo
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Department"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        classes={{ root: textClasses.formControlRoot }}
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_HR_DESIGNATION ? (
                <div className="col-1">
                  <Autocomplete
                    classes={classes}
                    options={designationDropDown!}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option, designation)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setDesignation(newValue as responseType);
                      } else {
                        setDesignation(null);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === Keys.BACKSPACE) {
                        setDesignation(null);
                      }
                    }}
                    openOnFocus
                    freeSolo
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Designation"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        classes={{ root: textClasses.formControlRoot }}
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_HR_JOBTYPE ? (
                <div className="col-1">
                  <Autocomplete
                    classes={classes}
                    options={jobTypeDropDown!}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option, jobtype)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setJobType(newValue as responseType);
                      } else {
                        setJobType(null);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === Keys.BACKSPACE) {
                        setJobType(null);
                      }
                    }}
                    openOnFocus
                    freeSolo
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="JobType"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        classes={{ root: textClasses.formControlRoot }}
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_HR_GRADE ? (
                <div className="col-1">
                  <Autocomplete
                    classes={classes}
                    options={gradeDropDown!}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option, grade)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setGrade(newValue as responseType);
                      } else {
                        setGrade(null);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === Keys.BACKSPACE) {
                        setGrade(null);
                      }
                    }}
                    openOnFocus
                    freeSolo
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Grade"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        classes={{ root: textClasses.formControlRoot }}
                      />
                    )}
                  />
                </div>
              ) : null}
              {USE_HR_CATEGORY ? (
                <div className="col-1">
                  <Autocomplete
                    classes={classes}
                    options={categoryDropDown!}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option, category)
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setCategory(newValue as responseType);
                      } else {
                        setCategory(null);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === Keys.BACKSPACE) {
                        setCategory(null);
                      }
                    }}
                    openOnFocus
                    freeSolo
                    forcePopupIcon
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        classes={{ root: textClasses.formControlRoot }}
                      />
                    )}
                  />
                </div>
              ) : null}
            </div>
            <div className="row g-0 salary-process__emp-attendance--details1">
              <div className="col-2">
                <TextField
                  className="salary-process__emp-attendance--details1--textfield"
                  label="Salary Process Month"
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={
                    monthlyData?.data?.node?.sal_wrk_for_month!
                      ? MonthName(
                          monthlyData?.data?.node?.sal_wrk_for_month
                        ).toString()
                      : "-"
                  }
                />
              </div>
              <div className="col-2">
                <TextField
                  className="salary-process__emp-attendance--details1--textfield"
                  label="Month Days"
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={no_of_days ?? 0}
                />
              </div>
              <div className="col-2">
                <TextField
                  className="salary-process__emp-attendance--details1--textfield"
                  label="Weekends"
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={no_of_weekend_days ?? 0}
                />
              </div>
              <div className="col-2">
                <TextField
                  className="salary-process__emp-attendance--details1--textfield"
                  label="General Holidays"
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={no_of_general ?? 0}
                />
              </div>
              <div className="col-2">
                <TextField
                  className="salary-process__emp-attendance--details1--textfield"
                  label="Working Days"
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={total_no_of_workdays ?? 0}
                />
              </div>
            </div>
            <div className="salary-process__emp-attendance--table">
              {employeesError ? (
                <>{employeesError.message}</>
              ) : employees?.GetPayRollSalaryMkEmpAtt.edges.length! > 0 ? (
                <TableContainer className={stickyClasses.table}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {HR_Table.SalaryProcess.EmpAttendance.StickyHeaders.filter(
                          (th: TableHeaderProps) =>
                            th.labelName !== stickyTableHeaders.WORKED_DAYS
                        ).map((th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell
                              className={
                                th.labelName === stickyTableHeaders.SL
                                  ? stickyClasses.stickyHeaderSl
                                  : th.labelName === stickyTableHeaders.ID
                                  ? stickyClasses.stickyHeaderEmpId
                                  : stickyClasses.stickyHeaderName
                              }
                              key={index}
                              align="center"
                            >
                              {th.labelName}
                            </TableCell>
                          );
                        })}
                        {HR_Table.SalaryProcess.EmpAttendance.Table_Headers.filter(
                          (th: TableHeaderProps) => {
                            if (
                              th.labelName === DESIGNATION &&
                              USE_HR_DESIGNATION === false
                            ) {
                              return false;
                            } else {
                              return true;
                            }
                          }
                        ).map((th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell
                              key={index}
                              className={
                                th.className === "Designation"
                                  ? stickyClasses.stickyHeaderDesc
                                  : stickyClasses.stickyHeader
                              }
                              align="center"
                            >
                              {th.labelName}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentRepo &&
                        currentRepo &&
                        (rowsPerPage > 0 &&
                        currentRepo.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        ).length > 0
                          ? currentRepo.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : currentRepo
                        ).map(({ node }, index) => {
                          return (
                            <TableRow
                              key={index}
                              onClick={() => {
                                setLeaveEntryModal({
                                  id: node.id,
                                  flag: true,
                                });
                                dispatch({
                                  type: payloadTypes.SET_EMPLOYEE_ID,
                                  payload: {
                                    employeeId: node.emp_details.id,
                                  },
                                });

                                setLeaveCount({
                                  lwp_leaves: node.att_lwp_days,
                                  paid_leaves: node.att_emp_paid_leave,
                                });
                              }}
                            >
                              <TableCell
                                id="td-center"
                                className={stickyClasses.stickyColumnSl}
                              >
                                {page * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell
                                className={`salary-process__emp-attendance--table--name ${stickyClasses.stickyColumnEmpId}`}
                              >
                                {node.emp_details.emp_id}
                              </TableCell>

                              <TableCell
                                className={stickyClasses.stickyColumnName}
                              >
                                {node.emp_details.emp_first_name +
                                  " " +
                                  node.emp_details.emp_middle_name +
                                  " " +
                                  node.emp_details.emp_last_name}
                              </TableCell>

                              {USE_HR_DESIGNATION ? (
                                <TableCell
                                  className={`salary-process__emp-attendance--table--desc ${stickyClasses.ColumnDesc}`}
                                  id="td-center"
                                >
                                  {node.emp_details.pr_designation_details
                                    ?.designation_desc !== NOTALLOCATED
                                    ? node.emp_details.pr_designation_details
                                        ?.designation_desc
                                    : HYPHEN}
                                </TableCell>
                              ) : null}
                              <TableCell
                                className={`salary-process__emp-attendance--table--status ${stickyClasses.Column}`}
                                id="td-center"
                              >
                                {node.emp_details.emp_status}
                              </TableCell>
                              <TableCell
                                className={`salary-process__emp-attendance--table--input ${stickyClasses.Column}`}
                                id="td-center"
                              >
                                {node.att_emp_paid_leave}
                              </TableCell>
                              <TableCell
                                className={`salary-process__emp-attendance--table--input  ${stickyClasses.Column}`}
                                id="td-center"
                              >
                                {node.att_lwp_days}
                              </TableCell>
                              <TableCell
                                className={`salary-process__emp-attendance--table--input  ${stickyClasses.Column}`}
                                id="td-center"
                              >
                                {node.att_emp_leaves}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={PAGINATION_ARRAY}
                          count={totalCount ?? 0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                          labelDisplayedRows={defaultLabelDisplayedRows}
                          labelRowsPerPage={TABLE_DATA_PER_PAGE}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              ) : (
                <b className="nodata">No data found</b>
              )}
            </div>
          </div>
        </div>

        <Button
          type="submit"
          onClick={handleSubmit}
          disabled={noOfleaves.length !== 0 || sal_wrk_job_completed}
        >
          Close Attendance
        </Button>

        <Button
          mode="excel"
          onClick={() => {
            setImportExcel(!importExcel);
          }}
          disabled={sal_wrk_job_completed}
        >
          Import Attendance Sheet
        </Button>

        <Button
          mode="excel"
          onClick={handleLeaveDetails}
          disabled={sal_wrk_is_sal_str_added}
        >
          Update Leave Details
        </Button>

        <Button
          mode="clear"
          onClick={() => {
            setLeaves([]);
            setNoOfLeaves([]);
          }}
        />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={saveModal}
        style={SuccessModalCustomStyles}
        ariaHideApp={false}
      >
        <Title>Attendance En-Rolled Done</Title>
        <div>
          <Label> Attendance En-Rolled for Employees</Label>
        </div>
        <Button
          mode="okay"
          onClick={() => {
            setSaveModal(!saveModal);
            navigate(
              `/${InstId}/hr/payroll/salaryprocess/${monthId}/confirmemployeesalary`
            );
          }}
        />
      </Modal>
      {/* leave-entry */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={leaveEntryModal.flag}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Employee Leave Entry</Title>

            <div className="salary-process__emp-attendance--leave-ledger">
              <div className="row g-0 salary-process__emp-attendance--leave-ledger--datablock">
                <div className="col salary-process__emp-attendance--leave-ledger--datablock--left">
                  <div className="row g-0 salary-process__emp-attendance--leave-ledger--details">
                    <div className="col">
                      <TextField
                        label="Emp ID"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={
                          employeeDetails
                            ? employeeDetails.node.emp_details.emp_id
                            : EMPTY_STRING
                        }
                        className="salary-process__emp-attendance--leave-ledger--details--textfield"
                      />
                      {USE_HR_DESIGNATION ? (
                        <TextField
                          label="Designation"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          value={
                            employeeDetails
                              ? employeeDetails.node.emp_details
                                  .pr_designation_details.designation_desc
                              : EMPTY_STRING
                          }
                          className="salary-process__emp-attendance--leave-ledger--details--textfield"
                        />
                      ) : null}
                      <TextField
                        label="Name"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={
                          employeeDetails
                            ? `${employeeDetails.node.emp_details.emp_first_name} ${employeeDetails.node.emp_details.emp_middle_name} ${employeeDetails.node.emp_details.emp_last_name}`
                            : EMPTY_STRING
                        }
                        className="salary-process__emp-attendance--leave-ledger--details--textfield"
                      />
                    </div>
                    <div className="col">
                      {USE_HR_DEPARTMENT ? (
                        <TextField
                          label="Department"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          value={
                            employeeDetails
                              ? employeeDetails.node.emp_details.pr_dept_details
                                  .dept_desc
                              : EMPTY_STRING
                          }
                          className="salary-process__emp-attendance--leave-ledger--details--textfield"
                        />
                      ) : null}
                      {USE_HR_CATEGORY ? (
                        <TextField
                          label="Category"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          value={
                            employeeDetails
                              ? employeeDetails.node.emp_details
                                  .pr_category_details.category_desc
                              : EMPTY_STRING
                          }
                          className="salary-process__emp-attendance--leave-ledger--details--textfield"
                        />
                      ) : null}

                      {USE_HR_JOBTYPE ? (
                        <TextField
                          label="Job Type"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          value={
                            employeeDetails
                              ? employeeDetails.node.emp_details
                                  .pr_job_type_details.job_type_desc
                              : EMPTY_STRING
                          }
                          className="salary-process__emp-attendance--leave-ledger--details--textfield"
                        />
                      ) : null}
                    </div>

                    <div className="col-1 salary-process__emp-attendance--leave-ledger--details--image">
                      <img src={Avatar} alt="/" />
                    </div>
                  </div>
                  <div className="row g-0 salary-process__emp-attendance--leave-ledger--dates">
                    <div className="col">
                      <TextField
                        label="Salary Process Month"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={
                          monthlyData?.data?.node?.sal_wrk_for_month!
                            ? MonthName(
                                monthlyData?.data?.node?.sal_wrk_for_month!
                              ).toString()
                            : "-"
                        }
                        className="salary-process__emp-attendance--leave-ledger--textfield"
                      />
                    </div>

                    <div className="col">
                      <TextField
                        label="Working Days"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={total_no_of_workdays ?? 0}
                        className="salary-process__emp-attendance--leave-ledger--textfield"
                      />
                    </div>
                  </div>
                  {monthlyAttendanceStatus !==
                  AttEntryMethodEnum.WITHOUT_LEAVE_MANAGEMENT ? (
                    <div className="row g-0 salary-process__emp-attendance--leave-ledger--tableblock">
                      {EmpLeaveData.error ? (
                        <b className="nodata">{EmpLeaveData.error.message}</b>
                      ) : leaves.length > 0 ? (
                        <TableContainer className="salary-process__emp-attendance--leave-ledger--table">
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                {HR_Table.SalaryProcess.EmpAttendance.LeaveLedger.map(
                                  (th: TableHeaderProps, index: React.Key) => {
                                    return (
                                      <TableCell
                                        key={index}
                                        className={th.className}
                                      >
                                        {th.labelName}
                                      </TableCell>
                                    );
                                  }
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {leaves.map((node, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell
                                      className="salary-process__emp-attendance--leave-ledger--table--slno"
                                      id="td-center"
                                    >
                                      {index + 1}
                                    </TableCell>
                                    <TableCell>{node.leaveDesc}</TableCell>
                                    <TableCell
                                      className="salary-process__emp-attendance--leave-ledger--table--slno"
                                      id="td-center"
                                    >
                                      {node.leaves_available}
                                    </TableCell>
                                    <TableCell
                                      className="salary-process__emp-attendance--leave-ledger--table--input"
                                      id="td-center"
                                    >
                                      {node.leaves_used}
                                    </TableCell>
                                    <TableCell
                                      className="salary-process__emp-attendance--leave-ledger--table--input"
                                      id="td-center"
                                    >
                                      {node.leaves_balance}
                                    </TableCell>
                                    <TableCell
                                      className="salary-process__emp-attendance--leave-ledger--table--input "
                                      id="td-center"
                                    >
                                      {node.leaves_used > node.leaves_available
                                        ? node.leaves_used -
                                          node.leaves_available
                                        : 0}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                            <TableFooter>
                              <TableRow>
                                <TableCell colSpan={2} className="total">
                                  Total Leaves :
                                </TableCell>
                                <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno balance-count">
                                  {totalLeaves.leaves_availed}
                                </TableCell>
                                <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno balance-count">
                                  {totalLeaves.leaves_used}
                                </TableCell>
                                <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno balance-count">
                                  {totalLeaves.leaves_balance}
                                </TableCell>
                                <TableCell className="salary-process__emp-attendance--leave-ledger--table--slno balance-count">
                                  {totalLeaves.lwp}
                                </TableCell>
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </TableContainer>
                      ) : (
                        <b className="nodata">No data found Try adding....</b>
                      )}
                    </div>
                  ) : null}

                  <div className="row g-0 salary-process__emp-attendance--leave-ledger--dates">
                    <div className="col">
                      <TextField
                        label="Leave Without Pay"
                        InputLabelProps={{ shrink: true }}
                        value={leavesCount.lwp_leaves}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => {
                          if (
                            leavesCount.paid_leaves + Number(e.target.value) <
                            total_no_of_workdays!
                          ) {
                            setLeaveCount((prev) => ({
                              ...prev,
                              lwp_leaves: Number(e.target.value),
                            }));
                          } else {
                            setMessage({
                              message:
                                "Total Leaves cannot be more than Total no of work days",
                              flag: true,
                              operation: Operation.NONE,
                            });
                          }
                        }}
                        className="salary-process__emp-attendance--leave-ledger--textfield"
                      />
                    </div>

                    <div className="col">
                      <TextField
                        label="Paid Leaves"
                        InputLabelProps={{ shrink: true }}
                        onFocus={(e) => e.target.select()}
                        value={leavesCount.paid_leaves}
                        onChange={(e) => {
                          if (
                            leavesCount.lwp_leaves + Number(e.target.value) <
                            total_no_of_workdays!
                          ) {
                            setLeaveCount((prev) => ({
                              ...prev,
                              paid_leaves: Number(e.target.value),
                            }));
                          } else {
                            setMessage({
                              message:
                                "Total Leaves cannot be more than Total no of work days",
                              flag: true,
                              operation: Operation.NONE,
                            });
                          }
                        }}
                        className="salary-process__emp-attendance--leave-ledger--textfield"
                      />
                    </div>
                    <div className="col">
                      <TextField
                        label="Total Leaves"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        value={leavesCount.lwp_leaves + leavesCount.paid_leaves}
                        className="salary-process__emp-attendance--leave-ledger--textfield"
                      />
                    </div>
                  </div>
                </div>
                <div className="col salary-process__emp-attendance--leave-ledger--datablock--right">
                  <div className="row g-0 salary-process__emp-attendance--leave-ledger--graph">
                    <div className="col">
                      <Label
                        onClick={() => {
                          if (!attendanceStatus) {
                            setAttendanceStatus(!attendanceStatus);
                          }
                        }}
                      >
                        Overall Attendance Percentage for This Month :
                        <b>{`${format(
                          Number((count.present / no_of_days!) * 100)
                        )} ${
                          Number((count.present / no_of_days!) * 100)
                            ? "%"
                            : EMPTY_STRING
                        } `}</b>
                      </Label>
                    </div>
                    <div className="col-4 h-100">
                      <ReactEcharts option={options} />
                    </div>
                  </div>
                  <div className="row g-0 salary-process__emp-attendance--calendar">
                    <ul className="salary-process__emp-attendance--calendar--grid-container">
                      {days.map((day, index: React.Key) => {
                        return (
                          <li
                            key={index}
                            className="salary-process__emp-attendance--calendar--grid-container--day"
                          >
                            {day}
                          </li>
                        );
                      })}
                      {weekCount().map((day, index: React.Key) => {
                        return day.day > 0 ? (
                          <li
                            key={index}
                            className={`salary-process__emp-attendance--calendar--grid-item${
                              day.leave_short_desc
                                ? day.leave_short_desc === AttendanceStatus.P
                                  ? "--present"
                                  : "--absent"
                                : ""
                            }`}
                            onClick={() => {
                              setFormData({
                                date: day.date,
                                present:
                                  day.leave_short_desc === AttendanceStatus.P,
                              });
                              if (day.leave_short_desc !== AttendanceStatus.P) {
                                setLeaveLedger(
                                  leaveOptions.find(
                                    (leave) =>
                                      leave.value === day.leave_short_desc
                                  ) ?? null
                                );
                              }
                              setAttendanceStatus(!attendanceStatus);
                            }}
                          >
                            <span className="salary-process__emp-attendance--calendar--grid-item--date">
                              {new Date(day.date).getDate()}
                            </span>
                            {day.leave_short_desc &&
                            day.leave_short_desc !== AttendanceStatus.P ? (
                              <span className="salary-process__emp-attendance--calendar--grid-item--font">
                                {day.leave_short_desc}
                              </span>
                            ) : null}
                          </li>
                        ) : (
                          <li key={index}></li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              <Button
                mode="save"
                type="submit"
                buttonref={saveRef!}
                disabled={sal_wrk_job_completed}
                onClick={hanldeEmpAttendance}
              />
              <Button mode="clear" />
              <Button
                mode="cancel"
                onClick={() =>
                  setLeaveEntryModal({
                    id: 0,
                    flag: false,
                  })
                }
              />
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() =>
                setLeaveEntryModal({
                  id: 0,
                  flag: false,
                })
              }
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag}
        handleClose={handleClose}
        operation={message.operation}
        value={message.message}
      />

      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={attendanceStatus}
        onClose={() => setAttendanceStatus(!attendanceStatus)}
      >
        <div className="salary-process__emp-attendance-status">
          <div className="salary-process__emp-attendance-status--title">
            <Title>Attendance Status</Title>
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setAttendanceStatus(!attendanceStatus)}
            />
          </div>
          <div className="salary-process__emp-attendance-status--title">
            <Label variant="LabelBold">{toStandardDate(formData.date)}</Label>
            <FormGroup className="academic-subject-details__form-labels">
              <FormControlLabel
                label=""
                name="attendance"
                checked={formData.present}
                control={
                  <AntSwitch
                    onClick={(e: React.MouseEvent) =>
                      setFormData((prevValues) => ({
                        ...prevValues,
                        present: (e.target as HTMLInputElement).checked,
                      }))
                    }
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER) {
                        handleMUISelectEvent(e);
                      }
                    }}
                  />
                }
                labelPlacement="start"
              />
            </FormGroup>
          </div>
          <div className="row g-0">
            <div className="col-8">
              {formData.present ? (
                <span className="font-green">Present</span>
              ) : (
                <span className="font-red">Absent</span>
              )}
            </div>
          </div>
          {!formData.present && (
            <div className="row g-0  salary-process__emp-attendance-status--select">
              <div className="col-6">
                <Autocomplete
                  classes={classes}
                  options={leaveOptions}
                  value={leaveledger}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setLeaveLedger(newValue);
                    } else {
                      setLeaveLedger(null);
                    }
                  }}
                  openOnFocus
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Leave Leadger"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            </div>
          )}

          <Button
            mode="save"
            onClick={hanldeEmpAtttendanceForEmployee}
            disabled={sal_wrk_job_completed}
          >
            &nbsp;Attendance
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default EmpAttendance;
