import React, { useContext, useEffect, useState } from "react";
import List from "./List";
import Avatar from "../../../../images/Avatar.svg";
import { Autocomplete, TextField } from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import useAcdStudentsAdmNumber from "../../hooks/useAcdStudentsAdmNumber";
import {
  InstitutionConfigurationTypes,
  PageFor,
  StudentReportType,
} from "../../../../utils/Enum.types";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
} from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import { responseType } from "../../../../utils/Form.types";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import {
  toInputStandardDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";

import Attendance from "../../../Master/Student/Dashboard/Attendance";
import OverAllAttendance from "../../../Master/Student/Dashboard/Academics/OverAllAttendance";
import Sessionwise from "../../../Master/Student/Dashboard/Academics/Sessionwise";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import Modal from "react-modal";
import Close from "../../../../images/Close.svg";
import DataFetch from "../../../../images/Edit.svg";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import StudentList from "../../students/List";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
const IndividualStudent = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const [searchAdmNo, setSearchAdmNo] = useState("");
  const { state, dispatch } = useContext(AppContext);
  const { studentFormData } = useStudentDatabyId();
  const [studentModal, setStudentModal] = useState(false);
  const { studentAddmissionNumber } = useAcdStudentsAdmNumber(
    searchAdmNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentReportType.GENERAL
  );
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );
  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;
  const [imageString, setImageString] = useState("");

  useEffect(() => {
    if (studentFormData.std_profile_filename !== EMPTY_STRING) {
      // eslint-disable-next-line
      const ers = getDownloadUrl(
        studentFormData.std_profile_filename,
        false,
        setImageString
      );
    }
  }, [studentFormData.std_profile_filename]);
  const {categoryLabel}=useInstLabels();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Attendance Report</Title>
      <div className="individual-std-report">
        <div className="individual-std-report__left">
          <List />
        </div>
        <div className="individual-std-report__right">
          <div className="individual-std-report__select row g-0">
            <div className="col">
              <div className="transport-assign-route__details--image-flex">
                <Autocomplete
                  classes={classes}
                  options={studentAddmissionNumber ?? EMPTY_RESPONSETYPE_OBJECT}
                  isOptionEqualToValue={(option) =>
                    option?.value === state.studentId
                  }
                  openOnFocus
                  freeSolo
                  autoHighlight
                  forcePopupIcon
                  value={state.studentId ? `${studentFormData.adm_no}` : null}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: (newValue as responseType)?.value,
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Admission Number"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchAdmNo(e.target.value)
                      }
                      autoFocus
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
                <img
                  className="data-fetch-icon"
                  src={DataFetch}
                  alt="/"
                  onClick={() => {
                    setStudentModal(!studentModal);
                  }}
                />
              </div>

              <TextField
                label="Reg No"
                className="transport-assign-route__details--textfield"
                InputLabelProps={{ shrink: true }}
                value={studentFormData.reg_number}
                disabled
              />
              <TextField
                label="Date of Birth"
                className="transport-assign-route__details--textfield"
                InputLabelProps={{ shrink: true }}
                value={toInputStandardDate(studentFormData.std_dob)}
                disabled
              />
            </div>
            <div className="col">
              <TextField
                label="Name"
                className="transport-assign-route__details--textfield"
                InputLabelProps={{ shrink: true }}
                value={
                  studentFormData.first_name +
                  studentFormData.middle_name +
                  studentFormData.last_name
                }
                disabled
              />
              <TextField
                label="Father Name"
                className="transport-assign-route__details--textfield"
                InputLabelProps={{ shrink: true }}
                value={studentFormData.father_name}
                disabled
              />
              <TextField
                label="Branch"
                className="transport-assign-route__details--textfield"
                InputLabelProps={{ shrink: true }}
                value={studentFormData.branch}
                disabled
              />
            </div>
            <div className="col">
              <TextField
                label="Class"
                className="transport-assign-route__details--textfield"
                InputLabelProps={{ shrink: true }}
                value={studentFormData.class}
                disabled
              />
              <TextField
                label={categoryLabel}
                className="transport-assign-route__details--textfield"
                InputLabelProps={{ shrink: true }}
                value={studentFormData.category}
                disabled
              />
              <TextField
                label="Academic Year"
                className="transport-assign-route__details--textfield"
                InputLabelProps={{ shrink: true }}
                value={studentFormData.acd_yr}
                disabled
              />
            </div>
            <div className="col-1 individual-std-report__select--profile">
              {imageString ? (
                <img src={imageString} alt="" />
              ) : (
                <img src={Avatar} alt="" />
              )}
            </div>
          </div>
          {state.studentId ? (
            <div className="individual-std-report__bottom row g-0">
              <div className="col individual-std-report__bottom--frame">
                <Attendance pageType={PageFor.REPORT} />
              </div>
              {enablePerSessionAtt && (
                <div className="col individual-std-report__bottom--frame">
                  <Sessionwise />
                </div>
              )}
              <div className="col individual-std-report__bottom--frame">
                <OverAllAttendance />
              </div>
            </div>
          ) : (
            <b className="nodata">No data found</b>
          )}
        </div>
      </div>
      {/* student-modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              setStudentModal={setStudentModal}
              queryType={StudentReportType.CURR_STUDENTS}
              studentModal={studentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IndividualStudent;
