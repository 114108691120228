import { InstitutionDetails } from "../../../../../utils/Enum.types";
import { InitialStateType } from "./StatesProvider";
import { Actions, payloadType } from "./types";

export const reducer = (state: InitialStateType, action: Actions) => {
  switch (action.type) {
    case InstitutionDetails.DEPARTMENT:
      return {
        ...state,
        dept: action.payload.dept,
      };
    case InstitutionDetails.BRANCH:
      return {
        ...state,
        branch: action.payload.branch,
      };

    case InstitutionDetails.CLASS:
      return {
        ...state,
        class: action.payload.class,
      };

    case InstitutionDetails.SEMESTER:
      return {
        ...state,
        sem: action.payload.sem,
      };

    case InstitutionDetails.SECTION:
      return {
        ...state,
        section: action.payload.section,
      };

    case InstitutionDetails.CATEGORY:
      return {
        ...state,
        category: action.payload.category,
      };
    case payloadType.STATUS:
      return {
        ...state,
        selectedStatus: action.payload.selectedStatus,
      };
    case payloadType.SORT_BY:
      return {
        ...state,
        selectedSortBy: action.payload.selectedSortBy,
      };
    case payloadType.FIELDS:
      return {
        ...state,
        selectedFields: action.payload.selectedFields,
      };
    case payloadType.ABOVE_AGE:
      return {
        ...state,
        aboveAge: action.payload.aboveAge,
      };
    case payloadType.BELOW_AGE:
      return {
        ...state,
        belowAge: action.payload.belowAge,
      };
    case payloadType.CASTE:
      return {
        ...state,
        caste: action.payload.caste,
      };
    case payloadType.RELIGION:
      return {
        ...state,
        religion: action.payload.religion,
      };
    default:
      return state;
  }
};
