import React, { useContext, useState } from "react";
import { Title } from "../../../../stories/Title/Title";

import Compare from "../../../../images/Compare.svg";
import RightArrow from "../../../../images/DownArrow.svg";
import ApprovedStatus from "../../../../images/ApprovedStatus.svg";
import ViewStatus from "../../../../images/ViewStatus.svg";
import RejectedStatus from "../../../../images/RejectedStatus.svg";
import Close from "../../../../images/Close.svg";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { TextField, Tooltip } from "@mui/material";
import Modal from "react-modal";
import {
  ApprovedStatusModal,
  CheckerRequestsStyles,
} from "../../../../styles/ModalStyles";
import View, { PendingStatusFor } from "./View";
import Status from "./Status";
import { useStyles } from "../../../../styles/TooltipStyles";

import RejectedRequestImage from "../../../../images/RejectedRequest.svg";
import ApprovedRequestImage from "../../../../images/ApprovedRequest.svg";
import ForwardRequestImage from "../../../../images/ForwardRequest.svg";
import useMkckVoucherData from "../../hooks/useMkckVoucherData";
import { MkCkQueryType } from "../../common/Enum.types";
import { toStandardDate } from "../../../../utils/UtilFunctions";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import DeleteModal from "../../../../pages/DeleteModal";
import { DeleteAcctVoucherMasterMkCkAndDetails } from "../../queries/receipts/mutation/Index";
import { useMutation } from "@apollo/client";
import { Direction, Operation } from "../../../../utils/Enum.types";
import MessageModal from "../../../../pages/MessageModal";
import { msgType } from "../../../../utils/Form.types";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { GetAcctVouchersMkCk } from "../../queries/Vouchers/query";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  pageType: MkCkQueryType;
  type: PendingStatusFor;
}

const ApprovedRequest = ({ setModalFlag, pageType, type }: Props) => {
  const tooltipClasses = useStyles();
  const { InstId } = useParams();
  const [viewModal, setViewModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const { token } = useToken();
  const [approvedRequest, setApprovedRequest] = useState(false);
  const [rejectedRequest, setRejectedRequest] = useState(false);
  const [forwardRequest, setForwardRequest] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [mkckId, setMkckId] = useState(0);
  const handleToggleApproved = () => setApprovedRequest(!approvedRequest);
  const handleToggleRejected = () => setRejectedRequest(!rejectedRequest);
  const handleToggleForward = () => setForwardRequest(!forwardRequest);
  const { state, dispatch } = useContext(AppContext);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { user_details } = useLoggedInUserDetails();
  const [DeleteMkckVoucher] = useMutation(
    DeleteAcctVoucherMasterMkCkAndDetails,
    {
      onError: (e) => {
        setDeleteModal(!deleteModal);
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        });
      },
    }
  );

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setMkckId(0);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const renderModal = (
    pageType: MkCkQueryType,
    isOpen: boolean,
    handleClose: () => void
  ) => (
    <Modal
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      style={CheckerRequestsStyles}
    >
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">
          <ApprovedRequest
            setModalFlag={handleClose}
            pageType={pageType}
            type={PendingStatusFor.CHECKER}
          />
        </div>
        <div className="modal-flex__image">
          <img src={Close} alt="/" onClick={handleClose} />
        </div>
      </div>
    </Modal>
  );
  const handleDelete = (id: number) => {
    DeleteMkckVoucher({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctVouchersMkCk,
          variables: {
            finYrId: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            input: { pr_emp_id: state.empLoginId, query_type: pageType },
            instId: InstId!,
            orderBy: { direction: Direction.ASC },
            token,
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Deleted Sucessfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  const { mkckVouchers } = useMkckVoucherData(pageType, EMPTY_STRING);
  return (
    <>
      <Title>
        {pageType === MkCkQueryType.MK_CK_APPROVED
          ? "Approved Requests"
          : pageType === MkCkQueryType.MK_CK_REJECTED
          ? "Rejected Requests"
          : pageType === MkCkQueryType.MK_CK_FORWARDED
          ? "Forwarded Requests"
          : "Pending Requests"}
      </Title>
      <div className="vouchers-approved-request">
        <div className="row g-0 vouchers-approved-request__filters">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col-2"></div>

          <div className="col vouchers-approved-request__filters--approved">
            <b
              className={
                pageType === MkCkQueryType.MK_CK_APPROVED
                  ? "LabelPrimary"
                  : pageType === MkCkQueryType.MK_CK_REJECTED
                  ? "font-red"
                  : "Label"
              }
            >
              {mkckVouchers.data?.GetAcctVouchersMkCk.totalCount}
            </b>
            <span className="Label">
              {pageType === MkCkQueryType.MK_CK_APPROVED
                ? "Approved"
                : pageType === MkCkQueryType.MK_CK_REJECTED
                ? "Rejected"
                : pageType === MkCkQueryType.MK_CK_FORWARDED
                ? "Forwarded"
                : "Pending"}
            </span>
          </div>
          <div className="col-4 vouchers-approved-request__filters--date">
            <TextField
              className="vouchers-approved-request__textfield"
              label="Start Date"
              InputLabelProps={{ shrink: true }}
              type="date"
            />
            <img src={Compare} alt="/" />

            <TextField
              className="vouchers-approved-request__textfield"
              label="End Date"
              InputLabelProps={{ shrink: true }}
              type="date"
            />
          </div>
        </div>
        <div className="vouchers-approved-request__datablock">
          <ul>
            {mkckVouchers.data?.GetAcctVouchersMkCk.edges.map((edge, index) => {
              return (
                <React.Fragment key={index}>
                  <li>
                    <div
                      className="vouchers-approved-request__datablock--li"
                      onClick={() => {
                        setViewModal(!viewModal);
                        dispatch({
                          type: payloadTypes.SET_MK_CK_ID,
                          payload: { mkckId: edge.node.id },
                        });
                        dispatch({
                          type: payloadTypes.SET_MKCK_CREATED_ID,
                          payload: {
                            createdMkckId: edge.node.created_emp_details.id,
                          },
                        });
                      }}
                    >
                      <div>
                        <div className="vouchers-approved-request__datablock--v-details">
                          <b>
                            {index + 1}. {"Payment Voucher"}
                          </b>
                          <span> (Voucher No : {edge.node.mkck_no})</span>
                        </div>
                        <span className="vouchers-approved-request__datablock--approvedby">
                          {pageType === MkCkQueryType.MK_CK_APPROVED
                            ? "Approved By"
                            : pageType === MkCkQueryType.MK_CK_REJECTED
                            ? "Rejected By"
                            : pageType === MkCkQueryType.MK_CK_FORWARDED
                            ? "Forwarded"
                            : "Pending"}
                        </span>
                        <div className="vouchers-approved-request__datablock--approved-details">
                          <span>
                            {
                              edge.node.created_emp_details
                                .pr_designation_details.designation_desc
                            }
                          </span>
                          {/* <img src={ApprovedBy} alt="/" /> */}
                          <span>
                            (
                            {edge.node.created_emp_details.emp_first_name +
                              edge.node.created_emp_details.emp_middle_name +
                              edge.node.created_emp_details.emp_last_name}
                            )
                            {/* <img
                              src={Delete}
                              alt=""
                              onClick={() => {
                                setMkckId(edge.node.id);
                                setDeleteModal(!deleteModal);
                              }}
                            />
                            <button
                              onClick={() => {
                                setViewModal(!viewModal);
                                dispatch({
                                  type: payloadTypes.SET_MK_CK_ID,
                                  payload: { mkckId: edge.node.id },
                                });
                                dispatch({
                                  type: payloadTypes.SET_MKCK_CREATED_ID,
                                  payload: {
                                    createdMkckId:
                                      edge.node.created_emp_details.id,
                                  },
                                });
                              }}
                            >
                              View
                            </button> */}
                          </span>
                        </div>
                        <div className="vouchers-approved-request__datablock--remarks">
                          {edge.node.remarks}
                        </div>
                      </div>
                      <div className="vouchers-approved-request__datablock--date">
                        {toStandardDate(edge.node.mkck_date)}
                      </div>
                    </div>
                    <div className="vouchers-approved-request__datablock--approved-status">
                      <div
                        className="vouchers-approved-request__datablock--image"
                        onClick={() => {
                          dispatch({
                            type: payloadTypes.SET_MK_CK_ID,
                            payload: { mkckId: edge.node.id },
                          });
                          setStatusModal(!statusModal);
                        }}
                      >
                        {pageType === MkCkQueryType.MK_CK_APPROVED ? (
                          <>
                            <img
                              src={ApprovedStatus}
                              alt=""
                              onClick={() => {
                                setViewModal(!viewModal);
                                dispatch({
                                  type: payloadTypes.SET_MK_CK_ID,
                                  payload: { mkckId: edge.node.id },
                                });
                              }}
                            />

                            <span>Approved</span>
                          </>
                        ) : pageType === MkCkQueryType.MK_CK_REJECTED ? (
                          <>
                            <img
                              src={RejectedStatus}
                              alt=""
                              onClick={() => {
                                setViewModal(!viewModal);
                                dispatch({
                                  type: payloadTypes.SET_MK_CK_ID,
                                  payload: { mkckId: edge.node.id },
                                });
                              }}
                            />
                            <span>Rejected</span>
                          </>
                        ) : pageType === MkCkQueryType.MK_CK_FORWARDED ? (
                          <>
                            <img
                              src={ForwardRequestImage}
                              alt=""
                              onClick={() => {
                                setViewModal(!viewModal);
                                dispatch({
                                  type: payloadTypes.SET_MK_CK_ID,
                                  payload: { mkckId: edge.node.id },
                                });
                              }}
                            />
                            <span>Forwarded</span>
                          </>
                        ) : (
                          <>
                            <img src={ViewStatus} alt="" />
                            <span>View Status</span>
                          </>
                        )}
                      </div>
                      <div
                        className="vouchers-approved-request__datablock--arrow"
                        onClick={() => setViewModal(!viewModal)}
                      >
                        <img src={RightArrow} alt="" />
                      </div>
                    </div>
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
        </div>
        <div className="row g-0">
          <div className="col">
            <Button mode="clear" />
            <Button mode="cancel" onClick={() => setModalFlag(false)} />
          </div>
          {pageType === MkCkQueryType.MK_CK_PENDING &&
            type === PendingStatusFor.CHECKER && (
              <div className="payment-voucher__icons col-4">
                <Tooltip
                  title="Approved Request"
                  placement="top"
                  classes={{
                    tooltip: tooltipClasses.customTooltipGrey,
                    arrow: tooltipClasses.customArrowGrey,
                  }}
                  arrow
                >
                  <img
                    src={ApprovedRequestImage}
                    alt="/"
                    onClick={() => setApprovedRequest(!approvedRequest)}
                  />
                </Tooltip>
                <Tooltip
                  title="Rejected Request"
                  placement="top"
                  classes={{
                    tooltip: tooltipClasses.customTooltipGrey,
                    arrow: tooltipClasses.customArrowGrey,
                  }}
                  arrow
                >
                  <img
                    src={RejectedRequestImage}
                    alt="/"
                    onClick={() => setRejectedRequest(!rejectedRequest)}
                  />
                </Tooltip>
                <Tooltip
                  title="Forwarded Request"
                  placement="top"
                  classes={{
                    tooltip: tooltipClasses.customTooltipGrey,
                    arrow: tooltipClasses.customArrowGrey,
                  }}
                  arrow
                >
                  <img
                    src={ForwardRequestImage}
                    alt="/"
                    onClick={() => setForwardRequest(!forwardRequest)}
                  />
                </Tooltip>
              </div>
            )}
        </div>

        <Modal
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          isOpen={viewModal}
          style={CheckerRequestsStyles}
        >
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <View
                setModalFlag={setViewModal}
                pageType={pageType}
                type={type}
              />
            </div>
            <div className="modal-flex__image">
              <img
                src={Close}
                alt="/"
                className="modal-close-icon"
                onClick={() => setViewModal(!viewModal)}
              />
            </div>
          </div>
        </Modal>

        <Modal
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          isOpen={statusModal}
          style={ApprovedStatusModal}
        >
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <Status />
            </div>
            <div className="modal-flex__image">
              <img
                src={Close}
                alt="/"
                className="modal-close-icon"
                onClick={() => setStatusModal(!statusModal)}
              />
            </div>
          </div>
        </Modal>
      </div>
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDelete}
        id={mkckId}
      />

      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
      {renderModal(
        MkCkQueryType.MK_CK_APPROVED,
        approvedRequest,
        handleToggleApproved
      )}
      {renderModal(
        MkCkQueryType.MK_CK_REJECTED,
        rejectedRequest,
        handleToggleRejected
      )}
      {renderModal(
        MkCkQueryType.MK_CK_FORWARDED,
        forwardRequest,
        handleToggleForward
      )}
    </>
  );
};

export default ApprovedRequest;
