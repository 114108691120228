import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Autocomplete,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";

import LoadingModal from "../../../../pages/LoadingModal";
import Home from "../../Home/Index";

import { TableHeaderProps } from "../../../../Types/Tables";
import { HRTitleProps } from "../../../../Types/Titles";
import { msgType, responseType } from "../../../../utils/Form.types";
import { Direction, Operation, SortBy } from "../../../../utils/Enum.types";
import { Keys } from "../../../../utils/Enum.keys";
import Modal from "react-modal";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  FETCH_MORE_DATA,
  NOTALLOCATED,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";

import {
  getModifiedScrollHeight,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";

import useEmpMasterData from "../../hooks/useEmpMasterData";
import useEmployee, {
  empQueryTypes,
  PayRollEmpEdges,
  PrEmpQueryType,
} from "../../hooks/useEmployee";

import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";
import { HYPHEN } from "../../constants";

import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { AntSwitch } from "../../../../pages/Switch";
import { useMutation } from "@apollo/client";
import { EnablePayRollEmpMasterLoginStatusWithNewEmail } from "../../queries/employee/mutation";
import Close from "../../../../images/Close.svg";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import { GetPayRollEmp } from "../../queries/employee/query";
import { InstitutionCustomStyles } from "../../../../styles/ModalStyles";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { UpdateEmpLoginStatus } from "../../../../queries/students/mutations/new";
import useCheckAllocationType from "../../../Academics/hooks/useCheckAllocationType";

const { HRTitles } = require("../../json/title.json");
const { HR_Table } = require("../../json/table.json");

const Index = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { user_details } = useLoggedInUserDetails();

  const navigate = useNavigate();
  const { InstId } = useParams();

  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_GRADE,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [searchData, setSearchData] = useState("");
  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [departmentId, setDepartmentId] = useState<responseType | null>(null);
  const [designationId, setDesignationId] = useState<responseType | null>(null);
  const [categoryId, setCategoryId] = useState<responseType | null>(null);
  const [gradeId, setGradeId] = useState<responseType | null>(null);
  const [jobTypeId, setJobTypeId] = useState<responseType | null>(null);
  const { token } = useToken();
  const [empId, setEmpId] = useState(0);
  const [emailModal, setEmailModal] = useState(false);
  const [eMail, setEmail] = useState("");

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { flag } = useCheckAllocationType();
  const {
    empDetails: { data, loading: empDetailsLoading, fetchMore },
  } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.GENERAL,
    searchData,
    0,
    departmentId ? departmentId.value : 0,
    designationId ? designationId.value : 0,
    categoryId ? categoryId.value : 0,
    gradeId ? gradeId.value : 0,
    jobTypeId ? jobTypeId.value : 0
  );
  const [EnableLogin, { loading: EnableLoading }] = useMutation(
    UpdateEmpLoginStatus,
    {
      onError: (e) => {
        setEmailModal(!emailModal);
      },
    }
  );
  const [UpdateEmail, { loading }] = useMutation(
    EnablePayRollEmpMasterLoginStatusWithNewEmail,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const TableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          {HR_Table.EmployeeList.Login_Page.map(
            (th: TableHeaderProps, index: React.Key) => {
              return (
                <TableCell key={index} className={th.className}>
                  {th.labelName}
                </TableCell>
              );
            }
          )}
        </TableRow>
      </TableHead>
    );
  };
  const {
    departmentDropDown,
    designationDropDown,
    jobTypeDropDown,
    gradeDropDown,
    categoryDropDown,
  } = useEmpMasterData();

  const handleSubmit = (pr_emp_id: number) => {
    const flagData = data?.GetPayRollEmp.edges.find(
      (data) => data.node.id === pr_emp_id
    )?.node;
    EnableLogin({
      variables: {
        token,
        pr_emp_id,
        login_status: !flagData?.is_login_created,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetPayRollEmp,
          variables: {
            after: null,
            direction: Direction.ASC,
            first: ROWS_PER_PAGE,
            input: {
              ids: InstId,
              pr_emp_query_type: PrEmpQueryType.EMP_BY_INST_ID,
            },
            per_std_subj_allocation: flag,
            name: "",
            departmentId: departmentId ? departmentId : null,
            designationId: designationId ? designationId : null,
            categoryId: categoryId ? categoryId : null,
            gradeId: gradeId ? gradeId : null,
            jobTypeId: jobTypeId ? jobTypeId : null,
            employeeId: null,
            sortBy: SortBy.EMP_FIRST_NAME,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: !flagData?.is_login_created
            ? "Employee Login Enabled Successfully"
            : "Employee Login Disabled Successfully",
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const handleUpdate = () => {
    UpdateEmail({
      variables: {
        token,
        pr_emp_id: empId,
        email: eMail,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetPayRollEmp,
          variables: {
            after: null,
            direction: Direction.ASC,
            first: ROWS_PER_PAGE,
            input: {
              ids: InstId,
              pr_emp_query_type: PrEmpQueryType.EMP_BY_INST_ID,
            },
            per_std_subj_allocation: flag,
            name: "",
            departmentId: departmentId ? departmentId : null,
            designationId: designationId ? designationId : null,
            categoryId: categoryId ? categoryId : null,
            gradeId: gradeId ? gradeId : null,
            jobTypeId: jobTypeId ? jobTypeId : null,
            employeeId: null,
            sortBy: SortBy.EMP_FIRST_NAME,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Employee E-mail Updated Successfully",
          operation: Operation.UPDATE,
        });
        setEmailModal(!emailModal);
      }
    });
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !empDetailsLoading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetPayRollEmp.edges;
            const pageInfo = fetchMoreResult.GetPayRollEmp.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetPayRollEmp.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetPayRollEmp: {
                edges: [...employees, ...newEdges],
                pageInfo,
                totalCount: data?.GetPayRollEmp.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetPayRollEmp.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = employees.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setEmployees(updatedNewData);
      } else {
        setEmployees(newData);
      }
      setHasNextPage(data.GetPayRollEmp.pageInfo.hasNextPage);
      setEndCursor(data.GetPayRollEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {HRTitles.Employee_List.Titles.map(
          (title: HRTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Login}</React.Fragment>;
          }
        )}
      </Title>
      <div className="employee-list">
        <div className={"row g-0 employee-list__details"}>
          <div className="col-1">
            <Input
              id="search"
              placeholder="Search..."
              type="text"
              onChange={(e) => setSearchData(e.target.value)}
            />
          </div>

          {USE_HR_CATEGORY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={categoryDropDown!}
                onChange={(e, newValue) => {
                  if (newValue) setCategoryId(newValue as responseType);
                  else setCategoryId(EMPTY_RESPONSETYPE_OBJECT);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE)
                    setCategoryId(EMPTY_RESPONSETYPE_OBJECT);
                }}
                openOnFocus
                freeSolo
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                     label="Category"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_HR_DEPARTMENT ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={departmentDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentId)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentId(newValue as responseType);
                  } else {
                    setDepartmentId(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentId(null);
                  }
                }}
                openOnFocus
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Departments"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_HR_DESIGNATION ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={designationDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, designationId)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDesignationId(newValue as responseType);
                  } else {
                    setDesignationId(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setDesignationId(null);
                  }
                }}
                openOnFocus
                freeSolo
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Designation"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_HR_GRADE ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={gradeDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, gradeId)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setGradeId(newValue as responseType);
                  } else {
                    setGradeId(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setGradeId(null);
                  }
                }}
                openOnFocus
                freeSolo
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Grade"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_HR_JOBTYPE ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={jobTypeDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, jobTypeId)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setJobTypeId(newValue as responseType);
                  } else {
                    setJobTypeId(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setJobTypeId(null);
                  }
                }}
                openOnFocus
                freeSolo
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Job Type"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col"></div>
        </div>
        <div className={"employee-list__tableblock"}>
          <TableContainer
            className="employee-list__tableblock--table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHeader />
              <TableBody>
                {data
                  ? data.GetPayRollEmp.edges.map((edge, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            className="employee-list__tableblock--table"
                            id="td-center"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            className="employee-list__tableblock--table"
                            id="td-center"
                          >
                            {edge.node.emp_id}
                          </TableCell>
                          <TableCell>
                            {edge.node.emp_first_name +
                              " " +
                              edge.node.emp_middle_name +
                              " " +
                              edge.node.emp_last_name}
                          </TableCell>
                          {USE_HR_DEPARTMENT ? (
                            <TableCell className="employee-list__tableblock--table--desc">
                              {edge.node.pr_dept_details.dept_desc ===
                              NOTALLOCATED
                                ? HYPHEN
                                : edge.node.pr_dept_details.dept_desc}
                            </TableCell>
                          ) : null}

                          {USE_HR_DESIGNATION ? (
                            <TableCell className="employee-list__tableblock--table--desc">
                              {edge.node.pr_designation_details
                                .designation_desc === NOTALLOCATED
                                ? HYPHEN
                                : edge.node.pr_designation_details
                                    .designation_desc}
                            </TableCell>
                          ) : null}
                          <TableCell>{edge.node.emp_email}</TableCell>
                          <TableCell
                            className="employee-list__tableblock--table--actions"
                            id="td-center"
                          >
                            <FormControlLabel
                              label=""
                              control={
                                <AntSwitch
                                  checked={edge.node.is_login_created}
                                  onClick={() => {
                                    handleSubmit(edge.node.id);
                                    setEmpId(edge.node.id);
                                  }}
                                />
                              }
                              labelPlacement="start"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={emailModal}
        style={InstitutionCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>
              {HRTitles.Employee_List.Titles.map(
                (title: HRTitleProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>{title.Email}</React.Fragment>
                  );
                }
              )}
            </Title>
            <br></br>
            <div className="col">
              <TextField
                className="submission-details__textfield"
                label="New E-mail"
                value={eMail}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setEmailModal(!emailModal)}
            />
          </div>
        </div>
        <Button type="submit" mode="save" onClick={handleUpdate} />

        <Button
          mode="cancel"
          type="button"
          onClick={() => setEmailModal(!emailModal)}
        />
      </Modal>
      <LoadingModal flag={EnableLoading || loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;
