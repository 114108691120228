import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Home from "../../Home/Index";
import List from "./List";
import Input from "../../../../stories/Input/Input";
import {
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DataGridTableStyles";
import { useNavigate, useParams } from "react-router-dom";
import {
  DataGridPro,
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { Button } from "../../../../stories/Button/Button";
import { Autocomplete, TextField } from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import useEmpMasterData from "../../../HR/hooks/useEmpMasterData";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import { responseType } from "../../../../utils/Form.types";
import { Keys } from "../../../../utils/Enum.keys";
import Marked from "../../../../images/ApprovedStatus.svg";
import { GetAcdAttDailyMarkedStatus } from "../../queries/holidays/list";
import { useLazyQuery } from "@apollo/client";
import {
  attendanceMarkedDetails,
  GetAcdAttDailyMarkedStatusData,
  GetAcdAttDailyMarkedStatusDetails,
  GetAcdAttDailyMarkedStatusVars,
} from "../../types/attendance";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import { TODAY_DATE } from "../../../../utils/constants";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
interface ProfileDetails {
  profile_pic: string;
  sessions: string[];
}
const TeacherSession = () => {
  const dataClasses = useDataGridStyles();
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { designationDropDown } = useEmpMasterData();
  const [designationSelected, setDesignationSelected] =
    useState<responseType | null>(null);
  const [sessions, setSessions] = useState<attendanceMarkedDetails[]>([]);

  const [empSessions, setEmpSessions] = useState<Map<number, ProfileDetails>>(
    new Map()
  );

  const [dateSelected, setDateSelected] = useState(
    toInputStandardDate(TODAY_DATE)
  );

  const [GetAcdAttDailyMarkedStatusDetails, { data }] = useLazyQuery<
    GetAcdAttDailyMarkedStatusData,
    GetAcdAttDailyMarkedStatusVars
  >(GetAcdAttDailyMarkedStatus, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      attendance_date: dateSelected
        ? toIsoDate(dateSelected)
        : toIsoDate(serverDate),
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
  useEffect(() => {
    if (token && state.ActiveAcdYr && InstId) {
      GetAcdAttDailyMarkedStatusDetails().then(({ data }) => {
        const temp = empSessions;

        let tempSessions = sessions;
        let tempSessionDescriptions = sessions.map(
          ({ session_desc }) => session_desc
        );

        if (data && data.GetAcdAttDailyMarkedStatus) {
          data.GetAcdAttDailyMarkedStatus.map((emp) => {
            emp.att_marked_details.map((sess) => {
              if (
                tempSessionDescriptions.includes(sess.session_desc) === false
              ) {
                tempSessionDescriptions = [
                  sess.session_desc,
                  ...tempSessionDescriptions,
                ];
                tempSessions = [sess, ...tempSessions];
              }
            });
            temp.set(emp.pr_emp_id, {
              profile_pic: emp.emp_profile_filename,
              sessions: emp.att_marked_details.map((i) => i.session_desc),
            });
          });

          setSessions(tempSessions);
        }
        setEmpSessions(temp);
      });
    }
  }, [GetAcdAttDailyMarkedStatusDetails, token, state.ActiveAcdYr, data]);

  const columns: GridColDef[] = [
    {
      headerName: "Sl No.",
      cellClassName: "td-sl-no",
      field: "id",
      headerAlign: "center" as GridAlignment,
      align: "center" as GridAlignment,
    },
    {
      headerName: "Emp Name",
      cellClassName: "td-name",
      field: "emp_name",
      headerAlign: "center" as GridAlignment,
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.emp_name}</>;
      },
    },
    ...((data &&
      sessions.length > 0 &&
      sessions
        .map((detail) => {
          return {
            headerName: detail.session_desc,
            cellClassName: "td-name",
            field: `${detail.session_idx}`,
            headerAlign: "center" as GridAlignment,
            align: "center" as GridAlignment,
            renderCell: (params: GridCellParams) => {
              const enabled = params.row[`${detail.session_desc}`];

              return (
                <>
                  {enabled ? (
                    <img src={Marked} alt="" style={{ width: "25px" }} />
                  ) : (
                    "-"
                  )}
                </>
              );
            },
          };
        })
        .sort((a, b) => {
          return Number(a.field) - Number(b.field);
        })) ||
      []),

    {
      headerName: "Total Sessions Marked",
      cellClassName: "td-name",
      field: "total_session",
      headerAlign: "center" as GridAlignment,
      align: "center" as GridAlignment,
    },
  ];

  const getStatus = (id: number) => {
    const dynamicColumns: { [key: string]: boolean } = sessions.length
      ? sessions.reduce((acc, itm) => {
          const descExists = empSessions.get(id)
            ? empSessions.get(id)?.sessions.includes(itm.session_desc)
            : false;
          acc[`${itm.session_desc}`] = descExists ? true : false;
          return acc;
        }, {} as { [key: string]: boolean })
      : {};

    return dynamicColumns;
  };
  const rows: GridValidRowModel[] = (
    data && data.GetAcdAttDailyMarkedStatus
      ? data.GetAcdAttDailyMarkedStatus
      : []
  ).map((edge, index) => {
    return {
      id: index + 1,
      emp_name: edge.pr_emp_name,
      ...getStatus(edge.pr_emp_id),
      total_session: edge.att_marked_details.length,
    };
  });

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Attendance Report</Title>
      <div className="att-report">
        <div className="att-report__block">
          <div className="att-report__block--left">
            <List />
          </div>
          <div className="att-report__block--right">
            <div className="att-report__block--right--select row g-0">
              <div className="col-2">
                <Input id="search" placeholder="Search..." />
              </div>
              <div className="col-2">
                <Autocomplete
                  classes={classes}
                  options={designationDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, designationSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (designationSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setDesignationSelected(null);
                    }
                  }}
                  openOnFocus
                  value={designationSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setDesignationSelected(newValue);
                    } else {
                      setDesignationSelected(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Designation"
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
              <div className="col"></div>
              <div className="col-2 flex-end">
                <TextField
                  value={
                    dateSelected
                      ? dateSelected
                      : toInputStandardDate(TODAY_DATE)
                  }
                  className="att-report__block--right--textfield"
                  InputLabelProps={{ shrink: true }}
                  label="Date"
                  type="date"
                  onChange={(e) => {
                    setDateSelected(e.target.value);
                  }}
                />
              </div>
            </div>

            <div
              className={`att-report__block--right--tableblock ${dataClasses.root}`}
            >
              <DataGridPro
                columns={columns}
                rows={rows}
                rowHeight={TABLE_ROW_HEIGHT}
                disableRowSelectionOnClick
                hideFooter
                slotProps={{
                  columnsPanel: { disableHideAllButton: true },
                }}
              />
            </div>
            <div className="row g-0">
              <div className="col">
                <Button mode="excel">Export</Button>
                <Button mode="back" onClick={() => navigate(-1)} />
              </div>
              <div className="col-2 flex-end">
                <div className="student-total-count">
                  Total Employee's{" "}
                  <b>{data && data.GetAcdAttDailyMarkedStatus.length}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherSession;
