import React, { useContext, useEffect, useState } from "react";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";
import TotalTest from "../../../images/TotalTestPlanned.svg";
import TestYetToComplete from "../../../images/TestYetToComplete.svg";
import CompletedTest from "../../../images/CompletedTest.svg";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import More from "../../../images/More.svg";
import View from "../../../images/EyeWhite.svg";
import Close from "../../../images/Close.svg";
import Modal from "react-modal";
import CreateTest from "../Test/TestCreation/Index";
import { ViewPickedSubjectModalStyles } from "../../../styles/ModalStyles";
import {
  InstitutionConfigurationTypes,
  Operation,
} from "../../../utils/Enum.types";
import Add from "../../../images/Add.svg";
import { useStyles } from "../../../styles/TooltipStyles";
import Edit from "../../../images/EditBlue.svg";
import Delete from "../../../images/DeleteRed.svg";
import { CustomModalProps } from "../Dashboard/Teacher/Index";
import useTests, { GetAcdTestNameDetails } from "../hooks/useTests";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import useAcdTestTypes from "../hooks/useAcdTestTypes";
import { msgType, responseType } from "../../../utils/Form.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetTestPlanningDashBoardCount } from "../../../queries/common";
import useToken from "../../../customhooks/useToken";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import { toInputStandardDate, toIsoDate } from "../../../utils/UtilFunctions";
import { EMPTY_STRING, TODAY_DATE } from "../../../utils/constants";
import DeleteModal from "../../../pages/DeleteModal";
import { DeleteAcdTestNameById } from "../queries/holidays/mutation";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { GetAcdTestName } from "../queries/test/query";
import MessageModal from "../../../pages/MessageModal";
import { AppContext } from "../../../context/context";
import GradingScale from "./Grade/Index";

const Index = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const tooltipclasses = useStyles();
  const navigate = useNavigate();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const [testId, setTestId] = useState(0);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [gradeModal, setGradeModal] = useState(false);
  const { InstId } = useParams();
  const [createTest, setCreateTest] = useState(false);
  const [editTest, setEditTest] = useState(false);

  const [searchTest, setSearchTest] = useState("");

  const [testType, setTestType] = useState<responseType | null>(null);
  const { acdTests } = useTests(false, testType ? testType.value : 0);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const [operation, setOperation] = useState(Operation.CREATE);
  const { activeAcademicYearData } = useActiveAcademicYear();
  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;
  const { acdTestTypes } = useAcdTestTypes();
  const [GetExamDashBoardData, { data: DashBoardData }] = useLazyQuery(
    GetTestPlanningDashBoardCount
  );

  const testTypeDropDown = acdTestTypes.responseType;
  const allocationLevel =
    configData.data?.GetSwConfigVariables[0].config_string_value!;
  const CustomModal: React.FC<CustomModalProps> = ({
    isOpen,
    onRequestClose,
    contentComponent,
  }) => {
    return (
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={ViewPickedSubjectModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">{contentComponent}</div>
          <div className="modal-flex__image">
            <img src={Close} alt="Close" onClick={onRequestClose} />
          </div>
        </div>
      </Modal>
    );
  };
  const [DeleteTest] = useMutation(DeleteAcdTestNameById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const HandleDelete = (id: Number) => {
    DeleteTest({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdTestName,
          variables: {
            inst_id: InstId!,
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            alloted_level: EMPTY_STRING,
            allotted_id: 0,
            for_planning: false,
            test_type_id: testType ? testType.value : 0,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Test Name Deleted Successfully",
          operation: Operation.DELETE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setDeleteModal(!DeleteModal);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (token && InstId && state.ActiveAcdYr) {
      GetExamDashBoardData({
        variables: {
          token,
          inst_id: InstId,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          date_of_attendance: toIsoDate(toInputStandardDate(TODAY_DATE)),
        },
      });
    }
  }, [token, GetExamDashBoardData, InstId, state.ActiveAcdYr]);
  const searchTests = (test: GetAcdTestNameDetails) => {
    return (
      test.test_name.toLowerCase().includes(searchTest.toLowerCase()) ||
      searchTest === EMPTY_STRING
    );
  };
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Exam/Test Planning</Title>
      <div className="test-planner">
        <div className="test-planner__cards row g-0">
          <div className="col test-planner__cards--list">
            <div className="test-planner__cards--list--grid">
              <img src={TotalTest} alt="" />
              <span>Total Test Planned</span>
              <b className="font-blue">
                {DashBoardData
                  ? DashBoardData?.GetTestPlanningDashBoardCount
                      .total_tests_planned
                  : 0}
              </b>
            </div>
            <div className="test-planner__cards--list--grid">
              <img src={TestYetToComplete} alt="" />
              <span>Test Yet To Complete</span>
              <b className="font-blue">
                {DashBoardData
                  ? DashBoardData?.GetTestPlanningDashBoardCount
                      .total_tests_yet_comp
                  : 0}
              </b>
            </div>
            <div className="test-planner__cards--list--grid">
              <img src={CompletedTest} alt="" />
              <span>Completed Test</span>
              <b className="font-blue">
                {DashBoardData
                  ? DashBoardData?.GetTestPlanningDashBoardCount
                      .total_tests_comp
                  : 0}
              </b>
            </div>
          </div>
          <div className="col-3">
            <Title variant="subtitle1">
              Subjects Allocation Level :
              <Label variant="LabelPrimary">{allocationLevel}</Label>
            </Title>
          </div>
        </div>
        <div className="row g-0 test-planner__filters">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search..."
              onChange={(e) => {
                setSearchTest(e.target.value);
              }}
            />
          </div>

          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={testTypeDropDown}
              value={testType}
              onChange={(e, newValue) => {
                if (newValue) {
                  setTestType(newValue);
                } else {
                  setTestType(null);
                }
              }}
              openOnFocus
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter By"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        </div>
        <div className="test-planner__data">
          <div className="test-planner__data--list">
            {acdTests.data ? (
              acdTests.data.GetAcdTestName.filter(searchTests).map(
                (data, index) => {
                  const endDate = new Date();
                  const presentDate = new Date();

                  const isEndDateBeforePresentDate = endDate < presentDate;

                  const isderivative = data.is_marks_derived;
                  const isNonAcademic = data.is_non_academic;

                  return (
                    <div
                      className={
                        isNonAcademic
                          ? "test-planner__data--grid"
                          : isderivative
                          ? "test-planner__data--grid "
                          : isEndDateBeforePresentDate
                          ? "test-planner__data--grid planned"
                          : "test-planner__data--grid notplanned"
                      }
                      key={index}
                    >
                      <Tooltip
                        classes={{
                          tooltip: tooltipclasses.studentOptions,
                        }}
                        placement="left-start"
                        title={
                          <ul>
                            <li
                              className="test-planner__data--li"
                              onClick={() => {
                                setEditTest(!editTest);
                                setTestId(data.id);
                                setOperation(Operation.UPDATE);
                              }}
                            >
                              <img src={Edit} alt="/" />
                              &nbsp;
                              <span>Edit Test</span>
                            </li>
                            <li
                              className="test-planner__data--li"
                              onClick={() => {
                                setDeleteModal(!deleteModal);
                                setDeleteId(data.id);
                              }}
                            >
                              <img src={Delete} alt="/" />
                              <span>Delete Test</span>
                            </li>
                          </ul>
                        }
                      >
                        <img
                          src={More}
                          alt=""
                          className="test-planner__data--grid--image"
                        />
                      </Tooltip>

                      <b className="test-planner__data--grid--title">
                        {data.test_name}
                      </b>
                      {isNonAcademic ? (
                        <span className="test-planner__data--grid--non-academic">
                          Non Academic
                        </span>
                      ) : isderivative ? (
                        <span className="test-planner__data--grid--derivative">
                          Derivative
                        </span>
                      ) : (
                        <span className="test-planner__data--grid--subtitle">
                          ( {data.test_type_details.test_type_name} )
                        </span>
                      )}
                      {/* <div className="test-planner__data--grid--flex">
                    <TextField
                      label="Start Date"
                      className="test-planner__data--grid--flex--textfield"
                      type="date"
                      // value={data.sdate}
                      disabled
                      InputLabelProps={{ shrink: true }}
                    />
                    <img src={Compare} alt="" />
                    <TextField
                      label="End Date"
                      className="test-planner__data--grid--flex--textfield"
                      type="date"
                      // value={data.edate}
                      disabled
                      InputLabelProps={{ shrink: true }}
                    />
                  </div> */}
                      {/* <div className="row g-0 test-planner__data--grid--total">
                    <div className="col">
                      <span className="test-planner__data--grid--subtitle">
                        Total Classes
                      </span>
                      <span className="test-planner__data--grid--number">
                        0
                      </span>
                    </div>
                    <div className="col">
                      <span className="test-planner__data--grid--subtitle">
                        Test Planned Classes
                      </span>
                      <span className="test-planner__data--grid--number font-green">
                        0
                      </span>
                    </div>
                    <div className="col">
                      <span className="test-planner__data--grid--subtitle">
                        Test Not Planned Classes
                      </span>
                      <span className="test-planner__data--grid--number font-red">
                        0
                      </span>
                    </div>
                  </div> */}
                      <div className="test-planner__data--grid--view">
                        <button
                          onClick={() => {
                            if (isderivative) {
                              navigate(
                                `/${InstId}/academics/${data.id}/examplanner/view/derivative`
                              );
                            } else if (isNonAcademic) {
                              navigate(
                                `/${InstId}/academics/${data.id}/examplanner/view/nonacademic`
                              );
                            } else
                              navigate(
                                `/${InstId}/academics/${data.id}/examplanner/view`
                              );
                          }}
                        >
                          View Details <img src={View} alt="" />
                        </button>
                      </div>
                    </div>
                  );
                }
              )
            ) : (
              <b className="nodata">
                {acdTests.error ? acdTests.error.message : EMPTY_STRING}
              </b>
            )}
          </div>
        </div>
        <Button
          onClick={() => {
            setCreateTest(!createTest);
            setOperation(Operation.CREATE);
          }}
        >
          <img src={Add} alt="" />
          Create New Test
        </Button>
        <Button mode="grade" onClick={() => setGradeModal(!gradeModal)} />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <CustomModal
        isOpen={createTest}
        onRequestClose={() => setCreateTest(!createTest)}
        contentComponent={
          <CreateTest
            setModal={setCreateTest}
            operation={Operation.CREATE}
            setOperation={setOperation}
          />
        }
      />
      <CustomModal
        isOpen={editTest}
        onRequestClose={() => setEditTest(!editTest)}
        contentComponent={
          <CreateTest
            setModal={setEditTest}
            operation={Operation.UPDATE}
            setOperation={setOperation}
            testId={testId}
          />
        }
      />
      <CustomModal
        isOpen={gradeModal}
        onRequestClose={() => setGradeModal(!gradeModal)}
        contentComponent={<GradingScale setModal={setGradeModal} />}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={deleteId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;
