import React, { useContext, useState } from "react";
import Home from "../Master/configurations/Home/Index";
import { Autocomplete, Tab, Tabs, TextField } from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../styles/AutocompleteListStyles";
import Compare from "../../images/Compare.svg";
import { Title } from "../../stories/Title/Title";
import Input from "../../stories/Input/Input";
import { Button } from "../../stories/Button/Button";
import Avatar from "../../images/Avatar.svg";
import { TabPanel, a11yProps } from "../../styles/Tabs";
import AdmissionsImage from "../../images/Admissions.svg";
import AccountsImage from "../../images/AccountsLogo.svg";
import AcademicsImage from "../../images/AcademicsLogo.svg";
import PayRollImage from "../../images/HR.svg";
import EnquiryImage from "../../images/Enquiry.svg";
import TransportImage from "../../images/Transport.svg";
import { useTheme } from "@mui/material/styles";
import Accounts from "./ModuleWiseDetails/Accounts";
import Admissions from "./ModuleWiseDetails/Admissions";
import { useNavigate } from "react-router-dom";
import useEmpMasterData from "../HR/hooks/useEmpMasterData";
import { responseType } from "../../utils/Form.types";
import {
  DateRange,
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../utils/UtilFunctions";
import DownArrow from "../../images/DownArrow.svg";
import { Keys } from "../../utils/Enum.keys";
import useEmployee, { empQueryTypes } from "../HR/hooks/useEmployee";
import { EMPTY_STRING, TODAY_DATE } from "../../utils/constants";
import { toInputStandardDate } from "../../utils/UtilFunctions";
import { AppContext } from "../../context/context";
import useUserRightsByEmpId from "../UserRights/hooks/useUserRightsByEmpId";
import { payloadTypes } from "../../context/reducer";

const EmployeeLogs = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const [endDate, setEndDate] = useState(toInputStandardDate(TODAY_DATE));
  const [userid, setUserid] = useState(0);

  const { dispatch, state } = useContext(AppContext);

  const {
    departmentDropDown,
    designationDropDown,
    categoryDropDown,
    jobTypeDropDown,
  } = useEmpMasterData();
  let DefaultDate = new Date();
  const dates = DateRange(DefaultDate.toString());

  const [pr_dept, setpr_dept] = useState<responseType | null>(null);
  const [empName, setEmpname] = useState("");
  const [pr_designation, setpr_designation] = useState<responseType | null>(
    null
  );
  const [pr_category, setpr_category] = useState<responseType | null>(null);
  const [pr_job_type, setpr_job_type] = useState<responseType | null>(null);
  const [startDate, setStartDate] = useState(dates?.firstDay!);
  const { empDetails } = useEmployee(
    50,
    empQueryTypes.JUST_LOGIN_ACCTS,
    "",
    0,
    pr_dept?.value!,
    pr_designation?.value!
  );
  const {
    USE_ACCOUNTS,
    USE_PAYROLL,
    USE_ACADEMICS,
    USE_ENQUIRY,
    USE_TRANSPORT,
    USE_ADMISSIONS,
  } = useUserRightsByEmpId();
  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Logs</Title>
      <div className="emp-logs">
        <div className="emp-logs__select row g-0">
          <div className="col-2">
            <Autocomplete
              options={departmentDropDown}
              classes={classes}
              onChange={(e, newValue) => {
                if (newValue) {
                  setpr_dept(newValue);
                } else {
                  setpr_dept(null);
                  setpr_designation(null);
                  setpr_category(null);
                  setpr_job_type(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setpr_dept(null);
                  setpr_designation(null);
                  setpr_category(null);
                  setpr_job_type(null);
                }
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (pr_dept) {
                    handleMUISelectEvent(e);
                  }
                }
              }}
              openOnFocus
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Department"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col-2">
            <Autocomplete
              options={designationDropDown}
              classes={classes}
              value={pr_designation}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, pr_designation)
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setpr_designation(newValue);
                } else {
                  setpr_designation(null);
                  setpr_category(null);
                  setpr_job_type(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setpr_designation(null);
                  setpr_category(null);
                  setpr_job_type(null);
                }
                if (e.key === Keys.ENTER) {
                  e.preventDefault();

                  if (pr_designation) {
                    handleMUISelectEvent(e);
                  }
                }
              }}
              openOnFocus
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Designation"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col-2">
            <Autocomplete
              options={categoryDropDown}
              classes={classes}
              value={pr_category}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, pr_category)
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setpr_category(newValue as responseType);
                } else {
                  setpr_category(null);
                  setpr_job_type(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setpr_category(null);
                  setpr_job_type(null);
                }
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (pr_category) {
                    handleMUISelectEvent(e);
                  }
                }
              }}
              openOnFocus
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={jobTypeDropDown!}
              value={pr_job_type}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, pr_job_type)
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setpr_job_type(newValue);
                } else {
                  setpr_job_type(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setpr_job_type(null);
                }
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (pr_job_type) {
                    handleMUISelectEvent(e);
                  }
                }
              }}
              openOnFocus
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Job Type"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-3">
            <div className="emp-logs__select--flex">
              <TextField
                className="emp-logs__select--textfield"
                type="date"
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: {
                    min: state.ActiveFinYr
                      ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                      : EMPTY_STRING,
                    max: toInputStandardDate(TODAY_DATE),
                  },
                }}
                value={toInputStandardDate(startDate!)}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <img src={Compare} alt="" />
              <TextField
                className="emp-logs__select--textfield"
                type="date"
                label="End Date"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: {
                    min: state.ActiveFinYr
                      ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                      : EMPTY_STRING,
                    max: toInputStandardDate(TODAY_DATE),
                  },
                }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="emp-logs__block">
          <div className="emp-logs__block--left">
            <Title variant="subtitle1">Employee Details</Title>
            <Input id="search" placeholder="Search..." />
            <ul className="emp-logs__block--list">
              {empDetails.data
                ? empDetails.data.GetPayRollEmp.edges.map((data, index) => {
                    return (
                      <li
                        className={
                          userid === data.node.id
                            ? "emp-logs__block--grid--active"
                            : "emp-logs__block--grid"
                        }
                        key={index}
                      >
                        <div className="emp-logs__block--grid--profile">
                          <img src={Avatar} alt="" />
                          <div
                            className="emp-logs__block--grid--profile--details"
                            onClick={() => {
                              dispatch({
                                type: payloadTypes.SET_EMPLOYEE_ID,
                                payload: {
                                  employeeId: data.node.id,
                                },
                              });
                              setUserid(data.node.id);

                              setEmpname(
                                data.node.emp_first_name +
                                  " " +
                                  data.node.emp_middle_name +
                                  " " +
                                  data.node.emp_last_name
                              );
                            }}
                          >
                            <span>
                              {data.node.emp_first_name +
                                " " +
                                data.node.emp_middle_name +
                                " " +
                                data.node.emp_last_name}
                            </span>
                            <b>
                              {
                                data.node.pr_designation_details
                                  .designation_desc
                              }
                            </b>
                          </div>
                        </div>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
          <div className="emp-logs__block--right">
            <div className="emp-logs__block--tabs">
              <Tabs value={value} onChange={handleChange}>
                {USE_ACCOUNTS && userid ? (
                  <Tab
                    label={
                      <div className="emp-logs__block--tabs--flex">
                        <img src={AccountsImage} alt="" />
                        Accounts
                        {/* <span>20</span> */}
                      </div>
                    }
                    value={0}
                    {...a11yProps(0)}
                  />
                ) : null}
                {USE_ADMISSIONS && userid ? (
                  <Tab
                    label={
                      <div className="emp-logs__block--tabs--flex">
                        <img src={AdmissionsImage} alt="" />
                        Admissions
                        {/* <span>20</span> */}
                      </div>
                    }
                    value={1}
                    {...a11yProps(1)}
                  />
                ) : null}

                {USE_ACADEMICS && userid ? (
                  <Tab
                    label={
                      <div className="emp-logs__block--tabs--flex">
                        <img src={AcademicsImage} alt="" />
                        Academics
                        {/* <span>20</span> */}
                      </div>
                    }
                    value={2}
                    {...a11yProps(2)}
                  />
                ) : null}
                {USE_PAYROLL && userid ? (
                  <Tab
                    label={
                      <div className="emp-logs__block--tabs--flex">
                        <img src={PayRollImage} alt="" />
                        PayRoll
                        {/* <span>20</span> */}
                      </div>
                    }
                    value={3}
                    {...a11yProps(3)}
                  />
                ) : null}
                {USE_ENQUIRY && userid ? (
                  <Tab
                    label={
                      <div className="emp-logs__block--tabs--flex">
                        <img src={EnquiryImage} alt="" />
                        Enquiry
                        {/* <span>20</span> */}
                      </div>
                    }
                    value={4}
                    {...a11yProps(4)}
                  />
                ) : null}
                {USE_TRANSPORT && userid ? (
                  <Tab
                    label={
                      <div className="emp-logs__block--tabs--flex">
                        <img src={TransportImage} alt="" />
                        Transport
                        {/* <span>20</span> */}
                      </div>
                    }
                    value={5}
                    {...a11yProps(5)}
                  />
                ) : null}
              </Tabs>
            </div>
            {userid ? <Title variant="subtitle1"> {empName}</Title> : null}
            {userid ? (
              <div className="emp-logs__block--tabpanel">
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Accounts
                    endDate={endDate}
                    startDate={startDate}
                    userid={userid}
                    empName={empName}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <Admissions />
                </TabPanel>
              </div>
            ) : null}
          </div>
        </div>
        {/* <Button onClick={() => navigate(`/${InstId}/completelogs`)}>
          <img src={AllLogs} alt="" />
          All Logs
        </Button> */}
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default EmployeeLogs;
