import { TextField } from "@mui/material";
import React from "react";
import Modal from "react-modal";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import { BookDetailsStyles } from "../../../../styles/ModalStyles";
import { LibraryTitles } from "../../../../Types/Titles";

//Images
import Close from "../../../../images/Close.svg";
import LibraryBook from "../../../../images/LibraryBook.svg";
import { bookDetailsModal } from "../../BooksAllocation/Index";
import useLibraryformJson from "../../json/useLibraryformJson";

const { Library } = require("../../../../json/title.json");

interface Props {
  modalFlag: bookDetailsModal;
  setModalFlag: React.Dispatch<
    React.SetStateAction<bookDetailsModal | undefined>
  >;
}
const Index = ({ modalFlag, setModalFlag }: Props) => {
  const { bookDetails } = modalFlag || {};
  const { LibraryFormLabels } = useLibraryformJson();
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={modalFlag?.flag!}
      style={BookDetailsStyles}
      ariaHideApp={false}
    >
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">
          <Title>
            {Library.BookTransaction.Titles.map(
              (title: LibraryTitles, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {title.BookDetails}
                  </React.Fragment>
                );
              }
            )}
          </Title>

          <div className="row">
            <div className="col">
              <div className="books-allocation__details--book">
                <img src={LibraryBook} alt="/" />
              </div>
              <TextField
                id="outlined-uncontrolled"
                label="Price"
                value={bookDetails?.price}
                disabled
                InputLabelProps={{ shrink: true }}
                className="books-allocation__details--shelf-textfield"
              />
              <TextField
                id="outlined-uncontrolled"
                label="No of Pages"
                disabled
                value={bookDetails?.no_of_pages}
                InputLabelProps={{ shrink: true }}
                className="books-allocation__details--shelf-textfield"
              />
            </div>
            <div className="col-10">
              <div className="books-allocation__details--span-2">
                {LibraryFormLabels.ModalBookDetails?.map(
                  (label: any, index: React.Key) => {
                    return (
                      <TextField
                        id="outlined-uncontrolled"
                        label={label.LabelName}
                        className="books-allocation__details--shelf-textfield"
                        key={index}
                        disabled
                        value={bookDetails?.[label.inputName]}
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <Button
            onClick={() => setModalFlag({ flag: false })}
            autoFocus
            mode="cancel"
          />
        </div>
        <div className="modal-flex__image">
          <img
            src={Close}
            alt="/"
            onClick={() => setModalFlag({ flag: false })}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Index;
