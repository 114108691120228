import React, { useContext, useState } from "react";
import DownArrow from "../../../../images/DownArrow.svg";

import { Autocomplete, ClassNameMap, TextField } from "@mui/material";
import { responseType } from "../../../../utils/Form.types";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import useAgents from "../../../../customhooks/useAgents";
import { Label } from "../../../../stories/Label/Label";
import { Keys } from "../../../../utils/Enum.keys";
import { handleMUISelectEvent } from "../../../../utils/UtilFunctions";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";

interface Props {
  styles?: {
    classes: ClassNameMap<string>;
    textClasses: ClassNameMap<string>;
  };
  states?: {
    agentName: responseType | null;
    setAgentName: React.Dispatch<React.SetStateAction<responseType | null>>;
  };

  labelRequired?: boolean;
}
const AgentOptionsAutocomplete = ({ states, styles, labelRequired }: Props) => {
  const { agentName, setAgentName } = states ?? {};

  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();

  const { dispatch } = useContext(AppContext);
  const [searchAgent, setSearchAgent] = useState("");

  const { agentOptions, handleScroll } = useAgents(searchAgent);

  return (
    <Autocomplete
      classes={styles ? styles.classes : classes}
      options={agentOptions}
      openOnFocus
      popupIcon={<img src={DownArrow} alt="/" />}
      forcePopupIcon
      value={agentName}
      onChange={(e, newValue) => {
        if (newValue) {
          setAgentName?.(newValue);
          dispatch({
            payload: {
              agentId: newValue.value,
            },
            type: payloadTypes.SET_AGENT_ID,
          });
        } else {
          setAgentName?.(null);
          dispatch({
            payload: {
              agentId: 0,
            },
            type: payloadTypes.SET_AGENT_ID,
          });
        }
      }}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      onKeyDown={(e) => {
        if (e.key === Keys.BACKSPACE) {
          setAgentName?.(null);
          dispatch({
            payload: {
              agentId: 0,
            },
            type: payloadTypes.SET_AGENT_ID,
          });
        } else if (e.key === Keys.ENTER) {
          handleMUISelectEvent(e);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={labelRequired ? "Agent" : ""}
          autoFocus
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setSearchAgent(e.target.value)}
          fullWidth
          classes={{
            root: styles
              ? styles.textClasses.formControlRoot
              : textClasses.formControlRoot,
          }}
        />
      )}
    />
  );
};

export default AgentOptionsAutocomplete;
