import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  GetInstMasterDataDetails,
  GetInstMasterDataVars,
} from "../../../../customhooks/useInstMasterDataByInstId";
import useToken from "../../../../customhooks/useToken";
import { Button } from "../../../../stories/Button/Button";
import { GetInstMasterData } from "../../../../queries/institution/masterdata";
import TotalStudents from "../../../../images/TotalMale.svg";
import ReadyToImport from "../../../../images/Present.svg";
import ContainsError from "../../../../images/Absent.svg";
import DownArrow from "../../../../images/DownArrow.svg";
import FileAttach from "../../../../images/BrowseFiles.svg";
import Close from "../../../../images/Close.svg";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ValidateAcdTestMarksForStd } from "../../../../queries/xls";
import { Title } from "../../../../stories/Title/Title";
import {
  toIsoDate,
  getHeaderRowStyle,
  toStandardDate,
  handleClear,
} from "../../../../utils/UtilFunctions";
import { ImportStudentDataTableStyles } from "../../../../styles/StickyTableStyles";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  E4_CELL,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import {
  AttendanceStatus,
  Direction,
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  InstitutionConfigurationTypes,
  Operation,
  SortBy,
} from "../../../../utils/Enum.types";

import Modal from "react-modal";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { HEADER_ALIGNMENT_CENTER } from "../../../Library/Constants";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import Validate from "../../../../images/Generate.svg";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import { EMPTY_STRING } from "../../../../utils/constants";
import MessageModal from "../../../../pages/MessageModal";
import { msgType } from "../../../../utils/Form.types";
import ExcelLoading from "../../../../pages/ExcelLoading";
import {
  EditModalCustomStyles,
  LoadingStyles,
} from "../../../../styles/ModalStyles";
import useTestClassSubjects, {
  TestClassSubjectQueryType,
} from "../../hooks/useTestClassSubjects";
import useCheckAllocationType from "../../hooks/useCheckAllocationType";
import {
  AcdStudentsvars,
  GetStudentAcdData,
} from "../../hooks/useAcdStudentsData";
import { GetAcdStudents } from "../../queries/students/Index";
import { StudentAcdType } from "../../../../utils/studentAcdEnum.types";
import { Label } from "../../../../stories/Label/Label";
import LoadingModal from "../../../../pages/LoadingModal";
import { ImportAcdTestMarksForStdsFromXlx } from "../../queries/test/mutation";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import { GetAcdTestClassSubjects } from "../../queries/test/query";

interface ValidateStudentDataForImportVars {
  token: string;
  inst_id: number | string;
  acd_yr_id: number | string;
  acd_test_class_id: number | string;
  student_id: number | string;
  marks_details: {
    subj_master_id: number;
    marks_ext_scored: number;
    scored_grade: string;
    teacher_comments: string;
    is_std_present: boolean;
  }[];
  entry_level: string;
  entry_id: number | string;
  per_std_subj_allocation: boolean;
}
interface ValidateStudentDataForImportData {
  ValidateStudentDataForImport: boolean;
}

interface dr {
  Class: string;
  Department: string;
  Branch: string;
  Semester: string;
  Section: string;
  Category: string;
}
interface Subject {
  sub_id: number;
  subj_desc: string;
  is_elective: boolean;
}
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const MarksImport = ({ setModalFlag }: Props) => {
  const { token } = useToken();
  const { InstId, entryId, testId } = useParams();
  const { state } = useContext(AppContext);

  const { serverDate } = useServerDateandTime();
  const { flag: AllocationFlag } = useCheckAllocationType();
  const { activeAcademicYearData } = useActiveAcademicYear();

  const classes = ImportStudentDataTableStyles();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { testConductDetails } = useTestClassSubjects(
    TestClassSubjectQueryType.TO_ADD_MARKS,
    AllocationFlag ? AllocationFlag : false
  );
  const [selectedSubjects, setSelectedSubjects] = useState<Subject[]>([]);

  const [successCount, setSuccessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);

  const [expanded, setExpanded] = useState(false);
  const [schema, setSchema] = useState<any>();
  const [importModal, setImportModal] = useState(false);
  const [records, setRecords] = useState<any[]>([]);
  const [stdConfig, setStdConfig] = useState(false);

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const {
    USE_CLASS_KEY,
    USE_BRANCH_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
    USE_SECTION_KEY,
    entry_level,
  } = useInstitutionConfiguration();
  const [GetMasterDetails, { data }] = useLazyQuery<
    GetInstMasterDataDetails,
    GetInstMasterDataVars
  >(GetInstMasterData, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });
  const totalRecords = records.filter(
    (record) => !record.isValidatedAndError
  ).length;
  const [message, setMessage] = useState<msgType>({
    message: EMPTY_STRING,
    flag: false,
    operation: Operation.NONE,
  });

  const { user_details } = useLoggedInUserDetails();
  const { flag } = useCheckAllocationType(entryId ? Number(entryId) : 0);
  const [ValidateEachRow] = useLazyQuery<
    ValidateStudentDataForImportData,
    ValidateStudentDataForImportVars
  >(ValidateAcdTestMarksForStd);
  const [AddMarks, { loading }] = useMutation(
    ImportAcdTestMarksForStdsFromXlx,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [
    GetStudentsData,
    { data: StudentsSubjData, loading: StudentsDataLoading },
  ] = useLazyQuery<GetStudentAcdData, AcdStudentsvars>(GetAcdStudents);

  useEffect(() => {
    if (
      token &&
      state.ActiveAcdYr &&
      configData.data &&
      configData.data.GetSwConfigVariables[0].config_string_value &&
      entry_level &&
      selectedSubjects &&
      selectedSubjects.length > 0
    ) {
      GetStudentsData({
        variables: {
          after: null,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          first: null,
          name: EMPTY_STRING,
          token,
          orderBy: [
            {
              direction: Direction.ASC,
              field: SortBy.STD_ROLL_NO,
            },
          ],
          input: {
            ids: selectedSubjects[0].is_elective
              ? [Number(InstId)!, Number(entryId), selectedSubjects[0].sub_id]
              : [Number(entryId)],
            acd_std_query_type: selectedSubjects[0].is_elective
              ? StudentAcdType.BY_ENTRY_LEVEL_AND_BY_SUBJ_ID
              : StudentAcdType.ACD_STDS_BY_ENTRY_LEVEL,
            str_data: [entry_level],
          },
        },
      });
    }
  }, [
    GetStudentsData,
    token,
    state.ActiveAcdYr,
    configData.data,
    InstId,
    entryId,
    entry_level,
    selectedSubjects,
  ]);

  const { InstDetails } = useInstDetails(1);

  const classDetails = data?.GetInstMasterData.class_details || [];
  const deptDetails = data?.GetInstMasterData.dept_details || [];
  const branchDetails = data?.GetInstMasterData.branch_details || [];
  const sectionDetails = data?.GetInstMasterData.section_details || [];
  const semesterDetails = data?.GetInstMasterData.semester_details || [];
  const categoryDetails = data?.GetInstMasterData.category_details || [];
  const maxLength = Math.max(
    classDetails.length,
    deptDetails.length,
    branchDetails.length,
    sectionDetails.length,
    semesterDetails.length,
    categoryDetails.length
  );
  let arrayTreeMapedData: dr[] = [];
  for (let index = 0; index < maxLength; index++) {
    const classe = classDetails[index]?.class_desc;
    const dept = deptDetails[index]?.dept_desc;
    const branche = branchDetails[index]?.branch_desc;
    const sectione = sectionDetails[index]?.section_desc;
    const semestere = semesterDetails[index]?.sem_desc;
    const categorye = categoryDetails[index]?.cat_desc;

    arrayTreeMapedData.push({
      Class: classe || "",
      Department: dept || "",
      Branch: branche || "",
      Semester: semestere || "",
      Section: sectione || "",
      Category: categorye || "",
    });
  }

  const subjects = testConductDetails?.data?.GetAcdTestClassSubjects || [];

  const handleSubjectChange = (subject: Subject) => {
    setSelectedSubjects((prevSelected) => {
      if (subject.is_elective) {
        return prevSelected.some((s) => s.sub_id === subject.sub_id)
          ? []
          : [subject];
      } else {
        return prevSelected.some((s) => s.sub_id === subject.sub_id)
          ? prevSelected.filter((s) => s.sub_id !== subject.sub_id)
          : [...prevSelected, subject];
      }
    });
  };
  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedSubjects(
        subjects
          .filter((s) => !s.subj_is_elective)
          .map((s) => ({
            sub_id: s.subject_master_details.id,
            subj_desc: s.subject_master_details.subj_desc,
            is_elective: s.subj_is_elective,
          }))
      );
    } else {
      setSelectedSubjects([]);
    }
  };

  const allSelected =
    selectedSubjects.length ===
    subjects.filter((s) => !s.subj_is_elective).length;
  const isIndeterminate = selectedSubjects.length > 0 && !allSelected;

  const hasSelectedElective = selectedSubjects.some((s) => s.is_elective);
  const hasSelectedMain = selectedSubjects.some((s) => !s.is_elective);
  const studentData = [
    {
      Headers: "EduateId",
      key: "std_id",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    {
      Headers: "AcdTestId",
      key: "test_id",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    {
      Headers: "SubjectId",
      key: "subj_id",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    {
      Headers: "AdmissionNumber",
      key: "adm_no",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    {
      Headers: "StudentName",
      key: "StudentName",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    ...(selectedSubjects || []).map((res) => ({
      Headers: `Subject :- ${res.subj_desc}`,
      key: res.sub_id,
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    })),
  ];
  const student_schema = {
    EduateId: {
      Headers: "EduateId",
      key: "std_id",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    AcdTestId: {
      Headers: "AcdTestId",
      key: "test_id",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    SubjectId: {
      Headers: "SubjectId",
      key: "subj_id",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    AdmissionNumber: {
      Headers: "AdmissionNumber",
      key: "adm_no",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    StudentName: {
      Headers: "StudentName",
      key: "StudentName",
      width: 50,
      required: true,
      use_in_sheet: true,
    },
  };

  const dynamicSubjects =
    testConductDetails &&
    testConductDetails.data?.GetAcdTestClassSubjects?.reduce((acc, res) => {
      acc[`Subject :- ${res.subject_master_details.subj_desc}`] = {
        Headers: `Subject :- ${res.subject_master_details.subj_desc}`,
        key: res.subject_master_details.id,
        width: 50,
        required: false,
        use_in_sheet: true,
      };
      return acc;
    }, {} as Record<string, { Headers: string; key: string | number; width: number; required: boolean; use_in_sheet: boolean }>);

  const combinedSchema = {
    ...student_schema,
    ...dynamicSubjects,
  };

  const schemaKeys = Object.keys(combinedSchema);

  const errorData = records
    .filter((record) => record?.errors)
    .map((f) => ({
      adm_no: f.AdmissionNumber,
      firstname: f.StudentName,
      middlename: f.MiddleName,
      lastname: f.LastName,
      email: f.Email,
      contactnumber: f.ContactNumber,
      fathername: f.FatherName,
      dob: f.DateOfBirth,
      sex: f.Gender,
      fresher: f.Fresher,
      ...(USE_DEPARTMENT_KEY ? { Department: f.Department } : {}),
      ...(USE_BRANCH_KEY ? { Branch: f.Branch } : {}),
      ...(USE_CLASS_KEY ? { class: f.class } : {}),
      ...(USE_SEMESTER_KEY ? { Semester: f.Semester } : {}),
      ...(USE_CATEGORY_KEY ? { category: f.category } : {}),
      ...(USE_SECTION_KEY ? { Section: f.Section } : {}),
    }));
  const filteredSchemaKeys = schemaKeys.filter((key) =>
    records.some((record) => {
      const value = record[key];
      const excludedKeys = ["EduateId", "AcdTestId", "SubjectId"];
      return (
        !excludedKeys.includes(key) &&
        value !== undefined &&
        value !== null &&
        value !== ""
      );
    })
  );

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.INST_CONFIG_DATA);
    const worksheet1 = workbook.addWorksheet("StudentInfo");
    worksheet.views = FROZEN_CELLS;
    const studentsData =
      StudentsSubjData &&
      StudentsSubjData.GetAcdStudents.edges.map((data) => ({
        EduateId: data.node.id,
        AcdTestId: testId,
        SubjectId: selectedSubjects.map((res) => res.sub_id),
        AdmissionNumber: data.node.std_adm_no,
        StudentName:
          data.node.first_name +
          " " +
          data.node.middle_name +
          " " +
          data.node.last_name,
      }));
    const dataRows = studentsData
      ? studentsData?.map((item) => [
          item.EduateId,
          item.AcdTestId,
          item.SubjectId,
          item.AdmissionNumber,
          item.StudentName,
        ])
      : [];
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet1.getColumn(1).hidden = true;
    worksheet1.getColumn(2).hidden = true;
    worksheet1.getColumn(3).hidden = true;

    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 6);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "InstConfigdata";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  const headerRow = worksheet1.getRow(1);
                  headerRow.height = 25;
                  headerRow.font = ACC_HEADER_FONT;
                  const filteredStudentExcelData = studentData.filter(
                    (column) => column.use_in_sheet
                  );

                  let studentInfoHeader = filteredStudentExcelData
                    .filter((student) => student.use_in_sheet)
                    .map((f) => f.Headers);
                  for (let i = 0; i < filteredStudentExcelData.length; i++) {
                    const cell = headerRow.getCell(i + 1);

                    cell.value = studentInfoHeader[i];
                    cell.fill = TABLE_HEADER_CSS;
                    cell.border = BORDER_DATA;

                    cell.alignment = { horizontal: ExcelAlignment.CENTER };
                    worksheet1.columns.forEach((column) => {
                      column.width = 20;
                    });
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet1.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, "Marks Sheet");
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  const downloadExcelContainsError = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("StudentInfo");
    worksheet1.views = [{ state: "frozen", xSplit: 1, ySplit: 1 }];
    if (records.length === 0) {
      console.warn("No data available to create the Excel file.");
      return;
    }

    const errorColumns = ["errors"]; // Add any other error columns you want to include
    const headers = Object.keys(records[0]).filter(
      (header) =>
        !["isChecked", "isValidated", "isValidatedAndError", "id"].includes(
          header
        )
    );

    // Add error columns if they are not present
    errorColumns.forEach((col) => {
      if (!headers.includes(col)) {
        headers.push(col);
      }
    });
    worksheet1.columns = headers.map((header) => ({
      header,
      key: header,
      width: 20,
    }));

    const headerRow = worksheet1.getRow(1);
    headerRow.height = 25;
    headerRow.font = { name: "Arial", size: 12, bold: true };
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFE0B2" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    records
      .filter((record) => record?.errors)
      .forEach((entry, rowIndex) => {
        const row = worksheet1.getRow(rowIndex + 2);
        headers.forEach((header, colIndex) => {
          const cell = row.getCell(colIndex + 1);
          cell.value = entry[header];
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "StudentInfo.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const processExcel = (data: any) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[1];

    var records = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    // eslint-disable-next-line
    records.map((record: any) => {
      let errors: any = [];
      schema &&
        // eslint-disable-next-line
        Object.keys(schema).map((key) => {
          // @ts-ignore
          let keySchema = student_schema[key];
          let keyValue = record[key];
          if (
            keySchema.required &&
            (keyValue === null || keyValue === undefined)
          ) {
            let errorObj = {
              column: key,
              error: "Required",
            };
            errors.push(errorObj);
          }

          // if (key === "ContactNumber" && !_isPhone.mask.test(keyValue)) {
          //   let errorObj = {
          //     column: key,
          //     error: "Invalid Phone",
          //   };
          //   errors.push(errorObj);
          // }

          // if (
          //   key === "DateOfBirth" &&
          //   !/^\d{2}[-/]\d{2}[-/]\d{4}$/.test(keyValue)
          // ) {
          //   let errorObj = {
          //     column: key,
          //     error:
          //       "Invalid D.O.A, the format should be DD/MM/YYYY or DD-MM-YYYY",
          //   };
          //   errors.push(errorObj);
          // }
          // if (
          //   key === "DateOfAdmission" &&
          //   !/^\d{2}[-/]\d{2}[-/]\d{4}$/.test(keyValue)
          // ) {
          //   let errorObj = {
          //     column: key,
          //     error:
          //       "Invalid D.O.A, the format should be DD/MM/YYYY or DD-MM-YYYY",
          //   };
          //   errors.push(errorObj);
          // }

          // if (key === "Gender" && !_genderRegex.mask.test(keyValue)) {
          //   let errorObj = {
          //     column: key,
          //     error: "Invalid Gender, the format should be male or female",
          //   };
          //   errors.push(errorObj);
          // }
        });
      if (errors.length > 0) {
        record.errors = errors;
      }
    });
    return records;
  };

  const handleButtonClick = async () => {
    try {
      await Promise.all(records.map((record) => handleClick(record.id)));
    } catch (error) {
      console.error(error);
    }
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setRecords([]);
    }
    setMessage({
      flag: false,
      message: EMPTY_STRING,
      operation: Operation.NONE,
    });
  };

  const handleClick = (id: number) => {
    return new Promise((resolve, reject) => {
      const filteredData = records.find((record) => record.id === id);
      if (filteredData) {
        const subjectIds = JSON.parse(filteredData.SubjectId);
        const subjectKeys = Object.keys(filteredData).filter((key) =>
          key.startsWith("Subject :- ")
        );
        const marksDetails = subjectIds.map((subjId: number, index: number) => {
          const subjectKey = subjectKeys[index];

          return {
            subj_master_id: subjId,
            marks_ext_scored:
              subjectKey && filteredData[subjectKey] === AttendanceStatus.A
                ? 0
                : filteredData[subjectKey] ?? 0,
            scored_grade: "",
            teacher_comments: "",
            is_std_present: true,
          };
        });
        ValidateEachRow({
          variables: {
            inst_id: InstId!,
            token,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            acd_test_class_id: filteredData.AcdTestId,
            student_id: filteredData.EduateId,
            marks_details: marksDetails,
            entry_level,
            entry_id: entryId!,
            per_std_subj_allocation: flag!,
          },
        }).then(({ data, error }) => {
          if (data) {
            const newData = records.map((record) => {
              if (id === record.id) {
                return {
                  ...record,
                  isChecked: !record?.isChecked,
                  isValidated: !record.isValidated,
                  isValidatedAndError: false,
                };
              }
              return record;
            });
            setRecords(newData);
            resolve(data);
          }
          if (error) {
            const newData = records.map((record) => {
              if (record.id === id) {
                record.errors = record.errors ? record.errors : [];
                record.errors.push(error && error.message);
                return {
                  ...record,
                  isChecked: !record?.isChecked,
                  isValidated: true,
                  isValidatedAndError: true,
                };
              }
              return record;
            });
            setRecords(newData);
            reject(error);
          }
        });
      }
    });
  };

  const handleAddStudent = async () => {
    setSuccessCount(0);
    setErrorCount(0);
    setImportModal(true);

    for (const record of records.filter(
      (record) => !record.isValidatedAndError
    )) {
      try {
        const subjectIds = JSON.parse(record.SubjectId);
        const subjectKeys = Object.keys(record).filter((key) =>
          key.startsWith("Subject :- ")
        );
        const marksDetails = subjectIds.map((subjId: number, index: number) => {
          const subjectKey = subjectKeys[index];
          return {
            subj_master_id: subjId,
            marks_scored:
              subjectKey && record[subjectKey] === AttendanceStatus.A
                ? 0
                : record[subjectKey],
            scored_grade: "",
            teacher_comments: "",
            is_std_present:
              record[subjectKey] === AttendanceStatus.A ? false : true,
          };
        });

        await AddMarks({
          variables: {
            token,
            todays_date: toIsoDate(serverDate),
            inst_id: Number(InstId),
            user_details,
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
            per_std_subj_allocation: flag,
            input: {
              acd_referance_ids: {
                inst_id: Number(InstId),
                acd_test_class_id: record.AcdTestId,
                entry_level,
                entry_id: entryId,
                test_type_id: 0,
              },
              student_details: [
                {
                  student_id: record.EduateId,
                  test_remarks: "",
                  subj_marks: marksDetails,
                },
              ],
            },
          },
          refetchQueries: [
            {
              query: GetAcdTestClassSubjects,
              variables: {
                token,
                inst_id: Number(InstId)!,
                acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
                acd_test_classes_id: state.testConductId || Number(testId),
                pr_emp_id: state.empLoginId,
                entry_level,
                entry_id: entryId ? Number(entryId) : 0,
                query_type: TestClassSubjectQueryType.TO_ADD_MARKS,
                per_std_subj_allocation: AllocationFlag
                  ? AllocationFlag
                  : false,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setSuccessCount((prev) => prev + 1);
          }
        });
      } catch (error) {
        console.error(error);
        setErrorCount((prev) => prev + 1);
      }
    }
  };

  const displayFileName = (): void => {
    const inputElement = document.getElementById("input") as HTMLInputElement;
    const fileName = inputElement.value;
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = fileName.split("\\").pop()!;
  };
  useEffect(() => {
    const input = document.getElementById("input") as HTMLInputElement;
    if (input) {
      input.addEventListener("change", () => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const res = processExcel(reader.result);
          setRecords(
            res.map((f: any, index: number) => {
              return {
                ...f,
                id: index + 1,
                isChecked: false,
                isValidated: false,
                isValidatedAndError: false,
              };
            })
          );
        };
        reader.readAsBinaryString(input.files![0]);
      });
    }
  });

  useEffect(() => {
    if (
      USE_CLASS_KEY ||
      USE_DEPARTMENT_KEY ||
      USE_SEMESTER_KEY ||
      USE_CATEGORY_KEY ||
      USE_BRANCH_KEY
    ) {
      setSchema(student_schema);
    } // eslint-disable-next-line
  }, [
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
    USE_BRANCH_KEY,
  ]);

  useEffect(() => {
    if (token) {
      GetMasterDetails();
    }
  }, [token, GetMasterDetails]);

  return (
    <>
      <Title>Import Student Marks</Title>
      <div className="import-excel-data__marks">
        <div className="row g-0 import-excel-data__blocks">
          <div className="col-6">
            <div className="import-excel-data__file-upload">
              <div className="import-excel-data__file-upload--file-name">
                <span id="file-name"></span>
              </div>
              <div className="import-excel-data__file-upload--browse">
                <label htmlFor="input">
                  <img src={FileAttach} alt="/" /> Choose Excel File
                </label>
                <input
                  id="input"
                  type="file"
                  name="file"
                  onChange={() => displayFileName()}
                  className="import-excel-data__file-upload--input"
                />
              </div>
            </div>
          </div>

          <div className="col import-excel-data__blocks--cards">
            <b>
              <img src={TotalStudents} alt="/" />
              Total Students
            </b>
            <span className="import-excel-data__blocks--cards--total-students">
              {records.length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ReadyToImport} alt="/" />
              Ready to Import
            </b>
            <span className="import-excel-data__blocks--cards--ready-to-import">
              {records.filter((record) => !record.isValidatedAndError).length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ContainsError} alt="/" />
              Contains Error
            </b>
            <span className="import-excel-data__blocks--cards--contains-error">
              {records.filter((record) => record?.errors).length}
            </span>
          </div>
        </div>
        <div className="import-excel-data__datablock">
          {records.length > 0 && schema ? (
            <>
              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--records-count">
                  <span>
                    {
                      records.filter((record) => !record.isValidatedAndError)
                        .length
                    }
                  </span>
                  <b> Records Found to Import</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              <div className="import-excel-data__datablock--tableblock">
                <TableContainer className={classes.table}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.stickyHeaderSl}
                          id="td-center"
                        >
                          Sl
                        </TableCell>
                        {filteredSchemaKeys.map((key, index) => (
                          <TableCell
                            key={index}
                            className={
                              key === "StudentName"
                                ? classes.stickyHeaderName
                                : key === "AdmissionNumber"
                                ? classes.stickyHeaderAdmNo
                                : classes.stickyHeader
                            }
                            id="td-center"
                          >
                            {key}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {records
                        .filter((record) => !record?.errors)
                        .map((record, index) => (
                          <TableRow key={index}>
                            <TableCell
                              className={classes.stickyColumnSl}
                              id="td-center"
                            >
                              {index + 1}
                            </TableCell>
                            {filteredSchemaKeys.map((key, index) => {
                              // @ts-ignore
                              const fieldSchema = combinedSchema[key];
                              const value = record[key];

                              if (
                                value !== undefined &&
                                value !== null &&
                                value !== ""
                              ) {
                                return (
                                  <TableCell
                                    key={index}
                                    className={
                                      fieldSchema?.name === "adm_no"
                                        ? classes.stickyColumnAdmno
                                        : fieldSchema?.name === "StudentName"
                                        ? classes.stickyColumnName
                                        : classes.stickyColumn
                                    }
                                  >
                                    {value}
                                  </TableCell>
                                );
                              }

                              return null;
                            })}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--errors-count">
                  <span>{records.filter((d) => d?.errors).length}</span>
                  <b>Records Which Contains error(s)</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {expanded && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className={classes.table}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className={classes.stickyHeaderSl}
                            id="td-center"
                          >
                            Sl
                          </TableCell>
                          {filteredSchemaKeys.map((key, index) => (
                            <TableCell
                              key={index}
                              className={
                                key === "StudentName"
                                  ? classes.stickyHeaderName
                                  : key === "AdmissionNumber"
                                  ? classes.stickyHeaderAdmNo
                                  : classes.stickyHeader
                              }
                              id="td-center"
                            >
                              {key}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records
                          .filter((record) => !record?.errors)
                          .map((record, index) => (
                            <TableRow key={index}>
                              <TableCell
                                className={classes.stickyColumnSl}
                                id="td-center"
                              >
                                {index + 1}
                              </TableCell>
                              {filteredSchemaKeys.map((key, index) => {
                                // @ts-ignore
                                const fieldSchema = combinedSchema[key];
                                const value = record[key];

                                if (
                                  value !== undefined &&
                                  value !== null &&
                                  value !== ""
                                ) {
                                  return (
                                    <TableCell
                                      key={index}
                                      className={
                                        fieldSchema?.name === "adm_no"
                                          ? classes.stickyColumnAdmno
                                          : fieldSchema?.name === "StudentName"
                                          ? classes.stickyColumnName
                                          : classes.stickyColumn
                                      }
                                    >
                                      {value}
                                    </TableCell>
                                  );
                                }

                                return null;
                              })}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </>
          ) : null}
        </div>
        <div className="row ">
          <div className="col">
            <Button
              onClick={() => {
                setImportModal(true);
                handleAddStudent();
              }}
              disabled={
                !records.filter((record) => record?.isChecked).length ||
                !records.length
                  ? true
                  : false
              }
              mode="excel"
            >
              Import Students Marks
            </Button>
            <Button onClick={handleButtonClick}>
              <img src={Validate} alt="" />
              Validate
            </Button>
            <Button onClick={handleClear} mode="clear" />

            <Button onClick={downloadExcelContainsError} mode="excel">
              Export error Data
            </Button>
            <Button mode="cancel" onClick={() => setModalFlag(false)} />
          </div>
          <div className="col import-excel-data__button">
            <Button mode="excel" onClick={() => setStdConfig(!stdConfig)}>
              Generate Template XLS File
            </Button>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={stdConfig}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="import-excel-data__marks--modal">
          <div className="import-excel-data__marks--modal--title">
            <Title variant="subtitle1">Choose Subjects To Enter Marks</Title>
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStdConfig(!stdConfig)}
            />
          </div>
          <div className="row g-0">
            <div className="col">
              <div className="import-excel-data__marks--modal--block">
                <div className="select-all">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="allSelect"
                          className="select-all--checkbox"
                          checked={allSelected}
                          indeterminate={isIndeterminate}
                          onChange={handleSelectAllChange}
                          disabled={hasSelectedElective}
                        />
                      }
                      label="Select All"
                    />
                  </FormGroup>
                </div>
                <Label>Main Subjects</Label>
                {subjects
                  .filter((res) => !res.subj_is_elective)
                  .map((label, index) => {
                    const subject = {
                      sub_id: label.subject_master_details.id,
                      subj_desc: label.subject_master_details.subj_desc,
                      is_elective: false,
                    };
                    return (
                      <FormGroup className="select-all__checkboxes" key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={subject.subj_desc}
                              checked={selectedSubjects.some(
                                (s) => s.sub_id === subject.sub_id
                              )}
                              onChange={() => handleSubjectChange(subject)}
                              disabled={hasSelectedElective}
                            />
                          }
                          label={
                            <>
                              &nbsp; {index + 1}) &nbsp;
                              {subject.subj_desc}
                            </>
                          }
                        />
                      </FormGroup>
                    );
                  })}
              </div>
            </div>
            <div className="col">
              <Label>Elective Subjects</Label>
              {subjects
                .filter((res) => res.subj_is_elective)
                .map((label, index) => {
                  const subject = {
                    sub_id: label.subject_master_details.id,
                    subj_desc: label.subject_master_details.subj_desc,
                    is_elective: true,
                  };
                  return (
                    <FormGroup className="select-all__checkboxes" key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={subject.subj_desc}
                            checked={selectedSubjects.some(
                              (s) => s.sub_id === subject.sub_id
                            )}
                            onChange={() => handleSubjectChange(subject)}
                            disabled={hasSelectedMain}
                          />
                        }
                        label={
                          <>
                            &nbsp; {index + 1}) &nbsp;
                            {subject.subj_desc}
                          </>
                        }
                      />
                    </FormGroup>
                  );
                })}
            </div>
          </div>
          <Button mode="excel" onClick={downloadExcel}>
            Download To Excel
          </Button>
          <Button mode="cancel" onClick={() => setStdConfig(!stdConfig)} />
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={LoadingStyles}
        ariaHideApp={false}
      >
        <ExcelLoading
          total={totalRecords}
          success={successCount}
          errors={errorCount}
          loading={loading}
        />
        <div className="modal-flex__image">
          <img
            onClick={() => setImportModal(!importModal)}
            src={Close}
            alt="/"
            className="modal-close-icon"
          />
        </div>
      </Modal>
      <LoadingModal flag={StudentsDataLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default MarksImport;
