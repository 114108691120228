import { useContext, useEffect, useMemo, useState } from "react";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useAcdDropDownData from "../hooks/useAcdDropDownData";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import { msgType, responseType } from "../../../utils/Form.types";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import TotalClasses from "../../../images/TotalClasses.svg";
import PlannedTest from "../../../images/TotalAttendanceMarked.svg";
import NotPlannedTest from "../../../images/TotalAttendanceNotMarked.svg";
import { Label } from "../../../stories/Label/Label";
import {
  InstitutionConfigurationTypes,
  Operation,
  TableHeaders,
} from "../../../utils/Enum.types";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  TODAY_DATE,
} from "../../../utils/constants";
import { TreeType } from "../Attendance/ForAdmin";

import Allocate from "../../../images/Assign.svg";
import Edit from "../../../images/EditButton.svg";
import Delete from "../../../images/DeleteButton.svg";
import { Button } from "../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import useMstInstTreeByAllocatedLevel from "../../../customhooks/general/useMstInstTreeByAllocatedLevel";
import { Keys } from "../../../utils/Enum.keys";
import useDisplayAutoCompleteTag from "../hooks/useDisplayAutoCompleteTag";
import DeleteModal from "../../../pages/DeleteModal";
import { DeleteAcdTestClassesAndSubjects } from "../queries/test/mutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import useToken from "../../../customhooks/useToken";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { GetAcdInstTreeForAllocatedLevel } from "../../../queries/common";
import { InstTreeQueryType } from "../../HR/Types/masterDataTypes";
import MessageModal from "../../../pages/MessageModal";
import { GetAcdTestNameById } from "../queries/test/query";
import { GetAcdTestNameByIdData } from "../Test/TestCreation/Index";
import { singleNodeVars } from "../../../Types/Accounting";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import useTestClassData, {
  AcdTestConductQueryType,
} from "../hooks/useTestClassData";
import { AppContext } from "../../../context/context";
import {
  CheckPerStdSubjAllocAtEntryLevelData,
  CheckPerStdSubjAllocAtEntryLevelVars,
} from "../../../Types/Combinations/queries";
import { CheckPerStdSubjAllocAtEntryLevel } from "../../../queries/customallocation/combination/queries";
import LoadingModal from "../../../pages/LoadingModal";
interface AssignComponentProp {
  id: number;
  subjects: number;
}

interface Props {
  isderivative: boolean;
  isnonacademic: boolean;
}
const Planner = ({ isderivative, isnonacademic }: Props) => {
  const navigate = useNavigate();
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { InstId, testId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const allocationLevel =
    configData.data?.GetSwConfigVariables[0].config_string_value!;
  const { activeAcademicYearData } = useActiveAcademicYear();
  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [DeleteTestandClass] = useMutation(DeleteAcdTestClassesAndSubjects, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [GetTestTypeDetails, { data: TestName }] = useLazyQuery<
    GetAcdTestNameByIdData,
    singleNodeVars
  >(GetAcdTestNameById);

  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const { displayClass, displaySection, displaySemester } =
    useDisplayAutoCompleteTag();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();

  let idsSet = new Set();
  let last_level = useMemo(() => {
    let final = 0;
    if (USE_DEPARTMENT_KEY) {
      final++;
    }
    if (USE_BRANCH_KEY) {
      final++;
    }
    if (USE_CLASS_KEY && displayClass) {
      final++;
    }
    if (USE_SEMESTER_KEY && displaySemester) {
      final++;
    }
    if (USE_SECTION_KEY && displaySection) {
      final++;
    }
    return final + 1;
  }, [
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
    displayClass,
    displaySemester,
    displaySection,
  ]);
  const { testConductDetails } = useTestClassData(
    Number(testId),
    deleteId,
    AcdTestConductQueryType.BY_ACD_TEST_NAME
  );

  const [CheckPerStdConfig, { loading }] = useLazyQuery<
    CheckPerStdSubjAllocAtEntryLevelData,
    CheckPerStdSubjAllocAtEntryLevelVars
  >(CheckPerStdSubjAllocAtEntryLevel);

  const testClassId = testConductDetails.data
    ? testConductDetails.data.GetAcdTestClass.edges[0].node.id
    : 0;

  const { data } = useMstInstTreeByAllocatedLevel(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    toIsoDate(TODAY_DATE),
    Number(testId)
  );

  const modifiedArrayForTable: TreeType[] = data
    ? data?.GetAcdInstTreeForAllocatedLevel.flatMap((level1) => {
        if (
          level1.acd_level_2_details &&
          level1.acd_level_2_details.length > 0
        ) {
          return level1.acd_level_2_details.flatMap((level2) => {
            if (
              level2.acd_level_3_details &&
              level2.acd_level_3_details.length > 0
            ) {
              return level2.acd_level_3_details.flatMap((level3) => {
                if (
                  level3.acd_level_4_details &&
                  level3.acd_level_4_details.length > 0
                ) {
                  return level3.acd_level_4_details.flatMap((level4) => {
                    if (
                      level4.acd_level_5_details &&
                      level4.acd_level_5_details.length > 0
                    ) {
                      return level4.acd_level_5_details.map((level5) => {
                        let result = {
                          level1_name: idsSet.has(level1.acd_level_1_id)
                            ? EMPTY_STRING
                            : level1.acd_level_1_desc,
                          level1_id: level1.acd_level_1_id,
                          level1_rowspan: level1.sub_acd_level_total_count
                            ? level1.sub_acd_level_total_count
                            : 1,
                          level2_name: idsSet.has(level2.acd_level_2_id)
                            ? EMPTY_STRING
                            : level2.acd_level_2_desc,
                          level2_rowspan: level2.sub_acd_level_total_count
                            ? level2.sub_acd_level_total_count
                            : 1,
                          level2_id: level2.acd_level_2_id,
                          level3_name: idsSet.has(level3.acd_level_3_id)
                            ? EMPTY_STRING
                            : level3.acd_level_3_desc,
                          level3_span: level3.sub_acd_level_total_count
                            ? level3.sub_acd_level_total_count
                            : 1,
                          level3_id: level3.acd_level_3_id,
                          level4_name: idsSet.has(level4.acd_level_4_id)
                            ? EMPTY_STRING
                            : level4.acd_level_4_desc,
                          level4_span: level4.sub_acd_level_total_count
                            ? level4.sub_acd_level_total_count
                            : 1,
                          level4_id: level4.acd_level_4_id,
                          level5_name: level5.acd_level_5_desc,
                          level5_id: level5.acd_level_5_id,

                          level1_std_level_alloc: false,
                          level2_std_level_alloc: false,
                          level3_std_level_alloc: false,
                          level4_std_level_alloc: false,
                          level5_std_level_alloc: false,
                          filterThis: false,
                          total_test_subjects: level5.total_test_subjects,
                          test_start_date: level5.test_start_date,
                          test_end_date: level5.test_end_date,
                        };
                        idsSet = new Set([
                          level1.acd_level_1_id,
                          level2.acd_level_2_id,
                          level3.acd_level_3_id,
                          level4.acd_level_4_id,
                          level5.acd_level_5_id,
                          ...idsSet,
                        ]);
                        return result;
                      });
                    } else {
                      let result = {
                        level1_name: idsSet.has(level1.acd_level_1_id)
                          ? EMPTY_STRING
                          : level1.acd_level_1_desc,
                        level1_id: level1.acd_level_1_id,
                        level1_rowspan: level1.sub_acd_level_total_count
                          ? level1.sub_acd_level_total_count
                          : 1,
                        level2_name: idsSet.has(level2.acd_level_2_id)
                          ? EMPTY_STRING
                          : level2.acd_level_2_desc,
                        level2_rowspan: level2.sub_acd_level_total_count
                          ? level2.sub_acd_level_total_count
                          : 1,
                        level2_id: level2.acd_level_2_id,
                        level3_name: idsSet.has(level3.acd_level_3_id)
                          ? EMPTY_STRING
                          : level3.acd_level_3_desc,
                        level3_span: level3.sub_acd_level_total_count
                          ? level3.sub_acd_level_total_count
                          : 1,
                        level3_id: level3.acd_level_3_id,
                        level4_name: idsSet.has(level4.acd_level_4_id)
                          ? EMPTY_STRING
                          : level4.acd_level_4_desc,
                        level4_span: level4.sub_acd_level_total_count
                          ? level4.sub_acd_level_total_count
                          : 1,
                        level4_id: level4.acd_level_4_id,
                        level5_name: EMPTY_STRING,
                        level5_id: 0,
                        filterThis: !(last_level <= 5),
                        level1_std_level_alloc: false,
                        level2_std_level_alloc: false,
                        level3_std_level_alloc: false,
                        level4_std_level_alloc: false,
                        level5_std_level_alloc: false,
                        total_test_subjects: level4.total_test_subjects,
                        test_start_date: level4.test_start_date,
                        test_end_date: level4.test_end_date,
                      };
                      idsSet = new Set([
                        level1.acd_level_1_id,
                        level2.acd_level_2_id,
                        level3.acd_level_3_id,
                        level4.acd_level_4_id,
                        ...idsSet,
                      ]);
                      return result;
                    }
                  });
                } else {
                  let result = {
                    level1_name: idsSet.has(level1.acd_level_1_id)
                      ? EMPTY_STRING
                      : level1.acd_level_1_desc,
                    level1_id: level1.acd_level_1_id,
                    level1_rowspan: level1.sub_acd_level_total_count
                      ? level1.sub_acd_level_total_count
                      : 1,
                    level2_name: idsSet.has(level2.acd_level_2_id)
                      ? EMPTY_STRING
                      : level2.acd_level_2_desc,
                    level2_rowspan: level2.sub_acd_level_total_count
                      ? level2.sub_acd_level_total_count
                      : 1,
                    level2_id: level2.acd_level_2_id,
                    level3_name: idsSet.has(level3.acd_level_3_id)
                      ? EMPTY_STRING
                      : level3.acd_level_3_desc,
                    level3_span: level3.sub_acd_level_total_count
                      ? level3.sub_acd_level_total_count
                      : 1,
                    level3_id: level3.acd_level_3_id,
                    level4_name: EMPTY_STRING,
                    level4_span: 1,
                    level4_id: 0,
                    level5_name: EMPTY_STRING,
                    level5_id: 0,
                    filterThis: !(last_level <= 4) || !(last_level <= 5),
                    level1_std_level_alloc: false,
                    level2_std_level_alloc: false,
                    level3_std_level_alloc: false,
                    level4_std_level_alloc: false,
                    level5_std_level_alloc: false,
                    total_test_subjects: level3.total_test_subjects,
                    test_start_date: level3.test_start_date,
                    test_end_date: level3.test_end_date,
                  };
                  idsSet = new Set([
                    level1.acd_level_1_id,
                    level2.acd_level_2_id,
                    level3.acd_level_3_id,
                    ...idsSet,
                  ]);
                  return result;
                }
              });
            } else {
              let result = {
                level1_name: idsSet.has(level1.acd_level_1_id)
                  ? EMPTY_STRING
                  : level1.acd_level_1_desc,
                level1_id: level1.acd_level_1_id,
                level1_rowspan: level1.sub_acd_level_total_count
                  ? level1.sub_acd_level_total_count
                  : 1,
                level2_name: idsSet.has(level2.acd_level_2_id)
                  ? EMPTY_STRING
                  : level2.acd_level_2_desc,
                level2_rowspan: level2.sub_acd_level_total_count
                  ? level2.sub_acd_level_total_count
                  : 1,
                level2_id: level2.acd_level_2_id,
                level3_name: EMPTY_STRING,
                level3_span: 1,
                level3_id: 0,
                level4_name: EMPTY_STRING,
                level4_span: 1,
                level4_id: 0,
                level5_name: EMPTY_STRING,
                level5_id: 0,
                level1_std_level_alloc: false,
                level2_std_level_alloc: false,
                level3_std_level_alloc: false,
                level4_std_level_alloc: false,
                level5_std_level_alloc: false,
                filterThis:
                  !(last_level <= 3) ||
                  !(last_level <= 4) ||
                  !(last_level <= 5),
                total_test_subjects: level2.total_test_subjects,
                test_start_date: level2.test_start_date,
                test_end_date: level2.test_end_date,
              };
              idsSet = new Set([
                level1.acd_level_1_id,
                level2.acd_level_2_id,
                ...idsSet,
              ]);
              return result;
            }
          });
        } else {
          let result = {
            level1_name: idsSet.has(level1.acd_level_1_id)
              ? EMPTY_STRING
              : level1.acd_level_1_desc,
            level1_id: level1.acd_level_1_id,
            level1_rowspan: level1.sub_acd_level_total_count,
            level2_name: EMPTY_STRING,
            level2_rowspan: 0,
            level2_id: 0,
            level3_name: EMPTY_STRING,
            level3_span: 0,
            level3_id: 0,
            level4_name: EMPTY_STRING,
            level4_span: 0,
            level4_id: 0,
            level5_name: EMPTY_STRING,
            level5_id: 0,
            level1_std_level_alloc: false,
            level2_std_level_alloc: false,
            level3_std_level_alloc: false,
            level4_std_level_alloc: false,
            level5_std_level_alloc: false,
            filterThis:
              !(last_level <= 2) ||
              !(last_level <= 3) ||
              !(last_level <= 4) ||
              !(last_level <= 5),
            total_test_subjects: level1.total_test_subjects,
            test_start_date: level1.test_start_date,
            test_end_date: level1.test_end_date,
          };
          idsSet = new Set([level1.acd_level_1_id, ...idsSet]);
          return result;
        }
      })
    : [];
  const filteredTreeFromEmptyMasterDetails = modifiedArrayForTable.filter(
    ({ filterThis }) => !filterThis
  );

  const HandleDelete = (id: Number) => {
    DeleteTestandClass({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcdId,
        test_class_id: testClassId,
      },
      refetchQueries: [
        {
          query: GetAcdInstTreeForAllocatedLevel,
          variables: {
            inst_id: InstId,
            id: 0,
            token,
            query_type: InstTreeQueryType.TREE_BY_INST_ID,
            date_of_attendance: toIsoDate(TODAY_DATE),
            testNameId: Number(testId),
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Tests Deleted Successfully",
          operation: Operation.DELETE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setDeleteModal(!deleteModal);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const AssignComponent = ({ id, subjects }: AssignComponentProp) => {
    if (isderivative) {
      return subjects === 0 ? (
        <button
          className="allocate-button"
          onClick={() =>
            navigate(
              `/${InstId}/academics/${testId}/examplanner/${id}/createderivative`
            )
          }
        >
          Create a derivative <img src={Allocate} alt="" />
        </button>
      ) : (
        <>
          <button
            className="edit-button"
            onClick={() =>
              navigate(
                `/${InstId}/academics/${testId}/examplanner/${id}/updatederivative`
              )
            }
          >
            Update derivative
            <img src={Edit} alt="" />
          </button>
          <button
            className="delete-button"
            onClick={() => {
              setDeleteModal(!deleteModal);
              setDeleteId(id);
            }}
          >
            Delete derivative
            <img src={Delete} alt="" />
          </button>
        </>
      );
    } else if (isnonacademic) {
      return subjects === 0 ? (
        <button
          className="allocate-button"
          onClick={() => {
            navigate(
              `/${InstId}/academics/${testId}/examplanner/${id}/createnonacademictest`
            );
          }}
        >
          Plan For Test <img src={Allocate} alt="" />
        </button>
      ) : (
        <>
          <button
            className="edit-button"
            onClick={() =>
              navigate(
                `/${InstId}/academics/${testId}/examplanner/${id}/updatenonacademictest`
              )
            }
          >
            Update Tests
            <img src={Edit} alt="" />
          </button>
          <button
            className="delete-button"
            onClick={() => {
              setDeleteModal(!deleteModal);
              setDeleteId(id);
            }}
          >
            Delete Tests
            <img src={Delete} alt="" />
          </button>
        </>
      );
    } else
      return subjects === 0 && !isnonacademic ? (
        <button
          className="allocate-button"
          onClick={() => {
            CheckPerStdConfig({
              variables: {
                acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
                entry_id: Number(id)!,
                entry_level: allocationLevel,
                inst_id: InstId!,
                token,
              },
            }).then(({ data }) => {
              if (data) {
                if (data.CheckPerStdSubjAllocAtEntryLevel) {
                  navigate(
                    `/${InstId}/academics/${testId}/examplanner/${id}/createtest/perstudent`
                  );
                } else {
                  navigate(
                    `/${InstId}/academics/${testId}/examplanner/${id}/createtest`
                  );
                }
              }
            });
          }}
        >
          Plan For Test <img src={Allocate} alt="" />
        </button>
      ) : (
        <>
          <button
            className="edit-button"
            onClick={() =>
              navigate(
                `/${InstId}/academics/${testId}/examplanner/${id}/updatetest`
              )
            }
          >
            Update Tests
            <img src={Edit} alt="" />
          </button>
          <button
            className="delete-button"
            onClick={() => {
              setDeleteModal(!deleteModal);
              setDeleteId(id);
            }}
          >
            Delete Tests
            <img src={Delete} alt="" />
          </button>
        </>
      );
  };
  useEffect(() => {
    if (testId && token) {
      GetTestTypeDetails({
        variables: { id: testId ? Number(testId) : 0, token },
      });
    }
  }, [testId, GetTestTypeDetails, token]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Exam / Test Planning</Title>
      <div className="test-planner__view">
        <div className="test-planner__view--filters row g-0">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={departmentDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue);

                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY && displayClass ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY && displaySemester ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY && displaySection ? (
            <div className="col-2">
              <Autocomplete
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, sectionSelected)
                }
                classes={classes}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (sectionSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue);
                  } else {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <Title variant="subtitle1">{TestName?.node.test_name}</Title>
        <div className="test-planner__view--cards row g-0">
          <div className="col test-planner__view--cards--list">
            <div className="test-planner__view--cards--list--grid">
              <img src={TotalClasses} alt="" />
              <span>Total Classes</span>
              <b className="font-blue">0</b>
            </div>
            <div className="test-planner__view--cards--list--grid">
              <img src={PlannedTest} alt="" />
              <span>Test Planned Classes</span>
              <b className="font-green">0</b>
            </div>
            <div className="test-planner__view--cards--list--grid">
              <img src={NotPlannedTest} alt="" />
              <span>Test Not Planned Classes</span>
              <b className="font-red">0</b>
            </div>
          </div>
          <div className="col-3 flex-end">
            <Title variant="subtitle2">
              Allocation Level :{" "}
              <Label variant="LabelPrimary">{allocationLevel}</Label>
            </Title>
          </div>
        </div>
        <div className="test-planner__view--tableblock">
          <TableContainer className="test-planner__view--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow className="test-planner__view--table--groupheader">
                  {USE_DEPARTMENT_KEY ? (
                    <TableCell>{departmentLabel}</TableCell>
                  ) : null}
                  {USE_BRANCH_KEY ? <TableCell>{branchLabel}</TableCell> : null}
                  {USE_CLASS_KEY && displayClass ? (
                    <TableCell>{classLabel}</TableCell>
                  ) : null}
                  {USE_SEMESTER_KEY && displaySemester ? (
                    <TableCell>{semesterLabel}</TableCell>
                  ) : null}
                  {USE_SECTION_KEY && displaySection ? (
                    <TableCell>{sectionLabel}</TableCell>
                  ) : null}
                  {isderivative === false ? (
                    <>
                      <TableCell>Conducted Subjects</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                    </>
                  ) : (
                    <TableCell>Status</TableCell>
                  )}
                  <TableCell>{TableHeaders.ACTION}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTreeFromEmptyMasterDetails.map((data, i) => {
                  if (data) {
                    return (
                      <TableRow key={i}>
                        {data.level1_name !== EMPTY_STRING ? (
                          <TableCell rowSpan={data.level1_rowspan}>
                            {data.level1_name}
                          </TableCell>
                        ) : null}
                        {data &&
                        (data.level2_name ||
                          data.level2_name !== EMPTY_STRING) ? (
                          <TableCell rowSpan={data.level2_rowspan}>
                            {data.level2_name}
                          </TableCell>
                        ) : null}
                        {data && data.level3_name !== EMPTY_STRING ? (
                          <TableCell rowSpan={data.level3_span}>
                            {data.level3_name}
                          </TableCell>
                        ) : null}
                        {data &&
                        data.level4_name !== EMPTY_STRING &&
                        displaySemester ? (
                          <TableCell rowSpan={data.level4_span}>
                            {data.level4_name}
                          </TableCell>
                        ) : null}
                        {data.level5_name !== EMPTY_STRING && displaySection ? (
                          <TableCell>{data.level5_name}</TableCell>
                        ) : null}

                        {isderivative ? (
                          <TableCell
                            id="td-center"
                            className={`test-planner__view--table ${
                              data.total_test_subjects
                                ? "font-green"
                                : "font-red"
                            }`}
                          >
                            {data.total_test_subjects
                              ? "Derived"
                              : "Not Derived"}
                          </TableCell>
                        ) : (
                          <>
                            <TableCell
                              id="td-center"
                              className="test-planner__view--table--number"
                            >
                              {data.total_test_subjects}
                            </TableCell>

                            <TableCell
                              id="td-center"
                              className="test-planner__view--table--date"
                            >
                              {data.test_start_date &&
                              toStandardDate(data.test_start_date) !==
                                toStandardDate(DEFAULT_TIME)
                                ? toStandardDate(data.test_start_date)
                                : "-"}
                            </TableCell>
                            <TableCell
                              id="td-center"
                              className="test-planner__view--table--date"
                            >
                              {data.test_end_date &&
                              toStandardDate(data.test_end_date) !==
                                toStandardDate(DEFAULT_TIME)
                                ? toStandardDate(data.test_end_date)
                                : "-"}
                            </TableCell>
                          </>
                        )}

                        <TableCell
                          id="td-center"
                          className="test-planner__view--table--actions"
                        >
                          {last_level - 1 === 1 && data.level1_name && (
                            <AssignComponent
                              id={data.level1_id}
                              subjects={data.total_test_subjects!}
                            />
                          )}
                          {last_level - 1 === 2 && data.level2_name && (
                            <AssignComponent
                              id={data.level2_id}
                              subjects={data.total_test_subjects!}
                            />
                          )}
                          {last_level - 1 === 3 && data.level3_name && (
                            <AssignComponent
                              id={data.level3_id}
                              subjects={data.total_test_subjects!}
                            />
                          )}
                          {last_level - 1 === 4 && data.level4_name && (
                            <AssignComponent
                              id={data.level4_id}
                              subjects={data.total_test_subjects!}
                            />
                          )}
                          {last_level - 1 === 5 && data.level5_name && (
                            <AssignComponent
                              id={data.level5_id}
                              subjects={data.total_test_subjects!}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <DeleteModal
        id={deleteId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={loading} />
    </>
  );
};

export default Planner;
