import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TabPanel, a11yProps } from "../../styles/Tabs";
import Feeds from "./Feeds/Index";
import Announcements from "./Announcements/Index";

import Home from "../../Modules/Master/Student/Dashboard/Home/Index";
import { Title } from "../../stories/Title/Title";
import useSwConfigData from "../../customhooks/useSwConfigData";
import { InstitutionConfigurationTypes } from "../../utils/Enum.types";
const Index = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  // eslint-disable-next-line
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_MY_CAMPUS_FEEDS
  );
  const { configData: AnnouncementsFlags } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_ANNOUNCEMENTS
  );

  return (
    <>
      <Home />
      <Title>Communication Platform</Title>
      <div className="row g-0 feeds justify-content-center">
        <div className="col-5 h-100">
          <div className="feeds__tabs">
            <Tabs value={value} onChange={handleChange}>
              {configData?.data?.GetSwConfigVariables[0]
                .config_boolean_value && (
                <Tab label="Feeds" value={0} {...a11yProps(0)} />
              )}
              {AnnouncementsFlags?.data?.GetSwConfigVariables[0]
                .config_boolean_value && (
                <Tab label="Announcements" value={1} {...a11yProps(1)} />
              )}
            </Tabs>
          </div>
          <div className="feeds__swipable">
            {/* values for Attendance */}
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Feeds />
            </TabPanel>
            {/* values for Assignments */}
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Announcements />
            </TabPanel>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
