import { Title } from "../stories/Title/Title";
import Header from "../components/common/Header/index";
import BreadCrumb from "../components/common/BreadCrumb/index";
import TotalStudents from "../images/TotalStudentsDashboard.svg";
import TotalBoys from "../images/Boys.svg";
import TotalGirls from "../images/Girls.svg";
import More from "../images/More.svg";
import Masters from "../images/Admissions.svg";
import Accounts from "../images/AccountsLogo.svg";
import Academics from "../images/Academic.svg";
import PayRoll from "../images/HR.svg";
import Library from "../images/Library.svg";
import StudentEnquiry from "../images/Enquiry.svg";
import Chat from "../images/Chat.svg";
import Transport from "../images/Transport.svg";
import TotalInstitutions from "../images/TotalInstitutions.svg";
import GlobalUser from "../images/MakeAsGlobalUser.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Input from "../stories/Input/Input";
import { useStyles } from "../styles/TooltipStyles";
import useAssignedInstbyEmpId, {
  InstUserAccessQueryType,
} from "../Modules/UserRights/hooks/useAssignedInstbyEmpId";
import { useContext, useEffect } from "react";
import { AppContext } from "../context/context";
import useActiveInstModules from "../Modules/UserRights/hooks/useActiveInstModules";
import useUserRightsByEmpId from "../Modules/UserRights/hooks/useUserRightsByEmpId";
import { toStandardCase } from "../utils/UtilFunctions";
import { useLazyQuery } from "@apollo/client";
import { GetUserDashBoardDetails } from "../Modules/UserRights/queries/List";
import useToken from "../customhooks/useToken";
interface GetUserDashBoardDetailsData {
  GetUserDashBoardDetails: {
    total_inst_count: number;
    total_std_count: number;
    boys_count: number;
    girls_count: number;
  };
}
interface GetUserDashBoardDetailsVars {
  token: string;
  inst_id: number;
  pr_emp_id: number;
}

const EmployeeHome = () => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const { MultipleInstitutions } = useAssignedInstbyEmpId(
    InstUserAccessQueryType.USER_ACCESS_BY_EMP_ID,
    state.empLoginId
  );
  const { token } = useToken();

  const { ActiveModules } = useActiveInstModules();
  const { userRights } = useUserRightsByEmpId();
  const moduleImageMap = {
    ACADEMICS: Academics,
    "ACADEMICS-APP": Academics,
    ACCOUNTS: Accounts,
    ADMISSION: Masters,
    ENQUIRY: StudentEnquiry,
    LIBRARY: Library,
    "MESSAGE-APP": Chat,
    PAYROLL: PayRoll,
    TRANSPORT: Transport,
  };

  const [GetDetails, { data }] = useLazyQuery<
    GetUserDashBoardDetailsData,
    GetUserDashBoardDetailsVars
  >(GetUserDashBoardDetails);
  useEffect(() => {
    if (state.empLoginId && token && state.InstId) {
      GetDetails({
        variables: {
          token,
          inst_id: Number(state.InstId),
          pr_emp_id: state.empLoginId,
        },
      });
    }
  }, [state.empLoginId, token, GetDetails, state.InstId]);

  return (
    <>
      <div className="row g-0">
        <Header />
      </div>
      <div className="row g-0">
        <BreadCrumb />
      </div>
      <div className="student-dashboard__nav"></div>
      <Title>Home</Title>
      <div className="home-page">
        <div className="row g-0 home-page__details">
          <div className="col home-page__details--cards">
            <div className="home-page__details--cards--title">
              <Title variant="subtitle1">Total Institutions</Title>
              <img src={More} alt="/" />
            </div>
            <div className="home-page__details--cards--data">
              <img src={TotalInstitutions} alt="" />
              <span>{data?.GetUserDashBoardDetails.total_inst_count}</span>
            </div>
          </div>
          <div className="col home-page__details--cards">
            <div className="home-page__details--cards--title">
              <Title variant="subtitle1">Total Students</Title>
              <img src={More} alt="/" />
            </div>
            <div className="home-page__details--cards--data">
              <img src={TotalStudents} alt="" />
              <span>{data?.GetUserDashBoardDetails.total_std_count}</span>
            </div>
          </div>
          <div className="col home-page__details--cards">
            <div className="home-page__details--cards--title">
              <Title variant="subtitle1">Only Boys</Title>
              <img src={More} alt="/" />
            </div>
            <div className="home-page__details--cards--data">
              <img src={TotalBoys} alt="" />
              <span>{data?.GetUserDashBoardDetails.boys_count}</span>
            </div>
          </div>
          <div className="col home-page__details--cards">
            <div className="home-page__details--cards--title">
              <Title variant="subtitle1">Only Girls</Title>
              <img src={More} alt="/" />
            </div>
            <div className="home-page__details--cards--data">
              <img src={TotalGirls} alt="" />
              <span>{data?.GetUserDashBoardDetails.girls_count}</span>
            </div>
          </div>
        </div>
        <div className="row g-0 home-page__search">
          <div className="col-2">
            <Input id="search" placeholder="Search Institutions..." />
          </div>
        </div>
        <div className="home-page__tableblock">
          <TableContainer className="home-page__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Sl</TableCell>
                  <TableCell>List of Allocated Institutions</TableCell>
                  <TableCell>Modules Enabled</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {MultipleInstitutions.institutions.map((institution, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className="home-page__table--slno"
                        id="td-center"
                      >
                        1
                      </TableCell>
                      <TableCell className="home-page__table--title">
                        {institution.label}
                        {userRights.data?.GetUserRightsByEmpId
                          .is_inst_sysadmin && <img src={GlobalUser} alt="" />}
                      </TableCell>

                      <TableCell
                        className="home-page__table--images"
                        id="td-right"
                      >
                        {ActiveModules.data?.GetMstInstActiveModules.map(
                          (module) => {
                            const moduleName =
                              module.eduate_module_details.Name;
                            const hasRights =
                              userRights.data?.GetUserRightsByEmpId.user_rights_details.some(
                                (d) =>
                                  d.inst_module_details.eduate_module_details
                                    .Name === moduleName
                              );

                            const moduleImage =
                              // @ts-ignore
                              moduleImageMap[moduleName.toUpperCase()];

                            return (
                              <Tooltip
                                title={toStandardCase(moduleName)}
                                arrow
                                placement="top"
                                classes={{
                                  tooltip: classes.customTooltipGrey,
                                  arrow: classes.customArrowGrey,
                                }}
                              >
                                <img
                                  src={moduleImage}
                                  alt=""
                                  className={
                                    hasRights
                                      ? ""
                                      : "home-page__table--images--disabled"
                                  }
                                />
                              </Tooltip>
                            );
                          }
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default EmployeeHome;
