import React, { useState } from "react";
import { Autocomplete, Drawer, TextField, Tooltip } from "@mui/material";
import { DrawerFlagType, ModalFlagType } from "./TreeView";
import { PayRollMasters } from "../enums/Enum.types";
import { addOrganizationalCharts } from "../../../styles/DrawerStyles";
import { Title } from "../../../stories/Title/Title";
import {
  isOptionEqualToValue,
  toStandardCase,
} from "../../../utils/UtilFunctions";
import Close from "../../../images/Close.svg";
import Fetch from "../../../images/Add.svg";
import { Label } from "../../../stories/Label/Label";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import { useStyles } from "../../../styles/TooltipStyles";
import { Button } from "../../../stories/Button/Button";
import AddModal from "./AddModal";

import useEmpMasterData from "../hooks/useEmpMasterData";
import { msgType, responseType } from "../../../utils/Form.types";
import { Operation } from "../../../utils/Enum.types";
import MessageModal from "../../../pages/MessageModal";

interface Props {
  drawerFlag: DrawerFlagType;
  setDrawerFlag: React.Dispatch<React.SetStateAction<DrawerFlagType>>;
  handleCreateOrg: (id: responseType) => void;
}
const AddDrawer = ({ drawerFlag, setDrawerFlag, handleCreateOrg }: Props) => {
  const drawerClasses = addOrganizationalCharts();
  const textClasses = formAutoCompleteTextStyles();
  const classes = formAutoCompleteStyles();
  const tooltipClasses = useStyles();

  const [addModal, setAddModal] = useState<ModalFlagType>({
    type: PayRollMasters.DEPARTMENT,
    flag: false,
  });
  const [id, setId] = useState<responseType | null>(null);
  const { departmentDropDown, designationDropDown } = useEmpMasterData();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setDrawerFlag({
        flag: false,
        type: PayRollMasters.DEPARTMENT,
        parent_id: -1,
        filterId: 0,
      });
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  return (
    <Drawer
      anchor="right"
      open={drawerFlag.flag}
      onClose={() =>
        setDrawerFlag({
          flag: false,
          type: PayRollMasters.DEPARTMENT,
          parent_id: 0,
          filterId: 0,
        })
      }
      className={drawerClasses.drawer}
      classes={{
        paper: drawerClasses.drawerPaper,
      }}
    >
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">
          <div className="organizational-chart__add">
            <div className="organizational-chart__add--title">
              <Title variant="subtitle1">
                {toStandardCase(drawerFlag.type)}
              </Title>
              <img
                src={Close}
                alt=""
                className="modal-close-icon"
                onClick={() =>
                  setDrawerFlag({
                    flag: false,
                    parent_id: 0,
                    type: PayRollMasters.DEPARTMENT,
                    filterId: 0,
                  })
                }
              />
            </div>
            <div className="organizational-chart__add--grid">
              <Label>Select {toStandardCase(drawerFlag.type)}</Label>
              <Autocomplete
                classes={classes}
                options={
                  drawerFlag.type === PayRollMasters.DEPARTMENT
                    ? departmentDropDown.filter(
                        ({ value }) => value !== drawerFlag.filterId
                      )
                    : designationDropDown.filter(
                        ({ value }) => value !== drawerFlag.filterId
                      )
                }
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, id)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setId(newValue);
                  } else {
                    setId(null);
                  }
                }}
                openOnFocus
                renderInput={(params) => (
                  <TextField
                    {...params}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
              <Tooltip
                title={`Add New ${toStandardCase(drawerFlag.type)}`}
                arrow
                placement="bottom"
                classes={{
                  tooltip: tooltipClasses.customTooltip,
                  arrow: classes.customArrow,
                }}
              >
                <img
                  src={Fetch}
                  alt=""
                  className="data-fetch-icon"
                  onClick={() =>
                    setAddModal({
                      flag: true,
                      type: drawerFlag.type,
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
          <div className="organizational-chart__add--buttons">
            <Button
              mode="save"
              onClick={() => {
                if (id) {
                  handleCreateOrg(id);
                }
              }}
              disabled={id === null}
            />
            <Button mode="clear" />
            <Button
              mode="cancel"
              onClick={() =>
                setDrawerFlag({
                  flag: false,
                  type: PayRollMasters.DEPARTMENT,
                  parent_id: 0,
                  filterId: 0,
                })
              }
            />
          </div>
        </div>
      </div>

      <AddModal modalFlag={addModal} setModalFlag={setAddModal} />
      <MessageModal
        value={message.message}
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
      />
    </Drawer>
  );
};

export default AddDrawer;
