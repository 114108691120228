import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

import { useMutation, useLazyQuery } from "@apollo/client";
import { Autocomplete, SelectChangeEvent, TextField } from "@mui/material";

import { Form, Formik } from "formik";

import { GetCustomers } from "../../../queries/customer/list";
import { AddCustomer } from "../../../queries/customer/mutations/new";
import { UpdateCustomer } from "../../../queries/customer/mutations/update";
import { GetCustomerByNode } from "../../../queries/customer/list/byId";

import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import { Label } from "../../../stories/Label/Label";
import InputHoc from "../../../components/common/Input/Index";
import Close from "../../../images/Close.svg";
import {
  AUTOCOMPLETE_CLEAR_CLASSNAME,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../utils/constants";
import {
  Direction,
  LicenseTypes,
  Operation,
  PredefinedDataTypes,
  SortBy,
} from "../../../utils/Enum.types";
import {
  CustomerFormDataTypes,
  msgType,
  optionsType,
} from "../../../utils/Form.types";
import { TitleProps } from "../../../Types/Titles";
import { LabelNameProps } from "../../../Types/Labels";
import {
  CutomerListEdge,
  GetCustomersData,
  GetCustomersVars,
} from "../../../Types/Customers/paginationTypes";
import { customer_validation } from "../../../utils/validationRules";
import { ModalCustomStyles } from "../../../styles/ModalStyles";
import LoadingModal from "../../../pages/LoadingModal";
import {
  getModifiedScrollHeight,
  handleMUISelectEvent,
  removeMoreSpace,
} from "../../../utils/UtilFunctions";
import Home from "../Home/Index";
// import axios from "axios";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import DownArrow from "../../../images/DownArrow.svg";
import Edit from "../../../images/EditProfile.svg";

import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import useToken from "../../../customhooks/useToken";
import MessageModal from "../../../pages/MessageModal";
import { Keys } from "../../../utils/Enum.keys";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useMasterTableJson from "../../../json/useMasterTableJson";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../styles/DataGridTableStyles";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../utils/types";
const { Customers: CustomerTitle } = require("../../../json/title.json");
// import axios from "axios";
const { customerformLabels } = require("../../../json/config.json");
const ManageCustomer = () => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const navigate = useNavigate();
  const { Customers } = useMasterTableJson();

  let filename = "";
  const { token } = useToken();
  const [sortBy] = useState(SortBy.CUST_NAME);
  const [customerId, setCustomerId] = useState(0);
  const [searchData, setSearchData] = useState("");
  const [type, setType] = useState(Operation.CREATE);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [customers, setCustomers] = useState<CutomerListEdge[]>([]);

  const dataClasses = useDataGridStyles();
  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const { user_details } = useLoggedInUserDetails();
  //use state for modal
  const [customerModal, setCustomerModal] = useState(false);

  const [formData, setFormData] = useState<CustomerFormDataTypes>({
    cust_name: "",
    cust_address: "",
    cust_email: "",
    cust_contact_person: "",
    cust_state: "",
    cust_pin: "",
    cust_url: "",
    cust_banner_1: "",
    cust_banner_2: "",
    cust_phone: "",
    cust_mobile: "",
    cust_place: "",
    cust_logo_filename: "",
  });

  const saveRef = useRef<HTMLButtonElement>(null);
  const pincodeRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLSelectElement>(null);
  const StateCloseRef = stateRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;
  const placeRef = useRef<HTMLSelectElement>(null);
  const PlaceCloseRef = placeRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;
  const Name = document.getElementsByName("name")[0] as HTMLInputElement;

  const [direction] = useState(Direction.ASC);
  const [GetCustomerData, { data, loading, fetchMore }] = useLazyQuery<
    GetCustomersData,
    GetCustomersVars
  >(GetCustomers, {
    variables: {
      first: ROWS_PER_PAGE,
      after: null,
      sortBy,
      direction,
      token,
      name: searchData,
    },
  });

  // const d = useQuery(characters, {
  //   context: { clientName: serverList.RICK_MORTY },
  // });
  // const d2 = useQuery(countries, {
  //   context: { clientName: serverList.COUNTRIES },
  // });
  const [GetCustomerDetail] = useLazyQuery(GetCustomerByNode, {
    variables: { token, id: customerId },
  });

  const [createCustomer, { loading: creationLoading }] = useMutation(
    AddCustomer,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateCustomerByid, { loading: updationLoading }] = useMutation(
    UpdateCustomer,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const dynamicHeaders: TableHeaderProps[] = Customers.Table_Headers.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );
  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "cust_actions",
      headerName: "Actions",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      sortable: false,

      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <img
              src={Edit}
              alt=""
              onClick={() => {
                setCustomerId(params.row.custId);
                setType(Operation.UPDATE);
                setCustomerModal(!customerModal);
              }}
            />
          </>
        );
      },
    },
  ];
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string | number>
  ) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value.toString(),
    }));
  };
  const {
    PredefinedData: { dropDown: City },
  } = usePredefinedDataByType(PredefinedDataTypes.CITY, formData.cust_place);

  const {
    PredefinedData: { dropDown: States },
  } = usePredefinedDataByType(PredefinedDataTypes.STATE, formData.cust_state);

  const HandleRegister = () => {
    createCustomer({
      variables: {
        token,
        input: {
          cust_name: removeMoreSpace(formData.cust_name),
          cust_address: removeMoreSpace(formData.cust_address),
          cust_place: removeMoreSpace(formData.cust_place),
          cust_state: removeMoreSpace(formData.cust_state),
          cust_pin: formData.cust_pin.toString(),
          cust_contact_person: removeMoreSpace(formData.cust_contact_person),
          cust_phone: formData.cust_phone,
          cust_email: removeMoreSpace(formData.cust_email),
          cust_mobile: formData.cust_mobile,
          cust_banner_1: removeMoreSpace(formData.cust_banner_1),
          cust_banner_2: removeMoreSpace(formData.cust_banner_2),
          cust_url: formData.cust_url,
          cust_logo_filename: filename,
        },
        user_details,
      },

      refetchQueries: [
        {
          query: GetCustomers,
          variables: {
            token,
            first: ROWS_PER_PAGE,
            direction: Direction.ASC,
            name: EMPTY_STRING,
            after: null,
            sortBy: SortBy.CUST_NAME,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Customer Created Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
    setFormData({
      cust_name: "",
      cust_address: "",
      cust_email: "",
      cust_contact_person: "",
      cust_state: "",
      cust_pin: "",
      cust_url: "",
      cust_banner_1: "",
      cust_banner_2: "",
      cust_phone: "",
      cust_mobile: "",
      cust_place: "",
      cust_logo_filename: "",
    });
    setCustomerModal(!customerModal);
    setType(Operation.CREATE);
  };

  const HandleUpdate = async () => {
    await UpdateCustomerByid({
      variables: {
        id: customerId,
        token,
        input: {
          cust_name: removeMoreSpace(formData.cust_name),
          cust_address: removeMoreSpace(formData.cust_address),
          cust_place: removeMoreSpace(formData.cust_place),
          cust_state: removeMoreSpace(formData.cust_state),
          cust_pin: formData.cust_pin,
          cust_contact_person: removeMoreSpace(formData.cust_contact_person),
          cust_phone: formData.cust_phone,
          cust_email: formData.cust_email,
          cust_mobile: formData.cust_mobile,
          cust_banner_1: removeMoreSpace(formData.cust_banner_1),
          cust_banner_2: removeMoreSpace(formData.cust_banner_2),
          cust_url: formData.cust_url,
          cust_logo_filename: filename,
        },
        user_details,
      },
      refetchQueries: [
        {
          query: GetCustomers,
          variables: {
            token,
            first: ROWS_PER_PAGE,
            direction: Direction.ASC,
            name: "",
            after: null,
            sortBy: SortBy.CUST_NAME,
          },
        },
        {
          query: GetCustomerByNode,
          variables: { token, id: customerId },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Customer Updated Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
    setCustomerModal(!customerModal);
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }

    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleClear = () => {
    setFormData({
      cust_name: "",
      cust_address: "",
      cust_email: "",
      cust_contact_person: "",
      cust_state: "",
      cust_pin: "",
      cust_url: "",
      cust_banner_1: "",
      cust_banner_2: "",
      cust_phone: "",
      cust_mobile: "",
      cust_place: "",
      cust_logo_filename: "",
    });
    if (customerModal) {
      PlaceCloseRef.click();
      StateCloseRef.click();
      Name.focus();
    }
  };

  useEffect(() => {
    if (type === Operation.UPDATE && token) {
      GetCustomerDetail().then(({ data }) => {
        if (data) {
          const {
            cust_name,
            cust_address,
            cust_place,
            cust_state,
            cust_pin,
            cust_contact_person,
            cust_phone,
            cust_email,
            cust_mobile,
            cust_url,
            cust_banner_1,
            cust_banner_2,
            cust_logo_filename,
          } = data.node;

          setFormData({
            cust_name,
            cust_address,
            cust_place,
            cust_state,
            cust_pin,
            cust_contact_person,
            cust_phone,
            cust_email,
            cust_mobile,
            cust_url,
            cust_banner_1,
            cust_banner_2,
            cust_logo_filename,
          });
        }
      });
    }
  }, [type, GetCustomerDetail, customerModal, token]);

  useEffect(() => {
    if (token) {
      GetCustomerData();
    }
  }, [token, GetCustomerData]);
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;

      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetCustomers.edges;
                const pageInfo = fetchMoreResult.GetCustomers.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetCustomers.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetCustomers: {
                    edges: [...customers, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetCustomers.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };

      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetCustomers.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.custId && row.custId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setCustomers(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            custId: node.id,
            cust_name: node.cust_name,
            cust_place: node.cust_place,
            cust_contact_person: node.cust_contact_person,
            cust_mobile: node.cust_mobile,
          }))
        );
      } else {
        setCustomers(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            custId: node.id,
            cust_name: node.cust_name,
            cust_place: node.cust_place,
            cust_contact_person: node.cust_contact_person,
            cust_mobile: node.cust_mobile,
          }))
        );
      }
      setEndCursor(data.GetCustomers.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  return (
    <>
      <Home NavType={LicenseTypes.EDUATE_CUSTOMER} DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col">
          <Title>
            {CustomerTitle.Titles.map((title: TitleProps, index: React.Key) => {
              return <React.Fragment key={index}>{title.List}</React.Fragment>;
            })}
          </Title>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-2 button-left">
          <Input
            id="search"
            type="text"
            placeholder="Search ..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchData(e.target.value)
            }
          />
        </div>
        <div className="col"></div>

        <div className="col-1 ">
          <Button
            mode="addnew"
            autoFocus
            onClick={() => {
              setMessage({
                message: "",
                flag: false,
                operation: Operation.NONE,
              });
              handleClear();
              setType(Operation.CREATE);
              setCustomerModal(!customerModal);
            }}
            className="container__list--addnew"
          />
        </div>
      </div>
      <div className={`container__list g-0 ${dataClasses.root}`}>
        <DataGridPro
          columns={columns}
          rows={rows}
          onCellClick={(params) => {
            if (params.field === "cust_name")
              navigate(`/eduate/customer/${params.row.custId}/institutionlist`);
          }}
          disableRowSelectionOnClick
          disableChildrenSorting
          rowHeight={TABLE_ROW_HEIGHT}
          slotProps={{
            columnsPanel: { disableHideAllButton: true },
          }}
        />
      </div>
      <div className="button-left">
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={customerModal}
        style={ModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>
              {CustomerTitle.Titles.map(
                (title: TitleProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      {type === Operation.CREATE
                        ? title.Registration
                        : title.Update}
                    </React.Fragment>
                  );
                }
              )}
            </Title>

            <Formik
              initialValues={formData}
              validationSchema={customer_validation}
              onSubmit={
                type === Operation.CREATE ? HandleRegister : HandleUpdate
              }
              enableReinitialize
            >
              {(meta) => {
                return (
                  <Form className="registration">
                    <div className="row g-0 registration__data">
                      <div className="col h-100">
                        <div className="details">
                          <h4>Customer Details</h4>
                        </div>
                        {customerformLabels.Labels.PersonalDetails.map(
                          (label: LabelNameProps, index: React.Key) => {
                            return (
                              <React.Fragment key={index}>
                                <InputHoc
                                  LabelName={label.LabelName}
                                  name={label.inputName}
                                  type={label.dataType}
                                  values={formData[label.inputName]}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    meta.handleChange(e);
                                    handleValueChange(e);
                                  }}
                                  required={label.required}
                                  maxLength={label.maxLength}
                                  autoFocus={label.autoFocus}
                                />
                              </React.Fragment>
                            );
                          }
                        )}
                        <div className="details frame-space">
                          <h4>Location Details</h4>
                        </div>

                        <InputHoc
                          LabelName="Address"
                          type="text"
                          name="cust_address"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            meta.handleChange(e);
                            handleValueChange(e);
                          }}
                          values={formData.cust_address}
                        />

                        <div className="label-grid">
                          <Label>Place</Label>
                          <Autocomplete
                            classes={classes}
                            options={City}
                            ref={placeRef!}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (formData.cust_place) {
                                  handleMUISelectEvent(e);
                                }
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    cust_place: EMPTY_STRING,
                                  };
                                });
                              }
                            }}
                            value={
                              City?.find(
                                ({ value }) => value === formData.cust_place
                              )! ?? null
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    cust_place: (newValue as optionsType)
                                      ?.value,
                                  };
                                });
                              } else {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    cust_place: EMPTY_STRING,
                                  };
                                });
                              }
                            }}
                            openOnFocus
                            freeSolo
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                onChange={(e) => {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      cust_place: e.target.value!,
                                    };
                                  });
                                }}
                                fullWidth
                                classes={{ root: textClasses.formControlRoot }}
                              />
                            )}
                          />
                        </div>
                        <InputHoc
                          LabelName="Pin Code"
                          type="text"
                          name="cust_pin"
                          inputRef={pincodeRef}
                          onKeyDown={(e: any) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              handleMUISelectEvent(e);
                            }
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            meta.handleChange(e);
                            handleValueChange(e);
                          }}
                          values={formData.cust_pin}
                          maxLength={6}
                        />
                        <div className="label-grid">
                          <Label>State</Label>
                          <Autocomplete
                            classes={classes}
                            options={States!}
                            ref={stateRef!}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                handleMUISelectEvent(e);
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    cust_state: EMPTY_STRING,
                                  };
                                });
                              }
                            }}
                            value={
                              States?.find(
                                ({ value }) => value === formData.cust_state
                              )! ?? null
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    cust_state: (newValue as optionsType)
                                      ?.value,
                                  };
                                });
                              } else {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    cust_state: EMPTY_STRING,
                                  };
                                });
                              }
                            }}
                            openOnFocus
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                onChange={(e) => {
                                  setFormData((prevValues) => {
                                    return {
                                      ...prevValues,
                                      cust_state: e.target.value!,
                                    };
                                  });
                                }}
                                fullWidth
                                classes={{ root: textClasses.formControlRoot }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col h-100">
                        <div className="details">
                          <h4>Other Details</h4>
                        </div>
                        {customerformLabels.Labels.OtherDetails.map(
                          (label: LabelNameProps, index: React.Key) => {
                            return (
                              <React.Fragment key={index}>
                                <InputHoc
                                  LabelName={label.LabelName}
                                  name={label.inputName}
                                  type={label.dataType}
                                  values={formData[label.inputName]}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    meta.handleChange(e);
                                    handleValueChange(e);
                                  }}
                                />
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <>
                      <Button mode="save" buttonref={saveRef} type="submit" />

                      <Button
                        mode="clear"
                        type="button"
                        onClick={handleClear}
                      />
                      <Button
                        mode="cancel"
                        type="button"
                        onClick={() => setCustomerModal(!customerModal)}
                      />
                    </>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setCustomerModal(!customerModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={creationLoading || updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};
export default ManageCustomer;
