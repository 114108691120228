import { Autocomplete, Tab, Tabs, TextField } from "@mui/material";
import React, { useState } from "react";

import { Title } from "../../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import Home from "../../Home/Index";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import TestDetails from "./TestDetails";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useAcdDropDownData from "../../hooks/useAcdDropDownData";
import { responseType } from "../../../../utils/Form.types";
import { TabPanel, a11yProps } from "../../../../styles/Tabs";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";

const Index = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const handleChangeIndex = (index: number) => setValue(index);

  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue);
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Test Reports</Title>
      <div className="academics-test-report">
        <div className="row g-0 academics-test-report__select">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={departmentDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_BRANCH_KEY ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-2">
              <Autocomplete
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, sectionSelected)
                }
                classes={classes}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (sectionSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue);
                  } else {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="academics-test-report__tabs row g-0">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Test/Exam" value={0} {...a11yProps(0)} />

            <Tab label="attendance" value={1} {...a11yProps(1)} />
            <Tab label="Assignments" value={2} {...a11yProps(2)} />
          </Tabs>
        </div>
        <div className="academics-test-report__swipable row g-0">
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            className="h-100"
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <TestDetails
                branchSelected={branchSelected}
                departmentSelected={departmentSelected}
                classSelected={classSelected}
                semesterSelected={semesterSelected}
                sectionSelected={sectionSelected}
                setDepartmentSelected={setDepartmentSelected}
                setBranchSelected={setBranchSelected}
                setClassSelected={setClassSelected}
                setSemesterSelected={setSemesterSelected}
                setSectionSelected={setSectionSelected}
              />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              Attendance
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              Assignment
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    </>
  );
};

export default Index;
