import React, { useState } from "react";
import { optionsType, responseType } from "../../../../utils/Form.types";
import { Autocomplete, TextField } from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import useAcademicYears from "../../hooks/useAcademicYears";
import { isOptionEqualToValue } from "../../../../utils/UtilFunctions";

interface Props {
  valueId: number;
  onChange: (newValue: responseType | null) => void;
  label: string;
}
const AcademicsDropDown = ({ valueId, label, onChange }: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();

  const withoutLabelClass = formAutoCompleteStyles();
  const withoutLabelTextClass = formAutoCompleteTextStyles();

  const {
    academicYearResponse: { responseType },
  } = useAcademicYears();

  const foundAcdYrFromValueId =
    responseType.find(({ value }) => value === valueId) ?? null;

  return (
    <Autocomplete
      classes={label ? classes : withoutLabelClass}
      options={responseType}
      openOnFocus={true}
      value={foundAcdYrFromValueId}
      onChange={(
        e: React.SyntheticEvent<Element, Event>,
        newValue: responseType | null
      ) => onChange(newValue)}
      isOptionEqualToValue={(option) =>
        isOptionEqualToValue(option, foundAcdYrFromValueId)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || ""}
          InputLabelProps={{ shrink: label.length > 0 }}
          classes={{
            root: label
              ? textClasses.formControlRoot
              : withoutLabelTextClass.formControlRoot,
          }}
        />
      )}
    />
  );
};

export default AcademicsDropDown;
