import React, { useContext, useEffect, useState } from "react";
import Input from "../../../stories/Input/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import Compare from "../../../images/Compare.svg";
import { Button } from "../../../stories/Button/Button";
import Avatar from "../../../images/Avatar.svg";
import { Title } from "../../../stories/Title/Title";
import useLogsTableJson from "../json/useLogsTableJson";
import { TableHeaderProps } from "../../../Types/Tables";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcctStdConcessionsData,
  GetAcctStdConcessionsVars,
} from "../../../Types/Accounting";
import dayjs from "dayjs";

import { GetAcctStdConcessions } from "../../Accounts/queries/Vouchers/query";
import { Direction, Operation } from "../../../utils/Enum.types";
import { useParams } from "react-router-dom";

import useToken from "../../../customhooks/useToken";
import {
  toInputStandardDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";

interface Props {
  operation: Operation;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  userid: number;
  endDate: string;
  startDate: string;
  empName: string;
}
const ConcessionDetails = ({
  operation,
  setModal,
  userid,
  endDate,
  startDate,
  empName,
}: Props) => {
  const { Logs_Table } = useLogsTableJson();
  const { InstId } = useParams();
  const { token } = useToken();
  const [name, setname] = useState("");
  const { state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const [GetAcctConcession, { data: ConcessionData }] = useLazyQuery<
    GetAcctStdConcessionsData,
    GetAcctStdConcessionsVars
  >(GetAcctStdConcessions);

  useEffect(() => {
    if (userid && token && state.ActiveFinYr) {
      GetAcctConcession({
        variables: {
          after: null,
          direction: Direction.ASC,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          first: 200,
          input: {
            query_type:
              operation === Operation.CREATE
                ? "ADD_CONCESSION_LOG"
                : "EDIT_CONCESSION_LOG",
            vo_start_date: dayjs(startDate).format(),
            vo_end_date: dayjs(endDate).format(),
            user_details,
          },
          inst_id: InstId!,
          name,
          sortBy: "CONCESSION_VOUCH_DATE",
          token,
        },
      });
    }
  }, [
    state.ActiveFinYr,
    userid,
    startDate,
    endDate,
    GetAcctConcession,
    InstId,
    token,
    operation,
    name,
  ]);
  return (
    <>
      <Title>
        {operation === Operation.CREATE
          ? "Added Concession Details"
          : "Edited Concession Details"}
      </Title>
      <div className="emp-logs__filtered-data">
        <div className="emp-logs__filtered-data--select row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search..."
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
          </div>

          <div className="col-4">
            <div className="emp-logs__filtered-data--select--flex">
              <TextField
                value={toInputStandardDate(startDate)}
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                className="emp-logs__filtered-data--textfield"
              />
              <img src={Compare} alt="" />
              <TextField
                value={endDate}
                label="End Date"
                InputLabelProps={{ shrink: true }}
                className="emp-logs__filtered-data--textfield"
              />
            </div>
          </div>
          <div className="col"></div>
          <div className="col-3">
            <div className="emp-logs__filtered-data--profile">
              <img src={Avatar} alt="" />
              <div className="emp-logs__filtered-data--profile--details">
                <span>{empName}</span>
                {/* <b>Manager</b> */}
              </div>
            </div>
          </div>
        </div>
        <div className="emp-logs__filtered-data--tableblock">
          <TableContainer className="emp-logs__filtered-data--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Logs_Table.FeeReceiptDetails.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {ConcessionData?.GetAcctStdConcessions.edges.map(
                  (res, index) => {
                    const utcTimestamp = new Date(res.node.created_at);
                    const utcTimestampUpdated = new Date(res.node.updated_at);

                    return (
                      <TableRow>
                        <TableCell
                          className="emp-logs__filtered-data--table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className="emp-logs__filtered-data--table--number">
                          {res.node.concession_vouch_no}
                        </TableCell>
                        <TableCell
                          className="emp-logs__filtered-data--table--date"
                          id="td-center"
                        >
                          {toStandardDate(res.node.concession_vouch_date)}
                        </TableCell>
                        <TableCell className="emp-logs__filtered-data--table--number">
                          {res.node.mst_student.std_adm_no}
                        </TableCell>
                        <TableCell className="emp-logs__filtered-data--table--number">
                          {res.node.mst_student.std_reg_no}
                        </TableCell>
                        <TableCell>
                          {res.node.mst_student.first_name +
                            " " +
                            res.node.mst_student.middle_name +
                            " " +
                            res.node.mst_student.last_name}
                        </TableCell>
                        <TableCell className="emp-logs__filtered-data--table--desc">
                          {res.node.mst_student.branch.branch_desc}
                        </TableCell>
                        <TableCell className="emp-logs__filtered-data--table--desc">
                          {res.node.mst_student.class.class_desc}
                        </TableCell>
                        <TableCell
                          className="emp-logs__filtered-data--table--amount"
                          id="td-right"
                        >
                          {res.node.concession_total}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell
                          className="emp-logs__filtered-data--table--date"
                          id="td-center"
                        >
                          <TableCell
                            className="emp-logs__filtered-data--table--date"
                            id="td-center"
                          >
                            {operation === Operation.CREATE
                              ? utcTimestamp.toLocaleString()
                              : utcTimestampUpdated.toLocaleString()}
                          </TableCell>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
    </>
  );
};

export default ConcessionDetails;
