import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import {
  AcdSubjectType,
  InstitutionConfigurationTypes,
  Operation,
  SubjectType,
} from "../../../../utils/Enum.types";
import {
  StudentModalStyles,
  ViewBookDetailsModalStyles,
  WaiveOffModalStyles,
} from "../../../../styles/ModalStyles";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import { TableHeaderProps } from "../../../../Types/Tables";
import Home from "../../Home/Index";

import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";

import Delete from "../../../../images/Delete.svg";
import Close from "../../../../images/Close.svg";
import Enter from "../../../../images/Enter.svg";
import useAcademicSubjects, {
  SubjectDetailsNode,
} from "../../hooks/useAcademicSubjects";
import { useMutation } from "@apollo/client";
import More from "../../../../images/More.svg";
import Add from "../../../../images/Add.svg";
import Update from "../../../../images/Update.svg";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import LoadingModal from "../../../../pages/LoadingModal";
import { msgType, responseType } from "../../../../utils/Form.types";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { AddOrDeleteAcdSubjectClassAllotedDetails } from "../../queries/subjects/mutation/Index";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import CopySubjectDetails from "./CopySubjectDetails";

import { Keys } from "../../../../utils/Enum.keys";

import useDisplayAutoCompleteTag from "../../hooks/useDisplayAutoCompleteTag";
import NewSubject from "../NewSubject";
import useSubjectAllotedForClass from "../../hooks/useSubjectAllotedForClass";
import useInstConfigByEntryId from "../../hooks/useInstConfigByEntryId";
import { GetAcdSubjectAllotedForClass } from "../../queries/subjects/List.tsx/Index";
import { isOptionEqualToValue } from "../../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { Title } from "../../../../stories/Title/Title";
import ViewElectiveSubjects, {
  ElectiveSubjectData,
} from "../ElectiveSubject/ViewElectiveSubjects";
import { useStyles } from "../../../../styles/TooltipStyles";
import ElectiveSubjectAllocate from "../ElectiveSubject/ElectiveSubjectsAllocate";
import useCheckAllocationType from "../../hooks/useCheckAllocationType";
const { Academics_Table } = require("../../json/table.json");

interface Props {
  operation: Operation;
}
interface CustomModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  contentComponent: React.ReactNode;
}
const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onRequestClose,
  contentComponent,
}) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      style={StudentModalStyles}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
    >
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">{contentComponent}</div>
        <div className="modal-flex__image">
          <img src={Close} alt="Close" onClick={onRequestClose} />
        </div>
      </div>
    </Modal>
  );
};

interface ExtendedSubjectOptions extends responseType {
  board_code: string;
  subject_type: string;
}
const AllocateSubjects = ({ operation }: Props) => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const navigate = useNavigate();
  const { InstId, allotedID } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const [viewSubjects, setViewSubjects] = useState(false);
  const tooltipclasses = useStyles();

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [CopySubject, setCopySubject] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [items, setItems] = useState<SubjectDetailsNode[]>([]);

  const [deletedItems, setDeletedItems] = useState<SubjectDetailsNode[]>([]);
  const [enableToolTipModal, setEnableToolTipModal] = useState(false);
  const [allocateModal, setAllocateModal] = useState(false);
  const [allocateUpdateModal, setAllocateUpdateModal] = useState(false);

  const [filteredSubjects, setFilteredSubjects] = useState<
    ExtendedSubjectOptions[]
  >([]);

  const [subjectId, setSubjectId] = useState<ExtendedSubjectOptions | null>(
    null
  );
  const [electiveId, setElectiveId] = useState<ElectiveSubjectData>({
    id: 0,
    name: "",
  });

  const [addSubjectDetails, setAddSubjectDetails] = useState(false);
  const [electiveModal, setElectiveModal] = useState(false);
  //refs

  const subjectRef = useRef<HTMLSelectElement>(null);
  const subjectInputRef = subjectRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { flag } = useCheckAllocationType();
  const [AllocateSubjects, { loading }] = useMutation(
    AddOrDeleteAcdSubjectClassAllotedDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const { subjects } = useAcademicSubjects(
    0,
    0,
    0,
    searchText,
    ROWS_PER_PAGE,
    AcdSubjectType.GENERAL
  );

  const { activeAcademicYearData } = useActiveAcademicYear();
  const { token } = useToken();

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const { InstConfigDetails } = useInstConfigByEntryId(allotedID!);
  const {
    acd_dept_desc,
    acd_branch_desc,
    acd_class_desc,
    acd_semester_desc,
    acd_section_desc,
  } = InstConfigDetails.data
    ? InstConfigDetails.data.GetAcdInstConfigNames
    : {
        acd_dept_desc: "",
        acd_branch_desc: "",
        acd_class_desc: "",
        acd_semester_desc: "",
        acd_section_desc: "",
      };
  const { USE_DEPARTMENT_KEY, USE_BRANCH_KEY } = useInstitutionConfiguration();

  const { displayClass, displaySection, displaySemester } =
    useDisplayAutoCompleteTag();
  const { subjectsForClass } = useSubjectAllotedForClass(Number(allotedID));

  const handleAllocateSubjects = (operation: Operation) => {
    if (items.length === 0) {
      alert("Please Select Subjects");
      subjectInputRef?.select();
      subjectInputRef?.focus();
      return;
    }
    AllocateSubjects({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
        input: {
          acd_subject_class_main: {
            allotted_level:
              configData.data?.GetSwConfigVariables[0].config_string_value,
            allotted_id: allotedID,
          },
          add_subject_class_details: items.map((subject, index) => ({
            subj_sl: index + 1,
            subj_is_grade: false,
            subj_master_id: subject.id,
          })),
          delete_subject_class_details: deletedItems
            .filter(({ updated }) => updated)
            .map((subject) => ({
              subj_sl: subject.subj_idx,
              subj_is_grade: false,
              subj_master_id: subject.id,
            })),
        },
      },
      refetchQueries: [
        {
          query: GetAcdSubjectAllotedForClass,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            alloted_id: Number(allotedID),
            alloted_level:
              configData.data?.GetSwConfigVariables[0].config_string_value!,
            inst_id: InstId!,
            per_std_subj_allocation: flag,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (
        data &&
        isElectiveSubject.length === 0 &&
        operation === Operation.CREATE
      ) {
        setMessage({
          flag: true,
          message: "Successfully allocated subjects ",
          operation: Operation.CREATE,
        });
        navigate(`/${InstId}/academics/subjectallocationdetails`);
      } else if (operation === Operation.UPDATE && data) {
        setMessage({
          flag: true,
          message: "Successfully Updated subjects ",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (
      message.operation !== Operation.NONE &&
      message.flag &&
      operation === Operation.UPDATE
    ) {
      setItems([]);
      navigate(`/${InstId}/academics/subjectallocationdetails`);
    }
    if (message.operation !== Operation.NONE && message.flag) {
      setItems([]);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const handleItemDeleted = (ids: number) => {
    const updatedItems = items.filter(({ id }) => id !== ids);
    const filteredupdatedData = filteredSubjects.map((subject) => {
      if (subject.value === ids) {
        const newData = {
          ...subject,
          isChecked: false,
        };
        return newData;
      }
      return subject;
    });
    setFilteredSubjects(filteredupdatedData);
    setItems(updatedItems);
    if (operation === Operation.UPDATE) {
      const deletedItem = items.find((data) => data.id === ids);
      setDeletedItems((prevDeletedItems) =>
        deletedItem ? [...prevDeletedItems, deletedItem] : prevDeletedItems
      );
    }
  };

  const handleItems = () => {
    const filteredSubject = subjects.data?.GetAcdSubject.edges.find(
      (subject) => subject.node.id === subjectId?.value
    );
    const itemAlreadyExists = items.filter((d) => d.id === subjectId?.value);
    if (itemAlreadyExists.length >= 1) {
      alert("duplicate Subject entry");
      setSubjectId(null);

      subjectInputRef?.select();
      subjectInputRef?.focus();
      return;
    }

    const updatedData = filteredSubjects?.map((id) => {
      if (id.value === subjectId?.value) {
        const newData = {
          ...id,
          value: id.value,
          label: id.label,
          isChecked: !id.isChecked,
        };
        return newData;
      }
      return id;
    });
    setFilteredSubjects(updatedData);
    if (subjectId?.value && filteredSubject) {
      setItems((prevItems: SubjectDetailsNode[]) => [
        ...prevItems,
        {
          id: subjectId?.value,
          subj_apptitude_avialed: filteredSubject?.node.subj_apptitude_avialed,
          subj_board_code: filteredSubject?.node.subj_board_code,
          subj_code: filteredSubject?.node.subj_code,
          subj_desc: filteredSubject?.node.subj_desc,
          subj_idx: prevItems.length,
          subj_internals_avialed: filteredSubject?.node.subj_internals_avialed,
          subj_is_core_subject: filteredSubject?.node.subj_is_core_subject,
          subj_is_elective: filteredSubject?.node.subj_is_elective,
          subj_is_lang: filteredSubject?.node.subj_is_lang,
          subj_is_non_academic: filteredSubject?.node.subj_is_non_academic,
          subj_marks_in_grades: filteredSubject?.node.subj_marks_in_grades,
          isChecked: true,
          subj_is_lab: filteredSubject.node.subj_is_lab,
          teacher_count: filteredSubject.node.teacher_count,
          newAdded: true,
          updated: false,
        },
      ]);
    }

    setSubjectId(null);
    setSearchText("");
    subjectInputRef?.select();
    subjectInputRef?.focus();
  };

  const handleClear = () => {
    setItems([]);
  };
  const isElectiveSubject = items
    .filter((data) => data.subj_is_elective)
    .map((res) => res.subj_desc);

  useEffect(() => {
    if (subjects.data && !subjects.loading) {
      const checkSet = new Set(items.map((item) => item.id));
      const filteredResult = subjects.data.GetAcdSubject.edges.filter((el) => {
        return checkSet.has(el.node.id) === false;
      });

      setFilteredSubjects(
        filteredResult.map((edge) => ({
          value: edge.node.id,
          // eslint-disable-next-line
          label: edge.node.subj_desc + " " + `(${edge.node.subj_code})`,
          isChecked: false,
          board_code: edge.node.subj_board_code,
          subject_type: edge.node.subj_is_core_subject
            ? SubjectType.CORE
            : edge.node.subj_is_elective
            ? SubjectType.ELECTIVE
            : edge.node.subj_is_lang
            ? SubjectType.LANGUAGE
            : EMPTY_STRING,
        }))
      );
    }
  }, [subjects.data, subjects.loading, searchText, items]);

  useEffect(() => {
    if (
      subjectsForClass.data &&
      !subjectsForClass.loading &&
      operation !== Operation.CREATE &&
      allotedID
    ) {
      const { data } = subjectsForClass;
      setItems(
        data.GetAcdSubjectAllotedForClass.map((subject) => ({
          id: subject.subject_details.id!,
          subject_master_id: subject.id,
          subj_apptitude_avialed:
            subject.subject_details.subj_apptitude_avialed,
          subj_board_code: subject.subject_details.subj_board_code,
          subj_code: subject.subject_details.subj_code,
          subj_desc: subject.subj_is_elective
            ? `${
                subject.subject_details.subj_desc
              } (${subject.elective_details.map(
                (res) => res.subject_details.subj_desc
              )})`
            : subject.subject_details.subj_desc,
          subj_idx: subject.subj_sl,
          subj_internals_avialed:
            subject.subject_details.subj_internals_avialed,
          subj_is_core_subject: subject.subject_details.subj_is_core_subject,
          subj_is_elective: subject.subject_details.subj_is_elective,
          subj_is_lang: subject.subject_details.subj_is_lang,
          subj_is_non_academic: subject.subject_details.subj_is_non_academic,
          subj_marks_in_grades: subject.subject_details.subj_marks_in_grades,
          isChecked: true,
          subj_is_lab: subject.subject_details.subj_is_lab,
          teacher_count: subject.subject_details.teacher_count,
          newAdded: false,
          updated: true,
        }))
      );
    } // eslint-disable-next-line
  }, [subjectsForClass.data, subjectsForClass.loading, operation, allotedID]);

  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {operation === Operation.CREATE
          ? "Subject Allocation"
          : "Update Subject Allocation"}
      </Title>
      <div className="test-planner__subject-allocation">
        <div className="row g-0   test-planner__subject-allocation--select">
          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <TextField
                className="test-planner__subject-allocation--textfield"
                label={departmentLabel}
                value={acd_dept_desc}
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <TextField
                className="test-planner__subject-allocation--textfield"
                label={branchLabel}
                value={acd_branch_desc}
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </div>
          ) : null}
          {displayClass ? (
            <div className="col-1">
              <TextField
                className="test-planner__subject-allocation--textfield"
                label={classLabel}
                value={acd_class_desc}
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </div>
          ) : null}
          {displaySemester ? (
            <div className="col-1">
              <TextField
                className="test-planner__subject-allocation--textfield"
                label={semesterLabel}
                value={acd_semester_desc}
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </div>
          ) : null}
          {displaySection ? (
            <div className="col-1">
              <TextField
                className="test-planner__subject-allocation--textfield"
                label={sectionLabel}
                InputLabelProps={{ shrink: true }}
                disabled
                value={acd_section_desc}
              />
            </div>
          ) : null}
          <div className="col"></div>
          <div className="col-4 test-planner__subject-allocation--buttons">
            <Button mode="copy" onClick={() => setCopySubject(!CopySubject)}>
              Copy Subject From
            </Button>
            <Button
              mode="addnew"
              onClick={() => setAddSubjectDetails(!addSubjectDetails)}
            >
              &nbsp; New Subjects
            </Button>
          </div>
        </div>
        <div className="test-planner__subject-allocation--tableblock">
          <TableContainer className="test-planner__subject-allocation--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Academics_Table.AllocateSubjects.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="test-planner__subject-allocation--table--row">
                  <TableCell id="td-center"> {items.length + 1}</TableCell>
                  <TableCell>
                    <Autocomplete
                      ref={subjectRef}
                      classes={classes}
                      // disabled={!state}
                      value={subjectId}
                      options={filteredSubjects.filter(
                        ({ isChecked }) => !isChecked
                      )}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(option, subjectId)
                      }
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          if (subjectId?.value) {
                            buttonRef.current?.focus();
                          }
                        } else if (e.key === Keys.BACKSPACE) {
                          setSubjectId(null);
                        }
                      }}
                      // disabled={
                      //   !classSelected.value ||
                      //   !semesterSelected.value ||
                      //   !sectionSelected.value
                      // }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          const data = newValue as ExtendedSubjectOptions;
                          setSubjectId({
                            label: data.label,
                            value: data.value,
                            isChecked: true,
                            board_code: data.board_code,
                            subject_type: data.subject_type,
                          });
                        } else {
                          setSubjectId(null);
                        }
                      }}
                      openOnFocus
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => setSearchText(e.target.value)}
                          classes={{ root: textClasses.formControlRoot }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    {subjectId ? subjectId.board_code : EMPTY_STRING}
                  </TableCell>
                  <TableCell>
                    {subjectId ? subjectId.subject_type : EMPTY_STRING}
                  </TableCell>
                  <TableCell id="td-center">
                    <button
                      disabled={!subjectId?.value}
                      ref={buttonRef}
                      onClick={(e: React.FormEvent) => handleItems()}
                    >
                      <img alt="/" src={Enter} />
                    </button>
                    {/* <img src={Enter} alt="/" /> */}
                  </TableCell>
                </TableRow>
                {items
                  .filter((data) => !data.subj_is_non_academic)
                  .map((item, index) => {
                    return (
                      <TableRow>
                        <TableCell
                          className="allocatesubjects__table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell>{item.subj_desc}</TableCell>
                        <TableCell className="allocatesubjects__table--code">
                          {item.subj_board_code}
                        </TableCell>
                        <TableCell className="allocatesubjects__table--code">
                          {item.subj_is_core_subject
                            ? "Core"
                            : item.subj_is_elective
                            ? "Elective"
                            : item.subj_is_lang
                            ? "Language"
                            : item.subj_is_lab
                            ? "Lab"
                            : item.subj_is_non_academic
                            ? "Non Academic"
                            : EMPTY_STRING}
                        </TableCell>
                        <TableCell
                          className="allocatesubjects__table--actions"
                          id="td-center"
                        >
                          {operation !== Operation.DELETE &&
                          operation === Operation.CREATE ? (
                            <img
                              src={Delete}
                              alt="/"
                              onClick={() => handleItemDeleted(item.id)}
                            />
                          ) : null}
                          {operation !== Operation.DELETE &&
                          operation === Operation.UPDATE ? (
                            <img
                              src={Delete}
                              alt="/"
                              onClick={() => handleItemDeleted(item.id)}
                            />
                          ) : null}

                          {item.subj_is_elective &&
                          operation === Operation.UPDATE ? (
                            <Tooltip
                              onClick={() =>
                                setEnableToolTipModal(!enableToolTipModal)
                              }
                              classes={{
                                tooltip: tooltipclasses.studentOptions,
                              }}
                              placement="left-start"
                              title={
                                enableToolTipModal && (
                                  <>
                                    <ul>
                                      <li
                                        onClick={() => {
                                          setAllocateModal(!allocateModal);
                                          setElectiveId({
                                            id: item.subject_master_id!,
                                            name: item.subj_desc,
                                          });
                                        }}
                                        className="studentlist__table--more--fee"
                                      >
                                        <img src={Add} alt="/" />
                                        <span> Add Elective Subjects</span>
                                      </li>
                                      <li
                                        onClick={() => {
                                          setAllocateUpdateModal(
                                            !allocateUpdateModal
                                          );
                                          setElectiveId({
                                            id: item.subject_master_id!,
                                            name: item.subj_desc,
                                          });
                                        }}
                                        className="studentlist__table--more--fee"
                                      >
                                        <img src={Update} alt="/" />
                                        <span> Update Elective Subjects</span>
                                      </li>
                                    </ul>
                                  </>
                                )
                              }
                            >
                              <img src={More} alt="/" />
                            </Tooltip>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                <TableRow className="assign-class-teacher__table--open-elective">
                  <TableCell colSpan={5}>Non Academic Subjects</TableCell>
                </TableRow>

                {items
                  .filter((data) => data.subj_is_non_academic)
                  .map((item, index) => {
                    return (
                      <TableRow>
                        <TableCell
                          className="allocatesubjects__table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell>{item.subj_desc}</TableCell>
                        <TableCell className="allocatesubjects__table--code">
                          {item.subj_board_code}
                        </TableCell>
                        <TableCell className="allocatesubjects__table--code">
                          {item.subj_is_core_subject
                            ? "Core"
                            : item.subj_is_elective
                            ? "Elective"
                            : item.subj_is_lang
                            ? "Language"
                            : item.subj_is_lab
                            ? "Lab"
                            : item.subj_is_non_academic
                            ? "Non Academic"
                            : EMPTY_STRING}
                        </TableCell>
                        <TableCell
                          className="allocatesubjects__table--actions"
                          id="td-center"
                        >
                          {operation !== Operation.DELETE &&
                          operation === Operation.CREATE ? (
                            <img
                              src={Delete}
                              alt="/"
                              onClick={() => handleItemDeleted(item.id)}
                            />
                          ) : null}
                          {operation !== Operation.DELETE &&
                          operation === Operation.UPDATE ? (
                            <img
                              src={Delete}
                              alt="/"
                              onClick={() => handleItemDeleted(item.id)}
                            />
                          ) : null}

                          {item.subj_is_elective &&
                          operation === Operation.UPDATE ? (
                            <Tooltip
                              onClick={() =>
                                setEnableToolTipModal(!enableToolTipModal)
                              }
                              classes={{
                                tooltip: tooltipclasses.studentOptions,
                              }}
                              placement="left-start"
                              title={
                                enableToolTipModal && (
                                  <>
                                    <ul>
                                      <li
                                        onClick={() => {
                                          setAllocateModal(!allocateModal);
                                          setElectiveId({
                                            id: item.subject_master_id!,
                                            name: item.subj_desc,
                                          });
                                        }}
                                        className="studentlist__table--more--fee"
                                      >
                                        <img src={Add} alt="/" />
                                        <span> Add Elective Subjects</span>
                                      </li>
                                      <li
                                        onClick={() => {
                                          setAllocateUpdateModal(
                                            !allocateUpdateModal
                                          );
                                          setElectiveId({
                                            id: item.subject_master_id!,
                                            name: item.subj_desc,
                                          });
                                        }}
                                        className="studentlist__table--more--fee"
                                      >
                                        <img src={Update} alt="/" />
                                        <span> Update Elective Subjects</span>
                                      </li>
                                    </ul>
                                  </>
                                )
                              }
                            >
                              <img src={More} alt="/" />
                            </Tooltip>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {operation === Operation.CREATE && (
          <Button
            mode="save"
            disabled={!items.length}
            onClick={() => {
              if (isElectiveSubject && isElectiveSubject.length > 0) {
                setElectiveModal(!electiveModal);
                handleAllocateSubjects(operation);
              } else handleAllocateSubjects(operation);
            }}
          />
        )}
        {operation === Operation.UPDATE && (
          <Button
            mode="save"
            disabled={!items.length}
            onClick={() => {
              handleAllocateSubjects(Operation.UPDATE);
            }}
          />
        )}
        <Button mode="clear" onClick={handleClear} />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      {/* Copy Subject Details */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={CopySubject}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <CopySubjectDetails
              setModalFlag={setCopySubject}
              setItems={setItems}
              items={items}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setCopySubject(!CopySubject)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addSubjectDetails}
        style={ViewBookDetailsModalStyles}
        ariaHideApp={false}
      >
        <NewSubject
          setAddSubjectDetails={setAddSubjectDetails}
          operation={Operation.CREATE}
        />
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={electiveModal}
        style={WaiveOffModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title variant="subtitle1">
              {" "}
              Would you like to add Subjects to{" "}
            </Title>
            <p>
              {isElectiveSubject.map((subject, index) => (
                <div
                  key={index}
                  className="test-planner__subject-allocation--sub-list"
                >
                  {subject}
                  {index !== isElectiveSubject.length - 1 && <br />}
                </div>
              ))}
            </p>

            <Button mode="yes" onClick={() => setViewSubjects(!viewSubjects)} />
            <Button
              mode="no"
              onClick={() =>
                navigate(`/${InstId}/academics/subjectallocationdetails`)
              }
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setElectiveModal(!electiveModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={allocateUpdateModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ElectiveSubjectAllocate
              operation={Operation.UPDATE}
              electiveId={electiveId}
              setAllocateModal={setAllocateModal}
              setAllocateUpdateModal={setAllocateUpdateModal}
              setElectiveId={setElectiveId}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="Close"
              onClick={() => setAllocateUpdateModal(!allocateUpdateModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={allocateModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ElectiveSubjectAllocate
              operation={Operation.CREATE}
              electiveId={electiveId}
              setAllocateModal={setAllocateModal}
              setAllocateUpdateModal={setAllocateUpdateModal}
              setElectiveId={setElectiveId}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="Close"
              onClick={() => setAllocateModal(!allocateModal)}
            />
          </div>
        </div>
      </Modal>
      <CustomModal
        isOpen={viewSubjects}
        onRequestClose={() => setViewSubjects(!viewSubjects)}
        contentComponent={
          <>
            <ViewElectiveSubjects setModal={setViewSubjects} />
          </>
        }
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={loading} />
    </>
  );
};

export default AllocateSubjects;
