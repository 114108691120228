import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";
import { Actions, SortBy, sortbyOptionsType } from "./types";
import { optionsType, responseType } from "../../../../../utils/Form.types";

export type InitialStateType = {
  dept: responseType | null;
  branch: responseType | null;
  class: responseType | null;
  sem: responseType | null;
  section: responseType | null;
  category: responseType | null;
  selectedStatus: string[];
  selectedSortBy: sortbyOptionsType;
  caste: optionsType | null;
  religion: optionsType | null;
  selectedFields: optionsType[];
  aboveAge: number;
  belowAge: number;
};

export const initialState: InitialStateType = {
  dept: null,
  branch: null,
  class: null,
  sem: null,
  section: null,
  category: null,
  selectedStatus: [],
  selectedSortBy: {
    label: "Student Name",
    value: SortBy.STD_NAME,
  },
  caste: null,
  religion: null,
  selectedFields: [],
  aboveAge: 0,
  belowAge: 0,
};

const StatesContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<Actions>;
}>({
  state: initialState,
  dispatch: () => null,
});

interface Props {
  children: React.ReactNode;
}

const StatesProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StatesContext.Provider value={{ dispatch, state }}>
      {children}
    </StatesContext.Provider>
  );
};
export { StatesProvider, StatesContext };
