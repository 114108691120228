import { gql } from "@apollo/client";

export const ValidateStudentDataForImport = gql`
  query ValidateStudentDataForImport(
    $token: String!
    $inst_id: ID!
    $input: ValidateStdData!
  ) {
    ValidateStudentDataForImport(
      token: $token
      inst_id: $inst_id
      input: $input
    )
  }
`;
export const ValidateAcdTestMarksForStd = gql`
  query ValidateAcdTestMarksForStd(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $acd_test_class_id: ID!
    $student_id: ID!
    $marks_details: [SubjectWiseMarks!]!
    $entry_level: String!
    $entry_id: ID!
    $per_std_subj_allocation: Boolean!
  ) {
    ValidateAcdTestMarksForStd(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      acd_test_class_id: $acd_test_class_id
      student_id: $student_id
      marks_details: $marks_details
      entry_level: $entry_level
      entry_id: $entry_id
      per_std_subj_allocation: $per_std_subj_allocation
    )
  }
`;

export const ExportAdmStdtoExcel = gql`
  query ExportAdmStdtoExcel(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $query_type: ExcelAdmQueryType!
    $input: ExcelQueryData!
    $parameters: [String!]!
    $sort_by: SortBy!
  ) {
    ExportAdmStdtoExcel(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      query_type: $query_type
      input: $input
      parameters: $parameters
      sort_by: $sort_by
    )
  }
`;

export const ExportAcctStdtoExcel = gql`
  query ExportAcctStdtoExcel(
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
    $query_type: ExcelAcctQueryType!
    $input: ExcelQueryData!
    $parameters: [String!]!
    $sort_by: SortBy!
  ) {
    ExportAcctStdtoExcel(
      token: $token
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      query_type: $query_type
      input: $input
      parameters: $parameters
      sort_by: $sort_by
    )
  }
`;
