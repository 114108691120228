import React, { useState } from "react";
import Home from "../../Academics/Home/Index";
import { Title } from "../../../stories/Title/Title";
import InstDetails from "../DailyActivities/InstDetails";
import { Button } from "../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import SubjectsList from "./SubjectsList";
import Create from "./Create";
import { SubjectFormData, SubjectType } from "../types/subject/Subject";
const Assign = () => {
  const navigate = useNavigate();

  const [selectedSubject, setSelectedSubject] = useState<SubjectType | null>(
    null
  );

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Lesson Planner</Title>
      <div className="assign-lesson-planner">
        <InstDetails />
        <div className="assign-lesson-planner__frame">
          <div className="assign-lesson-planner__frame--left">
            <SubjectsList
              selectedSubject={selectedSubject}
              setSelectedSubject={setSelectedSubject}
            />
          </div>
          <div className="assign-lesson-planner__frame--right">
            <Create selectedSubject={selectedSubject} />
          </div>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Assign;
