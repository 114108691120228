import React, { useContext, useEffect, useRef, useState } from "react";
import { Title } from "../../../stories/Title/Title";

import {
  Autocomplete,
  Checkbox,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import {
  BankOrCash,
  DebitOrCredit,
  Direction,
  Operation,
  ReceiptTypes,
  SortBy,
  StudentAcctReportType,
  TableHeaders,
  VoucherBookKey,
} from "../../../utils/Enum.types";
import { Label } from "../../../stories/Label/Label";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../utils/constants";
import { Keys } from "../../../utils/Enum.keys";
import { msgType, responseType } from "../../../utils/Form.types";
import {
  AcctLedgerQueryType,
  AcctStudentQueryType,
  DepositLedgerQueryType,
} from "../common/QueryTypes";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import {
  formatter,
  getModifiedScrollHeight,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import { useParams } from "react-router-dom";
import useAcctLedgerData from "../hooks/useAcctLedgerData";
import MessageModal from "../../../pages/MessageModal";
import { GetStdCompleteFeeDetailsByStudentID } from "../../../queries/students/list/byId";

import DownArrow from "../../../images/DownArrow.svg";

import useDropdownData from "../../../customhooks/useDropdownData";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import { nodevars } from "../../../Types/Accounting";
import UseStudentsbyDemandAmount, {
  GetAcctStdDemandData,
  StudentDemandEdges,
  vars,
} from "../hooks/useStudentsbyDemandAmount";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";

import {
  GetAcctVoucherAnonymousNodeDetails,
  UnkownStudentByMasterVoucherIdNodeData,
} from "../hooks/useAcctVoucherDetailsByDates";
import { UnkownStudentByMasterVoucherId } from "../../../queries/common";
import { AppContext } from "../../../context/context";
import { AddReconciledStdReceipt } from "../queries/receipts/mutation/Index";
import { VoucherBookData, VoucherBookVars } from "../hooks/useVoucherNumber";
import {
  GetAcctVoucherAnonymous,
  GetVoucherNumber,
} from "../queries/Vouchers/query";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import {
  AcctStudentType,
  InstitutionAcctConfigurationTypes,
} from "../common/Enum.types";
import { GetAcctStdDeposits } from "../queries/FeeLedgers/query/Byid";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import StudentTotalCount from "../../Master/Student/Components/StudentTotalCount";
import { AdjustFeeStyles } from "../../../styles/DrawerStyles";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import LoadingModal from "../../../pages/LoadingModal";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import { GetAcctStdDemandForAgents } from "../../../queries/students/list/newApi";

enum TableHeaderFor {
  MAIN_TABLE = "Main Table",
  SELECTED_TABLE = "Selected Table",
}

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (studentId?: number) => void;
  rowCount: number;
  tableType: TableHeaderFor;
}

interface Props {
  setAdjustmentsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Adjustment = ({ setAdjustmentsModal }: Props) => {
  const { token } = useToken();
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const formClasses = formAutoCompleteStyles();
  const formTextClasses = formAutoCompleteTextStyles();
  const drawerClasses = AdjustFeeStyles();

  const { format } = formatter;
  const { InstId } = useParams<{ InstId: string }>();
  const { state } = useContext(AppContext);

  const [drawer, setDrawer] = useState(false);

  const [branchId, setBranchId] = useState<responseType | null>(null);
  const [classId, setClassId] = useState<responseType | null>(null);
  const [departmentId, setDepartmentId] = useState<responseType | null>(null);
  const [semId, setSemId] = useState<responseType | null>(null);
  const [sectionId, setSectionId] = useState<responseType | null>(null);

  const [searchData, setSearchData] = useState("");
  const [searchStudentName, setSearchStudentName] = useState("");
  const [categoryId, setCategoryId] = useState<responseType | null>(null);
  const [rcptDate, setRcptDate] = useState("");

  const [selectedStudents, setSelectedStudents] = useState(new Set());
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0,
  });
  const [UpdatedReceviedAmount, setUpdatedReceviedAmount] = useState(0);

  const [accountLedgerId, setAccountLedgerId] = useState<responseType | null>(
    null
  );

  const [oldReceiptData, setOldReceiptData] =
    useState<GetAcctVoucherAnonymousNodeDetails>();
  const [hasNextPage, setHasNextPage] = useState(true);
  const [studentSelectedFlag, setStudentSelectedFlag] = useState(false);
  const [students, setStudents] = useState<StudentDemandEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [entryId, setEntryId] = useState(0);

  const [date, setDate] = useState(TODAY_DATE);
  const [receivedAmount, setReceivedAmount] = useState(0);

  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const { user_details } = useLoggedInUserDetails();

  const [GetVoucherDetailsByVoucherId] = useLazyQuery<
    UnkownStudentByMasterVoucherIdNodeData,
    nodevars
  >(UnkownStudentByMasterVoucherId);

  const {
    USE_CATEGORY_KEY,
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel
  } = useInstLabels();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentId ? departmentId.value : 0,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    semId ? semId.value : 0
  );

  const [GetAccountStudents, { data, loading, fetchMore }] = useLazyQuery<
    GetAcctStdDemandData,
    vars
  >(GetAcctStdDemandForAgents, {
    variables: {
      after: null,
      balance: null,
      direction: Direction.ASC,
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      first: ROWS_PER_PAGE,
      input: {
        ids: state.agentId
          ? [
              Number(InstId),
              entryId,
              categoryId ? categoryId.value : 0,
              state.agentId,
            ]
          : [Number(InstId), entryId, categoryId ? categoryId.value : 0],
        std_demand_query_type: state.agentId
          ? AcctStudentQueryType.BY_AGENT_STUDENT_ASSOCI_ID
          : AcctStudentQueryType.ACCT_STD_DEMAND_BY_ENTRY_ID,
      },
      name: searchStudentName,
      status: "",
      stdFresher: false,
      token,
      sortBy: SortBy.STUDENT_ID,
    },
  });

  const filteredCreditData =
    oldReceiptData?.voucher_master_details?.acct_voucher_details?.find(
      (data) => data.vo_cr_db === DebitOrCredit.CREDIT
    );

  const { categoryDropDown } = useInstMasterDataByInstId();
  const { acctLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.DEPOSIT_FEE_LDGRS,
    ROWS_PER_PAGE
  );
  const { configData: AllowPartialPayment } = useSwConfigData(
    InstitutionAcctConfigurationTypes.ALLOW_PARTIAL_SCHOLARSHIP_ADJUSTMENT
  );

  const [GenerateStudentReceipts, { loading: VohcerLoading }] = useMutation(
    AddReconciledStdReceipt,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetVoucherData] = useLazyQuery<VoucherBookData, VoucherBookVars>(
    GetVoucherNumber,
    { fetchPolicy: "network-only" }
  );
  const { data: serverDateData, loading: serverDateLoading } =
    useServerDateandTime();
  const toggleStudents = (studentId?: number) => {
    if (studentId !== undefined) {
      // Toggle the isChecked value for an individual student with the given studentId

      const selectedStudent = selectedStudents;
      if (selectedStudent.has(studentId)) {
        selectedStudent.delete(studentId);
        setSelectedStudents(selectedStudent);
      } else {
        selectedStudent.add(studentId);
        setSelectedStudents(selectedStudent);
      }
      setStudentSelectedFlag(!studentSelectedFlag);
    } else {
      // Toggle the isChecked value for all students at once
      if (data) {
        if (selectedStudents.size === data.GetAcctStdDemand.edges.length) {
          setSelectedStudents(new Set());
        } else {
          setSelectedStudents(
            new Set([
              ...data.GetAcctStdDemand.edges.map(
                (ele) => ele.node.mst_student.id
              ),
            ])
          );
        }
      }
      setStudentSelectedFlag(!studentSelectedFlag);
    }
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    //enableAdd and enableEdit should stay false when you close the success modal
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const onEdit = (id: number, updatedRcptAmount: number) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setUpdatedReceviedAmount(updatedRcptAmount);
  };

  const handleClear = () => {
    setBranchId(null);
    setClassId(null);
    setCategoryId(null);
    setAccountLedgerId(null);
    setStudents((prev) => [
      ...prev.map((d) => ({
        ...d,
        node: {
          ...d.node,
          mst_student: {
            ...d.node.mst_student,
            isChecked: false,
            amount: 0,
          },
        },
      })),
    ]);
  };
  const updateItem = (
    dataArray: StudentDemandEdges[],
    id: number,
    updatedAmount: number
  ) => {
    return dataArray.map((item) => {
      if (item.node.mst_student.id === id) {
        return {
          ...item,
          node: {
            ...item.node,
            mst_student: {
              ...item.node.mst_student,
              amount: updatedAmount,
            },
          },
        };
      }
      return item;
    });
  };

  let totalAssignedAmount = students.length
    ? students
        .filter((student) => selectedStudents.has(student.node.mst_student.id))
        .reduce((acc, i) => {
          acc += i.node.mst_student.amount ? i.node.mst_student.amount : 0;
          return acc;
        }, 0)
    : 0;
  const HandleEditItem = (sl_number: number) => {
    const updatedData = updateItem(students, sl_number, UpdatedReceviedAmount);
    if (updatedData) {
      setStudents(updatedData);
    } else alert("Invalid ledger type");
  };
  const handledwStddentReceipts = async () => {
    if (
      !AllowPartialPayment.data?.GetSwConfigVariables[0].config_boolean_value &&
      totalAssignedAmount !== receivedAmount
    ) {
      alert("Please Assign Complete Amount to the students");
      return;
    }
    const selectedDemandStudents = students.filter(
      (student) =>
        selectedStudents.has(student.node.mst_student.id) &&
        student.node.mst_student.amount! > 0
    );

    for (const studentEdge of selectedDemandStudents) {
      const voucherData = await GetVoucherData({
        variables: {
          vo_book_key: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
          token,
          inst_id: InstId!,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        },
      });
      // Use the fetched voucher number for each student
      await (async () => {
        GenerateStudentReceipts({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

            voucher_master_id: oldReceiptData?.voucher_master_details.id,
            input: {
              acct_voucher_master: {
                fin_yr: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

                v_type: ReceiptTypes.STUDENT_RECEIPT,
                v_no: voucherData?.data?.GetVoucherNumber.vo_number,
                v_date: toIsoDate(rcptDate),
                v_total_cr_amt: studentEdge.node.mst_student.amount,
                v_total_db_amt: studentEdge.node.mst_student.amount,
                v_reconciled: false,
                v_reconciled_date: toIsoDate(DEFAULT_TIME),
                v_std_receipt: true,
                v_std_non_demand_receipt: false,
                v_std_refund: false,
                v_std_enquiry: false,
                v_std_receipt_ob: false,
                enquiry_student_id: 0,
                v_std_deposit_adjusted: false,
                v_book_type: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
                v_std_deposit: true,
                v_std_passout_receipt: false,
                v_std_demand_receipt: false,

                v_std_scholarship_deposit: true,
                v_std_receipt_anonymous: false,
                v_std_anonymous_deposit_adjusted: true,
                student_id: studentEdge.node.mst_student.id,
                v_std_refund_deposit: false,
                class_id: studentEdge.node.mst_student.class.id,
                semester_id: studentEdge.node.mst_student.semester.id,
                v_std_amt_receipt: studentEdge.node.mst_student.amount,
                v_std_amt_deposit: studentEdge.node.mst_student.amount,
                v_std_amt_fine: 0,
                v_std_amt_total: studentEdge.node.mst_student.amount,
                v_std_amt_refunded: 0,
                v_std_amt_adjusted: 0,
                v_transcation_type: `Adjusted from ${filteredCreditData?.acct_ldgr.ldgr_desc}`,
                v_transcation_cash_or_bank: BankOrCash.ADJ,
                v_transcation_no: oldReceiptData?.v_transcation_no ?? "",
                v_transcation_date: toIsoDate(DEFAULT_TIME),
                v_transcation_narration: "",

                // paid_party_id: "",
                // party_bill_no: "",
                party_bill_date: toIsoDate(DEFAULT_TIME),
                // party_name: "",
                annx_yesno: false,
                // annx_id: Math.random() * 1000,
                is_vouch_multi_entry: false,
                acct_ldgr_id_cr: accountLedgerId?.value,
                acct_ldgr_id_db: filteredCreditData?.acct_ldgr.id,
              },
              acct_voucher_db: {
                vo_cr_db: DebitOrCredit.DEBIT,
                vo_sl_no: 1,
                vo_cr: 0,
                vo_db: studentEdge.node.mst_student.amount,
                vo_cr_total: 0,
                vo_db_total: studentEdge.node.mst_student.amount,
                acct_ldgr_id: filteredCreditData?.acct_ldgr.id,
              },
              acct_voucher_cr: {
                vo_sl_no: 1,
                vo_cr_db: DebitOrCredit.CREDIT,
                vo_cr: studentEdge.node.mst_student.amount,
                vo_db: 0,
                vo_cr_total: studentEdge.node.mst_student.amount,
                vo_db_total: 0,
                acct_ldgr_id: accountLedgerId?.value,
              },
            },
          },
          refetchQueries: [
            {
              query: GetAcctVoucherAnonymous,
              variables: {
                after: null,
                direction: Direction.ASC,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

                first: ROWS_PER_PAGE,
                sortBy: "CREATED_AT",
                token,
                vTranscationNo: "",
                inst_id: InstId!,
                anonymous_std_type: AcctStudentType.AnonymousRecolied,
              },
            },
            {
              query: GetAcctVoucherAnonymous,
              variables: {
                after: null,
                direction: Direction.ASC,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

                first: ROWS_PER_PAGE,
                sortBy: "CREATED_AT",
                token,
                vTranscationNo: "",
                inst_id: InstId!,
                anonymous_std_type: AcctStudentType.SocialWelfare,
              },
            },
            {
              query: GetAcctStdDeposits,
              variables: {
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

                query_type: DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID,
                student_id: state.studentId,
                token,
                inst_id: InstId,
              },
            },
            {
              query: GetStdCompleteFeeDetailsByStudentID,
              variables: {
                token,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

                inst_id: InstId!,
                student_id: state.studentId,
              },
            },
            {
              query: GetVoucherNumber,
              variables: {
                vo_book_key: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
                token,
                inst_id: InstId!,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              },
            },
          ],
          // ... (other options and refetchQueries)
        }).then(({ data }) => {
          if (data) {
            setMessage({
              message: `Successfully generated fee`,
              flag: true,
              operation: Operation.CREATE,
            });
          }
        });
      })();

      await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust the pause time as needed
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctStdDemand.edges;
            const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
              ({
                node: {
                  mst_student: { id },
                },
              }) =>
                newEdges.findIndex(
                  ({
                    node: {
                      mst_student: { id: newId },
                    },
                  }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctStdDemand: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data?.GetAcctStdDemand.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctStdDemand.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) =>
              student.node.mst_student.id === newStudent.node.mst_student.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                mst_student: {
                  ...newStudent.node.mst_student,
                  amount: 0,
                  isChecked: false,
                },
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data.GetAcctStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    if (state.unknownReceiptId) {
      GetVoucherDetailsByVoucherId({
        variables: { token, ids: [state.unknownReceiptId] },
      }).then(({ data }) => {
        if (data) {
          setOldReceiptData(data.nodes[0]);
          setReceivedAmount(data.nodes[0].bal_amt);
        }
      });
    }
  }, [state.unknownReceiptId, GetVoucherDetailsByVoucherId, token]);

  useEffect(() => {
    if (serverDateData && !serverDateLoading) {
      setRcptDate(serverDateData.GetServerDateAndTime);
    }
  }, [serverDateData, serverDateLoading]);

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount, tableType } = props;

    return (
      <TableHead>
        <TableRow>
          {tableType !== TableHeaderFor.SELECTED_TABLE && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 ? numSelected === rowCount : false}
                onClick={() => onSelectAllClick(undefined)}
              />
            </TableCell>
          )}

          <TableCell>{TableHeaders.SLNO}</TableCell>
          <TableCell>{TableHeaders.REGISTER_NUMBER}</TableCell>
          <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
          <TableCell>{TableHeaders.NAME}</TableCell>
          <TableCell>{branchLabel}</TableCell>
          <TableCell>{classLabel}</TableCell>
          <TableCell>{categoryLabel}</TableCell>
          {tableType === TableHeaderFor.SELECTED_TABLE && (
            <TableCell>Amount</TableCell>
          )}
        </TableRow>
      </TableHead>
    );
  };

  useEffect(() => {
    if (sectionId && USE_SECTION_KEY) {
      setEntryId(sectionId.value);
    } else if (semId && USE_SEMESTER_KEY) {
      setEntryId(semId.value);
    } else if (classId && USE_CLASS_KEY) {
      setEntryId(classId.value);
    } else if (branchId && USE_BRANCH_KEY) {
      setEntryId(branchId.value);
    } else if (departmentId && USE_DEPARTMENT_KEY) {
      setEntryId(departmentId.value);
    } else {
      setEntryId(Number(InstId));
    }
  }, [
    sectionId,
    USE_SECTION_KEY,
    semId,
    USE_SEMESTER_KEY,
    classId,
    USE_CLASS_KEY,
    branchId,
    USE_BRANCH_KEY,
    departmentId,
    USE_DEPARTMENT_KEY,
    InstId,
  ]);

  useEffect(() => {
    if (token && entryId && state.ActiveFinYr) {
      GetAccountStudents();
    }
  }, [
    token,
    GetAccountStudents,
    InstId,
    entryId,
    state.agentId,
    searchStudentName,
    state.ActiveFinYr,
    categoryId,
  ]);
  return (
    <>
      <Title>Adjust Scholarship Receipt</Title>
      <div className="unknown-bank-trans__adjustments">
        <form className="unknown-bank-trans__adjustments--options row g-0">
          <div className="col-1">
            <Input
              id="search"
              placeholder="Search..."
              onChange={(e) => setSearchStudentName(e.target.value)}
            />
          </div>
          {USE_DEPARTMENT_KEY && (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={departmentDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentId)
                }
                value={departmentId}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (departmentId && e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentId(null);
                  }
                }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentId(newValue);
                  } else {
                    setDepartmentId(null);
                  }
                  setBranchId(null);
                  setClassId(null);
                  setCategoryId(null);
                  setSemId(null);
                  setSectionId(null);
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    autoFocus
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          )}
          {USE_BRANCH_KEY && (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={branchDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, branchId)
                }
                value={branchId}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (branchId && e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchId(null);
                  }
                }}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchId(newValue);
                  } else {
                    setBranchId(null);
                  }
                  setClassId(null);
                  setCategoryId(null);
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </div>
          )}
          {USE_CLASS_KEY && (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                value={classId}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, classId)
                }
                options={classDropDown}
                openOnFocus
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && classId) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassId(null);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassId(newValue);
                  } else {
                    setClassId(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </div>
          )}
          {USE_SEMESTER_KEY && (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                value={semId}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, semId)
                }
                options={semesterDropDown}
                openOnFocus
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && semId) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemId(null);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemId(newValue);
                  } else {
                    setSemId(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </div>
          )}
          {USE_SECTION_KEY && (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                value={sectionId}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, sectionId)
                }
                options={sectionDropDown}
                openOnFocus
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && sectionId) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionId(null);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionId(newValue);
                  } else {
                    setSectionId(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </div>
          )}
          {USE_CATEGORY_KEY && (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={categoryDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, categoryId)
                }
                value={categoryId}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.BACKSPACE) {
                    setCategoryId(null);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategoryId(newValue);
                  } else {
                    setCategoryId(null);
                  }
                }}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    label={categoryLabel}
                    {...params}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </div>
          )}
        </form>

        <div className="unknown-bank-trans__adjustments--tableblock">
          <TableContainer
            className="unknown-bank-trans__adjustments--table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <EnhancedTableHead
                onSelectAllClick={toggleStudents}
                numSelected={selectedStudents.size}
                rowCount={data ? data.GetAcctStdDemand.edges.length : 0}
                tableType={TableHeaderFor.MAIN_TABLE}
              />
              <TableBody>
                {students.map((edge, index) => {
                  return (
                    <TableRow
                      role="checkbox"
                      aria-checked={edge.node.mst_student.isChecked}
                      key={edge.node.mst_student.id}
                      selected={edge.node.mst_student.isChecked}
                    >
                      <TableCell
                        padding="checkbox"
                        id="td-center"
                        className="unknown-bank-trans__adjustments--table--checkbox"
                      >
                        <Checkbox
                          checked={selectedStudents.has(
                            edge.node.mst_student.id
                          )}
                          onClick={() =>
                            toggleStudents(edge.node.mst_student.id)
                          }
                        />
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="unknown-bank-trans__adjustments--table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="unknown-bank-trans__adjustments--table--regno">
                        {edge.node.mst_student.std_reg_no}
                      </TableCell>
                      <TableCell className="unknown-bank-trans__adjustments--table--regno">
                        {edge.node.mst_student.std_adm_no}
                      </TableCell>
                      <TableCell>
                        {edge.node.mst_student.first_name +
                          " " +
                          edge.node.mst_student.middle_name +
                          " " +
                          edge.node.mst_student.last_name}
                      </TableCell>
                      <TableCell className="unknown-bank-trans__adjustments--table--desc">
                        {edge.node.mst_student.branch.branch_desc}
                      </TableCell>
                      <TableCell className="unknown-bank-trans__adjustments--table--desc">
                        {edge.node.mst_student.class.class_desc}
                      </TableCell>
                      <TableCell className="unknown-bank-trans__adjustments--table--desc">
                        {edge.node.mst_student.category.cat_desc}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              mode="adjustment-receipts"
              onClick={() => setDrawer(!drawer)}
              disabled={selectedStudents.size === 0}
            />

            <Button mode="cancel" onClick={() => setAdjustmentsModal(false)} />
          </div>
          <div className="col feedemand-additionalfee-group__total">
            <StudentTotalCount
              totalCount={data ? data?.GetAcctStdDemand.totalCount : 0}
            />
            <Label>Selected</Label>
            <div className="feedemand-additionalfee-group__total--count">
              {
                students.filter((student) => student.node.mst_student.isChecked)
                  .length
              }
            </div>
          </div>
        </div>
      </div>
      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={drawer}
        onClose={() => setDrawer(!drawer)}
      >
        <div className="unknown-bank-trans__adjustments--drawer">
          <Title>Adjust Receipt</Title>
          <div className="row g-0 unknown-bank-trans__adjustments--drawer--details">
            <div className="col ">
              <div className="label-grid">
                <Label>Amount</Label>
                <Input value={format(receivedAmount)} disabled />

                <Label>Adjusted From</Label>
                <Input
                  disabled
                  value={`${filteredCreditData?.acct_ldgr.ldgr_desc} ( ${oldReceiptData?.voucher_master_details?.v_no} ${oldReceiptData?.v_transcation_no} ) `}
                />
                <Label>Deposit Ledger</Label>
                <Autocomplete
                  classes={formClasses}
                  options={acctLedgers.responseType}
                  openOnFocus
                  freeSolo
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, accountLedgerId)
                  }
                  value={accountLedgerId}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      const checkedStudents = students.filter(
                        ({ node }) => node.mst_student.isChecked
                      );

                      if (accountLedgerId && checkedStudents.length > 0) {
                        setInEditMode({
                          rowKey: checkedStudents[0].node.mst_student.id,
                          status: true,
                        });
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setAccountLedgerId(null);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      const data = newValue as responseType;
                      setAccountLedgerId(data);
                    } else {
                      setAccountLedgerId(null);
                    }
                  }}
                  popupIcon={<img src={DownArrow} alt="/" />}
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      onChange={(e) => setSearchData(e.target.value)}
                      classes={{ root: formTextClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
              <div className="unknown-bank-trans__adjustments--drawer--temp-flex">
                <div>
                  <TextField
                    type="date"
                    label="Date"
                    className="unknown-bank-trans__adjustments--drawer--details--date"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputProps: {
                        min: state.ActiveFinYr
                          ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                          : EMPTY_STRING,
                        max: toInputStandardDate(TODAY_DATE),
                      },
                    }}
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
                <div className="unknown-bank-trans__adjustments--drawer--remaining-bal">
                  Remaining Balance:
                  <span className="font-red">
                    {format(receivedAmount - totalAssignedAmount)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="unknown-bank-trans__adjustments--drawer--tableblock">
            <TableContainer
              className="unknown-bank-trans__adjustments--drawer--table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>{TableHeaders.SLNO}</TableCell>
                    <TableCell>{TableHeaders.REGISTER_NUMBER}</TableCell>
                    <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
                    <TableCell>{TableHeaders.NAME}</TableCell>
                    <TableCell>{TableHeaders.AMOUNT}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students
                    .filter((student) =>
                      selectedStudents.has(student.node.mst_student.id)
                    )
                    .map((edge, index) => {
                      return (
                        <TableRow
                          role="checkbox"
                          aria-checked={edge.node.mst_student.isChecked}
                          key={edge.node.mst_student.id}
                          selected={edge.node.mst_student.isChecked}
                        >
                          <TableCell
                            id="td-center"
                            className="unknown-bank-trans__adjustments--drawer--table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="unknown-bank-trans__adjustments--drawer--table--regno">
                            {edge.node.mst_student.std_reg_no}
                          </TableCell>
                          <TableCell className="unknown-bank-trans__adjustments--drawer--table--regno">
                            {edge.node.mst_student.std_adm_no}
                          </TableCell>
                          <TableCell>
                            {edge.node.mst_student.first_name +
                              " " +
                              edge.node.mst_student.middle_name +
                              " " +
                              edge.node.mst_student.last_name}
                          </TableCell>

                          <TableCell
                            className="unknown-bank-trans__adjustments--drawer--table--input"
                            id="td-right"
                            onClick={() => {
                              if (
                                accountLedgerId &&
                                selectedStudents.has(edge.node.mst_student.id)
                              ) {
                                onEdit(
                                  edge.node.mst_student.id!,
                                  edge.node.mst_student.amount!
                                );
                                if (edge.node.mst_student.amount! === 0) {
                                  onEdit(edge.node.mst_student.id!, 0);
                                }
                              }
                            }}
                          >
                            {inEditMode.status &&
                            inEditMode.rowKey === edge.node.mst_student.id ? (
                              <input
                                className="storybook-input"
                                autoFocus
                                disabled={
                                  !accountLedgerId ||
                                  !selectedStudents.has(
                                    edge.node.mst_student.id
                                  )
                                }
                                id="td-right"
                                type="number"
                                onBlur={(e) => {
                                  setInEditMode({
                                    status: false,
                                    rowKey: 0,
                                  });
                                }}
                                value={UpdatedReceviedAmount}
                                onFocus={(e) => e.target.select()}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  setUpdatedReceviedAmount(
                                    Number(e.target.value)!
                                  )
                                }
                                onKeyDown={(event: React.KeyboardEvent) => {
                                  if (event.key === Keys.ENTER) {
                                    const checkedStudents = students.filter(
                                      ({ node }) =>
                                        selectedStudents.has(
                                          node.mst_student.id
                                        )
                                    );
                                    if (index === checkedStudents.length - 1) {
                                      event.preventDefault();
                                      if (saveButtonRef.current) {
                                        saveButtonRef.current.focus();
                                      }
                                    }
                                    if (
                                      checkedStudents[index + 1] &&
                                      checkedStudents[index + 1].node
                                        .mst_student.id
                                    ) {
                                      setUpdatedReceviedAmount(
                                        checkedStudents[index + 1].node
                                          .mst_student.amount!
                                      );
                                      onEdit(
                                        checkedStudents[index + 1].node
                                          .mst_student.id,
                                        checkedStudents[index + 1].node
                                          .mst_student.amount!
                                      );
                                      HandleEditItem(edge.node.mst_student.id);
                                    } else {
                                      onEdit(
                                        edge.node.mst_student.id,
                                        edge.node.mst_student.amount!
                                      );
                                      HandleEditItem(edge.node.mst_student.id);

                                      setInEditMode({
                                        rowKey: -805,
                                        status: false,
                                      });
                                    }
                                  }
                                }}
                              />
                            ) : (
                              format(
                                edge.node.mst_student.amount
                                  ? edge.node.mst_student.amount
                                  : 0
                              )
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell className="total" colSpan={4}>
                      Total Amount :
                    </TableCell>
                    <TableCell className="totalcount" id="td-right">
                      {format(totalAssignedAmount)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
          <Button
            mode="save"
            disabled={totalAssignedAmount > receivedAmount}
            buttonref={saveButtonRef!}
            onClick={handledwStddentReceipts}
          />
          <Button mode="clear" onClick={handleClear} />
          <Button mode="cancel" onClick={() => setDrawer(!drawer)} />
        </div>
        <MessageModal
          modalFlag={message.flag!}
          value={message.message!}
          handleClose={handleClose}
          operation={message.operation!}
        />
        <LoadingModal flag={VohcerLoading} />
      </Drawer>
    </>
  );
};

export default Adjustment;
