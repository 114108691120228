import React, { useContext, useEffect } from "react";
import useAcdSubjectForEntryLevel from "../hooks/useAcdSubjectForEntryLevel";
import {
  AcdTeacherClassQueryType,
  GetAcdSubjectLessonStatusByStudentData,
  GetAcdSubjectLessonStatusByStudentVars,
  GetAcdSubjectLessonStatusByTeacherData,
  GetAcdSubjectLessonStatusByTeacherVars,
  GetAcdTeacherClassAssociationsData,
  GetAcdTeacherClassAssociationsVars,
  SubjectType,
} from "../types/subject/Subject";

import Correct from "../../../images/Correct.svg";

import Wrong from "../../../images/Wrong.svg";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdSubjectLessonStatusByStudent,
  GetAcdSubjectLessonStatusByTeacher,
  GetAcdTeacherClassAssociations,
} from "../queries/subjects/List.tsx/Index";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { AppContext } from "../../../context/context";
import useCheckAllocationType from "../hooks/useCheckAllocationType";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import useEmpDetailsById from "../../HR/hooks/useEmpDetailsById";
import { UserType } from "../../../utils/Enum.types";

interface Props {
  setSelectedSubject: React.Dispatch<React.SetStateAction<SubjectType | null>>;
  selectedSubject: React.SetStateAction<SubjectType | null>;
}
const SubjectsList = ({ setSelectedSubject, selectedSubject }: Props) => {
  const { data, loading } = useAcdSubjectForEntryLevel();

  const { InstId, entryId } = useParams();
  const { entry_level } = useInstitutionConfiguration();
  const { state } = useContext(AppContext);
  const {
    user_details: { id },
  } = useLoggedInUserDetails();
  const { token } = useToken();

  const { studentEntryId } = useStudentDatabyId();
  const { flag } = useCheckAllocationType(studentEntryId);

  const { empolyeeData } = useEmpDetailsById(false, true);

  const [GetAcdSubjectLessonStatus, { data: studentSubjects }] = useLazyQuery<
    GetAcdSubjectLessonStatusByStudentData,
    GetAcdSubjectLessonStatusByStudentVars
  >(GetAcdSubjectLessonStatusByStudent, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      inst_id: InstId!,
      token,
      per_std_subj_allocation: flag!,
      entry_id: studentEntryId,
      entry_level,
      student_id: state.studentId,
    },
  });

  const [GetAcdSubjectLessonStatusTeacher, { data: teacherSubjects }] =
    useLazyQuery<
      GetAcdSubjectLessonStatusByTeacherData,
      GetAcdSubjectLessonStatusByTeacherVars
    >(GetAcdSubjectLessonStatusByTeacher, {
      variables: {
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        inst_id: InstId!,
        token,
        per_std_subj_allocation: flag!,
        entry_id: entryId!,
        entry_level,
        pr_emp_id: state.empLoginId,
      },
    });

  const [GetSubjectsTeachersAssociated, { data: teacherClassAssoc }] =
    useLazyQuery<
      GetAcdTeacherClassAssociationsData,
      GetAcdTeacherClassAssociationsVars
    >(GetAcdTeacherClassAssociations, {
      variables: {
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        inst_id: InstId!,
        token,
        per_std_subj_allocation: flag,
        input: {
          query_type:
            AcdTeacherClassQueryType.ALL_SUBJECTS_BY_TEACHER_ID_AT_ENTRY_LEVEL,
          entry_id: Number(entryId)!,
          entry_level: entry_level,
          pr_emp_id: id,
          subj_master_id: 0,
        },
      },
    });
  useEffect(
    () => {
      if (data && !loading && selectedSubject === null) {
        const selectedSubject = data
          ? {
              id: data.GetAcdSubjectForEntryLevel[0].subj_master_id,
              subj_code:
                data.GetAcdSubjectForEntryLevel[0].subject_details.subj_code,
              subj_desc:
                data.GetAcdSubjectForEntryLevel[0].subject_details.subj_desc,
            }
          : null;
        setSelectedSubject(selectedSubject);
      }
    },
    // eslint-disable-next-line
    [data, loading]
  );

  useEffect(() => {
    if (
      state.claims &&
      state.claims.EMPLOYEE &&
      state.ActiveAcdYr &&
      flag !== undefined
    ) {
      GetSubjectsTeachersAssociated().then(({ data }) => {
        if (
          data &&
          data.GetAcdTeacherClassAssociations &&
          data.GetAcdTeacherClassAssociations.length
        ) {
          const selectedSubject = data
            ? {
                id: data.GetAcdTeacherClassAssociations[0].subj_master_id,
                subj_code:
                  data.GetAcdTeacherClassAssociations[0].subject_details
                    .subj_code,
                subj_desc:
                  data.GetAcdTeacherClassAssociations[0].subject_details
                    .subj_desc,
              }
            : null;
          setSelectedSubject(selectedSubject);
        }
      });
    }
  }, [
    GetSubjectsTeachersAssociated,
    state.claims,
    teacherClassAssoc,
    state.ActiveAcdYr,
    flag,
  ]);

  useEffect(() => {
    if (token && state.claims && state.ActiveAcdYr && entry_level) {
      if (state.claims.STUDENT && studentEntryId && state.studentId)
        GetAcdSubjectLessonStatus();

      if (
        state.claims.EMPLOYEE &&
        empolyeeData.data &&
        empolyeeData.data.node.emp_type === UserType.TEACHER &&
        state.empLoginId
      )
        GetAcdSubjectLessonStatusTeacher();
    }
  }, [
    token,
    state.claims,
    state.ActiveAcdYr,
    flag,
    studentEntryId,
    entry_level,
    state.studentId,
    GetAcdSubjectLessonStatus,
    GetAcdSubjectLessonStatusTeacher,
    state.empLoginId,
    empolyeeData.data,
    entryId,
  ]);

  return (
    <>
      <ul className="assign-lesson-planner__subs">
        {state.claims ? (
          state.claims.STUDENT ? (
            studentSubjects &&
            studentSubjects.GetAcdSubjectLessonStatusByStudent.map(
              (data, index) => {
                return (
                  <li
                    className="assign-lesson-planner__subs--li"
                    key={index}
                    onClick={() => {
                      const selectedSubject = data
                        ? {
                            id: data.subj_master_id,
                            subj_code: data.subj_code,
                            subj_desc: data.subj_desc,
                          }
                        : null;
                      setSelectedSubject(selectedSubject);
                    }}
                  >
                    <b>{`${data.subj_desc}(${data.subj_code})`}</b>
                    <span>({data.subj_code})</span>
                    <span>
                      {true ? (
                        <>
                          <img src={Correct} alt="" />
                        </>
                      ) : (
                        <>
                          <img src={Wrong} alt="" />
                        </>
                      )}
                      &nbsp;
                      {true ? "Planned" : "Not Planned"}
                    </span>
                  </li>
                );
              }
            )
          ) : empolyeeData.data &&
            empolyeeData.data.node.emp_type === UserType.EMPLOYEE ? (
            teacherSubjects &&
            teacherSubjects.GetAcdSubjectLessonStatusByTeacher.map(
              (data, index) => {
                return (
                  <li
                    className="assign-lesson-planner__subs--li"
                    key={index}
                    onClick={() => {
                      const selectedSubject = data
                        ? {
                            id: data.subj_master_id,
                            subj_code: data.subj_code,
                            subj_desc: data.subj_desc,
                          }
                        : null;
                      setSelectedSubject(selectedSubject);
                    }}
                  >
                    <b>{`${data.subj_desc}(${data.subj_code})`}</b>
                    <span>({data.subj_code})</span>
                    <span>
                      {true ? (
                        <>
                          <img src={Correct} alt="" />
                        </>
                      ) : (
                        <>
                          <img src={Wrong} alt="" />
                        </>
                      )}
                      &nbsp;
                      {true ? "Planned" : "Not Planned"}
                    </span>
                  </li>
                );
              }
            )
          ) : state.claims.EMPLOYEE ? (
            teacherClassAssoc ? (
              teacherClassAssoc.GetAcdTeacherClassAssociations.map(
                (data, index) => {
                  return (
                    <li
                      className="assign-lesson-planner__subs--li"
                      key={index}
                      onClick={() => {
                        const selectedSubject = data
                          ? {
                              id: data.subj_master_id,
                              subj_code: data.subject_details.subj_code,
                              subj_desc: data.subject_details.subj_desc,
                            }
                          : null;
                        setSelectedSubject(selectedSubject);
                      }}
                    >
                      <b>{`${data.subject_details.subj_desc}(${data.subject_details.subj_code})`}</b>
                      <span>({data.subject_details.subj_code})</span>
                      <span>
                        {true ? (
                          <>
                            <img src={Correct} alt="" />
                          </>
                        ) : (
                          <>
                            <img src={Wrong} alt="" />
                          </>
                        )}
                        &nbsp;
                        {true ? "Planned" : "Not Planned"}
                      </span>
                    </li>
                  );
                }
              )
            ) : null
          ) : data ? (
            data.GetAcdSubjectForEntryLevel.map((data, index) => {
              return (
                <li
                  className="assign-lesson-planner__subs--li"
                  key={index}
                  onClick={() => {
                    const selectedSubject = data
                      ? {
                          id: data.subj_master_id,
                          subj_code: data.subject_details.subj_code,
                          subj_desc: data.subject_details.subj_desc,
                        }
                      : null;
                    setSelectedSubject(selectedSubject);
                  }}
                >
                  <b>{`${data.subject_details.subj_desc}(${data.subject_details.subj_code})`}</b>
                  <span>({data.subject_details.subj_code})</span>
                  <span>
                    {data.is_lessons_planned ? (
                      <>
                        <img src={Correct} alt="" />
                      </>
                    ) : (
                      <>
                        <img src={Wrong} alt="" />
                      </>
                    )}
                    &nbsp;{data.is_lessons_planned ? "Planned" : "Not Planned"}
                  </span>
                </li>
              );
            })
          ) : null
        ) : (
          <b className="nodata">User not found</b>
        )}
      </ul>
    </>
  );
};

export default SubjectsList;
