import React, { useContext, useEffect, useMemo, useState } from "react";
import TotalTest from "../../../../../images/Total_Test_Coducted.svg";
import useTestStatus, {
  AcdTestMarksStatusQueryType,
  GetAcdTestMarksStatusDetails,
} from "../../../hooks/useTestStatus";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../../../stories/Input/Input";
import { Autocomplete, TextField } from "@mui/material";
import Compare from "../../../../../images/Compare.svg";
import { toInputStandardDate } from "../../../../../utils/UtilFunctions";
import View from "../../../../../images/EyeWhite.svg";
import { responseType } from "../../../../../utils/Form.types";
import useAcdTestTypes from "../../../hooks/useAcdTestTypes";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../../styles/AutocompleteListStyles";
import { AppContext } from "../../../../../context/context";
import { TODAY_DATE } from "../../../../../utils/constants";
import useCheckAllocationType from "../../../hooks/useCheckAllocationType";

const Test = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { state } = useContext(AppContext);
  const { InstId, entryId } = useParams();
  const navigate = useNavigate();
  const { statusOfSubject } = useTestStatus(
    AcdTestMarksStatusQueryType.FINALIZED_TESTS,
    Number(entryId)!
  );

  const [searchTest, setSearchTest] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(toInputStandardDate(TODAY_DATE));
  const [filteredTests, setFilteredTests] = useState<
    GetAcdTestMarksStatusDetails[]
  >([]);
  const [selectedTestType, setSelectedTestType] = useState<responseType | null>(
    null
  );
  const {
    acdTestTypes: { responseType: acdTestTypes },
  } = useAcdTestTypes();
  const { flag } = useCheckAllocationType();
  const allTests = useMemo(
    () => statusOfSubject.data?.GetAcdTestMarksStatus || [],
    [statusOfSubject.data]
  );

  useEffect(() => {
    const filteredTests = allTests.filter((test) => {
      const testStartDate = new Date(test.test_class_details.test_start_date);
      const testEndDate = new Date(test.test_class_details.test_end_date);
      const filterStartDate = startDate !== "" ? new Date(startDate) : null;
      const filterEndDate = endDate !== "" ? new Date(endDate) : null;

      return (
        (!filterStartDate || testStartDate >= filterStartDate) &&
        (!filterEndDate || testEndDate <= filterEndDate)
      );
    });

    setFilteredTests(filteredTests);
  }, [startDate, endDate, allTests]);
  useEffect(() => {
    if (state.ActiveAcdYr) {
      setStartDate(toInputStandardDate(state.ActiveAcdYr.acd_st_date));
    }
  }, [state.ActiveAcdYr]);
  return (
    <>
      <div className="acd-reports__test">
        <div className="acd-reports__test--select row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search..."
              onChange={(e) => {
                setSearchTest(e.target.value);
              }}
            />
          </div>

          <div className="col acd-reports__test--textfields">
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              className="acd-reports__test--flex--textfield"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setStartDate(e.target.value)}
            />

            <TextField
              label="End Date"
              type="date"
              value={endDate}
              className="acd-reports__test--flex--textfield"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={acdTestTypes}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSelectedTestType(newValue);
                } else {
                  setSelectedTestType(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sort By"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        </div>
        <div className="acd-reports__test--cards">
          <div className="acd-reports__test--card">
            <div className="acd-reports__test--card--flex">
              <img src={TotalTest} alt="" />
              <span>Total Test Conducted</span>
            </div>
            <b>{statusOfSubject.data?.GetAcdTestMarksStatus.length}</b>
          </div>
        </div>
        <div className="acd-reports__test--datablock">
          <div className="acd-reports__test--grids">
            {filteredTests
              .filter((test) =>
                test.test_class_details.test_name_details.test_name.includes(
                  searchTest
                )
              )
              .map((test, index) => {
                return (
                  <div className="acd-reports__test--grid" key={index}>
                    <b className="acd-reports__test--grid--test-name">
                      {test.test_class_details.test_name_details.test_name}
                    </b>
                    <span className="acd-reports__test--grid--test-type">
                      (
                      {
                        test.test_class_details.test_name_details
                          .test_type_details.test_type_name
                      }
                      )
                    </span>
                    <span className="acd-reports__test--grid--sub-text">
                      Subjects Conducted
                    </span>
                    <b className="acd-reports__test--grid--sub-count">
                      {test.test_class_details.class_subject_details.length}
                    </b>
                    <div className="acd-reports__test--flex">
                      <TextField
                        className="acd-reports__test--flex--textfield"
                        disabled
                        label="Start Date"
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        defaultValue={toInputStandardDate(
                          test.test_class_details.test_start_date
                        )}
                      />
                      <img src={Compare} alt="" />
                      <TextField
                        className="acd-reports__test--flex--textfield"
                        disabled
                        label="End Date"
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        defaultValue={toInputStandardDate(
                          test.test_class_details.test_end_date
                        )}
                      />
                    </div>
                    <button
                      className="acd-reports__test--grid--view-button"
                      onClick={() => {
                        if (flag) {
                          navigate(
                            `/${InstId}/academics/reports/completeReports/${entryId}/view/${test.test_class_details.id}/${test.test_class_details.test_name_details.test_type_id}/pertestdetails/combinations`
                          );
                        } else {
                          navigate(
                            `/${InstId}/academics/reports/completeReports/${entryId}/view/${test.test_class_details.id}/${test.test_class_details.test_name_details.test_type_id}/pertestdetails`
                          );
                        }
                      }}
                    >
                      View Details <img src={View} alt="" />
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Test;
