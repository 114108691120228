import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import { Label } from "../../../stories/Label/Label";
import Input from "../../../components/common/Input/Index";
import Modal from "react-modal";

import { Button } from "../../../stories/Button/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddParentUser,
  UpdateParentUserById,
} from "../../../queries/parent/mutations/new";
import {
  AuthParentTypes,
  msgType,
  optionsType,
  responseType,
} from "../../../utils/Form.types";
import { Form, Formik } from "formik";
import { parentValidation } from "../../../utils/validationRules";
import { AppContext } from "../../../context/context";

import {
  Direction,
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  PageLabel,
  ParentQueryType,
  PredefinedDataTypes,
  ReturnType,
  SortBy,
  StudentListFor,
  StudentReportType,
} from "../../../utils/Enum.types";
import { useNavigate, useParams } from "react-router-dom";
import {
  handleMUISelectEvent,
  removeMoreSpace,
} from "../../../utils/UtilFunctions";
import { GetParents, ParentByNodeId } from "../../../queries/parent/list";
import { ParentTitleProps } from "../../../Types/Titles";
import { LabelNameProps } from "../../../Types/Labels";
import {
  AUTOCOMPLETE_CLEAR_CLASSNAME,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import {
  Autocomplete,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import LoadingModal from "../../../pages/LoadingModal";
import DownArrow from "../../../images/DownArrow.svg";

import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import useToken from "../../../customhooks/useToken";
import { Keys } from "../../../utils/Enum.keys";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import StudentList from "../Student/List";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";

import useStudentAdmissionNumber from "../../Accounts/hooks/UseStudentAdmissionNumber";
import MessageModal from "../../../pages/MessageModal";
import Home from "../Home/Index";
import Edit from "../../../images/Edit.svg";
import Close from "../../../images/Close.svg";

import { Title } from "../../../stories/Title/Title";
import { payloadTypes } from "../../../context/reducer";
import useUploadComponent from "../../../customhooks/useUploadComponent";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useInstDetails from "../../../customhooks/general/useInstDetails";

const { ParentFormLabels } = require("../../../json/config.json");
const { Parents } = require("../../../json/title.json");
interface Props {
  operation: Operation;
  pageType: PageFor;
  setCloseModal?: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal?: boolean;
  studentModal: boolean;
  setStudentModal: React.Dispatch<React.SetStateAction<boolean>>;
  step: number;
}
const stepHeader = () => {
  return ["Parent Details", "Parent Student Association "];
};
const Registration = ({
  operation,
  pageType,
  setCloseModal,
  closeModal,
  step,
}: Props) => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const { dispatch } = useContext(AppContext);
  const stepsHeader = stepHeader();
  const { user_details } = useLoggedInUserDetails();
  const { studentData } = useStudentDatabyId();

  const { token } = useToken();
  const [AddAuthParent, { loading: addLoading }] = useMutation(AddParentUser, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateAuthParent, { loading: updateLoading }] = useMutation(
    UpdateParentUserById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  //userefs
  const placeRef = useRef<HTMLSelectElement>(null);
  const stateRef = useRef<HTMLSelectElement>(null);
  const StateCloseRef = stateRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;

  const PlaceCloseRef = placeRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;
  const pincodeRef = useRef<HTMLInputElement>(null);
  const occupationRef = useRef<HTMLSelectElement>(null);
  const OccupationCloseRef = occupationRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;
  const occupationInputRef = occupationRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const typeRef = useRef<HTMLSelectElement>(null);
  const typeInputRef = typeRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;

  // eslint-disable-next-line
  const { InstId, parentId } = useParams();
  const navigate = useNavigate();
  const [studentModal, setStudentModal] = useState(false);
  const [admNo, setAdmNo] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [formData, setFormData] = useState<AuthParentTypes>({
    first_name: "",
    middle_name: "",
    last_name: "",
    parent_address: "",
    parent_place: "",
    parent_state: "",
    parent_pin: "",
    parent_email: "",
    parent_mobile: "",
    parent_occup: "",
    parent_qualification: "",
    parent_aadhar: "",
    rights: "",
    parent_type: "",
    parent_rights: "",
    parent_profile_filename: "",
  });
  const { InstDetails } = useInstDetails(1);
  const { component, setFile, uploadFile } = useUploadComponent(
    `${InstDetails.data?.nodes[0]?.inst_name}/parents/${formData.first_name} ${formData.middle_name} ${formData.last_name}(${formData.parent_mobile})/parent_profile_pic/parent_profile_pic`,
    operation,
    formData.parent_profile_filename,
    InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE
  );
  const [GetParentsData, { data: ParentData, loading: ParentLoading }] =
    useLazyQuery(ParentByNodeId, {
      variables: {
        id: parentId!,
        token,
      },
    });

  const { studentAddmissionNumber } = useStudentAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentReportType.GENERAL
  );

  useEffect(() => {
    if (ParentData && !ParentLoading && parentId) {
      const empMobileWithoutPlus = ParentData.node.parent_mobile.replaceAll(
        "+",
        ""
      );
      const {
        first_name,
        last_name,
        middle_name,
        parent_aadhar,
        parent_address,
        parent_email,
        parent_occup,
        parent_pin,
        parent_place,
        parent_qualification,
        parent_rights,
        parent_state,
        parent_type,
        parent_profile_filename,
      } = ParentData.node;
      setFormData({
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        parent_address: parent_address,
        parent_place: parent_place,
        parent_state: parent_state,
        parent_pin: parent_pin,
        parent_email: parent_email.toLowerCase(),
        parent_mobile: empMobileWithoutPlus,
        parent_occup: parent_occup,
        parent_qualification: parent_qualification,
        parent_aadhar: parent_aadhar,
        rights: "",
        parent_type: parent_type,
        parent_rights: parent_rights,
        parent_profile_filename,
      });
    }
  }, [ParentData, ParentLoading, parentId]);

  // eslint-disable-next-line
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value as string,
    }));
  }; // eslint-disable-next-line
  const updateForm = async () => {
    if (!(formData.parent_mobile || formData.parent_email)) {
      alert("Please fill any one the field, Email/Mobile No:");
      return;
    }
    try {
      const uploadResult = await uploadFile();
      if (uploadResult) {
        UpdateAuthParent({
          variables: {
            token,
            id: parentId!,
            input: {
              first_name: removeMoreSpace(formData.first_name),
              parent_address: removeMoreSpace(formData.parent_address),
              parent_email: removeMoreSpace(
                formData.parent_email.toLowerCase()
              ),
              parent_mobile: `+${formData.parent_mobile}`,
              parent_place: removeMoreSpace(formData.parent_place),
              parent_state: removeMoreSpace(formData.parent_state),
              parent_pin: formData.parent_pin,
              parent_occup: removeMoreSpace(formData.parent_occup),
              parent_qualification: removeMoreSpace(
                formData.parent_qualification
              ),
              parent_aadhar: formData.parent_aadhar,
              parent_type: formData.parent_type,
              parent_profile_filename: `${InstDetails.data?.nodes[0]?.inst_name}/parents/${formData.first_name} ${formData.middle_name} ${formData.last_name}(${formData.parent_mobile})/parent_profile_pic/parent_profile_pic`,
            },
            user_details,
          },
          refetchQueries: [
            {
              query: GetParents,
              variables: {
                after: null,
                direction: Direction.ASC,
                name: "",
                first: ROWS_PER_PAGE,
                inst_id: InstId!,
                sortBy: SortBy.FIRST_NAME,
                queryType: ParentQueryType.PARENTS_BY_INST_ID,
                token,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "Parent Updated Successfully",
              operation: Operation.UPDATE,
            });
          }
          operation === Operation.CREATE
            ? navigate(
                `/${InstId}/admissions/parents/2/${parentId}/parentAssosiation`
              )
            : navigate(`/${InstId}/admissions/parents/${parentId}/ParentData`);
        });
      } else {
        setMessage({
          flag: true,
          operation: Operation.NONE,
          message: "Image Upload Failed",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNumberChange = (mobileNo: string) => {
    setFormData((prevValues) => ({
      ...prevValues,
      parent_mobile: mobileNo,
    }));
  };
  const submitForm = async () => {
    if (!(formData.parent_mobile || formData.parent_email)) {
      alert("Please fill any one the field, Email/Mobile No:");
      return;
    }
    if (formData.parent_type) {
      try {
        const uploadResult = await uploadFile();
        if (uploadResult) {
          await AddAuthParent({
            variables: {
              token,
              input: {
                inst_id: InstId,
                first_name: removeMoreSpace(formData.first_name),
                parent_address: removeMoreSpace(formData.parent_address),
                parent_email: removeMoreSpace(
                  formData.parent_email.toLowerCase()
                ),
                parent_mobile: formData.parent_mobile
                  ? `+${formData.parent_mobile}`
                  : EMPTY_STRING,
                parent_place: removeMoreSpace(formData.parent_place),
                parent_state: removeMoreSpace(formData.parent_state),
                parent_pin: formData.parent_pin,
                parent_occup: removeMoreSpace(formData.parent_occup),
                parent_qualification: removeMoreSpace(
                  formData.parent_qualification
                ),
                parent_aadhar: formData.parent_aadhar,
                parent_type: formData.parent_type,
                parent_profile_filename: `${InstDetails.data?.nodes[0]?.inst_name}/parents/${formData.first_name} ${formData.middle_name} ${formData.last_name}(${formData.parent_mobile})/parent_profile_pic/parent_profile_pic`,
              },
              user_details,
            },
            refetchQueries: [
              {
                query: GetParents,
                variables: {
                  after: null,
                  direction: Direction.ASC,
                  first: ROWS_PER_PAGE,
                  inst_id: InstId!,
                  queryType: ParentQueryType.PARENTS_BY_INST_ID,
                  token,
                  name: EMPTY_STRING,
                  sortBy: SortBy.FIRST_NAME,
                },
              },
            ],
          }).then(({ data }) => {
            if (data) {
              if (pageType === PageFor.MODAL) {
                setCloseModal?.(!closeModal);
              } else {
                navigate(
                  `/${InstId}/admissions/parents/2/${data.AddParentUser.id}/parentAssosiation`
                );
              }
            }
          });
        } else {
          setMessage({
            flag: true,
            message: "Image Upload failed",
            operation: Operation.NONE,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const {
    PredefinedData: { dropDown: ParentType },
  } = usePredefinedDataByType(
    PredefinedDataTypes.PARENT_TYPE,
    formData.parent_type
  );

  const {
    PredefinedData: { dropDown: City },
  } = usePredefinedDataByType(
    PredefinedDataTypes.CITY,
    formData.parent_place,
    ReturnType.VALUE_SAME_AS_LABEL
  );
  const {
    PredefinedData: { dropDown: Occupation },
  } = usePredefinedDataByType(
    PredefinedDataTypes.OCCUPATION,
    formData.parent_occup
  );
  const {
    PredefinedData: { dropDown: States },
  } = usePredefinedDataByType(PredefinedDataTypes.STATE, formData.parent_state);

  useEffect(() => {
    if (studentData.data?.nodes[0].std_adm_no)
      setAdmNo(studentData.data?.nodes[0].std_adm_no);
  }, [studentData]);
  useEffect(() => {
    if (studentData.data && !studentData.loading) {
      const { std_father_name, std_details } = studentData.data.nodes[0];
      setFormData((prevValues) => ({
        ...prevValues,
        first_name: std_father_name,
        parent_address: std_details.std_address,
        parent_pin: std_details.std_pincode,
        parent_place: std_details.std_place,
        parent_state: std_details.std_state,
      }));
    }
  }, [studentData.data, studentData.loading]);
  const handleClear = () => {
    setAdmNo("");
    setFormData({
      first_name: "",
      middle_name: "",
      last_name: "",
      parent_address: "",
      parent_place: "",
      parent_state: "",
      parent_pin: "",
      parent_email: "",
      parent_mobile: "+91",
      parent_occup: "",
      parent_qualification: "",
      parent_aadhar: "",
      rights: "",
      parent_type: "",
      parent_rights: "",
      parent_profile_filename: "",
    });
    PlaceCloseRef?.click();
    StateCloseRef?.click();
    OccupationCloseRef?.click();
    setFile(null);
  };

  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    if (operation === Operation.UPDATE) {
      navigate(`/${InstId}/admissions/parents/${parentId!}/ParentData`);
    }
  };
  useEffect(() => {
    if (token && parentId!) {
      GetParentsData();
    }
  }, [token, parentId, GetParentsData]);
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div
        className={
          pageType === PageFor.GENERAL ? "parentReg" : "parentReg__modal"
        }
      >
        {pageType === PageFor.MODAL && <Title>Add Parent Details</Title>}

        {pageType === PageFor.GENERAL && operation === Operation.CREATE ? (
          <Stepper alternativeLabel activeStep={step - 1}>
            {stepsHeader.map((step, index) => {
              return (
                <Step key={index}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        ) : null}
        {pageType === PageFor.GENERAL && operation === Operation.UPDATE ? (
          <Title>Update Parent Details</Title>
        ) : null}
        <Formik
          initialValues={formData}
          validationSchema={parentValidation}
          onSubmit={
            (parentId && parentId === "0") || pageType === PageFor.MODAL
              ? submitForm
              : updateForm
          }
          enableReinitialize
        >
          {(meta) => {
            return (
              <>
                <Form
                  className={
                    pageType === PageFor.GENERAL &&
                    operation === Operation.CREATE
                      ? "parentReg__form"
                      : pageType === PageFor.GENERAL &&
                        operation === Operation.UPDATE
                      ? "parentReg__form--update"
                      : "parentReg__form--modal"
                  }
                >
                  <div className="row g-0  parentReg__details">
                    <div className="col">
                      <div className="details">
                        <h4>
                          {Parents.Titles.map(
                            (title: ParentTitleProps, index: React.Key) => {
                              return (
                                <React.Fragment key={index}>
                                  {title.Personal_Details}
                                </React.Fragment>
                              );
                            }
                          )}
                        </h4>
                      </div>
                      <div className="parentReg__image-flex">
                        {operation === Operation.CREATE &&
                        pageType === PageFor.GENERAL ? (
                          <>
                            <Label>Student Admission no</Label>
                            <Autocomplete
                              classes={classes}
                              options={
                                studentAddmissionNumber! ?? [EMPTY_STRING]
                              }
                              value={
                                studentAddmissionNumber?.find(
                                  ({ label }) =>
                                    label ===
                                      studentData.data?.nodes[0].std_adm_no ??
                                    admNo
                                )! ?? null
                              }
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  dispatch({
                                    type: payloadTypes.SET_STUDENT_ID,
                                    payload: {
                                      studentId: Number(
                                        (newValue as responseType)?.value
                                      ),
                                    },
                                  });
                                } else {
                                  dispatch({
                                    type: payloadTypes.SET_STUDENT_ID,
                                    payload: {
                                      studentId: 0,
                                    },
                                  });
                                }
                              }}
                              onKeyDown={(e) => {
                                if (e.key === Keys.BACKSPACE) {
                                  dispatch({
                                    type: payloadTypes.SET_STUDENT_ID,
                                    payload: {
                                      studentId: 0,
                                    },
                                  });
                                }
                              }}
                              freeSolo
                              autoHighlight
                              popupIcon={<img src={DownArrow} alt="/" />}
                              forcePopupIcon
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onChange={(e) => setAdmNo(e.target.value)}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  classes={{
                                    root: textClasses.formControlRoot,
                                  }}
                                />
                              )}
                            />
                          </>
                        ) : null}
                        {operation === Operation.CREATE &&
                        pageType === PageFor.GENERAL ? (
                          <img
                            className="data-fetch-icon"
                            src={Edit}
                            alt="/"
                            onClick={() => setStudentModal(!studentModal)}
                          />
                        ) : null}
                      </div>

                      {studentData.data?.nodes[0].first_name ? (
                        <Input
                          LabelName="Selected Student"
                          type="text"
                          name="name"
                          values={
                            admNo
                              ? studentData.data?.nodes[0].first_name +
                                " " +
                                studentData.data?.nodes[0].middle_name +
                                " " +
                                studentData.data?.nodes[0].last_name
                              : EMPTY_STRING
                          }
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            meta.handleChange(e);
                            handleValueChange(e);
                          }}
                          disabled
                        />
                      ) : null}

                      {ParentFormLabels.Labels.Personaldetails.map(
                        (label: LabelNameProps, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <Input
                                LabelName={label.LabelName}
                                name={label.inputName}
                                values={formData[label.inputName]}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  meta.handleChange(e);
                                  handleValueChange(e);
                                }}
                                maxLength={label.maxLength}
                                required={label.required}
                                type={label.dataType}
                                autoFocus={label.autoFocus}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                      <div className="label-grid">
                        <Label>Mobile</Label>
                        <PhoneInput
                          country={"in"}
                          value={formData.parent_mobile}
                          onChange={handleNumberChange}
                          inputProps={{
                            required: false,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              typeInputRef?.focus();
                            }
                          }}
                        />
                      </div>

                      <div className="label-grid">
                        <Label>Parent Type</Label>
                        <Autocomplete
                          classes={classes}
                          ref={typeRef!}
                          options={ParentType! ?? [EMPTY_STRING]}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              if (formData.parent_type) {
                                occupationInputRef?.focus();
                              }
                            }
                            if (e.key === Keys.BACKSPACE) {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  type: EMPTY_STRING,
                                };
                              });
                            }
                          }}
                          value={
                            ParentType?.find(
                              ({ value }) => value === formData.parent_type
                            )! ?? null
                          }
                          openOnFocus
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  parent_type: (newValue as optionsType)
                                    ?.value!,
                                };
                              });
                            } else {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  parent_type: EMPTY_STRING,
                                };
                              });
                            }
                          }}
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              classes={{ root: textClasses.formControlRoot }}
                              required
                            />
                          )}
                        />
                      </div>

                      <div className="details frame-space">
                        <h4>Other Details</h4>
                      </div>
                      <div className="label-grid">
                        <Label>Occupation</Label>

                        <Autocomplete
                          classes={classes}
                          options={Occupation!}
                          ref={occupationRef!}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              handleMUISelectEvent(e);
                            }
                            if (e.key === Keys.BACKSPACE) {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  parent_occup: EMPTY_STRING,
                                };
                              });
                            }
                          }}
                          value={
                            Occupation?.find(
                              ({ value }) => value === formData.parent_occup
                            )! ?? null
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  parent_occup: (newValue as optionsType)
                                    ?.value!,
                                };
                              });
                            } else {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  parent_occup: EMPTY_STRING,
                                };
                              });
                            }
                          }}
                          openOnFocus
                          freeSolo
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    parent_occup: e.target.value!,
                                  };
                                });
                              }}
                              fullWidth
                              classes={{ root: textClasses.formControlRoot }}
                            />
                          )}
                        />
                      </div>
                      {ParentFormLabels.Labels.Otherdetails.map(
                        (label: LabelNameProps, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <Input
                                LabelName={label.LabelName}
                                name={label.inputName}
                                values={formData[label.inputName]}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  meta.handleChange(e);
                                  handleValueChange(e);
                                }}
                                required={label.required}
                                maxLength={label.maxLength}
                                type={label.dataType}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                    </div>
                    <div className="col">
                      <div className="details">
                        <h4>Location Details</h4>
                      </div>

                      <Input
                        LabelName="Address"
                        type="text"
                        name="parent_address"
                        values={formData.parent_address}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFormData({
                            ...formData,
                            parent_address: e.target.value,
                          });
                        }}
                      />
                      <div className="label-grid">
                        <Label>Place</Label>
                        <Autocomplete
                          classes={classes}
                          options={City! ?? [EMPTY_STRING]}
                          ref={placeRef}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              if (formData.parent_place) {
                                handleMUISelectEvent(e);
                              }
                            }
                            if (e.key === Keys.BACKSPACE) {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  parent_place: EMPTY_STRING,
                                };
                              });
                            }
                          }}
                          value={
                            City?.find(
                              ({ value }) => value === formData.parent_place
                            )! ?? null
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  parent_place: (newValue as optionsType)
                                    ?.value!,
                                };
                              });
                            } else {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  parent_place: EMPTY_STRING,
                                };
                              });
                            }
                          }}
                          openOnFocus
                          freeSolo
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    parent_place: e.target.value!,
                                  };
                                });
                              }}
                              fullWidth
                              classes={{ root: textClasses.formControlRoot }}
                            />
                          )}
                        />
                      </div>
                      <Input
                        LabelName="Pin Code"
                        type="text"
                        name="pin"
                        inputRef={pincodeRef}
                        onKeyDown={(e: any) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            handleMUISelectEvent(e);
                          }
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFormData({
                            ...formData,
                            parent_pin: e.target.value,
                          });
                        }}
                        values={formData.parent_pin}
                        maxLength={6}
                      />
                      <div className="label-grid">
                        <Label>State</Label>
                        <Autocomplete
                          classes={classes}
                          options={States!}
                          ref={stateRef}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              if (formData.parent_state) {
                                handleMUISelectEvent(e);
                              }
                            }
                            if (e.key === Keys.BACKSPACE) {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  parent_state: EMPTY_STRING,
                                };
                              });
                            }
                          }}
                          value={
                            States?.find(
                              ({ value }) => value === formData.parent_state
                            )! ?? null
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  parent_state: (newValue as optionsType)
                                    ?.value!,
                                };
                              });
                            } else {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  parent_state: EMPTY_STRING,
                                };
                              });
                            }
                          }}
                          openOnFocus
                          freeSolo
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => {
                                setFormData((prevValues) => {
                                  return {
                                    ...prevValues,
                                    parent_state: e.target.value!,
                                  };
                                });
                              }}
                              fullWidth
                              classes={{ root: textClasses.formControlRoot }}
                            />
                          )}
                        />
                      </div>

                      {component}
                    </div>
                  </div>

                  {operation === Operation.CREATE ? (
                    <Button mode={"save-continue"} type="submit" />
                  ) : (
                    <Button mode="save" type="submit" />
                  )}
                  <Button mode="clear" type="button" onClick={handleClear} />
                  {pageType === PageFor.GENERAL ? (
                    <Button
                      mode="back"
                      type="button"
                      onClick={() => navigate(-1)}
                    />
                  ) : (
                    <Button
                      type="button"
                      mode="cancel"
                      onClick={() => setCloseModal?.(!closeModal)}
                    />
                  )}
                </Form>
                {operation === Operation.CREATE ? (
                  <Modal
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={true}
                    isOpen={studentModal}
                    style={StudentModalStyles}
                  >
                    <div className="modal-flex h-100">
                      <div className="modal-flex__data h-100">
                        <StudentList
                          pageType={PageFor.MODAL}
                          studentListFor={StudentListFor.GENERAL}
                          queryType={StudentReportType.GENERAL}
                          setStudentModal={setStudentModal}
                          studentModal={studentModal}
                          label={PageLabel.TRUE}
                        />
                      </div>
                      <div className="modal-flex__image">
                        <img
                          src={Close}
                          alt="/"
                          className="modal-close-icon"
                          onClick={() => setStudentModal(!studentModal)}
                        />
                      </div>
                    </div>
                  </Modal>
                ) : null}
              </>
            );
          }}
        </Formik>
      </div>

      <LoadingModal flag={addLoading || updateLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Registration;
