import { useEffect, useState } from "react";

import Calendar from "../../../../images/Calendar.svg";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";

import { toStandardDate } from "../../../../utils/UtilFunctions";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { Operation } from "../../../../utils/Enum.types";
import useTestStatus, {
  AcdTestMarksStatusQueryType,
} from "../../hooks/useTestStatus";
import MessageModal from "../../../../pages/MessageModal";
import { msgType, responseType } from "../../../../utils/Form.types";
import Input from "../../../../stories/Input/Input";
import { Autocomplete, TextField } from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { Title } from "../../../../stories/Title/Title";
import useAcdTestTypes from "../../hooks/useAcdTestTypes";
interface Props {
  departmentSelected: responseType | null;
  branchSelected: responseType | null;
  classSelected: responseType | null;
  semesterSelected: responseType | null;
  sectionSelected: responseType | null;
  setDepartmentSelected: React.Dispatch<
    React.SetStateAction<responseType | null>
  >;
  setBranchSelected: React.Dispatch<React.SetStateAction<responseType | null>>;
  setClassSelected: React.Dispatch<React.SetStateAction<responseType | null>>;
  setSemesterSelected: React.Dispatch<
    React.SetStateAction<responseType | null>
  >;
  setSectionSelected: React.Dispatch<React.SetStateAction<responseType | null>>;
}

const TestDetails = ({
  departmentSelected,
  branchSelected,
  classSelected,
  semesterSelected,
  sectionSelected,
  setDepartmentSelected,
  setBranchSelected,
  setClassSelected,
  setSemesterSelected,
  setSectionSelected,
}: Props) => {
  const navigate = useNavigate();
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { InstId } = useParams();
  const { USE_SECTION_KEY, USE_CLASS_KEY, USE_SEMESTER_KEY } =
    useInstitutionConfiguration();
  const {
    acdTestTypes: { responseType },
  } = useAcdTestTypes();
  const [filterBy, setFilterBy] = useState<responseType | null>(null);
  const [entryId, setEntryId] = useState(0);
  const [searchTest, setSearchTest] = useState("");
  const { statusOfSubject } = useTestStatus(
    AcdTestMarksStatusQueryType.FINALIZED_TESTS,
    entryId
  );

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleClear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
  };
  useEffect(() => {
    USE_CLASS_KEY && setEntryId(classSelected?.value!);
    USE_SEMESTER_KEY && setEntryId(semesterSelected?.value!);
    USE_SECTION_KEY && setEntryId(sectionSelected?.value!);
  }, [
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
    classSelected?.value,
    semesterSelected?.value,
    sectionSelected?.value,
  ]);
  return (
    <>
      <div className="academics-test-report__filters row g-0">
        <div className="col-2">
          <Input
            placeholder="Search Test"
            id="search"
            type="text"
            onChange={(e) => {
              setSearchTest(e.target.value);
            }}
          />
        </div>
        <div className="col">
          <Title variant="subtitle1"> Conducted Tests</Title>
        </div>
        <div className="col-2">
          <Autocomplete
            classes={classes}
            options={responseType}
            openOnFocus
            onChange={(e, newValue) => {
              if (newValue) {
                setFilterBy(newValue);
              } else {
                setFilterBy(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter By"
                InputLabelProps={{ shrink: true }}
                classes={{ root: textClasses.formControlRoot }}
              />
            )}
          />
        </div>
      </div>

      <div className="academics-test-report__datablock">
        {(USE_SEMESTER_KEY && semesterSelected && !semesterSelected.value) ||
        (USE_SECTION_KEY &&
          sectionSelected &&
          !sectionSelected.value) ? null : (
          <div className="academics-test-report__datablock--grids">
            {statusOfSubject.data?.GetAcdTestMarksStatus.filter(
              (test) =>
                test.test_class_details.test_name_details.test_name.includes(
                  searchTest
                ) &&
                (test.test_class_details.test_name_details.test_type_details
                  .test_type_name === filterBy?.label ||
                  filterBy === null)
            ).map((test, index) => {
              return (
                <div
                  className="academics-test-report__datablock--grids--cards"
                  key={index}
                >
                  <div className="academics-test-report__datablock--grids--cards--title">
                    <b>{test.test_class_details.test_name_details.test_name}</b>
                    <span>
                      {
                        test.test_class_details.test_name_details
                          .test_type_details.test_type_name
                      }
                    </span>
                  </div>
                  <div className="academics-test-report__datablock--grids--cards--date">
                    <span>
                      {toStandardDate(test.test_class_details.test_start_date)}/
                      {toStandardDate(test.test_class_details.test_end_date)}
                    </span>
                    <img src={Calendar} alt="/" />
                  </div>
                  <div className="academics-test-report__datablock--grids--cards--status">
                    <div className="academics-test-report__datablock--grids--cards--status--present">
                      <b>Present</b>
                      <span className="font-green">
                        {test.num_of_std_test_taken}
                      </span>
                    </div>
                    {/* to be implemented later */}
                    {/* <span className="academics-test-report__datablock--grids--cards--status--separator">
                      |
                    </span> */}
                    {/* <div className="academics-test-report__datablock--grids--cards--status--present">
                      <b>Absent</b>
                      <span className="font-red">05</span>
                    </div> */}
                  </div>
                  <div className="academics-test-report__datablock--grids--cards--button">
                    <Button
                      mode="view"
                      onClick={() =>
                        navigate(
                          `/${InstId}/academics/reports/${test.acd_test_class_id}/test/${entryId}/view`
                        )
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* <div className="academics-test-report__datablock">
        <div className="academics-test-report__datablock--grids">
          <div className="academics-test-report__datablock--grids--cards">
            <div className="academics-test-report__datablock--grids--cards--title">
              <b>test1</b>
              <span>(Test Name)</span>
            </div>
            <div className="academics-test-report__datablock--grids--cards--date">
              <span>11-05-2023/ 21-05-2023</span>
              <img src={Calendar} alt="/" />
            </div>
            <div className="academics-test-report__datablock--grids--cards--status">
              <div className="academics-test-report__datablock--grids--cards--status--present">
                <b>Present</b>
                <span className="font-green">02</span>
              </div>
              <span className="academics-test-report__datablock--grids--cards--status--separator">
                |
              </span>
              <div className="academics-test-report__datablock--grids--cards--status--present">
                <b>Absent</b>
                <span className="font-red">05</span>
              </div>
            </div>
            <div className="academics-test-report__datablock--grids--cards--button">
              <Button
                mode="view"
                onClick={() =>
                  navigate(`/${InstId}/academics/reports/test/view`)
                }
              />
            </div>
          </div>
          <div className="academics-test-report__datablock--grids--cards">
            <div className="academics-test-report__datablock--grids--cards--title">
              <b>test1</b>
              <span>(Test Name)</span>
            </div>
            <div className="academics-test-report__datablock--grids--cards--date">
              <span>11-05-2023/ 21-05-2023</span>
              <img src={Calendar} alt="/" />
            </div>
            <div className="academics-test-report__datablock--grids--cards--status">
              <div className="academics-test-report__datablock--grids--cards--status--present">
                <b>Present</b>
                <span className="font-green">02</span>
              </div>
              <span className="academics-test-report__datablock--grids--cards--status--separator">
                |
              </span>
              <div className="academics-test-report__datablock--grids--cards--status--present">
                <b>Absent</b>
                <span className="font-red">05</span>
              </div>
            </div>
            <div className="academics-test-report__datablock--grids--cards--button">
              <Button
                mode="view"
                onClick={() =>
                  navigate(`/${InstId}/academics/reports/test/view`)
                }
              />
              <Button>Finalize</Button>
            </div>
          </div>
          <div className="academics-test-report__datablock--grids--cards">
            <div className="academics-test-report__datablock--grids--cards--title">
              <b>test1</b>
              <span>(Test Name)</span>
            </div>
            <div className="academics-test-report__datablock--grids--cards--date">
              <span>11-05-2023/ 21-05-2023</span>
              <img src={Calendar} alt="/" />
            </div>
            <div className="academics-test-report__datablock--grids--cards--status">
              <div className="academics-test-report__datablock--grids--cards--status--present">
                <b>Present</b>
                <span className="font-green">02</span>
              </div>
              <span className="academics-test-report__datablock--grids--cards--status--separator">
                |
              </span>
              <div className="academics-test-report__datablock--grids--cards--status--present">
                <b>Absent</b>
                <span className="font-red">05</span>
              </div>
            </div>
            <div className="academics-test-report__datablock--grids--cards--button">
              <Button
                mode="view"
                onClick={() =>
                  navigate(`/${InstId}/academics/reports/test/view`)
                }
              />
              <Button>Finalize</Button>
            </div>
          </div>
          <div className="academics-test-report__datablock--grids--cards">
            <div className="academics-test-report__datablock--grids--cards--title">
              <b>test1</b>
              <span>(Test Name)</span>
            </div>
            <div className="academics-test-report__datablock--grids--cards--date">
              <span>11-05-2023/ 21-05-2023</span>
              <img src={Calendar} alt="/" />
            </div>
            <div className="academics-test-report__datablock--grids--cards--status">
              <div className="academics-test-report__datablock--grids--cards--status--present">
                <b>Present</b>
                <span className="font-green">02</span>
              </div>
              <span className="academics-test-report__datablock--grids--cards--status--separator">
                |
              </span>
              <div className="academics-test-report__datablock--grids--cards--status--present">
                <b>Absent</b>
                <span className="font-red">05</span>
              </div>
            </div>
            <div className="academics-test-report__datablock--grids--cards--button">
              <Button
                mode="view"
                onClick={() =>
                  navigate(`/${InstId}/academics/reports/test/view`)
                }
              />
              <Button>Finalize</Button>
            </div>
          </div>
          <div className="academics-test-report__datablock--grids--cards">
            <div className="academics-test-report__datablock--grids--cards--title">
              <b>test1</b>
              <span>(Test Name)</span>
            </div>
            <div className="academics-test-report__datablock--grids--cards--date">
              <span>11-05-2023/ 21-05-2023</span>
              <img src={Calendar} alt="/" />
            </div>
            <div className="academics-test-report__datablock--grids--cards--status">
              <div className="academics-test-report__datablock--grids--cards--status--present">
                <b>Present</b>
                <span className="font-green">02</span>
              </div>
              <span className="academics-test-report__datablock--grids--cards--status--separator">
                |
              </span>
              <div className="academics-test-report__datablock--grids--cards--status--present">
                <b>Absent</b>
                <span className="font-red">05</span>
              </div>
            </div>
            <div className="academics-test-report__datablock--grids--cards--button">
              <Button
                mode="view"
                onClick={() =>
                  navigate(`/${InstId}/academics/reports/test/view`)
                }
              />
            </div>
          </div>
        </div>
      </div> */}
      <div>
        <Button mode="clear" onClick={handleClear} />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default TestDetails;
