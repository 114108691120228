import React, { useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";

import { TableHeaderProps } from "../../../Types/Tables";
import {
  debounce,
  defaultLabelDisplayedRows,
  handleFormEvent,
} from "../../../utils/UtilFunctions";

import useToken from "../../../customhooks/useToken";
import {
  CURRENT_PAGE,
  DEBOUNCE_TIME_INTERVAL,
  EMPTY_STRING,
  PAGINATION_ARRAY,
  ROWS_PER_PAGE,
  TABLE_DATA_PER_PAGE,
} from "../../../utils/constants";

import { StudentsTitlesType } from "../../../Types/Student/json";
import { GetTransportRouteMasters, GetTransportStd } from "../queries/list";
import {
  Direction,
  SortBy,
  TransportQueryType,
} from "../../../utils/Enum.types";
import { TreeView } from "@mui/x-tree-view/TreeView";
import {
  CloseSquare,
  MinusSquare,
  PlusSquare,
  StyledTreeItem,
} from "../../../pages/TreeView";
import {
  GetTransportRouteMastersData,
  GetTransportRouteMastersVars,
} from "../../../customhooks/general/useTransportRoutesData";
import { TablePaginationActions } from "../../../pages/CustomTablePagination";
import { Button } from "../../../stories/Button/Button";
import Home from "../Home/Index";
import useAcademicYear from "../../Academics/hooks/useActiveAcademicYear";
import Input from "../../../stories/Input/Input";
import useTransportTableJson from "../json/useTransportTableJson";
const { Students } = require("../../../json/title.json");
interface RouteTypes {
  TransQueryType: TransportQueryType;
  id: number;
}
const ViewTransportDetails = () => {
  const { InstId } = useParams<{ InstId: string }>();
  const { token } = useToken();
  const navigate = useNavigate();
  const { Transport_Table } = useTransportTableJson();
  const [searchData, setSearchData] = useState("");
  const [routeType, setRouteType] = useState<RouteTypes>({
    TransQueryType: TransportQueryType.BY_ROUTE_MASTER,
    id: 0,
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [sortBy] = useState(SortBy.STUDENT_ID);
  const { activeAcademicYearData } = useAcademicYear();

  const [GetTransportStudent, { data, loading, fetchMore }] = useLazyQuery(
    GetTransportStd,
    {
      variables: {
        token,
        name: searchData,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId?.id!,
        first: rowsPerPage,
        sortBy: sortBy,
        direction: Direction.ASC,
        after: null,
        input: { TransQueryType: routeType.TransQueryType, ids: routeType.id },
      },
    }
  );

  const [GetRoutesData, { data: masterData }] = useLazyQuery<
    GetTransportRouteMastersData,
    GetTransportRouteMastersVars
  >(GetTransportRouteMasters, {
    variables: {
      token,
      inst_id: InstId!,
      first: ROWS_PER_PAGE,
      sortBy: SortBy.ROUTE_DESC,
      direction: Direction.ASC,
      name: EMPTY_STRING,
      after: null,
    },
    fetchPolicy: "network-only",
  });
  const totalCount = data?.GetTransportStd?.totalCount;
  const endCursor = data?.GetTransportStd?.pageInfo?.endCursor;
  const indexOfLastrepo = CURRENT_PAGE * totalCount!;
  const indexOfFirstrepo = indexOfLastrepo - totalCount!;
  const currentRepo = data
    ? data.GetTransportStd.edges.slice(indexOfFirstrepo, indexOfLastrepo)
    : [];

  const handleMore = () => {
    fetchMore({
      variables: {
        after: endCursor,
      },

      updateQuery: (prevResult, { fetchMoreResult }) => {
        fetchMoreResult.GetTransportStd.edges = [
          ...prevResult.GetTransportStd.edges,
          ...fetchMoreResult.GetTransportStd.edges,
        ];

        return fetchMoreResult;
      },
    });
  };

  const updateDebounceText = debounce(() => {
    handleMore();
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setTimeout(() => {
      setPage(newPage);
      updateDebounceText();
    }, DEBOUNCE_TIME_INTERVAL);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    if (token) {
      GetTransportStudent();
    }
  }, [token, GetTransportStudent]);
  useEffect(() => {
    if (token) {
      GetRoutesData();
    }
  }, [token, GetRoutesData]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {Students.Titles.map((title: StudentsTitlesType, index: React.Key) => {
          return <React.Fragment key={index}>{title.TreeView}</React.Fragment>;
        })}
      </Title>
      <div className="assigned-routes__treeview">
        <div className="row g-0 assigned-routes__treeview--block">
          <div className="col-4 assigned-routes__treeview--block--frame">
            <div className="tree-view">
              <TreeView
                className="departmenttree-view"
                defaultExpanded={["1"]}
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<CloseSquare />}
              >
                {masterData?.GetTransportRouteMasters?.edges.map(
                  (route, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <StyledTreeItem
                          onClick={() =>
                            setRouteType({
                              TransQueryType:
                                TransportQueryType.BY_ROUTE_MASTER,
                              id: route.node.id,
                            })
                          }
                          nodeId={route.node.id.toString()!}
                          label={route.node.route_desc}
                        >
                          {route.node.route_details.map(
                            (routeStage: any, index: number) => {
                              return (
                                <React.Fragment key={index}>
                                  <StyledTreeItem
                                    onClick={() =>
                                      setRouteType({
                                        TransQueryType:
                                          TransportQueryType.BY_ROUTE_DETAILS,
                                        id: routeStage.id,
                                      })
                                    }
                                    nodeId={routeStage.id}
                                    label={routeStage.route_stage_desc}
                                  ></StyledTreeItem>
                                </React.Fragment>
                              );
                            }
                          )}
                        </StyledTreeItem>
                      </React.Fragment>
                    );
                  }
                )}
              </TreeView>
            </div>
          </div>
          <div className="col assigned-routes__treeview--block--frame">
            <div className="col-2">
              <Input
                id="search"
                type="text"
                placeholder="Search ..."
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchData(e.target.value)
                }
                onKeyDown={handleFormEvent}
              />
            </div>
            <div className="assigned-routes__treeview--tableblock">
              {!loading && !currentRepo.length ? (
                <b className="nodata">
                  Sorry, no students has been assigned to this Route.
                </b>
              ) : (
                <TableContainer className="assigned-routes__treeview--table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {Transport_Table.MasterData.StudentDetails.map(
                          (th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell key={index} className={th.className}>
                                {th.labelName}
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentRepo &&
                        currentRepo &&
                        (rowsPerPage > 0
                          ? currentRepo.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : currentRepo
                        ).map((response: any, index: number) => (
                          <TableRow key={response.node.id}>
                            <TableCell
                              id="td-center"
                              className="alumini-student-list__table--slno"
                            >
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell>
                              {response.node.student_details.first_name +
                                " " +
                                response.node.student_details.middle_name +
                                " " +
                                response.node.student_details.last_name}
                            </TableCell>

                            <TableCell
                              className="alumini-student-list__table--mobile"
                              id="td-center"
                            >
                              {response.node.route_details.route_stage_desc}
                            </TableCell>
                            <TableCell className="alumini-student-list__table--email">
                              {response.node.route_fee_details.route_fee}
                            </TableCell>
                            <TableCell className="alumini-student-list__table--email">
                              {response.node.student_details.branch.branch_desc}
                            </TableCell>
                            <TableCell className="alumini-student-list__table--email">
                              {response.node.student_details.class.class_desc}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={PAGINATION_ARRAY}
                          count={totalCount ?? 0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                          labelDisplayedRows={defaultLabelDisplayedRows}
                          labelRowsPerPage={TABLE_DATA_PER_PAGE}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              )}
            </div>
          </div>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default ViewTransportDetails;
