import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import More from "../../../images/More.svg";
import Received from "../../../images/ReceivedInDashboard.svg";
import Receivable from "../../../images/Receivable.svg";
import BankCollection from "../../../images/BankCollection.svg";
import FeeCollection from "../../../images/FeeCollection.svg";
import CashCollection from "../../../images/CashCollection.svg";
import BankExpense from "../../../images/BankExpense.svg";
import CashExpense from "../../../images/CashExpense.svg";
// import BankReceipts from "../../../images/BankReceipt.svg";

import FeeBalance from "../../../images/FeeBalance.svg";
import CashBalance from "../../../images/CashBalance.svg";
import Payments from "../../../images/Payments.svg";
import Close from "../../../images/Close.svg";
import {
  AcctDashBoardDetails,
  GetAcctDashBoardDetailsData,
  GetAcctDashBoardDetailsVars,
} from "./types";
import { GetAcctDashBoardDetails } from "../queries/DashBoard";
import { useLazyQuery } from "@apollo/client";
import usePollIntervalTime from "../../../customhooks/usePollIntervalTime";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import {
  formatter,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { AppContext } from "../../../context/context";
import { AcctDashboard } from "../common/Enum.types";
import { TextField, Tooltip } from "@mui/material";
import Balance from "../../../images/Balance.svg";
import BankBalance from "../../../images/BankBalance.svg";
import { Button } from "../../../stories/Button/Button";
import DashboardBalance from "./DashBoardBalance";

import { DashBoardFor, FileUploadParams } from "../../../utils/Enum.types";
import GraphDetails from "./GraphDetails";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  selectedYear: number;
  year: string;
}
const ViewHistoricData = ({ setModalFlag, selectedYear, year }: Props) => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { InstId } = useParams();
  const { serverDate } = useServerDateandTime();
  const pollIntervalTime = usePollIntervalTime();
  const { format } = formatter;
  const { InstDetails } = useInstDetails(1);
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const [dashBoardDetails, setDahbordDetails] = useState<AcctDashBoardDetails>({
    total_fee_collection: 0,
    total_receipts_count: 0,
    fee_collection_bank: 0,
    fee_collection_cash: 0,
    cash_receipts_count: 0,
    bank_receipts_count: 0,
    total_payments: 0,
    total_payments_count: 0,
    payments_bank: 0,
    payments_cash: 0,
    cash_payments_count: 0,
    bank_payments_count: 0,
    total_balance: 0,
    bank_balance: 0,
    cash_balance: 0,
    total_demand: 0,
    total_received: 0,
    total_demand_balance: 0,
    upi_receipts_count: 0,
    cheque_receipts_count: 0,
    dd_receipts_count: 0,
    neft_receipts_count: 0,
    imps_receipts_count: 0,
    cardswipe_receipts_count: 0,
    total_std_demand: 0,
    total_std_ob: 0,
    total_std_concession: 0,
    total_std_refund: 0,
  });
  const [GetEmpDashBoardDetails, { data: EmpDashBoardData }] = useLazyQuery<
    GetAcctDashBoardDetailsData,
    GetAcctDashBoardDetailsVars
  >(GetAcctDashBoardDetails, {
    variables: {
      fin_yr_id: selectedYear ? Number(selectedYear) : 0,
      inst_id: InstId!,
      token,
      today_date: toIsoDate(serverDate),
      query_type: AcctDashboard.THIS_YEAR,
    },
    pollInterval: pollIntervalTime,
  });

  useEffect(() => {
    if (token && InstId && state.ActiveFinYr && serverDate) {
      GetEmpDashBoardDetails();

      if (EmpDashBoardData) {
        setDahbordDetails(EmpDashBoardData.GetAcctDashBoardDetails);
      }
    }
  }, [
    token,
    InstId,
    state.ActiveFinYr,
    GetEmpDashBoardDetails,
    state.claims,
    EmpDashBoardData,
    serverDate,
  ]);

  return (
    <>
      <div className="accounts-dashboard__historic--view">
        <div className="accounts-dashboard__historic--title">
          <Title>Historic Data</Title>
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <div className="accounts-dashboard__historic--view--inst-name">
          <div className="accounts-dashboard__historic--view--flex">
            {LogoOrSign.defaultLogo ? (
              <img className="logo-1" src={LogoOrSign.defaultLogo} alt="/" />
            ) : null}
            <b> {InstDetails.data?.nodes[0].inst_name}</b>{" "}
          </div>
          <TextField
            value={year}
            label="Financial year"
            className="accounts-dashboard__historic--view--year"
            disabled
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="accounts-dashboard__details">
          <div className="accounts-dashboard__details--block">
            <div className="accounts-dashboard__details--block--title">
              <Title variant="subtitle1">Fee Collection</Title>
              <img src={More} alt="/" />
            </div>
            <div className="accounts-dashboard__details--block--details">
              <img src={FeeCollection} alt="/" />
              <div className="accounts-dashboard__details--block--g-count--block">
                <span
                  className={
                    dashBoardDetails.total_fee_collection > 99999
                      ? "font-14 accounts-dashboard__details--block--details--total"
                      : "accounts-dashboard__details--block--details--total"
                  }
                >
                  {format(dashBoardDetails.total_fee_collection ?? 0)}
                </span>
                <Tooltip title="Receipts" arrow placement="top">
                  <span className="accounts-dashboard__details--block--details--receipts">
                    ({dashBoardDetails.total_receipts_count})
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className="accounts-dashboard__details--block--g-count">
              <div className="accounts-dashboard__details--block--g-count--flex separator">
                <img src={BankCollection} alt="/" />
                <div className="accounts-dashboard__details--block--g-count--block">
                  <span className="accounts-dashboard__details--block--g-count--block--text">
                    Bank
                  </span>
                  <span
                    className={
                      dashBoardDetails.fee_collection_bank > 9999
                        ? "accounts-dashboard__details--block--g-count--block--total font-10"
                        : "accounts-dashboard__details--block--g-count--block--total"
                    }
                    //   onClick={() => {
                    //     if (state.claims?.EMPLOYEE)
                    //       setFeeCollectionModal(!feeCollectionModal);
                    //   }}
                  >
                    {format(dashBoardDetails.fee_collection_bank ?? 0)}
                  </span>
                  <Tooltip title="Receipts" arrow placement="right">
                    <span className="accounts-dashboard__details--block--g-count--block--receipts">
                      ({dashBoardDetails.bank_receipts_count})
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className="accounts-dashboard__details--block--g-count--flex">
                <img src={CashCollection} alt="/" />
                <div className="accounts-dashboard__details--block--g-count--block">
                  <span className="accounts-dashboard__details--block--g-count--block--text">
                    Cash
                  </span>
                  <span
                    className={
                      dashBoardDetails.fee_collection_cash > 9999
                        ? "accounts-dashboard__details--block--g-count--block--total font-10"
                        : "accounts-dashboard__details--block--g-count--block--total"
                    }
                  >
                    {format(dashBoardDetails.fee_collection_cash ?? 0)}
                  </span>
                  <Tooltip title="Receipts" arrow placement="top">
                    <span className="accounts-dashboard__details--block--g-count--block--receipts">
                      ({dashBoardDetails.cash_receipts_count})
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="accounts-dashboard__details--block">
            <div className="accounts-dashboard__details--block--title">
              <Title variant="subtitle1">Payments</Title>

              <img
                src={More}
                alt="/"
                // onClick={() => {
                //   if (state.claims?.EMPLOYEE) setPaymentModal(!paymentModal);
                // }}
              />
            </div>

            {/* values for  today expense*/}
            <div className="accounts-dashboard__details--block--details">
              <img src={Payments} alt="/" />
              <div className="accounts-dashboard__details--block--g-count--block">
                <span
                  className={
                    dashBoardDetails.total_payments > 99999
                      ? "accounts-dashboard__details--block--details--total font-14"
                      : "accounts-dashboard__details--block--details--total"
                  }
                >
                  {format(dashBoardDetails.total_payments ?? 0)}
                </span>
                <Tooltip title="Receipts" arrow placement="top">
                  <span className="accounts-dashboard__details--block--details--receipts">
                    ({dashBoardDetails.total_payments_count})
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className="accounts-dashboard__details--block--g-count">
              <div className="accounts-dashboard__details--block--g-count--flex separator">
                <img src={BankExpense} alt="/" />
                <div className="accounts-dashboard__details--block--g-count--block">
                  <span className="accounts-dashboard__details--block--g-count--block--text">
                    Bank
                  </span>
                  <span
                    className={
                      dashBoardDetails.payments_bank > 9999
                        ? "accounts-dashboard__details--block--g-count--block--total font-10"
                        : "accounts-dashboard__details--block--g-count--block--total"
                    }
                  >
                    {format(dashBoardDetails.payments_bank ?? 0)}
                  </span>
                  <Tooltip title="Receipts" arrow placement="top">
                    <span className="accounts-dashboard__details--block--g-count--block--receipts">
                      ({dashBoardDetails.bank_payments_count})
                    </span>
                  </Tooltip>
                </div>
              </div>

              <div className="accounts-dashboard__details--block--g-count">
                <img src={CashExpense} alt="/" />
                <div className="accounts-dashboard__details--block--g-count--flex">
                  <div className="accounts-dashboard__details--block--g-count--block">
                    <span className="accounts-dashboard__details--block--g-count--block--text">
                      Cash
                    </span>
                    <span
                      className={
                        dashBoardDetails.payments_cash > 9999
                          ? "accounts-dashboard__details--block--g-count--block--total font-10"
                          : "accounts-dashboard__details--block--g-count--block--total"
                      }
                    >
                      {format(dashBoardDetails.payments_cash ?? 0)}
                    </span>
                    <Tooltip title="Receipts" arrow placement="top">
                      <span className="accounts-dashboard__details--block--g-count--block--receipts">
                        ({dashBoardDetails.cash_payments_count})
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DashboardBalance
            title={`Closing Balance (As On ${toStandardDate(serverDate)})`}
            mainImageSrc={Balance}
            subtitle1="Bank"
            subtitle1ImgSrc={BankBalance}
            subtitle1Balance={dashBoardDetails.bank_balance}
            subtitle2="Cash"
            subtitle2ImgSrc={CashBalance}
            subtitle2balance={dashBoardDetails.cash_balance}
            totalBalance={dashBoardDetails.total_balance}
          />
          <DashboardBalance
            title={`Demand Balance  (As On ${toStandardDate(serverDate)})`}
            mainImageSrc={Receivable}
            subtitle1=" Total Demand"
            subtitle1ImgSrc={FeeBalance}
            subtitle1Balance={dashBoardDetails.total_demand}
            subtitle2="Received"
            subtitle2ImgSrc={Received}
            subtitle2balance={dashBoardDetails.total_received}
            totalBalance={dashBoardDetails.total_demand_balance}
          />
        </div>
        <div className="accounts-dashboard__historic--view--graph">
          <GraphDetails selectedYear={selectedYear} pageType={DashBoardFor.PROPRIETOR}/>
        </div>
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
    </>
  );
};

export default ViewHistoricData;
