import React, { useEffect, useState } from "react";
import { Title } from "../../stories/Title/Title";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../Types/Tables";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetSwMsgTemplatesData, GetSwMsgTemplatesVars } from "./utils/types";
import { GetMsgInstTemplates, GetSwMsgTemplates } from "./queries/list";
import { ROWS_PER_PAGE } from "../../utils/constants";
import { Direction, Operation } from "../../utils/Enum.types";
import useToken from "../../customhooks/useToken";
import { AddMsgInstTemplate } from "./queries/mutation";
import { useParams } from "react-router-dom";
import { msgType } from "../../utils/Form.types";
import { Button } from "../../stories/Button/Button";
import MessageModal from "../../pages/MessageModal";
import LoadingModal from "../../pages/LoadingModal";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";

const { EduateModules } = require("../../json/table.json");

interface templateData {
  index: number;
  desc: string;
  module: string;
}
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const TagsListImport = ({ setModalFlag }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<templateData[]>(
    []
  );

  const [GetTemplates, { data: templatesList }] = useLazyQuery<
    GetSwMsgTemplatesData,
    GetSwMsgTemplatesVars
  >(GetSwMsgTemplates);

  const [CreateTemplate, { loading: creationLoading }] = useMutation(
    AddMsgInstTemplate,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          operation: Operation.NONE,
          flag: true,
        }),
    }
  );

  const toggleCheckbox = (index: number, desc: string, module: string) => {
    const existingCheckbox = selectedCheckboxes.find(
      (checkbox) => checkbox.index === index
    );
    if (existingCheckbox) {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((checkbox) => checkbox.index !== index)
      );
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, { index, desc, module }]);
    }
  };

  const handleAddTemplate = () => {
    CreateTemplate({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        input: selectedCheckboxes.map((checkbox) => ({
          msg_tpl_idx: checkbox.index,
          msg_tpl_desc: checkbox.desc,
          inst_module: checkbox.module,
          inst_type: "S",
        })),
      },
      refetchQueries: [
        {
          query: GetMsgInstTemplates,
          variables: {
            name: "",
            token,
            after: null,
            first: null,
            inst_id: InstId!,
            orderBy: {
              direction: Direction.ASC,
              field: "MSG_TPL_DESC",
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Template Created Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setModalFlag(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (token) {
      GetTemplates({
        variables: {
          name: "",
          token,
          after: null,
          first: ROWS_PER_PAGE,
          orderBy: {
            direction: Direction.ASC,
            field: "MSG_TPL_DESC",
          },
        },
      });
    }
  }, [GetTemplates, token]);

  return (
    <>
      <Title>Eduate Templates List</Title>
      <div className="template-list">
        <div className="template-list__tableblock">
          <TableContainer className="template-list__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {EduateModules.EduateTemplates.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {templatesList?.GetSwMsgTemplates.edges.map((res, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className="template-list__table--slno"
                        id="td-center"
                      >
                        <Checkbox
                          checked={selectedCheckboxes.some(
                            (checkbox) => checkbox.index === index
                          )}
                          onClick={() =>
                            toggleCheckbox(
                              index,
                              res.node.msg_tpl_desc,
                              res.node.inst_module
                            )
                          }
                        />
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="template-list__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>{res.node.msg_tpl_desc}</TableCell>
                      <TableCell className="template-list__table--desc">
                        {res.node.inst_module}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="save" onClick={handleAddTemplate} />
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>

      <LoadingModal flag={creationLoading} />
      <MessageModal
        handleClose={handleClose}
        value={message.message}
        modalFlag={message.flag}
        operation={message.operation}
      />
    </>
  );
};

export default TagsListImport;
