import { gql } from "@apollo/client";
export const ImportStudentDataFromXls = gql`
  mutation ImportStudentDataFromXls(
    $token: String!
    $inst_id: ID!
    $input: ImportAllStudentData!
    $flags: StdFlags!
    $user_details: SubscribedUser!
  ) {
    ImportStudentDataFromXls(
      token: $token
      inst_id: $inst_id
      input: $input
      flags: $flags
      user_details: $user_details
    )
  }
`;
export const ImportOldStdDataFromXls = gql`
  mutation ImportOldStdDataFromXls(
    $token: String!
    $inst_id: ID!
    $input: OldStdInputData!
    $flags: StdFlags!
    $user_details: SubscribedUser!
  ) {
    ImportOldStdDataFromXls(
      token: $token
      inst_id: $inst_id
      input: $input
      flags: $flags
      user_details: $user_details
    )
  }
`;
