import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";

import {
  PageFor,
  StudentRegitrationForms,
  YesNo,
} from "../../../../utils/Enum.types";

import { studentPersonalData } from "../../../../utils/Form.types";

import { AppContext } from "../../../../context/context";
import PreviewTabs from "./PreviewTabs";
import StudentAcademicDetails from "./StudentAcademicDetails";
import {
  GetSwConfigStudentDataEntryByInstIdData,
  GetSwConfigStudentDataEntryByInstIdVars,
} from "../../../../Types/Student/Configuration";
import { GetSwConfigStudentDataEntryByInstId } from "../../../../queries/institution/configuration/query";
import { TextField } from "@mui/material";
import Home from "../../Home/Index";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import useToken from "../../../../customhooks/useToken";
import { useLazyQuery } from "@apollo/client";
interface Props {
  pageType: PageFor;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const StudentPersonalData = ({ pageType, setModal }: Props) => {
  const { InstId, studentId } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const navigate = useNavigate();

  const [GetConfigData, { data: GetStudentSwConfig }] = useLazyQuery<
    GetSwConfigStudentDataEntryByInstIdData,
    GetSwConfigStudentDataEntryByInstIdVars
  >(GetSwConfigStudentDataEntryByInstId, {
    variables: { token, inst_id: InstId! },
  });

  const PersonalData =
    GetStudentSwConfig &&
    GetStudentSwConfig.GetSwConfigStudentDataEntryByInstId.filter(
      ({ data_used_in_table, data_field_enable }) =>
        data_used_in_table === StudentRegitrationForms.PERSONAL_DATA &&
        data_field_enable
    );

  const filteredPersonalData =
    PersonalData &&
    PersonalData.filter((data) => data.data_field_name !== "std_sts_no");
  const [studentPersonalFormData, setStudentPersonalFormData] =
    useState<studentPersonalData>({
      std_blood_group: "",
      std_aadhar: "",
      std_sts_no: "",
      std_address: "",
      std_place: "",
      std_district: "",
      std_state: "",
      std_phone: "",
      std_pincode: "",
      std_corr_address: "",
      std_corr_place: "",
      std_corr_district: "",
      std_corr_state: "",
      std_corr_phone: "",
      std_corr_pincode: "",
      std_religion: "",
      std_caste: "",
      std_sub_caste: "",
      std_caste_group: "",
      std_sc_st: "",
      std_caste_minority: YesNo.NO,
      std_rural_urban: "",
      std_mother_tongue: "",
      std_annual_income: 0,
      std_martial_status: "",
      std_identity_marks: "",
      std_place_of_birth: "",
      std_nationality: "",
      std_is_phy_challenged: YesNo.NO,
      std_nearest_police_station: "",
      std_nearest_railway_station: "",
      std_km_from_residence: "",
      std_belongs_to_jk: YesNo.NO,
      std_belongs_to_jk_brahmin: YesNo.NO,
      std_disability_name: "",
      std_disability_percentage: 0,
      std_height: 0,
      std_is_bpl_card: YesNo.NO,
      std_is_child_of_farmer: YesNo.NO,
      std_is_child_of_freedom_fighter: YesNo.NO,
      std_is_cultural: YesNo.NO,
      std_is_defence: YesNo.NO,
      std_is_destitude_woman: YesNo.NO,
      std_is_gadinadu_horanadu: YesNo.NO,
      std_is_hiv_devadasi: YesNo.NO,
      std_is_ksou_emp: YesNo.NO,
      std_is_ncc: YesNo.NO,
      std_is_nirashritharu: YesNo.NO,
      std_is_non_emp_status: YesNo.NO,
      std_is_nss: YesNo.NO,
      std_is_political_sufferer: YesNo.NO,
      std_is_sports_person: YesNo.NO,
      std_weight: 0,
    });
  const { studentData } = useStudentDatabyId();
  useEffect(() => {
    if (!studentData.loading && studentData.data) {
      setStudentPersonalFormData({
        std_aadhar: studentData.data?.nodes[0].std_details.std_aadhar,
        std_address: studentData.data?.nodes[0].std_details.std_address,
        std_place: studentData.data?.nodes[0].std_details.std_place,
        std_district: studentData.data?.nodes[0].std_details.std_district,
        std_sts_no: "",
        std_state: studentData.data?.nodes[0].std_details.std_state,
        std_phone: studentData.data?.nodes[0].std_details.std_phone,
        std_pincode: studentData.data?.nodes[0].std_details.std_pincode,

        std_blood_group: studentData.data?.nodes[0].std_details.std_blood_group,
        std_corr_address:
          studentData.data?.nodes[0].std_details.std_corr_address,
        std_corr_place: studentData.data?.nodes[0].std_details.std_corr_place,
        std_corr_district:
          studentData.data?.nodes[0].std_details.std_corr_district,
        std_corr_state: studentData.data?.nodes[0].std_details.std_corr_state,
        std_corr_phone: studentData.data?.nodes[0].std_details.std_corr_phone,
        std_corr_pincode:
          studentData.data?.nodes[0].std_details.std_corr_pincode,

        std_religion: studentData.data?.nodes[0].std_details.std_religion,
        std_caste: studentData.data?.nodes[0].std_details.std_caste,
        std_sub_caste: studentData.data?.nodes[0].std_details.std_sub_caste,
        std_caste_group: studentData.data?.nodes[0].std_details.std_caste_group,
        std_sc_st: studentData.data?.nodes[0].std_details.std_sc_st,
        std_caste_minority: studentData.data?.nodes[0].std_details
          .std_caste_minority
          ? YesNo.YES
          : YesNo.NO,
        std_rural_urban: studentData.data?.nodes[0].std_details.std_rural_urban,
        std_mother_tongue:
          studentData.data?.nodes[0].std_details.std_mother_tongue,
        std_annual_income:
          studentData.data?.nodes[0].std_details.std_annual_income,
        std_martial_status:
          studentData.data?.nodes[0].std_details.std_martial_status,
        std_identity_marks:
          studentData.data?.nodes[0].std_details.std_identity_marks,
        std_place_of_birth:
          studentData.data?.nodes[0].std_details.std_place_of_birth,
        std_nationality: studentData.data?.nodes[0].std_details.std_nationality,
        std_is_phy_challenged: studentData.data.nodes[0].std_details
          .std_is_phy_challenged
          ? YesNo.YES
          : YesNo.NO,
        std_nearest_police_station:
          studentData.data?.nodes[0].std_details.std_nearest_police_station,
        std_nearest_railway_station:
          studentData.data?.nodes[0].std_details.std_nearest_railway_station,
        std_km_from_residence:
          studentData.data?.nodes[0].std_details.std_km_from_residence,
        std_belongs_to_jk: studentData.data.nodes[0].std_details
          .std_belongs_to_jk
          ? YesNo.YES
          : YesNo.NO,
        std_belongs_to_jk_brahmin:
          studentData.data.nodes[0].std_details.std_belongs_to_jk_brahmin ===
          true
            ? YesNo.YES
            : YesNo.NO,

        std_disability_name:
          studentData.data.nodes[0].std_details.std_disability_name,
        std_disability_percentage:
          studentData.data.nodes[0].std_details.std_disability_percentage,
        std_height: studentData.data.nodes[0].std_details.std_height,
        std_is_bpl_card:
          studentData.data.nodes[0].std_details.std_is_bpl_card === true
            ? YesNo.YES
            : YesNo.NO,
        std_is_child_of_farmer:
          studentData.data.nodes[0].std_details.std_is_child_of_farmer === true
            ? YesNo.YES
            : YesNo.NO,
        std_is_child_of_freedom_fighter:
          studentData.data.nodes[0].std_details
            .std_is_child_of_freedom_fighter === true
            ? YesNo.YES
            : YesNo.NO,

        std_is_cultural:
          studentData.data.nodes[0].std_details.std_is_cultural === true
            ? YesNo.YES
            : YesNo.NO,

        std_is_defence:
          studentData.data.nodes[0].std_details.std_is_defence === true
            ? YesNo.YES
            : YesNo.NO,
        std_is_destitude_woman:
          studentData.data.nodes[0].std_details.std_is_destitude_woman === true
            ? YesNo.YES
            : YesNo.NO,

        std_is_gadinadu_horanadu:
          studentData.data.nodes[0].std_details.std_is_gadinadu_horanadu ===
          true
            ? YesNo.YES
            : YesNo.NO,

        std_is_hiv_devadasi:
          studentData.data.nodes[0].std_details.std_is_hiv_devadasi === true
            ? YesNo.YES
            : YesNo.NO,

        std_is_ksou_emp:
          studentData.data.nodes[0].std_details.std_is_ksou_emp === true
            ? YesNo.YES
            : YesNo.NO,

        std_is_ncc:
          studentData.data.nodes[0].std_details.std_is_ncc === true
            ? YesNo.YES
            : YesNo.NO,

        std_is_nirashritharu:
          studentData.data.nodes[0].std_details.std_is_nirashritharu === true
            ? YesNo.YES
            : YesNo.NO,

        std_is_non_emp_status:
          studentData.data.nodes[0].std_details.std_is_non_emp_status === true
            ? YesNo.YES
            : YesNo.NO,

        std_is_nss:
          studentData.data.nodes[0].std_details.std_is_nss === true
            ? YesNo.YES
            : YesNo.NO,

        std_is_political_sufferer:
          studentData.data.nodes[0].std_details.std_is_political_sufferer ===
          true
            ? YesNo.YES
            : YesNo.NO,

        std_is_sports_person:
          studentData.data.nodes[0].std_details.std_is_sports_person === true
            ? YesNo.YES
            : YesNo.NO,
        std_weight: studentData.data.nodes[0].std_details.std_weight,
      });
    }
  }, [studentData.loading, studentData.data]);
  useEffect(() => {
    if (token) {
      GetConfigData();
    }
  }, [token, GetConfigData]);
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}

      {pageType === PageFor.GENERAL ? (
        <PreviewTabs pageType={PageFor.GENERAL} />
      ) : null}

      <div
        className={pageType === PageFor.GENERAL ? "preview" : "preview--modal"}
      >
        <StudentAcademicDetails />
        <div
          className={
            pageType === PageFor.GENERAL
              ? "preview__frame"
              : "preview__frame--modal"
          }
        >
          <div className="row preview__frame--data">
            <div className="col preview__span-4">
              {filteredPersonalData &&
                filteredPersonalData.map((label, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TextField
                        id="outlined-uncontrolled"
                        className="preview__textfield"
                        label={label.data_field_label}
                        InputLabelProps={{ shrink: true }}
                        value={studentPersonalFormData[label.data_field_name]}
                        disabled
                        name={label.data_field_name}
                      />
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
          {!state.claims!.STUDENT && (
            <>
              {pageType === PageFor.GENERAL ? (
                <Button
                  mode="edit"
                  autoFocus
                  onClick={() => {
                    navigate(
                      `/${InstId}/admissions/students/${studentId}/personalDetailsUpdate`
                    );
                  }}
                />
              ) : null}

              {pageType === PageFor.GENERAL ? (
                <Button
                  mode="back"
                  onClick={() => navigate(`/${InstId}/admissions/students`)}
                />
              ) : null}
            </>
          )}
          {pageType === PageFor.MODAL && (
            <Button mode="cancel" onClick={() => setModal?.(false)} />
          )}
        </div>
      </div>
    </>
  );
};

export default StudentPersonalData;
