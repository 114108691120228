//React
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Apollo client
import { useLazyQuery, useMutation } from "@apollo/client";

//Material ui
import { Autocomplete, Checkbox, TextField } from "@mui/material";

//Formik
import { Form, Formik } from "formik";

//Styles
import {
  formAutoCompleteTextStyles,
  requiredAutoCompleteStyles,
} from "../../../../styles/AutocompleteStyles";

//Images
import DownArrow from "../../../../images/DownArrow.svg";

//HTML Components
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";

//Queries
import { GetLibKeywordsByInstId } from "../../../../queries/Library/MasterData/Keyword/list/byInstId";
import { GetLibBookMasterKeywords } from "../../../../queries/Library/MasterData/BookMasterKeyword/list/byId";

//Mutations
import { AddLibBookMasterKeyword } from "../../../../queries/Library/MasterData/BookMasterKeyword/mutations/new";
import { DeleteLibBookMasterKeywordById } from "../../../../queries/Library/MasterData/BookMasterKeyword/mutations/delete";

//Modals
import DeleteModal from "../../../../pages/DeleteModal";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";

//Components
import Home from "../../Home/Index";

//custom Hooks
import useToken from "../../../../customhooks/useToken";

//Types
import {
  Direction,
  Operation,
  ReturnType,
  SortBy,
} from "../../../../utils/Enum.types";
import { msgType, responseType } from "../../../../utils/Form.types";
import {
  BookMasterKeywordNode,
  GetLibBookMasterKeywordsData,
  GetLibBookMasterKeywordsVars,
} from "../../../../Types/Library/MasterData/BookMasterKeyword/paginationTypes";
import {
  GetLibKeywordsByInstIdData,
  GetLibKeywordsByInstIdVars,
} from "../../../../Types/Library/MasterData/Keyword/paginationTypes";
import { LibraryTitles } from "../../../../Types/Titles";

//Other functions and const
import {
  AUTOCOMPLETE_INPUT_CLASSNAME,
  EMPTY_RESPONSETYPE,
  EMPTY_RESPONSETYPE_OBJECT,
  LIBRARY_ROWS_PER_PAGE,
  MULTI_SELECT_LIMIT_TAGS,
} from "../../../../utils/constants";
import { BookMasterKeywordValidation } from "../../../../utils/validationRules";
import useClassification from "../../customHooks/useClassification";
import { Keys } from "../../../../utils/Enum.keys";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

//JSON
const { Library } = require("../../../../json/title.json");
//Interface
export interface BookMasterKeywordNodeData {
  nodes: BookMasterKeywordNode[];
}
export interface BookMasterKeywordNodeDataVars {
  token: string;
  ids: number[] | number;
}

const Index = () => {
  //token useParams
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  //useStates for flags
  const [deleteModal, setDeleteModal] = useState(false);
  const [operation, setOperation] = useState(Operation.CREATE);

  //useStates
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [direction] = useState(Direction.ASC);
  const [classification, setClassification] = useState("");
  const [keyword, setKeyword] = useState("");
  const [keywordOptions, setKeywordOptions] =
    useState<responseType[]>(EMPTY_RESPONSETYPE);
  const [classificationId, setClassificationId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [Keywords, setKeywords] = useState<responseType[]>([]);
  //Styles
  const requiredClasses = requiredAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  //Queries
  const [GetBookMasterKeyword, { data }] = useLazyQuery<
    GetLibBookMasterKeywordsData,
    GetLibBookMasterKeywordsVars
  >(GetLibBookMasterKeywords, {
    fetchPolicy: "network-only",
  });

  const {
    classificationsList: { dropDown: classificationsDropDown },
  } = useClassification(
    classification,
    LIBRARY_ROWS_PER_PAGE,
    ReturnType.RESPONSE_TYPE,
    null,
    null,
    null,
    null,
    null
  );

  const [GetKeywordData, { data: KeywordData, loading: KeywordLoading }] =
    useLazyQuery<GetLibKeywordsByInstIdData, GetLibKeywordsByInstIdVars>(
      GetLibKeywordsByInstId,
      {
        variables: {
          token,
          inst_id: InstId!,
          first: LIBRARY_ROWS_PER_PAGE,
          sortBy: SortBy.KEYWORD,
          direction,
          name: keyword,
          after: null,
        },
      }
    );

  //userefs for enter key
  const keywordRef = useRef<HTMLSelectElement>(null);
  const keywordEnterRef = keywordRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;
  const saveRef = useRef<HTMLButtonElement>(null);
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;

  //Mutations
  const [AddBookMasterKeyword, { loading: creationLoading }] = useMutation(
    AddLibBookMasterKeyword,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [DeleteBookMasterKeyword, { loading: deleteLoading }] = useMutation(
    DeleteLibBookMasterKeywordById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  //Methods
  const handleClear = () => {
    setClassificationId(EMPTY_RESPONSETYPE_OBJECT);
    setKeywords([]);
    classInputRef?.focus();
  };
  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      //incase if user clicks back the modal will open which should not happen and if he clicks the back in message it should close
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const HandleSubmit = () => {
    AddBookMasterKeyword({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        input: {
          lib_book_classification_id: classificationId.value,
          lib_keyword_ids: Keywords?.map(({ value }) => value),
        },
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: `Classification associated with keywords`,
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };

  const HandleDelete = (lib_book_classification_id: number) => {
    DeleteBookMasterKeyword({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        lib_book_classification_id,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Classification & Keywords Association deleted succesfully",
          flag: true,
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  //useEffects
  useEffect(() => {
    if (KeywordData && !KeywordLoading) {
      setKeywordOptions(
        KeywordData &&
          KeywordData.GetLibKeywordsByInstId.edges?.map(({ node }) => ({
            label: node.keyword,
            value: node.id,
          }))
      );
    } else {
      setKeywordOptions(EMPTY_RESPONSETYPE);
    }
  }, [KeywordData, KeywordLoading]);

  useEffect(() => {
    if (token) {
      GetKeywordData();
    }
  }, [token, GetKeywordData]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {Library.Titles.BookMasterKeyword.map(
          (title: LibraryTitles, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {operation === Operation.DELETE ? title.Delete : title.Keyword}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <Formik
        initialValues={{ classificationId: classificationId.value }}
        validationSchema={BookMasterKeywordValidation}
        enableReinitialize
        onSubmit={HandleSubmit}
      >
        {(meta) => {
          return (
            <Form className="library__frame">
              <div className="library__frame--row ">
                <div className="h-100 booktype-left">
                  <div className="library__frame--title">
                    <Title variant="subtitle1">
                      {Library.Titles.BookMasterKeyword.map(
                        (title: LibraryTitles, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {operation === Operation.DELETE
                                ? title.DeleteKeyword
                                : title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>
                  <div className="library__frame--block  library__frame--block--autocomplete">
                    <div className="label-grid">
                      <Label>Classification</Label>
                      <Autocomplete
                        classes={requiredClasses}
                        options={classificationsDropDown!}
                        openOnFocus
                        ref={classRef!}
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            keywordEnterRef?.focus();
                            e.preventDefault();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setClassificationId(EMPTY_RESPONSETYPE_OBJECT);
                          }
                        }}
                        value={classificationId}
                        popupIcon={
                          <img
                            src={DownArrow}
                            alt="/"
                            className="down-arrow-autocomplete"
                          />
                        }
                        onChange={(e, newValue) => {
                          if (newValue) setClassificationId(newValue);
                          else setClassificationId(EMPTY_RESPONSETYPE_OBJECT);
                          if (newValue?.value) {
                            GetBookMasterKeyword({
                              variables: {
                                token,
                                lib_book_classification_id: newValue?.value,
                              },
                            }).then(({ data }) => {
                              if (data) {
                                setKeywords(
                                  data?.GetLibBookMasterKeywords?.map(
                                    ({ key_words }) => ({
                                      label: key_words.keyword,
                                      value: key_words.id,
                                    })
                                  )
                                );
                              }
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            name="lib_book_classification_id"
                            autoFocus
                            onChange={(e) => {
                              setClassification(e.target.value);
                            }}
                            classes={{ root: textClasses.formControlRoot }}
                          />
                        )}
                      />
                    </div>
                    {operation !== Operation.DELETE ? (
                      <div className="label-grid library__frame--block--autocomplete--multiselect">
                        <Label>Keyword</Label>
                        <Autocomplete
                          classes={requiredClasses}
                          multiple
                          limitTags={MULTI_SELECT_LIMIT_TAGS}
                          id="multiple-limit-tags"
                          options={keywordOptions!}
                          ref={keywordRef!}
                          onKeyDown={(e: React.KeyboardEvent) => {
                            if (e.key === Keys.ENTER) {
                              saveRef?.current?.focus();
                              e.preventDefault();
                            }
                            if (e.key === Keys.BACKSPACE) {
                              setKeywords([]);
                            }
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={
                                  Keywords?.find(
                                    ({ value }) => value === option.value
                                  )
                                    ? true
                                    : false
                                }
                              />
                              {option.label}
                            </li>
                          )}
                          openOnFocus
                          value={Keywords ?? []}
                          popupIcon={
                            <img
                              src={DownArrow}
                              alt="/"
                              className="down-arrow-autocomplete"
                            />
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setKeywords((newValue as responseType[]) ?? []);
                            } else {
                              setKeywords([]);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              name="lib_keyword_id"
                              onChange={(e) => {
                                setKeyword(e.target.value);
                              }}
                              classes={{ root: textClasses.formControlRoot }}
                            />
                          )}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="h-100 booktype-right">
                  <div className="library__frame--title">
                    <Title variant="subtitle1">
                      {Library.Titles.BookMasterKeyword.map(
                        (title: LibraryTitles, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>
                  <div className="library__frame--block">
                    <div className="library__frame--block--keywords">
                      <Label>
                        <b>Classification : &nbsp;</b>
                        {
                          classificationsDropDown?.find(
                            ({ value }) => value === classificationId.value
                          )?.label
                        }
                      </Label>
                      <div className="library__frame--block--keywords--footer">
                        {classificationId
                          ? data?.GetLibBookMasterKeywords?.map(
                              ({ key_words }) => {
                                return <label>{key_words?.keyword}</label>;
                              }
                            )
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="library__buttons">
                {operation !== Operation.DELETE ? (
                  <Button mode="save" type="submit" buttonref={saveRef!} />
                ) : null}
                {operation === Operation.DELETE ? (
                  <Button
                    mode="delete"
                    type="button"
                    onClick={() => setDeleteModal(!deleteModal)}
                  />
                ) : null}
                {classificationId && operation !== Operation.DELETE ? (
                  <Button
                    mode="delete"
                    type="button"
                    onClick={() => setOperation(Operation.DELETE)}
                  >
                    Association
                  </Button>
                ) : null}
                <Button mode="clear" type="button" onClick={handleClear} />
                <Button
                  mode="back"
                  type="button"
                  onClick={() => {
                    operation === Operation.DELETE
                      ? setOperation(Operation.CREATE)
                      : navigate(-1);
                  }}
                />
              </div>
            </Form>
          );
        }}
      </Formik>

      <LoadingModal flag={creationLoading ?? deleteLoading} />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={classificationId.value}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;
