import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";

import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import HRImport from "../../Imports/EmployeesImport";

import Close from "../../../../images/Close.svg";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";

import LoadingModal from "../../../../pages/LoadingModal";
import Home from "../../Home/Index";

import { TableHeaderProps } from "../../../../Types/Tables";
import { HRTitleProps } from "../../../../Types/Titles";
import { responseType } from "../../../../utils/Form.types";
import { PageFor } from "../../../../utils/Enum.types";
import { Keys } from "../../../../utils/Enum.keys";

import {
  EMPTY_RESPONSETYPE_OBJECT,
  FETCH_MORE_DATA,
  NOTALLOCATED,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";

import {
  getModifiedScrollHeight,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../../utils/UtilFunctions";

import useEmpMasterData from "../../hooks/useEmpMasterData";
import useEmployee, {
  PayRollEmpEdges,
  empQueryTypes,
} from "../../hooks/useEmployee";

import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";
import {
  ACTIONS,
  CATEGORY,
  DEPARTMENT,
  DESIGNATION,
  HYPHEN,
  JOB_TYPE,
} from "../../constants";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import { payloadTypes } from "../../../../context/reducer";
import { AppContext } from "../../../../context/context";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { PayRollMasters } from "../../enums/Enum.types";

const { HRTitles } = require("../../json/title.json");
const { HR_Table } = require("../../json/table.json");

export const enum ComponentForEmployee {
  GENERAL = "GENERAL",
  ORGANIZATIONAL = "ORGANIZATIONAL",
  EDIT = "EDIT",
  AUTOCOMPLETE = "AUTOCOMPLETE",
}

interface Props {
  pageType: PageFor;
  setModalFlag?: React.Dispatch<React.SetStateAction<boolean>>;
  queryType?: empQueryTypes;
  type?: ComponentForEmployee;
}

const Index = ({ pageType, setModalFlag, queryType, type }: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_GRADE,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();
  const [searchData, setSearchData] = useState("");

  const [importModal, setImportModal] = useState(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);
  const [departmentId, setDepartmentId] = useState<responseType | null>(null);
  const [designationId, setDesignationId] = useState<responseType | null>(null);
  const [categoryId, setCategoryId] = useState<responseType | null>(null);
  const [gradeId, setGradeId] = useState<responseType | null>(null);
  const [jobTypeId, setJobTypeId] = useState<responseType | null>(null);

  const {
    empDetails: { data, loading, fetchMore },
  } = useEmployee(
    ROWS_PER_PAGE,
    pageType === PageFor.MODAL ? queryType! : empQueryTypes.GENERAL,
    searchData,
    0,
    departmentId ? departmentId.value : 0,
    designationId ? designationId.value : 0,
    categoryId ? categoryId.value : 0,
    gradeId ? gradeId.value : 0,
    jobTypeId ? jobTypeId.value : 0
  );

  const TableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          {HR_Table.EmployeeList.Table_Headers?.filter(
            (th: TableHeaderProps) => {
              if (
                (th.labelName === DESIGNATION &&
                  USE_HR_DESIGNATION === false) ||
                (th.labelName === DEPARTMENT && USE_HR_DEPARTMENT === false) ||
                (th.labelName === CATEGORY && USE_HR_CATEGORY === false) ||
                (th.labelName === JOB_TYPE && USE_HR_JOBTYPE === false) ||
                (th.labelName === ACTIONS && pageType === PageFor.MODAL)
              ) {
                return false;
              } else {
                return true;
              }
            }
          ).map((th: TableHeaderProps, index: React.Key) => {
            return (
              <TableCell key={index} className={th.className}>
                {th.labelName}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };
  const {
    departmentDropDown,
    designationDropDown,
    jobTypeDropDown,
    gradeDropDown,
    categoryDropDown,
  } = useEmpMasterData();

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetPayRollEmp.edges;
            const pageInfo = fetchMoreResult.GetPayRollEmp.pageInfo;

            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetPayRollEmp.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetPayRollEmp: {
                edges: [...employees, ...newEdges],
                pageInfo,
                totalCount: data?.GetPayRollEmp.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetPayRollEmp.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = employees.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setEmployees(updatedNewData);
      } else {
        setEmployees(newData);
      }
      setHasNextPage(data.GetPayRollEmp.pageInfo.hasNextPage);
      setEndCursor(data.GetPayRollEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  return (
    <>
      {pageType === PageFor.GENERAL &&
        type === ComponentForEmployee.GENERAL && (
          <Home DashBoardRequired={false} />
        )}
      {type === ComponentForEmployee.ORGANIZATIONAL ? null : (
        <Title>
          {HRTitles.Employee_List.Titles.map(
            (title: HRTitleProps, index: React.Key) => {
              return <React.Fragment key={index}>{title.List}</React.Fragment>;
            }
          )}
        </Title>
      )}

      <div
        className={
          pageType === PageFor.MODAL ? "employee-list__modal" : "employee-list"
        }
      >
        <div className="row g-0 employee-list__details">
          <div className="col-1">
            <Input
              id="search"
              placeholder="Search..."
              type="text"
              onChange={(e) => setSearchData(e.target.value)}
            />
          </div>

          {USE_HR_CATEGORY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={categoryDropDown!}
                onChange={(e, newValue) => {
                  if (newValue) setCategoryId(newValue as responseType);
                  else setCategoryId(EMPTY_RESPONSETYPE_OBJECT);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE)
                    setCategoryId(EMPTY_RESPONSETYPE_OBJECT);
                }}
                openOnFocus
                freeSolo
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                     label="Category"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_HR_DEPARTMENT ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={departmentDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentId)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentId(newValue as responseType);
                  } else {
                    setDepartmentId(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentId(null);
                  }
                }}
                openOnFocus
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Departments"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_HR_DESIGNATION ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={designationDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, designationId)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDesignationId(newValue as responseType);
                  } else {
                    setDesignationId(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setDesignationId(null);
                  }
                }}
                openOnFocus
                freeSolo
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Designation"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_HR_GRADE ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={gradeDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, gradeId)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setGradeId(newValue as responseType);
                  } else {
                    setGradeId(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setGradeId(null);
                  }
                }}
                openOnFocus
                freeSolo
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Grade"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_HR_JOBTYPE ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={jobTypeDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, jobTypeId)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setJobTypeId(newValue as responseType);
                  } else {
                    setJobTypeId(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setJobTypeId(null);
                  }
                }}
                openOnFocus
                freeSolo
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Job Type"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col"></div>
          {pageType === PageFor.GENERAL &&
            type === ComponentForEmployee.GENERAL && (
              <div className="col-1">
                <Button
                  className="employee-list__details--addnew"
                  mode="addnew"
                  autoFocus
                  onClick={() =>
                    navigate(`/${InstId}/hr/employee/1/0/registration`)
                  }
                />
              </div>
            )}
        </div>
        <div className="employee-list__tableblock">
          <TableContainer
            className="employee-list__tableblock--table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHeader />
              <TableBody>
                {employees.map((edge, index) => {
                  return (
                    <TableRow
                      key={index}
                      onClick={() => {
                        if (pageType === PageFor.MODAL) {
                          if (type === ComponentForEmployee.EDIT) {
                            dispatch({
                              type: payloadTypes.SET_EMPLOYEE_ID,
                              payload: { employeeId: edge.node.id },
                            });
                          }
                          if (type === ComponentForEmployee.AUTOCOMPLETE) {
                            dispatch({
                              type: payloadTypes.SET_SEARCH_EMPLOYEE_ID,
                              payload: { searchEmployeeId: edge.node.id },
                            });
                          }
                          setModalFlag?.(false);
                        }
                      }}
                    >
                      <TableCell
                        className="employee-list__tableblock--table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        className="employee-list__tableblock--table--empid"
                        id="td-center"
                      >
                        {edge.node.emp_id}
                      </TableCell>
                      <TableCell
                        className="employee-list__tableblock--table--name"
                        onClick={() => {
                          if (pageType === PageFor.GENERAL) {
                            navigate(
                              `/${InstId}/hr/employee/${edge.node.id}/preview/basicdetails`
                            );
                          }
                        }}
                      >
                        {edge.node.emp_first_name +
                          " " +
                          edge.node.emp_middle_name +
                          " " +
                          edge.node.emp_last_name}
                      </TableCell>
                      {USE_HR_DEPARTMENT ? (
                        <TableCell className="employee-list__tableblock--table--desc">
                          {edge.node.pr_dept_details.dept_desc === NOTALLOCATED
                            ? HYPHEN
                            : edge.node.pr_dept_details.dept_desc}
                        </TableCell>
                      ) : null}
                      {USE_HR_JOBTYPE ? (
                        <TableCell className="employee-list__tableblock--table--desc">
                          {edge.node.pr_job_type_details.job_type_desc ===
                          NOTALLOCATED
                            ? HYPHEN
                            : edge.node.pr_job_type_details.job_type_desc}
                        </TableCell>
                      ) : null}
                      <TableCell
                        className="employee-list__tableblock--table--number"
                        id="td-center"
                      >
                        {edge.node.emp_experience}
                      </TableCell>
                      {USE_HR_DESIGNATION ? (
                        <TableCell className="employee-list__tableblock--table--desc">
                          {edge.node.pr_designation_details.designation_desc ===
                          NOTALLOCATED
                            ? HYPHEN
                            : edge.node.pr_designation_details.designation_desc}
                        </TableCell>
                      ) : null}
                      {USE_HR_CATEGORY ? (
                        <TableCell className="employee-list__tableblock--table--desc">
                          {edge.node.pr_category_details.category_desc ===
                          NOTALLOCATED
                            ? HYPHEN
                            : edge.node.pr_category_details.category_desc}
                        </TableCell>
                      ) : null}
                      <TableCell
                        className="employee-list__tableblock--table--date"
                        id="td-center"
                      >
                        {toStandardDate(edge.node.emp_doj)}
                      </TableCell>
                      <TableCell
                        className="employee-list__tableblock--table--date"
                        id="td-center"
                      >
                        {edge.node.emp_on_roll ? "On Role" : "Off Role"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {pageType === PageFor.GENERAL ? (
          <>
            <Button
              type="button"
              mode="excel"
              onClick={() => setImportModal(!importModal)}
            >
              Import Employees
            </Button>
            <Button type="button" mode="export" />

            <Button mode="back" onClick={() => navigate(-1)} />
          </>
        ) : (
          <Button mode="cancel" onClick={() => setModalFlag?.(false)} />
        )}
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <HRImport MasterType={PayRollMasters.CATEGORY} />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setImportModal(!importModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
