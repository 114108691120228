import React, { useEffect, useState } from "react";
import useSwConfigData from "./useSwConfigData";
import { InstitutionConfigurationTypes } from "../utils/Enum.types";

const usePollIntervalTime = () => {
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.DASHBOARD_REFRESH_TIMEOUT
  );
  const [pollIntervalTime, setPollIntervalTime] = useState(0);
  useEffect(() => {
    if (configData.data && !configData.loading) {
      const intervalInSeconds =
        configData.data.GetSwConfigVariables[0].config_integer_value;
      const intervalInMilliseconds = intervalInSeconds * 100;
      setPollIntervalTime(intervalInMilliseconds);
    }
  }, [configData.data, configData.loading]);
  return pollIntervalTime;
};

export default usePollIntervalTime;
