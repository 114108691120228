import React from "react";
import { Operation } from "../../../../utils/Enum.types";
import {
  Autocomplete,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import {
  getFileIcon,
  handleDownloadFiles,
} from "../../../../utils/UtilFunctions";
import Download from "../../../../images/DocumentsDownload.svg";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";

interface Props {
  operation: Operation;
  step: number;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
interface files {
  filename: string;
  url: string;
}
const documents: files[] = [
  {
    filename: "sample.jpg",
    url: "https://images.freeimages.com/images/previews/884/buddha-1310506.jpg",
  },
  { filename: "sample.pdf", url: "https://example.com/documents/sample.pdf" },
  { filename: "sample.doc", url: "https://example.com/documents/sample.doc" },
];

const stepHeader = () => {
  return ["Staff Details", "Staff Documents"];
};
const Documents = ({ step, operation, setModalFlag }: Props) => {
  const stepsHeader = stepHeader();
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const navigate = useNavigate();

  return (
    <>
      {operation === Operation.CREATE && <Home DashBoardRequired={false} />}

      <div className="vms-staff-details-new">
        <div className="vms-staff-details-new__stepper">
          {operation === Operation.CREATE && (
            <Stepper alternativeLabel activeStep={step - 1}>
              {stepsHeader.map((step, index) => {
                return (
                  <Step key={index}>
                    <StepLabel>{step}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}
        </div>
        <Title>Documents</Title>
        <div
          className={
            operation === Operation.CREATE
              ? "vms-staff-details-new__documents-block"
              : ""
          }
        >
          <div
            className={
              operation === Operation.CREATE
                ? "vms-staff-details-new__documents-details"
                : "vms-staff-details-new__documents-details--update"
            }
          >
            <div className="vms-staff-details-new__documents-details--header">
              <div className="vms-staff-details-new__documents-details--header--name">
                File Name
              </div>
              <div className="vms-staff-details-new__documents-details--header--type">
                Document Type
              </div>
            </div>
            <div className="vms-staff-details-new__documents-details--list">
              <div className="vms-staff-details-new__documents-details--list--file-type">
                <ul>
                  {documents.map((file, index) => (
                    <li
                      key={index}
                      onClick={() =>
                        handleDownloadFiles(file.filename, file.url)
                      }
                    >
                      <span>
                        <img src={getFileIcon(file.filename)} alt="" />
                        {file.filename}
                      </span>
                      <img src={Download} alt="/" />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="vms-staff-details-new__documents-details--list--select">
                <Autocomplete
                  classes={classes}
                  options={["License", "Study Certificate"]}
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            </div>
            <button className="vms-staff-details-new__documents-details--list--button">
              + Choose One More File Here
            </button>

            <div className="vms-staff-details-new__documents-details--footer">
              Please Upload File (Max File Size 5MB. Only jpeg, jpg and png
              allowed)
            </div>
          </div>
        </div>

        <Button mode="save" />
        <Button mode="clear" />
        {operation === Operation.CREATE ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        )}
      </div>
    </>
  );
};

export default Documents;
