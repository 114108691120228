import {
  Autocomplete,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { Step, StepLabel, Stepper } from "@mui/material";
import Home from "../../Home/Index";
import InputHoc from "../../../../components/common/Input/Index";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import { LabelNameProps } from "../../../../Types/Labels";
import { HRTitleProps } from "../../../../Types/Titles";
import {
  Direction,
  LicenseTypes,
  Operation,
  PageFor,
  PredefinedDataTypes,
  SortBy,
} from "../../../../utils/Enum.types";
import DownArrow from "../../../../images/DownArrow.svg";
import Add from "../../../../images/Add.svg";
import Close from "../../../../images/Close.svg";
import { AntSwitch } from "../../../../pages/Switch";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import PayrollDepartment from "../../Payroll/Department/Index";
import PayrollCategory from "../../Payroll/Category/Index";
import PayrollDesignation from "../../Payroll/Designation/Index";
import PayrollGrade from "../../Payroll/Grade/Index";
import PayrollJobType from "../../Payroll/JobType/Index";
import { useNavigate, useParams } from "react-router-dom";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import { Form, Formik } from "formik";
import { EmpBasicDetailsSchema } from "../../../../utils/payrole";

import useToken from "../../../../customhooks/useToken";
import useEmpMasterData from "../../hooks/useEmpMasterData";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../utils/Form.types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLazyQuery, useMutation } from "@apollo/client";
import LoadingModal from "../../../../pages/LoadingModal";
import {
  getMonthDateAndDay,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import {
  AUTOCOMPLETE_INPUT_CLASSNAME,
  CURR,
  DEFAULT_TIME,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
  TODAY_DATE,
  image_upload_size,
} from "../../../../utils/constants";
import useEmpDetailsById from "../../hooks/useEmpDetailsById";
import useEmployee, {
  PrEmpQueryType,
  empQueryTypes,
} from "../../hooks/useEmployee";
import { Keys } from "../../../../utils/Enum.keys";
import { GetPayRollEmp, GetPayRollEmpId } from "../../queries/employee/query";
import {
  AddPayRollEmpMaster,
  UpdatePayRollEmpMaster,
} from "../../queries/employee/mutation";
import { EmpBasicDetailsFormType } from "../../Types/formTypes";
import { GetPayRollEmpIdData, InstIdVars } from "../../Types/masterDataTypes";
import useInstReferenceDataByType, {
  InstReferenceDataByType,
} from "../../../../customhooks/useInstReferenceDataByType";
import MessageModal from "../../../../pages/MessageModal";
import usePayRollMasterNotAllocatedIds from "../../hooks/usePayRollMasterNotAllocatedIds";
import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";
import { EMP_EXPERIENCE, stepHeader } from "../../constants";
import InputStory from "../../../../stories/Input/Input";
import FileAttach from "../../../../images/BrowseFiles.svg";

import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { handleUploadAndDownloadFile } from "../../../../utils/Upload";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { imageFileType } from "../../../Master/Student/Documents/Index";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { ComponentFor, PayRollConfigKeys } from "../../enums/Enum.types";
import EmpList, { ComponentForEmployee } from "../List/Index";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useCheckAllocationType from "../../../Academics/hooks/useCheckAllocationType";
import { AccountentType } from "../../../Accounts/common/Enum.types";
const { HRTitles } = require("../../json/title.json");
const { HRFormLabels } = require("../../json/form.json");

interface props {
  operation: Operation;
  step: number;
}

const BasicDetails = ({ step, operation }: props) => {
  const stepsHeader = stepHeader();
  const { token } = useToken();
  const navigate = useNavigate();
  const { InstId, empId } = useParams();
  const { dispatch, state } = useContext(AppContext);
  //Styles
  const textClasses = formAutoCompleteTextStyles();
  const classes = formAutoCompleteStyles();
  const { user_details } = useLoggedInUserDetails();
  //Modal Flags
  const [departmentModal, setDepartmentModal] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [gradeModal, setGradeModal] = useState(false);
  const [designationModal, setDesignationModal] = useState(false);
  const [jobTypeModal, setJobTypeModal] = useState(false);
  const [date, setDate] = useState<Date>();

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  //useRefs
  const empTitleRef = useRef<HTMLSelectElement>(null);
  const empTitleInputRef = empTitleRef.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;

  const saveRef = useRef<HTMLButtonElement>(null);
  const { serverDate } = useServerDateandTime();
  const [formData, setFormData] = useState<EmpBasicDetailsFormType>({
    emp_dept_idx: 0,
    emp_dob: "",
    emp_doj: TODAY_DATE,
    emp_dor: "",
    emp_email: "",
    emp_experience: 0,
    emp_first_name: "",
    emp_id: "",
    emp_idx: 0,
    emp_last_name: "",
    emp_lsd: "",
    emp_middle_name: "",
    emp_mobile: "",
    emp_on_roll: false,
    emp_qualification: "",
    emp_profile_filename: "",
  });

  const [searchEmployee, setSearchEmployee] = useState("");

  const [emp_title, setemp_title] = useState<optionsType | null>(null);
  const [pr_category, setpr_category] = useState<responseType | null>(null);
  const [pr_dept, setpr_dept] = useState<responseType | null>(null);

  const [pr_designation, setpr_designation] = useState<responseType | null>(
    null
  );
  const [pr_parent_designation, setpr_parent_designation] =
    useState<responseType | null>(null);
  const [pr_reporting_to, setpr_reporting_to] = useState<responseType | null>(
    null
  );
  const [pr_grade, setpr_grade] = useState<responseType | null>(null);
  const [pr_job_type, setpr_job_type] = useState<responseType | null>(null);
  const [emp_gender, setemp_gender] = useState<optionsType | null>(null);
  const [empType, setEmpType] = useState("");
  const [emp_id, setemp_id] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [imageString, setImageString] = useState("");
  const [progress, setProgress] = useState(0);
  const { InstFormData } = useInstDetails(1);
  const [empListModal, setEmpListModal] = useState(false);

  const {
    empDetails: { responseType: employeesOptions },
  } = useEmployee(
    NumberOfAdmissionNumberToBeDisplayed,
    empQueryTypes.GENERAL,
    searchEmployee,
    state.searchEmployeeId
  );
  let filename = `${InstFormData.inst_name}/payroll/${formData.emp_first_name} ${file?.name}`;

  const filetype = file?.name.split(".").pop();

  const imageRef = useRef<HTMLInputElement>(null);
  const genderRef = useRef<HTMLSelectElement>(null);
  const genderInputRef = genderRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const [GetEmployeeId] = useLazyQuery<GetPayRollEmpIdData, InstIdVars>(
    GetPayRollEmpId
  );

  const { flag } = useCheckAllocationType();
  const {
    configData: { data: empEditEnableData },
  } = useSwConfigData(PayRollConfigKeys.PR_EDIT_EMP_ID);
  const {
    configData: { data: enableMiddleNameData },
  } = useSwConfigData(PayRollConfigKeys.PR_ENABLE_MIDDLE_NAME);
  const {
    configData: { data: enableLastNameData },
  } = useSwConfigData(PayRollConfigKeys.PR_ENABLE_LAST_NAME);
  const enableEmpIdEdit =
    empEditEnableData?.GetSwConfigVariables[0].config_boolean_value;
  const enableMiddleName =
    enableMiddleNameData?.GetSwConfigVariables[0].config_boolean_value;
  const enableLastName =
    enableLastNameData?.GetSwConfigVariables[0].config_boolean_value;

  const [AddEmpBasicDetails, { loading: creationLoading }] = useMutation(
    AddPayRollEmpMaster,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          flag: true,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateEmpBasicDetails, { loading: updationLoading }] = useMutation(
    UpdatePayRollEmpMaster,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          flag: true,
          operation: Operation.NONE,
        }),
    }
  );
  const { empolyeeData } = useEmpDetailsById();
  const {
    InstreferenceData: { optionsType: nameTitleOptions },
  } = useInstReferenceDataByType(InstReferenceDataByType.NAME_TITLE);
  const {
    PredefinedData: { dropDown: Gender },
  } = usePredefinedDataByType(PredefinedDataTypes.GENDER, EMPTY_STRING);
  const {
    departmentDropDown,
    designationDropDown,
    jobTypeDropDown,
    gradeDropDown,
    categoryDropDown,
  } = useEmpMasterData();
  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_GRADE,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();
  const {
    NotAllocatedCategoryId,
    NotAllocatedDepartmentId,
    NotAllocatedDesignationId,
    NotAllocatedGradeId,
    NotAllocatedJobTypeId,
  } = usePayRollMasterNotAllocatedIds();
  useEffect(() => {
    if (empolyeeData.data && !empolyeeData.loading) {
      const { node } = empolyeeData.data;
      setFormData({
        emp_id: node.emp_id,
        emp_first_name: node.emp_first_name,
        emp_middle_name: node.emp_middle_name,
        emp_last_name: node.emp_last_name,
        emp_mobile: node.emp_mobile,
        emp_email: node.emp_email,
        emp_qualification: node.emp_qualification,
        emp_experience: node.emp_experience,
        emp_status: node.emp_status,
        emp_on_roll: node.emp_on_roll,
        emp_dor: node.emp_dor,
        emp_lsd: node.emp_lsd,
        emp_idx: node.emp_idx,
        emp_dept_idx: node.emp_dept_idx,
        emp_dob: toInputStandardDate(node.emp_dob),
        emp_doj: toInputStandardDate(node.emp_doj),
        emp_profile_filename: node.emp_profile_filename,
      });
      setpr_grade(
        gradeDropDown.find(({ value }) => value === node.pr_grade_id) ?? null
      );
      setpr_dept(
        departmentDropDown.find(({ value }) => value === node.pr_dept_id) ??
          null
      );
      setpr_category(
        categoryDropDown.find(({ value }) => value === node.pr_category_id) ??
          null
      );
      setpr_designation(
        designationDropDown.find(
          ({ value }) => value === node.pr_designation_id
        ) ?? null
      );
      setpr_parent_designation(
        designationDropDown.find(
          ({ value }) => value === node.parent_designation_id
        ) ?? null
      );
      setpr_job_type(
        jobTypeDropDown.find(({ value }) => value === node.pr_job_type_id) ??
          null
      );
      setemp_title(
        nameTitleOptions.find(({ value }) => value === node.emp_title) ?? null
      );
      setemp_gender(Gender.find(({ value }) => value === node.emp_sex) ?? null);
      setpr_reporting_to(
        employeesOptions.find(
          ({ value }) => value === node.reporting_to_emp_id
        ) ?? null
      );
    }
  }, [
    empolyeeData.data,
    empolyeeData.loading,
    nameTitleOptions,
    gradeDropDown,
    departmentDropDown,
    categoryDropDown,
    designationDropDown,
    jobTypeDropDown,
    Gender,
    employeesOptions,
  ]);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));

  const handleSubmitForm = () => {
    if (!(formData.emp_mobile.length === 12 || formData.emp_email)) {
      alert("Please fill any one the field, Email/Mobile No:");
      return;
    }
    if (emp_gender && emp_title) {
      AddEmpBasicDetails({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          flags: {
            is_emp_id_edited: formData.emp_id === emp_id ? false : true,
            use_emp_id_as_is: false,
          },
          input: {
            ...formData,
            emp_mobile: formData.emp_mobile
              ? `+${formData.emp_mobile}`
              : EMPTY_STRING,
            emp_dob: toIsoDate(formData.emp_dob),
            emp_doj: toIsoDate(formData.emp_doj),
            emp_dor: toIsoDate(DEFAULT_TIME),
            emp_lsd: toIsoDate(DEFAULT_TIME),
            emp_sex: emp_gender.value,
            emp_title: emp_title.value,
            emp_type: empType,
            just_login_acct: formData.emp_on_roll ? false : true,
            pr_grade_id: USE_HR_GRADE ? pr_grade?.value : NotAllocatedGradeId,
            pr_dept_id: USE_HR_DEPARTMENT
              ? pr_dept?.value
              : NotAllocatedDepartmentId,
            pr_category_id: USE_HR_CATEGORY
              ? pr_category?.value
              : NotAllocatedCategoryId,
            pr_job_type_id: USE_HR_JOBTYPE
              ? pr_job_type?.value
              : NotAllocatedJobTypeId,
            pr_designation_id: USE_HR_DESIGNATION
              ? pr_designation?.value
              : NotAllocatedDesignationId,
            parent_designation_id: USE_HR_DESIGNATION
              ? pr_parent_designation?.value
              : NotAllocatedDesignationId,
            reporting_to_emp_id: pr_reporting_to?.value,
            emp_profile_filename: file ? filename : EMPTY_STRING,
          },
        },
        refetchQueries: [
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.EMP_BY_INST_ID,
              },
              per_std_subj_allocation: flag,
              name: EMPTY_STRING,
              sortBy: SortBy.EMP_FIRST_NAME,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              employeeId: null,
              token,
            },
          },
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.EMP_SAL_STR_ASSGINED,
              },
              per_std_subj_allocation: flag,
              name: EMPTY_STRING,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              employeeId: null,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
            },
          },
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.EMP_SAL_STR_NOT_ASSGINED,
              },
              per_std_subj_allocation: flag,
              name: EMPTY_STRING,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              employeeId: null,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          navigate(
            `/${InstId}/hr/employee/2/${data.AddPayRollEmpMaster.id}/personaldetails`
          );
        }
      });
    } else {
      setMessage({
        message: "Please fill the Required fields",
        flag: true,
        operation: Operation.NONE,
      });
    }
  };

  const handleUpdateEmpDetails = () => {
    if (emp_gender && emp_title) {
      UpdateEmpBasicDetails({
        variables: {
          token,
          inst_id: InstId,
          user_details,

          id: empId,
          input: {
            ...formData,
            emp_mobile: `+${formData.emp_mobile}`,
            pr_grade_id: USE_HR_GRADE ? pr_grade?.value : NotAllocatedGradeId,
            pr_dept_id: USE_HR_DEPARTMENT
              ? pr_dept?.value
              : NotAllocatedDepartmentId,
            pr_category_id: USE_HR_CATEGORY
              ? pr_category?.value
              : NotAllocatedCategoryId,
            pr_job_type_id: USE_HR_JOBTYPE
              ? pr_job_type?.value
              : NotAllocatedJobTypeId,
            pr_designation_id: USE_HR_DESIGNATION
              ? pr_designation?.value
              : NotAllocatedDesignationId,
            emp_sex: emp_gender.value,
            emp_dob: toIsoDate(formData.emp_dob),
            emp_doj: toIsoDate(formData.emp_doj),
            emp_dor: toIsoDate(DEFAULT_TIME),
            emp_lsd: toIsoDate(DEFAULT_TIME),
            emp_title: emp_title.value,
            parent_designation_id: USE_HR_DESIGNATION
              ? pr_parent_designation?.value
              : NotAllocatedDesignationId,
            reporting_to_emp_id: pr_reporting_to?.value,
            emp_profile_filename: file ? filename : EMPTY_STRING,
          },
        },
        refetchQueries: [
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              per_std_subj_allocation: flag,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.EMP_BY_INST_ID,
              },
              name: EMPTY_STRING,
              sortBy: SortBy.EMP_FIRST_NAME,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              token,
            },
          },
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              per_std_subj_allocation: flag,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.EMP_SAL_STR_ASSGINED,
              },
              name: EMPTY_STRING,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
            },
          },
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.EMP_SAL_STR_NOT_ASSGINED,
              },
              per_std_subj_allocation: flag,
              name: EMPTY_STRING,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
            },
          },
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              per_std_subj_allocation: flag,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.EMP_SAL_STR_NOT_ASSGINED,
              },
              name: EMPTY_STRING,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          if (operation === Operation.CREATE) {
            navigate(
              `/${InstId}/hr/employee/2/${data.UpdatePayRollEmpMaster.id}/personaldetails`
            );
          } else {
            navigate(
              `/${InstId}/hr/employee/${data.UpdatePayRollEmpMaster.id}/preview/basicdetails`
            );
          }
        }
      });
    } else {
      setMessage({
        message: "Please fill the required fields",
        flag: true,
        operation: Operation.NONE,
      });
    }
  };

  const handleClear = () => {
    setFormData({
      emp_dept_idx: 0,
      emp_dob: "",
      emp_doj: "",
      emp_dor: "",
      emp_email: "",
      emp_experience: 0,
      emp_first_name: "",
      emp_id: "",
      emp_idx: 0,
      emp_last_name: "",
      emp_lsd: "",
      emp_middle_name: "",
      emp_mobile: "",
      emp_on_roll: false,
      emp_qualification: "",
      emp_status: CURR,
      emp_profile_filename: "",
    });
    setemp_title(null);
    setpr_category(null);
    setpr_dept(null);
    setpr_designation(null);
    setpr_grade(null);
    setpr_job_type(null);
    setemp_gender(null);
    empTitleInputRef.focus();
  };
  const handleNumberChange = (mobileNo: string) => {
    setFormData((prevValues) => ({
      ...prevValues,
      emp_mobile: mobileNo,
    }));
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleImagePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (file?.size! > image_upload_size) {
        alert("huge file");
        setFile(null);
        return;
      }
      let image_as_files = e.target.files[0];
      setFile(image_as_files);
    }
  };
  useEffect(() => {
    if (file) {
      // eslint-disable-next-line
      Object.values(imageFileType).map((d) => {
        if (d === filetype) {
          setImageString(URL.createObjectURL(file));
        }
      });
    }
  }, [file, filetype]);
  useEffect(() => {
    if (serverDate) {
      const { day, month, year } = getMonthDateAndDay(serverDate);
      setDate(new Date(year, month - 1, day));
    }
  }, [serverDate]);
  useEffect(() => {
    if (Number(empId) === 0) {
      GetEmployeeId({
        variables: {
          token,
          inst_id: InstId!,
        },
      }).then(({ data }) => {
        if (data) {
          setFormData((prevValues) => ({
            ...prevValues,
            emp_id: data.GetPayRollEmpId,
          }));
          setemp_id(data.GetPayRollEmpId);
        }
      });
    }
  }, [empId, GetEmployeeId, token, InstId]);
  useEffect(() => {
    if (state.searchEmployeeId) {
      setpr_reporting_to(
        employeesOptions.find(
          ({ value }) => value === state.searchEmployeeId
        ) ?? null
      );
    }
  }, [state, employeesOptions]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="employee-details">
        <Stepper alternativeLabel activeStep={step - 1}>
          {stepsHeader.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Title variant="subtitle1">
          {HRTitles.Employee_Registration.Basic_Details.map(
            (title: HRTitleProps, index: React.Key) => {
              return <React.Fragment key={index}>{title.Add}</React.Fragment>;
            }
          )}
        </Title>
        <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={EmpBasicDetailsSchema}
          onSubmit={
            Number(empId) === 0 ? handleSubmitForm : handleUpdateEmpDetails
          }
        >
          {(meta) => {
            return (
              <Form className="employee-details__form">
                <div className="employee-details__block">
                  <div className="employee-details__block--frame">
                    {HRFormLabels.Employee.BasicDetails.InputType1.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <InputHoc
                              LabelName={label.LabelName}
                              values={formData[label.inputName]}
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              autoFocus={label.autoFocus}
                              min={date}
                              type={label.dataType}
                              disabled={
                                enableEmpIdEdit ? false : label.disabled
                              }
                              required={label.required ? true : false}
                            />
                          </React.Fragment>
                        );
                      }
                    )}

                    <div className="employee-details__block--frame--grid-trio">
                      {HRFormLabels.Employee.BasicDetails.InputType6.map(
                        (label: LabelNameProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              <Label>{label.LabelName}</Label>
                              <Autocomplete
                                classes={classes}
                                options={nameTitleOptions}
                                value={emp_title}
                                ref={empTitleRef}
                                isOptionEqualToValue={(option) =>
                                  isOptionEqualToValue(option, emp_title)
                                }
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setemp_title(newValue as optionsType);
                                  } else {
                                    setemp_title(null);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === Keys.BACKSPACE) {
                                    setemp_title(null);
                                  }
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    handleMUISelectEvent(e);
                                  }
                                }}
                                openOnFocus
                                freeSolo
                                popupIcon={<img src={DownArrow} alt="/" />}
                                forcePopupIcon
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    fullWidth
                                    autoFocus
                                    classes={{
                                      root: textClasses.formControlRoot,
                                    }}
                                  />
                                )}
                              />
                              <InputHoc
                                values={formData[label.inputName]}
                                name={label.inputName}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleValueChange(e);
                                  meta.handleChange(e);
                                }}
                                required={label.required ? true : false}
                              />
                            </React.Fragment>
                          );
                        }
                      )}
                    </div>

                    {HRFormLabels.Employee.BasicDetails.InputType2.filter(
                      (label: LabelNameProps) =>
                        label.inputName !== "emp_mobile"
                    ).map(
                      //  eslint-disable-next-line
                      (label: LabelNameProps, index: React.Key) => {
                        if (
                          (enableMiddleName &&
                            label.inputName === "emp_middle_name") ||
                          (enableLastName &&
                            label.inputName === "emp_last_name") ||
                          (label.inputName !== "emp_last_name" &&
                            label.inputName !== "emp_middle_name")
                        ) {
                          return (
                            <React.Fragment key={index}>
                              <InputHoc
                                LabelName={label.LabelName}
                                values={formData[label.inputName]}
                                name={label.inputName}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleValueChange(e);
                                  meta.handleChange(e);
                                }}
                                maxLength={label.maxLength}
                                required={label.required ? true : false}
                                type={label.dataType}
                                max={toInputStandardDate(serverDate)}
                              />
                            </React.Fragment>
                          );
                        }
                      }
                    )}
                    <div className="label-grid">
                      <Label>Mobile</Label>
                      <PhoneInput
                        country={"in"}
                        value={formData.emp_mobile}
                        onChange={handleNumberChange}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            genderInputRef?.focus();
                          }
                        }}
                      />
                    </div>
                    <div className="label-grid">
                      <Label>Gender</Label>
                      <Autocomplete
                        classes={classes}
                        options={Gender!}
                        ref={genderRef!}
                        value={emp_gender}
                        isOptionEqualToValue={(option) =>
                          isOptionEqualToValue(option, emp_title)
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setemp_gender(newValue as optionsType);
                          } else {
                            setemp_gender(null);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            setemp_gender(null);
                          }
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            if (emp_gender) {
                              handleMUISelectEvent(e);
                            }
                          }
                        }}
                        openOnFocus
                        freeSolo
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="label-grid">
                      <Label>Employee Type</Label>
                      <Autocomplete
                        classes={classes}
                        options={Object.keys(AccountentType)}
                        ref={genderRef!}
                        value={empType}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setEmpType(newValue);
                          } else {
                            setEmpType(EMPTY_STRING);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            setEmpType(EMPTY_STRING);
                          }
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            if (emp_gender) {
                              handleMUISelectEvent(e);
                            }
                          }
                        }}
                        openOnFocus
                        freeSolo
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>

                    {HRFormLabels.Employee.BasicDetails.InputType3.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <InputHoc
                              type={label.dataType}
                              LabelName={label.LabelName}
                              values={formData[label.inputName]}
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              onFocus={(e: React.FocusEvent) => {
                                (e.target as HTMLInputElement).select();
                              }}
                              required={label.required ? true : false}
                              min={date}
                            />
                          </React.Fragment>
                        );
                      }
                    )}

                    {HRFormLabels.Employee.BasicDetails.InputType4.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <InputHoc
                              LabelName={label.LabelName}
                              values={formData[label.inputName]}
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              required={label.required ? true : false}
                              type={label.dataType}
                              min={toInputStandardDate(date?.toString()!)}
                              onFocus={(
                                e: React.FocusEvent<HTMLInputElement>
                              ) => {
                                if (label.inputName === EMP_EXPERIENCE) {
                                  e.target.select();
                                }
                              }}
                              maxLength={label.maxLength}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    <>
                      {USE_HR_CATEGORY ? (
                        <div className="employee-details__block--frame--select-grid">
                          <Label>Category</Label>
                          <Autocomplete
                            classes={classes}
                            options={categoryDropDown}
                            value={pr_category}
                            isOptionEqualToValue={(option) =>
                              isOptionEqualToValue(option, pr_category)
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setpr_category(newValue as responseType);
                              } else {
                                setpr_category(null);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === Keys.BACKSPACE) {
                                setpr_category(null);
                              }
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (pr_category) {
                                  handleMUISelectEvent(e);
                                }
                              }
                            }}
                            openOnFocus
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                classes={{
                                  root: textClasses.formControlRoot,
                                }}
                              />
                            )}
                          />
                          <img
                            className="data-fetch-icon"
                            src={Add}
                            alt="/"
                            onClick={() => setCategoryModal(!categoryModal)}
                          />
                        </div>
                      ) : null}
                      {USE_HR_DEPARTMENT ? (
                        <div className="employee-details__block--frame--select-grid">
                          <Label>Department</Label>
                          <Autocomplete
                            classes={classes}
                            options={departmentDropDown}
                            value={pr_dept}
                            isOptionEqualToValue={(option) =>
                              isOptionEqualToValue(option, pr_dept)
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setpr_dept(newValue);
                              } else {
                                setpr_dept(null);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === Keys.BACKSPACE) {
                                setpr_dept(null);
                              }
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (pr_dept) {
                                  handleMUISelectEvent(e);
                                }
                              }
                            }}
                            openOnFocus
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                required
                                classes={{
                                  root: textClasses.formControlRoot,
                                }}
                              />
                            )}
                          />
                          <img
                            className="data-fetch-icon"
                            src={Add}
                            alt="/"
                            onClick={() => setDepartmentModal(!departmentModal)}
                          />
                        </div>
                      ) : null}
                      {USE_HR_DESIGNATION ? (
                        <div className="employee-details__block--frame--select-grid">
                          <Label>Designation</Label>
                          <Autocomplete
                            classes={classes}
                            options={designationDropDown}
                            value={pr_designation}
                            isOptionEqualToValue={(option) =>
                              isOptionEqualToValue(option, pr_designation)
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setpr_designation(newValue);
                              } else setpr_designation(null);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === Keys.BACKSPACE) {
                                setpr_designation(null);
                              }
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();

                                if (pr_designation) {
                                  handleMUISelectEvent(e);
                                }
                              }
                            }}
                            openOnFocus
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                classes={{
                                  root: textClasses.formControlRoot,
                                }}
                              />
                            )}
                          />
                          <img
                            className="data-fetch-icon"
                            src={Add}
                            alt="/"
                            onClick={() =>
                              setDesignationModal(!designationModal)
                            }
                          />
                        </div>
                      ) : null}
                      {USE_HR_GRADE ? (
                        <div className="employee-details__block--frame--select-grid">
                          <Label>Emp. Grade</Label>
                          <Autocomplete
                            classes={classes}
                            options={gradeDropDown}
                            value={pr_grade}
                            isOptionEqualToValue={(option) =>
                              isOptionEqualToValue(option, pr_grade)
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setpr_grade(newValue);
                              } else {
                                setpr_grade(null);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === Keys.BACKSPACE) {
                                setpr_grade(null);
                              }
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (pr_grade) {
                                  handleMUISelectEvent(e);
                                }
                              }
                            }}
                            openOnFocus
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                classes={{
                                  root: textClasses.formControlRoot,
                                }}
                              />
                            )}
                          />
                          <img
                            className="data-fetch-icon"
                            src={Add}
                            alt="/"
                            onClick={() => setGradeModal(!gradeModal)}
                          />
                        </div>
                      ) : null}
                      {USE_HR_JOBTYPE ? (
                        <div className="employee-details__block--frame--select-grid">
                          <Label>Job Type</Label>
                          <Autocomplete
                            classes={classes}
                            options={jobTypeDropDown!}
                            value={pr_job_type}
                            isOptionEqualToValue={(option) =>
                              isOptionEqualToValue(option, pr_job_type)
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setpr_job_type(newValue);
                              } else {
                                setpr_job_type(null);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === Keys.BACKSPACE) {
                                setpr_job_type(null);
                              }
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (pr_job_type) {
                                  handleMUISelectEvent(e);
                                }
                              }
                            }}
                            openOnFocus
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                classes={{
                                  root: textClasses.formControlRoot,
                                }}
                              />
                            )}
                          />
                          <img
                            className="data-fetch-icon"
                            src={Add}
                            alt="/"
                            onClick={() => setJobTypeModal(!jobTypeModal)}
                          />
                        </div>
                      ) : null}
                      {USE_HR_DESIGNATION ? (
                        <div className="employee-details__block--frame--select-grid">
                          <Label>Parent Designation</Label>
                          <Autocomplete
                            classes={classes}
                            options={designationDropDown}
                            value={pr_parent_designation}
                            isOptionEqualToValue={(option) =>
                              isOptionEqualToValue(
                                option,
                                pr_parent_designation
                              )
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setpr_parent_designation(newValue);
                              } else setpr_parent_designation(null);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === Keys.BACKSPACE) {
                                setpr_parent_designation(null);
                              }
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();

                                if (pr_designation) {
                                  handleMUISelectEvent(e);
                                }
                              }
                            }}
                            openOnFocus
                            popupIcon={<img src={DownArrow} alt="/" />}
                            forcePopupIcon
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                classes={{
                                  root: textClasses.formControlRoot,
                                }}
                              />
                            )}
                          />
                          <img
                            className="data-fetch-icon"
                            src={Add}
                            alt="/"
                            onClick={() =>
                              setDesignationModal(!designationModal)
                            }
                          />
                        </div>
                      ) : null}

                      <div className="employee-details__block--frame--select-grid">
                        <Label>Reporting to</Label>
                        <Autocomplete
                          classes={classes}
                          options={employeesOptions}
                          value={pr_reporting_to}
                          isOptionEqualToValue={(option) =>
                            isOptionEqualToValue(option, pr_reporting_to)
                          }
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setpr_reporting_to(newValue);
                            } else {
                              setpr_reporting_to(null);
                              dispatch({
                                type: payloadTypes.SET_SEARCH_EMPLOYEE_ID,
                                payload: { searchEmployeeId: 0 },
                              });
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === Keys.BACKSPACE) {
                              setpr_reporting_to(null);
                              dispatch({
                                type: payloadTypes.SET_SEARCH_EMPLOYEE_ID,
                                payload: { searchEmployeeId: 0 },
                              });
                            }
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              if (pr_dept) {
                                handleMUISelectEvent(e);
                              }
                            }
                          }}
                          openOnFocus
                          popupIcon={<img src={DownArrow} alt="/" />}
                          forcePopupIcon
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              onChange={(e) => {
                                setSearchEmployee(e.target.value);
                              }}
                              classes={{
                                root: textClasses.formControlRoot,
                              }}
                            />
                          )}
                        />
                        <img
                          className="data-fetch-icon"
                          src={Add}
                          alt="/"
                          onClick={() => setEmpListModal(!empListModal)}
                        />
                      </div>
                    </>
                    <FormGroup>
                      <FormControlLabel
                        className="payroll-masterdata__block--form-labels"
                        label="On Role"
                        control={
                          <AntSwitch
                            checked={formData.emp_on_roll}
                            onClick={(e: React.MouseEvent) =>
                              setFormData((prev) => ({
                                ...prev,
                                emp_on_roll: (e.target as HTMLInputElement)
                                  .checked,
                              }))
                            }
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                handleMUISelectEvent(e);
                              }
                            }}
                          />
                        }
                        labelPlacement="start"
                      />
                    </FormGroup>
                    <div className="employee-details__block--frame--select-grid">
                      <Label>Upload Image</Label>
                      <InputStory
                        value={file?.name}
                        disabled
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleImagePreview(e)
                        }
                      />
                      <label className="student-documents__browse">
                        <img src={FileAttach} alt="/" className="browse-icon" />
                        Browse
                        <input
                          id="student-documents__browse--inputTag"
                          type="file"
                          className="assignment-creation__fileattach--input"
                          ref={imageRef!}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleImagePreview(e)
                          }
                          accept="image/*"
                        />
                      </label>
                      <Button
                        mode="upload"
                        type="button"
                        onClick={() => {
                          if (file) {
                            handleUploadAndDownloadFile(
                              file,
                              filename,
                              setProgress,
                              false
                            );
                          }
                          if (!file) {
                            alert("Please Select A image ");
                          }
                        }}
                      />
                    </div>
                    {file ? (
                      <>
                        <img
                          src={
                            Operation.UPDATE === operation &&
                            formData.emp_profile_filename !== EMPTY_STRING
                              ? getDownloadUrl(
                                  formData.emp_profile_filename,
                                  false,
                                  setImageString
                                )
                              : imageString
                          }
                          alt="/"
                          className="image-preview"
                        />
                        <div className="modal-flex__image">
                          <img
                            src={Close}
                            alt="/"
                            onClick={() => setFile(null)}
                          />
                        </div>
                        <div className="label-grid">
                          <Label>Uploading In Progress</Label>
                          <progress value={progress}></progress>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>

                {Number(empId) === 0 ? (
                  <Button
                    mode="save-continue"
                    type="submit"
                    buttonref={saveRef!}
                  />
                ) : (
                  <Button mode="update" type="submit" />
                )}

                <Button mode="clear" type="button" onClick={handleClear} />
                <Button
                  mode="back"
                  type="button"
                  onClick={() => navigate(-1)}
                />
              </Form>
            );
          }}
        </Formik>
      </div>

      {/* department-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={departmentModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PayrollDepartment
              pageType={PageFor.MODAL}
              setModalFlag={setDepartmentModal}
              type={ComponentFor.MASTERS}
              LicenseType={LicenseTypes.PAYROLL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              className="modal-close-icon"
              src={Close}
              alt="/"
              onClick={() => setDepartmentModal(!departmentModal)}
            />
          </div>
        </div>
      </Modal>
      {/* designation-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={designationModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PayrollDesignation
              pageType={PageFor.MODAL}
              setModalFlag={setDesignationModal}
              type={ComponentFor.MASTERS}
              LicenseType={LicenseTypes.PAYROLL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              className="modal-close-icon"
              src={Close}
              alt="/"
              onClick={() => setDesignationModal(!designationModal)}
            />
          </div>
        </div>
      </Modal>
      {/* grade-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={gradeModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PayrollGrade
              pageType={PageFor.MODAL}
              setModalFlag={setGradeModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              className="modal-close-icon"
              src={Close}
              alt="/"
              onClick={() => setGradeModal(!gradeModal)}
            />
          </div>
        </div>
      </Modal>
      {/* category-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={categoryModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PayrollCategory
              pageType={PageFor.MODAL}
              setModalFlag={setCategoryModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              className="modal-close-icon"
              src={Close}
              alt="/"
              onClick={() => setCategoryModal(!categoryModal)}
            />
          </div>
        </div>
      </Modal>
      {/* jobType-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={jobTypeModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PayrollJobType
              pageType={PageFor.MODAL}
              setModalFlag={setJobTypeModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              className="modal-close-icon"
              src={Close}
              alt="/"
              onClick={() => setJobTypeModal(!jobTypeModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={creationLoading || updationLoading} />
      <MessageModal
        handleClose={handleClose}
        value={message.message}
        modalFlag={message.flag}
        operation={message.operation}
      />
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={empListModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <EmpList
              pageType={PageFor.MODAL}
              setModalFlag={setEmpListModal}
              queryType={empQueryTypes.GENERAL}
              type={ComponentForEmployee.AUTOCOMPLETE}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEmpListModal(!empListModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BasicDetails;
