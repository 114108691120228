import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { FormControlLabel, TextField } from "@mui/material";
import {
  Direction,
  Operation,
  ParentQueryType,
  ParentType,
  SortBy,
} from "../../../../utils/Enum.types";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";
import Modal from "react-modal";

import useParentsData, {
  ParentListEdge,
} from "../../../../customhooks/useParentsData";
import { Title } from "../../../../stories/Title/Title";
import { TitleProps } from "../../../../Types/Titles";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";

import Home from "../../Home/Index";
import { AntSwitch } from "../../../../pages/Switch";
import {
  EnableParentLoginStatusWithNewEmail,
  UpdateParentLoginStatus,
} from "../../../../queries/parent/mutations/new";
import { useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import Close from "../../../../images/Close.svg";

import { GetParents } from "../../../../queries/parent/list";
import MessageModal from "../../../../pages/MessageModal";
import { msgType } from "../../../../utils/Form.types";
import LoadingModal from "../../../../pages/LoadingModal";
import { InstitutionCustomStyles } from "../../../../styles/ModalStyles";
import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../utils/types";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DataGridTableStyles";
const { Parents } = require("../../../../json/title.json");

interface Prop {
  queryType: ParentQueryType;
  ParentName?: string;
}
export interface idType {
  relationship: ParentType;
  parent_id: number;
  name: string;
}

const ParentsList = ({ ParentName, queryType }: Prop) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const { Masters_Table } = useMasterTableJson();

  const [searchData, setSearchData] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [parentsList, setParentsList] = useState<ParentListEdge[]>([]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [eMail, setEmail] = useState("");
  const [parentId, setParentId] = useState(0);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const dataClasses = useDataGridStyles();

  const navigate = useNavigate();
  const [emailModal, setEmailModal] = useState(false);

  const { parentsData } = useParentsData(ROWS_PER_PAGE, queryType, searchData);

  const [EnableLogin, { loading: EnableLoading }] = useMutation(
    UpdateParentLoginStatus,
    {
      onError: (e) => {
        setEmailModal(!emailModal);
      },
    }
  );
  const [UpdateEmail, { loading }] = useMutation(
    EnableParentLoginStatusWithNewEmail,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleSubmit = (parent_id: number) => {
    const flagData = parentsData.data?.GetParents.edges.find(
      (data) => data.node.id === parent_id
    )?.node;
    EnableLogin({
      variables: {
        token,
        parent_id: parent_id,
        login_status: !flagData?.is_login_created,
        user_details,
      },
      refetchQueries: [
        {
          query: GetParents,
          variables: {
            after: null,
            direction: Direction.ASC,
            first: ROWS_PER_PAGE,
            inst_id: InstId!,
            name: "",
            sortBy: SortBy.FIRST_NAME,
            queryType,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: !flagData?.is_login_created
            ? "Parent Login Enabled Successfully"
            : "Parent Login Disabled Successfully",
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const handleUpdate = () => {
    UpdateEmail({
      variables: {
        token,
        parent_id: parentId,
        email: eMail,
        user_details,
      },
      refetchQueries: [
        {
          query: GetParents,
          variables: {
            after: null,
            direction: Direction.ASC,
            first: ROWS_PER_PAGE,
            inst_id: InstId!,
            name: "",
            sortBy: SortBy.FIRST_NAME,
            queryType,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Parent E-mail Updated Successfully",
          operation: Operation.UPDATE,
        });
        setEmailModal(!emailModal);
      }
    });
  };

  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const dynamicHeaders: TableHeaderProps[] =
    Masters_Table.Parents.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "is_login_created",
      cellClassName: "td-status ",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: "Login Status",
      renderCell: (params) => {
        const parentId = params.row.parent_id;

        return (
          <>
            <FormControlLabel
              label=""
              control={
                <AntSwitch
                  checked={params.row.is_login_created}
                  onClick={() => {
                    handleSubmit(parentId);
                    setParentId(parentId);
                  }}
                />
              }
              labelPlacement="start"
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (parentsData.data && !parentsData.loading) {
      const newData = parentsData.data.GetParents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = parentsList.find(
            (parent) => parent.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setParentsList(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,

            parent_name: node.first_name + node.middle_name + node.last_name,
            parent_type: node.parent_type,
            parent_mobile: node.parent_mobile,
            parent_id: node.id,

            parent_email: node.parent_email,
            is_login_created: node.is_login_created,
          }))
        );
      } else {
        setParentsList(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,

            parent_name: node.first_name + node.middle_name + node.last_name,
            parent_type: node.parent_type,
            parent_mobile: node.parent_mobile,

            parent_email: node.parent_email,
            parent_id: node.id,
            is_login_created: node.is_login_created,
          }))
        );
      }
      setEndCursor(parentsData.data.GetParents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [parentsData.data, parentsData.loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !parentsData.loading) {
          parentsData.fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetParents.edges;
              const pageInfo = fetchMoreResult.GetParents.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetParents.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetParents: {
                  edges: [...parentsList, ...newEdges],
                  pageInfo,
                  totalCount: parentsData.data
                    ? parentsData.data.GetParents.totalCount!
                    : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);
  useEffect(() => {
    if (ParentName) {
      setSearchData(ParentName);
    }
  }, [ParentName]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {Parents.Titles.map((title: TitleProps, index: React.Key) => {
          return <React.Fragment key={index}>{title.Login}</React.Fragment>;
        })}
      </Title>
      <div className="row g-0">
        <div className="col-2 button-left">
          <Input
            id="search"
            type="text"
            placeholder="Search ..."
            value={searchData}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchData(e.target.value);
            }}
          />
        </div>
        <div className="col"></div>
      </div>
      <div className={"container__list"}>
        <div className={`h100 ${dataClasses.root}`}>
          <DataGridPro
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            disableRowSelectionOnClick
            hideFooter
            slotProps={{
              columnsPanel: { disableHideAllButton: true },
            }}
          />
        </div>
      </div>

      <div className="button-left">
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={emailModal}
        style={InstitutionCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>
              {Parents.Titles.map((title: TitleProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>{title.Email}</React.Fragment>
                );
              })}
            </Title>
            <br></br>
            <div className="col">
              <TextField
                id="outlined-uncontrolled"
                className="submission-details__textfield"
                label="New E-mail"
                value={eMail}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setEmailModal(!emailModal)}
            />
          </div>
        </div>
        <Button type="submit" mode="save" onClick={handleUpdate} />

        <Button
          mode="cancel"
          type="button"
          onClick={() => {
            setEmailModal(!emailModal);
          }}
        />
      </Modal>
      <LoadingModal flag={loading || EnableLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default ParentsList;
