import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AddAcdSemesterYr } from "../../../../queries/institution/mutations/update";
import React, { useState } from "react";
import { msgType, responseType } from "../../../../utils/Form.types";
import { Button } from "../../../../stories/Button/Button";

import InstitutionTabs from "../../routes/InstitutionTabs";

import { Operation } from "../../../../utils/Enum.types";

import {
  Autocomplete,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { ListCategoryByInstId } from "../../../../queries/institution/categories/byId";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { Title } from "../../../../stories/Title/Title";

import LoadingModal from "../../../../pages/LoadingModal";
import useToken from "../../../../customhooks/useToken";
import {
  CategoryList,
  GetAcdSemesterYrsData,
  GetAcdSemesterYrsVars,
} from "../../../../Types/Student";
import { GetInstMasterData } from "../../../../queries/institution/masterdata";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import { Keys } from "../../../../utils/Enum.keys";
import useAcademicYears from "../../../Academics/hooks/useAcademicYears";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useDropdownData from "../../../../customhooks/useDropdownData";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { GetAcdSemesterYrs } from "../../../../queries/common";

const SemAcdConfig = () => {
  const { InstId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const textClasses = formAutoCompleteTextStyles();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [addModal, setAddModal] = useState(false);
  // eslint-disable-next-line
  const [item, setitems] = useState<CategoryList[]>([]);
  //flags for sucess model
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { academicYearResponse } = useAcademicYears();
  const [classSelected, setClassSelected] = useState<responseType | null>(null);

  const classes = formAutoCompleteStyles();

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    0
  );
  const [GetSemConfigData, { data: SemDropDown }] = useLazyQuery<
    GetAcdSemesterYrsData,
    GetAcdSemesterYrsVars
  >(GetAcdSemesterYrs);

  const [selectedSemesters, setSelectedSemesters] = useState<responseType[]>(
    []
  );
  const [movedSemesters, setMovedSemesters] = useState<responseType[]>([]);
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    semester: responseType
  ) => {
    if (event.target.checked) {
      setSelectedSemesters([...selectedSemesters, semester]);
    } else {
      setSelectedSemesters(
        selectedSemesters.filter((s) => s.value !== semester.value)
      );
    }
  };

  const handleMoveTo = () => {
    const newSemesters = selectedSemesters.filter(
      (sem) => !movedSemesters.some((movedSem) => movedSem.value === sem.value)
    );
    setMovedSemesters([...movedSemesters, ...newSemesters]);
    setSelectedSemesters([]);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedSemesters(semesterDropDown);
    } else {
      setSelectedSemesters([]);
    }
  };

  const { departmentLabel, branchLabel, classLabel } = useInstLabels();
  const { USE_DEPARTMENT_KEY, USE_BRANCH_KEY, USE_CLASS_KEY } =
    useInstitutionConfiguration();
  const nameRef = document.getElementsByName("cat_desc")[0] as HTMLInputElement;

  const [NewSemConfig, { loading: creationLoading }] = useMutation(
    AddAcdSemesterYr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const HandleNewSemCOnfig = () => {
    NewSemConfig({
      variables: {
        token,
        user_details,
        inst_id: InstId!,
        acd_yr_id: academicYearResponse.data?.GetAcdYrsByInstId[0].id,
        semester_ids: movedSemesters.map((data) => data.value),
        input: {
          acd_sem_st_date: toIsoDate(startDate),
          acd_sem_end_date: toIsoDate(endDate),
        },
      },
      refetchQueries: [
        {
          query: ListCategoryByInstId,
          variables: { token, inst_id: InstId! },
        },
        {
          query: GetInstMasterData,
          variables: {
            token,
            inst_id: InstId!,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Sem Configuration Created Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
const {semesterLabel}=useInstLabels()
  return (
    <>
      <InstitutionTabs />
 
      <div className="row g-0">
        <div className="col">
          <Title>{semesterLabel}</Title>
        </div>
      </div>

      <div className="masterconfig">

        <div className="row g-0 masterconfig__details frame-space">
          <div className="col h-100 booktype-left">
            <Title variant="subtitle1">Select {semesterLabel}</Title>
            <div className="sem-config">
            <div className="row g-0 sem-config__select">
   {USE_DEPARTMENT_KEY ? (
              <div className="col-3">
                <Autocomplete
                  classes={classes}
                  options={departmentDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, departmentSelected)
                  }
                  //   ref={departmentRef!}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER && departmentSelected) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setDepartmentSelected(null);
                    }
                  }}
                  openOnFocus
                  value={departmentSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setDepartmentSelected(newValue);
                    } else {
                      setDepartmentSelected(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label={departmentLabel}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}

            {USE_BRANCH_KEY ? (
              <div className="col-3">
                <Autocomplete
                  classes={classes}
                  options={branchDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, branchSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER && branchSelected) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setBranchSelected(null);
                    }
                  }}
                  openOnFocus
                  value={branchSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBranchSelected(newValue);
                    } else {
                      setBranchSelected(null);
                    }
                    // clearRestInstDetails(InstitutionDetails.BRANCH);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label={branchLabel}
                      id="outlined Branches"
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_CLASS_KEY ? (
              <div className="col-3">
                <Autocomplete
                  classes={classes}
                  options={classDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, classSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER && classSelected) {
                      e.preventDefault();
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setClassSelected(null);
                    }
                  }}
                  openOnFocus
                  value={classSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setClassSelected(newValue);
                    } else {
                      setClassSelected(null);
                    }
                    // clearRestInstDetails(InstitutionDetails.CLASS);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label={classLabel}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
   </div>
<div className="sem-config__tableblock">
<TableContainer className="sem-config__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedSemesters.length === semesterDropDown.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>{semesterLabel}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {semesterDropDown &&
                    semesterDropDown.map((res, index) => {
                      return (
                        <TableRow>
                          <TableCell key={index} id="td-center">
                            <Checkbox
                              checked={selectedSemesters.some(
                                (s) => s.value === res.value
                              )}
                              onChange={(e) => handleCheckboxChange(e, res)}
                            />{" "}
                          </TableCell>
                          <TableCell>{res.label}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
</div>

            </div>
   

            
          </div>
          <div className="col h-100 booktype-right">
          <Title variant="subtitle1">Moved {semesterLabel}</Title>
          <div className="sem-config">
            <div className="sem-config__select row g-0">
              <div className="col-3">   <TextField
              value={toInputStandardDate(startDate)}
              className="vacation-entry__block--textfield"
              // InputProps={{
              //   inputProps: {
              //     min: toInputStandardDate(TODAY_DATE),
              //     max: toInputStandardDate(
              //       academicYearResponse.data?.GetAcdYrsByInstId[0]
              //         .acd_end_date!
              //     ),
              //   },
              // }}
              type="date"
              label="Start Date"
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            /></div>
              <div className="col-3">
              <TextField
              className="vacation-entry__block--textfield"
              type="date"
              label="End Date"
              value={toInputStandardDate(endDate)}
              // InputProps={{
              //   inputProps: {
              //     min: toInputStandardDate(TODAY_DATE),
              //     max: toInputStandardDate(
              //       academicYearResponse.data?.GetAcdYrsByInstId[0]
              //         .acd_end_date!
              //     ),
              //   },
              // }}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
              </div>
            </div>

            <div className="sem-config__tableblock--right">
              <TableContainer className="sem-config__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Moved Semesters</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {movedSemesters.map((semester, index) => (
                      <TableRow key={index}>
                        <TableCell>{semester.label}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Button mode="save" onClick={HandleNewSemCOnfig} />


          </div>

         
          
         
          </div>
        </div>
        <div className="masterconfig__buttons">
          <Button mode="move-to" onClick={handleMoveTo} />
          <Button mode="addnew" onClick={() => setAddModal(!addModal)} />
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>

      <LoadingModal flag={creationLoading} />
      {/* <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      /> */}
    </>
  );
};

export default SemAcdConfig;
