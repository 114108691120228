import React, { useEffect, useState } from "react";
import Home from "../../../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Tab, Tabs, TextField } from "@mui/material";
import { TabPanel, a11yProps } from "../../../../../styles/Tabs";
import ClassWise from "./ClassWise/Index";

import {
  InstitutionConfigurationTypes,
  TestWiseReportPageType,
} from "../../../../../utils/Enum.types";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import useInstConfigByEntryId from "../../../hooks/useInstConfigByEntryId";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdTestNameByIdData,
  GetAcdTestTypeByIdData,
} from "../../../Test/TestCreation/Index";
import { singleNodeVars } from "../../../../../Types/Accounting";
import {
  GetAcdTestNameById,
  GetAcdTestTypeDetailsById,
} from "../../../queries/test/query";
import { EMPTY_STRING } from "../../../../../utils/constants";
import useToken from "../../../../../customhooks/useToken";
const ViewDetailedTestwiseReport = () => {
  const theme = useTheme();
  const [value, setValue] = useState(TestWiseReportPageType.CLASS_WISE);
  const { token } = useToken();
  const { testId, allotedID, testClassId } = useParams();

  const { branchLabel, classLabel } = useInstLabels();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const { InstConfigDetails } = useInstConfigByEntryId(Number(allotedID)!);

  const {
    acd_dept_desc,
    acd_branch_desc,
    acd_class_desc,
    acd_semester_desc,
    acd_section_desc,
  } = InstConfigDetails.data
    ? InstConfigDetails.data.GetAcdInstConfigNames
    : {
        acd_dept_desc: "",
        acd_branch_desc: "",
        acd_class_desc: "",
        acd_semester_desc: "",
        acd_section_desc: "",
      };
  const [GetTestTypeDetails, { data: TestName }] = useLazyQuery<
    GetAcdTestNameByIdData,
    singleNodeVars
  >(GetAcdTestNameById);
  useEffect(() => {
    if (testId && token) {
      GetTestTypeDetails({
        variables: { id: testId ? Number(testId) : 0, token },
      });
    }
  }, [testId, GetTestTypeDetails, token]);

  const [GetTestTypeMarks, { data: TestTypeDetails }] = useLazyQuery<
    GetAcdTestTypeByIdData,
    singleNodeVars
  >(GetAcdTestTypeDetailsById);

  useEffect(() => {
    if ((Number(testClassId) || testId) && testId) {
      GetTestTypeMarks({
        variables: {
          id: testClassId ? Number(testClassId) : Number(testId),
          token,
        },
      });
    }
  }, [testClassId, GetTestTypeMarks, token, testId]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Test Wise Toppers List</Title>
      <div className="test-wise-detailed-report">
        <div className="test-wise-detailed-report__select row g-0">
          <div className="col-2">
            <TextField
              disabled
              className="test-wise-view__multiple--select--textfield"
              label={branchLabel}
              InputLabelProps={{ shrink: true }}
              value={acd_branch_desc}
            />
          </div>
          <div className="col-2">
            <TextField
              disabled
              className="test-wise-view__multiple--select--textfield"
              label={classLabel}
              InputLabelProps={{ shrink: true }}
              value={acd_class_desc}
            />
          </div>
        </div>
        <div className="test-wise-detailed-report__title">
          <b>{TestName ? TestName.node.test_name : EMPTY_STRING}</b>
          <span>
            {" "}
            (
            {TestTypeDetails
              ? TestTypeDetails.node.test_type_name
              : EMPTY_STRING}
            )
          </span>
        </div>
        <div className="test-wise-detailed-report__tabs">
          <Tabs
            value={value}
            onChange={(event: React.SyntheticEvent, newValue: number) =>
              setValue(newValue)
            }
          >
            <Tab
              label="Class wise"
              value={TestWiseReportPageType.CLASS_WISE}
              {...a11yProps(TestWiseReportPageType.CLASS_WISE)}
            />

            <Tab
              label="Subject wise"
              value={TestWiseReportPageType.SUB_WISE}
              {...a11yProps(TestWiseReportPageType.SUB_WISE)}
            />
          </Tabs>
        </div>
        <div className="test-wise-detailed-report__tabpanel">
          <TabPanel
            value={value}
            index={TestWiseReportPageType.CLASS_WISE}
            dir={theme.direction}
          >
            <ClassWise pageType={TestWiseReportPageType.CLASS_WISE} />
          </TabPanel>
          <TabPanel
            value={value}
            index={TestWiseReportPageType.SUB_WISE}
            dir={theme.direction}
          >
            <ClassWise pageType={TestWiseReportPageType.SUB_WISE} />
          </TabPanel>
        </div>
      </div>
    </>
  );
};

export default ViewDetailedTestwiseReport;
