import { gql } from "@apollo/client";

export const AddPayRollJustLoginTeacher = gql`
  mutation AddPayRollJustLoginTeacher(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: JustLoginAcctInput!
  ) {
    AddPayRollJustLoginTeacher(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;
export const UpdatePayRollJustLoginTeacher = gql`
  mutation UpdatePayRollJustLoginTeacher(
    $token: String!
    $pr_emp_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: JustLoginAcctInput!
  ) {
    UpdatePayRollJustLoginTeacher(
      token: $token
      pr_emp_id: $pr_emp_id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
