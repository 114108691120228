import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import { Button } from "../../../../stories/Button/Button";
import { DEFAULT_TIME, EMPTY_STRING } from "../../../../utils/constants";
import {
  LicenseTypes,
  Operation,
  PredefinedDataTypes,
} from "../../../../utils/Enum.types";
import { msgType, optionsType } from "../../../../utils/Form.types";
import {
  DateRange,
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { useMutation } from "@apollo/client";
import { AddAcdInstGeneralHolidays } from "../../queries/holidays/mutation";
import MessageModal from "../../../../pages/MessageModal";
import { Title } from "../../../../stories/Title/Title";
import { AddPayRollGeneralHolidays } from "../../../HR/queries/holidays/mutations";
import { PayRollAcademicYearList } from "../../../HR/hooks/usePayRollAcademicYears";
import { ActiveAcademicYearData } from "../../../../Types/Accounting";
import { ModalType } from "./Index";
import { GetAcdInstGeneralHolidays } from "../../queries/holidays/list";
import { holiday_query_type } from "../../hooks/useAcdGeneralHolidays";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

interface Props {
  date: Date;
  moduleType: LicenseTypes.PAYROLL | LicenseTypes.ACADEMIC;
  setInsertModal: React.Dispatch<React.SetStateAction<ModalType>>;
  prAcdYearId?: PayRollAcademicYearList | null;
  acdYearId?: ActiveAcademicYearData;
}

const DateWiseHolidayEntry = ({
  date,
  setInsertModal,
  moduleType,
  prAcdYearId,
  acdYearId,
}: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { firstDay, lastDay } = DateRange(date.toString()) || {};
  const [holidayType, setHolidayType] = useState<optionsType | null>(null);

  const [holiday_desc, setHolidayDesc] = useState("");
  const [holidayDuration, setHolidayDuration] = useState<optionsType | null>(
    null
  );

  const { PredefinedData: HolidayDurartion } = usePredefinedDataByType(
    PredefinedDataTypes.HOLIDAY_FULL_HALF_DAY,
    EMPTY_STRING
  );
  const { PredefinedData: HolidayType } = usePredefinedDataByType(
    PredefinedDataTypes.HOLIDAY_TYPE,
    EMPTY_STRING
  );

  const [AddHoliday] = useMutation(AddAcdInstGeneralHolidays, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [AddPayRollHolidays] = useMutation(AddPayRollGeneralHolidays, {
    onError: (e) =>
      setMessage({
        operation: Operation.NONE,
        message: e.message,
        flag: true,
      }),
  });
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
    setHolidayDesc("");
    setHolidayDuration(null);
    setHolidayType(null);
    setInsertModal((prev) => ({
      ...prev,
      flag: false,
    }));
  };
  const handleHoliday = () => {
    if (moduleType === LicenseTypes.ACADEMIC) {
      AddHoliday({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          acd_yr_id: acdYearId?.id,
          input: {
            holiday_type: holidayType?.value,
            holiday_duration: holidayDuration?.value,
            holiday_desc,
            week_idx: 256,
            day_idx: 256,
            holiday_date: toIsoDate(date.toString()),
          },
        },
        refetchQueries: [
          {
            query: GetAcdInstGeneralHolidays,
            variables: {
              acd_yr_id: acdYearId?.id,
              input: {
                end_date: toIsoDate(lastDay!),
                in_between_flag: true,
                start_date: toIsoDate(firstDay!),
                holiday_query_type: holiday_query_type.ALL_HOLIDAYS,
              },
              inst_id: InstId,
              token,
              holidayDesc: EMPTY_STRING,
            },
          },
          {
            query: GetAcdInstGeneralHolidays,
            variables: {
              acd_yr_id: acdYearId?.id,
              input: {
                end_date: toIsoDate(lastDay!),
                in_between_flag: true,
                start_date: toIsoDate(firstDay!),
                holiday_query_type:
                  holiday_query_type.GENERAL_AND_UNEXPECTED_HOLIDAYS,
              },
              inst_id: InstId,
              token,
              holidayDesc: EMPTY_STRING,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: `Holiday for Date ${toStandardDate(
              date.toString()
            )} created`,
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      AddPayRollHolidays({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          pr_acd_yr_id: prAcdYearId?.id!,
          input: {
            holiday_type: holidayType?.value,
            holiday_duration: holidayDuration?.value,
            holiday_desc,
            week_idx: 256,
            day_idx: 256,
            holiday_date: toIsoDate(date.toString()),
          },
        },
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: `Holiday for Date ${toStandardDate(
              date.toString()
            )} created`,
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  return (
    <>
      <Title>Add Holidays</Title>
      <div className="holiday-entry__add">
        <TextField
          label="Holiday Date"
          type="date"
          disabled
          value={toInputStandardDate(date.toString())}
          InputLabelProps={{ shrink: true }}
          className="holiday-entry__add--textfield--date"
        />

        <TextField
          label="Holiday Description"
          type="text"
          value={holiday_desc}
          onChange={(e) => setHolidayDesc(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <div className="holiday-entry__add--select-options">
          <Autocomplete
            classes={classes}
            options={HolidayDurartion.dropDown}
            value={holidayDuration}
            isOptionEqualToValue={(option) =>
              isOptionEqualToValue(option, holidayDuration)
            }
            onChange={(e, newValue) => {
              if (newValue) {
                setHolidayDuration(newValue);
              } else {
                setHolidayDuration(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Holiday Duration"
                InputLabelProps={{ shrink: true }}
                classes={{ root: textClasses.formControlRoot }}
              />
            )}
          />
        </div>
        <div className="holiday-entry__add--select-options">
          <Autocomplete
            classes={classes}
            options={HolidayType.dropDown}
            value={holidayType}
            isOptionEqualToValue={(option) =>
              isOptionEqualToValue(option, holidayType)
            }
            onChange={(e, newValue) => {
              if (newValue) {
                setHolidayType(newValue);
              } else {
                setHolidayType(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Holiday Type"
                InputLabelProps={{ shrink: true }}
                classes={{ root: textClasses.formControlRoot }}
              />
            )}
          />
        </div>
        <Button
          mode="save"
          disabled={!holiday_desc || !holidayDuration || !holidayType}
          onClick={handleHoliday}
        />
        <Button
          mode="cancel"
          onClick={() =>
            setInsertModal({
              date: new Date(DEFAULT_TIME),
              flag: false,
              operation: Operation.NONE,
            })
          }
        />
      </div>

      <MessageModal
        modalFlag={message!.flag!}
        value={message!.message!}
        handleClose={handleClose}
        operation={message!.operation!}
      />
    </>
  );
};

export default DateWiseHolidayEntry;
