import React from "react";
import Home from "../../../../Home/Index";
import { Title } from "../../../../../../stories/Title/Title";
import InstDetails from "../../../../DailyActivities/InstDetails";
import RightArrow from "../../../../../../images/ArrowRight.svg";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../../../styles/AutocompleteListStyles";
import { Button } from "../../../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import { TableHeaders } from "../../../../../../utils/Enum.types";
const CombinationWise = () => {
  const navigate = useNavigate();
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Acdemics Report</Title>
      <div className="combination-report-view">
        <InstDetails />

        <div className="combination-report__back">
          <img src={RightArrow} alt="/" />
          Go back to Combination Wise
        </div>
        <div className="combination-report__title">Third Internals (Test)</div>
        <div className="combination-report-view__select row g-0">
          <div className="col-3">
            <Autocomplete
              classes={classes}
              options={["Combination Major", "Combination Minor"]}
              openOnFocus
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  classes={{ root: textClasses.formControlRoot }}
                  label="Combination Name"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />{" "}
          </div>
          <div className="col"></div>
          <div className="col-3">
            <b>Combination Major</b>
          </div>
          <div className="col"></div>
          <div className="col-3">
            <div className="combination-report-view__select--flex">
              <TextField
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                disabled
                className="combination-report-view__select--textfield"
              />
              <TextField
                label="End Date"
                InputLabelProps={{ shrink: true }}
                disabled
                className="combination-report-view__select--textfield"
              />
            </div>
          </div>
        </div>

        <div className="row g-0">
          <div className="col">
            <div className="acd-reports__per-test--cards">
              <div className="acd-reports__per-test--card">
                <span>Total Students</span>
                <b className="font-green">0 </b>
              </div>
              <div className="acd-reports__per-test--card">
                <span>Present</span>
                <b className="font-green">0 </b>
              </div>
              <div className="acd-reports__per-test--card">
                <span>Absent</span>
                <b className="font-red">0 </b>
              </div>
            </div>
          </div>
          <div className="col-2 acd-reports__per-test--button">
            <button
              onClick={() => {
                // setViewSubjects(!viewSubjects);
                // dispatch({
                //   type: payloadTypes.SET_TEST_CONDUCT_ID,
                //   payload: {
                //     testConductId: Number(testConductId),
                //   },
                // });
              }}
            >
              View Test/Exam Dates
            </button>
          </div>
        </div>
        <div className="combination-report-view__tableblock">
          <TableContainer className="combination-report-view__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2}>{TableHeaders.SLNO}</TableCell>
                  <TableCell rowSpan={2}>
                    {TableHeaders.ADMISSION_NUMBER}
                  </TableCell>
                  <TableCell rowSpan={2}>
                    {TableHeaders.REGISTER_NUMBER}
                  </TableCell>
                  <TableCell rowSpan={2}>{TableHeaders.STUDENT_NAME}</TableCell>
                  <TableCell colSpan={2}>English</TableCell>
                  <TableCell colSpan={2}>{TableHeaders.TOTAL}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell >25</TableCell>
                  <TableCell>09</TableCell>
                  <TableCell>125</TableCell>
                  <TableCell>45</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="combination-report-view__table--slno" id="td-center">1</TableCell>
                  <TableCell className="combination-report-view__table--admno">STD001</TableCell>
                  <TableCell className="combination-report-view__table--admno">1SJ20C01</TableCell>
                  <TableCell>Anjaneya</TableCell>
                  <TableCell id="td-center" colSpan={2} className="combination-report-view__table--marks">20</TableCell>
                  <TableCell colSpan={2} className="totalcount combination-report-view__table--marks" align="center">20</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default CombinationWise;
