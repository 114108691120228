import React, { useState } from "react";
import { Label } from "../../../stories/Label/Label";
import Input from "../../../stories/Input/Input";
import { Autocomplete, TextField } from "@mui/material";
import {
  formAutoCompleteTextStyles,
  requiredAutoCompleteStyles,
} from "../../../styles/AutocompleteStyles";
import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import {
  PageFor,
  PredefinedDataTypes,
  ReturnType,
} from "../../../utils/Enum.types";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../utils/constants";
import { msgType, optionsType, responseType } from "../../../utils/Form.types";
import { Keys } from "../../../utils/Enum.keys";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../utils/UtilFunctions";
import useAcctLedgerData from "../hooks/useAcctLedgerData";
import { AcctLedgerQueryType } from "../common/QueryTypes";
import { recepitsw } from "../StudentDetails/FeeReceipt/Index";
import TextArea from "../../../stories/TextArea/TextArea";
import { Button } from "../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";

interface Refs {
  saveButtonRef: React.RefObject<HTMLButtonElement>;
  feeDescRef: React.RefObject<HTMLSelectElement>;
}
interface setStatesProp {
  setBankTransactionType: React.Dispatch<
    React.SetStateAction<optionsType | null>
  >;
  setTransactionLedgerId: React.Dispatch<
    React.SetStateAction<responseType | null>
  >;
  setNarration: React.Dispatch<React.SetStateAction<string>>;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setReceipts: React.Dispatch<React.SetStateAction<recepitsw[]>>;
}

interface methodsProp {
  handleClear: () => void;
  handleStudentReceipts: (e: React.FormEvent) => void;
}
interface statesProp {
  bankTransactionType: optionsType | null;
  transactionBookLedgerId: responseType | null;
  narration: string;
  amount: number;
  GenerateStudentReceiptsLoading: boolean;
  message: msgType;
  vo_number: string;
}
interface Props {
  refs: Refs;
  pageType: PageFor;
  setStates: setStatesProp;
  states: statesProp;
  methods: methodsProp;
  uploadComponent: JSX.Element;
}
const AddReconciledForm = ({
  refs,
  pageType,
  setStates,
  states,
  methods,
  uploadComponent,
}: Props) => {
  const classes = requiredAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();

  const navigate = useNavigate();
  const {
    setBankTransactionType,
    setModalFlag,
    setNarration,
    setTransactionLedgerId,
    setReceipts,
  } = setStates;

  const { handleClear, handleStudentReceipts } = methods;

  const {
    bankTransactionType,
    narration,
    transactionBookLedgerId,
    GenerateStudentReceiptsLoading,
    amount,
    message,
    vo_number,
  } = states;
  const {
    PredefinedData: { dropDown: bankTransactionTypes },
  } = usePredefinedDataByType(
    PredefinedDataTypes.BANK_COLLECTION,
    EMPTY_STRING,
    ReturnType.WITH_ID
  );
  const { acctLedgers: BankLedgers } = useAcctLedgerData(
    EMPTY_STRING,
    AcctLedgerQueryType.ACCT_LDGRS_BANK,
    ROWS_PER_PAGE
  );
  const [referenceNumber, setReferenceNumber] = useState("");

  return (
    <form onSubmit={handleStudentReceipts}>
      <div className="unknown-bank-trans__add--receiptblock row g-0">
        <div className="col unknown-bank-trans__add--receiptblock--frame">
          <div className="unknown-bank-trans__add--receiptblock--frame--label-grid">
            <Label>Receipt No.</Label>
            <Input disabled value={vo_number} />
          </div>
        </div>
        <div className="col unknown-bank-trans__add--receiptblock--frame">
          <div className="unknown-bank-trans__add--receiptblock--frame--label-grid">
            <Label>Bank Transaction Type</Label>
            <Autocomplete
              classes={classes}
              options={bankTransactionTypes}
              value={bankTransactionType}
              ref={refs.feeDescRef}
              isOptionEqualToValue={isOptionEqualToValue}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBankTransactionType(newValue as optionsType);
                } else {
                  setBankTransactionType(null);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setBankTransactionType(null);
                }
              }}
              openOnFocus
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
            <Label>Bank Ledger</Label>
            <Autocomplete
              classes={classes}
              options={BankLedgers.responseType!}
              openOnFocus
              autoHighlight
              isOptionEqualToValue={isOptionEqualToValue}
              value={transactionBookLedgerId}
              onChange={(e, newValue) => {
                if (newValue) {
                  setTransactionLedgerId(newValue as responseType);
                  setReceipts([]);
                } else {
                  setTransactionLedgerId(null);
                  setReceipts([]);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (
                  transactionBookLedgerId &&
                  transactionBookLedgerId.value &&
                  e.key === Keys.ENTER
                ) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setTransactionLedgerId(null);
                }
              }}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
            <Label>Transaction Ref No.</Label>
            <Input
              required
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) handleMUISelectEvent(e);
              }}
              value={referenceNumber}
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setReferenceNumber(e.target.value)
              }
            />
          </div>
        </div>
        <div className="col unknown-bank-trans__add--receiptblock--frame">
          <div className="label-grid">
            <Label>Remarks</Label>
            <TextArea
              rows={3}
              onChange={(e) => setNarration(e.target.value)}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  refs.saveButtonRef.current?.focus();
                }
              }}
              value={narration}
            />
          </div>
          {uploadComponent}
        </div>
      </div>
      <Button
        mode="save"
        buttonref={refs.saveButtonRef}
        disabled={!amount || message.flag || GenerateStudentReceiptsLoading}
      />
      <Button mode="clear" onClick={handleClear} />
      {pageType === PageFor.GENERAL ? (
        <Button mode="back" onClick={() => navigate(-1)} />
      ) : (
        <Button
          mode="cancel"
          onClick={() => {
            setModalFlag(false);
          }}
        />
      )}
    </form>
  );
};

export default AddReconciledForm;
