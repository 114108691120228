import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import Department from "../../../images/OrganizationDepartment.svg";
import Institution from "../../../images/OrganizationInstitution.svg";
import Designation from "../../../images/OrganizationDesignation.svg";
import DownArrow from "../../../images/DownArrow.svg";
import Add from "../../../images/AddOrganization.svg";
import { Button } from "../../../stories/Button/Button";
import { useStyles } from "../../../styles/TooltipStyles";
import Delete from "../../../images/Delete.svg";

import { Operation } from "../../../utils/Enum.types";
import { PayRollMasters } from "../enums/Enum.types";

import AddDrawer from "./AddDrawer";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router";
import { GetPayRollInstOrgChart } from "../queries/organizationchart/list";
import {
  AddPayRollInstOrgChart,
  DeletePayRollInstOrgChartById,
} from "../queries/organizationchart/mutations";
import { msgType, responseType } from "../../../utils/Form.types";
import DeleteModal from "../../../pages/DeleteModal";
import MessageModal from "../../../pages/MessageModal";
import { toStandardCase } from "../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";

export interface ModalFlagType {
  type: PayRollMasters.DEPARTMENT | PayRollMasters.DESIGNATION;
  flag: boolean;
}
export interface DrawerFlagType {
  type: PayRollMasters.DEPARTMENT | PayRollMasters.DESIGNATION;
  parent_id: number;
  flag: boolean;
  filterId: number;
}

export interface ChartType {
  id: number;
  pr_dept_id: number;
  pr_designation_id: number;
  parent_id: number;
  child_count: number;
  name_desc: string;
  child_nodes: ChartType[];
  is_open: boolean;
  option_dept: boolean;
  option_designation: boolean;
}
export interface PayRollInstOrgChart {
  org_chart_details: {
    id: number;
    pr_dept_id: number;
    pr_designation_id: number;
    parent_id: number;
    name_desc: string;
    child_count: number;
  };
  option_dept: boolean;
  option_designation: boolean;
}
export interface GetPayRollInstOrgChartData {
  GetPayRollInstOrgChart: PayRollInstOrgChart[];
}
export interface GetPayRollInstOrgChartVars {
  token: string;
  inst_id: string;
  parent_id: number;
}
const TreeView = () => {
  const { user_details } = useLoggedInUserDetails();
  const [pageRenderFlag, setPageRenderFlag] = useState(false);
  const { token } = useToken();
  const { InstId } = useParams();
  const classes = useStyles();
  const [openGraph, setOpenGraph] = useState(false);
  const [parentId, setParentId] = useState(0);
  const [addDrawer, setDrawer] = useState<DrawerFlagType>({
    type: PayRollMasters.DEPARTMENT,
    parent_id: 0,
    filterId: 0,
    flag: false,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [createOrganization] = useMutation(AddPayRollInstOrgChart);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [chart, setChart] = useState<ChartType[]>([]);

  const handleCreateOrg = (id: responseType) => {
    createOrganization({
      variables: {
        token,
        inst_id: InstId,
        user_details,

        parent_id: addDrawer.parent_id ?? 0,
        input: {
          pr_dept_id:
            addDrawer.type === PayRollMasters.DEPARTMENT && id ? id.value : 0,
          pr_designation_id:
            addDrawer.type === PayRollMasters.DESIGNATION && id ? id.value : 0,
          name_desc: id?.label,
        },
      },
      refetchQueries: [
        {
          query: GetPayRollInstOrgChart,
          variables: {
            inst_id: InstId,
            parent_id: addDrawer.parent_id,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        const chartDetails: ChartType = {
          ...data.AddPayRollInstOrgChart.org_chart_details,
          child_nodes: [],
          is_open: false,
          option_dept: data.AddPayRollInstOrgChart.option_dept,
          option_designation: data.AddPayRollInstOrgChart.option_designation,
        };
        if (addDrawer.parent_id) {
          setChart(
            chart.map((chart) =>
              addBranch(chart, addDrawer.parent_id, chartDetails)
            )
          );
        } else {
          setChart([...chart, chartDetails]);
        }
        setMessage({
          flag: true,
          message: `Successfully Added ${toStandardCase(addDrawer.type)}`,
          operation: Operation.CREATE,
        });
        setDrawer({
          type: PayRollMasters.DEPARTMENT,
          parent_id: -1,
          flag: false,
          filterId: 0,
        });
      }
    });
  };
  const [GetChart] = useLazyQuery<
    GetPayRollInstOrgChartData,
    GetPayRollInstOrgChartVars
  >(GetPayRollInstOrgChart);
  const [DeleteOrgChart] = useMutation(DeletePayRollInstOrgChartById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const handleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteOrgChart({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetPayRollInstOrgChart,
          variables: { token, parent_id: id, inst_id: InstId },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        let updatedData = chart;
        if (chart.find(({ id: mapId }) => mapId === id)) {
          updatedData = chart.filter(({ id: mapId }) => mapId !== id);
        } else {
          updatedData = chart.map((chart) => {
            return deletedBranch(chart, id)!;
          });
        }

        updatedData = updatedData.map((chart) => {
          return changeCount(chart, parentId);
        });

        setChart(updatedData);
        setMessage({
          message: "Deleted Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };

  const getFirstChild = () => {
    if (token) {
      if (!openGraph) {
        GetChart({
          variables: { inst_id: InstId!, token, parent_id: 0 },
          fetchPolicy: "network-only",
        }).then(({ data }) => {
          if (data) {
            if (data.GetPayRollInstOrgChart.length > 0) {
              setChart(
                data.GetPayRollInstOrgChart.map(
                  ({ org_chart_details, option_dept, option_designation }) => ({
                    ...org_chart_details,
                    child_nodes: [],
                    is_open: false,
                    option_dept,
                    option_designation,
                  })
                )
              );
              setOpenGraph(!openGraph);
            } else {
              setMessage({
                flag: true,
                message: "Child Nodes not present",
                operation: Operation.NONE,
              });
            }
          }
        });
      } else {
        setOpenGraph(!openGraph);
      }
    }
  };

  const getChild = (nodeDetails: ChartType) => {
    if (nodeDetails.is_open) {
      nodeDetails.is_open = false;
      setPageRenderFlag(!pageRenderFlag);
    } else {
      GetChart({
        variables: { inst_id: InstId!, token, parent_id: nodeDetails.id },
        fetchPolicy: "network-only",
      }).then(({ data }) => {
        if (data) {
          nodeDetails.child_nodes = data.GetPayRollInstOrgChart.map(
            ({ org_chart_details, option_dept, option_designation }) => ({
              ...org_chart_details,
              option_dept,
              option_designation,
              child_nodes: [],
              is_open: false,
            })
          );
          nodeDetails.is_open = true;
          setPageRenderFlag(!pageRenderFlag);
        }
      });
    }
  };
  const AddTooltip = (parent_id: number, dept: boolean, id: number) => {
    return (
      <ul className="organizational-chart__tree-view--menu">
        {dept && (
          <li
            onClick={() =>
              setDrawer({
                flag: true,
                type: PayRollMasters.DEPARTMENT,
                parent_id: parent_id,
                filterId: id,
              })
            }
          >
            Add Department
          </li>
        )}
        <li
          onClick={() =>
            setDrawer({
              flag: true,
              type: PayRollMasters.DESIGNATION,
              parent_id: parent_id,
              filterId: id,
            })
          }
        >
          Add Designation
        </li>
      </ul>
    );
  };
  const getImage = (type: string) => {
    switch (type) {
      case "inst":
        return `${Institution}`;
      case "dept":
        return `${Department}`;
      case "desgn":
        return `${Designation}`;
      default:
        break;
    }
  };
  // const deleteBranch = (branchId: number) => {
  //   // Base case: If the current node's ID matches the branch ID, delete the branch
  //   let deletedChart = chart;
  //   const found = chart?.find(({ id }) => id === branchId);
  //   if (found) {
  //     deletedChart = chart.filter(({ id }) => id === branchId);
  //   } else {
  //   }
  // };

  const addBranch = (
    tree: ChartType,
    branchId: number,
    data: ChartType
  ): ChartType => {
    if (tree.id === branchId) {
      return {
        ...tree,
        child_nodes: [...(tree.child_nodes ? tree.child_nodes : []), data],
        child_count: [...(tree.child_nodes ? tree.child_nodes : []), data]
          .length,
      }; // Returning null will remove the branch
    }
    // Recursive case: Traverse through the children and delete the branch from each child
    const updatedChildren = tree.child_nodes.map((child) =>
      addBranch(child, branchId, data)
    );

    // Update the tree with the modified children array
    return {
      ...tree,
      child_nodes: updatedChildren, // Filter out null values (deleted branches)
      child_count: updatedChildren.length,
    };
  };
  const changeCount = (tree: ChartType, branchId: number): ChartType => {
    if (tree.id === branchId) {
      return {
        ...tree,
        child_count: tree.child_nodes ? tree.child_nodes.length : 0,
      }; // Returning null will remove the branch
    }
    // Recursive case: Traverse through the children and delete the branch from each child
    const updatedChildren = tree.child_nodes?.map((child) =>
      changeCount(child, branchId)
    );

    // Update the tree with the modified children array
    return {
      ...tree,
      child_nodes: updatedChildren ?? [],
    };
  };

  const deletedBranch = (
    tree: ChartType,
    branchId: number
  ): ChartType | null => {
    if (tree.id === branchId) {
      return null; // Returning null will remove the branch
    }
    // Recursive case: Traverse through the children and delete the branch from each child
    const updatedChildren = tree.child_nodes
      ? tree.child_nodes.map((child) => deletedBranch(child, branchId)!)
      : [];

    // Update the tree with the modified children array
    return {
      ...tree,
      child_nodes: updatedChildren.filter(Boolean), // Filter out null values (deleted branches)
    };
  };

  const jsxElm = (resArray: (ChartType | null)[]) => {
    return resArray?.map((desgn: ChartType | null, index: number) => {
      if (desgn) {
        return (
          <ol className="organizational-chart__tree-view--ol--level-2">
            <React.Fragment key={index}>
              <li className="organizational-chart__tree-view--level-2">
                <div>
                  <img
                    src={getImage(desgn.pr_dept_id ? "dept" : "desgn")}
                    alt=""
                  />
                  {desgn.name_desc}
                </div>
                <div>
                  <img
                    src={Delete}
                    onClick={() => {
                      if (desgn.child_count) {
                        setMessage({
                          flag: true,
                          message: `Cannot delete ${desgn.name_desc} as it contains children`,
                          operation: Operation.NONE,
                        });
                      } else {
                        setDeleteId(desgn.id);
                        setParentId(desgn.parent_id);
                        setDeleteModal(!deleteModal);
                      }
                    }}
                    alt=""
                    className="organizational-chart__tree-view--delete-image"
                  />
                  <Tooltip
                    classes={{
                      tooltip: classes.customTooltipOrganization,
                    }}
                    placement="bottom"
                    title={AddTooltip(
                      desgn.id,
                      desgn.option_dept,
                      desgn.pr_dept_id
                        ? desgn.pr_dept_id
                        : desgn.pr_designation_id
                    )}
                  >
                    <img
                      src={Add}
                      alt=""
                      className="organizational-chart__tree-view--add-image"
                    />
                  </Tooltip>

                  {desgn.child_count ? (
                    <img
                      src={DownArrow}
                      alt=""
                      onClick={() => desgn && getChild(desgn)}
                      className={desgn.is_open ? "rotate-img" : ""}
                    />
                  ) : null}
                </div>
              </li>
              {desgn.is_open && desgn.child_nodes.length
                ? jsxElm(desgn.child_nodes)
                : null}
            </React.Fragment>
          </ol>
        );
      } else {
        return null;
      }
    });
  };
  const hanldeClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <div className="organizational-chart__tree-view">
        <ol className="organizational-chart__tree-view--ol--level-1">
          <React.Fragment>
            <li className="organizational-chart__tree-view--level-1">
              <div>
                <img src={Institution} alt="" />
                Inst1
              </div>
              <div className="organizational-chart__tree-view--level-1--images">
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltipOrganization,
                  }}
                  placement="bottom"
                  title={AddTooltip(0, true, 0)}
                >
                  <img
                    src={Add}
                    alt=""
                    className="organizational-chart__tree-view--add-image"
                  />
                </Tooltip>

                <img
                  src={DownArrow}
                  alt=""
                  onClick={() => getFirstChild()}
                  className={openGraph ? "rotate-img" : ""}
                />
              </div>
            </li>
            {openGraph ? jsxElm(chart) : null}
          </React.Fragment>
        </ol>
      </div>
      <Button mode="back" />
      <AddDrawer
        drawerFlag={addDrawer}
        setDrawerFlag={setDrawer}
        handleCreateOrg={handleCreateOrg}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDelete}
        id={deleteId}
      />
      <MessageModal
        value={message.message}
        handleClose={hanldeClose}
        modalFlag={message.flag}
        operation={message.operation}
      />
    </>
  );
};

export default TreeView;
