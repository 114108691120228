import React, { useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";
import TextArea from "../../../stories/TextArea/TextArea";

import { Button } from "../../../stories/Button/Button";
import Close from "../../../images/Close.svg";
import {
  InstitutionConfigurationTypes,
  Operation,
} from "../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddAcdLessonPlannerDetails,
  UpdateAcdLessonPlannerDetails,
} from "../queries/lesson_planner/mutation";
import {
  AddAcdLessonPlannerDetailsData,
  AddAcdLessonPlannerDetailsVars,
  UpdateAcdLessonPlannerDetailsData,
  UpdateAcdLessonPlannerDetailsVars,
} from "../types/lessonplanner/mutations";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useInstConfigByEntryId from "../hooks/useInstConfigByEntryId";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { EMPTY_STRING, emptyMessageType } from "../../../utils/constants";
import DownArrow from "../../../images/DownArrow.svg";
import {
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  removeMoreSpace,
} from "../../../utils/UtilFunctions";
import MessageModal from "../../../pages/MessageModal";
import { defaultEditSubChapter, editSubChapterType } from "./List";
import { GetAcdLessonDetailsByNodeId } from "../queries/lesson_planner/list/byNode";
import { CommonNodeVars } from "../../HR/Types/masterDataTypes";
import { GetAcdLessonPlannerMain } from "../queries/lesson_planner/list";
import { SubjectFormData, SubjectType } from "../types/subject/Subject";
import Time from "../../../components/common/Time/Index";
import { FormType, SubTopicForm, optionsType } from "../../../utils/Form.types";
import { GetSwConfigReferenceDataByKey } from "../../../queries/institution/configuration/query/SoftwreConfig";
import { SoftwareConfigList, SoftwareVars } from "../../../Types/configtypes";
import { Autocomplete, TextField } from "@mui/material";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import { Keys } from "../../../utils/Enum.keys";
import { AcdLessonPlannerDetailsNode } from "../types/lessonplanner/queries";
const { AcademicsDetails } = require("../json/formLabels.json");
interface Props {
  setDrawer: React.Dispatch<React.SetStateAction<editSubChapterType>>;
  details: editSubChapterType;
  selectedSubject: SubjectType | null;
}

const CreateSubChapter = ({
  setDrawer,
  details: { lesson_main_id, operation, sub_topic_id },
  selectedSubject,
}: Props) => {
  const { token } = useToken();
  const { InstId, entryId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const {
    InstConfigDetails: { alloted },
  } = useInstConfigByEntryId(Number(entryId));

  const [formData, setFormData] = useState<SubTopicForm>({
    sub_topic_name: "",
    classroom_process: "",
    learning_objectives: "",
    resources_required: "",
    teacher_reflection: "",
    techniques: "",
  });

  const [hrs, setHrs] = useState("");

  const [mins, setMinutes] = useState("");

  const [message, setMessage] = useState(emptyMessageType);

  const [fiveEOptions, set5EOptions] = useState<optionsType[]>([]);

  const [selected5E, setSelected5E] = useState<optionsType | null>(null);

  const textClasses = formAutoCompleteTextStyles();
  const classes = formAutoCompleteStyles();

  const [GetConfigData, { data: configData }] = useLazyQuery<
    SoftwareConfigList,
    SoftwareVars
  >(GetSwConfigReferenceDataByKey, {
    variables: {
      token,
      config_key: InstitutionConfigurationTypes.FIVE_ES,
      inst_id: InstId!,
      page_number: 0,
    },
  });

  useEffect(() => {
    if (token) {
      GetConfigData().then(({ data }) => {
        if (data && data.GetSwConfigReferenceDataByKey) {
          const { list_item_list } = data.GetSwConfigReferenceDataByKey;
          const separatedItems = list_item_list
            .split(";")
            .filter((item) => item !== "");
          set5EOptions(
            separatedItems.map((item) => {
              const separateColon = item.split(":");
              return {
                label: separateColon[0],
                value: separateColon[1],
              };
            })
          );
        }
      });
    }
  }, [GetConfigData, configData, InstId, token]);
  const [AddAcdLessonDetail] = useMutation<
    AddAcdLessonPlannerDetailsData,
    AddAcdLessonPlannerDetailsVars
  >(AddAcdLessonPlannerDetails);

  const [UpdateAcdLessonDetail] = useMutation<
    UpdateAcdLessonPlannerDetailsData,
    UpdateAcdLessonPlannerDetailsVars
  >(UpdateAcdLessonPlannerDetails);
  const [GetAcdLesson, { data }] = useLazyQuery<
    { node: AcdLessonPlannerDetailsNode },
    CommonNodeVars
  >(GetAcdLessonDetailsByNodeId, {
    variables: {
      token,
      id: sub_topic_id,
    },
  });

  useEffect(() => {
    if (operation === Operation.UPDATE && token && sub_topic_id) {
      GetAcdLesson().then(({ data }) => {
        if (data && data.node) {
          setFormData({
            classroom_process: data.node.classroom_process,
            learning_objectives: data.node.learning_objectives,
            resources_required: data.node.resources_required,
            sub_topic_name: data.node.sub_topic_name,
            teacher_reflection: data.node.teacher_reflection,
            techniques: data.node.techniques,
          });
          setSelected5E(
            fiveEOptions.find(({ value }) => value === data.node.five_es) ??
              null
          );

          setHrs(Math.trunc(data.node.duration_in_mins / 60).toString());
          setMinutes((data.node.duration_in_mins % 60).toString());
        }
      });
    }
  }, [GetAcdLesson, operation, data, token, sub_topic_id, fiveEOptions]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (operation === Operation.CREATE)
      AddAcdLessonDetail({
        variables: {
          token,
          inst_id: InstId!,
          lesson_main_id,
          user_details,
          input: {
            duration_in_mins: Number(hrs) ? Number(mins) + Number(hrs) * 60 : 0,
            sub_topic_name: removeMoreSpace(formData.sub_topic_name),
            classroom_process: removeMoreSpace(formData.classroom_process),
            five_es: selected5E ? selected5E.value : EMPTY_STRING,
            resources_required: removeMoreSpace(formData.resources_required),
            teacher_reflection: removeMoreSpace(formData.teacher_reflection),
            techniques: removeMoreSpace(formData.techniques),
          },
        },
        refetchQueries: [
          {
            query: GetAcdLessonPlannerMain,
            variables: {
              token,
              acd_yr_id: activeAcademicYearData.data
                ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
                : 0,
              allotted_id: alloted.id,
              allotted_level: alloted.level,
              inst_id: InstId!,
              subj_master_id: selectedSubject ? selectedSubject.id : 0,
            },
          },
        ],
      }).then(({ data }) => {
        if (data && data.AddAcdLessonPlannerDetails) {
          setMessage({
            flag: true,
            message: "Lesson plan created successfully",
            operation: Operation.CREATE,
          });
        }
      });

    if (operation === Operation.UPDATE) {
      if (lesson_main_id === 0 || sub_topic_id === 0) {
        setMessage({
          flag: true,
          message: `${lesson_main_id ? "Lesson" : "Sub topic"} not found`,
          operation: Operation.NONE,
        });
        return;
      }
      UpdateAcdLessonDetail({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          lesson_details_id: sub_topic_id,
          input: {
            duration_in_mins:
              Number(hrs) && Number(mins) ? Number(mins) + Number(hrs) * 60 : 0,
            sub_topic_name: removeMoreSpace(formData.sub_topic_name),
            classroom_process: removeMoreSpace(formData.classroom_process),
            five_es: selected5E ? selected5E.value : EMPTY_STRING,
            resources_required: removeMoreSpace(formData.resources_required),
            teacher_reflection: removeMoreSpace(formData.teacher_reflection),
            learning_objectives: removeMoreSpace(formData.learning_objectives),
            techniques: removeMoreSpace(formData.techniques),
          },
        },
        refetchQueries: [
          {
            query: GetAcdLessonPlannerMain,
            variables: {
              token,
              acd_yr_id: activeAcademicYearData.data
                ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
                : 0,
              allotted_id: alloted.id,
              allotted_level: alloted.level,
              inst_id: InstId!,
              subj_master_id: selectedSubject
                ? selectedSubject.subj_master_id
                : 0,
            },
          },
        ],
      }).then(({ data }) => {
        if (data && data.UpdateAcdLessonPlannerDetails) {
          setMessage({
            flag: true,
            message: "Lesson plan updated successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const handleClear = () => {
    setFormData({
      classroom_process: "",
      learning_objectives: "",
      resources_required: "",
      sub_topic_name: "",
      teacher_reflection: "",
      techniques: "",
    });
    setHrs("");
    setMinutes("");
  };

  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setDrawer(defaultEditSubChapter);
      handleClear();
    }
    setMessage(emptyMessageType);
  };
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target as HTMLInputElement;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="create-main-chapter">
        <div className="create-main-chapter__title">
          <Title>
            {operation === Operation.UPDATE
              ? "Update Sub Chapter"
              : "Create Sub Chapter"}
          </Title>
          <img
            src={Close}
            alt=""
            onClick={() => {
              handleClear();
              setDrawer(defaultEditSubChapter);
            }}
          />
        </div>
        <form>
          <div className="create-main-chapter--frame">
            <div className="label-grid">
              <Label>Description</Label>
              <TextArea
                value={formData.sub_topic_name}
                name="sub_topic_name"
                rows={3}
                onChange={handleValueChange}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && e.shiftKey === false) {
                    handleFormEvent(e);
                  }

                  if (e.shiftKey && e.key === Keys.ENTER) {
                    setFormData((prev) => ({
                      ...prev,
                      sub_topic_name: `${formData.sub_topic_name} \n`,
                    }));
                  }
                }}
                autoFocus
              />
            </div>
            <div className="label-grid">
              <Label>Set Duration</Label>
              <Time
                onChangeHrs={setHrs}
                onChangeMins={setMinutes}
                hrs={hrs}
                mins={mins}
              />
            </div>

            <div className="label-grid">
              <Label>5 E's</Label>
              <Autocomplete
                classes={classes}
                options={fiveEOptions}
                value={selected5E}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, selected5E)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSelected5E(newValue as optionsType);
                  } else {
                    setSelected5E(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setSelected5E(null);
                  }
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    handleMUISelectEvent(e);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    classes={{
                      root: textClasses.formControlRoot,
                    }}
                  />
                )}
              />
            </div>
            {AcademicsDetails.SubTopicsForm.map(
              (item: FormType, index: number) => {
                return (
                  <div className="label-grid">
                    <Label>{item.LabelName}</Label>
                    <TextArea
                      value={formData[item.inputName]}
                      rows={3}
                      name={item.inputName}
                      onChange={handleValueChange}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER && e.shiftKey === false) {
                          handleFormEvent(e);
                        }

                        if (e.shiftKey && e.key === Keys.ENTER) {
                          setFormData((prev) => ({
                            ...prev,
                            [item.inputName]: `${formData[item.inputName]} \n`,
                          }));
                        }
                      }}
                    />
                  </div>
                );
              }
            )}
          </div>
          <Button mode="save" onClick={handleSubmit} />
          <Button
            mode="cancel"
            onClick={() => {
              handleClear();
              setDrawer(defaultEditSubChapter);
            }}
          />
        </form>
      </div>

      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default CreateSubChapter;
