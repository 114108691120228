import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { Autocomplete, Checkbox, Drawer, TextField } from "@mui/material";
import Modal from "react-modal";
import Input from "../../../../stories/Input/Input";
import {
  Direction,
  InstitutionDetails,
  Operation,
  SortBy,
  StudentReportType,
  TableHeaders,
} from "../../../../utils/Enum.types";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { UpdateStdSectionByIds } from "../../../../queries/students/mutations/update";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { studentQueryTypes } from "../../../../utils/studentqueryEnum.types";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import useToken from "../../../../customhooks/useToken";
import { Keys } from "../../../../utils/Enum.keys";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { msgType, responseType } from "../../../../utils/Form.types";
import { useNavigate, useParams } from "react-router-dom";
import { Label } from "../../../../stories/Label/Label";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import useNotAlloactedIdFromInst from "../../../../customhooks/useNotAlloactedIdFromInst";
import { sectionAllocationStyles } from "../../../../styles/DrawerStyles";
import StudentTotalCount from "../Components/StudentTotalCount";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import useAcdDropDownData from "../../../Academics/hooks/useAcdDropDownData";
import useAcdStudentsForAdmission, {
  StudentEdges,
} from "../../../Academics/hooks/useAcdStudentsForAdmission";
import { GetAcdStudents } from "../../../Academics/queries/students/Index";
interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

const InterChangeSections = () => {
  const navigate = useNavigate();
  //Styles
  const classes = ListAutoCompleteStyles();
  const { state } = useContext(AppContext);
  const textClasses = ListAutoCompleteTextStyles();
  //usestates
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [sectionStudents, setSectionStudents] = useState<StudentEdges[]>([]);
  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          {USE_SECTION_KEY &&
            sectionDropDown.length > 0 &&
            interchangeSection === false &&
            leftSectionSelected && (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                />
              </TableCell>
            )}
          <TableCell>{TableHeaders.SLNO}</TableCell>
          <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
          <TableCell>{TableHeaders.REGISTER_NUMBER}</TableCell>
          <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>
          {USE_BRANCH_KEY ? (
            <TableCell className="studentlist__table--status">
              {branchLabel}
            </TableCell>
          ) : null}
          {USE_CLASS_KEY ? (
            <TableCell className="studentlist__table--status">
              {classLabel}
            </TableCell>
          ) : null}
          {USE_SECTION_KEY ? (
            <TableCell className="studentlist__table--status">
              {sectionLabel}
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
  };
  const EnhancedTableHeadForDrawer = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>{TableHeaders.SLNO}</TableCell>
          <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
          <TableCell>{TableHeaders.REGISTER_NUMBER}</TableCell>
          <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>

          {USE_SECTION_KEY ? (
            <TableCell className="studentlist__table--status">
              {sectionLabel}
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
  };

  //useStates
  const { token } = useToken();
  const { InstId } = useParams();
  const drawerClasses = sectionAllocationStyles();
  const [interchangeSection, setInterchangeSection] = useState(false);

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [sectionHasNextPage, setSectionHasNextPage] = useState<boolean>(true);
  const [sectionEndCursor, setSectionEndCursor] = useState<string | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [leftSectionSelected, setLeftSectionSelected] =
    useState<responseType | null>(null);
  const [rightSectionSelected, setRightSectionSelected] =
    useState<responseType | null>(null);
  const [searchData, setSearchData] = useState("");
  const [viewStudentModal, setViewStudentModal] = useState(false);
  //useRefs
  const { NotAllocatedDeptId } = useNotAlloactedIdFromInst();
  const { user_details } = useLoggedInUserDetails();
  //useMutations
  const [UpdateStdSection, { loading: updationLoading }] = useMutation(
    UpdateStdSectionByIds,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
    masterIds,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  //Left table
  const { AcademicsStudentData: LeftStudentsData } = useAcdStudentsForAdmission(
    USE_DEPARTMENT_KEY
      ? departmentSelected
        ? departmentSelected.value
        : 0
      : NotAllocatedDeptId,
    masterIds.branchId,
    masterIds.classId,
    masterIds.semesterId,
    leftSectionSelected ? leftSectionSelected.value : 0,
    0,
    ROWS_PER_PAGE,
    0,
    searchData,
    StudentReportType.CURR_STUDENTS,
    EMPTY_STRING,
    []
  );

  //pagination for leftSection
  // Right table

  const { AcademicsStudentData: RightStudentsData } =
    useAcdStudentsForAdmission(
      0,
      0,
      0,
      0,
      rightSectionSelected ? rightSectionSelected.value : 0,
      0,
      ROWS_PER_PAGE,
      0,
      searchData,
      StudentReportType.CURR_STUDENTS,
      EMPTY_STRING,
      []
    );
  //pagination for modalview
  const righttotalCount = RightStudentsData.data
    ? RightStudentsData.data.GetAcdStudents?.totalCount
    : 0;

  useEffect(() => {
    if (LeftStudentsData.data && !LeftStudentsData.loading && token) {
      const newData = LeftStudentsData.data.GetAcdStudents.edges.map(
        (edge) => ({
          ...edge,
          node: {
            ...edge.node,
            isChecked: false,
          },
        })
      );
      if (endCursor) {
        const filteredStudents = students.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: false,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(LeftStudentsData.data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [LeftStudentsData.data, LeftStudentsData.loading, token]);
  useEffect(() => {
    if (RightStudentsData.data && !RightStudentsData.loading && token) {
      const newData = RightStudentsData.data.GetAcdStudents.edges.map(
        (edge) => ({
          ...edge,
          node: {
            ...edge.node,
            isChecked: false, // set default value of isChecked to true
          },
        })
      );

      if (sectionEndCursor) {
        const filteredStudents = sectionStudents.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: false,
              },
            };
          }
          return newStudent;
        });
        setSectionStudents(updatedNewData);
      } else {
        setSectionStudents(newData);
      }
      setSectionEndCursor(
        RightStudentsData.data.GetAcdStudents.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [RightStudentsData.data, RightStudentsData.loading, token]);

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setStudents([]);
      setInterchangeSection(!interchangeSection);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !LeftStudentsData.loading) {
        LeftStudentsData.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...students!, ...newEdges],
                pageInfo,
                totalCount: LeftStudentsData.data
                  ? LeftStudentsData.data.GetAcdStudents.totalCount!
                  : 0,
              },
            };
          },
        });
      }
    }
  };
  const rightHandleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight + 2 >= getModifiedScrollHeight(scrollHeight)) {
      if (sectionHasNextPage && !RightStudentsData.loading) {
        RightStudentsData.fetchMore({
          variables: {
            first: ROWS_PER_PAGE,
            after: sectionEndCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setSectionEndCursor(pageInfo.endCursor);
            setSectionHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...sectionStudents!, ...newEdges],
                pageInfo,
                totalCount: RightStudentsData.data?.GetAcdStudents.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const handleClick = (
    data: StudentEdges,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const mappedDataLeft = students?.map((row) => {
      if (row.node.id === data.node.id) {
        return {
          ...row,
          node: {
            ...row.node,
            isChecked: (e.target as HTMLInputElement).checked ? true : false,
          },
        };
      } else {
        return row;
      }
    });

    setStudents(mappedDataLeft);
  };
  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setLeftSectionSelected(null);
    setRightSectionSelected(null);
    setStudents([]);
    setSearchData("");
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelecteds = students?.map((edge) => ({
      ...edge,
      node: {
        ...edge.node,
        isChecked: event.target.checked,
      },
    }));
    setStudents(newSelecteds);
  };

  const handleAllocationOfStudentsToSection = () => {
    UpdateStdSection({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        student_ids: students
          .filter((student) => student.node.isChecked)
          ?.map(({ node }) => node.id),
        section_id: rightSectionSelected?.value,
      },
      refetchQueries: [
        {
          query: GetAcdStudents,
          variables: {
            admNo: EMPTY_STRING,
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            name: EMPTY_STRING,
            token,
            direction: Direction.ASC,
            sortBy: SortBy.FIRST_NAME,
            input: {
              ids: [rightSectionSelected?.value],
              acd_std_query_type: studentQueryTypes.STATUS_EQ_CUR_BY_SECTION_ID,
            },
          },
        },
        {
          query: GetAcdStudents,
          variables: {
            admNo: EMPTY_STRING,
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            name: EMPTY_STRING,
            token,
            direction: Direction.ASC,
            sortBy: SortBy.FIRST_NAME,
            input: {
              ids: [leftSectionSelected?.value],
              std_query_type: studentQueryTypes.STATUS_EQ_CUR_BY_SECTION_ID,
            },
          },
        },
        {
          query: GetAcdStudents,
          variables: {
            admNo: EMPTY_STRING,
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            name: EMPTY_STRING,
            token,
            direction: Direction.ASC,
            sortBy: SortBy.FIRST_NAME,
            input: {
              ids: [InstId],
              std_query_type: studentQueryTypes.BY_SECTION_ID,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Students Section Interchanged Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
        clear();
      }
    });
  };
  const clearRestInstDetails = (type: InstitutionDetails) => {
    switch (type) {
      case InstitutionDetails.DEPARTMENT:
        setBranchSelected(null);
        setClassSelected(null);
        setSemesterSelected(null);
        setLeftSectionSelected(null);
        break;
      case InstitutionDetails.BRANCH:
        setClassSelected(null);
        setSemesterSelected(null);
        setLeftSectionSelected(null);
        break;
      case InstitutionDetails.CLASS:
        setSemesterSelected(null);
        setLeftSectionSelected(null);
        break;
      case InstitutionDetails.SEMESTER:
        setLeftSectionSelected(null);
        break;
      default:
        break;
    }
  };
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  return (
    <>
      <form className="row g-0 section-allocation__options">
        <div className="col-1 studentlist__search">
          <Input
            id="search"
            type="text"
            placeholder="Search..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchData(e.target.value);
            }}
            value={searchData}
            onKeyDown={handleFormEvent}
          />
        </div>

        {USE_DEPARTMENT_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={departmentDropDown!}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, departmentSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && departmentSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setDepartmentSelected(null);
                }
              }}
              openOnFocus
              value={departmentSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setDepartmentSelected(newValue);
                  setHasNextPage(true);
                } else {
                  setDepartmentSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.DEPARTMENT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={departmentLabel}
                  id="outlined Departments"
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}

        {USE_BRANCH_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, branchSelected)
              }
              options={branchDropDown}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && branchSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(null);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue);
                } else {
                  setBranchSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.BRANCH);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={branchLabel}
                  id="outlined Branches"
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
        {USE_CLASS_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, classSelected)
              }
              options={classDropDown}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && classSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassSelected(null);
                }
              }}
              openOnFocus
              value={classSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassSelected(newValue);
                } else {
                  setClassSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.CLASS);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={classLabel}
                  id="outlined Classes"
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
        {USE_SEMESTER_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, semesterSelected)
              }
              options={semesterDropDown}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && semesterSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setSemesterSelected(null);
                }
              }}
              openOnFocus
              value={semesterSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSemesterSelected(newValue);
                } else {
                  setSemesterSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.SEMESTER);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={semesterLabel}
                  id="outlined Semesters"
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
        <div className="col-1">
          {USE_SECTION_KEY ? (
            <Autocomplete
              classes={classes}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, leftSectionSelected)
              }
              options={sectionDropDown}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && classSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setLeftSectionSelected(null);
                }
              }}
              openOnFocus
              value={leftSectionSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setLeftSectionSelected(newValue);
                } else {
                  setLeftSectionSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={`From ${sectionLabel}`}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          ) : null}
        </div>
      </form>
      <div className="section-allocation__datablock frame-space">
        {!students?.length ? (
          <b className="nodata">Sorry no students found </b>
        ) : (
          <>
            <Title variant="subtitle1">List of Students</Title>
            <div className="section-allocation__tableblock">
              <TableContainer
                className="section-allocation__table"
                onScroll={handleScroll}
              >
                <Table stickyHeader>
                  <EnhancedTableHead
                    numSelected={
                      students?.filter((data) => data.node.isChecked).length
                    }
                    onSelectAllClick={(event) => handleSelectAllClick(event)}
                    rowCount={students?.length}
                  />
                  <TableBody>
                    {students?.map((response, index: number) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={students[index]?.node.isChecked}
                          tabIndex={-1}
                          key={response.node.id}
                          selected={students[index]?.node.isChecked}
                        >
                          {USE_SECTION_KEY &&
                            sectionDropDown.length > 0 &&
                            leftSectionSelected && (
                              <TableCell
                                padding="checkbox"
                                id="td-center"
                                className="section-allocation__table--slno"
                              >
                                <Checkbox
                                  checked={
                                    students[index]?.node.isChecked ?? false
                                  }
                                  onClick={(event) =>
                                    handleClick(response, event)
                                  }
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>
                            )}
                          <TableCell
                            id="td-center"
                            className="section-allocation__table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="section-allocation__table--admno">
                            {response.node.std_adm_no}
                          </TableCell>
                          <TableCell className="section-allocation__table--admno">
                            {response.node.std_reg_no}
                          </TableCell>

                          <TableCell id={labelId}>
                            {response.node.first_name +
                              " " +
                              response.node.middle_name +
                              " " +
                              response.node.last_name}
                          </TableCell>
                          {USE_BRANCH_KEY ? (
                            <TableCell className=" section-allocation__table--desc ">
                              {response.node.acd_branch.branch_desc}
                            </TableCell>
                          ) : null}
                          {USE_CLASS_KEY ? (
                            <TableCell className=" section-allocation__table--desc ">
                              {response.node.acd_class.class_desc}
                            </TableCell>
                          ) : null}
                          {USE_SECTION_KEY ? (
                            <TableCell className="section-allocation__table--desc">
                              {response.node.acd_section.section_desc}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
      </div>
      <div className="row g-0">
        <div className="col">
          <Button
            onClick={() => setInterchangeSection(!interchangeSection)}
            disabled={
              !(students?.filter((data) => data.node.isChecked).length > 0)
            }
            mode="move-to"
          />

          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
        <div className="col-2 section-allocation__total">
          <StudentTotalCount
            totalCount={LeftStudentsData.data?.GetAcdStudents?.totalCount!}
          />
        </div>
      </div>

      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={interchangeSection}
        onClose={() => setInterchangeSection(!interchangeSection)}
      >
        <div className="section-allocation__move-to">
          <div className="row g-0 section-allocation__move-to--select">
            {USE_SECTION_KEY ? (
              <div className="col-4">
                <Autocomplete
                  classes={classes}
                  options={
                    sectionDropDown?.filter(
                      (data) => data.value !== leftSectionSelected?.value
                    )!
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setRightSectionSelected(null);
                    }
                  }}
                  openOnFocus
                  value={
                    sectionDropDown?.find(
                      ({ value }) =>
                        Number(value) === rightSectionSelected?.value
                    )! ?? null
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setRightSectionSelected(newValue);
                    } else {
                      setRightSectionSelected(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label={`Move to ${sectionLabel}`}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
          </div>
          <div
            className="section-allocation__move-to--count"
            onClick={() => {
              rightSectionSelected && setViewStudentModal(!viewStudentModal);
            }}
          >
            <b>{rightSectionSelected && righttotalCount}</b>
            <Label>Students :</Label>
            <Label>{sectionLabel}</Label>
          </div>
          <div className="section-allocation__move-to--tableblock">
            <TableContainer
              className="section-allocation__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <EnhancedTableHeadForDrawer />
                <TableBody>
                  {students &&
                    students
                      .filter((data) => data.node.isChecked)
                      .map((response, index) => {
                        return (
                          <TableRow
                            key={response.node.id}
                            hover
                            aria-checked={response.node.isChecked}
                            tabIndex={-1}
                            selected={response.node.isChecked}
                          >
                            <TableCell
                              id="td-center"
                              className="section-allocation__table--slno"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell className="section-allocation__table--admno">
                              {response.node.std_adm_no}
                            </TableCell>
                            <TableCell className="section-allocation__table--admno">
                              {response.node.std_reg_no}
                            </TableCell>

                            <TableCell>
                              {response.node.first_name +
                                " " +
                                response.node.middle_name +
                                " " +
                                response.node.last_name}
                            </TableCell>

                            {USE_SECTION_KEY ? (
                              <TableCell className="section-allocation__table--desc">
                                {
                                  sectionDropDown?.find(
                                    ({ value }) =>
                                      Number(value) ===
                                      rightSectionSelected?.value
                                  )?.label
                                }
                              </TableCell>
                            ) : null}
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button mode="save" onClick={handleAllocationOfStudentsToSection} />
          <Button
            mode="cancel"
            onClick={() => {
              clear();
              setInterchangeSection(!interchangeSection);
            }}
          />
          <MessageModal
            modalFlag={message.flag!}
            value={message.message!}
            handleClose={handleClose}
            operation={message.operation!}
          />
          <LoadingModal flag={updationLoading} />
        </div>
      </Drawer>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewStudentModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>List of Students In Section</Title>
            <TableContainer
              className="section-allocation__table"
              onClick={rightHandleScroll}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className="section-allocation__table--th-slno">
                      {TableHeaders.SLNO}
                    </TableCell>
                    <TableCell className="section-allocation__table--th-admno">
                      {TableHeaders.ADMISSION_NUMBER}
                    </TableCell>
                    <TableCell className="section-allocation__table--th-admno">
                      {TableHeaders.REGISTER_NUMBER}
                    </TableCell>
                    <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>
                    {USE_DEPARTMENT_KEY ? (
                      <TableCell className="section-allocation__table--desc">
                        {departmentLabel}
                      </TableCell>
                    ) : null}
                    {USE_BRANCH_KEY ? (
                      <TableCell className="section-allocation__table--desc">
                        {branchLabel}
                      </TableCell>
                    ) : null}

                    {USE_CLASS_KEY ? (
                      <TableCell className="section-allocation__table--desc">
                        {classLabel}
                      </TableCell>
                    ) : null}

                    {USE_SEMESTER_KEY ? (
                      <TableCell className="section-allocation__table--desc">
                        {semesterLabel}
                      </TableCell>
                    ) : null}
                    {USE_SECTION_KEY ? (
                      <TableCell className="section-allocation__table--desc">
                        {sectionLabel}
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rightSectionSelected &&
                    sectionStudents?.map((response, index: number) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow>
                          <TableCell
                            id="td-center"
                            className="section-allocation__table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="section-allocation__table--admno">
                            {response.node.std_adm_no}
                          </TableCell>
                          <TableCell className="section-allocation__table--admno">
                            {response.node.std_reg_no}
                          </TableCell>

                          <TableCell id={labelId}>
                            {response.node.first_name +
                              " " +
                              response.node.middle_name +
                              " " +
                              response.node.last_name}
                          </TableCell>
                          {USE_DEPARTMENT_KEY ? (
                            <TableCell className="section-allocation__table--desc">
                              {response.node.acd_dept.dept_desc}
                            </TableCell>
                          ) : null}
                          {USE_BRANCH_KEY ? (
                            <TableCell className="section-allocation__table--desc">
                              {response.node.acd_branch.branch_desc}
                            </TableCell>
                          ) : null}

                          {USE_CLASS_KEY ? (
                            <TableCell className="section-allocation__table--desc">
                              {response.node.acd_class.class_desc}
                            </TableCell>
                          ) : null}

                          {USE_SEMESTER_KEY ? (
                            <TableCell className="section-allocation__table--desc">
                              {response.node.acd_semester.sem_desc}
                            </TableCell>
                          ) : null}
                          {USE_SECTION_KEY ? (
                            <TableCell className="section-allocation__table--desc">
                              {response.node.acd_section.section_desc}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setViewStudentModal(!viewStudentModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InterChangeSections;
