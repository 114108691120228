import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { AssignRouteToStudentTitleProps } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import Avatar from "../../../../images/Avatar.svg";
import Close from "../../../../images/Close.svg";
import Warning from "../../../../images/Warning.svg";
import InActiveRoute from "../../../../images/InActiveRoute.svg";
import ActiveRoute from "../../../../images/ActiveRoute.svg";
import DataFetch from "../../../../images/Edit.svg";
import ChoosedRoute from "../../../../images/ChoosedRoute.svg";
import { Label } from "../../../../stories/Label/Label";
import Modal from "react-modal";
import {
  StudentModalStyles,
  WaiveOffModalStyles,
} from "../../../../styles/ModalStyles";
import View from "./View";
import Delete from "./Delete";
import StudentList from "../../Student/List";
import {
  Direction,
  Operation,
  PageFor,
  SortBy,
  StageDetails,
  StudentReportType,
} from "../../../../utils/Enum.types";
import useTransportRoutesData from "../../../../customhooks/general/useTransportRoutesData";
import useAcdStudentsAdmNumber from "../../../Academics/hooks/useAcdStudentsAdmNumber";
import {
  AUTOCOMPLETE_CLEAR_CLASSNAME,
  EMPTY_RESPONSETYPE,
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import {
  msgType,
  responseType,
  transportResponse,
} from "../../../../utils/Form.types";
import { payloadTypes } from "../../../../context/reducer";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";

import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import {
  AddTransportStudent,
  UpdateTransportRouteMaster,
  UpdateTransportStudent,
} from "../../queries/mutations";
import MessageModal from "../../../../pages/MessageModal";
import {
  GetStudentTransportByData,
  GetTransportStudentByVars,
  RouteDetailsByData,
  RouteDetailsByVars,
} from "../../paginationTypes";
import {
  GetTransportFeeDetails,
  GetTransportRouteDetailsByMasterId,
  GetTransportRouteMasters,
  GetTransportStudent,
} from "../../queries/list";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import {
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import LoadingModal from "../../../../pages/LoadingModal";
import { Keys } from "../../../../utils/Enum.keys";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { AddOrUpdateOrDeleteStudentDemandDetails } from "../../../Accounts/queries/demands/mutation";
import { GetStudents } from "../../../../queries/students/list/newApi";
import { studentQueryTypes } from "../../../../utils/studentqueryEnum.types";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { GetAcdStudentsForAdmissions } from "../../../Academics/queries/students/Index";
const { Transport_Title } = require("../../json/title.json");
export interface Dates {
  start_date: string;
  end_date: string;
  due_date: string;
}
interface Props {
  pageType: PageFor;
  operation: Operation;
  setModalFlag?: React.Dispatch<React.SetStateAction<boolean>>;
}
const ToStudent = ({ pageType, operation, setModalFlag }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { serverDate } = useServerDateandTime();
  const { user_details } = useLoggedInUserDetails();

  const [imageString, setImageString] = useState("");

  const { state, dispatch } = useContext(AppContext);
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();

  const { InstDetails } = useInstDetails(1);

  const [studentModal, setStudentModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [searchAdmNo, setSearchAdmNo] = useState("");
  const [date, setDate] = useState<Dates>({
    start_date: "",
    end_date: "",
    due_date: "",
  });
  const [deleteModal, setDeleteModal] = useState(false);

  const [routeId, setRouteId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [stageSelected, setStageSelected] = useState<transportResponse | null>(
    null
  );

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [activeModal, setActiveModal] = useState(false);
  const [searchRoutes, setSearchData] = useState("");
  const [formData, setFormData] = useState<Dates>({
    start_date: "",
    end_date: "",
    due_date: "",
  });
  const { studentAddmissionNumber } = useAcdStudentsAdmNumber(
    searchAdmNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentReportType.GENERAL
  );
  const { studentFormData, studentData } = useStudentDatabyId();

  //Use Refs
  const routeRef = useRef<HTMLSelectElement>(null);
  const routeInputRef = routeRef?.current?.childNodes[0]?.childNodes[1]
    .childNodes[0] as HTMLInputElement;
  const stageRef = useRef<HTMLSelectElement>(null);
  const stageInputRef = stageRef?.current?.childNodes[0]?.childNodes[1]
    .childNodes[0] as HTMLInputElement;
  const admNoRef = useRef<HTMLSelectElement>(null);
  const admNoClearRef = admNoRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;
  const saveRef = useRef<HTMLButtonElement>(null);

  const [GetTransportStudentData, { data: StudentRouteData }] = useLazyQuery<
    GetStudentTransportByData,
    GetTransportStudentByVars
  >(GetTransportStudent, {
    variables: {
      token,
      student_id: state.studentId,
    },
  });
  const [
    UpdateStudentDemandDetails,
    { loading: UpdateStudentDemandDetailsLoading },
  ] = useMutation(AddOrUpdateOrDeleteStudentDemandDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [GetRouteDetailsById, { data }] = useLazyQuery<
    RouteDetailsByData,
    RouteDetailsByVars
  >(GetTransportRouteDetailsByMasterId, {
    variables: {
      token,
      inst_id: InstId!,
      route_master_id:
        operation === Operation.CREATE
          ? routeId.value
          : StudentRouteData?.GetTransportStudent.route_details
              .route_master_id!,
    },
  });

  const [AssignRoute, { loading: creationLoading }] = useMutation(
    AddTransportStudent,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateRouteStatus, { loading: updationLoading }] = useMutation(
    UpdateTransportRouteMaster,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateRoute, { loading: RouteupdationLoading }] = useMutation(
    UpdateTransportStudent,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [GetRouteFeeData, { data: FeeData }] = useLazyQuery(
    GetTransportFeeDetails
  );

  useEffect(() => {
    if (stageSelected) {
      GetRouteFeeData({
        variables: {
          token,
          inst_id: state.InstId,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          acct_ldgr_id: stageSelected?.acct_ldgr_id,
        },
      });
    }
  }, [stageSelected, GetRouteFeeData, state.ActiveFinYr, token, state.InstId]);

  const stagesDropDown: transportResponse[] =
    data?.GetTransportRouteDetailsByMasterId.map((res) => ({
      label: res.route_stage_desc,
      value: res.id,
      ldgr_desc: res.acct_ldgr_details?.ldgr_desc,
      acct_ldgr_id: res.acct_ldgr_details.id,
    })) || [];

  const { routesData } = useTransportRoutesData(searchRoutes, false);
  const activeDetails = routesData.data?.GetTransportRouteMasters.edges
    .filter((id) => id.node.id === routeId.value)
    .map((res) => res.node.route_is_active);

  const routeActive =
    StudentRouteData?.GetTransportStudent.route_details.route_master_details
      .route_is_active;
  const HandleUpdateStatus = () => {
    UpdateRouteStatus({
      variables: {
        token,
        id: routeId.value,
        inst_id: InstId,
        user_details,
        input: {
          route_is_active: true,
        },
      },
      refetchQueries: [
        {
          query: GetTransportRouteMasters,
          variables: {
            token,
            inst_id: InstId!,
            first: ROWS_PER_PAGE,
            sortBy: SortBy.ROUTE_DESC,
            direction: Direction.ASC,
            name: EMPTY_STRING,
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setActiveModal(!activeModal);
      }
    });
  };
  const HandleSubmit = () => {
    UpdateStudentDemandDetails({
      variables: {
        token,
        student_id: [state.studentId],
        add_std_demand_details: [
          {
            fee_demand: FeeData?.GetTransportFeeDetails,
            acct_ldgr_id: stageSelected?.acct_ldgr_id,
            fee_due_date: toIsoDate(date.due_date),
          },
        ],
        user_details,
        inst_id: InstId,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Demand Updated Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
    if (operation === Operation.UPDATE)
      UpdateRoute({
        variables: {
          token,
          student_id: state.studentId,
          inst_id: InstId,
          user_details,
          input: {
            student_id: state.studentId,
            route_detail_id: stageSelected?.value,
          },
        },
        refetchQueries: [
          {
            query: GetTransportStudent,
            variables: {
              token,
              student_id: state.studentId,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            operation: Operation.UPDATE,
            message: "Successfully Updated Student Route",
          });
        }
      });
    if (operation === Operation.CREATE)
      AssignRoute({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          route_detail_id: stageSelected?.value,
          student_ids: state.studentId,
        },
        refetchQueries: [
          {
            query: GetTransportStudent,
            variables: {
              token,
              student_id: state.studentId,
            },
          },
          {
            query: GetAcdStudentsForAdmissions,
            variables: {
              after: null,
              acd_yr_id:
              state.ActiveAcdYr
                ? state.ActiveAcdYr.id
                : 0,
              status: EMPTY_STRING,

              first: ROWS_PER_PAGE,
              name: EMPTY_STRING,
              token,
              orderBy: [
                {
                  direction: Direction.ASC,
                  field: SortBy.FIRST_NAME,
                },
              ],
              input: {
                ids: [Number(InstId)],
                std_query_type: StudentReportType.STDS_NOT_ASSIGNED_ROUTE,
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Successfully Assigned Route to Student",
            operation: Operation.CREATE,
          });
        }
      });
  };
  const filterData = data?.GetTransportRouteDetailsByMasterId.find(
    (data) => stageSelected && data.route_stage_desc === stageSelected.label
  );

  const ChoosedRouteData = [
    {
      name: StageDetails.ROUTE,
      route: filterData?.route_master_details.route_desc,
    },
    {
      name: StageDetails.STAGE,
      route: filterData?.route_stage_desc,
    },
    {
      name: StageDetails.LEDGER,
      route: filterData?.acct_ldgr_details.ldgr_desc,
    },
    {
      name: StageDetails.FEE,
      route: FeeData?.GetTransportFeeDetails,
    },
  ];
  const PrevRouteData = [
    {
      name: StageDetails.ROUTE,
      route:
        StudentRouteData?.GetTransportStudent.route_details.route_master_details
          .route_desc,
    },
    {
      name: StageDetails.STAGE,
      route:
        StudentRouteData?.GetTransportStudent.route_details.route_stage_desc,
    },
    {
      name: StageDetails.LEDGER,
      route: StudentRouteData?.GetTransportStudent.acct_ldgr_details.ldgr_desc,
    },
  ];
  const handleClear = () => {
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0,
      },
    });
    setDate({
      end_date: "",
      start_date: "",
      due_date: "",
    });
    setRouteId(EMPTY_RESPONSETYPE_OBJECT);
    setStageSelected(null);
    admNoClearRef?.click();
  };
  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      pageType === PageFor.GENERAL
        ? navigate(`/${InstId}/transport/masterdata/assignroutetostudent/list`)
        : setModalFlag?.(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (routeId.value) {
      GetRouteDetailsById({
        variables: {
          token,
          inst_id: InstId!,
          route_master_id: routeId.value,
        },
      });
    }
  }, [GetRouteDetailsById, InstId, routeId.value, token]);

  useEffect(() => {
    if (operation === Operation.UPDATE) {
      GetTransportStudentData({
        variables: {
          token,
          student_id: state.studentId,
        },
      });
    }
  }, [GetTransportStudentData, operation, state.studentId, token]);
  const { branchLabel,categoryLabel } = useInstLabels();

  useEffect(() => {
    if (
      studentData.data &&
      studentData.data.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      const studentProfielPic = `${InstDetails.data?.nodes[0].inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      const _ = getDownloadUrl(studentProfielPic, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);

  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title>
        {Transport_Title.MasterData.AssignRouteToStudent.map(
          (title: AssignRouteToStudentTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {operation === Operation.CREATE ? title.Title : title.Update}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "transport-assign-route"
            : "transport-assign-route__modal"
        }
      >
        <div className="transport-assign-route__details row g-0">
          <div className="col">
            <div className="transport-assign-route__details--image-flex">
              {pageType === PageFor.GENERAL &&
              operation === Operation.CREATE ? (
                <Autocomplete
                  classes={classes}
                  options={studentAddmissionNumber ?? EMPTY_RESPONSETYPE_OBJECT}
                  isOptionEqualToValue={(option) =>
                    option?.value === state.studentId
                  }
                  ref={admNoRef}
                  autoHighlight
                  forcePopupIcon
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      routeInputRef?.focus();
                    }
                    if (e.key === Keys.BACKSPACE) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: (newValue as responseType)?.value,
                        },
                      });
                    } else {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                      setImageString("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Admission Number"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchAdmNo(e.target.value)
                      }
                      autoFocus
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              ) : (
                <TextField
                  label="Admission No"
                  className="transport-assign-route__details--textfield"
                  InputLabelProps={{ shrink: true }}
                  value={studentFormData.adm_no}
                  disabled
                />
              )}
              {pageType === PageFor.GENERAL &&
              operation === Operation.CREATE ? (
                <img
                  className="data-fetch-icon"
                  src={DataFetch}
                  alt="/"
                  onClick={() => setStudentModal(!studentModal)}
                />
              ) : null}
            </div>
            <TextField
              label="Reg No."
              className="transport-assign-route__details--textfield"
              InputLabelProps={{ shrink: true }}
              value={studentFormData.reg_number}
              disabled
            />
            <TextField
              label="Year"
              className="transport-assign-route__details--textfield"
              InputLabelProps={{ shrink: true }}
              value={studentFormData.acd_yr}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              label="Name"
              className="transport-assign-route__details--textfield"
              InputLabelProps={{ shrink: true }}
              value={
                studentFormData.first_name +
                " " +
                studentFormData.middle_name +
                " " +
                studentFormData.last_name
              }
              disabled
            />
            <TextField
              label="Father Name"
              className="transport-assign-route__details--textfield"
              InputLabelProps={{ shrink: true }}
              value={studentFormData.father_name}
              disabled
            />
            <TextField
              label={branchLabel}
              className="transport-assign-route__details--textfield"
              InputLabelProps={{ shrink: true }}
              value={studentFormData.branch}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              label="Date"
              className="transport-assign-route__details--date"
              InputLabelProps={{ shrink: true }}
              value={toInputStandardDate(serverDate)}
              disabled
              type="date"
            />
            <TextField
               label={categoryLabel}
              className="transport-assign-route__details--textfield"
              InputLabelProps={{ shrink: true }}
              value={studentFormData.category}
              disabled
            />
            <TextField
              label="Mobile No."
              className="transport-assign-route__details--textfield"
              InputLabelProps={{ shrink: true }}
              value={studentFormData.std_mobile}
              disabled
            />
          </div>
          <div className="col-1 transport-assign-route__details--studentprofile">
            <img src={imageString ? imageString : Avatar} alt="/" />
          </div>
        </div>

        <div className="row g-0 transport-assign-route__select">
          <div className="col-3">
            <Autocomplete
              classes={classes}
              options={routesData.routesDropDown}
              openOnFocus
              ref={routeRef}
              disabled={!state.studentId}
              forcePopupIcon
              value={routeId}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  if (routeId.value) {
                    stageInputRef?.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setStageSelected(null);
                }
              }}
              onChange={(e, newValue) => {
                if (newValue) {
                  setRouteId(newValue as responseType);
                } else {
                  setRouteId(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Bus Route"
                  onChange={(e) => setSearchData(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          {activeDetails?.[0] || routeActive ? (
            <>
              <div className="col-2">
                <Autocomplete
                  classes={classes}
                  options={stagesDropDown ?? EMPTY_RESPONSETYPE}
                  openOnFocus
                  forcePopupIcon
                  ref={stageRef}
                  value={stageSelected}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      if (stageSelected?.value) {
                        saveRef.current?.focus();
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setStageSelected(null);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setStageSelected(newValue);
                    } else {
                      setStageSelected(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Student Stage At"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
              <div className="col-3 transport-assign-route__datablock--textfield">
                <TextField
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  label="Start Date"
                  value={formData.start_date}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      start_date: e.target.value,
                    })
                  }
                  className="transport-assign-route__datablock--textfield--date"
                />
                <TextField
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  label="End Date"
                  value={formData.end_date}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      end_date: e.target.value,
                    })
                  }
                  className="transport-assign-route__datablock--textfield--date"
                />
              </div>
            </>
          ) : null}
          <div className="col">
            {routeId.value ? (
              activeDetails?.[0] || routeActive ? (
                <Title variant="subtitle2">
                  <img src={ActiveRoute} alt="/" />
                  Active Route
                </Title>
              ) : (
                <Title variant="subtitle2">
                  <img src={InActiveRoute} alt="/" />
                  Inactive Route
                </Title>
              )
            ) : null}
          </div>
          <div className="col-2">
            {routeId.value ? (
              activeDetails?.[0] || routeActive ? null : (
                <Button
                  mode="active"
                  onClick={() => setActiveModal(!activeModal)}
                >
                  Make Route Active
                </Button>
              )
            ) : null}
          </div>
        </div>
        <div className="row g-0 transport-assign-route__datablock">
          {routeActive ? (
            <div className="col-3 transport-assign-route__choosed-route">
              <Title variant="subtitle2">
                <img src={ChoosedRoute} alt="/" />
                Previously Assigned Route
              </Title>
              <ul className="transport-assign-route__choosed-route--list">
                {PrevRouteData.map((data, index) => {
                  return (
                    <li key={index + 1}>
                      <TextField
                        label={data.name}
                        className="transport-assign-route__choosed-route--list--textfield"
                        InputLabelProps={{ shrink: true }}
                        value={data.route}
                        disabled
                      />
                    </li>
                  );
                })}
              </ul>
              <div className="transport-assign-route__choosed-route--label-grid">
                <TextField
                  label="Start Date"
                  className="transport-assign-route__details--date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                />
                <TextField
                  label="End Date"
                  className="transport-assign-route__details--date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                />
              </div>
            </div>
          ) : null}
          {stageSelected?.value && (activeDetails?.[0] || routeActive) ? (
            <div className="col-3 transport-assign-route__choosed-route">
              <Title variant="subtitle2">
                <img src={ChoosedRoute} alt="/" />
                Currently Choosed Route
              </Title>
              <ul className="transport-assign-route__choosed-route--list">
                {ChoosedRouteData.map((data, index) => {
                  return (
                    <li key={index + 1}>
                      <TextField
                        label={data.name}
                        className="transport-assign-route__choosed-route--list--textfield"
                        InputLabelProps={{ shrink: true }}
                        value={data.route}
                        disabled
                      />
                    </li>
                  );
                })}
                <TextField
                  label="Fee Due Date"
                  type="date"
                  value={date.due_date}
                  onChange={(e) =>
                    setDate({
                      ...date,
                      due_date: e.target.value,
                    })
                  }
                  className="student-services__transport--textfield--date"
                  InputLabelProps={{ shrink: true }}
                />
              </ul>
              <div className="transport-assign-route__choosed-route--label-grid">
                <TextField
                  label="Start Date"
                  className="transport-assign-route__details--date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  value={formData.start_date}
                />
                <TextField
                  label="End Date"
                  className="transport-assign-route__details--date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  value={formData.end_date}
                />
              </div>
            </div>
          ) : null}
        </div>
        <Button mode="save" buttonref={saveRef} onClick={HandleSubmit} />
        {pageType === PageFor.GENERAL ? (
          <>
            <Button mode="clear" onClick={handleClear} />
            <Button mode="view" onClick={() => setViewModal(!viewModal)} />
          </>
        ) : null}
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button mode="cancel" onClick={() => setModalFlag?.(false)} />
        )}
      </div>
      {/* active-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={activeModal}
        ariaHideApp={false}
        style={WaiveOffModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title variant="subtitle2">
              <img src={Warning} alt="/" />
              Are You Sure?
            </Title>
            <div>
              <Label>Do You Really Want to Active This Route</Label>
            </div>
            <Button mode="yes" onClick={HandleUpdateStatus} />
            <Button
              mode="cancel"
              onClick={() => setActiveModal(!activeModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setActiveModal(!activeModal)}
            />
          </div>
        </div>
      </Modal>
      {/* view-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <View modalFlag={setViewModal} pageType={PageFor.GENERAL} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setViewModal(!viewModal)} />
          </div>
        </div>
      </Modal>

      {/* delete-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={deleteModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Delete modalFlag={setDeleteModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDeleteModal(!deleteModal)}
            />
          </div>
        </div>
      </Modal>
      {/* student-modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              setStudentModal={setStudentModal}
              queryType={StudentReportType.TRANSPORT_NOT_ASSIGNED}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal
        flag={
          creationLoading ||
          updationLoading ||
          RouteupdationLoading ||
          UpdateStudentDemandDetailsLoading
        }
      />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default ToStudent;
