import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import {
  GetAcdReportCardsData,
  GetAcdReportCardsVars,
  GetAcdReportTestsData,
  GetAcdReportTestsVars,
} from "../../../types/Progresscard";
import {
  GetAcdReportCards,
  GetAcdReportTests,
} from "../../../queries/reportcard";
import useToken from "../../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../../context/context";
import { responseType } from "../../../../../utils/Form.types";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../../styles/AutocompleteStyles";
import { isOptionEqualToValue } from "../../../../../utils/UtilFunctions";
import { ROWS_PER_PAGE } from "../../../../../utils/constants";
import { Direction } from "../../../../../utils/Enum.types";
import { Keys } from "../../../../../utils/Enum.keys";
import Home from "../../../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import { Button } from "../../../../../stories/Button/Button";
import Update from "../../../../../images/EditProfile.svg";
const AcdTestReportList = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const navigate=useNavigate();
  const [reportSelected, setReportSelected] = useState<responseType | null>(
    null
  );
  const [GetReportCardData, { data: reportList }] = useLazyQuery<
    GetAcdReportCardsData,
    GetAcdReportCardsVars
  >(GetAcdReportCards, {
    variables: {
      token,
      inst_id: Number(InstId),
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      first: ROWS_PER_PAGE,
      after: null,
      orderBy: {
        direction: Direction.ASC,
      },
    },
  });
  useEffect(() => {
    if (token) {
      GetReportCardData();
    }
  }, [token, GetReportCardData]);
  const reportDropDown = reportList
    ? reportList?.GetAcdReportCards.edges.map((list) => ({
        label: list.node.report_name,
        value: list.node.id,
      }))
    : [];

  const [
    GetAcdReportTestDetails,
    { data: TestDetails, loading, error, fetchMore },
  ] = useLazyQuery<GetAcdReportTestsData, GetAcdReportTestsVars>(
    GetAcdReportTests,
    {
      variables: {
        token,
        inst_id: Number(InstId),
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        acd_report_card_id: reportSelected ? reportSelected.value : 0,
      },
    }
  );
  useEffect(() => {
    if (token && InstId!) {
      GetAcdReportTestDetails();
    }
  }, [token, GetAcdReportTestDetails]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Alloted Test List </Title>
      <div className="report-card-test">
        <div className="report-card-test__filters row g-0">
          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={reportDropDown!}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, reportSelected)
              }
              value={reportSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setReportSelected(newValue);
                } else {
                  setReportSelected(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setReportSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Report Name"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                  fullWidth
                />
              )}
            />
          </div>
        </div>
        <div className="report-card-test__tableblock">
        <TableContainer className="report-card-test__table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Sl No</TableCell>
                <TableCell>Test Description</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {TestDetails?.GetAcdReportTests.map((item, index) => {
                return (
                  <TableRow>
                    <TableCell
                      className="allocatesubjects__table--slno"
                      id="td-center"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell>{item.test_name_details.test_name}</TableCell>

                    <TableCell
                      className="allocatesubjects__table--actions"
                      id="td-center"
                    >
                        <img src={Update} alt="" />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Button mode="back" onClick={()=>navigate(-1)}/>
      </div>
     
    </>
  );
};

export default AcdTestReportList;
