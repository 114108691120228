import React from "react";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import { useNavigate } from "react-router-dom";
import { TableHeaderProps } from "../../../Types/Tables";
import Edit from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
const { VMS_Table } = require("../../json/table.json");

const tableData = [
  {
    vno: "KA01AB1234",
    eno: "JAPB749cs000567",
    cno: "SV30-0169266",
    rname: "Demo School Van",
    rdate: "31-08-2023",
    vtype: "Bus",
    seatingcapacity: 20,
  },
];

const Index = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const navigate = useNavigate();
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Vehicle Details</Title>
      <div className="vms-v-details">
        <div className="vms-v-details__select row g-0">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={["a", "b"]}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Vehicle Type"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={["a", "b"]}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Manufacturer"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={["a", "b"]}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Manufacturer Model"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={["a", "b"]}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Body Type"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-1">
            <Button
              mode="addnew"
              className="vms-v-details__select--addnew"
              onClick={() => navigate(`/vms/vehicledetails/new`)}
            />
          </div>
        </div>
        <div className="vms-v-details__tableblock">
          <TableContainer className="vms-v-details__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {VMS_Table.Vehicle_Details.TableHeaders.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((data, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className="vms-v-details__table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        className="vms-v-details__table--vno"
                        onClick={() => navigate(`/vms/vehicledetails/preview`)}
                      >
                        {data.vno}
                      </TableCell>
                      <TableCell className="vms-v-details__table--eno">
                        {data.eno}
                      </TableCell>
                      <TableCell className="vms-v-details__table--eno">
                        {data.cno}
                      </TableCell>
                      <TableCell>{data.rname}</TableCell>
                      <TableCell className="vms-v-details__table--date">
                        {data.rdate}
                      </TableCell>
                      <TableCell
                        className="vms-v-details__table--type"
                        id="td-center"
                      >
                        {data.vtype}
                      </TableCell>
                      <TableCell
                        className="vms-v-details__table--seating"
                        id="td-center"
                      >
                        {data.seatingcapacity}
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="vms-v-details__table--actions"
                      >
                        <img
                          src={Edit}
                          alt=""
                          onClick={() =>
                            navigate(`/vms/vehicledetails/preview`)
                          }
                        />
                        <img src={Delete} alt="" />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Index;
