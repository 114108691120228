import { useParams } from "react-router-dom";
import Navbar from "../../../../components/common/Navbar/Index";

import { DashBoardFor, LicenseTypes } from "../../../../utils/Enum.types";
import { Label } from "../../../../stories/Label/Label";
import { NavLink } from "react-router-dom";

import Masters from "../../../../images/Admissions.svg";
import Academics from "../../../../images/Academic.svg";
import Accounts from "../../../../images/AccountsLogo.svg";
import Library from "../../../../images/Library.svg";
import Settings from "../../../../images/Settings.svg";
import HR from "../../../../images/HR.svg";
import UserRights from "../../../../images/User_Rights.svg";
import Transport from "../../../../images/Transport.svg";
import Enquiry from "../../../../images/Enquiry.svg";

import MastersDashboard from "../../../Master/Dashboard/Index";
import AccountsDashboard from "../../../Accounts/Dashboard/Index";
import LibraryDashboard from "../../../Library/Dashboard/Index";
import useActiveInstModules from "../../../UserRights/hooks/useActiveInstModules";
import { OtherAccountLedgerBooks } from "../../../../utils/types";

interface props {
  type: LicenseTypes;
}

interface DashBoardProps {
  DashBoardRequired: boolean;
}

const Home = ({ type }: props, { DashBoardRequired }: DashBoardProps) => {
  const { InstId } = useParams<{ InstId: string }>();
  const {
    USE_ACCOUNTS,
    USE_LIBRARY,
    USE_PAYROLL,
    USE_ACADEMICS,
    USE_ENQUIRY,
    USE_TRANSPORT,
    USE_ADMISSION,
  } = useActiveInstModules();

  return (
    <>
      <Navbar NavType={type} />
      {type === LicenseTypes.EDUATE_GENERAL && (
        <div className="instindex__container">
          <div className="row g-0 instindex__container--group">
            {USE_ADMISSION && (
              <div className="col">
                <NavLink to={`/eduate/${InstId}/instdetails/admissions`}>
                  <div className="instindex__container--card">
                    <img src={Masters} alt="/" />
                    <br />
                    <Label>Admissions</Label>
                  </div>
                </NavLink>
              </div>
            )}
            {USE_ACCOUNTS && (
              <div className="col">
                <NavLink to={`/eduate/${InstId}/instdetails/accounts`}>
                  <div className="instindex__container--card">
                    <img src={Accounts} alt="/" />
                    <br />
                    <Label>Accounts</Label>
                  </div>
                </NavLink>
              </div>
            )}
            {USE_ACADEMICS && (
              <div className="col">
                <NavLink to={`/eduate/${InstId}/instdetails/academics`}>
                  <div className="instindex__container--card">
                    <img src={Academics} alt="/" />
                    <br />
                    <Label>Academics</Label>
                  </div>
                </NavLink>
              </div>
            )}
            {USE_LIBRARY && (
              <div className="col">
                <NavLink to={`/eduate/${InstId}/instdetails/library`}>
                  <div className="instindex__container--card">
                    <img src={Library} alt="/" />
                    <br />
                    <Label>Library</Label>
                  </div>
                </NavLink>
              </div>
            )}
            <div className="col">
              <NavLink to={`/eduate/${InstId}/instdetails/configurations`}>
                <div className="instindex__container--card">
                  <img src={Settings} alt="/" />
                  <br />
                  <Label>Configurations</Label>
                </div>
              </NavLink>
            </div>
          </div>
          <div className="row g-0 instindex__container--group">
            {USE_PAYROLL && (
              <div className="col">
                <NavLink to={`/eduate/${InstId}/instdetails/hr`}>
                  <div className="instindex__container--card">
                    <img src={HR} alt="/" />
                    <br />
                    <Label>Staff Management</Label>
                  </div>
                </NavLink>
              </div>
            )}
            {USE_TRANSPORT && (
              <div className="col">
                <NavLink to={`/eduate/${InstId}/instdetails/transport`}>
                  <div className="instindex__container--card">
                    <img src={Transport} alt="/" />
                    <br />
                    <Label>Transport</Label>
                  </div>
                </NavLink>
              </div>
            )}
            {USE_ENQUIRY && (
              <div className="col">
                <NavLink to={`/eduate/${InstId}/instdetails/enquiry`}>
                  <div className="instindex__container--card">
                    <img src={Enquiry} alt="/" />
                    <br />
                    <Label>Enquiry</Label>
                  </div>
                </NavLink>
              </div>
            )}
            <div className="col">
              <NavLink to={`/eduate/${InstId}/instdetails/users`}>
                <div className="instindex__container--card">
                  <img src={UserRights} alt="/" />
                  <br />
                  <Label>User Rights</Label>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      )}

      {type === LicenseTypes.MASTERS && (
        <MastersDashboard pageType={DashBoardFor.PROPRIETOR} />
      )}
      {type === LicenseTypes.ACCOUNTS && (
        <AccountsDashboard
          type={OtherAccountLedgerBooks.FEE_COLLECTED}
          pageType={DashBoardFor.GENERAL}
        />
      )}
      {type === LicenseTypes.LIBRARY && <LibraryDashboard />}
    </>
    // please dont remove this code avd
    // <>
    //   <>
    //     {type === LicenseTypes.ACADEMIC ? (
    //       <>
    //         {ActiveFinancicalYear.data ? (
    //           <Navbar NavType={LicenseTypes.ACADEMIC} />
    //         ) : null}
    //       </>
    //     ) : type === LicenseTypes.PAYROLL ? (
    //       <>
    //         {ActiveFinancicalYear.data ? (
    //           <Navbar NavType={LicenseTypes.PAYROLL} />
    //         ) : null}
    //       </>
    //     ) : type === LicenseTypes.ACCOUNTS ? (
    //       <>
    //         {ActiveFinancicalYear.data ? (
    //           <Navbar NavType={LicenseTypes.ACCOUNTS} />
    //         ) : null}
    //       </>
    //     ) : type === LicenseTypes.LIBRARY ? (
    //       <>
    //         {ActiveFinancicalYear.data ? (
    //           <Navbar NavType={LicenseTypes.LIBRARY} />
    //         ) : null}
    //       </>
    //     ) : type === LicenseTypes.INSTITUTION_CONFIG ? (
    //       <>
    //         {ActiveFinancicalYear.data ? (
    //           <Navbar NavType={LicenseTypes.INSTITUTION_CONFIG} />
    //         ) : null}
    //       </>
    //     ) : type === LicenseTypes.MASTERS ? (
    //       <>
    //         {ActiveFinancicalYear.data ? (
    //           <Navbar NavType={LicenseTypes.MASTERS} />
    //         ) : null}
    //       </>
    //     ) : type === LicenseTypes.USER_RIGHTS ? (
    //       <>
    //         {ActiveFinancicalYear.data ? (
    //           <Navbar NavType={LicenseTypes.USER_RIGHTS} />
    //         ) : null}
    //       </>
    //     ) : (
    //       <>
    //         <Navbar NavType={LicenseTypes.EDUATE} />
    //         {DashBoardRequired && <MastersDashboard />}
    //       </>
    //     )}
    //     {type === LicenseTypes.EDUATE_GENERAL && (
    //       <div className="instindex__container">
    //         <div className="row g-0 instindex__container--group">
    //           <div className="col">
    //             <NavLink to={`/eduate/${InstId}/instdetails/admissions`}>
    //               <div className="instindex__container--card">
    //                 <img src={Masters} alt="/" />
    //                 <br />
    //                 <Label>Admissions</Label>
    //               </div>
    //             </NavLink>
    //           </div>
    //           <div className="col">
    //             <NavLink to={`/eduate/${InstId}/instdetails/accounts`}>
    //               <div className="instindex__container--card">
    //                 <img src={Accounts} alt="/" />
    //                 <br />
    //                 <Label>Accounts</Label>
    //               </div>
    //             </NavLink>
    //           </div>
    //           <div className="col">
    //             <NavLink to={`/eduate/${InstId}/instdetails/academics`}>
    //               <div className="instindex__container--card">
    //                 <img src={Academics} alt="/" />
    //                 <br />
    //                 <Label>Academics</Label>
    //               </div>
    //             </NavLink>
    //           </div>
    //           <div className="col">
    //             <NavLink to={`/eduate/${InstId}/instdetails/library`}>
    //               <div className="instindex__container--card">
    //                 <img src={Library} alt="/" />
    //                 <br />
    //                 <Label>Library</Label>
    //               </div>
    //             </NavLink>
    //           </div>
    //           <div className="col">
    //             <NavLink to={`/eduate/${InstId}/instdetails/configurations`}>
    //               <div className="instindex__container--card">
    //                 <img src={Settings} alt="/" />
    //                 <br />
    //                 <Label>Configurations</Label>
    //               </div>
    //             </NavLink>
    //           </div>
    //         </div>
    //         <div className="row g-0 instindex__container--group">
    //           <div className="col">
    //             <NavLink to={`/eduate/${InstId}/instdetails/hr`}>
    //               <div className="instindex__container--card">
    //                 <img src={HR} alt="/" />
    //                 <br />
    //                 <Label>PayRoll</Label>
    //               </div>
    //             </NavLink>
    //           </div>
    //           {configData.data?.GetSwConfigVariables[0].config_boolean_value ? (
    //             <div className="col">
    //               <NavLink to={`/eduate/${InstId}/instdetails/transport`}>
    //                 <div className="instindex__container--card">
    //                   <img src={Transport} alt="/" />
    //                   <br />
    //                   <Label>Transport</Label>
    //                 </div>
    //               </NavLink>
    //             </div>
    //           ) : null}

    //           {/* <div className="col">
    //             <NavLink to={`/${InstId}/masters/chatconfiguration`}>
    //               <div className="instindex__container--card">
    //                 <img src={Chat} alt="/" />
    //                 <br />
    //                 <Label>Information Center</Label>
    //               </div>
    //             </NavLink>
    //           </div> */}
    //           <div className="col">
    //             <NavLink to={`/eduate/${InstId}/instdetails/enquiry`}>
    //               <div className="instindex__container--card">
    //                 <img src={Enquiry} alt="/" />
    //                 <br />
    //                 <Label>Enquiry</Label>
    //               </div>
    //             </NavLink>
    //           </div>
    //           <div className="col">
    //             <NavLink to={`/eduate/${InstId}/instdetails/users`}>
    //               <div className="instindex__container--card">
    //                 <img src={UserRights} alt="/" />
    //                 <br />
    //                 <Label>User Rights</Label>
    //               </div>
    //             </NavLink>
    //           </div>
    //           <div className="col"></div>
    //           <div className="col"></div>
    //         </div>
    //       </div>
    //     )}
    //   </>
    //   {type === LicenseTypes.MASTERS ? (
    //     <MastersDashboard />
    //   ) : type === LicenseTypes.ACCOUNTS ? (
    //     <AccountsDashboard />
    //   ) : type === LicenseTypes.LIBRARY ? (
    //     <LibraryDashboard />
    //   ) : null}
    // </>
  );
};

export default Home;
