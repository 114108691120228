import Home from "../../Home/Index";
import PreviewTabs from "./PreviewTabs";
import EmployeeDetails from "../Registration/EmployeeDetails";
import React, { useEffect, useState } from "react";
import { employeePersonalDetailsType } from "../../Types/formTypes";
import { TextField } from "@mui/material";
import { LabelNameProps } from "../../../../Types/Labels";
import useEmpDetailsById from "../../hooks/useEmpDetailsById";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";

const { EmployeePreview } = require("../../json/form.json");
const BasicData = () => {
  const {
    empolyeeData: { data, loading },
  } = useEmpDetailsById();
  const { InstId, empId } = useParams();
  const navigate = useNavigate();

  const [EmpOtherDetailsFormData, setEmpOtherDetailsFormData] =
    useState<employeePersonalDetailsType>({
      emp_father_name: "",
      emp_mother_name: "",
      emp_spouse_name: "",
      emp_martial: "",
      emp_religion: "",
      emp_caste: "",
      emp_nationality: "",
      emp_blood_grp: "",
      emp_tel: "",
      emp_perm_address: "",
      emp_perm_city: "",
      emp_perm_pin: "",
      emp_perm_district: "",
      emp_perm_state: "",
      emp_corr_address: "",
      emp_corr_city: "",
      emp_corr_pin: "",
      emp_corr_district: "",
      emp_corr_state: "",
    });
  useEffect(() => {
    if (data && !loading) {
      setEmpOtherDetailsFormData({
        ...data.node.personal_details,
      });
    }
  }, [data, loading]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <PreviewTabs />
      <div className="emp-preview">
        <EmployeeDetails />
        <div className="emp-preview__frame--personal">
          <div className="emp-preview__frame--data row">
            <div className="col emp-preview__span-4">
              {EmployeePreview.PersonalDetails.Input1?.map(
                (label: LabelNameProps, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <TextField
                        id="outlined-uncontrolled"
                        className="emp-preview__textfield"
                        label={label.LabelName}
                        InputLabelProps={{ shrink: true }}
                        value={EmpOtherDetailsFormData[
                          label.inputName
                        ]?.toString()}
                        disabled
                        name={label.inputName}
                      />
                    </React.Fragment>
                  );
                }
              )}
            </div>
          </div>
          <Button
            mode="edit"
            onClick={() =>
              navigate(
                `/${InstId}/hr/employee/2/${empId}/update/personaldetails`
              )
            }
          />
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
    </>
  );
};

export default BasicData;
