import React, { useRef, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Template from "./Template";
import ReactToPrint from "react-to-print";
import { Button } from "../../../../stories/Button/Button";
import { studentPRProps } from "../Template1/Index";
import Close from "../../../../images/Close.svg";
import Configurations from "../../../Academics/Reports/Progress/Configurations";
import Modal from "react-modal";
import { PrintConfigModalStyles } from "../../../../styles/ModalStyles";
import Settings from "../../../../images/Settings.svg";

const Index = ({
  selectedStudents,
  setStudentIdSet,
  setModalFlag,
}: studentPRProps) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [configuration, setConfiguration] = useState(false);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title>Print Report Cards</Title>
        <img
          src={Settings}
          alt="/"
          onClick={() => setConfiguration(!configuration)}
        />
        {/* <img src={Close} alt="" onClick={() => setModalFlag(false)} /> */}
      </div>
      <div className="bgs-pn-pr">
        <div className="bgs-pn-pr__block" ref={componentRef}>
          <style type="text/css" media="print" scoped>
            {`
                  
                  body {  
                   height: 100% !important;
                         overflow: initial !important;
                         -webkit-print-color-adjust: exact;
                     }
              @page {
          size: A4 Portrait; 
          width:100%;
          height:100%;
              }
          .bgs-pn-pr__block{
            height:100% !important;
          }  
          .bgs-pn-pr__block--data{
            height:100% !important;
            padding:60px;
          }  
            `}
          </style>
          <Template
            selectedStudents={selectedStudents}
            setStudentIdSet={setStudentIdSet}
          />
        </div>
      </div>

      <ReactToPrint
        trigger={() => <Button mode="print" />}
        content={() => componentRef.current}
      />
      <Button mode="cancel" onClick={() => setModalFlag(false)} />
      <Modal
        ariaHideApp={false}
        style={PrintConfigModalStyles}
        isOpen={configuration}
      >
        <Configurations setModalFlag={setConfiguration} />
      </Modal>
    </>
  );
};

export default Index;
