import React, { useEffect, useState } from "react";
import { Button } from "../../../../../stories/Button/Button";
import { getModifiedScrollHeight } from "../../../../../utils/UtilFunctions";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "../../../../../images/Avatar.svg";

import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../../utils/constants";
import MessageModal from "../../../../../pages/MessageModal";
import { Operation, TableHeaders } from "../../../../../utils/Enum.types";
import { msgType } from "../../../../../utils/Form.types";
import useEmployee, {
  empQueryTypes,
  PayRollEmpEdges,
} from "../../../../HR/hooks/useEmployee";
import View from "../../../../../images/EyeWhite.svg";

import { AccountentType } from "../../../../Accounts/common/Enum.types";
import Input from "../../../../../stories/Input/Input";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../../styles/DataGridTableStyles";
import { TableHeaderProps } from "../../../../../utils/types";
import useAcademicTableJson from "../../../json/useAcademicTable";
export const enum ReportType {
  Formwise = "Formwise",
  Subwise = "Subwise",
  TeacherWise = "TeacherWise",
}
interface Props {
  pageType: ReportType;
}
const FormsReport = ({ pageType }: Props) => {
  const { FeedBackTeacherWise } = useAcademicTableJson();
  const navigate = useNavigate();
  const { InstId } = useParams();

  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);

  const [searchData, setSearchData] = useState("");
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const dataClasses = useDataGridStyles();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const {
    empDetails: { data, loading, fetchMore },
  } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.EMP_BY_TYPE,
    searchData,
    0,
    0,
    0,
    0,
    0,
    0,
    [AccountentType.TEACHER]
  );
  const dynamicHeaders: TableHeaderProps[] =
    FeedBackTeacherWise.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));
  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "actions",
      cellClassName: "td-status ",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        const empId = params.row.user_id;
        return (
          <>
            <button
              className="teacher-wise-allocated-reports__view"
              onClick={() =>
                navigate(
                  `/${InstId}/academics/reports/teacherwise/feedback/${empId}/formsByEmp`
                )
              }
            >
              View <img src={View} alt="" />
            </button>
          </>
        );
      },
    },
  ];

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      // navigate(`/${InstId}/academics/${testId}/examplanner/view`);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetPayRollEmp.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = employees.find(
            (employee) => employee.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setEmployees(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            name:
              node.emp_first_name + node.emp_middle_name + node.emp_last_name,
            mobile: node.emp_mobile,
            email: node.emp_email,
            user_type: node.emp_type,
            user_id: node.id,
            designation: node.pr_designation_details.designation_desc,
            emp_id: node.emp_id,
          }))
        );
      } else {
        setEmployees(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            name:
              node.emp_first_name + node.emp_middle_name + node.emp_last_name,
            mobile: node.emp_mobile,
            email: node.emp_email,
            user_type: node.emp_type,
            user_id: node.id,
            designation: node.pr_designation_details.designation_desc,
            emp_id: node.emp_id,
          }))
        );
      }
      setEndCursor(data.GetPayRollEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetPayRollEmp.edges;
              const pageInfo = fetchMoreResult.GetPayRollEmp.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetPayRollEmp.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetPayRollEmp: {
                  edges: [...employees, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetPayRollEmp.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  return (
    <>
      {pageType === ReportType.Formwise ? (
        <></>
      ) : (
        <>
          <div className="fb-teacher-wise-report__emp">
            <div className="fb-teacher-wise-report__emp--flex">
              <img src={Avatar} alt="" />
              <div className="fb-teacher-wise-report__emp--block">
                <b>karan</b>
                <span>Emp001</span>
              </div>
            </div>
          </div>
          <b className="fb-teacher-wise-report__subtitle">
            Allocated Forms List
          </b>
        </>
      )}

      <div
        className={
          pageType === ReportType.Formwise
            ? "fb-form-report__datablock"
            : "fb-teacher-wise-report__datablock"
        }
      >
        {/* <div className="fb-form-report__datablock--grids"> */}
        {/* {FormDropDown &&
            FormDropDown.GetAcdFeedbackForms.map((data, index) => {
              return (
                <div
                  className={
                    data.is_academic
                      ? "fb-form-report__datablock--grid"
                      : "fb-form-report__datablock--event"
                  }
                >
                  <b>{data.form_name}</b>
                  <span
                    className={
                      data.is_academic
                        ? "fb-form-report__datablock--grid--acd"
                        : "fb-form-report__datablock--event--acd"
                    }
                  >
                    {data.is_academic ? "Academic" : "Non-Academics"}
                  </span>
                  <div className="fb-form-report__datablock--grid--flex">
                    <TextField
                      className="fb-form-report__datablock--grid--textfield"
                      disabled
                      label="Start Date"
                      InputLabelProps={{ shrink: true }}
                      value={toStandardDate(data.form_st_date)}
                    />
                    <img src={Compare} alt="" />
                    <TextField
                      className="fb-form-report__datablock--grid--textfield"
                      disabled
                      label="End Date"
                      InputLabelProps={{ shrink: true }}
                      value={toStandardDate(data.form_end_date)}
                    />
                  </div>

                  <div className="fb-form-report__datablock--grid--flex">
                    <div className="fb-form-report__datablock--grid--block">
                      <span>Total Students</span>
                      <b className="font-blue">{data.total}</b>
                    </div>
                    <div className="fb-form-report__datablock--grid--block">
                      <span>Filled Form</span>
                      <b className="font-green">{data.filled}</b>
                    </div>
                  </div>

                  {data.is_report_generated ? (
                    <button
                      className="fb-form-report__datablock--grid--button"
                      onClick={() => {
                        pageType === ReportType.Formwise
                          ? navigate(
                              `/${InstId}/academics/reports/feedback/view`
                            )
                          : navigate(
                              `/${InstId}/academics/reports/teacherwise/feedback/view`
                            );
                      }}
                    >
                      View Report <img src={Eye} alt="" />
                    </button>
                  ) : (
                    <button
                      className="fb-form-report__datablock--grid--button"
                      onClick={() => {
                        handleSubmit(data.id);
                      }}
                    >
                      Finalize <img src={Eye} alt="" />
                    </button>
                  )}
                </div>
              );
            })} */}
        <div className="col-3">
          <Input
            id="search"
            placeholder="Search..."
            onChange={(e) => setSearchData(e.target.value)}
          />
        </div>
        <div
          className={`fb-teacher-wise-report__tableblock ${dataClasses.root}`}
        >
          <DataGridPro
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            disableRowSelectionOnClick
            hideFooter
            slotProps={{
              columnsPanel: { disableHideAllButton: true },
            }}
          />
        </div>
      </div>
      {/* </div> */}
      <Button mode="back" onClick={() => navigate(-1)} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default FormsReport;
