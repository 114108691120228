import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { TablePaginationActions } from "../../../../pages/CustomTablePagination";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { EmpBankScrollTableStyles } from "../../../../styles/StickyTableStyles";

import { TableHeaderProps } from "../../../../Types/Tables";
import {
  PAGINATION_ARRAY,
  ROWS_PER_PAGE,
  TABLE_DATA_PER_PAGE,
} from "../../../../utils/constants";
import { defaultLabelDisplayedRows } from "../../../../utils/UtilFunctions";
const { HR_Table } = require("../../json/table.json");

const enum stickyTableHeaders {
  SL = "Sl",
  Name = "Staff Name",
}

const BankScroll = () => {
  const listClasses = ListAutoCompleteStyles();
  const listTextClasses = ListAutoCompleteTextStyles();
  // const [{ payRollReportName }] = useContext(AppContext);
  // eslint-disable-next-line
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const classes = EmpBankScrollTableStyles();

  return (
    <>
      <div className="row g-0 payroll-reports__rightblock--details">
        <div className="col-2">
          <Input id="search" placeholder="Search..." type="text" />
        </div>
        <div className="col-2">
          <Autocomplete
            classes={listClasses}
            options={["a", "b"]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Departments"
                InputLabelProps={{ shrink: true }}
                classes={{ root: listTextClasses.formControlRoot }}
              />
            )}
          />
        </div>
        <div className="col-2">
          <Autocomplete
            classes={listClasses}
            options={["a", "b"]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                InputLabelProps={{ shrink: true }}
                classes={{ root: listTextClasses.formControlRoot }}
              />
            )}
          />
        </div>
        <div className="col-2">
          <Autocomplete
            classes={listClasses}
            options={["a", "b"]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Job Type"
                InputLabelProps={{ shrink: true }}
                classes={{ root: listTextClasses.formControlRoot }}
              />
            )}
          />
        </div>
      </div>
      <div className="payroll-reports__rightblock--data">
        <div className="payroll-reports__rightblock--data--title">
          <Title variant="subtitle1">Report Name</Title>
          <div>
            <TextField
              type="month"
              label="Test End Date"
              InputLabelProps={{ shrink: true }}
              value="2022-11"
              className="payroll-reports__payslip--details--textfield"
            />
          </div>
        </div>
        <div className="salary-process__prov-salary--table">
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  {HR_Table.SalaryProcess.ProvisionalSalaryDetails.StickyHeaders.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell
                          className={
                            th.labelName === stickyTableHeaders.SL
                              ? classes.stickyHeaderSl
                              : th.labelName === stickyTableHeaders.Name
                              ? classes.stickyHeaderName
                              : classes.stickyHeaderAccNo
                          }
                          key={index}
                          align="center"
                        >
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                  {HR_Table.Reports.BankScroll.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={classes.stickyHeader}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell id="td-center" className={classes.stickyColumnSl}>
                    1
                  </TableCell>
                  <TableCell className={classes.stickyColumnName}>aa</TableCell>
                  <TableCell className={classes.stickyColumnAccNo}>
                    21457
                  </TableCell>

                  <TableCell className={classes.stickyColumnDesc}>
                    21457
                  </TableCell>
                  <TableCell className={classes.stickyColumnDesc}>
                    Kotak Mahindra Bank
                  </TableCell>
                  <TableCell className={classes.stickyColumnDesc}>0</TableCell>
                  <TableCell className={classes.stickyColumnDesc}>0</TableCell>
                  <TableCell
                    className={classes.stickyColumnAmount}
                    id="td-right"
                  >
                    0
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={PAGINATION_ARRAY}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={defaultLabelDisplayedRows}
                    labelRowsPerPage={TABLE_DATA_PER_PAGE}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default BankScroll;
