import React, { useContext, useEffect } from "react";
import Home from "../../../../Master/Student/Dashboard/Home/Index";
import FormTypeImage from "../../../../../images/TotalPercentageMarked.svg";
import { Title } from "../../../../../stories/Title/Title";
import Compare from "../../../../../images/Compare.svg";
import { TextField } from "@mui/material";
import { Button } from "../../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Arrow from "../../../../../images/ArrowRight.svg";
import Completed from "../../../../../images/Assignments_Submitted.svg";
import Notcompleted from "../../../../../images/Assignments_Not_Submitted.svg";
import Assign from "../../../../../images/Assign.svg";
import Update from "../../../../../images/Update.svg";

import Avatar from "../../../../../images/Avatar.svg";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdSubjectAllotedToStudentData,
  GetAcdSubjectAllotedToStudentVars,
} from "../../../../../Types/Combinations/queries";
import { GetAcdSubjectAllotedToStudent } from "../../../../../queries/customallocation/combination/queries";
import useToken from "../../../../../customhooks/useToken";
import { AppContext } from "../../../../../context/context";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import { InstitutionConfigurationTypes } from "../../../../../utils/Enum.types";
import useCheckAllocationType from "../../../../Academics/hooks/useCheckAllocationType";
import { GetFormDataByFormId } from "../../../../Academics/queries/general";
import { toStandardDate } from "../../../../../utils/UtilFunctions";

const Form = () => {
  const { token } = useToken();
  const { formName, formId } = useParams();
  const { state } = useContext(AppContext);
  const { allocationId, studentEntryId } = useStudentDatabyId();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const { flag } = useCheckAllocationType(studentEntryId);

  const subjectAllocationLevel =
    configData && configData.data?.GetSwConfigVariables[0].config_string_value;

  const navigate = useNavigate();
  const { InstId } = useParams();
  const [GetSubjAllocatedForStudent, { data }] = useLazyQuery<
    GetAcdSubjectAllotedToStudentData,
    GetAcdSubjectAllotedToStudentVars
  >(GetAcdSubjectAllotedToStudent);
  const [GetFormDataById, { data: formData }] = useLazyQuery(
    GetFormDataByFormId,
    {
      variables: {
        id: Number(formId),
        token,
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (formId && token) {
      GetFormDataById();
    }
  }, [formId, GetFormDataById, token]);
  useEffect(() => {
    if (
      token &&
      state.studentId &&
      subjectAllocationLevel &&
      allocationId &&
      (flag === true || flag === false)
    ) {
      GetSubjAllocatedForStudent({
        variables: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          inst_id: Number(InstId)!,
          student_id: state.studentId,
          token,
          allotted_id: allocationId,
          allotted_level: subjectAllocationLevel!,
          per_std_subj_allocation: flag!,
          form_name_id: Number(formId),
        },
      });
    } // eslint-disable-next-line
  }, [
    token,
    GetSubjAllocatedForStudent,
    InstId,
    state.studentId,
    allocationId,
    state.ActiveAcdYr,
    flag,
  ]);

  return (
    <>
      <Home />
      <div className="formtype-form">
        <div className="formtype-form__title">
          <img src={FormTypeImage} alt="" />
          <Title>Feedback Form</Title>
        </div>
        <div className="formtype-form__flex">
          <div className="formtype-form__flex--header">
            <b>{formName}</b> &nbsp;
            <span className="formtype-form__flex--header--acd">
              {formData && formData.node.is_academic
                ? "Academic"
                : "Non-Academic"}
            </span>
          </div>
          <div className="formtype-form__flex--dates">
            <TextField
              className="formtype-form__flex--textfield"
              label="Start Date"
              value={formData && toStandardDate(formData.node.form_st_date)}
              disabled
              InputLabelProps={{ shrink: true }}
            />
            <img src={Compare} alt="" />
            <TextField
              className="formtype-form__flex--textfield"
              label="End Date"
              InputLabelProps={{ shrink: true }}
              disabled
              value={formData && toStandardDate(formData.node.form_end_date)}
            />
          </div>
        </div>
        <div className="formtype-form__datablock">
          <ul>
            {data &&
              data.GetAcdSubjectAllotedToStudent.map((res, index) => {
                return (
                  <li key={index}>
                    <div className="formtype-form__datablock--s">
                      <b className="formtype-form__datablock--s--name">
                        {res.subj_desc}
                      </b>
                      <span className="formtype-form__datablock--s--code">
                        {res.subj_code}
                      </span>
                    </div>
                    <div className="formtype-form__datablock--flex">
                      <div className="formtype-form__datablock--emp">
                        <img src={Avatar} alt="" />
                        <div className="formtype-form__datablock--emp--block">
                          <b className="formtype-form__datablock--emp--name">
                            {res.Teachers.map(
                              (data) =>
                                data.emp_first_name +
                                " " +
                                data.emp_middle_name +
                                " " +
                                data.emp_last_name
                            )}
                          </b>
                          <span className="formtype-form__datablock--emp--id">
                            {res.Teachers !== undefined
                              ? res.Teachers.map((res) => res.emp_id)
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="formtype-form__datablock--status">
                        <div
                          className={
                            res.form_filled_by_std === true
                              ? "formtype-form__datablock--status--comp"
                              : "formtype-form__datablock--status--not-comp"
                          }
                        >
                          {res.form_filled_by_std === true ? (
                            <>
                              <img src={Completed} alt="" />{" "}
                              <span>Completed</span>
                            </>
                          ) : (
                            <>
                              <img src={Notcompleted} alt="" />{" "}
                              <span>Not Completed</span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="formtype-form__datablock--arrow">
                        {res.form_filled_by_std ? (
                          <button
                            className="form-type__datablock--form--update"
                            onClick={() =>
                              navigate(
                                `/${InstId}/masters/studentlist/${
                                  state.studentId
                                }/${formName}/${formId}/${
                                  res.Teachers &&
                                  res.Teachers.length > 0 &&
                                  res.Teachers[0].pr_emp_id
                                    ? res.Teachers[0].pr_emp_id
                                    : 0
                                }/${
                                  res.subj_master_id
                                }/academics/feedback/preview`
                              )
                            }
                          >
                            Update Form <img src={Update} alt="" />
                          </button>
                        ) : (
                          <button
                            className="form-type__datablock--form--button"
                            onClick={() =>
                              navigate(
                                `/${InstId}/masters/studentlist/${
                                  state.studentId
                                }/${formName}/${formId}/${
                                  res.Teachers &&
                                  res.Teachers.length > 0 &&
                                  res.Teachers[0].pr_emp_id
                                    ? res.Teachers[0].pr_emp_id
                                    : 0
                                }/${
                                  res.subj_master_id
                                }/academics/feedback/fillform`
                              )
                            }
                          >
                            Fill Form <img src={Assign} alt="" />
                          </button>
                        )}

                        <img src={Arrow} alt="" />
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default Form;
