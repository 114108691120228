import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { AppContext } from "../../../context/context";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import Input from ".././../../stories/Input/Input";
import {
  StudentListFields,
  TableHeaders,
  StudentReportType,
  PageFor,
  ColumnVisibilityFor,
} from "../../../utils/Enum.types";
import Message from "../../../images/SendMessage.svg";
import More from "../../../images/More.svg";
import MessageListModal from "../../Channel/MessageList";
import Modal from "react-modal";
import Close from "../../../images/Close.svg";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../utils/constants";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import Home from "../Home/Index";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import { Keys } from "../../../utils/Enum.keys";
import useAcdStudentsData, { StudentEdges } from "../hooks/useAcdStudentsData";
import useAcdDropDownData from "../hooks/useAcdDropDownData";
import { responseType } from "../../../utils/Form.types";
import { payloadTypes } from "../../../context/reducer";
import StudentTotalCount from "../../Master/Student/Components/StudentTotalCount";
import LegendsInStudentList from "../../Master/Student/Components/LegendsInStudentList";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import { useStyles } from "../../../styles/TooltipStyles";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../utils/types";
import useStudentTableJson from "../../../json/useStudentTableJson";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../styles/DataGridTableStyles";
import { FetchStudentStatusClassName } from "../../Master/Student/List";

interface Props {
  pageType: PageFor;
  studentModal: boolean;
  setStudentModal: React.Dispatch<React.SetStateAction<boolean>>;
  queryType: StudentReportType;
  instSelected?: responseType | null;
}
const PassedOutStudentAcdList = ({
  pageType,
  setStudentModal,
  studentModal,
  queryType,
  instSelected,
}: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const navigate = useNavigate();
  const tooltipclasses = useStyles();
  const dataClasses = useDataGridStyles();

  const { dispatch } = useContext(AppContext);
  const [messageModal, setMessageModal] = useState(false);
  const [enableToolTipModal, setEnableToolTipModal] = useState(false);

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );

  const [searchData, setSearchData] = useState("");
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const { Student } = useStudentTableJson({
    jsonFor: ColumnVisibilityFor.FOR_PASS_ACD,
  });
  const {
    AcademicsStudentData: { data, loading, fetchMore },
  } = useAcdStudentsData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    0,
    ROWS_PER_PAGE,
    0,
    searchData,
    StudentReportType.PASSED_OUT
  );
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();

  var [studentListOptions] = useState<readonly string[]>([
    StudentListFields.STD_ADMISSION_NO,
    StudentListFields.STD_REG_NO,
    StudentListFields.STD_NAME,
    StudentListFields.STD_FATHER_NAME,
    StudentListFields.CATEGORY_ID,
    StudentListFields.STD_STATUS,
  ]);

  if (USE_CLASS_KEY === false) {
    studentListOptions = studentListOptions?.filter(
      (response) => response !== StudentListFields.CLASS_ID
    );
  }

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setSearchData("");
  };

  const dynamicHeaders: TableHeaderProps[] = Student.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "status",
      headerName: TableHeaders.STATUS,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-status",
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            className={`td-status__cell ${FetchStudentStatusClassName(
              params.row.status
            )}`}
          >
            {params.row.status}
          </div>
        );
      },
    },
    {
      field: "actions",
      cellClassName: "td-status ",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        const studentId = params.row.std_id || "";

        return (
          <>
            <Tooltip
              onClick={() => setEnableToolTipModal(!enableToolTipModal)}
              classes={{
                tooltip: tooltipclasses.studentOptions,
              }}
              placement="left-start"
              title={
                enableToolTipModal && (
                  <>
                    <ul>
                      <li
                        onClick={() => {
                          setMessageModal(!messageModal);
                          dispatch({
                            type: payloadTypes.SET_STUDENT_ID,
                            payload: { studentId: studentId },
                          });
                        }}
                        className="studentlist__table--more--fee"
                      >
                        <img src={Message} alt="/" />
                        <span> Send Message</span>
                      </li>
                    </ul>
                  </>
                )
              }
            >
              <img src={More} alt="/" />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_type: false,
      std_mother_name: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,
      std_email: false,
      std_mobile: false,
      std_roll_no: false,
      dept: USE_DEPARTMENT_KEY,
      branch: USE_BRANCH_KEY,
      class: USE_CLASS_KEY,
      sem: USE_SEMESTER_KEY,
      sec: USE_SECTION_KEY,
    });
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name: node.first_name,
            std_father_name: node.std_father_name,
            std_mobile: node.std_mobile,
            status: node.std_status,
            std_id: node.id,
            std_mother_name: node.std_mother_name,
            std_sex: node.std_sex,
            std_doa: toStandardDate(node.std_doa),
            std_dob: toStandardDate(node.std_dob),
            std_email: node.std_email,
            is_login_created: node.is_login_created,
            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            sem: node.acd_semester.sem_desc,
            sec: node.acd_section.section_desc,
            category: node.category.cat_desc,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name: node.first_name,
            std_father_name: node.std_father_name,
            std_mobile: node.std_mobile,
            status: node.std_status,
            std_id: node.id,
            std_mother_name: node.std_mother_name,
            std_sex: node.std_sex,
            std_doa: toStandardDate(node.std_doa),
            std_dob: toStandardDate(node.std_dob),
            std_email: node.std_email,
            is_login_created: node.is_login_created,
            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            sem: node.acd_semester.sem_desc,
            sec: node.acd_section.section_desc,
            category: node.category.cat_desc,
          }))
        );
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetAcdStudents.edges;
              const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetAcdStudents: {
                  edges: [...students, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.FOR_PASS_ACD
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.FOR_PASS_ACD,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);
  return (
    <div
      className={
        pageType === PageFor.GENERAL ? "studentlist" : "studentlist--modal"
      }
    >
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>Passed Out Student List</Title>
        </div>
      </div>
      <form
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 studentlist__options"
            : "row g-0 studentlist__options--modal"
        }
      >
        <div className="col-1 studentlist__select-flex studentlist__search">
          <Input
            id="search"
            type="text"
            placeholder="Search "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchData(e.target.value)
            }
            value={searchData}
            onKeyDown={handleFormEvent}
          />
        </div>

        {USE_DEPARTMENT_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={departmentDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, departmentSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (departmentSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setDepartmentSelected(null);
                }
              }}
              openOnFocus
              value={departmentSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setDepartmentSelected(newValue);
                  setHasNextPage(true);

                } else {
                  setDepartmentSelected(null);
                }
                setBranchSelected(null);
                setClassSelected(null);
                setSemesterSelected(null);
                setSectionSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={departmentLabel}
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
        {USE_BRANCH_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={branchDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, branchSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (branchSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(null);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue);
                } else {
                  setBranchSelected(null);
                }
                setClassSelected(null);
                setSemesterSelected(null);
                setSectionSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={branchLabel}
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
        {USE_CLASS_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={classDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, classSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (classSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassSelected(null);
                }
              }}
              openOnFocus
              value={classSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassSelected(newValue);
                } else {
                  setClassSelected(null);
                }
                setSemesterSelected(null);
                setSectionSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={classLabel}
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                  fullWidth
                />
              )}
            />
          </div>
        ) : null}
        {USE_SEMESTER_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={semesterDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, semesterSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (semesterSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSemesterSelected(null);
                }
              }}
              openOnFocus
              value={semesterSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSemesterSelected(newValue);
                } else {
                  setSemesterSelected(null);
                }
                setSectionSelected(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={semesterLabel}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
        {USE_SECTION_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={sectionDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, sectionSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (sectionSelected) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSectionSelected(null);
                }
              }}
              openOnFocus
              value={sectionSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSectionSelected(newValue);
                } else {
                  setSectionSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={sectionLabel}
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}

        <div className="col"></div>
        <div className="col-1">
          <Button
            mode="clear"
            onClick={(e: React.FormEvent) => {
              e.preventDefault();
              clear();
            }}
            className={
              pageType === PageFor.GENERAL
                ? "student-clear-button--acd"
                : "student-clear-button--modal"
            }
          />
        </div>
      </form>
      <div
        id={
          pageType === PageFor.MODAL
            ? "studentlist__transport--modal"
            : "studentlist__transport"
        }
      >
        <>
          <div className={`h100 ${dataClasses.root}`}>
            <DataGridPro
              columns={columns}
              rows={rows}
              rowHeight={TABLE_ROW_HEIGHT}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              onCellClick={(params) => {
                if (pageType === PageFor.MODAL && params.field === "std_name") {
                  dispatch({
                    type: payloadTypes.SET_STUDENT_ID,
                    payload: {
                      studentId: params.row.std_id,
                    },
                  });
                  setStudentModal(!studentModal);
                }
              }}
              disableRowSelectionOnClick
              hideFooter
              slotProps={{
                columnsPanel: { disableHideAllButton: true },
              }}
            />
          </div>
        </>
      </div>
      <div className="studentlist__buttons">
        {pageType === PageFor.GENERAL ? (
          <div className="button-left">
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        ) : (
          <Button mode="cancel" onClick={() => setStudentModal?.(false)} />
        )}
        <LegendsInStudentList />
        <StudentTotalCount totalCount={data?.GetAcdStudents.totalCount!} />
      </div>
      {/* MessageModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={messageModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MessageListModal
              pageType={PageFor.MODAL}
              setMessageModal={setMessageModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setMessageModal(!messageModal)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PassedOutStudentAcdList;
