import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import { SalaryProcessTitleProps } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import SalaryProcess from "./SalaryProcess";

import Save from "../../../../images/Save.svg";
import CashOutFlow from "../../../../images/CashOutFlow.svg";
import EmployeeGotSalary from "../../../../images/EmployeeGotSalary.svg";
import TotalMale from "../../../../images/TotalMale.svg";
import TotalFemale from "../../../../images/TotalFemale.svg";
import { Label } from "../../../../stories/Label/Label";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { SuccessModalCustomStyles } from "../../../../styles/ModalStyles";
import useSalaryMkWorkByMonth from "../../hooks/useSalaryMkWorkByMonth";
import useToken from "../../../../customhooks/useToken";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UpdatePrSalWorkSalCompleted } from "../../queries/salary/mutation";
import { msgType } from "../../../../utils/Form.types";
import { Operation } from "../../../../utils/Enum.types";
import MessageModal from "../../../../pages/MessageModal";
import {
  GetPayRollSalaryMkWorkByMonthId,
  GetPayRollSalaryProcessCount,
} from "../../queries/salary/list";
import { formatterDecimal, toIsoDate } from "../../../../utils/UtilFunctions";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
const { HRTitles } = require("../../json/title.json");
interface GetPayRollSalaryProcessCountList {
  total_emp_count: number;
  total_emp_male_count: number;
  total_emp_female_count: number;
  total_emp_sal_count: number;
  total_emp_sal_mk_basic: number;
  total_emp_sal_mk_earned_basic: number;
  total_emp_sal_mk_other_earn: number;
  total_emp_sal_mk_gross: number;
  total_emp_sal_mk_others_ded: number;
  total_emp_sal_mk_net: number;
}
export interface GetPayRollSalaryProcessCountData {
  GetPayRollSalaryProcessCount: GetPayRollSalaryProcessCountList;
}

export interface GetPayRollSalaryProcessCountVars {
  token: string;
  inst_id: string;
  pr_acd_yr_id: number;
  pr_sal_mk_month_id: string;
}
const CloseSalaryProcess = () => {
  const navigate = useNavigate();
  const [saveModal, setSaveModal] = useState(false);
  const { token } = useToken();
  const { monthId, InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const { format } = formatterDecimal;
  const { monthlyData, pr_acd_yr } = useSalaryMkWorkByMonth(monthId!);

  const { sal_wrk_job_completed } = monthlyData.data?.node || {};
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { serverDate } = useServerDateandTime();
  const [GetSalaryProcessCount, { data }] = useLazyQuery<
    GetPayRollSalaryProcessCountData,
    GetPayRollSalaryProcessCountVars
  >(GetPayRollSalaryProcessCount, {
    variables: {
      inst_id: InstId!,
      pr_acd_yr_id: pr_acd_yr?.id!,
      token,
      pr_sal_mk_month_id: monthId!,
    },
  });

  const {
    total_emp_count,
    total_emp_male_count,
    total_emp_female_count,
    total_emp_sal_count,
    total_emp_sal_mk_net,
  } = data?.GetPayRollSalaryProcessCount || {};
  const [completeSalaryProcess] = useMutation(UpdatePrSalWorkSalCompleted, {
    onError: (e) =>
      setMessage({
        operation: Operation.NONE,
        message: e.message,
        flag: true,
      }),
  });
  const completeSalary = () => {
    completeSalaryProcess({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        pr_sal_mk_month_id: monthlyData.data?.node.id!,
        month_start_date: toIsoDate(serverDate),
      },
      refetchQueries: [
        {
          query: GetPayRollSalaryMkWorkByMonthId,
          variables: {
            token,
            inst_id: InstId!,
            id: Number(monthId),
          },
        },
      ],
    }).then(({ data }) => {
      if (data) navigate(`/${InstId}/hr/payroll/salaryprocess/selectmonth`);
    });
  };
  const handleClose = () =>
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  useEffect(() => {
    if (pr_acd_yr) {
      GetSalaryProcessCount();
    }
  }, [pr_acd_yr, GetSalaryProcessCount]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {HRTitles.SalaryProcess.Titles.map(
          (title: SalaryProcessTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.CloseSalaryProcess}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="salary-process">
        <div className="salary-process__data">
          <div className="salary-process__data--left-block">
            <SalaryProcess monthDetails={monthlyData.data?.node!} />
          </div>
          <div className="salary-process__data--right-block">
            <div className="row g-0 salary-process__close-salary--textfield">
              <div className="col"></div>
              <div className="col-2">
                <TextField
                  type="month"
                  label="Salary Process Month"
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value="2022-11"
                  className="salary-process__prov-salary--label--textfield"
                />
              </div>
            </div>
            <div className="row g-0 salary-process__close-salary--details">
              <div className="col salary-process__close-salary--details--block">
                <Title variant="subtitle1">Total Employees</Title>
                <span>{total_emp_count}</span>
                <div className="salary-process__close-salary--details--block--image-flex">
                  <div className="salary-process__close-salary--details--block--image-flex--block">
                    <img src={TotalMale} alt="/" />
                    <span>{total_emp_male_count}</span>
                    <Label>Male</Label>
                  </div>
                  <div className="salary-process__close-salary--details--block--image-flex--block">
                    <img src={TotalFemale} alt="/" />
                    <span>{total_emp_female_count}</span>
                    <Label>Female</Label>
                  </div>
                </div>
              </div>
              <div className="col salary-process__close-salary--details--block">
                <Title variant="subtitle1">Total Employees Got Salary</Title>
                <div className="salary-process__close-salary--details--block--image-flex2">
                  <img src={EmployeeGotSalary} alt="/" />
                  <span>{total_emp_sal_count}</span>
                </div>
              </div>
              <div className="col salary-process__close-salary--details--block">
                <Title variant="subtitle1">Total Cash Outflow This Month</Title>
                <div className="salary-process__close-salary--details--block--image-flex3">
                  <img src={CashOutFlow} alt="/" />
                  <span>{format(total_emp_sal_mk_net!)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          onClick={() => setSaveModal(!saveModal)}
          disabled={sal_wrk_job_completed}
        >
          <img src={Save} alt="/" />
          Close Salary for This Month
        </Button>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={saveModal}
        style={SuccessModalCustomStyles}
        ariaHideApp={false}
      >
        <Title>Close Salary Process</Title>
        <div>
          <Label>
            Are You Sure You Wanted to Close this Month Salary Process
          </Label>
        </div>
        <Button mode="yes" onClick={completeSalary} />
        <Button mode="cancel" onClick={() => setSaveModal(!saveModal)} />
      </Modal>

      <MessageModal
        modalFlag={message.flag}
        handleClose={handleClose}
        value={message.message}
        operation={message.operation}
      />
    </>
  );
};

export default CloseSalaryProcess;
