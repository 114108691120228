import { gql } from "@apollo/client";

export const AddDemoEnquiry = gql`
  mutation AddDemoEnquiry($input: CreateDemoEnquiryInput!) {
    AddDemoEnquiry(input: $input)
  }
`;

export const GetDemoEnquires = gql`
  query GetDemoEnquires(
    $name: String
    $first: Int
    $after: Cursor
    $sortBy: DemoEnquiryOrderField
    $direction: OrderDirection!
    $token: String!
  ) {
    GetDemoEnquires(
      token: $token
      first: $first
      after: $after
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ demoEnqInstNameContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          created_at
          demo_enq_name
          demo_enq_designation
          demo_enq_mobile_no
          demo_enq_email
          demo_enq_inst_name
          demo_enq_comments
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
