import { optionsType } from "../../../utils/Form.types";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import { StudentStatus } from "../../../utils/Enum.types";
import { Autocomplete, TextField } from "@mui/material";
import { isOptionEqualToValue } from "../../../utils/UtilFunctions";
interface Props {
  statusValue: string;
  onChange: (newValue: optionsType | null) => void;
  label: string;
}

export const StatusOptions = [
  {
    label: "Current",
    value: StudentStatus.CUR,
  },
  {
    label: "Transfer Certificate (TC)",
    value: StudentStatus.TC,
  },
  {
    label: "Detained",
    value: StudentStatus.DET,
  },
  {
    label: "Not Eligible",
    value: StudentStatus.NE,
  },
  {
    label: "Reservation",
    value: StudentStatus.NXT,
  },
  {
    label: "Shortage of Attendance",
    value: StudentStatus.SOA,
  },
  {
    label: "Cancelled",
    value: StudentStatus.CXLD,
  },
  {
    label: "Passed Out",
    value: StudentStatus.PASS,
  },
];
const StatusAutocomplete = ({ statusValue, onChange, label }: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();

  const withoutLabelClass = formAutoCompleteStyles();
  const withoutLabelTextClass = formAutoCompleteTextStyles();

  const foundValue = StatusOptions.find(({ value }) => value === statusValue);
  const autoValue = foundValue ? foundValue : null;
  return (
    <Autocomplete
      classes={label ? classes : withoutLabelClass}
      options={StatusOptions}
      openOnFocus={true}
      {...(statusValue ? { value: autoValue } : {})}
      onChange={(
        e: React.SyntheticEvent<Element, Event>,
        newValue: optionsType | null
      ) => onChange(newValue)}
      isOptionEqualToValue={(option) => isOptionEqualToValue(option, autoValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || ""}
          fullWidth={true}
          InputLabelProps={{ shrink: label.length > 0 }}
          classes={{
            root: label
              ? textClasses.formControlRoot
              : withoutLabelTextClass.formControlRoot,
          }}
        />
      )}
    />
  );
};

export default StatusAutocomplete;
