import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { TableHeaderProps } from "../../../../Types/Tables";
import { StudentTitles } from "../../../../Types/Titles";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
const { Students } = require("../../../../json/title.json");

const options = ["1", "2"];

const tableData = [
  {
    admno: "1",
    name: "prerana",
    regno: "1",
    prevyr: "ME-3rd Year",
    curyr: "ME 3 yr",
    reason: "Student Character not good",
  },
];

const ViewDetainedStudentList = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { Masters_Table } = useMasterTableJson();
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  return (
    <>
      <Title>
        {Students.Titles.map((title: StudentTitles, index: React.Key) => {
          return (
            <React.Fragment key={index}>
              {title.Detained_Student_List}
            </React.Fragment>
          );
        })}
      </Title>
      <div className="row g-0 detained-student-list__view">
        <div className="col-3 detained-student-list__view--search">
          <Input id="search" placeholder="Search..." />
          <Autocomplete
            classes={classes}
            options={options}
            openOnFocus
            onChange={(e, newValue) => {}}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search By"
                InputLabelProps={{ shrink: true }}
                id="outlined Search By"
                classes={{ root: textClasses.formControlRoot }}
              />
            )}
          />
        </div>
        <div className="col detained-student-list__view--select-flex">
          <Autocomplete
            classes={classes}
            options={options}
            openOnFocus
            onChange={(e, newValue) => {}}
            renderInput={(params) => (
              <TextField
                {...params}
                label={departmentLabel}
                InputLabelProps={{ shrink: true }}
                classes={{ root: textClasses.formControlRoot }}
              />
            )}
          />
          <Autocomplete
            classes={classes}
            options={options}
            openOnFocus
            onChange={(e, newValue) => {}}
            renderInput={(params) => (
              <TextField
                {...params}
                label={branchLabel}
                InputLabelProps={{ shrink: true }}
                classes={{ root: textClasses.formControlRoot }}
              />
            )}
          />
          <Autocomplete
            classes={classes}
            options={options}
            openOnFocus
            onChange={(e, newValue) => {}}
            renderInput={(params) => (
              <TextField
                {...params}
                label={classLabel}
                InputLabelProps={{ shrink: true }}
                classes={{ root: textClasses.formControlRoot }}
              />
            )}
          />
          <Autocomplete
            classes={classes}
            options={options}
            openOnFocus
            onChange={(e, newValue) => {}}
            renderInput={(params) => (
              <TextField
                {...params}
                label={semesterLabel}
                InputLabelProps={{ shrink: true }}
                classes={{ root: textClasses.formControlRoot }}
              />
            )}
          />
          <Autocomplete
            classes={classes}
            options={options}
            openOnFocus
            onChange={(e, newValue) => {}}
            renderInput={(params) => (
              <TextField
                {...params}
                label={sectionLabel}
                InputLabelProps={{ shrink: true }}
                classes={{ root: textClasses.formControlRoot }}
              />
            )}
          />
        </div>
      </div>
      <TableContainer className="detained-student-list__view--table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {Masters_Table.Students.DetainedStudentList.Table_Headers.map(
                (th: TableHeaderProps, index: React.Key) => {
                  return (
                    <TableCell key={index} className={th.className}>
                      {th.labelName}
                    </TableCell>
                  );
                }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((data, index) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    id="td-center"
                    className="detained-student-list__view--table--slno"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell className="detained-student-list__view--table--admno">
                    {data.admno}
                  </TableCell>
                  <TableCell className="detained-student-list__view--table--name">
                    {data.name}
                  </TableCell>
                  <TableCell className="detained-student-list__view--table--regno">
                    {data.regno}
                  </TableCell>
                  <TableCell className="detained-student-list__view--table--year">
                    {data.prevyr}
                  </TableCell>
                  <TableCell className="detained-student-list__view--table--year">
                    {data.curyr}
                  </TableCell>
                  <TableCell>{data.reason}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ViewDetainedStudentList;
