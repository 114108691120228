import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import Compare from "../../../../images/Compare.svg";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import InputNumber from "../../../../components/common/Input/InputNumber";
import { Button } from "../../../../stories/Button/Button";
import Modal from "react-modal";
import Export from "./Export";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import { optionsType } from "../../../../utils/Form.types";
import { StatesContext } from "./GlobalStates/StatesProvider";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useInstitutionDetailsDataByInstId from "../../../../customhooks/useInstitutionDetailsDataByInstId";
import {
  InstitutionDetails,
  PredefinedDataTypes,
} from "../../../../utils/Enum.types";
import { payloadType } from "./GlobalStates/types";
import PredefinedAutocomplete from "../../../../components/common/PredefinedAutocomplete/PredefinedAutocomplete";
import { ExcelAcctQueryType, ExcelAdmQueryType } from "./useExportMutation";
import useAcdDropDownData from "../../../Academics/hooks/useAcdDropDownData";
import { Label } from "../../../../stories/Label/Label";
import { EMPTY_STRING } from "../../../../utils/constants";
import AgentOptionsAutocomplete from "../../../Accounts/Agents/Components/AgentOptionsAutocomplete";
interface Props {
  reportType: ExcelAdmQueryType | ExcelAcctQueryType;
}
enum ChosenType {
  ALL = "ALL",
  PARTICULAR = "PARTICULAR",
}

const GenerateReport = ({ reportType }: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { state, dispatch } = useContext(StatesContext);
  const [statesChange, setStatesChange] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(ChosenType.ALL);
  const [selectedBranch, setSelectedBranch] = useState(ChosenType.ALL);
  const [selectedClass, setSelectedClass] = useState(ChosenType.ALL);
  const [selectedSem, setSelectedSem] = useState(ChosenType.ALL);
  const [selectedSection, setSelectedSection] = useState(ChosenType.ALL);
  const [selectedCategory, setSelectedCategory] = useState(ChosenType.ALL);

  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel
  } = useInstLabels();
  const {
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();
  const basicOptions: (optionsType | null)[] = [
    {
      label: "Admission Number",
      value: "std_adm_no",
    },
    {
      label: "Register Number",
      value: "std_reg_no",
    },
    {
      label: "Student Status",
      value: "std_status",
    },
    {
      label: "Gender",
      value: "std_sex",
    },
    {
      label: "STS No",
      value: "std_sts_no",
    },
    {
      label: "Date of Admission",
      value: "std_doa",
    },
    {
      label: "Fresher",
      value: "std_fresher",
    },
    {
      label: "Date of Birth",
      value: "std_dob",
    },
    {
      label: "Email",
      value: "std_email",
    },
    {
      label: "Mobile",
      value: "std_mobile",
    },
    {
      label: "Father Name",
      value: "std_father_name",
    },
    {
      label: "Father Mobile",
      value: "std_father_mobile",
    },
    {
      label: "Mother Name",
      value: "std_mother_name",
    },
    {
      label: "Mother Mobile",
      value: "std_mother_mobile",
    },

    USE_DEPARTMENT_KEY
      ? {
          label: `${departmentLabel}`,
          value: "dept",
        }
      : null,
    USE_BRANCH_KEY
      ? {
          label: `${branchLabel}`,
          value: "branch",
        }
      : null,
    USE_CLASS_KEY
      ? {
          label: `${classLabel}`,
          value: "class",
        }
      : null,
    USE_SEMESTER_KEY
      ? {
          label: `${semesterLabel}`,
          value: "semester",
        }
      : null,
    USE_SECTION_KEY
      ? {
          label: `${sectionLabel}`,
          value: "section",
        }
      : null,
    USE_CATEGORY_KEY
      ? {
          label: `${categoryLabel}`,
          value: "category",
        }
      : null,
  ].filter((opt) => opt !== null);

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    state.dept ? state.dept.value : 0,
    state.branch ? state.branch.value : 0,
    state.class ? state.class.value : 0,
    state.sem ? state.sem.value : 0
  );

  const { categoryDropDown } = useInstitutionDetailsDataByInstId(
    InstitutionDetails.CATEGORY
  );

  const [customizeDetails, setCustomizeDetails] = useState(false);

  const handleChange = (
    newValue: optionsType | null,
    type: PredefinedDataTypes
  ) => {
    dispatch({
      payload: {
        dept: {
          label: "De",
          value: 0,
        },
      },
      type: InstitutionDetails.DEPARTMENT,
    });
    if (type === PredefinedDataTypes.CASTE) {
      if (newValue) {
        console.log(newValue, "sdad");

        dispatch({
          payload: {
            caste: newValue,
          },
          type: payloadType.CASTE,
        });
      }
    } else {
      if (newValue) {
        dispatch({
          payload: {
            religion: newValue,
          },
          type: payloadType.RELIGION,
        });
      } else {
        dispatch({
          payload: {
            religion: null,
          },
          type: payloadType.RELIGION,
        });
      }
    }
    setStatesChange(!statesChange);
  };

  return (
    <>
      <div className="inst-level-report__md">
        <div className="inst-level-report__md--header">
          <b>Generate Report</b>
        </div>
        <div className="inst-level-report__md--block">
          {USE_DEPARTMENT_KEY ? (
            <div className="inst-level-report__md--block--flex">
              <FormControl>
                <RadioGroup
                  row
                  value={selectedDepartment}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = e.target;
                    if (value === ChosenType.ALL) {
                      dispatch({
                        type: InstitutionDetails.DEPARTMENT,
                        payload: {
                          dept: null,
                        },
                      });
                    }
                    setSelectedDepartment(value as ChosenType);
                  }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={`All ${departmentLabel}`}
                    value={ChosenType.ALL}
                  />
                  <img
                    src={Compare}
                    alt=""
                    className="inst-level-report__md--block--compare"
                  />
                  <FormControlLabel
                    value={ChosenType.PARTICULAR}
                    control={<Radio />}
                    label={`Particular ${departmentLabel}`}
                  />
                </RadioGroup>
              </FormControl>
              <Autocomplete
                classes={classes}
                options={departmentDropDown}
                openOnFocus
                value={state.dept}
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: InstitutionDetails.DEPARTMENT,
                      payload: {
                        dept: newValue,
                      },
                    });
                  } else {
                    dispatch({
                      type: InstitutionDetails.DEPARTMENT,
                      payload: {
                        dept: null,
                      },
                    });
                  }
                }}
                disabled={selectedDepartment === ChosenType.ALL}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="inst-level-report__md--block--flex">
              <FormControl>
                <RadioGroup
                  value={selectedBranch}
                  row
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = e.target;
                    if (value === ChosenType.ALL) {
                      dispatch({
                        type: InstitutionDetails.BRANCH,
                        payload: {
                          branch: null,
                        },
                      });
                    }
                    setSelectedBranch(value as ChosenType);
                  }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={`All ${branchLabel}`}
                    value={ChosenType.ALL}
                  />
                  <img
                    src={Compare}
                    alt=""
                    className="inst-level-report__md--block--compare"
                  />
                  <FormControlLabel
                    value={ChosenType.PARTICULAR}
                    control={<Radio />}
                    label={`Particular ${branchLabel}`}
                  />
                </RadioGroup>
              </FormControl>
              <Autocomplete
                classes={classes}
                options={branchDropDown}
                openOnFocus
                value={state.branch}
                disabled={selectedBranch === ChosenType.ALL}
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: InstitutionDetails.BRANCH,
                      payload: {
                        branch: newValue,
                      },
                    });
                  } else {
                    dispatch({
                      type: InstitutionDetails.BRANCH,
                      payload: {
                        branch: null,
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_CLASS_KEY ? (
            <div className="inst-level-report__md--block--flex">
              <FormControl>
                <RadioGroup
                  row
                  value={selectedClass}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = e.target;
                    if (value === ChosenType.ALL) {
                      dispatch({
                        type: InstitutionDetails.CLASS,
                        payload: {
                          class: null,
                        },
                      });
                    }
                    setSelectedClass(value as ChosenType);
                  }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={`All ${classLabel}`}
                    value={ChosenType.ALL}
                  />
                  <img
                    src={Compare}
                    alt=""
                    className="inst-level-report__md--block--compare"
                  />
                  <FormControlLabel
                    value={ChosenType.PARTICULAR}
                    control={<Radio />}
                    label={`Particular ${classLabel}`}
                  />
                </RadioGroup>
              </FormControl>
              <Autocomplete
                classes={classes}
                options={classDropDown}
                openOnFocus
                value={state.class}
                disabled={selectedClass === ChosenType.ALL}
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: InstitutionDetails.CLASS,
                      payload: {
                        class: newValue,
                      },
                    });
                  } else {
                    dispatch({
                      type: InstitutionDetails.CLASS,
                      payload: {
                        class: null,
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="inst-level-report__md--block--flex">
              <FormControl>
                <RadioGroup
                  row
                  value={selectedSem}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = e.target;
                    if (value === ChosenType.ALL) {
                      dispatch({
                        type: InstitutionDetails.SEMESTER,
                        payload: {
                          sem: null,
                        },
                      });
                    }
                    setSelectedSem(value as ChosenType);
                  }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={`All ${semesterLabel}`}
                    value={ChosenType.ALL}
                  />
                  <img
                    src={Compare}
                    alt=""
                    className="inst-level-report__md--block--compare"
                  />
                  <FormControlLabel
                    value={ChosenType.PARTICULAR}
                    control={<Radio />}
                    label={`Particular ${semesterLabel}`}
                  />
                </RadioGroup>
              </FormControl>
              <Autocomplete
                classes={classes}
                options={semesterDropDown}
                disabled={selectedSem === ChosenType.ALL}
                openOnFocus
                value={state.sem}
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: InstitutionDetails.SEMESTER,
                      payload: {
                        sem: newValue,
                      },
                    });
                  } else {
                    dispatch({
                      type: InstitutionDetails.SEMESTER,
                      payload: {
                        sem: null,
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="inst-level-report__md--block--flex">
              <FormControl>
                <RadioGroup
                  row
                  value={selectedSection}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = e.target;
                    if (value === ChosenType.ALL) {
                      dispatch({
                        type: InstitutionDetails.SECTION,
                        payload: {
                          section: null,
                        },
                      });
                    }
                    setSelectedSection(value as ChosenType);
                  }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={`All ${sectionLabel}`}
                    value={ChosenType.ALL}
                  />
                  <img
                    src={Compare}
                    alt=""
                    className="inst-level-report__md--block--compare"
                  />
                  <FormControlLabel
                    value={ChosenType.PARTICULAR}
                    control={<Radio />}
                    label={`Particular ${sectionLabel}`}
                  />
                </RadioGroup>
              </FormControl>
              <Autocomplete
                classes={classes}
                options={sectionDropDown}
                disabled={selectedSection === ChosenType.ALL}
                openOnFocus
                value={state.section}
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: InstitutionDetails.SECTION,
                      payload: {
                        section: newValue,
                      },
                    });
                  } else {
                    dispatch({
                      type: InstitutionDetails.SECTION,
                      payload: {
                        section: null,
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CATEGORY_KEY ? (
            <div className="inst-level-report__md--block--flex">
              <FormControl>
                <RadioGroup
                  row
                  value={selectedCategory}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = e.target;
                    if (value === ChosenType.ALL) {
                      dispatch({
                        type: InstitutionDetails.CATEGORY,
                        payload: {
                          category: null,
                        },
                      });
                    }
                    setSelectedCategory(value as ChosenType);
                  }}
                >
                  <div className="inst-level-report__md--block--left">
                    <FormControlLabel
                      control={<Radio />}
                      label={`All ${categoryLabel}`}
                      value={ChosenType.ALL}
                    />
                  </div>

                  <img
                    src={Compare}
                    alt=""
                    className="inst-level-report__md--block--compare"
                  />
                  <FormControlLabel
                    value={ChosenType.PARTICULAR}
                    control={<Radio />}
                    label={`Particular ${categoryLabel}`}
                  />
                </RadioGroup>
              </FormControl>
              <Autocomplete
                classes={classes}
                options={categoryDropDown}
                disabled={selectedCategory === ChosenType.ALL}
                openOnFocus
                value={state.category}
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: InstitutionDetails.CATEGORY,
                      payload: {
                        category: newValue,
                      },
                    });
                  } else {
                    dispatch({
                      type: InstitutionDetails.CATEGORY,
                      payload: {
                        category: null,
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {reportType === ExcelAdmQueryType.RELIGION_STUDENT_LIST && (
            <div className="inst-level-report__md--block--flex">
              <Label>Select Religion</Label>
              <PredefinedAutocomplete
                label=""
                type={PredefinedDataTypes.RELIGION}
                onChange={handleChange}
                value={state.religion ? state.religion.value : EMPTY_STRING}
              />
            </div>
          )}
          {reportType === ExcelAdmQueryType.AGE_STUDENT_LIST && (
            <div className="inst-level-report__md--block--flex">
              <Label>Enter Age</Label>
              <InputNumber
                placeholder="Above Age"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch({
                    type: payloadType.ABOVE_AGE,
                    payload: {
                      aboveAge: Number(e.target.value),
                    },
                  });
                }}
                className="inst-level-report__md--block--age"
              />
              <InputNumber
                placeholder="Below Age"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch({
                    type: payloadType.BELOW_AGE,
                    payload: {
                      belowAge: Number(e.target.value),
                    },
                  });
                }}
                className="inst-level-report__md--block--age"
              />
            </div>
          )}
          {reportType === ExcelAdmQueryType.CASTE_STUDENT_LIST && (
            <div className="inst-level-report__md--block--flex">
              <Label>Select Caste</Label>
              <PredefinedAutocomplete
                label=""
                type={PredefinedDataTypes.CASTE}
                onChange={handleChange}
                value={state.caste ? state.caste.value : EMPTY_STRING}
              />
            </div>
          )}
          {reportType === ExcelAcctQueryType.ACCT_STD_FEE_BY_AGENT_REPORT && (
            <div className="inst-level-report__md--block--flex">
              <Label>By Agent</Label>
              <AgentOptionsAutocomplete />
            </div>
          )}
        </div>
        <div className="inst-level-report__md--buttons">
          <Button
            mode="excel"
            disabled={
              (reportType === ExcelAdmQueryType.CASTE_STUDENT_LIST &&
                state.caste === null) ||
              (reportType === ExcelAdmQueryType.RELIGION_STUDENT_LIST &&
                state.religion === null)
            }
            onClick={() => {
              setCustomizeDetails(!customizeDetails);
              dispatch({
                type: payloadType.FIELDS,
                payload: {
                  selectedFields: [],
                },
              });
            }}
          >
            Initialize Report
          </Button>
        </div>
      </div>
      <Modal
        isOpen={customizeDetails}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <Export setModal={setCustomizeDetails} reportType={reportType} />
      </Modal>
    </>
  );
};

export default GenerateReport;
