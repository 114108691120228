import { useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import React, { useContext, useState } from "react";
import { AppContext } from "../../../../context/context";
import { useMutation } from "@apollo/client";
import { AcctMakeIciciOnlinePayment } from "../../../../queries/common";
import { userDetails } from "../../../../Types/Accounting/other";
import useToken from "../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import {
  DebitOrCredit,
  InstitutionConfigurationTypes,
  Operation,
} from "../../../../utils/Enum.types";
import { EMPTY_STRING } from "../../../../utils/constants";
import { studentRecepit } from "../../../../Types/Accounting";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { toIsoDate } from "../../../../utils/UtilFunctions";
import { msgType } from "../../../../utils/Form.types";
import MessageModal from "../../../../pages/MessageModal";
import LoadingModal from "../../../../pages/LoadingModal";

export interface MakeAcctMakeIciciOnlinePaymentVars {
  token: string;
  inst_id: string;
  user_details: userDetails;
  student_id: number;
  transaction_amount: string;
  payment_mode: string;
  mobile_payment: boolean;
  input: {
    vo_date: String;
    vo_cr_db: String;
    vo_sl_no: number;
    vo_cr: number;
    vo_db: number;
    vo_cr_total: number;
    vo_db_total: number;
    acct_ldgr_id: number;
  }[];
}

export interface MakeAcctMakeIciciOnlinePaymentData {
  AcctMakeIciciOnlinePayment: {
    encrypted_mandatory_fields: string;
    encrypted_optional_fields: string;
    encrypted_return_url: string;
    encrypted_sub_merchant_id: string;
    encrypted_transaction_amount: string;
    encrypted_payment_mode: string;
    encrypted_reference_no: string;
  };
}
export const useOnlinePayment = (
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>,
  items: studentRecepit[],
  totalFineAmt: number
): {
  mutationFlag: boolean;
  component: React.ReactElement;
} => {
  // Reference N unique
  // mandatory fields=456789|45|1 encrypted
  // sonali.rajesh@icicibank.com
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });

  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  let paymentGateWayComponent: React.ReactElement = <></>;
  const [
    MakeAcctMakeIciciOnlinePayment,
    { loading: AcctMakeIciciMutationLoading },
  ] = useMutation<
    MakeAcctMakeIciciOnlinePaymentData,
    MakeAcctMakeIciciOnlinePaymentVars
  >(AcctMakeIciciOnlinePayment, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  // const encryptionKey = "6000042803405012";
  const {
    configData: { data: paymentGatewayConfig, error },
  } = useSwConfigData(InstitutionConfigurationTypes.PAYMENT_GATEWAY);
  const { serverDate } = useServerDateandTime();
  const {
    config_string_value,
    config_string_value_2: aesEncryptionKey,
    config_integer_value: defaultBank,
  } = paymentGatewayConfig &&
  paymentGatewayConfig.GetSwConfigVariables.length > 0
    ? paymentGatewayConfig.GetSwConfigVariables[0]
    : {
        config_string_value: "",
        config_string_value_2: "",
        config_integer_value: 0,
      };

  const payMode = "9";
  // the mandatory feilds should be in the same order, refrencenumber|submerchantId|amount do not alter the order
  const totalAmount = items.reduce((acc, item) => {
    acc += item.rcpt_amt!;

    return acc;
  }, 0);
  const handleOnlinePayment = () => {
    if (totalAmount > 0) {
      if (serverDate && defaultBank) {
        MakeAcctMakeIciciOnlinePayment({
          variables: {
            token,
            inst_id: InstId ? InstId : state.InstId.toString(),
            user_details,
            student_id: state.studentId,
            transaction_amount: (totalAmount + totalFineAmt).toString(),
            payment_mode: payMode,
            mobile_payment: false,
            input: items.map(({ rcpt_amt, acct_ldgr_details }, index) => ({
              vo_date: toIsoDate(serverDate),
              vo_cr_db: DebitOrCredit.CREDIT,
              vo_sl_no: index + 1,
              vo_cr: rcpt_amt,
              vo_db: 0,
              vo_cr_total: totalAmount + totalFineAmt,
              vo_db_total: 0,
              acct_ldgr_id: acct_ldgr_details.id,
            })),
          },
          fetchPolicy: "network-only",
        }).then(({ data }) => {
          if (data && data.AcctMakeIciciOnlinePayment) {
            const {
              encrypted_mandatory_fields,
              encrypted_optional_fields,
              encrypted_payment_mode,
              encrypted_reference_no,
              encrypted_return_url,
              encrypted_sub_merchant_id,
              encrypted_transaction_amount,
            } = data.AcctMakeIciciOnlinePayment;

            if (
              encrypted_mandatory_fields &&
              encrypted_payment_mode &&
              encrypted_reference_no &&
              encrypted_return_url &&
              encrypted_sub_merchant_id &&
              encrypted_transaction_amount &&
              aesEncryptionKey &&
              config_string_value
            ) {
              window.location.href = `https://eazypayuat.icicibank.com/EazyPG?merchantid=600343&mandatory%20fields=${encrypted_mandatory_fields}&optional%20fields=&returnurl=${encrypted_return_url}&Reference%20No=${encrypted_reference_no}&submerchantid=${encrypted_sub_merchant_id}&transaction%20amount=${encrypted_transaction_amount}&paymode=${encrypted_payment_mode}`;
            }
          }
        });
      } else {
        if (serverDate === "") {
          setMessage({
            flag: true,
            message: "Invalid Today Date",
            operation: Operation.NONE,
          });
        }
        if (defaultBank < 1) {
          setMessage({
            flag: true,
            message: "Invalid Bank Details",
            operation: Operation.NONE,
          });
        }
      }
    } else {
      setMessage({
        flag: true,
        message: " Amount not found",
        operation: Operation.NONE,
      });
    }
  };

  if ((error && error.message) || !config_string_value || !aesEncryptionKey) {
    paymentGateWayComponent = (
      <b className="nodata">
        {error
          ? error.message
          : !config_string_value
          ? "Payment Gateway Type not found"
          : !aesEncryptionKey
          ? "Encryption Key not found"
          : EMPTY_STRING}
      </b>
    );
  } else {
    AcctMakeIciciMutationLoading
      ? (paymentGateWayComponent = (
          <>
            <LoadingModal flag={AcctMakeIciciMutationLoading} />
            <b className="nodata">Redirecting to payment gateway...</b>
          </>
        ))
      : (paymentGateWayComponent = (
          <>
            <Button
              mode="pay-fee"
              onClick={handleOnlinePayment}
              disabled={AcctMakeIciciMutationLoading}
            />
            <Button mode="cancel" onClick={() => setModalFlag(false)} />
            <MessageModal
              handleClose={() => {
                setMessage({
                  flag: false,
                  message: "",
                  operation: Operation.NONE,
                });
              }}
              modalFlag={message.flag}
              operation={message.operation}
              value={message.message}
            />
            <LoadingModal flag={AcctMakeIciciMutationLoading} />
          </>
        ));
  }

  return {
    mutationFlag: AcctMakeIciciMutationLoading,
    component: paymentGateWayComponent,
  };
};
