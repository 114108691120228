import ReactDOM from "react-dom";

import "./scss/main.scss";
import Eduate from "./Eduate";
import { ApolloProvider } from "@apollo/client";
import { client } from "./client/index";
import { AppProvider } from "./context/context";
ReactDOM.render(
  // @ts-ignore
  <AppProvider>
    <ApolloProvider client={client}>
      <Eduate />
    </ApolloProvider>
  </AppProvider>,

  document.getElementById("root")
);
