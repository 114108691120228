import { gql } from "@apollo/client";

export const AccountVoucherDetailsByMasterVoucherId = gql`
  query AccountVoucherDetailsByMasterVoucherId($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on AcctVoucherMaster {
        v_no
        id
        created_at
        v_type
        v_book_type
        v_date
        v_total_cr_amt
        v_total_db_amt
        v_std_receipt_anonymous
        party_bill_no
        party_bill_date
        party_name
        v_transcation_no
        v_transcation_cash_or_bank
        payment_invoice_filename
        v_transcation_narration
        acct_voucher_details {
          id
          vo_date
          vo_cr_db
          vo_sl_no
          vo_cr
          vo_db
          vo_cr_total
          vo_db_total
          acct_ldgr_id
          voucher_master_id
          acct_ldgr {
            ldgr_desc
            id
            ldgr_cash_bank_other
          }
        }
      }
    }
  }
`;
export const EnqStudentDetailsByMasterVoucherId = gql`
  query EnqStudentDetailsByMasterVoucherId($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on AcctVoucherMaster {
        id
        v_no
        v_type
        v_book_type
        v_date
        v_total_cr_amt
        v_total_db_amt
        v_reconciled
        v_std_receipt
        v_std_refund
        v_std_deposit_adjusted
        v_std_deposit
        v_std_demand_receipt
        v_std_scholarship_deposit
        v_transcation_type
        v_std_passout_receipt
        v_transcation_no
        v_transcation_narration
        v_transcation_cash_or_bank
        class_details {
          id
          class_desc
        }
        branch_details {
          id
          branch_desc
        }
        semester_details {
          id
          sem_desc
        }
        acct_voucher_details {
          id
          vo_date
          vo_cr_db
          vo_cr
          vo_db
          vo_cr_total
          vo_db_total
          acct_ldgr_id
          voucher_master_id
          acct_ldgr {
            ldgr_desc
            id
            ldgr_cash_bank_other
          }
        }
      }
    }
  }
`;
export const PassedOutStudentDetailsByMasterVoucherId = gql`
  query PassedOutStudentDetailsByMasterVoucherId(
    $ids: [ID!]!
    $token: String!
  ) {
    nodes(ids: $ids, token: $token) {
      ... on AcctVoucherMaster {
        id
        v_no
        v_type
        v_book_type
        v_date
        v_total_cr_amt
        v_total_db_amt
        v_reconciled
        v_std_receipt
        v_std_refund
        v_std_deposit_adjusted
        v_std_deposit
        v_std_demand_receipt
        v_std_scholarship_deposit
        v_transcation_type
        v_std_passout_receipt
        v_transcation_no
        v_transcation_narration
        v_transcation_cash_or_bank
        passout_std_details {
          std_name
          branch_details {
            branch_desc
          }
          class_details {
            class_desc
          }
        }
        acct_voucher_details {
          id
          vo_date
          vo_cr_db
          vo_cr
          vo_db
          vo_cr_total
          vo_db_total
          acct_ldgr_id
          voucher_master_id
          acct_ldgr {
            ldgr_desc
            id
            ldgr_cash_bank_other
          }
        }
      }
    }
  }
`;
export const PassedOutStudentDetailsByMasterChallanId = gql`
  query PassedOutStudentDetailsByMasterChallanId(
    $ids: [ID!]!
    $token: String!
  ) {
    nodes(ids: $ids, token: $token) {
      ... on AcctChallanMaster {
        id
        v_no
        v_type
        v_book_type
        v_date
        v_total_cr_amt
        v_total_db_amt
        v_reconciled
        v_std_receipt
        v_std_refund
        v_std_receipt_anonymous
        v_std_deposit_adjusted
        v_std_deposit
        v_std_demand_receipt
        v_transcation_type
        v_std_scholarship_deposit
        v_std_refund_deposit
        v_std_passout_receipt
        v_transcation_no
        v_transcation_narration
        v_transcation_cash_or_bank
        passout_std_details {
          std_name
          id
        }
        acct_challan_details {
          id
          vo_date
          vo_cr_db
          vo_sl_no
          vo_cr
          vo_db
          vo_cr_total
          vo_db_total
          acct_ldgr_id
          challan_master_id
        }
      }
    }
  }
`;

export const EnquiryStudentDetailsByMasterVoucherId = gql`
  query EnquiryStudentDetailsByMasterVoucherId($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on AcctVoucherMaster {
        id
        v_no
        v_type
        v_book_type
        v_date
        v_total_cr_amt
        v_total_db_amt
        v_reconciled
        v_std_receipt
        v_std_refund
        v_std_deposit_adjusted
        v_std_deposit
        v_std_demand_receipt
        v_std_scholarship_deposit
        v_std_enquiry
        v_transcation_type
        v_std_passout_receipt
        v_transcation_no
        v_transcation_narration
        v_transcation_cash_or_bank
        enq_std_details {
          id
          first_name
          middle_name
          last_name
          std_father_name
          branch_details {
            branch_desc
          }
          class_details {
            class_desc
          }
        }
        acct_voucher_details {
          id
          vo_date
          vo_cr_db
          vo_cr
          vo_db
          vo_cr_total
          vo_db_total
          acct_ldgr_id
          voucher_master_id
          acct_ldgr {
            ldgr_desc
            id
            ldgr_cash_bank_other
          }
        }
      }
    }
  }
`;
export const EnquiryStudentDetailsByMasterChallanId = gql`
  query EnquiryStudentDetailsByMasterChallanId($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on AcctChallanMaster {
        id
        v_no
        v_type
        v_book_type
        v_date
        v_total_cr_amt
        v_total_db_amt
        v_reconciled
        v_std_receipt
        v_std_refund
        v_std_receipt_anonymous
        v_std_deposit_adjusted
        v_std_deposit
        v_std_demand_receipt
        v_transcation_type
        v_std_scholarship_deposit
        v_std_refund_deposit
        v_std_passout_receipt
        v_transcation_no
        v_transcation_narration
        v_transcation_cash_or_bank

        enquiry_student_details {
          first_name
          middle_name
          last_name
          id
        }

        acct_challan_details {
          id
          vo_date
          vo_cr_db
          vo_sl_no
          vo_cr
          vo_db
          vo_cr_total
          vo_db_total
          acct_ldgr_id
          challan_master_id
        }
      }
    }
  }
`;
export const StudentDetailsByMasterVoucherId = gql`
  query StudentDetailsByMasterVoucherId($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on AcctVoucherMaster {
        id
        v_no
        v_type
        v_book_type
        v_date
        v_total_cr_amt
        v_total_db_amt
        v_reconciled
        v_std_receipt
        v_std_refund
        v_std_receipt_anonymous
        v_std_deposit_adjusted
        v_std_deposit
        v_std_demand_receipt
        v_transcation_type
        v_std_scholarship_deposit
        v_std_refund_deposit
        v_std_passout_receipt
        v_transcation_no
        v_transcation_narration
        v_transcation_cash_or_bank
        class_details {
          id
          class_desc
        }
        branch_details {
          id
          branch_desc
        }
        mst_student {
          id
          first_name
          middle_name
          last_name
          std_adm_no
          std_father_name
          std_reg_no
          branch {
            branch_desc
            id
          }
          class {
            class_desc
            id
          }
          section {
            id
            section_desc
          }
        }

        acct_voucher_details {
          id
          vo_date
          vo_cr_db
          vo_cr
          vo_db
          vo_cr_total
          vo_db_total
          acct_ldgr_id
          voucher_master_id
          acct_ldgr {
            ldgr_desc
            id
            ldgr_cash_bank_other
          }
        }
      }
    }
  }
`;
export const StudentDetailsByChallanVoucherId = gql`
  query StudentDetailsByChallanVoucherId($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on AcctChallanMaster {
        id
        v_no
        v_type
        v_book_type
        v_date
        v_total_cr_amt
        v_total_db_amt
        v_reconciled
        v_std_receipt
        v_std_refund
        v_std_receipt_anonymous
        v_std_deposit_adjusted
        v_std_deposit
        v_std_demand_receipt
        v_transcation_type
        v_std_scholarship_deposit
        v_std_refund_deposit
        v_std_passout_receipt
        v_transcation_no
        v_transcation_narration
        v_transcation_cash_or_bank
        mst_student {
          id
          first_name
          middle_name
          last_name
          std_adm_no
          std_father_name
          std_reg_no
          branch {
            branch_desc
            id
          }
          class {
            class_desc
            id
          }
          section {
            id
            section_desc
          }
        }

        acct_challan_details {
          id
          vo_date
          vo_cr_db
          vo_sl_no
          vo_cr
          vo_db
          vo_cr_total
          vo_db_total
          acct_ldgr_id
          challan_master_id
          acct_ldgr_details {
            ldgr_desc
            id
          }
        }
        acct_ldgr_db_details {
          ldgr_desc
          id
          ldgr_total_db
        }
      }
    }
  }
`;
export const StudentDetailsByChallanId = gql`
  query StudentDetailsByChallanVoucherId($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on AcctChallanMaster {
        id
        v_no
        v_type
        v_book_type
        v_date
        v_total_cr_amt
        v_total_db_amt
        v_reconciled
        v_std_receipt
        v_std_refund
        v_std_receipt_anonymous
        v_std_deposit_adjusted
        v_std_deposit
        v_std_demand_receipt
        v_transcation_type
        v_transcation_date
        v_std_scholarship_deposit
        v_std_refund_deposit
        v_std_passout_receipt
        v_transcation_no
        v_transcation_narration
        v_transcation_cash_or_bank
        acct_challan_details {
          id
          vo_date
          vo_cr_db
          vo_sl_no
          vo_cr
          vo_db
          vo_cr_total
          vo_db_total
          acct_ldgr_id
          challan_master_id
          acct_ldgr_details {
            ldgr_desc
            id
          }
        }
        acct_ldgr_db_details {
          ldgr_desc
          id
          ldgr_total_db
        }
      }
    }
  }
`;
export const UnkownStudentByMasterVoucherId = gql`
  query UnkownStudentByMasterVoucherId($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on AcctVoucherAnonymous {
        id
        voucher_master_id
        v_transcation_no
        inst_id
        fin_yr_id
        anonymous_type
        received_amt
        bal_amt
        adjusted_amt
        voucher_master_details {
          id
          v_no
          v_type
          v_book_type
          v_date
          v_total_cr_amt
          v_total_db_amt
          v_reconciled
          v_std_receipt
          v_std_refund
          v_std_receipt_anonymous
          v_std_deposit_adjusted
          v_std_deposit
          v_std_demand_receipt
          v_std_scholarship_deposit
          v_std_refund_deposit
          v_transcation_type
          v_std_passout_receipt
          v_transcation_no
          v_transcation_narration
          v_transcation_cash_or_bank
          acct_voucher_details {
            id
            vo_date
            vo_cr_db
            vo_cr
            vo_db
            vo_cr_total
            vo_db_total
            acct_ldgr_id
            voucher_master_id
            acct_ldgr {
              ldgr_desc
              id
              ldgr_cash_bank_other
            }
          }
        }
      }
    }
  }
`;
export const StudentDetailsByConcessionVoucherId = gql`
  query StudentDetailsByConcessionVoucherId($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on AcctStdConcession {
        id
        concession_vouch_no
        concession_vouch_date
        concession_total
        concession_total
        concession_for_staff
        concession_auth_by
        concession_letter_enclosed
        concession_naration

        concession_details {
          id
          concession_amt
          acct_ldgr_details {
            id
            ldgr_desc
          }
        }
        mst_student {
          id
          first_name
          middle_name
          last_name
          std_adm_no
          std_father_name
          std_reg_no
          branch {
            branch_desc
            id
          }
          class {
            class_desc
            id
          }
        }
      }
    }
  }
`;
export const AcctVoucherAnonymousAdjustmentDetailsByNode = gql`
  query AcctVoucherAnonymousAdjustmentDetailsByNode(
    $ids: [ID!]!
    $token: String!
  ) {
    nodes(ids: $ids, token: $token) {
      ... on AcctVoucherAnonymous {
        received_amt
        adjustment_details {
          id
          adjusted_amt
          v_no
          voucher_master_details {
            v_date
          }
          student_details {
            id
            first_name
            middle_name
            last_name
            std_adm_no
            std_father_name
            std_reg_no
            branch {
              branch_desc
              id
            }
            class {
              class_desc
              id
            }
          }
        }
      }
    }
  }
`;

export const GetStudentDetails = gql`
  query GetStudentDetails($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on MstStudent {
        id
        first_name
        middle_name
        last_name
        std_reg_no
        std_adm_no
        std_father_name
        std_mother_name
        std_father_mobile
        std_mother_mobile
        std_email
        std_mobile
        std_sex
        std_doa
        std_dob
        std_studying
        std_fresher
        std_status
        std_sts_no
        std_profile_filename

        inst_details {
          id
          inst_code
          inst_name
          inst_short_name
          inst_address
          inst_place
          inst_state
          inst_pin
          inst_contact_person
          inst_phone
          inst_email
          inst_mobile
          inst_url
          inst_is_active
          inst_status
          customer_details {
            id
            cust_code
            cust_name
            cust_address
            cust_place
            cust_state
            cust_pin
            cust_contact_person
            cust_phone
            cust_email
            cust_mobile
            cust_banner_1
            cust_banner_2
            cust_url
            cust_is_active
            cust_status
            cust_num_inst
          }
        }
        acct_std_demand {
          std_demand_ob
          std_demand_amt
          std_demand_concession
          std_demand_receivable
          std_demand_received
          std_demand_refunds
          std_demand_bal
          std_deposit_ob
          std_deposit_amt
          std_deposit_total
          std_deposit_adjusted
          std_deposit_refunded
          std_deposit_bal
          no_of_letters
          new_due_date
          remarks
          demand_details {
            id
            fee_refunds
            fee_received
            fee_receivable
            fee_ob
            fee_due_date
            fee_demand
            fee_concession
            fee_bal
            acct_ldgr_id
            acct_ldgr_details {
              ldgr_desc
              id
              ldgr_cash_bank_other
            }
          }
        }
        class {
          class_desc
          id
        }

        branch {
          branch_desc
          id
        }
        semester {
          sem_desc
          id
        }
        section {
          id
          semester_id
          section_desc
        }
        acd_section {
          id
          section_desc
        }
        acd_semester {
          id
          sem_desc
        }
        acd_class {
          id
          class_desc
        }
        dept {
          dept_desc
          id
        }
        category {
          cat_desc
          id
        }
        acd_yr {
          acd_yr
          id
        }
        fin_yr {
          fin_yr
          id
        }
        std_details {
          std_aadhar
          std_address
          std_place
          std_district
          std_state
          std_phone
          std_pincode
          std_corr_address
          std_corr_place
          std_corr_district
          std_corr_state
          std_corr_phone
          std_corr_pincode
          std_blood_group
          std_weight
          std_height
          std_is_phy_challenged
          std_disability_name
          std_disability_percentage
          std_religion
          std_caste
          std_sub_caste
          std_caste_group
          std_sc_st
          std_caste_minority
          std_rural_urban
          std_mother_tongue
          std_annual_income
          std_martial_status
          std_identity_marks
          std_place_of_birth
          std_nationality
          std_nearest_police_station
          std_nearest_railway_station
          std_km_from_residence
          std_belongs_to_jk
          std_belongs_to_jk_brahmin
          std_is_sports_person
          std_is_ncc
          std_is_nss
          std_is_gadinadu_horanadu
          std_is_defence
          std_is_political_sufferer
          std_is_cultural
          std_is_hiv_devadasi
          std_is_child_of_freedom_fighter
          std_is_child_of_farmer
          std_is_destitude_woman
          std_is_nirashritharu
          std_is_bpl_card
          std_is_non_emp_status
          std_is_ksou_emp
        }
        std_other_details {
          std_program_name
          std_open_elective
          std_fee_amount
          std_fee_payment_date
          std_discipline_1
          std_language_1
          std_challan_no
          std_kk_reservation
          std_discipline_2
          std_language_2
          std_prev_tc_details
          std_prev_inst
          std_prev_quali_exam
          std_prev_studied_board
          std_prev_studied_state
          std_prev_subject_studied
          std_prev_scored_marks
          std_prev_total_per
          std_prev_tech_subject_studied
          std_prev_tech_total_mark
          std_prev_tech_total_per
          std_10_board_name
          std_10_inst_name
          std_10_state
          std_10_reg_no
          std_10_grade_system
          std_10_total_marks
          std_10_scored_marks
          std_10_scored_percentage
          std_10_lang_medium
          std_10_year
          std_12_course_name
          std_12_inst_name
          std_12_state
          std_12_reg_no
          std_12_grade_system
          std_12_total_marks
          std_12_scored_marks
          std_12_scored_percentage
          std_12_lang_medium
          std_12_year
          std_ug_course_name
          std_ug_univ_name
          std_ug_inst_name
          std_ug_state
          std_ug_reg_no
          std_ug_grade_system
          std_ug_total_marks
          std_ug_scored_marks
          std_ug_scored_percentage
          std_ug_lang_medium
          std_ug_doa
          std_ug_doc
          std_bank_name
          std_bank_branch
          std_bank_acct
          std_bank_ifsc
          std_adm_appl_no
          std_adm_free_pymnt_seat
          std_adm_state_or_outside
          std_adm_seat_got_through
          std_adm_category
          std_adm_cet_no
          std_adm_cet_rank
          std_adm_eli_no
          std_adm_eli_date
          std_adm_cet_paid_rcpt_no
          std_adm_cet_paid_rcpt_date
          std_adm_cet_paid_amt
          std_adm_cet_alloted_category
          std_kannada_medium
        }
        parents {
          relationship
          parent {
            id
            first_name
            middle_name
            last_name
            parent_address
            parent_place
            parent_state
            parent_pin
            parent_email
            parent_mobile
            parent_occup
            parent_qualification
            parent_aadhar
            parent_rights
            parent_type
          }
        }
      }
    }
  }
`;
export const GetStudentDetailsForRefetch = gql`
  query GetStudentDetails($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on MstStudent {
        id
        first_name
        middle_name
        last_name
        std_reg_no
        std_adm_no
        std_father_name
        std_mother_name
        std_father_mobile
        std_mother_mobile
        std_email
        std_mobile
        std_sex
        std_doa
        std_dob
        std_studying
        std_fresher
        std_status
        std_sts_no
        std_profile_filename
        class {
          class_desc
          id
        }
        branch {
          branch_desc
          id
        }
        semester {
          sem_desc
          id
        }
        section {
          id
          semester_id
          section_desc
        }
        acd_section {
          id
          section_desc
        }
        acd_semester {
          id
          sem_desc
        }
        acd_class {
          id
          class_desc
        }
        dept {
          dept_desc
          id
        }
        category {
          cat_desc
          id
        }
      }
    }
  }
`;
export const GetNotAllocatedIds = gql`
  query GetNotAllocatedIds(
    $token: String!
    $inst_id: ID!
    $table: TableType!
    $table_id: ID!
  ) {
    GetNotAllocatedIds(
      token: $token
      inst_id: $inst_id
      table: $table
      table_id: $table_id
    ) {
      dept_id
      branch_id
      class_id
      semester_id
      section_id
    }
  }
`;
export const GetAcdSemesterYrs = gql`
  query GetAcdSemesterYrs($token: String!, $inst_id: ID!, $acd_yr_id: ID!) {
    GetAcdSemesterYrs(token: $token, inst_id: $inst_id, acd_yr_id: $acd_yr_id) {
      id
      acd_sem_st_date
      acd_sem_end_date
      inst_id
      acd_yr_id
      acd_semester_id
    }
  }
`;
export const GetNotAllocatedIdsForScan = gql`
  query GetNotAllocatedIdsForScan(
    $inst_id: ID!
    $table: TableType!
    $table_id: ID!
  ) {
    GetNotAllocatedIdsForScan(
      inst_id: $inst_id
      table: $table
      table_id: $table_id
    ) {
      dept_id
      branch_id
      class_id
      semester_id
      section_id
    }
  }
`;
export const GetMstInstTree = gql`
  query GetMstInstTree(
    $token: String!
    $inst_id: ID!
    $id: ID!
    $query_type: InstTreeQueryType!
  ) {
    GetMstInstTree(
      token: $token
      inst_id: $inst_id
      id: $id
      query_type: $query_type
    ) {
      level_1
      level_1_name
      level_1_desc
      level_1_id
      total_count
      boys_count
      girls_count
      fresher_count
      existing_count
      total_vacant_seats
      total_seats
      show_seats_info
      sub_level_total_count
      level_2_details {
        level_2
        level_2_name
        level_2_desc
        level_2_id
        total_count
        boys_count
        girls_count
        fresher_count
        existing_count
        total_vacant_seats
        total_seats
        show_seats_info
        sub_level_total_count
        level_3_details {
          level_3
          level_3_name
          level_3_desc
          level_3_id
          total_count
          boys_count
          girls_count
          fresher_count
          existing_count
          total_vacant_seats
          total_seats
          show_seats_info
          sub_level_total_count
          level_4_details {
            level_4
            level_4_name
            level_4_desc
            level_4_id
            total_count
            boys_count
            girls_count
            fresher_count
            existing_count
            total_vacant_seats
            total_seats
            show_seats_info
            sub_level_total_count
            level_5_details {
              level_5
              level_5_name
              level_5_desc
              level_5_id
              boys_count
              girls_count
              total_count
              fresher_count
              existing_count
            }
          }
        }
      }
    }
  }
`;
export const GetAcdInstTree = gql`
  query GetAcdInstTree(
    $token: String!
    $inst_id: ID!
    $id: ID!
    $query_type: InstTreeQueryType!
    $date_of_attendance: Time!
    $testNameId: ID!
    $acd_yr_id: ID!
  ) {
    GetAcdInstTree(
      token: $token
      inst_id: $inst_id
      id: $id
      query_type: $query_type
      date_of_attendance: $date_of_attendance
      testNameId: $testNameId
      acd_yr_id: $acd_yr_id
    ) {
      acd_level_1
      acd_level_1_name
      acd_level_1_desc
      acd_level_1_id
      total_count
      total_att_present
      total_att_absent
      att_percentage
      att_marked
      total_subjects
      allocated_level
      total_test_subjects
      test_start_date
      total_subject_planned
      test_end_date
      sub_acd_level_total_count
      cal_days_count
      att_marked_days_count

      class_teacher_name
      acd_level_2_details {
        acd_level_2
        acd_level_2_name
        acd_level_2_desc
        acd_level_2_id
        total_count
        total_att_present
        total_att_absent
        total_subject_planned
        att_percentage
        att_marked
        total_subjects
        allocated_level
        total_test_subjects
        test_start_date
        test_end_date
        sub_acd_level_total_count
        class_teacher_name
        cal_days_count
        att_marked_days_count
        acd_level_3_details {
          acd_level_3
          acd_level_3_name
          acd_level_3_desc
          acd_level_3_id
          total_subject_planned
          total_count
          total_att_present
          total_att_absent
          att_percentage
          att_marked
          total_subjects
          allocated_level
          total_test_subjects
          test_start_date
          test_end_date
          sub_acd_level_total_count
          cal_days_count
          att_marked_days_count
          class_teacher_name
          acd_level_4_details {
            acd_level_4
            acd_level_4_name
            acd_level_4_desc
            acd_level_4_id
            total_count
            total_att_present
            total_subject_planned
            total_att_absent
            att_percentage
            att_marked
            total_subjects
            allocated_level
            total_test_subjects
            test_start_date
            test_end_date
            sub_acd_level_total_count
            cal_days_count
            att_marked_days_count
            class_teacher_name
            acd_level_5_details {
              acd_level_5
              acd_level_5_name
              acd_level_5_desc
              acd_level_5_id
              total_count
              total_att_present
              total_subject_planned
              total_att_absent
              att_percentage
              att_marked
              total_subjects
              allocated_level
              total_test_subjects
              test_start_date
              test_end_date
              sub_acd_level_total_count
              cal_days_count
              att_marked_days_count
              class_teacher_name
            }
          }
        }
      }
    }
  }
`;
export const GetAcdTeacherTree = gql`
  query GetAcdTeacherTree(
    $token: String!
    $inst_id: ID!
    $id: ID!
    $query_type: InstTreeQueryType!
    $pr_emp_id: ID!
    $acd_yr_id: ID!
  ) {
    GetAcdTeacherTree(
      token: $token
      inst_id: $inst_id
      id: $id
      query_type: $query_type
      pr_emp_id: $pr_emp_id
      acd_yr_id: $acd_yr_id
    ) {
      acd_level_1
      acd_level_1_name
      acd_level_1_desc
      acd_level_1_id
      teacher_assigned
      subject_details {
        subj_code
        subj_desc
      }
      sub_acd_level_total_count
      acd_level_2_details {
        acd_level_2
        acd_level_2_name
        acd_level_2_desc
        acd_level_2_id
        teacher_assigned
        subject_details {
          subj_code
          subj_desc
        }
        sub_acd_level_total_count
        acd_level_3_details {
          acd_level_3
          acd_level_3_name
          acd_level_3_desc
          acd_level_3_id
          teacher_assigned
          subject_details {
            subj_code
            subj_desc
          }
          sub_acd_level_total_count
          acd_level_4_details {
            acd_level_4
            acd_level_4_name
            acd_level_4_desc
            acd_level_4_id
            teacher_assigned
            subject_details {
              subj_code
              subj_desc
            }
            sub_acd_level_total_count
            acd_level_5_details {
              acd_level_5
              acd_level_5_name
              acd_level_5_desc
              acd_level_5_id
              teacher_assigned
              subject_details {
                subj_code
                subj_desc
              }
              sub_acd_level_total_count
            }
          }
        }
      }
    }
  }
`;
export const GetAcdFeedbackFormTree = gql`
  query GetAcdFeedbackFormTree(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $id: ID!
    $query_type: InstTreeQueryType!
    $form_name_id: ID!
  ) {
    GetAcdFeedbackFormTree(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      id: $id
      query_type: $query_type
      form_name_id: $form_name_id
    ) {
      acd_level_1
      acd_level_1_name
      acd_level_1_desc
      acd_level_1_id
      form_exists
      total_std_count
      form_filled_count
      sub_acd_level_total_count
      acd_level_2_details {
        acd_level_2
        acd_level_2_name
        acd_level_2_desc
        acd_level_2_id
        form_exists
        total_std_count
        form_filled_count
        sub_acd_level_total_count
        acd_level_3_details {
          acd_level_3
          acd_level_3_name
          acd_level_3_desc
          acd_level_3_id
          form_exists
          total_std_count
          form_filled_count
          sub_acd_level_total_count
          acd_level_4_details {
            acd_level_4
            acd_level_4_name
            acd_level_4_desc
            acd_level_4_id
            form_exists
            total_std_count
            form_filled_count
            sub_acd_level_total_count
            acd_level_5_details {
              acd_level_5
              acd_level_5_name
              acd_level_5_desc
              acd_level_5_id
              form_exists
              total_std_count
              form_filled_count
              sub_acd_level_total_count
            }
          }
        }
      }
    }
  }
`;
export const GetAcdInstTreeForAllocatedLevel = gql`
  query GetAcdInstTreeForAllocatedLevel(
    $token: String!
    $inst_id: ID!
    $id: ID!
    $query_type: InstTreeQueryType!
    $date_of_attendance: Time!
    $testNameId: ID!
    $acd_yr_id: ID!
  ) {
    GetAcdInstTreeForAllocatedLevel(
      token: $token
      inst_id: $inst_id
      id: $id
      query_type: $query_type
      date_of_attendance: $date_of_attendance
      testNameId: $testNameId
      acd_yr_id: $acd_yr_id
    ) {
      acd_level_1
      acd_level_1_name
      acd_level_1_desc
      acd_level_1_id
      per_std_subj_allocation
      total_count
      total_att_present
      total_att_absent
      att_percentage
      att_marked
      total_subjects
      allocated_level
      total_test_subjects
      test_start_date
      test_end_date
      sub_acd_level_total_count
      acd_level_2_details {
        acd_level_2
        acd_level_2_name
        acd_level_2_desc
        acd_level_2_id
        total_count
        total_att_present
        total_att_absent
        att_percentage
        att_marked
        total_subjects
        allocated_level
        total_test_subjects
        test_start_date
        test_end_date
        sub_acd_level_total_count
        per_std_subj_allocation
        acd_level_3_details {
          acd_level_3
          acd_level_3_name
          acd_level_3_desc
          acd_level_3_id
          total_count
          total_att_present
          total_att_absent
          att_percentage
          att_marked
          total_subjects
          allocated_level
          total_test_subjects
          test_start_date
          test_end_date
          sub_acd_level_total_count
          per_std_subj_allocation
          acd_level_4_details {
            acd_level_4
            acd_level_4_name
            acd_level_4_desc
            acd_level_4_id
            total_count
            total_att_present
            total_att_absent
            att_percentage
            att_marked
            total_subjects
            allocated_level
            total_test_subjects
            test_start_date
            test_end_date
            sub_acd_level_total_count
            per_std_subj_allocation
            acd_level_5_details {
              acd_level_5
              acd_level_5_name
              acd_level_5_desc
              acd_level_5_id
              total_count
              total_att_present
              total_att_absent
              att_percentage
              att_marked
              total_subjects
              allocated_level
              total_test_subjects
              test_start_date
              test_end_date
              per_std_subj_allocation
            }
          }
        }
      }
    }
  }
`;

export const AcctMakeIciciOnlinePayment = gql`
  mutation AcctMakeIciciOnlinePayment(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $student_id: ID!
    $transaction_amount: String!
    $payment_mode: String!
    $mobile_payment: Boolean!
    $input: [CreateAcctVoucherDetailsOnlineInput!]!
  ) {
    AcctMakeIciciOnlinePayment(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      student_id: $student_id
      transaction_amount: $transaction_amount
      payment_mode: $payment_mode
      input: $input
      mobile_payment: $mobile_payment
    ) {
      encrypted_mandatory_fields
      encrypted_optional_fields
      encrypted_return_url
      encrypted_sub_merchant_id
      encrypted_transaction_amount
      encrypted_payment_mode
      encrypted_reference_no
    }
  }
`;
export const GetAcdFeedbackFormOverAllRating = gql`
  query GetAcdFeedbackFormOverAllRating(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $pr_emp_id: ID!
    $form_name_id: ID!
  ) {
    GetAcdFeedbackFormOverAllRating(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      pr_emp_id: $pr_emp_id
      form_name_id: $form_name_id
    ) {
      id
      num_questions
      rating_1
      rating_2
      rating_3
      rating_4
      rating_5
      rating_6
      rating_7
      rating_8
      rating_9
      rating_10
      rating_11
      rating_12
      rating_13
      rating_14
      rating_15
      inst_id
      acd_yr_id
      pr_emp_id
      form_name_id
    }
  }
`;
export const GetAcdFeedbackStdRatingCount = gql`
  query GetAcdFeedbackStdRatingCount(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $entry_level: String!
    $entry_id: ID!
    $form_name_id: ID!
    $pr_emp_id: ID!
    $subj_master_id: ID!
    $perStdSubjAllocation: Boolean!
  ) {
    GetAcdFeedbackStdRatingCount(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      pr_emp_id: $pr_emp_id
      form_name_id: $form_name_id
      entry_level: $entry_level
      entry_id: $entry_id
      subj_master_id: $subj_master_id
      perStdSubjAllocation: $perStdSubjAllocation
    ) {
      total_std_count
      form_filled_count
    }
  }
`;
export const Decrypt128AesEcb = gql`
  query Decrypt128AesEcb(
    $token: String!
    $aes_key: String!
    $str_to_decrypt: String!
  ) {
    Decrypt128AesEcb(
      token: $token
      aes_key: $aes_key
      str_to_decrypt: $str_to_decrypt
    )
  }
`;
export const GetAttDashBoardCount = gql`
  query GetAttDashBoardCount(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $date_of_attendance: Time!
  ) {
    GetAttDashBoardCount(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      date_of_attendance: $date_of_attendance
    ) {
      total_classes
      total_att_marked
      total_att_not_marked
      total_students
      total_students_present
      total_students_absent
      att_percentage
      marked_percentage
    }
  }
`;

export const GetAttDashBoardTestDetails = gql`
  query GetAttDashBoardTestDetails(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $date_of_attendance: Time!
  ) {
    GetAttDashBoardTestDetails(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      date_of_attendance: $date_of_attendance
    ) {
      up_coming_tests {
        test_name
        start_date
        end_date
        test_type
      }
      in_progress_tests {
        test_name
        start_date
        end_date
        test_type
      }
    }
  }
`;

export const GetAttDashBoardGraphData = gql`
  query GetAttDashBoardGraphData(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $date_of_attendance: Time!
  ) {
    GetAttDashBoardGraphData(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      date_of_attendance: $date_of_attendance
    ) {
      id
      cal_month
      att_percent_day_1
      att_percent_day_2
      att_percent_day_3
      att_percent_day_4
      att_percent_day_5
      att_percent_day_6
      att_percent_day_7
      att_percent_day_8
      att_percent_day_9
      att_percent_day_10
      att_percent_day_11
      att_percent_day_12
      att_percent_day_13
      att_percent_day_14
      att_percent_day_15
      att_percent_day_16
      att_percent_day_17
      att_percent_day_18
      att_percent_day_19
      att_percent_day_20
      att_percent_day_21
      att_percent_day_22
      att_percent_day_23
      att_percent_day_24
      att_percent_day_25
      att_percent_day_26
      att_percent_day_27
      att_percent_day_28
      att_percent_day_29
      att_percent_day_30
      att_percent_day_31
      marked_percent_day_1
      marked_percent_day_2
      marked_percent_day_3
      marked_percent_day_4
      marked_percent_day_5
      marked_percent_day_6
      marked_percent_day_7
      marked_percent_day_8
      marked_percent_day_9
      marked_percent_day_10
      marked_percent_day_11
      marked_percent_day_12
      marked_percent_day_13
      marked_percent_day_14
      marked_percent_day_15
      marked_percent_day_16
      marked_percent_day_17
      marked_percent_day_18
      marked_percent_day_19
      marked_percent_day_20
      marked_percent_day_21
      marked_percent_day_22
      marked_percent_day_23
      marked_percent_day_24
      marked_percent_day_25
      marked_percent_day_26
      marked_percent_day_27
      marked_percent_day_28
      marked_percent_day_29
      marked_percent_day_30
      marked_percent_day_31
    }
  }
`;

export const GetDiaryDashBoardGraphData = gql`
  query GetDiaryDashBoardGraphData(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $date_of_attendance: Time!
  ) {
    GetDiaryDashBoardGraphData(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      date_of_attendance: $date_of_attendance
    ) {
      id
      percent_day_1
      percent_day_2
      percent_day_3
      percent_day_4
      percent_day_5
      percent_day_6
      percent_day_7
      percent_day_8
      percent_day_9
      percent_day_10
      percent_day_11
      percent_day_12
      percent_day_13
      percent_day_14
      percent_day_15
      percent_day_16
      percent_day_17
      percent_day_18
      percent_day_19
      percent_day_20
      percent_day_21
      percent_day_22
      percent_day_23
      percent_day_24
      percent_day_25
      percent_day_26
      percent_day_27
      percent_day_28
      percent_day_29
      percent_day_30
      percent_day_31
      pr_acd_yr_id
      pr_cal_month_id
      inst_id
    }
  }
`;

export const GetDailyDiaryDashBoardCount = gql`
  query GetDailyDiaryDashBoardCount(
    $token: String!
    $inst_id: ID!
    $pr_acd_yr_id: ID!
    $date_of_attendance: Time!
  ) {
    GetDailyDiaryDashBoardCount(
      token: $token
      inst_id: $inst_id
      pr_acd_yr_id: $pr_acd_yr_id
      date_of_attendance: $date_of_attendance
    ) {
      total_teachers
      total_teachers_filled
      total_teachers_not_filled
      total_teachers_percentage
    }
  }
`;

export const GetTestPlanningDashBoardCount = gql`
  query GetTestPlanningDashBoardCount(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $date_of_attendance: Time!
  ) {
    GetTestPlanningDashBoardCount(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      date_of_attendance: $date_of_attendance
    ) {
      total_tests_planned
      total_tests_yet_comp
      total_tests_comp
    }
  }
`;
export const PredefinedDataNode = gql`
  query PredefinedDataNode($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on PredefinedData {
        id
        type
        index
        value1
        value2
        value3
        value4
      }
    }
  }
`;
export const PassOutStudentNode = gql`
  query PassOutStudentNode($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on MstStudentPassout {
        id
        std_name
        std_passout_yr
        std_mobile
        std_email
        inst_id
        branch_id
        class_id
        class_details {
          class_desc
        }
        branch_details {
          branch_desc
        }
      }
    }
  }
`;

export const GetTempAcctVouchersDetails = gql`
  query GetTempAcctVouchersDetails(
    $token: String!
    $fin_yr_id: ID!
    $voucher_master_id: ID!
  ) {
    GetTempAcctVouchersDetails(
      token: $token
      fin_yr_id: $fin_yr_id
      voucher_master_id: $voucher_master_id
    ) {
      id
      vo_date
      vo_cr_db
      vo_sl_no
      vo_cr
      vo_db
      vo_cr_total
      vo_db_total
      acct_ldgr_details {
        id
        ldgr_cash_bank_other
        ldgr_desc
      }
      voucher_master_details {
        id
        v_type
        v_date
        v_no
        v_std_passout_receipt
        v_total_db_amt
        v_std_amt_total
        v_total_cr_amt
        v_book_type
        v_std_enquiry
        v_std_refund
        enquiry_student_id
        v_std_deposit_adjusted
        v_std_deposit
        v_std_demand_receipt
        v_std_receipt
        party_bill_no
        v_std_scholarship_deposit

        v_transcation_type
        student_id
        v_transcation_cash_or_bank
        v_transcation_no
        party_name
        v_transcation_narration
        acct_voucher_details {
          id
          vo_date
          vo_cr_db
          vo_sl_no
          vo_cr
          vo_db
          vo_cr_total
          vo_db_total
          acct_ldgr_id
          voucher_master_id
          acct_ldgr {
            ldgr_desc
            id
            ldgr_cash_bank_other
          }
        }
      }
    }
  }
`;
export const AccountMkckDetailsByMasterVoucherId = gql`
  query AccountMkckDetailsByMasterVoucherId($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on AcctVoucherMasterMkCk {
        mkck_no
        id
        created_at
        mkck_type
        payment_invoice_filename
        mkck_book_type
        mkck_date
        mkck_total_cr_amt
        mkck_total_db_amt
        party_name
        party_bill_no
        party_bill_date
        transcation_no
        remarks
        acct_voucher_details_mkck {
          id
          vo_date
          vo_cr_db
          vo_sl_no
          vo_cr
          vo_db
          vo_cr_total
          vo_db_total
          acct_ldgr_id
          acct_ldgr {
            ldgr_desc
            id
            ldgr_cash_bank_other
          }
          voucher_master_details {
            remarks
          }
        }
        created_emp_details {
          id
          emp_first_name
          emp_middle_name
          emp_last_name
        }
        last_assigned_details {
          remarks
        }
      }
    }
  }
`;
