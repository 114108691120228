import { useNavigate, useParams } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase";
import Skins from "./Skins";
import { AppContext } from "../../../context/context";

import { Autocomplete, TextField, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import {
  LogOutModalStyles,
  WaiveOffModalStyles,
} from "../../../styles/ModalStyles";

import Enlarge from "../../../images/Enlarge.svg";
import Username from "../../../images/Username.svg";
import Inbox from "../../../images/Inbox.svg";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { toggleFullSceen } from "../../../utils/UtilFunctions";

import { Drawer } from "@mui/material";
import { Label } from "../../../stories/Label/Label";
import Avatar from "../../../images/Avatar.svg";
import Information from "../../../images/Information.svg";
import Chat from "../../../images/Chat.svg";
import PayFee from "../../../images/PayFee.svg";
import { EMPTY_STRING } from "../../../utils/constants";
import { FileUploadParams } from "../../../utils/Enum.types";

import { studentDetailsStyles } from "../../../styles/DrawerStyles";
import { payloadTypes } from "../../../context/reducer";
import { responseType } from "../../../utils/Form.types";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import Captcha from "../../../Modules/Master/Captcha";
import Close from "../../../images/Close.svg";

import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import useAssignedInstbyEmpId, {
  InstUserAccessQueryType,
} from "../../../Modules/UserRights/hooks/useAssignedInstbyEmpId";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import { LogoutUser } from "../../../queries/DetailsBytoken";
import useToken from "../../../customhooks/useToken";
import {
  formAutoCompleteStyles,
  instAutoCompleteStyles,
  instAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import DownArrow from "../../../images/DownArrow.svg";
import { useStyles } from "../../../styles/TooltipStyles";
import useInstLabels from "../../../customhooks/general/useInstLabels";

import useFinancialYears from "../../../Modules/Academics/hooks/useFinancialYears";
import useActiveFinancialYear from "../../../Modules/Accounts/hooks/useActiveFinancialYear";
import {
  ListAcademicYears,
  ListFinancialYears,
} from "../../../Types/Accounting";
import { useMutation } from "@apollo/client";
import useAcademicYears from "../../../Modules/Academics/hooks/useAcademicYears";
import useActiveAcademicYear from "../../../Modules/Academics/hooks/useActiveAcademicYear";

export const handleSignOut = () => {
  signOut(auth)
    .then(() => (window.location.href = "/"))
    .catch((error) => {
      alert(error);
    });
};

const Index = () => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteStyles();
  const instClasses = instAutoCompleteStyles();
  const instTextClasses = instAutoCompleteTextStyles();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });

  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);

  const { token } = useToken();
  const { InstId } = useParams();

  const { MultipleInstitutions } = useAssignedInstbyEmpId(
    InstUserAccessQueryType.USER_ACCESS_BY_EMP_ID,
    state.empLoginId
  );

  const { ActiveFinancicalYear } = useActiveFinancialYear();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { studentFormData } = useStudentDatabyId();
  const [userLogout, setUserLogout] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);

  const { InstDetails } = useInstDetails(1);

  const [DeleteDeviceId] = useMutation(LogoutUser);
  const { classLabel } = useInstLabels();
  const { financialYearResponse } = useFinancialYears();
  const { academicYearResponse } = useAcademicYears();
  const handleDeleteUserDeviceId = () => {
    if (state.claims?.EDUATE || state.claims?.OWNER) {
      handleSignOut();
    } else
      DeleteDeviceId({
        variables: {
          token,
          device_token: EMPTY_STRING,
          input: {
            id: state?.claims!.EMPLOYEE
              ? state.empLoginId
              : state?.claims!.PARENT
              ? state.parentId
              : state.studentId,
            user_type: state.claims!.EMPLOYEE
              ? "EMPLOYEE"
              : state.claims!.PARENT
              ? "PARENT"
              : "STUDENT",
          },
        },
      }).then(({ data }) => {
        if (data) {
          handleSignOut();
        }
      });
  };

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  const drawerClasses = studentDetailsStyles();
  const tooltipClasses = useStyles();

  useEffect(() => {
    if (
      ActiveFinancicalYear.data &&
      !ActiveFinancicalYear.loading &&
      state.ActiveFinYr === null
    ) {
      dispatch({
        type: payloadTypes.SET_ACTIVE_FIN_YR,
        payload: {
          ActiveFinYr: ActiveFinancicalYear.data
            .GetFinYrActiveByInstId as unknown as ListFinancialYears,
        },
      });
    } // eslint-disable-next-line
  }, [
    ActiveFinancicalYear.data,
    ActiveFinancicalYear.loading,
    state.ActiveFinYr,
  ]);
  useEffect(() => {
    if (
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr === null
    ) {
      dispatch({
        type: payloadTypes.SET_ACTIVE_ACD_YR,
        payload: {
          ActiveAcdYr: activeAcademicYearData.data
            .GetAcdYrActiveByInstId as unknown as ListAcademicYears,
        },
      });
    } // eslint-disable-next-line
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    state.ActiveAcdYr,
  ]);

  return (
    <>
      <div className="header">
        <div className="row g-0 h-100">
          <div className="col-5 header__inst-details h-100">
            {window.location.pathname !== "/home" &&
            state.claims!.EMPLOYEE &&
            MultipleInstitutions.institutions.length > 1 ? (
              <>
                <Autocomplete
                  classes={instClasses}
                  options={MultipleInstitutions.institutions}
                  openOnFocus
                  onChange={(e, newValue) => {
                    if (newValue) {
                      const customUrl = `/${
                        (newValue as responseType).value
                      }/admissions`;

                      dispatch({
                        type: payloadTypes.SET_INST_ID,
                        payload: {
                          InstId: (newValue as responseType)?.value,
                        },
                      });
                      window.location.href = customUrl;
                    }
                  }}
                  freeSolo
                  forcePopupIcon
                  popupIcon={<img src={DownArrow} alt="/" />}
                  clearIcon={false}
                  value={{
                    label: InstDetails.data?.nodes[0]?.inst_name || "",
                    value: Number(InstDetails.data?.nodes[0].id),
                    defaultLogo: LogoOrSign.defaultLogo || "",
                  }}
                  renderInput={(params) => (
                    <div style={{ display: "flex", columnGap: "10px" }}>
                      {LogoOrSign.defaultLogo && (
                        <img
                          className="logo-1"
                          src={LogoOrSign.defaultLogo}
                          alt="/"
                        />
                      )}
                      <TextField
                        {...params}
                        placeholder="Search Institutions."
                        InputLabelProps={{ shrink: false }}
                        classes={{ root: instTextClasses.formControlRoot }}
                      />
                    </div>
                  )}
                />
              </>
            ) : (
              <li>
                {LogoOrSign.defaultLogo ? (
                  <img
                    className="logo-1"
                    src={LogoOrSign.defaultLogo}
                    alt="/"
                  />
                ) : null}
                &nbsp;
                <b className="inst-name">
                  {InstDetails.data?.nodes[0]?.inst_name}
                </b>
              </li>
            )}
          </div>
          <div className="col h-100"></div>
          <div className="col-5 h-100">
            <ul className="header__icon">
              {state?.claims!.EMPLOYEE &&
              window.location.pathname !== "/home" &&
              window.location.pathname.includes("/cashierdashboard") ===
                false ? (
                <>
                  {window.location.pathname.includes("/accounts") ||
                  window.location.pathname.includes("/admissions") ||
                  window.location.pathname.includes("/pendingstudentdata") ? (
                    <li className="header__icon--li">
                      <Autocomplete
                        classes={classes}
                        options={
                          financialYearResponse.data
                            ? financialYearResponse.data.GetFinYrsByInstId
                            : []
                        }
                        openOnFocus
                        getOptionLabel={(option) => option.fin_yr}
                        value={state.ActiveFinYr}
                        isOptionEqualToValue={(option) => {
                          if (state.ActiveFinYr) {
                            return option.id === state.ActiveFinYr.id;
                          } else {
                            return false;
                          }
                        }}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            dispatch({
                              type: payloadTypes.SET_ACTIVE_FIN_YR,
                              payload: {
                                ActiveFinYr: newValue,
                              },
                            });
                          } else {
                            dispatch({
                              type: payloadTypes.SET_ACTIVE_FIN_YR,
                              payload: {
                                ActiveFinYr: ActiveFinancicalYear.data
                                  ?.GetFinYrActiveByInstId! as unknown as ListFinancialYears,
                              },
                            });
                          }
                        }}
                        forcePopupIcon
                        popupIcon={<img src={DownArrow} alt="/" />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Financial Year"
                            placeholder="Search By Financial Year"
                            classes={{ root: textClasses.formControlRoot }}
                          />
                        )}
                      />
                    </li>
                  ) : null}
                  {window.location.pathname.includes("/academics") ||
                  window.location.pathname.includes(
                    "/pendingAcdstudentdata"
                  ) ? (
                    <li className="header__icon--li">
                      <Autocomplete
                        classes={classes}
                        options={
                          academicYearResponse.data
                            ? academicYearResponse.data.GetAcdYrsByInstId
                            : []
                        }
                        openOnFocus
                        getOptionLabel={(option) => option.acd_yr}
                        value={state.ActiveAcdYr}
                        isOptionEqualToValue={(option) => {
                          if (state.ActiveAcdYr) {
                            return option.id === state.ActiveAcdYr.id;
                          } else {
                            return false;
                          }
                        }}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            dispatch({
                              type: payloadTypes.SET_ACTIVE_ACD_YR,
                              payload: {
                                ActiveAcdYr: newValue,
                              },
                            });
                          } else {
                            dispatch({
                              type: payloadTypes.SET_ACTIVE_ACD_YR,
                              payload: {
                                ActiveAcdYr: activeAcademicYearData.data
                                  ?.GetAcdYrActiveByInstId! as unknown as ListAcademicYears,
                              },
                            });
                          }
                        }}
                        forcePopupIcon
                        popupIcon={<img src={DownArrow} alt="/" />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Academic Year"
                            placeholder="Search By Academic Year"
                            classes={{ root: textClasses.formControlRoot }}
                          />
                        )}
                      />
                    </li>
                  ) : null}
                  {/* <li className="header__icon--li">
                    <Autocomplete
                      classes={classes}
                      options={studentAddmissionNumber}
                      openOnFocus
                      onChange={(e, newValue) => {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: (newValue as responseType)?.value,
                          },
                        });
                      }}
                      forcePopupIcon
                      popupIcon={<img src={DownArrow} alt="/" />}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          state.studentId && toggleDrawer();
                        } else if (e.key === Keys.BACKSPACE) {
                          dispatch({
                            type: payloadTypes.SET_STUDENT_ID,
                            payload: { studentId: 0 },
                          });
                        }
                      }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => setAdmNumber(e.target.value)}
                          placeholder="Search By Adm No."
                          classes={{ root: textClasses.formControlRoot }}
                        />
                      )}
                    />
                  </li> */}
                </>
              ) : null}
              <li>
                <Tooltip
                  title="Full Screen"
                  arrow
                  placement="top"
                  classes={{
                    tooltip: tooltipClasses.customTooltipGrey,
                    arrow: tooltipClasses.customArrowGrey,
                  }}
                >
                  <img
                    src={Enlarge}
                    alt="/"
                    onClick={() => toggleFullSceen()}
                  />
                </Tooltip>
              </li>

              {state.empLoginId && state.InstId ? (
                <li>
                  <Skins />
                </li>
              ) : null}
              {state.claims?.EDUATE ? (
                <li>
                  <Tooltip
                    title="Token"
                    arrow
                    placement="top"
                    className="tooltip-hide"
                  >
                    <img
                      src={Inbox}
                      alt="/"
                      onClick={() => navigate("/token")}
                    />
                  </Tooltip>
                </li>
              ) : null}

              <li>
                <Tooltip
                  title="SignOut"
                  arrow
                  placement="top"
                  className="tooltip-hide"
                >
                  <img
                    src={Username}
                    alt="/"
                    onClick={() => setUserLogout(!userLogout)}
                  />
                </Tooltip>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={userLogout}
        ariaHideApp={false}
        style={LogOutModalStyles}
        onRequestClose={() => setUserLogout(!userLogout)}
      >
        <div className="logout-modal">
          <img src={Username} alt="/" />
          <Title variant="subtitle1">UserName</Title>
          <Title variant="subtitle2">{auth.currentUser?.email}</Title>

          <Label
            variant="LabelPrimary"
            onClick={() => {
              setPasswordModal(!passwordModal);
              setUserLogout(!userLogout);
            }}
          >
            Change Password
          </Label>

          <Button
            onClick={() => {
              handleDeleteUserDeviceId();
              setUserLogout(!userLogout);
            }}
            mode="sign-out"
          />
        </div>
      </Modal>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
      >
        <Title variant="subtitle1">Student Details</Title>
        <div className="header__student-details">
          <div className="row g-0 header__student-details--data">
            <div className="col-5">
              <img
                src={Avatar}
                alt="/"
                className="header__student-details--data--student-profile"
              />
            </div>
            <div className="col">
              <div className="header__student-details--data--label-grid">
                <Label>Reg No. </Label>
                <span>:</span>
                <Label variant="LabelBold">{studentFormData.adm_no}</Label>
                <Label>Name </Label> <span>:</span>
                <Label>{studentFormData.std_name}</Label>
                <Label>{classLabel} </Label>
                <span>:</span> <Label>{studentFormData.class}</Label>
                <Label>Mob No.</Label> <span>:</span>
                <Label>{studentFormData.std_mobile}</Label>
              </div>
            </div>
          </div>
          <div className="header__student-details--navigation">
            <Tooltip
              title="More Information"
              arrow
              placement="top"
              className="tooltip-hide"
            >
              <img
                src={Information}
                alt="/"
                className="header__student-details--navigation--info"
                onClick={() =>
                  navigate(
                    `/${InstId}/masters/studentlist/${state.studentId}/studentbasicdata`
                  )
                }
              />
            </Tooltip>
            <Tooltip
              title="Pay Fee"
              arrow
              placement="top"
              className="tooltip-hide"
            >
              <img
                src={PayFee}
                alt="/"
                onClick={() =>
                  navigate(`/${InstId}/accounts/studentfeereceipt`)
                }
              />
            </Tooltip>

            <Tooltip
              title="Chat"
              arrow
              placement="top"
              className="tooltip-hide"
            >
              <img src={Chat} alt="/" />
            </Tooltip>
          </div>
        </div>
      </Drawer>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={passwordModal}
        style={WaiveOffModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="col">
              <Captcha setPasswordModal={setPasswordModal} />
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPasswordModal(!passwordModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
