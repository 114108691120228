import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDropdownData from "../../../customhooks/useDropdownData";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import { Title } from "../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import { TableHeaderProps } from "../../../Types/Tables";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import { Keys } from "../../../utils/Enum.keys";
import { Cell } from "exceljs";
import Eduate from "../../../images/Eduate_Logo_image.png";
import ExcelJS from "exceljs";

import {
  ExcelAlignment,
  ExcelFont,
  ExcelFooterHeader,
  ExcelPageHeader,
  ExcelSheetsNames,
  FileUploadParams,
  InstitutionConfigurationTypes,
  StudentAcctReportType,
  StudentStatus,
} from "../../../utils/Enum.types";
import { responseType } from "../../../utils/Form.types";
import {
  formatter,
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import Home from "../Home/Index";
import UseStudentsbyDemandAmount, {
  StudentDemandEdges,
} from "../hooks/useStudentsbyDemandAmount";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BALANCE_FONT,
  BALANCE_FONT_BOLD,
  BLOB_TYPE,
  BORDER_DATA,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  F4_CELL,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FOOTER_CSS,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  K4_CELL,
  LEDGER_WISE_FONT,
  RIGHT_ALIGNMENT,
  TABLE_CSS,
  TABLE_HEADER_CSS,
  TOTAL,
} from "../../Library/Constants";
import { ldgrHeaderData } from "../common/HeaderConsts";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import { LedgerWiseReportStyles } from "../../../styles/StickyTableStyles";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import StudentTotalCount from "../../Master/Student/Components/StudentTotalCount";
import useSummationDatabyNewApi from "../hooks/useSummationDatabyNewApi";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useAcctTableJson from "../json/useAcctTableJson";
import { AppContext } from "../../../context/context";

const StudentLedgerWiseReport = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const tableClasses = LedgerWiseReportStyles();
  const { Accounts_Table } = useAcctTableJson();
  const navigate = useNavigate();
  const { format } = formatter;
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { state } = useContext(AppContext);

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );

  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [status, setStatus] = useState("");
  // const [selectedLedgers, setSelectedLedgers] = useState<responseType[]>([]);
  // const [searchLedger, setSearchLedger] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentDemandEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [searchData, setSearchData] = useState("");
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
    categoryLabel
  } = useInstLabels();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  const { categoryDropDown } = useInstMasterDataByInstId();

  const { students: StudentsData } = UseStudentsbyDemandAmount(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    searchData,
    rowsPerPage,
    StudentAcctReportType.DEMAND_RAISED,
    0,
    status
  );
  const { StudentsSummationData } = useSummationDatabyNewApi(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    StudentAcctReportType.DEMAND_RAISED,
    status
  );

  const { data, fetchMore, loading } = StudentsData;

  let outStandingBalance = 0;
  let demandAmount = 0;
  let concession = 0;
  let balance = 0;
  let payable = 0;
  let paidAmount = 0;
  let refunds = 0;

  for (const edge of data ? data.GetAcctStdDemand.edges : [] || []) {
    outStandingBalance += edge.node.std_demand_ob;
    demandAmount += edge.node.std_demand_amt;
    concession += edge.node.std_demand_concession;
    balance += edge.node.std_demand_bal;
    payable += edge.node.std_demand_receivable;
    paidAmount += edge.node.std_demand_received;
    refunds += edge.node.std_demand_refunds;
  }

  const { InstDetails } = useInstDetails(1);

  const studentReport = data
    ? data.GetAcctStdDemand.edges.map((edge, index) => {
        const ledgerDetails = edge.node.demand_details.map(
          (acctLedger) => acctLedger.acct_ldgr_details.ldgr_desc
        );
        const demandOB = edge.node.demand_details.map(
          (demand) => demand.fee_ob
        );
        const demandAmt = edge.node.demand_details.map((amt) =>
          format(amt.fee_demand)
        );
        const concession = edge.node.demand_details.map((res) =>
          format(res.fee_concession)
        );
        const refunds = edge.node.demand_details.map((refund) =>
          format(refund.fee_refunds)
        );
        const receivables = edge.node.demand_details.map((receivable) =>
          format(receivable.fee_receivable)
        );
        const recieved = edge.node.demand_details.map((reciev) =>
          format(reciev.fee_received)
        );
        const balance = edge.node.demand_details.map((bal) =>
          format(bal.fee_bal)
        );
        const row = {
          SlNo: index + 1,
          regNo: edge.node.mst_student.std_reg_no,
          admNo: edge.node.mst_student.std_adm_no,
          stdName: edge.node.mst_student.first_name,
          ldgrName: ledgerDetails,
          OB: demandOB,
          totalOB: edge.node.std_demand_ob,
          demandamt: demandAmt,
          totalAmt: format(edge.node.std_demand_amt),
          concession: concession,
          totalConcession: format(edge.node.std_demand_concession),
          refunds: refunds,
          totalRefund: format(edge.node.std_demand_refunds),
          receivables: receivables,
          totalRec: format(edge.node.std_demand_receivable),
          recieved: recieved,
          totalReceived: format(edge.node.std_demand_received),
          balance: balance,
          totalBalance: format(edge.node.std_demand_bal),
        };
        return row;
      })
    : [];
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.LEDGER_WISE);
    const dataRows = studentReport
      ? studentReport.map((data) => ({
          regNo: data.regNo,
          admNo: data.admNo,
          name: data.stdName,
          ledgers: data.ldgrName.map((ledger, index) => ({
            ledgerName: ledger,
            amount: data.OB[index],
            demandAmount: data.demandamt[index],
            concession: data.concession[index],
            refund: data.refunds[index],
            receivables: data.receivables[index],
            received: data.recieved[index],
            balance: data.balance[index],
          })),
          totalOB: data.totalOB,
          totalDemand: data.totalAmt,
          totalConcession: data.totalConcession,
          totalRefund: data.totalRefund,
          totalReceivable: data.totalRec,
          totalReceived: data.totalReceived,
          totalBalance: data.totalBalance,
        }))
      : [];

    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 33;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 20;
    worksheet.getColumn(1).width = 5;
    worksheet.getColumn(2).width = 13;
    worksheet.getColumn(3).width = 13;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 19;
    worksheet.getColumn(6).width = 17;
    worksheet.getColumn(7).width = 17;
    worksheet.getColumn(8).width = 17;
    worksheet.getColumn(9).width = 17;
    worksheet.getColumn(10).width = 17;
    worksheet.getColumn(11).width = 17;
    worksheet.getColumn(12).width = 17;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          if (configLogo) {
            worksheet.addImage(imageV, "K1:K3");
          }
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");
                  worksheet.mergeCells(1, 1, 1, 12);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:L2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:L3");

                  const mergedHeader: Cell = worksheet.getCell(F4_CELL);
                  mergedHeader.value = ExcelPageHeader.LEDGER_WISE;
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("F4:J4");
                  const mergedDate: Cell = worksheet.getCell(K4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("K4:L4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:E4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < ldgrHeaderData.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = ldgrHeaderData[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }
                  let dataRow = 6;
                  let ledgersLength = 0;
                  dataRows?.forEach((rowData, index) => {
                    const slCell = worksheet.getCell(
                      `A${dataRow + ledgersLength}`
                    );
                    const regCell = worksheet.getCell(
                      `B${dataRow + ledgersLength}`
                    );
                    const admCell = worksheet.getCell(
                      `C${dataRow + ledgersLength}`
                    );
                    const nameCell = worksheet.getCell(
                      `D${dataRow + ledgersLength}`
                    );

                    slCell.value = index + 1;
                    worksheet.mergeCells(
                      `A${dataRow + ledgersLength}:A${
                        dataRow + rowData.ledgers.length + ledgersLength
                      }`
                    );
                    slCell.style = {
                      alignment: HEADER_ALIGNMENT_CENTER,
                    };

                    worksheet.mergeCells(
                      `B${dataRow + ledgersLength}:B${
                        dataRow + rowData.ledgers.length + ledgersLength
                      }`
                    );
                    regCell.style = {
                      alignment: HEADER_ALIGNMENT_LEFT,
                    };
                    worksheet.mergeCells(
                      `C${dataRow + ledgersLength}:C${
                        dataRow + rowData.ledgers.length + ledgersLength
                      }`
                    );
                    admCell.style = {
                      alignment: HEADER_ALIGNMENT_LEFT,
                    };
                    worksheet.mergeCells(
                      `D${dataRow + ledgersLength}:D${
                        dataRow + rowData.ledgers.length + ledgersLength
                      }`
                    );
                    nameCell.style = {
                      alignment: HEADER_ALIGNMENT_LEFT,
                    };

                    regCell.value = rowData.regNo;
                    admCell.value = rowData.admNo;
                    nameCell.value = rowData.name;
                    nameCell.font = { name: ExcelFont.CENTURY_GOTHIC, size: 9 };

                    rowData.ledgers.forEach((ledger, index) => {
                      const ledgerCell = worksheet.getCell(
                        `E${dataRow + ledgersLength + index}`
                      );
                      const obCell = worksheet.getCell(
                        `F${dataRow + ledgersLength + index}`
                      );
                      const demandCell = worksheet.getCell(
                        `G${dataRow + ledgersLength + index}`
                      );
                      const concessionCell = worksheet.getCell(
                        `H${dataRow + ledgersLength + index}`
                      );
                      const refundCell = worksheet.getCell(
                        `I${dataRow + ledgersLength + index}`
                      );
                      const recievableCell = worksheet.getCell(
                        `J${dataRow + ledgersLength + index}`
                      );
                      const recievedCell = worksheet.getCell(
                        `K${dataRow + ledgersLength + index}`
                      );
                      const balanceCell = worksheet.getCell(
                        `L${dataRow + ledgersLength + index}`
                      );

                      ledgerCell.value = ledger.ledgerName;

                      obCell.value = ledger.amount;
                      demandCell.value = ledger.demandAmount;
                      concessionCell.value = ledger.concession;
                      refundCell.value = ledger.refund;
                      recievableCell.value = ledger.receivables;
                      recievedCell.value = ledger.received;
                      balanceCell.value = ledger.balance;
                      ledgerCell.font = {
                        name: ExcelFont.CENTURY_GOTHIC,
                        size: 9,
                      };
                      ledgerCell.alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };

                      obCell.font = BALANCE_FONT_BOLD;

                      demandCell.alignment = RIGHT_ALIGNMENT;
                      demandCell.font = BALANCE_FONT_BOLD;
                      concessionCell.alignment = RIGHT_ALIGNMENT;
                      concessionCell.font = BALANCE_FONT_BOLD;

                      refundCell.alignment = RIGHT_ALIGNMENT;
                      refundCell.font = BALANCE_FONT_BOLD;

                      recievableCell.alignment = RIGHT_ALIGNMENT;
                      recievableCell.font = BALANCE_FONT_BOLD;

                      recievedCell.alignment = RIGHT_ALIGNMENT;
                      recievedCell.font = BALANCE_FONT_BOLD;

                      balanceCell.alignment = RIGHT_ALIGNMENT;
                      balanceCell.font = BALANCE_FONT_BOLD;
                    });

                    const totalCell = worksheet.getCell(
                      `E${dataRow + ledgersLength + rowData.ledgers.length}`
                    );
                    const totalOBcell = worksheet.getCell(
                      `F${dataRow + ledgersLength + rowData.ledgers.length}`
                    );
                    const totalDemandCell = worksheet.getCell(
                      `G${dataRow + ledgersLength + rowData.ledgers.length}`
                    );
                    const totalConcessionCell = worksheet.getCell(
                      `H${dataRow + ledgersLength + rowData.ledgers.length}`
                    );
                    const totalRefundCell = worksheet.getCell(
                      `I${dataRow + ledgersLength + rowData.ledgers.length}`
                    );
                    const totalReceivableCell = worksheet.getCell(
                      `J${dataRow + ledgersLength + rowData.ledgers.length}`
                    );
                    const totalReceivedCell = worksheet.getCell(
                      `K${dataRow + ledgersLength + rowData.ledgers.length}`
                    );
                    const totalBalanceCell = worksheet.getCell(
                      `L${dataRow + ledgersLength + rowData.ledgers.length}`
                    );
                    totalCell.value = TOTAL;
                    totalConcessionCell.value = rowData.totalConcession;
                    totalConcessionCell.fill = TABLE_CSS;
                    totalConcessionCell.alignment = RIGHT_ALIGNMENT;
                    totalRefundCell.value = rowData.totalRefund;
                    totalRefundCell.fill = TABLE_CSS;
                    totalRefundCell.alignment = RIGHT_ALIGNMENT;
                    totalReceivableCell.value = rowData.totalReceivable;
                    totalReceivableCell.fill = TABLE_CSS;
                    totalReceivableCell.alignment = RIGHT_ALIGNMENT;
                    totalReceivedCell.value = rowData.totalReceived;
                    totalReceivedCell.fill = TABLE_CSS;
                    totalReceivedCell.alignment = RIGHT_ALIGNMENT;
                    totalBalanceCell.value = rowData.totalBalance;
                    totalBalanceCell.fill = TABLE_CSS;
                    totalBalanceCell.alignment = RIGHT_ALIGNMENT;

                    totalCell.font = LEDGER_WISE_FONT;
                    totalCell.fill = TABLE_CSS;
                    totalCell.alignment = RIGHT_ALIGNMENT;
                    totalOBcell.value = rowData.totalOB;
                    totalOBcell.font = BALANCE_FONT;
                    totalOBcell.fill = TABLE_CSS;

                    totalDemandCell.value = rowData.totalDemand;
                    totalDemandCell.alignment = RIGHT_ALIGNMENT;
                    totalDemandCell.font = BALANCE_FONT;
                    totalDemandCell.fill = TABLE_CSS;
                    totalConcessionCell.font = BALANCE_FONT;
                    totalRefundCell.font = BALANCE_FONT;
                    totalReceivableCell.font = BALANCE_FONT;
                    totalReceivedCell.font = BALANCE_FONT;
                    totalBalanceCell.font = BALANCE_FONT;
                    ledgersLength += rowData.ledgers.length + 1;
                  });
                  const footerRow = worksheet.addRow([]);
                  footerRow.getCell(5).value = ExcelFooterHeader.GRAND_TOTAL;
                  footerRow.getCell(5).font = {
                    name: ExcelFont.ARIAL,
                    size: 10,
                  };
                  footerRow.getCell(6).value = outStandingBalance;
                  footerRow.getCell(7).value = demandAmount;
                  footerRow.getCell(8).value = concession;
                  footerRow.getCell(9).value = refunds;
                  footerRow.getCell(10).value = payable;
                  footerRow.getCell(11).value = paidAmount;
                  footerRow.getCell(12).value = balance;
                  for (let i = 5; i <= 12; i++) {
                    footerRow.getCell(i).alignment = {
                      horizontal: ExcelAlignment.RIGHT,
                    };
                  }
                  worksheet.addConditionalFormatting({
                    ref: `F${footerRow.number}:L${footerRow.number}`,
                    rules: FOOTER_CSS,
                  });
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, ExcelSheetsNames.LEDGER_WISE);
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(ROWS_PER_PAGE);
                });
              });
          });
        });
      });
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight + 1;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctStdDemand.edges;
            const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
              ({
                node: {
                  mst_student: { id },
                },
              }) =>
                newEdges.findIndex(
                  ({
                    node: {
                      mst_student: { id: newId },
                    },
                  }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctStdDemand: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data?.GetAcctStdDemand.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (
      excelFlag &&
      rowsPerPage === null &&
      !loading &&
      data?.GetAcctStdDemand.edges.length === data?.GetAcctStdDemand.totalCount
    ) {
      downloadExcel();
    }
    // eslint-disable-next-line
  }, [excelFlag, rowsPerPage, loading]);
  useEffect(() => {
    if (data && !loading && excelFlag === false) {
      const newData = data.GetAcctStdDemand.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) =>
              student.node.mst_student.id === newStudent.node.mst_student.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data.GetAcctStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, excelFlag]);
  const StudentsSummationDataCount =
    StudentsSummationData.data &&
    StudentsSummationData.data.GetAcctStdDemandSummation.length
      ? StudentsSummationData.data.GetAcctStdDemandSummation[0]
      : {
          std_demand_amt: 0,
          std_demand_concession: 0,
          std_demand_ob: 0,
          std_demand_receivable: 0,
          std_demand_received: 0,
          std_demand_refunds: 0,
          std_demand_bal: 0,
        };
  return (
    <>
      <Home DashBoardRequired={false} />

      <Title>Ledger Wise Student Report </Title>
      <div className="ledger-wise-student-report">
        <form className="row g-0 ledger-wise-student-report__options">
          <div className="col-1 studentlist__select-flex studentlist__search">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
            />
          </div>

          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={departmentDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentSelected)
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    InputLabelProps={{ shrink: true }}
                    id="outlined Departments"
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={branchDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, branchSelected)
                }
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={classDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, classSelected)
                }
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={semesterDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, semesterSelected)
                }
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, sectionSelected)
                }
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue);
                  } else {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CATEGORY_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={categoryDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, categorySelected)
                }
                value={categorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategorySelected(newValue);
                  } else {
                    setCategorySelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={categoryLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={Object.values(StudentStatus)}
              openOnFocus
              value={status}
              isOptionEqualToValue={(option) =>
                status === "" || option === status
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setStatus(newValue);
                } else {
                  setStatus(EMPTY_STRING);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setStatus(EMPTY_STRING);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                  fullWidth
                />
              )}
            />
          </div>
          {/* to be implemented later */}
          {/* <div className="col-1">
            <Autocomplete
              classes={classes}
              options={ledgerOptions}
              multiple
              limitTags={MULTI_SELECT_LIMIT_TAGS}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setSelectedLedgers([]);
                }
              }}
              value={selectedLedgers}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSelectedLedgers(newValue);
                } else {
                  setSelectedLedgers([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Fee Ledgers"
                  fullWidth
                  onChange={(e) => setSearchLedger(e.target.value)}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div> */}
        </form>
        <div className="ledger-wise-student-report__tableblock">
          <TableContainer
            className={tableClasses.table}
            onScroll={handleScroll}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {Accounts_Table.Reports.LedgerWiseStudentReport.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell
                          key={index}
                          className={
                            th.labelName === "Sl"
                              ? tableClasses.stickyHeaderSl
                              : th.labelName === "Reg No."
                              ? tableClasses.stickyHeaderRegNo
                              : th.labelName === "Adm No."
                              ? tableClasses.stickyHeaderAdmNo
                              : th.labelName === "Student Name"
                              ? tableClasses.stickyHeaderName
                              : th.labelName === classLabel
                              ? tableClasses.stickyHeaderCName
                              : th.className === "Ledger Name"
                              ? tableClasses.stickyHeaderLedgerName
                              : tableClasses.stickyHeaderAmount
                          }
                          align="center"
                        >
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((record, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        id="td-center"
                        className={tableClasses.stickyColumnSl}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className={tableClasses.stickyColumnRegno}>
                        {record.node.mst_student.std_reg_no}
                      </TableCell>
                      <TableCell className={tableClasses.stickyColumnAdmno}>
                        {record.node.mst_student.std_adm_no}
                      </TableCell>
                      <TableCell className={tableClasses.stickyColumnName}>
                        {record.node.mst_student.first_name +
                          " " +
                          record.node.mst_student.middle_name +
                          " " +
                          record.node.mst_student.last_name}
                      </TableCell>
                      <TableCell className={tableClasses.stickyColumnCName}>
                        {record.node.class.class_desc}
                      </TableCell>
                      <TableCell className={tableClasses.stickyColumnCName}>
                        {record.node.mst_student.std_father_name}
                      </TableCell>
                      <TableCell className={tableClasses.stickyColumnCName}>
                        {record.node.mst_student.std_mobile}
                      </TableCell>
                      <TableCell
                        className={tableClasses.stickyColumnLedgerName}
                      >
                        <ul>
                          {record.node.demand_details.map(
                            (acctLedger, index) => {
                              return (
                                <li
                                  key={acctLedger.acct_ldgr_details.id}
                                  className="ledger-wise-student-report__table--ledgers--ledgers"
                                >
                                  {`${index + 1})`}&nbsp;
                                  {acctLedger.acct_ldgr_details.ldgr_desc}
                                </li>
                              );
                            }
                          )}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            Total :
                          </li>
                        </ul>
                      </TableCell>

                      <TableCell
                        id="td-right"
                        className={tableClasses.stickyColumnAmount}
                      >
                        <ul>
                          {record.node.demand_details.map((demand, index) => {
                            return (
                              <li
                                key={index}
                                className="ledger-wise-student-report__table--ledgers--ledgers"
                              >
                                {format(demand.fee_ob)}
                              </li>
                            );
                          })}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            {format(record.node.std_demand_ob)}
                          </li>
                        </ul>
                      </TableCell>

                      <TableCell
                        id="td-right"
                        className={tableClasses.stickyColumnAmount}
                      >
                        <ul>
                          {record.node.demand_details.map((demand, index) => {
                            return (
                              <li
                                key={index}
                                className="ledger-wise-student-report__table--ledgers--ledgers"
                              >
                                {format(demand.fee_demand)}
                              </li>
                            );
                          })}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            {format(record.node.std_demand_amt)}
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={tableClasses.stickyColumnAmount}
                      >
                        <ul>
                          {record.node.demand_details.map((demand, index) => {
                            return (
                              <li
                                key={index}
                                className="ledger-wise-student-report__table--ledgers--ledgers"
                              >
                                {format(demand.fee_concession)}
                              </li>
                            );
                          })}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            {format(record.node.std_demand_concession)}
                          </li>
                        </ul>
                      </TableCell>

                      <TableCell
                        id="td-right"
                        className={tableClasses.stickyColumnAmount}
                      >
                        <ul>
                          {record.node.demand_details.map((demand, index) => {
                            return (
                              <li
                                key={index}
                                className="ledger-wise-student-report__table--ledgers--ledgers"
                              >
                                {format(demand.fee_receivable)}
                              </li>
                            );
                          })}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            {format(record.node.std_demand_receivable)}
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={tableClasses.stickyColumnAmount}
                      >
                        <ul>
                          {record.node.demand_details.map((demand, index) => {
                            return (
                              <li
                                key={index}
                                className="ledger-wise-student-report__table--ledgers--ledgers"
                              >
                                {format(demand.fee_received)}
                              </li>
                            );
                          })}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            {format(record.node.std_demand_received)}
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={tableClasses.stickyColumnAmount}
                      >
                        <ul>
                          {record.node.demand_details.map((demand, index) => {
                            return (
                              <li
                                key={index}
                                className="ledger-wise-student-report__table--ledgers--ledgers"
                              >
                                {format(demand.fee_refunds)}
                              </li>
                            );
                          })}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            {format(record.node.std_demand_refunds)}
                          </li>
                        </ul>
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className={tableClasses.stickyColumnAmount}
                      >
                        <ul>
                          {record.node.demand_details.map((demand, index) => {
                            return (
                              <li
                                key={index}
                                className="ledger-wise-student-report__table--ledgers--ledgers"
                              >
                                {format(demand.fee_bal)}
                              </li>
                            );
                          })}
                          <li className="ledger-wise-student-report__table--ledgers--total">
                            {format(record.node.std_demand_bal)}
                          </li>
                        </ul>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {searchData.length === 0 &&
              StudentsSummationData &&
              StudentsSummationData.data &&
              StudentsSummationData.data.GetAcctStdDemandSummation.length ? (
                <TableFooter>
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      className={tableClasses.stickyColumnFooterTotal}
                      id="td-right"
                    >
                      Grand Total:
                    </TableCell>
                    <TableCell
                      className={tableClasses.stickyColumnFooter}
                      id="td-right"
                    >
                      {format(StudentsSummationDataCount.std_demand_ob)}
                    </TableCell>
                    <TableCell
                      className={tableClasses.stickyColumnFooter}
                      id="td-right"
                    >
                      {format(StudentsSummationDataCount.std_demand_amt)}
                    </TableCell>
                    <TableCell
                      className={tableClasses.stickyColumnFooter}
                      id="td-right"
                    >
                      {format(StudentsSummationDataCount.std_demand_concession)}
                    </TableCell>

                    <TableCell
                      className={tableClasses.stickyColumnFooter}
                      id="td-right"
                    >
                      {format(StudentsSummationDataCount.std_demand_receivable)}
                    </TableCell>
                    <TableCell
                      className={tableClasses.stickyColumnFooter}
                      id="td-right"
                    >
                      {format(StudentsSummationDataCount.std_demand_received)}
                    </TableCell>
                    <TableCell
                      className={tableClasses.stickyColumnFooter}
                      id="td-right"
                    >
                      {format(StudentsSummationDataCount.std_demand_refunds)}
                    </TableCell>
                    <TableCell
                      className={tableClasses.stickyColumnFooter}
                      id="td-right"
                    >
                      {format(StudentsSummationDataCount.std_demand_bal)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              ) : null}
            </Table>
          </TableContainer>
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              onClick={() => {
                setRowsPerPage(null);
                setExcelFlag(true);
              }}
              mode="export"
              disabled={classSelected && classSelected.value ? false : true}
            />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          {data ? (
            <div className="col-2 ledger-wise-student-report__total">
              <StudentTotalCount
                totalCount={data ? data.GetAcctStdDemand.totalCount! : 0}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default StudentLedgerWiseReport;
