import { FormControlLabel, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import { EditModalCustomStyles } from "../../../styles/ModalStyles";
import Home from "../Home/Index";
import Close from "../../../images/Close.svg";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import { Label } from "../../../stories/Label/Label";
import Input from "../../../stories/Input/Input";
import { AntSwitch } from "../../../pages/Switch";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetAcdInstMonthlyWorkingCalendar } from "../queries/holidays/list";
import {
  GetAcdInstMonthlyWorkingCalendarData,
  GetAcdInstMonthlyWorkingCalendarDetails,
  GetAcdInstMonthlyWorkingCalendarVars,
} from "../types/attendance";
import useToken from "../../../customhooks/useToken";
import {
  MonthName,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { Operation } from "../../../utils/Enum.types";
import { EMPTY_STRING } from "../../../utils/constants";
import { msgType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";
import { UpdateAcdInstMonthlyWorkingCalendar } from "../queries/holidays/mutation";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import {
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../styles/DataGridTableStyles";
import useHRtable from "../../HR/json/useHRtable";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../utils/types";

const MonthlyList = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const [viewModal, setViewModal] = useState(false);
  const dataClasses = useDataGridStyles();
  const { HR_Table } = useHRtable();
  const [monthlyAttendenceDetails, setMonthlyAttendenceDetails] = useState<
    GetAcdInstMonthlyWorkingCalendarDetails[]
  >([]);
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [allSetToProceed, setAllSetToProceed] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [filteredMonthlyDetail, setFilteredMonthlyDetail] =
    useState<GetAcdInstMonthlyWorkingCalendarDetails>();
  const { activeAcademicYearData } = useActiveAcademicYear();

  const [updateMonthlyHolidays] = useMutation(
    UpdateAcdInstMonthlyWorkingCalendar,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetAttendenceMonthlyDetails] = useLazyQuery<
    GetAcdInstMonthlyWorkingCalendarData,
    GetAcdInstMonthlyWorkingCalendarVars
  >(GetAcdInstMonthlyWorkingCalendar, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      inst_id: InstId!,
      token,
    },

    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleFilterMonthDetails = (id: number) => {
    const res = monthlyAttendenceDetails.find(
      (monthlyDetail) => monthlyDetail.id === id
    );
    if (res) {
      setFilteredMonthlyDetail(res);
      setAllSetToProceed(res.calendar_entered);
      // setStartDate(res.month_start_date);
      // setEndDate(res.month_end_date);
    }
  };
  const handleUpdate = () => {
    updateMonthlyHolidays({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
        cal_month: toIsoDate(filteredMonthlyDetail?.month_start_date!),
        all_set_proceed: allSetToProceed,
      },
      refetchQueries: [
        {
          query: GetAcdInstMonthlyWorkingCalendar,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            inst_id: InstId!,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: `Added Attendence for the month ${MonthName(
            filteredMonthlyDetail?.month_start_date.toString()!
          )}`,
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setViewModal(!viewModal);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (
      (activeAcademicYearData.data &&
        !activeAcademicYearData.loading &&
        state.ActiveAcdYr) ||
      viewModal
    ) {
      GetAttendenceMonthlyDetails().then(({ data }) => {
        if (data) {
          setMonthlyAttendenceDetails(data.GetAcdInstMonthlyWorkingCalendar);
        }
      });
    } // eslint-disable-next-line
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    viewModal,
    state.ActiveAcdYr,
  ]);
  const dynamicHeaders: TableHeaderProps[] = HR_Table.Attendance.Monthly.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );
  const columns: GridColDef[] = [...dynamicHeaders];
  const rows: GridValidRowModel[] =
    (monthlyAttendenceDetails || []).map((data, index) => {
      return {
        id: index + 1,
        months: MonthName(data.cal_month),
        s_date: toStandardDate(data.month_start_date),
        end_date: toStandardDate(data.month_end_date),
        status: data.calendar_entered ? "Completed" : "-",
        general_holidays: data.no_of_general,
        unexcepected_holidays: data.no_of_unexpected,
        vacation_holidays: data.no_of_vacation,
        weekend_holidays: data.no_of_weekend_days,
        month_id: data.id,
      };
    }) || [];
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>List of Months With Attendance Status</Title>
      <div className="monthly-attendance-list">
        <div
          className={`monthly-attendance-list__tableblock ${dataClasses.root}`}
        >
          <DataGridPro
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            onCellClick={(params) => {
              if (params.field === "months") {
                setViewModal(!viewModal);
                handleFilterMonthDetails(params.row.month_id);
              }
            }}
            disableRowSelectionOnClick
            hideFooter
            slotProps={{
              columnsPanel: { disableHideAllButton: true },
            }}
          />
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={viewModal}
        style={EditModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="monthly-attendance-list__view h-100">
              <Title>View Start & End Date</Title>
              <div className="monthly-attendance-list__view--label-grid">
                <Label>Month</Label>
                <Input
                  disabled
                  value={MonthName(filteredMonthlyDetail?.cal_month!)}
                />
                <Label>Start Date</Label>
                <Input
                  type="date"
                  value={toInputStandardDate(
                    filteredMonthlyDetail?.month_start_date! || EMPTY_STRING
                  )}
                  disabled
                />
                <Label>End Date</Label>
                <Input
                  type="date"
                  value={toInputStandardDate(
                    filteredMonthlyDetail?.month_end_date! || EMPTY_STRING
                  )}
                  disabled
                />
              </div>

              <div className="row g-0 monthly-attendance-list__days">
                <div className="col">
                  <TextField
                    disabled
                    className="monthly-attendance-list__textfield"
                    InputLabelProps={{ shrink: true }}
                    label="Month Days"
                    value={filteredMonthlyDetail?.no_of_days || 0}
                  />
                </div>
                <div className="col">
                  <TextField
                    disabled
                    className="monthly-attendance-list__textfield"
                    InputLabelProps={{ shrink: true }}
                    label="Weekends"
                    value={filteredMonthlyDetail?.no_of_weekend_days || 0}
                  />
                </div>
                <div className="col">
                  <TextField
                    disabled
                    className="monthly-attendance-list__textfield"
                    InputLabelProps={{ shrink: true }}
                    label="General Holidays"
                    value={filteredMonthlyDetail?.no_of_general || 0}
                  />
                </div>
              </div>
              <div className="row g-0 monthly-attendance-list__days">
                <div className="col">
                  <TextField
                    disabled
                    className="monthly-attendance-list__textfield"
                    InputLabelProps={{ shrink: true }}
                    label="Unexpected Holidays"
                    value={filteredMonthlyDetail?.no_of_unexpected || 0}
                  />
                </div>
                <div className="col">
                  <TextField
                    className="monthly-attendance-list__textfield"
                    InputLabelProps={{ shrink: true }}
                    label="Total Holidays"
                    disabled
                    value={filteredMonthlyDetail?.total_no_of_holidays || 0}
                  />
                </div>
                <div className="col">
                  <TextField
                    className="monthly-attendance-list__textfield"
                    InputLabelProps={{ shrink: true }}
                    label="Total Working Days"
                    disabled
                    value={filteredMonthlyDetail?.total_no_of_workdays || 0}
                  />
                </div>
                <div className="col">
                  <TextField
                    className="monthly-attendance-list__textfield"
                    InputLabelProps={{ shrink: true }}
                    label="Vacation Holidays"
                    disabled
                    value={filteredMonthlyDetail?.no_of_vacation || 0}
                  />
                </div>
              </div>
              <FormControlLabel
                label="All Set to Proceed"
                labelPlacement="start"
                control={
                  <AntSwitch
                    checked={allSetToProceed}
                    onClick={() => setAllSetToProceed(!allSetToProceed)}
                    disabled={allSetToProceed}
                  />
                }
                className="monthly-attendance-list__view--form-labels"
              />
              {filteredMonthlyDetail?.calendar_entered ? (
                <span
                  className="monthly-attendance-list__view--status"
                  id="td-center"
                >
                  "...Process Completed...."
                </span>
              ) : null}
              <div>
                <Button
                  mode="save"
                  disabled={!allSetToProceed}
                  onClick={handleUpdate}
                />
                <Button
                  mode="cancel"
                  onClick={() => setViewModal(!viewModal)}
                />
              </div>
            </div>
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setViewModal(!viewModal)} />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default MonthlyList;
