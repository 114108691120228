import {
  Autocomplete,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { TableHeaderProps } from "../../../../Types/Tables";
import { EMPTY_STRING } from "../../../../utils/constants";
import {
  Direction,
  InstitutionConfigurationTypes,
  Operation,
  SortBy,
} from "../../../../utils/Enum.types";
import { msgType, responseType } from "../../../../utils/Form.types";
import Home from "../../Home/Index";
import {
  AcdStudentsvars,
  GetStudentAcdData,
  StudentEdges,
} from "../../hooks/useAcdStudentsData";
import { Keys } from "../../../../utils/Enum.keys";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AddAcdTestMarksForStds } from "../../queries/test/mutation";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import LoadingModal from "../../../../pages/LoadingModal";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import Input from "../../../../stories/Input/Input";
import useTestClassSubjects, {
  TestClassSubjectQueryType,
} from "../../hooks/useTestClassSubjects";
import Modal from "react-modal";

import { GetAcdTestClassSubjects } from "../../queries/test/query";
// import { sub_marks } from "./UpdateMarks";
import { AntSwitch } from "../../../../pages/Switch";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { GetAcdStudents } from "../../queries/students/Index";
import { StudentAcdType } from "../../../../utils/studentAcdEnum.types";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import InstDetails from "../InstDetails";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import {
  GetAcdGradeScalesByInstIdData,
  GetAcdGradeScalesByInstIdVars,
} from "../../types/grade";
import Close from "../../../../images/Close.svg";

import { GetAcdGradeScalesByInstId } from "../../queries/grade";
import useCheckAllocationType from "../../hooks/useCheckAllocationType";
import TextArea from "../../../../stories/TextArea/TextArea";
import Enlarge from "../../../../images/Enlarge.svg";
import { allocateTeachers } from "../../../../styles/DrawerStyles";
import Remarks from "./Remarks";
import MarksImport from "./MarksImport";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
const { Academics_Table } = require("../../json/table.json");
export enum EnterMarksWise {
  SUBJECT_WISE = "Subject Wise",
  STUDENT_WISE = "Student Wise",
}
export interface RemarksType {
  [index: number]: string;
}
export interface ISubjectMarks {
  [subjectId: number]: StudentEdges[];
}
const EnterMarks = () => {
  const navigate = useNavigate();
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { user_details } = useLoggedInUserDetails();
  const [remarksModal, setRemarksModal] = useState(false);
  const { InstId, testId, entryId, subjectId } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const formClasses = formAutoCompleteStyles();
  const formTextClasses = formAutoCompleteTextStyles();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [importModal, setImportModal] = useState(false);

  const saveRef = useRef<HTMLButtonElement>(null);
  const { serverDate } = useServerDateandTime();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const [subjectSelected, setSubjectSelected] = useState<responseType | null>(
    null
  );

  const { flag: AllocationFlag } = useCheckAllocationType();
  const { entry_level } = useInstitutionConfiguration();
  const { testConductDetails } = useTestClassSubjects(
    TestClassSubjectQueryType.TO_ADD_MARKS,
    AllocationFlag ? AllocationFlag : false
  );

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [
    GetStudentsData,
    { data: StudentsSubjData, loading: StudentsDataLoading, fetchMore },
  ] = useLazyQuery<GetStudentAcdData, AcdStudentsvars>(GetAcdStudents);
  const remarksFlag =
    testConductDetails.data &&
    testConductDetails.data.GetAcdTestClassSubjects.find(
      (res) => res.subject_master_details.id === subjectSelected?.value
    )?.test_class_details.enable_teachers_comments;

  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [subjectMarks, setSubjectMarks] = useState<ISubjectMarks>({});
  const [remarks, setRemarks] = useState<RemarksType>({});

  const drawerClasses = allocateTeachers();
  const isNonAcademic =
    testConductDetails &&
    testConductDetails.data?.GetAcdTestClassSubjects.find(
      (data) => data.subject_master_details.id === subjectSelected?.value
    )?.test_class_details.is_non_academic;

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0,
    subjectId: -89,
  });

  const { flag } = useCheckAllocationType();

  const [AddMarks, { loading }] = useMutation(AddAcdTestMarksForStds, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const onEdit = (id: number, subjectId: number) => {
    setInEditMode({
      status: true,
      rowKey: id,
      subjectId,
    });
  };

  const handleMarksChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    studentId: number,
    subjectId: number
  ) => {
    const newMarks = parseInt(event.target.value);
    const res = testConductDetails.data?.GetAcdTestClassSubjects.find(
      (subject) => subject.subject_master_details.id === subjectId
    );
    if (!res) return;
    const maxMarks = res.subj_total_max_marks;
    if (newMarks > maxMarks) {
      alert("Marks cannot be greater than max marks");
      return;
    } else {
      setSubjectMarks((prevState) => {
        const updatedMarks = prevState[subjectId].map((student) => {
          if (student.node.id === studentId) {
            return {
              ...student,
              node: {
                ...student.node,
                [event.target.name]: newMarks,
              },
            };
          }
          return student;
        });

        return { ...prevState, [subjectId]: updatedMarks };
      });
    }
  };

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const handlemarkUpdate = () => {
    AddMarks({
      variables: {
        token,
        todays_date: toIsoDate(serverDate),
        inst_id: Number(InstId),
        user_details,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
        per_std_subj_allocation: flag,
        input: {
          acd_referance_ids: {
            inst_id: Number(InstId),
            acd_test_class_id: testId,
            entry_level,
            entry_id: entryId,
            test_type_id: 0,
          },
          subjects: Object.entries(subjectMarks).map(
            ([subjectId, students]: [string, StudentEdges[]]) => ({
              subj_master_id: parseInt(subjectId),
              std_marks: students.map(({ node }, index) => ({
                student_id: node.id,
                marks_ext_scored:
                  node.is_present && node.ext_marks ? node.ext_marks : 0,
                is_std_present: node.is_present,
                scored_grade: node.scored_grade,
                teacher_comments: remarks[index],
              })),
            })
          ),
        },
      },
      refetchQueries: [
        {
          query: GetAcdTestClassSubjects,
          variables: {
            token,
            inst_id: Number(InstId)!,
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            acd_test_classes_id: state.testConductId || Number(testId),
            pr_emp_id: state.empLoginId,
            entry_level,
            entry_id: entryId ? Number(entryId) : 0,
            query_type: TestClassSubjectQueryType.TO_ADD_MARKS,
            per_std_subj_allocation: AllocationFlag ? AllocationFlag : false,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        navigate(
          `/${InstId}/academics/dailyactivities/${entryId}/${subjectId}/classdetails`
        );
      }
    });
  };
  const handleClear = () => {
    setSubjectSelected(null);
  };

  const changeGrade = (
    studentId: number,
    subjectId: number,
    value: string,
    gradeIndex: number,
    studentIndex: number
  ) => {
    setSubjectMarks((prevState) => {
      const updatedMarks = prevState[subjectId].map((student) => {
        if (student.node.id === studentId) {
          return {
            ...student,
            node: {
              ...student.node,
              scored_grade: value,
            },
          };
        }
        return student;
      });
      return { ...prevState, [subjectId]: updatedMarks };
    });

    if (gradeDetails && gradeDetails.GetAcdGradeScalesByInstId[gradeIndex]) {
      const selectedGradeRemarks =
        gradeDetails.GetAcdGradeScalesByInstId[gradeIndex].grade_remarks;

      setRemarks((prevRemarks) => ({
        ...prevRemarks,
        [studentIndex]: selectedGradeRemarks || "",
      }));
    }
  };

  const changeAttendance = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    studentId: number,
    subjectId: number
  ) => {
    const att_status = (event.target as HTMLInputElement).checked;

    setSubjectMarks((prevState) => {
      const updatedMarks = prevState[subjectId].map((student) => {
        if (student.node.id === studentId) {
          return {
            ...student,
            node: {
              ...student.node,
              ext_marks: 0,
              int_marks: 0,
              [(event.target as HTMLInputElement).name]: att_status,
            },
          };
        }
        return student;
      });
      return { ...prevState, [subjectId]: updatedMarks };
    });
  };

  useEffect(() => {
    if (
      token &&
      state.ActiveAcdYr &&
      subjectSelected &&
      configData.data &&
      configData.data.GetSwConfigVariables[0].config_string_value &&
      flag !== undefined
    ) {
      GetStudentsData({
        variables: {
          after: null,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          first: null,
          name: EMPTY_STRING,
          token,
          orderBy: [
            {
              direction: Direction.ASC,
              field: SortBy.STD_ROLL_NO,
            },
          ],
          input: {
            ids: [
              Number(InstId)!,
              Number(entryId),
              subjectSelected ? subjectSelected.value : 0,
            ],
            acd_std_query_type: flag
              ? StudentAcdType.PER_STUDENT_SUBJECT_ALLOCATION_AT_ENTRY_LEVEL
              : StudentAcdType.BY_ENTRY_LEVEL_AND_BY_SUBJ_ID,
            str_data: [entry_level],
          },
        },
      });
    }
  }, [
    GetStudentsData,
    token,
    state.ActiveAcdYr,
    subjectSelected,
    configData.data,
    InstId,
    entryId,
    entry_level,
    flag,
  ]);

  useEffect(
    () => {
      if (StudentsSubjData && !StudentsDataLoading && subjectSelected) {
        const newData = StudentsSubjData.GetAcdStudents.edges.map((edge) => {
          return {
            ...edge,
            node: {
              ...edge.node,
              isChecked: true,
              // set default value of isChecked to true
            },
          };
        });

        if (endCursor) {
          // If we have already fetched some data, we need to check if there
          // are any duplicates in the new data, and update their isChecked
          // property based on the existing data.
          // const filteredStudents = students.filter(
          //   (student) => !student.node.isChecked
          // );

          const updatedNewData = newData.map((newStudent) => {
            const filteredStudent = students.find(
              (student) => student.node.id === newStudent.node.id
            );
            if (filteredStudent) {
              return {
                ...newStudent,
                node: {
                  ...newStudent.node,
                  // isChecked: filteredStudent.node.isChecked,
                },
              };
            }
            return newStudent;
          });
          setStudents(updatedNewData);
        } else {
          setStudents(newData);
        }
        setEndCursor(StudentsSubjData.GetAcdStudents.pageInfo.endCursor);
      }
    },
    // eslint-disable-next-line
    [StudentsSubjData, StudentsDataLoading, fetchMore, subjectSelected]
  );
  useEffect(() => {
    if (subjectSelected && students.length) {
      const res: ISubjectMarks = {
        [subjectSelected.value]: students.map((student) => ({
          ...student,
          node: {
            ...student.node,
            is_present: true,
          },
        })),
      };
      setSubjectMarks(res);
    }
  }, [subjectSelected, students]);

  const enterKeyFuncForMarks = (index: number, subjectIndex: number) => {
    const nextIndex = index + 1;
    const foundSubject = subjectSelected ? subjectSelected : null;

    if (students[nextIndex]) {
      const getStudentId = students[nextIndex]?.node.id;
      const getSubjectId =
        foundSubject?.value || subjectSelected?.value || null;
      onEdit(getStudentId, getSubjectId!);
    } else {
      saveRef.current?.focus();
    }
  };

  const [GetAcdGradeScaleDetails, { data: gradeDetails }] = useLazyQuery<
    GetAcdGradeScalesByInstIdData,
    GetAcdGradeScalesByInstIdVars
  >(GetAcdGradeScalesByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
    },
  });

  useEffect(() => {
    if (token && InstId) {
      GetAcdGradeScaleDetails();
    }
  }, [token, InstId, GetAcdGradeScaleDetails]);

  const gradeDropDown = gradeDetails
    ? gradeDetails.GetAcdGradeScalesByInstId.map((list) => list.grade)
    : [];

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Marks Entry</Title>
      <div className="academics__exam--marks-entry">
        <InstDetails />
        <div className="row g-0 academics__exam--marks-entry--select-sub">
          <div className="col-4">
            <Autocomplete
              classes={classes}
              options={testConductDetails.subjectsOptions}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, subjectSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && subjectSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setSubjectSelected(null);
                }
              }}
              openOnFocus
              autoFocus
              value={subjectSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSubjectSelected(newValue);
                } else {
                  setSubjectSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label="Choose Subject"
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        </div>
        <div className="row g-0 academics__exam--marks-entry--subtitle">
          <div className="col-8">
            <Title variant="subtitle1">
              {testConductDetails.data?.GetAcdTestClassSubjects[0]
                ? `${testConductDetails.data?.GetAcdTestClassSubjects[0].test_class_details.test_name_details.test_name} (${testConductDetails.data?.GetAcdTestClassSubjects[0].test_class_details.test_name_details.test_type_details.test_type_name})`
                : ""}
            </Title>
          </div>
          <div className="col-4 academics__exam--marks-entry--date">
            <TextField
              disabled
              type="date"
              label="Test Start Date"
              InputLabelProps={{ shrink: true }}
              className="academics__exam--marks-entry--textfield--date"
              value={
                toInputStandardDate(
                  testConductDetails.data &&
                    testConductDetails.data.GetAcdTestClassSubjects[0]
                    ? testConductDetails.data.GetAcdTestClassSubjects[0]
                        .test_class_details.test_start_date
                    : ""
                ) || ""
              }
            />
            <TextField
              disabled
              type="date"
              label="Test End Date"
              InputLabelProps={{ shrink: true }}
              value={
                toInputStandardDate(
                  testConductDetails.data &&
                    testConductDetails.data.GetAcdTestClassSubjects[0]
                    ? testConductDetails.data.GetAcdTestClassSubjects[0]
                        .test_class_details.test_end_date
                    : ""
                ) || ""
              }
              className="academics__exam--marks-entry--textfield--date"
            />
          </div>
        </div>
        <div className="row g-0 academics__exam--marks-entry--tableblock">
          <TableContainer className="academics__exam--marks-entry--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow className="academics__exam--marks-entry--table--groupheader">
                  {Academics_Table.DailyActivities.Exam.MarksEntry.map(
                    (th: TableHeaderProps, index: React.Key) => (
                      <TableCell key={index} rowSpan={3}>
                        {th.labelName}
                      </TableCell>
                    )
                  )}
                  {isNonAcademic && <TableCell rowSpan={3}>Grade</TableCell>}{" "}
                  {remarksFlag && isNonAcademic && (
                    <TableCell rowSpan={3}>Remarks</TableCell>
                  )}
                  {testConductDetails.data?.GetAcdTestClassSubjects?.filter(
                    (data) =>
                      data.subject_master_details.id === subjectSelected?.value
                  ).map((res, index) => (
                    <TableCell key={index} colSpan={isNonAcademic ? 1 : 2}>
                      {subjectSelected?.label}
                    </TableCell>
                  ))}
                  {remarksFlag && !isNonAcademic && (
                    <TableCell rowSpan={3}>Enter Remarks</TableCell>
                  )}
                </TableRow>

                <TableRow className="academics__exam--marks-entry--table--subheader">
                  {testConductDetails.data?.GetAcdTestClassSubjects.filter(
                    (data) =>
                      data.subject_master_details.id === subjectSelected?.value
                  ).map((subject, index) => {
                    return (
                      <React.Fragment key={index}>
                        {!isNonAcademic &&
                        subject.test_class_details.test_name_details
                          .show_marks_in_grade ? (
                          <>
                            <TableCell>Grade</TableCell>
                          </>
                        ) : (
                          <>{!isNonAcademic && <TableCell>Marks</TableCell>}</>
                        )}

                        <TableCell>Attendance</TableCell>
                      </React.Fragment>
                    );
                  })}
                </TableRow>

                <TableRow className="academics__exam--marks-entry--table--subheader1">
                  {testConductDetails.data &&
                    testConductDetails.data.GetAcdTestClassSubjects.filter(
                      (data) =>
                        data.subject_master_details.id ===
                        subjectSelected?.value
                    ).map((subject, index) => {
                      return (
                        <React.Fragment key={index}>
                          {!isNonAcademic && (
                            <TableCell className="academics__exam--marks-entry--table--grade">
                              {subject.test_class_details.test_name_details
                                .show_marks_in_grade
                                ? EMPTY_STRING
                                : `${subject.subj_total_max_marks}(${subject.subj_total_min_marks})`}
                            </TableCell>
                          )}
                          <TableCell>P: Present A: Absent</TableCell>
                        </React.Fragment>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student, studentIndex) => (
                  <TableRow
                    key={studentIndex}
                    className="academics__exam--marks-entry--table--select-row"
                  >
                    <TableCell
                      className="academics__exam--marks-entry--table--slno"
                      id="td-center"
                    >
                      {studentIndex + 1}
                    </TableCell>

                    <TableCell className="academics__exam--marks-entry--table--regno">
                      {student.node.std_adm_no}
                    </TableCell>
                    <TableCell className="academics__exam--marks-entry--table--regno">
                      {student.node.std_reg_no}
                    </TableCell>
                    <TableCell>{`${student.node.first_name} ${student.node.middle_name} ${student.node.last_name}`}</TableCell>
                    {isNonAcademic && (
                      <>
                        <TableCell>
                          <Autocomplete
                            classes={formClasses}
                            options={gradeDropDown!}
                            value={
                              subjectMarks[
                                subjectSelected ? subjectSelected.value : 0
                              ]?.find((s) => s.node.id === student.node.id)
                                ?.node?.scored_grade ?? null
                            }
                            onChange={(event, newValue) => {
                              if (newValue !== null) {
                                const gradeIndex =
                                  gradeDropDown.indexOf(newValue);
                                changeGrade(
                                  student.node.id,
                                  subjectSelected ? subjectSelected.value : 0,
                                  newValue,
                                  gradeIndex,
                                  studentIndex
                                );
                              } else {
                                changeGrade(
                                  student.node.id,
                                  subjectSelected ? subjectSelected.value : 0,
                                  EMPTY_STRING,
                                  -1,
                                  studentIndex
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                classes={{
                                  root: formTextClasses.formControlRoot,
                                }}
                                fullWidth
                              />
                            )}
                          />
                        </TableCell>
                        {remarksFlag && (
                          <TableCell className="academics__exam--marks-entry--table--textarea">
                            <TextArea
                              rows={4}
                              value={remarks[studentIndex] || ""}
                              onChange={(e) => {
                                setRemarks((prevRemarks) => ({
                                  ...prevRemarks,
                                  [studentIndex]: e.target.value,
                                }));
                              }}
                            />
                          </TableCell>
                        )}
                        {console.log(
                          remarks[studentIndex],
                          remarks,
                          studentIndex,
                          "index"
                        )}
                      </>
                    )}
                    {testConductDetails.data?.GetAcdTestClassSubjects.filter(
                      (data) =>
                        data.subject_master_details.id ===
                        subjectSelected?.value
                    ).map((res, index) => {
                      return res.test_class_details.test_name_details
                        .show_marks_in_grade ? (
                        <>
                          {!isNonAcademic && (
                            <TableCell>
                              <Autocomplete
                                classes={formClasses}
                                options={gradeDropDown!}
                                value={
                                  subjectMarks[
                                    subjectSelected ? subjectSelected.value : 0
                                  ]?.find((s) => s.node.id === student.node.id)
                                    ?.node?.scored_grade ?? null
                                }
                                onChange={(event, newValue) => {
                                  if (newValue !== null) {
                                    const gradeIndex =
                                      gradeDropDown.indexOf(newValue);
                                    changeGrade(
                                      student.node.id,
                                      subjectSelected
                                        ? subjectSelected.value
                                        : 0,
                                      newValue,
                                      gradeIndex,
                                      studentIndex
                                    );
                                  } else {
                                    changeGrade(
                                      student.node.id,
                                      subjectSelected
                                        ? subjectSelected.value
                                        : 0,
                                      EMPTY_STRING,
                                      -1,
                                      studentIndex
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    classes={{
                                      root: formTextClasses.formControlRoot,
                                    }}
                                    fullWidth
                                  />
                                )}
                              />
                            </TableCell>
                          )}

                          <TableCell className="academics__exam--marks-entry--table--switch">
                            <AntSwitch
                              checked={
                                subjectMarks[
                                  subjectSelected ? subjectSelected.value : 0
                                ]?.find((s) => s.node.id === student.node.id)
                                  ?.node?.is_present || false
                                // enteredMarks.get(subjectSelected?.value)
                                //   ? enteredMarks
                                //       .get(subjectSelected?.value)
                                //       ?.get(student.node.id)?.is_present
                                //   : false
                              }
                              onClick={(e) =>
                                changeAttendance(
                                  e,
                                  student.node.id,
                                  subjectSelected ? subjectSelected.value : 0
                                )
                              }
                              name="is_present"
                              // disabled={allSetToProceed}
                            />
                            {subjectMarks[
                              subjectSelected ? subjectSelected.value : 0
                            ]?.find((s) => s.node.id === student.node.id)?.node
                              ?.is_present
                              ? "P"
                              : "A"}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <React.Fragment key={index}>
                            {!isNonAcademic && (
                              <TableCell
                                className="academics__exam--marks-entry--table--input"
                                id="td-center"
                                onClick={() => {
                                  if (
                                    subjectMarks[
                                      subjectSelected
                                        ? subjectSelected.value
                                        : 0
                                    ]?.find(
                                      (s) => s.node.id === student.node.id
                                    )?.node.is_present
                                  ) {
                                    onEdit(
                                      student.node.id,
                                      subjectSelected
                                        ? subjectSelected.value
                                        : 0
                                    );
                                  }
                                }}
                              >
                                {inEditMode.status &&
                                inEditMode.rowKey === student.node.id &&
                                subjectSelected &&
                                inEditMode.subjectId ===
                                  subjectSelected.value ? (
                                  <Input
                                    autoFocus
                                    type="number"
                                    name="ext_marks"
                                    value={
                                      subjectMarks[
                                        subjectSelected?.value
                                      ]?.find(
                                        (s) => s.node.id === student.node.id
                                      )?.node.ext_marks || 0
                                    }
                                    onBlur={() => {
                                      setInEditMode({
                                        status: false,
                                        rowKey: 0,
                                        subjectId: -89,
                                      });
                                    }}
                                    onChange={(event) =>
                                      handleMarksChange(
                                        event,
                                        student.node.id,
                                        subjectSelected?.value
                                      )
                                    }
                                    onKeyDown={(event) => {
                                      if (event.key === Keys.ENTER) {
                                        enterKeyFuncForMarks(
                                          studentIndex,
                                          index
                                        );
                                      }
                                    }}
                                  />
                                ) : subjectMarks[
                                    subjectSelected ? subjectSelected.value : 0
                                  ]?.find((s) => s.node.id === student.node.id)
                                    ?.node.is_present ? (
                                  subjectMarks[
                                    subjectSelected ? subjectSelected.value : 0
                                  ]?.find((s) => s.node.id === student.node.id)
                                    ?.node.ext_marks
                                ) : (
                                  "Absent"
                                )}
                              </TableCell>
                            )}
                            <TableCell className="academics__exam--marks-entry--table--switch">
                              <AntSwitch
                                checked={
                                  subjectMarks[
                                    subjectSelected ? subjectSelected.value : 0
                                  ]?.find((s) => s.node.id === student.node.id)
                                    ?.node?.is_present || false
                                  // enteredMarks.get(subjectSelected?.value)
                                  //   ? enteredMarks
                                  //       .get(subjectSelected?.value)
                                  //       ?.get(student.node.id)?.is_present
                                  //   : false
                                }
                                onClick={(e) =>
                                  changeAttendance(
                                    e,
                                    student.node.id,
                                    subjectSelected ? subjectSelected.value : 0
                                  )
                                }
                                name="is_present"
                                // disabled={allSetToProceed}
                              />
                              {subjectMarks[
                                subjectSelected ? subjectSelected.value : 0
                              ]?.find((s) => s.node.id === student.node.id)
                                ?.node?.is_present
                                ? "P"
                                : "A"}
                            </TableCell>
                            {remarksFlag && !isNonAcademic && (
                              <TableCell className="academics__exam--marks-entry--table--textarea">
                                <TextArea
                                  rows={4}
                                  value={remarks[studentIndex] || ""}
                                  onChange={(e) => {
                                    const updatedRemarks = { ...remarks };
                                    updatedRemarks[studentIndex] =
                                      e.target.value;
                                    setRemarks(updatedRemarks);
                                  }}
                                />
                                <img
                                  src={Enlarge}
                                  alt=""
                                  onClick={() => setRemarksModal(!remarksModal)}
                                />
                              </TableCell>
                            )}
                          </React.Fragment>
                        </>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="save" onClick={handlemarkUpdate} buttonref={saveRef!} />
        <Button
          mode="excel"
          type="button"
          onClick={() => setImportModal(!importModal)}
        >
          Import Marks
        </Button>
        <Button mode="clear" onClick={handleClear} />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <LoadingModal flag={loading || StudentsDataLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={remarksModal}
        onClose={() => setRemarksModal(!remarksModal)}
      >
        <Remarks setModal={setRemarksModal} />
      </Drawer>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MarksImport setModalFlag={setImportModal} />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setImportModal(!importModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EnterMarks;
