import React, { useContext, useEffect, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { TextField } from "@mui/material";
import Compare from "../../../../images/Compare.svg";
import More from "../../../../images/More.svg";
import EnquiredStudents from "../../../../images/EnquiredStudents.svg";
import AdmittedStudents from "../../../../images/AdmittedStudents.svg";
import ToFollowUp from "../../../../images/ToFollowUp.svg";
import CalledToday from "../../../../images/CalledToday.svg";
import Conversion from "../../../../images/Conversion.svg";
import Interested from "../../../../images/Interested.svg";
import NotInterested from "../../../../images/NotInterested.svg";
import NotDecided from "../../../../images/NotDecided.svg";
import TodayToFollowUp from "../../../../images/TodaytoFollowUp.svg";
import QRCode from "qrcode.react";
import Table from "./Table";
import { useLazyQuery } from "@apollo/client";
import { GetStudentEnquiryDashboardDetailsData } from "../Details";
import { DashboardDetailsEmpVars } from "../Index";
import { GetStudentEnquiryDashboardDetails } from "../../../../queries/students/list/newApi";
import {
  sixMonthsAgo,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { EMPTY_STRING } from "../../../../utils/constants";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { AppContext } from "../../../../context/context";
import useStudentEnquiries from "../../hooks/useStudentEnquires";
import { SortBy, StdEnqQueryType } from "../../../../utils/Enum.types";

const EnquiryTeacherDashboard = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { serverDate } = useServerDateandTime();
  const [startDate, setStartDate] = useState(serverDate);
  const [endDate, setEndDate] = useState(serverDate);
  const { state } = useContext(AppContext);

  const [GetEnqStudentData, { data }] = useLazyQuery<
    GetStudentEnquiryDashboardDetailsData,
    DashboardDetailsEmpVars
  >(GetStudentEnquiryDashboardDetails, {
    variables: {
      token,
      inst_id: InstId!,
      pr_emp_id: state.empLoginId,
      today_date: toIsoDate(serverDate),
      enq_start_date: startDate ? toIsoDate(startDate) : EMPTY_STRING,
      enq_end_date: endDate ? toIsoDate(endDate) : EMPTY_STRING,
    },
    fetchPolicy: "network-only",
  });
  const {
    EnquiryData: { StudentEnquiryData },
  } = useStudentEnquiries(
    null,
    StdEnqQueryType.STD_ENQ_BY_EMP_ID,
    EMPTY_STRING,
    EMPTY_STRING,
    null,
    null,
    SortBy.ENQ_DATE,
    endDate,
    startDate,
    0,
    0,
    state.empLoginId ? state.empLoginId : 0
  );
  useEffect(() => {
    if (state.ActiveFinYr && serverDate !== EMPTY_STRING && state.empLoginId) {
      GetEnqStudentData();
    }
  }, [
    state.ActiveFinYr,
    GetEnqStudentData,
    serverDate,
    token,
    state.empLoginId,
  ]);
  useEffect(() => {
    if (serverDate) {
      setEndDate(serverDate);
      setStartDate(sixMonthsAgo(serverDate));
    }
  }, [serverDate]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Enquiry Teacher</Title>
      <div className="enquiry-teacher">
        <div className="enquiry-teacher__filters">
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Start Date"
            className="enquiry-teacher__textfield"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <img src={Compare} alt="" />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="End Date"
            className="enquiry-teacher__textfield"
            type="date"
            value={toInputStandardDate(endDate)}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="enquiry-teacher__cards">
          <div className="enquiry-teacher__card">
            <div className="enquiry-teacher__card--title">
              <Title variant="subtitle1">Enquired Students</Title>
              <img src={More} alt="" />
            </div>
            <ul>
              <li>
                <div>
                  <img src={EnquiredStudents} alt="" />

                  <span>Total Students Assigned</span>
                </div>
                <b className="font-purple">
                  {StudentEnquiryData.data &&
                    StudentEnquiryData.data.GetStudentEnquires.edges.length}
                </b>
              </li>
              <li>
                <div>
                  <img src={AdmittedStudents} alt="" />

                  <span>No of Admitted</span>
                </div>
                <b className="font-pink">
                  {data &&
                  data.GetStudentEnquiryDashboardDetails.admitted_student_count
                    ? data.GetStudentEnquiryDashboardDetails
                        .admitted_student_count
                    : null}
                </b>
              </li>
              <li>
                <div>
                  <img src={Conversion} alt="" />

                  <span>Conversion Percentage</span>
                </div>
                <b className="font-blue">5</b>
              </li>
            </ul>
          </div>
          <div className="enquiry-teacher__card">
            <div className="enquiry-teacher__card--title">
              <Title variant="subtitle1">FollowUp Status</Title>
              <img src={More} alt="" />
            </div>

            <li>
              <div>
                <img src={Interested} alt="" />
                <span>Interested</span>
              </div>
              <b className="font-green">
                {data &&
                  data.GetStudentEnquiryDashboardDetails.interested_count}
              </b>
            </li>
            <li>
              <div>
                <img src={NotInterested} alt="" />
                <span>Not Interested</span>
              </div>
              <b className="font-red">
                {data &&
                  data.GetStudentEnquiryDashboardDetails.not_interested_count}
              </b>
            </li>
            <li>
              <div>
                <img src={NotDecided} alt="" />
                <span>Not Decided</span>
              </div>
              <b className="font-amber">
                {data &&
                  data.GetStudentEnquiryDashboardDetails.not_decided_count}
              </b>
            </li>
          </div>
          <div className="enquiry-teacher__card">
            <div className="enquiry-teacher__card--title">
              <Title variant="subtitle1">FollowUp Details</Title>
              <img src={More} alt="" />
            </div>

            <li>
              <div>
                <img src={ToFollowUp} alt="" />
                <span>To FollowUp</span>
              </div>

              <b className="font-red">
                {data &&
                  data.GetStudentEnquiryDashboardDetails.to_follow_up_count}
              </b>
            </li>
            <li>
              <div>
                <img src={TodayToFollowUp} alt="" />
                <span>Today's FollowUp</span>
              </div>

              <b className="font-grey">
                {data &&
                  data.GetStudentEnquiryDashboardDetails.today_follow_up_count}
              </b>
            </li>
            <li>
              <div>
                <img src={CalledToday} alt="" />
                <span>Called Today</span>
              </div>

              <b className="font-blue">
                {data &&
                  data.GetStudentEnquiryDashboardDetails.called_today_count}
              </b>
            </li>
          </div>
          <div className="enquiry-teacher__card">
            <QRCode
              value={`https://dev.myeduate.com/${InstId}/studentenquiry/basicdetails`}
              className="enquiry-teacher__card--url"
            />
            <b className="enquiry-teacher__card--text">Scan to fill Details</b>
          </div>
        </div>
        <div className="enquiry-teacher__tableblock">
          <Table />
        </div>
      </div>
    </>
  );
};

export default EnquiryTeacherDashboard;
