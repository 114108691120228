import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableHeaderProps } from "../../../Types/Tables";
import Institution from "../../../images/TotalInstitutions.svg";
import Input from "../../../stories/Input/Input";
import { Label } from "../../../stories/Label/Label";
import { instTotalStats } from "../../Accounts/Dashboard/types";
import { formatter } from "../../../utils/UtilFunctions";
import { useNavigate } from "react-router-dom";
import { AccountsDashboardStyles } from "../../../styles/StickyTableStyles";
import useAcctTableJson from "../../Accounts/json/useAcctTableJson";

interface Props {
  instStats: instTotalStats[];
}
const Accounts = ({ instStats }: Props) => {
  const tableClasses = AccountsDashboardStyles();
  const { format } = formatter;
  const navigate = useNavigate();
  const { Accounts_Table } = useAcctTableJson();
  const {
    totalDemandAmount,
    totalReceived,
    totalbalance,
    totalBankBalance,
    totalCashBalance,
  } = instStats
    .filter((d) => d !== null)
    .reduce(
      (acc, item) => {
        acc.totalDemandAmount += item.total_demand;
        acc.totalbalance += item.total_demand_balance;
        acc.totalReceived += item.total_received!;

        acc.totalBankBalance += item.bank_balance;
        acc.totalCashBalance += item.cash_balance;
        return acc;
      },
      {
        totalDemandAmount: 0,
        totalReceived: 0,
        totalbalance: 0,

        totalBankBalance: 0,
        totalCashBalance: 0,
      }
    );

  return (
    <>
      <div className="proprietor-dashboard">
        <div className="row g-0 proprietor-dashboard__row">
          <div className="col-2">
            <Input id="search" placeholder="Search" />
          </div>
          <div className="col"></div>
          <div className="col-3 proprietor-dashboard__row--flex">
            <div className="proprietor-dashboard__row--total">
              <img src={Institution} alt="/" />
              <Label>Total Institution</Label>
              <Label variant="LabelBold">
                {instStats.filter((d) => d !== null).length}
              </Label>
            </div>
          </div>
        </div>
        <div className="proprietor-dashboard__tableblock">
          <TableContainer className={tableClasses.table}>
            <Table>
              <TableHead>
                <TableRow>
                  {Accounts_Table.ProprietorDashboard.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell
                          key={index}
                          className={
                            th.labelName === "Sl"
                              ? tableClasses.stickyHeaderSl
                              : th.labelName === "Institution Name"
                              ? tableClasses.stickyHeaderName
                              : tableClasses.stickyHeader
                          }
                          align="center"
                        >
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {instStats
                  .filter((d) => d !== null)
                  .map((inst, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className={tableClasses.stickyColumnSl}
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          className={`proprietor-dashboard__table--name ${tableClasses.stickyColumnName}`}
                          onClick={() => navigate(`/${inst.inst_id}/accounts`)}
                        >
                          <img
                            src={Institution}
                            alt="/"
                            className="proprietor-dashboard__inst-image"
                          />
                          {inst.inst_name}
                        </TableCell>

                        <TableCell
                          className={tableClasses.stickyColumn}
                          id="td-right"
                        >
                          {inst.bank_balance < 0
                            ? `(${format(Math.abs(inst.bank_balance))})`
                            : format(inst.bank_balance)}
                        </TableCell>
                        <TableCell
                          className={tableClasses.stickyColumn}
                          id="td-right"
                        >
                          {inst.cash_balance < 0
                            ? `(${format(Math.abs(inst.cash_balance))})`
                            : format(inst.cash_balance)}
                        </TableCell>

                        <TableCell
                          className={`${tableClasses.stickyColumn} font-blue`}
                          id="td-right"
                        >
                          {format(inst.total_demand)}
                        </TableCell>
                        <TableCell
                          className={`${tableClasses.stickyColumn} font-green`}
                          id="td-right"
                        >
                          {format(inst.total_received)}
                        </TableCell>
                        <TableCell
                          className={`${tableClasses.stickyColumn} font-red`}
                          id="td-right"
                        >
                          {format(inst.total_demand_balance)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    className={tableClasses.stickyColumnFooterTotal}
                    id="td-right"
                  >
                    Total:
                  </TableCell>

                  <TableCell
                    className={tableClasses.stickyColumnFooter}
                    id="td-right"
                  >
                    {totalBankBalance < 0
                      ? `(${format(Math.abs(totalBankBalance))})`
                      : format(totalBankBalance)}
                  </TableCell>
                  <TableCell
                    className={tableClasses.stickyColumnFooter}
                    id="td-right"
                  >
                    {totalCashBalance < 0
                      ? `(${format(Math.abs(totalCashBalance))})`
                      : format(totalCashBalance)}
                  </TableCell>

                  <TableCell
                    className={tableClasses.stickyColumnFooterDemand}
                    id="td-right"
                  >
                    {format(totalDemandAmount)}
                  </TableCell>
                  <TableCell
                    className={tableClasses.stickyColumnFooter}
                    id="td-right"
                  >
                    {format(totalReceived)}
                  </TableCell>
                  <TableCell
                    className={tableClasses.stickyColumnFooterBalance}
                    id="td-right"
                  >
                    {format(totalbalance)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default Accounts;
