import React, { useContext, useEffect, useRef, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { TextField, Tooltip } from "@mui/material";
import Review, { PageFor } from "./Review";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import {
  HolidayType,
  Operation,
  PrEmpDailyDiaryQueryType,
} from "../../../utils/Enum.types";
import CalendarLegends, { CalendarFor } from "../../../pages/CalendarLegends";
import { useNavigate, useParams } from "react-router-dom";
import Home from "../Home/Index";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import Previous from "../../../images/CalenderPreviousButton.svg";
import Next from "../../../images/CalenderNextButton.svg";
import Modal from "react-modal";
import {
  DateRange,
  GetnumberOfDaysInMonth,
  MonthName,
  handleDate,
  toInputStandardDate,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import { INCREMENT_DECREMENT } from "../../Enquiry/Dashboard/Index";
import { msgType } from "../../../utils/Form.types";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import dayjs from "dayjs";
import {
  GetPayRollEmpDailyDiary,
  GetPayRollEmpDailyDiaryStatus,
} from "./queries";
import { useLazyQuery } from "@apollo/client";
import { DEFAULT_TIME, days } from "../../../utils/constants";
import { monthDaysType } from "../DailyActivities/Attendance/MonthlyOverview";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../hooks/useAcdGeneralHolidays";
import useToken from "../../../customhooks/useToken";
import usePayRollActiveAcademicYear from "../../HR/hooks/usePayRollActiveAcademicYear";
import { AppContext } from "../../../context/context";
import {
  GetPayRollEmpDailyDiaryData,
  GetPayRollEmpDailyDiaryVars,
} from "./Index";
import MessageModal from "../../../pages/MessageModal";
import LoadingModal from "../../../pages/LoadingModal";
export interface GetPayRollEmpDailyDiaryStatusList {
  id: number;
  day_1: boolean;
  day_2: boolean;
  day_3: boolean;
  day_4: boolean;
  day_5: boolean;
  day_6: boolean;
  day_7: boolean;
  day_8: boolean;
  day_9: boolean;
  day_10: boolean;
  day_11: boolean;
  day_12: boolean;
  day_13: boolean;
  day_14: boolean;
  day_15: boolean;
  day_16: boolean;
  day_17: boolean;
  day_18: boolean;
  day_19: boolean;
  day_20: boolean;
  day_21: boolean;
  day_22: boolean;
  day_23: boolean;
  day_24: boolean;
  day_25: boolean;
  day_26: boolean;
  day_27: boolean;
  day_28: boolean;
  day_29: boolean;
  day_30: boolean;
  day_31: boolean;
  pr_emp_id: number;
  pr_acd_yr_id: number;
  inst_id: number;
  pr_cal_month_id: number;
}
export interface GetPayRollEmpDailyDiaryStatusData {
  GetPayRollEmpDailyDiaryStatus: GetPayRollEmpDailyDiaryStatusList;
}
export interface GetPayRollEmpDailyDiaryStatusVars {
  token: string;
  input: {
    cal_month: string;
    inst_id: number;
    pr_acd_yr_id: number;
    pr_emp_id: number;
  };
}
const ViewDetailed = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const [update, setUpdate] = useState(false);
  const { serverDate } = useServerDateandTime();
  const [errorAppearedFromApi, setErrorAppearedFromApi] = useState("");
  const [monthDays, setMonthDays] = useState<monthDaysType[]>();
  var generalHolidaysMap = useRef(new Map());
  const [date, setDate] = useState(new Date());

  const { activeAcademicYearData } = useActiveAcademicYear();

  const { firstDay, lastDay } = DateRange(date.toString()) || {};

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [GetStudentHolidayDetails, { data, loading }] = useLazyQuery<
    GetPayRollEmpDailyDiaryStatusData,
    GetPayRollEmpDailyDiaryStatusVars
  >(GetPayRollEmpDailyDiaryStatus, {
    onError: (e) => {
      setErrorAppearedFromApi(e.message);
    },
  });

  const [GetDairyData, { data: DairyData, loading: DairyLoading }] =
    useLazyQuery<GetPayRollEmpDailyDiaryData, GetPayRollEmpDailyDiaryVars>(
      GetPayRollEmpDailyDiary
    );
  const { payRollActiveAcademicYear } = usePayRollActiveAcademicYear();
  const pr_acd_yr_id = payRollActiveAcademicYear.data
    ? payRollActiveAcademicYear.data.GetPayRollAcdYrActiveByInstId.id
    : 0;
  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
    lastDay!,
    firstDay!,
    "",
    holiday_query_type.ALL_HOLIDAYS
  );

  const chooseTypeAndColor = (status: boolean, day: number) => {
    const today = new Date().getDate();

    if (generalHolidaysMap.current.has(day)) {
      const holiday_details = generalHolidaysMap.current.get(day)!;
      switch (holiday_details.holiday_type) {
        case HolidayType.GENERAL || HolidayType.UNEXPECTED:
          return {
            type: holiday_details.holiday_type,
            description: holiday_details.holiday_description,
            class_name: "border-left-for-holidays",
          };
        case HolidayType.WEEKEND || HolidayType.VACATION:
          return {
            type: holiday_details.holiday_type,
            description: holiday_details.holiday_description,
            class_name: "weekend-dates",
          };
        case HolidayType.VACATION:
          return {
            type: holiday_details.holiday_type,
            description: holiday_details.holiday_description,
            class_name: "weekend-dates",
          };
        default:
          break;
      }
    } else {
      if (status === true) {
        return {
          type: true,
          description: "",
          class_name: "cal-bg-green",
        };
      } else if (status === false && day > today) {
        return {
          type: "",
          description: "",
          class_name: "",
        };
      } else if (status === false) {
        return {
          type: false,
          description: "",
          class_name: "cal-bg-red",
        };
      } else {
        return {
          type: "",
          description: "",
          class_name: "",
        };
      }
    }
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const emptyDaysByWeek = (week: string) => {
    let numberOfEmpty = -1;
    let numberOfEmptyToLast = -1;
    switch (week) {
      case "Sun":
        numberOfEmpty = 6;
        numberOfEmptyToLast = 0;
        break;
      case "Mon":
        numberOfEmpty = 0;
        numberOfEmptyToLast = 6;
        break;
      case "Tue":
        numberOfEmpty = 1;
        numberOfEmptyToLast = 5;

        break;
      case "Wed":
        numberOfEmpty = 2;
        numberOfEmptyToLast = 4;
        break;
      case "Thu":
        numberOfEmpty = 3;
        numberOfEmptyToLast = 3;
        break;
      case "Fri":
        numberOfEmpty = 4;
        numberOfEmptyToLast = 2;
        break;
      case "Sat":
        numberOfEmpty = 5;
        numberOfEmptyToLast = 1;
        break;
      default:
        break;
    }
    return { numberOfEmpty, numberOfEmptyToLast };
  };

  const weekCount = () => {
    if (monthDays && monthDays.length > 0) {
      const firstWeek = monthDays[0].week;

      const lastWeek = monthDays[monthDays.length - 1]?.week;

      const startArray = Array.from({
        length: emptyDaysByWeek(firstWeek).numberOfEmpty,
      })
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
        }));

      const endArray = new Array(emptyDaysByWeek(lastWeek!).numberOfEmptyToLast)
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
        }));

      return [...startArray, ...monthDays, ...endArray];
    } else {
      return [];
    }
  };
  const month = new Date(date).getMonth();
  const year = new Date(date).getFullYear();
  useEffect(() => {
    if (
      firstDay &&
      payRollActiveAcademicYear.data &&
      !payRollActiveAcademicYear.loading
    ) {
      generalHolidaysMap.current = new Map(
        InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.map(
          ({ node }) => {
            return [
              new Date(node.holiday_date).getDate(),
              {
                holiday_type: node.holiday_type,
                holiday_description: node.holiday_desc,
              },
            ];
          }
        )
      );
      GetStudentHolidayDetails({
        variables: {
          token,
          input: {
            cal_month: toIsoDate(firstDay!),
            inst_id: Number(InstId)!,
            pr_acd_yr_id,
            pr_emp_id: state.empLoginId,
          },
        },
      }).then(({ data }) => {
        if (data) {
          const arrLength = GetnumberOfDaysInMonth(firstDay);
          const res = Array.from({ length: arrLength }, (_, i) => {
            const dayNum = i + 1;
            const dayKey = `day_${dayNum}`;
            const date = new Date(year, month, dayNum);
            const holiday_details = chooseTypeAndColor(
              (
                data.GetPayRollEmpDailyDiaryStatus as unknown as Record<
                  string,
                  boolean
                >
              )[dayKey],
              dayNum
            );

            return {
              day: dayNum,
              status: holiday_details?.type,
              description: holiday_details?.description,
              week: date.toLocaleString("en-US", { weekday: "short" }),
              class_name: holiday_details?.class_name!,
              date,
              holiday_types: [],
              events_length: 0,
            };
          });
          setMonthDays(res);
          setErrorAppearedFromApi("");
        }
      });
    }
    // eslint-disable-next-line
  }, [
    state.studentId,
    firstDay,
    state.InstId,
    payRollActiveAcademicYear.data,
    payRollActiveAcademicYear.loading,
    GetStudentHolidayDetails,
    InstGeneralHolidays.data,
    data,
    date,
    state.ActiveAcdYr,
  ]);
  useEffect(() => {
    if (token && pr_acd_yr_id && date) {
      GetDairyData({
        variables: {
          token,
          inst_id: Number(InstId)!,
          pr_acd_yr_id,
          pr_emp_id: state.empLoginId,
          input: {
            query_type: PrEmpDailyDiaryQueryType.DIARY_NOTES,
            date_of_diary: toIsoDate(date.toString()),
            question_idx: 0,
          },
        },
      });
    } // eslint-disable-next-line
  }, [token, GetDairyData, pr_acd_yr_id, date]);
  useEffect(() => {
    if (serverDate) {
      setDate(new Date(serverDate));
    }
  }, [serverDate]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Daily Diary</Title>
      <div className="daily-diary-view">
        <div className="daily-diary-view__frame row g-0">
          <div className="daily-diary-view__frame--left col">
            <div className="daily-diary-view__frame--left--calendar">
              <div className="daily-diary-view__frame--left--calendar--flex">
                <img
                  src={Previous}
                  alt="/"
                  onClick={() =>
                    handleDate(
                      date,
                      setDate,
                      payRollActiveAcademicYear.data
                        ?.GetPayRollAcdYrActiveByInstId.payroll_st_date!,
                      payRollActiveAcademicYear.data
                        ?.GetPayRollAcdYrActiveByInstId!.payroll_end_date!,
                      setMessage,
                      INCREMENT_DECREMENT.DECREMENT
                    )
                  }
                />
                <Title variant="subtitle1">{MonthName(date.toString())}</Title>
                <img
                  src={Next}
                  alt="/"
                  onClick={() => {
                    const nextDate = new Date(
                      date.getFullYear(),
                      date.getMonth() + 1,
                      date.getDate()
                    );
                    const serverDateDayjs = dayjs(serverDate);
                    const selectedDateDayjs = dayjs(nextDate);

                    if (
                      serverDateDayjs.isAfter(selectedDateDayjs) &&
                      !serverDateDayjs.isSame(selectedDateDayjs)
                    ) {
                      handleDate(
                        date,
                        setDate,
                        payRollActiveAcademicYear.data
                          ?.GetPayRollAcdYrActiveByInstId.payroll_st_date!,
                        payRollActiveAcademicYear.data
                          ?.GetPayRollAcdYrActiveByInstId!.payroll_end_date!,
                        setMessage,
                        INCREMENT_DECREMENT.INCREMENT
                      );
                    } else {
                      setMessage({
                        flag: true,
                        operation: Operation.NONE,
                        message: "Cannot Access Future Dates",
                      });
                    }
                  }}
                />
              </div>

              {errorAppearedFromApi ? (
                <div className="flex_error">
                  <b className="nodata">{errorAppearedFromApi}</b>
                </div>
              ) : (
                <>
                  <ul className="student-dashboard__attendance--grid-container">
                    {days.map((day, index: React.Key) => {
                      return (
                        <li
                          key={index}
                          className="enquiry-dashboard__data--calendar--grid-container--day"
                        >
                          {day}
                        </li>
                      );
                    })}
                    {weekCount().map(
                      (
                        {
                          day,
                          class_name,
                          description,
                          date: monthDate,
                          status,
                        },
                        index
                      ) => {
                        return day > 0 ? (
                          status === HolidayType.GENERAL ||
                          status === HolidayType.UNEXPECTED ||
                          status === HolidayType.VACATION ? (
                            <Tooltip
                              title={<>{description}</>}
                              arrow
                              placement="right"
                              key={index}
                            >
                              <li
                                className={`student-dashboard__attendance--grid-container--grid-item ${class_name}`}
                                key={index}
                              >
                                <span>{day > 0 ? day : ""}</span>
                              </li>
                            </Tooltip>
                          ) : (
                            <li
                              className={`student-dashboard__attendance--grid-container--grid-item ${class_name}`}
                              onClick={() => {
                                dayjs(monthDate!).isAfter(serverDate)
                                  ? setMessage({
                                      flag: true,
                                      message: "Cannot Access Future Dates",
                                      operation: Operation.NONE,
                                    })
                                  : setDate(monthDate!);
                              }}
                              key={index}
                            >
                              <span>{day > 0 ? day : ""}</span>
                            </li>
                          )
                        ) : (
                          <li key={index}></li>
                        );
                      }
                    )}
                  </ul>
                </>
              )}
            </div>

            <CalendarLegends useCalendarIn={CalendarFor.DAILY_DIARY} />
          </div>
          <div className="daily-diary-view__frame--right col">
            <div className="row g-0 justify-content-center">
              <div className="col-4">
                <TextField
                  className="daily-diary-view__frame--right--textfield"
                  label="Date"
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={toInputStandardDate(date.toString())}
                  type="date"
                />
              </div>
            </div>
            <div className="daily-diary-view__frame--right--block">
              <ul className="daily-diary-view__frame--right--block--ul">
                {DairyData &&
                  DairyData.GetPayRollEmpDailyDiary.map((res, index) => {
                    return (
                      <li key={index}>
                        <b>{res.question}</b>
                        <span>{res.answer}</span>
                      </li>
                    );
                  })}
              </ul>
            </div>
            {/* <Button mode="update" onClick={() => setUpdate(!update)} /> */}
          </div>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal isOpen={update} style={StudentModalStyles} ariaHideApp={false}>
        <Review
          setModal={setUpdate}
          setFillDiary={setUpdate}
          pageType={PageFor.UPDATE}
        />
      </Modal>
      <LoadingModal flag={loading || DairyLoading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default ViewDetailed;
