import { Autocomplete, Checkbox, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../stories/Button/Button";
import { Title } from "../../../stories/Title/Title";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import {
  ColumnVisibilityFor,
  Direction,
  Operation,
  PredefinedDataTypes,
  SortBy,
  StudentReportType,
  TableHeaders,
} from "../../../utils/Enum.types";
import Home from "../Home/Index";
import Input from "../../../stories/Input/Input";
import { msgType, optionsType, responseType } from "../../../utils/Form.types";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../utils/UtilFunctions";
import { Keys } from "../../../utils/Enum.keys";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import { useMutation } from "@apollo/client";
import { DeleteStudents } from "../../../queries/students/mutations/update";
import useToken from "../../../customhooks/useToken";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import { GetStudents } from "../../../queries/students/list/newApi";
import { studentQueryTypes } from "../../../utils/studentqueryEnum.types";
import DeleteModal from "../../../pages/DeleteModal";
import StudentTotalCount from "./Components/StudentTotalCount";
import { FetchStudentStatusClassName } from "./List";
import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../utils/types";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../styles/DataGridTableStyles";
import useStudentTableJson from "../../../json/useStudentTableJson";
import useAcdDropDownData from "../../Academics/hooks/useAcdDropDownData";
import useAcdStudentsForAdmission, {
  StudentEdges,
} from "../../Academics/hooks/useAcdStudentsForAdmission";

const StudentReservedList = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { InstId } = useParams();
  const { token } = useToken();
  const [searchData, setSearchData] = useState("");
  const [studentDeleteModal, setStudentDeleteModal] = useState(false);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const dataClasses = useDataGridStyles();

  // eslint-disable-next-line
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const {
    PredefinedData: { dropDown: STUDENT_STATUS },
  } = usePredefinedDataByType(PredefinedDataTypes.STUDENT_STATUS, EMPTY_STRING);
  const [statusSelected, setStatusSelected] = useState<optionsType | null>(
    null
  );
  const { Student } = useStudentTableJson({
    jsonFor: ColumnVisibilityFor.FOR_DELETE,
  });
  const { user_details } = useLoggedInUserDetails();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();
  const [DeleteMasterStudents, { loading: deleteLoading }] = useMutation(
    DeleteStudents,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    0,
    categorySelected ? categorySelected.value : 0,
    ROWS_PER_PAGE,
    0,
    searchData,
    StudentReportType.ACD_STD_GENERAL,
    statusSelected ? statusSelected.value : EMPTY_STRING,
    []
  );

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const { categoryDropDown } = useInstMasterDataByInstId();
  const handleCheckboxChange = (id: number) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSubmit = () => {
    DeleteMasterStudents({
      variables: {
        token,
        id: selectedRows,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetStudents,
          variables: {
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            input: {
              ids: [Number(InstId)],
              std_query_type: studentQueryTypes.BY_INST_ID,
              str_data: [],
            },
            name: EMPTY_STRING,
            orderBy: [
              {
                direction: Direction.ASC,
                field: SortBy.FIRST_NAME,
              },
            ],
            status: EMPTY_STRING,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Students Deleted Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (message.operation !== Operation.NONE && message.flag) {
      setStudentDeleteModal(!studentDeleteModal);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const { branchLabel, classLabel, departmentLabel, semesterLabel,categoryLabel } =
    useInstLabels();

  const dynamicHeaders: TableHeaderProps[] = Student.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );

  const columns: GridColDef[] = [
    {
      field: "actions",
      cellClassName: "td-actions-print ",
      headerClassName: "td-actions-print-header",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      headerName: TableHeaders.ACTION,
      renderCell: (params) => {
        const studentId = params.row.std_id || "";

        return (
          <>
            <Checkbox
              name={`select_${params.row.id}`}
              className="select-all--checkbox"
              checked={selectedRows.includes(studentId)}
              onChange={() => handleCheckboxChange(studentId)}
            />
          </>
        );
      },
    },
    ...dynamicHeaders,
    {
      field: "status",
      headerName: TableHeaders.STATUS,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-status",
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            className={`td-status__cell ${FetchStudentStatusClassName(
              params.row.status
            )}`}
          >
            {params.row.status}
          </div>
        );
      },
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_type: false,
      std_mother_name: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,
      std_email: false,
      std_mobile: false,
      is_login_created: false,
      std_roll_no: false,
      dept: USE_DEPARTMENT_KEY,
      branch: USE_BRANCH_KEY,
      class: USE_CLASS_KEY,
      sem: USE_SEMESTER_KEY,
      sec: USE_SECTION_KEY,
    });
  useEffect(() => {
    if (AcademicsStudentData.data && !AcademicsStudentData.loading) {
      const newData = AcademicsStudentData.data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name: node.first_name,
            std_father_name: node.std_father_name,
            std_mobile: node.std_mobile,
            status: node.std_status,
            std_id: node.id,
            std_mother_name: node.std_mother_name,
            std_sex: node.std_sex,

            std_email: node.std_email,
            is_login_created: "",
            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            sem: node.acd_semester.sem_desc,
            sec: node.acd_section.section_desc,
            category: node.category.cat_desc,

            std_type: node.std_fresher ? "Fresher" : "Old Student",
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name: node.first_name,
            std_father_name: node.std_father_name,
            std_mobile: node.std_mobile,
            status: node.std_status,
            std_id: node.id,
            std_mother_name: node.std_mother_name,
            std_sex: node.std_sex,

            std_email: node.std_email,
            is_login_created: "",
            dept: node.acd_dept.dept_desc,
            branch: node.acd_branch.branch_desc,
            class: node.acd_class.class_desc,
            sem: node.acd_semester.sem_desc,
            sec: node.acd_section.section_desc,
            category: node.category.cat_desc,

            std_type: node.std_fresher ? "Fresher" : "Old Student",
          }))
        );
      }
      setEndCursor(AcademicsStudentData.data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [
    AcademicsStudentData.data,
    AcademicsStudentData.loading,
    studentDeleteModal,
  ]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !AcademicsStudentData.loading) {
          AcademicsStudentData.fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetAcdStudents.edges;
              const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetAcdStudents: {
                  edges: [...students, ...newEdges],
                  pageInfo,
                  totalCount: AcademicsStudentData.data
                    ? AcademicsStudentData.data.GetAcdStudents.totalCount!
                    : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.FOR_ADMISSIONS
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.FOR_ADMISSIONS,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Delete Students</Title>
      <div className="delete-student">
        <form className="row g-0 delete-student__select-options">
          <div className="col-1 ">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchData(e.target.value)
              }
              onKeyDown={handleFormEvent}
            />
          </div>
          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={departmentDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    InputLabelProps={{ shrink: true }}
                    id="outlined Departments"
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      handleMUISelectEvent(e);
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue);
                  } else {
                    setSemesterSelected(null);
                  }
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_CATEGORY_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={categoryDropDown}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, categorySelected)
                }
                value={categorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategorySelected(newValue);
                  } else {
                    setCategorySelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={categoryLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={STUDENT_STATUS}
              openOnFocus
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, statusSelected)
              }
              value={statusSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setStatusSelected(newValue);
                } else {
                  setStatusSelected(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setStatusSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                  fullWidth
                />
              )}
            />
          </div>
        </form>
        <div className={`delete-student__tableblock ${dataClasses.root}`}>
          <DataGridPro
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            disableRowSelectionOnClick
            hideFooter
            slotProps={{
              columnsPanel: { disableHideAllButton: true },
            }}
          />
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              mode="delete"
              onClick={() => setStudentDeleteModal(!studentDeleteModal)}
            />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 delete-student__total">
            <StudentTotalCount
              totalCount={
                AcademicsStudentData.data
                  ? AcademicsStudentData.data.GetAcdStudents.totalCount
                  : 0
              }
            />
          </div>
        </div>
      </div>
      <LoadingModal flag={deleteLoading} />
      <DeleteModal
        modalFlag={studentDeleteModal}
        setModalFlag={setStudentDeleteModal}
        handleMultipleDelete={handleSubmit}
        id={selectedRows}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};
export default StudentReservedList;
