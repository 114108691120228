import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Button } from "../../../../../stories/Button/Button";
import { Title } from "../../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../../Types/Tables";
import Home from "../../../Home/Index";

import {
  formatter,
  getHeaderRowStyle,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";

import { EMPTY_STRING } from "../../../../../utils/constants";
import { AppContext } from "../../../../../context/context";
import { payloadTypes } from "../../../../../context/reducer";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcctExpencesReport,
  GetAcctIncomeReport,
  GetAcctProfitAndLossReport,
} from "../../../queries/FeeLedgers/query/Byid";
import {
  acct_ldgr_details,
  GetAcctReceiptsAndPaymentsReportVars,
} from "../R&P/Index";
import { Cell } from "exceljs";
import ExcelJS from "exceljs";
import useToken from "../../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import LoadingModal from "../../../../../pages/LoadingModal";
import Eduate from "../../../../../images/Eduate_Logo_image.png";
import { acct_group_ldgr_details } from "../../../hooks/useAcctLedgerData";
import { GetAcctProfitAndLossReportData } from "../BalanceSheet/Index";
import {
  ExcelAlignment,
  ExcelFont,
  ExcelFooterHeader,
  ExcelPageHeader,
  ExcelSheetsNames,
  PageFor,
} from "../../../../../utils/Enum.types";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  A5_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  A_CELL,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  D_CELL,
  E5_CELL,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  EXCESS_INCOME,
  E_CELL,
  F4_CELL,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FOOTER_CSS,
  FROZEN_CELLS,
  GROUP_LDGR_FONT,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  INCOME_FONT,
  LESS_INCOME,
  TABLE_CSS,
  TABLE_HEADER_CSS,
  TABLE_HEADER_CSS2,
  TABLE_HEADER_CSS4,
} from "../../../../Library/Constants";
import { RandPheaderData } from "../../../common/HeaderConsts";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import useAcctTableJson from "../../../json/useAcctTableJson";
interface gr_ldgr_ie_summary {
  id: number;
  gr_ie_ob: number;
  gr_ie_cr: number;
  gr_ie_db: number;
  gr_ie_cb: number;
  acct_group_ldgr_details: acct_group_ldgr_details;
}

interface acct_ldgr_ie_summary {
  ldgr_ie_ob: number;
  ldgr_ie_cr: number;
  ldgr_ie_db: number;
  ldgr_ie_cb: number;
  acct_group_ldgr_details: acct_group_ldgr_details;
  acct_ldgr_details: acct_ldgr_details;
}
interface GetAcctIncomeReportDetails {
  group_ldgr_ie_summary: gr_ldgr_ie_summary[];
  acct_ldgr_ie_summary: acct_ldgr_ie_summary[];
}
interface GetAcctIncomeReportData {
  GetAcctIncomeReport: GetAcctIncomeReportDetails;
  GetAcctExpencesReport: GetAcctIncomeReportDetails;
}
interface Props {
  pageType: PageFor;
  setModalFlag?: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({ pageType, setModalFlag }: Props) => {
  const { dispatch, state } = useContext(AppContext);
  const { Accounts_Table } = useAcctTableJson();
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { format } = formatter;
  const { serverDate } = useServerDateandTime();

  const [GetIncome, { data, loading, error }] = useLazyQuery<
    GetAcctIncomeReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctIncomeReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });

  const [
    GetExp,
    { data: Expenditure, loading: ExpenditureLoading, error: ExpendetireError },
  ] = useLazyQuery<
    GetAcctIncomeReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctExpencesReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
    },
    fetchPolicy: "network-only",
  });
  const { InstDetails } = useInstDetails(1);
  const [GetPandL, { data: ProfitndLoss }] = useLazyQuery<
    GetAcctProfitAndLossReportData,
    GetAcctReceiptsAndPaymentsReportVars
  >(GetAcctProfitAndLossReport, {
    variables: {
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      inst_id: InstId!,
      token,
    },
  });

  let grandTotalOfIncome = 0;
  let totalExpendeture = 0;

  let grandTotalProfitLoss = 0;
  // eslint-disable-next-line
  data?.GetAcctIncomeReport.group_ldgr_ie_summary.map((edge) => {
    grandTotalOfIncome += edge.gr_ie_cb;
  });
  // eslint-disable-next-line
  Expenditure?.GetAcctExpencesReport.group_ldgr_ie_summary.map((edge) => {
    totalExpendeture += edge.gr_ie_cb;
  });
  grandTotalProfitLoss = ProfitndLoss
    ? ProfitndLoss.GetAcctProfitAndLossReport.gr_ie_cb
    : 0;
  let message =
    grandTotalProfitLoss < 0
      ? "LESS INCOME OVER EXPENDITURE"
      : "EXCESS OF INCOME OVER EXPENDITURE";
  const className = grandTotalProfitLoss < 0 ? "danger" : "totalcount";

  const grandExpTotal = grandTotalProfitLoss * -1 + totalExpendeture;

  const groupedExpenditure =
    Expenditure?.GetAcctExpencesReport.group_ldgr_ie_summary?.map((edge) => {
      const accountLedgers =
        Expenditure.GetAcctExpencesReport.acct_ldgr_ie_summary?.filter(
          (f) =>
            f.acct_group_ldgr_details.id === edge.acct_group_ldgr_details.id
        );
      return {
        groupLedgerDesc: edge.acct_group_ldgr_details.gr_ldgr_desc,
        accLedgers: accountLedgers?.map((ldgr) => ({
          accLedgersDesc: ldgr.acct_ldgr_details.ldgr_desc,
          ldgrCb: ldgr.ldgr_ie_cb,
        })),
        groupLdgrCb: edge.gr_ie_cb,
      };
    });
  const groupedIncome = data?.GetAcctIncomeReport?.group_ldgr_ie_summary?.map(
    (edge) => {
      const accLdgr = data.GetAcctIncomeReport?.acct_ldgr_ie_summary?.filter(
        (f) => f.acct_group_ldgr_details.id === edge.acct_group_ldgr_details.id
      );
      return {
        groupLdgrDesc: edge.acct_group_ldgr_details.gr_ldgr_desc,
        accLedgers: accLdgr.map((ldgr) => ({
          accLedgersDesc: ldgr.acct_ldgr_details.ldgr_desc,
          ldgrcb: ldgr.ldgr_ie_cb,
        })),
        groupLdgrCb: edge.gr_ie_cb,
      };
    }
  );

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.INCOME_EXPENDITURE);
    const dataRows = groupedExpenditure
      ? groupedExpenditure.map((group) => ({
          group: group.groupLedgerDesc,
          list: group.accLedgers.map((ledger) => ({
            desc: ledger.accLedgersDesc,
            amount: format(Math.abs(ledger.ldgrCb)),
            groupRpPymtShow: format(Math.abs(group.groupLdgrCb)),
          })),
        }))
      : [];
    const dataRows2 = groupedIncome
      ? groupedIncome.map((group) => ({
          group: group.groupLdgrDesc,
          list: group.accLedgers.map((ledger) => ({
            desc: ledger.accLedgersDesc,
            amount: format(ledger.ldgrcb),
            groupRpPymtShow: format(group.groupLdgrCb),
          })),
        }))
      : [];

    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 32;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 21;
    worksheet.getRow(5).height = 25;
    worksheet.getColumn(1).width = 34;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 26;
    worksheet.getColumn(4).width = 10;
    worksheet.getColumn(5).width = 34;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "M1:M1");
          worksheet.mergeCells(
            1,
            1,
            1,
            RandPheaderData.length + RandPheaderData.length + 1
          );
          const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
          mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
          mergedCell.fill = HEADER_CSS;
          mergedCell.font = headerStyle[0].font;
          mergedCell.alignment = HEADER_ALIGNMENT;

          const mergedAddress: Cell = worksheet.getCell(A2_CELL);
          mergedAddress.value = InstDetails.data?.nodes[0]?.inst_address;
          mergedAddress.fill = HEADER_CSS;
          mergedAddress.font = ADDRESS_FONT;
          mergedAddress.alignment = HEADER_ALIGNMENT;
          worksheet.mergeCells("A2:G2");

          const mergedPlace: Cell = worksheet.getCell(A3_CELL);
          mergedPlace.value =
            InstDetails.data?.nodes[0]?.inst_place +
            "-" +
            InstDetails.data?.nodes[0]?.inst_pin;

          mergedPlace.fill = HEADER_CSS;
          mergedPlace.font = ADDRESS_FONT;
          mergedPlace.alignment = ADDRESS_ALIGNMENT;
          worksheet.mergeCells("A3:G3");

          const mergedHeader: Cell = worksheet.getCell(C4_CELL);
          mergedHeader.value = ExcelPageHeader.INCOME_EXPENDITURE;
          mergedHeader.fill = FILE_NAME_CSS;
          mergedHeader.font = FILE_NAME_FONT;
          mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("C4:E4");
          const mergedDate: Cell = worksheet.getCell(F4_CELL);
          mergedDate.value = ExcelPageHeader.DATE + toStandardDate(serverDate);
          mergedDate.fill = FILE_NAME_CSS;
          mergedDate.font = FIN_YEAR_FONT;
          mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("F4:G4");
          const mergedYear: Cell = worksheet.getCell(A4_CELL);
          mergedYear.value = state.ActiveFinYr
            ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
            : ExcelPageHeader.YEAR;
          mergedYear.fill = FILE_NAME_CSS;
          mergedYear.font = FIN_YEAR_FONT;
          mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet.mergeCells("A4:B4");

          worksheet.mergeCells("A5:C5");
          const mergedCell2: Cell = worksheet.getCell(A5_CELL);
          mergedCell2.value = ExcelPageHeader.EXPENDITURE_ACCOUNT;
          mergedCell2.fill = TABLE_HEADER_CSS2;
          mergedCell2.font = INCOME_FONT;
          mergedCell2.alignment = {
            horizontal: ExcelAlignment.CENTER,
            vertical: ExcelAlignment.MIDDLE,
          };
          worksheet.mergeCells("E5:G5");
          const mergedCell3: Cell = worksheet.getCell(E5_CELL);
          mergedCell3.value = ExcelPageHeader.INCOME_ACCOUNT;
          mergedCell3.fill = TABLE_HEADER_CSS2;
          mergedCell3.font = INCOME_FONT;
          mergedCell3.alignment = {
            horizontal: ExcelAlignment.CENTER,
            vertical: ExcelAlignment.MIDDLE,
          };
          let Char = FIRST_INDEX;

          for (let i = 0; i < RandPheaderData.length; i++) {
            Char = String.fromCharCode(Char.charCodeAt(0) + 1);

            const rowData: Cell = worksheet.getCell(Char + 6);
            rowData.value = RandPheaderData[i];
            rowData.fill = TABLE_HEADER_CSS;
            rowData.border = BORDER_DATA;
            rowData.font = ACC_HEADER_FONT;
            rowData.alignment = { horizontal: ExcelAlignment.CENTER };
          }
          let Char2 = D_CELL;

          for (let i = 0; i < RandPheaderData.length; i++) {
            Char2 = String.fromCharCode(Char2.charCodeAt(0) + 1);

            const rowData: Cell = worksheet.getCell(Char2 + 6);
            rowData.value = RandPheaderData[i];
            rowData.fill = TABLE_HEADER_CSS;
            rowData.border = BORDER_DATA;
            rowData.font = ACC_HEADER_FONT;
            rowData.alignment = { horizontal: ExcelAlignment.CENTER };
          }
          let DataStart = A_CELL;
          let DataNmbr = 7;
          // eslint-disable-next-line
          let Increment = 0;
          // eslint-disable-next-line
          let num = 0;

          dataRows?.forEach((rowData, index) => {
            let cell4 = worksheet.getCell(DataStart + DataNmbr);
            cell4.value = rowData.group;
            worksheet.mergeCells(`A${DataNmbr}:C${DataNmbr}`);
            cell4.font = GROUP_LDGR_FONT;
            cell4.alignment = {
              horizontal: ExcelAlignment.LEFT,
            };
            cell4.fill = TABLE_CSS;

            rowData.list.forEach((listItem, i) => {
              let cellA = worksheet.getCell(`A${DataNmbr + i + 1}`);
              let cellB = worksheet.getCell(`B${DataNmbr + i + 1}`);
              let styleCellC = worksheet.getCell(`C${DataNmbr + i + 1}`);
              let cellC = worksheet.getCell(
                `C${DataNmbr + rowData.list.length}`
              );
              cellA.value = listItem.desc;
              cellB.value = listItem.amount;
              cellC.value = listItem.groupRpPymtShow;
              cellA.fill = TABLE_HEADER_CSS4;
              cellB.fill = TABLE_HEADER_CSS4;
              styleCellC.fill = TABLE_HEADER_CSS4;
              cellB.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
              cellA.alignment = {
                horizontal: ExcelAlignment.CENTER,
              };
              cellB.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellC.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellC.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
            });
            DataNmbr += rowData.list ? rowData.list.length + 1 : 1;
            Increment += rowData.list ? rowData.list.length + 1 : 1;
            num = 7 + rowData.list.length + index + 1;
          });
          let DataStartNmbr = E_CELL;
          let Dnumber = 7;
          // eslint-disable-next-line
          let Increment2 = 0;
          // eslint-disable-next-line
          let num2 = 0;
          dataRows2.forEach((row, index) => {
            let cellE4 = worksheet.getCell(DataStartNmbr + Dnumber);
            cellE4.value = row.group;
            worksheet.mergeCells(`E${Dnumber}:G${Dnumber}`);
            cellE4.font = GROUP_LDGR_FONT;
            cellE4.alignment = {
              horizontal: ExcelAlignment.LEFT,
            };
            cellE4.fill = TABLE_CSS;

            row.list.forEach((listItem, i) => {
              let cellE = worksheet.getCell(`E${Dnumber + i + 1}`);
              let cellF = worksheet.getCell(`F${Dnumber + i + 1}`);
              let styleCellG = worksheet.getCell(`G${Dnumber + i + 1}`);
              let cellG = worksheet.getCell(
                `G${Dnumber + i + row.list.length}`
              );
              cellE.value = listItem.desc;
              cellF.value = listItem.amount;
              cellG.value = listItem.groupRpPymtShow;
              cellE.fill = TABLE_HEADER_CSS4;
              cellF.fill = TABLE_HEADER_CSS4;
              styleCellG.fill = TABLE_HEADER_CSS4;
              cellF.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
              cellE.alignment = {
                horizontal: ExcelAlignment.CENTER,
              };
              cellF.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellG.alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              cellG.font = {
                name: ExcelFont.COURIER_NEW,
                size: 10,
              };
            });
            Dnumber += row.list ? row.list.length + 1 : 1;
            Increment2 += row.list ? row.list.length + 1 : 1;
            num2 = 7 + row.list.length + index + 1;
          });

          const footerRow2 = worksheet.addRow([]);
          footerRow2.getCell(1).value =
            grandTotalProfitLoss < 0 ? LESS_INCOME : EXCESS_INCOME;
          footerRow2.getCell(1).font = {
            name: ExcelFont.ARIAL,
            size: 10,
          };
          footerRow2.getCell(1).alignment = {
            horizontal: ExcelAlignment.LEFT,
          };
          footerRow2.getCell(3).value =
            grandTotalProfitLoss < 0
              ? `(${format(Math.abs(grandTotalProfitLoss))})`
              : format(Math.abs(grandTotalProfitLoss));
          footerRow2.getCell(3).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };
          worksheet.addConditionalFormatting({
            ref: `A${footerRow2.number}:C${footerRow2.number}`,
            rules: [
              {
                type: "expression",
                priority: 1,
                formulae: ["TRUE"],
                style: {
                  font: {
                    name: "Arial",
                    size: 10,
                    bold: true,
                    color: {
                      argb: grandTotalProfitLoss < 0 ? "FF000000" : "FFFFFFFF",
                    },
                  },
                  fill: {
                    type: "pattern",
                    pattern: "solid",
                    bgColor: {
                      argb: grandTotalProfitLoss < 0 ? "FFFF9999" : "059669",
                    },
                  },
                },
              },
            ],
          });
          worksheet.addRow([]);
          const footerRow = worksheet.addRow([]);
          footerRow.getCell(2).value = ExcelFooterHeader.GRAND_TOTAL;
          footerRow.getCell(2).font = {
            name: ExcelFont.ARIAL,
            size: 10,
          };
          footerRow.getCell(2).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };
          footerRow.getCell(6).value = ExcelFooterHeader.GRAND_TOTAL;
          footerRow.getCell(6).font = {
            name: ExcelFont.ARIAL,
            size: 10,
          };
          footerRow.getCell(6).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };

          footerRow.getCell(3).value = format(Math.abs(grandExpTotal));
          footerRow.getCell(3).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };
          footerRow.getCell(7).value = format(grandTotalOfIncome);
          footerRow.getCell(7).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };
          worksheet.addConditionalFormatting({
            ref: `C${footerRow.number}`,
            rules: FOOTER_CSS,
          });
          worksheet.addConditionalFormatting({
            ref: `G${footerRow.number}`,
            rules: FOOTER_CSS,
          });

          workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
            const blob = new Blob([buffer], {
              type: BLOB_TYPE,
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement(ELEMENT);
            link.href = url;
            link.setAttribute(DOWNLOAD, ExcelSheetsNames.IE_ACCOUNT);
            document.body.appendChild(link);
            link.click();
          });
        });
      });
  };

  useEffect(() => {
    if (state.ActiveFinYr) {
      GetIncome();
      GetExp();
      GetPandL();
    }
  }, [state.ActiveFinYr, GetIncome, GetExp, GetPandL]);
  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}

      <Title>Income & Expenditure</Title>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "receipt-payment-account"
            : "receipt-payment-account__modal"
        }
      >
        {pageType === PageFor.MODAL && (
          <div className="row g-0">
            <div className="col"></div>
            <div className="col-2 receipt-payment-account__modal--year">
              <TextField
                label="Financial Year"
                disabled
                InputLabelProps={{ shrink: true }}
                className="receipt-payment-account__modal--textfield"
              />
            </div>
          </div>
        )}
        <div
          className={
            pageType === PageFor.GENERAL
              ? "row g-0 receipt-payment-account__tableblock"
              : "row g-0 receipt-payment-account__modal--tableblock"
          }
        >
          <div className="col h100">
            {ExpendetireError ? (
              <b className="nodata"> {ExpendetireError.message}</b>
            ) : (
              <TableContainer className="receipt-payment-account__tableblock--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="receipt-payment-account__tableblock--table--groupheader">
                      <TableCell colSpan={2}>Expenditure</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow className="receipt-payment-account__tableblock--table--subheader">
                      {Accounts_Table.Reports.ReceiptAndPaymentAccount.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {Expenditure?.GetAcctExpencesReport.group_ldgr_ie_summary?.map(
                        (edge, index) => {
                          return (
                            <>
                              <TableRow
                                className="receipt-payment-account__tableblock--table--rowheader"
                                key={index}
                              >
                                <TableCell colSpan={3}>
                                  {edge.acct_group_ldgr_details.gr_ldgr_desc}
                                </TableCell>
                              </TableRow>
                              {Expenditure.GetAcctExpencesReport.acct_ldgr_ie_summary
                                .filter(
                                  (f) =>
                                    f.acct_group_ldgr_details.id ===
                                    edge.acct_group_ldgr_details.id
                                )
                                .map((ldgr, index2) => {
                                  return (
                                    <TableRow
                                      key={index2}
                                      onClick={() => {
                                        dispatch({
                                          type: payloadTypes.SET_ACCOUNT_LEDGER_ID,
                                          payload: {
                                            accountLedgerId:
                                              ldgr.acct_ldgr_details.id,
                                          },
                                        });
                                      }}
                                    >
                                      <TableCell>
                                        {ldgr.acct_ldgr_details.ldgr_desc}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="receipt-payment-account__tableblock--table--amount"
                                      >
                                        {ldgr.ldgr_ie_cb > 0
                                          ? `(${format(
                                              Math.abs(ldgr.ldgr_ie_cb)
                                            )})`
                                          : format(Math.abs(ldgr.ldgr_ie_cb))}
                                      </TableCell>
                                      <TableCell
                                        id="td-right"
                                        className="receipt-payment-account__tableblock--table--amount font-green "
                                      >
                                        {index2 ===
                                        Expenditure.GetAcctExpencesReport.acct_ldgr_ie_summary.filter(
                                          (f) =>
                                            f.acct_group_ldgr_details.id ===
                                            edge.acct_group_ldgr_details.id
                                        ).length -
                                          1
                                          ? edge.gr_ie_cb > 0
                                            ? `(${format(
                                                Math.abs(edge.gr_ie_cb)
                                              )})`
                                            : format(Math.abs(edge.gr_ie_cb))
                                          : EMPTY_STRING}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </>
                          );
                        }
                      )}

                      <TableRow className={className}>
                        <TableCell>{message}</TableCell>
                        <TableCell></TableCell>
                        <TableCell id="td-right">
                          {grandTotalProfitLoss < 0
                            ? `(${format(Math.abs(grandTotalProfitLoss))})`
                            : format(Math.abs(grandTotalProfitLoss))}
                        </TableCell>
                      </TableRow>
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
          <div className="col h100">
            {error ? (
              <b className="nodata">{error.message}</b>
            ) : (
              <TableContainer className="receipt-payment-account__tableblock--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="receipt-payment-account__tableblock--table--groupheader">
                      <TableCell colSpan={2}>Income</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow className="receipt-payment-account__tableblock--table--subheader">
                      {Accounts_Table.Reports.ReceiptAndPaymentAccount.Table_Headers.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} className={th.className}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.GetAcctIncomeReport?.group_ldgr_ie_summary?.map(
                      (edge, index) => {
                        return (
                          <>
                            <TableRow
                              className="receipt-payment-account__tableblock--table--rowheader"
                              key={index}
                            >
                              <TableCell colSpan={3}>
                                {edge.acct_group_ldgr_details.gr_ldgr_desc}
                              </TableCell>
                            </TableRow>
                            {data.GetAcctIncomeReport?.acct_ldgr_ie_summary
                              ?.filter(
                                (f) =>
                                  f.acct_group_ldgr_details.id ===
                                  edge.acct_group_ldgr_details.id
                              )
                              .map((ldgr, index2) => {
                                return (
                                  <TableRow key={index2}>
                                    <TableCell>
                                      {ldgr.acct_ldgr_details.ldgr_desc}
                                    </TableCell>
                                    <TableCell
                                      id="td-right"
                                      className="receipt-payment-account__tableblock--table--amount"
                                    >
                                      {ldgr.ldgr_ie_cb < 0
                                        ? `(${format(
                                            Math.abs(ldgr.ldgr_ie_cb)
                                          )})`
                                        : format(ldgr.ldgr_ie_cb)}
                                    </TableCell>
                                    <TableCell
                                      id="td-right"
                                      className="receipt-payment-account__tableblock--table--amount font-green"
                                    >
                                      {index2 ===
                                      data.GetAcctIncomeReport.acct_ldgr_ie_summary.filter(
                                        (f) =>
                                          f.acct_group_ldgr_details.id ===
                                          edge.acct_group_ldgr_details.id
                                      ).length -
                                        1
                                        ? edge.gr_ie_cb < 0
                                          ? `(${format(
                                              Math.abs(edge.gr_ie_cb)
                                            )})`
                                          : format(edge.gr_ie_cb)
                                        : EMPTY_STRING}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>

        <div className="receipt-payment-account__footer row g-0">
          <div className="col receipt-payment-account__footer--total">
            <div className="receipt-payment-account__footer--total--text">
              Grand Total :
            </div>
            <div className="receipt-payment-account__footer--total--count">
              {format(Math.abs(grandExpTotal))}
            </div>
          </div>
          <div className="col receipt-payment-account__footer--total">
            <div className="receipt-payment-account__footer--total--text">
              Grand Total :
            </div>
            <div className="receipt-payment-account__footer--total--count">
              {format(grandTotalOfIncome)}
            </div>
          </div>
        </div>
      </div>
      {pageType === PageFor.GENERAL ? (
        <div className="button-left">
          <Button mode="export" onClick={downloadExcel} />
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      ) : (
        <Button mode="cancel" onClick={() => setModalFlag?.(false)} />
      )}

      <LoadingModal flag={loading || ExpenditureLoading} />
    </>
  );
};

export default Index;
