/**
 * Adjustments component is responsible for handling the adjustments of student fee advances.
 * It displays the student details, fee details, and allows the user to adjust the fee amount.
 * The component also provides options to save the adjustments, clear the form, and navigate back.
 * It uses various hooks and components from the MUI library for UI elements and functionality.
 * The component also uses GraphQL queries and mutations to fetch and update data from the server.
 * The component is used in the student fee receipt page and can be rendered in a modal as well.
 */
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-modal";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import { Label } from "../../../stories/Label/Label";
import { Title } from "../../../stories/Title/Title";
import {
  DebitOrCredit,
  PageNumbers,
  ReceiptTypes,
  PageFor,
  VoucherBookKey,
  StudentListFor,
  StudentAcctReportType,
  Operation,
  BankOrCash,
  ModuleName,
  Direction,
  SortBy,
  NonDemand,
} from "../../../utils/Enum.types";

import { studentRecepit } from "../../../Types/Accounting/index";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  DateRange,
  formatter,
  NameOfTheDay,
  toggleFullSceen,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../utils/constants";
import { AddAcctVoucherMasterAndDetails } from "../queries/receipts/mutation/Index";
import dayjs from "dayjs";

import { AccountsTableHeaderProps } from "../../../Types/Tables";
import ConfigurationSettings from "../../Master/configurations/general/Index";

import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import { AppContext } from "../../../context/context";

import Home from "../Home/Index";
import {
  ConfigurationsModalStyles,
  DeleteLedgerModalStyles,
  DepositModalCustomStyles,
  EditModalCustomStyles,
  PrintConfigModalStyles,
  PrintModalStyles,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
import FeeLedger from "../FeeLedger/Index";
import LoadingModal from "../../../pages/LoadingModal";
import Settings from "../../../images/Settings.svg";
import Enlarge from "../../../images/Enlarge.svg";
import Edit from "../../../images/Edit.svg";
import Avatar from "../../../images/Avatar.svg";
import Close from "../../../images/Close.svg";

import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import { msgType, responseType } from "../../../utils/Form.types";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import useToken from "../../../customhooks/useToken";
import { Keys } from "../../../utils/Enum.keys";
import useStudentDepositDetails, {
  DepositEdge,
} from "../hooks/useStudentDepositDetails";
import useStudentDemandDetails, {
  studentDemandDetailsData,
} from "../hooks/useStudentDemandDetails";
import MessageModal from "../../../pages/MessageModal";
import { getDownloadUrl } from "../../../utils/DownloadFile";
import { payloadTypes } from "../../../context/reducer";
import useVoucherNumber, { VDetails } from "../hooks/useVoucherNumber";
import StudentList from "../common/StudentList";
import useAcctStdAdmissionNumber from "../hooks/useAcctStdAdmissionNumber";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import {
  DemandDetailsQueryType,
  DepositLedgerQueryType,
  LedgerType,
} from "../common/QueryTypes";
import { Data } from "../../Print/Accounts/FeeReceipts/Standard/Index";
import FeeReceiptPrint from "../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";
import PerModuleConfiguration from "../../Configurations/PerModuleConfiguration";
import { SwConfigQueryType } from "../../HR/enums/Enum.types";
import { GetAcctStdDeposits } from "../queries/FeeLedgers/query/Byid";
import { GetAcctVouchers, GetVoucherNumber } from "../queries/Vouchers/query";
import {
  InstitutionAcctConfigurationTypes,
  VoucherQueryTypes,
} from "../common/Enum.types";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { GlobalPageConfigData } from "../../../Types/configtypes";
import TextArea from "../../../stories/TextArea/TextArea";
import useAcctTableJson from "../json/useAcctTableJson";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import {
  GroupsWithSum,
  TotalInterface,
} from "../StudentDetails/FeeReceipt/Index";
import MultipleReceipts, {
  IMultipleReceipts,
} from "../StudentDetails/FeeReceipt/modals/MultipleReceipts";
import {
  AcctVoucherMasterAndDetails,
  AddAcctVoucherMasterAndDetailsData,
  AddAcctVoucherMasterAndDetailsVars,
} from "../../../Types/Accounting/mutations";
import { InstitutionPrintConfig } from "../../Print/Enum.types";
import useInstDetails from "../../../customhooks/general/useInstDetails";

interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  selectStudent: boolean;
}

const Adjustments = ({ pageType, setModalFlag, selectStudent }: Props) => {
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const listClasses = ListAutoCompleteStyles();
  const listTextClasses = ListAutoCompleteTextStyles();
  const { InstId } = useParams<{ InstId: string }>();
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
const {categoryLabel}=useInstLabels();
  const { studentId } = useParams();
  const navigate = useNavigate();
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.STUDENT_RECEIPT_PAGE
  );
  const [rcptDate, setRcptDate] = useState("");
  const { data: serverDateData, loading: serverDateLoading } =
    useServerDateandTime();
  const [admNo, setAdmNo] = useState("");
  const [multiplereceipts, setMultipleReceipts] = useState<IMultipleReceipts[]>(
    []
  );
  const [multipleReceiptsModal, setMultipleRecepitsModal] = useState(false);
  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.FEE_ADVANCE
  );

  //use states for modal screens
  const [studentModal, setStudentModal] = useState(false);
  const [feeLedgerModal, setFeeledgerModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [configurationModal, setConfigurationModal] = useState(false);
  const [printConfig, setPrintConfigModal] = useState(false);
  const [tableData, setTableData] = useState<Data[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [v_date, set_v_Date] = useState("");
  const [printModal, SetPrintModal] = useState(false);
  //use states for input tags
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [UpdatedReceviedAmount, setUpdatedReceviedAmount] = useState(0);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [narration, setNarration] = useState("");
  const [imageString, setImageString] = useState("");
  const [generatedVoucherNumbers, setVoucherNumbers] = useState<VDetails[]>([]);

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0,
  });

  //use states for table items
  const [items, setItems] = useState<studentRecepit[]>([]);
  const [depositItems, setDepositItems] = useState<DepositEdge[]>([]);
  const [filteredDepositItems, setFilteredDepositItems] =
    useState<DepositEdge>();
  const [depositSelectModal, setDepositSelectModal] = useState(false);
  const receivedAmountRef = useRef<HTMLInputElement>(null);

  const transactionRefNumberRef = useRef<HTMLInputElement>(null);
  const remarksRef = useRef<HTMLTextAreaElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  // use queries
  const { user_details } = useLoggedInUserDetails();
  const { studentData, studentFormData } = useStudentDatabyId();
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  const [voucherKeys, setVoucherKeys] = useState<string[]>([]);

  const { firstDay, lastDay } = DateRange(new Date().toString()) || {};

  const { InstDetails } = useInstDetails(1);

  const { configData: configKeys } = useSwConfigData([
    InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE,
    InstitutionAcctConfigurationTypes.MULTIPLE_BILL_BOOKS,
    InstitutionPrintConfig.PRINT_RECEIPT_BY_DEFAULT,
  ]);
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let editDate = false;
    let isMultipleBillook = false;
    let enableReceiptPrintByDefault = false;

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.MULTIPLE_BILL_BOOKS:
            isMultipleBillook = item.config_boolean_value;
            break;
          case InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE:
            editDate = item.config_boolean_value;
            break;
          case InstitutionPrintConfig.PRINT_RECEIPT_BY_DEFAULT:
            enableReceiptPrintByDefault = item.config_boolean_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      editDate,
      isMultipleBillook,
      enableReceiptPrintByDefault,
    };
  };

  const { editDate, isMultipleBillook, enableReceiptPrintByDefault } =
    filterDataByConfigKey(configKeys.data?.GetSwConfigVariables!);
  const { voucherNumber } = useVoucherNumber(
    isMultipleBillook
      ? (voucherKeys as VoucherBookKey[])!
      : VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK
  );

  const { StudentDemandDetails } = useStudentDemandDetails(
    DemandDetailsQueryType.DEMAND_DETAILS_GT_0,
    false,
    EMPTY_STRING
  );
  const { StudentDepositData } = useStudentDepositDetails(
    DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID
  );

  //mutations
  const [GenerateStudentReceipts, { loading: GenerateStudentReceiptsLoading }] =
    useMutation<
      AddAcctVoucherMasterAndDetailsData,
      AddAcctVoucherMasterAndDetailsVars
    >(AddAcctVoucherMasterAndDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  const total = useMemo(() => {
    return items.reduce(
      (acc, item) => {
        acc.totalOutStandingBalance += item.fee_ob;
        acc.totalDemandAmount += item.fee_demand;
        acc.totalconcession += item.fee_concession;
        acc.totalbalance += item.fee_bal;
        acc.totalPayable += item.fee_receivable;
        acc.totalpaidAmount += item.fee_received;
        acc.totaldepositAmount += item.rcpt_amt;
        return acc;
      },

      {
        totalOutStandingBalance: 0,
        totalDemandAmount: 0,
        totalconcession: 0,
        totalbalance: 0,
        totalPayable: 0,
        totalpaidAmount: 0,
        totaldepositAmount: 0,
      }
    );
  }, [items]);

  const {
    totalDemandAmount,
    totalOutStandingBalance,
    totalPayable,
    totalbalance,
    totalconcession,
    totaldepositAmount,
    totalpaidAmount,
  } = total;

  const itemsVoBookDescSet = useMemo(() => {
    return new Set(
      items.map(
        (item) => item.acct_ldgr_details.voucher_book_details?.vo_book_desc
      )
    );
  }, [items]);

  const handleFilterDepositDetails = (id: number) => {
    const result = depositItems.filter(
      ({ node: { acct_ldgr_id } }) => id === acct_ldgr_id
    )[0];
    setFilteredDepositItems(result);
    setDepositSelectModal(!depositSelectModal);

    setReceivedAmount(result.node.deposit_bal ?? 0);
  };
  const onEdit = (id: number, updatedRcptAmount: number) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setUpdatedReceviedAmount(updatedRcptAmount);
  };

  const groups = useMemo(() => {
    return items.reduce(
      (acc: Record<string, studentRecepit[]>, item, index) => {
        const groupId: string = item.v_no || "";
        const group = acc[groupId] || [];

        // Loop through generatedVoucherNumbers to find a match
        // generatedVoucherNumbers.forEach((voucher) => {
        //   if (
        //     item?.acct_ldgr_details?.voucher_book_details?.vo_book_key ===
        //     voucher?.vo_book_key
        //   ) {
        //     // Update the voucher number
        //     item.v_no = voucher.vo_number;
        //   }
        // });

        const generatedItem = generatedVoucherNumbers
          .filter(Boolean)
          .find(
            (voucher) =>
              item.acct_ldgr_details?.voucher_book_details?.vo_book_key ===
              voucher.vo_book_key
          );
        item.v_no = generatedItem ? generatedItem.vo_number : "";

        return {
          ...acc,
          [groupId]: [...group, item],
        };
      },
      {}
    );
  }, [items, generatedVoucherNumbers]);

  const it = useMemo(() => {
    return Object.entries(groups)
      .map(([groupId, items]) => ({
        groupId,
        items_rcpts: items.filter((item) => item.rcpt_amt > 0),
      }))
      .filter(({ items_rcpts }) => items_rcpts.length > 0);
  }, [groups]);

  const groupsWithSum: GroupsWithSum = Object.entries(groups).reduce(
    (acc, [groupId, group]) => ({
      ...acc,
      [groupId]: group.reduce(
        (total: TotalInterface, item) => ({
          sum: total.sum + (item.rcpt_amt || 0),
          vo_key: item.acct_ldgr_details.voucher_book_details?.vo_book_key!,
          vo_number: item.v_no!,
        }),
        { sum: 0, vo_key: "", vo_number: "" }
      ),
    }),
    {}
  );

  const handleStudentReceipts = () => {
    if (filteredDepositItems && state.ActiveFinYr) {
      if (isMultipleBillook) {
        const input: AcctVoucherMasterAndDetails[] = it.map(
          ({ groupId, items_rcpts }, index) => {
            const keys = Object.entries(groupsWithSum)?.find(
              ([id, num]: [string, TotalInterface]) => id === groupId
            )?.[1]?.vo_key!;
            const totalAmount = Object.entries(groupsWithSum).find(
              ([id, num]: [string, TotalInterface]) => id === groupId
            )?.[1].sum!;
            if (index === it.length - 1) {
              return {
                acct_voucher_master: {
                  fin_yr: state.ActiveFinYr
                    ? state.ActiveFinYr.fin_yr
                    : EMPTY_STRING,
                  v_type: ReceiptTypes.STUDENT_RECEIPT,
                  v_no: groupId,
                  v_std_anonymous_deposit_adjusted: false,
                  v_date: toIsoDate(rcptDate),
                  v_total_cr_amt: totalAmount,
                  v_total_db_amt: totalAmount,
                  v_reconciled: false,
                  v_reconciled_date: toIsoDate(DEFAULT_TIME),
                  v_std_receipt: true,
                  v_std_non_demand_receipt: false,
                  v_std_refund: false,
                  v_std_enquiry: false,
                  enquiry_student_id: 0,
                  v_std_deposit_adjusted: true,
                  v_std_receipt_anonymous: false,
                  v_std_refund_deposit: false,
                  v_book_type: keys,
                  v_std_deposit: false,
                  v_std_receipt_ob: false,
                  v_std_passout_receipt: false,
                  v_std_demand_receipt: true,
                  v_std_scholarship_deposit: false,
                  student_id: state.studentId,
                  class_id: studentData.data?.nodes[0].class.id,
                  semester_id: studentData.data?.nodes[0].semester.id,
                  v_std_amt_receipt: totalAmount,
                  v_std_amt_deposit: totalAmount,
                  v_std_amt_fine: 0,
                  v_std_amt_total: totalAmount,
                  v_std_amt_refunded: 0,
                  v_std_amt_adjusted: 0,
                  v_transcation_type: `Adjusted from ${filteredDepositItems?.node.acct_ldgr_details.ldgr_desc}`,
                  v_transcation_cash_or_bank: BankOrCash.ADJ,
                  v_transcation_no: referenceNumber,
                  v_transcation_date: toIsoDate(rcptDate),
                  v_transcation_narration: narration,
                  // paid_party_id: "",
                  // party_bill_no: "",
                  party_bill_date: toIsoDate(DEFAULT_TIME),
                  // party_name: "",
                  annx_yesno: false,
                  // annx_id: Math.random() * 1000,
                  is_vouch_multi_entry: false,
                  acct_ldgr_id_cr: items.filter((d) => d.rcpt_amt > 0)[0]
                    .acct_ldgr_details.id,
                  acct_ldgr_id_db: [filteredDepositItems][0]?.node
                    ?.acct_ldgr_details.id,
                },
                acct_voucher_db: [filteredDepositItems].map((item, index) => ({
                  vo_cr_db: DebitOrCredit.DEBIT,
                  vo_sl_no: index + 1,
                  vo_cr: 0,
                  vo_db: Object.entries(groupsWithSum).find(
                    (sum) => sum[0] === groupId
                  )?.[1].sum!,
                  vo_cr_total: 0,
                  vo_db_total: Object.entries(groupsWithSum).find(
                    (sum) => sum[0] === groupId
                  )?.[1].sum!,
                  acct_ldgr_id: item.node.acct_ldgr_details.id,
                })),
                acct_voucher_cr: items_rcpts.map((item, index) => ({
                  vo_sl_no: index + 1,
                  vo_cr_db: DebitOrCredit.CREDIT,
                  vo_cr: item.rcpt_amt,
                  vo_db: 0,
                  vo_cr_total: Object.entries(groupsWithSum).find(
                    (sum) => sum[0] === groupId
                  )?.[1].sum!,
                  vo_db_total: 0,
                  acct_ldgr_id: item.acct_ldgr_details.id,
                })),
              };
            } else
              return {
                acct_voucher_master: {
                  fin_yr: state.ActiveFinYr
                    ? state.ActiveFinYr.fin_yr
                    : EMPTY_STRING,
                  v_type: ReceiptTypes.STUDENT_RECEIPT,
                  v_no: groupId,
                  v_std_anonymous_deposit_adjusted: false,
                  v_date: toIsoDate(rcptDate),
                  v_total_cr_amt: totalAmount,
                  v_total_db_amt: totalAmount,
                  v_reconciled: false,
                  v_reconciled_date: toIsoDate(DEFAULT_TIME),
                  v_std_receipt: true,
                  v_std_non_demand_receipt: false,
                  v_std_refund: false,
                  v_std_enquiry: false,
                  enquiry_student_id: 0,
                  v_std_deposit_adjusted: true,
                  v_std_receipt_anonymous: false,
                  v_std_refund_deposit: false,
                  v_book_type: keys,
                  v_std_deposit: false,
                  v_std_receipt_ob: false,
                  v_std_passout_receipt: false,
                  v_std_demand_receipt: true,
                  v_std_scholarship_deposit: false,
                  student_id: state.studentId,
                  class_id: studentData.data?.nodes[0].class.id,
                  semester_id: studentData.data?.nodes[0].semester.id,
                  v_std_amt_receipt: totalAmount,
                  v_std_amt_deposit: totalAmount,
                  v_std_amt_fine: 0,
                  v_std_amt_total: totalAmount,
                  v_std_amt_refunded: 0,
                  v_std_amt_adjusted: 0,
                  v_transcation_type: `Adjusted from ${filteredDepositItems?.node.acct_ldgr_details.ldgr_desc}`,
                  v_transcation_cash_or_bank: BankOrCash.ADJ,
                  v_transcation_no: referenceNumber,
                  v_transcation_date: toIsoDate(rcptDate),
                  v_transcation_narration: narration,
                  // paid_party_id: "",
                  // party_bill_no: "",
                  party_bill_date: toIsoDate(DEFAULT_TIME),
                  // party_name: "",
                  annx_yesno: false,
                  // annx_id: Math.random() * 1000,
                  is_vouch_multi_entry: false,
                  acct_ldgr_id_cr: items.filter((d) => d.rcpt_amt > 0)[0]
                    .acct_ldgr_details.id,
                  acct_ldgr_id_db: [filteredDepositItems][0]?.node
                    ?.acct_ldgr_details.id,
                },
                acct_voucher_db: [filteredDepositItems].map((item, index) => ({
                  vo_cr_db: DebitOrCredit.DEBIT,
                  vo_sl_no: index + 1,
                  vo_cr: 0,
                  vo_db: Object.entries(groupsWithSum).find(
                    (sum) => sum[0] === groupId
                  )?.[1].sum!,
                  vo_cr_total: 0,
                  vo_db_total: Object.entries(groupsWithSum).find(
                    (sum) => sum[0] === groupId
                  )?.[1].sum!,
                  acct_ldgr_id: item.node.acct_ldgr_details.id,
                })),
                acct_voucher_cr: items_rcpts.map((item, index) => ({
                  vo_sl_no: index + 1,
                  vo_cr_db: DebitOrCredit.CREDIT,
                  vo_cr: item.rcpt_amt,
                  vo_db: 0,
                  vo_cr_total: Object.entries(groupsWithSum).find(
                    (sum) => sum[0] === groupId
                  )?.[1].sum!,
                  vo_db_total: 0,
                  acct_ldgr_id: item.acct_ldgr_details.id,
                })),
              };
          }
        );
        GenerateStudentReceipts({
          variables: {
            token,
            inst_id: InstId!,
            user_details,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            input,
          },
          refetchQueries: [
            {
              query: GetAcctStdDeposits,
              variables: {
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                query_type: DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID,
                student_id: state.studentId,
                token,
              },
            },
            {
              query: GetAcctVouchers,
              variables: {
                after: null,
                direction: Direction.ASC,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                first: ROWS_PER_PAGE,
                sortBy: SortBy.V_DATE,
                token,
                name: EMPTY_STRING,
                deposit: null,
                vTranscationType: EMPTY_STRING,
                partyName: EMPTY_STRING,
                vTranscationNo: EMPTY_STRING,
                vTranscationNarration: EMPTY_STRING,
                ldgrDesc: EMPTY_STRING,
                amount: null,
                input: {
                  inst_id: InstId!,
                  voucher_query_type: VoucherQueryTypes.BY_DATES,
                  vo_end_date: toIsoDate(lastDay!),
                  acct_ldgr_id: 0,
                  vo_start_date: toIsoDate(firstDay!),
                  vo_type: EMPTY_STRING,
                },
              },
            },
            {
              query: GetVoucherNumber,
              variables: {
                token,
                inst_id: InstId!,
                vo_book_key: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            const { id } = data.AddAcctVoucherMasterAndDetails[0];
            dispatch({
              type: payloadTypes.SET_RECEPIT_ID,
              payload: { receiptId: id },
            });
            dispatch({
              type: payloadTypes.SET_RECEPIT_ID,
              payload: { receiptId: id },
            });

            setMultipleReceipts(data.AddAcctVoucherMasterAndDetails);
            setMultipleRecepitsModal(!multipleReceiptsModal);
          }
        });
      } else {
        GenerateStudentReceipts({
          variables: {
            token,
            inst_id: InstId!,
            user_details,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            input: [
              {
                acct_voucher_master: {
                  fin_yr: state.ActiveFinYr
                    ? state.ActiveFinYr.fin_yr
                    : EMPTY_STRING,
                  v_type: ReceiptTypes.STUDENT_RECEIPT,
                  v_no: voucherNumber.data?.GetVoucherNumber.vo_number,
                  v_date: dayjs(rcptDate).format(),
                  v_total_cr_amt: totaldepositAmount,
                  v_total_db_amt: totaldepositAmount,
                  v_reconciled: false,
                  v_reconciled_date: dayjs(DEFAULT_TIME).format(),
                  v_std_receipt: true,
                  v_std_anonymous_deposit_adjusted: false,
                  v_std_non_demand_receipt: false,
                  v_std_refund: false,
                  v_std_enquiry: false,
                  v_std_receipt_ob: false,
                  v_std_refund_deposit: false,
                  v_std_deposit_adjusted: true,
                  v_book_type: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
                  v_std_deposit: false,
                  v_std_passout_receipt: false,
                  v_std_demand_receipt: true,
                  v_std_scholarship_deposit: false,
                  student_id: state.studentId,
                  class_id: studentData.data?.nodes[0].class.id,
                  semester_id: studentData.data?.nodes[0].semester.id,
                  v_std_amt_receipt: totaldepositAmount,
                  v_std_amt_deposit: totaldepositAmount,
                  v_std_amt_fine: 0,
                  v_std_amt_total: totaldepositAmount,
                  v_std_amt_refunded: 0,
                  v_std_amt_adjusted: 0,
                  v_transcation_type: `Adjusted from ${filteredDepositItems?.node.acct_ldgr_details.ldgr_desc}`,
                  v_transcation_cash_or_bank: BankOrCash.ADJ,
                  v_transcation_no: referenceNumber,
                  v_transcation_date: dayjs(rcptDate).format(),
                  v_transcation_narration: narration,
                  // paid_party_id: "",
                  v_std_receipt_anonymous: false,
                  // party_bill_no: "",
                  party_bill_date: dayjs(DEFAULT_TIME).format(),
                  // party_name: "",
                  annx_yesno: false,
                  // annx_id: Math.random() * 1000,
                  is_vouch_multi_entry: false,
                  acct_ldgr_id_cr: items.filter((item) => item.rcpt_amt > 0)[0]
                    .acct_ldgr_details.id,
                  acct_ldgr_id_db: [filteredDepositItems][0]?.node
                    ?.acct_ldgr_details.id,
                },

                acct_voucher_db: [filteredDepositItems].map(
                  (receipt, index) => ({
                    vo_cr_db: DebitOrCredit.DEBIT,
                    vo_sl_no: index + 1,
                    vo_cr: 0,
                    vo_db: totaldepositAmount,
                    vo_cr_total: 0,
                    vo_db_total: totaldepositAmount,
                    acct_ldgr_id: receipt.node?.acct_ldgr_details.id,
                  })
                ),
                acct_voucher_cr: items
                  .filter((item) => item.rcpt_amt > 0)
                  .map((item, index) => ({
                    vo_sl_no: index + 1,
                    vo_cr_db: DebitOrCredit.CREDIT,
                    vo_cr: item.rcpt_amt,
                    vo_db: 0,
                    vo_cr_total: totaldepositAmount,
                    vo_db_total: 0,
                    acct_ldgr_id: item.acct_ldgr_id,
                  })),
              },
            ],
          },
          refetchQueries: [
            {
              query: GetAcctStdDeposits,
              variables: {
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                query_type: DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID,
                student_id: state.studentId,
                token,
              },
            },
            {
              query: GetAcctVouchers,
              variables: {
                after: null,
                direction: Direction.ASC,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                first: ROWS_PER_PAGE,
                sortBy: SortBy.V_DATE,
                token,
                name: EMPTY_STRING,
                deposit: null,
                vTranscationType: EMPTY_STRING,
                partyName: EMPTY_STRING,
                vTranscationNo: EMPTY_STRING,
                vTranscationNarration: EMPTY_STRING,
                ldgrDesc: EMPTY_STRING,
                amount: null,
                input: {
                  inst_id: InstId!,
                  voucher_query_type: VoucherQueryTypes.BY_DATES,
                  vo_end_date: toIsoDate(lastDay!),
                  acct_ldgr_id: 0,
                  vo_start_date: toIsoDate(firstDay!),
                  vo_type: EMPTY_STRING,
                },
              },
            },
            {
              query: GetVoucherNumber,
              variables: {
                token,
                inst_id: InstId!,
                vo_book_key: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            const { v_no, v_date, id } = data.AddAcctVoucherMasterAndDetails[0];
            dispatch({
              type: payloadTypes.SET_RECEPIT_ID,
              payload: { receiptId: id },
            });
            setTableData(
              items
                .filter((item) => item.rcpt_amt > 0)
                .map((item) => ({
                  particular: item.acct_ldgr_details.ldgr_desc,
                  amount: item.rcpt_amt,
                }))
            );
            set_v_Date(v_date);
            set_vo_no(v_no);

            enableReceiptPrintByDefault && !printModal
              ? SetPrintModal(!printModal)
              : handleClear();
            setMessage({
              message: `Successfully generated fee receipt for student ${studentData.data?.nodes[0].first_name}`,
              flag: true,
              operation: Operation.CREATE,
            });
          }
        });
      }
    }
  };
  const handleClear = () => {
    !printModal &&
      dispatch({
        type: payloadTypes.SET_STUDENT_ID,
        payload: { studentId: 0 },
      });
    setItems([]);
    setReceivedAmount(0);
    setUpdatedReceviedAmount(0);
    setFilteredDepositItems(undefined);
    setImageString(EMPTY_STRING);
    setReferenceNumber(EMPTY_STRING);
    setNarration(EMPTY_STRING);
  };
  const HandleEditItem = (sl_number: number) => {
    const updatedData = items.map((item) => {
      if (item.id === sl_number) {
        if (UpdatedReceviedAmount > item.fee_receivable) {
          alert("Please check you're recepit amount");
          return item;
        } else {
          const newData = {
            ...item,
            fee_ob: item.fee_ob,
            fee_demand: item.fee_demand,
            fee_concession: item.fee_concession,
            fee_receivable: item.fee_receivable,
            fee_received: item.fee_received,
            fee_refunds: item.fee_refunds,
            fee_bal: item.fee_receivable - UpdatedReceviedAmount,
            acct_ldgr_id: item.acct_ldgr_id,
            id: item.id,
            cr_db: DebitOrCredit.CREDIT,
            rcpt_amt: UpdatedReceviedAmount,
          };

          return newData;
        }
      }
      return item;
    });
    setItems(updatedData);
  };

  const handleSplittingAmount = () => {
    let initalAmount = receivedAmount;
    if (items.length) {
      const rcvd_amt: studentRecepit[] = items.reduce(
        (result: studentRecepit[], element: studentRecepit) => {
          const rcpt_amt = Math.min(
            initalAmount,
            element.fee_receivable - element.fee_received
          );
          initalAmount -= rcpt_amt;
          result.push({
            ...element,
            rcpt_amt,
            fee_bal: element.fee_receivable - element.fee_received - rcpt_amt,
          });
          return result;
        },
        []
      );

      setItems(rcvd_amt);
      if (items[0].rcpt_amt > 0) {
        onEdit(items[0].id!, items[0].rcpt_amt);
      }
    }
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const mapStudentDemandDetails = (data: studentDemandDetailsData) => {
    return data.GetAcctStdDemandDetails.filter((item) => item !== null).map(
      (item) => ({
        fee_ob: item.fee_ob,
        fee_demand: item.fee_demand,
        fee_concession: item.fee_concession,
        fee_received: item.fee_received,
        fee_refunds: item.fee_refunds,
        fee_bal: item.fee_bal,
        acct_ldgr_id: item.acct_ldgr_id,
        id: item.id,
        cr_db: DebitOrCredit.CREDIT,
        recevied_amount: 0,
        legederType: LedgerType.GENERAL,
        fine_amt: 0,
        rcpt_amt: 0,
        acct_ldgr_details: item.acct_ldgr_details,
        fee_receivable: item.fee_receivable,
        fee_due_date: item.fee_due_date,
        v_book_desc: item.acct_ldgr_details.voucher_book_details
          ? item.acct_ldgr_details.voucher_book_details.vo_book_desc
          : "",
        vo_key: item.acct_ldgr_details.voucher_book_details
          ? item.acct_ldgr_details.voucher_book_details.vo_book_key
          : EMPTY_STRING,
        initialBalance: item.fee_bal,
        isDepositLedger: item.acct_ldgr_details.ldgr_std_fee_is_deposit_ldgr,
        student_id: item.student_id,
      })
    );
  };
  const getElementFromMultipleBooksArray = (checkId: number) => {
    const resArray: studentRecepit[] = [...itemsVoBookDescSet].reduce(
      (acc: studentRecepit[], v_book_desc) => {
        let fileteredBasedOnVDesc = items.filter(
          (item) =>
            item.acct_ldgr_details.voucher_book_details?.vo_book_desc ===
            v_book_desc
        );
        acc = [...acc, ...fileteredBasedOnVDesc];
        return acc;
      },
      []
    );
    const foundElement = resArray.find(({ id }) => id === checkId);
    const indexOfElement = foundElement ? resArray.indexOf(foundElement) : -1;
    const returnEle = foundElement ? resArray.indexOf(foundElement) : 0;

    return {
      nextElement:
        resArray.length > returnEle + 1 && resArray[returnEle + 1]
          ? resArray[returnEle + 1]
          : null,
      previousElement:
        resArray.length > returnEle - 1 && resArray[returnEle - 1]
          ? resArray[returnEle - 1]
          : null,
      elementIndex: indexOfElement,
      multipleBooksArray: resArray,
      length: resArray.length,
    };
  };

  const studentDemandDetailsmemoizedData = useMemo(() => {
    if (StudentDemandDetails.data && !StudentDemandDetails.loading) {
      return mapStudentDemandDetails(StudentDemandDetails.data);
    }
    return items;
    // eslint-disable-next-line
  }, [StudentDemandDetails.data, StudentDemandDetails.loading]);
  useEffect(() => {
    setItems(
      studentDemandDetailsmemoizedData.filter(
        ({ isDepositLedger }) => !isDepositLedger
      )
    );
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== EMPTY_STRING
    ) {
      const studentProfiePicUrl = `${
        InstDetails.data?.nodes[0]?.inst_name
      }/students/${
        state.studentId ? state.studentId : studentId
      }/std_profile_pic/std_profile_pic`;

      getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [
    studentDemandDetailsmemoizedData,
    state.studentId,
    studentId,
    studentData.data,
    InstDetails.data,
  ]);

  const studentDepositMemoizedData = useMemo(() => {
    if (StudentDepositData.data && !StudentDepositData.loading) {
      return StudentDepositData.data?.GetAcctStdDeposits.edges;
    }
    return depositItems;
    // eslint-disable-next-line
  }, [StudentDepositData.data]);

  const voucherKeysMemo = useMemo(() => {
    if (
      StudentDemandDetails.data &&
      !StudentDemandDetails.loading &&
      state.studentId
    ) {
      return [
        ...new Set(
          StudentDemandDetails.data.GetAcctStdDemandDetails.filter(
            (item) => item !== null
          ).map(
            (demand) =>
              demand.acct_ldgr_details.voucher_book_details?.vo_book_key
          )
        ),
      ] as string[];
    }
    return [];
  }, [
    StudentDemandDetails.data,
    StudentDemandDetails.loading,
    state.studentId,
  ]);
  const voucherNumbers = useMemo(() => {
    if (
      isMultipleBillook &&
      voucherNumber.data &&
      !voucherNumber.loading &&
      state.studentId
    ) {
      return isMultipleBillook
        ? voucherNumber.multipleVoucherNumber
        : [voucherNumber.data.GetVoucherNumber.vo_number];
    }
    return [];
  }, [
    isMultipleBillook,
    voucherNumber.data,
    voucherNumber.loading,
    voucherNumber.multipleVoucherNumber,
    state.studentId,
  ]);

  useEffect(() => {
    setVoucherKeys(voucherKeysMemo);
    setVoucherNumbers(voucherNumbers as VDetails[]);
  }, [voucherKeysMemo, voucherNumbers]);

  useEffect(() => {
    if (studentDepositMemoizedData) {
      if (studentDepositMemoizedData.length > 1) {
        setDepositItems(studentDepositMemoizedData);
        if (state.studentId) {
          setDepositSelectModal(true);
        }
      }
      if (studentDepositMemoizedData.length === 1) {
        setFilteredDepositItems(studentDepositMemoizedData[0]);
        setReceivedAmount(studentDepositMemoizedData[0].node.deposit_bal);
      }
    } // eslint-disable-next-line
  }, [studentDepositMemoizedData, state.studentId]);

  useEffect(() => {
    if (serverDateData && !serverDateLoading) {
      setRcptDate(serverDateData.GetServerDateAndTime);
    }
  }, [serverDateData, serverDateLoading]);

  const { branchLabel, classLabel } = useInstLabels();

  useEffect(() => {
    if (items.length) {
      setVoucherKeys([
        ...new Set(items.map((item) => item.vo_key)),
      ] as string[]);
    }
  }, [items]);

  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}

      <div className="row g-0">
        <div className="col">
          <Title>Academic Fee Receipt - Adjustments from Fee Deposits</Title>
        </div>

        <div className="configuration-settings">
          {pageType === PageFor.GENERAL ? (
            <>
              {USE_CONFIG_KEY && (
                <img
                  src={Settings}
                  alt="/"
                  id="settings-icon"
                  onClick={() => setConfigurationModal(!configurationModal)}
                />
              )}
            </>
          ) : (
            <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
          )}
        </div>
      </div>

      <div
        className={
          pageType === PageFor.GENERAL
            ? "student-fee-receipt"
            : "student-fee-receipt--modal"
        }
      >
        <div className="student-fee-receipt__details">
          <div className="row g-0 student-fee-receipt__details--select-options">
            <div className="col student-fee-receipt__frame">
              <div className="student-fee-receipt__frame--imagefield">
                {selectStudent ? (
                  <Autocomplete
                    classes={listClasses}
                    options={studentAddmissionNumber}
                    value={
                      state.studentId
                        ? studentAddmissionNumber?.find(
                            ({ value }) => value === state.studentId
                          )!
                        : null
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: (newValue as responseType).value,
                          },
                        });
                      } else {
                        setDepositItems([]);
                        handleClear();
                      }
                    }}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER) {
                        if (state.studentId) {
                          receivedAmountRef.current?.select();
                          receivedAmountRef.current?.focus();
                        }
                      }
                      if (e.key === Keys.BACKSPACE) {
                        dispatch({
                          type: payloadTypes.SET_STUDENT_ID,
                          payload: {
                            studentId: 0,
                          },
                        });
                      }
                    }}
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => setAdmNo(e.target.value)}
                        label="Admission Number"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        classes={{ root: listTextClasses.formControlRoot }}
                      />
                    )}
                  />
                ) : (
                  <TextField
                    label=" Admission Number"
                    className="student-fee-receipt__frame--textfield"
                    InputLabelProps={{ shrink: true }}
                    value={studentFormData?.adm_no}
                    disabled
                  />
                )}
                {pageType === PageFor.GENERAL ? (
                  <img
                    className="data-fetch-icon"
                    src={Edit}
                    alt="/"
                    onClick={() => setStudentModal(!studentModal)}
                  />
                ) : null}
              </div>

              <TextField
                label="Reg No."
                className="student-fee-receipt__frame--textfield"
                InputLabelProps={{ shrink: true }}
                value={studentFormData?.reg_number}
                disabled
              />
              <TextField
                label={classLabel}
                className="student-fee-receipt__frame--textfield"
                InputLabelProps={{ shrink: true }}
                value={studentFormData?.class}
                disabled
              />
            </div>

            <div className="col student-fee-receipt__frame g-0">
              <TextField
                label="Name"
                className="student-fee-receipt__frame--textfield"
                InputLabelProps={{ shrink: true }}
                value={studentFormData?.std_name}
                disabled
              />
              <TextField
                label="Father Name"
                className="student-fee-receipt__frame--textfield"
                InputLabelProps={{ shrink: true }}
                value={studentFormData?.father_name}
                disabled
              />
              <TextField
                label={branchLabel}
                className="student-fee-receipt__frame--textfield"
                InputLabelProps={{ shrink: true }}
                value={studentFormData?.branch}
                disabled
              />
            </div>
            <div className="col student-fee-receipt__frame g-0">
              <TextField
                type="date"
                label="Date"
                className="student-fee-receipt__frame--textfield--date"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: {
                    min: state.ActiveFinYr
                      ? toInputStandardDate(state.ActiveFinYr.fin_st_date)
                      : EMPTY_STRING,
                    max: toInputStandardDate(TODAY_DATE),
                  },
                }}
                value={toInputStandardDate(rcptDate)}
                onChange={(e) => setRcptDate(e.target.value)}
                disabled={editDate ? false : true}
              />
              <Label variant="present-day">{NameOfTheDay(rcptDate)}</Label>

              {USE_CATEGORY_KEY && (
                <TextField
                   label={categoryLabel}
                  className="student-fee-receipt__frame--textfield"
                  InputLabelProps={{ shrink: true }}
                  value={studentFormData?.category}
                  disabled
                />
              )}
              <div className="student-fee-receipt__label-gridtrio">
                <Input
                  id="receivedamount"
                  pattern="[0-9]*"
                  placeholder="Amount"
                  type="number"
                  inputRef={receivedAmountRef}
                  value={receivedAmount}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (Number(e.target.value) >= 0) {
                      setReceivedAmount(Number(e.target.value));
                      setUpdatedReceviedAmount(Number(e.target.value));
                    }
                  }}
                  onKeyDown={(event: React.KeyboardEvent) => {
                    if (event.key === Keys.ENTER) {
                      handleSplittingAmount();
                    }
                  }}
                />
                <Button mode="received" onClick={handleSplittingAmount} />
              </div>
            </div>
            <div className="col-1 student-fee-receipt__frame--image h-100">
              {imageString === EMPTY_STRING ? (
                <img src={Avatar} alt="/" />
              ) : (
                <img src={imageString} alt="/" />
              )}
            </div>
          </div>

          <div
            className={
              pageType === PageFor.GENERAL
                ? "row g-0 student-fee-receipt__tableblock"
                : "row g-0 student-fee-receipt__tableblock--modal"
            }
          >
            <TableContainer className="student-fee-receipt__table g-0">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Accounts_Table.Receipts.StudentFeeReceipt.Table_Headers.map(
                      (th: AccountsTableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName === "Receipt Amt."
                              ? "Adjusted Amt."
                              : th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isMultipleBillook
                    ? [...itemsVoBookDescSet].map((v_book_desc, index) => {
                        return (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell
                                className="student-fee-receipt__table--slno"
                                colSpan={10}
                              >
                                <b>{v_book_desc}</b>
                              </TableCell>
                            </TableRow>
                            {items
                              .filter(
                                (item) =>
                                  item.acct_ldgr_details.voucher_book_details
                                    ?.vo_book_desc === v_book_desc
                              )
                              .map((v_item, ldgr_index) => {
                                return (
                                  <TableRow key={ldgr_index}>
                                    <TableCell
                                      className="student-fee-receipt__table--slno"
                                      align="center"
                                    >
                                      {ldgr_index + 1}
                                    </TableCell>
                                    <TableCell className="student-fee-receipt__table--desc">
                                      {v_item.acct_ldgr_details.ldgr_desc}
                                    </TableCell>
                                    <TableCell
                                      id="td-right"
                                      className="student-fee-receipt__table--amount"
                                    >
                                      {toStandardDate(
                                        v_item.fee_due_date &&
                                          v_item.fee_due_date !== DEFAULT_TIME
                                          ? v_item.fee_due_date
                                          : "-"
                                      )}
                                    </TableCell>
                                    <TableCell
                                      id="td-right"
                                      className="student-fee-receipt__table--amount"
                                    >
                                      {format(v_item.fee_ob)}
                                    </TableCell>
                                    <TableCell
                                      id="td-right"
                                      className="student-fee-receipt__table--amount"
                                    >
                                      {format(v_item.fee_demand)}
                                    </TableCell>
                                    <TableCell
                                      id="td-right"
                                      className="student-fee-receipt__table--amount"
                                    >
                                      {format(v_item.fee_concession)}
                                    </TableCell>
                                    <TableCell
                                      id="td-right"
                                      className="student-fee-receipt__table--amount"
                                    >
                                      {format(v_item.fee_receivable)}
                                    </TableCell>
                                    <TableCell
                                      id="td-right"
                                      className="student-fee-receipt__table--amount"
                                    >
                                      {format(v_item.fee_received)}
                                    </TableCell>
                                    <TableCell
                                      onClick={() => {
                                        if (
                                          v_item.acct_ldgr_details
                                            .ldgr_std_fee_is_deposit_ldgr ===
                                          false
                                        ) {
                                          if (receivedAmount > 0) {
                                            if (v_item.rcpt_amt > 0) {
                                              onEdit(
                                                v_item.id!,
                                                v_item.rcpt_amt
                                              );
                                            }
                                            if (v_item.rcpt_amt === 0) {
                                              onEdit(v_item.id!, 0);
                                            }
                                          }
                                        } else {
                                          setMessage({
                                            flag: true,
                                            message:
                                              "Cannot Adjust to deposits",
                                            operation: Operation.NONE,
                                          });
                                        }
                                      }}
                                      id="td-right"
                                    >
                                      {inEditMode.status &&
                                      inEditMode.rowKey === v_item.id &&
                                      v_item.acct_ldgr_details
                                        .ldgr_std_fee_is_deposit_ldgr ===
                                        false ? (
                                        <input
                                          autoFocus
                                          id="td-right"
                                          type="number"
                                          className="totalcountcell"
                                          value={UpdatedReceviedAmount}
                                          onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                          ) => {
                                            if (Number(e.target.value) >= 0)
                                              setUpdatedReceviedAmount(
                                                Number(e.target.value)!
                                              );
                                          }}
                                          onKeyDown={(
                                            event: React.KeyboardEvent
                                          ) => {
                                            if (event.key === Keys.ENTER) {
                                              const {
                                                nextElement,
                                                elementIndex,
                                              } =
                                                getElementFromMultipleBooksArray(
                                                  v_item.id!
                                                );

                                              if (nextElement) {
                                                onEdit(
                                                  nextElement.id!,
                                                  nextElement.rcpt_amt
                                                );
                                                HandleEditItem(v_item.id!);
                                              } else {
                                                onEdit(
                                                  items[elementIndex].id!,
                                                  items[elementIndex].rcpt_amt
                                                );
                                                HandleEditItem(v_item.id!);

                                                setInEditMode({
                                                  rowKey: -805,
                                                  status: false,
                                                });
                                                transactionRefNumberRef.current?.focus();
                                              }
                                            }
                                          }}
                                        />
                                      ) : (
                                        format(v_item.rcpt_amt)
                                      )}
                                    </TableCell>
                                    <TableCell
                                      id="td-right"
                                      className="balance-amount student-fee-receipt__table--amount"
                                    >
                                      {format(v_item.fee_bal)}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </React.Fragment>
                        );
                      })
                    : items.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              className="student-fee-receipt__table--slno"
                              align="center"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell className="student-fee-receipt__table--desc">
                              {item.acct_ldgr_details.ldgr_desc}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="student-fee-receipt__table--amount"
                            >
                              {toStandardDate(
                                item.fee_due_date &&
                                  item.fee_due_date !== DEFAULT_TIME
                                  ? item.fee_due_date
                                  : "-"
                              )}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="student-fee-receipt__table--amount"
                            >
                              {format(item.fee_ob)}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="student-fee-receipt__table--amount"
                            >
                              {format(item.fee_demand)}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="student-fee-receipt__table--amount"
                            >
                              {format(item.fee_concession)}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="student-fee-receipt__table--amount"
                            >
                              {format(item.fee_receivable)}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="student-fee-receipt__table--amount"
                            >
                              {format(item.fee_received)}
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                if (
                                  item.acct_ldgr_details
                                    .ldgr_std_fee_is_deposit_ldgr === false
                                ) {
                                  if (receivedAmount > 0) {
                                    if (item.rcpt_amt > 0) {
                                      onEdit(item.id!, item.rcpt_amt);
                                    }
                                    if (item.rcpt_amt === 0) {
                                      onEdit(item.id!, 0);
                                    }
                                  }
                                } else {
                                  setMessage({
                                    flag: true,
                                    message: "Cannot Adjust to Deposits",
                                    operation: Operation.NONE,
                                  });
                                }
                              }}
                              id="td-right"
                            >
                              {inEditMode.status &&
                              inEditMode.rowKey === item.id &&
                              item.acct_ldgr_details
                                .ldgr_std_fee_is_deposit_ldgr === false ? (
                                <input
                                  autoFocus
                                  id="td-right"
                                  type="number"
                                  className="totalcountcell"
                                  value={UpdatedReceviedAmount}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (Number(e.target.value) >= 0)
                                      setUpdatedReceviedAmount(
                                        Number(e.target.value)!
                                      );
                                  }}
                                  onKeyDown={(event: React.KeyboardEvent) => {
                                    if (event.key === Keys.ENTER) {
                                      if (
                                        items[index + 1] &&
                                        items[index + 1].id
                                      ) {
                                        onEdit(
                                          items[index + 1].id!,
                                          items[index + 1].rcpt_amt
                                        );
                                        HandleEditItem(item.id!);
                                      } else {
                                        onEdit(
                                          items[index].id!,
                                          items[index].rcpt_amt
                                        );
                                        HandleEditItem(item.id!);

                                        setInEditMode({
                                          rowKey: -805,
                                          status: false,
                                        });
                                        transactionRefNumberRef.current?.focus();
                                      }
                                    }
                                  }}
                                />
                              ) : (
                                format(item.rcpt_amt)
                              )}
                            </TableCell>
                            <TableCell
                              id="td-right"
                              className="balance-amount student-fee-receipt__table--amount"
                            >
                              {format(item.fee_bal)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={3} className="total">
                      Total :
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totalOutStandingBalance)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totalDemandAmount)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totalconcession)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totalPayable)}
                    </TableCell>
                    <TableCell id="td-right" className="totalcount">
                      {format(totalpaidAmount)}
                    </TableCell>

                    <TableCell id="td-right" className="totalcount">
                      {format(totaldepositAmount)}
                    </TableCell>
                    <TableCell id="td-right" className="balance-count">
                      {format(totalbalance)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
          <div
            className={
              pageType === PageFor.GENERAL
                ? "row g-0 student-fee-receipt__receiptblock"
                : "row g-0 student-fee-receipt__receiptblock--modal"
            }
          >
            <div className="col-6 student-fee-receipt__receiptblock--frame">
              <div className="label-grid">
                <Label>Receipt No.</Label>
                <Input
                  disabled
                  value={
                    isMultipleBillook
                      ? voucherNumber.multipleVoucherNumber.length
                        ? voucherNumber.multipleVoucherNumber
                            .map((data) => data.vo_number)
                            .join(", ")
                        : EMPTY_STRING
                      : voucherNumber.data?.GetVoucherNumber.vo_number ??
                        EMPTY_STRING
                  }
                />
                <Label> Adjusted from</Label>
                <Input
                  disabled
                  value={
                    filteredDepositItems?.node?.acct_ldgr_details.ldgr_desc ??
                    EMPTY_STRING
                  }
                />

                <Label>Advance Ack No.</Label>
                <Input
                  inputRef={transactionRefNumberRef}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setReferenceNumber(e.target.value)
                  }
                  value={referenceNumber}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      remarksRef.current?.focus();
                    }
                  }}
                />
              </div>
            </div>
            <div className="col student-fee-receipt__receiptblock--frame label-grid">
              <Label>Remarks :</Label>
              <TextArea
                rows={3}
                textAreaRef={remarksRef}
                value={narration}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    saveButtonRef.current?.focus();
                  }
                }}
                onChange={(e) => setNarration(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row student-fee-receipt__buttons">
        <div className="col">
          <Button
            mode="save"
            disabled={!items.length || totaldepositAmount === 0}
            onClick={handleStudentReceipts}
            buttonref={saveButtonRef}
          />

          <Button
            mode="clear"
            onClick={handleClear}
            disabled={!state.studentId}
          />

          {pageType === PageFor.GENERAL ? (
            <Button mode="back" onClick={() => navigate(-1)} />
          ) : (
            <Button mode="back" onClick={() => setModalFlag(false)} />
          )}
        </div>
      </div>

      {/* studentmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.FEE_ADVANCE}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={feeLedgerModal}
        ariaHideApp={false}
        style={EditModalCustomStyles}
      >
        <FeeLedger
          pageFor={PageFor.MODAL}
          setFeeledgerModal={setFeeledgerModal}
        />
      </Modal>
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.STUDENT_RECEIPT_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={GenerateStudentReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={multipleReceiptsModal}
        style={DeleteLedgerModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MultipleReceipts items={multiplereceipts} type={NonDemand.ROLE} />
            <Button
              mode="cancel"
              onClick={() => {
                setMultipleRecepitsModal(!multipleReceiptsModal);
                handleClear();
              }}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                setMultipleRecepitsModal(!multipleReceiptsModal);
                handleClear();
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={depositSelectModal}
        style={DepositModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title variant="subtitle1"> Student Fee Advance List</Title>
            <hr className="dashed" />
            <span>
              {depositItems.map((depositItem) => {
                return (
                  <ul>
                    <li
                      key={depositItem?.node.id}
                      className="student-fee-receipt__advance-fee-modal"
                      onClick={() => {
                        handleFilterDepositDetails(
                          depositItem?.node.acct_ldgr_details.id
                        );
                      }}
                    >
                      <Label>
                        {depositItem?.node?.acct_ldgr_details.ldgr_desc}
                      </Label>

                      <span className="student-fee-receipt__advance-fee-modal--amount">
                        {depositItem?.node?.deposit_bal}
                      </span>
                    </li>
                  </ul>
                );
              })}
            </span>
            <hr />
            <Label variant="LabelPrimary">
              Select the fee ledger through which adjustment of fees should be
              done.
            </Label>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setDepositSelectModal(!depositSelectModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <FeeReceiptPrint
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              transaction_no={referenceNumber}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                if (enableReceiptPrintByDefault) SetPrintModal(!printModal);
                dispatch({
                  type: payloadTypes.SET_STUDENT_ID,
                  payload: { studentId: 0 },
                });
                handleClear();
              }}
            />
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>

      {/* print configuration modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printConfig}
        style={PrintConfigModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PerModuleConfiguration
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.RECEIPT_PRINT}
              int_value={0}
              setModalFlag={setPrintConfigModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Adjustments;
