import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";

import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";

import Enter from "../../../../images/Enter.svg";
import Edit from "../../../../images/EditProfile.svg";
import DataFetch from "../../../../images/Edit.svg";
import Delete from "../../../../images/Delete.svg";
import Avatar from "../../../../images/Avatar.svg";
import Close from "../../../../images/Close.svg";

import Home from "../../Home/Index";
import MessageModal from "../../../../pages/MessageModal";
import EmployeeList from "../../Employee/List/Index";

import { TableHeaderProps } from "../../../../Types/Tables";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import { Gender, Operation, PageFor } from "../../../../utils/Enum.types";

import useEmployee, { empQueryTypes } from "../../hooks/useEmployee";
import useEmpDetailsById from "../../hooks/useEmpDetailsById";
import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";
import useLeaveLedgersByInstId from "../../hooks/useLeaveLedgersByInstId";
import usePayRollActiveAcademicYear from "../../hooks/usePayRollActiveAcademicYear";
import useToken from "../../../../customhooks/useToken";

import { msgType, responseType } from "../../../../utils/Form.types";
import { Keys } from "../../../../utils/Enum.keys";
import { clearType } from "../../enums/Enum.types";

import {
  AddOrUpdateOrDeletePayRollEmpLeaveLdgrs,
  DeletePayRollEmpLeavesMasterAndDetails,
} from "../../queries/leaveledger/mutation";

import {
  EMPTY_RESPONSETYPE_OBJECT,
  INPUT,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import { toInputStandardDate } from "../../../../utils/UtilFunctions";
import useEmpLeaveMaster from "../../hooks/useEmpLeaveMaster";
import LoadingModal from "../../../../pages/LoadingModal";
import DeleteModal from "../../../../pages/DeleteModal";
import { Actions } from "../../constants";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { HR_Table } = require("../../json/table.json");
interface itemsType extends responseType {
  leaves_availed: number;
  newAdded: boolean;
  updated: boolean;
}

const AssignLeaveLedger = () => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const listClasses = ListAutoCompleteStyles();
  const listTextClasses = ListAutoCompleteTextStyles();
  const { state, dispatch } = useContext(AppContext);
  const { InstId } = useParams();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const navigate = useNavigate();
  const [empModal, setEmpModal] = useState(false);
  const [operation, setOperation] = useState(Operation.UPDATE);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [enableEdit, setEnableEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  // eslint-disable-next-line
  const [searchLeave, setSearchLeave] = useState("");

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [leaves_availed, set_leaves_availed] = useState(0);
  const [items, setItems] = useState<itemsType[]>([]);
  const [deletedItems, setDeletedItems] = useState<itemsType[]>([]);
  const [itemIndex, setItemIndex] = useState(0);
  const [leaveLedger, setLeaveLedger] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );

  //useRefs
  const leaveLedgerRef = useRef<HTMLSelectElement>(null);
  const leaveLedgerInputRef = leaveLedgerRef.current?.getElementsByTagName(
    INPUT
  )[0] as HTMLInputElement;

  const numofLeavesRef = useRef<HTMLInputElement>(null);
  const { empDetails } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.EMPS_WITH_ASSIGNED_LEAVES,
    searchEmployee,
    state.employeeId
  );

  const { employeeFormData } = useEmpDetailsById();

  const { payRollActiveAcademicYear } = usePayRollActiveAcademicYear();

  const { id: activePayRollId } =
    payRollActiveAcademicYear.data?.GetPayRollAcdYrActiveByInstId || {};
  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();
  const {
    leaveLedgers: { responseType: leaveLedgerOptions },
  } = useLeaveLedgersByInstId(
    searchLeave,
    ROWS_PER_PAGE,
    employeeFormData.emp_sex.toUpperCase() === Gender.MALE
  );
  const { USE_PAYROLL } = useUserRightsByEmpId();

  const { EmpLeaveData } = useEmpLeaveMaster(activePayRollId!);

  const [UpdateEmpLeaveLedger, { loading: updationLoading }] = useMutation(
    AddOrUpdateOrDeletePayRollEmpLeaveLdgrs,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteEmpLeaveLedger, { loading: deletionLoading }] = useMutation(
    DeletePayRollEmpLeavesMasterAndDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const itemsArraySet = useMemo(() => {
    return new Set(items.map(({ value }) => value));
  }, [items]);

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear(clearType.CLEAR_ALL);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const deleteItem = (id: number) => {
    setItems(items.filter(({ value }) => value !== id));

    //Once the operation is Update we need to push the items deleted
    if (operation === Operation.UPDATE) {
      setDeletedItems(items.filter(({ value }) => value === id));
    }
  };
  const handleItem = () => {
    if (enableEdit) {
      items[itemIndex].label = leaveLedger.label;
      items[itemIndex].value = leaveLedger.value;
      items[itemIndex].leaves_availed = leaves_availed;
      if (operation === Operation.UPDATE) {
        items[itemIndex].updated = true;
      }
      setEnableEdit(!enableEdit);
    } else if (leaveLedger.value && leaves_availed) {
      setItems((prevValues) => [
        ...prevValues,
        {
          ...leaveLedger,
          leaves_availed,
          id: 0,
          newAdded: operation === Operation.UPDATE ? true : false,
          updated: false,
        },
      ]);
    } else if (!leaveLedger.value) {
      setMessage({
        message: "Please Select Leave Ledger",
        flag: true,
        operation: Operation.NONE,
      });
      leaveLedgerInputRef.focus();
      return;
    } else if (!leaves_availed) {
      setMessage({
        message: "Total Number of leaves cannot be zero",
        flag: true,
        operation: Operation.NONE,
      });
      numofLeavesRef.current?.select();
      return;
    }
    leaveLedgerInputRef.focus();
    setLeaveLedger(EMPTY_RESPONSETYPE_OBJECT);
    set_leaves_availed(0);
  };
  const handleEditItem = (item: itemsType) => {
    setLeaveLedger({
      label: item.label,
      value: item.value,
    });
    set_leaves_availed(item.leaves_availed);
  };

  const handleClear = (type: clearType) => {
    setLeaveLedger(EMPTY_RESPONSETYPE_OBJECT);
    set_leaves_availed(0);
    dispatch({
      type: payloadTypes.SET_EMPLOYEE_ID,
      payload: { employeeId: 0 },
    });
    if (type === clearType.CLEAR_ALL) {
      setItems([]);
    }
  };
  const handleSubmit = () => {
    UpdateEmpLeaveLedger({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        pr_emp_id: state.employeeId,
        pr_acd_yr_id: activePayRollId!,
        input: {
          update_leave_details: items
            .filter(({ updated }) => updated)
            .map(({ leaves_availed, value }) => ({
              leaves_availed: leaves_availed,
              pr_leave_ldgr_id: value,
            })),
          add_leave_details: items
            .filter(({ newAdded }) => newAdded)
            .map(({ leaves_availed, value }) => ({
              leaves_availed: leaves_availed,
              pr_leave_ldgr_id: value,
            })),
          delete_leave_details: deletedItems.map(({ value }) => ({
            pr_leave_ldgr_id: value,
          })),
        },
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Leave Ledger Updated SuccessFully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const handleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteEmpLeaveLedger({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        pr_acd_yr_id: activePayRollId!,
        pr_emp_id: id,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Leave Ledger Deleted SuccessFully",
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
  };
  useEffect(() => {
    if (EmpLeaveData.data && !EmpLeaveData.loading) {
      setItems(
        EmpLeaveData.data.GetPayRollEmpLeaveMasterDetails.leave_details.map(
          ({ leaves_availed, leave_ldgr_details, id }) => ({
            label: leave_ldgr_details.leave_desc,
            value: leave_ldgr_details.id,
            leaves_availed: leaves_availed,
            id,
            newAdded: false,
            updated: false,
          })
        )
      );
    }
  }, [EmpLeaveData.data, EmpLeaveData.loading, operation]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Assign Leave Ledger</Title>
      <div className="emp-assign-leave-ledger">
        <div className="row g-0 emp-assign-leave-ledger__options">
          <div className="col">
            <div className="emp-assign-leave-ledger__options--imageflex">
              <Autocomplete
                classes={listClasses}
                options={empDetails.responseType}
                openOnFocus
                value={
                  empDetails.responseType.find(
                    ({ value }) => value === state.employeeId
                  ) ?? EMPTY_RESPONSETYPE_OBJECT
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: (newValue as responseType).value },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: 0 },
                    });
                    setSearchEmployee("");
                    handleClear(clearType.CLEAR_ALL);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: 0 },
                    });
                  }
                  if (e.key === Keys.ENTER) {
                    leaveLedgerInputRef.focus();
                  }
                }}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Employee ID"
                    autoFocus
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchEmployee(e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: listTextClasses.formControlRoot }}
                  />
                )}
              />
              <img
                className="data-fetch-icon"
                src={DataFetch}
                alt="/"
                onClick={() => setEmpModal(!empModal)}
              />
            </div>
            {USE_HR_DESIGNATION && (
              <TextField
                label="Designation"
                InputLabelProps={{ shrink: true }}
                disabled
                value={employeeFormData?.designation}
                className="emp-assign-leave-ledger__textfield"
              />
            )}
            {USE_HR_JOBTYPE && (
              <TextField
                label="Job Type"
                InputLabelProps={{ shrink: true }}
                disabled
                value={employeeFormData?.job_type}
                className="emp-assign-leave-ledger__textfield"
              />
            )}
          </div>
          <div className="col">
            <TextField
              label="Name"
              InputLabelProps={{ shrink: true }}
              disabled
              value={employeeFormData?.emp_name}
              className="emp-assign-leave-ledger__textfield"
            />
            {USE_HR_DEPARTMENT && (
              <TextField
                label="Department"
                InputLabelProps={{ shrink: true }}
                disabled
                value={employeeFormData?.department}
                className="emp-assign-leave-ledger__textfield"
              />
            )}
            <TextField
              label="Qualification"
              InputLabelProps={{ shrink: true }}
              disabled
              value={employeeFormData?.emp_qualification}
              className="emp-assign-leave-ledger__textfield"
            />
          </div>
          <div className="col">
            {USE_HR_CATEGORY && (
              <TextField
                 label="Category"
                InputLabelProps={{ shrink: true }}
                value={employeeFormData?.category}
                disabled
                className="emp-assign-leave-ledger__textfield"
              />
            )}
            <TextField
              label="Year of Experience"
              InputLabelProps={{ shrink: true }}
              value={employeeFormData?.emp_experience}
              disabled
              className="emp-assign-leave-ledger__textfield"
            />
            <TextField
              label="Date of Join"
              InputLabelProps={{ shrink: true }}
              value={toInputStandardDate(employeeFormData?.emp_doj!)}
              disabled
              type="date"
              className="emp-assign-leave-ledger__textfield--date"
            />
          </div>
          <div className="col-1 h-100 emp-assign-leave-ledger__profile">
            <img src={Avatar} alt="/" />
          </div>
        </div>
        <div className="emp-assign-leave-ledger__tableblock">
          <TableContainer className="emp-assign-leave-ledger__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {HR_Table.AssignLeaveLedger.Table_Headers.filter(
                    (th: TableHeaderProps) =>
                      (th.labelName !== Actions &&
                        (operation === Operation.DELETE ||
                          operation === Operation.VIEW)) ||
                      operation === Operation.UPDATE
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {operation === Operation.UPDATE && state.employeeId ? (
                  <TableRow className="emp-assign-leave-ledger__row">
                    <TableCell
                      className="emp-assign-leave-ledger__table--slno"
                      id="td-center"
                    ></TableCell>
                    <TableCell>
                      {enableEdit ? (
                        `${leaveLedger.label} ${leaveLedger.value}`
                      ) : (
                        <Autocomplete
                          classes={classes}
                          value={leaveLedger}
                          options={leaveLedgerOptions.filter(
                            (leave) =>
                              !itemsArraySet.has(leave.value) ||
                              (leave.value === leaveLedger.value && enableEdit)
                          )}
                          ref={leaveLedgerRef!}
                          onChange={(e, newValue) => {
                            if (newValue) {
                              setLeaveLedger(newValue as responseType);
                            } else {
                              setLeaveLedger(EMPTY_RESPONSETYPE_OBJECT);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === Keys.BACKSPACE) {
                              setLeaveLedger(EMPTY_RESPONSETYPE_OBJECT);
                            }
                            if (e.key === Keys.ENTER && leaveLedger.value) {
                              numofLeavesRef.current?.focus();
                            }
                          }}
                          openOnFocus
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              classes={{
                                root: textClasses.formControlRoot,
                              }}
                            />
                          )}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      className="emp-assign-leave-ledger__table--number"
                      id="td-center"
                    >
                      <input
                        className="storybook-input"
                        type="number"
                        value={leaves_availed}
                        ref={numofLeavesRef!}
                        onFocus={(e) => e.target.select()}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            handleItem();
                          }
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          set_leaves_availed(Number(e.target.value))
                        }
                      />
                    </TableCell>

                    <TableCell
                      className="emp-assign-leave-ledger__table--actions"
                      id="td-center"
                    >
                      <img
                        src={Enter}
                        onClick={handleItem}
                        alt="/"
                        className="emp-assign-leave-ledger__table--enter-image"
                      />
                    </TableCell>
                  </TableRow>
                ) : null}

                {state.employeeId
                  ? items.map((leave, index) => {
                      return (
                        <TableRow>
                          <TableCell
                            className="emp-assign-leave-ledger__table--slno"
                            id="td-center"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell>{leave.label}</TableCell>
                          <TableCell
                            className="emp-assign-leave-ledger__table--number"
                            id="td-center"
                          >
                            {leave.leaves_availed}
                          </TableCell>
                          {operation === Operation.UPDATE ? (
                            <TableCell
                              className="emp-assign-leave-ledger__table--actions"
                              id="td-center"
                            >
                              {USE_PAYROLL.details?.edit ? (
                                <img
                                  src={Edit}
                                  alt="/"
                                  onClick={() => {
                                    setEnableEdit(!enableEdit);
                                    handleEditItem(leave);
                                    setItemIndex(index);
                                  }}
                                />
                              ) : null}
                              {USE_PAYROLL.details?.delete ? (
                                <img
                                  src={Delete}
                                  alt="/"
                                  onClick={() => deleteItem(leave.value)}
                                />
                              ) : null}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {operation !== Operation.VIEW && (
          <Button
            mode="save"
            onClick={() => {
              if (operation === Operation.UPDATE) {
                handleSubmit();
              } else {
                setDeleteModal(!deleteModal);
              }
            }}
            disabled={!(items.length > 0 && state.employeeId)}
          />
        )}
        {operation === Operation.UPDATE ? (
          <>
            <Button
              mode="view"
              onClick={() => {
                handleClear(clearType.CLEAR_ALL);
                setOperation(Operation.VIEW);
              }}
            />
            <Button
              mode="delete"
              onClick={() => {
                handleClear(clearType.CLEAR_ALL);
                setOperation(Operation.DELETE);
              }}
            >
              {" "}
              Leave Ledger Association
            </Button>
            <Button
              mode="clear"
              onClick={() => handleClear(clearType.CLEAR_ALL)}
            />
          </>
        ) : null}

        <Button
          mode="back"
          onClick={() => {
            if (operation === Operation.UPDATE) {
              navigate(-1);
            } else {
              setOperation(Operation.UPDATE);
            }
            handleClear(clearType.CLEAR_ALL);
          }}
        />
      </div>

      {/* Employeemodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={empModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <EmployeeList
              pageType={PageFor.MODAL}
              setModalFlag={setEmpModal}
              queryType={empQueryTypes.GENERAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEmpModal(!empModal)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        value={message.message}
        operation={message.operation}
        modalFlag={message.flag}
        handleClose={handleClose}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDelete}
        id={state.employeeId}
      />
      <LoadingModal flag={updationLoading || deletionLoading} />
    </>
  );
};

export default AssignLeaveLedger;
