import React, { useContext, useEffect } from "react";
import { Title } from "../../../../stories/Title/Title";
import Close from "../../../../images/Close.svg";
import Avatar from "../../../../images/Avatar.svg";
import { TextField } from "@mui/material";
import Input from "../../../../stories/Input/Input";
import {
  DataGridPro,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DataGridTableStyles";
import { TableHeaders } from "../../../../utils/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { toStandardDate } from "../../../../utils/UtilFunctions";
import { useLazyQuery } from "@apollo/client";
import { GetAcdSubjectAllotedToStudent } from "../../../../queries/customallocation/combination/queries";
import {
  GetAcdSubjectAllotedToStudentData,
  GetAcdSubjectAllotedToStudentVars,
} from "../../../../Types/Combinations/queries";
import useToken from "../../../../customhooks/useToken";
import { AppContext } from "../../../../context/context";
import { useParams } from "react-router-dom";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
interface props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PerStudentDetails = ({ setModal }: props) => {
  const classes = useDataGridStyles();
  const {categoryLabel}=useInstLabels();
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const [GetSubjAllocatedForStudent, { data }] = useLazyQuery<
    GetAcdSubjectAllotedToStudentData,
    GetAcdSubjectAllotedToStudentVars
  >(GetAcdSubjectAllotedToStudent);
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "sub_name",
      headerName: TableHeaders.SUBJECT_DESCRIPTION,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sub-type",
      flex: 1,
    },
    {
      field: "sub_code",
      headerName: TableHeaders.SUBJECT_CODE,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sub-code",
    },
  ];

  // const rows: GridValidRowModel[] =
  //   (data ? data.GetAcdSubjectAllotedToStudent : []).map((response, index) => {
  //     return {
  //       id: index + 1,
  //       sub_code: response.subj_master_details.subj_code,
  //       sub_name: response.subj_master_details.subj_desc,
  //     };
  //   }) || [];

  const { studentFormData } = useStudentDatabyId();

  // useEffect(() => {
  //   if (token && state.studentId) {
  //     GetSubjAllocatedForStudent({
  //       variables: {
  //         acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
  //         inst_id: Number(InstId)!,
  //         student_id: state.studentId,
  //         token,
  //       },
  //     });
  //   }
  // }, [
  //   token,
  //   GetSubjAllocatedForStudent,
  //   InstId,
  //   state.ActiveAcdYr,
  //   state.studentId,
  // ]);
  return (
    <>
      <div className="sub-details__per-std">
        <div className="sub-details__per-std--title">
          <Title>Student Subject Details</Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
        <div className="sub-details__per-std--details row g-0">
          <div className="col">
            <TextField
              className="sub-details__per-std--textfield"
              disabled
              label="Admission No."
              value={studentFormData.adm_no}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className="sub-details__per-std--textfield"
              disabled
              label="Reg No."
              value={studentFormData.reg_number}
              InputLabelProps={{ shrink: true }}
            />{" "}
            <TextField
              className="sub-details__per-std--textfield"
              disabled
              label="DOB"
              value={toStandardDate(studentFormData.std_dob)}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="col">
            <TextField
              className="sub-details__per-std--textfield"
              disabled
              label="Student Name"
              value={studentFormData.std_name}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className="sub-details__per-std--textfield"
              disabled
              label="Father Name"
              value={studentFormData.father_name}
              InputLabelProps={{ shrink: true }}
            />{" "}
            <TextField
              className="sub-details__per-std--textfield"
              disabled
              label={categoryLabel}
              value={studentFormData.category}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="col-3 h-100 sub-details__per-std--std-profile">
            <img src={Avatar} alt="" />
          </div>
        </div>
        <Title variant="subtitle1">
          <b>Combination 1</b>
        </Title>

        <Input id="search" placeholder="Search..." />
        <div className={`sub-details__per-std--tableblock ${classes.root}`}>
          {/* <DataGridPro
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
          /> */}
        </div>
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
    </>
  );
};

export default PerStudentDetails;
