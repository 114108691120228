import React, { useEffect, useState } from "react";
import {
  ColumnVisibilityFor,
  Direction,
  PageFor,
  SortBy,
} from "../../../utils/Enum.types";
import Home from "../Home/Index";
import { Title } from "../../../stories/Title/Title";
import Enlarge from "../../../images/Enlarge.svg";
import {
  getModifiedScrollHeight,
  toggleFullSceen,
} from "../../../utils/UtilFunctions";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../styles/DataGridTableStyles";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridColumnVisibilityModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { StudentPageInfo } from "../../Academics/hooks/useAcdStudentsData";
import { useLazyQuery } from "@apollo/client";
import { GetAgents } from "../queries/Agents/List";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import Close from "../../../images/Close.svg";
interface Props {
  pageType: PageFor;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface AgentNode {
  id: number;
  agent_name: string;
  agent_address: string;
  agent_city: string;
  agent_state: string;
  agent_country: string;
  agent_mobile: string;
  agent_email: string;
  agent_pan_no: string;
  agent_aadhar_no: string;
  agent_gst_no: string;
  agent_bank_ac_no: string;
  agent_bank_ifsc: string;
  agent_bank_name: string;
  agent_bank_address: string;
  agent_bank_city: string;
  agent_bank_type: string;
}

export interface AgentsEdges {
  node: AgentNode;
}

export interface GetAgentsList {
  edges: AgentsEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}

export interface GetAgentsData {
  GetAgents: GetAgentsList;
}
export interface GetAgentsVars {
  token: string;
  inst_id: string;
  after: string | null;
  first: number | null;
  orderBy: {
    direction: Direction;
    field: SortBy.AGENT_NAME;
  };
  name: String;
}
const List = ({ pageType, setModal }: Props) => {
  const dataClasses = useDataGridStyles();

  const { InstId } = useParams();
  const { token } = useToken();

  const navigate = useNavigate();
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [searchAgent, setSearchAgent] = useState("");
  const [agenst, setAgents] = useState<AgentsEdges[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const [GetAcctAgents, { data, loading, fetchMore }] = useLazyQuery<
    GetAgentsData,
    GetAgentsVars
  >(GetAgents, {
    variables: {
      after: null,
      first: ROWS_PER_PAGE,
      inst_id: InstId!,
      name: searchAgent,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.AGENT_NAME,
      },
      token,
    },
  });

  useEffect(() => {
    if (token) {
      GetAcctAgents();
    }
  }, [token, GetAcctAgents, InstId, searchAgent]);

  const columns: GridColDef[] = [
    {
      headerName: "Sl",
      cellClassName: "td-sl-no",
      field: "id",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
    },
    {
      headerName: "Name",
      cellClassName: "td-name td-onClick",
      field: "agent_name",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
      flex: 1,
    },
    {
      headerName: "Address",
      cellClassName: "td-address",
      field: "agent_address",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
    {
      headerName: "City",
      cellClassName: "td-city",
      field: "agent_city",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
    {
      headerName: "State",
      cellClassName: "td-state",
      field: "agent_state",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
    {
      headerName: "Country",
      cellClassName: "td-country",
      field: "agent_country",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
    {
      headerName: "Mobile",
      cellClassName: "td-mobile",
      field: "agent_mobile",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
    {
      headerName: "Email",
      cellClassName: "td-email",
      field: "agent_email",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
    {
      headerName: "PAN No",
      cellClassName: "td-pan-no",
      field: "agent_pan_no",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
    {
      headerName: "Aadhar No",
      cellClassName: "td-aadhar-no",
      field: "agent_aadhar_no",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
    {
      headerName: "GST No",
      cellClassName: "td-gst-no",
      field: "agent_gst_no",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
    {
      headerName: "Bank Account No",
      cellClassName: "td-bank-ac-no",
      field: "agent_bank_ac_no",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
    {
      headerName: "Bank IFSC",
      cellClassName: "td-bank-ifsc",
      field: "agent_bank_ifsc",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
    {
      headerName: "Bank Name",
      cellClassName: "td-bank-name",
      field: "agent_bank_name",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
    {
      headerName: "Bank Address",
      cellClassName: "td-bank-address",
      field: "agent_bank_address",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
    {
      headerName: "Bank City",
      cellClassName: "td-bank-city",
      field: "agent_bank_city",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
    {
      headerName: "Bank Type",
      cellClassName: "td-bank-type",
      field: "agent_bank_type",
      headerAlign: HEADER_TEXT_ALIGN,
      align: "" as GridAlignment,
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      agent_address: false,
      agent_city: false,
      agent_state: false,
      agent_country: false,
      agent_gst_no: false,
      agent_bank_ac_no: false,
      agent_bank_ifsc: false,
      agent_bank_name: false,
      agent_bank_address: false,
      agent_bank_city: false,
      agent_bank_type: false,
    });
  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.FOR_AGENTS
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.FOR_AGENTS,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAgents.edges;
                const pageInfo = fetchMoreResult.GetAgents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAgents.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetAgents: {
                    edges: [...agenst, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAgents.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        // eslint-disable-next-line
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAgents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = agenst.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setAgents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            agent_name: node.agent_name,
            agent_address: node.agent_address,
            agent_city: node.agent_city,
            agent_state: node.agent_state,
            agent_country: node.agent_country,
            agent_mobile: node.agent_mobile,
            agent_email: node.agent_email,
            agent_pan_no: node.agent_pan_no,
            agent_aadhar_no: node.agent_gst_no,
            agent_gst_no: node.agent_gst_no,
            agent_bank_ac_no: node.agent_bank_ac_no,
            agent_bank_ifsc: node.agent_bank_ifsc,
            agent_bank_name: node.agent_bank_name,
            agent_bank_address: node.agent_bank_address,
            agent_bank_city: node.agent_bank_city,
            agent_bank_type: node.agent_bank_type,
          }))
        );
      } else {
        setAgents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            agent_name: node.agent_name,
            agent_address: node.agent_address,
            agent_city: node.agent_city,
            agent_state: node.agent_state,
            agent_country: node.agent_country,
            agent_mobile: node.agent_mobile,
            agent_email: node.agent_email,
            agent_pan_no: node.agent_pan_no,
            agent_aadhar_no: node.agent_gst_no,
            agent_gst_no: node.agent_gst_no,
            agent_bank_ac_no: node.agent_bank_ac_no,
            agent_bank_ifsc: node.agent_bank_ifsc,
            agent_bank_name: node.agent_bank_name,
            agent_bank_address: node.agent_bank_address,
            agent_bank_city: node.agent_bank_city,
            agent_bank_type: node.agent_bank_type,
          }))
        );
      }
      setEndCursor(data.GetAgents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [loading, data]);
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="agents-list__title">
          <Title>Agents</Title>
          {pageType === PageFor.MODAL && (
            <>
              <img src={Close} alt="" onClick={() => setModal(false)} />
            </>
          )}
        </div>
      <div
        className={
          pageType === PageFor.GENERAL ? "agents-list" : "agents-list--modal"
        }
      >
  

        <div className="row g-0">
          <div className="col-2">
            <Input
              onChange={(e) => {
                setSearchAgent(e.target.value);
              }}
              placeholder="Search Agent..."
              id="search"
            />
          </div>
        </div>
        <div className={`agents-list__tableblock ${dataClasses.root}`}>
          <DataGridPro
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            disableRowSelectionOnClick
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            hideFooter
            slotProps={{
              columnsPanel: { disableHideAllButton: true },
            }}
          />
        </div>
        {pageType === PageFor.MODAL ? (
          <Button mode="cancel" onClick={() => setModal(false)} />
        ) : (
          <Button mode="back" onClick={() => navigate(-1)} />
        )}
      </div>
    </>
  );
};

export default List;
