import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../../styles/DataGridTableStyles";
import {
  EMPTY_STRING,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../../../utils/constants";
import {
  AcdSubjectAllocationData,
  InstitutionConfigurationTypes,
  Operation,
  StudentReportType,
  TableHeaders,
} from "../../../../../utils/Enum.types";
import { Title } from "../../../../../stories/Title/Title";
import Close from "../../../../../images/Close.svg";

import Delete from "../../../../../images/Delete.svg";

import Input from "../../../../../stories/Input/Input";
import { Button } from "../../../../../stories/Button/Button";
import useStudentsByNodeIds from "../../../../../customhooks/useStudentsByNodeIds";
import { useMutation } from "@apollo/client";
import {
  AddAcdStudentComboDetails,
  DeleteAcdStudentComboDetails,
} from "../../../../../queries/customallocation/combination/mutations";
import {
  AddAcdStudentComboDetailsData,
  AddAcdStudentComboDetailsVars,
} from "../../../../../Types/Combinations/mutations";
import MessageModal from "../../../../../pages/MessageModal";
import useActiveAcademicYear from "../../../hooks/useActiveAcademicYear";
import useToken from "../../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";
import { ComboStdAssociData } from "../../../../../Types/Combinations/queries";
import useInstConfigByEntryId from "../../../hooks/useInstConfigByEntryId";
import useAcdStudentsData from "../../../hooks/useAcdStudentsData";
import DeleteModal from "../../../../../pages/DeleteModal";
interface props {
  setDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  selectedStudentIds: Set<number>;
  comboDetails: ComboStdAssociData | null;
}

const SelectedStudents = ({
  setDrawer,
  selectedStudentIds,
  comboDetails,
}: props) => {
  const { combinationId, InstId, allocationId } = useParams();

  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [searchStudent, setSearchStudent] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [message, setMessage] = useState(emptyMessageType);
  const [stdComboId, setStdComboId] = useState(0);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );

  const subjectAllocLevel = configData.data
    ? configData.data.GetSwConfigVariables[0].config_string_value
    : EMPTY_STRING;

  const {
    InstConfigDetails: { data: instDetailsData },
  } = useInstConfigByEntryId(allocationId!);

  const [AddAcdSubCombo] = useMutation<
    AddAcdStudentComboDetailsData,
    AddAcdStudentComboDetailsVars
  >(AddAcdStudentComboDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [DeleteAcdSubCombo] = useMutation(DeleteAcdStudentComboDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const classes = useDataGridStyles();

  const { data } = useStudentsByNodeIds(selectedStudentIds);

  const allocationLevel =
    configData.data && configData.data.GetSwConfigVariables.length
      ? configData.data.GetSwConfigVariables[0].config_string_value
      : EMPTY_STRING;
  const getAllotedId = () => {
    if (instDetailsData && configData) {
      switch (allocationLevel) {
        case AcdSubjectAllocationData.BRANCH:
          return instDetailsData.GetAcdInstConfigNames.acd_branch_id;
        case AcdSubjectAllocationData.CLASS:
          return instDetailsData.GetAcdInstConfigNames.acd_class_id;
        case AcdSubjectAllocationData.SEMESTER:
          return instDetailsData.GetAcdInstConfigNames.acd_semester_id;
        case AcdSubjectAllocationData.SECTION:
          return instDetailsData.GetAcdInstConfigNames.acd_section_id;
        default:
          return 0;
      }
    } else return 0;
  };

  const {
    AcademicsStudentData: { data: studentData },
  } = useAcdStudentsData(
    instDetailsData ? instDetailsData.GetAcdInstConfigNames.acd_dept_id : 0,
    instDetailsData ? instDetailsData.GetAcdInstConfigNames.acd_branch_id : 0,
    instDetailsData ? instDetailsData.GetAcdInstConfigNames.acd_class_id : 0,
    instDetailsData ? instDetailsData.GetAcdInstConfigNames.acd_semester_id : 0,
    instDetailsData ? instDetailsData.GetAcdInstConfigNames.acd_section_id : 0,
    0,
    ROWS_PER_PAGE,
    getAllotedId(),
    searchStudent,
    StudentReportType.BY_COMBO_ID_AT_ALLOTED_LEVEL,
    0,
    0,
    0
  );
  const rows = comboDetails
    ? studentData
      ? studentData.GetAcdStudents.edges.map(({ node }, index) => ({
          id: index + 1,
          std_adm_no: node.std_adm_no,
          std_reg_no: node.std_reg_no,
          std_name: `${node.first_name} ${node.middle_name} ${node.last_name}`,
          std_father: node.std_father_name,
          std_mobile: node.std_mobile,
          std_status: node.std_status,
          std_id: node.id,
        }))
      : []
    : data && data.nodes.length
    ? data.nodes.map((node, index) => ({
        id: index + 1,
        std_adm_no: node.std_adm_no,
        std_reg_no: node.std_reg_no,
        std_name: `${node.first_name} ${node.middle_name} ${node.last_name}`,
        std_father: node.std_father_name,
        std_mobile: node.std_mobile,
        std_status: node.std_status,
        std_id: node.id,
      }))
    : [];
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "std_adm_no",
      headerName: TableHeaders.ADMISSION_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "std_reg_no",
      headerName: TableHeaders.REGISTER_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "std_name",
      headerName: TableHeaders.STUDENT_NAME,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-onClick td-name",
      flex: 1,
    },
    {
      field: "action",
      headerName: TableHeaders.ACTION,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-actions-view",
      headerClassName: "td-actions-view",
      align: "center",
      renderCell: (params) => {
        return (
          <img
            src={Delete}
            alt=""
            onClick={() => {
              // setDeleteModal(!deleteModal);
              handleDelete(params.row.std_id);
            }}
          />
        );
      },
    },
  ];
  const handleSubmit = () => {
    AddAcdSubCombo({
      variables: {
        alloted_level: subjectAllocLevel,
        allotted_id: allocationId!,
        acd_subj_combo_id: combinationId!,
        acd_yr_id: activeAcademicYearData.data
          ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
          : 0,
        inst_id: InstId!,
        student_ids: [...selectedStudentIds],
        token,
        user_details,
      },
    }).then(({ data }) => {
      if (data && data.AddAcdStudentComboDetails) {
        setMessage({
          flag: true,
          message: "Students added successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleDelete = (id: number) => {
    DeleteAcdSubCombo({
      variables: {
        alloted_level: subjectAllocLevel,
        allotted_id: allocationId!,
        acd_subj_combo_id: Number(comboDetails?.acd_subj_combo_id!),
        acd_yr_id: activeAcademicYearData.data
          ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
          : 0,
        inst_id: InstId!,
        student_ids: [id],
        token,
        user_details,
      },
    }).then(({ data }) => {
      if (data && data.DeleteAcdStudentComboDetails) {
        setMessage({
          flag: true,
          message: "Students Deleted successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setDrawer(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  return (
    <>
      <div className="sub-allocation-comb__selected-students">
        <div className="sub-allocation-comb__selected-students--title">
          <Title>Selected Students for confirmation</Title>
          <img src={Close} alt="" onClick={() => setDrawer(false)} />
        </div>
        <b className="sub-allocation-comb__selected-students--b">
          Selected Combination :{" "}
          {comboDetails ? comboDetails.subj_combo_name : EMPTY_STRING}
        </b>
        <Input id="search" placeholder="Search..." />
        <div
          className={`sub-allocation-comb__selected-students--tableblock ${classes.root}`}
        >
          <DataGridPro
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            // onCellClick={(params: GridCellParams, studentId) => {
            //   handleCellClick(params);
            // }}
            disableRowSelectionOnClick
            slotProps={{
              columnsPanel: { disableHideAllButton: true },
            }}
          />
        </div>
        <Button mode="save" onClick={handleSubmit} />
        <Button mode="clear" />
        <Button mode="cancel" onClick={() => setDrawer(false)} />
      </div>
      <DeleteModal
        id={stdComboId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={handleDelete}
      />
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default SelectedStudents;
