import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import { Title } from "../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import Home from "../Home/Index";
import { useNavigate, useParams } from "react-router-dom";
import useUserRights, { GetUserRightsedges } from "../hooks/useUserRights";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../utils/constants";
import { useEffect, useState } from "react";
import {
  getModifiedScrollHeight,
  toStandardCase,
} from "../../../utils/UtilFunctions";
import yes from "../../../images/Correct.svg";
import No from "../../../images/Wrong.svg";
import usePayRollMastersConfig from "../../HR/hooks/usePayRollMastersConfig";
import useEmpMasterData from "../../HR/hooks/useEmpMasterData";
import useActiveInstModules from "../hooks/useActiveInstModules";
const List = () => {
  const { InstId } = useParams();
  const navigate = useNavigate();
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [employees, setEmployees] = useState<GetUserRightsedges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const { users } = useUserRights(ROWS_PER_PAGE);

  const { USE_HR_DEPARTMENT, USE_HR_DESIGNATION, USE_HR_JOBTYPE } =
    usePayRollMastersConfig();
  const { departmentDropDown, designationDropDown, jobTypeDropDown } =
    useEmpMasterData();
  const { data, fetchMore, loading } = users;
  const { ActiveModules, USE_PAYROLL } = useActiveInstModules();
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetUserRights.edges;
            const pageInfo = fetchMoreResult.GetUserRights.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetUserRights.edges.filter(
              ({
                node: {
                  pr_emp_details: { id },
                },
              }) =>
                newEdges.findIndex(
                  ({
                    node: {
                      pr_emp_details: { id: newId },
                    },
                  }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetUserRights: {
                edges: [...employees, ...newEdges],
                pageInfo,
                totalCount: data?.GetUserRights.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetUserRights.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = employees.filter(
        //   (emp) => !emp.node.isChecked
        // );

        const updatedNewData = newData.map((newEmp) => {
          const filteredStudent = employees.find(
            (emp) =>
              emp.node.pr_emp_details.id === newEmp.node.pr_emp_details.id
          );
          if (filteredStudent) {
            return {
              ...newEmp,
              node: {
                ...newEmp.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newEmp;
        });
        setEmployees(updatedNewData);
      } else {
        setEmployees(newData);
      }
      setEndCursor(data.GetUserRights.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row">
        <Title>User Rights Configurations</Title>
      </div>
      <div className="user-configuration">
        <div className="row g-0 user-configuration__filters">
          <div className="col-1">
            <Input placeholder="By Name..." id="search" />
          </div>
          {USE_HR_DEPARTMENT ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={departmentDropDown}
                renderInput={(params) => (
                  <TextField
                    label="Department"
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    classes={{
                      root: textClasses.formControlRoot,
                    }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_HR_DESIGNATION ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={designationDropDown}
                renderInput={(params) => (
                  <TextField
                    label="Designation"
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    classes={{
                      root: textClasses.formControlRoot,
                    }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_HR_JOBTYPE ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={jobTypeDropDown}
                renderInput={(params) => (
                  <TextField
                    label="Job Type"
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    classes={{
                      root: textClasses.formControlRoot,
                    }}
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={[]}
              renderInput={(params) => (
                <TextField
                  label="Login Type"
                  {...params}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  classes={{
                    root: textClasses.formControlRoot,
                  }}
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-2">
            <Button
              onClick={() => navigate(`/${InstId}/rights/assignrights`)}
              mode="user-rights"
            />
          </div>
        </div>
        <div className="user-configuration__tableblock">
          <TableContainer
            className="user-configuration__table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Sl</TableCell>
                  {USE_PAYROLL ? <TableCell>Emp ID</TableCell> : null}
                  <TableCell>Name</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>User Type</TableCell>

                  {USE_HR_DESIGNATION ? (
                    <TableCell>Designation</TableCell>
                  ) : null}
                  {ActiveModules.data?.GetMstInstActiveModules.map((module) => {
                    return (
                      <TableCell key={module.eduate_module_details.Name}>
                        {toStandardCase(module.eduate_module_details.Name)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {employees.map((employee, index) => {
                  return (
                    <TableRow key={employee.node.id}>
                      <TableCell
                        id="td-center"
                        className="user-configuration__table--sl"
                      >
                        {index + 1}
                      </TableCell>
                      {USE_PAYROLL ? (
                        <TableCell className="user-configuration__table--id">{`${employee.node.pr_emp_details.emp_id}`}</TableCell>
                      ) : null}

                      <TableCell>{`${employee.node.pr_emp_details.emp_first_name} ${employee.node.pr_emp_details.emp_middle_name} ${employee.node.pr_emp_details.emp_last_name}`}</TableCell>
                      <TableCell>
                        {employee.node.pr_emp_details.emp_mobile}
                      </TableCell>
                      <TableCell>
                        {employee.node.pr_emp_details.emp_email}
                      </TableCell>
                      <TableCell>
                        {employee.node.pr_emp_details.emp_type}
                      </TableCell>
                      {USE_HR_DESIGNATION ? (
                        <TableCell className="user-configuration__table--desc">{`${employee.node.pr_emp_details.pr_designation_details.designation_desc}`}</TableCell>
                      ) : null}
                      {ActiveModules.data?.GetMstInstActiveModules.map(
                        (module) => {
                          const moduleName = module.eduate_module_details.Name;
                          const hasRights =
                            employee.node.user_rights_details.some(
                              (rights) =>
                                rights.inst_module_details.eduate_module_details
                                  .Name === moduleName
                            );

                          return (
                            <TableCell
                              id="td-center"
                              key={module.eduate_module_details.Name}
                              className="user-configuration__table--sl"
                            >
                              {hasRights ? (
                                <img src={yes} alt="yes" />
                              ) : (
                                <img src={No} alt="No" />
                              )}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default List;
