import React, { useContext, useEffect, useState } from "react";
import Avatar from "../../../images/Avatar.svg";

import { useLazyQuery, useMutation } from "@apollo/client";

import { useParams } from "react-router-dom";

import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";
import {
  DateRange,
  handleMUISelectEvent,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import { AppContext } from "../../../context/context";
import { msgType, optionsType } from "../../../utils/Form.types";
import { AttendanceStatus, Operation } from "../../../utils/Enum.types";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import { GetPayRollEmpAttendanceByEmpId } from "../queries/attendance/query";
import {
  GetPayRollEmpAttendanceByEmpIdData,
  GetPayRollEmpAttendanceByEmpIdList,
  GetPayRollEmpAttendanceByEmpIdVars,
} from "../Types/masterDataTypes";
import usePayRollActiveAcademicYear from "../hooks/usePayRollActiveAcademicYear";
import { days } from "../../../utils/constants";
import usePayRollGeneralHolidays from "../hooks/usePayRollGeneralHolidays";
import useToken from "../../../customhooks/useToken";
import useEmpDetailsById from "../hooks/useEmpDetailsById";
import { HolidayQueryType } from "../hooks/usePayRollGeneralHolidays";
import { GetPayRollGeneralHolidaysEdges } from "../Types/paginationTypes";

import {
  Autocomplete,
  Drawer,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import Close from "../../../images/Close.svg";
import { AntSwitch } from "../../../pages/Switch";
import { Keys } from "../../../utils/Enum.keys";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import { empAttendanceStatus } from "../../../styles/DrawerStyles";
import { Button } from "../../../stories/Button/Button";
import { UpdatePayRollAttendanceForEmployee } from "../queries/attendance/mutations";
import { pr_acd_yr_prop } from "./AbsenteesList";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";

interface FontColorProps {
  className: string;
  disabled: boolean;
}

interface monthDaysType {
  day: number;
  leave_short_desc: string;
  date: string;
  week: string;
}
const emptyDaysByWeek = (week: string) => {
  let numberOfEmpty = -1;
  let numberOfEmptyToLast = -1;
  switch (week) {
    case "Sun":
      numberOfEmpty = 6;
      numberOfEmptyToLast = 0;
      break;
    case "Mon":
      numberOfEmpty = 0;
      numberOfEmptyToLast = 6;
      break;
    case "Tue":
      numberOfEmpty = 1;
      numberOfEmptyToLast = 5;

      break;
    case "Wed":
      numberOfEmpty = 2;
      numberOfEmptyToLast = 4;
      break;
    case "Thu":
      numberOfEmpty = 3;
      numberOfEmptyToLast = 3;
      break;
    case "Fri":
      numberOfEmpty = 4;
      numberOfEmptyToLast = 2;
      break;
    case "Sat":
      numberOfEmpty = 5;
      numberOfEmptyToLast = 1;
      break;
    default:
      break;
  }
  return { numberOfEmpty, numberOfEmptyToLast };
};

const ViewPerEmployeeAttendance = ({ pr_acd_yr_id }: pr_acd_yr_prop) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);
  const { employeeFormData } = useEmpDetailsById();
  const { user_details } = useLoggedInUserDetails();
  const { serverDate } = useServerDateandTime();
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const drawerClasses = empAttendanceStatus();
  const [leaveOptions, setLeaveOptions] = useState<optionsType[]>([]);
  const [leaveledger, setLeaveLedger] = useState<optionsType | null>(null);

  const [formData, setFormData] = useState({
    date: "",
    present: false,
  });
  const { firstDay, lastDay } = DateRange(serverDate!) || {};
  const { payRollActiveAcademicYear } = usePayRollActiveAcademicYear();
  const { PayRollGeneralHolidays } = usePayRollGeneralHolidays(
    payRollActiveAcademicYear.data?.GetPayRollAcdYrActiveByInstId.id!,
    lastDay!,
    firstDay!,
    "",
    HolidayQueryType.ALL_HOLIDAYS,
    true
  );
  const [monthDays, setMonthDays] = useState<monthDaysType[]>();
  const [updateAttendanceModal, setUpdateAttendanceModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [updatePayRolEmpAttendance] = useMutation(
    UpdatePayRollAttendanceForEmployee,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          flag: true,
          operation: Operation.NONE,
        }),
    }
  );

  const [GetAttendanceByEmpId, { loading }] = useLazyQuery<
    GetPayRollEmpAttendanceByEmpIdData,
    GetPayRollEmpAttendanceByEmpIdVars
  >(GetPayRollEmpAttendanceByEmpId, {
    variables: {
      token,
      inst_id: InstId!,
      pr_acd_yr_id:
        payRollActiveAcademicYear.data?.GetPayRollAcdYrActiveByInstId.id!,
      cal_month: firstDay!,
      pr_emp_id: state.employeeId,
    },
  });

  const month = new Date(serverDate).getMonth();
  const year = new Date(serverDate).getFullYear();

  const getFontColor = (
    date: Date,
    holidays: GetPayRollGeneralHolidaysEdges[]
  ): FontColorProps => {
    const today = new Date();
    const holiday = holidays?.find(
      (edge) => new Date(edge.node.holiday_date).getDate() === date.getDate()
    );
    const isHoliday = holiday?.node.holiday_type === "WD";
    const isSelectedDate =
      date.getDate() === new Date(serverDate).getDate() &&
      date.getMonth() === new Date(serverDate).getMonth() &&
      date.getFullYear() === new Date(serverDate).getFullYear();
    const isDisabled = date > today;

    const fontColor = isHoliday
      ? "font-red"
      : isSelectedDate
      ? "font-selected-theme"
      : "";

    return {
      className: `enquiry-dashboard__data--calendar--grid-item--date ${fontColor}`,
      disabled: isHoliday || isSelectedDate || isDisabled,
    };
  };

  const weekCount = () => {
    if (monthDays && monthDays.length > 0) {
      const firstWeek = monthDays[0].week;

      const lastWeek = monthDays[monthDays.length - 1]?.week;
      const startArray = Array.from({
        length: emptyDaysByWeek(firstWeek).numberOfEmpty,
      })
        .fill(null)
        .map(() => ({
          day: -1,
          present: false,
          leave_short_desc: "",
          date: "",
          week: "",
        }));

      const endArray = new Array(emptyDaysByWeek(lastWeek!).numberOfEmptyToLast)
        .fill(null)
        .map(() => ({
          day: -1,
          present: false,
          leave_short_desc: "",
          date: "",
          week: "",
        }));

      return [...startArray, ...monthDays, ...endArray];
    } else {
      return [];
    }
  };
  const handleAttendence = () => {
    updatePayRolEmpAttendance({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        pr_acd_yr_id: pr_acd_yr_id,
        date_of_attendance: toIsoDate(formData.date),
        pr_emp_id: state.employeeId,
        from_status: monthDays?.find(
          (month_day) => month_day.date === formData.date
        )?.leave_short_desc
          ? monthDays?.find((month_day) => month_day.date === formData.date)
              ?.leave_short_desc
          : "",
        to_status: formData.present ? AttendanceStatus.P : leaveledger?.value,
      },
      refetchQueries: [
        {
          query: GetPayRollEmpAttendanceByEmpId,
          variables: {
            token,
            inst_id: InstId!,
            pr_acd_yr_id: pr_acd_yr_id,
            cal_month: toIsoDate(formData.date),
            pr_emp_id: state.employeeId,
          },
          fetchPolicy: "network-only",
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Attendance updated successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const fillAttendanceDetailsForUpdation = (
    date: string,
    leave_short_desc: string
  ) => {
    setFormData({
      date: date,
      present: leave_short_desc === AttendanceStatus.P,
    });
    if (leave_short_desc !== AttendanceStatus.P) {
      setLeaveLedger(
        leaveOptions.find((leave) => leave.value === leave_short_desc) ?? null
      );
    }
    setUpdateAttendanceModal(!updateAttendanceModal);
  };
  useEffect(() => {
    if (
      payRollActiveAcademicYear.data &&
      !payRollActiveAcademicYear.loading &&
      state.employeeId &&
      firstDay &&
      token
    ) {
      GetAttendanceByEmpId().then(({ data }) => {
        if (data) {
          const res = Array.from({ length: 30 }, (_, i) => {
            const dayNum = i + 1;
            const dayKey = `day_${dayNum}`;
            const date = new Date(year, month, dayNum);
            return {
              day: dayNum,
              present:
                (data.GetPayRollEmpAttendanceByEmpId as Record<string, string>)[
                  dayKey
                ] === AttendanceStatus.P,
              leave_short_desc: (
                data.GetPayRollEmpAttendanceByEmpId as Record<string, string>
              )[dayKey],
              date: date.toString(),
              week: date.toLocaleString("en-US", { weekday: "short" }),
            };
          });
          setMonthDays(res);
          setLeaveOptions(
            (
              data.GetPayRollEmpAttendanceByEmpId as GetPayRollEmpAttendanceByEmpIdList
            ).emp_details.leave_master_details.leave_details.map((leave) => ({
              label: leave.leave_ldgr_details.leave_desc,
              value: leave.leave_ldgr_details.leave_short_code,
            }))
          );
        }
      });
    } // eslint-disable-next-line
  }, [
    state.employeeId,
    firstDay,
    payRollActiveAcademicYear.data,
    payRollActiveAcademicYear.loading,
    message.flag,
    loading,
  ]);

  return (
    <>
      <div className="attendance-overview__student-attendance">
        <div className="row g-0 attendance-overview__student-attendance--title">
          <Title variant="subtitle1">Monthly Attendance Overview</Title>
        </div>
        <div className="row g-0 attendance-overview__student-attendance--details">
          <div className="col-4 attendance-overview__student-attendance--details--image">
            <img src={Avatar} alt="/" />
          </div>
          <div className="col attendance-overview__student-attendance--details--list">
            <Label>Name</Label>
            <Label>:</Label>
            <Label>{employeeFormData.emp_name}</Label>
            <Label>Emp Id.</Label>
            <Label>:</Label>
            <Label>{employeeFormData.emp_id}</Label>
            <Label>Date of Joining:</Label>
            <Label>:</Label>
            <Label>{toStandardDate(employeeFormData.emp_doj)}</Label>
          </div>
        </div>
        {/* <div className="attendance-overview__student-attendance--percentage">
          <Label>OverAll Attendance Percentage</Label>
          <b className="font-green">{`${format(
            Number((count.present / no_of_days!) * 100)
          )} ${
            Number((count.present / no_of_days!) * 100) ? "%" : EMPTY_STRING
          } `}</b>
        </div> */}
        <div className="row g-0 attendance-overview__student-attendance--calendar">
          <ul className="enquiry-dashboard__data--calendar--grid-container">
            {days.map((day, index: React.Key) => {
              return (
                <li
                  key={index}
                  className="enquiry-dashboard__data--calendar--grid-container--day"
                >
                  {day}
                </li>
              );
            })}

            {weekCount().map(({ day, date, leave_short_desc }, index) => {
              const res =
                PayRollGeneralHolidays.data?.GetPayRollGeneralHolidays.edges.filter(
                  (edge) =>
                    new Date(edge.node.holiday_date).getMonth() ===
                    new Date(serverDate).getMonth()
                );
              const { disabled, className } = getFontColor(
                new Date(date),
                res!
              );
              return day && res ? (
                <button
                  style={{
                    background:
                      leave_short_desc === AttendanceStatus.P
                        ? "green"
                        : leave_short_desc === "" || leave_short_desc === "WD"
                        ? ""
                        : "red",
                  }}
                  onClick={() =>
                    fillAttendanceDetailsForUpdation(date, leave_short_desc)
                  }
                  disabled={disabled}
                  key={index}
                  className="enquiry-dashboard__data--calendar--grid-item"
                >
                  <span className={className}>
                    {date ? new Date(date).getDate() : ""}
                  </span>
                </button>
              ) : (
                <button
                  key={index}
                  onClick={() =>
                    fillAttendanceDetailsForUpdation(date, leave_short_desc)
                  }
                ></button>
              );
            })}
          </ul>
        </div>
      </div>

      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={updateAttendanceModal}
        onClose={() => setUpdateAttendanceModal(!updateAttendanceModal)}
      >
        <div className="salary-process__emp-attendance-status">
          <div className="salary-process__emp-attendance-status--title">
            <Title>Attendance Status</Title>
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setUpdateAttendanceModal(!updateAttendanceModal)}
            />
          </div>
          <div className="salary-process__emp-attendance-status--title">
            <Label variant="LabelBold">{toStandardDate(formData.date)}</Label>
            <Button mode="save" onClick={handleAttendence}>
              &nbsp;Attendance
            </Button>
          </div>
          <div className="row g-0">
            <div className="col-8">
              <FormGroup className="academic-subject-details__form-labels">
                <FormControlLabel
                  label=""
                  name="attendance"
                  checked={formData.present}
                  control={
                    <AntSwitch
                      onClick={(e: React.MouseEvent) =>
                        setFormData((prevValues) => ({
                          ...prevValues,
                          present: (e.target as HTMLInputElement).checked,
                        }))
                      }
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          handleMUISelectEvent(e);
                        }
                      }}
                    />
                  }
                  labelPlacement="start"
                />
              </FormGroup>

              {formData.present ? (
                <span className="font-green">Present</span>
              ) : (
                <span className="font-red">Absent</span>
              )}
            </div>
          </div>
          {!formData.present && (
            <div className="row g-0  salary-process__emp-attendance-status--select">
              <div className="col-6">
                <Autocomplete
                  classes={classes}
                  options={leaveOptions}
                  value={leaveledger}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setLeaveLedger(newValue);
                    } else {
                      setLeaveLedger(null);
                    }
                  }}
                  openOnFocus
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Leave Leadger"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </Drawer>
      <LoadingModal flag={loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default ViewPerEmployeeAttendance;
