import { Label } from "../../../../stories/Label/Label";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Button } from "../../../../stories/Button/Button";
import { useParams } from "react-router";
import {
  Direction,
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  PredefinedDataTypes,
  SortBy,
  StdEnqQueryType,
  StudentStatus,
  YesNo,
} from "../../../../utils/Enum.types";
import { studentValidation } from "../../../../utils/validationRules";
import {
  msgType,
  optionsType,
  studentBasicDetails,
} from "../../../../utils/Form.types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AddStudent } from "../../../../queries/students/mutations/new";
import Input from "../../../../components/common/Input/Index";
import { Form, Formik } from "formik";
import InputStory from "../../../../stories/Input/Input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  removeMoreSpace,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import useNotAlloactedIdFromInst from "../../../../customhooks/useNotAlloactedIdFromInst";
import {
  StudentFormLabelsType,
  StudentsTitlesType,
} from "../../../../Types/Student/json";
import { BranchListVarsByInstId } from "../../../../Types/Student";
import { Autocomplete, TextField } from "@mui/material";
import LoadingModal from "../../../../pages/LoadingModal";
import {
  GetStudentEnquiresByEnquiryId,
  GetStudents,
} from "../../../../queries/students/list/newApi";
import { studentQueryTypes } from "../../../../utils/studentqueryEnum.types";
import {
  GetBranchWiseAdmissionNumber,
  GetInstWiseAdmissionNumber,
} from "../../../../queries/institution/configuration/query";
import {
  AddmissionNumberByBranchId,
  AddmissionNumberByInstId,
} from "../../configurations/institutionconfiguration/InstitutionNeeds";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
  requiredAutoCompleteStyles,
} from "../../../../styles/AutocompleteStyles";
import DownArrow from "../../../../images/DownArrow.svg";
import Close from "../../../../images/Close.svg";
import Fetch from "../../../../images/Fetch.svg";
import { Title } from "../../../../stories/Title/Title";
import useToken from "../../../../customhooks/useToken";
import { Keys } from "../../../../utils/Enum.keys";
import MessageModal from "../../../../pages/MessageModal";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { updateStudentById } from "../../../../queries/students/mutations/update";
import { GetStudentDetails } from "../../../../queries/common";
import { AppContext } from "../../../../context/context";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import { payloadTypes } from "../../../../context/reducer";
import Home from "../../Home/Index";
import { useNavigate } from "react-router-dom";
import useActiveAcademicYear from "../../../Academics/hooks/useActiveAcademicYear";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useDropdownData from "../../../../customhooks/useDropdownData";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import StudentEnquiry from "../../../Enquiry/StudentEnquiry";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { GlobalPageConfigData } from "../../../../Types/configtypes";
import useStudentProfilePicUpload from "../../../../customhooks/useStudentProfilePicUpload";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
const { Students } = require("../../../../json/title.json");
const { StudentFormLabels } = require("../../../../json/config.json");
interface Props {
  operation: Operation;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const StudentReservation = ({ operation, setModalFlag }: Props) => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const requiredClasses = requiredAutoCompleteStyles();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.INSTITUTION_USE_ADM_NUMBER
  );

  const { InstDetails } = useInstDetails(1);
  const { configData: NamesConfig } = useSwConfigData([
    InstitutionConfigurationTypes.ENABLE_MIDDLE_NAME,
    InstitutionConfigurationTypes.ENABLE_LAST_NAME,
  ]);
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let useAdmNumber = "";
    let enableMiddleName = false;
    let enableLastName = false;
    let enableStdNumEdit = false;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.ENABLE_MIDDLE_NAME:
            enableMiddleName = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_LAST_NAME:
            enableLastName = item.config_boolean_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      useAdmNumber,
      enableMiddleName,
      enableLastName,
      enableStdNumEdit,
    };
  };
  const { enableMiddleName, enableLastName } = filterDataByConfigKey(
    NamesConfig.data?.GetSwConfigVariables!
  );

  const [gender, setGender] = useState<optionsType | null>(null);
  const [fresher, setFresher] = useState<optionsType>({
    label: "Yes",
    value: YesNo.YES,
  });

  const { serverDate } = useServerDateandTime();

  const navigate = useNavigate();
  const { dispatch } = useContext(AppContext);
  const { InstId } = useParams();
  const { studentData } = useStudentDatabyId();
  const [studentEnquiryModal, setStudentEnquiryModal] = useState(false);
  const [GetEnquiryData] = useLazyQuery(GetStudentEnquiresByEnquiryId, {
    variables: {
      token,
      id: state.studentEnquiryId,
    },
  });
  const [formData, setFormData] = useState<studentBasicDetails>({
    first_name: "",
    middle_name: "",
    last_name: "",
    std_status: StudentStatus.NXT,
    studying: YesNo.NO,
    sex: "",
    reg_number: "",
    adm_number: "",
    doa: "",
    fresher: YesNo.YES,
    fin_yr_id: 0,
    acd_yr_id: 0,
    dob: "",
    email: "",
    mobile: "",
    father_name: "",
    mother_name: "",
    std_father_mobile: "",
    std_mother_mobile: "",
    firebaseid: "",
    dept_id: 0,
    branch_id: 0,
    class_id: 0,
    semester_id: 0,
    category_id: 0,
    section_id: 0,
    std_curr_fin_yr: "",
    std_profile_filename: "",
    std_curr_acd_yr: "",
  });

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [admNumber, setadmNumber] = useState("");

  const nameRef = document.getElementsByName(
    "first_name"
  )[0] as HTMLInputElement;
  const saveRef = useRef<HTMLButtonElement>(null);
  const dobRef = useRef<HTMLInputElement>(null);

  const dateOfAdmissionRef = useRef<HTMLInputElement>(null);
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prevValues: studentBasicDetails) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const { user_details } = useLoggedInUserDetails();
  const { component, setFile, uploadFile, file } = useStudentProfilePicUpload(
    operation,
    formData.std_profile_filename,
    InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE
  );

  const handleNumberChange = (mobileNo: string) => {
    setFormData((prevValues) => ({
      ...prevValues,
      mobile: mobileNo,
    }));
  };
  const [createStudent, { loading: creationLoading }] = useMutation(
    AddStudent,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [updateStudent] = useMutation(updateStudentById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  //Department Ids
  const { NotAllocatedDeptId, NotAllocatedSectionId } =
    useNotAlloactedIdFromInst();
  const { categoryDropDown } = useInstMasterDataByInstId();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    semesterDropDown,
    masterIds,
  } = useDropdownData(
    formData.dept_id,
    formData.branch_id,
    formData.class_id,
    formData.semester_id
  );

  const { activeAcademicYearData } = useActiveAcademicYear();

  const [GetBranchAdmissionDetails, { data: branchAdmissionNumber }] =
    useLazyQuery<AddmissionNumberByBranchId, BranchListVarsByInstId>(
      GetBranchWiseAdmissionNumber,
      {
        variables: { inst_id: InstId!, token, branch_id: formData.branch_id },
      }
    );
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const [GetInstAdmissionDetails, { data: instAdmissionNumber }] = useLazyQuery<
    AddmissionNumberByInstId,
    BranchListVarsByInstId
  >(GetInstWiseAdmissionNumber, {
    variables: { inst_id: InstId!, token, branch_id: formData.branch_id },
  });

  const {
    PredefinedData: { dropDown: Fresher },
  } = usePredefinedDataByType(PredefinedDataTypes.YESNO, EMPTY_STRING);

  const {
    PredefinedData: { dropDown: Gender },
  } = usePredefinedDataByType(PredefinedDataTypes.GENDER, EMPTY_STRING);

  const submitForm = async () => {
    if (!(formData.mobile || formData.email)) {
      alert("Please fill any one the field, Email/Mobile No:");
      return;
    }

    const doa = formData.doa
      ? new Date(formData.doa).toISOString()
      : new Date(serverDate).toISOString();
    const dob = formData.dob
      ? new Date(formData.dob).toISOString()
      : new Date(serverDate).toISOString();
    let studying,
      fresher = null;
    // eslint-disable-next-line
    {
      // eslint-disable-next-line
      formData.studying === YesNo.YES ? (studying = true) : (studying = false);
      // eslint-disable-next-line
      formData.fresher === YesNo.YES ? (fresher = true) : (fresher = false);
    }
    if (operation === Operation.CREATE) {
      createStudent({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          flags: {
            is_adm_num_edited: !(formData.adm_number === admNumber),
            use_adm_num_as_is: false,
          },
          enquiry_std_id: 0,
          input: {
            inst_id: InstId,
            dept_id: formData.dept_id ? formData.dept_id : NotAllocatedDeptId,
            section_id: NotAllocatedSectionId,
            branch_id: masterIds.branchId,
            class_id: masterIds.classId,
            semester_id: masterIds.semesterId,
            category_id: formData.category_id,
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first_name: removeMoreSpace(formData.first_name),
            middle_name: removeMoreSpace(formData.middle_name),
            last_name: removeMoreSpace(formData.last_name),
            std_studying: studying,
            std_status: formData.std_status,
            std_sex: gender?.value,
            std_reg_no: removeMoreSpace(formData.reg_number),
            std_adm_no: formData.adm_number,
            std_doa: toIsoDate(doa),
            std_fresher: fresher,
            std_dob: toIsoDate(dob),
            std_email: removeMoreSpace(formData.email),
            std_mobile:
              formData.mobile.length === 12
                ? `+${formData.mobile}`
                : EMPTY_STRING,
            std_father_name: removeMoreSpace(formData.father_name),
            std_mother_name: removeMoreSpace(formData.mother_name),
            std_father_mobile:
              formData.std_father_mobile &&
              formData.std_father_mobile.length === 12
                ? `+${formData.std_father_mobile}`
                : EMPTY_STRING,
            std_mother_mobile:
              formData.std_mother_mobile &&
              formData.std_mother_mobile.length === 12
                ? `+${formData.std_mother_mobile}`
                : EMPTY_STRING,
            std_profile_filename:
              file && InstDetails.data
                ? `${InstDetails.data?.nodes[0]?.inst_name}/students/${
                    formData.adm_number || admNumber
                  }/std_profile_pic/std_profile_pic`
                : EMPTY_STRING,
          },
        },
        refetchQueries: [
          {
            query: GetStudents,
            variables: {
              after: null,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              name: EMPTY_STRING,
              token,
              direction: Direction.ASC,
              sortBy: SortBy.FIRST_NAME,
              input: {
                ids: [Number(InstId)],
                std_query_type: studentQueryTypes.STATUS_EQ_NXT_BY_INST_ID,
              },
            },
          },
          {
            query: GetStudents,
            variables: {
              after: null,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              name: EMPTY_STRING,
              token,
              direction: Direction.ASC,
              sortBy: SortBy.FIRST_NAME,
              input: {
                ids: [Number(InstId)],
                std_query_type: studentQueryTypes.BY_INST_ID,
              },
            },
          },
        ],
      }).then(async ({ data }) => {
        if (data) {
          try {
            const uploadResult = await uploadFile(data.AddStudent.id);
            if (uploadResult) {
              setMessage({
                flag: true,
                message:
                  "Successfully Reserved Student for Next Academic Year ",
                operation: Operation.CREATE,
              });
            } else {
              setMessage({
                flag: true,
                operation: Operation.NONE,
                message: "Image upload failed",
              });
            }
          } catch (err) {
            setMessage({
              flag: true,
              operation: Operation.NONE,
              message: `${err}`,
            });
          }
        }
      });
    } else {
      try {
        const uploadResult = await uploadFile(state.studentId);
        if (uploadResult) {
          updateStudent({
            variables: {
              token,
              inst_id: InstId!,
              user_details,
              id: state.studentId,
              input: {
                first_name: removeMoreSpace(formData.first_name),
                middle_name: removeMoreSpace(formData.middle_name),
                last_name: removeMoreSpace(formData.last_name),
                std_doa: toIsoDate(doa),
                std_fresher: fresher,
                std_sex: gender?.value,
                std_dob: toIsoDate(dob),
                std_mobile:
                  formData.mobile.length === 12
                    ? `+${formData.mobile}`
                    : EMPTY_STRING,
                std_father_name: removeMoreSpace(formData.father_name),
                std_mother_name: removeMoreSpace(formData.mother_name),
                std_father_mobile:
                  formData.std_father_mobile &&
                  formData.std_father_mobile.length === 12
                    ? `+${formData.std_father_mobile}`
                    : EMPTY_STRING,
                std_mother_mobile:
                  formData.std_mother_mobile &&
                  formData.std_mother_mobile.length === 12
                    ? `+${formData.std_mother_mobile}`
                    : EMPTY_STRING,
                std_email: removeMoreSpace(formData.email),
                std_profile_filename:
                  file && studentData.data && InstDetails.data
                    ? `${InstDetails.data?.nodes[0]?.inst_name}/students/${studentData.data?.nodes[0].id}/std_profile_pic/std_profile_pic`
                    : EMPTY_STRING,
              },
            },
            refetchQueries: [
              {
                query: GetStudentDetails,
                variables: {
                  ids: [state.studentId] ?? EMPTY_STRING,
                  token,
                },
              },
            ],
          }).then(({ data }) => {
            if (data) {
              setMessage({
                message: "Student Details Updated Successfully",
                flag: true,
                operation: Operation.UPDATE,
              });
            }
          });
        } else {
          setMessage({
            flag: true,
            operation: Operation.NONE,
            message: "Image upload failed",
          });
        }
      } catch (error) {
        setMessage({
          flag: true,
          operation: Operation.NONE,
          message: `${error}`,
        });
      }
    }
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setModalFlag(false);
      handleClear();
      navigate(`/${InstId}/admissions/students/reservations`);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(
    () => {
      if (configData.data && !configData.loading && formData.branch_id !== 0) {
        if (
          configData.data?.GetSwConfigVariables[0].config_string_value === "B"
        ) {
          GetBranchAdmissionDetails().then(({ data }) => {
            if (data) {
              setFormData({
                ...formData,
                adm_number: data.GetBranchWiseAdmissionNumber,
                doa:
                  operation === Operation.CREATE
                    ? toInputStandardDate(serverDate)
                    : toInputStandardDate(studentData.data?.nodes[0].std_doa!),
              });
              setadmNumber(data.GetBranchWiseAdmissionNumber);
            }
          });
        }
      }
      if (
        configData.data?.GetSwConfigVariables[0].config_string_value === "I"
      ) {
        GetInstAdmissionDetails().then(({ data }) => {
          if (data) {
            setFormData({
              ...formData,
              adm_number: data.GetInstWiseAdmissionNumber,
              doa:
                operation === Operation.CREATE
                  ? toInputStandardDate(serverDate)
                  : toInputStandardDate(studentData.data?.nodes[0].std_doa!),
            });
            setadmNumber(data.GetInstWiseAdmissionNumber);
          }
        });
      }
    }, // eslint-disable-next-line
    [
      configData.data,
      GetInstAdmissionDetails,
      branchAdmissionNumber?.GetBranchWiseAdmissionNumber,
      instAdmissionNumber?.GetInstWiseAdmissionNumber,
      configData.loading,
      GetBranchAdmissionDetails,
      formData.branch_id,
    ]
  );
  useEffect(() => {
    if (studentData.data && !studentData.loading) {
      const empMobileWithoutPlus =
        studentData.data?.nodes[0].std_mobile.replaceAll("+", "");
      setFormData({
        first_name: studentData.data?.nodes[0].first_name,
        middle_name: studentData.data?.nodes[0].middle_name,
        last_name: studentData.data?.nodes[0].last_name,
        mobile: empMobileWithoutPlus,
        father_name: studentData.data?.nodes[0].std_father_name,
        mother_name: studentData.data?.nodes[0].std_mother_name,
        email: studentData.data?.nodes[0].std_email,
        std_status: "",
        studying: "",
        sex: studentData.data?.nodes[0].std_sex,
        reg_number: "",
        adm_number: studentData.data?.nodes[0].std_adm_no,
        doa:
          toInputStandardDate(studentData.data?.nodes[0].std_doa) ===
          DEFAULT_TIME
            ? ""
            : toInputStandardDate(studentData.data?.nodes[0].std_doa),
        dob: studentData.data?.nodes[0].std_dob
          ? toInputStandardDate(studentData.data?.nodes[0].std_dob)
          : "",
        fresher:
          studentData.data?.nodes[0].std_fresher === false
            ? YesNo.NO
            : YesNo.YES,
        fin_yr_id: 0,
        acd_yr_id: 0,
        dept_id: studentData.data?.nodes[0].dept.id,
        branch_id: studentData.data?.nodes[0].branch.id,
        class_id: studentData.data?.nodes[0].class.id,
        semester_id: studentData.data?.nodes[0].semester.id,
        category_id: studentData.data?.nodes[0].category.id,
        section_id: studentData.data?.nodes[0].section.id,
        std_curr_fin_yr: "",
        std_profile_filename: studentData.data?.nodes[0].std_profile_filename,
        std_curr_acd_yr: "",
        firebaseid: "",
        std_father_mobile:
          studentData.data?.nodes[0].std_father_mobile.replaceAll("+", ""),
        std_mother_mobile:
          studentData.data?.nodes[0].std_mother_mobile.replaceAll("+", ""),
      });
      setGender(
        Gender.find(
          ({ value }) => value === studentData.data?.nodes[0].std_sex
        ) ?? null
      );
      setFresher(
        studentData.data?.nodes[0].std_fresher
          ? {
              label: YesNo.YES,
              value: YesNo.YES,
            }
          : {
              label: YesNo.NO,
              value: YesNo.NO,
            }
      );
    }
  }, [studentData.loading, studentData.data, Gender, Fresher]);
  useEffect(
    () => {
      if (state.studentEnquiryId !== 0)
        GetEnquiryData().then(({ data }) => {
          if (data) {
            const {
              first_name,
              middle_name,
              last_name,
              std_sex,
              std_father_name,
              std_mother_name,
              std_email,
              std_mobile,
            } = data.node;
            setFormData({
              ...formData,
              first_name,
              middle_name,
              last_name,
              sex: std_sex,
              doa: toInputStandardDate(serverDate),
              email: std_email,
              mobile: std_mobile,
              father_name: std_father_name,
              mother_name: std_mother_name,
            });
          }
        });
    },
    // eslint-disable-next-line
    [state.studentEnquiryId, GetEnquiryData]
  );

  const handleClear = () => {
    setFormData({
      first_name: "",
      middle_name: "",
      last_name: "",
      std_status: StudentStatus.NXT,
      studying: YesNo.YES,
      sex: "",
      reg_number: "",
      adm_number: "",
      doa: "",
      fresher: YesNo.YES,
      fin_yr_id: 0,
      acd_yr_id: 0,
      dob: "",
      email: "",
      mobile: "",
      father_name: "",
      mother_name: "",
      firebaseid: "",
      dept_id: 0,
      branch_id: 0,
      class_id: 0,
      semester_id: 0,
      category_id: 0,
      section_id: 0,
      std_curr_fin_yr: "",
      std_curr_acd_yr: "",
      std_profile_filename: "",
      std_father_mobile: "",
      std_mother_mobile: "",
    });
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0,
      },
    });
    setGender(null);
    setFresher({
      label: "Yes",
      value: YesNo.YES,
    });
    setFile(null);
    nameRef?.focus();
  };
  const { departmentLabel, branchLabel, classLabel, semesterLabel,categoryLabel } =
    useInstLabels();
  return (
    <>
      {operation === Operation.CREATE ? (
        <Home DashBoardRequired={false} />
      ) : null}

      <Title>
        {Students.StudentReservation.Titles.map(
          (title: StudentsTitlesType, index: React.Key) => {
            return <React.Fragment key={index}>{title.Create}</React.Fragment>;
          }
        )}
      </Title>
      <Formik
        initialValues={formData}
        validationSchema={studentValidation}
        onSubmit={submitForm}
        validateOnChange
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form
              className={
                Operation.UPDATE === operation
                  ? "std-reservation__update"
                  : "std-reservation__add"
              }
            >
              <div className="row g-0 std-reservation__add--data">
                <div className="col">
                  {StudentFormLabels.Labels.BasicDetails.filter(
                    (label: StudentFormLabelsType) => {
                      return (
                        (label.inputName === "middle_name" &&
                          enableMiddleName) ||
                        (label.inputName === "last_name" && enableLastName) ||
                        (label.inputName !== "middle_name" &&
                          label.inputName !== "last_name")
                      );
                    }
                  ).map((label: StudentFormLabelsType, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        <Input
                          onKeyDown={handleFormEvent}
                          LabelName={label.LabelName}
                          name={label.inputName}
                          autoFocus={label.autoFocus}
                          values={formData[label.inputName]}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            meta.handleChange(e);
                            handleValueChange(e);
                          }}
                          required={label.required}
                          type={label.dataType}
                          maxLength={label.maxLength}
                          className={label.className}
                          disabled={label.disabled}
                        />
                      </React.Fragment>
                    );
                  })}
                  <div className="label-grid">
                    <Label>Mobile</Label>
                    <PhoneInput
                      country={"in"}
                      value={formData.mobile}
                      onChange={handleNumberChange}
                      inputProps={{
                        required: false,
                      }}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          handleMUISelectEvent(e);
                        }
                      }}
                    />
                  </div>
                  {StudentFormLabels.Labels.FatherData.map(
                    (label: StudentFormLabelsType, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <Input
                            onKeyDown={handleFormEvent}
                            LabelName={label.LabelName}
                            name={label.inputName}
                            autoFocus={label.autoFocus}
                            values={formData[label.inputName]}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            required={label.required}
                            type={label.dataType}
                            maxLength={label.maxLength}
                            className={label.className}
                            disabled={label.disabled}
                          />
                        </React.Fragment>
                      );
                    }
                  )}
                  <div className="label-grid">
                    <Label>Father Mobile</Label>
                    <PhoneInput
                      country={"in"}
                      value={formData.std_father_mobile}
                      onChange={(newMobile) => {
                        setFormData((prevValues) => ({
                          ...prevValues,
                          std_father_mobile: newMobile,
                        }));
                      }}
                      inputProps={{
                        required: false,
                      }}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          handleMUISelectEvent(e);
                        }
                      }}
                    />
                  </div>
                  {StudentFormLabels.Labels.MotherData.map(
                    (label: StudentFormLabelsType, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <Input
                            onKeyDown={handleFormEvent}
                            LabelName={label.LabelName}
                            name={label.inputName}
                            autoFocus={label.autoFocus}
                            values={formData[label.inputName]}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            required={label.required}
                            type={label.dataType}
                            maxLength={label.maxLength}
                            className={label.className}
                            disabled={label.disabled}
                          />
                        </React.Fragment>
                      );
                    }
                  )}
                  <div className="label-grid">
                    <Label>Mother Mobile</Label>
                    <PhoneInput
                      country={"in"}
                      value={formData.std_mother_mobile}
                      onChange={(newMobile) => {
                        setFormData((prevValues) => ({
                          ...prevValues,
                          std_mother_mobile: newMobile,
                        }));
                      }}
                      inputProps={{
                        required: false,
                      }}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          dobRef.current?.focus();
                        }
                      }}
                    />
                  </div>
                  {StudentFormLabels.Labels.DOB.map(
                    (label: StudentFormLabelsType, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <Input
                            onKeyDown={handleFormEvent}
                            inputRef={dobRef!}
                            LabelName={label.LabelName}
                            name={label.inputName}
                            autoFocus={label.autoFocus}
                            values={formData[label.inputName]}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            type={label.dataType}
                            maxLength={label.maxLength}
                            max={toInputStandardDate(serverDate)}
                          />
                        </React.Fragment>
                      );
                    }
                  )}
                </div>
                <div className="col">
                  <div className="label-grid">
                    <Label>Gender</Label>
                    <Autocomplete
                      classes={classes}
                      options={Gender!}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(option, gender)
                      }
                      value={gender}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setGender(newValue);
                        } else {
                          setGender(null);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          if (gender?.value) handleMUISelectEvent(e);
                        }
                        if (e.key === Keys.BACKSPACE) {
                          setFormData((prevValues) => {
                            return {
                              ...prevValues,
                              sex: EMPTY_STRING,
                            };
                          });
                        }
                      }}
                      openOnFocus
                      popupIcon={<img src={DownArrow} alt="/" />}
                      forcePopupIcon
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          onChange={(e) => {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                sex: e.target.value!,
                              };
                            });
                          }}
                          fullWidth
                          classes={{ root: textClasses.formControlRoot }}
                        />
                      )}
                    />
                  </div>
                  <div className="label-grid">
                    <Label>Fresher</Label>
                    <Autocomplete
                      classes={classes}
                      options={Fresher}
                      value={fresher}
                      isOptionEqualToValue={(option) =>
                        isOptionEqualToValue(option, fresher)
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setFresher(newValue);
                        } else {
                          setFresher({
                            label: "Yes",
                            value: YesNo.YES,
                          });
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          if (fresher.value) handleMUISelectEvent(e);
                        }
                        if (e.key === Keys.BACKSPACE) {
                          setFresher({
                            label: "Yes",
                            value: YesNo.YES,
                          });
                        }
                      }}
                      disableClearable
                      openOnFocus
                      popupIcon={<img src={DownArrow} alt="/" />}
                      forcePopupIcon
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                fresher: e.target.value!,
                              };
                            });
                          }}
                          fullWidth
                          classes={{ root: textClasses.formControlRoot }}
                        />
                      )}
                    />
                  </div>
                  {USE_DEPARTMENT_KEY ? (
                    <div className="label-grid">
                      <Label>{departmentLabel}</Label>
                      <Autocomplete
                        classes={
                          meta.errors.dept_id ? classes : requiredClasses
                        }
                        options={departmentDropDown!}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            if (formData.dept_id) handleMUISelectEvent(e);
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                dept_id: 0,
                                branch_id: 0,
                                class_id: 0,
                                semester_id: 0,
                              };
                            });
                          }
                        }}
                        openOnFocus
                        disabled={studentData.data ? true : false}
                        value={
                          departmentDropDown?.find(
                            ({ value }) => Number(value) === formData.dept_id
                          )! ?? null
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                dept_id: Number(newValue?.value!),
                              };
                            });
                          } else {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                dept_id: 0,
                                branch_id: 0,
                                class_id: 0,
                                semester_id: 0,
                              };
                            });
                          }
                        }}
                        popupIcon={
                          <img
                            src={DownArrow}
                            alt="/"
                            className="down-arrow-autocomplete"
                          />
                        }
                        renderInput={(params) => (
                          <TextField
                            required={!formData?.dept_id && USE_DEPARTMENT_KEY}
                            {...params}
                            fullWidth
                            classes={{ root: textClasses.formControlRoot }}
                          />
                        )}
                      />
                    </div>
                  ) : null}
                  {USE_BRANCH_KEY ? (
                    <div className="label-grid">
                      <Label>{branchLabel}</Label>
                      <Autocomplete
                        classes={
                          meta.errors.branch_id ? classes : requiredClasses
                        }
                        popupIcon={
                          <img
                            src={DownArrow}
                            alt="/"
                            className="down-arrow-autocomplete"
                          />
                        }
                        options={branchDropDown!}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            if (formData.branch_id) handleMUISelectEvent(e);
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                branch_id: 0,
                                class_id: 0,
                                semester_id: 0,
                              };
                            });
                          }
                        }}
                        openOnFocus
                        disabled={studentData.data ? true : false}
                        value={
                          branchDropDown?.find(
                            ({ value }) => value === formData.branch_id
                          )! ?? null
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                branch_id: newValue?.value!,
                              };
                            });
                          } else {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                branch_id: 0,
                                class_id: 0,
                                semester_id: 0,
                              };
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            required={!formData?.branch_id && USE_BRANCH_KEY}
                            {...params}
                            fullWidth
                            classes={{ root: textClasses.formControlRoot }}
                          />
                        )}
                      />
                    </div>
                  ) : null}
                  {USE_CLASS_KEY ? (
                    <div className="label-grid">
                      <Label>{classLabel}</Label>
                      <Autocomplete
                        classes={
                          meta.errors.class_id ? classes : requiredClasses
                        }
                        popupIcon={
                          <img
                            src={DownArrow}
                            alt="/"
                            className="down-arrow-autocomplete"
                          />
                        }
                        options={classDropDown!}
                        openOnFocus
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            if (formData.class_id) handleMUISelectEvent(e);
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                class_id: 0,
                                semester_id: 0,
                              };
                            });
                          }
                        }}
                        disabled={studentData.data ? true : false}
                        value={
                          classDropDown?.find(
                            ({ value }) => value === formData.class_id
                          )! ?? null
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                class_id: newValue?.value!,
                              };
                            });
                          } else {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                class_id: 0,
                                semester_id: 0,
                              };
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            required={!formData?.class_id && USE_CLASS_KEY}
                            {...params}
                            classes={{ root: textClasses.formControlRoot }}
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  ) : null}

                  {USE_SEMESTER_KEY ? (
                    <div className="label-grid">
                      <Label>{semesterLabel}</Label>
                      <Autocomplete
                        classes={
                          meta.errors.semester_id ? classes : requiredClasses
                        }
                        popupIcon={
                          <img
                            src={DownArrow}
                            alt="/"
                            className="down-arrow-autocomplete"
                          />
                        }
                        options={semesterDropDown!}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            if (formData.semester_id) handleMUISelectEvent(e);
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                semester_id: 0,
                              };
                            });
                          }
                        }}
                        openOnFocus
                        disabled={studentData.data ? true : false}
                        value={
                          semesterDropDown?.find(
                            ({ value }) => value === formData.semester_id
                          )! ?? null
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                semester_id: newValue?.value!,
                              };
                            });
                          } else {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                semester_id: 0,
                              };
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            required={
                              !formData?.semester_id && USE_SEMESTER_KEY
                            }
                            {...params}
                            fullWidth
                            classes={{ root: textClasses.formControlRoot }}
                          />
                        )}
                      />
                    </div>
                  ) : null}
                  {USE_CATEGORY_KEY ? (
                    <div className="label-grid">
                      <Label>{categoryLabel}</Label>
                      <Autocomplete
                        classes={
                          meta.errors.category_id ? classes : requiredClasses
                        }
                        popupIcon={
                          <img
                            src={DownArrow}
                            alt="/"
                            className="down-arrow-autocomplete"
                          />
                        }
                        options={categoryDropDown!}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            if (formData.category_id) handleMUISelectEvent(e);
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                category_id: 0,
                              };
                            });
                          }
                        }}
                        openOnFocus
                        disabled={studentData.data ? true : false}
                        value={
                          categoryDropDown?.find(
                            ({ value }) => value === formData.category_id
                          )! ?? null
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                category_id: newValue?.value!,
                              };
                            });
                          } else {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                category_id: 0,
                              };
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            required={
                              !formData?.category_id && USE_CATEGORY_KEY
                            }
                            {...params}
                            fullWidth
                            classes={{ root: textClasses.formControlRoot }}
                          />
                        )}
                      />
                    </div>
                  ) : null}
                  <div className="date-input label-grid">
                    <Label>DOA</Label>
                    <InputStory
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === Keys.ENTER) {
                          saveRef?.current?.focus();
                        }
                      }}
                      value={formData.doa}
                      name="doa"
                      inputRef={dateOfAdmissionRef!}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleValueChange(e);
                        meta.handleChange(e);
                      }}
                      type="date"
                      max={toInputStandardDate(serverDate)}
                    />
                  </div>
                  {component}
                </div>
              </div>
              <Button mode="save" type="submit" buttonref={saveRef!} />
              <Button mode="clear" type="button" onClick={handleClear} />
              {operation === Operation.UPDATE ? (
                <Button
                  mode="cancel"
                  type="button"
                  onClick={() => setModalFlag(false)}
                />
              ) : (
                <>
                  <Button
                    type="button"
                    onClick={() => setStudentEnquiryModal(!studentEnquiryModal)}
                  >
                    <img src={Fetch} alt="/" />
                    Fetch Student Enquiries
                  </Button>
                  <Button
                    mode="back"
                    type="button"
                    onClick={() =>
                      navigate(`/${InstId}/admissions/students/reservations`)
                    }
                  />
                </>
              )}
            </Form>
          );
        }}
      </Formik>

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={creationLoading} />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentEnquiryModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentEnquiry
              pageType={PageFor.MODAL}
              setModalFlag={setStudentEnquiryModal}
              queryType={StdEnqQueryType.STD_ENQ_NOT_ADMITTED}
              shouldDispatchId={true}
            />
            <Button
              mode="cancel"
              onClick={() => setStudentEnquiryModal(!studentEnquiryModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setStudentEnquiryModal(!studentEnquiryModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StudentReservation;
