import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Title } from "../../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { TableHeaderProps } from "../../../../Types/Tables";
import { HRTitleProps } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import Modal from "react-modal";
import {
  EditRuleStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import { Label } from "../../../../stories/Label/Label";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import Avatar from "../../../../images/Avatar.svg";
import DataFetch from "../../../../images/Edit.svg";
import Close from "../../../../images/Close.svg";
import EmployeeList from "../../Employee/List/Index";
import {
  Direction,
  Operation,
  PageFor,
  ReturnType,
  SortBy,
} from "../../../../utils/Enum.types";

import {
  editRuleFormData,
  msgType,
  optionsType,
  responseType,
} from "../../../../utils/Form.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";

//Types
import {
  formatter,
  toInputStandardDate,
  toStandardCase,
} from "../../../../utils/UtilFunctions";
import {
  AUTOCOMPLETE_INPUT_CLASSNAME,
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import usePayRollSalaryStructure from "../../hooks/usePayRollSalaryStucture";
import { LabelNameProps } from "../../../../Types/Labels";
import CalculationModal from "./CalculationModal";
import { Keys } from "../../../../utils/Enum.keys";
import { useParams } from "react-router";
import MessageModal from "../../../../pages/MessageModal";
import LoadingModal from "../../../../pages/LoadingModal";

import useEmployee, {
  empQueryTypes,
  PrEmpQueryType,
} from "../../hooks/useEmployee";
import {
  GetPayRollEmp,
  GetPayRollEmpSalaryStruture,
} from "../../queries/employee/query";
import { GetPayRollSalaryStructMastersAndDetails } from "../../queries/salary/list";
import { CreatePayRollEmpSalaryStructMasterAndDetails } from "../../queries/salary/mutation";
import {
  DeletePayRollEmpSalaryStructMasterAndDetails,
  UpdatePayRollEmpSalaryStructMasterAndDetails,
} from "../../queries/employee/mutation";
import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";
import {
  applyHeadsOn,
  calculationType,
  OverAllSalaryLedgerPageType,
  PayRollConfigKeys,
  SalaryCalculationConfig,
  SalaryLedgerType,
} from "../../enums/Enum.types";
import OverAllStructure from "./OverAllStructure";
import EditProfile from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";
import DeleteModal from "../../../../pages/DeleteModal";
import {
  calculationMethodModal,
  IncomeCalculatorType,
} from "../../Types/dataTypes";
import {
  checkForFlags,
  chooseAmtBasedOnType,
  convertGroupIdsToString,
  findApplyHeadsBasedOnGroupIds,
  findEarningsForExpressionString,
  getGroupIds,
  handleEarning,
  incomeArrayToRule,
} from "../../utils/Functions";
import { ESI, ESI_LIMIT } from "../../constants";
import useEmpDetailsById from "../../hooks/useEmpDetailsById";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useCheckAllocationType from "../../../Academics/hooks/useCheckAllocationType";

const { HRTitles } = require("../../json/title.json");
const { HR_Table } = require("../../json/table.json");
const { HRFormLabels } = require("../../json/form.json");

export enum Rules {
  FLR = "FLR",
  UDV = "UDV",
  UDM = "UDVM",
  RULE_ON_SALARY = "ROS",
}
const RuleOptions = [
  { label: "Flat Rate", value: Rules.FLR },
  { label: "User Defined Value (Fixed)", value: Rules.UDV },
  { label: "User Defined Value (Monthly)", value: Rules.UDM },
  { label: "Rule On Basic Salary", value: Rules.RULE_ON_SALARY },
];
export const getRule = (data: PayRollSalaryStructDetails) => {
  let rule = EMPTY_STRING;
  if (data.sal_str_earn_flat_rate > 0)
    rule = "FLR :" + data.sal_str_earn_flat_rate;
  if (data.sal_str_earn_basic_max_to > 0)
    rule =
      rule + "; Value limit below basic :" + +data.sal_str_earn_basic_max_to;
  if (data.sal_str_earn_restrict_to > 0)
    rule = rule + "; Value restricted to :" + data.sal_str_earn_restrict_to;

  if (data.sal_str_earn_UDM_val > 0) rule = "UDV :" + data.sal_str_earn_UDM_val;
  if (data.sal_str_ded_is_UDM) rule = "UDVM";
  if (data.sal_str_ded_rule) rule = data.sal_str_ded_rule;
  if (data.sal_str_earn_rule) rule = data.sal_str_earn_rule;

  return rule;
};
export interface PayRollEmpSalaryStructMaster {
  id: number;
  emp_sal_str_created_date: string;
  emp_sal_str_last_edit_date: string;
  emp_sal_str_basic: number;
  emp_sal_str_total_earnings: number;
  emp_sal_str_total_deductions: number;
  emp_sal_str_net: number;
  emp_sal_str_net_is_roundoff: boolean;
  pr_emp_id: number;
  pr_sal_str_master_id: number;
  salary_str_details: PayRollEmpSalaryStructDetails[];
}

export interface salaryLedgerDetails {
  id: number;
  sal_ldgr_desc: string;
  sal_ldgr_short_desc: string;
  sal_ldgr_idx: number;
  sal_ldgr_earn_deduct: string;
  sal_ldgr_type: string;
  sal_ldgr_is_system_generated: boolean;
  sal_ldgr_is_active: boolean;
  sal_ldgr_round_to_next_int: boolean;
  sal_ldgr_is_basic: boolean;
  sal_ldgr_remove_decimal: boolean;

  inst_id: number;
}
export interface PayRollEmpSalaryStructDetails {
  id: number;
  emp_sal_sl: number;
  emp_sal_ldgr_value: number;
  emp_sal_ldgr_sl: number;
  emp_sal_earn_ded: string;
  emp_sal_earn_type: string;
  emp_sal_earn_flat_rate: number;
  emp_sal_earn_is_UDM: boolean;
  emp_sal_earn_UDM_val: number;
  emp_sal_earn_restrict_to: number;
  emp_sal_earn_to_min_basic: number;
  emp_sal_earn_rule_apply: boolean;
  emp_sal_earn_rule: string;
  emp_sal_group_id_heads: string;
  emp_sal_remaining_amt_rule: boolean;
  emp_sal_ded_is_UDM: boolean;
  emp_sal_ded_UDM_value: number;
  emp_sal_ded_is_applied_on_heads: boolean;
  emp_sal_ded_on_heads: string;
  emp_sal_ded_to_max_value: number;
  emp_sal_ded_rule: string;
  pr_sal_mk_emp_master_id: number;
  pr_sal_ldgr_id: number;
  sal_ldgr_details: salaryLedgerDetails;
}
export interface PayRollSalaryStructDetails {
  id: number;
  sal_str_sl: number;
  sal_str_earn_ded: SalaryLedgerType;
  sal_str_earn_type: string;
  sal_str_earn_flat_rate: number;
  sal_str_earn_is_UDM: boolean;
  sal_str_earn_UDM_val: number;
  sal_str_earn_restrict_to: number;
  sal_str_earn_basic_max_to: number;
  sal_str_earn_rule_apply: boolean;
  sal_str_earn_rule: string;
  sal_str_ded_is_UDM: boolean;
  sal_str_ded_UDM_val: number;
  sal_str_ded_is_applied_on_heads: boolean;
  sal_str_ded_heads: applyHeadsOn;
  sal_str_ded_rule: string;
  sal_str_ded_to_max_value: number;
  sal_str_group_id_heads: string;
  pr_sal_str_master_id: number;
  sal_remaining_amt_rule: boolean;
  pr_sal_ldgr_id: number;
  sal_ldgr_details: salaryLedgerDetails;
}
export interface PayRollSalaryStructMaster {
  id: number;
  sal_str_desc: string;
  sal_str_assigned_to_all: boolean;
  sal_str_in_use: boolean;
  inst_id: number;
  pr_acd_yr_id: number;
  pr_category_id: number;
  pr_dept_id: number;
  pr_designation_id: number;
}
export interface GetPayRollSalaryStructDetailsByMasterIdList {
  master_data: PayRollSalaryStructMaster;
  details_data: PayRollSalaryStructDetails[];
}
export interface GetPayRollSalaryStructDetailsByMasterIdData {
  GetPayRollSalaryStructMastersAndDetails: GetPayRollSalaryStructDetailsByMasterIdList;
}

export interface GetPayRollSalaryStructDetailsByMasterIdVars {
  token: string;
  inst_id: string;
  pr_sal_str_master_id: number;
}

export interface LedgersListType {
  id: number;
  ledger_type: SalaryLedgerType;
  earn_type: string;
  ledger_desc: string;
  ledger_applied_to: applyHeadsOn;
  ledger_rule: string;
  ledger_udvMonthly: boolean;
  ledger_applied_on_heads: boolean;
  earnings: number;
  deductions: number;
  earnedValueFixedBelowBasic: number;
  earnedValueRestrictedTo: number;
  callToMaxValue: number;
  flat_rate: number;
  udv: number;
  sal_ldgr_round_to_next_int: boolean;
  sal_ldgr_remove_decimal: boolean;
  remaining_balance: boolean;
  emp_sal_str_sl: number;
  sal_ldgr_is_basic: boolean;
  emp_sal_str_ldgr_sl: number;
  isChecked?: boolean;
  selectedForDeductions?: boolean;
  groupLedgers?: number[] | null;
  rule_applied?: boolean;
  from_sal_process?: boolean;
}
export interface EmpSalaryDetailsOutput {
  emp_sal_master_details: PayRollEmpSalaryStructMaster;
  emp_sal_details: PayRollEmpSalaryStructDetails[];
}

export interface GetPayRollEmpSalaryStrutureData {
  GetPayRollEmpSalaryStruture: EmpSalaryDetailsOutput;
}
export interface GetPayRollEmpSalaryStrutureVars {
  token: string;
  inst_id: string;
  pr_emp_id: number;
}
interface editModalType {
  flag: boolean;
  id: number;
}
export interface udvMonthlyType {
  index: number;
  value: string;
}

export const getEmpRule = ({
  emp_sal_earn_flat_rate,
  emp_sal_earn_to_min_basic,
  emp_sal_earn_restrict_to,
  emp_sal_earn_UDM_val,
  emp_sal_ded_is_UDM,
  emp_sal_ded_rule,
  emp_sal_earn_rule,
}: PayRollEmpSalaryStructDetails) => {
  const rules = [
    {
      condition: emp_sal_earn_flat_rate,
      rule: `FLR: ${emp_sal_earn_flat_rate}`,
    },
    {
      condition: emp_sal_earn_to_min_basic,
      rule: `Value limit below basic: ${emp_sal_earn_to_min_basic}`,
    },
    {
      condition: emp_sal_earn_restrict_to,
      rule: `Value restricted to: ${emp_sal_earn_restrict_to}`,
    },
    {
      condition: emp_sal_earn_UDM_val,
      rule: `UDV: ${emp_sal_earn_UDM_val}`,
    },
    { condition: emp_sal_ded_is_UDM, rule: "UDVM" },
    { condition: emp_sal_earn_rule, rule: emp_sal_earn_rule },
    { condition: emp_sal_ded_rule, rule: emp_sal_ded_rule },
  ];

  const matchingRule = rules.find(({ condition }) => condition);
  return matchingRule ? matchingRule.rule : EMPTY_STRING;
};

const AssignSalaryStructure = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const ruleStyles = formAutoCompleteStyles();
  const ruleTextStyles = formAutoCompleteTextStyles();
  //Token and Others
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const [salaryStructureId, setSalaryStructureId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );

  const { format } = formatter;
  const [ledgers, setLedgers] = useState<LedgersListType[]>([]);
  const [searchSalaryStructure, setSearchSalaryStructure] = useState("");
  const [udvMonthly, setUdvMonthly] = useState<udvMonthlyType>();
  const [searchEmployee, setSearchEmployee] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { state, dispatch } = useContext(AppContext);
  const [editModal, setEditModal] = useState<editModalType>();
  const [operation, setOperation] = useState<Operation>(Operation.CREATE);
  const [amount, setAmount] = useState(0);
  const [employeeModal, setEmployeeModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ledgerId, setLedgerId] = useState(0);
  const [editRuleFormData, setEditRuleFormData] = useState<editRuleFormData>({
    rule: EMPTY_STRING,
    percentage: 0,
    earned_value_restricted_to: 0,
    value_limit_below_basic: 0,
    udv: 0,
    rule_expression: EMPTY_STRING,
  });
  const [calculationMethod, setCalculationMethod] =
    useState<calculationMethodModal>();
  const [incomeCalculatorItems, setIncomeCalculatorItems] = useState<
    IncomeCalculatorType[]
  >([
    {
      calculation_type: calculationType.Percentage,
      from_amt: 0,
      id: 1,
      income_value_basis: 0,
      upto_amt: 0,
    },
  ]);

  // useRefs
  const empIdRef = useRef<HTMLSelectElement>(null);
  const empIdInputRef = empIdRef.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;
  const salaryStructureRef = useRef<HTMLSelectElement>(null);
  const salaryStructureInputRef =
    salaryStructureRef.current?.getElementsByClassName(
      AUTOCOMPLETE_INPUT_CLASSNAME
    )[0] as HTMLInputElement;
  const amountRef = useRef<HTMLInputElement>(null);
  const amountInputRef = amountRef.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;

  const { flag } = useCheckAllocationType();
  //Custom Hooks
  const { empDetails } = useEmployee(
    ROWS_PER_PAGE,
    operation === Operation.CREATE
      ? empQueryTypes.SAL_STR_NOT_ASSGINED
      : empQueryTypes.SAL_STR_ASSGINED,
    searchEmployee,
    state.employeeId
  );
  const { configData } = useSwConfigData(
    PayRollConfigKeys.SALARY_CALCULATIONS_BASED_ON
  );

  const salaryConfigKey = configData.data
    ? configData.data?.GetSwConfigVariables[0].config_string_value
    : EMPTY_STRING;
  const { configData: salRoundOffDecimal } = useSwConfigData(
    PayRollConfigKeys.ROUND_TO_DECIMAL
  );
  const salRoundOffKey = salRoundOffDecimal.data
    ? salRoundOffDecimal.data?.GetSwConfigVariables[0].config_boolean_value
    : false;
  const { employeeFormData } = useEmpDetailsById();
  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();
  const [GetEmployeeDataSalaryStructDetails] = useLazyQuery<
    GetPayRollEmpSalaryStrutureData,
    GetPayRollEmpSalaryStrutureVars
  >(GetPayRollEmpSalaryStruture);
  const [GetSalaryStructureDetails] = useLazyQuery<
    GetPayRollSalaryStructDetailsByMasterIdData,
    GetPayRollSalaryStructDetailsByMasterIdVars
  >(GetPayRollSalaryStructMastersAndDetails);
  const [createPayRollEmpSalary, { loading: creationLoading }] = useMutation(
    CreatePayRollEmpSalaryStructMasterAndDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [updatePayRollEmpSalary, { loading: updationLoading }] = useMutation(
    UpdatePayRollEmpSalaryStructMasterAndDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [deletePayRollEmpSalary, { loading: deletionLoading }] = useMutation(
    DeletePayRollEmpSalaryStructMasterAndDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const {
    salaryStructMasters: { dropDown: salaryStructureDropdown },
  } = usePayRollSalaryStructure(
    ReturnType.RESPONSE_TYPE,
    searchSalaryStructure
  );

  const getIndex = (value: number) => {
    return ledgers?.indexOf(ledgers?.find(({ id }) => id === value)!);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditRuleFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const calculationMethodCancel = () => {
    setIncomeCalculatorItems([
      {
        calculation_type: calculationType.Percentage,
        from_amt: 0,
        id: 1,
        income_value_basis: 0,
        upto_amt: 0,
      },
    ]);
    setCalculationMethod({
      flag: false,
      id: 0,
      applyOnHeads: applyHeadsOn.BASIC_SALARY,
    });
  };
  const setRuleOnSalary = () => {
    const index = getIndex(calculationMethod?.id!);
    ledgers[index].ledger_rule = incomeArrayToRule(incomeCalculatorItems);
    if (ledgers[index].ledger_type === SalaryLedgerType.EARNING)
      ledgers[index].earnings = findEarningsForExpressionString(
        incomeArrayToRule(incomeCalculatorItems),
        amount,
        salRoundOffKey
      );
    else
      ledgers[index].deductions = findEarningsForExpressionString(
        incomeArrayToRule(incomeCalculatorItems),
        amount,
        salRoundOffKey
      );
    setCalculationMethod({
      flag: false,
      id: 0,
      applyOnHeads: applyHeadsOn.BASIC_SALARY,
    });
    setEditModal({
      flag: false,
      id: 0,
    });
  };

  const saveRule = () => {
    const index = getIndex(editModal?.id!);
    let rule = EMPTY_STRING;
    if (editRuleFormData.rule === Rules.FLR) {
      let newEarning = 0;
      let newBasicSalary = 0;
      newBasicSalary = amount;
      rule = Rules.FLR + " :" + editRuleFormData.percentage;
      ledgers[index].flat_rate = editRuleFormData.percentage;
      if (editRuleFormData.value_limit_below_basic > 0) {
        rule +=
          "; Value limit below basic :" +
          editRuleFormData.value_limit_below_basic;
        ledgers[index].earnedValueFixedBelowBasic =
          editRuleFormData.value_limit_below_basic;
        if (newBasicSalary > editRuleFormData.value_limit_below_basic) {
          ledgers[index].earnedValueFixedBelowBasic =
            editRuleFormData.value_limit_below_basic;
          newBasicSalary = editRuleFormData.value_limit_below_basic;
        }
      }
      newEarning = (editRuleFormData.percentage / 100) * newBasicSalary;
      if (editRuleFormData.earned_value_restricted_to > 0) {
        rule +=
          "; Value restricted to :" +
          editRuleFormData.earned_value_restricted_to;
        ledgers[index].earnedValueRestrictedTo =
          editRuleFormData.earned_value_restricted_to;
        if (amount > editRuleFormData.earned_value_restricted_to) {
          ledgers[index].earnedValueRestrictedTo =
            editRuleFormData.earned_value_restricted_to;
          newEarning = editRuleFormData.earned_value_restricted_to;
        }
      }
      ledgers[index].earn_type = Rules.FLR;
      ledgers[index].earnings = newEarning;
    }

    if (editRuleFormData.rule === Rules.UDV) {
      rule = Rules.UDV + " :" + editRuleFormData.udv;
      ledgers[index].earn_type = Rules.UDV;
      ledgers[index].udv = editRuleFormData.udv;
      ledgers[index].earnings = editRuleFormData.udv;
    }
    if (editRuleFormData.rule === Rules.UDM) {
      rule = Rules.UDM + " :" + editRuleFormData.udv;
      ledgers[index].earn_type = Rules.UDM;
      ledgers[index].udv = editRuleFormData.udv;
      ledgers[index].earnings = editRuleFormData.udv;
    }
    ledgers[index].ledger_rule = rule;

    setEditModal({
      flag: false,
      id: 0,
    });
  };

  const total = ledgers.reduce(
    (result, ledger) => {
      result.totalEarning += ledger.earnings;
      result.totalDeduction += ledger.deductions;
      return result;
    },
    {
      totalEarning: 0,
      totalDeduction: 0,
    }
  );

  const amtChange = () => {
    const totalEarning = ledgers.reduce((result, ledger) => {
      const configAmount = chooseAmtBasedOnType(
        ledger.sal_ldgr_is_basic,
        ledgers,
        salaryConfigKey,
        salRoundOffKey,
        amount
      );
      if (ledger.sal_ldgr_is_basic) {
        result += configAmount;
      } else {
        result += handleEarning(ledger, salRoundOffKey, configAmount);
      }
      return result;
    }, 0);

    const response = ledgers?.map((data, index) => {
      const configAmount = chooseAmtBasedOnType(
        data.sal_ldgr_is_basic,
        ledgers,
        salaryConfigKey,
        salRoundOffKey,
        amount
      );

      if (data.sal_ldgr_is_basic) {
        data.earnings = checkForFlags(data, salRoundOffKey, configAmount);
      } else if (data.remaining_balance) {
        ledgers[index].earnings = checkForFlags(
          data,
          salRoundOffKey,
          amount - totalEarning
        );
      } else {
        ledgers[index].earnings = handleEarning(
          data,
          salRoundOffKey,
          configAmount
        );
      }

      if (
        data.earn_type === Rules.RULE_ON_SALARY &&
        data.ledger_type === SalaryLedgerType.EARNING
      ) {
        ledgers[index].earnings = checkForFlags(
          data,
          salRoundOffKey,
          findEarningsForExpressionString(
            data.ledger_rule,
            amount,
            salRoundOffKey
          )
        );
      }
      if (data.ledger_type === SalaryLedgerType.DEDUCTION) {
        const appliedOnHead = findApplyHeadsBasedOnGroupIds(data.groupLedgers!);

        const ledgerIdSet = new Set(data.groupLedgers);
        let salary = 0;

        switch (appliedOnHead) {
          case applyHeadsOn.BASIC_SALARY:
            salary = configAmount;
            break;
          case applyHeadsOn.GROSS_SALARY:
            salary =
              salaryConfigKey === SalaryCalculationConfig.BASIC
                ? totalEarning
                : amount;
            break;
          case applyHeadsOn.ON_GROUP_OF_LEDGERS:
            salary = ledgers.reduce((result, ledger) => {
              if (ledgerIdSet.has(ledger.id)) {
                if (ledger.remaining_balance) {
                  result += amount - totalEarning;
                } else if (ledger.sal_ldgr_is_basic) {
                  result += configAmount;
                } else {
                  result += handleEarning(ledger, salRoundOffKey, configAmount);
                }
              }
              return result;
            }, 0);
            break;
        }
        const finalSal = ledgers[index].callToMaxValue
          ? ledgers[index].callToMaxValue
          : salary;

        ledgers[index].deductions = checkForFlags(
          data,
          salRoundOffKey,
          findEarningsForExpressionString(
            data.ledger_rule,
            finalSal,
            salRoundOffKey
          )
        );
      }

      return data;
    });

    setLedgers(response);
  };

  const handleSubmit = () => {
    const input = {
      emp_sal_master: {
        emp_sal_str_basic: chooseAmtBasedOnType(
          true,
          ledgers,
          salaryConfigKey,
          salRoundOffKey,
          amount
        ),
        emp_sal_str_total_earnings: total.totalEarning,
        emp_sal_str_total_deductions: total.totalDeduction,
        emp_sal_str_net: total.totalEarning - total.totalDeduction,
        emp_sal_str_net_is_roundoff: false,
        pr_sal_str_master_id: salaryStructureId.value,
      },
      emp_sal_details: ledgers?.map((data) => {
        return {
          emp_sal_sl: data.emp_sal_str_sl,
          emp_sal_ldgr_value:
            data.ledger_type === SalaryLedgerType.EARNING
              ? data.earnings
              : data.deductions,
          emp_sal_ldgr_sl: data.emp_sal_str_ldgr_sl,
          emp_sal_earn_ded: data.ledger_type,
          emp_sal_earn_type: data.earn_type,
          emp_sal_earn_flat_rate: data.flat_rate,
          emp_sal_earn_is_UDM:
            data.ledger_type === SalaryLedgerType.EARNING
              ? data.ledger_udvMonthly
              : false,
          emp_sal_remaining_amt_rule: data.remaining_balance,
          emp_sal_earn_UDM_val: data.udv,
          emp_sal_earn_restrict_to: data.earnedValueRestrictedTo,
          emp_sal_earn_to_min_basic: data.earnedValueFixedBelowBasic,
          emp_sal_earn_rule_apply: false,
          emp_sal_earn_rule: EMPTY_STRING,
          emp_sal_ded_is_UDM:
            data.ledger_type === SalaryLedgerType.DEDUCTION
              ? data.ledger_udvMonthly
              : false,
          emp_sal_ded_UDM_value: data.udv,
          emp_sal_ded_is_applied_on_heads: data.ledger_applied_on_heads,
          emp_sal_ded_on_heads: data.ledger_applied_to,
          emp_sal_ded_to_max_value: data.callToMaxValue,
          emp_sal_ded_rule: data.ledger_rule,
          pr_sal_ldgr_id: data.id,
          emp_sal_group_id_heads: data.groupLedgers
            ? convertGroupIdsToString(data.groupLedgers!)
            : "1;",
        };
      }),
    };

    if (operation === Operation.CREATE)
      createPayRollEmpSalary({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          pr_emp_id: state.employeeId,
          input,
        },
        refetchQueries: [
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.EMP_SAL_STR_NOT_ASSGINED,
              },
              per_std_subj_allocation: flag,
              name: EMPTY_STRING,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              employeeId: null,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
            },
          },
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.EMP_SAL_STR_ASSGINED,
              },
              per_std_subj_allocation: flag,
              name: EMPTY_STRING,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              employeeId: null,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          dispatch({
            type: payloadTypes.SET_EMPLOYEE_ID,
            payload: { employeeId: 0 },
          });
          setMessage({
            flag: true,
            message: "Salary structure assigned successfully",
            operation: Operation.CREATE,
          });
        }
      });

    if (operation === Operation.UPDATE)
      updatePayRollEmpSalary({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          pr_emp_id: state.employeeId,
          input,
        },
        refetchQueries: [
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.EMP_SAL_STR_ASSGINED,
              },
              per_std_subj_allocation: flag,
              name: EMPTY_STRING,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              employeeId: null,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
            },
          },
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.EMP_SAL_STR_NOT_ASSGINED,
              },
              per_std_subj_allocation: flag,
              name: EMPTY_STRING,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              employeeId: null,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Salary structure Updated successfully",
            operation: Operation.UPDATE,
          });
        }
      });
  };
  const handleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    if (operation === Operation.UPDATE) {
      setLedgers(ledgers.filter(({ id }) => id !== ledgerId));
    }
    if (operation === Operation.DELETE) {
      deletePayRollEmpSalary({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          pr_emp_id: state.employeeId,
        },

        refetchQueries: [
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.EMP_SAL_STR_ASSGINED,
              },
              per_std_subj_allocation: flag,
              name: EMPTY_STRING,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
            },
          },
          {
            query: GetPayRollEmp,
            variables: {
              after: null,
              direction: Direction.ASC,
              first: ROWS_PER_PAGE,
              input: {
                ids: [Number(InstId)],
                pr_emp_query_type: PrEmpQueryType.EMP_SAL_STR_NOT_ASSGINED,
              },
              per_std_subj_allocation: flag,
              name: EMPTY_STRING,
              departmentId: null,
              designationId: null,
              categoryId: null,
              gradeId: null,
              jobTypeId: null,
              sortBy: SortBy.EMP_FIRST_NAME,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Salary structure Deleted successfully",
            operation: Operation.DELETE,
          });
        }
      });
    }
  };
  const handleClear = () => {
    dispatch({
      type: payloadTypes.SET_EMPLOYEE_ID,
      payload: { employeeId: 0 },
    });
    setLedgers([]);
    setAmount(0);
    setSalaryStructureId(EMPTY_RESPONSETYPE_OBJECT);
    empIdInputRef.select();
  };
  const handleClose = () => {
    handleClear();
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const checkESIFilter = (data: LedgersListType) => {
    if (data.ledger_type === SalaryLedgerType.DEDUCTION) {
      if (data.ledger_desc === ESI) {
        if (total.totalEarning > 0 && total.totalEarning <= ESI_LIMIT) {
          return data;
        } else if (total.totalEarning < ESI_LIMIT) {
          return false;
        }
      } else {
        return data;
      }
    } else {
      return true;
    }
  };

  const getExistingStrutureDetails = (value: number) => {
    GetSalaryStructureDetails({
      variables: {
        token,
        inst_id: InstId!,
        pr_sal_str_master_id: value,
      },
    }).then(({ data }) => {
      if (data) {
        setLedgers(
          data?.GetPayRollSalaryStructMastersAndDetails.details_data.map(
            (data) => {
              return {
                id: data.pr_sal_ldgr_id,
                ledger_type: data.sal_str_earn_ded as SalaryLedgerType,
                earn_type: data.sal_str_earn_type,
                ledger_desc: data.sal_ldgr_details.sal_ldgr_desc,
                ledger_applied_to: data.sal_str_ded_heads,
                ledger_rule: getRule(data),
                ledger_udvMonthly:
                  data.sal_str_earn_ded === SalaryLedgerType.EARNING
                    ? data.sal_str_earn_is_UDM
                    : data.sal_str_ded_is_UDM,
                earnings: 0,
                deductions: 0,
                earnedValueFixedBelowBasic: data.sal_str_earn_basic_max_to,
                earnedValueRestrictedTo: data.sal_str_earn_restrict_to,
                callToMaxValue: data.sal_str_ded_to_max_value,
                flat_rate: data.sal_str_earn_flat_rate,
                remaining_balance: data.sal_remaining_amt_rule,
                sal_ldgr_remove_decimal:
                  data.sal_ldgr_details.sal_ldgr_remove_decimal,
                sal_ldgr_round_to_next_int:
                  data.sal_ldgr_details.sal_ldgr_round_to_next_int,
                udv:
                  data.sal_str_earn_type === Rules.UDM
                    ? 0
                    : data.sal_str_earn_UDM_val,
                ledger_applied_on_heads: data.sal_str_ded_is_applied_on_heads,
                emp_sal_str_sl: data.sal_str_sl,
                emp_sal_str_ldgr_sl: data.sal_ldgr_details.sal_ldgr_idx,
                sal_ldgr_is_basic: data.sal_ldgr_details.sal_ldgr_is_basic,
                isChecked: false,
                selectedForDeductions: false,
                groupLedgers: getGroupIds(data.sal_str_group_id_heads),
              };
            }
          )
        );
      }
    });
  };
  useEffect(() => {
    if (state.employeeId) {
      if (operation !== Operation.CREATE) {
        GetEmployeeDataSalaryStructDetails({
          variables: {
            token,
            inst_id: InstId!,
            pr_emp_id: state.employeeId,
          },
        }).then(({ data }) => {
          if (data) {
            setSalaryStructureId(
              salaryStructureDropdown?.find(
                ({ value }) =>
                  value ===
                  data.GetPayRollEmpSalaryStruture.emp_sal_master_details
                    .pr_sal_str_master_id
              )!
            );

            setLedgers(
              data?.GetPayRollEmpSalaryStruture?.emp_sal_details?.map(
                (data) => {
                  return {
                    id: data.pr_sal_ldgr_id,
                    ledger_type: data.emp_sal_earn_ded as SalaryLedgerType,
                    earn_type: data.emp_sal_earn_type,
                    ledger_desc: data.sal_ldgr_details?.sal_ldgr_desc,
                    ledger_applied_to:
                      data.emp_sal_ded_on_heads as applyHeadsOn,
                    ledger_rule: getEmpRule(data),
                    ledger_udvMonthly:
                      data.emp_sal_earn_ded === SalaryLedgerType.EARNING
                        ? data.emp_sal_earn_is_UDM
                        : data.emp_sal_ded_is_UDM,
                    earnings:
                      data.emp_sal_earn_ded === SalaryLedgerType.EARNING
                        ? data.emp_sal_ldgr_value
                        : 0,
                    deductions:
                      data.emp_sal_earn_ded === SalaryLedgerType.DEDUCTION
                        ? data.emp_sal_ldgr_value
                        : 0,

                    sal_ldgr_remove_decimal:
                      data.sal_ldgr_details.sal_ldgr_remove_decimal,
                    sal_ldgr_round_to_next_int:
                      data.sal_ldgr_details.sal_ldgr_round_to_next_int,
                    remaining_balance: data.emp_sal_remaining_amt_rule,
                    earnedValueFixedBelowBasic: data.emp_sal_earn_to_min_basic,
                    earnedValueRestrictedTo: data.emp_sal_earn_restrict_to,
                    callToMaxValue: data.emp_sal_ded_to_max_value,
                    flat_rate: data.emp_sal_earn_flat_rate,
                    udv:
                      data.emp_sal_earn_type === Rules.UDM
                        ? 0
                        : data.emp_sal_earn_UDM_val,
                    ledger_applied_on_heads:
                      data.emp_sal_ded_is_applied_on_heads,
                    emp_sal_str_sl: data.emp_sal_sl,
                    emp_sal_str_ldgr_sl: data.sal_ldgr_details.sal_ldgr_idx,
                    sal_ldgr_is_basic: data.sal_ldgr_details.sal_ldgr_is_basic,
                    groupLedgers:
                      data.sal_ldgr_details.sal_ldgr_earn_deduct ===
                      SalaryLedgerType.DEDUCTION
                        ? getGroupIds(data.emp_sal_group_id_heads)
                        : null,
                  };
                }
              )
            );
            setAmount(
              salaryConfigKey === SalaryCalculationConfig.BASIC
                ? data.GetPayRollEmpSalaryStruture.emp_sal_master_details
                    .emp_sal_str_basic
                : data.GetPayRollEmpSalaryStruture.emp_sal_master_details
                    .emp_sal_str_total_earnings
            );
          }
        });
      }
    }
  }, [
    state.employeeId,
    operation,
    InstId,
    GetEmployeeDataSalaryStructDetails,
    salaryStructureDropdown,
    token,
    salaryConfigKey,
  ]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {HRTitles.SalaryStructure.AssignSalaryStructure.map(
          (title: HRTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {operation === Operation.CREATE
                  ? title.Add
                  : operation === Operation.UPDATE
                  ? title.Edit
                  : operation === Operation.VIEW
                  ? title.View
                  : title.Delete}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="assign-salary-structure">
        <div className="row g-0 assign-salary-structure__details">
          <div className="col">
            <div className="assign-salary-structure__details--imagefield">
              <Autocomplete
                classes={classes}
                options={empDetails.responseType}
                openOnFocus
                value={
                  empDetails.responseType.find(
                    ({ value }) => value === state.employeeId
                  ) ?? null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: (newValue as responseType).value },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: 0 },
                    });
                  }
                }}
                ref={empIdRef}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: 0 },
                    });
                  }
                  if (e.key === Keys.ENTER) {
                    salaryStructureInputRef.focus();
                  }
                }}
                freeSolo
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="outlined-uncontrolled"
                    label="Employee ID"
                    autoFocus
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchEmployee(e.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
              <img
                className="data-fetch-icon"
                src={DataFetch}
                alt="/"
                onClick={() => setEmployeeModal(!employeeModal)}
              />
            </div>

            {USE_HR_DESIGNATION && (
              <TextField
                id="outlined-uncontrolled"
                className="assign-salary-structure__details--textfield"
                label="Designation"
                InputLabelProps={{ shrink: true }}
                value={employeeFormData?.designation}
                size="small"
                disabled
              />
            )}

            {USE_HR_JOBTYPE && (
              <TextField
                id="outlined-uncontrolled"
                className="assign-salary-structure__details--textfield"
                label="Job Type"
                value={employeeFormData?.job_type}
                InputLabelProps={{ shrink: true }}
                size="small"
                disabled
              />
            )}
          </div>
          <div className="col">
            <TextField
              id="outlined-uncontrolled"
              className="assign-salary-structure__details--textfield"
              label="Name"
              value={employeeFormData?.emp_name}
              InputLabelProps={{ shrink: true }}
              size="small"
              disabled
            />
            {USE_HR_DEPARTMENT && (
              <TextField
                id="outlined-uncontrolled"
                className="assign-salary-structure__details--textfield"
                label="Department"
                value={employeeFormData?.department}
                InputLabelProps={{ shrink: true }}
                size="small"
                disabled
              />
            )}
            <TextField
              id="outlined-uncontrolled"
              className="assign-salary-structure__details--textfield"
              label="Qualification"
              value={employeeFormData?.emp_qualification}
              InputLabelProps={{ shrink: true }}
              size="small"
              disabled
            />
          </div>
          <div className="col">
            {USE_HR_CATEGORY && (
              <TextField
                id="outlined-uncontrolled"
                className="assign-salary-structure__details--textfield"
                label="Category"
                value={employeeFormData?.category}
                InputLabelProps={{ shrink: true }}
                size="small"
                disabled
              />
            )}
            <TextField
              id="outlined-uncontrolled"
              className="assign-salary-structure__details--textfield"
              label="Year of Experience"
              value={employeeFormData?.emp_experience}
              InputLabelProps={{ shrink: true }}
              size="small"
              disabled
            />
            <TextField
              type="date"
              id="outlined-uncontrolled"
              className="assign-salary-structure__details--textfield--date"
              label="Date of Joining"
              value={toInputStandardDate(employeeFormData?.emp_doj)}
              InputLabelProps={{ shrink: true }}
              size="small"
              disabled
            />
          </div>
          <div className="col-1 assign-salary-structure__details--image">
            <img src={Avatar} alt="/" />
          </div>
        </div>

        <div className="assign-salary-structure__datablock">
          {state.employeeId ? (
            <div className="row g-0 assign-salary-structure__details--select">
              <div className="col-3">
                <Autocomplete
                  classes={classes}
                  options={salaryStructureDropdown!}
                  openOnFocus
                  ref={salaryStructureRef!}
                  disabled={operation === Operation.VIEW}
                  value={salaryStructureId}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSalaryStructureId(newValue as responseType);
                      getExistingStrutureDetails(
                        (newValue as responseType).value
                      );
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      amountInputRef.focus();
                    }
                  }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Salary Structure"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchSalaryStructure(e.target.value);
                      }}
                      fullWidth
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
              <div className="col-1"></div>

              <div className="col-3">
                <TextField
                  className="assign-salary-structure__textfield"
                  label={toStandardCase(salaryConfigKey)}
                  type="number"
                  ref={amountRef!}
                  InputLabelProps={{ shrink: true }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  disabled={
                    operation === Operation.VIEW ||
                    operation === Operation.DELETE
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setAmount(Number(e.target.value))
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      amtChange();
                    }
                  }}
                  value={amount}
                />
              </div>
            </div>
          ) : null}
          {operation === Operation.CREATE && state.employeeId ? (
            <OverAllStructure
              selectedOverAllLedger={ledgers}
              basicSalary={0}
              pageFor={OverAllSalaryLedgerPageType.ASSIGN_SALARY}
            />
          ) : null}
          {state.employeeId && operation !== Operation.CREATE ? (
            <div className="assign-salary-structure__tableblock">
              {salaryStructureId?.value ? (
                <TableContainer className="assign-salary-structure__table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {HR_Table.SalaryStructure.AssignSalaryStructure.Table_Headers.filter(
                          (th: TableHeaderProps) =>
                            (th.labelName !== "Actions" &&
                              operation !== Operation.UPDATE) ||
                            operation === Operation.UPDATE
                        ).map((th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell className={th.className} key={index}>
                              {th.labelName}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ledgers.filter(checkESIFilter)?.map((data, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              id="td-center"
                              className="assign-salary-structure__table--slno"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell className="assign-salary-structure__table--desc">
                              {data.ledger_desc}
                            </TableCell>
                            <TableCell className="assign-salary-structure__table--desc">
                              {data.ledger_applied_to}
                            </TableCell>
                            <TableCell className="assign-salary-structure__table--rule">
                              {data.ledger_rule}
                            </TableCell>
                            <TableCell
                              className="assign-salary-structure__table--amount"
                              id="td-right"
                            >
                              {data.ledger_udvMonthly &&
                              data.ledger_type === SalaryLedgerType.EARNING &&
                              operation === Operation.UPDATE ? (
                                <Input
                                  size="small"
                                  id="td-right"
                                  value={
                                    index === udvMonthly?.index
                                      ? udvMonthly?.value
                                      : data.udv
                                  }
                                  type="number"
                                  onFocus={(e) => {
                                    e.target.select();
                                    setUdvMonthly({
                                      index,
                                      value: e.target.value,
                                    });
                                  }}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setUdvMonthly({
                                      index: index,
                                      value: e.target.value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === Keys.ENTER) {
                                      if (
                                        data.remaining_balance === false &&
                                        data.ledger_udvMonthly
                                      ) {
                                        ledgers[index].udv =
                                          index === udvMonthly?.index
                                            ? Number(udvMonthly.value)
                                            : ledgers[index].udv;
                                      }
                                      ledgers[index].earnings =
                                        index === udvMonthly?.index
                                          ? Number(udvMonthly.value)
                                          : ledgers[index].earnings;
                                      setUdvMonthly({
                                        index: -1,
                                        value: "0",
                                      });
                                    }
                                  }}
                                />
                              ) : (
                                format(data.earnings)
                              )}
                            </TableCell>
                            <TableCell
                              className="assign-salary-structure__table--amount"
                              id="td-right"
                            >
                              {data.ledger_udvMonthly &&
                              data.ledger_type ===
                                SalaryLedgerType.DEDUCTION ? (
                                <Input
                                  size="small"
                                  value={
                                    index === udvMonthly?.index
                                      ? udvMonthly?.value
                                      : data.udv
                                  }
                                  id="td-right"
                                  onFocus={(e) => {
                                    e.target.select();
                                    setUdvMonthly({
                                      index,
                                      value: e.target.value,
                                    });
                                  }}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setUdvMonthly({
                                      index: index,
                                      value: e.target.value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === Keys.ENTER) {
                                      ledgers[index].udv =
                                        index === udvMonthly?.index
                                          ? Number(udvMonthly.value)
                                          : ledgers[index].udv;
                                      ledgers[index].deductions =
                                        index === udvMonthly?.index
                                          ? Number(udvMonthly.value)
                                          : ledgers[index].deductions;
                                      setLedgerId(data.id);
                                      setLedgers(ledgers);
                                    }
                                  }}
                                />
                              ) : (
                                format(data.deductions)
                              )}
                            </TableCell>
                            {operation === Operation.UPDATE ? (
                              <TableCell
                                className="assign-salary-structure__table--actions"
                                id="td-center"
                              >
                                {!data.sal_ldgr_is_basic &&
                                data.ledger_desc !== "PF" &&
                                data.ledger_desc !== "ESI" &&
                                data.ledger_desc !== "PT" ? (
                                  <>
                                    <img
                                      onClick={() => {
                                        if (
                                          data.ledger_type ===
                                          SalaryLedgerType.EARNING
                                        )
                                          setEditModal({
                                            flag: true,
                                            id: data.id,
                                          });
                                        else
                                          setCalculationMethod({
                                            flag: true,
                                            id: data.id,
                                            applyOnHeads:
                                              data.ledger_applied_to,
                                          });
                                      }}
                                      src={EditProfile}
                                      alt="/"
                                    />
                                    &nbsp;
                                    <img
                                      onClick={() => {
                                        setLedgerId(data.id);
                                        setDeleteModal(!deleteModal);
                                      }}
                                      src={Delete}
                                      alt="/"
                                    />
                                  </>
                                ) : null}
                              </TableCell>
                            ) : null}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={4} className="total ">
                          Total :
                        </TableCell>
                        <TableCell
                          className="totalcountcell assign-salary-structure__table--amount"
                          id="td-right"
                        >
                          {format(total.totalEarning)}
                        </TableCell>
                        <TableCell
                          className="balance-count assign-salary-structure__table--amount"
                          id="td-right"
                        >
                          {format(total.totalDeduction)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={5} className="total">
                          Net :
                        </TableCell>
                        <TableCell
                          className="totalcount assign-salary-structure__table--amount"
                          id="td-right"
                        >
                          {format(
                            total.totalEarning - total.totalDeduction > 0
                              ? total.totalEarning - total.totalDeduction
                              : 0
                          )}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              ) : null}
            </div>
          ) : null}
        </div>

        <div className="assign-salary-structure__buttons">
          {operation === Operation.CREATE ? (
            <>
              <Button
                disabled={amount === 0}
                onClick={handleSubmit}
                mode="assign-salary-structure"
              />

              <Button
                mode="edit"
                onClick={() => {
                  handleClear();
                  setOperation(Operation.UPDATE);
                }}
              />
              <Button
                mode="view"
                onClick={() => {
                  handleClear();
                  setOperation(Operation.VIEW);
                }}
              />
              <Button
                mode="delete"
                onClick={() => {
                  handleClear();
                  setOperation(Operation.DELETE);
                }}
              >
                &nbsp;Employee Salary Structure
              </Button>
            </>
          ) : null}
          {operation === Operation.UPDATE ? (
            <Button mode="save" onClick={handleSubmit} />
          ) : null}
          {operation === Operation.DELETE ? (
            <Button
              mode="delete"
              onClick={() => {
                setDeleteModal(!deleteModal);
              }}
            />
          ) : null}
          <Button mode="clear" />
          {operation !== Operation.CREATE && (
            <Button
              mode="back"
              onClick={() => {
                handleClear();
                setOperation(Operation.CREATE);
              }}
            />
          )}
        </div>
      </div>

      {/* edit Rule modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={editModal?.flag!}
        style={EditRuleStyles}
        ariaHideApp={false}
      >
        <Title variant="subtitle1">Edit Rule</Title>
        <div className="assign-salary-structure__edit-rule">
          <div className="assign-salary-structure__edit-rule--grid">
            <Label>Rule</Label>
            <Autocomplete
              classes={ruleStyles}
              options={RuleOptions}
              openOnFocus
              onChange={(e, newValue) => {
                if (newValue) {
                  setEditRuleFormData((prevValues) => ({
                    ...prevValues,
                    rule: (newValue as optionsType)?.value,
                  }));
                  if (
                    (newValue as optionsType)?.value === Rules.RULE_ON_SALARY
                  ) {
                    setCalculationMethod({
                      flag: true,
                      id: editModal?.id!,
                      applyOnHeads: ledgers?.find(
                        (data) => data.id === editModal?.id!
                      )?.ledger_applied_to!,
                    });
                  }
                }
              }}
              freeSolo
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  fullWidth
                  classes={{ root: ruleTextStyles.formControlRoot }}
                />
              )}
            />
          </div>

          {/* enable these three fields when value is Flat Rate and disable for other values */}
          {editRuleFormData.rule === Rules.FLR && (
            <div className="assign-salary-structure__edit-rule--grid">
              {HRFormLabels.Employee.EditRule.flat_rate?.map(
                (label: LabelNameProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      <Label>{label.LabelName}</Label>
                      <Input
                        name={label.inputName}
                        type="number"
                        onFocus={(e) => {
                          (e.target as HTMLInputElement)?.select();
                        }}
                        onChange={handleChange}
                        value={editRuleFormData[label.inputName]}
                      />
                    </React.Fragment>
                  );
                }
              )}
            </div>
          )}
          {/* disable when value is flatRate enable for other three fields */}
          {(editRuleFormData.rule === Rules.UDV ||
            editRuleFormData.rule === Rules.UDM) && (
            <div className="assign-salary-structure__edit-rule--grid">
              <Label>Enter Value</Label>
              <Input
                name="udv"
                type="number"
                onFocus={(e) => {
                  (e.target as HTMLInputElement)?.select();
                }}
                onChange={handleChange}
                value={editRuleFormData.udv}
              />
            </div>
          )}
        </div>

        <Button mode="save" onClick={saveRule} />
        <Button mode="clear" />
        <Button
          mode="cancel"
          onClick={() =>
            setEditModal({
              flag: false,
              id: 0,
            })
          }
        />
      </Modal>
      {/* employee details modal */}

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={employeeModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <EmployeeList
              pageType={PageFor.MODAL}
              setModalFlag={setEmployeeModal}
              queryType={
                operation === Operation.CREATE
                  ? empQueryTypes.SAL_STR_NOT_ASSGINED
                  : empQueryTypes.SAL_STR_ASSGINED
              }
            />
            <Button
              mode="cancel"
              onClick={() => setEmployeeModal(!employeeModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEmployeeModal(!employeeModal)}
            />
          </div>
        </div>
      </Modal>
      <CalculationModal
        calculationMethodModal={calculationMethod!}
        incomeCalculatorItems={incomeCalculatorItems}
        setIncomeCalculatorItems={setIncomeCalculatorItems}
        closeMethod={calculationMethodCancel}
        saveMethod={setRuleOnSalary}
        ledgerDesc={
          ledgers?.find(({ id }) => id === calculationMethod?.id)?.ledger_desc!
        }
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <DeleteModal
        modalFlag={deleteModal}
        handleDelete={handleDelete}
        id={ledgerId}
        setModalFlag={setDeleteModal}
      />
      <LoadingModal
        flag={creationLoading ?? updationLoading ?? deletionLoading}
      />
    </>
  );
};

export default AssignSalaryStructure;
