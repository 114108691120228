import React, { useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import { TextField } from "@mui/material";

import { EMPTY_STRING } from "../../../../utils/constants";
import { ListAutoCompleteTextStyles } from "../../../../styles/AutocompleteListStyles";
import { Button } from "../../../../stories/Button/Button";
import { useMutation } from "@apollo/client";
import { AddPrefinedDataForm } from "../../../../queries/preDefinedData/new";
import { AddPredefinedDataResult, AddPrefinedDataFormVars } from "../../Types";
import { Operation, PredefinedDataTypes } from "../../../../utils/Enum.types";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useToken from "../../../../customhooks/useToken";
import { msgType } from "../../../../utils/Form.types";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<msgType>>;
}
const AddDocType = ({ setModalFlag, setMessage }: Props) => {
  const classes = ListAutoCompleteTextStyles();
  const [docType, setDocType] = useState("");
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const [AddPredefinedData] = useMutation<
    AddPredefinedDataResult,
    AddPrefinedDataFormVars
  >(AddPrefinedDataForm);

  const handleSave = () => {
    AddPredefinedData({
      variables: {
        index: 1,
        token,
        type: PredefinedDataTypes.CERTIFICATE,
        user_details,
        value1: docType,
        value2: docType,
        value3: "",
        value4: EMPTY_STRING,
      },
    }).then(({ data }) => {
      if (data && data.AddPredefinedData) {
        setMessage({
          flag: true,
          message: "Doc Type added successfully",
          operation: Operation.CREATE,
        });
        setDocType("");
        setModalFlag(false);
      }
    });
  };

  return (
    <>
      <Title>Add Document Type</Title>
      <div className="add-doc-type">
        <TextField
          label="Document Type"
          autoFocus
          InputLabelProps={{ shrink: true }}
          classes={{ root: classes.formControlRoot }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDocType(e.target.value)
          }
          className="add-doc-type--textfield"
        />

        <div className="add-doc-type--btns">
          <Button onClick={handleSave} mode="save" />
        </div>
      </div>
    </>
  );
};

export default AddDocType;
