import { useLazyQuery } from "@apollo/client";
import { Tooltip } from "@mui/material";
import {
  getIdToken,
  getIdTokenResult,
  onAuthStateChanged,
  ParsedToken,
} from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "../components/common/Header";

import Sidebar from "../components/common/Sidebar";
import { AppContext } from "../context/context";
import { payloadTypes } from "../context/reducer";
import Modal from "react-modal";

import { auth } from "../firebase";
import { GetOwnerDetailsByAuthId } from "../Modules/Proprietor/queries/List";
import {
  GetEduateDetailsByAuthId,
  GetEmployeeByAuthId,
  GetParentDetailsByAuthId,
  GetStudentDetailsByAuthId,
} from "../queries/chatapplication/list";
import {
  GetAuthDetailsByToken,
  ValidateAuthUser,
} from "../queries/DetailsBytoken";
import {
  CURRENT_TIME,
  CURRENT_YEAR,
  EMPTY_STRING,
  TODAY_DATE,
} from "../utils/constants";
import { UserType } from "../utils/Enum.types";
import { toStandardDate } from "../utils/UtilFunctions";
import {
  CheckEmpResetPwdStatus,
  CheckStdResetPwdStatus,
} from "../queries/customer/list";
import {
  PaymentDueModalStyles,
  WaiveOffModalStyles,
} from "../styles/ModalStyles";
import ResetPassword from "../Modules/Master/ResetPassword";
import { useStyles } from "../styles/TooltipStyles";
import {
  GetSwConfigVariablesData,
  GetSwConfigVariablesVars,
} from "../Types/configtypes";
import { GetSwConfigVariables } from "../queries/institution/configuration/query/SoftwreConfig";
import { SwConfigQueryType } from "../Modules/HR/enums/Enum.types";
import PaymentIssue from "../Modules/Eduate/Institution/PaymentIssue";

interface IGetTokenDetailsVars {
  token: string;
  device_token: String;
}
interface IGetTokenDetailsDetails {
  id: number;
  auth_user_id: number;
  auth_group_type: string;
  firebase_id: number;
  inst_id: number;
}
interface IGetTokenDetailsData {
  GetAuthDetailsByToken: IGetTokenDetailsDetails;
}

export interface GetEmployeeByAuthIdData {
  GetEmployeeByAuthId: {
    id: number;
    emp_first_name: string;
    inst_id: number;
    just_login_acct: boolean;
    emp_type: string;
  };
}
export interface GetEmployeeByAuthIdVars {
  token: string;
  auth_id: number;
}
interface Props {
  children: React.ReactNode;
}
const DashBoard: React.FC<Props> = ({ children }) => {
  const tooltipClasses = useStyles();
  // eslint-disable-next-line
  let count = 0;

  let [time, setTime] = useState(CURRENT_TIME);
  const navigate = useNavigate();
  const [claims, setClaims] = useState<ParsedToken>({});
  const { state, dispatch } = useContext(AppContext);
  const [passwordModal, setPaswordModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [passwordMesg, setPasswordMesg] = useState("");
  const [GetDetailsByIdToken] = useLazyQuery<
    IGetTokenDetailsData,
    IGetTokenDetailsVars
  >(GetAuthDetailsByToken);

  const [isUserValid] = useLazyQuery(ValidateAuthUser);
  const [studentDetails] = useLazyQuery(GetStudentDetailsByAuthId);
  const [GetStaffDetailsByAuthId] = useLazyQuery<
    GetEmployeeByAuthIdData,
    GetEmployeeByAuthIdVars
  >(GetEmployeeByAuthId);
  const [parentDetails] = useLazyQuery(GetParentDetailsByAuthId);
  const [getOwnerDetails] = useLazyQuery(GetOwnerDetailsByAuthId);
  const [GetEmpPwdStatus] = useLazyQuery(CheckEmpResetPwdStatus);
  const [eduateDetails] = useLazyQuery(GetEduateDetailsByAuthId);
  const [GetConfigDetails] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables);

  const [GetStdPwdStatus] = useLazyQuery(CheckStdResetPwdStatus);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTime();
    }, 1000);

    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, []);

  //Caution : disabling right click should be in prod mode only
  useEffect(() => {
    if (process.env.React_App_access_type === "PROD") {
      document.addEventListener("contextmenu", (event) => {
        alert("The functionality is not allowed.");
        event.preventDefault();
      });
      document.addEventListener("keydown", (event) => {
        if (event.ctrlKey && event.shiftKey && event.key === "I") {
          alert("The functionality is not allowed.");
          event.preventDefault();
        }
      });
    }

    // eslint-disable-next-line
  }, []);

  const updateTime = () => {
    const newTime = new Date().toLocaleTimeString();
    const updatedTime = newTime
      .split("/")
      .splice(0, 5)
      .join(" ")
      .concat(" (IST)");
    setTime(updatedTime);
    count++;
  };
  const path = window.location.pathname;
  const match = path.match(/\/(\d+)\//);
  const InstId = match ? Number(match[1]) : null;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getIdToken(user).then((token) => {
          GetDetailsByIdToken({
            variables: { token, device_token: EMPTY_STRING },
          }).then(({ data }) => {
            if (data) {
              const { auth_group_type } = data.GetAuthDetailsByToken;
              if (auth_group_type === UserType.EMPLOYEE) {
                dispatch({
                  type: payloadTypes.SET_INST_ID,
                  payload: {
                    InstId:
                      window.location.pathname === "/"
                        ? data.GetAuthDetailsByToken.inst_id
                        : Number(InstId),
                  },
                });
              }

              switch (auth_group_type) {
                case UserType.STUDENT:
                  studentDetails({
                    variables: {
                      token,
                      auth_id: data.GetAuthDetailsByToken.id,
                    },
                  }).then(({ data: Stddata }) => {
                    if (Stddata) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: Stddata.GetStudentDetailsByAuthId.id,
                        },
                      });
                      if (InstId || data.GetAuthDetailsByToken.inst_id)
                        isUserValid({
                          variables: {
                            token,
                            inst_id:
                              InstId || data.GetAuthDetailsByToken.inst_id,
                            input: {
                              user_type: UserType.STUDENT,
                              id: Stddata.GetStudentDetailsByAuthId.id,
                            },
                          },
                        }).then(({ data }) => {
                          if (data && !data?.ValidateAuthUser) {
                            handleSignOut();
                          }
                          if (Stddata) {
                            GetConfigDetails({
                              variables: {
                                token,
                                inst_id:
                                  Stddata.GetStudentDetailsByAuthId.inst_id!,
                                input: {
                                  config_query_type:
                                    SwConfigQueryType.BY_CONFIG_KEY,
                                  str_value: "ENABLE_PAYMENT_DUE_PAGE",
                                  int_value: 0,
                                },
                              },
                            }).then(({ data: PaymentDueData }) => {
                              if (
                                PaymentDueData &&
                                PaymentDueData.GetSwConfigVariables[0]
                                  .config_boolean_value
                              ) {
                                setPaymentModal(true);
                              }
                            });
                          }
                          if (Stddata) {
                            GetStdPwdStatus({
                              variables: {
                                token,
                                inst_id:
                                  Stddata.GetStudentDetailsByAuthId.inst_id!,
                                student_id:
                                  Stddata.GetStudentDetailsByAuthId.id,
                              },
                            }).then(({ data: PwdStatusData }) => {
                              if (
                                PwdStatusData &&
                                PwdStatusData.CheckStdResetPwdStatus.status
                              ) {
                                setPaswordModal(true);
                                setPasswordMesg(
                                  PwdStatusData?.CheckStdResetPwdStatus.message
                                );
                              }
                            });
                          }
                        });
                      dispatch({
                        type: payloadTypes.SET_INST_ID,
                        payload: {
                          InstId: Stddata.GetStudentDetailsByAuthId.inst_id,
                        },
                      });
                    }
                  });

                  break;
                case UserType.EMPLOYEE:
                  GetStaffDetailsByAuthId({
                    variables: {
                      token,
                      auth_id: data.GetAuthDetailsByToken.id,
                    },
                  }).then(({ data: EmpData }) => {
                    if (EmpData) {
                      dispatch({
                        type: payloadTypes.SET_EMP_LOGIN_ID,
                        payload: {
                          empLoginId: EmpData.GetEmployeeByAuthId.id,
                        },
                      });
                      if (
                        EmpData.GetEmployeeByAuthId.emp_type ===
                        UserType.CASHIER
                      )
                        dispatch({
                          type: payloadTypes.SET_SUB_USER_TYPE,
                          payload: {
                            subUserType: UserType.CASHIER,
                          },
                        });
                      isUserValid({
                        variables: {
                          token,
                          inst_id:
                            InstId ?? EmpData.GetEmployeeByAuthId.inst_id,
                          input: {
                            user_type: UserType.EMPLOYEE,
                            id: EmpData.GetEmployeeByAuthId.id,
                          },
                        },
                      }).then(({ data }) => {
                        if (data && !data?.ValidateAuthUser) {
                          // handleSignOut();
                        }
                        if (EmpData) {
                          GetConfigDetails({
                            variables: {
                              token,
                              inst_id: state.InstId,
                              input: {
                                config_query_type:
                                  SwConfigQueryType.BY_CONFIG_KEY,
                                str_value: "ENABLE_PAYMENT_DUE_PAGE",
                                int_value: 0,
                              },
                            },
                          }).then(({ data: PaymentDueData }) => {
                            if (
                              PaymentDueData &&
                              PaymentDueData.GetSwConfigVariables[0]
                                .config_boolean_value
                            ) {
                              setPaymentModal(true);
                            }
                          });
                        }
                        if (EmpData) {
                          GetEmpPwdStatus({
                            variables: {
                              token,
                              inst_id: EmpData.GetEmployeeByAuthId.inst_id!,
                              pr_emp_id: EmpData.GetEmployeeByAuthId.id,
                            },
                          }).then(({ data: PwdStatusData }) => {
                            if (
                              PwdStatusData &&
                              PwdStatusData.CheckEmpResetPwdStatus.status
                            ) {
                              setPaswordModal(true);
                              setPasswordMesg(
                                PwdStatusData?.CheckEmpResetPwdStatus.message
                              );
                            }
                          });
                        }
                      });
                      if (
                        EmpData.GetEmployeeByAuthId.just_login_acct &&
                        EmpData.GetEmployeeByAuthId.emp_type ===
                          UserType.TEACHER
                      ) {
                        window.location.pathname === "/" &&
                          state.InstId &&
                          navigate(`/${state.InstId}/academics`);
                      } else if (
                        EmpData.GetEmployeeByAuthId.just_login_acct &&
                        EmpData.GetEmployeeByAuthId.emp_type ===
                          UserType.CASHIER
                      ) {
                        window.location.pathname === "/" &&
                          state.InstId &&
                          navigate(
                            `/${state.InstId}/accounts/cashierdashboard`
                          );
                      } else {
                        window.location.pathname === "/" &&
                          state.InstId &&
                          navigate(`/${state.InstId}/admissions`);
                      }
                    }
                  });
                  break;
                case UserType.OWNER:
                  // signOut(auth);
                  getOwnerDetails({
                    variables: {
                      token,
                      auth_id: data.GetAuthDetailsByToken.id,
                    },
                  }).then(({ data }) => {
                    if (data) {
                      dispatch({
                        type: payloadTypes.SET_CUST_ID,
                        payload: {
                          custId: data.GetOwnerDetailsByAuthId.mst_customer_id,
                        },
                      });
                      window.location.pathname === "/" &&
                        state.custId &&
                        navigate(
                          `/eduate/customer/${state.custId}/proprietor/accounts`
                        );
                    }
                  });
                  break;
                case UserType.PARENT:
                  parentDetails({
                    variables: {
                      token,
                      auth_id: data.GetAuthDetailsByToken.id,
                    },
                  }).then(({ data }) => {
                    if (data) {
                      dispatch({
                        type: payloadTypes.SET_PARENT_ID,
                        payload: {
                          parentId: data.GetParentDetailsByAuthId.id,
                        },
                      });
                      dispatch({
                        type: payloadTypes.SET_INST_ID,
                        payload: {
                          InstId: data.GetParentDetailsByAuthId.inst_id,
                        },
                      });
                      isUserValid({
                        variables: {
                          token,
                          inst_id: InstId,
                          input: {
                            user_type: UserType.PARENT,
                            id: data.GetParentDetailsByAuthId.id,
                          },
                        },
                      }).then(({ data }) => {
                        if (data && !data?.ValidateAuthUser) {
                          handleSignOut();
                        }
                      });
                      window.location.pathname === "/" &&
                        navigate("/parentdashboard");
                    }
                  });
                  break;
                case UserType.EDUATE:
                  eduateDetails({
                    variables: {
                      token,
                      auth_id: data.GetAuthDetailsByToken.id,
                    },
                  }).then(({ data }) => {
                    if (data) {
                      dispatch({
                        type: payloadTypes.SET_EDUATE_ID,
                        payload: {
                          eduateId: data.GetEduateDetailsByAuthId.id,
                        },
                      });
                      isUserValid({
                        variables: {
                          token,
                          inst_id: InstId,
                          input: {
                            user_type: UserType.EDUATE,
                            id: data.GetEduateDetailsByAuthId.id,
                          },
                        },
                      }).then(({ data }) => {
                        if (data && !data?.ValidateAuthUser) {
                          handleSignOut();
                        }
                      });
                      window.location.pathname === "/" &&
                        navigate("/eduate/customer/list");
                    }
                  });
                  break;
                default:
                  break;
              }
            }
          });
        });

        getIdTokenResult(user).then((IdTokenResult) => {
          const { claims } = IdTokenResult;
          setClaims(claims);
        });
      }
    });
  }, [
    isUserValid,
    GetDetailsByIdToken,
    studentDetails,
    GetStaffDetailsByAuthId,
    parentDetails,
    getOwnerDetails,
    dispatch,
    navigate,
    state.InstId,
    InstId,
    state.custId,
    state.subUserType,
    GetEmpPwdStatus,
    GetStdPwdStatus,
    eduateDetails,
    GetConfigDetails,
  ]);

  return (
    <>
      <div className="container-fluid g-0 h100">
        <div className="row g-0 h-100 ">
          <div className="col-auto g-0 h-100">
            <Sidebar claims={claims} />
          </div>
          <section className="col g-0 h-100 responsive-layout">
            <div className="main">{children}</div>
          </section>
        </div>
        <footer className="container-fluid fixed-bottom">
          <div className="row g-0">
            <div className="col footer__lost-login">
              {/* <span>
                User Last Login:
                {auth.currentUser && auth.currentUser.metadata.lastSignInTime
                  ? toStandardDate(auth.currentUser.metadata.lastSignInTime)
                  : EMPTY_STRING}
              </span> */}
            </div>

            <div className="col-6 footer_version">
              <Tooltip
                title="Active Finacial Year"
                placement="top"
                classes={{
                  tooltip: tooltipClasses.customTooltipGrey,
                  arrow: tooltipClasses.customArrowGrey,
                }}
              >
                <span>
                  {(state.ActiveFinYr && state.ActiveFinYr.fin_yr) ||
                    CURRENT_YEAR}
                </span>
              </Tooltip>
              <span>{toStandardDate(TODAY_DATE)} </span>
              <span>{time}</span>
              <span>
                Version <b>2.6.3</b>
              </span>
            </div>
          </div>
        </footer>
      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={passwordModal}
        style={WaiveOffModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ResetPassword
              setPasswordModal={setPaswordModal}
              passwordMesg={passwordMesg}
            />
          </div>
          <div className="modal-flex__image"></div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={paymentModal}
        style={PaymentDueModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PaymentIssue />
          </div>
          <div className="modal-flex__image"></div>
        </div>
      </Modal>
    </>
  );
};

export default DashBoard;
