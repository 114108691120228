import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { TablePaginationActions } from "../../../../pages/CustomTablePagination";
import { Title } from "../../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { TableHeaderProps } from "../../../../Types/Tables";
import {
  CURRENT_PAGE,
  DEBOUNCE_TIME_INTERVAL,
  LIBRARY_ROWS_PER_PAGE,
  PAGINATION_ARRAY,
  TABLE_DATA_PER_PAGE,
} from "../../../../utils/constants";
import { Keys } from "../../../../utils/Enum.keys";
import { BooksReportType, UserType } from "../../../../utils/Enum.types";
import {
  debounce,
  defaultLabelDisplayedRows,
} from "../../../../utils/UtilFunctions";
import useBookEntryOptions from "../../customHooks/useBookEntryOptions";
import { shelfType } from "../../customHooks/useShelfOptions";
import useLibraryTableJson from "../../json/useLibraryTableJson";

interface Props {
  shelfId: number;
  shelfName: string;
  shelves: shelfType[];
  setSearchShelf: React.Dispatch<React.SetStateAction<string>>;
}
const ShelfBooks = ({
  shelfId: shelfIdFromProp,
  shelfName,
  shelves,
  setSearchShelf,
}: Props) => {
  //Styles
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  //States
  const [searchBook, setSearchBook] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(LIBRARY_ROWS_PER_PAGE);

  const [page, setPage] = useState(0);
  const [shelfId, setShelfId] = useState(shelfIdFromProp);

  //customHooks
  const {
    bookEntries: {
      queryData: { data: booksFromSelectedShelf, fetchMore },
    },
  } = useBookEntryOptions(
    searchBook,
    rowsPerPage,
    BooksReportType.BOOKS_BY_RACK_ID,
    UserType.STUDENT,
    shelfId
  );
  const { Library_Table } = useLibraryTableJson();

  //Pagination Methods
  const totalCount = booksFromSelectedShelf?.GetLibBookMaster?.totalCount;
  const endCursor =
    booksFromSelectedShelf?.GetLibBookMaster?.pageInfo?.endCursor;
  const indexOfLastrepo = CURRENT_PAGE * totalCount!;
  const indexOfFirstrepo = indexOfLastrepo - totalCount!;
  const currentRepo = booksFromSelectedShelf?.GetLibBookMaster?.edges?.slice(
    indexOfFirstrepo,
    indexOfLastrepo
  );
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setTimeout(() => {
      setPage(newPage);
      updateDebounceText();
    }, DEBOUNCE_TIME_INTERVAL);
  };
  const updateDebounceText = debounce(() => {
    handleMore();
  });
  const handleMore = () => {
    fetchMore({
      variables: {
        after: endCursor,
      },

      updateQuery: (prevResult, { fetchMoreResult }) => {
        fetchMoreResult.GetLibBookMaster.edges = [
          ...prevResult.GetLibBookMaster.edges,
          ...fetchMoreResult.GetLibBookMaster.edges,
        ];

        return fetchMoreResult;
      },
    });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Title variant="subtitle1">
        List of Books In
        <b> {shelfName}</b>
      </Title>
      <div className="row g-0 books-allocation__details">
        <div className="col-2">
          <TextField
            id="outlined-uncontrolled"
            label="Search By Title"
            className="books-allocation__details--textfield"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchBook(e.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-2 books-allocation__details--autocomplete">
          <Autocomplete
            classes={classes}
            options={
              shelves! ?? [
                {
                  lib_rack_desc: "",
                  lib_rack_no: "",
                  id: 0,
                },
              ]
            }
            getOptionLabel={(option) =>
              (option.lib_rack_no + " " + option.lib_rack_desc).toString()
            }
            value={shelves.find((data) => data.id === shelfId) ?? null}
            openOnFocus
            onChange={(e, newValue) => {
              setShelfId(newValue?.id!);
            }}
            onKeyDown={(e) => {
              if (e.key === Keys.BACKSPACE) {
                setShelfId(0);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Shelf"
                InputLabelProps={{ shrink: true }}
                fullWidth
                onChange={(e) => {
                  setSearchShelf(e.target.value);
                }}
                classes={{ root: textClasses.formControlRoot }}
              />
            )}
          />
        </div>
      </div>
      <div className="books-allocation__tableblock--table--modal">
        {!currentRepo?.length ? (
          <b className="nodata">Sorry, no results.</b>
        ) : (
          <TableContainer className="h-100">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Library_Table.BooksShelfAllocation.Table_Headers.filter(
                    (th: TableHeaderProps) => th.labelName !== "Move to Shelf"
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell key={index} className={th.className}>
                        {th.labelName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentRepo &&
                  currentRepo &&
                  (rowsPerPage > 0 &&
                  currentRepo.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).length > 0
                    ? currentRepo.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : currentRepo
                  ).map((response, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          id="td-center"
                          className="books-allocation__tableblock--table--slno"
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell className="books-allocation__tableblock--table--accessno">
                          {response.node.book_access_no}
                        </TableCell>
                        <TableCell>
                          {response.node.classification.classification_desc}
                        </TableCell>
                        <TableCell className="books-allocation__tableblock--table--desc">
                          {response.node.classification.author.author_name}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={PAGINATION_ARRAY}
                    count={totalCount ? totalCount : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={defaultLabelDisplayedRows}
                    labelRowsPerPage={TABLE_DATA_PER_PAGE}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
};

export default ShelfBooks;
