import React from "react";
import TotalStudentsTable from "./Table";
import { Title } from "../../../../stories/Title/Title";
import More from "../../../../images/More.svg";

const TotalStudents = () => {
  return (
    <>
      <div className="acd-admin-dashboard__card--title">
        <Title variant="subtitle1">Total Students</Title>
        <img src={More} alt="" />
      </div>
      <TotalStudentsTable />
    </>
  );
};

export default TotalStudents;
