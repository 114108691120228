import React from "react";
import Close from "../../../../../images/Close.svg";
import { Title } from "../../../../../stories/Title/Title";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../../styles/DataGridTableStyles";
import { TableHeaderProps } from "../../../../../utils/types";
import useAcademicTableJson from "../../../json/useAcademicTable";
interface props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const Data = [
  {
    admno: "52",
    regno: "12",
    s_name: "Praveen",
    phone_no: "8963251478",
  },
];
const FormNotFilled = ({ setModalFlag }: props) => {
  const classes = useDataGridStyles();
  const { FormNotFilledStudents } = useAcademicTableJson();

  const dynamicHeaders: TableHeaderProps[] =
    FormNotFilledStudents.TableHeader.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));
  const columns: GridColDef[] = [...dynamicHeaders];

  const rows: GridValidRowModel[] =
    (Data ? Data : []).map((response, index) => {
      return {
        id: index + 1,
        adm_no: response.admno,
        reg_no: response.regno,
        name: response.s_name,
        mobile: response.phone_no,
      };
    }) || [];

  return (
    <>
      <div className="form-not-filled">
        <div className="form-not-filled__title">
          <Title>Form not filled Students List</Title>
          <img
            src={Close}
            alt=""
            className="modal-close-icon"
            onClick={() => setModalFlag(false)}
          />
        </div>
        <div className={`${classes.root} form-not-filled__tableblock`}>
          <DataGridPro
            columns={columns}
            rows={rows}
            disableRowSelectionOnClick
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
            slotProps={{
              columnsPanel: { disableHideAllButton: true },
            }}
            hideFooter
          />
        </div>
        {/* <Button mode="excel">Export</Button>
        <Button mode="pdf" />
        <Button mode="cancel" onClick={() => setModalFlag(false)} /> */}
      </div>
    </>
  );
};

export default FormNotFilled;
