import React, { useContext, useEffect, useRef, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import {
  Direction,
  LicenseTypes,
  Operation,
  PredefinedDataTypes,
  ReturnType,
  SortBy,
} from "../../../../utils/Enum.types";
import { Form, Formik } from "formik";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import {
  InstitutionFormDataTypes,
  msgType,
  optionsType,
} from "../../../../utils/Form.types";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import { removeMoreSpace } from "../../../../utils/UtilFunctions";
import { useMutation } from "@apollo/client";
import Home from "../../Home/Index";

import { AddInst } from "../../../../queries/institution/mutations/new";
import { UpdateInst } from "../../../../queries/institution/mutations/update";
import {
  GetInstsByCustId,
  InstDetailsByNodeId,
} from "../../../../queries/institution/list/byId";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import useToken from "../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import { customer_institution_validation } from "../../../../utils/validationRules";
import InputHoc from "../../../../components/common/Input/Index";
import { LabelNameProps } from "../../../../Types/Labels";
import { Keys } from "../../../../utils/Enum.keys";
import { Label } from "../../../../stories/Label/Label";
import { Autocomplete, TextField } from "@mui/material";
import DownArrow from "../../../../images/DownArrow.svg";
import { Button } from "../../../../stories/Button/Button";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import Steps from "./Steps";
import { AppContext } from "../../../../context/context";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
const { Institutionformlabels } = require("../../../../json/config.json");

interface Props {
  operation: Operation;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const InstBasicData = ({ operation, setModal }: Props) => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const { state } = useContext(AppContext);
  const { custId, InstId } = useParams();
  const navigate = useNavigate();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const [formData, setFormData] = useState<InstitutionFormDataTypes>({
    id: 0,
    inst_code: "",
    inst_name: "",
    inst_short_name: "",
    inst_address: "",
    inst_place: "",
    inst_state: "",
    inst_pin: "",
    inst_contact_person: "",
    inst_phone: "",
    inst_email: "",
    inst_mobile: "",
    inst_url: "",
    inst_is_active: "",
    inst_status: "",
    inst_logo_filename: "",
    inst_latitude: 0,
    inst_longitude: 0,
    inst_time_zone: "",
    inst_name_to_print: "",
  });

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const {
    PredefinedData: { dropDown: City },
  } = usePredefinedDataByType(
    PredefinedDataTypes.CITY,
    formData.inst_place,
    ReturnType.VALUE_SAME_AS_LABEL
  );

  const {
    PredefinedData: { dropDown: States },
  } = usePredefinedDataByType(PredefinedDataTypes.STATE, formData.inst_state);

  //mutations
  const [createInstituion, { loading: creationLoading }] = useMutation(
    AddInst,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateInstitution, { loading: updationLoading }] = useMutation(
    UpdateInst,
    {
      onCompleted: (e) => {
        setMessage({
          flag: true,
          message: "Institution Updated",
          operation: Operation.UPDATE,
        });
        operation === Operation.CREATE
          ? navigate(`/eduate/${custId}/${InstId}/financialyear`)
          : setModal(false);
      },
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  //Refs
  const saveRef = useRef<HTMLButtonElement>(null);
  const pincodeRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLSelectElement>(null);
  const placeRef = useRef<HTMLSelectElement>(null);

  const { InstDetails } = useInstDetails(1);
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const HandleRegister = async () => {
    await createInstituion({
      variables: {
        token,
        input: {
          customer_id: custId,
          inst_name: removeMoreSpace(formData.inst_name),
          inst_short_name: removeMoreSpace(formData.inst_short_name),
          inst_address: removeMoreSpace(formData.inst_address),
          inst_place: removeMoreSpace(formData.inst_place),
          inst_state: removeMoreSpace(formData.inst_state),
          inst_pin: formData.inst_pin,
          inst_contact_person: removeMoreSpace(formData.inst_contact_person),
          inst_phone: formData.inst_phone,
          inst_email: formData.inst_email,
          inst_mobile: formData.inst_mobile,
          inst_name_to_print: removeMoreSpace(formData.inst_name_to_print),
          inst_url: formData.inst_url,
          inst_latitude: formData.inst_latitude,
          inst_longitude: formData.inst_longitude,
        },
        user_details,
      },
      refetchQueries: [
        {
          query: GetInstsByCustId,
          variables: {
            customer_id: Number(custId),
            first: ROWS_PER_PAGE,
            direction: Direction.ASC,
            name: "",
            after: null,
            sortBy: SortBy.INST_NAME,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        const { id } = data.AddInst;
        setMessage({
          message: "Institution Created Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
        navigate(`/eduate/${custId}/${id}/financialyear`);
      }
    });
  };
  const HandleUpdate = async () => {
    await UpdateInstitution({
      variables: {
        id: formData.id,
        token,
        input: {
          inst_name: removeMoreSpace(formData.inst_name),
          inst_short_name: removeMoreSpace(formData.inst_short_name),
          inst_address: removeMoreSpace(formData.inst_address),
          inst_place: removeMoreSpace(formData.inst_place),
          inst_state: removeMoreSpace(formData.inst_state),
          inst_pin: formData.inst_pin,
          inst_contact_person: removeMoreSpace(formData.inst_contact_person),
          inst_phone: formData.inst_phone,
          inst_email: formData.inst_email,
          inst_mobile: formData.inst_mobile,
          inst_url: formData.inst_url,
          inst_name_to_print: removeMoreSpace(formData.inst_name_to_print),
        },
        user_details,
      },
      refetchQueries: [
        {
          query: GetInstsByCustId,
          variables: {
            customer_id: Number(custId),
            first: ROWS_PER_PAGE,
            direction: Direction.ASC,
            name: "",
            after: null,
            sortBy: SortBy.INST_NAME,
            token,
          },
        },
        {
          query: InstDetailsByNodeId,
          variables: { ids: [state.InstId], token },
        },
      ],
    });
  };

  const handleClear = () => {
    setFormData({
      id: 0,
      inst_code: "",
      inst_name: "",
      inst_short_name: "",
      inst_address: "",
      inst_place: "",
      inst_state: "",
      inst_pin: "",
      inst_contact_person: "",
      inst_phone: "",
      inst_email: "",
      inst_mobile: "",
      inst_url: "",
      inst_is_active: "",
      inst_status: "",
      inst_logo_filename: "",
      inst_latitude: 0,
      inst_longitude: 0,
      inst_time_zone: "",
      inst_name_to_print: "",
    });
  };
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (InstDetails.data && !InstDetails.loading && (state.InstId || InstId)) {
      const {
        inst_name,
        inst_short_name,
        inst_address,
        inst_place,
        inst_state,
        inst_pin,
        inst_contact_person,
        inst_phone,
        inst_email,
        inst_mobile,
        inst_url,
        inst_logo_filename,
        inst_latitude,
        inst_longitude,
        id,
        inst_code,
        inst_is_active,
        inst_status,
        inst_time_zone,
        inst_name_to_print,
      } = InstDetails.data.nodes[0];

      setFormData({
        id,
        inst_name,
        inst_short_name,
        inst_address,
        inst_place,
        inst_state,
        inst_pin,
        inst_contact_person,
        inst_phone,
        inst_email,
        inst_mobile,
        inst_url,
        inst_logo_filename,
        inst_latitude,
        inst_longitude,
        inst_code,
        inst_is_active,
        inst_status,
        inst_time_zone,
        inst_name_to_print,
      });
    }
  }, [InstDetails.data, InstId, state.InstId, InstDetails.loading, operation]);
  return (
    <>
      {operation === Operation.CREATE && (
        <>
          <Home
            DashBoardRequired={false}
            NavType={LicenseTypes.EDUATE_CUSTOMER}
          />
          <Steps step={1} />
        </>
      )}

      <Formik
        initialValues={formData}
        validationSchema={customer_institution_validation}
        onSubmit={
          operation === Operation.CREATE && InstId === "0"
            ? HandleRegister
            : HandleUpdate
        }
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form
              className={
                operation === Operation.UPDATE
                  ? "inst-registration__modal"
                  : "inst-registration"
              }
            >
              <Title>
                {operation === Operation.CREATE ? "Registration" : "Update"}
              </Title>
              <div className="row g-0 inst-registration__data">
                <div className="col">
                  <div className="details">
                    <h4>Institution Details</h4>
                  </div>
                  {Institutionformlabels.Labels.PersonalDetails.map(
                    (label: LabelNameProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <InputHoc
                            LabelName={label.LabelName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            values={formData[label.inputName]}
                            name={label.inputName}
                            type={label.dataType}
                            required={label.required}
                            maxLength={label.maxLength}
                            autoFocus={label.autoFocus}
                          />
                        </React.Fragment>
                      );
                    }
                  )}
                  <div className="details frame-space">
                    <h4>Other Details</h4>
                  </div>
                  {Institutionformlabels.Labels.OtherDetails.map(
                    (label: LabelNameProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <InputHoc
                            LabelName={label.LabelName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            values={formData[label.inputName]}
                            name={label.inputName}
                            type={label.dataType}
                          />
                        </React.Fragment>
                      );
                    }
                  )}
                </div>
                <div className="col">
                  <div className="details">
                    <h4>Location Details</h4>
                  </div>
                  {Institutionformlabels.Labels.LocationDetails.map(
                    (label: LabelNameProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <InputHoc
                            LabelName={label.LabelName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            values={formData[label.inputName]}
                            name={label.inputName}
                            type={label.dataType}
                            required={label.required}
                            maxLength={label.maxLength}
                          />
                        </React.Fragment>
                      );
                    }
                  )}
                  <div className="label-grid">
                    <Label>Place</Label>
                    <Autocomplete
                      classes={classes}
                      options={City!}
                      ref={placeRef!}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          if (formData.inst_place) {
                            pincodeRef?.current?.focus();
                          }
                        }
                        if (e.key === Keys.BACKSPACE) {
                          setFormData((prevValues) => {
                            return {
                              ...prevValues,
                              inst_place: EMPTY_STRING,
                            };
                          });
                        }
                      }}
                      value={
                        City?.find(
                          ({ value }) => value === formData.inst_place
                        )! ?? null
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setFormData((prevValues) => {
                            return {
                              ...prevValues,
                              inst_place: (newValue as optionsType)?.value,
                            };
                          });
                        } else {
                          setFormData((prevValues) => {
                            return {
                              ...prevValues,
                              inst_place: EMPTY_STRING,
                            };
                          });
                        }
                      }}
                      openOnFocus
                      freeSolo
                      popupIcon={<img src={DownArrow} alt="/" />}
                      forcePopupIcon
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                inst_place: e.target.value!,
                              };
                            });
                          }}
                          fullWidth
                          classes={{ root: textClasses.formControlRoot }}
                        />
                      )}
                    />
                  </div>
                  <InputHoc
                    LabelName="Pin Code"
                    type="text"
                    name="inst_pin"
                    inputRef={pincodeRef}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        stateRef?.current?.focus();
                      }
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      meta.handleChange(e);
                      handleValueChange(e);
                    }}
                    values={formData.inst_pin}
                    maxLength={6}
                  />

                  <div className="label-grid">
                    <Label>State</Label>
                    <Autocomplete
                      classes={classes}
                      options={States!}
                      ref={stateRef!}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          if (formData.inst_state) {
                            saveRef?.current?.focus();
                          }
                        }
                        if (e.key === Keys.BACKSPACE) {
                          setFormData((prevValues) => {
                            return {
                              ...prevValues,
                              inst_state: EMPTY_STRING,
                            };
                          });
                        }
                      }}
                      value={
                        States?.find(
                          ({ value }) => value === formData.inst_state
                        )! ?? null
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setFormData((prevValues) => {
                            return {
                              ...prevValues,
                              inst_state: (newValue as optionsType)?.value,
                            };
                          });
                        } else {
                          setFormData((prevValues) => {
                            return {
                              ...prevValues,
                              inst_state: EMPTY_STRING,
                            };
                          });
                        }
                      }}
                      openOnFocus
                      popupIcon={<img src={DownArrow} alt="/" />}
                      forcePopupIcon
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                inst_state: e.target.value!,
                              };
                            });
                          }}
                          fullWidth
                          classes={{ root: textClasses.formControlRoot }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              {operation === Operation.CREATE ? (
                <Button
                  mode="save-continue"
                  buttonref={saveRef}
                  type="submit"
                />
              ) : (
                <Button mode="save" buttonref={saveRef} type="submit" />
              )}
              <Button mode="clear" type="button" onClick={handleClear} />
              {operation === Operation.UPDATE ? (
                <Button mode="cancel" onClick={() => setModal(false)} />
              ) : (
                <Button
                  mode="back"
                  type="button"
                  onClick={() => navigate(-1)}
                />
              )}
            </Form>
          );
        }}
      </Formik>
      <LoadingModal flag={creationLoading || updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default InstBasicData;
