import React, { useContext, useState } from "react";
import Home from "../Master/Home/Index";
import { Title } from "../../stories/Title/Title";
import { Button } from "../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import MessageList from "./MessageList";
import Delete from "../../images/Delete.svg";
import DownArrow from "../../images/DownArrow.svg";
import Close from "../../images/Close.svg";
import Create from "../../images/CreateChannel.svg";
import Channels from "../../images/Channels.svg";
import { Tooltip } from "@mui/material";
import Modal from "react-modal";
import { AddLedgerModalStyles } from "../../styles/ModalStyles";
import { useMutation } from "@apollo/client";
import useToken from "../../customhooks/useToken";
import AddChannel from "./NewChannel";
import { GetChannelSubscribed } from "../../queries/chatapplication/list";
import { AppContext } from "../../context/context";
import { ChannelType, Operation, PageFor } from "../../utils/Enum.types";
import MessageModal from "../../pages/MessageModal";
import DeleteModal from "../../pages/DeleteModal";
import { msgType } from "../../utils/Form.types";
import { DeleteChannel } from "../../queries/chatapplication/mutations";
import { useStyles } from "../../styles/TooltipStyles";
import DepartmentImage from "../../images/department.svg";
import Branch from "../../images/branch.svg";
import Class from "../../images/class.svg";
import Semester from "../../images/Semester.svg";
import Section from "../../images/Section.svg";
import useChannelDetails from "../../customhooks/useChannelDetails";
import { payloadTypes } from "../../context/reducer";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";
import { ChannelQueryType } from "../Accounts/common/Enum.types";
import { EMPTY_STRING } from "../../utils/constants";

const Index = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const { InstId } = useParams();
  const { token } = useToken();
  const { dispatch, state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const [channels, setChannels] = useState(false);
  const [addChannel, setAddChannel] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [selectedRow, setSelectedRow] = useState(1);
  const { channelDetails } = useChannelDetails([
    ChannelQueryType.ENTRYLEVEL,
    ChannelQueryType.INDIVIDUAL,
    ChannelQueryType.ANNOUNCEMENTS,
    ChannelQueryType.CUSTOM,
  ]);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [DeleteChannelData] = useMutation(DeleteChannel, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const HandleDelete = (id: number) => {
    DeleteChannelData({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetChannelSubscribed,
          variables: {
            token,
            input: {
              user_type: "EMPLOYEE",
              id: channelDetails.data
                ? channelDetails.data.GetChannelSubscribed[0].id
                : 0,
            },
            device_token: EMPTY_STRING,
            inst_id: InstId,
            channel_type: [
              ChannelQueryType.ANNOUNCEMENTS,
              ChannelQueryType.ENTRYLEVEL,
              ChannelQueryType.INDIVIDUAL,
            ],
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Channel Deleted Sucessfully",
          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setDeleteId(0);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Channel Message</Title>
      <div className="channel">
        <div className="channel__datablock">
          <div className="channel__datablock--left">
            <div
              className="channel__datablock--left--menus"
              onClick={() => setChannels(!channels)}
            >
              <span>
                <img src={Channels} alt="/" />
                &nbsp; Channels
              </span>
              <img src={DownArrow} alt="/" />
            </div>
            {channels && (
              <ul className="channel__datablock--left--channels">
                {channelDetails.data
                  ? channelDetails.data.GetChannelSubscribed.map(
                      (channel, index) => {
                        return (
                          <Tooltip
                            title={
                              <>
                                <ul className="channel__datablock--left--levels">
                                  {channel.channel_type ===
                                  ChannelType.DEPARTMENT ? (
                                    <li>
                                      <img src={DepartmentImage} alt="/" />
                                      <span> dept</span>
                                    </li>
                                  ) : null}
                                  {channel.channel_type ===
                                  ChannelType.BRANCH ? (
                                    <li>
                                      <img src={Branch} alt="" />
                                      <span> branch</span>
                                    </li>
                                  ) : null}
                                  {channel.channel_type ===
                                  ChannelType.CLASS ? (
                                    <li>
                                      <img src={Class} alt="" />
                                      <span> class</span>
                                    </li>
                                  ) : null}
                                  {channel.channel_type ===
                                  ChannelType.SEMESTER ? (
                                    <li>
                                      <img src={Semester} alt="" />
                                      <span> semester</span>
                                    </li>
                                  ) : null}
                                  {channel.channel_type ===
                                  ChannelType.SECTION ? (
                                    <li>
                                      <img src={Section} alt="" />
                                      <span> section</span>
                                    </li>
                                  ) : null}
                                  {channel.channel_type ===
                                  ChannelType.INSTITUTION ? (
                                    <li>
                                      <img src={Section} alt="" />
                                      <span> Institution</span>
                                    </li>
                                  ) : null}
                                  {channel.channel_type ===
                                  ChannelType.INDIVIDUAL ? (
                                    <li>
                                      <img src={Section} alt="" />
                                      <span>ME</span>
                                    </li>
                                  ) : null}
                                </ul>
                              </>
                            }
                            arrow
                            placement="right"
                            classes={{
                              tooltip: classes.customTooltipGrey,
                              arrow: classes.customArrowGrey,
                            }}
                          >
                            <li
                              key={index}
                              className={
                                selectedRow === channel.id
                                  ? "channel__datablock--left--selected-row"
                                  : ""
                              }
                            >
                              <span
                                onClick={() => {
                                  dispatch({
                                    type: payloadTypes.SET_CHANNEL_ID,
                                    payload: {
                                      channelId: channel.id,
                                    },
                                  });
                                  setSelectedRow(channel.id);
                                }}
                              >
                                {index + 1}){" "}
                                {channel.channel_type === ChannelType.INDIVIDUAL
                                  ? "ME"
                                  : channel.channel_name}
                              </span>
                              {!state.claims?.STUDENT && (
                                <img
                                  onClick={() => {
                                    setDeleteId(channel.id);
                                    setDeleteModal(!deleteModal);
                                  }}
                                  src={Delete}
                                  alt="/"
                                />
                              )}
                            </li>
                          </Tooltip>
                        );
                      }
                    )
                  : null}
              </ul>
            )}
            <div className="channel__datablock--left--button">
              {state.claims?.EMPLOYEE ? (
                <>
                  {/* {channelDetails?.data?.GetChannelSubscribed.length === 5 ? (
                    <Tooltip
                      title="Cannot Create More than 5 Channels Please Contact us for more information"
                      arrow
                      placement="top"
                    >
                      <button
                        disabled={
                          channelDetails?.data?.GetChannelSubscribed.length ===
                          5
                        }
                        onClick={() => setAddChannel(!addChannel)}
                      >
                        <img src={Create} alt="/" />
                        Create New Channel
                      </button>
                    </Tooltip>
                  ) : ( */}
                  <button onClick={() => setAddChannel(!addChannel)}>
                    <img src={Create} alt="/" />
                    Create New Channel
                  </button>
                  {/* )} */}
                </>
              ) : null}
            </div>
          </div>
          {state.channelId ? (
            <div className="channel__datablock--right">
              <MessageList pageType={PageFor.GENERAL} />
            </div>
          ) : null}
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addChannel}
        style={AddLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <AddChannel
              setAddChannel={setAddChannel}
              operation={Operation.CREATE}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddChannel(!addChannel)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={deleteId}
      />
    </>
  );
};

export default Index;
