import { useNavigate, useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";

import { templateViewStyles } from "../../../styles/DrawerStyles";
import { useEffect, useRef, useState } from "react";
import { msgType, optionsType } from "../../../utils/Form.types";
import {
  Direction,
  EduateModule,
  LicenseTypes,
  Operation,
} from "../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetSwMsgTemplatesData,
  GetSwMsgTemplatesVars,
  GetTemplateByIdData,
  GetTemplateByIdVars,
} from "../../Templates/utils/types";
import Home from "../../Eduate/Home/Index";
import {
  GetMsgInstTemplates,
  GetSwMsgTags,
  GetSwMsgTemplates,
  GetTemplateById,
} from "../../Templates/queries/list";
import {
  AddSwMsgTemplate,
  DeleteSwMsgTemplate,
  UpdateSwMsgTemplate,
} from "../../Templates/queries/mutation";
import Edit from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import { GetSwMsgTagsData, GetSwMsgTagsVars } from "./paginationTypes";
import { Title } from "../../../stories/Title/Title";
import { Autocomplete, Drawer, TextField } from "@mui/material";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import MessageModal from "../../../pages/MessageModal";
import DeleteModal from "../../../pages/DeleteModal";
import LoadingModal from "../../../pages/LoadingModal";
import { toStandardCase } from "../../../utils/UtilFunctions";
import { HighlightedText } from "../../Templates/Index";
import { Label } from "../../../stories/Label/Label";
import TextArea from "../../../stories/TextArea/TextArea";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { EMPTY_STRING } from "../../../utils/constants";

const modules: optionsType[] = Object.keys(EduateModule).map((key) => ({
  label: toStandardCase(key),
  value: EduateModule[key as keyof typeof EduateModule],
}));

const Templates = () => {
  const drawerClasses = templateViewStyles();
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const { user_details } = useLoggedInUserDetails();
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [operation, setOperation] = useState(Operation.CREATE);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [templateMessage, setTemplateMessage] = useState("");
  const [moduleType, setModuleType] = useState<optionsType | null>(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [acctId, setAcctId] = useState(0);
  const [moduleSelected, setModuleSelected] = useState<optionsType | null>(
    null
  );
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const messageRef = useRef<HTMLTextAreaElement | null>(null);

  const [GetTemplates, { data: templatesList }] = useLazyQuery<
    GetSwMsgTemplatesData,
    GetSwMsgTemplatesVars
  >(GetSwMsgTemplates);
  const [GetTags, { data }] = useLazyQuery<GetSwMsgTagsData, GetSwMsgTagsVars>(
    GetSwMsgTags
  );
  const [GetTemplatesByNode] = useLazyQuery<
    GetTemplateByIdData,
    GetTemplateByIdVars
  >(GetTemplateById);

  const [DeleteTemplate, { loading: deleteTemplateLoading }] = useMutation(
    DeleteSwMsgTemplate,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [CreateTemplate, { loading: creationLoading }] = useMutation(
    AddSwMsgTemplate,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          operation: Operation.NONE,
          flag: true,
        }),
    }
  );
  const [UpdateTemplate, { loading: updationLoading }] = useMutation(
    UpdateSwMsgTemplate,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          operation: Operation.NONE,
          flag: true,
        }),
    }
  );

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setModuleType(null);
      setTemplateMessage("");
      setIsDrawerOpen(false);
      setDeleteModal(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const HandleDelete = (id: number) => {
    DeleteTemplate({
      variables: {
        token,
        id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetMsgInstTemplates,
          variables: { token, inst_id: InstId },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Voucher Deleted Sucessfully",
          operation: Operation.DELETE,
        });
      }
    });
  };
  const handleTemplateClick = (template: string) => {
    const selectedText = window.getSelection()?.toString();
    const updatedMessage =
      selectedText && selectedText.length > 0
        ? templateMessage.replace(selectedText, template)
        : templateMessage + " " + template;
    setTemplateMessage(updatedMessage);
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTemplateMessage(e.target.value);
  };

  const handleTextAreaSelect = () => {
    const selectedText = window.getSelection()?.toString();
    if (selectedText && selectedText.length > 0) {
      messageRef.current?.focus();
    }
  };

  const handleAddTemplate = () => {
    if (operation === Operation.CREATE) {
      CreateTemplate({
        variables: {
          token,
          input: {
            msg_tpl_idx: 0,
            msg_tpl_desc: templateMessage,
            inst_module: moduleType ? moduleType.label : "",
            inst_type: "S",
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetMsgInstTemplates,
            variables: { token, inst_id: InstId },
          },
          {
            query: GetSwMsgTemplates,
            variables: {
              name: "",
              token,
              after: null,
              first: null,
              orderBy: {
                direction: Direction.ASC,
                field: "MSG_TPL_DESC",
              },
            },
          },
          {
            query: GetSwMsgTags,
            variables: {
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Template Created Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      UpdateTemplate({
        variables: {
          token,
          id: acctId,
          input: {
            msg_tpl_idx: 0,
            msg_tpl_desc: templateMessage,
            inst_module: moduleType ? moduleType.label : "",
            inst_type: "S",
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetMsgInstTemplates,
            variables: { token, inst_id: InstId },
          },
          {
            query: GetSwMsgTemplates,
            variables: {
              name: "",
              token,
              after: null,
              first: null,
              orderBy: {
                direction: Direction.ASC,
                field: "MSG_TPL_DESC",
              },
            },
          },
          {
            query: GetSwMsgTags,
            variables: {
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Template Updated Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (token) {
      GetTemplates({
        variables: {
          name: search,
          token,
          after: null,
          first: null,
          orderBy: {
            direction: Direction.ASC,
            field: "MSG_TPL_DESC",
          },
        },
      });
    }
  }, [GetTemplates, token, search]);

  useEffect(() => {
    if (token) {
      GetTags({
        variables: {
          token,
        },
      });
    }
  }, [token, GetTags]);
  const filteredTemplates =
    templatesList &&
    templatesList.GetSwMsgTemplates.edges.filter((template) => {
      return (
        !moduleSelected || template.node.inst_module === moduleSelected.label
      );
    });

  useEffect(() => {
    if (operation === Operation.UPDATE && acctId && token) {
      GetTemplatesByNode({
        variables: {
          id: acctId,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          setTemplateMessage(data.node.msg_tpl_desc);
          setModuleType({
            label: data.node.inst_module,
            value:
              EduateModule[data.node.inst_module as keyof typeof EduateModule],
          });
        }
      });
    }
  }, [token, GetTemplatesByNode, acctId, operation]);
  return (
    <>
      <Home
        DashBoardRequired={false}
        NavType={LicenseTypes.EDUATE_CONFIGURATION}
      />
      <Title>Templates for Message</Title>
      <div className="message-template">
        <div className="message-template__filters row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search Message"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={modules}
              openOnFocus
              value={moduleSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setModuleSelected(newValue);
                } else {
                  setModuleSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Under Module"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-2">
            <Button
              mode="addnew"
              onClick={() => {
                setIsDrawerOpen(!isDrawerOpen);
                setOperation(Operation.CREATE);
              }}
              className="message-template__filters--button"
            />
          </div>
        </div>
        <div className="message-template__datablock">
          <ul>
            {filteredTemplates &&
              filteredTemplates.map((template, index) => {
                return (
                  <li
                    className="message-template__datablock--li"
                    key={template.node.id}
                  >
                    <div className="message-template__datablock--li--message">
                      {`${index + 1})`}&nbsp;
                      <HighlightedText text={template.node.msg_tpl_desc} />
                      
                    </div>
                    <div className="message-template__datablock--li--module">
                      {template.node.inst_module}
                    </div>
                    <div className="message-template__datablock--li--image">
                      <img
                        src={Edit}
                        alt=""
                        onClick={() => {
                          setIsDrawerOpen(!isDrawerOpen);
                          setOperation(Operation.UPDATE);
                          setAcctId(template.node.id);
                        }}
                      />
                      <img
                        src={Delete}
                        alt=""
                        onClick={() => {
                          setAcctId(template.node.id);
                          setDeleteModal(!deleteModal);
                        }}
                      />
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(!isDrawerOpen)}
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>
              {operation === Operation.CREATE
                ? " Add New Template"
                : "Update Template"}
            </Title>
            <div className="row g-0">
              <div className="col">
                <div className="label-grid">
                  <Label>Description</Label>
                  <TextArea
                    rows={3}
                    textAreaRef={messageRef}
                    value={templateMessage}
                    onChange={handleTextAreaChange}
                    onSelect={handleTextAreaSelect}
                  />
                </div>
              </div>
            </div>
            <div className="row g-0 message-template__autocomplete">
              <div className="col">
                <div className="label-grid">
                  <Label>Under Module</Label>
                  <Autocomplete
                    classes={classes}
                    options={modules}
                    openOnFocus
                    value={moduleType}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setModuleType(newValue);
                      } else {
                        setModuleType(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        classes={{ root: textClasses.formControlRoot }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="message-template__predefined-words">
              {data &&
                data.GetSwMsgTags.map((res) => {
                  return (
                    <button onClick={() => handleTemplateClick(res.tag_name)}>
                      {res.tag_name}
                    </button>
                  );
                })}
            </div>
            <Button
              mode="save"
              onClick={handleAddTemplate}
              disabled={!moduleType || !templateMessage}
            />
            <Button
              mode="cancel"
              onClick={() => {
                setIsDrawerOpen(!isDrawerOpen);
                if (operation === Operation.CREATE) {
                  setTemplateMessage(EMPTY_STRING);
                  setModuleType(null);
                }
              }}
            />
          </div>
        </div>
      </Drawer>
      <LoadingModal
        flag={creationLoading || deleteTemplateLoading || updationLoading}
      />
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={acctId}
      />
    </>
  );
};

export default Templates;
