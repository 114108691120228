import { makeStyles } from "@mui/styles";
import { CSSProperties } from "react";
import { NUMBER_FONT_FAMILY, TEXT_FONT_FAMILY } from "./DataGridTableStyles";

const commonHeaderStyles: CSSProperties = {
  position: "sticky",
  backgroundColor: "var(--level-8)",
  padding: "0px !important",
  color: "white !important",
  textAlign: "center",
  fontFamily: TEXT_FONT_FAMILY,
  borderRight: "1px solid var(--border-color) !important",
  height: "25px",
  fontSize: "14px !important",
  fontStyle: "normal",
  zIndex: 0,
  top: 0,
};

const stickyColumnStyles: CSSProperties = {
  position: "sticky",
  zIndex: 0,
  backgroundColor: "var(--level-1)",
  borderRight: "1px solid var(--border-color) !important",
  padding: "5px 3px !important",
  fontFamily: TEXT_FONT_FAMILY,
  fontSize: "14px !important",
};
const commonColumnStyles: CSSProperties = {
  padding: "5px 3px !important",
  zIndex: 0,
  backgroundColor: "var(--level-1)",
  borderRight: "1px solid var(--border-color) !important",
  fontFamily: TEXT_FONT_FAMILY,
  fontSize: "14px important",
};
const columnWithNumberFont: CSSProperties = {
  fontFamily: NUMBER_FONT_FAMILY,
};

const stickyFooterTotal: CSSProperties = {
  position: "sticky",
  bottom: 0,
  left: 0,
  padding: "0px 0px !important",
  textAlign: "right",
  fontWeight: "700 !important",
  color: "var(--text-color) !important",
  background: "var(--bg-color) !important",
  fontFamily: TEXT_FONT_FAMILY,
  fontSize: "14px !important",
  zIndex: 2,
  height: 25,
};

const stickyFooterAmount: CSSProperties = {
  ...columnWithNumberFont,
  position: "sticky",
  bottom: 0,
  zIndex: 0,
  padding: "0px 3px !important",
  color: "var(--text-color) !important",
  background: "#4ade80 !important",
  borderRight: "1px solid white",
  fontSize: "14px !important",
  fontWeight: 700,
};

export const studentAttendanceReportTableStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "100%",
  },

  stickyHeader: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 80,
    maxWidth: 80,
  },
  stickySubHeader: {
    ...commonHeaderStyles,
    top: 25,
    overflow: "hidden",
  },

  stickyHeaderSl: {
    ...commonHeaderStyles,
    zIndex: 998,
    left: 0,
  },
  stickyHeaderRegNo: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 200,
    maxWidth: 200,
    left: 80,
  },

  stickyHeaderName: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 280,
  },

  stickyHeaderStatus: {
    ...commonHeaderStyles,
    zIndex: 998,
    overflow: "hidden",
    right: 0,
    minWidth: 100,
    maxWidth: 100,
  },

  stickyColumnSl: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 0,
    minWidth: 80,
    maxWidth: 80,
  },
  stickyColumnRegno: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 80,
    minWidth: 200,
    maxWidth: 200,
  },

  stickyColumnName: {
    ...stickyColumnStyles,
    left: 440,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnStatus: {
    ...stickyColumnStyles,
    minWidth: 100,
    maxWidth: 100,
    right: 0,
    zIndex: 0,
  },
});

// HR Reports Table
export const EmpAttendanceReportTableStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "100%",
  },

  stickyHeader: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 80,
    maxWidth: 80,
  },
  stickyHeaderDesc: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 140,
    maxWidth: 140,
  },

  stickyHeaderSl: {
    ...commonHeaderStyles,
    zIndex: 996,
    minWidth: 80,
    maxWidth: 80,
    left: 0,
  },
  stickyHeaderEmpId: {
    ...commonHeaderStyles,
    zIndex: 996,
    minWidth: 160,
    maxWidth: 160,
    left: 80,
  },

  stickyHeaderName: {
    ...commonHeaderStyles,
    zIndex: 996,
    minWidth: 200,
    maxWidth: 200,
    left: 240,
  },

  stickyColumnSl: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 0,
    minWidth: 80,
    maxWidth: 80,
  },
  stickyColumnEmpId: {
    ...stickyColumnStyles,
    left: 80,
    minWidth: 160,
    maxWidth: 160,
  },

  stickyColumnName: {
    ...stickyColumnStyles,
    left: 240,
    minWidth: 200,
    maxWidth: 200,
  },
  Column: {
    ...commonColumnStyles,
    minWidth: 80,
    maxWidth: 80,
  },
  ColumnDesc: {
    ...commonColumnStyles,
    minWidth: 140,
    maxWidth: 140,
  },
});

export const ConfirmEmpSalaryTableStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "100%",
  },

  stickyHeaderCheckbox: {
    ...commonHeaderStyles,
    zIndex: 996,
    minWidth: 60,
    maxWidth: 60,
    left: 0,
  },
  stickyHeaderSl: {
    ...commonHeaderStyles,
    zIndex: 996,
    minWidth: 80,
    maxWidth: 80,
    left: 60,
  },
  stickyHeaderEmpId: {
    ...commonHeaderStyles,
    zIndex: 996,
    minWidth: 160,
    maxWidth: 160,
    left: 140,
  },
  stickyHeaderTitle: {
    ...commonHeaderStyles,
    zIndex: 996,
    minWidth: 80,
    maxWidth: 80,
    left: 300,
  },

  stickyHeaderName: {
    ...commonHeaderStyles,
    zIndex: 996,
    minWidth: 200,
    maxWidth: 200,
    left: 380,
  },
  stickyHeaderAmount: {
    ...commonHeaderStyles,
    minWidth: 140,
    maxWidth: 140,
  },
  stickyHeaderDays: {
    ...commonHeaderStyles,
    minWidth: 140,
    maxWidth: 140,
  },
  stickyHeaderStatus: {
    ...commonHeaderStyles,
    right: 0,
    minWidth: 140,
    maxWidth: 140,
  },

  stickyColumnCheckbox: {
    ...stickyColumnStyles,
    left: 0,
    minWidth: 60,
    maxWidth: 60,
  },

  stickyColumnSl: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 60,
    minWidth: 80,
    maxWidth: 80,
  },
  stickyColumnEmpId: {
    ...stickyColumnStyles,
    left: 140,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnEmpTitle: {
    ...stickyColumnStyles,
    left: 300,
    minWidth: 80,
    maxWidth: 80,
  },

  stickyColumnName: {
    ...stickyColumnStyles,
    left: 380,
    minWidth: 200,
    maxWidth: 200,
  },
  ColumnAmount: {
    ...commonColumnStyles,
    ...columnWithNumberFont,
    minWidth: 140,
    maxWidth: 140,
  },
  ColumnDays: {
    ...commonColumnStyles,
    ...columnWithNumberFont,
    minWidth: 140,
    maxWidth: 140,
  },
  ColumnStatus: {
    ...stickyColumnStyles,
    right: 0,
    minWidth: 140,
    maxWidth: 140,
  },
});
export const EmpPaySlipReportTableStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "100%",
  },

  stickyHeader: {
    ...commonHeaderStyles,
    minWidth: 100,
    maxWidth: 100,
  },

  stickyHeaderSl: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 80,
    maxWidth: 80,
    left: 0,
  },
  stickyHeaderEmpAccNo: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 200,
    maxWidth: 200,
    left: 240,
  },

  stickyHeaderName: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 80,
  },

  stickyColumnSl: {
    ...stickyColumnStyles,
    minWidth: 80,
    maxWidth: 80,
    left: 0,
  },
  stickyColumnName: {
    ...stickyColumnStyles,
    left: 80,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnEmpAccNo: {
    ...stickyColumnStyles,
    left: 240,
    minWidth: 200,
    maxWidth: 200,
  },

  stickyColumnAmount: {
    ...commonColumnStyles,
    maxWidth: 100,
    minwidth: 100,
  },
  stickyColumnDesc: {
    ...commonColumnStyles,
    maxWidth: 100,
    minwidth: 100,
  },
});
export const EmpLeavesReportTableStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "100%",
  },

  stickyHeader: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 80,
    maxWidth: 80,
  },

  stickyHeaderSl: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 80,
    maxWidth: 80,
    left: 0,
  },
  stickyHeaderEmpId: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 80,
  },

  stickyHeaderName: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 200,
    maxWidth: 200,
    left: 240,
  },

  stickyHeaderTotal: {
    ...commonHeaderStyles,
    zIndex: 998,
    overflow: "hidden",
    right: 0,
  },

  stickyColumnSl: {
    ...stickyColumnStyles,
    position: "sticky",
    left: 0,

    minWidth: 80,
    maxWidth: 80,
  },
  stickyColumnEmpId: {
    ...stickyColumnStyles,
    left: 80,
    minWidth: 160,
    maxWidth: 160,
  },

  stickyColumnName: {
    ...stickyColumnStyles,
    left: 240,
    minWidth: 200,
    maxWidth: 200,
  },
  stickyColumnTotal: {
    ...stickyColumnStyles,
    right: 0,
    zIndex: 0,
  },
});
export const EmpBankScrollTableStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "100%",
  },

  stickyHeader: {
    ...commonHeaderStyles,
    minWidth: 80,
    maxWidth: 80,
  },

  stickyHeaderSl: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 80,
    maxWidth: 80,
    left: 0,
  },
  stickyHeaderAccNo: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 280,
  },

  stickyHeaderName: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 200,
    maxWidth: 200,
    left: 80,
  },

  stickyColumnSl: {
    ...stickyColumnStyles,
    left: 0,
    minWidth: 80,
    maxWidth: 80,
  },
  stickyColumnAccNo: {
    ...stickyColumnStyles,
    left: 280,
    minWidth: 160,
    maxWidth: 160,
  },

  stickyColumnName: {
    left: 80,
    ...stickyColumnStyles,
    minWidth: 200,
    maxWidth: 200,
  },
  stickyColumnDesc: {
    ...commonColumnStyles,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnAmount: {
    ...commonColumnStyles,
    ...columnWithNumberFont,
    minWidth: 100,
    maxWidth: 100,
  },
});
export const EmpProvisionalSalaryReportTableStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "100%",
  },

  stickyHeader: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 100,
    maxWidth: 100,
  },

  stickyHeaderSl: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 80,
    maxWidth: 80,
    left: 0,
  },

  stickyHeaderName: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 80,
  },
  stickyHeaderAccNo: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 240,
  },

  stickyColumnSl: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 0,
    minWidth: 80,
    maxWidth: 80,
  },

  stickyColumnName: {
    ...stickyColumnStyles,
    left: 80,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnAccNo: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 240,
    zIndex: 0,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumn: {
    ...commonColumnStyles,
    ...columnWithNumberFont,
    minWidth: 100,
    maxWidth: 100,
  },
  stickyColumnDesc: {
    ...commonColumnStyles,
    minWidth: 120,
    maxWidth: 120,
  },
});

export const ImportStudentDataTableStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "calc(100% - 10px)",
    tableLayout: "fixed",
  },

  stickyHeader: {
    ...commonHeaderStyles,
    minWidth: 160,
    maxWidth: 160,
  },

  // stickyHeaderCheckbox: {
  //   ...commonHeaderStyles,
  //   zIndex: 998,
  //   minWidth: 80,
  //   maxWidth: 80,
  //   left: 0,
  // },
  stickyHeaderSl: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 80,
    maxWidth: 80,
    left: 0,
  },

  stickyHeaderAdmNo: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 80,
  },
  stickyHeaderName: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 240,
  },

  stickyHeaderStatus: {
    ...commonHeaderStyles,
    zIndex: 998,
    overflow: "hidden",
    right: 0,
    maxWidth: "160px",
    minWidth: "160px",
  },

  // stickyColumnCheckbox: {
  //   ...stickyColumnStyles,
  //   left: 0,
  //   zIndex: 0,
  //   minWidth: 80,
  //   maxWidth: 80,
  // },
  stickyColumnSl: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 0,
    minWidth: 80,
    maxWidth: 80,
  },

  stickyColumnAdmno: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 80,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnName: {
    ...stickyColumnStyles,
    left: 240,
    minWidth: 160,
    maxWidth: 160,
  },

  stickyColumn: {
    ...commonColumnStyles,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnStatus: {
    ...stickyColumnStyles,
    right: 0,
    zIndex: 0,
    backgroundColor: "red",
    maxWidth: "160px",
    minWidth: "160px",
  },
});
export const StudentTestReportStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "calc(100% - 10px)",
    tableLayout: "fixed",
  },

  stickyHeader: {
    ...commonHeaderStyles,
    overflow: "hidden",
    height: "50px !important",
    minWidth: 160,
    maxWidth: 160,
  },

  stickyHeaderSl: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 80,
    maxWidth: 80,
    left: 0,
  },
  stickyHeaderRegNo: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 80,
  },

  stickyHeaderAdmNo: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 240,
  },
  stickyHeaderName: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 400,
  },

  stickyHeaderTotal: {
    ...commonHeaderStyles,
    positiom:"sticky",
    zIndex:998,
    right: 100,
    maxWidth: 100,
    minWidth: 100,
  },
  stickyHeaderPercentage: {
    ...commonHeaderStyles,
    positiom:"sticky",
    zIndex: 998,
    right: 0,
    maxWidth: 100,
    minWidth: 100,
  },


  stickySubHeader: {
    backgroundColor: "var(--bg-color) !important",
    ...commonHeaderStyles,
    position:"sticky",
    top: 50,
    zIndex: 0,
    height: 25,
  },
  stickySubHeaderTotal: {
    backgroundColor: "var(--bg-color) !important",
    ...commonHeaderStyles,
    position:"sticky",
    top: 50,
    zIndex: 998,
    right:100,
    height: 25,
    maxWidth: 100,
    minWidth: 100,
  },
  stickyColumn: {
    ...commonColumnStyles,
    minWidth: 160,
    maxWidth: 160,
    fontSize: 14,
    zIndex: 0,
  },
  stickyColumnSl: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 0,
    minWidth: 80,
    maxWidth: 80,
  },

  stickyColumnRegno: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 80,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnAdmno: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 240,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnName: {
    ...stickyColumnStyles,
    left: 400,

    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnTotal: {
    ...stickyColumnStyles,
    right:100,
    zIndex: 0,
    maxWidth: 100,
    minWidth: 100,
    background: "#4ade80 !important",
  },
  stickyColumnPercentage: {
    ...stickyColumnStyles,
    right: 0,
    zIndex: 0,
    maxWidth: 100,
    minWidth: 100,
    background: "#4ade80 !important",
  },
});
export const PerStudentTestReportStyles = makeStyles({
  table: {
    minWidth: "100%",
    height: "calc(100% - 10px)",
    tableLayout: "fixed",
  },

  stickyHeader: {
    ...commonHeaderStyles,
    overflow: "hidden",
    height: 30,
    minWidth: 80,
    maxWidth: 80,
  },

  stickyHeaderSl: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 60,
    maxWidth: 60,
    left: 0,
  },

  stickyHeaderSName: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 110,
  },

  stickySubHeader: {
    ...commonHeaderStyles,
    position: "sticky",
    top: 30,
    zIndex: 0,
    height: 30,
  },
  stickySubHeader1: {
    ...commonHeaderStyles,
    position: "sticky",
    top: 60,
    zIndex: 0,
    height: 30,
  },


  stickyColumn: {
    ...commonColumnStyles,
    minWidth: 80,
    maxWidth: 80,
    fontSize: 14,
    zIndex: 0,
  },
  stickyColumnFooter: {
    ...commonColumnStyles,
    minWidth: 80,
    maxWidth: 80,
    fontSize: 14,
    zIndex: 0,
    padding: "0px !important",
  },
  stickyColumnFooterTotal: {
    ...commonColumnStyles,
    position: "sticky",
    minWidth: 400,
    maxWidth: 400,
    left: 0,
    fontSize: 14,
    zIndex: 0,
    padding: "0px !important",
    textAlign: "right",
    fontWeight: "700 !important",
    color: "var(--text-color) !important",
  },
  stickyColumnSl: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 0,
    minWidth: 60,
    maxWidth: 60,
  },

  stickyColumnSName: {
    ...stickyColumnStyles,
    left: 110,
    minWidth: 160,
    maxWidth: 160,
  },
});
export const StudentExcelTableStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "calc(100% - 10px)",
    tableLayout: "fixed",
  },

  stickyHeader: {
    ...commonHeaderStyles,
    minWidth: 160,
    maxWidth: 160,
  },

  stickyHeaderSl: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 80,
    maxWidth: 80,
    left: 0,
  },
  stickyHeaderId: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 80,
  },

  stickyHeaderAdmNo: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 240,
  },
  stickyHeaderRegNo: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 400,
  },
  stickyHeaderName: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 560,
  },

  stickyHeaderStatus: {
    ...commonHeaderStyles,
    zIndex: 998,
    overflow: "hidden",
    right: 0,
    maxWidth: "160px",
    minWidth: "160px",
  },

  stickyColumnSl: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 0,
    zIndex: 0,
    minWidth: 80,
    maxWidth: 80,
  },
  stickyColumnId: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 80,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnAdmno: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 240,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnRegno: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 400,
    minWidth: 160,
    maxWidth: 160,
  },

  stickyColumnName: {
    ...stickyColumnStyles,
    left: 560,
    minWidth: 160,
    maxWidth: 160,
  },

  stickyColumn: {
    ...commonColumnStyles,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnStatus: {
    ...stickyColumnStyles,
    right: 0,
    zIndex: 0,
    maxWidth: "160px",
    minWidth: "160px",
  },
});

export const StudentDetailedReportStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "calc(100% - 10px)",
    tableLayout: "fixed",
  },

  stickyHeader: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 120,
    maxWidth: 120,
  },

  stickyHeaderSl: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 80,
    maxWidth: 80,
    left: 0,
  },
  stickyHeaderAdmNo: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 100,
    maxWidth: 100,
    left: 80,
  },

  stickyHeaderRegNo: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 100,
    maxWidth: 100,
    left: 180,
  },
  stickyHeaderName: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 280,
  },
  stickyHeaderFName: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 160,
    maxWidth: 160,
  },
  stickyDate: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 70,
    maxWidth: 70,
  },

  stickyColumn: {
    ...commonColumnStyles,
    minWidth: 120,
    maxWidth: 120,
    fontSize: 14,
    zIndex: 0,
  },
  stickyColumnSl: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 0,
    minWidth: 80,
    maxWidth: 80,
  },

  stickyColumnAdmno: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 80,
    minWidth: 100,
    maxWidth: 100,
  },
  stickyColumnRegno: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 180,
    minWidth: 100,
    maxWidth: 100,
  },
  stickyColumnName: {
    ...stickyColumnStyles,
    left: 280,

    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnDate: {
    ...commonColumnStyles,
    ...columnWithNumberFont,
    minWidth: 80,
    maxWidth: 80,
    fontSize: 14,
    zIndex: 0,
  },
  stickyColumnFname: {
    ...commonColumnStyles,
    minWidth: 160,
    maxWidth: 160,
    fontSize: 14,
    zIndex: 0,
  },
});
export const DayWiseCollectionReportStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "calc(100% - 10px)",
    tableLayout: "fixed",
  },
  tfoot: {
    backgroundColor: "white",
  },
  stickyHeader: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 100,
    maxWidth: 100,
  },
  stickyHeaderSl: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 60,
    maxWidth: 60,
    left: 0,
  },
  stickyHeaderDate: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 100,
    maxWidth: 100,
    left: 60,
  },
  stickyHeaderVno: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 100,
    maxWidth: 100,
    left: 160,
  },
  stickyHeaderAdmNo: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 100,
    maxWidth: 100,
    left: 260,
  },
  stickyHeaderRegNo: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 100,
    maxWidth: 100,
    left: 360,
  },
  stickyHeaderName: {
    ...commonHeaderStyles,
    top: 0,
    zIndex: 998,
    minWidth: 150,
    maxWidth: 150,
    left: 460,
  },

  stickyColumn: {
    ...commonColumnStyles,
    minWidth: 100,
    maxWidth: 100,
    fontSize: 14,
    zIndex: 0,
  },
  stickyColumnSl: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 0,
    minWidth: 60,
    maxWidth: 60,
  },
  stickyColumnDate: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 60,
    minWidth: 100,
    maxWidth: 100,
  },
  stickyColumnVno: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 160,
    minWidth: 100,
    maxWidth: 100,
  },
  stickyColumnAdmno: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 260,
    minWidth: 100,
    maxWidth: 100,
  },
  stickyColumnRegno: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 360,
    minWidth: 100,
    maxWidth: 100,
  },
  stickyColumnName: {
    ...stickyColumnStyles,
    left: 460,
    minWidth: 150,
    maxWidth: 150,
  },
  stickyColumnFooterTotal: {
    minWidth: 100,
    maxWidth: 100,

    ...stickyFooterTotal,
  },
  stickyColumnFooter: {
    ...stickyFooterAmount,

    minWidth: 100,
    maxWidth: 100,
  },
});


export const AccountsDashboardStyles = makeStyles({
  table: {
    height: "calc(100% - 10px)",
    tableLayout: "fixed",
  },
  stickyHeader: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 180,
    maxWidth: 180,
  },
  stickyHeaderSl: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 60,
    maxWidth: 60,
    left: 0,
  },

  stickyHeaderName: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 300,
    maxWidth: 300,
    left: 60,
  },

  stickyColumn: {
    ...commonColumnStyles,
    ...columnWithNumberFont,
    minWidth: 180,
    maxWidth: 180,
    fontSize: 14,
    zIndex: 0,
  },

  stickyColumnSl: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 0,
    minWidth: 60,
    maxWidth: 60,
  },

  stickyColumnName: {
    ...stickyColumnStyles,
    left: 60,
    minWidth: 300,
    maxWidth: 300,
  },

  stickyColumnFooterTotal: {
    ...stickyFooterTotal,
    minWidth: 280,
    maxWidth: 280,
  },
  stickyColumnFooter: {
    ...stickyFooterAmount,
    minWidth: 180,
    maxWidth: 180,
  },
  stickyColumnFooterBalance: {
    ...stickyFooterAmount,
    minWidth: 180,
    maxWidth: 180,
    background: "#ef4444",
    color: "white !important",
    fontWeight: 700,
  },
  stickyColumnFooterDemand: {
    ...stickyFooterAmount,
    minWidth: 180,
    maxWidth: 180,
    background: "#0ea5e9",
    color: "white !important",
    fontWeight: 700,
  },
});

export const LedgerWiseReportStyles = makeStyles({
  table: {
    height: "calc(100% - 10px)",
    tableLayout: "fixed",
  },
  stickyHeader: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 100,
    maxWidth: 100,
  },
  stickyHeaderSl: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 60,
    maxWidth: 60,
    left: 0,
  },
  stickyHeaderRegNo: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 100,
    maxWidth: 100,
    left: 60,
  },
  stickyHeaderAdmNo: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 120,
    maxWidth: 120,
    left: 160,
  },
  stickyHeaderName: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 150,
    maxWidth: 150,
    left: 280,
  },

  stickyHeaderCName: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 180,
    maxWidth: 180,
  },
  stickyHeaderLedgerName: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 180,
    maxWidth: 180,
    textOverflow: "ellipsis",
  },
  stickyHeaderAmount: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 150,
    maxWidth: 150,
  },

  stickyColumnSl: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 0,
    minWidth: 60,
    maxWidth: 60,
  },
  stickyColumnRegno: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 60,
    minWidth: 100,
    maxWidth: 100,
  },
  stickyColumnAdmno: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 160,
    minWidth: 120,
    maxWidth: 120,
  },
  stickyColumnName: {
    ...stickyColumnStyles,
    left: 280,
    minWidth: 150,
    maxWidth: 150,
  },
  stickyColumnCName: {
    ...commonColumnStyles,
    minWidth: 100,
    maxWidth: 100,
    fontSize: 14,
    zIndex: 0,
  },
  stickyColumnLedgerName: {
    ...commonColumnStyles,
    minWidth: 280,
    maxWidth: 280,
    fontSize: 14,
    zIndex: 0,
    textOverflow: "ellipsis",
  },

  stickyColumnAmount: {
    ...commonColumnStyles,
    ...columnWithNumberFont,
    minWidth: 150,
    maxWidth: 150,
    fontSize: 14,
    zIndex: 0,
  },
  stickyColumnFooterTotal: {
    ...stickyFooterTotal,
    minWidth: 410,
    maxWidth: 410,
  },
  stickyColumnFooter: {
    ...stickyFooterAmount,
    minWidth: 150,
    maxWidth: 150,
  },
});
export const sessionWiseAttendanceStyles = makeStyles({
  table: {
    height: "calc(100% - 10px)",
    tableLayout: "fixed",
  },
  stickyHeader: {
    ...commonHeaderStyles,
    overflow: "hidden",
    minWidth: 40,
    maxWidth: 40,
  },

  stickyHeaderSessions: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 100,
    maxWidth: 100,
    left: 0,
  },
  stickyHeadertime: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 150,
    maxWidth: 150,
    left: 100,
  },

  stickyColumnSession: {
    ...stickyColumnStyles,
    left: 0,
    minWidth: 100,
    maxWidth: 100,
  },
  stickyColumnTime: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 100,
    minWidth: 150,
    maxWidth: 150,
  },
  stickyColumn: {
    ...commonColumnStyles,
    minWidth: 40,
    maxWidth: 40,
    textAlign: "center",
  },

  stickyColumnFooterTotal: {
    ...stickyFooterTotal,
    minWidth: 410,
    maxWidth: 410,
  },
  stickyColumnFooter: {
    ...stickyFooterAmount,
    minWidth: 150,
    maxWidth: 150,
  },
});
