import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { Button } from "../../../stories/Button/Button";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Eduate from "../../../images/Eduate_Logo_image.png";
import EduateLogo from "../../../images/Eduate_Logo_image.png";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import StudentDetails from "./StudentDetails";
import Close from "../../../images/Close.svg";
import useStudentEnquires from "../hooks/useStudentEnquires";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../utils/constants";
import {
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  PredefinedDataTypes,
  SortBy,
  StdEnqQueryType,
} from "../../../utils/Enum.types";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { AppContext } from "../../../context/context";
import { payloadTypes } from "../../../context/reducer";
import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import { Keys } from "../../../utils/Enum.keys";
import { optionsType } from "../../../utils/Form.types";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  H4_CELL,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../Library/Constants";
import { FollowUpListHeaders } from "../../Accounts/common/HeaderConsts";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import { GetStudentsEnquiryEdges } from "../StudentEnquiry";
import StudentTotalCount from "../../Master/Student/Components/StudentTotalCount";
import Input from "../../../stories/Input/Input";
import useEnquiryTable from "../json/useEnquiryTable";
import { TableHeaderProps } from "../../../utils/types";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../styles/DataGridTableStyles";
interface props {
  startDate: string;
  endDate: string;
}

const Index = ({ startDate, endDate }: props) => {
  const navigate = useNavigate();
  const { dispatch, state } = useContext(AppContext);
  const classes = ListAutoCompleteStyles();
  const { FollowUp } = useEnquiryTable();
  const textClasses = ListAutoCompleteTextStyles();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { serverDate } = useServerDateandTime();
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [students, setStudents] = useState<GetStudentsEnquiryEdges[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const dataClasses = useDataGridStyles();

  const [follow_up_status, set_follow_up_status] = useState<optionsType | null>(
    null
  );
  const [pdfFlag, setPdfFlag] = useState(false);
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const [searchEnqStudent, setSearchEnqStudent] = useState("");
  const {
    EnquiryData: { StudentEnquiryData },
  } = useStudentEnquires(
    rowsPerPage,
    StdEnqQueryType.STD_ENQ_NOT_ADMITTED,
    searchEnqStudent,
    follow_up_status ? follow_up_status.value : EMPTY_STRING,
    null,
    null,
    SortBy.ENQ_DATE,
    endDate,
    startDate,
    0,
    0,
    0
  );

  // const [GetStudentEnquiry] = useLazyQuery<
  //   GetStudentsEnquiryData,
  //   GetStudentsEnquiryVars
  // >(GetStudentEnquires, {
  //   variables: {
  //     name: EMPTY_STRING,
  //     lastFollowUpStatus: EMPTY_STRING,
  //     boughtApplication: applicationBoughtSelected
  //       ? applicationBoughtSelected.value
  //       : null,
  //     admitted: admittedSelected ? admittedSelected.value : null,
  //     deptID: deptSelected ? deptSelected.value : 0,
  //     branchID: branchSelected ? branchSelected.value : 0,
  //     classID: classSelected ? classSelected.value : 0,
  //     semesterID: semesterSelected ? semesterSelected.value : 0,
  //     categoryID: categorySelected ? categorySelected.value : 0,
  //     first: null,
  //     after: null,
  //     sortBy: SortBy.FIRST_NAME,
  //     direction: Direction.ASC,
  //     token,
  //     fin_yr_id: ActiveFinancicalYear.data?.GetFinYrActiveByInstId.id!,
  //     acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
  //     enq_query_type: queryType,
  //     today_date: toIsoDate(serverDate),
  //     inst_id: InstId!,
  //   },
  //   fetchPolicy: "network-only",
  // });

  const { InstDetails } = useInstDetails(1);
  const {
    PredefinedData: { dropDown: ENQ_STATUS_OPTIONS },
  } = usePredefinedDataByType(
    PredefinedDataTypes.ENQ_FOLLOW_UP_STATUS,
    EMPTY_STRING
  );
  const findEnquiry = (data: string) => {
    return (
      ENQ_STATUS_OPTIONS.find(({ value }) => value === data)?.label ??
      EMPTY_STRING
    );
  };
  const [studentDetailsModal, setStudentDetailsModal] = useState(false);
  const handleClear = () => {
    setSearchEnqStudent("");
    set_follow_up_status(null);
  };
  const studentCol = [
    { title: "Sl", field: "slNo" },
    { title: "Name", field: "Name" },
    { title: "E-Mail", field: "Email" },
    { title: "Mobile No", field: "Mobile" },
    { title: "Previous Studied Institution", field: "Inst" },
    { title: "Last updated Status", field: "Status" },
    { title: "Follow Up Date", field: "FollowUp" },
    { title: "Next Follow Up Date", field: "NextFollowUp" },
    { title: "Follow Up Count", field: "Count" },
  ];
  const stdData = StudentEnquiryData?.data?.GetStudentEnquires.edges.map(
    (data, index) => ({
      slNo: index + 1,
      Name: `${data.node.first_name} ${data.node.middle_name} ${data.node.last_name}`,
      Email: data.node.std_email,
      Mobile: data.node.std_mobile,
      Inst: data.node.std_previous_school,
      Status: findEnquiry(data.node.last_follow_up_status),
      FollowUp:
        toStandardDate(data.node.last_follow_up_made_date) ===
        toStandardDate(DEFAULT_TIME)
          ? "-"
          : toStandardDate(data.node.last_follow_up_made_date),
      NextFollowUp:
        toStandardDate(data.node.next_follow_up_date) ===
        toStandardDate(DEFAULT_TIME)
          ? "-"
          : toStandardDate(data.node.next_follow_up_date),
      Count: data.node.follow_up_count,
    })
  );

  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(EduateLogo)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const doc = new jsPDF("landscape", "mm", "a4");
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(18);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(240, 240, 240);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0); // Set text color to white
                  doc.text(
                    `${InstDetails.data?.nodes[0]?.inst_name}`,
                    80,
                    startY
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails.data?.nodes[0]?.inst_address}`,
                    120,
                    startY + 7
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails.data?.nodes[0]?.inst_place},${InstDetails.data?.nodes[0]?.inst_pin}`,
                    120,
                    startY + 12
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `Fin-year -${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    45,
                    startY + 19
                  );
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text(`Enquiry Follow Up List`, 120, startY + 19);
                  doc.setFontSize(13);
                  doc.text(
                    `As-on-date:${toStandardDate(serverDate)}`,
                    230,
                    startY + 19
                  );
                  doc.addImage(result, "JPEG", 15, 5, 22, 22);
                  // doc.addImage(result2, "JPEG", 80, 5, 55, 55);
                }

                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: studentCol.map((col) => ({
                    ...col,
                    dataKey: col?.field,
                    styles: { fontSize: 18 },
                  })),

                  body: stdData,
                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: function (data) {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);

                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                });

                doc.save(
                  `${InstDetails.data?.nodes[0]?.inst_name} ${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  } `
                );
              });
            });
        });
      });
    setPdfFlag(false);
    setRowsPerPage(ROWS_PER_PAGE);
  };
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Enquiry Students List");

    const dataRows = stdData
      ? stdData?.map((item) => [
          item.slNo,
          item.Name,
          item.Email,
          item.Mobile,
          item.Inst,
          item.Status,
          item.FollowUp,
          item.NextFollowUp,
          item.Count,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 25;
    worksheet.getColumn(7).width = 25;
    worksheet.getColumn(8).width = 25;
    worksheet.getColumn(9).width = 25;
    worksheet.getColumn(10).width = 25;
    worksheet.getColumn(11).width = 20;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          const lastHeaderColumn = String.fromCharCode(
            65 + FollowUpListHeaders.length - 1
          );
          const imageCellRange = `${lastHeaderColumn}1:${lastHeaderColumn}3`;
          // Add the image to the specified cell range
          worksheet.addImage(imageV, imageCellRange);
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:I2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:I3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "Enquiry Follow Up Students List";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:G4");
                  const mergedDate: Cell = worksheet.getCell(H4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("H4:I4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < FollowUpListHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = FollowUpListHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(
                      DOWNLOAD,
                      "Enquiry Follow Up Students List"
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(ROWS_PER_PAGE);
                });
              });
          });
        });
      });
  };

  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !StudentEnquiryData?.loading)
      downloadExcel();
    if (pdfFlag && rowsPerPage === null && !StudentEnquiryData?.loading)
      downloadPdf();
    // eslint-disable-next-line
  }, [excelFlag, pdfFlag, rowsPerPage, StudentEnquiryData?.loading]);

  const dynamicHeaders: TableHeaderProps[] = FollowUp.map((header) => ({
    headerName: header.headerName,
    className: header.cellClassName,
    field: header.field,
    headerAlign: header.headerAlign as GridAlignment,
    align: header.align as GridAlignment,
    flex: header.flex,
  }));
  const columns: GridColDef[] = [...dynamicHeaders];

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !StudentEnquiryData.loading) {
            StudentEnquiryData.fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetStudentEnquires.edges;
                const pageInfo = fetchMoreResult.GetStudentEnquires.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck =
                  prevResult.GetStudentEnquires.edges.filter(
                    ({ node: { id } }) =>
                      newEdges.findIndex(
                        ({ node: { id: newId } }) => newId === id
                      ) !== -1
                  );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetStudentEnquires: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: StudentEnquiryData.data
                      ? StudentEnquiryData.data.GetStudentEnquires.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (StudentEnquiryData.data && !StudentEnquiryData.loading) {
      const newData = StudentEnquiryData.data.GetStudentEnquires.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.std_id && row.std_id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_id: node.id,
            date_of_enquiry: toStandardDate(node.enq_date),
            name: node.first_name + node.middle_name + node.last_name,
            email: node.std_email,
            mobile: node.std_mobile,
            status: findEnquiry(node.last_follow_up_status),
            previous_studied_institution: node.std_previous_school,
            follow_up_date:
              toStandardDate(node.last_follow_up_made_date) ===
              toStandardDate(DEFAULT_TIME)
                ? "-"
                : toStandardDate(node.last_follow_up_made_date),
            next_follow_up_date:
              toStandardDate(node.next_follow_up_date) ===
              toStandardDate(DEFAULT_TIME)
                ? "-"
                : toStandardDate(node.next_follow_up_date),
            follow_up_count: node.follow_up_count,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_id: node.id,
            date_of_enquiry: toStandardDate(node.enq_date),
            name: node.first_name + node.middle_name + node.last_name,
            email: node.std_email,
            mobile: node.std_mobile,
            status: findEnquiry(node.last_follow_up_status),
            previous_studied_institution: node.std_previous_school,
            follow_up_date:
              toStandardDate(node.last_follow_up_made_date) ===
              toStandardDate(DEFAULT_TIME)
                ? "-"
                : toStandardDate(node.last_follow_up_made_date),
            next_follow_up_date:
              toStandardDate(node.next_follow_up_date) ===
              toStandardDate(DEFAULT_TIME)
                ? "-"
                : toStandardDate(node.next_follow_up_date),
            follow_up_count: node.follow_up_count,
          }))
        );
      }
      setEndCursor(
        StudentEnquiryData.data.GetStudentEnquires.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [StudentEnquiryData.data, StudentEnquiryData.loading]);

  return (
    <>
      <div className="std-enquiry-followup">
        <div className="row g-0 std-enquiry-followup__details">
          <div className="col-2 studentlist__Search">
            <Input
              id="search"
              placeholder="Serach..."
              value={searchEnqStudent}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchEnqStudent(e.target.value);
              }}
            />
          </div>
          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={ENQ_STATUS_OPTIONS}
              value={follow_up_status}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, follow_up_status)
              }
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (follow_up_status) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  set_follow_up_status(null);
                }
              }}
              onChange={(e, newValue) => {
                if (newValue) {
                  set_follow_up_status(newValue);
                } else {
                  set_follow_up_status(null);
                }
              }}
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Enquiry Status"
                  // required={follow_up_status ? false : true}
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-1">
            <Button
              mode="clear"
              className="std-enquiry-followup__details--clear"
              onClick={handleClear}
            />
          </div>
        </div>
        <div className={`std-enquiry-followup__tableblock ${dataClasses.root}`}>
          {StudentEnquiryData.error ? (
            <b className="nodata">{StudentEnquiryData.error.message}</b>
          ) : students?.length ? (
            <DataGridPro
              columns={columns}
              rows={rows}
              onCellClick={(params) => {
                if (params.field === "name")
                  dispatch({
                    type: payloadTypes.SET_STD_ENQUIRY_ID,
                    payload: { studentEnquiryId: params.row.std_id },
                  });
                setStudentDetailsModal(!studentDetailsModal);
              }}
              disableRowSelectionOnClick
              disableChildrenSorting
              rowHeight={TABLE_ROW_HEIGHT}
              hideFooter
              slotProps={{
                columnsPanel: { disableHideAllButton: true },
              }}
            />
          ) : (
            <b className="nodata">No Students found</b>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              onClick={() => {
                setRowsPerPage(null);
                setPdfFlag(true);
              }}
              mode="pdf"
            />
            <Button
              onClick={() => {
                setRowsPerPage(null);
                setExcelFlag(true);
              }}
              mode="export"
            />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 std-enquiry-followup__total">
            <StudentTotalCount totalCount={stdData?.length!} />
          </div>
        </div>
      </div>

      {/* student-enquiry-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentDetailsModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentDetails setStudentDetailsModal={setStudentDetailsModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentDetailsModal(!studentDetailsModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
