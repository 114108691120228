import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../Types/Tables";
import { SalaryProcessTitleProps } from "../../../../Types/Titles";
import { EMPTY_STRING, FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";
import {
  Operation,
  PrMkSalQueryType,
  ProvisionalSalaryDetailsType,
  SalaryDetailsModalType,
} from "../../../../utils/Enum.types";
import {
  formatter,
  getModifiedScrollHeight,
  MonthName,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import Home from "../../Home/Index";
import SalaryProcess from "./SalaryProcess";
import Modal from "react-modal";
import {
  PickSubjectModalStyles,
  SuccessModalCustomStyles,
} from "../../../../styles/ModalStyles";

import { useLazyQuery, useMutation } from "@apollo/client";
import { UpdatePrSalWorkSalMade } from "../../queries/salary/mutation";
import useToken from "../../../../customhooks/useToken";
import useSalaryMkWorkByMonth from "../../hooks/useSalaryMkWorkByMonth";
import { msgType } from "../../../../utils/Form.types";
import MessageModal from "../../../../pages/MessageModal";
import useSalaryMkEmpMaster from "../../hooks/useSalaryMkEmpMaster";
import {
  GetPayRollCurrentMonthCalendarData,
  GetPayRollCurrentMonthCalendarVars,
} from "../../Types/paginationTypes";
import { idWithModalType } from "./EmpAttendance";
import SalaryStructDetailsModal from "./SalaryStructDetailsModal";
import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";
import {
  GetPayRollSalaryMkWorkByMonthId,
  GetPayRollSalaryProcessCount,
} from "../../queries/salary/list";
import { DESIGNATION, ESI } from "../../constants";
import { GetPayRollCurrentMonthCalendar } from "../../queries/holidays/query";
import {
  calculateDeduction,
  calculateMonthlyBasicGrossGroupLedgers,
  getGroupIds,
  getSalCalDaysMethod,
} from "../../utils/Functions";
import {
  EarnedSalaryMethod,
  PayRollConfigKeys,
  SalaryLedgerType,
} from "../../enums/Enum.types";
import { EmpProvisionalSalaryReportTableStyles } from "../../../../styles/StickyTableStyles";
import {
  GetPayRollSalaryProcessCountData,
  GetPayRollSalaryProcessCountVars,
} from "./CloseSalaryProcess";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const { HRTitles } = require("../../json/title.json");
const { HR_Table } = require("../../json/table.json");
interface Props {
  pageType: ProvisionalSalaryDetailsType;
}

const enum stickyTableHeaders {
  SL = "Sl",
  Name = "Staff Name",
  Beneficiary = "Beneficiary Name",
}

interface EmpListStickyDetailsType {
  id: number;
  emp_name: string;
  emp_bank_acct_no: string;
}
interface EmpListType extends EmpListStickyDetailsType {
  emp_epf_no: string;
  designation_desc: string;
  emp_sal_str_basic: number;
  emp_sal_str_total_earnings: number;
  emp_sal_str_total_deductions: number;
  emp_net_salary: number;
  emp_bank_ifsc: string;
  emp_bank_name: string;
  emp_mobile: string;
  emp_email: string;
}
interface StickyTableCellsProps {
  node: EmpListStickyDetailsType;
  index: number;
}

const ProvisionalSalaryDetails = ({ pageType }: Props) => {
  const { InstId, monthId } = useParams();

  const navigate = useNavigate();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const { format } = formatter;
  const classes = EmpProvisionalSalaryReportTableStyles();
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [saveModal, setSaveModal] = useState(false);

  const [salaryDetailsModal, setSalaryDetailsModal] = useState<idWithModalType>(
    {
      id: 0,
      flag: false,
    }
  );
  const [employeesList, setEmployeesList] = useState<EmpListType[]>([]);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { serverDate } = useServerDateandTime();
  const { USE_HR_DESIGNATION } = usePayRollMastersConfig();
  const { monthlyData, pr_acd_yr } = useSalaryMkWorkByMonth(monthId!);
  const { sal_wrk_for_month, sal_wrk_job_completed } =
    monthlyData.data?.node || {};
  const { configData: salRoundOffDecimal } = useSwConfigData(
    PayRollConfigKeys.ROUND_TO_DECIMAL
  );
  const salRoundOffKey = salRoundOffDecimal.data
    ? salRoundOffDecimal.data?.GetSwConfigVariables[0].config_boolean_value
    : false;
  const { configData: salNoOfDaysConfig } = useSwConfigData(
    PayRollConfigKeys.EARNED_SAL_CAL
  );
  const salNoOfDaysType = salNoOfDaysConfig.data
    ? salNoOfDaysConfig.data?.GetSwConfigVariables[0].config_string_value
    : EarnedSalaryMethod.MONTH_DAYS;

  const {
    salaryMkEmployeeData: {
      data: employees,
      loading: employeesLoading,
      fetchMore,
    },
  } = useSalaryMkEmpMaster(
    pr_acd_yr?.id!,
    PrMkSalQueryType.FOR_MK_SAL_PROCESS,
    0,
    0,
    0,
    0,
    0,
    EMPTY_STRING
  );

  const [GetSalaryProcessCount, { data: countData }] = useLazyQuery<
    GetPayRollSalaryProcessCountData,
    GetPayRollSalaryProcessCountVars
  >(GetPayRollSalaryProcessCount, {
    variables: {
      inst_id: InstId!,
      pr_acd_yr_id: pr_acd_yr?.id!,
      token,
      pr_sal_mk_month_id: monthId!,
    },
  });

  const {
    total_emp_sal_mk_basic,
    total_emp_sal_mk_earned_basic,
    total_emp_sal_mk_other_earn,
    total_emp_sal_mk_gross,
    total_emp_sal_mk_others_ded,
    total_emp_sal_mk_net,
  } = countData?.GetPayRollSalaryProcessCount || {};

  const TableStickyHeaders = () => {
    const tableHeaderJson =
      pageType === ProvisionalSalaryDetailsType.DETAILED
        ? HR_Table.SalaryProcess.ProvisionalSalaryDetails.StickyHeaders
        : HR_Table.SalaryProcess.ProvisionalSalaryDetails
            .BeneficiaryStickyHeaders;
    return (
      <>
        {tableHeaderJson
          .filter((th: TableHeaderProps) => {
            if (th.labelName === DESIGNATION && USE_HR_DESIGNATION === false) {
              return false;
            } else {
              return true;
            }
          })
          .map((th: TableHeaderProps, index: React.Key) => {
            return (
              <TableCell
                key={index}
                className={
                  th.labelName === stickyTableHeaders.SL
                    ? classes.stickyHeaderSl
                    : th.labelName === stickyTableHeaders.Name ||
                      th.labelName === stickyTableHeaders.Beneficiary
                    ? classes.stickyHeaderName
                    : classes.stickyHeaderAccNo
                }
                align="center"
              >
                {th.labelName}
              </TableCell>
            );
          })}
      </>
    );
  };
  const TableStickyCells = ({ node, index }: StickyTableCellsProps) => {
    return (
      <>
        <TableCell id="td-center" className={classes.stickyColumnSl}>
          {index + 1}
        </TableCell>
        <TableCell
          className={`salary-process__prov-salary--table--name ${classes.stickyColumnName}`}
          onClick={() => {
            if (pageType === ProvisionalSalaryDetailsType.DETAILED) {
              setSalaryDetailsModal({
                id: node.id,
                flag: true,
              });
            }
          }}
        >
          {node.emp_name}
        </TableCell>
        <TableCell className={classes.stickyColumnAccNo}>
          {node.emp_bank_acct_no}
        </TableCell>
      </>
    );
  };
  //Pagination

  const employeeDetails = employees?.GetPayRollSalaryMkEmpMaster.edges.find(
    ({ node }) => node.id === salaryDetailsModal?.id
  );

  const [GetMonthHolidays, { data }] = useLazyQuery<
    GetPayRollCurrentMonthCalendarData,
    GetPayRollCurrentMonthCalendarVars
  >(GetPayRollCurrentMonthCalendar, {
    variables: {
      token,
      pr_acd_yr_id: pr_acd_yr?.id!,
      inst_id: InstId!,
      cal_month: monthlyData.data?.node.sal_wrk_for_month!,
    },
  });

  const { total_no_of_workdays, no_of_days, no_of_weekend_days } =
    data?.GetPayRollCurrentMonthCalendar || {};
  const [UpdateSalaryMake] = useMutation(UpdatePrSalWorkSalMade, {
    onError: (e) =>
      setMessage({
        operation: Operation.NONE,
        message: e.message,
        flag: true,
      }),
  });

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !employeesLoading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetPayRollSalaryMkEmpMaster.edges;
            const pageInfo =
              fetchMoreResult.GetPayRollSalaryMkEmpMaster.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetPayRollSalaryMkEmpMaster.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetPayRollSalaryMkEmpMaster: {
                edges: [
                  ...prevResult.GetPayRollSalaryMkEmpMaster.edges,
                  ...newEdges,
                ],
                pageInfo,
                totalCount: employees?.GetPayRollSalaryMkEmpMaster.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const handleSubmit = () => {
    UpdateSalaryMake({
      variables: {
        token,
        inst_id: InstId,
        user_details,

        pr_sal_mk_month_id: monthlyData.data?.node.id!,
        month_start_date: toIsoDate(serverDate),
      },
      refetchQueries: [
        {
          query: GetPayRollSalaryMkWorkByMonthId,
          variables: {
            token,
            inst_id: InstId!,
            id: Number(monthId),
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setSaveModal(!saveModal);
        navigate(
          `/${InstId}/hr/payroll/salaryprocess/${monthlyData.data?.node.id}/closesalaryprocess`
        );
      }
    });
  };
  const handleClose = () =>
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });

  useEffect(() => {
    if (pr_acd_yr?.id! && sal_wrk_for_month!) {
      GetMonthHolidays();
    }
  }, [pr_acd_yr?.id, sal_wrk_for_month, GetMonthHolidays]);
  useEffect(() => {
    if (employees && !employeesLoading && no_of_days && total_no_of_workdays) {
      const numOfDaysBasedOnCOnfiguration = getSalCalDaysMethod(
        salNoOfDaysType as EarnedSalaryMethod,
        no_of_days,
        no_of_weekend_days!
      );
      setEmployeesList(
        employees?.GetPayRollSalaryMkEmpMaster.edges?.map(({ node }) => {
          const { monthlyBasic, monthlyGross } =
            calculateMonthlyBasicGrossGroupLedgers(
              node.emp_sal_mk_basic,
              node.emp_sal_mk_fixed_gross,
              node.emp_sal_mk_earned_days - node.emp_sal_mk_lwp,
              node.confirmed_salary_details,
              numOfDaysBasedOnCOnfiguration!,
              salRoundOffKey,
              node.emp_sal_mk_remaining_ldgr_bal
            );

          const totalDeductions = node.confirmed_salary_details
            .filter(
              ({ emp_sal_earn_ded }) =>
                emp_sal_earn_ded === SalaryLedgerType.DEDUCTION
            )
            .reduce((acc, ledger) => {
              const {
                monthlyBasic: basicAmt,
                monthlyGross: grossAmt,
                monthlyGroupSalary: groupSal,
              } = calculateMonthlyBasicGrossGroupLedgers(
                node.emp_sal_mk_basic,
                node.emp_sal_mk_fixed_gross,
                node.emp_sal_mk_earned_days - node.emp_sal_mk_lwp,
                node.confirmed_salary_details,
                numOfDaysBasedOnCOnfiguration!!,
                salRoundOffKey,
                node.emp_sal_mk_remaining_ldgr_bal,
                new Set(getGroupIds(ledger.emp_sal_group_id_heads))
              );

              acc += calculateDeduction(
                getGroupIds(ledger.emp_sal_group_id_heads),
                ledger.emp_sal_ded_rule,
                basicAmt,
                grossAmt,
                groupSal,
                salRoundOffKey,
                ledger.sal_ldgr_details.sal_ldgr_type === ESI &&
                  node.emp_sal_mk_fixed_gross > 21000
              );
              return acc;
            }, 0);
          return {
            id: node.id,
            emp_name: `${node.emp_details.emp_first_name} ${node.emp_details.emp_middle_name} ${node.emp_details.emp_last_name}`,
            emp_bank_acct_no: node.emp_details.other_details.emp_bank_acct_no,
            emp_epf_no: node.emp_details.other_details.emp_epf_no,
            designation_desc:
              node.emp_details.pr_designation_details.designation_desc,
            emp_sal_str_basic: monthlyBasic,
            emp_sal_str_total_earnings: monthlyGross,
            emp_sal_str_total_deductions: totalDeductions,
            emp_net_salary: monthlyGross - totalDeductions,
            emp_bank_ifsc: node.emp_details.other_details.emp_bank_ifsc,
            emp_bank_name: node.emp_details.other_details.emp_bank_name,
            emp_mobile: node.emp_details.emp_mobile,
            emp_email: node.emp_details.emp_email,
          };
        }) ?? []
      );
    }
  }, [
    salRoundOffKey,
    employees,
    employeesLoading,
    no_of_days,
    total_no_of_workdays,
    hasNextPage,
    endCursor,
    no_of_weekend_days,
    salNoOfDaysType,
  ]);
  useEffect(() => {
    if (pr_acd_yr) {
      GetSalaryProcessCount();
    }
  }, [pr_acd_yr, GetSalaryProcessCount]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {HRTitles.SalaryProcess.Titles.map(
          (title: SalaryProcessTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.ProvisionalSalaryDetails}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="salary-process">
        <div className="salary-process__data">
          <div className="salary-process__data--left-block">
            <SalaryProcess monthDetails={monthlyData.data?.node!} />
          </div>
          <div className="salary-process__data--right-block">
            <div className="row g-0 salary-process__prov-salary--label">
              <div className="col salary-process__prov-salary--label--buttons">
                <NavLink
                  to={`/${InstId}/hr/payroll/salaryprocess/${monthlyData.data?.node.id}/provisionalsalarydetails/detailed`}
                >
                  <Label>Detailed Salary Information</Label>
                </NavLink>
                <NavLink
                  to={`/${InstId}/hr/payroll/salaryprocess/${monthlyData.data?.node.id}/provisionalsalarydetails/bankscroll`}
                >
                  <Label>Bank Scroll</Label>
                </NavLink>
              </div>
              <div className="col-2">
                <TextField
                  label="Salary Process Month"
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={MonthName(
                    monthlyData.data?.node.sal_wrk_for_month!
                  ).toString()}
                  className="salary-process__prov-salary--label--textfield"
                />
              </div>
            </div>
            <div className="row g-0 salary-process__prov-salary--details">
              <div className="salary-process__prov-salary--details--block">
                <Title variant="subtitle1">Total Salary Details</Title>
                <div className="salary-process__prov-salary--details--block--image-flex">
                  <div className="salary-process__prov-salary--details--block--image-flex--block">
                    <Label>Actual Basic</Label>
                    <span>{total_emp_sal_mk_basic}</span>
                  </div>
                  <div className="salary-process__prov-salary--details--block--image-flex--block">
                    <Label>Earned Basic</Label>
                    <span>{total_emp_sal_mk_earned_basic}</span>
                  </div>
                  <div className="salary-process__prov-salary--details--block--image-flex--block">
                    <Label>Other Earnings</Label>
                    <span>{total_emp_sal_mk_other_earn}</span>
                  </div>
                  <div className="salary-process__prov-salary--details--block--image-flex--block">
                    <Label>Gross Earnings</Label>
                    <span>{total_emp_sal_mk_gross}</span>
                  </div>
                  <div className="salary-process__prov-salary--details--block--image-flex--block">
                    <Label>Deductions</Label>
                    <span>{total_emp_sal_mk_others_ded}</span>
                  </div>
                  <div className="salary-process__prov-salary--details--block--image-flex--block">
                    <Label>Net Salary</Label>
                    <span>{total_emp_sal_mk_net}</span>
                  </div>
                </div>
              </div>
              <div className="salary-process__prov-salary--tableblock">
                {pageType === ProvisionalSalaryDetailsType.DETAILED ? (
                  <TableContainer
                    className={`salary-process__prov-salary--table ${classes.table}`}
                    onScroll={handleScroll}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableStickyHeaders />
                          {HR_Table.SalaryProcess.ProvisionalSalaryDetails.DetailedInformation.filter(
                            (th: TableHeaderProps) => {
                              if (
                                th.labelName === DESIGNATION &&
                                USE_HR_DESIGNATION === false
                              ) {
                                return false;
                              } else {
                                return true;
                              }
                            }
                          ).map((th: TableHeaderProps, index: React.Key) => {
                            return (
                              <TableCell
                                key={index}
                                className={classes.stickyHeader}
                              >
                                {th.labelName}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {employeesList.map((data, index) => {
                          return (
                            <TableRow key={index}>
                              <TableStickyCells
                                node={{
                                  id: data.id,
                                  emp_name: data.emp_name,
                                  emp_bank_acct_no: data.emp_bank_acct_no,
                                }}
                                index={index}
                              />

                              <TableCell className={classes.stickyColumn}>
                                {data.emp_epf_no}
                              </TableCell>
                              {USE_HR_DESIGNATION ? (
                                <TableCell className={classes.stickyColumnDesc}>
                                  {data.designation_desc}
                                </TableCell>
                              ) : null}
                              <TableCell
                                className={classes.stickyColumn}
                                id="td-right"
                              >
                                {data.emp_sal_str_basic}
                              </TableCell>
                              <TableCell
                                className={classes.stickyColumn}
                                id="td-right"
                              >
                                {data.emp_sal_str_total_earnings}
                              </TableCell>
                              <TableCell
                                className={classes.stickyColumn}
                                id="td-right"
                              >
                                {data.emp_sal_str_total_deductions}
                              </TableCell>
                              <TableCell
                                className={classes.stickyColumn}
                                id="td-right"
                              >
                                {data.emp_net_salary}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <TableContainer className={classes.table}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableStickyHeaders />
                          {HR_Table.SalaryProcess.ProvisionalSalaryDetails.BankScroll.map(
                            (th: TableHeaderProps, index: React.Key) => {
                              return (
                                <TableCell
                                  key={index}
                                  className={classes.stickyHeader}
                                >
                                  {th.labelName}
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {employeesList.map((data, index) => {
                          return (
                            <TableRow key={index}>
                              <TableStickyCells
                                node={{
                                  id: data.id,
                                  emp_name: data.emp_name,
                                  emp_bank_acct_no: data.emp_bank_acct_no,
                                }}
                                index={index}
                              />

                              <TableCell className={classes.stickyColumn}>
                                {data.emp_bank_ifsc}
                              </TableCell>
                              <TableCell className={classes.stickyColumnDesc}>
                                {data.emp_bank_name}
                              </TableCell>

                              <TableCell className={classes.stickyColumn}>
                                {data.emp_mobile}
                              </TableCell>
                              <TableCell className={classes.stickyColumnDesc}>
                                {data.emp_email}
                              </TableCell>
                              <TableCell
                                className={classes.stickyColumn}
                                id="td-right"
                              >
                                {format(data.emp_net_salary)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            </div>
          </div>
        </div>
        <Button mode="print" />
        <Button
          mode="save-continue"
          onClick={() => setSaveModal(!saveModal)}
          disabled={sal_wrk_job_completed}
        />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={saveModal}
        style={SuccessModalCustomStyles}
        ariaHideApp={false}
      >
        <Title>Salary Details Confirmed</Title>
        <div>
          <Label>Provisional Salary Has Been Confirmed Successfully</Label>
        </div>
        <Button mode="okay" onClick={handleSubmit} />
        <Button mode="cancel" onClick={() => setSaveModal(!saveModal)} />
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={salaryDetailsModal.flag}
        style={PickSubjectModalStyles}
        ariaHideApp={false}
      >
        <SalaryStructDetailsModal
          employeeDetails={employeeDetails!}
          salaryDetailsModalType={SalaryDetailsModalType.PROVISIONAL_SALARY}
          monthlyData={monthlyData?.data?.node!}
          setSalaryDetailsModal={setSalaryDetailsModal}
          monthDays={data?.GetPayRollCurrentMonthCalendar!}
        />
      </Modal>
      <MessageModal
        modalFlag={message.flag}
        handleClose={handleClose}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default ProvisionalSalaryDetails;
