import React, { useContext, useEffect, useRef, useState } from "react";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import Avatar from "../../../../images/Avatar.svg";
import useAcdStudentDetails from "../../hooks/useAcdStudentDetails";
import {
  DateRange,
  GetnumberOfDaysInMonth,
  MonthName,
  formatter,
  handleDate,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../../hooks/useAcdGeneralHolidays";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  ROWS_PER_PAGE,
  days,
} from "../../../../utils/constants";
import { useLazyQuery, useMutation } from "@apollo/client";

import {
  GetAcdAttendanceSubjMonthly,
  GetAcdStdAttBySession,
  GetAcdStdAttOverAll,
  GetAcdStdAttSubjMonthlyPerSessionForCal,
} from "../../queries/holidays/list";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import { UpdateAttendanceSubjMonthly } from "../../queries/holidays/mutation";
import { msgType } from "../../../../utils/Form.types";
import {
  AttendanceStatus,
  Direction,
  HolidayType,
  InstitutionConfigurationTypes,
  Operation,
  SortBy,
} from "../../../../utils/Enum.types";
import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import { monthDaysType } from "./MonthlyOverview";
import { Drawer, FormControlLabel, Tooltip } from "@mui/material";
import { AntSwitch } from "../../../../pages/Switch";
import { Button } from "../../../../stories/Button/Button";
import { INCREMENT_DECREMENT } from "../../../Enquiry/Dashboard/Index";
import Previous from "../../../../images/CalenderPreviousButton.svg";
import Next from "../../../../images/CalenderNextButton.svg";
import Close from "../../../../images/Close.svg";
import CalendarLegends, {
  CalendarFor,
} from "../../../../pages/CalendarLegends";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import {
  AcdStdAttSubjQueryType,
  GetAcdAttendanceSubjMonthlyNode,
} from "./SessionAttendance";
import { userDetails } from "../../../../Types/Accounting/other";
import { GetAcdStdAttOverAllData } from "../../hooks/useAcdStudentAttendance";
import {
  GetAcdStdAttOverAllVars,
  GetAcdStdAttSubjMonthlyPerSessionForCalData,
  GetAcdStdAttSubjMonthlyPerSessionForCalVars,
} from "../../types/holidays";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";

import { GetAcdInstSessionsList } from "../../hooks/useAcdInstSessions";
import { GetAcdAttSessionsBySubjectForTheDay } from "../../queries/sessions/list";
import dayjs from "dayjs";
import useCheckAllocationType from "../../hooks/useCheckAllocationType";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import Modal from "react-modal";
import { ErrorModalStyles } from "../../../../styles/ModalStyles";
import Edit from "../../../../images/EditProfile.svg";
import { perStudentAttendanceStyles } from "../../../../styles/DrawerStyles";
import AbsentDays from "../../../Master/Student/Dashboard/Academics/AbsentDays";
import GraphData from "./SessionDetails/GraphData";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { GlobalPageConfigData } from "../../../../Types/configtypes";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";

const emptyDaysByWeek = (week: string) => {
  let numberOfEmpty = -1;
  let numberOfEmptyToLast = -1;
  switch (week) {
    case "Sun":
      numberOfEmpty = 6;
      numberOfEmptyToLast = 0;
      break;
    case "Mon":
      numberOfEmpty = 0;
      numberOfEmptyToLast = 6;
      break;
    case "Tue":
      numberOfEmpty = 1;
      numberOfEmptyToLast = 5;

      break;
    case "Wed":
      numberOfEmpty = 2;
      numberOfEmptyToLast = 4;
      break;
    case "Thu":
      numberOfEmpty = 3;
      numberOfEmptyToLast = 3;
      break;
    case "Fri":
      numberOfEmpty = 4;
      numberOfEmptyToLast = 2;
      break;
    case "Sat":
      numberOfEmpty = 5;
      numberOfEmptyToLast = 1;
      break;
    default:
      break;
  }
  return { numberOfEmpty, numberOfEmptyToLast };
};

interface UpdateAttendanceSubjMonthlyData {
  UpdateAttendanceSubjMonthly: boolean;
}
interface UpdateAttendanceSubjMonthlyVars {
  token: string;
  inst_id: number;
  user_details: userDetails;
  acd_yr_id: number;
  date_of_attendance: string;
  student_id: number;
  session_id: number;
  subj_master_id: number;
  from_status: string;
  to_status: string;
}
interface Props {
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  studentAttendanceDetails: GetAcdAttendanceSubjMonthlyNode | null;
  setPerStudentAttendance: React.Dispatch<React.SetStateAction<boolean>>;
  perStudentAttendance: boolean;
  selectedQuerySession: GetAcdInstSessionsList | null;
}
const ViewPerStdAttendanceSession = ({
  selectedDate,
  setSelectedDate,
  studentAttendanceDetails,
  setPerStudentAttendance,
  perStudentAttendance,
  selectedQuerySession,
}: Props) => {
  const { token } = useToken();
  const { InstId, subjectId, entryId } = useParams();
  const { state } = useContext(AppContext);
  const { studentFormData } = useAcdStudentDetails();
  const { user_details } = useLoggedInUserDetails();
  const { serverDate } = useServerDateandTime();
  const { entry_level } = useInstitutionConfiguration();
  const { activeAcademicYearData } = useActiveAcademicYear();
  var generalHolidaysMap = useRef(new Map());
  const [imageString, setImageString] = useState("");
  const [absentiesList, setAbsentiesList] = useState(false);
  const [markAttendance, setMarkAttendance] = useState(false);
  const { firstDay, lastDay } = DateRange(selectedDate.toString()) || {};
  const [attStatus, setAttStatus] = useState(false);
  const drawerClasses = perStudentAttendanceStyles();

  const [monthDaysForSession, setMonthDaysForSession] =
    useState<monthDaysType[]>();

  const [errorAppearedFromApi, setErrorAppearedFromApi] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { userRights } = useUserRightsByEmpId();
  const isGlobalUser =
    userRights.data && userRights.data.GetUserRightsByEmpId.is_inst_sysadmin
      ? true
      : false;

  const [GetData, { data: attData }] = useLazyQuery<
    GetAcdStdAttSubjMonthlyPerSessionForCalData,
    GetAcdStdAttSubjMonthlyPerSessionForCalVars
  >(GetAcdStdAttSubjMonthlyPerSessionForCal, {
    onError: (e) => {
      setErrorAppearedFromApi(e.message);
    },
  });
  const { configData } = useSwConfigData([
    InstitutionConfigurationTypes.ENABLE_TEACHER_TO_EDIT_ATTENDANCE,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let enableTeacherAttEdit = false;

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.ENABLE_TEACHER_TO_EDIT_ATTENDANCE:
            enableTeacherAttEdit = item.config_boolean_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      enableTeacherAttEdit,
    };
  };

  const { enableTeacherAttEdit } = filterDataByConfigKey(
    configData.data ? configData.data.GetSwConfigVariables : []
  );

  const [GetStdAttendanceBySessionId, { data: attendanceData }] = useLazyQuery(
    GetAcdStdAttBySession
  );

  const { flag } = useCheckAllocationType();
  const sessionAttQuery = {
    query: GetAcdAttendanceSubjMonthly,
    variables: {
      token,
      input: {
        att_query_type:
          AcdStdAttSubjQueryType.SUBJ_MONTHLY_ATT_PER_SUBJECT_PER_SESSION,
        entry_id: entryId!,
        entry_level,
        cal_month: toIsoDate(firstDay!),
        inst_id: InstId!,
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
        student_id: 0,
        subj_master_id: Number(subjectId),
        date_of_attendance: toIsoDate(selectedDate.toString()),
        session_id: selectedQuerySession ? selectedQuerySession.id : 0,
      },
      per_std_subj_allocation: flag,
      after: null,
      first: ROWS_PER_PAGE,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.STD_ROLL_NO,
      },
    },
  };
  const sessionAttQueryStatus = {
    query: GetAcdStdAttBySession,
    variables: {
      token,
      inst_id: InstId,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      date_of_attendance: toIsoDate(selectedDate.toString()),
      student_id: state.studentId,
      session_id: selectedQuerySession ? selectedQuerySession.id : 0,
      subj_master_id: Number(subjectId),
      per_std_subj_allocation: flag ? flag : false,
    },
  };

  const sessionsQuery = {
    query: GetAcdAttSessionsBySubjectForTheDay,
    variables: {
      token,
      date_of_attendance: toIsoDate(selectedDate.toString()),
      cal_month: toIsoDate(firstDay!),
      acd_yr_id: activeAcademicYearData.data
        ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
        : 0,
      entry_id: entryId!,
      entry_level: entry_level,
      inst_id: InstId!,
      subj_master_id: Number(subjectId),
      per_std_subj_allocation: flag,
    },
  };
  const [UpdateAttendance, { loading }] = useMutation<
    UpdateAttendanceSubjMonthlyData,
    UpdateAttendanceSubjMonthlyVars
  >(UpdateAttendanceSubjMonthly, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const month = selectedDate.getMonth();
  const year = selectedDate.getFullYear();

  const chooseTypeAndColor = (status: string, day: number) => {
    if (generalHolidaysMap.current.has(day)) {
      const holiday_details = generalHolidaysMap.current.get(day)!;
      switch (holiday_details.holiday_type) {
        case HolidayType.GENERAL || HolidayType.UNEXPECTED:
          return {
            type: holiday_details.holiday_type,
            description: holiday_details.holiday_description,
            class_name: "border-left-for-holidays",
          };
        case HolidayType.WEEKEND || HolidayType.VACATION:
          return {
            type: holiday_details.holiday_type,
            description: holiday_details.holiday_description,
            class_name: "weekend-dates",
          };
        case HolidayType.VACATION:
          return {
            type: holiday_details.holiday_type,
            description: holiday_details.holiday_description,
            class_name: "weekend-dates",
          };
        default:
          break;
      }
    } else {
      if (status === "P;" || status === AttendanceStatus.P) {
        return {
          type: AttendanceStatus.P,
          description: "",
          class_name: "cal-bg-green",
        };
      } else if (status === "A;" || status === AttendanceStatus.A) {
        return {
          type: AttendanceStatus.A,
          description: "",
          class_name: "cal-bg-red",
        };
      } else {
        return {
          type: "",
          description: "",
          class_name: "",
        };
      }
    }
  };
  const weekCount = () => {
    if (monthDaysForSession && monthDaysForSession.length > 0) {
      const firstWeek = monthDaysForSession[0].week;

      const lastWeek =
        monthDaysForSession[monthDaysForSession.length - 1]?.week;

      const startArray = Array.from({
        length: emptyDaysByWeek(firstWeek).numberOfEmpty,
      })
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
          attendance: [],
        }));

      const endArray = new Array(emptyDaysByWeek(lastWeek!).numberOfEmptyToLast)
        .fill(null)
        .map(() => ({
          day: -1,
          status: "",
          description: "",
          week: "",
          date: new Date(DEFAULT_TIME),
          class_name: "",
          attendance: [],
        }));

      return [...startArray, ...monthDaysForSession, ...endArray];
    } else {
      return [];
    }
  };

  const handleAttendence = () => {
    if (!selectedDate || studentAttendanceDetails === null) return;
    UpdateAttendance({
      variables: {
        token,
        inst_id: Number(InstId),
        user_details,
        acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
        date_of_attendance: toIsoDate(selectedDate.toString()),
        student_id: state.studentId,
        session_id: studentAttendanceDetails.session_id,
        subj_master_id: Number(subjectId),
        from_status: attendanceData && attendanceData.GetAcdStdAttBySession,
        to_status: attStatus ? AttendanceStatus.P : AttendanceStatus.A,
      },
      refetchQueries: [sessionAttQuery, sessionsQuery, sessionAttQueryStatus],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Updated Attendance Status",
          operation: Operation.UPDATE,
        });
      }
    });
    setMarkAttendance(!markAttendance);
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setPerStudentAttendance(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const [GetOverAllAttendance, { data: OverAllData }] = useLazyQuery<
    GetAcdStdAttOverAllData,
    GetAcdStdAttOverAllVars
  >(GetAcdStdAttOverAll, {
    variables: {
      input: {
        acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,

        entry_id: entryId ? Number(entryId) : 0,
        entry_level,
        inst_id: state.InstId!,
        cal_month: toIsoDate(firstDay!),
        subj_master_id: 0,
        session_id: 0,
        student_id: state.studentId,
        att_query_type: "OVER_ALL_ATT_PER_STD",
        date_of_attendance: toIsoDate(serverDate),
      },
      token,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.STD_ROLL_NO,
      },
      after: null,
      first: null,
      name: EMPTY_STRING,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
  useEffect(() => {
    if (
      entry_level &&
      token &&
      entryId &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetOverAllAttendance();
    }
  }, [
    GetOverAllAttendance,
    entry_level,
    token,
    entryId,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    state.ActiveAcdYr,
  ]);

  const presentPercentage = Number(
    OverAllData &&
      OverAllData.GetAcdStdAttOverAll.edges.map((response, index) => {
        return response.node.over_all_per;
      })
  );
  const { format } = formatter;

  useEffect(() => {
    if (
      state.studentId &&
      firstDay &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetData({
        variables: {
          token,
          inst_id: state.InstId!,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          date_of_attendance: toIsoDate(firstDay),
          student_id: state.studentId,
          session_id: selectedQuerySession ? selectedQuerySession.id : 0,
          subj_master_id: Number(subjectId),
          per_std_subj_allocation: flag,
        },
      }).then(({ data }) => {
        if (data) {
          const arrLength = GetnumberOfDaysInMonth(firstDay);
          const res = Array.from({ length: arrLength }, (_, i) => {
            const dayNum = i + 1;
            const dayKey = `day_${dayNum}`;
            const date = new Date(year, month, dayNum);
            const holiday_details = chooseTypeAndColor(
              (
                data.GetAcdStdAttSubjMonthlyPerSessionForCal as Record<
                  string,
                  string
                >
              )[dayKey],
              dayNum
            );

            return {
              day: dayNum,
              status: holiday_details?.type,
              description: holiday_details?.description,
              week: date.toLocaleString("en-US", { weekday: "short" }),
              class_name: holiday_details?.class_name!,
              date,
              holiday_types: [],
              events_length: 0,
            };
          });
          setMonthDaysForSession(res);
          setErrorAppearedFromApi("");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.studentId,
    firstDay,
    state.InstId,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    GetData,
    attData,
    state.ActiveAcdYr,
    flag,
    // date,
  ]);

  useEffect(() => {
    if (attendanceData?.GetAcdStdAttBySession === AttendanceStatus.P) {
      setAttStatus(true);
    } else {
      setAttStatus(false);
    }
  }, [attendanceData, selectedDate]);
  useEffect(() => {
    if (
      token &&
      selectedQuerySession &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      subjectId &&
      selectedDate &&
      state.ActiveAcdYr
    ) {
      GetStdAttendanceBySessionId({
        variables: {
          token,
          inst_id: InstId,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          date_of_attendance: toIsoDate(selectedDate.toString()),
          student_id: state.studentId,
          session_id: selectedQuerySession.id,
          subj_master_id: Number(subjectId),
          per_std_subj_allocation: flag,
        },
      });
    }
  }, [
    GetStdAttendanceBySessionId,
    token,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    subjectId,
    selectedDate,
    state.ActiveAcdYr,
    selectedQuerySession,
    flag,
  ]);

  useEffect(() => {
    if (studentFormData.std_profile_pic !== "") {
      getDownloadUrl(studentFormData.std_profile_pic, false, setImageString);
    }
  }, [studentFormData.std_profile_pic]);

  return (
    <>
      <div className="attendance-overview__std-att">
        <div className="attendance-overview__std-att--title">
          <Title variant="subtitle1">Per Student Attendance Overview</Title>
          <img
            src={Close}
            alt="/"
            onClick={() => setPerStudentAttendance(!perStudentAttendance)}
          />
        </div>
        <div className="attendance-overview__std-att--datablock row g-0">
          <div className="col attendance-overview__std-att--frame">
            <div className="attendance-overview__std-att--details">
              <div className="attendance-overview__std-att--details--image">
                <img src={imageString ? imageString : Avatar} alt="/" />
              </div>
              <div className="col attendance-overview__std-att--details--list">
                <Label>Name</Label>
                <Label>:</Label>
                <Label>{studentFormData.std_name}</Label>
                <Label>Admission No.</Label>
                <Label>:</Label>
                <Label>{studentFormData.adm_no}</Label>
                <Label>Reg No.</Label>
                <Label>:</Label>
                <Label>{studentFormData.reg_number}</Label>
              </div>
            </div>
            <div className="attendance-overview__std-att--cards row g-0">
              <div className="attendance-overview__std-att--blocks col">
                <span className="attendance-overview__std-att--blocks--header">
                  Total Sessions
                </span>
                <span className="attendance-overview__std-att--blocks--attendance">
                  {attendanceData &&
                    OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node
                      ?.total_session}
                </span>
              </div>
              <div className="attendance-overview__std-att--blocks col">
                <span className="attendance-overview__std-att--blocks--header">
                  Total Present
                </span>
                <span className="attendance-overview__std-att--blocks--present">
                  {OverAllData &&
                    OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node
                      .total_session_present}
                </span>
              </div>
              <div className="attendance-overview__std-att--blocks col">
                <span className="attendance-overview__std-att--blocks--header">
                  Total Absent
                </span>
                <span className="attendance-overview__std-att--blocks--absent">
                  {OverAllData &&
                    OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node
                      .total_session_absent}
                </span>
              </div>
              <div className="attendance-overview__std-att--blocks col">
                <span className="attendance-overview__std-att--blocks--header">
                  Total Percentage
                </span>
                <span className="attendance-overview__std-att--blocks--percentage">
                  {OverAllData &&
                    OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node
                      .over_all_per}
                  %
                </span>
              </div>
            </div>
            {OverAllData &&
            OverAllData?.GetAcdStdAttOverAll?.edges[0]?.node.over_all_per ===
              100 ? null : (
              <div className="attendance-overview__std-att--button">
                <Button
                  mode="absent"
                  onClick={() => setAbsentiesList(!absentiesList)}
                >
                  View Absent Days
                </Button>
              </div>
            )}

            <div className="attendance-overview__std-att--percentage">
              <div className="attendance-overview__std-att--percentage--text">
                <b>OverAll Attendance Percentage</b>
                <b className="font-green">{format(presentPercentage)}%</b>
              </div>

              <div className="attendance-overview__std-atd--graph">
                <GraphData />
              </div>
            </div>
          </div>

          <div className="col attendance-overview__std-att--frame">
            <div className="attendance-overview__std-att--leave-details">
              <Title variant="subtitle1">
                Selected Session :&nbsp;
                <b>
                  {selectedQuerySession && selectedQuerySession.session_desc}
                </b>
              </Title>
              <Label>{toStandardDate(selectedDate.toString())}</Label>

              <div className="attendance-overview__std-att--leave-details--status">
                <div className="attendance-overview__std-att--leave-details--text">
                  <span>Attendance Status</span>
                  <b
                    className={
                      attendanceData &&
                      attendanceData.GetAcdStdAttBySession ===
                        AttendanceStatus.P
                        ? "attendance-overview__std-att--leave-details--pr"
                        : attendanceData &&
                          attendanceData.GetAcdStdAttBySession ===
                            AttendanceStatus.A
                        ? "attendance-overview__std-att--leave-details--ab"
                        : "attendance-overview__std-att--leave-details--pr"
                    }
                  >
                    {attendanceData &&
                    attendanceData.GetAcdStdAttBySession === AttendanceStatus.P
                      ? "Present"
                      : attendanceData &&
                        attendanceData.GetAcdStdAttBySession ===
                          AttendanceStatus.A
                      ? "Absent"
                      : "Mark Attendance"}
                  </b>
                  {enableTeacherAttEdit || isGlobalUser ? (
                    <img
                      src={Edit}
                      alt=""
                      className="attendance-overview__std-att--leave-details--edit"
                      onClick={() => setMarkAttendance(!markAttendance)}
                    />
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>

            <div className="attendance-overview__std-att--month">
              <img
                src={Previous}
                alt="/"
                onClick={() => {
                  handleDate(
                    selectedDate,
                    setSelectedDate,
                    activeAcademicYearData.data?.GetAcdYrActiveByInstId
                      .acd_st_date!,
                    activeAcademicYearData.data?.GetAcdYrActiveByInstId!
                      .acd_end_date!,
                    setMessage,
                    INCREMENT_DECREMENT.DECREMENT
                  );
                }}
              />

              <Title variant="subtitle1">
                {MonthName(selectedDate.toString())}
              </Title>
              <img
                src={Next}
                alt="/"
                onClick={() => {
                  const nextDate = new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth() + 1,
                    selectedDate.getDate()
                  );
                  const serverDateDayjs = dayjs(serverDate);
                  const selectedDateDayjs = dayjs(nextDate);
                  if (
                    serverDateDayjs.isAfter(selectedDateDayjs) &&
                    !serverDateDayjs.isSame(selectedDateDayjs)
                  ) {
                    handleDate(
                      selectedDate,
                      setSelectedDate,
                      activeAcademicYearData.data?.GetAcdYrActiveByInstId
                        .acd_st_date!,
                      activeAcademicYearData.data?.GetAcdYrActiveByInstId!
                        .acd_end_date!,
                      setMessage,
                      INCREMENT_DECREMENT.INCREMENT
                    );
                  } else {
                    setMessage({
                      message: "Cannot Access Future dates",
                      flag: true,
                      operation: Operation.NONE,
                    });
                  }
                }}
              />
            </div>
            {errorAppearedFromApi ? (
              <>
                <br />
                <b className="nodata">{errorAppearedFromApi}</b>
              </>
            ) : (
              <div className="attendance-overview__std-att--calendar">
                <ul className="attendance-overview__student-attendance--grid-day">
                  {days.map((day, index: React.Key) => {
                    return (
                      <li
                        key={index}
                        className="attendance-overview__student-attendance--grid-container--day"
                      >
                        {day}
                      </li>
                    );
                  })}
                </ul>
                <ul className="attendance-overview__student-attendance--grid-container">
                  {weekCount().map(
                    (
                      {
                        day,
                        status,
                        description,
                        date,
                        class_name,
                        attendance,
                      },
                      index
                    ) => {
                      return day ? (
                        status === HolidayType.GENERAL ||
                        status === HolidayType.UNEXPECTED ? (
                          <Tooltip
                            title={<>{description}</>}
                            arrow
                            placement="right"
                            key={index}
                          >
                            <li
                              className={`attendance-overview__student-attendance--grid-item ${class_name}`}
                            >
                              <span
                                className={`attendance-overview__student-attendance--grid-item--date `}
                              >
                                {day > 0 ? day : ""}
                              </span>
                            </li>
                          </Tooltip>
                        ) : (
                          <li
                            key={index}
                            className={`attendance-overview__student-attendance--grid-item ${class_name}`}
                          >
                            <span
                              className={`attendance-overview__student-attendance--grid-item--date `}
                            >
                              {day > 0 ? day : ""}
                            </span>
                          </li>
                        )
                      ) : (
                        <li key={index}></li>
                      );
                    }
                  )}
                </ul>
              </div>
            )}
            <div className="attendance-overview__std-att--legends">
              <CalendarLegends
                useCalendarIn={CalendarFor.PER_STUDENT_ATTENDANCE}
              />
            </div>
          </div>
        </div>

        <Button mode="cancel" onClick={() => setPerStudentAttendance(false)} />
      </div>

      <LoadingModal flag={loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={markAttendance}
        style={ErrorModalStyles}
      >
        <div className="attendance-overview__std-att--title">
          <Title>Change Status</Title>
          <img
            src={Close}
            alt=""
            onClick={() => setMarkAttendance(!markAttendance)}
          />
        </div>
        <div className="attendance-overview__std-att--block">
          <FormControlLabel
            label={
              attendanceData &&
              attendanceData.GetAcdStdAttBySession === AttendanceStatus.P
                ? "Present"
                : attendanceData &&
                  attendanceData.GetAcdStdAttBySession === AttendanceStatus.A
                ? "Absent"
                : "Mark Attendance"
            }
            labelPlacement="bottom"
            control={
              <AntSwitch
                checked={attStatus}
                onClick={() => setAttStatus(!attStatus)}
              />
            }
            className="monthly-attendance-list__view--form-labels"
          />
        </div>
        <Button mode="save" onClick={handleAttendence} />
        <Button
          mode="cancel"
          onClick={() => setMarkAttendance(!markAttendance)}
        />
      </Modal>
      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={absentiesList}
        onClose={() => setAbsentiesList(!absentiesList)}
      >
        <AbsentDays setModalFlag={setAbsentiesList} />
      </Drawer>
    </>
  );
};

export default ViewPerStdAttendanceSession;
