import { useState } from "react";
import { Button } from "../../../stories/Button/Button";
import Add from "../../../images/Add.svg";

import { Drawer } from "@mui/material";
import { attendanceOverViewStyles } from "../../../styles/DrawerStyles";
import New from "./New";
import {
  GetAcdSubjectForEntryLevelDetails,
  SubjectFormData,
  SubjectType,
} from "../types/subject/Subject";
import List, { defaultEditDetailsType } from "./List";
import { Operation } from "../../../utils/Enum.types";

import { convertMinsToHours } from "../../../utils/UtilFunctions";
interface Props {
  selectedSubject: SubjectType | null;
}

export interface totalType {
  total_chapters: number;
  total_duration: number;
}
const Create = ({ selectedSubject }: Props) => {
  const drawerClasses = attendanceOverViewStyles();

  const [mainChapter, setMainChapter] = useState(false);

  const [totals, setTotals] = useState<totalType>({
    total_chapters: 0,
    total_duration: 0,
  });

  return (
    <>
      <div className="create-lesson-planner">
        {selectedSubject ? (
          <div className="create-lesson-planner__header">
            <div className="create-lesson-planner__header--selected">
              <b>{selectedSubject.subj_desc}</b>
              <span>( {selectedSubject.subj_code} )</span>
            </div>
            <div className="create-lesson-planner__header--durations">
              <span>Total Chapters -</span>
              <b className="font-blue">{totals.total_chapters}</b>
              <div className="create-lesson-planner__header--separator"></div>
              <span>Total Duration -</span>
              <b className="font-blue">
                {convertMinsToHours(totals.total_duration)}
              </b>
            </div>
            <div className="create-lesson-planner__header--buttons">
              <Button mode="export"> Template</Button>
              <Button onClick={() => setMainChapter(!mainChapter)}>
                <img src={Add} alt="" />
                Create Main Chapter
              </Button>
            </div>
          </div>
        ) : null}
        <List selectedSubject={selectedSubject} setTotals={setTotals} />
      </div>
      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={mainChapter}
        onClose={() => setMainChapter(!mainChapter)}
      >
        <New
          setDrawer={setMainChapter}
          operation={Operation.CREATE}
          selectedSubject={selectedSubject}
          editDetails={defaultEditDetailsType}
        />
      </Drawer>
    </>
  );
};

export default Create;
