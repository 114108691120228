import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Compare from "../../../../../images/Compare.svg";
import { Button } from "../../../../../stories/Button/Button";

import { useLazyQuery } from "@apollo/client";

import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useActiveAcademicYear from "../../../hooks/useActiveAcademicYear";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import { toIsoDate } from "../../../../../utils/UtilFunctions";

import { GetAcdUnMarkedSessionsForTheDay } from "../../../queries/sessions/list";
import { AppContext } from "../../../../../context/context";
import useCheckAllocationType from "../../../hooks/useCheckAllocationType";
import useAcdInstSessions, {
  GetAcdInstSessionsList,
} from "../../../hooks/useAcdInstSessions";
export interface GetAcdUnMarkedSessionsForTheDayList {
  id: number;
  session_desc: string;
  start_time: string;
  end_time: string;
  session_idx: number;
  inst_id: number | string;
}
export interface GetAcdUnMarkedSessionsForTheDayData {
  GetAcdUnMarkedSessionsForTheDay: GetAcdUnMarkedSessionsForTheDayList[];
}
export interface GetAcdUnMarkedSessionsForTheDayVars {
  token: string;
  inst_id: string;
  acd_yr_id: number;
  entry_id: string | number;
  entry_level: string;
  date_of_attendance: string;
  per_std_subj_allocation: boolean | undefined;
}
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedSession: React.Dispatch<
    React.SetStateAction<GetAcdInstSessionsList | null>
  >;
  selectedSession: GetAcdInstSessionsList | null;
  attDate: Date;
  setAttendanceFlag?: React.Dispatch<React.SetStateAction<boolean>>;
}

const UnMarkedSessionList = ({
  setModalFlag,
  setSelectedSession,
  selectedSession,
  attDate,
  setAttendanceFlag,
}: Props) => {
  const { token } = useToken();
  const { InstId, entryId } = useParams();
  const { entry_level } = useInstitutionConfiguration();
  const { state } = useContext(AppContext);
  const { activeAcademicYearData } = useActiveAcademicYear();

  const { flag } = useCheckAllocationType();

  const handleChange = (node: GetAcdInstSessionsList) => {
    setSelectedSession(node);
  };
  const { SessionsQueryResult } = useAcdInstSessions();
  const [GetSessionData, { data: SessionData }] = useLazyQuery<
    GetAcdUnMarkedSessionsForTheDayData,
    GetAcdUnMarkedSessionsForTheDayVars
  >(GetAcdUnMarkedSessionsForTheDay, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      entry_level: entry_level,
      entry_id: entryId ? entryId : 0,
      date_of_attendance: toIsoDate(attDate.toString()),
      per_std_subj_allocation: flag,
    },
  });
  useEffect(() => {
    if (
      token &&
      entryId &&
      entry_level &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr
    ) {
      GetSessionData();
    }
  }, [
    GetSessionData,
    token,
    entryId,
    entry_level,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    state.ActiveAcdYr,
    flag,
  ]);

  return (
    <>
      <div className="per-session-details__list">
        <Title>Attendance Timing</Title>

        <ul className="per-session-details__list--data">
          <FormLabel id="demo-controlled-radio-buttons-group">
            Select a Single Session for Attendance entry
          </FormLabel>

          {SessionsQueryResult.data &&
          SessionsQueryResult.data.GetAcdInstSessions.length > 0 ? (
            SessionsQueryResult.data.GetAcdInstSessions.map((node, index) => {
              return (
                <li key={index}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={selectedSession}
                      onChange={() => handleChange(node)}
                    >
                      <FormControlLabel
                        value={node}
                        control={<Radio />}
                        label={
                          <div className="per-session-details__list--details">
                            <span> {node.session_desc}</span>
                            <div className="per-session-details__list--data--flex">
                              <TextField
                                label="Start Date"
                                InputLabelProps={{ shrink: true }}
                                defaultValue={node.start_time}
                                type="time"
                                className="per-session-details__list--data--textfield"
                              />
                              <img src={Compare} alt="" />
                              <TextField
                                label="End Date"
                                InputLabelProps={{ shrink: true }}
                                defaultValue={node.end_time}
                                type="time"
                                className="per-session-details__list--data--textfield"
                              />
                            </div>
                          </div>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </li>
              );
            })
          ) : (
            <b className="nodata">No Sessions available.</b>
          )}
        </ul>
        <Button
          mode="okay"
          disabled={!selectedSession}
          onClick={() => {
            setModalFlag(false);
            setAttendanceFlag?.(true);
          }}
        />
        <Button
          mode="cancel"
          onClick={() => {
            setModalFlag(false);
            setSelectedSession(null);
          }}
        />
      </div>
    </>
  );
};

export default UnMarkedSessionList;
