import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import { LibraryTitleProps } from "../../../../Types/Titles";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import EditProfile from "../../../../images/EditProfile.svg";
import Delete from "../../../../images/Delete.svg";

import Input from "../../../../stories/Input/Input";
import InputHoc from "../../../../components/common/Input/Index";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  D4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../Constants";
import Eduate from "../../../../images/Eduate_Logo_image.png";

import { LibraryLabelNameProps } from "../../../../Types/Labels";
import { Label } from "../../../../stories/Label/Label";
import { Button } from "../../../../stories/Button/Button";
import {
  Direction,
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
  PredefinedDataTypes,
  ReturnType,
  SortBy,
  TableHeaders,
} from "../../../../utils/Enum.types";
import {
  GetLibBookVendorsByInstIdData,
  GetLibBookVendorsByInstIdVars,
  VendorNode,
  VendorNodeEdge,
} from "../../../../Types/Library/MasterData/Vendor/paginationTypes";

import { useNavigate, useParams } from "react-router-dom";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import {
  AUTOCOMPLETE_CLEAR_CLASSNAME,
  AUTOCOMPLETE_INPUT_CLASSNAME,
  EMPTY_STRING,
  FETCH_MORE_DATA,
  LIBRARY_ROWS_PER_PAGE,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import Modal from "react-modal";
import Close from "../../../../images/Close.svg";

import { msgType, optionsType, VendorType } from "../../../../utils/Form.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GetLibBookVendorsByInstId } from "../../../../queries/Library/MasterData/Vendor/list/byInstId";
import { ByNode as VendorById } from "../../../../queries/Library/Node";
import { AddLibBookVendor } from "../../../../queries/Library/MasterData/Vendor/mutations/new";
import { UpdateLibBookVendor } from "../../../../queries/Library/MasterData/Vendor/mutations/update";
import { DeleteLibBookVendorById } from "../../../../queries/Library/MasterData/Vendor/mutations/delete";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  removeMoreSpace,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import { Form, Formik } from "formik";
import { VendorValidation } from "../../../../utils/validationRules";
import LoadingModal from "../../../../pages/LoadingModal";
import DeleteModal from "../../../../pages/DeleteModal";
import MessageModal from "../../../../pages/MessageModal";
import DownArrow from "../../../../images/DownArrow.svg";

import useToken from "../../../../customhooks/useToken";
import { Keys } from "../../../../utils/Enum.keys";

import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import useServerDateandTime from "../../customHooks/useServerDateandTime";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { VendorHeader } from "../../../Accounts/common/HeaderConsts";
import TextArea from "../../../../stories/TextArea/TextArea";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryTableJson from "../../json/useLibraryTableJson";
import useLibraryformJson from "../../json/useLibraryformJson";
import { AppContext } from "../../../../context/context";
import LibraryImport from "../../Imports/MasterImport";

import {
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DataGridTableStyles";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../utils/types";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import { LibraryMasters } from "../../enums/Enum.types";

const { Library } = require("../../../../json/title.json");

interface Props {
  type: string;
  modalFlag?: boolean;
  setModalFlag?: (modalFlag: boolean) => void;
}
export interface vendorNodeData {
  nodes: VendorNode[];
}
export interface vendorNodeVars {
  token: string;
  ids: number[] | number;
}

const Index = ({ type, modalFlag, setModalFlag }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const dataClasses = useDataGridStyles();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const { Library_Table } = useLibraryTableJson();
  const { LibraryFormLabels } = useLibraryformJson();
  const { user_details } = useLoggedInUserDetails();
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const [operation, setOperation] = useState(Operation.CREATE);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [sortBy] = useState(SortBy.VENDOR_NAME);
  const [direction] = useState(Direction.ASC);
  const [searchData, setSearchData] = useState("");
  const [VendorId, setVendorId] = useState(0);
  const nameRef = document.getElementsByName(
    "vendor_name"
  )[0] as HTMLInputElement;
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(
    LIBRARY_ROWS_PER_PAGE
  );
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { InstDetails } = useInstDetails(1);
  const { serverDate } = useServerDateandTime();

  const [formData, setFormData] = useState<VendorType>({
    vendor_name: "",
    vendor_address: "",
    vendor_city: "",
    vendor_state: "",
    vendor_country: "",
    vendor_phone: "",
    vendor_contact_person: "",
    vendor_contact_person_phone: "",
    vendor_pan: "",
    vendor_gst: "",
  });
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [vendorList, setVendorList] = useState<VendorNodeEdge[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [importModal, setImportModal] = useState(false);

  //Refs
  const CityRef = useRef<HTMLSelectElement>(null);
  const cityInputRef = CityRef?.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const CityCloseRef = CityRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;
  const StateRef = useRef<HTMLSelectElement>(null);
  const stateEnterRef = StateRef?.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const StateCloseRef = StateRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;
  const CountryRef = useRef<HTMLSelectElement>(null);
  const countryEnterRef = CountryRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_INPUT_CLASSNAME
  )[0] as HTMLInputElement;
  const CountryCloseRef = CountryRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;
  const SaveRef = useRef<HTMLButtonElement>(null);
  const vendorNameRef = document.getElementsByName(
    "vendor_name"
  )[0] as HTMLInputElement;

  //Queries
  const [GetVendorData, { data, error, loading, fetchMore }] = useLazyQuery<
    GetLibBookVendorsByInstIdData,
    GetLibBookVendorsByInstIdVars
  >(GetLibBookVendorsByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      first: ROWS_PER_PAGE,
      sortBy,
      direction,
      name: searchData,
      after: null,
    },
  });
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;
  const [GetVendor, { data: VendorData, loading: VendorLoading }] =
    useLazyQuery<vendorNodeData, vendorNodeVars>(VendorById);

  //Mutations
  const [AddVendor, { loading: creationLoading }] = useMutation(
    AddLibBookVendor,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateVendor, { loading: updationLoading }] = useMutation(
    UpdateLibBookVendor,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteVendor, { loading: deleteLoading }] = useMutation(
    DeleteLibBookVendorById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  //cutomHooks
  const {
    PredefinedData: { dropDown: City },
  } = usePredefinedDataByType(
    PredefinedDataTypes.CITY,
    formData.vendor_city ?? EMPTY_STRING,
    ReturnType.VALUE_SAME_AS_LABEL
  );
  const {
    PredefinedData: { dropDown: States },
  } = usePredefinedDataByType(
    PredefinedDataTypes.STATE,
    formData.vendor_state ?? EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: Nationality },
  } = usePredefinedDataByType(
    PredefinedDataTypes.NATIONALITY,
    formData.vendor_country ?? EMPTY_STRING
  );
  //Methods
  const handleClear = () => {
    setFormData({
      vendor_name: "",
      vendor_address: "",
      vendor_city: "",
      vendor_state: "",
      vendor_country: "",
      vendor_phone: "",
      vendor_contact_person: "",
      vendor_contact_person_phone: "",
      vendor_pan: "",
      vendor_gst: "",
    });
    CityCloseRef?.click();
    StateCloseRef?.click();
    CountryCloseRef?.click();
    vendorNameRef?.focus();
    setOperation(Operation.CREATE);
    nameRef?.focus();
  };

  const mediaData = data?.GetLibBookVendorsByInstId.edges.map(
    (data, index) => ({
      SlNo: index + 1,
      Name: data.node.vendor_name,
      Phone: data.node.vendor_phone,
      Pan: data.node.vendor_pan,
      Gst: data.node.vendor_gst,
    })
  );
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Vendor's List");

    const dataRows = mediaData
      ? mediaData?.map((item) => [
          item.SlNo,
          item.Name,
          item.Phone,
          item.Pan,
          item.Gst,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 12;
    worksheet.getColumn(2).width = 35;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 25;
    worksheet.getColumn(5).width = 22;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          if (configLogo) {
            worksheet.addImage(imageV, "E1:E3");
          }

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:E2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:E3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "Vendor's List";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("C4:C4");
                  const mergedDate: Cell = worksheet.getCell(D4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("D4:E4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < VendorHeader.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = VendorHeader[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(
                      DOWNLOAD,
                      InstDetails.data?.nodes[0]?.inst_name!
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(LIBRARY_ROWS_PER_PAGE);
                });
              });
          });
        });
      });
  };
  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setOperation(Operation.CREATE);
      //incase if user clicks back the modal will open which should not happen and if he clicks the back in message it should close
    }
    setMessage({
      flag: !message.flag,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleValueChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const HandleSubmit = () => {
    const input = {
      vendor_name: removeMoreSpace(formData.vendor_name),
      vendor_address: removeMoreSpace(formData.vendor_address),
      vendor_city: removeMoreSpace(formData.vendor_city),
      vendor_state: removeMoreSpace(formData.vendor_state),
      vendor_country: removeMoreSpace(formData.vendor_country),
      vendor_phone: removeMoreSpace(formData.vendor_phone),
      vendor_contact_person: removeMoreSpace(formData.vendor_contact_person),
      vendor_contact_person_phone: removeMoreSpace(
        formData.vendor_contact_person_phone
      ),
      vendor_pan: removeMoreSpace(formData.vendor_pan),
      vendor_gst: removeMoreSpace(formData.vendor_gst),
    };
    if (operation === Operation.CREATE) {
      AddVendor({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          input,
        },
        refetchQueries: [
          {
            query: GetLibBookVendorsByInstId,
            variables: {
              token,
              inst_id: InstId!,
              first: ROWS_PER_PAGE,
              sortBy,
              direction,
              name: EMPTY_STRING,
              after: null,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          if (type === PageFor.MODAL) {
            setModalFlag?.(!modalFlag);
          }
          setMessage({
            flag: !message.flag,
            message: "Vendor Added Successfully",

            operation: Operation.CREATE,
          });
        }
      });
    }

    if (operation === Operation.UPDATE) {
      UpdateVendor({
        variables: {
          token,
          id: VendorId,
          inst_id: InstId,
          user_details,
          input,
        },
        refetchQueries: [
          {
            query: GetLibBookVendorsByInstId,
            variables: {
              token,
              inst_id: InstId!,
              first: ROWS_PER_PAGE,
              sortBy,
              direction,
              name: EMPTY_STRING,
              after: null,
            },
          },
        ],
      }).then(({ data }) => {
        if (data)
          setMessage({
            flag: !message.flag,
            message: "Vendor Updated Successfully",

            operation: Operation.UPDATE,
          });
      });
    }
  };

  const HandleDelete = (id: number) => {
    DeleteVendor({
      variables: {
        token,
        id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetLibBookVendorsByInstId,
          variables: {
            token,
            inst_id: InstId!,
            first: ROWS_PER_PAGE,
            sortBy,
            direction,
            name: EMPTY_STRING,
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: !message.flag,
          message: "Vendor Deleted Successfully",

          operation: Operation.DELETE,
        });
        setDeleteModal(!deleteModal);
      }
    });
  };

  useEffect(() => {
    if (VendorData && !VendorLoading && operation === Operation.UPDATE) {
      const {
        vendor_name,
        vendor_address,
        vendor_city,
        vendor_state,
        vendor_country,
        vendor_phone,
        vendor_contact_person,
        vendor_contact_person_phone,
        vendor_pan,
        vendor_gst,
      } = VendorData.nodes[0];
      setFormData({
        vendor_name,
        vendor_address,
        vendor_city,
        vendor_state,
        vendor_country,
        vendor_phone,
        vendor_contact_person,
        vendor_contact_person_phone,
        vendor_pan,
        vendor_gst,
      });
    }
  }, [VendorData, VendorLoading, operation]);
  useEffect(() => {
    if (token) {
      GetVendorData();
    }
  }, [token, GetVendorData]);
  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !loading) downloadExcel();
    // eslint-disable-next-line
  }, [excelFlag, rowsPerPage, loading]);

  const dynamicHeaders: TableHeaderProps[] =
    Library_Table.Media.Table_Headers?.map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));
  const columns: GridColDef[] = [
    ...dynamicHeaders,

    {
      field: "actions",
      cellClassName: "td-status ",
      headerAlign: "center" as GridAlignment,
      align: "center" as GridAlignment,
      headerName: TableHeaders.ACTION,
      renderCell: (params: GridRenderCellParams) => {
        const vendorId = params.row.vendor_id;

        return (
          <>
            <img
              src={EditProfile}
              alt="/"
              onClick={() => {
                GetVendor({
                  variables: {
                    token,
                    ids: vendorId,
                  },
                }).then(({ data }) => {
                  //enableSave should always stay true if edit is clicked
                  if (data) {
                    setVendorId(vendorId);
                    setOperation(Operation.UPDATE);
                  }
                });
              }}
            />
            &nbsp;
            <img
              src={Delete}
              alt="/"
              onClick={() => {
                setVendorId(vendorId);
                setOperation(Operation.DELETE);
                setDeleteModal(!deleteModal);
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (data && !loading && excelFlag === false) {
      const newData = data.GetLibBookVendorsByInstId.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newVendor) => {
          const filteredStudent = vendorList.find(
            (vendor) => vendor.node.id === newVendor.node.id
          );
          if (filteredStudent) {
            return {
              ...newVendor,
              node: {
                ...newVendor.node,
              },
            };
          }
          return newVendor;
        });
        setVendorList(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            name: node.vendor_name,
            mobile: node.vendor_phone,
            pan_no: node.vendor_pan,
            gst_no: node.vendor_gst,
            vendor_id: node.id,
          }))
        );
      } else {
        setVendorList(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            name: node.vendor_name,
            mobile: node.vendor_phone,
            pan_no: node.vendor_pan,
            gst_no: node.vendor_gst,
            vendor_id: node.id,
          }))
        );
      }
      setEndCursor(data.GetLibBookVendorsByInstId.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, excelFlag]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetLibBookVendorsByInstId.edges;
              const pageInfo =
                fetchMoreResult.GetLibBookVendorsByInstId.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck =
                prevResult.GetLibBookVendorsByInstId.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetLibBookVendorsByInstId: {
                  edges: [...vendorList, ...newEdges],
                  pageInfo,
                  totalCount: data
                    ? data.GetLibBookVendorsByInstId.totalCount!
                    : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [rows]);
  if (error) return <>{error}</>;

  return (
    <>
      {type === PageFor.MODAL ? null : <Home DashBoardRequired={false} />}
      <Title>
        {Library.Titles.Vendor.map(
          (title: LibraryTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Vendor}</React.Fragment>;
          }
        )}
      </Title>

      <Formik
        initialValues={formData}
        validationSchema={VendorValidation}
        onSubmit={HandleSubmit}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form
              className={
                type === PageFor.MODAL
                  ? "library__frame--modal"
                  : "library__frame"
              }
            >
              <div className="library__frame--row">
                <div className="h-100 booktype-left">
                  <div className="library__frame--title">
                    <Title variant="subtitle1">
                      {Library.Titles.Vendor.map(
                        (title: LibraryTitleProps, index: React.Key) => {
                          return (
                            <React.Fragment key={index}>
                              {operation === Operation.UPDATE
                                ? title.Update
                                : title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                  </div>
                  <div className="library__frame--block">
                    {LibraryFormLabels.VendorDetails.InputType.map(
                      (label: LibraryLabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <InputHoc
                              name={label.inputName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              inputref={nameRef!}
                              values={formData[label.inputName]}
                              type={label.dataType}
                              required={label.required}
                              autoFocus={label.autoFocus}
                              LabelName={label.LabelName}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    {LibraryFormLabels.VendorDetails.MultiLine.map(
                      (label: LibraryLabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{label.LabelName}</Label>
                              <TextArea
                                name={label.inputName}
                                onKeyDown={(e: React.KeyboardEvent) => {
                                  if (e.key === Keys.ENTER) {
                                    e.preventDefault();
                                    cityInputRef?.focus();
                                  }
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLTextAreaElement>
                                ) => {
                                  meta.handleChange(e);
                                  handleValueChange(e);
                                }}
                                value={formData.vendor_address}
                                rows={3}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                    )}
                    <div className="label-grid">
                      <Label>City</Label>
                      <Autocomplete
                        classes={classes}
                        options={City!}
                        ref={CityRef!}
                        value={
                          City?.find(
                            ({ value }) => value === formData.vendor_city
                          )! ?? null
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                vendor_city: (newValue as optionsType)?.value!,
                              };
                            });
                          } else {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                vendor_city: EMPTY_STRING,
                              };
                            });
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            stateEnterRef?.focus();
                            CityCloseRef?.focus();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              vendor_city: "",
                            }));
                          }
                        }}
                        openOnFocus
                        freeSolo
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  vendor_city: e.target.value!,
                                };
                              });
                            }}
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="label-grid">
                      <Label>State</Label>
                      <Autocomplete
                        classes={classes}
                        options={States!}
                        value={
                          States?.find(
                            ({ value }) => value === formData.vendor_state
                          )! ?? null
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                vendor_state: (newValue as optionsType)?.value!,
                              };
                            });
                          } else {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                vendor_state: EMPTY_STRING,
                              };
                            });
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            countryEnterRef?.focus();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              vendor_state: "",
                            }));
                          }
                        }}
                        ref={StateRef!}
                        openOnFocus
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  vendor_state: e.target.value!,
                                };
                              });
                            }}
                            fullWidth
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="label-grid">
                      <Label>Country</Label>
                      <Autocomplete
                        classes={classes}
                        options={Nationality! ?? [EMPTY_STRING]}
                        value={
                          Nationality?.find(
                            ({ value }) => value === formData.vendor_country
                          )! ?? null
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                vendor_country: (newValue as optionsType)
                                  ?.value!,
                              };
                            });
                          } else {
                            setFormData((prevValues) => {
                              return {
                                ...prevValues,
                                vendor_country: EMPTY_STRING,
                              };
                            });
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            SaveRef?.current?.focus();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              vendor_country: "",
                            }));
                          }
                        }}
                        ref={CountryRef!}
                        openOnFocus
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => {
                              setFormData((prevValues) => {
                                return {
                                  ...prevValues,
                                  vendor_country: e.target.value!,
                                };
                              });
                            }}
                            fullWidth
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="h-100 booktype-right">
                  <div className="row g-0 library__frame--title">
                    <div className="col-3"></div>
                    <div className="col">
                      <Title variant="subtitle1">
                        {Library.Titles.Vendor.map(
                          (title: LibraryTitleProps, index: React.Key) => {
                            return (
                              <React.Fragment key={index}>
                                {title.List}
                              </React.Fragment>
                            );
                          }
                        )}
                      </Title>
                    </div>
                    <div className="col-3">
                      <Input
                        id="search"
                        placeholder="Search..."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setSearchData(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={`library__frame--block ${dataClasses.root}`}>
                    <DataGridPro
                      columns={columns}
                      rows={rows}
                      rowHeight={TABLE_ROW_HEIGHT}
                      disableRowSelectionOnClick
                      hideFooter
                      slotProps={{
                        columnsPanel: { disableHideAllButton: true },
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="library__buttons">
                <Button mode="save" type="submit" buttonref={SaveRef!} />
                <Button mode="clear" type="button" onClick={handleClear} />
                {type === PageFor.GENERAL ? (
                  <>
                    <Button
                      mode="back"
                      type="button"
                      onClick={() => navigate(-1)}
                    />
                    <Button
                      mode="excel"
                      type="button"
                      onClick={() => setImportModal(!importModal)}
                    >
                      Import Vendor's
                    </Button>
                    <Button
                      mode="excel"
                      type="button"
                      onClick={() => {
                        setRowsPerPage(null);
                        setExcelFlag(true);
                      }}
                    >
                      Export
                    </Button>
                  </>
                ) : (
                  <Button
                    mode="cancel"
                    type="button"
                    onClick={() => setModalFlag?.(false)}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <LibraryImport MasterType={LibraryMasters.VENDOR} />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setImportModal(!importModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      <LoadingModal
        flag={creationLoading ?? updationLoading ?? deleteLoading}
      />
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={VendorId}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;
