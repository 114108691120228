import React, { useContext, useEffect, useState } from "react";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import Modal from "react-modal";

import {
  PageNumbers,
  SortBy,
  StudentListFor,
  StudentAcctReportType,
  Operation,
  PageFor,
} from "../../../../utils/Enum.types";
import { Button } from "../../../../stories/Button/Button";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { AppContext } from "../../../../context/context";
import { useMutation } from "@apollo/client";
import {
  GetAcctLdgrsByTypeDetails,
  studentDemandDetails,
} from "../../../../Types/Accounting";

import { AddOrUpdateOrDeleteStudentDemandDetails } from "../../queries/demands/mutation/index";

import { formatter, toggleFullSceen } from "../../../../utils/UtilFunctions";
import {
  ConfigurationsModalStyles,
  EditModalCustomStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import {
  DemandFeePayableTitleProps,
  FeeDemandTitleProps,
} from "../../../../Types/Titles";
import { TableHeaderProps } from "../../../../Types/Tables";
import Home from "../../Home/Index";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";
import ConfigurationSettings from "../../../Master/configurations/general/Index";
import Avatar from "../../../../images/Avatar.svg";
import LoadingModal from "../../../../pages/LoadingModal";
import Settings from "../../../../images/Settings.svg";
import Edit from "../../../../images/Edit.svg";
import Close from "../../../../images/Close.svg";
import EditProfile from "../../../../images/EditProfile.svg";
import Enlarge from "../../../../images/Enlarge.svg";
import Delete from "../../../../images/Delete.svg";
import { msgType, responseType } from "../../../../utils/Form.types";
import {
  EMPTY_RESPONSETYPE,
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";

import useToken from "../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../../../pages/MessageModal";
import useStudentDemandDetails from "../../hooks/useStudentDemandDetails";
import { Keys } from "../../../../utils/Enum.keys";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import { payloadTypes } from "../../../../context/reducer";
import StudentList from "../../common/StudentList";
import useAcctStdAdmissionNumber from "../../hooks/useAcctStdAdmissionNumber";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  AcctLedgerQueryType,
  DemandDetailsQueryType,
} from "../../common/QueryTypes";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import useAcctTableJson from "../../json/useAcctTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import {
  AddOrUpdateOrDeleteStudentDemandDetailsData,
  AddOrUpdateOrDeleteStudentDemandDetailsVars,
} from "../../../../Types/Accounting/mutations";
import useInstDetails from "../../../../customhooks/general/useInstDetails";

const { AccountsTitles } = require("../../json/title.json");

interface Props {
  pageType: PageFor;
  modalFlag?: boolean;
  setModalFlag?: (modalFlag: boolean) => void;
}
export interface studentNewDemandItems {
  fee_ob: number;
  id: number;
  fee_demand: number;
  fee_concession: number;
  fee_receivable: number;
  fee_received: number;
  fee_refunds: number;
  fee_bal: number;
  acct_ldgr_id: number;
  acct_ldgr_details: GetAcctLdgrsByTypeDetails;
}
const Index = ({ pageType, setModalFlag }: Props) => {
  const textClasses = ListAutoCompleteTextStyles();
  const classes = ListAutoCompleteStyles();
  const { USE_ACCOUNTS } = useUserRightsByEmpId();
  const { format } = formatter;
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const navigate = useNavigate();
  const [admNo, setAdmNo] = useState("");
  const { InstId, studentId } = useParams();
  //use states for modals
  const [studentModal, setStudentModal] = useState(false);
  const [editableModal, setOpenEditableModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [items, setItems] = useState<studentDemandDetails[]>([]);
  const [deletedItems, setDeletedItems] = useState<studentDemandDetails[]>([]);
  //configuration modal
  const [configurationModal, setConfigurationModal] = useState(false);
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.FEE_DEMAND_FEE_PAYABLE_PAGE
  );
  //use state for adding new fee amount
  const [fee_ob, setOutStandingBalance] = useState(0);
  const [fee_demand, setDemandAmount] = useState(0);
  const [fee_concession, setFeeConcession] = useState(0);
  const [fee_receivable, setTotalFee] = useState(0);
  const [fee_received, setFeePaid] = useState(0);
  const [imageString, setImageString] = useState("");
  // eslint-disable-next-line
  const [fee_bal, setBalanceFee] = useState(0);
  const [fee_refunds, setFeeRefund] = useState(0);
  const [acct_ldgr_id, setAccountLedgerId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [id, setId] = useState(0);
  const { user_details } = useLoggedInUserDetails();
  const { InstDetails } = useInstDetails(1);

  const { studentData, studentFormData } = useStudentDatabyId();
  const { acctLedgers } = useAcctLedgerData(
    EMPTY_STRING,
    AcctLedgerQueryType.STD_FEE_ACCT_LDGRS,
    ROWS_PER_PAGE,
    false,
    SortBy.LDGR_DESC,
    0,
    acct_ldgr_id.value
  );
  //queries
  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.DEMAND_RAISED
  );
  const { StudentDemandDetails } = useStudentDemandDetails(
    DemandDetailsQueryType.ALL_DEMAND_DETAILS,
    false,
    EMPTY_STRING
  );
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  let totalOutStandingBalance = 0;
  let concessionSum = 0;
  let paidSum = 0;
  let payableSum = 0;
  let balanceSum = 0;
  let feeDemandSum = 0;

  items?.forEach(
    ({
      fee_demand,
      fee_concession,
      fee_received,
      fee_ob,
      fee_bal,
      fee_receivable,
    }) => {
      totalOutStandingBalance += fee_ob;
      feeDemandSum += fee_demand;
      concessionSum += fee_concession;
      balanceSum += fee_bal;
      payableSum += fee_receivable;
      paidSum += fee_received;
    }
  );

  //mutations
  const [
    UpdateStudentDemandDetails,
    { loading: UpdateStudentDemandDetailsLoading },
  ] = useMutation<
    AddOrUpdateOrDeleteStudentDemandDetailsData,
    AddOrUpdateOrDeleteStudentDemandDetailsVars
  >(AddOrUpdateOrDeleteStudentDemandDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const handleItemEdit = (
    fee_ob: number,
    fee_demand: number,
    fee_concession: number,
    fee_receivable: number,
    fee_received: number,
    fee_bal: number,
    fee_refunds: number
  ) => {
    setOutStandingBalance(fee_ob);
    setDemandAmount(fee_demand);
    setFeeConcession(fee_concession);
    setTotalFee(fee_receivable);
    setFeePaid(fee_received);
    setBalanceFee(fee_bal);
    setFeeRefund(fee_refunds);
  };

  const HandleSelectFeeType = (id: number) => {
    const filterdData = acctLedgers.responseType?.find(
      ({ value }) => value === id
    );

    if (filterdData) setAccountLedgerId(filterdData);
    return filterdData;
  };

  const HandleEditItem = (sl_number: number) => {
    if (fee_demand < fee_received) {
      alert("Feedemand cannot be less than received amount");
      return;
    }
    const updatedData = items.map((item) => {
      if (item.id === sl_number) {
        const newData = {
          ...item,
          fee_ob: fee_ob,
          fee_demand: fee_demand,
          fee_concession: item.fee_concession,
          fee_receivable:
            item.fee_receivable > 0
              ? fee_demand + fee_ob - item.fee_concession
              : item.fee_receivable,
          fee_received: item.fee_received,
          fee_bal:
            // fee recieble formula
            item.fee_bal > 0
              ? fee_demand + fee_ob - item.fee_concession - item.fee_received
              : item.fee_bal,
          fee_refunds: fee_refunds,
          acct_ldgr_id: HandleSelectFeeType(acct_ldgr_id.value)?.value!,
        };
        return newData;
      }

      return item;
    });
    setItems(updatedData);
    setOpenEditableModal(!editableModal);

    setOutStandingBalance(0);
    setDemandAmount(0);
    setFeeConcession(0);
    setTotalFee(0);
    setFeePaid(0);
    setBalanceFee(0);
    setFeeRefund(0);
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      dispatch({
        type: payloadTypes.SET_STUDENT_ID,
        payload: { studentId: 0 },
      });
      setItems([]);
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const handleStudentDemandDetailsUpdation = () => {
    if (InstId === undefined) {
      alert("InstId not found");
      return;
    }
    if (state.ActiveFinYr === null) {
      alert("Financial Year not found");
      return;
    }
    UpdateStudentDemandDetails({
      variables: {
        token,
        student_id: [state.studentId.toString()],
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        input: {
          update_std_demand_details: items
            .filter((item) => item.id)
            .map((item) => ({
              id: item.id!,
              fee_ob: item.fee_ob,
              fee_demand: item.fee_demand,
            })),
          delete_std_demand_details: deletedItems
            .filter((item) => item.id)
            .map((item) => ({
              id: item.id!,
            })),
        },
        user_details,
        inst_id: InstId!,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Demand Updated Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const handleItemDeleted = (id: number) => {
    const updatedItems = items.filter(
      ({ acct_ldgr_id }) => acct_ldgr_id !== id
    );
    const itemsTobeDeleted = items.filter(
      ({ acct_ldgr_id }) => acct_ldgr_id === id
    );
    setDeletedItems([...deletedItems, ...itemsTobeDeleted]);
    setItems(updatedItems);
  };

  const handleClear = () => {
    setItems([]);
    dispatch({ type: payloadTypes.SET_STUDENT_ID, payload: { studentId: 0 } });
    setImageString(EMPTY_STRING);
  };

  useEffect(() => {
    if (StudentDemandDetails.data && state.studentId > 0) {
      setItems(
        StudentDemandDetails.data?.GetAcctStdDemandDetails.filter(
          (item) => item !== null
        )
      );
    }
  }, [state.studentId, StudentDemandDetails.data]);
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== EMPTY_STRING &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${
        InstDetails.data?.nodes[0]?.inst_name
      }/students/${
        state.studentId ? state.studentId : studentId
      }/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    } else {
      setImageString("");
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  const { Accounts_Table } = useAcctTableJson();
  const { branchLabel, classLabel,categoryLabel } = useInstLabels();

  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>
            {AccountsTitles.DemandFeePayable.Titles.map(
              (title: DemandFeePayableTitleProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {title.Demand_Fee_Payable}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>
        <div className="configuration-settings">
          {pageType === PageFor.GENERAL ? (
            <>
              {USE_CONFIG_KEY && (
                <img
                  src={Settings}
                  alt="/"
                  id="settings-icon"
                  onClick={() => setConfigurationModal(!configurationModal)}
                />
              )}
            </>
          ) : (
            <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
          )}
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 demand-fee"
            : "row g-0 demand-fee--modal"
        }
      >
        <div className="col">
          <div className="demand-fee__grid">
            {pageType === PageFor.GENERAL ? (
              <Autocomplete
                classes={classes}
                options={studentAddmissionNumber}
                value={
                  state.studentId
                    ? studentAddmissionNumber?.find(
                        ({ value }) => value === state.studentId
                      )!
                    : null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: (newValue as responseType)?.value,
                      },
                    });
                  } else {
                    handleClear();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                    setAdmNo(EMPTY_STRING);
                  }
                }}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    onChange={(e) => setAdmNo(e.target.value)}
                    label="Admission No."
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            ) : (
              <TextField
                label="Admission No."
                className="demand-fee__textfield"
                InputLabelProps={{ shrink: true }}
                value={studentFormData?.adm_no}
                disabled
              />
            )}
            {pageType === PageFor.GENERAL ? (
              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() => {
                  dispatch({
                    type: payloadTypes.SET_STUDENT_ID,
                    payload: {
                      studentId: 0,
                    },
                  });
                  setStudentModal(!studentModal);
                }}
              />
            ) : null}
          </div>
          <TextField
            label="Name"
            className="demand-fee__textfield"
            InputLabelProps={{ shrink: true }}
            value={studentFormData?.std_name}
            disabled
          />

          <TextField
            label="Register Number"
            className="demand-fee__textfield"
            InputLabelProps={{ shrink: true }}
            value={studentFormData?.reg_number}
            disabled
          />
        </div>
        <div className="col-5">
          <TextField
            label={branchLabel}
            className="demand-fee__textfield"
            InputLabelProps={{ shrink: true }}
            value={studentFormData?.branch}
            disabled
          />
          <TextField
            label={classLabel}
            className="demand-fee__textfield"
            InputLabelProps={{ shrink: true }}
            value={studentFormData?.class}
            disabled
          />
          {USE_CATEGORY_KEY && (
            <TextField
               label={categoryLabel}
              className="demand-fee__textfield"
              InputLabelProps={{ shrink: true }}
              value={studentFormData?.category}
              disabled
            />
          )}
        </div>
        <div className="col-1 demand-fee__image">
          {imageString === EMPTY_STRING ? (
            <img src={Avatar} alt="/" />
          ) : (
            <img src={imageString} alt="/" />
          )}
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 demand-fee__tableblock"
            : "row g-0 demand-fee__tableblock--modal"
        }
      >
        <TableContainer className="demand-fee__table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Accounts_Table.FeeDemand.DemandFeePayable.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        <TableCell className={th.className}>
                          {th.labelName}
                        </TableCell>
                      </React.Fragment>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell
                        className="demand-fee__table--slno"
                        align="center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>{item.acct_ldgr_details.ldgr_desc}</TableCell>
                      <TableCell
                        id="td-right"
                        className="demand-fee__table--amount"
                      >
                        {format(item.fee_ob)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="demand-fee__table--amount"
                      >
                        {format(item.fee_demand)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="demand-fee__table--amount"
                      >
                        {format(item.fee_concession)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="demand-fee__table--amount"
                      >
                        {format(item.fee_receivable)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="demand-fee__table--amount"
                      >
                        {format(item.fee_received)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="demand-fee__table--amount"
                      >
                        {format(item.fee_bal)}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="demand-fee__table--actions"
                      >
                        <>
                          {USE_ACCOUNTS.details?.edit && (
                            <img
                              src={EditProfile}
                              onClick={() => {
                                handleItemEdit(
                                  item.fee_ob,
                                  item.fee_demand,
                                  item.fee_concession,
                                  item.fee_receivable,
                                  item.fee_received,
                                  item.fee_bal,
                                  item.fee_refunds
                                );
                                setOpenEditableModal(!editableModal);

                                HandleSelectFeeType(item.acct_ldgr_id);
                                setId(item.id!);
                              }}
                              alt="/"
                            />
                          )}
                          {item.fee_received <= 0 &&
                          USE_ACCOUNTS.details?.delete ? (
                            <img
                              id="delete-profile"
                              src={Delete}
                              alt="/"
                              onClick={() =>
                                handleItemDeleted(items[index].acct_ldgr_id)
                              }
                            />
                          ) : null}
                        </>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2} className="total">
                  Total :
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalOutStandingBalance)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(feeDemandSum)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(concessionSum)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(payableSum)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(paidSum)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(balanceSum)}
                </TableCell>
                <TableCell id="tfoot-td"></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <div
        className={pageType === PageFor.GENERAL ? "demand-fee__buttons" : ""}
      >
        <Button
          mode="save"
          onClick={handleStudentDemandDetailsUpdation}
          disabled={!state.studentId}
        />

        <Button
          mode="clear"
          onClick={handleClear}
          disabled={!state.studentId}
        />

        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} type="button" />
        ) : (
          <Button
            mode="cancel"
            type="button"
            onClick={() => setModalFlag?.(false)}
          />
        )}
      </div>

      {/* deletemodal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={deleteModal}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <>
          <Title>
            {AccountsTitles.FeeDemand.Titles.map(
              (title: FeeDemandTitleProps) => {
                return (
                  <React.Fragment key={title.Changes}>
                    {title.Changes}
                  </React.Fragment>
                );
              }
            )}
          </Title>
          <Label>
            Are you sure? You want to Delete. <br />
            Data will be deleted Permanently
          </Label>
          <div className="row">
            <div className="col">
              <Button mode="delete" onClick={() => setDeleteModal(false)} />

              <Button mode="clear" onClick={() => setDeleteModal(false)} />
            </div>
          </div>
        </>
      </Modal>

      {/* editable-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={editableModal}
        ariaHideApp={false}
        style={EditModalCustomStyles}
      >
        <>
          <Title>
            {AccountsTitles.DemandFeePayable.Titles.map(
              (title: DemandFeePayableTitleProps) => {
                return (
                  <React.Fragment key={title.Update}>
                    {title.Update}
                  </React.Fragment>
                );
              }
            )}
          </Title>
          <div className="label-grid">
            <Label>Fee Description</Label>
            <Autocomplete
              disabled
              classes={classes}
              options={acctLedgers.responseType ?? EMPTY_RESPONSETYPE}
              openOnFocus
              value={acct_ldgr_id}
              onChange={(e, newValue) => {
                if (newValue) {
                  setAccountLedgerId({
                    label: newValue.label,
                    value: newValue.value,
                  });
                } else {
                  setAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
            <Label>O.B</Label>
            <Input
              className="demand-fee__input"
              value={fee_ob}
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setOutStandingBalance(Number(e.target.value))
              }
            />
            <Label>Fee Demand</Label>
            <Input
              className="demand-fee__input"
              value={fee_demand}
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDemandAmount(Number(e.target.value))
              }
            />
            <Label>Concessions</Label>
            <Input disabled value={fee_concession} />
            <Label>Receivable </Label>
            <Input disabled value={fee_receivable} />
            <Label>Received</Label>
            <Input disabled value={fee_received} />
            <Label>Refund</Label>
            <Input disabled value={fee_refunds} />
          </div>

          <div className="row">
            <div className="col">
              <Button mode="save" onClick={() => HandleEditItem(id)} />

              <Button
                mode="cancel"
                onClick={() => {
                  setOpenEditableModal(!editableModal);
                  setFeeConcession(0);
                  setFeeRefund(0);
                  setBalanceFee(0);
                  setFeePaid(0);
                  setOutStandingBalance(0);
                  setTotalFee(0);
                  setAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                }}
              />
            </div>
          </div>
        </>
      </Modal>
      {/* studentmodal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.DEMAND_RAISED}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.FEE_DEMAND_FEE_PAYABLE_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={UpdateStudentDemandDetailsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;
