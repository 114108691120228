import React, { useEffect, useState } from "react";
import Close from "../../../../images/Close.svg";
import { Title } from "../../../../stories/Title/Title";
import InstDetails from "../InstDetails";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import {
  DataGridPro,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  AcdSubjectType,
  StudentReportType,
  TableHeaders,
} from "../../../../utils/Enum.types";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DataGridTableStyles";
import { GetComboDetailsList } from "../../../../Types/Combinations/queries";
import useAcdStudentsData, {
  StudentAcdNode,
  StudentEdges,
} from "../../hooks/useAcdStudentsData";
import { useParams } from "react-router-dom";
import useInstConfigByEntryId from "../../hooks/useInstConfigByEntryId";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../utils/constants";
import useAcademicSubjects from "../../hooks/useAcademicSubjects";
import { Drawer } from "@mui/material";
import { attendanceOverViewStyles } from "../../../../styles/DrawerStyles";
import PerStudentDetails from "./PerStudentDetails";
import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";

interface props {
  selectedComboNode: GetComboDetailsList | null;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const View = ({ setModal, selectedComboNode }: props) => {
  const classes = useDataGridStyles();
  const { entryId } = useParams();

  const [searchStudent, setSearchStudent] = useState("");
  const [searchSubject, setSearchSubject] = useState("");

  const [studentRows, setStdRows] = useState<GridValidRowModel[]>([]);

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentEdges[]>([]);

  const { entry_level } = useInstitutionConfiguration();

  const {
    InstConfigDetails: { alloted },
  } = useInstConfigByEntryId(entryId!);
  const {
    AcademicsStudentData: { data, fetchMore, loading },
  } = useAcdStudentsData(
    0,
    0,
    0,
    0,
    0,
    0,
    ROWS_PER_PAGE,
    alloted.id,
    searchStudent,
    StudentReportType.BY_COMBO_ID_AT_ALLOTED_LEVEL
  );

  const { subjects } = useAcademicSubjects(
    0,
    0,
    0,
    searchSubject,
    ROWS_PER_PAGE,
    AcdSubjectType.SUBJ_BY_COMBO_ID
  );
  const drawerClasses = attendanceOverViewStyles();

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcdStudents?.edges;
                const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetAcdStudents: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && studentRows && studentRows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [studentRows]
  );

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "sub_name",
      headerName: TableHeaders.SUBJECT_DESCRIPTION,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sub-type",
      flex: 1,
    },
    {
      field: "sub_code",
      headerName: TableHeaders.SUBJECT_CODE,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sub-code",
    },
  ];

  const rows: GridValidRowModel[] =
    (subjects.data ? subjects.data.GetAcdSubject.edges : []).map(
      ({ node }, index) => {
        return {
          id: index + 1,
          sub_code: node.subj_code,
          sub_name: node.subj_desc,
        };
      }
    ) || [];

  const stdColumns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "adm_no",
      headerName: TableHeaders.ADMISSION_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "reg_no",
      headerName: TableHeaders.REGISTER_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "std_name",
      headerName: TableHeaders.STUDENT_NAME,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-name",
      flex: 1,
    },
  ];

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetAcdStudents?.edges;
                const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetAcdStudents: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && studentRows && studentRows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [studentRows]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdStudents.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredStudents = students.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) =>
              student.node.id === (newStudent.node as StudentAcdNode).id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: true,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData as StudentEdges[]);
        setStdRows(
          (updatedNewData || []).map((response, index) => {
            return {
              id: index + 1,
              adm_no: response.node.std_adm_no,
              reg_no: response.node.std_reg_no,
              std_name: `${response.node.first_name} ${response.node.middle_name} ${response.node.last_name}`,
              std_id: response.node.id,
            };
          }) || []
        );
      } else {
        setStdRows(
          (newData || []).map((response, index) => {
            return {
              id: index + 1,
              adm_no: response.node.std_adm_no,
              reg_no: response.node.std_reg_no,
              std_name: `${response.node.first_name} ${response.node.middle_name} ${response.node.last_name}`,
              std_id: response.node.id,
            };
          }) || []
        );
        setStudents(newData as StudentEdges[]);
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  return (
    <>
      <div className="combination-sub-list__view">
        <div className="combination-sub-list__view--title">
          <Title>Students List</Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
        <InstDetails />
        <div className="combination-sub-list__view--subtitle">
          <Title variant="subtitle1">
            <b>
              {selectedComboNode ? selectedComboNode.subj_combo_name : null}
            </b>
          </Title>
        </div>
        <div className="combination-sub-list__view--datablock row g-0">
          <div className="combination-sub-list__view--datablock--left col-7">
            <Title variant="subtitle1">List of Students</Title>
            <div className="combination-sub-list__view--row row g-0">
              <div className="col-4">
                <Input
                  id="search"
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearchStudent(e.target.value);
                  }}
                />
              </div>
              <div className="col"></div>
              <div className="col-4">
                <div className="student-total-count">
                  Total Students :
                  <b>{data ? data.GetAcdStudents.totalCount : 0}</b>
                </div>
              </div>
            </div>
            <div
              className={`combination-sub-list__view--tableblock ${classes.root}`}
            >
              <DataGridPro
                columns={stdColumns}
                rows={studentRows}
                rowHeight={TABLE_ROW_HEIGHT}
                slotProps={{
                  columnsPanel: { disableHideAllButton: true },
                }}
              />
            </div>
          </div>
          <div className="combination-sub-list__view--datablock--right col">
            <Title variant="subtitle1">Allocated Subjects</Title>
            <div className="combination-sub-list__view--row row g-0 justify-content-center">
              <div className="col-4">
                <Input
                  id="search"
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearchSubject(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              className={`combination-sub-list__view--tableblock ${classes.root}`}
            >
              <DataGridPro
                columns={columns}
                rows={rows}
                rowHeight={TABLE_ROW_HEIGHT}
                slotProps={{
                  columnsPanel: { disableHideAllButton: true },
                }}
              />
            </div>
          </div>
        </div>
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
    </>
  );
};

export default View;
