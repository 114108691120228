import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import { Checkbox, Drawer, FormControlLabel, FormGroup } from "@mui/material";
import DownArrow from "../../../../images/DownArrow.svg";
import RightArrow from "../../../../images/ArrowRight.svg";
import Close from "../../../../images/Close.svg";
import { Button } from "../../../../stories/Button/Button";
import { StatesContext } from "./GlobalStates/StatesProvider";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { EMPTY_STRING } from "../../../../utils/constants";
import { optionsType } from "../../../../utils/Form.types";
import { payloadType } from "./GlobalStates/types";

import { ExportProps } from "./Index";
import { allocateTeachers } from "../../../../styles/DrawerStyles";
import Initialize from "./Initialize";
import { useLazyQuery } from "@apollo/client";
import {
  GetSwConfigStdDataFieldsData,
  GetSwConfigStudentDataEntryByInstIdVars,
} from "../../../../Types/Student/Configuration";
import { GetSwConfigStdDataFields } from "../../../../queries/institution/configuration/query";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { header } from "../../../Accounts/common/HeaderConsts";
import { ExportModuleType } from "../../../../utils/Enum.types";

interface Props extends ExportProps {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ModifiedList {
  header: string;
  sub_headers?:
    | {
        header: string;
        fields?: optionsType[];
      }[]
    | undefined;
  fields?: optionsType[] | undefined;
}
const Export = ({ setModal, reportType }: Props) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [expandedIndex2, setExpandedIndex2] = useState<number | null>(null);
  const { token } = useToken();
  const { InstId } = useParams();

  const accountHead = "Account Details";
  const basicDetailsHead = "Basic Details";

  const [initialize, setInitialize] = useState(false);

  const drawerClasses = allocateTeachers();

  const { state, dispatch } = useContext(StatesContext);
  const fieldsValue = state.selectedFields.map((field) => field.value);

  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  const [GetStudentConfig, { data }] = useLazyQuery<
    GetSwConfigStdDataFieldsData,
    GetSwConfigStudentDataEntryByInstIdVars
  >(GetSwConfigStdDataFields, {
    variables: { token, inst_id: InstId! },
  });
const {categoryLabel}=useInstLabels();
  const accountItems = [
    {
      header: "Basic Details",
      fields: [
        {
          label: "Admission Number",
          value: "std_adm_no",
        },
        {
          label: "Register Number",
          value: "std_reg_no",
        },
        {
          label: "Father's Name",
          value: "std_father_name",
        },
        {
          label: "Father's Mobile",
          value: "std_father_mobile",
        },
        {
          label: "Mother's Name",
          value: "std_mother_nam",
        },
        {
          label: "Mother's Mobile",
          value: "std_mother_mobile",
        },
        {
          label: "Student's Mobile",
          value: "std_mobile",
        },
        {
          label: "Email",
          value: "std_email",
        },
        {
          label: "Date of Birth",
          value: "std_dob",
        },
        {
          label: "Sex",
          value: "std_sex",
        },
        {
          label: "Fresher Status",
          value: "std_fresher",
        },

        USE_CATEGORY_KEY
          ? {
              label: `${categoryLabel}`,
              value: "category",
            }
          : null,
        {
          label: "Date of Admission",
          value: "std_doa",
        },
        {
          label: "Student Status",
          value: "std_status",
        },
      ],
    },
    {
      header: "Account Details",
      sub_headers: [
        {
          header: "Demand Details",
          fields: [
            {
              label: "Demand Outstanding Balance",
              value: "std_demand_ob",
            },
            {
              label: "Demand Amount",
              value: "std_demand_amt",
            },
            {
              label: "Demand Concession",
              value: "std_demand_concession",
            },
            {
              label: "Demand Receivable",
              value: "std_demand_receivable",
            },
            {
              label: "Demand Received",
              value: "std_demand_received",
            },
            {
              label: "Demand Refunds",
              value: "std_demand_refunds",
            },
            {
              label: "Demand Balance",
              value: "std_demand_bal",
            },
          ],
        },
        {
          header: "Deposit Details",
          fields: [
            {
              label: "Deposit Outstanding Balance",
              value: "std_deposit_ob",
            },
            {
              label: "Deposit Amount",
              value: "std_deposit_amt",
            },
            {
              label: "Deposit Total",
              value: "std_deposit_total",
            },
            {
              label: "Deposit Adjusted",
              value: "std_deposit_adjusted",
            },
            {
              label: "Deposit Refunded",
              value: "std_deposit_refunded",
            },
            {
              label: "Deposit Balance",
              value: "std_deposit_bal",
            },
          ],
        },
      ],
    },
  ];
  const List = [
    {
      header: "Registration",
      fields: [
        {
          label: "Admission Number",
          value: "std_adm_no",
        },
        {
          label: "Register Number",
          value: "std_reg_no",
        },
        {
          label: "Student Status",
          value: "std_status",
        },
        {
          label: "Gender",
          value: "std_sex",
        },
        {
          label: "STS No",
          value: "std_sts_no",
        },
        {
          label: "Date of Admission",
          value: "std_doa",
        },
        {
          label: "Fresher",
          value: "std_fresher",
        },
        {
          label: "Date of Birth",
          value: "std_dob",
        },
        {
          label: "Email",
          value: "std_email",
        },
        {
          label: "Mobile",
          value: "std_mobile",
        },
        {
          label: "Father Name",
          value: "std_father_name",
        },
        {
          label: "Father Mobile",
          value: "std_father_mobile",
        },
        {
          label: "Mother Name",
          value: "std_mother_name",
        },
        {
          label: "Mother Mobile",
          value: "std_mother_mobile",
        },
        USE_CATEGORY_KEY
          ? {
              label: `${categoryLabel}`,
              value: "category",
            }
          : null,
      ].filter((opt) => opt !== null) as optionsType[],
    },
    {
      header: "Personal Details",
      sub_headers: [
        {
          header: "Personal Details",
        },
        {
          header: "Correspondance Address",
        },
        {
          header: "Health Details",
        },
        {
          header: "Other Details",
        },
      ],
    },
    {
      header: "Academic Details",
      sub_headers: [
        {
          header: "Current Academic Details",
        },
        {
          header: "Previous Institution Details",
        },
        {
          header: "10th Details",
        },
        {
          header: "12th Details",
        },
        {
          header: "UG Details",
        },
        {
          header: "Bank Details",
        },
        {
          header: "Admission Details",
        },
        {
          header: "CET Details",
        },
      ],
    },
  ];
  const [modifiedList, setModifiedList] = useState<ModifiedList[]>(
    List as ModifiedList[]
  );

  const handleToggle = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleToggle2 = (index: number) => {
    setExpandedIndex2((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleSelectAll = (
    fields: {
      label: string;
      value: string;
    }[],
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;
    const onlyValues = fields.map((itm) => itm.value);
    if (checked) {
      const updatedArray = [...state.selectedFields];

      fields.forEach((item) => {
        const exists = updatedArray.some(
          (el) => el.label === item.label || el.value === item.value
        );

        if (!exists) {
          updatedArray.push(item); // Add the item if it doesn't exist
        }
      });

      // Update state with the new array (after filtering duplicates)

      dispatch({
        type: payloadType.FIELDS,
        payload: {
          selectedFields: updatedArray,
        },
      });
    } else {
      const result = state.selectedFields
        .filter((sel) => {
          return onlyValues.includes(sel.value) === false;
        })
        .filter(Boolean) as optionsType[];
      dispatch({
        type: payloadType.FIELDS,
        payload: {
          selectedFields: result,
        },
      });
    }
  };

  const handleFieldChange = (
    field: {
      label: string;
      value: string;
    } | null,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;
    if (field)
      if (checked) {
        dispatch({
          type: payloadType.FIELDS,
          payload: {
            selectedFields: [...state.selectedFields, field],
          },
        });
      } else {
        dispatch({
          type: payloadType.FIELDS,
          payload: {
            selectedFields: state.selectedFields.filter(
              (item) => item.value !== field.value
            ),
          },
        });
      }
  };

  useEffect(() => {
    if (token) {
      if (isAccounts) {
        setModifiedList(accountItems as ModifiedList[]);
      }
      if (isAccounts === false)
        GetStudentConfig().then(({ data }) => {
          if (data && data.GetSwConfigStdDataFields) {
            const response = data.GetSwConfigStdDataFields;

            const result = List.map((item) => {
              if (item.sub_headers) {
                const modifiedItem = item.sub_headers
                  .map((sub_item) => {
                    const foundFields = response.filter((it) => {
                      return it.data_field_block_name === sub_item.header;
                    });
                    const fields = foundFields.length
                      ? foundFields[0].field_details
                          .filter((f) => f.data_field_enable)
                          .map(({ data_field_label, data_field_name }) => ({
                            label: data_field_label,
                            value: data_field_name,
                          }))
                      : [];
                    if (fields.length)
                      return {
                        header: sub_item.header,
                        fields: fields,
                      };
                    else return undefined;
                  })
                  .filter(Boolean);

                return {
                  header: item.header,
                  sub_headers: modifiedItem,
                } as ModifiedList;
              } else {
                return item;
              }
            });

            setModifiedList(result);
          }
        });
    }
  }, [token, GetStudentConfig, data, InstId, USE_CATEGORY_KEY]);

  const isAccounts = window.location.pathname.includes(
    ExportModuleType.ACCOUNTS
  );

  const onlyAccountHead = accountItems.filter(
    ({ header }) => header !== basicDetailsHead
  );
  const onlyAccountFields = onlyAccountHead
    .map(({ sub_headers }) => {
      return sub_headers?.map(({ fields }) => fields.map(({ value }) => value));
    })
    .filter(Boolean)
    .flat(2);

  const acctFieldSelected = onlyAccountFields.some((item) =>
    state.selectedFields.map(({ value }) => value).includes(item!)
  );

  return (
    <>
      <div className="inst-level-report__export">
        <div className="inst-level-report__export--title">
          <Title>Export Student Details</Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
        <div className="inst-level-report__export--block">
          <ul>
            {modifiedList.map((res, index) => {
              const isExpanded = expandedIndex === index;

              const allChecked = res.fields
                ? res.fields.every((item) =>
                    fieldsValue.includes(item ? item.value : "")
                  )
                : false;

              return (
                <React.Fragment key={index}>
                  <li className="inst-level-report__export--block--header">
                    <div
                      className="inst-level-report__export--block--header--flex"
                      onClick={() => handleToggle(index)}
                    >
                      <b>{res.header}</b>
                      &nbsp;{" "}
                      <b className="nodata">
                        {res.header === accountHead
                          ? "(Note: At least one field from this section must be selected for export)"
                          : null}
                      </b>
                      {isExpanded ? (
                        <img src={DownArrow} alt="" />
                      ) : (
                        <img src={RightArrow} alt="" />
                      )}
                    </div>

                    {isExpanded ? (
                      <>
                        {res.fields ? (
                          <ul className="inst-level-report__export--block--list">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={allChecked}
                                    onChange={(e) =>
                                      handleSelectAll(
                                        (res.fields
                                          ? res.fields
                                          : []) as optionsType[],
                                        e
                                      )
                                    }
                                  />
                                }
                                label="Select All"
                                className="inst-level-report__export--block--list--h"
                              />
                            </FormGroup>
                            <div className="inst-level-report__export--block--options">
                              {res.fields.map((field, fieldIndex) => (
                                <li key={fieldIndex}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={fieldsValue.includes(
                                            field ? field.value : ""
                                          )}
                                          onChange={(e) => [
                                            handleFieldChange(field, e),
                                          ]}
                                        />
                                      }
                                      label={field ? field.label : EMPTY_STRING}
                                    />
                                  </FormGroup>
                                </li>
                              ))}
                            </div>
                          </ul>
                        ) : null}
                        {res.sub_headers ? (
                          <ul>
                            {res.sub_headers.map((sub_res, index2) => {
                              const isExpanded = expandedIndex2 === index2;
                              const allCheckedSub = sub_res.fields
                                ? sub_res.fields.every((item) =>
                                    fieldsValue.includes(item ? item.value : "")
                                  )
                                : false;

                              return (
                                <React.Fragment key={index2}>
                                  <li className="inst-level-report__export--block--header">
                                    <div
                                      className="inst-level-report__export--block--header--flex"
                                      onClick={() => handleToggle2(index2)}
                                    >
                                      <b>{sub_res.header}</b>

                                      {isExpanded ? (
                                        <img src={DownArrow} alt="" />
                                      ) : (
                                        <img src={RightArrow} alt="" />
                                      )}
                                    </div>

                                    {isExpanded ? (
                                      <>
                                        <ul className="inst-level-report__export--block--list">
                                          <FormGroup>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={allCheckedSub}
                                                  onChange={(e) => {
                                                    if (sub_res.fields)
                                                      handleSelectAll(
                                                        sub_res.fields,
                                                        e
                                                      );
                                                  }}
                                                />
                                              }
                                              label="Select All"
                                              className="inst-level-report__export--block--list--h"
                                            />
                                          </FormGroup>
                                          <div className="inst-level-report__export--block--options">
                                            {sub_res.fields
                                              ? sub_res.fields.map(
                                                  (sub_field, fieldIndex) => (
                                                    <li key={fieldIndex}>
                                                      <FormGroup>
                                                        <FormControlLabel
                                                          control={
                                                            <Checkbox
                                                              checked={fieldsValue.includes(
                                                                sub_field.value
                                                              )}
                                                              onChange={(e) => [
                                                                handleFieldChange(
                                                                  sub_field,
                                                                  e
                                                                ),
                                                              ]}
                                                            />
                                                          }
                                                          label={
                                                            sub_field.label
                                                          }
                                                        />
                                                      </FormGroup>
                                                    </li>
                                                  )
                                                )
                                              : null}
                                          </div>
                                        </ul>
                                      </>
                                    ) : null}
                                  </li>
                                </React.Fragment>
                              );
                            })}
                          </ul>
                        ) : null}
                      </>
                    ) : null}
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
        </div>
        <Button
          mode="excel"
          disabled={
            state.selectedFields.length === 0
              ? true
              : isAccounts
              ? acctFieldSelected === false || state.selectedFields.length === 0
              : false
          }
          onClick={() => {
            setInitialize(!initialize);
          }}
        >
          Initialize Report
        </Button>
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>

      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={initialize}
        onClose={() => setInitialize(!initialize)}
      >
        <Initialize setModal={setInitialize} reportType={reportType} />
      </Drawer>
    </>
  );
};

export default Export;
