import React, { useEffect } from "react";
import Home from "../../../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import { TextField } from "@mui/material";
import Compare from "../../../../../images/Compare.svg";
import { useNavigate, useParams } from "react-router-dom";
import ViewTable from "./Table";
import List from "../List";
import Avatar from "../../../../../images/Avatar.svg";
import { ReportType } from "./FormsReport";
import { EmpData } from "../../../../HR/hooks/useEmployee";
import { CommonNodeVars } from "../../../../HR/Types/masterDataTypes";
import { PayRollEmpMasterNodeForLogin } from "../../../../HR/queries/employee/query";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../../customhooks/useToken";
import { GetFormDataByFormId } from "../../../queries/general";
import { toInputStandardDate } from "../../../../../utils/UtilFunctions";
import { Button } from "../../../../../stories/Button/Button";
interface props {
  pageType: ReportType;
}
const View = ({ pageType }: props) => {
  const { token } = useToken();
  const { empId, formId } = useParams();
  const navigate=useNavigate();
  const [GetEmpDetailsForLogin, { data: teacherData }] = useLazyQuery<
    EmpData,
    CommonNodeVars
  >(PayRollEmpMasterNodeForLogin, {
    variables: {
      id: Number(empId),
      token,
    },
    fetchPolicy: "network-only",
  });

  const [GetFormDataById, { data: formData }] = useLazyQuery(
    GetFormDataByFormId,
    {
      variables: {
        id: Number(formId),
        token,
      },
      fetchPolicy: "network-only",
    }
  );
  useEffect(() => {
    if (empId && token) {
      GetEmpDetailsForLogin();
    }
  }, [empId, GetEmpDetailsForLogin, token]);
  useEffect(() => {
    if (formId && token) {
      GetFormDataById();
    }
  }, [formId, GetFormDataById, token]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Forms Report</Title>
      <div className="fb-form-report">
        <div className="fb-form-report__left">
          <List />
        </div>
        <div className="fb-form-report__right">
          <div className="fb-form-view">
            <div className="fb-form-view__title row g-0">
              <div
                className={
                  formData && formData.node.is_academic
                    ? "col-4 fb-form-view__title--left"
                    : "fb-form-view__title--non-acd"
                }
              >
                <b>{formData && formData.node.form_name}</b>{" "}
                <span className="fb-form-view__title--acd">
                  {formData && formData.node.is_academic
                    ? "Academic"
                    : "Non-Academic"}
                </span>
              </div>
              {pageType === ReportType.Formwise ? (
                <div className="col"></div>
              ) : (
                empId && (
                  <div className="col fb-form-view__questionwise--emp">
                    <div className="fb-form-view__questionwise--emp--flex">
                      <img src={Avatar} alt="" />
                      <div className="fb-form-view__questionwise--emp--block">
                        <b>
                          {teacherData &&
                            teacherData.node.emp_first_name +
                              " " +
                              teacherData.node.emp_middle_name +
                              " " +
                              teacherData.node.emp_last_name}
                        </b>
                        <span> {teacherData && teacherData.node.emp_id}</span>
                      </div>
                    </div>
                  </div>
                )
              )}

              <div className="col-4">
                <div className="fb-form-view__title--flex">
                  <TextField
                    className="fb-form-report__datablock--grid--textfield"
                    label="Start Date"
                    InputLabelProps={{ shrink: true }}
                    disabled
                    value={
                      formData &&
                      toInputStandardDate(formData.node.form_st_date)
                    }
                  />
                  <img src={Compare} alt="" />
                  <TextField
                    className="fb-form-report__datablock--grid--textfield"
                    label="End Date"
                    InputLabelProps={{ shrink: true }}
                    disabled
                    value={
                      formData &&
                      toInputStandardDate(formData.node.form_end_date)
                    }
                  />
                </div>
              </div>
            </div>
            {/* <div className="fb-form-view__subtitle">
              <b>Classes List</b>
            </div>
            <div className="fb-form-view__cards">
              <div className="fb-form-view__card">
                <img src={Total} alt="" />
                <span>Total Students</span>
                <b className="font-blue">100</b>
              </div>
              <div className="fb-form-view__card">
                <img src={FormFilled} alt="" />
                <span>Students Form Filled</span>
                <b className="font-green">20</b>
              </div>
            </div> */}

            <div className="fb-form-view__block">
              {pageType === ReportType.Formwise ? (
                <>
                  <ViewTable pageType={ReportType.Formwise} />
                </>
              ) : (
                <>
                  <ViewTable pageType={ReportType.Subwise} />
                </>
              )}

            </div>
            {/* <Button mode="excel">Export</Button>
            <Button mode="pdf" /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
