import { useContext } from "react";
import { Label } from "../../../../stories/Label/Label";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { MULTI_SELECT_LIMIT_TAGS } from "../../../../utils/constants";
import { StudentStatus } from "../../../../utils/Enum.types";
import { payloadType, SortBy, sortbyOptionsType } from "./GlobalStates/types";
import { StatesContext } from "./GlobalStates/StatesProvider";
import { isOptionEqualToValue } from "../../../../utils/UtilFunctions";

const OtherOptions = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();

  const { state, dispatch } = useContext(StatesContext);

  const sortbyOptions: sortbyOptionsType[] = [
    {
      label: "Student Name",
      value: SortBy.STD_NAME,
    },
    {
      label: "Admission Number",
      value: SortBy.STD_ADM_NUM,
    },
    {
      label: "Student Register Number",
      value: SortBy.STD_REG_NUM,
    },
    {
      label: "Student Roll Number",
      value: SortBy.STD_ROLL_NUM,
    },
  ];

  return (
    <div className="inst-level-report__rt">
      <div className="inst-level-report__rt--header">
        <b>Other Options</b>
      </div>
      <div className="inst-level-report__rt--block">
        <div className="inst-level-report__rt--select">
          <Label>Sort By</Label>
          <Autocomplete
            classes={classes}
            options={sortbyOptions}
            openOnFocus
            value={state.selectedSortBy}
            isOptionEqualToValue={(option) =>
              isOptionEqualToValue(state.selectedSortBy, option)
            }
            onChange={(e, newValue) => {
              if (newValue) {
                dispatch({
                  payload: {
                    selectedSortBy: newValue,
                  },
                  type: payloadType.SORT_BY,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                classes={{ root: textClasses.formControlRoot }}
              />
            )}
          />
          <Label>Student Status</Label>
          <Autocomplete
            classes={classes}
            limitTags={MULTI_SELECT_LIMIT_TAGS}
            multiple
            options={Object.values(StudentStatus) as string[]}
            renderOption={(props, option) => (
              <li {...props}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={state.selectedStatus.includes(option)}
                />
                {option}
              </li>
            )}
            openOnFocus
            value={state.selectedStatus}
            onChange={(e, newValue) => {
              if (newValue) {
                dispatch({
                  payload: {
                    selectedStatus: newValue,
                  },
                  type: payloadType.STATUS,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                classes={{ root: textClasses.formControlRoot }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default OtherOptions;
