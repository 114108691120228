import React from "react";
import { TEXT_FONT_FAMILY } from "../../../../../../styles/DataGridTableStyles";
import ReactEcharts from "echarts-for-react";
import { colorsInGraph } from "../../../../../../styles/Constants";
import useAcdTestPerformance from "../../../../hooks/useAcdTestPerformance";
import { responseType } from "../../../../../../utils/Form.types";
import { useParams } from "react-router-dom";
import {
  PercentageGraphScale,
  convertToK,
  getDynamicIntervals,
} from "../../../../../../utils/UtilFunctions";
import { Button } from "../../../../../../stories/Button/Button";
interface props {
  departmentSelected: responseType | null;
  branchSelected: responseType | null;
  classSelected: responseType | null;
  semesterSelected: responseType | null;
  sectionSelected: responseType | null;
}

const PercentageGraph = ({
  departmentSelected,
  branchSelected,
  classSelected,
  semesterSelected,
  sectionSelected,
}: props) => {
  const { testId } = useParams();
  const { data } = useAcdTestPerformance(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    testId ? Number(testId) : 0
  );

  const maxCount = Math.max(
    data ? data.GetAcdTestPerformance.test_perfromance.num_of_std_test_taken : 0
  );

  const maxNumber = Math.ceil(maxCount / 100) * 100;
  const intervals = getDynamicIntervals(maxNumber);
  const maxFromIntervals = Math.max(...intervals);

  const Options = {
    tooltip: {
      trigger: 'item',
    formatter: '{a} <br/>{b} : {c} ({d}%)',
      textStyle: {
        fontSize: 14,
        fontFamily: TEXT_FONT_FAMILY,
        color: "#1e293b",
      },
    },

    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        dataView: { show: false, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },

    legend: {
      show: false,
  
    },
 
    data: PercentageGraphScale,

    dataZoom: [
      {
        type: "inside",
        start: 0,
        end: 100,
      },
    ],
    series: [
      {
        data: [
          {
            value:
              data?.GetAcdTestPerformance.test_perfromance.num_of_std_mt_80_per,
            name:` >=80% (totalcount : ${data?.GetAcdTestPerformance.test_perfromance.num_of_std_mt_80_per})` ,
          },
          {
            value:
              data?.GetAcdTestPerformance.test_perfromance.num_of_std_mt_70_per,
            name:`70-79% (totalcount : ${data?.GetAcdTestPerformance.test_perfromance.num_of_std_mt_70_per})` ,
          },
          {
            value:
              data?.GetAcdTestPerformance.test_perfromance.num_of_std_mt_60_per,
            name: `60-69% (totalcount : ${data?.GetAcdTestPerformance.test_perfromance.num_of_std_mt_60_per})`,
          },
          {
            value:
              data?.GetAcdTestPerformance.test_perfromance.num_of_std_mt_50_per,
            name:`50-59% (totalcount : ${data?.GetAcdTestPerformance.test_perfromance.num_of_std_mt_50_per})` ,
          },
          {
            value:
              data?.GetAcdTestPerformance.test_perfromance.num_of_std_mt_40_per,
            name: `40-49% (totalcount : ${data?.GetAcdTestPerformance.test_perfromance.num_of_std_mt_40_per})`,
          },
          {
            value:
              data?.GetAcdTestPerformance.test_perfromance.num_of_std_lt_40_per,
            name: `<40% (totalcount : ${data?.GetAcdTestPerformance.test_perfromance.num_of_std_lt_40_per})`,
          },
        ],
        type: "pie",
        name: "Nightingale Chart",
        radius: ['20%', '80%'],
        center: ['50%', '50%'],
        roseType: "area",

        itemStyle: {
          color: function (params: any) {
            return colorsInGraph[params.dataIndex];
          },
          borderRadius: 8,
        },
      },
    ],
  };

  return (
    <>
      <ReactEcharts option={Options} />
    </>
  );
};

export default PercentageGraph;
