import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrintReceipts from "../../../../../images/PrintProgressCard.svg";
import { useParams } from "react-router";

import { Checkbox } from "@mui/material";

import Print from "../../../../../images/Print.svg";
import Modal from "react-modal";
import {
  StudentListFields,
  TableHeaders,
  StudentReportType,
  StudentListFor,
} from "../../../../../utils/Enum.types";
import { FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../../utils/constants";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";

import { PrintModalStyles } from "../../../../../styles/ModalStyles";

import { getModifiedScrollHeight } from "../../../../../utils/UtilFunctions";
import { Button } from "../../../../../stories/Button/Button";

import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../../styles/DataGridTableStyles";

import LegendsInStudentList from "../../../../Master/Student/Components/LegendsInStudentList";
import ProgressReport from "../../../../Print/ProgressReport/Template2/Index";

import useInstConfigByEntryId from "../../../hooks/useInstConfigByEntryId";
import useAcdStudentsData, {
  StudentEdges,
} from "../../../hooks/useAcdStudentsData";

interface Props {
  studentListFor: StudentListFor;
  queryType: StudentReportType;
}
export interface columnsType {
  label: string;
  value: StudentListFields;
  visible: boolean;
}

const StudentListForReportCards = ({ queryType }: Props) => {
  const tableClasses = useDataGridStyles();
  const navigate = useNavigate();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const { entryId } = useParams();
  const [previewModal, setPreviewModal] = useState(false);

  const [searchData, setSearchData] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [studentIdSet, setStudentIdSet] = useState<Set<number>>(new Set());
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const {
    InstConfigDetails: { data: configdata },
  } = useInstConfigByEntryId(entryId!);

  //Queries

  const {
    AcademicsStudentData: { data, loading, fetchMore },
  } = useAcdStudentsData(
    configdata ? configdata.GetAcdInstConfigNames.acd_dept_id : 0,
    configdata ? configdata.GetAcdInstConfigNames.acd_branch_id : 0,
    configdata ? configdata.GetAcdInstConfigNames.acd_class_id : 0,
    configdata ? configdata.GetAcdInstConfigNames.acd_semester_id : 0,
    configdata ? configdata.GetAcdInstConfigNames.acd_section_id : 0,
    0,
    ROWS_PER_PAGE,
    0,
    searchData,
    queryType,
    0,
    0,
    0
  );

  useEffect(() => {
    if (data && !loading && selectedStudents) {
      const newData = data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name: node.first_name,
            std_father: node.std_father_name,
            std_mobile: node.std_mobile,
            std_status: node.std_status,
            std_id: node.id,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name: node.first_name,
            std_father: node.std_father_name,
            std_mobile: node.std_mobile,
            std_status: node.std_status,
            std_id: node.id,
          }))
        );
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, selectedStudents]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetAcdStudents.edges;
              const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetAcdStudents: {
                  edges: [...students, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "std_adm_no",
      headerName: TableHeaders.ADMISSION_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "std_reg_no",
      headerName: TableHeaders.REGISTER_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "std_name",
      headerName: TableHeaders.STUDENT_NAME,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-onClick td-name",
      flex: 1,
    },

    {
      field: "std_father",
      headerName: TableHeaders.FATHER_NAME,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-name",
    },
    {
      field: "std_mobile",
      headerName: TableHeaders.MOBILE,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-mobile",
    },
    {
      field: "std_status",
      headerName: TableHeaders.STATUS,
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN, // cellClassName: FetchStudentStatusClassName(row.std_status),
    },

    {
      field: "print",
      cellClassName: "td-actions-print ",
      headerClassName: "td-actions-print-header",
      headerAlign: HEADER_TEXT_ALIGN,
      align: MARKS_TEXT_ALIGN,
      renderHeader: (params) => {
        return (
          <>
            <Checkbox checked={selectAll} onChange={() => handleSelectAll()} />
            Print Reports
            <img src={Print} alt="" />
          </>
        );
      },
      renderCell: (params) => {
        const studentId = params.row.std_id || "";

        return (
          <>
            {studentIdSet.has(studentId)}
            <Checkbox
              checked={studentIdSet.has(Number(studentId))}
              onChange={(e) => hanldeStudentClick(params, e)}
            />
            <img src={PrintReceipts} alt="" />
          </>
        );
      },
    },
  ];
  const hanldeStudentClick = (
    params: GridCellParams,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (params.field === "print") {
      const selectedRow = rows.find((row) => row.id === params.id);
      const tempSet: Set<number> = new Set(studentIdSet);
      const checked = e.target.checked;
      if (selectedRow && selectedRow.std_id) {
        if (checked) {
          tempSet.add(selectedRow.std_id);
        } else {
          tempSet.delete(selectedRow.std_id);
        }
      }

      setStudentIdSet(tempSet);
      setSelectedStudents(Array.from(tempSet));
      // Update selected students
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const allStudentIds = rows
        .map((student) => student.std_id)
        .filter((id) => Number.isInteger(id)) as number[];
      setSelectedStudents(allStudentIds);
      setStudentIdSet(new Set(allStudentIds.map((id) => id)));
      setSelectAll(true);
    } else {
      setSelectedStudents([]);
      setStudentIdSet(new Set());
      setSelectAll(false);
    }
  };
  return (
    <>
      <div className={`report-cards__std-list ${tableClasses.root}`}>
        {!students.length ? (
          <b className="nodata">Sorry, No Students Found</b>
        ) : (
          <>
            <DataGridPro
              columns={columns}
              rows={rows}
              rowHeight={TABLE_ROW_HEIGHT}
              // onCellClick={(params: GridCellParams, studentId) => {
              //   handleCellClick(params);
              // }}
              onColumnHeaderClick={() => handleSelectAll()}
              disableRowSelectionOnClick
              slotProps={{
                columnsPanel: { disableHideAllButton: true },
              }}
              hideFooter
            />
          </>
        )}
      </div>
      <div className="studentlist__buttons">
        <Button
          mode="print"
          disabled={selectedStudents.length === 0}
          onClick={() => {
            setPreviewModal(!previewModal);
          }}
        />
        <Button mode="back" onClick={() => navigate(-1)} />
        <LegendsInStudentList />
      </div>

      {/* preview-modal */}
      <Modal ariaHideApp={false} style={PrintModalStyles} isOpen={previewModal}>
        <ProgressReport
          selectedStudents={selectedStudents}
          setStudentIdSet={setStudentIdSet}
          setModalFlag={setPreviewModal}
        />
      </Modal>
    </>
  );
};

export default StudentListForReportCards;
