import React from "react";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { TextField } from "@mui/material";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useInstConfigByEntryId from "../hooks/useInstConfigByEntryId";
import { useParams } from "react-router-dom";
import {
  AcdSubjectAllocationData,
  InstitutionConfigurationTypes,
} from "../../../utils/Enum.types";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { EMPTY_STRING } from "../../../utils/constants";

const InstDetailsAllocationLevel = () => {
  const { allocationId } = useParams();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );

  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();
  const {
    InstConfigDetails: { data: configdata },
  } = useInstConfigByEntryId(allocationId!);

  const showConfig = (
    type:
      | AcdSubjectAllocationData.CLASS
      | AcdSubjectAllocationData.SEMESTER
      | AcdSubjectAllocationData.SECTION
  ) => {
    const allocationLevel =
      configData.data &&
      configData.data.GetSwConfigVariables.length &&
      configData.data.GetSwConfigVariables[0].config_string_value;

    if (allocationLevel) {
      switch (type) {
        case AcdSubjectAllocationData.CLASS:
          return (
            USE_CLASS_KEY && allocationLevel !== AcdSubjectAllocationData.BRANCH
          );
        case AcdSubjectAllocationData.SEMESTER:
          return (
            USE_SEMESTER_KEY &&
            allocationLevel !== AcdSubjectAllocationData.BRANCH &&
            allocationLevel !== AcdSubjectAllocationData.CLASS
          );
        case AcdSubjectAllocationData.SECTION:
          return (
            USE_SECTION_KEY &&
            allocationLevel !== AcdSubjectAllocationData.BRANCH &&
            allocationLevel !== AcdSubjectAllocationData.CLASS &&
            allocationLevel !== AcdSubjectAllocationData.SEMESTER
          );
      }
    } else return false;
  };

  return (
    <div className="row g-0 daily-activities__select">
      {USE_DEPARTMENT_KEY && (
        <div className="col-2">
          <TextField
            label={departmentLabel}
            value={configdata?.GetAcdInstConfigNames.acd_dept_desc}
            InputLabelProps={{ shrink: true }}
            disabled
            className="daily-activities__textfield"
          />
        </div>
      )}
      {USE_BRANCH_KEY && (
        <div className="col-2">
          <TextField
            label={branchLabel}
            value={configdata?.GetAcdInstConfigNames.acd_branch_desc}
            InputLabelProps={{ shrink: true }}
            disabled
            className="daily-activities__textfield"
          />
        </div>
      )}
      {showConfig(AcdSubjectAllocationData.CLASS) && (
        <div className="col-2">
          <TextField
            label={classLabel}
            value={configdata?.GetAcdInstConfigNames.acd_class_desc}
            InputLabelProps={{ shrink: true }}
            disabled
            className="daily-activities__textfield"
          />
        </div>
      )}
      {showConfig(AcdSubjectAllocationData.SEMESTER) && (
        <div className="col-2">
          <TextField
            label={semesterLabel}
            value={configdata?.GetAcdInstConfigNames.acd_semester_desc}
            InputLabelProps={{ shrink: true }}
            disabled
            className="daily-activities__textfield"
          />
        </div>
      )}
      {showConfig(AcdSubjectAllocationData.SECTION) && (
        <div className="col-2">
          <TextField
            label={sectionLabel}
            value={configdata?.GetAcdInstConfigNames.acd_section_desc}
            InputLabelProps={{ shrink: true }}
            disabled
            className="daily-activities__textfield"
          />
        </div>
      )}
    </div>
  );
};

export default InstDetailsAllocationLevel;
