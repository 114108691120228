import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { TableHeaderProps } from "../../../../Types/Tables";
import { EMPTY_STRING } from "../../../../utils/constants";
import { Direction, Operation, SortBy } from "../../../../utils/Enum.types";
import { msgType, responseType } from "../../../../utils/Form.types";
import Home from "../../Home/Index";
import { Keys } from "../../../../utils/Enum.keys";
import {
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddAcdTestRemarksForStds,
  UpdateAcdTestMarksBySubjectID,
} from "../../queries/test/mutation";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import LoadingModal from "../../../../pages/LoadingModal";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  GetStdTestMarks,
  GetStdTestMarksBySubjId,
} from "../../queries/test/query";
import { PageInfo } from "../../../../Types/Student/paginationTypes";
import { SubjectDetailsNode } from "../../hooks/useAcademicSubjects";
import { RemarksType } from "./EnterMarks";
import useTestClassSubjects, {
  TestClassSubjectQueryType,
} from "../../hooks/useTestClassSubjects";
import { AntSwitch } from "../../../../pages/Switch";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import InstDetails from "../InstDetails";
import Close from "../../../../images/Close.svg";

import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import { GetAcdGradeScalesByInstId } from "../../queries/grade";
import {
  GetAcdGradeScalesByInstIdData,
  GetAcdGradeScalesByInstIdVars,
} from "../../types/grade";
import Input from "../../../../stories/Input/Input";
import Modal from "react-modal";

import useCheckAllocationType from "../../hooks/useCheckAllocationType";

import { userDetails } from "../../../../Types/Accounting/other";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";

import {
  AcdStdMarksQueryType,
  GetStdTestMarksVariables,
} from "../../hooks/useStudentTestMarksForAllotedLevel";
import { GetStdTestMarksData } from "../../hooks/useStudentTestMarks";
import useTestDetailsForAllotedLevel from "../../hooks/useTestDetailsForAllotedLevel";
import {
  AcdTeacherClassQueryType,
  GetAcdTeacherClassAssociationsVars,
} from "../../types/subject/Subject";
import { GetAcdIsClassTeacher } from "../../queries/subjects/List.tsx/Index";
import useUserRightsByEmpId from "../../../UserRights/hooks/useUserRightsByEmpId";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import UpdateMarksImport from "./UpdateMarksImport";
import { StudentAcdNode } from "../../hooks/useAcdStudentsForAdmission";
const { Academics_Table } = require("../../json/table.json");

export interface GetStdTestMarksBySubjIdVars {
  token: string;
  acd_yr_id: number;
  inst_id: string;
  subj_master_id: number | string;
  acd_test_class_id: string;
  entry_level: string;
  entry_id: string;
  after: string | null;
  first: number | null;
  per_std_subj_allocation: boolean;
  orderBy: {
    direction: string;
    field: string;
  };
}

export interface AcdTestMarksIndividual {
  id: number;
  marks_max_total: number;
  marks_min_total: number;
  marks_scored_tot: number;
  marks_num_to_100: number;
  subj_result: number;
  teacher_comments: string;
  scored_grade: string;
  rank_entry_level: number;
  is_std_present: boolean;
  rank_allotted_level: number;
  inst_id: number;
  acd_yr_id: number;
  student_id: number;
  acd_class_id: number;
  acd_semester_id: number;
  acd_section_id: number;
  acd_test_class_id: number;
  subj_master_id: number;
  subject_details: SubjectDetailsNode;
  acd_test_class_details: {
    enable_teachers_comments: boolean;
  };
  student_details: StudentAcdNode;
}
export interface GetStdTestMarksBySubjIdEdges {
  node: AcdTestMarksIndividual;
  cursor: string;
}
interface GetStdTestMarksBySubjIdDetails {
  totalCount: number;
  pageInfo: PageInfo;
  edges: GetStdTestMarksBySubjIdEdges[];
}
export interface GetStdTestMarksBySubjIdData {
  GetStdTestMarksBySubjId: GetStdTestMarksBySubjIdDetails;
}
interface min_max_marks extends responseType {
  subj_total_max_marks: number;
  subj_total_min_marks: number;
}

export interface sub_marks {
  ext_marks: number;
  is_std_present?: boolean;
  scored_grade?: string;
  teacher_comments?: string;
}

interface UpdateMarksVars {
  token: string;
  inst_id: string;
  user_details: userDetails;
  acd_yr_id: number;
  input: {
    acd_referance_ids: {
      inst_id: string;
      acd_test_class_id: string;
      entry_level: string;
      entry_id: string;
      test_type_id: number;
    };
    std_comments: {
      student_id: number;
      test_remarks: string;
    }[];
  };
  todays_date: string;
  per_std_subj_allocation: boolean;
}

export const updatedRemarks = "UPDATE_REMARKS";
interface Props {
  operation: Operation.UPDATE | typeof updatedRemarks;
}
const UpdateMarks = ({ operation }: Props) => {
  const formClasses = formAutoCompleteStyles();
  const formTextClasses = formAutoCompleteTextStyles();

  const { userRights } = useUserRightsByEmpId();

  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { flag } = useCheckAllocationType();
  const {
    testConductDetails: { data: testData },
  } = useTestDetailsForAllotedLevel();
  const { testConductDetails } = useTestClassSubjects(
    TestClassSubjectQueryType.TO_UPDATE_MARKS,
    flag ? flag : false
  );

  const { serverDate } = useServerDateandTime();
  const [marks_ext_scored, setmarks_ext_scored] = useState(0);
  const [importModal, setImportModal] = useState(false);

  const { InstId, testId, entryId } = useParams();
  const { token } = useToken();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [subjects, setSubjects] = useState<min_max_marks[]>([]);
  const [stateChange, setStateChange] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<min_max_marks | null>(
    null
  );
  const remarksFlag =
    testConductDetails.data &&
    testConductDetails.data.GetAcdTestClassSubjects.find(
      (res) => res.subject_master_details.id === selectedSubject?.value
    )?.test_class_details.enable_teachers_comments;

  const [updatedStudentMarks, setUpdatedStudentMarks] = useState<
    Map<number, sub_marks>
  >(new Map());

  const saveRef = useRef<HTMLButtonElement>(null);
  const [remarks, setRemarks] = useState<RemarksType>({});
  const [testRemarks, setTestRemarks] = useState<Map<number, string>>(
    new Map()
  );

  const { entry_level } = useInstitutionConfiguration();
  const [GetAcdGradeScaleDetails, { data: gradesData }] = useLazyQuery<
    GetAcdGradeScalesByInstIdData,
    GetAcdGradeScalesByInstIdVars
  >(GetAcdGradeScalesByInstId, {
    variables: {
      token,
      inst_id: InstId!,
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
    },
  });
  const gradeDropDown = gradesData
    ? gradesData.GetAcdGradeScalesByInstId.map((list) => list.grade)
    : [];
  const isGlobalUser = userRights.data
    ? userRights.data.GetUserRightsByEmpId.is_inst_sysadmin
    : false;
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [
    GetStdTestMarksBySubj,
    { data: studentsData, loading: studentsLoading, error, fetchMore },
  ] = useLazyQuery<GetStdTestMarksBySubjIdData, GetStdTestMarksBySubjIdVars>(
    GetStdTestMarksBySubjId
  );

  const [GetMarks, { data: stdData }] = useLazyQuery<
    GetStdTestMarksData,
    GetStdTestMarksVariables
  >(GetStdTestMarks);

  useEffect(() => {
    if (token && state.ActiveAcdYr && entry_level) {
      GetMarks({
        variables: {
          token,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          admNo: EMPTY_STRING,
          after: null,
          first: null,
          per_std_subj_allocation: flag,
          orderBy: { direction: Direction.ASC, field: SortBy.STD_ROLL_NO },
          input: {
            entry_id: Number(entryId)!,
            entry_level: entry_level,
            ids: [Number(InstId), Number(testId)],
            marks_query_type: AcdStdMarksQueryType.TEST_MARKS_AT_ENTRY_LEVEL,
          },
        },
      }).then(({ data }) => {
        if (data && data.GetStdTestMarks) {
          let tempRemarks = {};
          data.GetStdTestMarks.edges.map((itm, index) => {
            tempRemarks = {
              ...tempRemarks,
              [index]: itm.node.test_remarks,
            };
            const temp = testRemarks;
            temp.set(itm.node.student_details.id, itm.node.test_remarks);
            setTestRemarks(temp);
          });
          setRemarks(tempRemarks);
        }
      });
    } // eslint-disable-next-line
  }, [
    GetMarks,
    token,
    state.ActiveAcdYr,
    serverDate,
    flag,
    entryId,
    entry_level,
    stdData,
  ]);

  const [students, setStudents] = useState<GetStdTestMarksBySubjIdEdges[]>([]);
  const [remarksOnlyUpdate, setRemarksOnlyUpdate] = useState(false);

  const [gradeSelected, setGradeSelected] = useState<{
    [key: string]: string;
  }>({});

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0,
  });

  const [CheckTeacherIsClassTeacher, { data: IsClassTeacherData }] =
    useLazyQuery<
      {
        GetAcdTeacherClassAssociations: {
          is_class_teacher: boolean;
          pr_emp_id: number;
        }[];
      },
      GetAcdTeacherClassAssociationsVars
    >(GetAcdIsClassTeacher);

  useEffect(() => {
    if (flag !== undefined)
      CheckTeacherIsClassTeacher({
        variables: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          input: {
            entry_id: Number(entryId)!,
            entry_level,
            pr_emp_id: state.empLoginId,
            query_type: AcdTeacherClassQueryType.All_TEACHERS_BY_ENTRY_ID,
            subj_master_id: 0,
          },
          inst_id: InstId!,
          per_std_subj_allocation: flag,
          token,
        },
      });
  }, [
    CheckTeacherIsClassTeacher,
    state,
    entryId,
    state.empLoginId,
    InstId,
    entry_level,
    flag,
    token,
  ]);

  const [UpdateMarks, { loading }] = useMutation(
    UpdateAcdTestMarksBySubjectID,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [AddRemarks, { loading: addRemarksLoading }] = useMutation<
    { AddAcdTestRemarksForStds: boolean },
    UpdateMarksVars
  >(AddAcdTestRemarksForStds, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const onEdit = (id: number) => {
    if (updatedStudentMarks.has(id)) {
      setmarks_ext_scored(updatedStudentMarks.get(id)?.ext_marks!);
    } else {
      setmarks_ext_scored(0);
    }
    setInEditMode({
      status: true,
      rowKey: id,
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE) {
      handleClear();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const findClassTeacher = IsClassTeacherData
    ? IsClassTeacherData.GetAcdTeacherClassAssociations.filter(
        (itm) => itm.pr_emp_id === state.empLoginId
      )
    : [];
  const isClassTeacher = IsClassTeacherData
    ? findClassTeacher.length && findClassTeacher[0].is_class_teacher
      ? true
      : false
    : false;

  const handlemarkUpdate = () => {
    if (flag === undefined) {
      alert("Per Student Allocation not found");
      return;
    }

    if (operation === Operation.UPDATE && remarksOnlyUpdate === false) {
      UpdateMarks({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          acd_yr_id: activeAcademicYearData.data
            ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
            : 0,
          acd_test_class_id: testId,
          subj_master_id: selectedSubject?.value,
          std_marks_details: Array.from(updatedStudentMarks).map(
            ([key, value], index) => ({
              student_id: key,
              marks_ext_scored: value.is_std_present ? value.ext_marks : 0,
              is_std_present: value.is_std_present,
              scored_grade:
                gradeSelected[index] !== undefined &&
                gradeSelected[index] !== null
                  ? gradeSelected[index]
                  : value.scored_grade,
              teacher_comments: remarks
                ? remarks[index]
                : value.teacher_comments,
            })
          ),
          entry_level,
          entry_id: entryId,
          per_std_subj_allocation: flag,
        },
        refetchQueries: [
          {
            query: GetStdTestMarksBySubjId,
            variables: {
              acd_test_class_id: testId!,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              after: null,
              entry_id: entryId!,
              entry_level: entry_level,
              first: null,
              inst_id: InstId!,
              orderBy: {
                direction: Direction.ASC,
                field: SortBy.STUDENT_ID,
              },
              per_std_subj_allocation: flag,
              subj_master_id: selectedSubject ? selectedSubject.value : 0,
              token,
            },
          },
          {
            query: GetStdTestMarks,
            variables: {
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              admNo: EMPTY_STRING,

              after: null,
              first: null,
              input: {
                entry_id: entryId ? Number(entryId)! : 0,
                entry_level,

                ids: [
                  Number(InstId!),
                  state.testConductId ? state.testConductId : Number(testId),
                ],
                marks_query_type: "TEST_MARKS_AT_ENTRY_LEVEL",
              },
              orderBy: { direction: Direction.ASC, field: "STD_ROLL_NO" },
              per_std_subj_allocation: flag,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Marks Updated Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      AddRemarks({
        variables: {
          acd_yr_id: activeAcademicYearData.data
            ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
            : 0,
          input: {
            acd_referance_ids: {
              inst_id: InstId!,
              acd_test_class_id: testId!,
              entry_id: entryId!,
              entry_level: entry_level,
              test_type_id: 0,
            },
            std_comments: Array.from(testRemarks).map(([key, value]) => ({
              student_id: key,
              test_remarks: value,
            })),
          },
          inst_id: InstId!,
          per_std_subj_allocation: flag,
          todays_date: toIsoDate(serverDate),
          token,
          user_details,
        },
        refetchQueries: [
          {
            query: GetStdTestMarks,
            variables: {
              token,
              acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
              admNo: EMPTY_STRING,
              after: null,
              first: null,
              per_std_subj_allocation: flag,
              orderBy: { direction: Direction.ASC, field: "STD_ROLL_NO" },
              input: {
                entry_id: Number(entryId)!,
                entry_level: entry_level,
                ids: [Number(InstId), Number(testId)],
                marks_query_type:
                  AcdStdMarksQueryType.TEST_MARKS_AT_ENTRY_LEVEL,
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data && data.AddAcdTestRemarksForStds) {
          setMessage({
            flag: true,
            message: "ReMarks Updated Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const handleClear = () => {
    setSelectedSubject(null);
  };
  const changeAttendance = (
    id: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const newStudentMarks: Map<number, sub_marks> = updatedStudentMarks;
    const alreadyExits = newStudentMarks.get(id)!;
    newStudentMarks.set(id, {
      ext_marks: alreadyExits?.is_std_present ? alreadyExits?.ext_marks : 0,
      is_std_present: (event.target as HTMLInputElement).checked,
      scored_grade: alreadyExits?.is_std_present
        ? alreadyExits?.scored_grade
        : EMPTY_STRING,
      teacher_comments: alreadyExits.is_std_present
        ? alreadyExits.teacher_comments
        : EMPTY_STRING,
    });
    setUpdatedStudentMarks(newStudentMarks);
    setStateChange(!stateChange);
  };

  useEffect(() => {
    if (
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      ((selectedSubject && selectedSubject.value) ||
        operation !== updatedRemarks) &&
      token &&
      state.ActiveAcdYr &&
      flag !== undefined
    ) {
      GetStdTestMarksBySubj({
        variables: {
          token,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          acd_test_class_id: testId!,
          after: null,
          entry_id: entryId!,
          entry_level: entry_level,
          first: null,
          inst_id: InstId!,
          subj_master_id: selectedSubject ? selectedSubject.value : 0,
          per_std_subj_allocation: flag,
          orderBy: {
            direction: Direction.ASC,
            field: SortBy.STD_ROLL_NO,
          },
        },
      });
    } // eslint-disable-next-line
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    selectedSubject,
    token,
    testId,
    InstId,
    GetStdTestMarks,
    entryId,
    state.ActiveAcdYr,
    entry_level,
    flag,
    updatedRemarks,
    operation,
  ]);
  useEffect(() => {
    if (token && InstId) {
      GetAcdGradeScaleDetails();
    }
  }, [token, InstId, GetAcdGradeScaleDetails]);
  useEffect(() => {
    if (testConductDetails.data && !testConductDetails.loading) {
      setSubjects(
        testConductDetails.data.GetAcdTestClassSubjects.map((test) => ({
          label: test.subject_master_details.subj_desc,
          value: test.subject_master_details.id,
          subj_total_max_marks: test.subj_total_max_marks,
          subj_total_min_marks: test.subj_total_min_marks,
        }))
      );
    }
  }, [testConductDetails.data, testConductDetails.loading]);

  useEffect(
    () => {
      if (studentsData && !studentsLoading && selectedSubject !== null) {
        const tempMarks: Map<number, sub_marks> = new Map();
        const newData = studentsData.GetStdTestMarksBySubjId.edges.map(
          (edge) => {
            tempMarks.set(edge.node.student_id, {
              ext_marks: edge.node.marks_scored_tot,
              is_std_present: updatedStudentMarks?.get(edge.node.student_id)
                ? updatedStudentMarks?.get(edge.node.student_id)?.is_std_present
                : edge.node.is_std_present,
              scored_grade: edge.node.scored_grade,
              teacher_comments: edge.node.teacher_comments,
            });
            return {
              ...edge,
              node: {
                ...edge.node,
                isChecked: true,
                // set default value of isChecked to true
              },
            };
          }
        );
        if (endCursor) {
          // If we have already fetched some data, we need to check if there
          // are any duplicates in the new data, and update their isChecked
          // property based on the existing data.
          // const filteredStudents = students.filter(
          //   (student) => !student.node.isChecked
          // );

          const updatedNewData = newData.map((newStudent) => {
            const filteredStudent = students.find(
              (student) => student.node.id === newStudent.node.id
            );
            if (filteredStudent) {
              return {
                ...newStudent,
                node: {
                  ...newStudent.node,
                  // isChecked: filteredStudent.node.isChecked,
                },
              };
            }
            return newStudent;
          });
          setStudents(updatedNewData);
        } else {
          setStudents(newData);
        }

        setEndCursor(studentsData.GetStdTestMarksBySubjId.pageInfo.endCursor);
        setUpdatedStudentMarks(tempMarks);
        setRemarks(
          studentsData.GetStdTestMarksBySubjId.edges.map(
            (d) => d.node.teacher_comments
          )
        );
      }
    },
    // eslint-disable-next-line
    [studentsData, studentsLoading, fetchMore, stateChange, selectedSubject]
  );

  const handleMarksChange = (
    id: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const marksScored = marks_ext_scored;
    const maxMarks = selectedSubject?.subj_total_max_marks;

    if (
      marksScored !== undefined &&
      maxMarks !== undefined &&
      marksScored <= maxMarks
    ) {
      const newStudentMarks: Map<number, sub_marks> = new Map(
        updatedStudentMarks
      );
      const existingMarks = newStudentMarks.get(id);

      const updatedMarks: sub_marks = {
        ext_marks: Number((e.target as HTMLInputElement).value),
        is_std_present: existingMarks?.is_std_present ?? false,
        scored_grade: existingMarks ? existingMarks.scored_grade : EMPTY_STRING,
        teacher_comments: existingMarks
          ? existingMarks.teacher_comments
          : EMPTY_STRING,
      };

      newStudentMarks.set(id, updatedMarks);
      setUpdatedStudentMarks(newStudentMarks);
    } else {
      setMessage({
        flag: true,
        message: "Entered Marks is More than Maximum Marks",
        operation: Operation.NONE,
      });
    }
  };
  const gradeDetails =
    testConductDetails.data &&
    testConductDetails.data.GetAcdTestClassSubjects.length
      ? testConductDetails.data.GetAcdTestClassSubjects[0].test_class_details
          .test_name_details.show_marks_in_grade
      : [];
  const isNonAcademic =
    testConductDetails &&
    testConductDetails.data?.GetAcdTestClassSubjects.find(
      (data) => data.subject_master_details.id === selectedSubject?.value
    )?.test_class_details.is_non_academic;

  const enabledRemarks = testData
    ? testData.node.test_name_details.add_test_remarks &&
      operation === updatedRemarks
    : false;

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Update Marks Entry</Title>
      <div className="academics__exam--marks-entry">
        <InstDetails />

        <div className="row g-0 academics__exam--marks-entry--select-sub">
          {operation === Operation.UPDATE ? (
            <>
              <div className="col-3">
                {remarksOnlyUpdate === false ? (
                  <Autocomplete
                    classes={classes}
                    value={selectedSubject}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setSelectedSubject(newValue);
                      } else {
                        setSelectedSubject(null);
                      }
                    }}
                    isOptionEqualToValue={(option) =>
                      isOptionEqualToValue(option, selectedSubject)
                    }
                    onKeyDown={(event) => {
                      if (event.key === Keys.ENTER) {
                        if (students.length && selectedSubject) {
                          setInEditMode({
                            rowKey: students[0].node.student_id,
                            status: true,
                          });
                        }
                      }
                    }}
                    options={subjects}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Select Subjects"
                        classes={{ root: textClasses.formControlRoot }}
                      />
                    )}
                  />
                ) : null}
              </div>
              {(testData &&
                testData.node.test_name_details.add_test_remarks &&
                isClassTeacher) ||
              (isGlobalUser &&
                testData &&
                testData.node.test_name_details.add_test_remarks) ? (
                <div className="col-3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={remarksOnlyUpdate}
                        onChange={(e) => {
                          setSelectedSubject(null);
                          const { checked } = e.target;

                          setRemarksOnlyUpdate(checked);
                        }}
                      />
                    }
                    label="Update Remarks"
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </div>
        <div className="row g-0 academics__exam--marks-entry--subtitle">
          <div className="col-8">
            <Title variant="subtitle1">
              {testData
                ? `${testData.node.test_name_details.test_name} (${testData.node.test_name_details.test_type_details.test_type_name})`
                : ""}
            </Title>
          </div>
          <div className="col-4 academics__exam--marks-entry--date">
            <TextField
              disabled
              type="date"
              label="Test Start Date"
              InputLabelProps={{ shrink: true }}
              className="academics__exam--marks-entry--textfield--date"
              value={
                testData
                  ? toInputStandardDate(testData.node.test_start_date)
                  : ""
              }
            />
            <TextField
              disabled
              type="date"
              label="Test End Date"
              InputLabelProps={{ shrink: true }}
              value={
                testData ? toInputStandardDate(testData.node.test_end_date) : ""
              }
              className="academics__exam--marks-entry--textfield--date"
            />
          </div>
        </div>
        <div className="row g-0 academics__exam--marks-entry--tableblock">
          {/* To Be Changed */}
          {error ? (
            <b className="nodata">{error.message}</b>
          ) : (
            (selectedSubject ||
              operation === updatedRemarks ||
              remarksOnlyUpdate) && (
              <TableContainer className="academics__exam--marks-entry--table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="academics__exam--marks-entry--table--groupheader">
                      {Academics_Table.DailyActivities.Exam.MarksEntry.map(
                        (th: TableHeaderProps, index: React.Key) => {
                          return (
                            <TableCell key={index} rowSpan={3}>
                              {th.labelName}
                            </TableCell>
                          );
                        }
                      )}

                      {selectedSubject ? (
                        <TableCell
                          colSpan={3}
                        >{`${selectedSubject?.label}`}</TableCell>
                      ) : null}
                      {isNonAcademic && remarksFlag && (
                        <TableCell rowSpan={3}>Update Remarks</TableCell>
                      )}
                      {enabledRemarks || remarksOnlyUpdate ? (
                        <TableCell rowSpan={3}>Update Remarks</TableCell>
                      ) : null}
                    </TableRow>

                    {selectedSubject ? (
                      <>
                        <TableRow className="academics__exam--marks-entry--table--subheader">
                          <TableCell colSpan={1}>
                            {gradeDetails || isNonAcademic
                              ? "Previous Grade"
                              : "Previous Marks"}
                          </TableCell>
                          <TableCell
                            colSpan={gradeDetails || isNonAcademic ? 1 : 2}
                          >
                            {gradeDetails || isNonAcademic
                              ? "New Grade"
                              : "New Marks"}
                          </TableCell>
                          {isNonAcademic && (
                            <TableCell>
                              Attendance (P:Present A:Absent)
                            </TableCell>
                          )}
                        </TableRow>
                        {gradeDetails || isNonAcademic ? null : (
                          <TableRow className="academics__exam--marks-entry--table--subheader1">
                            <TableCell>
                              Marks
                              {` (Max:${selectedSubject?.subj_total_max_marks}
                      Min:${selectedSubject?.subj_total_min_marks})`}
                            </TableCell>

                            <TableCell>Marks</TableCell>

                            <TableCell>
                              Attendance (P:Present A:Absent)
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : null}
                  </TableHead>
                  <TableBody>
                    {operation === Operation.UPDATE &&
                    remarksOnlyUpdate === false
                      ? students.map((student, index) => (
                          <TableRow key={index}>
                            <TableCell
                              id="td-center"
                              className="academics__exam--marks-entry--table--slno"
                            >
                              {index + 1}
                            </TableCell>

                            <TableCell className="academics__exam--marks-entry--table--regno">
                              {student.node.student_details.std_adm_no}
                            </TableCell>
                            <TableCell className="academics__exam--marks-entry--table--regno">
                              {student.node.student_details.std_reg_no}
                            </TableCell>
                            <TableCell>{`${student.node.student_details.first_name} ${student.node.student_details.middle_name} ${student.node.student_details.last_name}`}</TableCell>

                            {gradeDetails || isNonAcademic ? (
                              <>
                                <TableCell>
                                  {student.node.scored_grade}
                                </TableCell>
                                <TableCell>
                                  <Autocomplete
                                    classes={formClasses}
                                    options={gradeDropDown!}
                                    value={gradeSelected[index] || null}
                                    onChange={(event, newValue) => {
                                      if (newValue) {
                                        const selectedGrade =
                                          gradesData?.GetAcdGradeScalesByInstId.find(
                                            (grade) => grade.grade === newValue
                                          );
                                        const gradeRemarks =
                                          selectedGrade?.grade_remarks || "";

                                        setGradeSelected((prevState) => ({
                                          ...prevState,
                                          [index]: newValue,
                                        }));

                                        setRemarks((prevRemarks) => ({
                                          ...prevRemarks,
                                          [index]: gradeRemarks,
                                        }));
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === Keys.BACKSPACE) {
                                        setGradeSelected({});
                                        setRemarks({});
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        classes={{
                                          root: formTextClasses.formControlRoot,
                                        }}
                                        fullWidth
                                      />
                                    )}
                                  />
                                </TableCell>
                              </>
                            ) : (
                              <>
                                <TableCell
                                  className="academics__exam--marks-entry--table--marks"
                                  id="td-center"
                                >
                                  {student.node.marks_scored_tot}
                                </TableCell>

                                <TableCell
                                  onClick={() => {
                                    if (
                                      updatedStudentMarks?.get(
                                        student.node.student_id
                                      )?.is_std_present
                                    ) {
                                      onEdit(student.node.student_id);
                                    }
                                  }}
                                  className="academics__exam--marks-entry--table--input "
                                  id="td-center"
                                >
                                  {inEditMode.status &&
                                  inEditMode.rowKey ===
                                    student.node.student_id ? (
                                    <input
                                      autoFocus
                                      name="ext_marks"
                                      type="number"
                                      onFocus={(e) => {
                                        (e.target as HTMLInputElement).select();
                                      }}
                                      onBlur={() =>
                                        setInEditMode({
                                          rowKey: 0,
                                          status: false,
                                        })
                                      }
                                      className={
                                        selectedSubject?.subj_total_max_marks !==
                                          undefined &&
                                        selectedSubject?.subj_total_max_marks <
                                          marks_ext_scored
                                          ? "max_error"
                                          : ""
                                      }
                                      value={marks_ext_scored}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        if (Number(e.target.value) >= 0) {
                                          setmarks_ext_scored(
                                            Number(e.target.value)
                                          );
                                        } else {
                                          setMessage({
                                            flag: true,
                                            message:
                                              "Cannot Enter Negative Marks",
                                            operation: Operation.NONE,
                                          });
                                        }
                                      }}
                                      onKeyDown={(event) => {
                                        if (event.key === Keys.ENTER) {
                                          handleMarksChange(
                                            student.node.student_id,
                                            event
                                          );

                                          if (students[index + 1]) {
                                            onEdit(
                                              students[index + 1].node
                                                .student_id
                                            );
                                          } else {
                                            setInEditMode({
                                              rowKey: 0,
                                              status: false,
                                            });
                                            saveRef.current?.focus();
                                          }
                                        }
                                      }}
                                    />
                                  ) : updatedStudentMarks?.get(
                                      student.node.student_id
                                    )?.is_std_present ? (
                                    updatedStudentMarks.get(
                                      student.node.student_id
                                    )?.ext_marks
                                  ) : (
                                    "Absent"
                                  )}
                                </TableCell>
                              </>
                            )}

                            <TableCell
                              className="academics__exam--marks-entry--table--switch"
                              id="td-center"
                            >
                              <AntSwitch
                                checked={
                                  updatedStudentMarks?.get(
                                    student.node.student_id
                                  )?.is_std_present
                                    ? true
                                    : false
                                }
                                onClick={(e) =>
                                  changeAttendance(student.node.student_id, e)
                                }
                                name="is_present"
                              />
                              {updatedStudentMarks.get(student.node.student_id)
                                ?.is_std_present
                                ? "P"
                                : "A"}
                            </TableCell>
                            {gradeDetails || isNonAcademic ? (
                              <TableCell>
                                <Input
                                  type="text"
                                  value={remarks[index] || ""}
                                  onChange={(e) => {
                                    const updatedRemarks = { ...remarks };
                                    updatedRemarks[index] = e.target.value;
                                    setRemarks(updatedRemarks);
                                  }}
                                />
                              </TableCell>
                            ) : null}
                          </TableRow>
                        ))
                      : stdData
                      ? stdData.GetStdTestMarks.edges.map((student, index) => (
                          <TableRow key={index}>
                            <TableCell
                              id="td-center"
                              className="academics__exam--marks-entry--table--slno"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell className="academics__exam--marks-entry--table--regno">
                              {student.node.student_details.std_adm_no}
                            </TableCell>
                            <TableCell className="academics__exam--marks-entry--table--regno">
                              {student.node.student_details.std_reg_no}
                            </TableCell>
                            <TableCell>{`${student.node.student_details.first_name} ${student.node.student_details.middle_name} ${student.node.student_details.last_name}`}</TableCell>
                            {enabledRemarks || remarksOnlyUpdate ? (
                              <TableCell>
                                <Input
                                  type="text"
                                  value={remarks[index] || ""}
                                  onChange={(e) => {
                                    const updatedRemarks = { ...remarks };
                                    updatedRemarks[index] = e.target.value;
                                    setRemarks(updatedRemarks);
                                  }}
                                  onBlur={() => {
                                    const temp = testRemarks;
                                    temp.set(
                                      student.node.student_details.id,
                                      remarks[index]
                                    );
                                    setTestRemarks(temp);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === Keys.ENTER) {
                                      const temp = testRemarks;
                                      temp.set(
                                        student.node.student_details.id,
                                        remarks[index]
                                      );
                                      setTestRemarks(temp);
                                    }
                                  }}
                                />
                              </TableCell>
                            ) : null}
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          )}
        </div>
        {operation === Operation.UPDATE ? (
          <Button
            mode="save"
            onClick={handlemarkUpdate}
            buttonref={saveRef}
            disabled={
              (selectedSubject &&
                students.length > 0 &&
                operation === Operation.UPDATE) ||
              remarksOnlyUpdate
                ? false
                : true
            }
          />
        ) : (
          <Button
            mode="save"
            onClick={handlemarkUpdate}
            buttonref={saveRef}
            disabled={operation === updatedRemarks ? false : true}
          />
        )}
        <Button
          mode="excel"
          type="button"
          onClick={() => setImportModal(!importModal)}
        >
          Import Marks
        </Button>
        {remarksOnlyUpdate === false ? (
          <Button mode="clear" onClick={handleClear} />
        ) : null}
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <LoadingModal flag={loading || addRemarksLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <UpdateMarksImport setModalFlag={setImportModal} />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setImportModal(!importModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateMarks;
