import React, { useContext, useEffect, useState } from "react";
import { Direction, Operation, SortBy } from "../../../utils/Enum.types";
import { Autocomplete, TextField } from "@mui/material";
import Input from "../../../stories/Input/Input";
import { Label } from "../../../stories/Label/Label";

import { msgType, responseType } from "../../../utils/Form.types";
import {
  EMPTY_STRING,
  MULTI_SELECT_LIMIT_TAGS,
  ROWS_PER_PAGE,
  ROWS_PER_PAGE_30,
} from "../../../utils/constants";
import { Button } from "../../../stories/Button/Button";
import useInstitutionsByCustId from "../../../customhooks/useInstitutionsByCustId";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddCampusDetails,
  GetCampus,
  GetCampusById,
  UpdateCampusDetails,
  UpdateCampusPrimaryInst,
} from "../../../queries/Campus/mutations/new";

import useToken from "../../../customhooks/useToken";
import { Keys } from "../../../utils/Enum.keys";
import { handleMUISelectEvent } from "../../../utils/UtilFunctions";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import { GetCampusByIdData, GetCampusByIdVars } from "./Types";
import { AppContext } from "../../../context/context";
import { Title } from "../../../stories/Title/Title";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import Active from "../../../images/ActiveRoute.svg";
import Edit from "../../../images/EditProfile.svg";
import { payloadTypes } from "../../../context/reducer";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { GetInstsForCampus } from "../../../queries/institution/list/byId";
import { StudentPageInfo } from "../../Academics/hooks/useAcdStudentsData";
export interface GetInstsForCampusEdges {
  node: {
    id: number;
    inst_name: string;
  };
}
export interface GetInstsForCampusList {
  edges: GetInstsForCampusEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}

export interface GetInstsForCampusData {
  GetInstsForCampus: GetInstsForCampusList;
}
export interface GetInstsForCampusVars {
  token: string;
  customer_id: number;
  first: number | null;
  after: string | null;
  name: string;
  orderBy: { direction: string; field: string };
}
interface Props {
  operation: Operation;
  setPrimaryInstModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setCampusModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setCampusUpdateModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({
  operation,
  setCampusModal,
  setPrimaryInstModal,
  setCampusUpdateModal,
}: Props) => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const { token } = useToken();
  const { dispatch } = useContext(AppContext);
  const { custId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [institutionSelected, setInstitutionSelected] = useState<
    responseType[]
  >([]);

  const [searchInst, setSearchInst] = useState("");
  const [campusName, setCampusName] = useState<string>("");
  const { Institutions } = useInstitutionsByCustId(
    Number(custId)!,
    searchInst,
    ROWS_PER_PAGE
  );
  const instSelectedSet = new Set(
    institutionSelected.map(({ value }) => value)
  );

  const [AddCampus, { loading: addtionLoading }] = useMutation(
    AddCampusDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetInstDataForCampus, { data: InstList }] = useLazyQuery<
    GetInstsForCampusData,
    GetInstsForCampusVars
  >(GetInstsForCampus);
  const institutionList =
    InstList &&
    InstList.GetInstsForCampus.edges
      .map((res) => ({
        label: res.node.inst_name,
        value: res.node.id,
      }))
      .filter((v) => !instSelectedSet.has(v.value));
  const [UpdateCampus, { loading: updationLoading }] = useMutation(
    UpdateCampusDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateCampusInstitution, { loading: primaryLoading }] = useMutation(
    UpdateCampusPrimaryInst,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetCampusByID, { data, loading }] = useLazyQuery<
    GetCampusByIdData,
    GetCampusByIdVars
  >(GetCampusById);

  const handleSubmit = () => {
    if (operation === Operation.CREATE) {
      AddCampus({
        variables: {
          token,
          input: {
            campus_name: campusName,
            customer_id: custId,
          },
          inst_ids: institutionSelected?.map((inst) => inst.value),
          user_details,
        },
        refetchQueries: [
          {
            query: GetCampus,
            variables: {
              token,
              customer_id: Number(custId),
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          dispatch({
            type: payloadTypes.SET_CAMPUS_ID,
            payload: {
              campusId: data?.AddCampus?.id,
            },
          });
          setPrimaryInstModal?.(true);
        }
      });
    } else if (operation === Operation.UPDATE) {
      UpdateCampus({
        variables: {
          token,
          campus_id: state.campusId,
          campus_name: campusName,
          inst_ids: institutionSelected?.map((inst) => inst.value),
          user_details,
        },
        refetchQueries: [
          {
            query: GetCampus,
            variables: {
              token,
              customer_id: Number(custId),
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Campus Updated Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      UpdateCampusInstitution({
        variables: {
          token,
          campus_id: state.campusId,
          inst_id: institutionSelected[0].value,
          user_details,
        },
        refetchQueries: [
          {
            query: GetCampus,
            variables: {
              token,
              customer_id: Number(custId),
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Campus Primary Institution Updated Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const handleRadioChange = (index: any) => {
    const updatedInstitutions = institutionSelected.map((institution, i) => ({
      ...institution,
      isChecked: i === index,
    }));
    setInstitutionSelected(updatedInstitutions);
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setCampusModal?.(false);
      setCampusUpdateModal?.(false);
      setPrimaryInstModal?.(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (state.campusId && token) {
      GetCampusByID({
        variables: {
          token,
          id: state.campusId,
        },
      }).then(({ data }) => {
        if (data && data.node) {
          setCampusName(data.node.campus_name);
          const institutions = data.node.assoicated_insts.map((res) => ({
            label: res.inst_details.inst_name,
            value: res.inst_id,
          }));
          setInstitutionSelected(institutions);
        }
      });
    }
  }, [GetCampusByID, token, state.campusId]);
  useEffect(() => {
    if (token && custId) {
      GetInstDataForCampus({
        variables: {
          token,
          orderBy: {
            direction: Direction.ASC,
            field: SortBy.INST_NAME,
          },
          after: null,
          first: ROWS_PER_PAGE_30,
          name: "",
          customer_id: Number(custId),
        },
      });
    }
  }, [token, custId, GetInstDataForCampus]);
  return (
    <>
      <div className="campus-list__addnew">
        <Title variant="subtitle1">
          {operation === Operation.UPDATE
            ? "Update Details"
            : operation === Operation.PRIMARY
            ? "Select Primary Institution"
            : "Add New Campus"}
        </Title>
        <div
          className={
            operation === Operation.UPDATE
              ? "campus-list__addnew--data--update"
              : "campus-list__addnew--data"
          }
        >
          <div className="campus-list__addnew--label-grid">
            <Label>Campus Name</Label>
            <Input
              placeholder="Enter Campus Name"
              value={campusName}
              onChange={(e) => setCampusName(e.target.value)}
              disabled={operation === Operation.PRIMARY}
              id="input-with-primary-color"
            />
          </div>
          {operation === Operation.PRIMARY && (
            <Label>
              <img src={Active} alt="/" />
              Select Primary Institution for the Campus
            </Label>
          )}
          <div className="campus-list__addnew--select-primary">
            {operation === Operation.PRIMARY ? (
              institutionSelected.map((institution, index) => (
                <div key={index}>
                  <label>
                    <input
                      type="radio"
                      name="institution"
                      value={institution.label}
                      checked={institution.isChecked}
                      onChange={() => handleRadioChange(index)}
                    />
                    &nbsp;
                    <span>{institution.label}</span>
                  </label>
                </div>
              ))
            ) : (
              <div className="campus-list__addnew--label-grid">
                <Label>Select Institutions</Label>
                <Autocomplete
                  classes={classes}
                  options={institutionList! ?? []}
                  multiple
                  limitTags={MULTI_SELECT_LIMIT_TAGS}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (institutionSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setInstitutionSelected([]);
                    }
                  }}
                  value={institutionSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setInstitutionSelected(newValue as responseType[]);
                    } else {
                      setInstitutionSelected([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      onChange={(e) => setSearchInst(e.target.value)}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </div>
        {operation === Operation.UPDATE && (
          <div className="campus-list__addnew--primary">
            <div className="campus-list__addnew--primary--label">
              <img src={Active} alt="/" />
              <span>Primary Institution</span>
            </div>

            <div className="campus-list__addnew--primary--inst">
              <span>
                {data?.node.assoicated_insts.find(
                  ({ is_primary_inst }) => is_primary_inst
                )
                  ? data?.node.assoicated_insts.find(
                      ({ is_primary_inst }) => is_primary_inst
                    )?.inst_details.inst_name
                  : EMPTY_STRING}
              </span>
              <div>
                {/* <img src={Delete} alt="/" /> */}
                <img
                  src={Edit}
                  alt="/"
                  onClick={() => {
                    setPrimaryInstModal?.(true);
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <Button mode="save" onClick={handleSubmit} />
        <Button
          mode="cancel"
          onClick={() => {
            operation === Operation.UPDATE
              ? setCampusUpdateModal?.(false)
              : operation === Operation.PRIMARY
              ? setPrimaryInstModal?.(false)
              : setCampusModal?.(false);
            dispatch({
              type: payloadTypes.SET_CAMPUS_ID,
              payload: {
                campusId: 0,
              },
            });
          }}
        />
      </div>

      <LoadingModal
        flag={addtionLoading || updationLoading || primaryLoading || loading}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Index;
