/**
 * React functional component that handles the refund process for a student.
 *
 * Props:
 * - pageType: A prop that determines the type of page where the component is rendered (either 'GENERAL' or 'MODAL').
 *
 * Example Usage:
 * <Refunds pageType={PageFor.GENERAL} />
 *
 * Inputs:
 * - pageType: A prop that determines the type of page where the component is rendered (either 'GENERAL' or 'MODAL').
 *
 * Flow:
 * 1. The component starts by importing necessary dependencies and custom hooks.
 * 2. It defines the initial state variables and sets up the context and navigation.
 * 3. The component fetches and displays student information based on the selected admission number.
 * 4. It fetches deposit details for the selected student and displays them in a table.
 * 5. The user can edit the refund amount for each deposit by clicking on the corresponding cell in the table.
 * 6. The user can select the mode of transaction, authorized by, and bank details (if applicable).
 * 7. The user can enter remarks for the refund.
 * 8. The user can generate a refund receipt by clicking the save button.
 * 9. The component handles the mutation to generate the receipt and updates the table and receipt details accordingly.
 * 10. The component also handles error messages and displays loading and success modals.
 *
 * Outputs:
 * - The component renders a form for selecting a student, entering refund amounts, and generating a refund receipt.
 * - It displays student information, deposit details, and receipt information in a table format.
 * - It provides options for selecting the mode of transaction, authorized by, and bank details.
 * - It handles the generation of refund receipts and displays success and error messages.
 */
import React, { useContext, useEffect, useRef, useState } from "react";
import Input from "../../../stories/Input/Input";
import { Label } from "../../../stories/Label/Label";
import { Title } from "../../../stories/Title/Title";
import {
  PageFor,
  DebitOrCredit,
  VoucherBookKey,
  ReceiptTypes,
  StudentListFor,
  StudentAcctReportType,
  PredefinedDataTypes,
  BankOrCash,
  Operation,
  ModuleName,
  Direction,
  SortBy,
  InstitutionConfigurationTypes,
} from "../../../utils/Enum.types";
import {
  PrintConfigModalStyles,
  PrintModalStyles,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
import Modal from "react-modal";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Button } from "../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/context";

import { useMutation } from "@apollo/client";
import {
  DateRange,
  formatter,
  handleMUISelectEvent,
  NameOfTheDay,
  toggleFullSceen,
  toInputStandardDate,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import {
  DEFAULT_TIME,
  EMPTY_OPTIONSTYPE_OBJECT,
  EMPTY_STRING,
  emptyMessageType,
  inEditModeDefaultValue,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../utils/constants";
import { AddAcctVoucherMasterAndDetails } from "../queries/receipts/mutation/Index";
import dayjs from "dayjs";
import { RefundAcademicFeeTitleProps } from "../../../Types/Titles";
import { TableHeaderProps } from "../../../Types/Tables";
import Home from "../Home/Index";
import Avatar from "../../../images/Avatar.svg";
import Close from "../../../images/Close.svg";
import Edit from "../../../images/Edit.svg";

import LoadingModal from "../../../pages/LoadingModal";

import Enlarge from "../../../images/Enlarge.svg";
import useStudentDatabyId from "../../../customhooks/useStudentDatabyId";
import DownArrow from "../../../images/DownArrow.svg";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import { msgType, optionsType, responseType } from "../../../utils/Form.types";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import useAcctLedgerData from "../hooks/useAcctLedgerData";
import useToken from "../../../customhooks/useToken";
import { Keys } from "../../../utils/Enum.keys";
import useStudentDepositDetails, {
  DepositEdge,
} from "../hooks/useStudentDepositDetails";
import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import { ledgerOptions, recepitsw } from "../StudentDetails/FeeReceipt/Index";
import MessageModal from "../../../pages/MessageModal";
import { getDownloadUrl } from "../../../utils/DownloadFile";
import { payloadTypes } from "../../../context/reducer";
import useVoucherNumber from "../hooks/useVoucherNumber";
import StudentList from "../common/StudentList";
import useAcctStdAdmissionNumber from "../hooks/useAcctStdAdmissionNumber";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import {
  AcctLedgerQueryType,
  DepositLedgerQueryType,
} from "../common/QueryTypes";
import useInstReferenceDataByType, {
  InstReferenceDataByType,
} from "../../../customhooks/useInstReferenceDataByType";
import { Data } from "../../Print/Accounts/FeeReceipts/Standard/Index";
import Configurations from "../../Configurations/PerModuleConfiguration";
import Settings from "../../../images/Settings.svg";
import { SwConfigQueryType } from "../../HR/enums/Enum.types";
import { GetAcctStdDeposits } from "../queries/FeeLedgers/query/Byid";
import { GetAcctVouchers, GetVoucherNumber } from "../queries/Vouchers/query";
import {
  InstitutionAcctConfigurationTypes,
  VoucherQueryTypes,
} from "../common/Enum.types";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { GlobalPageConfigData } from "../../../Types/configtypes";
import TextArea from "../../../stories/TextArea/TextArea";
import VoucherPrint from "../../Print/Accounts/Vouchers/VoucherPrint";
import useAcctTableJson from "../json/useAcctTableJson";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import {
  AddAcctVoucherMasterAndDetailsData,
  AddAcctVoucherMasterAndDetailsVars,
} from "../../../Types/Accounting/mutations";
import useInstDetails from "../../../customhooks/general/useInstDetails";

const { AccountsTitles } = require("../json/title.json");

interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  selectStudent: boolean;
}
const Refunds = ({ pageType, setModalFlag, selectStudent }: Props) => {
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();

  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const listClasses = ListAutoCompleteStyles();
  const listTextClasses = ListAutoCompleteTextStyles();
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  const navigate = useNavigate();

  const { InstDetails } = useInstDetails(1);
  const { studentId } = useParams();

  const { firstDay, lastDay } = DateRange(new Date().toString()) || {};

  const [searchData, setsearchData] = useState("");
  const [imageString, setImageString] = useState("");
  const [rcptDate, setRcptDate] = useState("");

  const [remarks, setRemarks] = useState("");
  const { InstId } = useParams<{ InstId: string }>();
  const [items, setItems] = useState<DepositEdge[]>([]);
  const [refundAmount, setRefundAmount] = useState(0);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0,
  });
  const [ledgerType, setLedgerType] = useState<optionsType | null>(null);

  const [authorizedBy, setAuthorizedBy] = useState<optionsType | null>(null);
  const [printModal, SetPrintModal] = useState(false);

  const [tableData, setTableData] = useState<Data[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [v_date, set_v_Date] = useState("");
  const [cashAccount, setCashAccount] = useState(false);
  const [bankAccount, setBankAccount] = useState(false);

  const { configData: enableChallanReceipt } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_CHALLAN_RECEIPT
  );
  const challanFlag =
    enableChallanReceipt.data &&
    enableChallanReceipt.data.GetSwConfigVariables[0].config_boolean_value;
  const [transactionBookLedgerId, setTransactionLedgerId] =
    useState<responseType | null>(null);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [bankTransactionType, setBankTransactionType] =
    useState<optionsType | null>(null);
  const [receipts, setReceipts] = useState<recepitsw[]>([]);
  const [studentModal, setStudentModal] = useState(false);
  const [message, setMessage] = useState<msgType>(emptyMessageType);

  const [admNo, setAdmNo] = useState("");
  const [printConfig, setPrintConfigModal] = useState(false);

  const modeOfTransactionRef = useRef<HTMLSelectElement>(null);
  const modeOfTransactionInputRef = modeOfTransactionRef.current?.childNodes[0]
    .childNodes[0].childNodes[0] as HTMLInputElement;
  const remarksRef = useRef<HTMLTextAreaElement>(null);

  const transactionRefNumberRef = useRef<HTMLInputElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  // use queries
  const { user_details } = useLoggedInUserDetails();
  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.FEE_ADVANCE
  );

  const { InstreferenceData } = useInstReferenceDataByType(
    InstReferenceDataByType.AUTHORIZER
  );

  const {
    data: serverDateData,
    loading: serverDateLoading,
    serverDate,
  } = useServerDateandTime();
  const { studentData, studentFormData } = useStudentDatabyId();
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();
  const { acctLedgers: CashLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_CASH,
    ROWS_PER_PAGE
  );
  const { acctLedgers: BankLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.ACCT_LDGRS_BANK,
    ROWS_PER_PAGE
  );
  const {
    PredefinedData: { dropDown: bankTransactionTypes },
  } = usePredefinedDataByType(
    PredefinedDataTypes.BANK_COLLECTION,
    EMPTY_STRING
  );
  const { voucherNumber } = useVoucherNumber(
    VoucherBookKey.STUDENT_REFUND_RECEIPT_BOOK
  );
  //mutations
  const [GenerateStudentReceipts, { loading: GenerateStudentReceiptsLoading }] =
    useMutation<
      AddAcctVoucherMasterAndDetailsData,
      AddAcctVoucherMasterAndDetailsVars
    >(AddAcctVoucherMasterAndDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  let totalDepositOb = 0;
  let totalDepositAmount = 0;
  let totalDepositReceived = 0;
  let totalDepositAdjusted = 0;
  // db=>database
  let dbTotalDepositRefunded = 0;
  let totalDepositBalance = 0;
  let totalDepositRefundAmount = 0;
  // eslint-disable-next-line
  items.map((item) => {
    totalDepositOb += item.node.deposit_ob;
    totalDepositAmount += item.node.deposit_amt;
    totalDepositReceived += item.node.deposit_total;
    totalDepositAdjusted += item.node.deposit_adjusted;
    dbTotalDepositRefunded += item.node.deposit_refunded;
    totalDepositBalance += item.node.deposit_bal;
    totalDepositRefundAmount += item.node.deposit_refund;
  });
  const { StudentDepositData } = useStudentDepositDetails(
    DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID
  );

  const { configData: configKeys } = useSwConfigData([
    InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE,
  ]);
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let editDate = false;

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE:
            editDate = item.config_boolean_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      editDate,
    };
  };
  const { editDate } = filterDataByConfigKey(
    configKeys.data?.GetSwConfigVariables!
  );
  useEffect(() => {
    if (StudentDepositData.data && !StudentDepositData.loading) {
      // setStudentModal(!studentModal);
      const result: DepositEdge[] =
        StudentDepositData.data.GetAcctStdDeposits.edges.map((item) => ({
          ...item,
          node: {
            ...item.node,
            deposit_refund: item.node.deposit_bal,
          },
        }));
      setItems(result);
    }
  }, [
    state.studentId,
    token,
    StudentDepositData.data,
    StudentDepositData.loading,
  ]);
  useEffect(() => {
    if (challanFlag) {
      setLedgerType({
        label: "Bank Book",
        value: BankOrCash.BANK,
      });
      setBankAccount(true);
      setCashAccount(false);
    }
  }, [challanFlag]);
  useEffect(() => {
    if (serverDateData && !serverDateLoading) {
      setRcptDate(serverDateData.GetServerDateAndTime);
    }
  }, [serverDateData, serverDateLoading]);
  const onEdit = (id: number, updatedRcptAmount: number) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setRefundAmount(updatedRcptAmount);
  };

  const HandleEditItem = (sl_number: number) => {
    const itemIndex = items.findIndex((item) => item.node.id === sl_number);
    if (itemIndex === -1) return;
    if (refundAmount > items[itemIndex].node.deposit_bal) {
      alert("Please check you're refund amount");
      return;
    }
    const updatedData = [...items];
    updatedData[itemIndex].node.deposit_refund = refundAmount;
    setItems(updatedData);
  };

  const handleReceipts = () => {
    if (transactionBookLedgerId)
      setReceipts((items) => [
        ...items,
        {
          acct_ldgr_id: transactionBookLedgerId.value,
        },
      ]);
  };
  const handleClear = () => {
    setItems([]);
    dispatch({ type: payloadTypes.SET_STUDENT_ID, payload: { studentId: 0 } });

    setLedgerType(EMPTY_OPTIONSTYPE_OBJECT);
    setTransactionLedgerId(null);
    setAuthorizedBy(EMPTY_OPTIONSTYPE_OBJECT);
    setImageString(EMPTY_STRING);
    setRemarks(EMPTY_STRING);
    setReferenceNumber(EMPTY_STRING);
    setBankTransactionType(null);
    setsearchData(EMPTY_STRING);
    setRcptDate(serverDate);
    setRefundAmount(0);
    setInEditMode(inEditModeDefaultValue);
    setReceipts([]);
    setImageString(EMPTY_STRING);
  };
  const handleStudentReceipts = () => {
    const res = bankTransactionTypes.find(
      ({ value }) => bankTransactionType && value === bankTransactionType.value
    );
    if (totalDepositRefundAmount === 0) {
      alert("Please check ur refund amount");
      return;
    }
    if (state.studentId === 0) {
      alert("Student not found");
      return;
    }
    // if (handleReceipts())
    GenerateStudentReceipts({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        input: [
          {
            acct_voucher_master: {
              fin_yr: state.ActiveFinYr
                ? state.ActiveFinYr.fin_yr
                : EMPTY_STRING,
              v_type: ReceiptTypes.PYMT,
              v_no: voucherNumber.data
                ? voucherNumber.data.GetVoucherNumber.vo_number
                : EMPTY_STRING,
              v_date: toIsoDate(rcptDate),
              v_total_cr_amt: totalDepositRefundAmount,
              v_total_db_amt: totalDepositRefundAmount,
              v_reconciled: false,
              v_reconciled_date: dayjs(DEFAULT_TIME).format(),
              v_std_receipt: true,
              v_std_refund: false,
              v_std_receipt_ob: false,
              v_std_anonymous_deposit_adjusted: false,
              v_std_deposit_adjusted: false,
              v_book_type: VoucherBookKey.STUDENT_REFUND_RECEIPT_BOOK,
              v_std_deposit: false,
              v_std_demand_receipt: false,
              v_std_scholarship_deposit: false,

              student_id: state.studentId,
              v_std_non_demand_receipt: false,
              v_std_passout_receipt: false,
              v_std_enquiry: false,
              enquiry_student_id: 0,
              class_id: studentData.data
                ? studentData.data?.nodes[0].class.id
                : 0,
              semester_id: studentData.data
                ? studentData.data.nodes[0].semester.id
                : 0,
              v_std_amt_receipt: 0,
              v_std_amt_deposit: 0,
              v_std_amt_fine: 0,
              v_std_receipt_anonymous: false,
              v_std_refund_deposit: true,
              v_std_amt_total: totalDepositRefundAmount,
              v_std_amt_refunded: totalDepositRefundAmount,
              v_std_amt_adjusted: 0,
              v_transcation_type: res?.label ?? "Cash Book",
              v_transcation_cash_or_bank: res
                ? BankOrCash.BANK
                : BankOrCash.CASH,
              v_transcation_no: referenceNumber,
              v_transcation_date: dayjs(rcptDate).format(),
              v_transcation_narration:
                remarks +
                ";" +
                (authorizedBy ? authorizedBy.value : EMPTY_STRING),
              // paid_party_id: "",
              // party_bill_no: "",
              party_bill_date: dayjs(DEFAULT_TIME).format(),
              party_name: `${studentFormData.first_name} ${studentFormData.middle_name} ${studentFormData.last_name}`,
              annx_yesno: false,
              // annx_id: Math.random() * 1000,
              is_vouch_multi_entry: false,

              acct_ldgr_id_cr: items.filter(
                (item) => item.node.deposit_refund > 0
              )[0].node.acct_ldgr_details.id,
              acct_ldgr_id_db: receipts[0].acct_ldgr_id,
            },
            acct_voucher_db: items
              .filter(({ node: { deposit_refund } }) => deposit_refund > 0)
              .map((item, index) => ({
                vo_cr_db: DebitOrCredit.DEBIT,
                vo_sl_no: index + 1,
                vo_cr: 0,
                vo_db: item.node.deposit_refund,
                vo_cr_total: 0,
                vo_db_total: totalDepositRefundAmount,
                acct_ldgr_id: item.node.acct_ldgr_id,
              })),
            acct_voucher_cr: receipts.map((receipt, index) => ({
              vo_sl_no: index + 1,
              vo_cr_db: DebitOrCredit.CREDIT,
              vo_cr: totalDepositRefundAmount,
              vo_db: 0,
              vo_cr_total: totalDepositRefundAmount,
              vo_db_total: 0,
              acct_ldgr_id: receipt.acct_ldgr_id,
            })),
          },
        ],
      },
      refetchQueries: [
        {
          query: GetAcctStdDeposits,
          variables: {
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            query_type: DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID,
            student_id: state.studentId,
            token,
          },
        },
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            sortBy: SortBy.V_DATE,
            token,
            name: EMPTY_STRING,
            deposit: null,
            vTranscationType: EMPTY_STRING,
            partyName: EMPTY_STRING,
            vTranscationNo: EMPTY_STRING,
            vTranscationNarration: EMPTY_STRING,
            ldgrDesc: EMPTY_STRING,
            amount: null,
            input: {
              inst_id: InstId!,
              voucher_query_type: VoucherQueryTypes.BY_DATES,
              vo_end_date: toIsoDate(lastDay!),
              acct_ldgr_id: 0,
              vo_start_date: toIsoDate(firstDay!),
              vo_type: EMPTY_STRING,
            },
          },
        },
        {
          query: GetVoucherNumber,
          variables: {
            token,
            inst_id: InstId!,
            vo_book_key: VoucherBookKey.STUDENT_REFUND_RECEIPT_BOOK,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        const { v_no, v_date, id } = data.AddAcctVoucherMasterAndDetails[0];
        dispatch({
          type: payloadTypes.SET_RECEPIT_ID,
          payload: { receiptId: id },
        });
        setTableData(
          items
            .filter((item) => item.node.deposit_refund > 0)
            .map((item) => ({
              particular: item.node.acct_ldgr_details.ldgr_desc,
              amount: item.node.deposit_refund,
            }))
        );
        set_v_Date(v_date);
        set_vo_no(v_no);

        !printModal ? SetPrintModal(!printModal) : handleClear();
        setMessage({
          message: `Successfully generated fee receipt for student ${studentData.data?.nodes[0].first_name}`,
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (transactionBookLedgerId && transactionBookLedgerId.value > 0) {
      handleReceipts();
    } // eslint-disable-next-line
  }, [transactionBookLedgerId]);

  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== EMPTY_STRING &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${
        InstDetails.data?.nodes[0]?.inst_name
      }/students/${
        state.studentId ? state.studentId : studentId
      }/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);

  const { branchLabel, classLabel ,categoryLabel} = useInstLabels();
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <div className="row g-0">
        <div className="col">
          <Title>
            {AccountsTitles.RefundsFee.Titles.map(
              (title: RefundAcademicFeeTitleProps) => {
                return (
                  <React.Fragment key={title.Refund_Academic_Fee}>
                    {title.Refund_Deposit_Fee}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>

        <div className="configuration-settings">
          {pageType === PageFor.MODAL && (
            <>
              <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
            </>
          )}
        </div>
      </div>

      <div className="row g-0 refund-academic-fee">
        <div className="col refund-academic-fee__frame ">
          <div className="refund-academic-fee__frame--imagefield">
            {selectStudent ? (
              <Autocomplete
                classes={listClasses}
                options={studentAddmissionNumber}
                openOnFocus
                value={
                  state.studentId
                    ? studentAddmissionNumber?.find(
                        ({ value }) => value === state.studentId
                      )!
                    : null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: (newValue as responseType)?.value,
                      },
                    });
                  } else {
                    handleClear();
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (
                    state.studentId &&
                    e.key === Keys.ENTER &&
                    items[0].node.deposit_refund > 0
                  ) {
                    onEdit(items[0].node.id, items[0].node.deposit_refund);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                  }
                }}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => setAdmNo(e.target.value)}
                    label="Admission No."
                    fullWidth
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: listTextClasses.formControlRoot }}
                  />
                )}
              />
            ) : (
              <TextField
                id="Admission No."
                label="Register Number"
                className="refund-academic-fee__frame--textfield"
                InputLabelProps={{ shrink: true }}
                value={studentFormData?.adm_no ?? EMPTY_STRING}
                disabled
              />
            )}

            {pageType === PageFor.GENERAL ? (
              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() => setStudentModal(!studentModal)}
              />
            ) : null}
          </div>

          <TextField
            label="Register Number"
            className="refund-academic-fee__frame--textfield"
            InputLabelProps={{ shrink: true }}
            value={studentFormData?.reg_number ?? EMPTY_STRING}
            disabled
          />
          <TextField
            label={classLabel}
            className="refund-academic-fee__frame--textfield"
            InputLabelProps={{ shrink: true }}
            value={studentFormData?.class ?? EMPTY_STRING}
            disabled
          />
        </div>

        <div className="col refund-academic-fee__frame g-0">
          <TextField
            label="Name"
            className="refund-academic-fee__frame--textfield"
            InputLabelProps={{ shrink: true }}
            value={studentFormData?.std_name}
            disabled
          />
          <TextField
            label="Father Name"
            className="refund-academic-fee__frame--textfield"
            InputLabelProps={{ shrink: true }}
            value={studentFormData?.father_name ?? EMPTY_STRING}
            disabled
          />
          <TextField
            label={branchLabel}
            className="refund-academic-fee__frame--textfield"
            InputLabelProps={{ shrink: true }}
            value={studentFormData?.branch ?? EMPTY_STRING}
            disabled
          />
        </div>
        <div className="col refund-academic-fee__frame g-0">
          <TextField
            type="date"
            label="Date"
            onChange={(e) => setRcptDate(e.target.value)}
            className="refund-academic-fee__frame--textfield--date"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                min: state.ActiveFinYr
                  ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                  : 0,

                max: toInputStandardDate(TODAY_DATE),
              },
            }}
            value={toInputStandardDate(rcptDate)}
            disabled={editDate ? false : true}
          />
          <Label variant="present-day">{NameOfTheDay(rcptDate)}</Label>
          {USE_CATEGORY_KEY && (
            <TextField
               label={categoryLabel}
              className="refund-academic-fee__frame--textfield"
              InputLabelProps={{ shrink: true }}
              value={studentFormData?.category}
              disabled
            />
          )}
        </div>
        <div className="col-1 refund-academic-fee__frame--image h-100">
          {imageString === EMPTY_STRING ? (
            <img src={Avatar} alt="/" />
          ) : (
            <img src={imageString} alt="/" />
          )}
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 refund-academic-fee__tableblock"
            : "row g-0 refund-academic-fee__tableblock--modal"
        }
      >
        <TableContainer className="refund-academic-fee__table g-0">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Accounts_Table.DepositRefund.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell key={index} className={th.className}>
                        {th.labelName}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => {
                return (
                  <TableRow key={item.node.id}>
                    <TableCell
                      className="refund-academic-fee__table--slno"
                      align="center"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell>
                      {item.node.acct_ldgr_details.ldgr_desc}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="refund-academic-fee__table--fee"
                    >
                      {format(item.node.deposit_ob)}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="refund-academic-fee__table--fee"
                    >
                      {format(item.node.deposit_amt)}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="refund-academic-fee__table--fee"
                    >
                      {format(item.node.deposit_total)}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="refund-academic-fee__table--fee"
                    >
                      {format(item.node.deposit_adjusted)}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="refund-academic-fee__table--fee"
                    >
                      {format(item.node.deposit_refunded)}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="refund-academic-fee__table--fee"
                    >
                      {format(item.node.deposit_bal)}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      onClick={() => {
                        if (item.node.deposit_bal > 0)
                          onEdit(item.node.id!, item.node.deposit_refund);
                      }}
                      className="refund-academic-fee__table--fee editCell"
                    >
                      {inEditMode.status &&
                      inEditMode.rowKey === item.node.id ? (
                        <input
                          autoFocus
                          type="number"
                          value={refundAmount}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (Number(e.target.value) >= 0) {
                              setRefundAmount(Number(e.target.value));
                            }
                          }}
                          onFocus={(e: React.ChangeEvent<HTMLInputElement>) =>
                            e.target.select()
                          }
                          onKeyDown={(event: React.KeyboardEvent) => {
                            if (event.key === Keys.ENTER) {
                              if (refundAmount <= item.node.deposit_bal) {
                                if (
                                  items[index + 1] &&
                                  items[index + 1].node.id
                                ) {
                                  onEdit(
                                    items[index + 1].node.id!,
                                    items[index + 1].node.deposit_refund
                                  );

                                  HandleEditItem(item.node.id!);
                                } else {
                                  onEdit(
                                    items[index].node.id!,
                                    items[index].node.deposit_refund
                                  );
                                  HandleEditItem(item.node.id!);

                                  setInEditMode({
                                    rowKey: -805,
                                    status: false,
                                  });
                                  modeOfTransactionInputRef.focus();
                                }
                              } else {
                                setMessage({
                                  flag: true,
                                  message:
                                    "RefundAmount is greater than deposit balance amount",
                                  operation: Operation.NONE,
                                });
                                (event.target as HTMLInputElement).select();
                              }
                            }
                          }}
                        />
                      ) : (
                        format(item.node.deposit_refund)
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2} className="total">
                  Total :
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalDepositOb)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalDepositAmount)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalDepositReceived)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalDepositAdjusted)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(dbTotalDepositRefunded)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalDepositBalance)}
                </TableCell>
                <TableCell id="td-right" className="totalcount">
                  {format(totalDepositRefundAmount)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <form className="row g-0 refund-academic-fee__receiptblock">
        <div className="col account-frames">
          <div className="label-grid">
            <Label>Receipt No</Label>
            <Input
              disabled
              value={
                voucherNumber.data && state.studentId
                  ? voucherNumber.data.GetVoucherNumber.vo_number
                  : EMPTY_STRING
              }
            />
          </div>
          <div className="label-grid">
            <Label> Mode of Transaction</Label>
            {!challanFlag ? (
              <Autocomplete
                classes={classes}
                options={ledgerOptions}
                openOnFocus
                ref={modeOfTransactionRef}
                autoHighlight
                value={ledgerType}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setLedgerType(newValue as optionsType);
                    setTransactionLedgerId(null);

                    if ((newValue as optionsType).value === BankOrCash.BANK) {
                      setBankAccount(true);
                      setCashAccount(false);
                    } else {
                      setCashAccount(true);
                      setBankAccount(false);
                    }
                  } else {
                    setLedgerType(EMPTY_OPTIONSTYPE_OBJECT);
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && ledgerType) {
                    handleMUISelectEvent(e);
                  } else if (e.key === Keys.BACKSPACE) {
                    setLedgerType(EMPTY_OPTIONSTYPE_OBJECT);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            ) : (
              <Input disabled value={ledgerType?.label} />
            )}
          </div>
          <div className="label-grid">
            <Label>Authorized By</Label>
            <Autocomplete
              classes={classes}
              options={InstreferenceData.optionsType}
              openOnFocus
              autoHighlight
              value={authorizedBy}
              onChange={(e, newValue) => {
                if (newValue) {
                  setAuthorizedBy(newValue as optionsType);
                } else {
                  setAuthorizedBy(EMPTY_OPTIONSTYPE_OBJECT);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.BACKSPACE) {
                  setAuthorizedBy(EMPTY_OPTIONSTYPE_OBJECT);
                } else if (e.key === Keys.ENTER) {
                  handleMUISelectEvent(e);
                }
              }}
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        </div>
        {cashAccount ? (
          <div className="col account-frames label-grid">
            <Label> Cash Ledgers</Label>
            <Autocomplete
              classes={classes}
              options={CashLedgers.responseType!}
              openOnFocus
              autoHighlight
              // ref={cashAccount ? cashLedgerRef : null}
              value={transactionBookLedgerId}
              onChange={(e, newValue) => {
                if (newValue) {
                  setTransactionLedgerId(newValue as responseType);
                  setReceipts([]);
                } else {
                  setTransactionLedgerId(null);
                  setReceipts([]);
                }
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (transactionBookLedgerId && e.key === Keys.ENTER) {
                  handleMUISelectEvent(e);
                } else if (e.key === Keys.BACKSPACE) {
                  setTransactionLedgerId(null);
                }
              }}
              popupIcon={<img src={DownArrow} alt="/" />}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  onChange={(e) => setsearchData(e.target.value)}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
        {bankAccount ? (
          <div className="col account-frames">
            <div className="student-fee-receipt__receiptblock--frame--bankdetails">
              <Label> Bank Transaction Type</Label>
              <Autocomplete
                classes={classes}
                options={bankTransactionTypes!}
                openOnFocus
                autoHighlight
                value={bankTransactionType}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBankTransactionType(newValue as optionsType);
                  } else {
                    setBankTransactionType(EMPTY_OPTIONSTYPE_OBJECT);
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (ledgerType && e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  } else if (e.key === Keys.BACKSPACE) {
                    setBankTransactionType(EMPTY_OPTIONSTYPE_OBJECT);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
            <div className="student-fee-receipt__receiptblock--frame--bankdetails">
              <Label> Bank Ledgers</Label>
              <Autocomplete
                classes={classes}
                options={BankLedgers.responseType!}
                openOnFocus
                autoHighlight
                value={transactionBookLedgerId}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setTransactionLedgerId(newValue as responseType);
                    setReceipts([]);
                  } else {
                    setTransactionLedgerId(null);
                    setReceipts([]);
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (transactionBookLedgerId && e.key === Keys.ENTER) {
                    transactionRefNumberRef.current?.focus();
                  } else if (e.key === Keys.BACKSPACE) {
                    setTransactionLedgerId(null);
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    onChange={(e) => setsearchData(e.target.value)}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
            <div className="student-fee-receipt__receiptblock--frame--bankdetails">
              <Label>Transaction Reference Number</Label>
              <Input
                inputRef={transactionRefNumberRef}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                }}
                value={referenceNumber}
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setReferenceNumber(e.target.value)
                }
              />
            </div>
          </div>
        ) : null}
        <div className="col account-frames label-grid">
          <Label>Remarks :</Label>
          <TextArea
            textAreaRef={remarksRef}
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === Keys.ENTER) {
                saveButtonRef.current?.focus();
              }
            }}
            rows={3}
          />
        </div>
      </form>

      <div className="refund-academic-fee__btns">
        <Button
          mode="save"
          disabled={!state.studentId && !receipts.length}
          onClick={handleStudentReceipts}
          buttonref={saveButtonRef}
        />

        {/* <Button mode="view" /> */}
        <Button mode="clear" onClick={handleClear} />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button mode="cancel" onClick={() => setModalFlag(false)} />
        )}
      </div>
      {/* student-modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.FEE_ADVANCE}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              onClick={() => setStudentModal(!studentModal)}
              src={Close}
              alt="/"
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <VoucherPrint
              setModalFlag={SetPrintModal}
              handleClear={handleClear}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                SetPrintModal(!printModal);
                handleClear();
              }}
            />
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>

      {/* print configuration modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printConfig}
        style={PrintConfigModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Configurations
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.VOUCHER_PRINT}
              int_value={0}
              setModalFlag={setPrintConfigModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={GenerateStudentReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Refunds;
