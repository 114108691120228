import React, { useEffect, useRef, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import StartPosting from "../../../images/BookRenewal.svg";
import { Label } from "../../../stories/Label/Label";
import { Autocomplete, FormControlLabel, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Warning from "../../../images/Warning.svg";
import Interchange from "../../../images/PostingIcon.svg";
import Modal from "react-modal";
import { AboutChannelModalStyles } from "../../../styles/ModalStyles";
import Close from "../../../images/Close.svg";
import ReorderTable from "./ReorderTable";
import { CustomModalProps } from "../Dashboard/Teacher/Index";
import ViewSection from "./ViewSection";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AcdSemesterPosting,
  AcdYrPosting,
  DeleteAllChannel,
  ReCreateAllChannels,
} from "../queries/test/mutation";
import {
  InstitutionConfigurationTypes,
  Operation,
} from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import dayjs from "dayjs";
import { FinYrType } from "../../Accounts/FinYrPost/FinYrComponent";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import {
  handleMUISelectEvent,
  toInputStandardDate,
  toIsoDate,
} from "../../../utils/UtilFunctions";
import { Keys } from "../../../utils/Enum.keys";
import useToken from "../../../customhooks/useToken";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import ConfirmationModal from "../../Accounts/FinYrPost/ConfirmationModal";
import LoadingModal from "../../../pages/LoadingModal";
import { GetBranchAndClassDetailsForSchoolPosting } from "../queries/posting";
import {
  GetBranchAndClassDetailsForSchoolPostingData,
  GetBranchAndClassDetailsForSchoolPostingVars,
} from "../types/lessonplanner/queries";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import Home from "../../Master/configurations/Home/Index";
import {
  GetSwConfigVariablesData,
  GetSwConfigVariablesVars,
} from "../../../Types/configtypes";
import { GetSwConfigVariables } from "../../../queries/institution/configuration/query/SoftwreConfig";
import { SwConfigQueryType } from "../../HR/enums/Enum.types";
import { AntSwitch } from "../../../pages/Switch";
import { EMPTY_STRING } from "../../../utils/constants";
import DeleteConfirmationModal from "../../Accounts/FinYrPost/DeleteConfirmationModal";
import ReCreateChannelModal from "../../Accounts/FinYrPost/ReCreateChannelsModal";

export const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onRequestClose,
  contentComponent,
}) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      style={AboutChannelModalStyles}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
    >
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">{contentComponent}</div>
        <div className="modal-flex__image">
          <img src={Close} alt="Close" onClick={onRequestClose} />
        </div>
      </div>
    </Modal>
  );
};
const Index = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const [confirmModal, setConfirmModal] = useState(false);
  const acdYearRef = useRef<HTMLSelectElement>(null);
  const subjectInputRef = acdYearRef.current?.childNodes[0].childNodes[0]
    .childNodes[0] as HTMLInputElement;
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const navigate = useNavigate();
  const [reorder, setReorder] = useState(false);
  const [branchId, setBranchId] = useState(0);
  const [view, setView] = useState(false);
  const [selectedAcdYr, setSelectedAcdYr] = useState<FinYrType | null>(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [semPosting, setSemWisePosting] = useState(false);
  const [deleteChannel, setDeleteChannel] = useState(false);
  const [reCreateChannel, setReCreateChannel] = useState(false);

  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const { activeAcademicYearData } = useActiveAcademicYear();

  const [GetBranchAndClassDetails, { data: BranchClassData }] = useLazyQuery<
    GetBranchAndClassDetailsForSchoolPostingData,
    GetBranchAndClassDetailsForSchoolPostingVars
  >(GetBranchAndClassDetailsForSchoolPosting, {
    onError: (e) =>
      setMessage({
        flag: false,
        message: "",
        operation: Operation.NONE,
      }),
  });
  const [GetConfigdata, { data: stsData }] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables, {
    variables: {
      token,
      inst_id: InstId ? InstId : 0,
      input: {
        config_query_type: SwConfigQueryType.BY_CONFIG_KEY,
        str_value: "INSTITUTION_TYPE",
        int_value: 1,
      },
    },
  });
  const instTypeFlag =
    stsData && stsData.GetSwConfigVariables[0].config_string_value;
  const { configData: chronicalOrderFlagData } = useSwConfigData(
    InstitutionConfigurationTypes.CONSIDER_BRANCH_CHRONOLOGICAL_ORDER
  );
  const { configData: chronicalClassFlagData } = useSwConfigData(
    InstitutionConfigurationTypes.CONSIDER_CLASS_CHRONOLOGICAL_ORDER
  );
  const { configData: postingLevel } = useSwConfigData(
    InstitutionConfigurationTypes.ACADEMICS_POSTING_LEVEL
  );

  const chronicalOrderFlag =
    chronicalOrderFlagData &&
    chronicalOrderFlagData.data?.GetSwConfigVariables[0].config_boolean_value;

  const chronicalClassFlag =
    chronicalClassFlagData.data &&
    chronicalClassFlagData.data.GetSwConfigVariables[0].config_boolean_value;

  const [AcdYearPost, { loading }] = useMutation(AcdYrPosting, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [AcdSemWisePost, { loading: SemWiseLoading }] = useMutation(
    AcdSemesterPosting,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteChannels, { loading: channelLoading }] = useMutation(
    DeleteAllChannel,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [ReCreateChannels, { loading: channelReCreateLoading }] = useMutation(
    ReCreateAllChannels,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const HandleChannelsDelete = () => {
    DeleteChannels({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
      },
    }).then(({ data }) => {
      if (data && data.DeleteAllChannel) {
        setMessage({
          flag: true,
          message: "Channels Deleted Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const HandleChannelsCreate = () => {
    ReCreateChannels({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
      },
    }).then(({ data }) => {
      if (data && data.DeleteAllChannel) {
        setMessage({
          flag: true,
          message: "Channels Created Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const upcommingAcademicYears = () => {
    if (
      activeAcademicYearData.data &&
      activeAcademicYearData.data.GetAcdYrActiveByInstId
    ) {
      const currentYearInfo =
        activeAcademicYearData.data.GetAcdYrActiveByInstId;
      const currentYearStart = dayjs(currentYearInfo.acd_st_date);
      const currentYearEnd = dayjs(currentYearInfo.acd_end_date);
      return [...new Array(1).keys()].map((i) => {
        const nextYearStart = currentYearStart.add(i + 1, "year").format();
        const nextYearEnd = currentYearEnd.add(i + 1, "year").format();
        return {
          label: `${dayjs(nextYearStart).format("YYYY")}-${dayjs(
            nextYearEnd
          ).format("YYYY")}`,
          value: {
            startDate: nextYearStart,
            endDate: nextYearEnd,
          },
        };
      });
    } else {
      return [];
    }
  };

  const handleAcdYearPost = () => {
    if (selectedAcdYr === null && !semPosting) {
      setMessage({
        flag: true,
        message: "Please select financial year",
        operation: Operation.NONE,
      });
      return;
    }
    if (activeAcademicYearData.data === undefined) {
      setMessage({
        flag: true,
        message: "Active financial year not found",
        operation: Operation.NONE,
      });
      return;
    }
    if (semPosting) {
      AcdSemWisePost({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
          branch_ids:
            BranchClassData &&
            BranchClassData.GetBranchAndClassDetailsForSchoolPosting.map(
              (branch) => branch.id
            ),
        },
      }).then(({ data }) => {
        if (data && data.AcdYrPosting) {
          setMessage({
            flag: true,
            message: "Successfully posted",
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      AcdYearPost({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          prev_acd_yr_id:
            activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
          input: {
            acd_yr: selectedAcdYr && selectedAcdYr.label,
            acd_st_date: toIsoDate(
              selectedAcdYr ? selectedAcdYr.value.startDate : EMPTY_STRING
            ),
            acd_end_date: toIsoDate(
              selectedAcdYr ? selectedAcdYr.value.endDate : EMPTY_STRING
            ),
          },
          branch_ids:
            BranchClassData &&
            BranchClassData.GetBranchAndClassDetailsForSchoolPosting.map(
              (branch) => branch.id
            ),
        },
      }).then(({ data }) => {
        if (data && data.AcdYrPosting) {
          setMessage({
            flag: true,
            message: "Successfully posted",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  useEffect(() => {
    if (token && InstId!) {
      GetBranchAndClassDetails({
        variables: {
          token,
          inst_id: InstId!,
        },
      });
      GetConfigdata();
    }
  }, [token, InstId, GetBranchAndClassDetails, GetConfigdata]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Academic Data Posting</Title>
      <div className="acd-posting">
        <div className="acd-posting__data">
          <div className="acd-posting__data--left">
            <div className="acd-posting__data--dates-block">
              <div className="acd-posting__data--label-grid">
                <Label>Delete All Channels </Label>
                <FormControlLabel
                  label=""
                  labelPlacement="end"
                  control={
                    <AntSwitch
                      checked={deleteChannel}
                      onClick={() => setDeleteChannel(!deleteChannel)}
                    />
                  }
                  className="monthly-attendance-list__view--form-labels"
                />
                <Label>Re-Create All Channels </Label>
                <FormControlLabel
                  label=""
                  labelPlacement="end"
                  control={
                    <AntSwitch
                      checked={reCreateChannel}
                      onClick={() => setReCreateChannel(!reCreateChannel)}
                    />
                  }
                  className="monthly-attendance-list__view--form-labels"
                />
                <Label>Semester Wise Posting </Label>
                <FormControlLabel
                  label=""
                  labelPlacement="end"
                  control={
                    <AntSwitch
                      checked={semPosting}
                      onClick={() => setSemWisePosting(!semPosting)}
                    />
                  }
                  className="monthly-attendance-list__view--form-labels"
                />
                <Label>Current Academic Year</Label>
                <TextField
                  disabled
                  value={
                    activeAcademicYearData.data &&
                    activeAcademicYearData.data.GetAcdYrActiveByInstId.acd_yr
                  }
                  className="acd-posting__data--textfield"
                />
                {!semPosting && (
                  <>
                    <Label>New Academic Year</Label>
                    <Autocomplete
                      classes={classes}
                      ref={acdYearRef!}
                      options={[...upcommingAcademicYears()]}
                      value={selectedAcdYr}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setSelectedAcdYr(newValue);
                          setStartDate(
                            toInputStandardDate(newValue.value.startDate)
                          );
                          setEndDate(
                            dayjs(newValue.value.startDate)
                              .add(364, "day")
                              .format("YYYY-MM-DD")
                          );
                        } else {
                          setSelectedAcdYr(null);
                        }
                      }}
                      isOptionEqualToValue={(option) =>
                        selectedAcdYr &&
                        option.value.startDate ===
                          selectedAcdYr.value.startDate &&
                        option.value.endDate === selectedAcdYr.value.endDate
                          ? true
                          : false
                      }
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          if (selectedAcdYr) {
                            handleMUISelectEvent(e);
                          }
                        }
                        if (e.key === Keys.BACKSPACE) {
                          setSelectedAcdYr(null);
                        }
                      }}
                      openOnFocus
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          fullWidth
                          autoFocus
                          classes={{
                            root: textClasses.formControlRoot,
                          }}
                        />
                      )}
                    />
                    <Label>Start Academic Year</Label>
                    <TextField
                      type="date"
                      className="acd-posting__data--textfield"
                      value={startDate}
                      disabled
                    />
                    <Label>End Academic Year</Label>
                    <TextField
                      type="date"
                      className="acd-posting__data--textfield"
                      value={endDate}
                      disabled
                    />
                  </>
                )}
              </div>
            </div>

            <div className="acd-posting__data--warning-block">
              <Title variant="subtitle1">
                <img src={Warning} alt="/" />
                Warnings
              </Title>
              <ul>
                <li>
                  1. All Your Current Academic Year Details will Be Carried
                  Forward To New Academic Year.
                </li>
                <li>
                  2. Once Posting has Rolled Out to New Academic Year Charges
                  Like Alteration OR Deletion can not Be Done in Previous
                  Academic Year
                </li>
              </ul>
            </div>
          </div>
          <div className="acd-posting__data--right">
            {chronicalOrderFlag && (
              <ul>
                {BranchClassData &&
                  BranchClassData.GetBranchAndClassDetailsForSchoolPosting.map(
                    (data, branchIndex) => {
                      return (
                        <li>
                          <div
                            key={branchIndex}
                            className="acd-posting__data--right--title"
                          >
                            <div></div>
                            <b>{data.branch_desc}</b>
                            <Button
                              mode="reorder"
                              onClick={() => {
                                setReorder(!reorder);
                                setBranchId(data.id);
                              }}
                            />
                          </div>

                          {data.class_details.map((li, classIndex) => {
                            return (
                              <li
                                key={classIndex}
                                className="acd-posting__data--right--sublist"
                              >
                                <span>{li.class_desc}</span>
                                <img src={Interchange} alt="/" />
                                {data.class_details[classIndex + 1] ? (
                                  <div className="acd-posting__data--right--moving-sec">
                                    <span>
                                      {
                                        data.class_details[classIndex + 1]
                                          .class_desc
                                      }
                                    </span>
                                    {/* <button onClick={() => setView(!view)}>
                                      View Section
                                    </button> */}
                                  </div>
                                ) : BranchClassData
                                    .GetBranchAndClassDetailsForSchoolPosting[
                                    branchIndex + 1
                                  ] &&
                                  BranchClassData
                                    .GetBranchAndClassDetailsForSchoolPosting[
                                    branchIndex + 1
                                  ].class_details &&
                                  BranchClassData
                                    .GetBranchAndClassDetailsForSchoolPosting[
                                    branchIndex + 1
                                  ].class_details.length ? (
                                  <div className="acd-posting__data--right--moving-sec">
                                    <span>
                                      {
                                        BranchClassData
                                          .GetBranchAndClassDetailsForSchoolPosting[
                                          branchIndex + 1
                                        ].class_details[0].class_desc
                                      }
                                    </span>
                                  </div>
                                ) : (
                                  <div className="acd-posting__data--right--moving-sec">
                                    <b>PassedOut </b>
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        </li>
                      );
                    }
                  )}
              </ul>
            )}
            {chronicalClassFlag && instTypeFlag && (
              <ul>
                {BranchClassData &&
                  BranchClassData.GetBranchAndClassDetailsForSchoolPosting.map(
                    (data) =>
                      data.class_details.map((res, classIndex) => {
                        return (
                          <li>
                            <div
                              key={classIndex}
                              className="acd-posting__data--right--title"
                            >
                              <div></div>
                              <b>{res.class_desc}</b>
                              <Button
                                mode="reorder"
                                onClick={() => {
                                  setReorder(!reorder);
                                  setBranchId(data.id);
                                }}
                              />
                            </div>
                            {res.semester_details.map((li, semIndex) => {
                              return (
                                <li
                                  key={semIndex}
                                  className="acd-posting__data--right--sublist"
                                >
                                  <span>{li.semester_desc}</span>
                                  <img src={Interchange} alt="/" />
                                  {res.semester_details[semIndex + 1] ? (
                                    <div className="acd-posting__data--right--moving-sec">
                                      <span>
                                        {
                                          res.semester_details[semIndex + 1]
                                            .semester_desc
                                        }
                                      </span>
                                      {/* <button onClick={() => setView(!view)}>
                                      View Section
                                    </button> */}
                                    </div>
                                  ) : data.class_details[classIndex + 1] &&
                                    data.class_details[classIndex + 1]
                                      .semester_details &&
                                    data.class_details[classIndex + 1]
                                      .semester_details.length ? (
                                    <div className="acd-posting__data--right--moving-sec">
                                      <span>
                                        {
                                          data.class_details[classIndex + 1]
                                            .semester_details[0].semester_desc
                                        }
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="acd-posting__data--right--moving-sec">
                                      <b>PassedOut </b>
                                    </div>
                                  )}
                                </li>
                              );
                            })}
                          </li>
                        );
                      })
                  )}
              </ul>
            )}
            {!chronicalOrderFlag && !chronicalClassFlag && (
              <ul>
                {BranchClassData &&
                  BranchClassData.GetBranchAndClassDetailsForSchoolPosting.map(
                    (data, index) => {
                      return (
                        <li>
                          <div
                            key={index}
                            className="acd-posting__data--right--title"
                          >
                            <b>{data.branch_desc}</b>
                            <Button
                              mode="reorder"
                              onClick={() => {
                                setReorder(!reorder);
                                setBranchId(data.id);
                              }}
                            />
                          </div>

                          {data.class_details.map((li, index) => {
                            return (
                              <li
                                key={index}
                                className="acd-posting__data--right--sublist"
                              >
                                <span>{li.class_desc}</span>
                                <img src={Interchange} alt="/" />
                                {data.class_details[index + 1] ? (
                                  <div className="acd-posting__data--right--moving-sec">
                                    <span>
                                      {data.class_details[index + 1].class_desc}
                                    </span>
                                    {/* <button onClick={() => setView(!view)}>
                                      View Section
                                    </button> */}
                                  </div>
                                ) : (
                                  <div className="acd-posting__data--right--moving-sec">
                                    <b>Passout</b>
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        </li>
                      );
                    }
                  )}
              </ul>
            )}
          </div>
        </div>
        <Button
          onClick={(e: React.FormEvent) => {
            e.preventDefault();
            if (!selectedAcdYr && !semPosting) {
              subjectInputRef?.focus();
            } else setConfirmModal(!confirmModal);
          }}
          disabled={selectedAcdYr?.value || semPosting ? false : true}
        >
          <img src={StartPosting} alt="/" />
          Start Posting
        </Button>
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <ConfirmationModal
        handleFinYearPost={handleAcdYearPost}
        isOpen={confirmModal}
        setOpenModal={setConfirmModal}
      />
      <DeleteConfirmationModal
        handleFinYearPost={HandleChannelsDelete}
        isOpen={deleteChannel}
        setOpenModal={setDeleteChannel}
      />
      <ReCreateChannelModal
        handleFinYearPost={HandleChannelsCreate}
        isOpen={reCreateChannel}
        setOpenModal={setReCreateChannel}
      />
      <CustomModal
        isOpen={reorder}
        onRequestClose={() => setReorder(!reorder)}
        contentComponent={
          <ReorderTable setModal={setReorder} branchId={branchId} />
        }
      />
      <CustomModal
        isOpen={view}
        onRequestClose={() => setView(!view)}
        contentComponent={
          <ViewSection setModal={setView} branchId={branchId} />
        }
      />
      <MessageModal
        handleClose={() => {
          setMessage({
            flag: false,
            message: "",
            operation: Operation.NONE,
          });
          setConfirmModal(!confirmModal);
          setDeleteChannel(false);
          setReCreateChannel(false);
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal
        flag={
          loading || SemWiseLoading || channelLoading || channelReCreateLoading
        }
      />
    </>
  );
};

export default Index;
