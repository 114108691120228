import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import {
  DebitOrCredit,
  ReceiptTypes,
  PageFor,
  VoucherBookKey,
  BankOrCash,
  Direction,
  SortBy,
  Operation,
  InstitutionConfigurationTypes,
  ModuleName,
  UserType,
} from "../../../../utils/Enum.types";
import Print from "../../../Print/Accounts/FeeReceipts/Standard/FeeReceiptPrint";

import { ChallannodeData, nodevars } from "../../../../Types/Accounting/index";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  DateRange,
  formatter,
  NameOfTheDay,
  toggleFullSceen,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import {
  EMPTY_STRING,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";
import { AddAcctVoucherMasterAndDetails } from "../../queries/receipts/mutation/Index";

import { AccountsTableHeaderProps } from "../../../../Types/Tables";

import { AppContext } from "../../../../context/context";
import Home from "../../Home/Index";

import LoadingModal from "../../../../pages/LoadingModal";
import Enlarge from "../../../../images/Enlarge.svg";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import Avatar from "../../../../images/Avatar.svg";
import Settings from "../../../../images/Settings.svg";

import { msgType, optionsType } from "../../../../utils/Form.types";
import Close from "../../../../images/Close.svg";

import { payloadTypes } from "../../../../context/reducer";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import { GetAcctChallans } from "../../queries/Vouchers/query/index";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import useVoucherNumber from "../../hooks/useVoucherNumber";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { DepositLedgerQueryType } from "../../common/QueryTypes";
import { GetAcctStdDeposits } from "../../queries/FeeLedgers/query/Byid";
import { GetStdCompleteFeeDetailsByStudentID } from "../../../../queries/students/list/byId";
import {
  ChallanQueryTypes,
  InstitutionAcctConfigurationTypes,
} from "../../common/Enum.types";
import TextArea from "../../../../stories/TextArea/TextArea";
import useAcctTableJson from "../../json/useAcctTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import Configurations from "../../../Configurations/PerModuleConfiguration";
import Modal from "react-modal";

import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import useEnquiryStudentDetails from "../../../../customhooks/useEnquiryStudentDetails";
import { StudentDetailsByChallanId } from "../../../../queries/common";
import { Keys } from "../../../../utils/Enum.keys";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { Data } from "../../../Print/Accounts/FeeReceipts/Standard/Index";
import { SwConfigQueryType } from "../../../HR/enums/Enum.types";
import {
  PrintConfigModalStyles,
  PrintModalStyles,
} from "../../../../styles/ModalStyles";
import {
  AddAcctVoucherMasterAndDetailsData,
  AddAcctVoucherMasterAndDetailsVars,
} from "../../../../Types/Accounting/mutations";
import useAssignedInstbyEmpId, {
  InstUserAccessQueryType,
} from "../../../UserRights/hooks/useAssignedInstbyEmpId";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
export enum PayerType {
  STUDENT = "STUDENT",
  ACCOUNTING = "ACCOUNTING",
}
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  payer: PayerType;
  isDemand: boolean;
  challanId: number;
  challanNodeId: number;
}
export interface recepitsw {
  acct_ldgr_id: number;
}

export interface TotalInterface {
  sum: number;
  vo_key: string;
  vo_number: string;
}
export type GroupsWithSum = Record<number, TotalInterface>;
export const ledgerOptions: optionsType[] = [
  { label: "Cash Book", value: BankOrCash.CASH },
  { label: "Bank Book", value: BankOrCash.BANK },
];
const ChallanReconcileModal = ({
  pageType,
  setModalFlag,
  payer,
  isDemand,
  challanId,
  challanNodeId,
}: Props) => {
  const { format } = formatter;
  let DefaultDate = new Date();
  const transactionRefNumberRef = useRef<HTMLInputElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  const { InstId, studentId } = useParams<{
    InstId: string;
    studentId: string;
  }>();
  const { token } = useToken();
  const { dispatch, state } = useContext(AppContext);
  const {
    enquiryStudentData: { enquiryFormData },
  } = useEnquiryStudentDetails();
  const navigate = useNavigate();
  const { Accounts_Table } = useAcctTableJson();
  const dates = DateRange(DefaultDate.toString());
  const [printModal, SetPrintModal] = useState(false);
  const [tableData, setTableData] = useState<Data[]>([]);
  const [v_no, set_vo_no] = useState("");
  const [v_date, set_v_Date] = useState("");
  const { configData } = useSwConfigData([
    InstitutionConfigurationTypes.CHALLAN_RECONCILIATION_WITHIN_DAYS,
  ]);
  const [prinConfigModal, setPrintConfigModal] = useState(false);

  const { configData: printConfig } = useSwConfigData([
    InstitutionConfigurationTypes.ENABLE_PRINT_STD_FEE_RECEIPT_AFTER_CHALLAN_RECONCILE,
  ]);
  const enablePrintFlag =
    printConfig.data &&
    printConfig.data.GetSwConfigVariables[0].config_boolean_value;
  const { configData: configKeys } = useSwConfigData([
    InstitutionAcctConfigurationTypes.STD_RECEIPT_EDIT_DATE,
  ]);
  const [rcptDate, setRcptDate] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [imageString, setImageString] = useState("");
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const [GetStudentDetails, { data: NodeData }] = useLazyQuery<
    ChallannodeData,
    nodevars
  >(StudentDetailsByChallanId);

  useEffect(() => {
    if (token && challanNodeId) {
      GetStudentDetails({
        variables: {
          token,
          ids: [challanNodeId],
        },
      });
    } // eslint-disable-next-line
  }, [GetStudentDetails, challanNodeId, token]);

  const { user_details } = useLoggedInUserDetails();

  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();
  const { InstDetails } = useInstDetails(1);
  const { studentData, studentFormData, studentPassedOutFormData } =
    useStudentDatabyId();

  const {
    data: serverDateData,
    loading: serverDateLoading,
    serverDate,
  } = useServerDateandTime();

  const { voucherNumber } = useVoucherNumber(
    VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK
  );

  //mutations
  const [GenerateStudentReceipts, { loading: GenerateStudentReceiptsLoading }] =
    useMutation<
      AddAcctVoucherMasterAndDetailsData,
      AddAcctVoucherMasterAndDetailsVars
    >(AddAcctVoucherMasterAndDetails, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });

  const handleStudentReceipts = () => {
    if (referenceNumber.length === 0) {
      transactionRefNumberRef?.current?.focus();
    } else {
      GenerateStudentReceipts({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          input: [
            {
              acct_voucher_master: {
                fin_yr: state.ActiveFinYr
                  ? state.ActiveFinYr.fin_yr
                  : EMPTY_STRING,
                v_type: ReceiptTypes.STUDENT_RECEIPT,
                v_no: voucherNumber.data?.GetVoucherNumber.vo_number!,
                v_date: toIsoDate(rcptDate),
                v_total_cr_amt:
                  NodeData?.nodes[0].acct_challan_details[0].vo_cr_total,
                v_total_db_amt:
                  NodeData?.nodes[0].acct_challan_details[0].vo_cr_total, // Should this be vo_db_total?
                v_reconciled: false,
                v_std_receipt_ob: false,
                v_reconciled_date: toIsoDate(rcptDate),
                v_std_receipt: state.stdIdForChallan ? true : false,
                v_std_non_demand_receipt:
                  state.stdIdForChallan && isDemand
                    ? false
                    : state.stdIdForChallan && !isDemand
                    ? true
                    : false,
                v_std_anonymous_deposit_adjusted: false,
                challan_master_id: challanId,
                v_std_refund: false,
                v_std_enquiry: state.enqStdIdForChallan ? true : false,
                enquiry_student_id: state.enqStdIdForChallan
                  ? state.enqStdIdForChallan
                  : 0,
                v_std_deposit_adjusted: false,
                v_book_type: VoucherBookKey.STUDENT_COMMAN_RECEIPT_BOOK,
                v_std_deposit: false,
                v_std_passout_receipt: state.passOutStdIdForChallan
                  ? true
                  : false,
                v_std_demand_receipt:
                  state.stdIdForChallan && isDemand
                    ? true
                    : state.stdIdForChallan && !isDemand
                    ? false
                    : true,
                v_std_scholarship_deposit: false,
                passout_student_id: state.passOutStdIdForChallan
                  ? state.passOutStdIdForChallan
                  : 0,
                v_std_receipt_anonymous: false,
                student_id: state.stdIdForChallan ? state.stdIdForChallan : 0,
                v_std_refund_deposit: false,
                class_id: state.stdIdForChallan
                  ? studentData.data?.nodes[0].class.id
                  : 0,
                semester_id: state.stdIdForChallan
                  ? studentData.data?.nodes[0].semester.id
                  : 0,
                v_std_amt_receipt:
                  NodeData?.nodes[0].acct_challan_details[0].vo_cr_total,
                v_std_amt_deposit: 0,
                v_std_amt_fine: 0,
                v_std_amt_total:
                  NodeData?.nodes[0].acct_challan_details[0].vo_cr_total,
                v_std_amt_refunded: 0,
                v_std_amt_adjusted: 0,
                v_transcation_type: "Bank Book",
                v_transcation_cash_or_bank: BankOrCash.BANK,
                v_transcation_no: referenceNumber,
                v_transcation_date: NodeData?.nodes[0].v_transcation_date
                  ? toIsoDate(NodeData?.nodes[0].v_transcation_date)
                  : EMPTY_STRING,
                v_transcation_narration:
                  NodeData?.nodes[0].v_transcation_narration,
                annx_yesno: false,
                is_vouch_multi_entry: false,
                acct_ldgr_id_db: NodeData?.nodes[0].acct_ldgr_db_details.id,
                acct_ldgr_id_cr:
                  NodeData?.nodes[0].acct_challan_details[0].acct_ldgr_id,
              },
              acct_voucher_db: NodeData?.nodes.flatMap((item) =>
                item.acct_challan_details
                  .filter((data) => data.vo_cr === 0)
                  .map((res, index) => ({
                    vo_cr_db: DebitOrCredit.DEBIT,
                    vo_sl_no: index + 1,
                    vo_cr: 0,
                    vo_db: Number(res.vo_db),
                    vo_cr_total: 0,
                    vo_db_total: res.vo_db_total,
                    acct_ldgr_id: res.acct_ldgr_id,
                  }))
              ),
              acct_voucher_cr: NodeData?.nodes.flatMap((item) =>
                item.acct_challan_details
                  .filter((data) => data.vo_cr !== 0)
                  .map((res, index) => ({
                    vo_sl_no: index + 1,
                    vo_cr_db: DebitOrCredit.CREDIT,
                    vo_cr: res.vo_cr,
                    vo_db: 0,
                    vo_cr_total: res.vo_cr_total,
                    vo_db_total: 0,
                    acct_ldgr_id: res.acct_ldgr_id,
                  }))
              ),
            },
          ],
        },
        refetchQueries: [
          {
            query: GetAcctChallans,
            variables: {
              token,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              after: null,
              first: ROWS_PER_PAGE,
              sortBy: SortBy.V_DATE,
              inst_id: InstId!,
              direction: Direction.ASC,
              input: {
                challan_query_type: ChallanQueryTypes.ALL_CHALLAN,
                inst_id: InstId!,
                start_date: toIsoDate(TODAY_DATE),
                end_date: toIsoDate(TODAY_DATE),
                student_id: 0,
                acct_ldgr_id: 0,
                user_details,
              },
              ldgrDesc: EMPTY_STRING,
              amount: null,
              v_no: EMPTY_STRING,
              vTranscationNo: EMPTY_STRING,
            },
          },
          {
            query: GetAcctStdDeposits,
            variables: {
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              query_type: DepositLedgerQueryType.DEPOSIT_GT_0_BY_STD_ID,
              student_id: state.studentId,
              token,
            },
          },
          {
            query: GetStdCompleteFeeDetailsByStudentID,
            variables: {
              token,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              inst_id: InstId!,
              student_id: state.studentId,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          const { v_no, v_date, id } = data.AddAcctVoucherMasterAndDetails[0];
          dispatch({
            type: payloadTypes.SET_RECEPIT_ID,
            payload: { receiptId: id },
          });

          if (NodeData && NodeData.nodes) {
            setTableData(
              NodeData.nodes.flatMap((items) =>
                items.acct_challan_details.map((res) => ({
                  particular: res.acct_ldgr_details.ldgr_desc,
                  amount: res.vo_cr,
                }))
              )
            );
          } else {
            setTableData([]);
          }

          set_v_Date(v_date);
          set_vo_no(v_no);
          enablePrintFlag && SetPrintModal(!printModal);
          setMessage({
            message: `Successfully generated fee receipt for student ${
              state.passOutStdIdForChallan
                ? studentPassedOutFormData.std_name
                : studentData.data?.nodes[0].first_name
            }`,
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (message.operation !== Operation.NONE && message.flag) {
      setModalFlag(false);
      setReferenceNumber(EMPTY_STRING);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== EMPTY_STRING &&
      InstDetails.data
    ) {
      const studentProfiePicUrl = `${
        InstDetails.data?.nodes[0]?.inst_name
      }/students/${
        state.studentId ? state.studentId : studentId
      }/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    } else {
      setImageString("");
    }
  }, [studentData.data, InstDetails.data, state.studentId]);

  useEffect(() => {
    if (serverDateData && !serverDateLoading) {
      setRcptDate(serverDateData.GetServerDateAndTime);
    }
  }, [serverDateData, serverDateLoading]);

  const { branchLabel, classLabel,categoryLabel } = useInstLabels();

  const { MultipleInstitutions } = useAssignedInstbyEmpId(
    InstUserAccessQueryType.USER_ACCESS_BY_EMP_ID,
    state.empLoginId
  );
  const isAdmin =
    MultipleInstitutions?.data?.GetInstUserAccess.edges[0]?.node?.pr_emp_details
      .emp_type === UserType.SYS_ADMIN;

  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}

      <div className="row g-0">
        <div className="col">
          <Title>Student Fee Receipt</Title>
        </div>

        <div className="configuration-settings">
          <img src={Enlarge} alt="/" onClick={() => toggleFullSceen()} />
        </div>
      </div>

      <div
        className={
          pageType === PageFor.GENERAL
            ? "student-fee-receipt"
            : "student-fee-receipt--modal"
        }
      >
        <div className="student-fee-receipt__details">
          <div
            className={
              payer === PayerType.ACCOUNTING
                ? "row g-0 student-fee-receipt__details--select-options"
                : "row g-0"
            }
          >
            <div className="col student-fee-receipt__frame">
              {!state.enqStdIdForChallan && !state.passOutStdIdForChallan && (
                <div className="student-fee-receipt__frame--imagefield">
                  <TextField
                    label=" Admission Number"
                    className="student-fee-receipt__frame--textfield"
                    InputLabelProps={{ shrink: true }}
                    value={
                      studentData.data?.nodes[0].std_adm_no ?? EMPTY_STRING
                    }
                    disabled
                  />
                </div>
              )}

              {!state.enqStdIdForChallan && !state.passOutStdIdForChallan && (
                <TextField
                  label="Reg No."
                  className="student-fee-receipt__frame--textfield"
                  InputLabelProps={{ shrink: true }}
                  value={studentFormData.reg_number}
                  disabled
                />
              )}
              <TextField
                label={branchLabel}
                className="student-fee-receipt__frame--textfield"
                InputLabelProps={{ shrink: true }}
                value={
                  state.enqStdIdForChallan
                    ? enquiryFormData.seekingBranch
                    : state.passOutStdIdForChallan
                    ? studentPassedOutFormData.branch
                    : studentFormData.branch
                }
                disabled
              />
            </div>

            <div className="col student-fee-receipt__frame g-0">
              {payer === PayerType.ACCOUNTING && (
                <>
                  <TextField
                    label="Name"
                    className="student-fee-receipt__frame--textfield"
                    InputLabelProps={{ shrink: true }}
                    value={
                      state.enqStdIdForChallan
                        ? enquiryFormData.enq_student_name
                        : state.passOutStdIdForChallan
                        ? studentPassedOutFormData.std_name
                        : studentFormData?.std_name
                    }
                    disabled
                  />
                  {!state.passOutStdIdForChallan && (
                    <TextField
                      label="Father Name"
                      className="student-fee-receipt__frame--textfield"
                      InputLabelProps={{ shrink: true }}
                      value={
                        state.enqStdIdForChallan
                          ? enquiryFormData.std_father_name
                          : studentFormData?.father_name
                      }
                      disabled
                    />
                  )}

                  <TextField
                    label={classLabel}
                    className="student-fee-receipt__frame--textfield"
                    InputLabelProps={{ shrink: true }}
                    value={
                      state.enqStdIdForChallan
                        ? enquiryFormData.seekingClass
                        : state.passOutStdIdForChallan
                        ? studentPassedOutFormData.class
                        : studentFormData?.class
                    }
                    disabled
                  />
                </>
              )}
            </div>
            <div
              className={
                payer === PayerType.STUDENT
                  ? "col g-0"
                  : "col student-fee-receipt__frame g-0"
              }
            >
              {payer === PayerType.ACCOUNTING && (
                <>
                  <TextField
                    type="date"
                    label="Date"
                    className="student-fee-receipt__frame--textfield--date"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputProps: {
                        min: state.ActiveFinYr
                          ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                          : EMPTY_STRING,
                        max: toInputStandardDate(serverDate),
                      },
                    }}
                    value={toInputStandardDate(rcptDate)}
                    onChange={(e) => setRcptDate(e.target.value)}
                    disabled={
                      configKeys.data?.GetSwConfigVariables[0]
                        .config_boolean_value
                        ? false
                        : true
                    }
                  />
                  <Label variant="present-day">{NameOfTheDay(rcptDate)}</Label>

                  {USE_CATEGORY_KEY && state.stdIdForChallan && (
                    <TextField
                       label={categoryLabel}
                      className="student-fee-receipt__frame--textfield"
                      InputLabelProps={{ shrink: true }}
                      value={studentFormData.category}
                      disabled
                    />
                  )}
                </>
              )}
            </div>
            {payer === PayerType.ACCOUNTING ? (
              <div className="col-1 student-fee-receipt__frame--image h-100">
                {imageString === EMPTY_STRING ? (
                  <img src={Avatar} alt="/" />
                ) : (
                  <img src={imageString} alt="/" />
                )}
              </div>
            ) : null}
          </div>

          <div
            className={
              pageType === PageFor.GENERAL
                ? "row g-0 student-fee-receipt__tableblock"
                : payer === PayerType.STUDENT
                ? "row g-0 student-fee-receipt__tableblock--std-dashboard"
                : "row g-0 student-fee-receipt__tableblock--modal"
            }
          >
            <TableContainer className="student-fee-receipt__table g-0">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Accounts_Table.Receipts.StudentFeeReceipt.Table_Headers_Challan.map(
                      (th: AccountsTableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName === "Receivable" &&
                            payer === PayerType.STUDENT
                              ? "Payable"
                              : th.labelName === "Received" &&
                                payer === PayerType.STUDENT
                              ? "Paid"
                              : th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {NodeData?.nodes.map((item, index) => (
                    <React.Fragment key={index}>
                      {item.acct_challan_details
                        .filter((data) => data.vo_cr !== 0)
                        .map((res, indexes) => (
                          <TableRow key={indexes}>
                            <TableCell
                              className="student-fee-receipt__table--slNo"
                              align="center"
                            >
                              {indexes + 1}
                            </TableCell>
                            <TableCell className="student-fee-receipt__table--desc">
                              {res.acct_ldgr_details.ldgr_desc}
                            </TableCell>

                            <TableCell
                              id="td-right"
                              className="student-fee-receipt__table--amount"
                            >
                              {format(res.vo_cr)}
                            </TableCell>
                          </TableRow>
                        ))}
                    </React.Fragment>
                  ))}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={2} className="total">
                      Academic Fee Total :
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="totalcount student-fee-receipt__table__borderbottom"
                    >
                      {NodeData &&
                        NodeData.nodes[0].acct_challan_details[0].vo_cr_total}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
          {payer === PayerType.ACCOUNTING && (
            <form
              className={
                pageType === PageFor.GENERAL
                  ? "row g-0 student-fee-receipt__receiptblock"
                  : "row g-0 student-fee-receipt__receiptblock--modal"
              }
            >
              <div className="col student-fee-receipt__receiptblock--frame">
                <div className="label-grid">
                  <Label>Challan No.</Label>
                  <Input disabled value={NodeData && NodeData.nodes[0].v_no} />
                  <Label>Receipt No.</Label>
                  <Input
                    disabled
                    value={
                      voucherNumber.data?.GetVoucherNumber.vo_number ||
                      EMPTY_STRING
                    }
                  />
                  <Label> Mode of Transaction</Label>
                  <Input
                    value={NodeData && NodeData.nodes[0].v_transcation_type}
                    disabled
                  />
                </div>
              </div>

              <div className="col student-fee-receipt__receiptblock--frame">
                <div className="student-fee-receipt__receiptblock--frame--bankdetails">
                  <Label>Bank Transaction Type</Label>
                  <Input disabled value={"Challan"} />
                </div>
                <div className="student-fee-receipt__receiptblock--frame--bankdetails">
                  <Label> Bank Ledgers</Label>
                  <Input
                    value={NodeData?.nodes[0].acct_ldgr_db_details.ldgr_desc}
                    disabled
                  />
                </div>
                <div className="student-fee-receipt__receiptblock--frame--bankdetails">
                  <Label>Transaction Reference Number</Label>
                  <Input
                    inputRef={transactionRefNumberRef}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER) {
                        saveButtonRef?.current?.focus();
                      }
                    }}
                    value={referenceNumber}
                    autoFocus
                    required
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setReferenceNumber(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col student-fee-receipt__receiptblock--frame label-grid">
                <Label>Remarks :</Label>
                <TextArea
                  rows={3}
                  value={NodeData?.nodes[0].v_transcation_narration}
                  disabled
                />
              </div>
            </form>
          )}
        </div>
      </div>
      <div
        className={
          pageType === PageFor.GENERAL
            ? "row g-0 student-fee-receipt__buttons"
            : "row g-0 student-fee-receipt__buttons--modal"
        }
      >
        <div className="col">
          {payer === PayerType.ACCOUNTING && (
            <>
              <Button
                mode="save"
                onClick={handleStudentReceipts}
                buttonref={saveButtonRef!}
              />

              {pageType === PageFor.GENERAL ? (
                <Button mode="back" onClick={() => navigate(-1)} />
              ) : (
                <Button
                  mode="cancel"
                  type="button"
                  onClick={() => {
                    setModalFlag(false);
                    dispatch({
                      type: payloadTypes.SET_STD_ID_FOR_CHALLAN,
                      payload: {
                        stdIdForChallan: 0,
                      },
                    });
                    dispatch({
                      type: payloadTypes.SET_ENQ_STD_ID_FOR_CHALLAN,
                      payload: {
                        enqStdIdForChallan: 0,
                      },
                    });
                    dispatch({
                      type: payloadTypes.SET_PASSOUT_STD_ID_FOR_CHALLAN,
                      payload: {
                        passOutStdIdForChallan: 0,
                      },
                    });
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Print
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              transaction_no={referenceNumber}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                SetPrintModal(!printModal);
                setModalFlag(false);
              }}
            />
            {isAdmin ? (
              <>
                <img
                  src={Settings}
                  alt="/"
                  id="settings-icon"
                  onClick={() => setPrintConfigModal(!prinConfigModal)}
                />
              </>
            ) : null}
          </div>
        </div>
      </Modal>

      {/* print configuration modal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={prinConfigModal}
        style={PrintConfigModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Configurations
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.RECEIPT_PRINT}
              int_value={0}
              setModalFlag={setPrintConfigModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfigModal(!prinConfigModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={GenerateStudentReceiptsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default ChallanReconcileModal;
