import React, { useContext, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GetAcctDemandMainMappedTreeByInstId } from "../queries/Accountingledgers/List/ById";
import {
  CloseSquare,
  MinusSquare,
  PlusSquare,
  StyledTreeItem,
} from "../../../pages/TreeView";
import { AppContext } from "../../../context/context";

import { useParams } from "react-router-dom";

import useToken from "../../../customhooks/useToken";
import { payloadTypes } from "../../../context/reducer";
import { TreeView } from "@mui/x-tree-view/TreeView";

const FeedemandTreeView = () => {
  const { InstId } = useParams<{ InstId: string }>();
  // eslint-disable-next-line
  const { dispatch, state } = useContext(AppContext);

  const { token } = useToken();

  const [GetAcctDemandTree, { data, error }] = useLazyQuery(
    GetAcctDemandMainMappedTreeByInstId,
    {
      variables: {
        token,
        inst_id: InstId,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
      },
    }
  );

  useEffect(() => {
    if (token && InstId && state.ActiveFinYr) {
      GetAcctDemandTree();
    }
  }, [token, InstId, state.ActiveFinYr, GetAcctDemandTree]);

  return (
    <div className="tree-view">
      <TreeView
        className="departmenttree-view"
        defaultExpanded={["1"]}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
      >
        {data
          ? data.GetAcctDemandMainMappedTreeByInstId.map(
              (branch: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <StyledTreeItem
                      onClick={() => {
                        dispatch({
                          type: payloadTypes.SET_ACCOUNT_MAIN_ID,
                          payload: {
                            account_main_id: 0,
                          },
                        });
                      }}
                      nodeId={branch.branch_id.toString()}
                      label={branch.branch_desc}
                    >
                      {branch?.class_map?.map(
                        (classData: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <StyledTreeItem
                                onClick={() => {
                                  dispatch({
                                    type: payloadTypes.SET_ACCOUNT_MAIN_ID,
                                    payload: {
                                      account_main_id: 0,
                                    },
                                  });
                                }}
                                nodeId={classData.class_id.toString()}
                                label={classData.class_desc}
                              >
                                {classData.category_map.map(
                                  (categoryData: any, index: number) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <StyledTreeItem
                                          onClick={() => {
                                            dispatch({
                                              type: payloadTypes.SET_ACCOUNT_MAIN_ID,
                                              payload: {
                                                account_main_id:
                                                  categoryData.acct_demand_main_id,
                                              },
                                            });
                                          }}
                                          nodeId={categoryData.acct_demand_main_id.toString()}
                                          label={categoryData.cat_desc}
                                        />
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </StyledTreeItem>
                            </React.Fragment>
                          );
                        }
                      )}
                    </StyledTreeItem>
                  </React.Fragment>
                );
              }
            )
          : null}
      </TreeView>
    </div>
  );
};

export default FeedemandTreeView;
