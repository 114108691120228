import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import StudentsConfiguration, {
  StudentSwConfigType,
} from "../../../Eduate/configurations/StudentConfiguration/Index";

import { Button } from "../../../../stories/Button/Button";
import { Cell } from "exceljs";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import ExcelJS from "exceljs";

import { Autocomplete, Checkbox, TextField } from "@mui/material";

import Input from "../../../../stories/Input/Input";
import Modal from "react-modal";
import {
  StudentListFields,
  TableHeaders,
  StudentReportType,
  StudentListFor,
  Operation,
  ExcelFont,
  ExcelAlignment,
  ExcelPageHeader,
  YesNo,
  PageFor,
  PredefinedDataTypes,
} from "../../../../utils/Enum.types";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";

import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";

import {
  StudentModalStyles,
  columnStyles,
} from "../../../../styles/ModalStyles";
import useStudentsbyNewApi, {
  StudentEdges,
  StudentNode,
} from "../../../../customhooks/useStudentsbyNewApi";

import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import useDropdownData from "../../../../customhooks/useDropdownData";
import useToken from "../../../../customhooks/useToken";
import { Keys } from "../../../../utils/Enum.keys";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../utils/Form.types";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import MessageModal from "../../../../pages/MessageModal";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  D4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  F4_CELL,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FROZEN_CELLS,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import Close from "../../../../images/Close.svg";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import LegendsInStudentList from "../Components/LegendsInStudentList";
import StudentTotalCount from "../Components/StudentTotalCount";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { AppContext } from "../../../../context/context";
import { FetchStudentStatusClassName, columnsType } from "../List";
interface Props {
  studentListFor: StudentListFor;
  queryType: StudentReportType;
}

const StudentExport = ({ queryType }: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const isSelected = (name: string) => studentListOptions.indexOf(name) !== -1;
  const navigate = useNavigate();
  const { token } = useToken();
  const { serverDate } = useServerDateandTime();
  const { InstDetails } = useInstDetails(1);

  const { state } = useContext(AppContext);
  const InstName = InstDetails.data?.nodes[0]?.inst_name;
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const [searchData, setSearchData] = useState("");
  const [searchText, setSearchText] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [studentSwDataForModal, setStudentSwDataForModal] = useState<
    StudentSwConfigType[]
  >([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [statusSelected, setStatusSelected] = useState<optionsType | null>(
    null
  );
  const {
    PredefinedData: { dropDown: STUDENT_STATUS },
  } = usePredefinedDataByType(PredefinedDataTypes.STUDENT_STATUS, EMPTY_STRING);
  var [studentListOptions, setStudentListOptions] = useState<readonly string[]>(
    [
      StudentListFields.STD_ADMISSION_NO,
      StudentListFields.STD_REG_NO,
      StudentListFields.STD_NAME,
      StudentListFields.STD_FATHER_NAME,
      StudentListFields.BRANCH_ID,
      StudentListFields.CATEGORY_ID,
      StudentListFields.STD_STATUS,
    ]
  );

  //states for modal  screens
  const [columnsModal, setColumnsModal] = useState(false);
  const [stdConfig, setStdConfig] = useState(false);
  const [columnsList, setColumnsList] = useState<columnsType[]>([]);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { StudentsData } = useStudentsbyNewApi(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    statusSelected ? statusSelected.value : EMPTY_STRING,
    searchData,
    rowsPerPage,
    queryType
  );

  const { data, fetchMore, loading } = StudentsData;
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel
  } = useInstLabels();

  const { categoryDropDown } = useInstMasterDataByInstId();

  //Categories

  if (USE_CATEGORY_KEY === false) {
    studentListOptions = studentListOptions?.filter(
      (response) => response !== StudentListFields.CATEGORY_ID
    );
  }
  if (USE_CLASS_KEY === false) {
    studentListOptions = studentListOptions?.filter(
      (response) => response !== StudentListFields.CLASS_ID
    );
  }
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const getHeaderUseInSheetStatus = (headerKey: string) => {
    const configEntry = studentSwDataForModal.find(
      (entry) => entry.data_field_name === headerKey
    );
    if (configEntry) {
      return configEntry.data_field_enable;
    }
    return false;
  };
  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setCategorySelected(null);
    setSearchData("");
  };
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = studentListOptions.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(studentListOptions, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(studentListOptions.slice(1));
    } else if (selectedIndex === studentListOptions.length - 1) {
      newSelected = newSelected.concat(studentListOptions.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        studentListOptions.slice(0, selectedIndex),
        studentListOptions.slice(selectedIndex + 1)
      );
    }
    if (newSelected.length <= 10) setStudentListOptions(newSelected);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetStudents.edges;
            const pageInfo = fetchMoreResult.GetStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: data?.GetStudents.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  const studentExcelData = [
    {
      Headers: "EduateId",
      key: "eduate_id",
      width: 50,
      required: false,
      type: Number,
      use_in_sheet: true,
    },

    {
      Headers: "AdmissionNumber",
      key: "adm_no",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "RegisterNo",
      key: "std_reg_no",
      width: 50,
      required: true,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "FirstName",
      key: "first_name",
      width: 50,
      required: true,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "MiddleName",
      key: "middle_name",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "LastName",
      key: "last_name",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "Mobile",
      key: "std_mobile",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "Email",
      key: "std_email",
      width: 50,
      required: true,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "Gender",
      key: "std_sex",
      width: 50,
      required: true,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "FatherName",
      key: "fathername",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "MotherName",
      key: "mothername",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },

    {
      Headers: "Fresher",
      key: "std_fresher",
      width: 50,
      required: false,
      type: Boolean,
      use_in_sheet: true,
    },
    {
      Headers: "DateOfBirth",
      key: "std_dob",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: classLabel,
      width: 50,
      required: USE_CLASS_KEY,
      type: String,
      key: "class",
      use_in_sheet: USE_CLASS_KEY,
    },

    {
      Headers: sectionLabel,
      width: 50,
      required: USE_SECTION_KEY,
      type: String,
      key: "section",
      use_in_sheet: USE_SECTION_KEY,
    },
    {
      Headers: "Aadhar",
      width: 50,
      required: false,
      type: String,
      key: "std_aadhar",
      use_in_sheet: getHeaderUseInSheetStatus("std_aadhar"),
    },
    {
      Headers: "STSno",
      width: 50,
      required: false,
      type: String,
      key: "std_sts_no",
      use_in_sheet: getHeaderUseInSheetStatus("std_sts_no"),
    },
    {
      Headers: "BloodGroup",
      width: 50,
      required: false,
      type: String,
      key: "std_blood_group",
      use_in_sheet: getHeaderUseInSheetStatus("std_blood_group"),
    },
    {
      Headers: "Address",
      width: 50,
      required: false,
      type: String,
      key: "std_address",
      use_in_sheet: getHeaderUseInSheetStatus("std_address"),
    },
    {
      Headers: "City",
      width: 50,
      required: false,
      type: String,
      key: "std_place",
      use_in_sheet: getHeaderUseInSheetStatus("std_place"),
    },
    {
      Headers: "District",
      width: 50,
      required: false,
      type: String,
      key: "std_district",
      use_in_sheet: getHeaderUseInSheetStatus("std_district"),
    },
    {
      Headers: "State",
      width: 50,
      required: false,
      type: String,
      key: "std_state",
      use_in_sheet: getHeaderUseInSheetStatus("std_state"),
    },
    {
      Headers: "PinCode",
      width: 50,
      required: false,
      type: String,
      key: "std_pincode",
      use_in_sheet: getHeaderUseInSheetStatus("std_pincode"),
    },
    {
      Headers: "Telno",
      width: 50,
      required: false,
      type: String,
      key: "std_phone",
      use_in_sheet: getHeaderUseInSheetStatus("std_phone"),
    },

    {
      Headers: "CorrespondingAddress",
      width: 50,
      required: false,
      type: String,
      key: "std_corr_address",
      use_in_sheet: getHeaderUseInSheetStatus("std_corr_address"),
    },
    {
      Headers: "CorrespondingCity",
      width: 50,
      required: false,
      type: String,
      key: "std_corr_place",
      use_in_sheet: getHeaderUseInSheetStatus("std_corr_place"),
    },
    {
      Headers: "CorrDistrict",
      width: 50,
      required: false,
      type: String,
      key: "std_corr_district",
      use_in_sheet: getHeaderUseInSheetStatus("std_corr_district"),
    },
    {
      Headers: "CorrState",
      width: 50,
      required: false,
      type: String,
      key: "std_corr_state",
      use_in_sheet: getHeaderUseInSheetStatus("std_corr_state"),
    },
    {
      Headers: "CorrPinCode",
      width: 50,
      required: false,
      type: String,
      key: "std_corr_pincode",
      use_in_sheet: getHeaderUseInSheetStatus("std_corr_pincode"),
    },
    {
      Headers: "CorrTelephone",
      width: 50,
      required: false,
      type: String,
      key: "std_corr_phone",
      use_in_sheet: getHeaderUseInSheetStatus("std_corr_phone"),
    },
    {
      Headers: "Religion",
      width: 50,
      required: false,
      type: String,
      key: "std_religion",
      use_in_sheet: getHeaderUseInSheetStatus("std_religion"),
    },
    {
      Headers: "Caste",
      width: 50,
      required: false,
      type: String,
      key: "std_caste",
      use_in_sheet: getHeaderUseInSheetStatus("std_caste"),
    },
    {
      Headers: "SubCaste",
      width: 50,
      required: false,
      type: String,
      key: "std_sub_caste",
      use_in_sheet: getHeaderUseInSheetStatus("std_sub_caste"),
    },
    {
      Headers: "CasteGroup",
      width: 50,
      required: false,
      type: String,
      key: "std_caste_group",
      use_in_sheet: getHeaderUseInSheetStatus("std_caste_group"),
    },
    {
      Headers: "SCST",
      width: 50,
      required: false,
      type: String,
      key: "std_sc_st",
      use_in_sheet: getHeaderUseInSheetStatus("std_sc_st"),
    },
    {
      Headers: "Minority",
      width: 50,
      required: false,
      type: Boolean,
      key: "std_caste_minority",
      use_in_sheet: getHeaderUseInSheetStatus("std_caste_minority"),
    },
    {
      Headers: "Area",
      width: 50,
      required: false,
      type: String,
      key: "std_rural_urban",
      use_in_sheet: getHeaderUseInSheetStatus("std_rural_urban"),
    },
    {
      Headers: "MotherTongue",
      width: 50,
      required: false,
      type: String,
      key: "std_mother_tongue",
      use_in_sheet: getHeaderUseInSheetStatus("std_mother_tongue"),
    },
    {
      Headers: "AnnualIncome",
      width: 50,
      required: false,
      type: Number,
      key: "std_annual_income",
      use_in_sheet: getHeaderUseInSheetStatus("std_annual_income"),
    },
    {
      Headers: "Married",
      width: 50,
      required: false,
      type: String,
      key: "std_martial_status",
      use_in_sheet: getHeaderUseInSheetStatus("std_martial_status"),
    },
    {
      Headers: "Married",
      width: 50,
      required: false,
      type: String,
      key: "std_martial_status",
      use_in_sheet: getHeaderUseInSheetStatus("std_martial_status"),
    },
    {
      Headers: "IdentityMarks",
      width: 50,
      required: false,
      type: String,
      key: "std_identity_marks",
      use_in_sheet: getHeaderUseInSheetStatus("std_identity_marks"),
    },
    {
      Headers: "PlaceofBirth",
      width: 50,
      required: false,
      type: String,
      key: "std_place_of_birth",
      use_in_sheet: getHeaderUseInSheetStatus("std_place_of_birth"),
    },
    {
      Headers: "Nationality",
      width: 50,
      required: false,
      type: String,
      key: "std_nationality",
      use_in_sheet: getHeaderUseInSheetStatus("std_nationality"),
    },
    {
      Headers: "PhysicalChallenged",
      width: 50,
      required: false,
      type: Boolean,
      key: "std_is_phy_challenged",
      use_in_sheet: getHeaderUseInSheetStatus("std_is_phy_challenged"),
    },
    {
      Headers: "NearestPoliceStation",
      width: 50,
      required: false,
      type: String,
      key: "std_nearest_police_station",
      use_in_sheet: getHeaderUseInSheetStatus("std_nearest_police_station"),
    },
    {
      Headers: "NearestRailwayStation",
      width: 50,
      required: false,
      type: String,
      key: "std_nearest_railway_station",
      use_in_sheet: getHeaderUseInSheetStatus("std_nearest_railway_station"),
    },
    {
      Headers: "Kmsfromresidence",
      width: 50,
      required: false,
      type: String,
      key: "std_km_from_residence",
      use_in_sheet: getHeaderUseInSheetStatus("std_km_from_residence"),
    },
    {
      Headers: "BelongstoKashmir",
      width: 50,
      required: false,
      type: Boolean,
      key: "std_belongs_to_jk",
      use_in_sheet: getHeaderUseInSheetStatus("std_belongs_to_jk"),
    },
    {
      Headers: "IsKashmirBrahmin",
      width: 50,
      required: false,
      type: Boolean,
      key: "std_belongs_to_jk_brahmin",
      use_in_sheet: getHeaderUseInSheetStatus("std_belongs_to_jk_brahmin"),
    },
    {
      Headers: "PrevTCDetails",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_tc_details",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_tc_details"),
    },
    {
      Headers: "PrevInstitution",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_inst",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_inst"),
    },
    {
      Headers: "PrevQualification",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_quali_exam",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_quali_exam"),
    },
    {
      Headers: "PervStudiedBoard",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_studied_board",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_studied_board"),
    },
    {
      Headers: "PrevStudiedState",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_studied_state",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_studied_state"),
    },
    {
      Headers: "PrevStudySubjects",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_subject_studied",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_subject_studied"),
    },
    {
      Headers: "PrevTotalMarks",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_total_marks",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_total_marks"),
    },
    {
      Headers: "PrevMarksPercentage",
      width: 50,
      required: false,
      type: Number,
      key: "std_prev_total_per",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_total_per"),
    },
    {
      Headers: "PrevTechnicalSubjects",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_tech_subject_studied",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_tech_subject_studied"),
    },
    {
      Headers: "PrevTechnicalMarks",
      width: 50,
      required: false,
      type: Number,
      key: "std_prev_tech_total_mark",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_tech_total_mark"),
    },
    {
      Headers: "PrevTechnicalPercentage",
      width: 50,
      required: false,
      type: Number,
      key: "std_prev_tech_total_per",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_tech_total_per"),
    },
    {
      Headers: "tenthMarks",
      width: 50,
      required: false,
      type: Number,
      key: "std_prev_10_std_marks",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_10_std_marks"),
    },
    {
      Headers: "tenthPercentage",
      width: 50,
      required: false,
      type: Number,
      key: "std_prev_10_std_per",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_10_std_per"),
    },
    {
      Headers: "tenthLanguage",
      width: 50,
      required: false,
      type: String,
      key: "std_prev_10_lang_medium",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_10_lang_medium"),
    },
    {
      Headers: "twelthMarks",
      width: 50,
      required: false,
      type: Number,
      key: "std_prev_12_std_marks",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_12_std_marks"),
    },
    {
      Headers: "twelthPercentage",
      width: 50,
      required: false,
      type: Number,
      key: "std_prev_12_std_per",
      use_in_sheet: getHeaderUseInSheetStatus("std_prev_12_std_per"),
    },
    {
      Headers: "BankAccountNo",
      width: 50,
      required: false,
      type: String,
      key: "std_bank_acct",
      use_in_sheet: getHeaderUseInSheetStatus("std_bank_acct"),
    },
    {
      Headers: "BankName",
      width: 50,
      required: false,
      type: String,
      key: "std_bank_name",
      use_in_sheet: getHeaderUseInSheetStatus("std_bank_name"),
    },
    {
      Headers: "IFSC",
      width: 50,
      required: false,
      type: String,
      key: "std_bank_ifsc",
      use_in_sheet: getHeaderUseInSheetStatus("std_bank_ifsc"),
    },
    {
      Headers: "BankBranch",
      width: 50,
      required: false,
      type: String,
      key: "std_bank_branch",
      use_in_sheet: getHeaderUseInSheetStatus("std_bank_branch"),
    },
    {
      Headers: "CETapplicationNo",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_appl_no",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_appl_no"),
    },
    {
      Headers: "FREEorPaymentSeat",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_free_pymnt_seat",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_free_pymnt_seat"),
    },
    {
      Headers: "STATEorINTERSTATE",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_state_or_outside",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_state_or_outside"),
    },
    {
      Headers: "SeatGotThrough",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_seat_got_through",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_seat_got_through"),
    },
    {
      Headers: "CategoryThrough",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_category",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_category"),
    },
    {
      Headers: "CETno",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_cet_no",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_cet_no"),
    },
    {
      Headers: "CETrank",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_cet_rank",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_cet_rank"),
    },
    {
      Headers: "AdmEligibiltyNo",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_eli_no",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_eli_no"),
    },
    {
      Headers: "AdmEligibiltyDate",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_eli_date",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_eli_date"),
    },
    {
      Headers: "CETPaidReceiptNo",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_cet_paid_rcpt_no",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_cet_paid_rcpt_no"),
    },
    {
      Headers: "CETPaidReceiptDate",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_cet_paid_rcpt_date",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_cet_paid_rcpt_date"),
    },
    {
      Headers: "CETPaidAmount",
      width: 50,
      required: false,
      type: Number,
      key: "std_adm_cet_paid_amt",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_cet_paid_amt"),
    },
    {
      Headers: "CETAllotedCategory",
      width: 50,
      required: false,
      type: String,
      key: "std_adm_cet_alloted_category",
      use_in_sheet: getHeaderUseInSheetStatus("std_adm_cet_alloted_category"),
    },
  ];
  const filteredStudentExcelData = studentExcelData.filter(
    (column) => column.use_in_sheet
  );
  const sampleData = [
    "xxxxxxx",
    "xxxxxxxx",
    "xxxxxxxx",
    "xxxxxxx",
    "xxxxxxx",
    "xxxxxxx",
    "xxxxxxxx",
    "233202400679",
    "6/1 3rd main Mysore",
    "Mysore",
    "560079",
    "Karnataka",
    "080-25642252",
    "25000",
    "O+ve",
    "Brahmin",
    "3B",
    "YES/NO",
    "Kannada/Hindi",
    "5832313",
    "HINDHU/MUSLIM",
    "Karnataka",
    "Bangalore/Current Place",
    "Rajajinagar 3rd block",
    "Bangalore",
    "Kolar",
    "Andra Pradesh",
    "080-2362151",
    "560078",
    "LINGAYATH",
    "SC/ST (any one)",
    "Urban",
    "YES/NO",
    "INDIAN",
    "YES/NO",
    "5",
    "10",
    "20",
    "YES/NO",
    "YES/NO",
    "Enter Details of TC",
    "KLE College",
    "",
    "STATE/CENTRAL",
    "TAMIL NADU",
    "SCIENCE/COMMERCE",
    "500",
    "83",
    "Computer Science",
    "",
  ];

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet2 = workbook.addWorksheet("Reference");
    const worksheet = workbook.addWorksheet("StudentInfo");
    const dataRows = data?.GetStudents.edges?.map((item) => {
      const rowData = [
        item.node.id.toString(),
        item.node.std_adm_no,
        item.node.std_reg_no,
        item.node.first_name,
        item.node.middle_name,
        item.node.last_name,
        item.node.std_mobile,
        item.node.std_email,
        item.node.std_sex,
        item.node.std_father_name,
        item.node.std_mother_name,
        item.node.std_fresher === false ? "NO" : "YES",
        toStandardDate(item.node.std_dob),
        item.node.class.class_desc,
        item.node.section.section_desc,
      ];
      if (getHeaderUseInSheetStatus("std_aadhar")) {
        rowData.push(item.node.std_details.std_aadhar);
      }
      // if (getHeaderUseInSheetStatus("std_sts_no")) {
      //   rowData.push(item.node.std_details.std_sts_no);
      // }
      if (getHeaderUseInSheetStatus("std_blood_group")) {
        rowData.push(item.node.std_details.std_blood_group);
      }
      if (getHeaderUseInSheetStatus("std_address")) {
        rowData.push(item.node.std_details.std_address);
      }
      if (getHeaderUseInSheetStatus("std_place")) {
        rowData.push(item.node.std_details.std_place);
      }
      if (getHeaderUseInSheetStatus("std_district")) {
        rowData.push(item.node.std_details.std_district);
      }
      if (getHeaderUseInSheetStatus("std_state")) {
        rowData.push(item.node.std_details.std_state);
      }
      if (getHeaderUseInSheetStatus("std_pincode")) {
        rowData.push(item.node.std_details.std_pincode);
      }
      if (getHeaderUseInSheetStatus("std_phone")) {
        rowData.push(item.node.std_details.std_phone);
      }
      if (getHeaderUseInSheetStatus("std_corr_address")) {
        rowData.push(item.node.std_details.std_corr_address);
      }
      if (getHeaderUseInSheetStatus("std_corr_place")) {
        rowData.push(item.node.std_details.std_corr_place);
      }
      if (getHeaderUseInSheetStatus("std_corr_district")) {
        rowData.push(item.node.std_details.std_corr_district);
      }
      if (getHeaderUseInSheetStatus("std_corr_state")) {
        rowData.push(item.node.std_details.std_corr_state);
      }
      if (getHeaderUseInSheetStatus("std_corr_pincode")) {
        rowData.push(item.node.std_details.std_corr_pincode);
      }
      if (getHeaderUseInSheetStatus("std_corr_phone")) {
        rowData.push(item.node.std_details.std_corr_phone);
      }
      if (getHeaderUseInSheetStatus("std_religion")) {
        rowData.push(item.node.std_details.std_religion);
      }
      if (getHeaderUseInSheetStatus("std_caste")) {
        rowData.push(item.node.std_details.std_caste);
      }
      if (getHeaderUseInSheetStatus("std_sub_caste")) {
        rowData.push(item.node.std_details.std_sub_caste);
      }
      if (getHeaderUseInSheetStatus("std_caste_group")) {
        rowData.push(item.node.std_details.std_caste_group);
      }
      if (getHeaderUseInSheetStatus("std_sc_st")) {
        rowData.push(item.node.std_details.std_sc_st);
      }
      if (getHeaderUseInSheetStatus("std_caste_minority")) {
        rowData.push(
          item.node.std_details.std_caste_minority ? YesNo.YES : YesNo.NO
        );
      }
      if (getHeaderUseInSheetStatus("std_rural_urban")) {
        rowData.push(item.node.std_details.std_rural_urban);
      }
      if (getHeaderUseInSheetStatus("std_mother_tongue")) {
        rowData.push(item.node.std_details.std_mother_tongue);
      }
      if (getHeaderUseInSheetStatus("std_annual_income")) {
        rowData.push(item.node.std_details.std_annual_income.toString());
      }
      if (getHeaderUseInSheetStatus("std_martial_status")) {
        rowData.push(item.node.std_details.std_martial_status);
      }
      if (getHeaderUseInSheetStatus("std_identity_marks")) {
        rowData.push(item.node.std_details.std_identity_marks);
      }
      if (getHeaderUseInSheetStatus("std_place_of_birth")) {
        rowData.push(item.node.std_details.std_place_of_birth);
      }
      if (getHeaderUseInSheetStatus("std_nationality")) {
        rowData.push(item.node.std_details.std_nationality);
      }
      if (getHeaderUseInSheetStatus("std_is_phy_challenged")) {
        rowData.push(
          (item.node.std_details.std_is_phy_challenged
            ? YesNo.YES
            : YesNo.NO) as string
        );
      }
      if (getHeaderUseInSheetStatus("std_nearest_police_station")) {
        rowData.push(item.node.std_details.std_nearest_police_station);
      }
      if (getHeaderUseInSheetStatus("std_nearest_railway_station")) {
        rowData.push(item.node.std_details.std_nearest_railway_station);
      }
      if (getHeaderUseInSheetStatus("std_km_from_residence")) {
        rowData.push(item.node.std_details.std_km_from_residence);
      }
      if (getHeaderUseInSheetStatus("std_belongs_to_jk")) {
        rowData.push(
          item.node.std_details.std_belongs_to_jk ? YesNo.YES : YesNo.NO
        );
      }
      if (getHeaderUseInSheetStatus("std_belongs_to_jk_brahmin")) {
        rowData.push(
          item.node.std_other_details.std_belongs_to_jk_brahmin
            ? YesNo.YES
            : YesNo.NO
        );
      }
      if (getHeaderUseInSheetStatus("std_prev_tc_details")) {
        rowData.push(item.node.std_other_details.std_prev_tc_details);
      }
      if (getHeaderUseInSheetStatus("std_prev_inst")) {
        rowData.push(item.node.std_other_details.std_prev_inst);
      }
      if (getHeaderUseInSheetStatus("std_prev_quali_exam")) {
        rowData.push(item.node.std_other_details.std_prev_quali_exam);
      }
      if (getHeaderUseInSheetStatus("std_prev_studied_board")) {
        rowData.push(item.node.std_other_details.std_prev_studied_board);
      }
      if (getHeaderUseInSheetStatus("std_prev_studied_state")) {
        rowData.push(item.node.std_other_details.std_prev_studied_state);
      }
      if (getHeaderUseInSheetStatus("std_prev_subject_studied")) {
        rowData.push(item.node.std_other_details.std_prev_subject_studied);
      }
      if (getHeaderUseInSheetStatus("std_prev_scored_marks")) {
        rowData.push(
          item.node.std_other_details.std_prev_scored_marks.toString()
        );
      }
      if (getHeaderUseInSheetStatus("std_prev_total_per")) {
        rowData.push(item.node.std_other_details.std_prev_total_per.toString());
      }
      if (getHeaderUseInSheetStatus("std_prev_tech_subject_studied")) {
        rowData.push(item.node.std_other_details.std_prev_tech_subject_studied);
      }
      if (getHeaderUseInSheetStatus("std_prev_tech_total_mark")) {
        rowData.push(
          item.node.std_other_details.std_prev_tech_total_mark.toString()
        );
      }
      if (getHeaderUseInSheetStatus("std_prev_tech_total_per")) {
        rowData.push(
          item.node.std_other_details.std_prev_tech_total_mark.toString()
        );
      }
      if (getHeaderUseInSheetStatus("std_10_scored_marks")) {
        rowData.push(
          item.node.std_other_details.std_10_scored_marks.toString()
        );
      }
      if (getHeaderUseInSheetStatus("std_10_scored_percentage")) {
        rowData.push(
          item.node.std_other_details.std_10_scored_percentage.toString()
        );
      }
      if (getHeaderUseInSheetStatus("std_10_lang_medium")) {
        rowData.push(item.node.std_other_details.std_10_lang_medium);
      }
      if (getHeaderUseInSheetStatus("std_12_scored_marks")) {
        rowData.push(
          item.node.std_other_details.std_12_scored_marks.toString()
        );
      }
      if (getHeaderUseInSheetStatus("std_12_scored_percentage")) {
        rowData.push(
          item.node.std_other_details.std_12_scored_percentage.toString()
        );
      }
      if (getHeaderUseInSheetStatus("std_bank_acct")) {
        rowData.push(item.node.std_other_details.std_bank_acct);
      }
      if (getHeaderUseInSheetStatus("std_bank_name")) {
        rowData.push(item.node.std_other_details.std_bank_name);
      }
      if (getHeaderUseInSheetStatus("std_bank_ifsc")) {
        rowData.push(item.node.std_other_details.std_bank_ifsc);
      }
      if (getHeaderUseInSheetStatus("std_bank_branch")) {
        rowData.push(item.node.std_other_details.std_bank_branch);
      }
      if (getHeaderUseInSheetStatus("std_adm_appl_no")) {
        rowData.push(item.node.std_other_details.std_adm_appl_no);
      }
      if (getHeaderUseInSheetStatus("std_adm_free_pymnt_seat")) {
        rowData.push(item.node.std_other_details.std_adm_free_pymnt_seat);
      }
      if (getHeaderUseInSheetStatus("std_adm_state_or_outside")) {
        rowData.push(item.node.std_other_details.std_adm_state_or_outside);
      }
      if (getHeaderUseInSheetStatus("std_adm_seat_got_through")) {
        rowData.push(item.node.std_other_details.std_adm_seat_got_through);
      }
      if (getHeaderUseInSheetStatus("std_adm_category")) {
        rowData.push(item.node.std_other_details.std_adm_category);
      }
      if (getHeaderUseInSheetStatus("std_adm_cet_no")) {
        rowData.push(item.node.std_other_details.std_adm_cet_no);
      }
      if (getHeaderUseInSheetStatus("std_adm_cet_rank")) {
        rowData.push(item.node.std_other_details.std_adm_cet_rank);
      }
      if (getHeaderUseInSheetStatus("std_adm_eli_no")) {
        rowData.push(item.node.std_other_details.std_adm_eli_no);
      }
      if (getHeaderUseInSheetStatus("std_adm_eli_date")) {
        rowData.push(item.node.std_other_details.std_adm_eli_date);
      }
      if (getHeaderUseInSheetStatus("std_adm_cet_paid_rcpt_no")) {
        rowData.push(item.node.std_other_details.std_adm_cet_paid_rcpt_no);
      }
      if (getHeaderUseInSheetStatus("std_adm_cet_paid_rcpt_date")) {
        rowData.push(item.node.std_other_details.std_adm_cet_paid_rcpt_date);
      }
      if (getHeaderUseInSheetStatus("std_adm_cet_paid_amt")) {
        rowData.push(
          item.node.std_other_details.std_adm_cet_paid_amt.toString()
        );
      }
      if (getHeaderUseInSheetStatus("std_adm_cet_alloted_category")) {
        rowData.push(item.node.std_other_details.std_adm_cet_alloted_category);
      }

      return rowData;
    });

    worksheet.views = [
      {
        state: "frozen",
        xSplit: 7,
        ySplit: 1,
        activeCell: "A2",
      },
    ];
    worksheet2.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet2.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });
    worksheet.getRow(1).height = 35;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet2.addImage(imageV, "AE1:AE3");

          worksheet2.mergeCells(1, 1, 1, filteredStudentExcelData?.length!);

          const mergedCell: Cell = worksheet2.getCell(FIRST_CELL);
          mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
          mergedCell.fill = HEADER_CSS;
          mergedCell.font = headerStyle[0].font;
          mergedCell.alignment = {
            horizontal: "left",
            vertical: "bottom",
          };

          const mergedAddress: Cell = worksheet2.getCell(A2_CELL);
          mergedAddress.value = InstDetails.data?.nodes[0]?.inst_address;
          mergedAddress.fill = HEADER_CSS;
          mergedAddress.font = ADDRESS_FONT;
          mergedAddress.alignment = {
            horizontal: "left",
            vertical: "bottom",
          };
          worksheet2.mergeCells("A2:BV2");

          const mergedPlace: Cell = worksheet2.getCell(A3_CELL);
          mergedPlace.value =
            InstDetails.data?.nodes[0]?.inst_place +
            "-" +
            InstDetails.data?.nodes[0]?.inst_pin;

          mergedPlace.fill = HEADER_CSS;
          mergedPlace.font = ADDRESS_FONT;
          mergedPlace.alignment = {
            horizontal: "left",
            vertical: "bottom",
          };

          const mergedHeader: Cell = worksheet2.getCell(D4_CELL);
          mergedHeader.value = "Sample Student Data";
          mergedHeader.fill = FILE_NAME_CSS;
          mergedHeader.font = FILE_NAME_FONT;
          mergedHeader.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet2.mergeCells("D4:E4");
          const mergedDate: Cell = worksheet2.getCell(F4_CELL);
          mergedDate.value = ExcelPageHeader.DATE + toStandardDate(serverDate);
          mergedDate.fill = FILE_NAME_CSS;
          mergedDate.font = FIN_YEAR_FONT;
          mergedDate.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet2.mergeCells("F4:BV4");
          const mergedYear: Cell = worksheet2.getCell(A4_CELL);
          mergedYear.value = state.ActiveFinYr
            ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
            : ExcelPageHeader.YEAR;
          mergedYear.fill = FILE_NAME_CSS;
          mergedYear.font = FIN_YEAR_FONT;
          mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet2.mergeCells("A4:C4");
          const headerRow2 = worksheet2.getRow(5);
          headerRow2.height = 25;
          headerRow2.font = ACC_HEADER_FONT;
          headerRow2.alignment = { horizontal: ExcelAlignment.CENTER };
          worksheet2.getColumn(1).width = 6;
          worksheet2.getRow(1).height = 33;
          worksheet2.getRow(2).height = 20;
          worksheet2.getRow(3).height = 20;
          worksheet2.getRow(4).height = 20;
          const headerRow = worksheet.getRow(1);
          headerRow.height = 25;
          headerRow.font = ACC_HEADER_FONT;
          headerRow.alignment = { horizontal: ExcelAlignment.CENTER };
          let studentInfoHeader = filteredStudentExcelData
            .filter((student) => student.use_in_sheet)
            .map((f) => f.Headers);
          for (let i = 0; i < filteredStudentExcelData.length; i++) {
            const cell = headerRow.getCell(i + 1);
            cell.value = studentInfoHeader[i];
            cell.fill = TABLE_HEADER_CSS;
            cell.border = BORDER_DATA;
          }
          worksheet.columns.forEach((column) => {
            column.width = 20;
          });
          worksheet2.columns.forEach((column) => {
            column.width = 20;
          });

          dataRows!.forEach((rowData) => {
            const row = worksheet.addRow(rowData);
            row.eachCell({ includeEmpty: true }, (cell) => {
              cell.alignment = { horizontal: ExcelAlignment.CENTER };
              cell.font = { name: ExcelFont.COURIER_NEW, size: 9 };
              row.getCell(4).font = {
                name: ExcelFont.CENTURY_GOTHIC,
                size: 9,
              };
              row.getCell(5).font = {
                name: ExcelFont.CENTURY_GOTHIC,
                size: 9,
              };
            });
          });
          const row2 = worksheet2.addRow(sampleData);
          row2.getCell(1).value = 1;

          workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
            const blob = new Blob([buffer], {
              type: BLOB_TYPE,
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement(ELEMENT);
            link.href = url;
            link.setAttribute(DOWNLOAD, InstName!);
            document.body.appendChild(link);
            link.click();
          });
          setExcelFlag(false);
          setRowsPerPage(ROWS_PER_PAGE);
        });
      });
  };
  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !loading && data) downloadExcel();
    // eslint-disable-next-line
  }, [excelFlag, rowsPerPage, loading, data]);
  const Row = (props: { row: StudentNode; sl: number }) => {
    const { row, sl } = props;
    return (
      <React.Fragment key={row.id}>
        <TableRow>
          <TableCell id="td-center" className="studentlist__table--slno">
            {sl}
          </TableCell>
          {studentListOptions.find(
            (x) => x === StudentListFields.STD_ADMISSION_NO
          ) && (
            <TableCell className="studentlist__table--admno">
              {row.std_adm_no}
            </TableCell>
          )}
          {studentListOptions.find(
            (x) => x === StudentListFields.STD_REG_NO
          ) && (
            <TableCell className="studentlist__table--admno">
              {row.std_reg_no}
            </TableCell>
          )}

          {studentListOptions.find((x) => x === StudentListFields.STD_NAME) && (
            <TableCell>
              {row.first_name + " " + row.middle_name + " " + row.last_name}
            </TableCell>
          )}

          {studentListOptions.find(
            (x) => x === StudentListFields.STD_FATHER_NAME
          ) && (
            <TableCell className="studentlist__table--fname">
              {row.std_father_name}
            </TableCell>
          )}

          {studentListOptions.find(
            (x) => x === StudentListFields.CATEGORY_ID
          ) && USE_CATEGORY_KEY ? (
            <TableCell className="studentlist__table--catg">
              {row.category.cat_desc}
            </TableCell>
          ) : null}

          {studentListOptions.find((x) => x === StudentListFields.DEPT_ID) &&
          USE_DEPARTMENT_KEY ? (
            <TableCell className="studentlist__table--catg">
              {row.dept.dept_desc}
            </TableCell>
          ) : null}
          {studentListOptions.find((x) => x === StudentListFields.BRANCH_ID) &&
          USE_BRANCH_KEY ? (
            <TableCell className="studentlist__table--catg">
              {row.branch.branch_desc}
            </TableCell>
          ) : null}

          {studentListOptions.find((x) => x === StudentListFields.CLASS_ID) &&
          USE_CLASS_KEY ? (
            <TableCell className="studentlist__table--catg">
              {row.class.class_desc}
            </TableCell>
          ) : null}

          {studentListOptions.find(
            (x) => x === StudentListFields.SEMESTER_ID
          ) && USE_SEMESTER_KEY ? (
            <TableCell className="studentlist__table--catg">
              {row.semester.sem_desc}
            </TableCell>
          ) : null}
          {studentListOptions.find((x) => x === StudentListFields.SECTION_ID) &&
          USE_SECTION_KEY ? (
            <TableCell className="studentlist__table--catg">
              {row.section.section_desc}
            </TableCell>
          ) : null}
          {studentListOptions.find(
            (x) => x === StudentListFields.STD_MOBILE
          ) && (
            <TableCell className="studentlist__table--mobile">
              {row.std_mobile}
            </TableCell>
          )}
          {studentListOptions.find(
            (x) => x === StudentListFields.STD_EMAIL
          ) && (
            <TableCell className="studentlist__table--fname">
              {row.std_email}
            </TableCell>
          )}
          {studentListOptions.find(
            (x) => x === StudentListFields.STD_STATUS
          ) && (
            <TableCell
              className={`${FetchStudentStatusClassName(
                row.std_status
              )} studentlist__table--status`}
              id="td-center"
            >
              {row.std_status}
            </TableCell>
          )}
        </TableRow>
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (data && !loading && token && excelFlag === false) {
      const newData = data.GetStudents.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(data.GetStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, token]);

  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !loading) downloadExcel();
    // eslint-disable-next-line
  }, [excelFlag, rowsPerPage, loading]);

  const fields = [
    {
      label: TableHeaders.ADMISSION_NUMBER,
      value: StudentListFields.STD_ADMISSION_NO,
      visible: true,
    },
    {
      label: TableHeaders.REGISTER_NUMBER,
      value: StudentListFields.STD_REG_NO,
      visible: true,
    },
    {
      label: TableHeaders.NAME,
      value: StudentListFields.STD_NAME,
      visible: true,
    },
    {
      label: TableHeaders.FATHER_NAME,
      value: StudentListFields.STD_FATHER_NAME,
      visible: true,
    },
    {
      label: TableHeaders.MOBILE,
      value: StudentListFields.STD_MOBILE,
      visible: true,
    },
    {
      label: TableHeaders.TELEPHONE,
      value: StudentListFields.STD_TELEPHONE,
      visible: true,
    },
    {
      label: TableHeaders.EMAIL,
      value: StudentListFields.STD_EMAIL,
      visible: true,
    },

    {
      label: departmentLabel,
      value: StudentListFields.DEPT_ID,
      visible: USE_DEPARTMENT_KEY,
    },
    {
      label: branchLabel,
      value: StudentListFields.BRANCH_ID,
      visible: USE_BRANCH_KEY,
    },
    {
      label: classLabel,
      value: StudentListFields.CLASS_ID,
      visible: USE_CLASS_KEY,
    },
    {
      label: semesterLabel,
      value: StudentListFields.SEMESTER_ID,
      visible: USE_SEMESTER_KEY,
    },
    {
      label: sectionLabel,
      value: StudentListFields.SECTION_ID,
      visible: USE_SECTION_KEY,
    },
    {
      label: categoryLabel,
      value: StudentListFields.CATEGORY_ID,
      visible: USE_CATEGORY_KEY,
    },
    {
      label: TableHeaders.STATUS,
      value: StudentListFields.STD_STATUS,
      visible: true,
    },
  ];
  useEffect(() => {
    setColumnsList(fields);
  }, [
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
    USE_CLASS_KEY,
  ]);
  return (
    <>
      <div className="export-student">
        <form>
          <div className="row g-0 export-student__options">
            <div className="col-1 studentlist__search">
              <Input
                id="search"
                type="text"
                placeholder="Search "
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchData(e.target.value);
                }}
                value={searchData}
                onKeyDown={handleFormEvent}
              />
            </div>

            {USE_DEPARTMENT_KEY ? (
              <div className="col-1">
                <Autocomplete
                  classes={classes}
                  options={departmentDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, departmentSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      if (departmentSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setDepartmentSelected(null);
                    }
                  }}
                  openOnFocus
                  value={departmentSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setDepartmentSelected(newValue);
                      setHasNextPage(true);
                    } else {
                      setDepartmentSelected(null);
                    }
                    setBranchSelected(null);
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setSectionSelected(null);
                    setCategorySelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={departmentLabel}
                      InputLabelProps={{ shrink: true }}
                      id="outlined Departments"
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_BRANCH_KEY ? (
              <div className="col-1">
                <Autocomplete
                  classes={classes}
                  options={branchDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, branchSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setBranchSelected(null);
                    }
                  }}
                  openOnFocus
                  value={branchSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBranchSelected(newValue);
                    } else {
                      setBranchSelected(null);
                    }
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setSectionSelected(null);
                    setCategorySelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={branchLabel}
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_CLASS_KEY ? (
              <div className="col-1">
                <Autocomplete
                  classes={classes}
                  options={classDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, classSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (classSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setClassSelected(null);
                    }
                  }}
                  openOnFocus
                  value={classSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setClassSelected(newValue);
                    } else {
                      setClassSelected(null);
                    }
                    setSemesterSelected(null);
                    setSectionSelected(null);
                    setCategorySelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={classLabel}
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: textClasses.formControlRoot }}
                      fullWidth
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SEMESTER_KEY ? (
              <div className="col-1">
                <Autocomplete
                  classes={classes}
                  options={semesterDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, semesterSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (semesterSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSemesterSelected(null);
                    }
                  }}
                  openOnFocus
                  value={semesterSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSemesterSelected(newValue);
                    } else {
                      setSemesterSelected(null);
                    }
                    setSectionSelected(null);
                    setCategorySelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={semesterLabel}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SECTION_KEY ? (
              <div className="col-1">
                <Autocomplete
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, sectionSelected)
                  }
                  classes={classes}
                  options={sectionDropDown}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (sectionSelected) {
                        handleMUISelectEvent(e);
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSectionSelected(null);
                    }
                  }}
                  openOnFocus
                  value={sectionSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSectionSelected(newValue);
                    } else {
                      setSectionSelected(null);
                    }
                    setCategorySelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={sectionLabel}
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_CATEGORY_KEY ? (
              <div className="col-1">
                <Autocomplete
                  classes={classes}
                  options={categoryDropDown}
                  openOnFocus
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, categorySelected)
                  }
                  value={categorySelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setCategorySelected(newValue);
                    } else {
                      setCategorySelected(null);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={categoryLabel}
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: textClasses.formControlRoot }}
                      fullWidth
                    />
                  )}
                />
              </div>
            ) : null}
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={STUDENT_STATUS}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, statusSelected)
                }
                value={statusSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setStatusSelected(newValue);
                  } else {
                    setStatusSelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setStatusSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="col"></div>
            <div className="col-2  export-student__button">
              <Button
                mode="clear"
                onClick={(e: React.FormEvent) => {
                  e.preventDefault();
                  clear();
                }}
                className="student-clear-button"
              />
            </div>
          </div>
          <div className="row g-0 export-student__total-count">
            <div className="col-4">
              <StudentTotalCount totalCount={data?.GetStudents.totalCount!} />
            </div>
          </div>
        </form>
        <div className="export-student__tableblock">
          {!students.length ? (
            <b className="nodata">Sorry, No Students Found</b>
          ) : (
            <>
              <TableContainer
                className="studentlist__table"
                onScroll={handleScroll}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>{TableHeaders.SLNO}</TableCell>
                      {studentListOptions.find(
                        (x) => x === StudentListFields.STD_ADMISSION_NO
                      ) && (
                        <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
                      )}
                      {studentListOptions.find(
                        (x) => x === StudentListFields.STD_REG_NO
                      ) && (
                        <TableCell>{TableHeaders.REGISTER_NUMBER}</TableCell>
                      )}
                      {studentListOptions.find(
                        (x) => x === StudentListFields.STD_NAME
                      ) && <TableCell>{TableHeaders.NAME}</TableCell>}
                      {studentListOptions.find(
                        (x) => x === StudentListFields.STD_FATHER_NAME
                      ) && <TableCell>{TableHeaders.FATHER_NAME}</TableCell>}
                      {studentListOptions.find(
                        (x) => x === StudentListFields.CATEGORY_ID
                      ) && USE_CATEGORY_KEY ? (
                        <TableCell>{categoryLabel}</TableCell>
                      ) : null}
                      {studentListOptions.find(
                        (x) => x === StudentListFields.DEPT_ID
                      ) && USE_DEPARTMENT_KEY ? (
                        <TableCell>{departmentLabel}</TableCell>
                      ) : null}
                      {studentListOptions.find(
                        (x) => x === StudentListFields.BRANCH_ID
                      ) && USE_BRANCH_KEY ? (
                        <TableCell>{branchLabel}</TableCell>
                      ) : null}
                      {studentListOptions.find(
                        (x) => x === StudentListFields.CLASS_ID
                      ) && USE_CLASS_KEY ? (
                        <TableCell>{classLabel}</TableCell>
                      ) : null}
                      {studentListOptions.find(
                        (x) => x === StudentListFields.SEMESTER_ID
                      ) && USE_SEMESTER_KEY ? (
                        <TableCell>{semesterLabel}</TableCell>
                      ) : null}
                      {studentListOptions.find(
                        (x) => x === StudentListFields.SECTION_ID
                      ) && USE_SECTION_KEY ? (
                        <TableCell>{sectionLabel}</TableCell>
                      ) : null}
                      {studentListOptions.find(
                        (x) => x === StudentListFields.STD_MOBILE
                      ) && <TableCell>{TableHeaders.MOBILE}</TableCell>}
                      {studentListOptions.find(
                        (x) => x === StudentListFields.STD_EMAIL
                      ) && <TableCell>{TableHeaders.EMAIL}</TableCell>}
                      {studentListOptions.find(
                        (x) => x === StudentListFields.STD_STATUS
                      ) && <TableCell>{TableHeaders.STATUS}</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {students?.map((edge, index) => (
                      <Row key={index} row={edge.node} sl={index + 1} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
        <div className="studentlist__buttons">
          <Button onClick={() => setStdConfig(!stdConfig)} mode="export" />
          <Button mode="back" onClick={() => navigate(-1)} />

          <LegendsInStudentList />
        </div>
      </div>

      {/* filter-columns */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={columnsModal}
        style={columnStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Input
              autoFocus
              placeholder="Type to Search..."
              id="search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div className="studentlist__column-list">
              {columnsList
                .filter(({ visible }) => visible)
                ?.map((label, index: React.Key) => {
                  const isItemSelected = isSelected(label.value.toString());
                  return (
                    <ul
                      onClick={(event) =>
                        handleClick(event, label.value.toString())
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      key={index}
                    >
                      <li>
                        <Checkbox checked={isItemSelected} /> {label.label}
                      </li>
                    </ul>
                  );
                })}
            </div>
            <div>{studentListOptions.length} of 10 selected</div>

            <Button type="button" onClick={() => setColumnsModal(false)}>
              Done
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={stdConfig}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentsConfiguration
              pageType={PageFor.MODAL}
              setStudentSwDataForModal={setStudentSwDataForModal}
              studentSwDataForModal={studentSwDataForModal}
            />
            <Button
              mode="excel"
              onClick={() => {
                setRowsPerPage(null);
                setExcelFlag(true);
              }}
            >
              Download To Excel
            </Button>
            <Button mode="cancel" onClick={() => setStdConfig(!stdConfig)} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStdConfig(!stdConfig)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default StudentExport;
