import { useState } from "react";
import Home from "../../../Home/Index";
import { Title } from "../../../../../stories/Title/Title";
import { Button } from "../../../../../stories/Button/Button";
import { useNavigate } from "react-router-dom";
import StudentList from "./StudentList";
import { StudentReportType } from "../../../../../utils/Enum.types";
import SubjectList from "./SubjectsList";
import { Drawer } from "@mui/material";
import { attendanceOverViewStyles } from "../../../../../styles/DrawerStyles";

import SelectedStudents from "./SelectedStudents";
import Confirmation from "../../../../../images/Confirm_Students_Allocation.svg";
import InstDetailsAllocationLevel from "../../../DailyActivities/InstDetailsAllocationLevel";

const ChooseStudent = () => {
  const navigate = useNavigate();
  const [drawer, setDrawer] = useState(false);
  const drawerClasses = attendanceOverViewStyles();
  const [studentIds, setStudentIds] = useState<Set<number>>(new Set());
  //write a program to add 2 numbers in javascript

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Subject Allocation Details</Title>
      <div className="sub-allocation-comb">
        <InstDetailsAllocationLevel />
        <b className="sub-allocation-comb__title">Selected Combination</b>
        <div className="sub-allocation-comb__datablock">
          <div className="sub-allocation-comb__datablock--left ">
            <Title variant="subtitle1">Please Select atleast One Student</Title>
            <StudentList
              queryType={
                StudentReportType.COMBO_UNASSIGNED_STDS_AT_ALLOTED_LEVEL
              }
              idsState={{
                studentIds,
                setStudentIds,
              }}
            />
          </div>
          <div className="sub-allocation-comb__datablock--right">
            <Title variant="subtitle1">Allocated Subjects</Title>

            <SubjectList />
          </div>
        </div>
        <Button onClick={() => setDrawer(!drawer)}>
          <img src={Confirmation} alt="" />
          Confirm Student Allocation
        </Button>
        <Button mode="clear" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={drawer}
        onClose={() => setDrawer(!drawer)}
      >
        <SelectedStudents
          setDrawer={setDrawer}
          selectedStudentIds={studentIds}
          comboDetails={null}
        />
      </Drawer>
    </>
  );
};

export default ChooseStudent;
