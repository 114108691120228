import React from "react";
import { Title } from "../../../stories/Title/Title";
import Close from "../../../images/Close.svg";
import { Label } from "../../../stories/Label/Label";
import Input from "../../../stories/Input/Input";
import TextArea from "../../../stories/TextArea/TextArea";
import { FormControlLabel } from "@mui/material";
import { AntSwitch } from "../../../pages/Switch";
import { Button } from "../../../stories/Button/Button";
interface props{
    setModal:React.Dispatch<React.SetStateAction<boolean>>

}
const Edit = ({setModal}:props) => {
  return (
    <>
      <div className="miscellaneous-edit">
        <div className="miscellaneous-edit__title">
          <Title>Miscellaneous Remarks</Title>
          <img src={Close} alt=""  onClick={()=>setModal(false)}/>
        </div>
        <div className="miscellaneous-edit__form">
            <div className="label-grid">
                <Label>Date</Label>
                <Input disabled/>
                <Label>Miscellaneous Activity</Label>
                <Input disabled/>
                <Label>Remarks</Label>
                <TextArea rows={3}/>

            </div>
            <FormControlLabel
              label="Status"
              labelPlacement="start"
              control={<AntSwitch />}
              className="miscellaneous-add__datablock--form-labels"
            />
        </div>
        <Button mode="save"/>
        <Button mode="clear"/>
        <Button mode="cancel" onClick={()=>setModal(false)}/>


      </div>
    </>
  );
};

export default Edit;
