import React, { Key, useEffect, useRef, useState } from "react";
import { Button } from "../../../stories/Button/Button";

import { Label } from "../../../stories/Label/Label";
import { Title } from "../../../stories/Title/Title";
import { Operation, PageNumbers } from "../../../utils/Enum.types";

import Tree from "./Tree";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddAcctGroupLdgr,
  UpdateAcctGroupLdgrById,
} from "../queries/GroupLedgers/mutation/Index";
import {
  GroupLederTypes,
  msgType,
  responseType,
} from "../../../utils/Form.types";
import Input from "../../../components/common/Input/Index";

import { useNavigate, useParams } from "react-router-dom";
import {
  AccountGroupLdgrsTypeByInstId,
  GetGroupLedgerDetailsByNode,
} from "../queries/GroupLedgers/query/ById";
import { Form, Formik } from "formik";
import { groupLedgerValidation } from "../../../utils/validationRules";
import {
  handleMUISelectEvent,
  removeMoreSpace,
} from "../../../utils/UtilFunctions";
import { LedgerTitles } from "../../../Types/Titles";
import { LabelNameProps } from "../../../Types/Labels";
import Home from "../Home/Index";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import {
  ConfigurationsModalStyles,
  DeleteLedgerModalStyles,
} from "../../../styles/ModalStyles";
import Modal from "react-modal";
import ConfigurationSettings from "../../Master/configurations/general/Index";

import LoadingModal from "../../../pages/LoadingModal";
import { Autocomplete, TextField } from "@mui/material";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
  selectAutoCompleteStyles,
} from "../../../styles/AutocompleteStyles";
import DownArrow from "../../../images/DownArrow.svg";
import useToken from "../../../customhooks/useToken";
import MessageModal from "../../../pages/MessageModal";
import { Keys } from "../../../utils/Enum.keys";
import {
  GetAcctGroupLdgrs,
  GetAcctGroupLdgrType,
} from "../queries/FeeLedgers/query/Byid";
import useGroupLedgerData from "../hooks/useGroupLedgerData";
import Close from "../../../images/Close.svg";
import Settings from "../../../images/Settings.svg";
import {
  EMPTY_RESPONSETYPE,
  EMPTY_RESPONSETYPE_OBJECT,
} from "../../../utils/constants";
import Delete from "./Delete";
import {
  GetAcctGroupLdgrTypeData,
  GetAcctGroupLdgrTypeVars,
} from "../../../Types/Accounting";
import { AcctGroupLdgrQueryType } from "../common/QueryTypes";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
const { Accounts } = require("../../../json/account.json");
const { AccountsTitles } = require("../json/title.json");

const GroupLedger = () => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const selectClasses = selectAutoCompleteStyles();

  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();

  //useStates for FormData
  const [groupLedgerUnder, setgroupLedgerUnder] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [groupLedgerId, setGroupLedgerId] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [formData, setFormData] = useState<GroupLederTypes>({
    groupname: "",
    groupaliasname: "",
  });
  const [searchData, setSearchData] = useState("");

  //useStates for Add and Edit
  const [enableAdd, setEnableAdd] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [enableDelete, setEnableDelete] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  //useRefs
  const groupLedgerRef = useRef<HTMLInputElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);

  //configuration modal
  const [configurationModal, setConfigurationModal] = useState(false);

  //Condition to show form
  const showForm =
    (!enableEdit && !enableAdd) ||
    (groupLedgerId.value && enableEdit) ||
    enableAdd
      ? true
      : false;
  const { user_details } = useLoggedInUserDetails();

  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.GROUP_LEDGER_PAGE
  );

  //useLazyQuery for setting data in field while editing
  const [GetGroupLedger] = useLazyQuery(GetGroupLedgerDetailsByNode);

  const [AddGrpLdgr, { loading: creationLoading }] = useMutation(
    AddAcctGroupLdgr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [updateLedgerTransaction, { loading: updationLoading }] = useMutation(
    UpdateAcctGroupLdgrById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  // hooks

  const { groupLedgers } = useGroupLedgerData(
    searchData,
    AcctGroupLdgrQueryType.GROUP_LDGR_BY_INST_ID
  );
  const [GetGrpLdgrType, { data, loading }] = useLazyQuery<
    GetAcctGroupLdgrTypeData,
    GetAcctGroupLdgrTypeVars
  >(GetAcctGroupLdgrType, { variables: { token } });

  useEffect(() => {
    if (token) {
      GetGrpLdgrType();
    }
  }, [token, GetGrpLdgrType]);

  const GroupLedgerTypeResponseType: responseType[] =
    data && !loading
      ? data.GetAcctGroupLdgrType.map(({ id, type_desc }) => ({
          label: type_desc,
          value: id,
          isChecked: false,
        }))
      : EMPTY_RESPONSETYPE;

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const handleLedgerTransactions = () => {
    if (enableAdd) {
      AddGrpLdgr({
        variables: {
          token,
          input: {
            gr_ldgr_desc: removeMoreSpace(formData.groupname),
            gr_ldgr_alias: removeMoreSpace(formData.groupaliasname),
            group_ldgr_type_id: groupLedgerUnder.value,
            inst_id: InstId,
          },
          user_details,
          inst_id: InstId,
        },
        refetchQueries: [
          {
            query: AccountGroupLdgrsTypeByInstId,
            variables: {
              token,
              inst_id: InstId,
            },
          },
          {
            query: GetAcctGroupLdgrs,
            variables: {
              token,
              input: {
                ids: [InstId],
                acct_group_ldgr_query_type:
                  AcctGroupLdgrQueryType.GROUP_LDGR_BY_INST_ID,
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Group Ledger Created Successfully",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
    }
    if (enableEdit) {
      updateLedgerTransaction({
        variables: {
          token,
          input: {
            gr_ldgr_desc: removeMoreSpace(formData.groupname),
            gr_ldgr_alias: removeMoreSpace(formData.groupaliasname),
            gr_ldgr_aie: groupLedgerUnder.value,
            gr_ldgr_ob: 0,
          },
          user_details,
          inst_id: InstId,
          id: groupLedgerId.value,
        },
        refetchQueries: [
          {
            query: AccountGroupLdgrsTypeByInstId,
            variables: {
              token,
              inst_id: InstId,
            },
          },
          {
            query: GetAcctGroupLdgrs,
            variables: {
              token,
              input: {
                ids: [InstId],
                acct_group_ldgr_query_type:
                  AcctGroupLdgrQueryType.GROUP_LDGR_BY_INST_ID,
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Group Ledger Updated Successfully",
            flag: true,
            operation: Operation.UPDATE,
          });
        }
      });
    }
  };
  const handleClear = () => {
    setFormData({ groupaliasname: "", groupname: "" });
    setgroupLedgerUnder(EMPTY_RESPONSETYPE_OBJECT);
    setSearchData("");
    setGroupLedgerId(EMPTY_RESPONSETYPE_OBJECT);
    setEnableEdit(false);
    setEnableAdd(false);
    setEnableDelete(false);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (message.operation !== Operation.NONE && message.flag) {
      //enableAdd and enableEdit should stay false when you close the success modal
      setEnableAdd(false);
      setEnableEdit(false);
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (!groupLedgerId.value || !enableEdit) return;
    GetGroupLedger({
      variables: {
        id: groupLedgerId.value,
        token,
      },
    })
      .then(({ data }) => {
        const { gr_ldgr_alias, group_ldgr_type_id, gr_ldgr_desc } = data.node;
        const result =
          GroupLedgerTypeResponseType &&
          (GroupLedgerTypeResponseType.find(
            (options) => options.value === group_ldgr_type_id
          ) as responseType);
        setFormData({
          groupname: gr_ldgr_desc,
          groupaliasname: gr_ldgr_alias,
        });
        setgroupLedgerUnder(result);
      })
      .catch(() => console.log("Error"));
    // eslint-disable-next-line
  }, [groupLedgerId, enableEdit, GetGroupLedger, token]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col">
          <Title>
            {AccountsTitles.GroupLedger.map(
              (title: LedgerTitles, index: Key) => {
                return (
                  <React.Fragment key={index}>
                    {enableAdd
                      ? title.CREATE
                      : enableEdit
                      ? title.UPDATE
                      : title.GENERAL}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>
        <div className="configuration-settings">
          {USE_CONFIG_KEY && (
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          )}
        </div>
      </div>
      <Formik
        initialValues={formData}
        validationSchema={groupLedgerValidation}
        onSubmit={handleLedgerTransactions}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className="group-ledger">
              <div className="row g-0 group-ledger__blocks">
                <div className="col account-frames group-ledger__frames">
                  {enableEdit && (
                    <div className="label-grid">
                      <Label variant="LabelPrimary">
                        <b>Select Group Ledger</b>
                      </Label>
                      <Autocomplete
                        classes={groupLedgerId ? selectClasses : classes}
                        options={groupLedgers.responseType}
                        openOnFocus
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        value={groupLedgerId}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((formdata) => {
                              return {
                                ...formdata,
                                groupaliasname: "",
                                groupname: "",
                              };
                            });
                            setgroupLedgerUnder(EMPTY_RESPONSETYPE_OBJECT);
                            setGroupLedgerId({
                              label: newValue.label,
                              value: newValue.value,
                              isChecked: true,
                            });
                          } else {
                            setGroupLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.BACKSPACE) {
                            setGroupLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                          } else if (e.key === Keys.ENTER) {
                            handleMUISelectEvent(e);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            autoFocus={enableEdit}
                            onChange={(e) => setSearchData(e.target.value)}
                            {...params}
                            fullWidth
                            classes={{ root: textClasses.formControlRoot }}
                          />
                        )}
                      />
                    </div>
                  )}
                  {showForm ? (
                    Accounts.GroupLedger.map(
                      (label: LabelNameProps, index: Key) => {
                        return (
                          <React.Fragment key={index}>
                            <Input
                              name={label.inputName}
                              LabelName={label.LabelName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleValueChange(e);
                                meta.handleChange(e);
                              }}
                              values={formData[label.inputName]}
                              inputRef={label.ref && groupLedgerRef!}
                              required={
                                label.required && !formData[label.inputName]
                                  ? true
                                  : false
                              }
                              autoFocus={enableAdd && label.autoFocus}
                              disabled={!enableAdd && !enableEdit}
                            />
                          </React.Fragment>
                        );
                      }
                    )
                  ) : (
                    <div className="LedgerEditWarning">
                      Please Select the GroupLedger to be edited
                    </div>
                  )}
                  {showForm && (
                    <div className="label-grid">
                      <Label>Under</Label>
                      <Autocomplete
                        classes={classes}
                        options={GroupLedgerTypeResponseType}
                        openOnFocus
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        value={
                          GroupLedgerTypeResponseType?.find(
                            ({ value }) => value === groupLedgerUnder?.value
                          )! ?? null
                        }
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setgroupLedgerUnder(newValue);
                          } else {
                            setgroupLedgerUnder(EMPTY_RESPONSETYPE_OBJECT);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            saveRef?.current?.focus();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setgroupLedgerUnder(EMPTY_RESPONSETYPE_OBJECT);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            classes={{ root: textClasses.formControlRoot }}
                          />
                        )}
                        disabled={!enableAdd && !enableEdit}
                      />
                    </div>
                  )}
                </div>

                <div className="col account-frames group-ledger__frames">
                  <Tree />
                </div>
              </div>
              <div className="group-ledger__btns">
                <>
                  {enableAdd || enableEdit ? (
                    <>
                      <Button mode="save" buttonref={saveRef!} type="submit" />

                      <Button mode="clear" onClick={handleClear} />

                      <Button
                        mode="back"
                        onClick={() => {
                          handleClear();
                          setEnableAdd(false);
                          setEnableEdit(false);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        autoFocus
                        mode="addnew"
                        type="submit"
                        onClick={() => {
                          setMessage({
                            message: "",
                            flag: false,
                            operation: Operation.NONE,
                          });
                          setEnableAdd(true);
                        }}
                      />

                      <Button
                        mode="edit"
                        type="button"
                        onClick={() => {
                          handleClear();
                          setEnableEdit(true);
                        }}
                      />

                      <Button
                        mode="delete"
                        type="button"
                        onClick={() => {
                          handleClear();
                          setEnableDelete(true);
                        }}
                      />

                      <Button mode="back" onClick={() => navigate(-1)} />
                    </>
                  )}
                </>
              </div>
            </Form>
          );
        }}
      </Formik>
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.GROUP_LEDGER_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      {/* deleteModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={enableDelete}
        style={DeleteLedgerModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Delete setEnableDelete={setEnableDelete} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setEnableDelete(!enableDelete)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={creationLoading || updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default GroupLedger;
