import React, { useRef, useState } from "react";
import EventsList from "../../../images/EventsList.svg";
import Close from "../../../images/Close.svg";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import { Autocomplete, FormControlLabel, TextField } from "@mui/material";

import usePredefinedDataByType from "../../../customhooks/usePredefinedDataByType";
import {
  Direction,
  HolidayDuration,
  HolidayType,
  Operation,
  PredefinedDataTypes,
  SortBy,
} from "../../../utils/Enum.types";
import { DEFAULT_TIME, EMPTY_STRING, INPUT } from "../../../utils/constants";
import { msgType, optionsType } from "../../../utils/Form.types";
import {
  getDateRangeByMonth,
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { AntSwitch } from "../../../pages/Switch";
import {
  AddAcdInstGeneralHolidayVacations,
  AddAcdInstGeneralHolidays,
  DeleteAcdInstGeneralHolidayByDate,
  DeleteAcdInstGeneralHolidayByIds,
  UpdateAcdInstGeneralHolidayById,
} from "../queries/holidays/mutation";
import { useMutation } from "@apollo/client";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { ActiveAcademicYearData } from "../../../Types/Accounting";
import { ModalType } from "./HolidayEntry/Index";
import { GetAcdInstGeneralHolidays } from "../queries/holidays/list";
import useAcdGeneralHolidays, {
  holiday_query_type,
} from "../hooks/useAcdGeneralHolidays";
import MessageModal from "../../../pages/MessageModal";
import { GetAcdInstGeneralHolidaysNode } from "../types/attendance";
import { Label } from "../../../stories/Label/Label";
import Edit from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import CalendarForInput, {
  CustomisedInputFor,
} from "../../../utils/CalendarForInput";
import useHolidaysList from "../../../customhooks/useHolidaysList";
import { colors } from "./HolidayEntry/CustomizedCalendar";
import { Keys } from "../../../utils/Enum.keys";
import AddNew from "../../../images/Add.svg";
import TextArea from "../../../stories/TextArea/TextArea";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
interface Props {
  InsertModal: ModalType;
  setInsertModal: React.Dispatch<React.SetStateAction<ModalType>>;
  acdYearId: ActiveAcademicYearData;
}
interface inputType {
  holiday_type: HolidayType;
  holiday_duration: string;
  holiday_desc: string;
  week_idx: number;
  day_idx: number;
  holiday_date: string;
}
interface LiProps {
  data: GetAcdInstGeneralHolidaysNode;
  index: number;
}
export const defaultWeekAndDailyIdx = 256;
const AddHoliday = ({ InsertModal, setInsertModal, acdYearId }: Props) => {
  const classes = formAutoCompleteStyles();
  const [holiday_desc, setHolidayDesc] = useState("");
  const textClasses = formAutoCompleteTextStyles();
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const { endDate: lastDate, startDate } =
    getDateRangeByMonth(
      new Date(InsertModal.date).getFullYear(),
      new Date(InsertModal.date).getMonth()
    ) || {};
  const [endDate, setEndDate] = useState(InsertModal.date);
  const [enableEdit, setEnableEdit] = useState({
    flag: false,
    id: 0,
  });
  const [showButton, setShowButton] = useState(false);
  const [refetchQuery, setRefetchQuery] = useState(false);
  const { PredefinedData: HolidayDurartion } = usePredefinedDataByType(
    PredefinedDataTypes.HOLIDAY_FULL_HALF_DAY,
    EMPTY_STRING
  );
  const { PredefinedData: HolidayTypes } = usePredefinedDataByType(
    PredefinedDataTypes.HOLIDAY_TYPE,
    EMPTY_STRING
  );
  const { InstGeneralHolidays } = useAcdGeneralHolidays(
    acdYearId ? acdYearId.id : 0,
    InsertModal.operation === Operation.CREATE
      ? endDate.toString()
      : DEFAULT_TIME,
    InsertModal.date.toString(),
    EMPTY_STRING,
    holiday_query_type.HOLIDAY_BY_DATE
  );

  const [holidayDuration, setHolidayDuration] = useState<optionsType | null>(
    null
  );
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [holidayType, setHolidayType] = useState<optionsType | null>(null);
  const [makeEvent, setMakeEvent] = useState(false);
  const [makeVacation, setMakeVacation] = useState(false);
  const [makeAsWorking, setMakeAsWorking] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [events, setEvents] = useState<inputType[]>([]);
  const holidayDurationRef = useRef<HTMLInputElement>(null);
  const holidayTypeRef = useRef<HTMLInputElement>(null);
  const addAnotherEventRef = useRef<HTMLButtonElement>(null);
  const holidayDescRef = document.getElementsByName("holiday_desc")[0];
  const endDateRef = document.getElementsByName("end_date")[0];
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  const LiComponent: React.FC<LiProps> = ({ data, index }) => {
    return (
      <li>
        <span
          className="events-in-calendar"
          style={{
            backgroundColor: index >= colors.length ? "#0ea5e9" : colors[index],
          }}
          key={index}
        />
        <Label>{index + 1}</Label>
        <div className="calendar-holiday-list--data--holiday-list--events">
          <div>
            <b>{data.holiday_desc}</b>
          </div>
          <div className="calendar-holiday-list--data--holiday-list--desc">
            {data.holiday_duration}
          </div>
        </div>
        {!(data.holiday_type === HolidayType.VACATION) ? (
          <img
            src={Edit}
            onClick={() => {
              prefillForUpdate(data.id);
              setShowButton(true);
            }}
            alt="\"
          />
        ) : null}

        <img src={Delete} onClick={() => HandleDelete(data.id)} alt="\" />
      </li>
    );
  };

  const [AddHoliday] = useMutation(AddAcdInstGeneralHolidays, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [AddVacation] = useMutation(AddAcdInstGeneralHolidayVacations, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateHoliday] = useMutation(UpdateAcdInstGeneralHolidayById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [DeleteHoliday] = useMutation(DeleteAcdInstGeneralHolidayByIds, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [DeleteByDate] = useMutation(DeleteAcdInstGeneralHolidayByDate, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const dates = useHolidaysList(endDate, acdYearId?.id);
  const handleAnotherEvent = () => {
    if (holiday_desc.trim()) {
      const alreadyExistingEvent = events.find(
        (event) => event.holiday_desc === holiday_desc
      );
      if (alreadyExistingEvent) {
        setMessage({
          flag: true,
          message: "Event with same description already exists",
          operation: Operation.NONE,
        });
      } else {
        setEvents((prevValues) => [
          ...prevValues,
          {
            holiday_type: HolidayType.EVENT,
            holiday_duration: HolidayDuration.FULLDAY,
            holiday_desc,
            week_idx: defaultWeekAndDailyIdx,
            day_idx: defaultWeekAndDailyIdx,
            holiday_date: toIsoDate(InsertModal.date.toString()),
          },
        ]);
        setHolidayDesc("");
        holidayDescRef.focus();
      }
    } else {
      setMessage({
        flag: true,
        message: "Cannot add empty holiday description",
        operation: Operation.NONE,
      });
    }
  };
  const handleHoliday = () => {
    setButtonDisabled(true);
    const commonInput = {
      holiday_desc,
      week_idx: defaultWeekAndDailyIdx,
      day_idx: defaultWeekAndDailyIdx,
    };
    const input = makeEvent
      ? events.length
        ? events
        : {
            holiday_type: HolidayType.EVENT,
            holiday_duration: HolidayDuration.FULLDAY,
            holiday_date: toIsoDate(InsertModal.date.toString()),
            ...commonInput,
          }
      : !makeVacation && {
          holiday_type: holidayType?.value,
          holiday_duration: holidayDuration?.value,
          holiday_date: toIsoDate(InsertModal.date.toString()),
          ...commonInput,
        };
    if (enableEdit && InsertModal.operation === Operation.UPDATE) {
      UpdateHoliday({
        variables: {
          token,
          id: enableEdit.id,
          inst_id: InstId,
          user_details,
          input: {
            holiday_desc,
            holiday_duration: holidayDuration?.value,
            holiday_type: makeEvent
              ? HolidayType.EVENT
              : makeVacation
              ? HolidayType.VACATION
              : holidayType?.value,
          },
        },
        refetchQueries: [
          {
            query: GetAcdInstGeneralHolidays,
            variables: {
              acd_yr_id: acdYearId?.id,
              input: {
                end_date: toIsoDate(lastDate.toString()),
                in_between_flag: true,
                start_date: toIsoDate(startDate.toString()),
                holiday_query_type: holiday_query_type.ALL_HOLIDAYS,
              },
              orderBy: {
                direction: Direction.ASC,
                field: SortBy.HOLIDAY_DATE,
              },
              inst_id: InstId,
              token,
              holidayDesc: EMPTY_STRING,
            },
          },
          {
            query: GetAcdInstGeneralHolidays,
            variables: {
              acd_yr_id: acdYearId?.id,
              input: {
                end_date: toIsoDate(lastDate.toString()),
                in_between_flag: true,
                start_date: toIsoDate(startDate.toString()),
                holiday_query_type:
                  holiday_query_type.GENERAL_AND_UNEXPECTED_HOLIDAYS,
              },
              orderBy: {
                direction: Direction.ASC,
                field: SortBy.HOLIDAY_DATE,
              },
              inst_id: InstId,
              token,
              holidayDesc: EMPTY_STRING,
            },
          },
        ],
      }).then(({ data }) => {
        if (data && data.UpdateAcdInstGeneralHolidayById) {
          setMessage({
            flag: true,
            message: `Holiday for Date ${toStandardDate(
              InsertModal.date.toString()
            )} updated`,
            operation: Operation.UPDATE,
          });
        }
      });
    } else {
      if (makeVacation) {
        AddVacation({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            acd_yr_id: acdYearId.id,
            start_date: toIsoDate(InsertModal.date.toString()),
            end_date: toIsoDate(endDate.toString()),
            holiday_desc,
          },
          refetchQueries: [
            {
              query: GetAcdInstGeneralHolidays,
              variables: {
                acd_yr_id: acdYearId?.id,
                input: {
                  end_date: toIsoDate(lastDate.toString()),
                  in_between_flag: true,
                  start_date: toIsoDate(startDate.toString()),
                  holiday_query_type: holiday_query_type.ALL_HOLIDAYS,
                },
                orderBy: {
                  direction: Direction.ASC,
                  field: SortBy.HOLIDAY_DATE,
                },
                inst_id: InstId,
                token,
                holidayDesc: EMPTY_STRING,
              },
            },
            {
              query: GetAcdInstGeneralHolidays,
              variables: {
                acd_yr_id: acdYearId?.id,
                input: {
                  end_date: toIsoDate(lastDate.toString()),
                  in_between_flag: true,
                  start_date: toIsoDate(startDate.toString()),
                  holiday_query_type:
                    holiday_query_type.GENERAL_AND_UNEXPECTED_HOLIDAYS,
                },
                orderBy: {
                  direction: Direction.ASC,
                  field: SortBy.HOLIDAY_DATE,
                },
                inst_id: InstId,
                token,
                holidayDesc: EMPTY_STRING,
              },
            },
          ],
        }).then(({ data }) => {
          if (data && data.AddAcdInstGeneralHolidayVacations) {
            setMessage({
              flag: true,
              message: `Vacation from ${toStandardDate(
                InsertModal.date.toString()
              )} and  ${toStandardDate(endDate.toString())} created`,
              operation: Operation.CREATE,
            });
          }
        });
      } else {
        AddHoliday({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            acd_yr_id: acdYearId?.id,
            input: input,
          },
          refetchQueries: [
            {
              query: GetAcdInstGeneralHolidays,
              variables: {
                acd_yr_id: acdYearId?.id,
                input: {
                  end_date: toIsoDate(lastDate.toString()),
                  in_between_flag: true,
                  start_date: toIsoDate(startDate.toString()),
                  holiday_query_type: holiday_query_type.ALL_HOLIDAYS,
                },
                orderBy: {
                  direction: Direction.ASC,
                  field: SortBy.HOLIDAY_DATE,
                },
                inst_id: InstId,
                token,
                holidayDesc: EMPTY_STRING,
              },
            },
            {
              query: GetAcdInstGeneralHolidays,
              variables: {
                acd_yr_id: acdYearId?.id,
                input: {
                  end_date: toIsoDate(lastDate.toString()),
                  in_between_flag: true,
                  start_date: toIsoDate(startDate.toString()),
                  holiday_query_type:
                    holiday_query_type.GENERAL_AND_UNEXPECTED_HOLIDAYS,
                },
                orderBy: {
                  direction: Direction.ASC,
                  field: SortBy.HOLIDAY_DATE,
                },
                inst_id: InstId,
                token,
                holidayDesc: EMPTY_STRING,
              },
            },
          ],
        }).then(({ data }) => {
          if (data && data.AddAcdInstGeneralHolidays) {
            setRefetchQuery(!refetchQuery);
            const holidayType = `${
              makeEvent ? "Event" : makeVacation ? "Vacation" : "Holiday"
            }`;
            const messageAccordingToHolidayType = `${
              makeVacation
                ? "from Date" +
                  toStandardDate(InsertModal.date.toString()) +
                  "to" +
                  toStandardDate(InsertModal.date.toString())
                : "for Date " + toStandardDate(InsertModal.date.toString())
            }`;
            setMessage({
              flag: true,
              message: `${holidayType} ${messageAccordingToHolidayType} created`,
              operation: Operation.CREATE,
            });
          }
        });
      }
    }
  };
  const handleDeleteByDate = () => {
    DeleteByDate({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: acdYearId?.id,
        holiday_date: toIsoDate(InsertModal.date.toString()),
      },
      refetchQueries: [
        {
          query: GetAcdInstGeneralHolidays,
          variables: {
            acd_yr_id: acdYearId?.id,
            input: {
              end_date: toIsoDate(lastDate.toString()),
              in_between_flag: true,
              start_date: toIsoDate(startDate.toString()),
              holiday_query_type: holiday_query_type.ALL_HOLIDAYS,
            },
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.HOLIDAY_DATE,
            },
            inst_id: InstId,
            token,
            holidayDesc: EMPTY_STRING,
          },
        },
        {
          query: GetAcdInstGeneralHolidays,
          variables: {
            acd_yr_id: acdYearId?.id,
            input: {
              end_date: toIsoDate(lastDate.toString()),
              in_between_flag: true,
              start_date: toIsoDate(startDate.toString()),
              holiday_query_type:
                holiday_query_type.GENERAL_AND_UNEXPECTED_HOLIDAYS,
            },
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.HOLIDAY_DATE,
            },
            inst_id: InstId,
            token,
            holidayDesc: EMPTY_STRING,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.DeleteAcdInstGeneralHolidayByDate) {
        setMessage({
          flag: true,
          message: "Made Working date Successfully",
          operation: Operation.DELETE,
        });
      }
    });
  };
  const handleClear = () => {
    setHolidayDesc("");
    setHolidayDuration(null);
    setHolidayType(null);
    // holidayDescRef.focus();
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setInsertModal((prev) => ({
        ...prev,
        flag: false,
      }));
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const prefillForUpdate = (id: number) => {
    if (InstGeneralHolidays.data) {
      const foundData =
        InstGeneralHolidays.data.GetAcdInstGeneralHolidays.edges.find(
          ({ node }) => node.id === id
        );
      if (foundData) {
        setHolidayDesc(foundData.node.holiday_desc);
        setHolidayType(
          HolidayTypes.dropDown.find(
            ({ value }) => value === foundData.node.holiday_type
          ) ?? null
        );
        setHolidayDuration(
          HolidayDurartion.dropDown.find(
            ({ value }) => value === foundData.node.holiday_duration
          ) ?? null
        );
        if (foundData.node.holiday_type === HolidayType.VACATION) {
          setMakeVacation(true);
        }
        if (foundData.node.holiday_type === HolidayType.EVENT) {
          setMakeEvent(true);
        }
      }
      setEnableEdit({
        flag: true,
        id,
      });
    }
  };
  const HandleDelete = (id: Number) => {
    DeleteHoliday({
      variables: {
        token,
        ids: id,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdInstGeneralHolidays,
          variables: {
            acd_yr_id: acdYearId?.id,
            input: {
              end_date: toIsoDate(lastDate.toString()),
              in_between_flag: true,
              start_date: toIsoDate(startDate.toString()),
              holiday_query_type: holiday_query_type.ALL_HOLIDAYS,
            },
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.HOLIDAY_DATE,
            },
            inst_id: InstId,
            token,
            holidayDesc: EMPTY_STRING,
          },
        },
        {
          query: GetAcdInstGeneralHolidays,
          variables: {
            acd_yr_id: acdYearId?.id,
            input: {
              end_date: toIsoDate(lastDate.toString()),
              in_between_flag: true,
              start_date: toIsoDate(startDate.toString()),
              holiday_query_type:
                holiday_query_type.GENERAL_AND_UNEXPECTED_HOLIDAYS,
            },
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.HOLIDAY_DATE,
            },
            inst_id: InstId,
            token,
            holidayDesc: EMPTY_STRING,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Holiday Deleted Successfully",
          operation: Operation.DELETE,
        });
      }
    });
  };

  return (
    <>
      <div className="calendar-holiday-list">
        {InsertModal.operation === Operation.CREATE ||
        (InsertModal.operation === Operation.UPDATE && enableEdit.flag) ? (
          <>
            <div className="calendar-holiday-list--title">
              <Title variant="subtitle1">
                <img src={EventsList} alt="" /> &nbsp;{" "}
                {showButton ? "Update" : "Add"}{" "}
                {makeEvent ? "Event" : makeVacation ? "Vacation" : "Holiday"}
              </Title>
              <img
                src={Close}
                alt=""
                className="modal-close-icon"
                onClick={() => {
                  if (InsertModal.operation === Operation.CREATE) {
                    setInsertModal((prev) => ({
                      ...prev,
                      flag: false,
                    }));
                  } else {
                    setEnableEdit({
                      flag: false,
                      id: 0,
                    });
                  }
                }}
              />
            </div>
            <div className="calendar-holiday-list__add-holiday">
              <div className="row g-0">
                <div className="label-grid">
                  <Label>Description</Label>
                  <TextArea
                    name="holiday_desc"
                    value={holiday_desc}
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER && !e.shiftKey) {
                        e.preventDefault();
                        if (makeEvent && holiday_desc.trim()) {
                          addAnotherEventRef.current?.focus();
                        }
                        if (makeVacation) {
                          endDateRef.focus();
                        }
                        if (!makeVacation && !makeEvent) {
                          (
                            holidayDurationRef.current?.getElementsByTagName(
                              INPUT
                            )[0] as HTMLInputElement
                          ).focus();
                        }
                      }
                    }}
                    onChange={(e) => {
                      setHolidayDesc(e.target.value);
                    }}
                    rows={3}
                  />
                </div>

                {!makeEvent && !makeVacation ? (
                  <>
                    <div className="label-grid">
                      <Label>Holiday Duration</Label>

                      <Autocomplete
                        classes={classes}
                        options={HolidayDurartion.dropDown}
                        value={holidayDuration}
                        isOptionEqualToValue={(option) =>
                          isOptionEqualToValue(option, holidayDuration)
                        }
                        ref={holidayDurationRef}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setHolidayDuration(newValue);
                          } else {
                            setHolidayDuration(null);
                          }
                        }}
                        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                          if (e.key === Keys.ENTER) {
                            (
                              holidayTypeRef.current?.getElementsByTagName(
                                INPUT
                              )[0] as HTMLInputElement
                            ).focus();
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            classes={{ root: textClasses.formControlRoot }}
                          />
                        )}
                      />
                      <Label>Holiday Type</Label>
                      <Autocomplete
                        classes={classes}
                        options={HolidayTypes.dropDown}
                        value={holidayType}
                        ref={holidayTypeRef}
                        isOptionEqualToValue={(option) =>
                          isOptionEqualToValue(option, holidayType)
                        }
                        onChange={(e, newValue) => {
                          e.preventDefault();
                          if (newValue) {
                            setHolidayType(newValue);
                          } else {
                            setHolidayType(null);
                          }
                        }}
                        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            saveButtonRef.current?.focus();
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            classes={{ root: textClasses.formControlRoot }}
                          />
                        )}
                      />
                    </div>
                  </>
                ) : null}
                <div className="label-grid">
                  <Label>{makeVacation ? "Start Date" : "Date"}</Label>
                  <TextField
                    type="date"
                    disabled
                    name="end_date"
                    value={toInputStandardDate(InsertModal.date.toString())}
                    className="holiday-entry__add--textfield--date"
                  />
                </div>
                <div className="frame-space">
                  {makeVacation && (
                    <CalendarForInput
                      date={endDate}
                      dates={dates}
                      setDate={setEndDate}
                      allowFutureDatesAccess={true}
                      inputFor={CustomisedInputFor.GENERAL}
                      label="End Date"
                    />
                  )}
                </div>

                <FormControlLabel
                  label="Make a Event"
                  labelPlacement="start"
                  control={
                    <AntSwitch
                      checked={makeEvent}
                      onClick={() => {
                        setMakeEvent(!makeEvent);
                        setMakeVacation(false);
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label="Make a Vacation"
                  labelPlacement="start"
                  control={
                    <AntSwitch
                      checked={makeVacation}
                      onClick={() => {
                        setMakeVacation(!makeVacation);
                        setMakeEvent(false);
                      }}
                    />
                  }
                />
                {makeEvent && !showButton ? (
                  <>
                    <div>
                      <Button
                        onClick={handleAnotherEvent}
                        disabled={!holiday_desc.trim()}
                        buttonref={addAnotherEventRef}
                      >
                        <img src={AddNew} alt="" /> Add Another Event
                      </Button>
                    </div>
                    <ol>
                      {events.map((event, index) => (
                        <li key={index}>
                          {event.holiday_desc}{" "}
                          <img
                            src={Delete}
                            alt="/"
                            onClick={() =>
                              setEvents(events.filter((_, i) => i !== index))
                            }
                          />
                        </li>
                      ))}
                    </ol>
                  </>
                ) : null}
              </div>
            </div>

            <Button
              mode="save"
              disabled={buttonDisabled}
              onClick={handleHoliday}
              buttonref={saveButtonRef}
            />
            <Button mode="clear" onClick={handleClear} />
            <Button
              mode="cancel"
              onClick={() => {
                if (InsertModal.operation === Operation.CREATE) {
                  setInsertModal((prev) => ({
                    ...prev,
                    flag: false,
                  }));
                } else {
                  setEnableEdit({
                    flag: false,
                    id: 0,
                  });
                }
              }}
            />
          </>
        ) : (
          <>
            <div className="calendar-holiday-list--title">
              <Title>
                Date : {toStandardDate(InsertModal.date.toString())}
              </Title>
              <img
                src={Close}
                alt=""
                className="modal-close-icon"
                onClick={() =>
                  setInsertModal((prev) => ({
                    ...prev,
                    flag: false,
                  }))
                }
              />
            </div>

            {InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.filter(
              ({ node }) =>
                node.holiday_type === HolidayType.UNEXPECTED ||
                node.holiday_type === HolidayType.GENERAL
            ).length ? (
              <>
                <Title>Holidays</Title>
                <div className="calendar-holiday-list--data--holidays">
                  <ul className="calendar-holiday-list--data--holiday-list">
                    {InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges
                      .filter(
                        ({ node }) =>
                          node.holiday_type === HolidayType.UNEXPECTED ||
                          node.holiday_type === HolidayType.GENERAL
                      )
                      .map(({ node: data }, index) => (
                        <LiComponent data={data} index={index} />
                      ))}
                  </ul>
                </div>
              </>
            ) : null}
            {InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.filter(
              ({ node }) => node.holiday_type === HolidayType.WEEKEND
            ).length ? (
              <div>
                <Title>Weekends</Title>
                <ul className="calendar-holiday-list--data--holiday-list">
                  {InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges
                    .filter(
                      ({ node }) => node.holiday_type === HolidayType.WEEKEND
                    )
                    .map(({ node: data }, index) => (
                      <LiComponent data={data} index={index} />
                    ))}
                </ul>
              </div>
            ) : null}
            {InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.filter(
              ({ node }) => node.holiday_type === HolidayType.EVENT
            ).length ? (
              <div>
                <Title>Events</Title>

                <ul className="calendar-holiday-list--data--events-list">
                  {InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges
                    .filter(
                      ({ node }) => node.holiday_type === HolidayType.EVENT
                    )
                    .map(({ node: data }, index) => (
                      <LiComponent data={data} index={index} />
                    ))}
                </ul>
              </div>
            ) : null}
            {InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges.filter(
              ({ node }) => node.holiday_type === HolidayType.VACATION
            ).length ? (
              <div>
                <Title>Vacation</Title>

                <ul className="calendar-holiday-list--data--events-list">
                  {InstGeneralHolidays.data?.GetAcdInstGeneralHolidays.edges
                    .filter(
                      ({ node }) => node.holiday_type === HolidayType.VACATION
                    )
                    .map(({ node: data }, index) => (
                      <LiComponent data={data} index={index} />
                    ))}
                </ul>
              </div>
            ) : null}
            <div className="row g-0">
              <FormControlLabel
                label="Make as working day"
                labelPlacement="start"
                control={
                  <AntSwitch
                    checked={makeAsWorking}
                    onClick={() => {
                      setMakeAsWorking(!makeAsWorking);
                    }}
                  />
                }
              />
            </div>
            <Button
              mode="save"
              disabled={!makeAsWorking}
              onClick={handleDeleteByDate}
            />
            <Button
              mode="cancel"
              onClick={() =>
                setInsertModal((prev) => ({
                  ...prev,
                  flag: false,
                }))
              }
            />
          </>
        )}
      </div>

      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default AddHoliday;
