import React, { useEffect, useState } from "react";
import usePredefinedDataByType from "../../../../customhooks/usePredefinedDataByType";
import { Operation, PredefinedDataTypes } from "../../../../utils/Enum.types";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { optionsType } from "../../../../utils/Form.types";
import { Keys } from "../../../../utils/Enum.keys";
import { Button } from "../../../../stories/Button/Button";
import { TableHeaderProps } from "../../../../Types/Tables";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import Delete from "../../../../images/Delete.svg";
import { emptyMessageType } from "../../../../utils/constants";
import {
  AddInstUploadDocTypes,
  DeleteInstUploadDocTypes,
  ReOrderInstUploadDocTypes,
} from "../../../../queries/students/mutations/new";
import { useLazyQuery, useMutation } from "@apollo/client";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useToken from "../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import LoadingModal from "../../../../pages/LoadingModal";
import { GetMstInstUploadDocTypes } from "../../../../queries/students/list/newApi";
import MessageModal from "../../../../pages/MessageModal";
import DeleteModal from "../../../../pages/DeleteModal";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import { reOrderProcess } from "../../../../utils/UtilFunctions";
export interface Vars {
  token: string;
  inst_id: string | number;
}
export interface GetMstInstUploadDocTypesList {
  id: number;
  document_name: string;
  doc_idx: number;
  inst_id: number;
}
export interface GetMstInstUploadDocTypesData {
  GetMstInstUploadDocTypes: GetMstInstUploadDocTypesList[];
}

interface docsType extends optionsType {
  fromDatabase: boolean;
  id: number;
}

const StudentDocs = () => {
  const listClasses = ListAutoCompleteStyles();
  const [searchData, setSearchData] = useState("");
  const [message, setMessage] = useState(emptyMessageType);
  const { user_details } = useLoggedInUserDetails();
  const { token } = useToken();
  const { InstId } = useParams();
  const [deleteId, setDeleteId] = useState(-1);
  const [selectedFromDb, setSelectedFromDb] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate();
  const [reOrder, setReOrder] = useState(false);

  const [GetDocs, { data }] = useLazyQuery<GetMstInstUploadDocTypesData, Vars>(
    GetMstInstUploadDocTypes,
    {
      variables: {
        inst_id: InstId!,
        token,
      },
    }
  );
  const [AddUploadDocTypes, { loading }] = useMutation(AddInstUploadDocTypes, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [DeleteDoc, { loading: deleteLoading }] = useMutation(
    DeleteInstUploadDocTypes,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [ReOrderDocs, { loading: reorderLoading }] = useMutation(
    ReOrderInstUploadDocTypes,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const { PredefinedData } = usePredefinedDataByType(
    PredefinedDataTypes.CERTIFICATE,
    searchData
  );
  const { StudentsDefaultDocs } = useMasterTableJson();
  const [localItems, setLocalItems] = useState<docsType[]>([]);

  const [documents, setDocuments] = useState<docsType[]>([]);

  const textClasses = ListAutoCompleteTextStyles();

  const [documentType, setDocumentType] = useState<optionsType | null>(null);
  const handleItems = () => {
    const alreadyExists = documents.find(
      (item) => documentType && item.value === documentType.value
    )
      ? true
      : false;
    if (alreadyExists === false) {
      if (documentType)
        setDocuments((prev) => [
          ...prev,
          { fromDatabase: false, id: documents.length + 1, ...documentType },
        ]);
      //@ts-ignore
      setLocalItems((prev) => [
        ...prev,
        { fromDatabase: false, id: documents.length + 1, ...documentType },
      ]);
    } else
      setMessage({
        flag: true,
        message: `${documentType ? documentType.label : ""} already exists`,
        operation: Operation.NONE,
      });

    setDocumentType(null);
  };
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    setLocalItems((prev) => {
      const temp = [...prev];

      reOrderProcess(result, temp);
      setDocuments(temp);
      setLocalItems(temp);

      return temp;
    });
  };
  const handleSaveOrder = () => {
    // eslint-disable-next-line
    ReOrderDocs({
      variables: {
        token,
        input: localItems?.map((res, index) => ({
          doc_type_id: res.id,
          doc_idx: index + 1,
        })),
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetMstInstUploadDocTypes,
          variables: {
            inst_id: InstId!,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Re-Order Successfull",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
    setReOrder(!reOrder);
  };
  const handleSubmit = () => {
    AddUploadDocTypes({
      variables: {
        token,
        user_details,
        inst_id: InstId,
        input: documents
          .filter((i) => i.fromDatabase === false)
          .map((item, index) => ({
            document_name: item.value,
            doc_idx: index + 1,
          })),
      },
      refetchQueries: [
        {
          query: GetMstInstUploadDocTypes,
          variables: {
            inst_id: InstId!,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.AddInstUploadDocTypes) {
        setMessage({
          flag: true,
          message: "Added Successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  useEffect(() => {
    if (token && InstId) {
      GetDocs().then(({ data }) => {
        if (data && data.GetMstInstUploadDocTypes) {
          setLocalItems(
            data.GetMstInstUploadDocTypes.map((item) => ({
              fromDatabase: true,
              label: item.document_name,
              value: item.document_name,
              id: item.id,
            }))
          );
        }
      });
    }
  }, [token, InstId, GetDocs, data]);

  const handleDelete = (docsType: docsType) => {
    setDeleteId(docsType.id);
    setSelectedFromDb(docsType.fromDatabase);
    setDeleteModal(!deleteModal);
  };

  const deleteForModal = () => {
    if (selectedFromDb) {
      DeleteDoc({
        variables: {
          token,
          doc_type_id: deleteId,
          inst_id: InstId,
          user_details,
        },
        refetchQueries: [
          {
            query: GetMstInstUploadDocTypes,
            variables: {
              inst_id: InstId!,
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data && data.DeleteInstUploadDocTypes) {
          setMessage({
            flag: true,
            message: "Deleted Successfully",
            operation: Operation.DELETE,
          });
          setDeleteModal(!deleteModal);
        }
      });
    } else {
      const filterDocs = documents.filter((item) => item.id !== deleteId);
      setDocuments(filterDocs);
      setLocalItems(filterDocs);

      setDeleteModal(!deleteModal);
    }
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Student Default Documents</Title>
      <div className="student-default-docs">
        <div className="row g-0 student-default-docs__details justify-content-center">
          <div className="col-6">
            <div className="row g-0">
              <div className="col-3">
                <Autocomplete
                  classes={listClasses}
                  options={PredefinedData.dropDown}
                  openOnFocus
                  value={documentType}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setDocumentType(newValue as optionsType);
                    } else {
                      setDocumentType(null);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.BACKSPACE) {
                      setDocumentType(null);
                    }
                  }}
                  ListboxProps={{
                    onScroll: PredefinedData.handleScroll,
                  }}
                  forcePopupIcon
                  renderInput={(params) => (
                    <TextField
                      classes={{ root: textClasses.formControlRoot }}
                      {...params}
                      onChange={(e) => setSearchData(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="Document Type"
                    />
                  )}
                />
              </div>
              <div className="col"></div>
              <div className="col-2 flex-end">
                <Button mode="addnew" onClick={handleItems} />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0 student-default-docs__block justify-content-center">
          <div className="col-6 student-default-docs__block--table">
            <TableContainer className="student-default-docs__table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {reOrder ? <TableCell></TableCell> : null}

                    {StudentsDefaultDocs.Table_Headers.filter(
                      ({ labelName }: TableHeaderProps) =>
                        !(labelName === "Actions" && reOrder)
                    ).map((th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided: DroppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {localItems &&
                          localItems.map((response, index: number) => (
                            <Draggable
                              key={index}
                              draggableId={index.toString()}
                              index={index}
                              isDragDisabled={!reOrder}
                            >
                              {(
                                draggableProvided: DraggableProvided,
                                snapshot: DraggableStateSnapshot
                              ) => {
                                return (
                                  <React.Fragment key={index}>
                                    <TableRow
                                      key={index}
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                    >
                                      {reOrder ? (
                                        <TableCell
                                          {...draggableProvided.dragHandleProps}
                                          id="td-center"
                                          className="institution__table--slno"
                                        >
                                          =
                                        </TableCell>
                                      ) : null}
                                      <TableCell
                                        id="td-center"
                                        className="student-default-docs__table--slno"
                                      >
                                        {index + 1}
                                      </TableCell>

                                      <TableCell>{response.label}</TableCell>

                                      {reOrder === false ? (
                                        <TableCell
                                          id="td-center"
                                          {...draggableProvided.dragHandleProps}
                                          className="institution__table--action"
                                        >
                                          <>
                                            <img
                                              src={Delete}
                                              alt="/"
                                              onClick={() =>
                                                handleDelete(response)
                                              }
                                            />
                                          </>
                                        </TableCell>
                                      ) : null}
                                    </TableRow>
                                  </React.Fragment>
                                );
                              }}
                            </Draggable>
                          ))}
                        {droppableProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="row g-0 justify-content-center">
          <div className="col-6">
            {!reOrder && <Button mode="save" onClick={handleSubmit} />}
            {localItems && localItems.length > 1 ? (
              reOrder ? (
                <>
                  <Button
                    mode="save-order"
                    type="button"
                    onClick={handleSaveOrder}
                  />
                </>
              ) : (
                <Button
                  mode="reorder"
                  type="button"
                  onClick={() => setReOrder(!reOrder)}
                />
              )
            ) : null}
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
        </div>
      </div>
      <LoadingModal flag={loading || deleteLoading || reorderLoading} />
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />

      <DeleteModal
        id={deleteId}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={deleteForModal}
      />
    </>
  );
};

export default StudentDocs;
