import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment } from "@mui/material";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { AppContext } from "../../context/context";
import React, { useContext, useEffect, useRef, useState } from "react";
import EduateLogo from "../../images/EduateLogo.svg";
import { Button } from "../../stories/Button/Button";
import { payloadTypes } from "../../context/reducer";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Label } from "../../stories/Label/Label";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Keys } from "../../utils/Enum.keys";
import { EMPTY_STRING } from "../../utils/constants";
import { useLazyQuery } from "@apollo/client";
import { ValidateMobileNumber } from "./mutation/new";
import { Operation } from "../../utils/Enum.types";
import { msgType } from "../../utils/Form.types";
import MessageModal from "../../pages/MessageModal";
import OtpInput from "react-otp-input";

type AuthCred = {
  email: string;
  mobileNo: string;
};

const Login = () => {
  const auth = getAuth();
  const { dispatch } = useContext(AppContext);
  const [verificationCode, setVerificationCode] = useState("");

  const passwordRef = useRef<HTMLInputElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);
  const mobileRef = useRef<HTMLButtonElement>(null);
  const loginRef = useRef<HTMLButtonElement>(null);
  const [logInOtp, setLoginOtp] = useState(false);
  const [requestOTP, setRequestOTP] = useState(false);
  const [mobFlag, setMobFlag] = useState(false);

  const [confirmationResult, setConfirmationResult] = useState<any | null>(
    null
  );
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [continueFlag, setContinueFlag] = useState(false);

  const [inputType, setInputType] = useState<"email" | "phone">("email");
  const [authCred, setAuthCred] = useState<AuthCred>({
    email: "",
    mobileNo: "",
  });

  const [MobileNoStatusFlag] = useLazyQuery(ValidateMobileNumber);
  const [password, setPassword] = useState("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedAuthCred = { ...authCred };

    if (/^\S+@\S+\.\S+$/.test(value)) {
      updatedAuthCred.email = value;
      setInputType("email");
    } else if (/^\d+$/.test(value)) {
      updatedAuthCred.mobileNo = value;
      setInputType("phone");
    } else {
      updatedAuthCred[name as keyof AuthCred] = value;
    }

    setAuthCred(updatedAuthCred);
  };
  const handleSubmit = () => {
    signInWithEmailAndPassword(auth, authCred.email, password)
      .then((userCredential) => {
        dispatch({
          type: payloadTypes.SET_USER,
          payload: { user: userCredential.user },
        });
      })
      .catch((error) => {
        console.error("Error signing in:", error);

        let errorMessage = "Error signing in. Please try again.";
        if (error.code === "auth/user-not-found") {
          errorMessage =
            "User not found. Please check your email and try again.";
        } else if (error.code === "auth/wrong-password") {
          errorMessage = "Incorrect password. Please try again.";
        } else if (error.code === "auth/too-many-requests") {
          errorMessage =
            "Too many unsuccessful attempts. Please try again later.";
        }

        alert(errorMessage);
      });
  };

  useEffect(() => {
    if (inputType === "phone") {
      mobileRef.current?.focus();
    }
  }, [inputType]);
  const setupReCap = () => {
    const applicationVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        callback: () => {
          sendOtp();
        },
        defaultCountry: "IN",
      }
    );

    signInWithPhoneNumber(auth, `+${authCred.mobileNo}`, applicationVerifier)
      .then((confirmResult) => {
        setConfirmationResult(confirmResult);
      })
      .catch((error) => {
        console.error("Error requesting OTP:", error);
        let errorMessage = "Error requesting OTP. Please try again.";
        if (error.code === "auth/invalid-phone-number") {
          errorMessage = "Invalid phone number. Please enter a valid one.";
        } else if (error.code === "auth/quota-exceeded") {
          errorMessage = "Quota exceeded. Please try again later.";
        }
        alert(errorMessage);
      });
  };

  useEffect(() => {
    if (mobFlag && authCred.mobileNo.length === 12) {
      MobileNoStatusFlag({
        variables: {
          mobile_number: `+${authCred.mobileNo}`,
        },
      }).then(({ data, error }) => {
        if (data && data.ValidateMobileNumber.valid_phone_number === true) {
          if (data.ValidateMobileNumber.user_email_id === EMPTY_STRING) {
            setupReCap();
            setRequestOTP(!requestOTP);
          }
          setAuthCred({
            email: data.ValidateMobileNumber.user_email_id,
            mobileNo: authCred.mobileNo,
          });
        } else if (
          data &&
          data.ValidateMobileNumber.valid_phone_number === false
        ) {
          alert("Mobile Number has not been registered");
        }
        if (error) {
          alert("Invalid Phone Number");
        }
      });
    }
  }, [mobFlag, MobileNoStatusFlag]);
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const sendOtp = () => {
    if (confirmationResult && verificationCode) {
      confirmationResult
        .confirm(verificationCode)
        .then((userCredential: any) => {
          dispatch({
            type: payloadTypes.SET_USER,
            payload: { user: userCredential.user },
          });
        })
        .catch((error: any) => {
          console.error("Error confirming OTP:", error);
          let errorMessage = "Not a registered Mobile Number";
          if (error.code === "auth/invalid-verification-code") {
            errorMessage =
              "Invalid verification code. Please enter a valid one.";
          } else if (error.code === "auth/session-expired") {
            errorMessage = "Session expired. Please request a new OTP.";
          }
          alert(errorMessage);
        });
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleNumberChange = (mobileNo: string) => {
    setAuthCred((prevCred) => ({
      ...prevCred,
      mobileNo: mobileNo,
    }));
  };

  return (
    <>
      <div id="recaptcha-container"></div>

      <div className="login">
        <div className="login__left-block">
          <div className="login__left-block--container">
            <div className="login__left-block--container--image"></div>
            <div className="login__left-block--container--curve"></div>
          </div>
        </div>

        <div className="login__right-block">
          <div className="login__right-block--container">
            <div className="login__right-block--container--data">
              <div className="login__right-block--container--data--title">
                <img className="logo" src={EduateLogo} alt="logo" />
                <h4>My-Eduate</h4>
              </div>
              <b>Login to Your Account</b>
              <div className="login__block">
                <div className="login__block--panel">
                  <div className="login__block--flex">
                    <Label>Email / Phone No</Label>

                    {authCred.mobileNo && (
                      <button
                        className="login__block--clear"
                        onClick={() => {
                          setInputType("email");
                          setAuthCred({
                            email: "",
                            mobileNo: "",
                          });
                        }}
                      >
                        Clear
                      </button>
                    )}
                  </div>
                  {inputType === "email" ? (
                    <TextField
                      required
                      id="email"
                      name="email"
                      className="loginfield"
                      autoFocus
                      onChange={handleChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          passwordRef.current?.focus();
                        }
                      }}
                    />
                  ) : (
                    inputType === "phone" && (
                      <PhoneInput
                        country={"in"}
                        value={authCred.mobileNo}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            mobileRef.current?.focus();
                          }
                        }}
                        onChange={handleNumberChange}
                        inputProps={{
                          required: true,
                          ref: mobileRef,
                        }}
                      />
                    )
                  )}
                  {!continueFlag && (
                    <Button
                      onClick={() => {
                        setMobFlag(!mobFlag);
                        setContinueFlag(!continueFlag);
                      }}
                      buttonref={saveRef!}
                      mode="continue"
                    />
                  )}
                  {continueFlag && !requestOTP && !logInOtp && (
                    <>
                      <Label>Password *</Label>
                      <TextField
                        required
                        value={password}
                        className="loginfield"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        inputRef={passwordRef!}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            saveRef.current?.focus();
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                  {continueFlag && requestOTP && (
                    <>
                      <Label>Enter OTP</Label>
                      <OtpInput
                        value={verificationCode}
                        onChange={setVerificationCode}
                        numInputs={6}
                        renderSeparator={
                          <span style={{ color: "white" }}>-</span>
                        }
                        containerStyle={{ columnGap: "5px", height: "30px" }}
                        renderInput={(props) => (
                          <input
                            {...props}
                            className="login__block--otp"
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                loginRef.current?.focus();
                              }
                            }}
                          />
                        )}
                      />
                    </>
                  )}
                  {logInOtp && (
                    <>
                      <Label>Enter OTP</Label>

                      <OtpInput
                        value={verificationCode}
                        onChange={setVerificationCode}
                        numInputs={6}
                        renderSeparator={
                          <span style={{ color: "white" }}>-</span>
                        }
                        containerStyle={{ columnGap: "5px", height: "30px" }}
                        renderInput={(props) => (
                          <input
                            {...props}
                            className="login__block--otp"
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                loginRef.current?.focus();
                              }
                            }}
                          />
                        )}
                      />
                    </>
                  )}
                  {logInOtp && (
                    <Button
                      onClick={() => {
                        requestOTP ? sendOtp() : handleSubmit();
                      }}
                      buttonref={saveRef!}
                      mode="sign-in"
                    />
                  )}
                  {continueFlag && !logInOtp && (
                    <Button
                      onClick={() => {
                        requestOTP ? sendOtp() : handleSubmit();
                      }}
                      buttonref={saveRef!}
                      mode="sign-in"
                    />
                  )}
                  {continueFlag && inputType === "email" && (
                    <>
                      <span className="login__block--span">
                        Login with Phone No.
                      </span>
                      <button
                        className="login__block--phnno"
                        onClick={() => {
                          setInputType("phone");
                          setAuthCred({
                            email: "",
                            mobileNo: "",
                          });
                          setContinueFlag(true);
                        }}
                      >
                        Enter Phone No.
                      </button>
                    </>
                  )}
                  {continueFlag && inputType === "phone" && (
                    <>
                      <span className="login__block--span">
                        Login with Email
                      </span>
                      <button
                        className="login__block--phnno"
                        onClick={() => {
                          setLoginOtp(!logInOtp);
                          setupReCap();
                        }}
                      >
                        Request OTP
                      </button>
                      <button
                        className="login__block--phnno"
                        onClick={() => {
                          setInputType("email");
                          setAuthCred({
                            email: "",
                            mobileNo: "",
                          });
                          setContinueFlag(!continueFlag);
                        }}
                      >
                        Enter Email
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};
export default Login;
