import { useLazyQuery } from "@apollo/client";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  EnquiryStudentDetailsByMasterVoucherId,
  PassedOutStudentDetailsByMasterVoucherId,
  StudentDetailsByMasterVoucherId,
} from "../../../../queries/common";
import { Title } from "../../../../stories/Title/Title";

import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  ROWS_PER_PAGE_30,
  TODAY_DATE,
} from "../../../../utils/constants";

import {
  filterVouchersByType,
  formatter,
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleFormEvent,
  toInputStandardDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import {
  BankOrCash,
  DebitOrCredit,
  ExcelAlignment,
  ExcelFont,
  ExcelFooterHeader,
  ExcelPageHeader,
  ExcelSheetsNames,
  PageFor,
  ReceiptTypes,
  studentData,
} from "../../../../utils/Enum.types";
import List from "./List";
import Home from "../../Home/Index";
import { Button } from "../../../../stories/Button/Button";

import Input from "../../../../stories/Input/Input";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import useToken from "../../../../customhooks/useToken";
import { filterOptions } from "../FeeReceiptBook/List/Index";
import useAcctVoucherDetailsByDates, {
  GetAcctVouchersEdges,
  voucherMasterDetails,
} from "../../hooks/useAcctVoucherDetailsByDates";
import { nodevars, VouchernodeData } from "../../../../Types/Accounting";
import Eduate from "../../../../images/Eduate_Logo_image.png";
import { Cell } from "exceljs";
import ExcelJS from "exceljs";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import { AcctLedgerQueryType } from "../../common/QueryTypes";
import { payloadTypes } from "../../../../context/reducer";
import StudentDemandList from "../AccountLedgerBook/Modals/StudentVoucherView";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import Modal from "react-modal";
import { AppContext } from "../../../../context/context";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  F4_CELL,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FOOTER_CSS,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  NEW_SHEET,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import { optionsType } from "../../../../utils/Form.types";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
import { VoucherQueryTypes } from "../../common/Enum.types";
import { DayWiseCollectionReportStyles } from "../../../../styles/StickyTableStyles";
import StudentTotalCount from "../../../Master/Student/Components/StudentTotalCount";
type LedgerTotal = Record<number, number>;
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const BankAndCashReport = ({ pageType, setModalFlag }: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const tableClasses = DayWiseCollectionReportStyles();
  const { format } = formatter;
  const { token } = useToken();

  const { dispatch, state } = useContext(AppContext);
  const navigate = useNavigate();

  const { serverDate } = useServerDateandTime();
  const [filter, setFilter] = useState("");
  const [adjustmentFlag, setAdjustmentFlag] = useState(false);
  const [v_no, setV_no] = useState("");
  const [studentDetails, setStudentDetails] = useState<voucherMasterDetails[]>(
    []
  );

  const [passedOutStudentDetails, setPassedStudentDetails] = useState<
    voucherMasterDetails[]
  >([]);

  const [enquiryStudentDetails, setEnquiryStudentDetails] = useState<
    voucherMasterDetails[]
  >([]);

  const [vouchers, setVouchers] = useState<GetAcctVouchersEdges[]>([]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startDate, setStartDate] = useState(toInputStandardDate(TODAY_DATE));
  const [endDate, setEndDate] = useState(toInputStandardDate(TODAY_DATE));
  const [studentReceiptModal, setStudentReceiptModal] = useState(false);
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(
    ROWS_PER_PAGE_30
  );
  const [GetStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    StudentDetailsByMasterVoucherId
  );
  const [GetPassedStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    PassedOutStudentDetailsByMasterVoucherId
  );

  const [GetEnqStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    EnquiryStudentDetailsByMasterVoucherId
  );

  const { VoucherDetails } = useAcctVoucherDetailsByDates(
    rowsPerPage,
    endDate,
    startDate,
    VoucherQueryTypes.STD_DAILY_RECEIPTS,
    v_no,
    adjustmentFlag,
    filter
  );

  const { acctLedgers } = useAcctLedgerData(
    EMPTY_STRING,
    AcctLedgerQueryType.ACCT_LDGRS_BANK,
    ROWS_PER_PAGE
  );
  const { InstDetails } = useInstDetails(1);
  const { data, fetchMore, loading } = VoucherDetails;

  const bankTotals: LedgerTotal =
    acctLedgers.data?.GetAcctLdgrs.edges.reduce((acc, banks) => {
      const debitData = vouchers
        ?.flatMap((edge) => edge.node.acct_voucher_details)
        .filter(
          (d) =>
            d.vo_cr_db === DebitOrCredit.DEBIT &&
            d.acct_ldgr.id === banks.node.id
        );
      if (debitData && debitData.length > 0) {
        const ledgerID = banks.node.id;
        acc[ledgerID] = debitData.reduce(
          (sum, ban) => sum + ban.vo_db_total,
          0
        );
      }

      return acc;
    }, {} as LedgerTotal) || {};
  const totalCash =
    (vouchers
      ?.filter(
        (edge) => edge.node.v_transcation_cash_or_bank === BankOrCash.CASH
      )
      .reduce((sum, edge) => sum + edge.node.v_total_db_amt, 0) as number) || 0;
  const totalAdjustment =
    (vouchers
      ?.filter(
        (edge) => edge.node.v_transcation_cash_or_bank === BankOrCash.ADJ
      )
      .reduce((sum, edge) => sum + edge.node.v_total_db_amt, 0) as number) || 0;

  const filteredStudentsReceipts = filterVouchersByType(
    ReceiptTypes.STUDENT_RECEIPT,
    VoucherDetails.data!
  );
  const filteredPassedStudentReceipts = VoucherDetails.data
    ? VoucherDetails.data.GetAcctVouchers.edges.filter(
        ({ node }) =>
          node.v_type === ReceiptTypes.STUDENT_RECEIPT &&
          node.v_std_passout_receipt
      )
    : [];

  const filteredEnquiryStudentReceipts = VoucherDetails.data
    ? VoucherDetails.data.GetAcctVouchers.edges.filter(
        ({ node }) =>
          node.v_type === ReceiptTypes.STUDENT_RECEIPT && node.v_std_enquiry
      )
    : [];
  const FetchStudentDetails = (
    voucher: voucherMasterDetails,
    type: studentData
  ) => {
    const student = studentDetails?.find(
      (studentDetail) => studentDetail?.id === voucher.id
    );
    const enqStudent = enquiryStudentDetails.find(
      (enqStudentDetails) => enqStudentDetails?.id === voucher.id
    );
    const passedOutStudent = passedOutStudentDetails.find(
      (passedOutStudentDetails) => passedOutStudentDetails?.id === voucher.id
    );

    if (
      student &&
      voucher.student_id &&
      voucher.v_std_enquiry === false &&
      voucher.v_std_passout_receipt === false
    ) {
      switch (type) {
        case studentData.name:
          return (
            student.mst_student.first_name +
            " " +
            student.mst_student.middle_name +
            " " +
            student.mst_student.last_name +
            " " +
            `(${voucher.branch_details.branch_desc} / ${voucher.class_details.class_desc} / ${student.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return student.mst_student.std_adm_no!;
        case studentData.parent:
          return student.mst_student.std_father_name!;
        case studentData.reg:
          return student.mst_student.std_reg_no!;
        case studentData.id:
          return student.mst_student.id!;
        default:
          break;
      }
    }
    if (
      enqStudent &&
      voucher.student_id === 0 &&
      voucher.v_std_enquiry &&
      voucher.v_std_passout_receipt === false
    ) {
      switch (type) {
        case studentData.name:
          return (
            enqStudent.enq_std_details.first_name +
            " " +
            enqStudent.enq_std_details.middle_name +
            " " +
            enqStudent.enq_std_details.last_name +
            " " +
            `(${voucher.branch_details.branch_desc} / ${voucher.class_details.class_desc} / ${enqStudent.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return "-";
        case studentData.parent:
          return enqStudent.enq_std_details.std_father_name!;
        case studentData.reg:
          return "-";
        case studentData.id:
          return enqStudent.enq_std_details.id!;
        default:
          break;
      }
    }
    if (
      passedOutStudent &&
      voucher.student_id === 0 &&
      voucher.v_std_enquiry === false &&
      voucher.v_std_passout_receipt
    ) {
      switch (type) {
        case studentData.name:
          return (
            passedOutStudent?.passout_std_details.std_name +
            " " +
            `(${voucher.branch_details.branch_desc} / ${voucher.class_details.class_desc} / ${passedOutStudent?.acct_voucher_details[0].acct_ldgr.ldgr_desc})`
          );
        case studentData.admNo:
          return "-";
        case studentData.parent:
          return "-";
        case studentData.reg:
          return "-";
        case studentData.id:
          return passedOutStudent?.passout_std_details.id!;
        default:
          break;
      }
    }
  };

  const asData = data?.GetAcctVouchers.edges.map((edge, index) => {
    const bankValue = acctLedgers.data?.GetAcctLdgrs.edges.map((banks) => {
      const debitData = edge.node.acct_voucher_details.find(
        (ban) =>
          ban.vo_cr_db === DebitOrCredit.DEBIT &&
          ban.acct_ldgr.id === banks.node.id
      );
      return debitData ? debitData.vo_db_total : 0;
    });

    const filteredBankValue = bankValue?.filter((value) => !isNaN(value));

    return {
      SlNo: index + 1,
      VoucherNo: edge.node?.v_no,
      Date: toStandardDate(edge.node.v_date),
      AdmNo: FetchStudentDetails(edge.node, studentData.admNo),
      RegNo: FetchStudentDetails(edge.node, studentData.reg),
      Name: FetchStudentDetails(edge.node, studentData.name),
      Paid:
        edge.node.v_transcation_cash_or_bank === BankOrCash.BANK
          ? edge.node.v_transcation_type + " - " + edge.node.v_transcation_no
          : edge.node.v_transcation_type,
      Adjustment:
        edge.node.v_transcation_cash_or_bank === BankOrCash.ADJ
          ? edge.node.v_total_db_amt
          : "-",
      Cash:
        edge.node.v_transcation_cash_or_bank === BankOrCash.BANK ||
        edge.node.v_transcation_cash_or_bank === BankOrCash.ADJ
          ? "-"
          : edge.node.v_total_db_amt,
      Bank: filteredBankValue ? Number(filteredBankValue) : null,
    };
  });

  const showBank =
    (filter === BankOrCash.BANK || filter === EMPTY_STRING) && !adjustmentFlag;
  const showCash =
    (filter === BankOrCash.CASH || filter === EMPTY_STRING) && !adjustmentFlag;
  const reportHeader = [
    "Sl No",
    "Voucher No",
    "Date",
    "Adm No",
    "Reg No",
    "Student Name",
    "Paid Through",
    adjustmentFlag || filter === EMPTY_STRING ? "Adjustment" : null,
    showCash ? "Cash" : null,
    showBank ? "Bank" : null,
  ].filter(Boolean);

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(NEW_SHEET);

    const dataRows = asData
      ? asData?.map((item) =>
          [
            item.SlNo,
            item.VoucherNo,
            item.Date,
            item.AdmNo,
            item.RegNo,
            item.Name,
            item.Paid,
            adjustmentFlag || filter === EMPTY_STRING ? item.Adjustment : null,
            showCash ? item.Cash : null,
            showBank ? item.Bank : null,
          ].filter((value) => value !== null)
        )
      : [];
    worksheet.views = FROZEN_CELLS;

    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 13;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 65;
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "J1:J3");

          worksheet.mergeCells(1, 1, 1, 10);

          const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
          mergedCell.value = InstDetails?.data?.nodes[0]?.inst_name;
          mergedCell.fill = HEADER_CSS;
          mergedCell.font = headerStyle[0].font;
          mergedCell.alignment = HEADER_ALIGNMENT;

          const mergedAddress: Cell = worksheet.getCell(A2_CELL);
          mergedAddress.value = InstDetails?.data?.nodes[0]?.inst_address;
          mergedAddress.fill = HEADER_CSS;
          mergedAddress.font = ADDRESS_FONT;
          mergedAddress.alignment = HEADER_ALIGNMENT;
          worksheet.mergeCells("A2:J2");

          const mergedPlace: Cell = worksheet.getCell(A3_CELL);
          mergedPlace.value =
            InstDetails?.data?.nodes[0]?.inst_place +
            "-" +
            InstDetails?.data?.nodes[0]?.inst_pin;

          mergedPlace.fill = HEADER_CSS;
          mergedPlace.font = ADDRESS_FONT;
          mergedPlace.alignment = ADDRESS_ALIGNMENT;
          worksheet.mergeCells("A3:J3");

          const mergedHeader: Cell = worksheet.getCell(F4_CELL);
          mergedHeader.value = ExcelPageHeader.DAY_WISE_COLLECTION;
          mergedHeader.fill = FILE_NAME_CSS;
          mergedHeader.font = FILE_NAME_FONT;
          mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("F4:F4");
          const mergedDate: Cell = worksheet.getCell("G4");
          mergedDate.value = ExcelPageHeader.DATE + toStandardDate(serverDate);
          mergedDate.fill = FILE_NAME_CSS;
          mergedDate.font = FIN_YEAR_FONT;
          mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
          worksheet.mergeCells("G4:J4");
          const mergedYear: Cell = worksheet.getCell(A4_CELL);
          mergedYear.value = state.ActiveFinYr
            ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
            : 0;
          mergedYear.fill = FILE_NAME_CSS;
          mergedYear.font = FIN_YEAR_FONT;
          mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet.mergeCells("A4:E4");

          let Char = FIRST_INDEX;

          for (let i = 0; i < reportHeader.length; i++) {
            Char = String.fromCharCode(Char.charCodeAt(0) + 1);

            const rowData: Cell = worksheet.getCell(Char + 5);
            rowData.value = reportHeader[i];
            rowData.fill = TABLE_HEADER_CSS;
            rowData.border = BORDER_DATA;
            rowData.font = ACC_HEADER_FONT;
            rowData.alignment = { horizontal: ExcelAlignment.CENTER };
          }
          dataRows!.forEach((rowData) => {
            const row = worksheet.addRow(rowData);
            row.eachCell({ includeEmpty: true }, (cell) => {
              cell.alignment = { horizontal: ExcelAlignment.LEFT };
              cell.font = { name: ExcelFont.CENTURY_GOTHIC, size: 9 };
              row.getCell(8).font = { name: ExcelFont.COURIER_NEW, size: 9 };
              row.getCell(8).alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              row.getCell(9).font = { name: ExcelFont.COURIER_NEW, size: 9 };
              row.getCell(9).alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              row.getCell(10).font = { name: ExcelFont.COURIER_NEW, size: 9 };
              row.getCell(10).alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
            });
          });

          const footerRow = worksheet.addRow([]);
          footerRow.getCell(7).value = ExcelFooterHeader.TOTAL;
          footerRow.getCell(7).font = {
            name: ExcelFont.ARIAL,
            size: 10,
          };
          footerRow.getCell(8).value =
            filter === EMPTY_STRING
              ? totalAdjustment
              : filter === "C"
              ? totalCash
              : filter === "B"
              ? (
                  acctLedgers.data?.GetAcctLdgrs.edges.map((banks) =>
                    format(bankTotals[banks.node.id] || 0)
                  ) || []
                ).join(", ")
              : totalAdjustment;
          if (filter === EMPTY_STRING) {
            footerRow.getCell(9).value = totalCash;
          }
          if (filter === EMPTY_STRING) {
            footerRow.getCell(10).value = (
              acctLedgers.data?.GetAcctLdgrs.edges.map((banks) =>
                format(bankTotals[banks.node.id] || 0)
              ) || []
            ).join(", ");
          }
          for (let i = 7; i <= 14; i++) {
            footerRow.getCell(i).alignment = {
              horizontal: ExcelAlignment.RIGHT,
            };
          }
          worksheet.addConditionalFormatting({
            ref: `H${footerRow.number}`,
            rules: FOOTER_CSS,
          });
          if (filter === EMPTY_STRING) {
            worksheet.addConditionalFormatting({
              ref: `I${footerRow.number}`,
              rules: FOOTER_CSS,
            });
          }
          if (filter === EMPTY_STRING) {
            worksheet.addConditionalFormatting({
              ref: `J${footerRow.number}`,
              rules: FOOTER_CSS,
            });
          }

          workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
            const blob = new Blob([buffer], {
              type: BLOB_TYPE,
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement(ELEMENT);
            link.href = url;
            link.setAttribute(DOWNLOAD, ExcelSheetsNames.DAY_WISE);
            document.body.appendChild(link);
            link.click();
          });
          setExcelFlag(false);
          setRowsPerPage(ROWS_PER_PAGE);
        });
      });
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop + 1;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctVouchers.edges;
            const pageInfo = fetchMoreResult.GetAcctVouchers.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctVouchers.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctVouchers: {
                edges: [...vouchers, ...newEdges],
                pageInfo,
                totalCount: data?.GetAcctVouchers.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  useEffect(() => {
    if (
      excelFlag &&
      rowsPerPage === null &&
      !loading &&
      data?.GetAcctVouchers?.edges.length === data?.GetAcctVouchers?.totalCount
    )
      downloadExcel();
    // eslint-disable-next-line
  }, [excelFlag, rowsPerPage, loading]);
  useEffect(() => {
    if (data && !loading && token && excelFlag === false) {
      const newData = data.GetAcctVouchers.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newVoucher) => {
          const filteredStudent = vouchers.find(
            (voucher) => voucher.node.id === newVoucher.node.id
          );
          if (filteredStudent) {
            return {
              ...newVoucher,
              node: {
                ...newVoucher.node,
              },
            };
          }
          return newVoucher;
        });
        setVouchers(updatedNewData);
      } else {
        setVouchers(newData);
      }
      setEndCursor(data.GetAcctVouchers.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, token, excelFlag]);
  useEffect(() => {
    if (filteredStudentsReceipts?.length) {
      GetStudentDetails({
        variables: {
          token,
          ids: filteredStudentsReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        setStudentDetails(data?.nodes!);
      });
    } // eslint-disable-next-line
  }, [GetStudentDetails, filteredStudentsReceipts?.length, token]);

  useEffect(() => {
    if (filteredPassedStudentReceipts?.length) {
      GetPassedStudentDetails({
        variables: {
          token,
          ids: filteredPassedStudentReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setPassedStudentDetails(data.nodes);
      });
    } // eslint-disable-next-line
  }, [GetPassedStudentDetails, filteredPassedStudentReceipts?.length, token]);
  useEffect(() => {
    if (filteredEnquiryStudentReceipts.length) {
      GetEnqStudentDetails({
        variables: {
          token,
          ids: filteredEnquiryStudentReceipts.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setEnquiryStudentDetails(data.nodes);
      });
    } // eslint-disable-next-line
  }, [GetEnqStudentDetails, filteredEnquiryStudentReceipts.length, token]);
  const handleViewReceiptDetails = (node: voucherMasterDetails) => {
    dispatch({
      type: payloadTypes.SET_RECEPIT_ID,
      payload: {
        receiptId: node.id,
      },
    });

    if (node.v_std_receipt && node.student_id) {
      dispatch({
        type: payloadTypes.SET_ALUMINI_STUDENT_ID,
        payload: {
          aluminiStudentId: 0,
        },
      });
      dispatch({
        type: payloadTypes.SET_STD_ENQUIRY_ID,
        payload: {
          studentEnquiryId: 0,
        },
      });
      dispatch({
        type: payloadTypes.SET_STUDENT_ID,
        payload: {
          studentId: node.student_id,
        },
      });
    }

    if (node.v_std_passout_receipt && node.passout_student_id) {
      dispatch({
        type: payloadTypes.SET_STUDENT_ID,
        payload: {
          studentId: 0,
        },
      });
      dispatch({
        type: payloadTypes.SET_STD_ENQUIRY_ID,
        payload: {
          studentEnquiryId: 0,
        },
      });
      dispatch({
        type: payloadTypes.SET_ALUMINI_STUDENT_ID,
        payload: {
          aluminiStudentId: node.passout_student_id,
        },
      });
    }

    if (node.v_std_enquiry && node.enquiry_student_id) {
      dispatch({
        type: payloadTypes.SET_STUDENT_ID,
        payload: {
          studentId: 0,
        },
      });
      dispatch({
        type: payloadTypes.SET_ALUMINI_STUDENT_ID,
        payload: {
          aluminiStudentId: 0,
        },
      });
      dispatch({
        type: payloadTypes.SET_STD_ENQUIRY_ID,
        payload: {
          studentEnquiryId: node.enquiry_student_id,
        },
      });
    }
    setStudentReceiptModal(!studentReceiptModal);
  };
  return (
    <>
      {pageType === PageFor.GENERAL ? <Home DashBoardRequired={false} /> : null}
      <Title>Day Wise Collection Report</Title>
      <div
        className={`feecollected${pageType === PageFor.MODAL ? "--modal" : ""}`}
      >
        <div className="feecollected__tableblock">
          <List />
        </div>

        <div className="feecollected__reportblock">
          <div className="row g-0 feecollected__select-options">
            <div className="col-2">
              <Input
                id="search"
                type="text"
                placeholder="Search "
                className="feecollected__reportblock--filter-options--search"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setV_no(e.target.value)
                }
                onKeyDown={handleFormEvent}
              />
            </div>

            <div className="col-2">
              <Autocomplete
                classes={classes}
                options={filterOptions}
                openOnFocus
                autoHighlight
                onChange={(e, newValue) => {
                  if (newValue) {
                    const data = newValue as optionsType;
                    if (data.value !== BankOrCash.ADJ) {
                      setFilter(data.value);
                      setAdjustmentFlag(false);
                    } else {
                      setFilter(EMPTY_STRING);
                      setAdjustmentFlag(true);
                    }
                  } else {
                    setFilter(EMPTY_STRING);
                    setAdjustmentFlag(false);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="outlined-uncontrolled"
                    label="Receipt Types"
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
            <div className="col"></div>

            <div className="col-3 feecollected__reportblock--date">
              <TextField
                label="Start Date"
                type="date"
                InputProps={{
                  inputProps: {
                    min: state.ActiveFinYr
                      ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                      : EMPTY_STRING,
                    max: toInputStandardDate(TODAY_DATE),
                  },
                }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                className="feecollected__reportblock--textfield"
              />
              <TextField
                label="End Date"
                type="date"
                InputProps={{
                  inputProps: {
                    min: state.ActiveFinYr
                      ? toInputStandardDate(state.ActiveFinYr.fin_end_date!)
                      : EMPTY_STRING,
                    max: toInputStandardDate(TODAY_DATE),
                  },
                }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                className="feecollected__reportblock--textfield"
              />
            </div>
          </div>

          <div className="feecollected__reportblock--details">
            {vouchers && vouchers.length ? (
              <TableContainer
                className={`${tableClasses.table} feecollected__reportblock--table`}
                onScroll={handleScroll}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={tableClasses.stickyHeaderSl}
                        align="center"
                      >
                        Sl
                      </TableCell>
                      <TableCell
                        className={tableClasses.stickyHeaderDate}
                        align="center"
                      >
                        Date
                      </TableCell>
                      <TableCell
                        className={tableClasses.stickyHeaderVno}
                        align="center"
                      >
                        Receipt.No
                      </TableCell>

                      <TableCell
                        className={tableClasses.stickyHeaderAdmNo}
                        align="center"
                      >
                        Admission Number
                      </TableCell>
                      <TableCell
                        className={tableClasses.stickyHeaderRegNo}
                        align="center"
                      >
                        Register Number
                      </TableCell>
                      <TableCell
                        className={tableClasses.stickyHeaderName}
                        align="center"
                      >
                        Student Name
                      </TableCell>
                      <TableCell
                        className={tableClasses.stickyHeader}
                        align="center"
                      >
                        Received From
                      </TableCell>
                      {filter === EMPTY_STRING && (
                        <TableCell
                          className={tableClasses.stickyHeader}
                          align="center"
                        >
                          Adjustment
                        </TableCell>
                      )}
                      {showCash && (
                        <TableCell
                          className={tableClasses.stickyHeader}
                          align="center"
                        >
                          Cash
                        </TableCell>
                      )}
                      {showBank &&
                        acctLedgers?.responseType?.map((bank) => {
                          return (
                            <TableCell
                              key={bank.value}
                              className={tableClasses.stickyHeader}
                              align="center"
                            >
                              {bank.label}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {vouchers.map((edge, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            id="td-center"
                            className={tableClasses.stickyColumnSl}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            id="td-center"
                            className={tableClasses.stickyColumnDate}
                          >
                            {toStandardDate(edge.node.v_date)}
                          </TableCell>
                          <TableCell
                            id="td-center"
                            className={tableClasses.stickyColumnVno}
                          >
                            {edge.node.v_no}
                          </TableCell>

                          <TableCell className={tableClasses.stickyColumnAdmno}>
                            {FetchStudentDetails(edge.node, studentData.admNo)}
                          </TableCell>
                          <TableCell className={tableClasses.stickyColumnRegno}>
                            {FetchStudentDetails(edge.node, studentData.reg)}
                          </TableCell>
                          <TableCell
                            className={`${tableClasses.stickyColumnName} feecollected__reportblock--table--name`}
                            onClick={() => handleViewReceiptDetails(edge.node)}
                          >
                            {FetchStudentDetails(edge.node, studentData.name)}
                          </TableCell>
                          <TableCell className={tableClasses.stickyColumn}>
                            {edge.node.v_transcation_cash_or_bank ===
                            BankOrCash.BANK
                              ? edge.node.v_transcation_type +
                                " - " +
                                edge.node.v_transcation_no
                              : edge.node.v_transcation_type}
                          </TableCell>
                          {(adjustmentFlag || filter === EMPTY_STRING) && (
                            <TableCell
                              id="td-right"
                              className={tableClasses.stickyColumn}
                            >
                              {edge.node.v_transcation_cash_or_bank ===
                              BankOrCash.ADJ
                                ? format(edge.node.v_total_db_amt)
                                : "-"}
                            </TableCell>
                          )}
                          {showCash && (
                            <TableCell
                              id="td-right"
                              className={tableClasses.stickyColumn}
                            >
                              {edge.node.v_transcation_cash_or_bank ===
                                BankOrCash.BANK ||
                              edge.node.v_transcation_cash_or_bank ===
                                BankOrCash.ADJ
                                ? "-"
                                : format(edge.node.v_total_db_amt)}
                            </TableCell>
                          )}

                          {showBank &&
                            acctLedgers.data?.GetAcctLdgrs.edges.map(
                              (banks, bankIndex) => {
                                const debitData =
                                  edge.node.acct_voucher_details.find(
                                    (ban) =>
                                      ban.vo_cr_db === DebitOrCredit.DEBIT &&
                                      ban.acct_ldgr.id === banks.node.id
                                  );

                                return (
                                  <TableCell
                                    key={bankIndex}
                                    id="td-right"
                                    className={tableClasses.stickyColumn}
                                  >
                                    {debitData
                                      ? format(debitData.vo_db_total)
                                      : "-"}
                                  </TableCell>
                                );
                              }
                            )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell
                        colSpan={7}
                        className={tableClasses.stickyColumnFooterTotal}
                        id="td-right"
                      >
                        Total:
                      </TableCell>
                      {(adjustmentFlag || filter === EMPTY_STRING) && (
                        <TableCell
                          id="td-right"
                          className={tableClasses.stickyColumnFooter}
                        >
                          {format(totalAdjustment)}
                        </TableCell>
                      )}
                      {showCash && !adjustmentFlag && (
                        <TableCell
                          id="td-right"
                          className={tableClasses.stickyColumnFooter}
                        >
                          {format(totalCash)}
                        </TableCell>
                      )}

                      {showBank &&
                        acctLedgers.data?.GetAcctLdgrs.edges.map(
                          (banks, bankIndex) => (
                            <TableCell
                              key={bankIndex}
                              id="td-right"
                              className={tableClasses.stickyColumnFooter}
                            >
                              {format(bankTotals[banks.node.id] || 0)}
                            </TableCell>
                          )
                        )}
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            ) : (
              <b className="nodata">Sorry, no results.</b>
            )}
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col">
          <div className="feecollected__buttons">
            <Button
              onClick={() => {
                setRowsPerPage(null);
                setExcelFlag(true);
              }}
              mode="export"
            />

            {pageType === PageFor.GENERAL ? (
              <Button mode="back" onClick={() => navigate(-1)} />
            ) : (
              <Button mode="cancel" onClick={() => setModalFlag(false)} />
            )}
          </div>
        </div>

        <div className="col-2 feecollected__total">
          <StudentTotalCount totalCount={data?.GetAcctVouchers.totalCount!} />
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentReceiptModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentDemandList setModalFlag={setStudentReceiptModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentReceiptModal(!studentReceiptModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BankAndCashReport;
