//mui autocomplete in list

import { makeStyles } from "@mui/styles";
import { FormAutoCompleteTextStyles } from "./AutocompleteStyles";
import { TEXT_FONT_FAMILY } from "./DataGridTableStyles";

export const ListAutoCompleteStyles = makeStyles<FormAutoCompleteTextStyles>(
  () => ({
    inputRoot: {
      color: "var(--text-color)",
      fontFamily: TEXT_FONT_FAMILY,
      fontSize: 14,
      padding: "6px !important",

      "&:Mui-hover .MuiAutocomplete-root": {
        border: "1px solid var(--level-5)",
      },

      "&.Mui-focused .MuiAutocomplete-root": {
        border: "1px solid var(--level-5)",
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        background: "var(--disabled-color)",
        cursor: "not-allowed",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--border-color) !important",
      },

      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--level-3) !important",
      },

      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
        fontSize: 12,
      },

      "& .MuiSvgIcon-root ": {
        height: 16,
        width: 16,
      },

      "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
        fontSize: "12px !important",
        fontFamily: TEXT_FONT_FAMILY,
      },
    },
  })
);

export const ListAutoCompleteTextStyles =
  makeStyles<FormAutoCompleteTextStyles>(() => ({
    formControlRoot: {
      fontFamily: TEXT_FONT_FAMILY,
      color: "var(--text-color)",
      borderRadius: "6px",
      position: "relative",
      // marginTop: "10px",
      fontSize: 12,

      "&.Mui-focused .MuiInputBase-formControl": {
        border: "1px solid var(--level-5)",
      },
      " &.hover": {
        border: "1px solid var(--level-5) !important",
      },

      "& label.Mui-focused": {
        color: "var(--text-color)",
      },
      "& .MuiInputBase-input": {
        height: "3px !important",
      },

      "& .MuiAutocomplete-root    .MuiOutlinedInput-root   .MuiAutocomplete-input":
        {
          padding: 2,
        },
    },
    inputLabelRoot: {
      color: "black",
      fontFamily: TEXT_FONT_FAMILY,
      fontSize: 12,
      "&.focused": {
        color: "#ffffff",

        border: "1px solid var(--level-5)",
      },
      "&.hover": {
        color: "#ffffff",

        border: "1px solid var(--level-5)",
      },
    },
  }));
export const requiredListAutoCompleteStyles =
  makeStyles<FormAutoCompleteTextStyles>(() => ({
    inputRoot: {
      color: "var(--text-color)",
      fontFamily: TEXT_FONT_FAMILY,
      fontSize: 14,
      padding: "6px !important",

      "&:Mui-hover .MuiAutocomplete-root": {
        border: "1px solid var(--level-5)",
      },

      "&.Mui-focused .MuiAutocomplete-root": {
        border: "1px solid var(--level-5)",
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        background: "var(--disabled-color)",
        cursor: "not-allowed",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--border-color) !important",
        borderRight: "3px solid red !important",
      },

      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--level-3) !important",
      },

      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
        fontSize: 12,
      },

      "& .MuiSvgIcon-root ": {
        height: 16,
        width: 16,
      },
      " & .MuiAutocomplete-endAdornment ": {
        backgroundColor: "var(--bg-color)",
      },
      "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
        fontSize: "12px !important",
        fontFamily: TEXT_FONT_FAMILY,
      },
    },
  }));
