import React, { SetStateAction, useState } from "react";
import { useStyles } from "../../../../../styles/TooltipStyles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import DataView from "../../../../../images/DataView.svg";
import TableView from "../../../../../images/TableView.svg";
import Update from "../../../../../images/Update.svg";
import View from "../../../../../images/EyeWhite.svg";
import { Title } from "../../../../../stories/Title/Title";
import { TableHeaders } from "../../../../../utils/Enum.types";
import { Button } from "../../../../../stories/Button/Button";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";

interface Props {
  setModalFlag: React.Dispatch<SetStateAction<boolean>>;
}

const listData = [
  {
    dept: "Department",
    branch: "Branch",
    class: "Class",
    semester: "Semester",
    section: "Section",
    total: 10,
    completed: 8,
    pending: 2,
    status: "Marked",
  },
  {
    dept: "Department",
    branch: "Branch",
    class: "Class",
    semester: "Semester",
    section: "Section",
    total: 10,
    completed: 8,
    pending: 2,
    status: "Not Marked",
  },
  {
    dept: "Department",
    branch: "Branch",
    class: "Class",
    semester: "Semester",
    section: "Section",
    total: 10,
    completed: 8,
    pending: 2,
    status: "Marked",
  },
];
const MarksEntry = ({ setModalFlag }: Props) => {
  const [tableViewActive, setTableViewActive] = useState(true);
  const tooltipClasses = useStyles();
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();

  return (
    <>
      <Title>Marks Entry Details</Title>
      <div className="teacher-dashboard__marks-entry">
        <div className="row g-0 teacher-dashboard__marks-entry--filters">
          <div className="col"></div>
          <div className="col-2  teacher-dashboard__attendance--filters--images">
            {!tableViewActive ? (
              <Tooltip
                title="Card View"
                placement="top"
                classes={{
                  tooltip: tooltipClasses.customTooltipGrey,
                  arrow: tooltipClasses.customArrowGrey,
                }}
                arrow
              >
                <img
                  src={DataView}
                  alt=""
                  onClick={() => setTableViewActive(!tableViewActive)}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title="Table View"
                placement="top"
                classes={{
                  tooltip: tooltipClasses.customTooltipGrey,
                  arrow: tooltipClasses.customArrowGrey,
                }}
                arrow
              >
                <img
                  src={TableView}
                  alt=""
                  onClick={() => setTableViewActive(!tableViewActive)}
                />
              </Tooltip>
            )}
          </div>
        </div>
        {tableViewActive ? (
          <div className="teacher-dashboard__marks-entry--card-view">
            <div className="teacher-dashboard__marks-entry--card-view--gridlist">
              {listData.map((data, index) => {
                return (
                  <div
                    className={
                      data.status === "Marked"
                        ? "teacher-dashboard__marks-entry--card-view--grid marked"
                        : "teacher-dashboard__marks-entry--card-view--grid not-marked"
                    }
                  >
                    <span className="teacher-dashboard__marks-entry--card-view--grid--level-1">
                      {data.class}
                    </span>
                    <span className="teacher-dashboard__marks-entry--card-view--grid--level-2">
                      {data.semester}
                    </span>
                    <b className="teacher-dashboard__marks-entry--card-view--grid--level-3">
                      {data.section}
                    </b>
                    <div className="teacher-dashboard__marks-entry--card-view--grid--flex">
                      <div>
                        <span className="teacher-dashboard__marks-entry--card-view--grid--text">
                          Total Test Conducted
                        </span>
                        <span className="teacher-dashboard__marks-entry--card-view--grid--number font-grey">
                          {data.total}
                        </span>
                      </div>
                      <div>
                        <span className="teacher-dashboard__marks-entry--card-view--grid--text">
                          Marks Entry Completed
                        </span>
                        <span className="teacher-dashboard__marks-entry--card-view--grid--number font-green">
                          {data.completed}
                        </span>
                      </div>
                    </div>
                    <div className="teacher-dashboard__marks-entry--card-view--grid--flex2">
                      <span className="teacher-dashboard__marks-entry--card-view--grid--text">
                        Pending Marks Entry
                      </span>
                      <span className="teacher-dashboard__marks-entry--card-view--grid--number font-red">
                        {data.pending}
                      </span>
                    </div>
                    <div className="teacher-dashboard__marks-entry--card-view--grid--button">
                      {data.status === "Marked" ? (
                        <button className="teacher-dashboard__marks-entry--card-view--grid--update">
                          Update
                          <img src={Update} alt="" />
                        </button>
                      ) : (
                        <button className="teacher-dashboard__marks-entry--card-view--grid--view">
                          View Test/Exam
                          <img src={View} alt="" />
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="teacher-dashboard__marks-entry--tableblock">
            <TableContainer className="teacher-dashboard__marks-entry--table">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>{TableHeaders.SLNO}</TableCell>
                    <TableCell>{departmentLabel}</TableCell>
                    <TableCell>{branchLabel}</TableCell>
                    <TableCell>{classLabel}</TableCell>
                    <TableCell>{semesterLabel}</TableCell>
                    <TableCell>{sectionLabel}</TableCell>
                    <TableCell>Total Test / Exam</TableCell>
                    <TableCell>Completed Marks Entry</TableCell>
                    <TableCell>Pending Marks Entry</TableCell>
                    <TableCell>{TableHeaders.STATUS}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listData.map((data, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className="teacher-dashboard__marks-entry--table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell>{data.dept}</TableCell>
                        <TableCell>{data.branch}</TableCell>
                        <TableCell>{data.class}</TableCell>
                        <TableCell>{data.semester}</TableCell>
                        <TableCell>{data.section}</TableCell>
                        <TableCell
                          className="teacher-dashboard__marks-entry--table--number font-grey"
                          id="td-center"
                        >
                          {data.total}
                        </TableCell>
                        <TableCell
                          className="teacher-dashboard__marks-entry--table--number font-green"
                          id="td-center"
                        >
                          {data.completed}
                        </TableCell>
                        <TableCell
                          className="teacher-dashboard__marks-entry--table--number font-red"
                          id="td-center"
                        >
                          {data.pending}
                        </TableCell>
                        <TableCell
                          className="teacher-dashboard__marks-entry--table--status"
                          id="td-center"
                        >
                          {data.status === "Marked" ? (
                            <button className="teacher-dashboard__marks-entry--table--update">
                              Update <img src={Update} alt="" />
                            </button>
                          ) : (
                            <button className="teacher-dashboard__marks-entry--table--view">
                              View Test/Exam <img src={View} alt="" />
                            </button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
    </>
  );
};

export default MarksEntry;
