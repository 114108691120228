import { gql } from "@apollo/client";

export const GetStudents = gql`
  query GetStudents(
    $name: String
    $status: String
    $first: Int
    $after: Cursor
    $orderBy: [MstStudentOrder!]
    $token: String!
    $fin_yr_id: ID!
    $input: StdQueryData!
  ) {
    GetStudents(
      token: $token
      fin_yr_id: $fin_yr_id
      first: $first
      after: $after
      orderBy: $orderBy
      where: {
        and: [
          {
            or: [
              { firstNameContainsFold: $name }
              { middleNameContainsFold: $name }
              { stdAdmNoContainsFold: $name }
              { lastNameContainsFold: $name }
            ]
          }
          { stdStatusContainsFold: $status }
        ]
      }
      input: $input
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          std_adm_no
          first_name
          middle_name
          last_name
          std_father_name
          std_father_mobile
          std_mother_name
          std_mother_mobile
          std_profile_filename
          std_reg_no
          std_roll_no
          std_mobile
          std_status
          std_fresher
          std_email
          std_mobile
          std_dob
          std_sex
          std_doa

          category {
            id
            cat_desc
          }
          branch {
            id
            branch_desc
          }
          class {
            id
            class_desc
          }
          dept {
            id
            dept_desc
          }
          semester {
            id
            sem_desc
          }
          section {
            id
            section_desc
          }
          misc_data {
            availed_transport
            availed_hostel
            mobile_app_count
          }
          fin_yr {
            fin_yr
          }
          std_details {
            id
            std_blood_group
            std_aadhar
            std_address
            std_place
            std_district
            std_state
            std_phone
            std_pincode
            std_corr_address
            std_corr_place
            std_corr_district
            std_corr_state
            std_corr_phone
            std_corr_pincode
            std_religion
            std_caste
            std_sub_caste
            std_caste_group
            std_sc_st
            std_caste_minority
            std_rural_urban
            std_mother_tongue
            std_annual_income
            std_martial_status
            std_identity_marks
            std_place_of_birth
            std_nationality
            std_is_phy_challenged
            std_nearest_police_station
            std_nearest_railway_station
            std_km_from_residence
            std_belongs_to_jk
            std_belongs_to_jk_brahmin
          }
          std_other_details {
            id
            std_program_name
            std_open_elective
            std_fee_amount
            std_fee_payment_date
            std_discipline_1
            std_language_1
            std_challan_no
            std_kk_reservation
            std_kannada_medium
            std_discipline_2
            std_language_2
            std_prev_tc_details
            std_prev_inst
            std_prev_quali_exam
            std_prev_studied_board
            std_prev_studied_state
            std_prev_subject_studied
            std_prev_scored_marks
            std_prev_total_per
            std_prev_tech_subject_studied
            std_prev_tech_total_mark
            std_prev_tech_total_per
            std_10_board_name
            std_10_inst_name
            std_10_state
            std_10_reg_no
            std_10_grade_system
            std_10_total_marks
            std_10_scored_marks
            std_10_scored_percentage
            std_10_lang_medium
            std_10_year
            std_12_course_name
            std_12_inst_name
            std_12_state
            std_12_reg_no
            std_12_grade_system
            std_12_total_marks
            std_12_scored_marks
            std_12_scored_percentage
            std_12_lang_medium
            std_12_year
            std_ug_course_name
            std_ug_univ_name
            std_ug_inst_name
            std_ug_state
            std_ug_reg_no
            std_ug_grade_system
            std_ug_total_marks
            std_ug_scored_marks
            std_ug_scored_percentage
            std_ug_lang_medium
            std_ug_doa
            std_ug_doc
            std_bank_name
            std_bank_branch
            std_bank_acct
            std_bank_ifsc
            std_adm_appl_no
            std_adm_free_pymnt_seat
            std_adm_state_or_outside
            std_adm_seat_got_through
            std_adm_category
            std_adm_cet_no
            std_adm_cet_rank
            std_adm_eli_no
            std_adm_eli_date
            std_adm_cet_paid_rcpt_no
            std_adm_cet_paid_rcpt_date
            std_adm_cet_paid_amt
            std_adm_cet_alloted_category
          }
        }
      }
    }
  }
`;
export const GetStudentsBasicDetails = gql`
  query GetStudents(
    $name: String
    $status: String
    $first: Int
    $after: Cursor
    $orderBy: [MstStudentOrder!]
    $token: String!
    $fin_yr_id: ID!
    $input: StdQueryData!
  ) {
    GetStudents(
      token: $token
      fin_yr_id: $fin_yr_id
      first: $first
      after: $after
      orderBy: $orderBy
      where: {
        and: [
          {
            or: [
              { firstNameContainsFold: $name }
              { middleNameContainsFold: $name }
              { stdAdmNoContainsFold: $name }
              { lastNameContainsFold: $name }
            ]
          }
          { stdStatusContainsFold: $status }
        ]
      }
      input: $input
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          std_adm_no
          first_name
          middle_name
          last_name
          std_father_name
          std_father_mobile
          std_mother_name
          std_mother_mobile
          std_profile_filename
          std_reg_no
          std_roll_no
          std_mobile
          std_status
          std_fresher
          std_email
          std_mobile
          std_dob
          std_sex
          std_doa

          category {
            id
            cat_desc
          }
          branch {
            id
            branch_desc
          }
          class {
            id
            class_desc
          }
          dept {
            id
            dept_desc
          }
          semester {
            id
            sem_desc
          }
          section {
            id
            section_desc
          }
          misc_data {
            availed_transport
            availed_hostel
            mobile_app_count
          }
        }
      }
    }
  }
`;
export const GetStudentsForExcel = gql`
  query GetStudentsForExcel(
    $first: Int
    $after: Cursor
    $sortBy: MstStudentOrderField
    $direction: OrderDirection!
    $token: String!
    $fin_yr_id: ID!
    $input: StdQueryData!
  ) {
    GetStudents(
      token: $token
      fin_yr_id: $fin_yr_id
      first: $first
      after: $after
      orderBy: { direction: $direction, field: $sortBy }
      input: $input
    ) {
      totalCount
      pageInfo {
        endCursor
      }
      edges {
        node {
          id
          std_adm_no
          first_name
          middle_name
          last_name
          std_father_name
          std_mother_name
          std_reg_no
          std_mobile
          std_status
          std_fresher
          std_email
          std_mobile

          category {
            id
            cat_desc
          }
          branch {
            id
            branch_desc
          }
          class {
            id
            class_desc
          }
          dept {
            id
            dept_desc
          }
          semester {
            id
            sem_desc
          }
          section {
            id
            section_desc
          }
          std_details {
            id
            std_aadhar
            std_address
            std_place
            std_district
            std_state
            std_phone
            std_pincode
            std_corr_address
            std_corr_place
            std_corr_district
            std_corr_state
            std_corr_phone
            std_corr_pincode
            std_blood_group
            std_weight
            std_height
            std_is_phy_challenged
            std_disability_name
            std_disability_percentage
            std_religion
            std_caste
            std_sub_caste
            std_caste_group
            std_sc_st
            std_caste_minority
            std_rural_urban
            std_mother_tongue
            std_annual_income
            std_martial_status
            std_identity_marks
            std_place_of_birth
            std_nationality
            std_nearest_police_station
            std_nearest_railway_station
            std_km_from_residence
            std_belongs_to_jk
            std_belongs_to_jk_brahmin
            std_is_sports_person
            std_is_ncc
            std_is_nss
            std_is_gadinadu_horanadu
            std_is_defence
            std_is_political_sufferer
            std_is_cultural
            std_is_hiv_devadasi
            std_is_child_of_freedom_fighter
            std_is_child_of_farmer
            std_is_destitude_woman
            std_is_nirashritharu
            std_is_bpl_card
            std_is_non_emp_status
            std_is_ksou_emp
          }
          std_other_details {
            id
            std_program_name
            std_open_elective
            std_fee_amount
            std_fee_payment_date
            std_discipline_1
            std_language_1
            std_challan_no
            std_kk_reservation
            std_kannada_medium
            std_discipline_2
            std_language_2
            std_prev_tc_details
            std_prev_inst
            std_prev_quali_exam
            std_prev_studied_board
            std_prev_studied_state
            std_prev_subject_studied
            std_prev_scored_marks
            std_prev_total_per
            std_prev_tech_subject_studied
            std_prev_tech_total_mark
            std_prev_tech_total_per
            std_10_board_name
            std_10_inst_name
            std_10_state
            std_10_reg_no
            std_10_grade_system
            std_10_scored_marks
            std_10_scored_percentage
            std_10_lang_medium
            std_10_year
            std_12_course_name
            std_12_inst_name
            std_12_state
            std_12_reg_no
            std_12_grade_system
            std_12_total_marks
            std_12_scored_marks
            std_12_scored_percentage
            std_12_lang_medium
            std_12_year
            std_ug_course_name
            std_ug_univ_name
            std_ug_inst_name
            std_ug_state
            std_ug_reg_no
            std_ug_grade_system
            std_ug_total_marks
            std_ug_scored_marks
            std_ug_scored_percentage
            std_ug_lang_medium
            std_ug_doa
            std_ug_doc
            std_bank_name
            std_bank_branch
            std_bank_acct
            std_bank_ifsc
            std_adm_appl_no
            std_adm_free_pymnt_seat
            std_adm_state_or_outside
            std_adm_seat_got_through
            std_adm_category
            std_adm_cet_no
            std_adm_cet_rank
            std_adm_eli_no
            std_adm_eli_date
            std_adm_cet_paid_rcpt_no
            std_adm_cet_paid_rcpt_date
            std_adm_cet_paid_amt
            std_adm_cet_alloted_category
          }
        }
      }
    }
  }
`;

export const GetAcctStdDemand = gql`
  query GetAcctStdDemand(
    $token: String!
    $fin_yr_id: ID!
    $name: String
    $status: String
    $first: Int
    $after: Cursor
    $direction: OrderDirection!
    $input: StdDemandQueryData!
    $stdFresher: Boolean
    $balance: Float # $sortBy: AcctStdDemandOrderField
  ) {
    GetAcctStdDemand(
      token: $token
      fin_yr_id: $fin_yr_id
      input: $input
      after: $after
      first: $first
      orderBy: { direction: $direction }
      where: {
        or: [
          {
            stdDemandBal: $balance
            stdStatusContainsFold: $status
            hasMstStudentWith: [
              {
                or: [
                  { firstNameContainsFold: $name, stdFresher: $stdFresher }
                  { stdAdmNoContainsFold: $name, stdFresher: $stdFresher }
                  { stdRegNoContainsFold: $name, stdFresher: $stdFresher }
                ]
              }
            ]
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          std_demand_ob
          std_demand_amt
          std_demand_concession
          std_demand_receivable
          std_demand_received
          std_demand_refunds
          std_demand_bal
          std_deposit_ob
          std_deposit_amt
          std_deposit_total
          std_deposit_adjusted
          std_deposit_refunded
          std_deposit_bal
          no_of_letters
          new_due_date
          std_status
          remarks
          std_non_demand_collected

          dept {
            dept_desc
          }
          branch {
            branch_desc
          }
          class {
            class_desc
          }
          semester {
            sem_desc
          }
          section {
            section_desc
          }
          category {
            cat_desc
          }
          demand_details {
            id
            fee_refunds
            fee_received
            fee_receivable
            fee_ob
            fee_due_date
            fee_demand
            fee_concession
            fee_bal
            acct_ldgr_id
            acct_ldgr_details {
              ldgr_desc
              id
              ldgr_cash_bank_other
            }
          }
          mst_student {
            id
            std_adm_no
            first_name
            middle_name
            last_name
            std_father_name
            std_mother_name
            std_reg_no
            std_mobile
            std_status
            std_fresher
            std_email
            std_mobile
            std_sex
            std_roll_no
            std_type
            class {
              class_desc
              id
            }
            branch {
              id
              branch_desc
            }
            dept {
              id
              dept_desc
            }

            semester {
              sem_desc
              id
            }
            section {
              id
              section_desc
            }
            category {
              cat_desc
              id
            }
          }
        }
        cursor
      }
    }
  }
`;
export const GetStudentsByAcctLdgrId = gql`
  query GetStudentsByAcctLdgrId(
    $admNo: String
    $name: String
    $first: Int
    $after: Cursor
    $inst_id: ID!
    $orderBy: [MstStudentOrder!]
    $token: String!
    $fin_yr_id: ID!
    $branch_id: ID!
    $class_id: ID!
    $category_id: ID!
    $acct_ldgr_id: ID!
  ) {
    GetStudentsByAcctLdgrId(
      token: $token
      fin_yr_id: $fin_yr_id
      branch_id: $branch_id
      class_id: $class_id
      category_id: $category_id
      acct_ldgr_id: $acct_ldgr_id
      inst_id: $inst_id
      first: $first
      after: $after
      orderBy: $orderBy
      where: {
        or: [{ firstNameContainsFold: $name, stdAdmNoContainsFold: $admNo }]
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          std_adm_no
          first_name
          middle_name
          last_name
          std_father_name
          std_mother_name
          std_reg_no
          std_mobile
          std_status
          std_fresher
          std_email
          std_mobile
          acct_std_demand_details {
            id
            fee_ob
            fee_demand
            fee_concession
            fee_receivable
            fee_received
            fee_refunds
            fee_bal
            acct_ldgr_details {
              id
            }
          }
          acct_std_demand {
            std_demand_ob
            std_demand_amt
            std_demand_concession
            std_demand_receivable
            std_demand_received
            std_demand_refunds
            std_demand_bal
            std_deposit_ob
            std_deposit_amt
            std_deposit_total
            std_deposit_adjusted
            std_deposit_refunded
            std_deposit_bal
          }
          category {
            id
            cat_desc
          }
          branch {
            id
            branch_desc
          }
          class {
            id
            class_desc
          }
          dept {
            id
            dept_desc
          }
          semester {
            id
            sem_desc
          }
          section {
            id
            section_desc
          }
          # misc_data {
          #   id
          #   lib_book_count
          #   student_id
          # }
        }
      }
    }
  }
`;

export const GetStudentsAdmno = gql`
  query GetStudentsAdmno(
    $name: String
    $first: Int
    $after: Cursor
    $sortBy: MstStudentOrderField
    $direction: OrderDirection!
    $token: String!
    $fin_yr_id: ID!
    $input: StdQueryData!
  ) {
    GetStudents(
      token: $token
      fin_yr_id: $fin_yr_id
      first: $first
      after: $after
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [{ stdAdmNoContainsFold: $name }, { firstNameContainsFold: $name }]
      }
      input: $input
    ) {
      totalCount
      pageInfo {
        endCursor
      }
      edges {
        node {
          id
          std_adm_no
          first_name
          middle_name
          last_name
        }
      }
    }
  }
`;
export const GetStudentsByAdmNo = gql`
  query GetStudentsByAdmNo(
    $token: String!
    $fin_yr_id: ID!
    $admNo: String
    $first: Int
    $after: Cursor
    $direction: OrderDirection!
    $input: StdDemandQueryData!
    $id: ID
  ) {
    GetAcctStdDemand(
      token: $token
      fin_yr_id: $fin_yr_id
      first: $first
      after: $after
      orderBy: { direction: $direction }
      input: $input

      where: {
        or: [
          {
            hasMstStudentWith: [
              {
                or: [
                  { stdAdmNoContainsFold: $admNo, id: $id }
                  { firstNameContainsFold: $admNo, id: $id }
                ]
              }
            ]
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          mst_student {
            id
            std_adm_no
            first_name
            middle_name
            last_name
          }
        }
        cursor
      }
    }
  }
`;

//StudentEnquires query
export const GetStudentEnquires = gql`
  query GetStudentEnquires(
    $name: String
    $lastFollowUpStatus: String
    $boughtApplication: Boolean
    $admitted: Boolean
    $branchID: Int
    $classID: Int
    $first: Int
    $after: Cursor
    $sortBy: MstStudentEnquiryOrderField
    $enq_query_type: StdEnqQueryType!
    $direction: OrderDirection!
    $token: String!
    $pr_emp_id: ID!
    $inst_id: ID!
    $id: ID
    $today_date: Time!
    $enq_start_date: Time!
    $enq_end_date: Time!
  ) {
    GetStudentEnquires(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      pr_emp_id: $pr_emp_id
      enq_query_type: $enq_query_type
      enq_start_date: $enq_start_date
      enq_end_date: $enq_end_date
      today_date: $today_date
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [
          {
            branchID: $branchID
            classID: $classID
            firstNameContainsFold: $name
            lastFollowUpStatusContainsFold: $lastFollowUpStatus
            boughtApplication: $boughtApplication
            admitted: $admitted
            id: $id
          }
          {
            branchID: $branchID
            classID: $classID
            middleNameContainsFold: $name
            lastFollowUpStatusContainsFold: $lastFollowUpStatus
            boughtApplication: $boughtApplication
            admitted: $admitted
            id: $id
          }
          {
            branchID: $branchID
            classID: $classID
            lastNameContainsFold: $name
            lastFollowUpStatusContainsFold: $lastFollowUpStatus
            boughtApplication: $boughtApplication
            admitted: $admitted
            id: $id
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          first_name
          middle_name
          last_name
          std_father_name
          std_mother_name
          std_previous_school
          std_previous_place
          std_email
          enq_date
          std_mobile
          counselled_by
          enq_remarks
          admitted
          last_follow_up_made_date
          last_follow_up_status
          next_follow_up_date
          enq_join_status
          follow_up_count
          bought_application
          # admitted_date
          inst_id
          branch_id
          class_id

          branch_details {
            id
            branch_desc
          }
          class_details {
            id
            class_desc
          }
        }
      }
    }
  }
`;
export const GetStudentEnquiresByEnquiryId = gql`
  query GetStudentEnquiresByEnquiryId($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MstStudentEnquiry {
        id
        first_name
        middle_name
        last_name
        std_father_name
        std_mother_name
        std_previous_school
        std_previous_place
        std_email
        std_sex
        std_mobile
        counselled_by
        std_nationality
        enq_remarks
        enq_date
        enq_join_status
        bought_application
        admitted
        reference_through
        next_follow_up_date
        branch_details {
          branch_desc
          id
        }
        class_details {
          id
          class_desc
        }
        inst_details {
          id
          inst_code
          inst_name
          inst_short_name
          inst_address
          inst_place
          inst_state
          inst_pin
          inst_contact_person
          inst_phone
          inst_email
          inst_mobile
          inst_url
          inst_is_active
          inst_status
          customer_details {
            id
            cust_code
            cust_name
            cust_address
            cust_place
            cust_state
            cust_pin
            cust_contact_person
            cust_phone
            cust_email
            cust_mobile
            cust_banner_1
            cust_banner_2
            cust_url
            cust_is_active
            cust_status
            cust_num_inst
          }
        }
        follow_up_details {
          id
          follow_up_date
          follow_up_status
          follow_up_remarks
          follow_up_person
          next_follow_up_date
        }
      }
    }
  }
`;
//student Change Branch
export const GetStudentBranchChange = gql`
  query GetStudentBranchChange(
    $name: String
    $newDeptID: Int
    $newBranchID: Int
    $newClassID: Int
    $newSemesterID: Int
    $newSectionID: Int
    $newCategoryID: Int
    $first: Int
    $after: Cursor
    $sortBy: MstStudentBranchChangeOrderField
    $direction: OrderDirection!
    $token: String!
    $inst_id: ID!
    $fin_yr_id: ID!
  ) {
    GetStudentBranchChange(
      token: $token
      first: $first
      after: $after
      inst_id: $inst_id
      fin_yr_id: $fin_yr_id
      orderBy: { direction: $direction, field: $sortBy }
      where: {
        or: [
          {
            newDeptID: $newDeptID
            newBranchID: $newBranchID
            newClassID: $newClassID
            newSemesterID: $newSemesterID
            newSectionID: $newSectionID
            newCategoryID: $newCategoryID
            hasMstStudentWith: { or: [{ firstNameContainsFold: $name }] }
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        endCursor
      }
      edges {
        node {
          id
          created_at
          comments

          mst_student {
            first_name
            middle_name
            last_name
            std_reg_no
            std_adm_no
          }
          old_dept_details {
            id
            dept_desc
          }
          old_branch_details {
            id
            branch_desc
          }
          old_class_details {
            id
            class_desc
          }
          old_semester_details {
            id
            sem_desc
          }
          old_section_details {
            id
            section_desc
          }
          old_category_details {
            id
            cat_desc
          }
          new_dept_details {
            id
            dept_desc
          }
          new_branch_details {
            id
            branch_desc
          }
          new_class_details {
            id
            class_desc
          }
          new_semester_details {
            id
            sem_desc
          }
          new_section_details {
            id
            section_desc
          }
          new_category_details {
            id
            cat_desc
          }
        }
      }
    }
  }
`;
export const GetStudentBranchChangeByStdID = gql`
  query GetStudentBranchChangeByStdID(
    $inst_id: ID!
    $student_id: ID!
    $fin_yr_id: ID!
    $token: String!
  ) {
    GetStudentBranchChangeByStdID(
      inst_id: $inst_id
      student_id: $student_id
      token: $token
      fin_yr_id: $fin_yr_id
    ) {
      id
      mst_student {
        first_name
        middle_name
        last_name
        std_reg_no
        std_adm_no
      }
      old_dept_details {
        id
        dept_desc
      }
      old_branch_details {
        id
        branch_desc
      }
      old_class_details {
        id
        class_desc
      }
      old_semester_details {
        id
        sem_desc
      }
      old_section_details {
        id
        section_desc
      }
      old_category_details {
        id
        cat_desc
      }
      new_dept_details {
        id
        dept_desc
      }
      new_branch_details {
        id
        branch_desc
      }
      new_class_details {
        id
        class_desc
      }
      new_semester_details {
        id
        sem_desc
      }
      new_section_details {
        id
        section_desc
      }
      new_category_details {
        id
        cat_desc
      }
    }
  }
`;
export const GetStudentEnquiryEmpAssociList = gql`
  query GetStudentEnquiryEmpAssociList(
    $inst_id: ID!
    $enq_start_date: Time!
    $enq_end_date: Time!
    $token: String!
    $today_date: Time!
  ) {
    GetStudentEnquiryEmpAssociList(
      inst_id: $inst_id
      enq_start_date: $enq_start_date
      token: $token
      enq_end_date: $enq_end_date
      today_date: $today_date
    ) {
      pr_emp_id
      emp_id
      emp_title
      emp_first_name
      emp_middle_name
      emp_last_name
      designation_desc
      total_enq_count
      conver_count
    }
  }
`;

//Student Enquiry Dashboard
export const GetStudentEnquiryDashboardDetails = gql`
  query GetStudentEnquiryDashboardDetails(
    $token: String!
    $inst_id: ID!
    $enq_start_date: Time!
    $enq_end_date: Time!
    $today_date: Time!
    $pr_emp_id: ID!
  ) {
    GetStudentEnquiryDashboardDetails(
      token: $token
      inst_id: $inst_id
      today_date: $today_date
      enq_end_date: $enq_end_date
      enq_start_date: $enq_start_date
      pr_emp_id: $pr_emp_id
    ) {
      enq_student_count
      admitted_student_count
      to_follow_up_count
      called_today_count
      interested_count
      not_interested_count
      not_decided_count
      today_follow_up_count
      bought_app_count
      total_vacant_seats
      newspaper_ads_count
      flyers_count
      digital_count
      parents_count
      staff_count
      walkin_count
      others_count
    }
  }
`;

export const GetStudentEnquiryDashboardMonthCount = gql`
  query GetStudentEnquiryDashboardMonthCount(
    $token: String!
    $inst_id: ID!
    $today_date: Time!
  ) {
    GetStudentEnquiryDashboardMonthCount(
      token: $token
      inst_id: $inst_id
      today_date: $today_date
    ) {
      date
      date_day
      daily_total_count
      monthly_total_count
    }
  }
`;

export const GetStudentEnquiryDashboardGraphMonthlyCounts = gql`
  query GetStudentEnquiryDashboardGraphMonthlyCounts(
    $token: String!
    $inst_id: ID!
    $enq_start_date: Time!
    $enq_end_date: Time!
    $today_date: Time!
  ) {
    GetStudentEnquiryDashboardGraphMonthlyCounts(
      token: $token
      inst_id: $inst_id
      today_date: $today_date
      enq_end_date: $enq_end_date
      enq_start_date: $enq_start_date
    ) {
      enq_student_count
      month_name
    }
  }
`;
export const GetEduateModules = gql`
  query GetEduateModules($token: String!) {
    GetEduateModules(token: $token) {
      id
      Name
      Desc
      Version
      LaunchDate
      LastUpdatedDate
    }
  }
`;
export const EduateModuleById = gql`
  query nodequery($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on EduateModule {
        id
        Name
        Desc
        Version
        LaunchDate
        LastUpdatedDate
      }
    }
  }
`;

export const GetStudentEnquiryDashboardTree = gql`
  query GetStudentEnquiryDashboardTree(
    $token: String!
    $inst_id: ID!
    $enq_start_date: Time!
    $enq_end_date: Time!
    $today_date: Time!
  ) {
    GetStudentEnquiryDashboardTree(
      token: $token
      inst_id: $inst_id
      enq_start_date: $enq_start_date
      enq_end_date: $enq_end_date
      today_date: $today_date
    ) {
      branch_name
      branch_id
      branch_desc
      total_enq_count
      total_vacant_seats
      total_seats
      class_details {
        class_name
        class_id
        class_desc
        total_enq_count
        total_vacant_seats
        total_seats
      }
    }
  }
`;

export const GetStudentEnquiryEmpAssociCount = gql`
  query GetStudentEnquiryEmpAssociCount(
    $token: String!
    $inst_id: ID!
    $today_date: Time!
    $pr_emp_id: ID!
  ) {
    GetStudentEnquiryEmpAssociCount(
      token: $token
      inst_id: $inst_id
      today_date: $today_date
      pr_emp_id: $pr_emp_id
    )
  }
`;

export const GetMstInstUploadDocTypes = gql`
  query GetMstInstUploadDocTypes($token: String!, $inst_id: ID!) {
    GetMstInstUploadDocTypes(token: $token, inst_id: $inst_id) {
      id
      document_name
      doc_idx
    }
  }
`;

export const GetAcctStdDemandForAgents = gql`
  query GetAcctStdDemand(
    $token: String!
    $fin_yr_id: ID!
    $name: String
    $status: String
    $first: Int
    $after: Cursor
    $direction: OrderDirection!
    $input: StdDemandQueryData!
    $stdFresher: Boolean
    $balance: Float # $sortBy: AcctStdDemandOrderField
  ) {
    GetAcctStdDemand(
      token: $token
      fin_yr_id: $fin_yr_id
      input: $input
      after: $after
      first: $first
      orderBy: { direction: $direction }
      where: {
        or: [
          {
            stdDemandBal: $balance
            hasMstStudentWith: [
              {
                or: [
                  {
                    firstNameContainsFold: $name
                    stdStatusContainsFold: $status
                    stdFresher: $stdFresher
                  }
                  {
                    stdAdmNoContainsFold: $name
                    stdStatusContainsFold: $status
                    stdFresher: $stdFresher
                  }
                  {
                    stdRegNoContainsFold: $name
                    stdStatusContainsFold: $status
                    stdFresher: $stdFresher
                  }
                ]
              }
            ]
          }
        ]
      }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          mst_student {
            id
            std_adm_no
            std_reg_no
            first_name
            middle_name
            last_name
            std_father_name
            std_mother_name
            std_sex
            std_doa
            std_dob
            std_email
            std_mobile
            std_roll_no
            dept {
              dept_desc
            }
            branch {
              branch_desc
            }
            class {
              class_desc
            }
            semester {
              sem_desc
            }
            section {
              section_desc
            }
            category {
              cat_desc
            }
          }
        }
        cursor
      }
    }
  }
`;

export const GetStudentsNameAndId = gql`
  query GetStudents(
    $name: String
    $status: String
    $first: Int
    $after: Cursor
    $orderBy: [MstStudentOrder!]
    $token: String!
    $fin_yr_id: ID!
    $input: StdQueryData!
  ) {
    GetStudents(
      token: $token
      fin_yr_id: $fin_yr_id
      first: $first
      after: $after
      orderBy: $orderBy
      where: {
        and: [
          {
            or: [
              { firstNameContainsFold: $name }
              { middleNameContainsFold: $name }
              { stdAdmNoContainsFold: $name }
              { lastNameContainsFold: $name }
            ]
          }
          { stdStatusContainsFold: $status }
        ]
      }
      input: $input
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          first_name
          middle_name
          last_name
        }
      }
    }
  }
`;
