import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Enlarge from "../../../../../images/Enlarge.svg";
import Close from "../../../../../images/Close.svg";
import Modal from "react-modal";

import { TableHeaderProps } from "../../../../../Types/Tables";
import { useLazyQuery } from "@apollo/client";

import {
  DateRange,
  filterVouchersByType,
  formatter,
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleDbOrCr,
  toInputStandardDate,
  toIsoDate,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import {
  EnquiryStudentDetailsByMasterVoucherId,
  PassedOutStudentDetailsByMasterVoucherId,
  StudentDetailsByMasterVoucherId,
} from "../../../../../queries/common";
import {
  EditModalCustomStyles,
  PrintModalStyles,
  StudentModalStyles,
} from "../../../../../styles/ModalStyles";
import StudentDemandList from "./StudentVoucherView";
import VoucherModal from "./VoucherModal";
import {
  BankOrCash,
  DebitOrCredit,
  ExcelAlignment,
  ExcelFont,
  ExcelFooterHeader,
  ExcelPageHeader,
  ExcelSheetsNames,
  PageFor,
  ReceiptTypes,
  VoucherPageType,
} from "../../../../../utils/Enum.types";
import {
  GetAcctLdgrMonthlyDataByInstIdData,
  GetAcctLdgrMonthlyDataByInstIdDetails,
  GetAcctLdgrMonthlyDataByInstIdVars,
  GetAcctRegisterMonthlyDataByInstIdData,
  GetAcctRegisterMonthlyDataByInstIdDetails,
  GetAcctRegisterMonthlyDataByInstIdVars,
  nodevars,
  VouchernodeData,
} from "../../../../../Types/Accounting";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../../utils/constants";
import useToken from "../../../../../customhooks/useToken";
import { Title } from "../../../../../stories/Title/Title";
import Eduate from "../../../../../images/Eduate_Logo_image.png";
import { Cell } from "exceljs";
import ExcelJS from "exceljs";
import useAcctVoucherDetailsByDates, {
  GetAcctVouchersEdges,
  voucherMasterDetails,
} from "../../../hooks/useAcctVoucherDetailsByDates";
import { AppContext } from "../../../../../context/context";
import { payloadTypes } from "../../../../../context/reducer";
import {
  GetAcctLdgrMonthlyDataByInstId,
  GetAcctRegisterMonthlyDataByInstId,
} from "../../../queries/Accountingledgers/List/ById";
import { useParams } from "react-router-dom";
import { GroupLedgerRPTypes } from "../../../common/QueryTypes";
import { Button } from "../../../../../stories/Button/Button";
import Print from "../../../../Print/Accounts/LedgerReports/Template1";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FOOTER_CSS,
  FOOTER_CSS_RED,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  NEW_SHEET,
  TABLE_HEADER_CSS,
} from "../../../../Library/Constants";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
import { Label } from "../../../../../stories/Label/Label";
import { VoucherQueryTypes } from "../../../common/Enum.types";
import {
  LedgersOrRegisters,
  OtherAccountLedgerBooks,
} from "../../../../../utils/types";
import { belongsAccountLedgerBooks } from "../MonthlyTotal";
import useAcctTableJson from "../../../json/useAcctTableJson";

interface IFooter {
  acctLdgrmonthlyItem: GetAcctLdgrMonthlyDataByInstIdDetails[];
  registerMonthlyItem: GetAcctRegisterMonthlyDataByInstIdDetails[];
}

interface particularsType {
  description: string;
  amount: number;
  type: DebitOrCredit;
}
const getMonthlySummary = (
  acctLdgrmonthlyItem: GetAcctLdgrMonthlyDataByInstIdDetails[],
  date: string
) => {
  const acctMonthlyData = acctLdgrmonthlyItem.find(
    (item) => toIsoDate(item.acct_ldgr_month_date) === toIsoDate(date)
  );

  if (!acctMonthlyData) {
    return {
      openingBalance: 0,
      debitTotal: 0,
      creditTotal: 0,
      closingBalance: 0,
    };
  }
  const acctLedgerResultDebitorCredit = handleDbOrCr(
    acctMonthlyData.acct_group_ldgr_details.gr_ldgr_rp as GroupLedgerRPTypes,
    acctMonthlyData.acct_ldgr_month_cb
  );
  const ObDebitorCredit = handleDbOrCr(
    acctMonthlyData.acct_group_ldgr_details.gr_ldgr_rp as GroupLedgerRPTypes,
    acctMonthlyData.acct_ldgr_month_ob
  );

  return {
    openingBalance: Math.abs(acctMonthlyData.acct_ldgr_month_ob),
    debitTotal: acctMonthlyData.acct_ldgr_month_db,
    creditTotal: acctMonthlyData.acct_ldgr_month_cr,
    closingBalance: Math.abs(acctMonthlyData.acct_ldgr_month_cb),
    showSecondCell: acctLedgerResultDebitorCredit === DebitOrCredit.DEBIT,
    ObDebitorCredit,
    acctLedgerResultDebitorCredit,
  };
};

interface Props {
  setCompleteDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
  CompleteDetailsModal: boolean;
  pageType: PageFor;
  type: LedgersOrRegisters;
}
const CompleteTransactionModal = ({
  CompleteDetailsModal,
  setCompleteDetailsModal,
  pageType,
  type,
}: Props) => {
  const { dispatch, state } = useContext(AppContext);
  const { token } = useToken();
  let xlsDownload = false;

  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const [vouchers, setVouchers] = useState<GetAcctVouchersEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [query_type, setQueryType] = useState("");
  const { InstId } = useParams();
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const [acctLedgerMonthData, setAcctLedgerMonthlyData] = useState<
    GetAcctLdgrMonthlyDataByInstIdDetails[]
  >([]);
  const [registerItems, setRegisterItems] = useState<
    GetAcctRegisterMonthlyDataByInstIdDetails[]
  >([]);
  const [studentDetails, setStudentDetails] = useState<voucherMasterDetails[]>(
    []
  );

  const [passedOutStudentDetails, setPassedStudentDetails] = useState<
    voucherMasterDetails[]
  >([]);

  const [enquiryStudentDetails, setEnquiryStudentDetails] = useState<
    voucherMasterDetails[]
  >([]);

  const [filters, setFilters] = useState([
    { label: "Show narration also", isChecked: false },
    { label: "All Vouchers(Both Credit & Debit)", isChecked: false },
    { label: "Credit Entries Only", isChecked: false },
    { label: "Debit Entries Only", isChecked: false },
  ]);
  const [voucherTypeModal, setVoucherTypeModal] = useState(false);
  const {
    closingBalance,
    debitTotal,
    creditTotal,
    openingBalance,
    ObDebitorCredit,
  } = getMonthlySummary(acctLedgerMonthData, state.date.toString());
  const [GetMonthlyLedgersData] = useLazyQuery<
    GetAcctLdgrMonthlyDataByInstIdData,
    GetAcctLdgrMonthlyDataByInstIdVars
  >(GetAcctLdgrMonthlyDataByInstId);

  const [GetMonthlyRegistersData] = useLazyQuery<
    GetAcctRegisterMonthlyDataByInstIdData,
    GetAcctRegisterMonthlyDataByInstIdVars
  >(GetAcctRegisterMonthlyDataByInstId);
  const dates = DateRange(state.date.toString());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [studentDemandModal, setStudentDemandModal] = useState(false);
  const [paymentVoucherModal, setPaymentVoucherModal] = useState(false);
  const [v_no, setV_no] = useState("");

  const [GetStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    StudentDetailsByMasterVoucherId
  );

  const [GetPassedStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    PassedOutStudentDetailsByMasterVoucherId
  );

  const [GetEnqStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    EnquiryStudentDetailsByMasterVoucherId
  );
  const { VoucherDetails } = useAcctVoucherDetailsByDates(
    rowsPerPage,
    endDate,
    startDate,
    query_type,
    v_no
  );

  const handleSelectionOfUnmappedClass = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;

    let tempClass = filters.map((filter) =>
      filter.label === name ? { ...filter, isChecked: checked } : filter
    );

    if (name === "Credit Entries Only" && checked) {
      tempClass = tempClass.map((filter) =>
        filter.label === "Debit Entries Only" || filter.label === "All Vouchers"
          ? { ...filter, isChecked: false }
          : filter
      );
    } else if (name === "Debit Entries Only" && checked) {
      tempClass = tempClass.map((filter) =>
        filter.label === "Credit Entries Only" ||
        filter.label === "All Vouchers"
          ? { ...filter, isChecked: false }
          : filter
      );
    } else if (name === "All Vouchers" && checked) {
      tempClass = tempClass.map((filter) =>
        filter.label === "Credit Entries Only" ||
        filter.label === "Debit Entries Only"
          ? { ...filter, isChecked: false }
          : filter
      );
    } else if (
      (name === "Credit Entries Only" || name === "Debit Entries Only") &&
      !checked &&
      tempClass.find((filter) => filter.isChecked)
    ) {
      tempClass = tempClass.map((filter) =>
        filter.label === "All Vouchers"
          ? { ...filter, isChecked: false }
          : filter
      );
    }

    setFilters(tempClass);
  };

  const { InstDetails } = useInstDetails(1);

  const headerData = [
    "Sl No",
    "Date",
    "Particulars",
    filters[0].isChecked ? "Narration" : null,
    "Voucher Types",
    "Voucher No",
    filters[3].isChecked ? "Debit" : filters[2].isChecked ? null : "Debit",
    filters[2].isChecked ? "Credit" : filters[3].isChecked ? null : "Credit",
  ].filter((header) => header !== null);
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(NEW_SHEET);

    const dataRows = transactionData
      ? transactionData?.map((item) =>
          [
            item.SlNo,
            item.Date,
            item.Particulars,
            filters[0].isChecked ? item.Narration : null,
            item.VTypes,
            item.VoucherNo,
            filters[2].isChecked ? null : item.Debit,
            filters[3].isChecked ? null : item.Credit,
          ].filter((item) => item !== null)
        )
      : [];
    worksheet.views = [
      {
        state: "frozen",
        xSplit: 0,
        ySplit: 6,
        activeCell: "A7",
      },
    ];

    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 70;
    worksheet.getColumn(4).width = filters[0].isChecked ? 40 : 25;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 25;
    worksheet.getColumn(7).width = 25;
    worksheet.getColumn(8).width = 25;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });
          const imageConfig = filters[0].isChecked ? "H" : "G";
          worksheet.addImage(imageV, `${imageConfig}1:${imageConfig}3`);
          worksheet.mergeCells(1, 1, 1, filters[0].isChecked ? 8 : 7);

          const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
          mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
          mergedCell.fill = HEADER_CSS;
          mergedCell.font = headerStyle[0].font;
          mergedCell.alignment = HEADER_ALIGNMENT;
          const mergedAddress: Cell = worksheet.getCell(A2_CELL);
          mergedAddress.value = InstDetails.data?.nodes[0]?.inst_address;
          mergedAddress.fill = HEADER_CSS;
          mergedAddress.font = ADDRESS_FONT;
          mergedAddress.alignment = HEADER_ALIGNMENT;
          worksheet.mergeCells(`A2:${imageConfig}2`);

          const mergedPlace: Cell = worksheet.getCell(A3_CELL);
          mergedPlace.value =
            InstDetails.data?.nodes[0]?.inst_place +
            "-" +
            InstDetails.data?.nodes[0]?.inst_pin;

          mergedPlace.fill = HEADER_CSS;
          mergedPlace.font = ADDRESS_FONT;
          mergedPlace.alignment = ADDRESS_ALIGNMENT;
          worksheet.mergeCells(`A3:${imageConfig}3`);

          const mergedHeader: Cell = worksheet.getCell(C4_CELL);
          mergedHeader.value = state.ledger ? state.ledger.label : EMPTY_STRING;
          // toStandardCase(
          //   acctLedgers.data?.GetAcctLdgrs.edges.find(
          //     ({ node: { id } }) => id === state.accountLedgerId
          //   )?.node.ldgr_desc ?? EMPTY_STRING
          // );
          mergedHeader.fill = FILE_NAME_CSS;
          mergedHeader.font = FILE_NAME_FONT;
          mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
          const fileConfig = filters[0].isChecked ? "F" : "E";
          worksheet.mergeCells(`C4:${fileConfig}4`);
          const dateConfig = filters[0].isChecked ? "G" : "F";
          const mergedDate: Cell = worksheet.getCell(`${dateConfig}4`);
          mergedDate.value = `From: ${toStandardDate(
            startDate
          )} To: ${toStandardDate(endDate)}`;
          mergedDate.fill = FILE_NAME_CSS;
          mergedDate.font = FIN_YEAR_FONT;
          mergedDate.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet.mergeCells(`${dateConfig}4:${imageConfig}4`);

          const mergedYear: Cell = worksheet.getCell(A4_CELL);
          mergedYear.value = state.ActiveFinYr
            ? ExcelPageHeader.YEAR + state.ActiveFinYr
            : EMPTY_STRING;
          mergedYear.fill = FILE_NAME_CSS;
          mergedYear.font = FIN_YEAR_FONT;
          mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
          worksheet.mergeCells("A4:B4");

          let Char = FIRST_INDEX;

          for (let i = 0; i < headerData.length; i++) {
            Char = String.fromCharCode(Char.charCodeAt(0) + 1);

            const rowData: Cell = worksheet.getCell(Char + 5);
            rowData.value = headerData[i];
            rowData.fill = TABLE_HEADER_CSS;
            rowData.border = BORDER_DATA;
            rowData.font = ACC_HEADER_FONT;
            rowData.alignment = { horizontal: ExcelAlignment.CENTER };
          }
          const footerRow = worksheet.addRow([]);
          const Obcell = filters[0].isChecked ? 6 : 5;
          footerRow.getCell(Obcell).value = ExcelFooterHeader.OPENING_BALANCE;
          footerRow.getCell(Obcell).font = {
            name: ExcelFont.ARIAL,
            size: 12,
          };
          const { openingBalance, showSecondCell } = getMonthlySummary(
            acctLedgerMonthData,
            state.date.toString()
          );
          const debitCredit = showSecondCell ? "Dr" : "Cr";
          const column = filters[0].isChecked ? 7 : 6;

          const openingBalCss =
            filters[0].isChecked && showSecondCell ? "G" : "F";
          const cellCondition = showSecondCell ? column : 8;
          footerRow.getCell(cellCondition).value =
            openingBalance + " " + debitCredit;

          for (let i = 5; i <= 8; i++) {
            footerRow.getCell(i).alignment = {
              horizontal: ExcelAlignment.RIGHT,
            };
          }
          const BalRef = showSecondCell
            ? `${openingBalCss}${footerRow.number}`
            : `H${footerRow.number}`;
          worksheet.addConditionalFormatting({
            ref: BalRef,
            rules: FOOTER_CSS,
          });
          dataRows!.forEach((rowData) => {
            const row = worksheet.addRow(rowData);
            row.eachCell({ includeEmpty: true }, (cell) => {
              cell.alignment = { horizontal: ExcelAlignment.LEFT };
              cell.font = { name: ExcelFont.CENTURY_GOTHIC, size: 9 };
              row.getCell(column).alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };

              row.getCell(7).alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
              row.getCell(8).alignment = {
                horizontal: ExcelAlignment.RIGHT,
              };
            });
          });

          const footerRow2 = worksheet.addRow([]);
          footerRow2.getCell(Obcell).value = ExcelFooterHeader.TOTAL;
          footerRow2.getCell(Obcell).font = {
            name: ExcelFont.ARIAL,
            size: 10,
          };
          const { debitTotal, creditTotal } = getMonthlySummary(
            acctLedgerMonthData,
            state.date.toString()
          );
          const DBtotal = filters[0].isChecked ? 7 : 6;
          const CRtotal = filters[0].isChecked ? 8 : 7;
          if (filters[2].isChecked) {
            footerRow2.getCell(DBtotal).value = format(creditTotal);
          } else if (filters[3].isChecked) {
            footerRow2.getCell(DBtotal).value = format(debitTotal);
          } else {
            footerRow2.getCell(DBtotal).value = format(debitTotal);
            footerRow2.getCell(CRtotal).value = format(creditTotal);
          }

          for (let i = 5; i <= 8; i++) {
            footerRow2.getCell(i).alignment = {
              horizontal: ExcelAlignment.RIGHT,
            };
          }
          const totalCss = filters[0].isChecked ? "G" : "F";
          const totalCss2 = filters[0].isChecked ? "H" : "G";

          worksheet.addConditionalFormatting({
            ref: filters[2].isChecked
              ? `${totalCss}${footerRow2.number}`
              : filters[3].isChecked
              ? `${totalCss}${footerRow2.number}`
              : `${totalCss}${footerRow2.number}:${totalCss2}${footerRow2.number}`,
            rules: FOOTER_CSS,
          });
          const footerRow3 = worksheet.addRow([]);
          footerRow3.getCell(Obcell).value = ExcelFooterHeader.CLOSING_BALANCE;
          footerRow3.getCell(Obcell).font = {
            name: ExcelFont.ARIAL,
            size: 12,
          };
          const { closingBalance } = getMonthlySummary(
            acctLedgerMonthData,
            state.date.toString()
          );
          const close = showSecondCell ? column : 8;
          footerRow3.getCell(close).value = format(closingBalance);

          footerRow3.getCell(close).alignment = {
            horizontal: ExcelAlignment.RIGHT,
          };
          const closeBalRef = showSecondCell
            ? `${openingBalCss}${footerRow3.number}`
            : `H${footerRow3.number}`;
          worksheet.addConditionalFormatting({
            ref: closeBalRef,
            rules: FOOTER_CSS_RED,
          });

          workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
            const blob = new Blob([buffer], {
              type: BLOB_TYPE,
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement(ELEMENT);
            link.href = url;
            link.setAttribute(DOWNLOAD, ExcelSheetsNames.COMPLETE_TRANSACTION);
            document.body.appendChild(link);
            link.click();
          });
          setExcelFlag(false);
          setRowsPerPage(ROWS_PER_PAGE);
        });
      });
  };

  const filteredStudentsReceipts = filterVouchersByType(
    ReceiptTypes.STUDENT_RECEIPT,
    VoucherDetails.data!
  );

  const filteredPassedStudentReceipts = VoucherDetails.data
    ? VoucherDetails.data.GetAcctVouchers.edges.filter(
        ({ node }) =>
          node.v_type === ReceiptTypes.STUDENT_RECEIPT &&
          node.v_std_passout_receipt
      )
    : [];

  const filteredEnquiryStudentReceipts = VoucherDetails.data
    ? VoucherDetails.data.GetAcctVouchers.edges.filter(
        ({ node }) =>
          node.v_type === ReceiptTypes.STUDENT_RECEIPT && node.v_std_enquiry
      )
    : [];
  const FetchStudentDetails = (edge: voucherMasterDetails) => {
    const student = edge.v_std_passout_receipt
      ? passedOutStudentDetails?.find((id) => id?.id === edge.id)
      : edge.v_std_enquiry
      ? enquiryStudentDetails.find(({ id }) => id === edge.id)
      : studentDetails?.find((id) => id?.id === edge.id);

    const findCrOrDr = student?.acct_voucher_details.find(
      (v_d) => v_d.acct_ldgr_id === state.ledger?.value
    )?.vo_cr_db;
    const findOtherCrDr = student?.acct_voucher_details.filter(
      (v_db) => v_db.vo_cr_db !== findCrOrDr
    );
    if (student) {
      if (edge.v_std_passout_receipt) {
        return (
          student?.passout_std_details.std_name +
          " " +
          `(${edge.branch_details.branch_desc}/ ${edge.class_details.class_desc} / ${findOtherCrDr?.[0].acct_ldgr.ldgr_desc})`
        );
      } else if (edge.v_std_enquiry) {
        return (
          student.enq_std_details.first_name +
          " " +
          student?.enq_std_details.middle_name +
          " " +
          student?.enq_std_details.last_name +
          " " +
          `(${edge.branch_details.branch_desc}/ ${edge.class_details.class_desc} / ${findOtherCrDr?.[0].acct_ldgr.ldgr_desc})`
        );
      } else if (edge.v_std_receipt) {
        return (
          student?.mst_student.first_name +
          " " +
          student?.mst_student.middle_name +
          " " +
          student?.mst_student.last_name +
          " " +
          `(${edge.branch_details.branch_desc}/ ${edge.class_details.class_desc} / ${findOtherCrDr?.[0].acct_ldgr.ldgr_desc})`
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const FetchParticularDetails = (edge: voucherMasterDetails) => {
    if (edge) {
      const res = edge.acct_voucher_details.find(
        (f) => f.acct_ldgr.id === state.ledger?.value
      );
      const filteredLedgers = edge.acct_voucher_details.filter(
        (f) => f.acct_ldgr.id !== state.ledger?.value
      );

      if (res?.acct_ldgr.ldgr_cash_bank_other !== BankOrCash.OTHERS) {
        if (
          edge.v_type === ReceiptTypes.STUDENT_RECEIPT &&
          edge.v_std_receipt &&
          !edge.v_std_enquiry &&
          !edge.v_std_passout_receipt
        ) {
          return FetchStudentDetails(edge);
        } else if (edge.v_std_enquiry) {
          return (
            edge?.acct_voucher_details?.find(
              (f) => f.vo_cr_db === DebitOrCredit.DEBIT
            )!?.acct_ldgr?.ldgr_desc +
            " " +
            `(${
              edge.v_transcation_narration + edge.v_std_enquiry
                ? "Enquiry Receipt"
                : ""
            })`
          );
        } else if (edge.v_std_passout_receipt) {
          return FetchStudentDetails(edge);
        } else if (edge.v_type === ReceiptTypes.PYMT) {
          if (filteredLedgers.length === 1) {
            return filteredLedgers[0].acct_ldgr.ldgr_desc;
          } else
            return (
              <ul className="bank-book__table-section--transaction-details--table--ledger-list">
                {filteredLedgers
                  .sort((a, b) => a.vo_sl_no - b.vo_sl_no)
                  .map((d, index) => {
                    return (
                      <li key={index}>
                        <span>{d.acct_ldgr.ldgr_desc}</span>
                        <span className="number-font">
                          {format(d.vo_cr ? d.vo_cr : d.vo_db)}
                          {` `}
                          {d.vo_cr ? DebitOrCredit.CREDIT : DebitOrCredit.DEBIT}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            );
        } else if (edge.v_type === ReceiptTypes.RCPT) {
          if (filteredLedgers.length === 1) {
            return filteredLedgers[0].acct_ldgr.ldgr_desc;
          } else
            return (
              <ul className="bank-book__table-section--transaction-details--table--ledger-list">
                {filteredLedgers.map((d, index) => {
                  return (
                    <li key={index}>
                      <span>{d.acct_ldgr.ldgr_desc}</span>
                      <span className="number-font">
                        {format(d.vo_cr ? d.vo_cr : d.vo_db)}
                        {` `}
                        {d.vo_cr ? DebitOrCredit.CREDIT : DebitOrCredit.DEBIT}
                      </span>
                    </li>
                  );
                })}
              </ul>
            );
        } else if (edge.v_type === ReceiptTypes.CONTRA) {
          let res = edge.acct_voucher_details.filter(
            (d) => d.acct_ldgr.id !== state.ledger?.value
          );
          if (res.length === 1) {
            return res[0].acct_ldgr.ldgr_desc;
          } else if (res.length > 1) {
            return (
              <ul className="bank-book__table-section--transaction-details--table--ledger-list">
                {res.map((d, index) => {
                  return (
                    <li key={index}>
                      <span>{d.acct_ldgr.ldgr_desc}</span>
                      <span className="number-font">
                        {format(d.vo_cr ? d.vo_cr : d.vo_db)}
                        {` `}
                        {d.vo_cr ? DebitOrCredit.CREDIT : DebitOrCredit.DEBIT}
                      </span>
                    </li>
                  );
                })}
              </ul>
            );
          }
        }
      } else if (res.acct_ldgr.ldgr_cash_bank_other === BankOrCash.OTHERS) {
        const filteredAcctVouchers = edge.acct_voucher_details.filter(
          (f) => f.acct_ldgr.id !== state.ledger?.value
        );
        if (edge.v_type === ReceiptTypes.STUDENT_RECEIPT) {
          return FetchStudentDetails(edge);
        }
        if (filteredAcctVouchers.length === 1) {
          return filteredAcctVouchers[0].acct_ldgr.ldgr_desc;
        } else if (filteredAcctVouchers.length > 1) {
          return (
            <ul className="bank-book__table-section--transaction-details--table--ledger-list">
              {filteredAcctVouchers.map((d, index) => {
                return (
                  <li key={index}>
                    <span>{d.acct_ldgr.ldgr_desc}</span>
                    <span className="number-font">
                      {format(d.vo_cr ? d.vo_cr : d.vo_db)}
                      {` `}
                      {d.vo_cr ? DebitOrCredit.CREDIT : DebitOrCredit.DEBIT}
                    </span>
                  </li>
                );
              })}
            </ul>
          );
        }
      } else return "";
    }
  };

  const FetchParticularDetailsForExcel = (edge: voucherMasterDetails) => {
    const rows = [];
    if (edge) {
      const res = edge.acct_voucher_details.find(
        (f) => f.acct_ldgr.id === state.ledger?.value
      );
      const filteredLedgers = edge.acct_voucher_details.filter(
        (f) => f.acct_ldgr.id !== state.ledger?.value
      );

      if (res?.acct_ldgr.ldgr_cash_bank_other !== BankOrCash.OTHERS) {
        if (
          edge.v_type === ReceiptTypes.STUDENT_RECEIPT &&
          edge.v_std_receipt &&
          !edge.v_std_enquiry
        ) {
          rows.push(FetchStudentDetails(edge));
        } else if (edge.v_std_enquiry) {
          const narration =
            edge.v_transcation_narration + edge.v_std_enquiry
              ? "Enquiry Receipt"
              : "";
          const row = {
            description: `${
              edge?.acct_voucher_details?.find(
                (f) => f.vo_cr_db === DebitOrCredit.DEBIT
              )!?.acct_ldgr?.ldgr_desc
            } (${narration})`,
            amount: format(
              edge.acct_voucher_details[0].vo_cr
                ? edge.acct_voucher_details[0].vo_cr
                : edge.acct_voucher_details[0].vo_db
            ),
            type: edge.acct_voucher_details[0].vo_cr
              ? DebitOrCredit.CREDIT
              : DebitOrCredit.DEBIT,
          };
          rows.push(row);
        } else if (edge.v_type === ReceiptTypes.PYMT) {
          if (filteredLedgers.length === 1) {
            const row = {
              description: filteredLedgers[0].acct_ldgr.ldgr_desc,
              amount: format(
                filteredLedgers[0].vo_cr
                  ? filteredLedgers[0].vo_cr
                  : filteredLedgers[0].vo_db
              ),
              type: filteredLedgers[0].vo_cr
                ? DebitOrCredit.CREDIT
                : DebitOrCredit.DEBIT,
            };
            rows.push(row);
          } else {
            filteredLedgers.forEach((d) => {
              const row = {
                description: d.acct_ldgr.ldgr_desc,
                amount: format(d.vo_cr ? d.vo_cr : d.vo_db),
                type: d.vo_cr ? DebitOrCredit.CREDIT : DebitOrCredit.DEBIT,
              };
              rows.push(row);
            });
          }
        } else if (edge.v_type === ReceiptTypes.RCPT) {
          if (filteredLedgers.length === 1) {
            rows.push({
              description: filteredLedgers[0].acct_ldgr.ldgr_desc,
              amount: format(
                filteredLedgers[0].vo_cr
                  ? filteredLedgers[0].vo_cr
                  : filteredLedgers[0].vo_db
              ),
              type: filteredLedgers[0].vo_cr
                ? DebitOrCredit.CREDIT
                : DebitOrCredit.DEBIT,
            });
          } else {
            filteredLedgers.forEach((d) => {
              rows.push({
                description: d.acct_ldgr.ldgr_desc,
                amount: format(d.vo_cr ? d.vo_cr : d.vo_db),
                type: d.vo_cr ? DebitOrCredit.CREDIT : DebitOrCredit.DEBIT,
              });
            });
          }
        } else if (edge.v_type === ReceiptTypes.CONTRA) {
          let res = edge.acct_voucher_details.filter(
            (d) => d.acct_ldgr.id !== state.ledger?.value
          );
          if (res.length === 1) {
            rows.push({
              description: res[0].acct_ldgr.ldgr_desc,
              amount: format(res[0].vo_cr ? res[0].vo_cr : res[0].vo_db),
              type: res[0].vo_cr ? DebitOrCredit.CREDIT : DebitOrCredit.DEBIT,
            });
          } else if (res.length > 1) {
            res.forEach((d) => {
              rows.push({
                description: d.acct_ldgr.ldgr_desc,
                amount: format(d.vo_cr ? d.vo_cr : d.vo_db),
                type: d.vo_cr ? DebitOrCredit.CREDIT : DebitOrCredit.DEBIT,
              });
            });
          }
        } else if (res?.acct_ldgr.ldgr_cash_bank_other === BankOrCash.OTHERS) {
          const filteredAcctVouchers = edge.acct_voucher_details.filter(
            (f) => f.acct_ldgr.id !== state.ledger?.value
          );
          if (edge.v_type === ReceiptTypes.STUDENT_RECEIPT) {
            rows.push(...FetchStudentDetails(edge));
          }
          if (filteredAcctVouchers.length === 1) {
            rows.push({
              description: filteredAcctVouchers[0].acct_ldgr.ldgr_desc,
              amount: filteredAcctVouchers[0].vo_cr
                ? filteredAcctVouchers[0].vo_cr
                : filteredAcctVouchers[0].vo_db,
              type: filteredAcctVouchers[0].vo_cr
                ? DebitOrCredit.CREDIT
                : DebitOrCredit.DEBIT,
            });
          } else if (filteredAcctVouchers.length > 1) {
            filteredAcctVouchers.forEach((d) => {
              rows.push({
                description: d.acct_ldgr.ldgr_desc,
                amount: d.vo_cr ? d.vo_cr : d.vo_db,
                type: d.vo_cr ? DebitOrCredit.CREDIT : DebitOrCredit.DEBIT,
              });
            });
          }
        }
      }

      return rows;
    }
  };

  const FetchDebitDetails = (edge: voucherMasterDetails) => {
    if (belongsAccountLedgerBooks(type)) {
      const filteredLedgers = edge.acct_voucher_details.find(
        (f) => f.acct_ldgr.id === state.ledger?.value
      );
      if (!edge || !filteredLedgers) return "-";

      switch (edge.v_type) {
        case ReceiptTypes.STUDENT_RECEIPT:
          const cashBankOther = filteredLedgers?.acct_ldgr.ldgr_cash_bank_other;
          const isCashOrBank =
            cashBankOther === BankOrCash.CASH ||
            cashBankOther === BankOrCash.BANK;
          const isOthers = cashBankOther === BankOrCash.OTHERS;

          if (isCashOrBank) {
            if (
              edge.v_std_refund === false &&
              edge.v_std_refund_deposit === false
            ) {
              return filteredLedgers?.vo_db
                ? format(filteredLedgers?.vo_db || 0)
                : "-";
            } else {
              return "-";
            }
          } else if (isOthers) {
            if (
              (edge.v_std_refund === false ||
                edge.v_std_refund_deposit === false) &&
              !edge.v_std_deposit_adjusted
            ) {
              const v_db = edge.acct_voucher_details.find(
                ({ acct_ldgr }) => acct_ldgr.id === state.accountLedgerId
              );
              if (v_db) return v_db.vo_db ? v_db.vo_db : "-";
              else return "-";
            } else {
              return filteredLedgers?.vo_db === 0
                ? "-"
                : format(filteredLedgers?.vo_db || 0);
            }
          }

          break;
        case ReceiptTypes.RCPT:
          if (
            filteredLedgers?.acct_ldgr.ldgr_cash_bank_other ===
              BankOrCash.CASH ||
            filteredLedgers?.acct_ldgr.ldgr_cash_bank_other === BankOrCash.BANK
          ) {
            return filteredLedgers && filteredLedgers.vo_db
              ? format(filteredLedgers.vo_db)
              : "-";
          } else return "-";
        case ReceiptTypes.CONTRA:
          return filteredLedgers?.vo_db === 0
            ? "-"
            : format(filteredLedgers!.vo_db);
        case ReceiptTypes.PYMT:
        case ReceiptTypes.JOURNAL:
          return filteredLedgers?.vo_db === 0
            ? "-"
            : format(filteredLedgers?.vo_db!);

        default:
          return "-";
      }
    } else {
      if (!edge) return "-";
      switch (edge.v_type) {
        case ReceiptTypes.STUDENT_RECEIPT:
          return edge.v_std_refund || edge.v_std_refund_deposit
            ? "-"
            : format(
                edge.acct_voucher_details.find(
                  ({ vo_cr_db }) => vo_cr_db === DebitOrCredit.DEBIT
                )?.vo_db_total!
              );

        case ReceiptTypes.RCPT:
          return format(
            edge.acct_voucher_details.find(
              ({ vo_cr_db }) => vo_cr_db === DebitOrCredit.DEBIT
            )?.vo_db_total!
          );
        case ReceiptTypes.CONTRA:
          return format(
            edge.acct_voucher_details.find(
              ({ vo_cr_db }) => vo_cr_db === DebitOrCredit.DEBIT
            )?.vo_db_total!
          );
        case ReceiptTypes.PYMT:
          return "-";
        case ReceiptTypes.JOURNAL:
          return format(
            edge.acct_voucher_details.find(
              ({ vo_cr_db }) => vo_cr_db === DebitOrCredit.DEBIT
            )?.vo_db_total!
          );
        default:
          return "-";
      }
    }
  };

  const FetchCreditDetails = (edge: voucherMasterDetails) => {
    if (belongsAccountLedgerBooks(type)) {
      const filteredLedger = edge.acct_voucher_details.find(
        (f) => f.acct_ldgr.id === state.ledger?.value
      );

      if (!edge || !filteredLedger) return "-";
      switch (edge.v_type) {
        case ReceiptTypes.STUDENT_RECEIPT:
          if (
            filteredLedger?.acct_ldgr.ldgr_cash_bank_other ===
              BankOrCash.CASH ||
            filteredLedger?.acct_ldgr.ldgr_cash_bank_other === BankOrCash.BANK
          ) {
            return edge.v_std_refund || edge.v_std_refund_deposit
              ? format(filteredLedger.vo_cr)
              : "-";
          } else {
            return (edge.v_std_refund &&
              edge.v_std_refund_deposit &&
              !edge.v_std_deposit_adjusted) ||
              filteredLedger.vo_cr === 0
              ? "-"
              : format(filteredLedger.vo_cr);
          }
        case ReceiptTypes.RCPT:
          return filteredLedger.acct_ldgr.ldgr_cash_bank_other !==
            BankOrCash.OTHERS
            ? "-"
            : format(filteredLedger.vo_cr);
        case ReceiptTypes.CONTRA:
          return filteredLedger.vo_cr === 0
            ? "-"
            : format(filteredLedger.vo_cr);
        case ReceiptTypes.PYMT:
        case ReceiptTypes.JOURNAL:
          if (
            filteredLedger?.acct_ldgr.ldgr_cash_bank_other === BankOrCash.OTHERS
          ) {
            return filteredLedger.vo_cr > 0
              ? format(filteredLedger.vo_cr)
              : xlsDownload
              ? `- {edge.v_type}`
              : `-`;
          } else return format(filteredLedger?.vo_cr!);

        default:
          return "-";
      }
    } else {
      if (!edge) return "-";
      switch (edge.v_type) {
        case ReceiptTypes.STUDENT_RECEIPT:
          return edge.v_std_refund
            ? format(
                edge.acct_voucher_details.find(
                  ({ vo_cr_db }) => vo_cr_db === DebitOrCredit.CREDIT
                )?.vo_cr_total!
              )
            : "-";

        case ReceiptTypes.RCPT:
          return "-";
        case ReceiptTypes.CONTRA:
          return format(edge.acct_voucher_details[0].vo_cr_total);
        case ReceiptTypes.PYMT:
        case ReceiptTypes.JOURNAL:
          return format(edge.acct_voucher_details[0].vo_cr_total);

        default:
          return "-";
      }
    }
  };

  const FetchVoucherType = (type: string) => {
    switch (type) {
      case VoucherPageType.CONTRA_VOUCHER:
        return "Contra";
      case VoucherPageType.PAYMENT_VOUCHER:
        return "Payment";
      case VoucherPageType.RECEIPT_VOUCHER:
        return "Receipt";
      case VoucherPageType.STUDENT:
        return "Student";
      case VoucherPageType.JOURNAL:
        return "Journal";
      default:
        return "-";
    }
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop + 1;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !VoucherDetails.loading) {
        VoucherDetails.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctVouchers.edges;
            const pageInfo = fetchMoreResult.GetAcctVouchers.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctVouchers
              ? prevResult.GetAcctVouchers.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                )
              : [];

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctVouchers: {
                edges: [...vouchers, ...newEdges],
                pageInfo,
                totalCount: VoucherDetails.data?.GetAcctVouchers.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  const transactionData = VoucherDetails.data?.GetAcctVouchers?.edges?.map(
    (edge, index) => ({
      SlNo: index + 1,
      Date: toStandardDate(edge.node.v_date),
      VoucherNo: edge.node.v_no,
      Particulars: FetchParticularDetailsForExcel(edge.node)
        ?.map((det) => {
          if (typeof det === "string") {
            return det;
          } else
            return `${(det as particularsType).description}  (${
              (det as particularsType).amount
            }${(det as particularsType).type})`;
        })
        .join(", "),
      VTypes: FetchVoucherType(edge.node.v_type),
      Debit: FetchDebitDetails(edge.node),
      Credit: FetchCreditDetails(edge.node),
      Narration:
        edge.node.v_no +
        " " +
        edge.node.v_transcation_type +
        " " +
        edge.node.v_transcation_narration,
    })
  );

  useEffect(() => {
    if (
      state.ledger &&
      state.ActiveFinYr &&
      (belongsAccountLedgerBooks(type) ||
        type === OtherAccountLedgerBooks.GROUP_LEDGER)
    ) {
      GetMonthlyLedgersData({
        variables: {
          acct_ldgr_id: state.ledger.value,
          token,
          inst_id: InstId!,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        },
      }).then(({ data }) => {
        if (data) {
          setAcctLedgerMonthlyData(data.GetAcctLdgrMonthlyDataByInstId);
        }
      });
    }
  }, [
    state.ActiveFinYr,
    GetMonthlyLedgersData,
    InstId,
    state.ledger,
    token,
    type,
  ]);
  useEffect(() => {
    if (type === OtherAccountLedgerBooks.REGISTER) {
      GetMonthlyRegistersData({
        variables: {
          token,
          inst_id: Number(InstId)!,
          fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          register_type: state.registerType,
        },
      }).then(({ data }) => {
        if (data) {
          setRegisterItems(data.GetAcctRegisterMonthlyDataByInstId);
        }
      });
    }
  }, [
    state.ActiveFinYr,
    GetMonthlyRegistersData,
    setRegisterItems,
    InstId,
    token,
    type,
    state.registerType,
  ]);
  useEffect(() => {
    if (dates && dates.firstDay && dates.lastDay) {
      setStartDate(toInputStandardDate(dates?.firstDay!));
      setEndDate(toInputStandardDate(dates?.lastDay!));
    }
  }, [dates]);
  useEffect(() => {
    switch (type) {
      case BankOrCash.CASH:
      case BankOrCash.BANK:
      case BankOrCash.STD:
      case BankOrCash.OTHERS:
        setQueryType(VoucherQueryTypes.BY_DATES);
        break;
      case OtherAccountLedgerBooks.REGISTER:
        switch (state.registerType) {
          case ReceiptTypes.PYMT:
            setQueryType(VoucherQueryTypes.PAYMENT_VOUCHERS);
            break;
          case ReceiptTypes.RCPT:
            setQueryType(VoucherQueryTypes.RECEIPTS_VOUCHERS);
            break;
          case ReceiptTypes.CONTRA:
            setQueryType(VoucherQueryTypes.CONTRA_VOUCHERS);
            break;
          case ReceiptTypes.JOURNAL:
            setQueryType(VoucherQueryTypes.JOURNAL_VOUCHERS);
            break;
          case ReceiptTypes.STUDENT_RECEIPT:
            setQueryType(VoucherQueryTypes.STD_REGISTER);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }, [type, state.registerType]);

  useEffect(() => {
    if (
      VoucherDetails.data &&
      !VoucherDetails.loading &&
      token &&
      excelFlag === false
    ) {
      setHasNextPage(true);
      const newData = VoucherDetails.data.GetAcctVouchers.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newVoucher) => {
          const filteredStudent = vouchers.find(
            (voucher) => voucher.node.id === newVoucher.node.id
          );
          if (filteredStudent) {
            return {
              ...newVoucher,
              node: {
                ...newVoucher.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newVoucher;
        });
        setVouchers(updatedNewData);
      } else {
        setVouchers(newData);
      }
      setEndCursor(VoucherDetails.data.GetAcctVouchers.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [VoucherDetails.data, VoucherDetails.loading, token, excelFlag]);

  useEffect(() => {
    if (filteredStudentsReceipts?.length) {
      GetStudentDetails({
        variables: {
          token,
          ids: filteredStudentsReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setStudentDetails(data?.nodes!);
      });
    } // eslint-disable-next-line
  }, [
    GetStudentDetails,
    filteredStudentsReceipts?.length,
    token,
    state.ledger?.value,
  ]);
  useEffect(() => {
    if (filteredPassedStudentReceipts?.length) {
      GetPassedStudentDetails({
        variables: {
          token,
          ids: filteredPassedStudentReceipts?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setPassedStudentDetails(data.nodes);
      });
    } // eslint-disable-next-line
  }, [GetPassedStudentDetails, filteredPassedStudentReceipts?.length, token]);
  useEffect(() => {
    if (filteredEnquiryStudentReceipts.length) {
      GetEnqStudentDetails({
        variables: {
          token,
          ids: filteredEnquiryStudentReceipts.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        if (data && data.nodes) setEnquiryStudentDetails(data.nodes);
      });
    } // eslint-disable-next-line
  }, [
    GetEnqStudentDetails,
    filteredEnquiryStudentReceipts.length,
    VoucherDetails.data,
    token,
  ]);
  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !VoucherDetails.loading)
      downloadExcel();
    // eslint-disable-next-line
  }, [excelFlag, rowsPerPage, VoucherDetails.loading]);
  const [printModal, setPrintModal] = useState(false);

  const TableFooterBottom = ({ acctLdgrmonthlyItem }: IFooter) => {
    const { state } = useContext(AppContext);

    const { format } = formatter;
    const {
      openingBalance,
      debitTotal,
      creditTotal,
      closingBalance,
      ObDebitorCredit,
      acctLedgerResultDebitorCredit,
    } = getMonthlySummary(acctLdgrmonthlyItem, state.date.toString());
    return (
      <>
        <TableRow>
          <TableCell colSpan={5} className="total">
            Opening Balance :
          </TableCell>
          <TableCell
            id="td-right"
            className="totalcountcell bank-book__table-section--transaction-details--table--amount"
          >
            {ObDebitorCredit === DebitOrCredit.DEBIT
              ? format(openingBalance)
              : ""}
          </TableCell>

          <TableCell
            id="td-right"
            className="totalcountcell bank-book__table-section--transaction-details--table--amount"
          >
            {ObDebitorCredit === DebitOrCredit.CREDIT
              ? format(openingBalance)
              : ""}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={5} className="total">
            Total:
          </TableCell>
          <TableCell
            id="td-right"
            className="totalcount bank-book__table-section--transaction-details--table--amount"
          >
            {format(debitTotal)}
          </TableCell>
          <TableCell
            id="td-right"
            className="totalcount bank-book__table-section--transaction-details--table--amount"
          >
            {format(creditTotal)}
          </TableCell>
        </TableRow>
        <TableRow className="ledger-print">
          <TableCell colSpan={5} className="total">
            Closing Balance :
          </TableCell>
          <TableCell id="td-right" className="balance-count">
            {acctLedgerResultDebitorCredit === DebitOrCredit.DEBIT
              ? format(closingBalance)
              : ""}
          </TableCell>
          <TableCell id="td-right" className="balance-count">
            {acctLedgerResultDebitorCredit === DebitOrCredit.CREDIT
              ? format(closingBalance)
              : ""}
          </TableCell>
        </TableRow>
      </>
    );
  };
  const handleViewReceiptDetails = (node: voucherMasterDetails) => {
    if (node.v_type === ReceiptTypes.STUDENT_RECEIPT) {
      if (node.v_std_enquiry) {
        dispatch({
          type: payloadTypes.SET_STUDENT_ID,
          payload: { studentId: 0 },
        });
        dispatch({
          type: payloadTypes.SET_STD_ENQUIRY_ID,
          payload: {
            studentEnquiryId: node.enquiry_student_id,
          },
        });
      } else if (node.v_std_passout_receipt) {
        dispatch({
          type: payloadTypes.SET_STUDENT_ID,
          payload: { studentId: 0 },
        });
        dispatch({
          type: payloadTypes.SET_STD_ENQUIRY_ID,
          payload: {
            studentEnquiryId: 0,
          },
        });
        dispatch({
          type: payloadTypes.SET_ALUMINI_STUDENT_ID,
          payload: {
            aluminiStudentId: node.passout_student_id,
          },
        });
      } else if (node.student_id) {
        dispatch({
          type: payloadTypes.SET_STUDENT_ID,
          payload: { studentId: node.student_id },
        });
        dispatch({
          type: payloadTypes.SET_STD_ENQUIRY_ID,
          payload: {
            studentEnquiryId: 0,
          },
        });
        dispatch({
          type: payloadTypes.SET_ALUMINI_STUDENT_ID,
          payload: {
            aluminiStudentId: node.passout_student_id,
          },
        });
      }
      setStudentDemandModal(!studentDemandModal);
    }
    if (node.v_type !== ReceiptTypes.STUDENT_RECEIPT) {
      setPaymentVoucherModal(!paymentVoucherModal);
    }
    if (node.v_type !== EMPTY_STRING)
      dispatch({
        type: payloadTypes.SET_RECEPIT_ID,
        payload: {
          receiptId: node.id,
        },
      });
  };
  return (
    <>
      {pageType === PageFor.PRINT ? null : (
        <div className="bank-book__table-section--transaction-details--table--title">
          <Title variant="subtitle1">
            Ledger Transaction Details :-
            {/* {toStandardCase(
            acctLedgers.data?.GetAcctLdgrs.edges.find(
              ({ node: { id } }) => id === state.accountLedgerId
            )?.node.ldgr_desc ?? EMPTY_STRING
          )} */}
            {state.ledger ? state.ledger.label : EMPTY_STRING}
          </Title>

          <div>
            {/* {pageType === PageFor.GENERAL && (
            <img
              src={Enlarge}
              alt="/"
              onClick={() => setDisplayGraph(!displaygraph)}
            />
          )} */}
            {pageType === PageFor.GENERAL && (
              <img
                src={Enlarge}
                alt="/"
                onClick={() => setCompleteDetailsModal(!CompleteDetailsModal)}
              />
            )}

            {CompleteDetailsModal && (
              <>
                <TextField
                  className="monthly-wise-ledger__textfield--date"
                  label="Receipt Number"
                  InputLabelProps={{ shrink: true }}
                  placeholder=" E.G  001"
                  onChange={(e) => setV_no(e.target.value)}
                />
                <TextField
                  className="monthly-wise-ledger__textfield--date"
                  label="From"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputProps: {
                      min: state.ActiveFinYr
                        ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                        : EMPTY_STRING,
                      max: toInputStandardDate(TODAY_DATE),
                    },
                  }}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <TextField
                  type="date"
                  id="outlined-uncontrolled"
                  label="To"
                  className="monthly-wise-ledger__textfield--date"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputProps: {
                      min: state.ActiveFinYr
                        ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                        : EMPTY_STRING,
                      max: toInputStandardDate(TODAY_DATE),
                    },
                  }}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </>
            )}
          </div>
        </div>
      )}
      {pageType === PageFor.PRINT ? (
        <TableContainer className="ledger-print--table" onScroll={handleScroll}>
          <Table stickyHeader>
            <TableHead>
              <TableRow className="ledger-print--table--header">
                <TableCell colSpan={7} align="left">
                  Ledger: {state.ledger ? state.ledger.label : EMPTY_STRING}
                </TableCell>
              </TableRow>
              <TableRow className="ledger-print--table--subheader">
                <TableCell colSpan={7} align="left">
                  For the Period from <b>{toStandardDate(startDate)}</b> to{" "}
                  <b>{toStandardDate(endDate)}</b>{" "}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  className="ledger-print--table--th-date"
                  align="center"
                >
                  Date
                </TableCell>
                <TableCell
                  className="ledger-print--table--th-name"
                  align="center"
                >
                  Particulars
                </TableCell>
                <TableCell
                  className="ledger-print--table--th-type"
                  align="center"
                >
                  VchType
                </TableCell>
                <TableCell
                  className="ledger-print--table--th-vno"
                  align="center"
                >
                  VchNo.
                </TableCell>
                <TableCell
                  className="ledger-print--table--th-amount"
                  align="center"
                >
                  Debit
                </TableCell>
                <TableCell
                  className="ledger-print--table--th-amount"
                  align="center"
                >
                  Credit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="ledger-print--table--ob-text" colSpan={3}>
                  Opening Balance :
                </TableCell>
                <TableCell
                  id="td-right"
                  className="ledger-print--table--ob-amount"
                >
                  {ObDebitorCredit === DebitOrCredit.DEBIT
                    ? format(openingBalance)
                    : ""}
                </TableCell>
                <TableCell
                  id="td-right"
                  className="ledger-print--table--ob-amount"
                >
                  {ObDebitorCredit === DebitOrCredit.CREDIT
                    ? format(openingBalance)
                    : ""}
                </TableCell>
              </TableRow>

              {vouchers?.map((edge, index) => {
                const filteredLedgers = edge.node.acct_voucher_details.filter(
                  (f) => f.acct_ldgr.id !== state.ledger?.value
                );
                if (
                  filteredLedgers.length > 1 &&
                  (edge.node.v_type === ReceiptTypes.PYMT ||
                    edge.node.v_type === ReceiptTypes.RCPT)
                ) {
                  return filteredLedgers.map((ledger) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          id="td-center"
                          className="ledger-print--table--date"
                        >
                          {toStandardDate(edge.node.v_date)}
                        </TableCell>
                        <TableCell>{ledger.acct_ldgr.ldgr_desc}</TableCell>
                        <TableCell className="ledger-print--table--type">
                          {FetchVoucherType(edge.node.v_type)}
                        </TableCell>
                        <TableCell className="ledger-print--table--number">
                          {edge.node.v_no}
                        </TableCell>

                        <TableCell
                          id="td-right"
                          className="ledger-print--table--amount"
                        >
                          {ledger.vo_db ? format(ledger.vo_db) : ""}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="ledger-print--table--amount"
                        >
                          {ledger.vo_cr ? format(ledger.vo_cr) : ""}
                        </TableCell>
                      </TableRow>
                    );
                  });
                } else
                  return (
                    <TableRow key={index}>
                      <TableCell
                        id="td-center"
                        className="ledger-print--table--date"
                      >
                        {toStandardDate(edge.node.v_date)}
                      </TableCell>
                      <TableCell>{FetchParticularDetails(edge.node)}</TableCell>
                      <TableCell className="ledger-print--table--type">
                        {FetchVoucherType(edge.node.v_type)}
                      </TableCell>
                      <TableCell className="ledger-print--table--number">
                        {edge.node.v_no}
                      </TableCell>

                      <TableCell
                        id="td-right"
                        className="ledger-print--table--amount"
                      >
                        {FetchDebitDetails(edge.node)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="ledger-print--table--amount"
                      >
                        {FetchCreditDetails(edge.node)}
                      </TableCell>
                    </TableRow>
                  );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer
          className="bank-book__table-section--transaction-details--table"
          onScroll={handleScroll}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Accounts_Table.Reports.AccountLedgerBooks.LedgerTransactionDetails.Table_Headers.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell
                        key={index}
                        className={th.className}
                        align="center"
                      >
                        {th.labelName}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {vouchers?.map((edge, index) => {
                return (
                  <TableRow
                    key={index}
                    onClick={() => handleViewReceiptDetails(edge.node)}
                  >
                    <TableCell
                      id="td-center"
                      className="bank-book__table-section--transaction-details--table--slno"
                    >
                      {index + 1}
                    </TableCell>

                    <TableCell
                      id="td-center"
                      className="bank-book__table-section--transaction-details--table--year"
                    >
                      {toStandardDate(edge.node.v_date)}
                    </TableCell>
                    <TableCell className="bank-book__table-section--transaction-details--table--year">
                      {edge.node.v_no}
                    </TableCell>
                    <TableCell className="bank-book__table-section--transaction-details--table--name">
                      {FetchParticularDetails(edge.node)}
                    </TableCell>
                    <TableCell className="bank-book__table-section--transaction-details--table--type">
                      {FetchVoucherType(edge.node.v_type)}
                    </TableCell>

                    <TableCell
                      id="td-right"
                      className="bank-book__table-section--transaction-details--table--amount"
                    >
                      {FetchDebitDetails(edge.node)}
                    </TableCell>
                    <TableCell
                      id="td-right"
                      className="bank-book__table-section--transaction-details--table--amount"
                    >
                      {FetchCreditDetails(edge.node)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            {CompleteDetailsModal && (
              <TableFooter>
                <TableFooterBottom
                  acctLdgrmonthlyItem={acctLedgerMonthData}
                  registerMonthlyItem={registerItems}
                />
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      )}

      {pageType === PageFor.PRINT && (
        <>
          <div className="ledger-print--total">
            <b className="ledger-print--closing-balance--text"></b>{" "}
            <b className="ledger-print--closing-balance--amount">
              {format(
                debitTotal +
                  (ObDebitorCredit! === DebitOrCredit.DEBIT
                    ? openingBalance
                    : 0)
              )}
            </b>
            <b className="ledger-print--closing-balance--amount">
              {format(
                creditTotal +
                  (ObDebitorCredit! === DebitOrCredit.CREDIT
                    ? openingBalance
                    : 0)
              )}
            </b>
          </div>
          <div className="ledger-print--closing-balance">
            <b className="ledger-print--closing-balance--text">
              Closing Balance :
            </b>
            <b className="ledger-print--closing-balance--amount">
              {debitTotal +
                (ObDebitorCredit! === DebitOrCredit.DEBIT
                  ? openingBalance
                  : 0) <
              creditTotal +
                (ObDebitorCredit! === DebitOrCredit.CREDIT ? openingBalance : 0)
                ? format(closingBalance)
                : ""}
            </b>
            <b className="ledger-print--closing-balance--amount">
              {debitTotal +
                (ObDebitorCredit! === DebitOrCredit.DEBIT
                  ? openingBalance
                  : 0) >
              creditTotal +
                (ObDebitorCredit! === DebitOrCredit.CREDIT ? openingBalance : 0)
                ? format(closingBalance)
                : ""}
            </b>
          </div>
          <div className="ledger-print--total">
            <b className="ledger-print--closing-balance--text"></b>
            <b className="ledger-print--closing-balance--amount">
              {debitTotal +
                (ObDebitorCredit! === DebitOrCredit.DEBIT
                  ? openingBalance
                  : 0) <
              creditTotal +
                (ObDebitorCredit! === DebitOrCredit.CREDIT ? openingBalance : 0)
                ? format(
                    debitTotal +
                      (ObDebitorCredit! === DebitOrCredit.DEBIT
                        ? openingBalance
                        : 0) +
                      closingBalance
                  )
                : format(
                    debitTotal +
                      (ObDebitorCredit! === DebitOrCredit.DEBIT
                        ? openingBalance
                        : 0)
                  )}
            </b>
            <b className="ledger-print--closing-balance--amount">
              {debitTotal +
                (ObDebitorCredit! === DebitOrCredit.DEBIT
                  ? openingBalance
                  : 0) >
              creditTotal +
                (ObDebitorCredit! === DebitOrCredit.CREDIT ? openingBalance : 0)
                ? format(
                    creditTotal +
                      (ObDebitorCredit! === DebitOrCredit.CREDIT
                        ? openingBalance
                        : 0) +
                      closingBalance
                  )
                : format(
                    creditTotal +
                      (ObDebitorCredit! === DebitOrCredit.CREDIT
                        ? openingBalance
                        : 0)
                  )}
            </b>
          </div>
        </>
      )}
      <div className="row g-0">
        <div className="col">
          {CompleteDetailsModal && (
            <>
              <Button
                onClick={() => setVoucherTypeModal(!voucherTypeModal)}
                mode="export"
              />
              <Button mode="print" onClick={() => setPrintModal(!printModal)}>
                &nbsp;Preview
              </Button>
              <Button
                mode="cancel"
                onClick={() => setCompleteDetailsModal(!CompleteDetailsModal)}
              />
            </>
          )}
        </div>
        {pageType === PageFor.MODAL && (
          <div className="col-2 bank-book__total">
            <div className="student-total-count">
              Total Vouchers :&nbsp;
              <b>{VoucherDetails.data?.GetAcctVouchers.totalCount!}</b>
            </div>
          </div>
        )}
      </div>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentDemandModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentDemandList setModalFlag={setStudentDemandModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentDemandModal(!studentDemandModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Print type={type} setModalFlag={setPrintModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setPrintModal(!printModal)}
            />
          </div>
        </div>
      </Modal>
      {/*  voucher modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={paymentVoucherModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <VoucherModal setModalFlag={setPaymentVoucherModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setPaymentVoucherModal(!paymentVoucherModal)}
            />
          </div>
        </div>
      </Modal>
      {/*  Export  modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={voucherTypeModal}
        style={EditModalCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="bank-book__export-modal">
              <Title>Choose Columns to Include</Title>
              {filters.map((d, index) => {
                return (
                  <div className="bank-book__export-modal--flex" key={index}>
                    <Label>{index + 1})</Label>
                    <input
                      checked={d.isChecked}
                      type="checkbox"
                      name={d.label}
                      onChange={handleSelectionOfUnmappedClass}
                    />

                    <Label>{d.label}</Label>
                  </div>
                );
              })}
              <Button
                onClick={() => {
                  setRowsPerPage(null);
                  setExcelFlag(true);
                }}
                mode="export"
              />
              <Button
                mode="cancel"
                onClick={() => setVoucherTypeModal(!voucherTypeModal)}
              />
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setVoucherTypeModal(!voucherTypeModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CompleteTransactionModal;
