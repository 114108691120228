import { TextField } from "@mui/material";
import React, { useState } from "react";
import Compare from "../../../../../images/Compare.svg";
import View from "../../../../../images/EyeWhite.svg";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../../styles/ModalStyles";
import Close from "../../../../../images/Close.svg";
import ParticularStudentResults from "../../../Reports/Test/Modal/ParticularStudentResults";
import Input from "../../../../../stories/Input/Input";
const Index = () => {
  const [viewModal, setViewModal] = useState(false);
  return (
    <>
      <div className="combinations-individual__testwise">
        <div className="row g-0">
            <div className="col-2">
                <Input id="search" placeholder="Search..."/>
            </div>
        </div>
        <div className="combinations-individual__testwise--block">
        <div className="combinations-individual__testwise--cards">
          <div className="acd-reports__test--grid">
            <b className="acd-reports__test--grid--test-name">Test Name </b>
            <span className="acd-reports__test--grid--test-type">
              ( test type )
            </span>
            <span className="acd-reports__test--grid--sub-text">
              Subjects Conducted
            </span>
            <b className="acd-reports__test--grid--sub-count">0 </b>
            <div className="acd-reports__test--flex">
              <TextField
                className="acd-reports__test--flex--textfield"
                disabled
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                type="date"
                // defaultValue={toInputStandardDate(
                //   test.test_class_details.test_start_date
                // )}
              />
              <img src={Compare} alt="" />
              <TextField
                className="acd-reports__test--flex--textfield"
                disabled
                label="End Date"
                InputLabelProps={{ shrink: true }}
                type="date"
                // defaultValue={toInputStandardDate(
                //   test.test_class_details.test_end_date
                // )}
              />
            </div>
            <button
              className="acd-reports__test--grid--view-button"
              onClick={() => setViewModal(!viewModal)}
            >
              View Details <img src={View} alt="" />
            </button>
          </div>
        </div>
        </div>
       
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ParticularStudentResults
              setModalFlag={setViewModal}
              testConductId={null}
            />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="/" onClick={() => setViewModal(!viewModal)} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Index;
