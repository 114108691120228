import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import { useLazyQuery } from "@apollo/client";
import {
  GetAcdStdAttSubjMonthlyForTheDayData,
  GetAcdStdAttSubjMonthlyForTheDayVars,
  SessionDetails,
} from "../../../../Academics/types/holidays";
import { GetAcdStdAttSubjMonthlyForTheDay } from "../../../../Academics/queries/holidays/list";
import { AppContext } from "../../../../../context/context";
import useToken from "../../../../../customhooks/useToken";
import { toIsoDate } from "../../../../../utils/UtilFunctions";
import useCheckAllocationType from "../../../../Academics/hooks/useCheckAllocationType";
import { Button } from "../../../../../stories/Button/Button";
import { EMPTY_STRING } from "../../../../../utils/constants";
import RightArrow from "../../../../../images/ArrowRight.svg";
import ViewAllSubjects from "./ViewAllSubjects";
import {
  AttendanceStatus,
  Operation,
  PageFor,
} from "../../../../../utils/Enum.types";
import { Drawer, TextField } from "@mui/material";
import { attendanceOverViewStyles } from "../../../../../styles/DrawerStyles";
import Compare from "../../../../../images/Compare.svg";
import Close from "../../../../../images/Close.svg";
import Modal from "react-modal";
import { ViewDetails } from "../../../../../styles/ModalStyles";

const Sessionwise = () => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { flag } = useCheckAllocationType();
  const drawerClasses = attendanceOverViewStyles();

  const [subjectWiseAttendance, setSubjectWiseAttendance] = useState(false);
  const [sessionData, setSessionData] = useState<SessionDetails[]>([]);
  const [viewSubjects, setViewSubjects] = useState(false);
  const [subjectData, setSubjectData] = useState({
    subj_code: "",
    subj_desc: "",
  });
  const [customDate, setCustomDate] = useState(new Date());
  const [GetStdSessionDetails, { data: SessionDetails }] = useLazyQuery<
    GetAcdStdAttSubjMonthlyForTheDayData,
    GetAcdStdAttSubjMonthlyForTheDayVars
  >(GetAcdStdAttSubjMonthlyForTheDay);
  useEffect(() => {
    if (state.studentId && token && state.ActiveAcdYr) {
      GetStdSessionDetails({
        variables: {
          token,
          inst_id: state.InstId,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          date_of_attendance: toIsoDate(customDate.toString()),
          student_id: state.studentId,
          per_std_subj_allocation: flag ? flag : false,
        },
      });
    }
  }, [
    GetStdSessionDetails,
    token,
    state.studentId,
    state.ActiveAcdYr,
    customDate,
    state.InstId,
    flag,
  ]);
  return (
    <>
      <Title variant="subtitle1">
        <b>
          {customDate.toLocaleDateString("en-IN", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </b>
      </Title>
      <div className="student-dashboard__attendance--full-view--subjects-count">
        <span>Subjects Covered</span>
        <b>
          {SessionDetails &&
            SessionDetails.GetAcdStdAttSubjMonthlyForTheDay.map(
              (res) => res.subj_desc
            ).length}
        </b>
      </div>
      <ul className="student-dashboard__attendance--full-view--subjects-list">
        {SessionDetails &&
          SessionDetails.GetAcdStdAttSubjMonthlyForTheDay.map((data, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  setSubjectWiseAttendance(!subjectWiseAttendance);
                  setSessionData(
                    data.session_details.filter(
                      (data) => data.att_status !== EMPTY_STRING
                    )
                  );
                  setSubjectData({
                    subj_code: data.subj_code,
                    subj_desc: data.subj_desc,
                  });
                }}
              >
                <div className="student-dashboard__attendance--full-view--subjects">
                  <span className="student-dashboard__attendance--full-view--subjects--name">
                    {data.subj_desc}
                  </span>
                  <span className="student-dashboard__attendance--full-view--subjects--code">
                    ({data.subj_code})
                  </span>
                </div>
                <div className="student-dashboard__attendance--full-view--sessions">
                  <div className="student-dashboard__attendance--full-view--sessions--flex">
                    <span>
                      {`${
                        data.session_details.filter(
                          (session) => session.att_status !== EMPTY_STRING
                        ).length
                      } Sessions`}
                    </span>

                    <img src={RightArrow} alt="" />
                  </div>
                  <div className="student-dashboard__attendance--full-view--sessions--status">
                    {data.session_details
                      .filter((data) => data.att_status !== EMPTY_STRING)
                      .map((status, index) => (
                        <span
                          key={index}
                          className={`student-dashboard__attendance--full-view--dot ${
                            status.att_status === AttendanceStatus.P
                              ? "student-dashboard__attendance--full-view--present"
                              : "student-dashboard__attendance--full-view--absent"
                          }`}
                        ></span>
                      ))}
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
      <div className="student-dashboard__attendance--full-view--button">
        <Button mode="view" onClick={() => setViewSubjects(!viewSubjects)}>
          &nbsp;Subject Wise Attendance
        </Button>
      </div>
      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={subjectWiseAttendance}
        onClose={() => setSubjectWiseAttendance(!subjectWiseAttendance)}
      >
        <div className="selected-sub-wise-status">
          <div className="selected-sub-wise-status__title">
            <Title>Attendance Details</Title>
            <img
              src={Close}
              alt=""
              onClick={() => setSubjectWiseAttendance(!subjectWiseAttendance)}
            />
          </div>
          <div className="selected-sub-wise-status__details">
            <div className="selected-sub-wise-status__details--subject">
              <b>{subjectData ? subjectData.subj_desc : null}</b>
              <span>({subjectData ? subjectData.subj_code : null})</span>
            </div>
            <span className="selected-sub-wise-status__details--total-text">
              Total sessions Covered
            </span>
            <div className="selected-sub-wise-status__details--total">
              <b>
                {sessionData
                  ? sessionData.map((res) => res.session_desc).length
                  : 0}
              </b>
            </div>
          </div>
          <ul className="selected-sub-wise-status__sessions">
            {sessionData &&
              sessionData.map((list, index: number) => {
                return (
                  <li key={index}>
                    <b>{list.session_desc}</b>
                    <div className="selected-sub-wise-status__sessions--flex">
                      <div className="selected-sub-wise-status__sessions--filters">
                        <TextField
                          label="Start Time"
                          defaultValue={list.start_time}
                          type="time"
                          InputLabelProps={{ shrink: true }}
                          disabled
                          className="selected-sub-wise-status__sessions--textfield"
                        />
                        <img src={Compare} alt="/" />
                        <TextField
                          label="End Time"
                          defaultValue={list.end_time}
                          type="time"
                          disabled
                          InputLabelProps={{ shrink: true }}
                          className="selected-sub-wise-status__sessions--textfield"
                        />
                      </div>
                      <div className="selected-sub-wise-status__sessions--status">
                        <span>{list.att_status}</span>
                        <span
                          className={`student-dashboard__attendance--full-view--dot ${
                            list.att_status === AttendanceStatus.P
                              ? "student-dashboard__attendance--full-view--present"
                              : "student-dashboard__attendance--full-view--absent"
                          }`}
                        ></span>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
          <Button
            mode="cancel"
            onClick={() => setSubjectWiseAttendance(!subjectWiseAttendance)}
          />
        </div>
      </Drawer>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewSubjects}
        ariaHideApp={false}
        style={ViewDetails}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ViewAllSubjects
              setModalFlag={setViewSubjects}
              pageType={PageFor.GENERAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt=""
              onClick={() => setViewSubjects(!viewSubjects)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Sessionwise;
