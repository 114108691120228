import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../../../../../customhooks/useToken";

import DeleteIcon from "../../../../../images/Delete.svg";
import DeleteModal from "../../../../../pages/DeleteModal";
import LoadingModal from "../../../../../pages/LoadingModal";
import MessageModal from "../../../../../pages/MessageModal";
import { StudentDetailsByMasterVoucherId } from "../../../../../queries/common";
import Input from "../../../../../stories/Input/Input";
import { Title } from "../../../../../stories/Title/Title";
import { nodevars, VouchernodeData } from "../../../../../Types/Accounting";
import { TableHeaderProps } from "../../../../../Types/Tables";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../../utils/constants";
import { Direction, Operation, SortBy } from "../../../../../utils/Enum.types";
import { msgType } from "../../../../../utils/Form.types";
import {
  DateRange,
  formatter,
  getModifiedScrollHeight,
  toInputStandardDate,
} from "../../../../../utils/UtilFunctions";
import { VoucherQueryTypes } from "../../../common/Enum.types";
import useAcctVoucherDetailsByDates, {
  GetAcctVouchersEdges,
  voucherMasterDetails,
} from "../../../hooks/useAcctVoucherDetailsByDates";

import { DeleteAcctVoucherMasterAndDetails } from "../../../queries/receipts/mutation/Index";
import { GetAcctVouchers } from "../../../queries/Vouchers/query";
import useAcctTableJson from "../../../json/useAcctTableJson";
import useLoggedInUserDetails from "../../../hooks/useLoggedInUserDetails";
import { AppContext } from "../../../../../context/context";
interface Props {
  v_type: VoucherQueryTypes;
}
const Delete = ({ v_type }: Props) => {
  const { InstId } = useParams();
  const { token } = useToken();
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const DefaultDate = new Date();
  const { firstDay, lastDay } = DateRange(DefaultDate.toString()) || {};
  const [searchData, setSearchData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [voucherId, setVoucherId] = useState(0);
  const { state } = useContext(AppContext);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);

  const [studentDetails, setStudentDetails] = useState<voucherMasterDetails[]>(
    []
  );
  const [vouchers, setVouchers] = useState<GetAcctVouchersEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const { user_details } = useLoggedInUserDetails();

  const { VoucherDetails } = useAcctVoucherDetailsByDates(
    ROWS_PER_PAGE,
    endDate!,
    startDate!,
    v_type,
    searchData
  );

  const [GetStudentDetails] = useLazyQuery<VouchernodeData, nodevars>(
    StudentDetailsByMasterVoucherId
  );
  const [DeleteVoucher, { loading }] = useMutation(
    DeleteAcctVoucherMasterAndDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const FetchStudentDetails = (node: voucherMasterDetails) => {
    const student = studentDetails.find((id) => id.id === node.id);
    if (student)
      return (
        student.mst_student.first_name +
        " " +
        student.mst_student.middle_name +
        " " +
        student.mst_student.last_name +
        " " +
        `(${node.branch_details.branch_desc} / ${node.class_details.class_desc} / ${student.acct_voucher_details[0].acct_ldgr.ldgr_desc} / ${student.mst_student.std_adm_no})`
      );
    else return EMPTY_STRING;
  };
  const HandleDelete = (id: number) => {
    setDeleteModal(!deleteModal);
    DeleteVoucher({
      variables: {
        token,
        id,
        inst_id: InstId!,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            sortBy: SortBy.V_DATE,
            token,
            v_no: searchData,
            deposit: null,
            vTranscationCashOrBank: null,
            partyName: EMPTY_STRING,
            vTranscationNo: EMPTY_STRING,
            vTranscationNarration: EMPTY_STRING,
            ldgrDesc: EMPTY_STRING,
            amount: null,
            input: {
              inst_id: InstId!,
              voucher_query_type: VoucherQueryTypes.STUDENT_DEMAND_RECEIPTS,
              vo_end_date: dayjs(endDate).format(),
              acct_ldgr_id: 0,
              student_id: 0,
              vo_start_date: dayjs(startDate).format(),
              vo_type: EMPTY_STRING,
              user_details,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Voucher Deleted Sucessfully",
          operation: Operation.CREATE,
        });
        // setDeleteModal(!deleteModal);
        // setModal(!modal);
      }
    });
  };

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop + 1;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !VoucherDetails.loading) {
        VoucherDetails.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctVouchers.edges;
            const pageInfo = fetchMoreResult.GetAcctVouchers.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctVouchers.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctVouchers: {
                edges: [...vouchers, ...newEdges],
                pageInfo,
                totalCount: VoucherDetails.data?.GetAcctVouchers.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  useEffect(() => {
    if (VoucherDetails.data) {
      GetStudentDetails({
        variables: {
          token,
          ids: vouchers?.map((data) => data.node.id)!,
        },
      }).then(({ data }) => {
        setStudentDetails(data?.nodes!);
      });
    } // eslint-disable-next-line
  }, [GetStudentDetails, vouchers?.length, token]);
  useEffect(() => {
    if (VoucherDetails.data && !VoucherDetails.loading && token) {
      const newData = VoucherDetails.data.GetAcctVouchers.edges;

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newVoucher) => {
          const filteredStudent = vouchers.find(
            (voucher) => voucher.node.id === newVoucher.node.id
          );
          if (filteredStudent) {
            return {
              ...newVoucher,
              node: {
                ...newVoucher.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newVoucher;
        });
        setVouchers(updatedNewData);
      } else {
        setVouchers(newData);
      }
      setEndCursor(VoucherDetails.data.GetAcctVouchers.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [VoucherDetails.data, VoucherDetails.loading, token]);
  return (
    <>
      <Title>Delete Demand Receipt</Title>
      <div className="delete-demand-fee-receipt">
        <div className="row g-0 delete-demand-fee-receipt__details">
          <div className="col-3">
            <Input
              onChange={(e) => setSearchData(e.target.value)}
              placeholder="Search Receipt no..."
              id="search"
              autoFocus
            />
          </div>
          <div className="col"></div>
          <div className="col-2">
            <TextField
              type="date"
              label="Start Date."
              value={toInputStandardDate(startDate!)}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setStartDate(e.target.value)}
              className="delete-demand-fee-receipt__details--textfield"
            />
          </div>
          <div className="col-2">
            <TextField
              type="date"
              label="End Date."
              value={toInputStandardDate(endDate!)}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              className="delete-demand-fee-receipt__details--textfield"
            />
          </div>
        </div>
        <div className="delete-demand-fee-receipt__tableblock">
          {!vouchers?.length && !VoucherDetails.loading ? (
            <b className="nodata">Sorry No Results</b>
          ) : (
            <TableContainer
              className="delete-demand-fee-receipt__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Accounts_Table.DeleteDemandFeeReceipt.Table_Headers.map(
                      (th: TableHeaderProps, index: React.Key) => {
                        return (
                          <TableCell key={index} className={th.className}>
                            {th.labelName}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vouchers.map((response, index: number) => (
                    <TableRow key={response.node.id}>
                      <TableCell
                        id="td-center"
                        className="delete-demand-fee-receipt__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="delete-demand-fee-receipt__table--vno">
                        {response.node.v_no}
                      </TableCell>
                      <TableCell>
                        {FetchStudentDetails(response.node)}
                      </TableCell>
                      <TableCell
                        id="td-right"
                        className="delete-demand-fee-receipt__table--amount"
                      >
                        {format(response.node.v_std_amt_total)}
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="delete-demand-fee-receipt__table--actions"
                      >
                        <img
                          src={DeleteIcon}
                          onClick={() => {
                            setVoucherId(response.node.id);
                            setDeleteModal(!deleteModal);
                          }}
                          alt="/"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
      <LoadingModal flag={loading} />

      <DeleteModal
        modalFlag={deleteModal!}
        setModalFlag={setDeleteModal!}
        handleDelete={HandleDelete}
        id={voucherId}
      />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Delete;
