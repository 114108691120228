/**
 * React functional component that handles the individual student demand details.
 * Allows the user to select a student by their admission number, add new fee details, and update the demand details for the selected student.
 *
 * Example Usage:
 * <ForIndividualStudent />
 *
 * Inputs:
 * - Admission number of the student
 * - Fee description and amount
 * - Date of the fee demand
 *
 * Flow:
 * 1. Renders a form where the user can select a student by their admission number.
 * 2. User can enter the fee description and amount, and select a date for the fee demand.
 * 3. When the user clicks on the "Add New Fee" button, the fee details are added to the list of demand details for the selected student.
 * 4. Displays the existing demand details for the selected student.
 * 5. User can update the demand details by modifying the fee amounts.
 * 6. When the user clicks on the "Save" button, the updated demand details are sent to the server for storage.
 *
 * Outputs:
 * - Updated demand details for the selected student are saved on the server.
 */
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { TableHeaderProps } from "../../../../Types/Tables";
import Home from "../../Home/Index";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import {
  DebitOrCredit,
  PageFor,
  StudentListFor,
  StudentAcctReportType,
  Operation,
} from "../../../../utils/Enum.types";
import DataFetch from "../../../../images/Edit.svg";
import Close from "../../../../images/Close.svg";
import Avatar from "../../../../images/Avatar.svg";
import Input from "../../../../stories/Input/Input";
import {
  EMPTY_RESPONSETYPE,
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";
import { msgType, responseType } from "../../../../utils/Form.types";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { AppContext } from "../../../../context/context";
import DownArrow from "../../../../images/DownArrow.svg";
import {
  studentDemandDetails,
  studentRecepit,
} from "../../../../Types/Accounting";
import {
  formatter,
  RefsByTagName,
  toInputStandardDate,
  toIsoDate,
} from "../../../../utils/UtilFunctions";
import useStudentDemandDetails from "../../hooks/useStudentDemandDetails";
import { studentNewDemandItems } from "../../StudentDetails/DemandFeePayable/Index";
import { Keys } from "../../../../utils/Enum.keys";
import { AddOrUpdateOrDeleteStudentDemandDetails } from "../../queries/demands/mutation";
import { useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import LoadingModal from "../../../../pages/LoadingModal";
import { useNavigate, useParams } from "react-router-dom";
import useAcctLedgerData from "../../hooks/useAcctLedgerData";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import { payloadTypes } from "../../../../context/reducer";
import StudentList from "../../common/StudentList";
import useAcctStdAdmissionNumber from "../../hooks/useAcctStdAdmissionNumber";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import {
  AcctLedgerQueryType,
  DemandDetailsQueryType,
  LedgerType,
} from "../../common/QueryTypes";
import MessageModal from "../../../../pages/MessageModal";
import useAcctTableJson from "../../json/useAcctTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import {
  AddOrUpdateOrDeleteStudentDemandDetailsData,
  AddOrUpdateOrDeleteStudentDemandDetailsVars,
} from "../../../../Types/Accounting/mutations";
import useInstDetails from "../../../../customhooks/general/useInstDetails";

const ForIndividualStudent = () => {
  const listClasses = ListAutoCompleteStyles();
  const listTextClasses = ListAutoCompleteTextStyles();
  const { InstId } = useParams();
  const { Accounts_Table } = useAcctTableJson();

  let totalDemandAmount = 0;
  let totalOb = 0;
  const { state, dispatch } = useContext(AppContext);
  const { token } = useToken();
  const { format } = formatter;
  const navigate = useNavigate();
  const { InstDetails } = useInstDetails(1);
  const { studentId } = useParams();
  const [newItems, setNewItems] = useState<studentNewDemandItems[]>([]);
  const [arrayName, setArrayName] = useState<studentDemandDetails[]>([]);
  const [searchData, setSearchData] = useState("");
  const [ledgers, setLedgers] = useState<responseType[]>([]);
  const [date, setDate] = useState(TODAY_DATE);

  const [studentModal, setStudentModal] = useState(false);
  const [admNo, setAdmNo] = useState("");
  const [items, setItems] = useState<studentRecepit[]>([]);
  const [acct_ldgr_id, setAccountLedgerId] = useState(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [fee_demand, setDemandAmount] = useState<number>(0);
  const [imageString, setImageString] = useState("");
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  //refs

  const feedesc = useRef<HTMLSelectElement>(null);
  const feeDescInputRef = RefsByTagName(feedesc);
  const feeDemandAmountRef = useRef<HTMLInputElement>(null);
  const saveButton = useRef<HTMLButtonElement>(null);
  const newFeeButtonRef = useRef<HTMLButtonElement>(null);
  const { user_details } = useLoggedInUserDetails();

  const { studentAddmissionNumber } = useAcctStdAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentAcctReportType.DEMAND_RAISED
  );
  const { studentData, studentFormData } = useStudentDatabyId();

  const { StudentDemandDetails } = useStudentDemandDetails(
    DemandDetailsQueryType.ALL_DEMAND_DETAILS,
    false,
    EMPTY_STRING
  );
  const { acctLedgers } = useAcctLedgerData(
    searchData,
    AcctLedgerQueryType.STD_FEE_ACCT_LDGRS_WITHOUT_FINE,
    ROWS_PER_PAGE
  );
  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();
  const [
    UpdateStudentDemandDetails,
    { loading: UpdateStudentDemandDetailsLoading },
  ] = useMutation<
    AddOrUpdateOrDeleteStudentDemandDetailsData,
    AddOrUpdateOrDeleteStudentDemandDetailsVars
  >(AddOrUpdateOrDeleteStudentDemandDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const filterLedgerData = acctLedgers.responseType?.find(
    ({ value }) => value === acct_ldgr_id.value
  );
  const handleItems = () => {
    const itemAlreadyExists = items.filter(
      (d) => d.acct_ldgr_id === acct_ldgr_id?.value
    );
    if (itemAlreadyExists.length >= 1) {
      alert("duplicate fee Ledger entry");
      setAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
      setDemandAmount(0);

      feeDescInputRef?.select();
      feeDescInputRef?.focus();
      return;
    }
    if (acct_ldgr_id.value) {
      const result = ledgers.map((ledger) => {
        if (ledger.value === acct_ldgr_id.value) {
          const newItem = {
            ...ledger,
            label: ledger.label,
            value: ledger.value,
            isChecked: !ledger.isChecked,
          };
          return newItem;
        }
        return ledger;
      });
      setLedgers(result);
      setNewItems((items) => [
        ...items,
        {
          legederType: LedgerType.DEPOSIT,
          id: Math.random(),
          acct_ldgr_id: acct_ldgr_id.value,
          fee_bal: 0,
          fee_concession: 0,
          fee_demand,
          fee_ob: 0,
          fee_receivable: 0,
          fee_received: 0,
          fee_refunds: 0,
          acct_ldgr_details: {
            id: acct_ldgr_id.value,
            ldgr_desc: filterLedgerData?.label!,
            ldgr_ob: 0,
            ldgr_total_cr: 0,
            ldgr_total_db: 0,
            ldgr_cb: 0,
          },
        },
      ]);
      const res = ledgers.filter(({ isChecked }) => !isChecked).length;

      if (res === 0) {
        saveButton.current?.focus();
      }
      if (res !== 0) {
        feeDescInputRef?.select();
        feeDescInputRef?.focus();
      }
      setAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
      setDemandAmount(0);
    }
    if (!acct_ldgr_id) alert("please select all fields");
  };
  const handleClear = () => {
    setArrayName([]);
    setNewItems([]);
    dispatch({ payload: { studentId: 0 }, type: payloadTypes.SET_STUDENT_ID });
    setImageString(EMPTY_STRING);
  };
  const handleStudentDemandDetailsUpdation = () => {
    if (state.ActiveFinYr === null) {
      alert("FinYr mot found");
      return;
    }
    UpdateStudentDemandDetails({
      variables: {
        token,
        student_id: [state.studentId.toString()],
        fin_yr_id: state.ActiveFinYr?.id,
        input: {
          update_std_demand_details: items
            .filter((ele) => ele.id)
            .map((item) => ({
              id: item.id!,
              fee_ob: item.fee_ob,
              fee_demand: item.fee_demand,
            })),
          add_std_demand_details: newItems
            .filter((ele) => ele.id)
            .map((items) => ({
              fee_demand: items.fee_demand,
              acct_ldgr_id: items.acct_ldgr_id,
              fee_due_date: toIsoDate(date),
            })),
        },
        user_details,
        inst_id: InstId!,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Succesfully Updated Demand for student",
          operation: Operation.UPDATE,
        });
      }
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setItems([]);
      setNewItems([]);
      setArrayName([]);
      handleClear();
      setAdmNo("");
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  // eslint-disable-next-line
  arrayName.map((arr) => {
    totalDemandAmount += arr.fee_demand;
    totalOb += arr.fee_ob;
  });
  useEffect(() => {
    if (StudentDemandDetails.data && !StudentDemandDetails.loading) {
      const result = StudentDemandDetails.data?.GetAcctStdDemandDetails.filter(
        (item) => item !== null
      ).map((item) => ({
        fee_ob: item.fee_ob,
        fee_demand: item.fee_demand,
        fee_concession: item.fee_concession,
        fee_received: item.fee_received,
        fee_refunds: item.fee_refunds,
        fee_bal: item.fee_bal,
        acct_ldgr_id: item.acct_ldgr_id,
        id: item.id,
        cr_db: DebitOrCredit.CREDIT,
        fine_amt: 0,
        legederType: LedgerType.GENERAL,
        rcpt_amt: 0,
        acct_ldgr_details: item.acct_ldgr_details,
        fee_receivable: item.fee_receivable,
        initialBalance: item.fee_bal,
        student_id: item.student_id,
      }));
      setItems(result!);
    }
  }, [
    state.studentId,
    StudentDemandDetails.data,
    StudentDemandDetails.loading,
  ]);

  useEffect(() => {
    if (acctLedgers) {
      setLedgers(acctLedgers.responseType);
    }
  }, [acctLedgers]);
  useEffect(() => {
    if (state.studentId) {
      const result = newItems.length ? [...items, ...newItems] : items;
      setArrayName(result as studentDemandDetails[]);
    }
  }, [newItems, state.studentId, items]);
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== ""
    ) {
      const studentProfiePicUrl = `${
        InstDetails.data?.nodes[0]?.inst_name
      }/students/${
        state.studentId ? state.studentId : studentId
      }/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  const { branchLabel, classLabel ,categoryLabel} = useInstLabels();

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Student Demand Details</Title>
      <div className="feedemand-additionalfee-individual">
        <div className="row g-0 feedemand-additionalfee-individual__details">
          <div className="col">
            <div className="feedemand-additionalfee-individual__details--imagefield">
              <Autocomplete
                classes={listClasses}
                options={studentAddmissionNumber}
                openOnFocus
                value={
                  state.studentId
                    ? studentAddmissionNumber?.find(
                        ({ value }) => value === state.studentId
                      )!
                    : null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: Number((newValue as responseType)?.value),
                      },
                    });
                  } else {
                    handleClear();
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && state.studentId) {
                    feeDescInputRef?.focus();
                  }
                  if (e.key === Keys.BACKSPACE) {
                    handleClear();
                  }
                }}
                popupIcon={<img src={DownArrow} alt="/" />}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Admission Number"
                    autoFocus
                    onChange={(e) => setAdmNo(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: listTextClasses.formControlRoot }}
                  />
                )}
              />

              <img
                className="data-fetch-icon"
                src={DataFetch}
                alt="/"
                onClick={() => setStudentModal(!studentModal)}
              />
            </div>
            <TextField
              label="Reg Number"
              value={studentFormData.reg_number}
              className="feedemand-additionalfee-individual__details--textfield"
              InputLabelProps={{ shrink: true }}
              disabled
            />
            {USE_CATEGORY_KEY && (
              <TextField
                 label={categoryLabel}
                className="feedemand-additionalfee-individual__details--textfield"
                InputLabelProps={{ shrink: true }}
                value={studentFormData.category}
                disabled
              />
            )}
          </div>
          <div className="col">
            <TextField
              label="Name"
              className="feedemand-additionalfee-individual__details--textfield"
              InputLabelProps={{ shrink: true }}
              value={
                state.studentId
                  ? `${studentFormData.std_name} (${
                      studentFormData.std_name && studentFormData.fresher
                        ? "Fresher"
                        : "Existing"
                    })`
                  : EMPTY_STRING
              }
              disabled
            />
            <TextField
              label={branchLabel}
              className="feedemand-additionalfee-individual__details--textfield"
              InputLabelProps={{ shrink: true }}
              disabled
              value={studentFormData.branch}
            />
            <TextField
              label={classLabel}
              className="feedemand-additionalfee-individual__details--textfield"
              InputLabelProps={{ shrink: true }}
              disabled
              value={studentFormData.class}
            />
          </div>
          <div className="col">
            <Autocomplete
              classes={listClasses}
              options={ledgers?.filter(({ isChecked }) => !isChecked) || []}
              freeSolo
              autoHighlight
              openOnFocus
              disabled={!state.studentId}
              ref={feedesc}
              value={acct_ldgr_id}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === Keys.ENTER) {
                  if (acct_ldgr_id.value !== EMPTY_RESPONSETYPE[0].value) {
                    feeDemandAmountRef.current?.focus();
                  }
                  if (
                    acct_ldgr_id.value === EMPTY_RESPONSETYPE[0].value &&
                    arrayName.length >= 1 &&
                    saveButton.current
                  ) {
                    saveButton.current.focus();
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                  setSearchData("");
                }
              }}
              onChange={(e, newValue) => {
                if (newValue) {
                  const data = newValue as responseType;
                  setAccountLedgerId({
                    label: data.label,
                    value: data.value,
                    isChecked: true,
                  });
                } else {
                  setAccountLedgerId(EMPTY_RESPONSETYPE_OBJECT);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Fee Description"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={(e) => setSearchData(e.target.value)}
                  classes={{ root: listTextClasses.formControlRoot }}
                />
              )}
            />

            <div className="feedemand-additionalfee-individual__details--new-fee">
              <Input
                id="receivedamount"
                inputRef={feeDemandAmountRef}
                disabled={!acct_ldgr_id}
                value={fee_demand}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === Keys.ENTER && fee_demand !== 0) {
                    newFeeButtonRef.current?.focus();
                  }
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDemandAmount(Number(e.target.value))
                }
              />
              <TextField
                type="date"
                label="Due Date"
                className="feedemand-additionalfee-group__textfield"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: {
                    min: state.ActiveFinYr
                      ? toInputStandardDate(state.ActiveFinYr.fin_st_date!)
                      : EMPTY_STRING,
                    max: state.ActiveFinYr
                      ? toInputStandardDate(state.ActiveFinYr.fin_end_date!)
                      : EMPTY_STRING,
                  },
                }}
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
              <Button
                onClick={handleItems}
                mode="add-new-fee"
                buttonref={newFeeButtonRef}
                disabled={fee_demand === 0}
                className="feedemand-additionalfee-individual__details--button"
              >
                Add New Fee
              </Button>
            </div>
          </div>
          <div className="col-1 feedemand-additionalfee-individual__details--image h-100">
            {imageString === EMPTY_STRING ? (
              <img src={Avatar} alt="/" />
            ) : (
              <img src={imageString} alt="/" />
            )}
          </div>
        </div>
        <Title variant="subtitle1">Student Demand Details</Title>
        <div className="feedemand-additionalfee-individual__tableblock">
          <TableContainer className="feedemand-additionalfee-individual__tableblock--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.FeeDemand.AdditionalFee.Individual.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {arrayName.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className="feedemand-additionalfee-individual__tableblock--table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell> {item.acct_ldgr_details.ldgr_desc}</TableCell>
                      <TableCell
                        className="feedemand-additionalfee-individual__tableblock--table--amount"
                        id="td-right"
                      >
                        {format(item.fee_ob)}
                      </TableCell>
                      <TableCell
                        className="feedemand-additionalfee-individual__tableblock--table--amount"
                        id="td-right"
                      >
                        {format(item.fee_demand)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Total
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totalOb)}
                  </TableCell>
                  <TableCell id="td-right" className="totalcount">
                    {format(totalDemandAmount)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="button-left">
        <Button
          mode="save"
          buttonref={saveButton}
          onClick={handleStudentDemandDetailsUpdation}
          disabled={!arrayName.length || !state.studentId}
        />

        <Button mode="clear" onClick={handleClear} />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      {/* studentmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.DEMAND_RAISED}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={UpdateStudentDemandDetailsLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default ForIndividualStudent;
