import React, { useEffect, useState } from "react";
import useAcdStudentsData, {
  StudentEdges,
} from "../../../hooks/useAcdStudentsData";
import useInstConfigByEntryId from "../../../hooks/useInstConfigByEntryId";
import { useParams } from "react-router-dom";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../../utils/constants";
import {
  InstitutionConfigurationTypes,
  StudentReportType,
  TableHeaders,
} from "../../../../../utils/Enum.types";
import {
  DataGridPro,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { getModifiedScrollHeight } from "../../../../../utils/UtilFunctions";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../../styles/DataGridTableStyles";
import { Checkbox } from "@mui/material";
import Input from "../../../../../stories/Input/Input";
import useSwConfigData from "../../../../../customhooks/useSwConfigData";

interface Props {
  idsState: {
    setStudentIds: React.Dispatch<React.SetStateAction<Set<number>>>;
    studentIds: Set<number>;
  };
  queryType: StudentReportType;
}
const StudentList = ({ queryType, idsState }: Props) => {
  const { allocationId } = useParams();
  const [searchData, setSearchData] = useState("");
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [selectedStudent, setSelectedStudent] = useState(false);
  const classes = useDataGridStyles();
  const { studentIds, setStudentIds } = idsState;

  const {
    InstConfigDetails: { data: configdata },
  } = useInstConfigByEntryId(allocationId!);

  const {
    AcademicsStudentData: { data, loading, fetchMore },
  } = useAcdStudentsData(
    configdata ? configdata.GetAcdInstConfigNames.acd_dept_id : 0,
    configdata ? configdata.GetAcdInstConfigNames.acd_branch_id : 0,
    configdata ? configdata.GetAcdInstConfigNames.acd_class_id : 0,
    configdata ? configdata.GetAcdInstConfigNames.acd_semester_id : 0,
    configdata ? configdata.GetAcdInstConfigNames.acd_section_id : 0,
    0,
    ROWS_PER_PAGE,
    Number(allocationId),
    searchData,
    queryType,
    0,
    0,
    0
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcdStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name: `${node.first_name} ${node.middle_name} ${node.last_name}`,
            std_father: node.std_father_name,
            std_mobile: node.std_mobile,
            std_status: node.std_status,
            std_id: node.id,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            std_adm_no: node.std_adm_no,
            std_reg_no: node.std_reg_no,
            std_name: `${node.first_name} ${node.middle_name} ${node.last_name}`,
            std_father: node.std_father_name,
            std_mobile: node.std_mobile,
            std_status: node.std_status,
            std_id: node.id,
          }))
        );
      }
      setEndCursor(data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: FETCH_MORE_DATA,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetAcdStudents.edges;
              const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetAcdStudents: {
                  edges: [...students, ...newEdges],
                  pageInfo,
                  totalCount: data ? data.GetAcdStudents.totalCount! : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: TableHeaders.SLNO,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      align: SLNO_TEXT_ALIGN,
      renderCell: (props) => {
        return (
          <>
            <Checkbox
              checked={studentIds.has(props.row.std_id)}
              onChange={(e) => {
                if (e.target.checked) {
                  const tempSet = studentIds;
                  tempSet.add(props.row.std_id);
                  setStudentIds(tempSet);
                  setSelectedStudent(!selectedStudent);
                } else {
                  const tempSet = studentIds;
                  tempSet.delete(props.row.std_id);
                  setStudentIds(tempSet);
                  setSelectedStudent(!selectedStudent);
                }
              }}
            />
          </>
        );
      },
    },
    {
      field: "std_adm_no",
      headerName: TableHeaders.ADMISSION_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "std_reg_no",
      headerName: TableHeaders.REGISTER_NUMBER,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "std_name",
      headerName: TableHeaders.STUDENT_NAME,
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-onClick td-name",
      flex: 0.5,
    },
  ];
  return (
    <>
      <div className="sub-allocation-comb__datablock--std-list">
        <div className="sub-allocation-comb__datablock--std-list--row row g-0">
          <div className="col-3"></div>
          <div className="col"></div>
          <div className="col-3"></div>
        </div>
        <div className="sub-allocation-comb__datablock--search row g-0 ">
          <div className="col-3">
            {" "}
            <Input
              id="search"
              placeholder="Search..."
              onChange={(e) => setSearchData(e.target.value)}
            />
          </div>
          <div className="student-total-count col">
            Total Students : <b>{data?.GetAcdStudents.totalCount}</b>
          </div>
        </div>
        <div
          className={`sub-allocation-comb__datablock--std-list--tableblock ${classes.root}`}
        >
          <DataGridPro
            columns={columns}
            rows={rows}
            rowHeight={TABLE_ROW_HEIGHT}
            // onCellClick={(params: GridCellParams, studentId) => {
            //   handleCellClick(params);
            // }}
            disableRowSelectionOnClick
            slotProps={{
              columnsPanel: { disableHideAllButton: true },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default StudentList;
