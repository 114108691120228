//React Imports
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
//Other imports
import { useLazyQuery, useMutation } from "@apollo/client";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import { Form, Formik } from "formik";
//Queries Imports
import {
  GetLibBookMaster,
  GetLibBookNotAllocatedRack,
} from "../../../queries/Library/BookEntry/list/byInstId";
import { ByNode as GetClassificationByNode } from "../../../queries/Library/Node/index";
//Mutations Imports
import { AddLibBookMasterAndPurchaseDetails } from "../../../queries/Library/BookEntry/mutation/new";
//Style Imports
import { useStyles } from "../../../styles/TooltipStyles";
import { StudentModalStyles } from "../../../styles/ModalStyles";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
//Form Fields
import { Button } from "../../../stories/Button/Button";
import Input from "../../../components/common/Input/Index";
import { Label } from "../../../stories/Label/Label";
import { Title } from "../../../stories/Title/Title";
import { BookEntryTitleProps } from "../../../Types/Titles";
//Images
import Close from "../../../images/Close.svg";
import DownArrow from "../../../images/DownArrow.svg";
import Upload from "../../../images/Upload.svg";
import Add from "../../../images/Add.svg";
//Custom Hooks
import useLibMasterDataOptions, {
  LibMasterData,
} from "../customHooks/useLibMasterDataOptions";
import useToken from "../../../customhooks/useToken";
//Components
import Classification, {
  BookClassificationNodeData,
  BookClassificationNodeVars,
} from "../MasterDataForBookEntry/Classfication/Index";
import Vendor from "../MasterDataForBookEntry/VendorDetails/Index";
import Shelf from "../MasterDataForBookEntry/Shelf/Index";
import Home from "../Home/Index";
//Other imports
import {
  BookEntryFormType,
  msgType,
  optionsType,
  responseType,
} from "../../../utils/Form.types";
import { NewBookEntryValidation } from "../../../utils/validationRules";
import {
  handleMUISelectEvent,
  toInputStandardDate,
} from "../../../utils/UtilFunctions";
import { Keys } from "../../../utils/Enum.keys";
import {
  Direction,
  LibBookMasterQueryType,
  Operation,
  PageFor,
  SortBy,
  YesNo,
} from "../../../utils/Enum.types";
import {
  EMPTY_SHELF_RESPONSE,
  EMPTY_STRING,
  LIBRARY_ROWS_PER_PAGE,
  NOTALLOCATED,
} from "../../../utils/constants";
//Modals
import MessageModal from "../../../pages/MessageModal";
import LoadingModal from "../../../pages/LoadingModal";
import { GetAccessionNumber } from "../../../queries/Library/MasterData/AccessNo/list/byId";
import {
  GetAccessionNumberData,
  GetAccessionNumberVars,
} from "../../../Types/Library/MasterData/AccessionNo";
import {
  GetLibPurchaseMasterByVendorIdData,
  GetLibPurchaseMasterByVendorIdVars,
} from "../../../Types/Library/PurchaseDetails/paginationTypes";
import { GetLibPurchaseMasterByVendorId } from "../../../queries/Library/PurchaseDetails/byId";
import { LibraryLabelNameProps } from "../../../Types/Labels";
import { GetLibBookClassificationsByInstId } from "../../../queries/Library/MasterData/Classification/list/byInstId";
import useShelfOptions, { shelfType } from "../customHooks/useShelfOptions";
import {
  GetLibBookNotAllocationData,
  GetLibBookNotAllocationVars,
} from "../../../Types/Library/BookEntry/paginationTypes";
import useServerDateandTime from "../customHooks/useServerDateandTime";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryformJson from "../json/useLibraryformJson";
import Department from "../MasterDataForBookEntry/Department/Index";
import {
  GetLibBookDeptsData,
  GetLibBookDeptsVars,
} from "../../../Types/Library/MasterData/Media/paginationTypes";
import { GetLibBookDepts } from "../../../queries/Library/MasterData/Media/list/byInstId";

const { Library } = require("../../../json/title.json");

const NewBook = () => {
  //Styles
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const tooltipClasses = useStyles();
  const { LibraryFormLabels } = useLibraryformJson();

  //Params token navigate..
  const { InstId } = useParams();
  const { token } = useToken();
  const { serverDate } = useServerDateandTime();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();
  const [searchClassification, setSearchClassification] = useState("");
  const [searchVendor, setSearchVendor] = useState("");
  const [billNoOptions, setBillNoOptions] = useState<optionsType[]>([]);
  const [searchToShelf, setToSearchShelf] = useState("");
  const [searchToDept, setToSearchDept] = useState("");

  const [shelfSelected, setShelfSelected] =
    useState<shelfType>(EMPTY_SHELF_RESPONSE);
  const [deptSelected, setDeptSelected] = useState<responseType | null>(null);
  //SuccesMessage
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [reference, setReference] = useState("");
  const bookReservedDropDown = ["Yes", "No"];
  //Modal Flags
  const [addClassificationModal, setAddClassificationModal] = useState(false);
  const [addVendorModal, setAddVendorModal] = useState(false);
  const [addShelfModal, setAddShelfModal] = useState(false);
  const [addDeptModal, setAddDeptModal] = useState(false);
  //useRefs
  const ClassificationNoRef = useRef<HTMLInputElement>(null);
  const ClassificationClearRef = ClassificationNoRef?.current?.childNodes[0]
    ?.childNodes[1].childNodes[0] as HTMLButtonElement;
  const DateRef = useRef<HTMLInputElement>(null);
  const BillNoRef = useRef<HTMLInputElement>(null);
  const BillNoInput = BillNoRef?.current?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;
  const NoOfBooksRef = useRef<HTMLInputElement>(null);
  const VendorRef = useRef<HTMLInputElement>(null);
  const VendorInput = VendorRef?.current?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;
  const VendorClearRef = VendorRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLButtonElement;
  const AttachmentRef = useRef<HTMLInputElement>(null);

  //FormData
  const [formData, setFormData] = useState<BookEntryFormType>({
    book_access_no: "",
    book_entry_date: "",
    lib_book_classification_id: 0,
    lib_book_rack_id: 0,
    pur_bill_no: "",
    pur_date: "",
    lib_book_vendor_id: 0,
    pur_rate: 0,
    pur_qty: 0,
    pur_value: 0,
    pur_actual_value: 0,
    media: "",
    book_category: "",
    subject: "",
    language: "",
    attribute: "",
    author: "",
    publisher: "",
    book_pages: "",
    book_edition: "",
    book_published_year: "",
    book_volume: "",
    book_place: "",
  });

  const [files, setFiles] = useState<any[]>([]);

  //Queries
  const [GetClassificationDetails] = useLazyQuery<
    BookClassificationNodeData,
    BookClassificationNodeVars
  >(GetClassificationByNode);
  const [GetAccessionNo] = useLazyQuery<
    GetAccessionNumberData,
    GetAccessionNumberVars
  >(GetAccessionNumber);
  const [GetPurchaseMaster] = useLazyQuery<
    GetLibPurchaseMasterByVendorIdData,
    GetLibPurchaseMasterByVendorIdVars
  >(GetLibPurchaseMasterByVendorId);

  const [GetNotAllocatedData, { data: LibBooksNotAllocation }] = useLazyQuery<
    GetLibBookNotAllocationData,
    GetLibBookNotAllocationVars
  >(GetLibBookNotAllocatedRack, {
    variables: { token, inst_id: InstId! },
  });
  const [GetDepartmentData, { data, loading }] = useLazyQuery<
    GetLibBookDeptsData,
    GetLibBookDeptsVars
  >(GetLibBookDepts, {
    variables: {
      token,
      inst_id: InstId!,
      first: null,
      sortBy: SortBy.DEPT_NAME,
      direction: Direction.ASC,
      deptName: searchToDept,
      after: null,
    },
  });
  const departmentDropdown =
    data &&
    data.GetLibBookDepts.edges
      .filter((data) => data.node.dept_name !== NOTALLOCATED)
      .map((res) => ({
        label: res.node.dept_name,
        value: res.node.id,
      }));

  const { shelves: toShelves } = useShelfOptions(searchToShelf);

  //Mutations
  const [AddNewBookEntry, { loading: creationLoading }] = useMutation(
    AddLibBookMasterAndPurchaseDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const { classifications } = useLibMasterDataOptions(
    LibMasterData.CLASSIFICATION,
    searchClassification
  );
  const { vendors } = useLibMasterDataOptions(
    LibMasterData.VENDORS,
    searchVendor
  );
  const vendorDropdown =
    vendors && vendors.filter((data) => data.label !== NOTALLOCATED);

  //Methods
  const handleChange = (e: any) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newFile = e.target.files[i];
      newFile["id"] = Math.random();
      setFiles((prevState) => [...prevState, newFile]);
    }
  };
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const HandleSubmit = () => {
    AddNewBookEntry({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        input: {
          lib_dept_id: deptSelected ? deptSelected.value : 0,
          book_master: {
            book_access_no: formData.book_access_no,
            reference: reference === YesNo.YES ? true : false,
            book_entry_date: new Date(serverDate).toISOString(),
            lib_book_classification_id: formData.lib_book_classification_id,
            lib_book_rack_id: shelfSelected.id,
          },
          purchase_master: {
            pur_bill_no: formData.pur_bill_no,
            pur_date: new Date(formData.pur_date).toISOString(),
            lib_book_vendor_id: formData.lib_book_vendor_id,
          },
          purchase_details: {
            pur_rate: formData.pur_rate,
            pur_qty: formData.pur_qty,
            pur_value: formData.pur_value,
            pur_actual_value: formData.pur_actual_value,
          },
        },
      },
      refetchQueries: [
        {
          query: GetLibBookMaster,
          variables: {
            token,
            input: {
              book_query_type: LibBookMasterQueryType.BOOK_BY_INST_ID,
              ids: [Number(InstId)],
            },
            first: LIBRARY_ROWS_PER_PAGE,
            sortBy: SortBy.ACCESS_NO,
            direction: Direction.ASC,
            access_no: EMPTY_STRING,
            after: null,
          },
        },
        {
          query: GetLibBookClassificationsByInstId,
          variables: {
            token,
            inst_id: InstId!,
            first: LIBRARY_ROWS_PER_PAGE,
            sortBy: SortBy.CLASSIFICATION_DESC,
            direction: Direction.ASC,
            name: EMPTY_STRING,
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        handleClear();
        setMessage({
          message: "Book added successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const handleClear = () => {
    setFormData({
      ...formData,
      book_access_no: "",
      book_entry_date: "",
      lib_book_classification_id: 0,
      lib_book_rack_id: 0,
      pur_bill_no: "",
      lib_book_vendor_id: 0,
      pur_rate: 0,
      pur_qty: 0,
      pur_value: 0,
      pur_actual_value: 0,
      media: "",
      book_category: "",
      subject: "",
      language: "",
      attribute: "",
      author: "",
      publisher: "",
      book_pages: "",
      book_edition: "",
      book_published_year: "",
      book_volume: "",
      book_place: "",
    });
    setDeptSelected(null);
    VendorClearRef?.click();
    ClassificationClearRef.click();
    (
      BillNoRef?.current?.childNodes[0]?.childNodes[1]
        ?.childNodes[0] as HTMLButtonElement
    )?.click();
    (
      ClassificationNoRef?.current?.childNodes[0]?.childNodes[1]
        ?.childNodes[0] as HTMLButtonElement
    )?.click();
    setSearchClassification("");
    setSearchVendor("");
  };
  useEffect(() => {
    if (serverDate !== EMPTY_STRING) {
      setFormData((prevValues) => {
        return { ...prevValues, pur_date: toInputStandardDate(serverDate) };
      });
    }
  }, [serverDate]);
  useEffect(() => {
    if (token) {
      GetNotAllocatedData();
      GetDepartmentData();
    }
  }, [token, GetNotAllocatedData, GetDepartmentData]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {Library.BookEntry.Titles.map(
          (title: BookEntryTitleProps, index: React.Key) => {
            return <React.Fragment key={index}>{title.Add}</React.Fragment>;
          }
        )}
      </Title>
      <Formik
        initialValues={formData}
        onSubmit={HandleSubmit}
        validationSchema={NewBookEntryValidation}
        validateOnChange
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className="new-book">
              <div className="row g-0 new-book__row">
                <div className="col booktype-left h-100">
                  <div className="new-book__frame">
                    <div className="new-book__image-grid">
                      <Label>Classification Desc.</Label>
                      <Autocomplete
                        classes={classes}
                        options={classifications!}
                        openOnFocus
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.value}>
                              {option.label}
                            </li>
                          );
                        }}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_classification_id: (
                                newValue as responseType
                              )?.value,
                            }));
                          } else {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_classification_id: 0,
                            }));
                          }

                          if ((newValue as responseType)?.value) {
                            GetClassificationDetails({
                              variables: {
                                token,
                                ids: [(newValue as responseType)?.value],
                              },
                            }).then(({ data }) => {
                              if (data) {
                                const classification = data.nodes[0];
                                GetAccessionNo({
                                  variables: {
                                    token,
                                    inst_id: InstId!,
                                    lib_classfication_id: classification.id,
                                  },
                                }).then(({ data }) => {
                                  setFormData({
                                    ...formData,
                                    media: classification.media.media_desc,
                                    book_category:
                                      classification.category.category_desc,
                                    subject:
                                      classification.subject.subject_desc,
                                    publisher:
                                      classification.publisher.publisher_name,
                                    language: classification.language.language,
                                    attribute:
                                      classification.attribute.book_attribute,
                                    author: classification.author.author_name,
                                    book_pages: classification.book_pages,
                                    book_edition: classification.book_edition,
                                    book_published_year:
                                      classification.book_published_year,
                                    book_access_no: data?.GetAccessionNumber!,
                                    book_volume: classification.book_volume,
                                    book_place: classification.book_place,
                                    lib_book_classification_id:
                                      classification.id,
                                  });
                                });
                              }
                            });
                          } else {
                            setFormData({
                              book_access_no: "",
                              book_entry_date: "",
                              lib_book_classification_id: 0,
                              lib_book_rack_id: 0,
                              pur_bill_no: "",
                              pur_date: "",
                              lib_book_vendor_id: 0,
                              pur_rate: 0,
                              pur_qty: 0,
                              pur_value: 0,
                              pur_actual_value: 0,
                              media: "",
                              book_category: "",
                              subject: "",
                              language: "",
                              attribute: "",
                              author: "",
                              publisher: "",
                              book_pages: "",
                              book_edition: "",
                              book_published_year: "",
                              book_volume: "",
                              book_place: "",
                            });
                          }
                        }}
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            VendorInput?.focus();
                          }
                        }}
                        value={
                          classifications?.find(
                            ({ value }) =>
                              value === formData.lib_book_classification_id
                          ) ??
                          searchClassification ??
                          null
                        }
                        freeSolo
                        forcePopupIcon
                        popupIcon={<img src={DownArrow} alt="/" />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => {
                              setSearchClassification(e.target.value);
                            }}
                            required
                            autoFocus
                            ref={ClassificationNoRef!}
                            fullWidth
                            classes={{ root: textClasses.formControlRoot }}
                          />
                        )}
                      />

                      <Tooltip
                        title="Add New Classification No"
                        arrow
                        placement="top"
                        classes={{
                          tooltip: tooltipClasses.customTooltip,
                          arrow: tooltipClasses.customArrow,
                        }}
                        className="tooltip-hide"
                      >
                        <img
                          src={Add}
                          alt="/"
                          onClick={() =>
                            setAddClassificationModal(!addClassificationModal)
                          }
                          className="data-fetch-icon"
                        />
                      </Tooltip>
                    </div>
                    {/* <Input
                      LabelName="ISBN"
                      name="lib_book_isbn_id"
                      inputRef={ISBNRef!}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        meta.handleChange(e);
                        handleValueChange(e);
                      }}
                      disabled={type === Operation.CREATE && title}
                      maxLength={10}
                      values={formData.lib_book_isbn_id}
                      required
                    /> */}
                    <div className="new-book__span-2">
                      <div className="new-book__span-2--grid">
                        {LibraryFormLabels?.NewBookEntry?.map(
                          (field: LibraryLabelNameProps, index: React.Key) => {
                            return (
                              <Input
                                key={index}
                                LabelName={field.LabelName}
                                disabled
                                name={field.inputName}
                                values={formData[field.inputName]}
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div className="new-book__place">
                      <Input
                        LabelName="Place"
                        disabled
                        name="book_place"
                        values={formData.book_place}
                      />
                    </div>

                    <div className="new-book__label-grid">
                      <Label>Attachment</Label>
                      <div className="assignment-creation__fileattach">
                        <label htmlFor="assignment-creation__inputTag">
                          <img src={Upload} alt="/" className="upload-icon" />
                          <br />
                          Attach Document
                          <input
                            id="assignment-creation__inputTag"
                            type="file"
                            ref={AttachmentRef!}
                            className="assignment-creation__fileattach--input"
                            onChange={handleChange}
                            onKeyDown={(e: React.KeyboardEvent) => {
                              if (e.key === Keys.ENTER) {
                                VendorInput?.focus();
                                e.preventDefault();
                              }
                            }}
                          />
                          <br />
                          <span id="imageName">{files[0]?.name}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col booktype-right h-100">
                  <div className="new-book__frame--total">
                    <div className="new-book__image-grid">
                      <Label>Vendor Name</Label>
                      <Autocomplete
                        classes={classes}
                        options={vendorDropdown!}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_vendor_id: (newValue as responseType)
                                ?.value!,
                            }));
                          } else {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_vendor_id: 0,
                            }));
                          }

                          if ((newValue as responseType)?.value) {
                            GetPurchaseMaster({
                              variables: {
                                name: "",
                                first: null,
                                after: null,
                                vendor_id: (newValue as responseType)?.value!,
                                sortBy: SortBy.PUR_BILL_NO,
                                direction: Direction.ASC,
                                token,
                              },
                            }).then(({ data }) => {
                              if (data) {
                                setBillNoOptions(
                                  data?.GetLibPurchaseMasterByVendorId?.edges?.map(
                                    ({ node }) => ({
                                      label: node.pur_bill_no,
                                      value: node.pur_date,
                                    })
                                  )
                                );
                              }
                            });
                          } else {
                            setBillNoOptions([]);
                          }
                        }}
                        openOnFocus
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            BillNoInput?.focus();
                            e.preventDefault();
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              lib_book_vendor_id: 0,
                            }));
                          }
                        }}
                        value={
                          vendors?.find(
                            ({ value }) => value === formData.lib_book_vendor_id
                          ) ?? null
                        }
                        freeSolo
                        forcePopupIcon
                        popupIcon={<img src={DownArrow} alt="/" />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            onChange={(e) => {
                              setSearchVendor(e.target.value);
                            }}
                            ref={VendorRef!}
                            fullWidth
                            classes={{ root: textClasses.formControlRoot }}
                          />
                        )}
                      />
                      <Tooltip
                        title="Add New Vendor"
                        arrow
                        placement="top"
                        classes={{
                          tooltip: tooltipClasses.customTooltip,
                          arrow: tooltipClasses.customArrow,
                        }}
                        className="tooltip-hide"
                      >
                        <img
                          src={Add}
                          alt="/"
                          onClick={() => setAddVendorModal(!addVendorModal)}
                          className="data-fetch-icon"
                        />
                      </Tooltip>
                    </div>
                    <div className="new-book__label-grid">
                      <Label>Bill Number</Label>
                      <Autocomplete
                        classes={classes}
                        options={
                          billNoOptions! ?? [
                            {
                              label: "",
                              value: "",
                            },
                          ]
                        }
                        onChange={(e, newValue) => {
                          if ((newValue as optionsType)?.label)
                            setFormData((prevValues) => ({
                              ...prevValues,
                              pur_bill_no: (newValue as optionsType)?.label,
                              pur_date: toInputStandardDate(
                                (newValue as optionsType)?.value
                              ),
                            }));
                        }}
                        openOnFocus
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            if (formData.pur_bill_no) {
                              DateRef?.current?.focus();
                            }
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setFormData((prevValues) => ({
                              ...prevValues,
                              pur_bill_no: "",
                              pur_date: "",
                            }));
                          }
                        }}
                        value={
                          billNoOptions.find(
                            (data) => data.label === formData.pur_bill_no
                          ) ?? null
                        }
                        freeSolo
                        forcePopupIcon
                        popupIcon={<img src={DownArrow} alt="/" />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            onChange={(e) => {
                              meta.handleChange(e);
                              setFormData((prevValues) => ({
                                ...prevValues,
                                pur_bill_no: e.target.value!,
                              }));
                            }}
                            ref={BillNoRef}
                            fullWidth
                            classes={{ root: textClasses.formControlRoot }}
                          />
                        )}
                      />
                    </div>

                    <div className="row g-0 new-book__vendor-details">
                      <div className="col">
                        <Input
                          type="date"
                          LabelName="Date of Purchase"
                          name="pur_date"
                          inputRef={DateRef!}
                          onKeyDown={(e: React.KeyboardEvent) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              NoOfBooksRef?.current?.focus();
                            }
                          }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            meta.handleChange(e);
                            handleValueChange(e);
                          }}
                          values={formData.pur_date}
                        />
                      </div>
                      <div className="col">
                        <Input
                          LabelName="No of Books"
                          name="pur_qty"
                          type="number"
                          inputRef={NoOfBooksRef!}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            meta.handleChange(e);
                            handleValueChange(e);
                          }}
                          min={1}
                          required
                          values={
                            formData.pur_qty === 0 ? "" : formData.pur_qty
                          }
                          max={1000}
                        />
                      </div>
                    </div>
                    <div className="row g-0 new-book__vendor-details">
                      <div className="col">
                        <Input
                          LabelName="Purchase Cost Per Book"
                          name="pur_value"
                          type="number"
                          min={1}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            meta.handleChange(e);
                            handleValueChange(e);
                          }}
                          values={
                            formData.pur_value === 0 ? "" : formData.pur_value
                          }
                          required
                        />
                      </div>
                      <div className="col">
                        <Input
                          LabelName="Actual Cost"
                          name="pur_actual_value"
                          type="number"
                          values={
                            formData.pur_actual_value === 0
                              ? ""
                              : formData.pur_actual_value
                          }
                          min={formData.pur_value}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            meta.handleChange(e);
                            handleValueChange(e);
                          }}
                          required
                        />
                      </div>
                      <div className="new-book__frame--total">
                        <div className="new-book__image-grid">
                          <Label>Is Reference</Label>
                          <Autocomplete
                            classes={classes}
                            options={bookReservedDropDown!}
                            value={reference!}
                            openOnFocus
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setReference(newValue);
                              } else {
                                setReference(EMPTY_STRING);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                handleMUISelectEvent(e);
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setReference(EMPTY_STRING);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                classes={{
                                  root: textClasses.formControlRoot,
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="new-book__image-grid">
                          <Label>Move to Shelf</Label>
                          <Autocomplete
                            classes={classes}
                            options={
                              toShelves?.filter(
                                (data) =>
                                  data.id !==
                                  LibBooksNotAllocation
                                    ?.GetLibBookNotAllocatedRack.id
                              )! ?? EMPTY_SHELF_RESPONSE
                            }
                            getOptionLabel={(option) =>
                              (
                                option.lib_rack_desc +
                                " " +
                                option.lib_rack_no
                              ).toString()
                            }
                            value={shelfSelected!}
                            openOnFocus
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setShelfSelected(newValue);
                              } else {
                                setShelfSelected(EMPTY_SHELF_RESPONSE);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                handleMUISelectEvent(e);
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setShelfSelected(EMPTY_SHELF_RESPONSE);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                onChange={(e) =>
                                  setToSearchShelf(e.target.value)
                                }
                                classes={{
                                  root: textClasses.formControlRoot,
                                }}
                              />
                            )}
                          />
                          <Tooltip
                            title="Add New Shelf"
                            arrow
                            placement="top"
                            classes={{
                              tooltip: tooltipClasses.customTooltip,
                              arrow: tooltipClasses.customArrow,
                            }}
                            className="tooltip-hide"
                          >
                            <img
                              src={Add}
                              alt="/"
                              onClick={() => setAddShelfModal(!addShelfModal)}
                              className="data-fetch-icon"
                            />
                          </Tooltip>
                        </div>
                      </div>
                      <div className="new-book__frame--total">
                        <div className="new-book__image-grid">
                          <Label>Move to Department</Label>
                          <Autocomplete
                            classes={classes}
                            options={departmentDropdown!}
                            value={deptSelected!}
                            openOnFocus
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setDeptSelected(newValue);
                              } else {
                                setDeptSelected(null);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                onChange={(e) =>
                                  setToSearchDept(e.target.value)
                                }
                                classes={{
                                  root: textClasses.formControlRoot,
                                }}
                              />
                            )}
                          />
                          <Tooltip
                            title="Add New Department"
                            arrow
                            placement="top"
                            classes={{
                              tooltip: tooltipClasses.customTooltip,
                              arrow: tooltipClasses.customArrow,
                            }}
                            className="tooltip-hide"
                          >
                            <img
                              src={Add}
                              alt="/"
                              onClick={() => setAddDeptModal(!addDeptModal)}
                              className="data-fetch-icon"
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col"></div>
                    <div className="col-4 ">
                      <Input
                        LabelName="Total"
                        className="new-book__total"
                        name="pur_total_cost"
                        type="number"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          meta.handleChange(e);
                          handleValueChange(e);
                        }}
                        disabled
                        values={
                          Number(formData.pur_qty * formData.pur_value) ?? 0
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-left">
                <Button mode="save" type="submit" />
                <Button mode="clear" onClick={handleClear} type="button" />
                <Button
                  mode="back"
                  onClick={() => navigate(-1)}
                  type="button"
                />
              </div>
            </Form>
          );
        }}
      </Formik>

      {/* Add Book Classification modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addClassificationModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Classification
              type={PageFor.MODAL}
              modalFlag={addClassificationModal}
              setModalFlag={setAddClassificationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddClassificationModal(!addClassificationModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>

      {/* Book vendor modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addVendorModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Vendor
              type={PageFor.MODAL}
              modalFlag={addVendorModal}
              setModalFlag={setAddVendorModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddVendorModal(!addVendorModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>

      {/* add shelf modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addShelfModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Shelf
              type={PageFor.MODAL}
              modalFlag={addShelfModal}
              setModalFlag={setAddShelfModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddShelfModal(!addShelfModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      {/* add Department modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={addDeptModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Department
              type={PageFor.MODAL}
              modalFlag={addDeptModal}
              setModalFlag={setAddDeptModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setAddDeptModal(!addDeptModal)}
              className="modal-close-icon"
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />

      <LoadingModal flag={creationLoading || loading} />
    </>
  );
};

export default NewBook;
