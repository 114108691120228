import { Autocomplete, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Label } from "../../stories/Label/Label";
import { Title } from "../../stories/Title/Title";
import { LabelNameProps } from "../../Types/Labels";
import { TitleProps } from "../../Types/Titles";
import {
  Direction,
  InstitutionConfigurationTypes,
  InstitutionDetails,
  Operation,
  PredefinedDataTypes,
  SortBy,
  StdEnqQueryType,
} from "../../utils/Enum.types";
import DownArrow from "../../images/DownArrow.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InputHoc from "../../components/common/Input/Index";
import {
  msgType,
  optionsType,
  responseType,
  StudentEnquiryFormType,
} from "../../utils/Form.types";
import { StudentEnquiryValidation } from "../../utils/validationRules";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AddStudentEnquiry } from "../../queries/students/mutations/new";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  ROWS_PER_PAGE,
} from "../../utils/constants";
import useToken from "../../customhooks/useToken";
import { AppContext } from "../../context/context";
import useInstitutionConfiguration from "../../customhooks/useInstitutionConfiguration";
import useDropdownData from "../../customhooks/useDropdownData";
import {
  GetStudentEnquires,
  GetStudentEnquiresByEnquiryId,
  GetStudentEnquiryDashboardMonthCount,
} from "../../queries/students/list/newApi";
import { UpdateStudentEnquiry } from "../../queries/students/mutations/update";

import {
  formAutoCompleteTextStyles,
  requiredAutoCompleteStyles,
} from "../../styles/AutocompleteStyles";

import usePredefinedDataByType from "../../customhooks/usePredefinedDataByType";
import { Keys } from "../../utils/Enum.keys";
import {
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  removeMoreSpace,
  sixMonthsAgo,
  toInputStandardDate,
  toIsoDate,
} from "../../utils/UtilFunctions";
import { Button } from "../../stories/Button/Button";
import MessageModal from "../../pages/MessageModal";
import { payloadTypes } from "../../context/reducer";
import { useParams } from "react-router-dom";
import useActiveAcademicYear from "../Academics/hooks/useActiveAcademicYear";
import dayjs from "dayjs";
import useServerDateandTime from "../Library/customHooks/useServerDateandTime";
import useInstitutionDetailsDataByInstId from "../../customhooks/useInstitutionDetailsDataByInstId";
import { GetStudentsEnquiryNode } from "./StudentEnquiry";
import TextArea from "../../stories/TextArea/TextArea";
import useSwConfigData from "../../customhooks/useSwConfigData";
import { InstitutionAcctConfigurationTypes } from "../Accounts/common/Enum.types";
import useInstLabels from "../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../Accounts/hooks/useLoggedInUserDetails";
import Input from "../../stories/Input/Input";
import { GlobalPageConfigData } from "../../Types/configtypes";
import { StudentFormLabelsType } from "../../Types/Student/json";
const { StudentEnquiryForm } = require("../../json/config.json");
const { Students } = require("../../json/title.json");
interface EnquiryStudentByData {
  node: GetStudentsEnquiryNode;
}
interface EnquiryStudentByVars {
  id: number;
  token: string;
}
interface Props {
  setEnquiryDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
  enquiryId: number;
  operation: Operation;
}
const EnquiryDetails = ({
  setEnquiryDetailsModal,
  setWarningModal,
  enquiryId,
  operation,
}: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { dispatch, state } = useContext(AppContext);
  const formTextClasses = formAutoCompleteTextStyles();
  const requiredClasses = requiredAutoCompleteStyles();
  const [formData, setFormData] = useState<StudentEnquiryFormType>({
    first_name: "",
    middle_name: "",
    last_name: "",
    std_father_name: "",
    std_mother_name: "",
    std_previous_school: "",
    std_previous_place: "",
    std_email: "",
    std_mobile: "",
    std_sex: "",
    enq_date: "",
    counselled_by: "",
    enq_remarks: "",
  });
  const dobRef = document.getElementsByName("std_email")[0];

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [updateBranchSelected, setUpdateBranchSelected] =
    useState<responseType | null>(null);
  const [updateclassSelected, setUpdateClassSelected] =
    useState<responseType | null>(null);

  const [gender, setGender] = useState<optionsType | null>(null);
  const [nationality, setnationality] = useState<optionsType | null>(null);

  const [referenceBy, setReferenceBy] = useState<optionsType | null>(null);
  const [otherReference, setOtherReference] = useState("");

  const [AddEnquiry] = useMutation(AddStudentEnquiry, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const { USE_BRANCH_KEY, USE_CLASS_KEY } = useInstitutionConfiguration();
  const { branchDropDown } = useInstitutionDetailsDataByInstId(
    InstitutionDetails.BRANCH
  );

  const { classDropDown } = useDropdownData(
    0,
    updateBranchSelected ? updateBranchSelected?.value : 0,
    0,
    0,
    0
  );
  const [UpdateEnquiry] = useMutation(UpdateStudentEnquiry, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [GetEnquiryData] = useLazyQuery<
    EnquiryStudentByData,
    EnquiryStudentByVars
  >(GetStudentEnquiresByEnquiryId);
  const handleNumberChange = (mobileNo: string) => {
    setFormData((prevValues) => ({
      ...prevValues,
      std_mobile: mobileNo,
    }));
  };

  const { activeAcademicYearData } = useActiveAcademicYear();

  const { configData: enableEnqAppFee } = useSwConfigData(
    InstitutionAcctConfigurationTypes.ENABLE_APPLICATION_FEE
  );
  const { user_details } = useLoggedInUserDetails();
  const {
    PredefinedData: { dropDown: Gender },
  } = usePredefinedDataByType(PredefinedDataTypes.GENDER, EMPTY_STRING);
  const {
    PredefinedData: { dropDown: ENQ_STATUS_OPTIONS },
  } = usePredefinedDataByType(
    PredefinedDataTypes.ENQ_FOLLOW_UP_STATUS,
    EMPTY_STRING
  );
  const {
    PredefinedData: { dropDown: ReferenceBy },
  } = usePredefinedDataByType(
    PredefinedDataTypes.ENQ_REFERENACE_FROM,
    EMPTY_STRING
  );
  const { configData } = useSwConfigData([
    InstitutionConfigurationTypes.ENABLE_MIDDLE_NAME,
    InstitutionConfigurationTypes.ENABLE_LAST_NAME,
  ]);
  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let enableMiddleName = true;
    let enableLastName = true;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.ENABLE_MIDDLE_NAME:
            enableMiddleName = item.config_boolean_value;
            break;
          case InstitutionConfigurationTypes.ENABLE_LAST_NAME:
            enableLastName = item.config_boolean_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      enableMiddleName,
      enableLastName,
    };
  };
  const { enableMiddleName, enableLastName } = filterDataByConfigKey(
    configData.data?.GetSwConfigVariables!
  );
  const {
    PredefinedData: { dropDown: Nationality },
  } = usePredefinedDataByType(PredefinedDataTypes.NATIONALITY, EMPTY_STRING);
  const { serverDate } = useServerDateandTime();
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value.toString(),
    }));
  };

  const handleSubmit = () => {
    const stdMobileWithoutCountryCode = formData.std_mobile.substring(2);
    if (!stdMobileWithoutCountryCode) {
      alert("Please enter a phone number");
      return;
    }
    if (
      stdMobileWithoutCountryCode &&
      /^0+$/.test(stdMobileWithoutCountryCode.trim())
    ) {
      alert("Please enter a valid mobile number");
      return;
    }
    if (gender) {
      if (operation === Operation.UPDATE) {
        UpdateEnquiry({
          variables: {
            token,
            id: enquiryId,
            inst_id: InstId!,
            user_details,
            input: {
              ...formData,
              enq_date: toIsoDate(formData.enq_date),
              first_name: removeMoreSpace(formData.first_name),
              middle_name: removeMoreSpace(formData.middle_name),
              last_name: removeMoreSpace(formData.last_name),
              std_father_name: removeMoreSpace(formData.std_father_name),
              std_mother_name: removeMoreSpace(formData.std_mother_name),
              counselled_by: removeMoreSpace(formData.counselled_by),
              std_nationality: nationality?.value,
              branch_id: updateBranchSelected?.value,
              class_id: updateclassSelected?.value,
              std_sex: gender.value,
              std_mobile: `+${formData.std_mobile}`,
              reference_through:
                referenceBy && referenceBy.label === "others"
                  ? otherReference
                  : referenceBy?.value,
            },
          },
          refetchQueries: [
            {
              query: GetStudentEnquires,
              variables: {
                admitted: null,
                after: null,
                boughtApplication: null,
                branchID: null,
                classID: null,
                direction: Direction.ASC,
                enq_end_date: toIsoDate(serverDate),
                enq_query_type: StdEnqQueryType.STD_ENQ_ALL,
                enq_start_date: sixMonthsAgo(serverDate),
                name: EMPTY_STRING,
                lastFollowUpStatus: EMPTY_STRING,

                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                acd_yr_id:
                  activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
                first: ROWS_PER_PAGE,
                sortBy: SortBy.ENQ_DATE,
                today_date: toIsoDate(serverDate),
                token,
                pr_emp_id: state.employeeId,
                inst_id: InstId!,
              },
              fetchPolicy: "network-only",
            },
            {
              query: GetStudentEnquiresByEnquiryId,
              variables: {
                token,
                id: enquiryId,
              },
              fetchPolicy: "network-only",
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              flag: true,
              message: "Enquiry Updated Successfully",
              operation: Operation.UPDATE,
            });
          }
        });
      } else {
        AddEnquiry({
          variables: {
            token,
            inst_id: InstId,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
            user_details,
            input: {
              ...formData,
              first_name: removeMoreSpace(formData.first_name),
              middle_name: removeMoreSpace(formData.middle_name),
              last_name: removeMoreSpace(formData.last_name),
              std_father_name: removeMoreSpace(formData.std_father_name),
              std_mother_name: removeMoreSpace(formData.std_mother_name),
              counselled_by: removeMoreSpace(formData.counselled_by),
              std_nationality: nationality?.value,
              branch_id: updateBranchSelected?.value,
              class_id: updateclassSelected?.value,
              std_mobile: `+${formData.std_mobile}`,

              enq_date: dayjs(formData.enq_date).format(),
              std_sex: gender.value,
              reference_through:
                referenceBy?.label === "others"
                  ? otherReference
                  : referenceBy?.value,
            },
          },
          refetchQueries: [
            {
              query: GetStudentEnquires,
              variables: {
                admitted: null,
                after: null,
                boughtApplication: null,
                branchID: null,
                classID: null,
                direction: Direction.ASC,
                enq_end_date: toIsoDate(serverDate),
                enq_query_type: StdEnqQueryType.STD_ENQ_ALL,
                enq_start_date: toIsoDate(sixMonthsAgo(serverDate)),
                name: EMPTY_STRING,
                lastFollowUpStatus: EMPTY_STRING,
                fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
                acd_yr_id:
                  activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
                first: ROWS_PER_PAGE,
                sortBy: SortBy.ENQ_DATE,
                today_date: toIsoDate(serverDate),
                token,
                pr_emp_id: state.employeeId,
                inst_id: InstId!,
              },
              fetchPolicy: "network-only",
            },
            {
              query: GetStudentEnquiryDashboardMonthCount,
              variables: {
                token,
                inst_id: InstId!,
                today_date: toIsoDate(serverDate),
              },
              fetchPolicy: "network-only",
            },
          ],
        }).then(({ data }) => {
          if (data) {
            dispatch({
              type: payloadTypes.SET_STD_ENQUIRY_ID,
              payload: {
                studentEnquiryId: data.AddStudentEnquiry.id,
              },
            });
            setMessage({
              message: "Student Enquiry Added successfully",
              flag: true,
              operation: Operation.CREATE,
            });
          }
        });
      }
    } else {
      setMessage({
        message: "Please fill the required fields",
        flag: true,
        operation: Operation.NONE,
      });
    }
  };
  const handleClear = () => {
    setFormData({
      first_name: "",
      middle_name: "",
      last_name: "",
      std_father_name: "",
      std_mother_name: "",
      std_previous_school: "",
      std_previous_place: "",
      std_email: "",
      std_mobile: "",
      std_sex: "",
      enq_date: "",
      counselled_by: "",
      enq_remarks: "",
    });
    setUpdateBranchSelected(null);
    setUpdateClassSelected(null);
    setGender(null);
    setReferenceBy(null);
    setnationality(null);
    setFormData((prevValues) => ({
      ...prevValues,
      enq_date: toInputStandardDate(serverDate),
    }));
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      if (message.operation === Operation.CREATE) {
        if (
          enableEnqAppFee.data &&
          enableEnqAppFee.data.GetSwConfigVariables.length > 0 &&
          enableEnqAppFee.data.GetSwConfigVariables[0].config_boolean_value
        )
          setWarningModal(true);
      }
      setEnquiryDetailsModal(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (serverDate) {
      setFormData((prevValues) => ({
        ...prevValues,
        enq_date: toInputStandardDate(serverDate),
      }));
    }
  }, [serverDate]);
  useEffect(() => {
    if (
      enquiryId &&
      operation === Operation.UPDATE &&
      Gender.length &&
      ReferenceBy.length &&
      Nationality.length
    ) {
      GetEnquiryData({
        variables: {
          id: enquiryId,
          token,
        },
      }).then(({ data }) => {
        if (data && data.node) {
          const empMobileWithoutPlus = data?.node.std_mobile.replace("+", "");
          setFormData({
            first_name: data.node.first_name,
            middle_name: data.node.middle_name,
            last_name: data.node.last_name,
            std_father_name: data.node.std_father_name,
            std_mother_name: data.node.std_mother_name,
            std_previous_school: data.node.std_previous_school,
            std_previous_place: data.node.std_previous_place,
            std_email: data.node.std_email,
            std_mobile: empMobileWithoutPlus,
            counselled_by: data.node.counselled_by,
            enq_date:
              toInputStandardDate(data.node.enq_date) !== DEFAULT_TIME
                ? toInputStandardDate(data.node.enq_date)
                : EMPTY_STRING,
            enq_remarks: data.node.enq_remarks,
          });

          setGender(
            Gender?.find(({ value }) => value === data.node.std_sex)! ?? null
          );
          setnationality(
            Nationality?.find(
              ({ value }) => value === data.node.std_nationality
            )! ?? null
          );
          if (
            !ReferenceBy.map(({ value }) => value).includes(
              data.node.reference_through
            )
          ) {
            setReferenceBy({
              label: "others",
              value: "others",
            });
            setOtherReference(data.node.reference_through);
          } else {
            setReferenceBy(
              ReferenceBy.find(
                ({ value }) => value === data.node.reference_through
              )! ?? null
            );
          }
          const branchData = data.node.branch_details;
          setUpdateBranchSelected({
            label: branchData.branch_desc,
            value: branchData.id,
          });
          const classData = data.node.class_details;
          setUpdateClassSelected({
            label: classData.class_desc,
            value: classData.id,
          });
        }
      });
    }
  }, [
    enquiryId,
    GetEnquiryData,
    token,
    Gender,
    Nationality,
    ReferenceBy,
    operation,
    ENQ_STATUS_OPTIONS,
  ]);

  const { branchLabel, classLabel } = useInstLabels();
  return (
    <div className="modal-flex h-100">
      <div className="modal-flex__data h-100">
        <Title>
          {Students.StudentEnquiry.Titles.map(
            (title: TitleProps, index: React.Key) => {
              return (
                <React.Fragment key={index}>
                  {operation === Operation.UPDATE ? title.Update : title.Create}
                </React.Fragment>
              );
            }
          )}
        </Title>
        <Formik
          initialValues={formData}
          validationSchema={StudentEnquiryValidation}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(meta) => {
            return (
              <Form className="student-enquiry__form">
                <div className="row g-0 student-enquiry__form--data">
                  <div className="col h-100">
                    {StudentEnquiryForm.Labels.Name.filter(
                      (label: StudentFormLabelsType) => {
                        return (
                          (label.inputName === "middle_name" &&
                            enableMiddleName) ||
                          (label.inputName === "last_name" && enableLastName) ||
                          (label.inputName !== "middle_name" &&
                            label.inputName !== "last_name")
                        );
                      }
                    ).map((label: LabelNameProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>
                          <InputHoc
                            LabelName={label.LabelName}
                            name={label.inputName}
                            type={label.dataType}
                            values={formData[label.inputName]}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            required={label.required}
                            maxLength={label.maxLength}
                            autoFocus={label.autoFocus}
                          />
                        </React.Fragment>
                      );
                    })}
                    <div className="student-enquiry__form--data--gender ">
                      <Label>Gender</Label>
                      <Autocomplete
                        classes={requiredClasses}
                        options={Gender!}
                        value={gender}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setGender(newValue as optionsType);
                          } else {
                            setGender(null);
                          }
                        }}
                        isOptionEqualToValue={(option) =>
                          isOptionEqualToValue(option, gender)
                        }
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            if (gender) {
                              handleMUISelectEvent(e);
                            }
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setGender(null);
                          }
                        }}
                        openOnFocus
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            classes={{
                              root: formTextClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="label-grid">
                      <Label>Phone Number</Label>
                      <PhoneInput
                        country={"in"}
                        value={formData.std_mobile}
                        onChange={handleNumberChange}
                        inputProps={{
                          required: true,
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            dobRef.focus();
                          }
                        }}
                      />
                    </div>
                    {StudentEnquiryForm.Labels.OtherDetails.map(
                      (label: LabelNameProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <InputHoc
                              LabelName={label.LabelName}
                              name={label.inputName}
                              onKeyDown={handleFormEvent}
                              type={label.dataType}
                              values={formData[label.inputName]}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              required={label.required}
                              maxLength={label.maxLength}
                              autoFocus={label.autoFocus}
                            />
                          </React.Fragment>
                        );
                      }
                    )}
                    <div className="label-grid">
                      <Label>Nationality</Label>
                      <Autocomplete
                        classes={requiredClasses}
                        options={Nationality!}
                        value={nationality}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setnationality(newValue as optionsType);
                          } else {
                            setnationality(null);
                          }
                        }}
                        isOptionEqualToValue={(option) =>
                          isOptionEqualToValue(option, nationality)
                        }
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            if (gender) {
                              handleMUISelectEvent(e);
                            }
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setnationality(null);
                          }
                        }}
                        openOnFocus
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            classes={{
                              root: formTextClasses.formControlRoot,
                            }}
                            required={nationality ? false : true}
                          />
                        )}
                      />
                    </div>
                    <div className="label-grid">
                      <Label>Staff Remarks</Label>
                      <TextArea
                        name="staff_remarks"
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER && !e.shiftKey) {
                            handleMUISelectEvent(e);
                          }
                        }}
                        onChange={(e) => {
                          setFormData((prevValues) => {
                            return {
                              ...prevValues,
                              enq_remarks: e.target.value,
                            };
                          });
                        }}
                        value={formData.enq_remarks}
                        rows={4}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <InputHoc
                      LabelName={"Enquiry Date"}
                      name="enq_date"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleValueChange(e);
                        meta.handleChange(e);
                      }}
                      values={formData.enq_date}
                      max={toInputStandardDate(serverDate)}
                      type="date"
                      required
                    />

                    <div className="label-grid">
                      {USE_BRANCH_KEY ? (
                        <>
                          <Label>{branchLabel}</Label>
                          <Autocomplete
                            classes={requiredClasses}
                            popupIcon={
                              <img
                                src={DownArrow}
                                alt="/"
                                className="down-arrow-autocomplete"
                              />
                            }
                            options={branchDropDown}
                            isOptionEqualToValue={(option) =>
                              isOptionEqualToValue(option, updateBranchSelected)
                            }
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (updateBranchSelected) {
                                  handleMUISelectEvent(e);
                                }
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setUpdateBranchSelected(null);
                                setUpdateClassSelected(null);
                              }
                            }}
                            openOnFocus
                            value={updateBranchSelected}
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setUpdateBranchSelected(newValue);
                                setUpdateClassSelected(null);
                              } else {
                                setUpdateBranchSelected(null);
                                setUpdateClassSelected(null);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                required={updateBranchSelected ? false : true}
                                {...params}
                                fullWidth
                                classes={{
                                  root: formTextClasses.formControlRoot,
                                }}
                              />
                            )}
                          />
                        </>
                      ) : null}
                    </div>
                    <div className="label-grid">
                      {USE_CLASS_KEY ? (
                        <>
                          <Label>{classLabel}</Label>
                          <Autocomplete
                            classes={requiredClasses}
                            popupIcon={
                              <img
                                src={DownArrow}
                                alt="/"
                                className="down-arrow-autocomplete"
                              />
                            }
                            options={classDropDown}
                            openOnFocus
                            isOptionEqualToValue={(option) =>
                              isOptionEqualToValue(option, updateclassSelected)
                            }
                            onKeyDown={(e) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (updateclassSelected) {
                                  handleMUISelectEvent(e);
                                }
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setUpdateClassSelected(null);
                              }
                            }}
                            value={updateclassSelected}
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setUpdateClassSelected(newValue);
                              } else {
                                setUpdateClassSelected(null);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                required={updateclassSelected ? false : true}
                                {...params}
                                classes={{
                                  root: formTextClasses.formControlRoot,
                                }}
                                fullWidth
                              />
                            )}
                          />
                        </>
                      ) : null}
                    </div>

                    <div className="label-grid">
                      <Label>Reference Through</Label>
                      <Autocomplete
                        classes={requiredClasses}
                        options={ReferenceBy!}
                        value={referenceBy}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setReferenceBy(newValue as optionsType);
                          } else {
                            setReferenceBy(null);
                          }
                        }}
                        isOptionEqualToValue={(option) =>
                          isOptionEqualToValue(option, referenceBy)
                        }
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            if (referenceBy) {
                              handleMUISelectEvent(e);
                            }
                          }
                          if (e.key === Keys.BACKSPACE) {
                            setGender(null);
                          }
                        }}
                        openOnFocus
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            classes={{
                              root: formTextClasses.formControlRoot,
                            }}
                            required={referenceBy ? false : true}
                          />
                        )}
                      />
                    </div>
                    {referenceBy?.label === "others" && (
                      <div className="label-grid">
                        <Label>Other Reference</Label>

                        <Input
                          onKeyDown={(e: React.KeyboardEvent) => {
                            if (e.key === Keys.ENTER && !e.shiftKey) {
                              handleMUISelectEvent(e);
                            }
                          }}
                          onChange={(e) => {
                            setOtherReference(e.target.value);
                          }}
                          value={otherReference}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <>
                  <Button mode="save" type="submit" />
                  <Button mode="clear" type="button" onClick={handleClear} />
                  <Button
                    mode="cancel"
                    onClick={() => setEnquiryDetailsModal(false)}
                  />
                </>
              </Form>
            );
          }}
        </Formik>

        <MessageModal
          modalFlag={message.flag!}
          value={message.message!}
          handleClose={handleClose}
          operation={message.operation!}
        />
      </div>
    </div>
  );
};
export default EnquiryDetails;
