import React, { useContext, useEffect, useRef, useState } from "react";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import { BookTransactionTitleProps } from "../../../../Types/Titles";
import Home from "../../Home/Index";
import EmpList from "../../../HR/Employee/List/Index";
import { Button } from "../../../../stories/Button/Button";
import {
  Autocomplete,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import Modal from "react-modal";

import Avatar from "../../../../images/Avatar.svg";
import History from "../../../../images/Refresh.svg";
import Settings from "../../../../images/Settings.svg";

import { TableHeaderProps } from "../../../../Types/Tables";
import {
  ConfigurationsModalStyles,
  DamagedBooksCustomStyles,
  StudentModalStyles,
  WaiveOffModalStyles,
} from "../../../../styles/ModalStyles";
import {
  BookReturnSearchBy,
  BookConditionType,
  PageFor,
  ReturnType,
  UserType,
  BooksReportType,
  BookReturnType,
  PageNumbers,
  LibraryConfigKey,
  StudentReportType,
  Operation,
  LibBookMasterQueryType,
  SortBy,
  Direction,
  BookListType,
  BooksBy,
  LibBookIssueQueryType,
} from "../../../../utils/Enum.types";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
//Modal components
import StudentList from "../../../Academics/students/List";
import ConfigurationSettings from "../../../Master/configurations/general/Index";

import Edit from "../../../../images/Edit.svg";
import Close from "../../../../images/Close.svg";
import More from "../../../../images/More.svg";
import { LibraryLabelNameProps } from "../../../../Types/Labels";
import { useStyles } from "../../../../styles/TooltipStyles";
import {
  AUTOCOMPLETE_CLEAR_CLASSNAME,
  DEFAULT_TIME,
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
  ROWS_PER_PAGE_30,
  TODAY_DATE,
} from "../../../../utils/constants";
import {
  getDueDate,
  GetNoOfDaysDelayed,
  NameOfTheDay,
  toIsoDate,
  toStandardCase,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import TransactionHistory from "../../Modals/BookTransactions/Index";
import { useNavigate, useParams } from "react-router-dom";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import BookSearch from "../../BooksSearch/Index";
import {
  BookDetailsDamageLost,
  msgType,
  responseType,
} from "../../../../utils/Form.types";
import { AppContext } from "../../../../context/context";
import {
  AddLibBookRemarks,
  RenewalLibBookIssue,
  ReturnLibBookIssue,
} from "../../../../queries/Library/BookReturn/mutation/new";
import { useLazyQuery, useMutation } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import useFineSlabOptions from "../../customHooks/useFineSlabOptions";
import useBookEntryOptions from "../../customHooks/useBookEntryOptions";
import {
  BookIssueNode,
  BookMasterNode,
} from "../../../../Types/Library/BookEntry/paginationTypes";
import MessageModal from "../../../../pages/MessageModal";
import LoadingModal from "../../../../pages/LoadingModal";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import { GetLibBookCounters } from "../../../../queries/Library/Dashboard/new";
import useAcademicYear from "../../../Academics/hooks/useActiveAcademicYear";
import { Keys } from "../../../../utils/Enum.keys";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import useStudentAdmissionNumber from "../../../Accounts/hooks/UseStudentAdmissionNumber";
import DownArrow from "../../../../images/DownArrow.svg";
import { payloadTypes } from "../../../../context/reducer";
import {
  GetAllInstsOfCampus,
  GetLibBookMaster,
} from "../../../../queries/Library/BookEntry/list/byInstId";
import useServerDateandTime from "../../customHooks/useServerDateandTime";
import CollectLibraryFine from "../../../Accounts/StudentDetails/NonDemandFeePayable/CollectLibraryFine";
import { GetAcdStudents } from "../../../Academics/queries/students/Index";
import { StudentAcdType } from "../../../../utils/studentAcdEnum.types";
import TextArea from "../../../../stories/TextArea/TextArea";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryTableJson from "../../json/useLibraryTableJson";
import useLibraryformJson from "../../json/useLibraryformJson";
import {
  GetLibBookIssuedData,
  GetLibBookIssuedVars,
} from "../../../../Types/Library/BookIssue/paginationTypes";
import { GetLibBookIssued } from "../../../../queries/Library/BookIssue/list/byIds";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import {
  GetAllInstsOfCampusData,
  GetAllInstsOfCampusVars,
} from "../BookIssue/Index";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
const { Library } = require("../../../../json/title.json");
interface props {
  type: PageFor;
  BorrowerType: UserType;
  setModalFlag?: (modalFlag: boolean) => void;
}
interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}
interface bookReturnTypes {
  id: number;
  issue_id: number;
  issue_book_access_no: string;
  issue_book_remarks: string;
  issue_returned: boolean;
  issue_return_date: string;
  issue_return_type: string;
  issue_return_damaged: boolean;
  damaged_fine_amt: number;
  issue_return_lost: boolean;
  lost_fine_amt: number;
  issue_return_fine: boolean;
  delayed_fine_amt: number;
  issue_return_fine_amt: number;
  book_last_issue_date: string;
}

export interface bookReturnDetails {
  totalFineAmount: number;
  handleBook: (type: string, vendor_master_id: number) => void;
  flag: boolean;
}

const Index = ({ type, BorrowerType, setModalFlag }: props) => {
  //Autocomplete Styles
  const listClasses = ListAutoCompleteStyles();
  const listTextClasses = ListAutoCompleteTextStyles();
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const tooltipclasses = useStyles();
  const { Library_Table } = useLibraryTableJson();
  const { LibraryFormLabels } = useLibraryformJson();

  const { InstDetails } = useInstDetails(1);

  //useParams useNavigate token
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { token } = useToken();
  const { user_details } = useLoggedInUserDetails();
  const { serverDate } = useServerDateandTime();
  const { dispatch, state } = useContext(AppContext);
  //useStates
  const [searchBy, setSearchBy] = useState(BookReturnSearchBy.STUDENT);
  const [bookSelected, setBookSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );

  const [BooksReturn, setBooksReturn] = useState<bookReturnTypes[]>([]);
  const [damagedBooksList, setDamagedBooksList] = useState<bookReturnTypes[]>(
    []
  );
  const [bookLostList, setBookLostList] = useState<bookReturnTypes[]>([]);
  const [fine, setFine] = useState<responseType>();
  const [bookSearchModal, setBookSearchModal] = useState(false);

  const [date, setDate] = useState(TODAY_DATE);

  //UserRefs
  const admNoRef = useRef<HTMLSelectElement>(null);
  const admNoClearRef = admNoRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;
  const bookIdRef = useRef<HTMLSelectElement>(null);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const accRef = useRef<HTMLInputElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);

  const BookIdClearRef = bookIdRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;
  const [imageString, setImageString] = useState("");

  const [bookDetailsForm, setBookDetailsForm] = useState<BookDetailsDamageLost>(
    {
      id: 0,
      book_id: 0,
      book_accesion_no: "",
      book_title: "",
      remarks: "",
      percentage: 0,
      book_price: 0,
      percentage_amt: 0,
    }
  );
  //flags
  const [studentModal, setStudentModal] = useState(false);
  const [bookLostDamagedModal, setBookLostDamagedModal] = useState("");
  const [enableWaiveOff, setEnableWaiveOff] = useState(false);
  const [nonDemandModal, setNonDemandModal] = useState(false);
  const [configurationModal, setConfigurationModal] = useState(false);
  const [bookHistory, setBookHistory] = useState(false);
  const [waiveOffAmt, setWaiveOffAmt] = useState(0);
  const { activeAcademicYearData } = useAcademicYear();
  const [bookId, setBookId] = useState(0);

  const [admNo, setAdmNo] = useState("");
  // eslint-disable-next-line
  const [searchData, setSearchData] = useState("");
  const [instSelected, setInstSelected] = useState<responseType | null>(null);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const searchByOptions = [
    {
      label: toStandardCase(BorrowerType) + " ID",
      value:
        BorrowerType === UserType.STUDENT
          ? BookReturnSearchBy.STUDENT
          : BookReturnSearchBy.STAFF,
    },
    { label: "Book ID", value: BookReturnSearchBy.BOOK_ID },
  ];

  const [AddBookRemarks, { loading: BookRemarksLoading }] = useMutation(
    AddLibBookRemarks,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [BookReturnUpdate, { loading: BookReturnLoading }] = useMutation(
    ReturnLibBookIssue,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [BookRenewalUpdate, { loading: BookRenewalLoading }] =
    useMutation(RenewalLibBookIssue);

  //customHooks
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.LIB_BOOK_RETURN_PAGE
  );
  const { bookEntries } = useBookEntryOptions(
    searchData,
    ROWS_PER_PAGE,
    BooksReportType.BOOKS_ISSUED,
    BooksBy.ACCESS_NO
  );
  const [GetCampusData, { data: CampusInstData }] = useLazyQuery<
    GetAllInstsOfCampusData,
    GetAllInstsOfCampusVars
  >(GetAllInstsOfCampus);
  const instDropdown = CampusInstData
    ? CampusInstData.GetAllInstsOfCampus.map((res) => ({
        label: res.inst_details.inst_name,
        value: res.inst_details.id,
      }))
    : [];

  const bookDropDown = bookEntries.bookEntriesDropDown;
  const { configData: LibTranactionEdiatable } = useSwConfigData(
    LibraryConfigKey.LIB_BOOKS_TRANSACTION_DATE_EDITABLE
  );
  const { configData: BookDamageFinePercentage } = useSwConfigData(
    LibraryConfigKey.LIB_DAMAGEBOOK_FINE_PER
  );
  const { configData: BookLostFinePercentage } = useSwConfigData(
    LibraryConfigKey.LIB_LOSTBOOK_FINE_PER
  );
  const { configData: BookIssueLimit } = useSwConfigData(
    LibraryConfigKey.LIB_BOOKS_ISSUE_LIMIT
  );
  const { configData: NoOfBookBorrowingDays } = useSwConfigData(
    LibraryConfigKey.LIB_BOOK_NO_OF_BORROWING_DAYS
  );
  const { configData: FeeReceipt } = useSwConfigData(
    LibraryConfigKey.ENABLE_LIB_FEE_RECEIPT
  );
  const DAMAGEBOOK_FINE =
    BookDamageFinePercentage.data?.GetSwConfigVariables[0]
      .config_integer_value!;
  const EDIT_TRANSACTION_DATE =
    LibTranactionEdiatable.data?.GetSwConfigVariables[0].config_boolean_value!;
  const LOSTBOOK_FINE =
    BookLostFinePercentage.data?.GetSwConfigVariables[0].config_integer_value!;
  const BOOKS_ISSUE_LIMIT =
    BookIssueLimit.data?.GetSwConfigVariables[0].config_integer_value!;
  const DELAY_DAYS_LIMIT =
    NoOfBookBorrowingDays.data?.GetSwConfigVariables[0].config_integer_value!;
  const ENABLE_LIB_FEE_RECEIPT =
    FeeReceipt.data?.GetSwConfigVariables[0]?.config_boolean_value!;

  const {
    bookEntries: {
      issuedBooksData: { data: IssueBookData },
    },
  } = useBookEntryOptions(
    EMPTY_STRING,
    ROWS_PER_PAGE,
    BooksReportType.BOOKS_ISSUED,
    BorrowerType,
    0
  );
  const { branchLabel, classLabel } = useInstLabels();
  const [GetBookIssuedData, { data }] = useLazyQuery<
    GetLibBookIssuedData,
    GetLibBookIssuedVars
  >(GetLibBookIssued, {
    variables: {
      first: ROWS_PER_PAGE_30,
      after: null,
      input: {
        book_issue_query_type: LibBookIssueQueryType.BOOKS_ISSUED_BY_BOOK_ID,
        book_issue_from_date: toIsoDate(DEFAULT_TIME),
        book_issue_to_date: toIsoDate(DEFAULT_TIME),
        ids: [Number(InstId), state.bookId],
      },
      sortBy: SortBy.ISSUE_DATE,
      direction: Direction.ASC,
      token,
    },
  });
  const stdDataByBookId =
    data && data.GetLibBookIssued.edges.length
      ? data.GetLibBookIssued.edges[0]
      : null;

  const { studentAddmissionNumber } = useStudentAdmissionNumber(
    admNo,
    NumberOfAdmissionNumberToBeDisplayed,
    instSelected ? StudentReportType.CAMPUS : StudentReportType.GENERAL,
    instSelected ? instSelected.value : 0
  );
  const {
    fines: {
      finesDropdown,
      queryData: { data: finesData },
    },
  } = useFineSlabOptions(ReturnType.BOTH);
  const { studentFormData, studentData } = useStudentDatabyId();

  const handleClick = (
    event: React.MouseEvent<unknown>,
    data: BookMasterNode
  ) => {
    const newData: bookReturnTypes = {
      id: data.id,
      issue_id: data.issue_details.id,
      issue_book_access_no: data.book_access_no,
      issue_returned: false,
      issue_return_date: data.issue_details.issue_return_date,
      issue_return_type: "",
      issue_return_damaged: false,
      issue_book_remarks: "",
      issue_return_lost: false,
      damaged_fine_amt: 0,
      lost_fine_amt: 0,
      book_last_issue_date: "",
      delayed_fine_amt:
        getFine(
          GetNoOfDaysDelayed(
            getDueDate(data.book_last_issued_date, DELAY_DAYS_LIMIT)
          )
        ) *
        GetNoOfDaysDelayed(
          getDueDate(data.book_last_issued_date, DELAY_DAYS_LIMIT)
        ),
      issue_return_fine:
        getFine(
          GetNoOfDaysDelayed(
            getDueDate(data.book_last_issued_date, DELAY_DAYS_LIMIT)
          )
        ) > 0,
      issue_return_fine_amt: getFine(
        GetNoOfDaysDelayed(
          getDueDate(data.book_last_issued_date, DELAY_DAYS_LIMIT)
        )
      ),
    };

    if (!BooksReturn.find((data) => data.id === newData.id)) {
      setBooksReturn((prev) => [...prev, newData]);
    } else {
      setBooksReturn(BooksReturn?.filter(({ id }) => id !== data.id));
      if (
        damagedBooksList &&
        damagedBooksList.indexOf(
          damagedBooksList.find((label) => label.id === data.id)!
        ) > -1
      ) {
        setDamagedBooksList(
          damagedBooksList?.filter(({ id }) => id !== data.id)
        );
      }
      if (
        bookLostList.indexOf(
          bookLostList?.find((label) => label.id === data.id)!
        ) > -1
      ) {
        setBookLostList(bookLostList?.filter(({ id }) => id !== data.id));
      }
    }
  };
  const handleClickByBookId = (
    event: React.MouseEvent<unknown>,
    data: BookIssueNode
  ) => {
    const newData: bookReturnTypes = {
      id: data.id,
      issue_id: data.id,
      issue_book_access_no: data.book_master.book_access_no,
      issue_returned: false,
      issue_return_date: data.issue_return_date,
      issue_return_type: "",
      issue_return_damaged: false,
      issue_book_remarks: "",
      issue_return_lost: false,
      damaged_fine_amt: 0,
      lost_fine_amt: 0,
      book_last_issue_date: "",
      delayed_fine_amt:
        getFine(
          GetNoOfDaysDelayed(
            getDueDate(data.issue_return_date, DELAY_DAYS_LIMIT)
          )
        ) *
        GetNoOfDaysDelayed(
          getDueDate(data.issue_return_date, DELAY_DAYS_LIMIT)
        ),
      issue_return_fine:
        getFine(
          GetNoOfDaysDelayed(
            getDueDate(data.issue_return_date, DELAY_DAYS_LIMIT)
          )
        ) > 0,
      issue_return_fine_amt: getFine(
        GetNoOfDaysDelayed(getDueDate(data.issue_return_date, DELAY_DAYS_LIMIT))
      ),
    };

    if (!BooksReturn.find((data) => data.id === newData.id)) {
      setBooksReturn((prev) => [...prev, newData]);
    } else {
      setBooksReturn(BooksReturn?.filter(({ id }) => id !== data.id));
      if (
        damagedBooksList &&
        damagedBooksList.indexOf(
          damagedBooksList.find((label) => label.id === data.id)!
        ) > -1
      ) {
        setDamagedBooksList(
          damagedBooksList?.filter(({ id }) => id !== data.id)
        );
      }
      if (
        bookLostList.indexOf(
          bookLostList?.find((label) => label.id === data.id)!
        ) > -1
      ) {
        setBookLostList(bookLostList?.filter(({ id }) => id !== data.id));
      }
    }
  };
  const handleClear = () => {
    setImageString(EMPTY_STRING);
    if (!nonDemandModal) {
      dispatch({
        type: payloadTypes.SET_STUDENT_ID,
        payload: {
          studentId: 0,
        },
      });
      setBooksReturn([]);
      totalDelayedDays = 0;
      totalDelayedFine = 0;
      totalLostFine = 0;
      totalDamageFine = 0;
    }
    setDamagedBooksList([]);
    setBookLostList([]);
    admNoClearRef?.click();
    BookIdClearRef?.click();
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newBooksReturns = IssueBookData?.nodes?.map((data) => ({
        id: data.id,
        book_title: data.book_title,
        issue_id: data.issue_details.id,
        issue_book_access_no: data.book_access_no,
        issue_returned: false,
        issue_return_date: date,
        issue_return_type: "",
        issue_return_damaged: false,
        issue_book_remarks: "",
        issue_return_lost: false,
        damaged_fine_amt: 0,
        lost_fine_amt: 0,
        book_last_issue_date: "",
        delayed_fine_amt: getFine(
          GetNoOfDaysDelayed(
            getDueDate(data.book_last_issued_date, DELAY_DAYS_LIMIT)
          )
        ),
        issue_return_fine:
          getFine(
            GetNoOfDaysDelayed(
              getDueDate(data.book_last_issued_date, DELAY_DAYS_LIMIT)
            )
          ) > 0,
        issue_return_fine_amt: getFine(
          GetNoOfDaysDelayed(
            getDueDate(data.book_last_issued_date, DELAY_DAYS_LIMIT)
          )
        ),
      }));
      setBooksReturn(newBooksReturns!);
      return;
    }
    setBooksReturn([]);
  };

  const handleBookReturnRenewal = (type: string, voucher_master_id: number) => {
    switch (type) {
      case BookReturnType.RETURN:
        BookReturnUpdate({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            input: BooksReturn?.map(
              ({
                issue_id,
                issue_return_date,
                issue_return_type,
                issue_return_damaged,
                issue_return_lost,
                issue_return_fine,
                issue_return_fine_amt,
              }) => ({
                id: issue_id,
                issue_returned: true,
                issue_renewed: false,
                issue_return_date: toIsoDate(serverDate),
                issue_return_type: issue_return_type
                  ? issue_return_type
                  : BookConditionType.GOOD,
                issue_return_damaged,
                issue_return_lost,
                issue_return_fine_collected: issue_return_fine,
                issue_return_fine_voucher_generated: voucher_master_id > 0,
                issue_fine_amt: issue_return_fine_amt,
                voucher_master_id,
              })
            ),
          },
          refetchQueries: [
            {
              query: GetLibBookCounters,
              variables: {
                acd_yr_id:
                  activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
                inst_id: InstId!,
                token,
              },
            },
            {
              query: GetLibBookMaster,
              variables: {
                token,
                input: {
                  book_query_type:
                    LibBookMasterQueryType.BOOKS_ISSUED_TO_STUDENT,
                  ids: [Number(InstId), state.studentId],
                },
                first: ROWS_PER_PAGE,
                sortBy: SortBy.ACCESS_NO,
                direction: Direction.ASC,
                access_no: EMPTY_STRING,
                after: null,
              },
            },
            {
              query: GetLibBookMaster,
              variables: {
                token,
                input: {
                  book_query_type:
                    LibBookMasterQueryType.BOOKS_THAT_ARE_DAMAGED,
                  ids: [Number(InstId)],
                },
                first: ROWS_PER_PAGE,
                sortBy: SortBy.ACCESS_NO,
                direction: Direction.ASC,
                access_no: EMPTY_STRING,
                after: null,
              },
            },
            {
              query: GetLibBookMaster,
              variables: {
                token,
                input: {
                  book_query_type:
                    LibBookMasterQueryType.BOOKS_THAT_ARE_DAMAGED,
                  ids: [Number(InstId)],
                },
                first: ROWS_PER_PAGE,
                sortBy: SortBy.ACCESS_NO,
                direction: Direction.ASC,
                access_no: EMPTY_STRING,
                after: null,
              },
            },
            {
              query: GetAcdStudents,
              variables: {
                after: null,
                acd_yr_id:
                  activeAcademicYearData.data?.GetAcdYrActiveByInstId?.id!,
                first: ROWS_PER_PAGE,
                name: EMPTY_STRING,
                token,
                direction: Direction.ASC,
                sortBy: SortBy.FIRST_NAME,
                input: {
                  ids: [InstId],
                  acd_std_query_type:
                    StudentAcdType.LIB_BOOK_CLEARANCE_BY_INST_ID,
                  str_data: "",
                },
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              message: "Book Returned SuccessFully",
              flag: true,
              operation: Operation.UPDATE,
            });
            handleClear();
          }
        });
        break;
      case BookReturnType.RENEWAL:
        BookRenewalUpdate({
          variables: {
            token,
            inst_id: InstId,
            user_details,
            input: BooksReturn?.map(({ issue_id }) => ({
              id: issue_id,
              issue_returned: false,
              issue_renewed: true,
              issue_return_date: toIsoDate(
                getDueDate(serverDate, DELAY_DAYS_LIMIT).toString()
              ),
            })),
          },
          refetchQueries: [
            {
              query: GetLibBookMaster,
              variables: {
                token,
                input: {
                  book_query_type:
                    LibBookMasterQueryType.BOOKS_THAT_ARE_DAMAGED,
                  ids: [Number(InstId)],
                },
                first: ROWS_PER_PAGE,
                sortBy: SortBy.ACCESS_NO,
                direction: Direction.ASC,
                access_no: EMPTY_STRING,
                after: null,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              message: "Book Renewed SuccessFully",
              flag: true,
              operation: Operation.CREATE,
            });
            handleClear();
          }
        });
        break;
      default:
        break;
    }
  };
  const handleLostDamagedBooks = () => {
    AddBookRemarks({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        input: {
          remarks: bookDetailsForm.remarks,
          lib_book_master_id: bookDetailsForm.book_id,
        },
      },
    }).then(({ data }) => {
      if (data) {
        const BookInArray = BooksReturn?.find(
          (data) => data.id === bookDetailsForm.id
        )!;
        const BookIndex = BooksReturn?.indexOf(BookInArray);
        BooksReturn[BookIndex].issue_return_type = bookLostDamagedModal;
        BooksReturn[BookIndex].issue_book_remarks = bookDetailsForm.remarks;
        BooksReturn[BookIndex].issue_return_damaged =
          bookLostDamagedModal === BookConditionType.BOOK_DAMAGED;
        BooksReturn[BookIndex].damaged_fine_amt =
          bookLostDamagedModal === BookConditionType.BOOK_DAMAGED
            ? Math.round(bookDetailsForm.percentage_amt)
            : 0;
        BooksReturn[BookIndex].lost_fine_amt =
          bookLostDamagedModal === BookConditionType.BOOK_LOST
            ? Math.round(bookDetailsForm.percentage_amt)
            : 0;
        BooksReturn[BookIndex].issue_return_lost =
          bookLostDamagedModal === BookConditionType.BOOK_LOST;
        BooksReturn[BookIndex].issue_return_fine_amt =
          Math.round(bookDetailsForm.percentage_amt) +
          BooksReturn[BookIndex].issue_return_fine_amt;
        setBooksReturn(BooksReturn);
        if (
          BooksReturn.filter((data) => data.issue_return_damaged).length > 0
        ) {
          setDamagedBooksList(
            BooksReturn.filter((data) => data.issue_return_damaged)
          );
        }
        if (BooksReturn.filter((data) => data.issue_return_lost).length > 0) {
          setBookLostList(BooksReturn.filter((data) => data.issue_return_lost));
        }
        setBookId(0);
        setBookDetailsForm({
          id: 0,
          book_id: 0,
          book_accesion_no: "",
          book_title: "",
          remarks: "",
          percentage: 0,
          book_price: 0,
          percentage_amt: 0,
        });
      }
    });
  };
  const getFine = (noOfDaysDelayed: number) => {
    const fineDetails = finesData?.GetLibFineByInstId?.find(
      ({ id }) => id === fine?.value
    )!;
    if (
      noOfDaysDelayed >= fineDetails?.lib_slab1_from &&
      noOfDaysDelayed <= fineDetails?.lib_slab1_to
    ) {
      return fineDetails.lib_slab1_fine;
    }
    if (
      noOfDaysDelayed >= fineDetails?.lib_slab2_from &&
      noOfDaysDelayed <= fineDetails?.lib_slab2_to
    ) {
      return fineDetails.lib_slab2_fine;
    }
    if (
      noOfDaysDelayed >= fineDetails?.lib_slab3_from &&
      noOfDaysDelayed <= fineDetails?.lib_slab3_to
    ) {
      return fineDetails.lib_slab3_fine;
    }
    if (
      noOfDaysDelayed >= fineDetails?.lib_slab4_from &&
      noOfDaysDelayed <= fineDetails?.lib_slab4_to
    ) {
      return fineDetails.lib_slab4_fine;
    }
    if (
      noOfDaysDelayed >= fineDetails?.lib_slab5_from &&
      noOfDaysDelayed <= fineDetails?.lib_slab5_to
    ) {
      return fineDetails.lib_slab5_fine;
    }
    return 0;
  };
  var totalDelayedDays = 0;
  var totalDelayedFine = 0;
  var totalLostFine = 0;
  var totalDamageFine = 0;
  // eslint-disable-next-line
  BooksReturn?.map((data) => {
    totalDelayedFine += data.delayed_fine_amt;
    totalLostFine += data.lost_fine_amt;
    totalDamageFine += data.damaged_fine_amt;
    totalDelayedDays += GetNoOfDaysDelayed(new Date(data.issue_return_date));
  });
  const totalFine =
    totalDelayedFine + totalLostFine + totalDamageFine - waiveOffAmt;

  //Set Studentdetails
  useEffect(() => {
    if (token && InstId) {
      GetCampusData({
        variables: {
          token,
          inst_id: InstId!,
        },
      });
    }
  }, [token, InstId, GetCampusData]);
  useEffect(
    () => {
      if (bookId > 0) {
        const bookDetails = IssueBookData?.nodes?.find(
          (data) => data.id === bookId
        );
        setBookDetailsForm({
          ...bookDetailsForm,
          id: bookId,
          percentage:
            bookLostDamagedModal === BookConditionType.BOOK_DAMAGED
              ? DAMAGEBOOK_FINE
              : LOSTBOOK_FINE,
          book_id: bookDetails?.id!,
          book_title: bookDetails?.book_title!,
          book_accesion_no: bookDetails?.book_access_no!,
          book_price: bookDetails?.classification?.avg_book_rate!,
          percentage_amt:
            bookLostDamagedModal === BookConditionType.BOOK_DAMAGED
              ? (Number(DAMAGEBOOK_FINE) / 100) *
                Number(bookDetails?.classification?.avg_book_rate!)
              : bookLostDamagedModal === BookConditionType.BOOK_LOST
              ? (Number(LOSTBOOK_FINE) / 100) *
                Number(bookDetails?.classification?.avg_book_rate!)
              : 0,
        });
        setBookId(0);
      }
    },
    // eslint-disable-next-line
    [bookId, IssueBookData?.nodes]
  );
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      // eslint-disable-next-line
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  useEffect(() => {
    if (token && state.bookId) {
      GetBookIssuedData();
    }
  }, [token, GetBookIssuedData, state.bookId]);
  useEffect(() => {
    if (state.bookId) {
      setBookSelected(
        bookDropDown.find(({ value }) => value === state.bookId)!
      );
      // bookIdInputRef?.focus();
    }
  }, [state.bookId, bookDropDown]);

  const RemoveDamagedOrLostBook = (Book: bookReturnTypes) => {
    //Index of the book in BooksReturn
    const bookIndexInBookReturn = BooksReturn.indexOf(
      BooksReturn?.find((label) => label.id === Book.id)!
    );
    if (Book.issue_return_damaged) {
      totalDamageFine = totalDamageFine - Book.damaged_fine_amt;
      BooksReturn[bookIndexInBookReturn].damaged_fine_amt = 0;
      setBooksReturn(BooksReturn);
      setDamagedBooksList(damagedBooksList?.filter(({ id }) => id !== Book.id));
    }
    if (Book.issue_return_lost) {
      BooksReturn[bookIndexInBookReturn].lost_fine_amt = 0;
      setBooksReturn(BooksReturn);
      setBookLostList(bookLostList?.filter(({ id }) => id !== Book.id));
    }
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const tooltipVisibility = (id: number) => {
    if (damagedBooksList.filter((data) => data.id === id).length > 0)
      return false;
    else if (bookLostList.filter((data) => data.id === id).length > 0)
      return false;
    else return true;
  };
  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {Library_Table.BookTransaction.BookReturn.Table_Headers.map(
            (th: TableHeaderProps, index: React.Key) => {
              return (
                <TableCell key={index} className={th.className}>
                  {th.labelName}
                </TableCell>
              );
            }
          )}
          <TableCell className="book-return__tableblock--issued-books-table--actions"></TableCell>
        </TableRow>
      </TableHead>
    );
  };
  return (
    <>
      {type === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <div className="row g-0">
        <div className="col-4"></div>
        <div className="col">
          <Title>
            {toStandardCase(BorrowerType) + " "}
            {Library.BookTransaction.BookReturn.Titles.map(
              (title: BookTransactionTitleProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {title.BookReturn}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>
        <div className="col-3 book-return__date">
          <TextField
            type="date"
            id="outlined-uncontrolled"
            label="Date"
            className="book-return__details--textfield--date"
            InputProps={{
              inputProps: {
                min: { serverDate },
                max: { serverDate },
              },
            }}
            InputLabelProps={{ shrink: true }}
            value={date}
            disabled={!EDIT_TRANSACTION_DATE}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDate(e.target.value)
            }
          />

          <Label variant="present-day">{NameOfTheDay(serverDate)}</Label>
        </div>
        <div className="col-1"></div>
        <div className="configuration-settings">
          {USE_CONFIG_KEY && type === PageFor.GENERAL ? (
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          ) : null}
        </div>
      </div>

      <div
        className={
          type === PageFor.GENERAL ? "book-return" : "book-return__modal"
        }
      >
        <div className="row g-0 book-return__details">
          <div className="col-4">
            {type === PageFor.GENERAL ? (
              <Autocomplete
                classes={listClasses}
                options={searchByOptions}
                value={searchByOptions?.find(({ value }) => value === searchBy)}
                forcePopupIcon
                onChange={(e, newValue) => {
                  setSearchBy(newValue?.value!);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setSearchBy(BookReturnSearchBy.STUDENT);
                  }
                }}
                isOptionEqualToValue={(option) => option.value === searchBy}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search By"
                    InputLabelProps={{ shrink: true }}
                    autoFocus
                    fullWidth
                    classes={{ root: listTextClasses.formControlRoot }}
                  />
                )}
              />
            ) : null}
            <div className="book-return__details--imageflex">
              {searchBy === BookReturnSearchBy.BOOK_ID &&
              type === PageFor.GENERAL ? (
                <Autocomplete
                  classes={listClasses}
                  ref={bookIdRef}
                  options={bookDropDown!}
                  openOnFocus
                  forcePopupIcon
                  value={bookSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBookSelected(newValue);
                      dispatch({
                        type: payloadTypes.SET_BOOK_ID,
                        payload: {
                          bookId: (newValue as responseType)?.value,
                        },
                      });
                    } else {
                      setBookSelected(EMPTY_RESPONSETYPE_OBJECT);
                      dispatch({
                        type: payloadTypes.SET_BOOK_ID,
                        payload: {
                          bookId: 0,
                        },
                      });
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      if (bookSelected) {
                        checkboxRef?.current?.focus();
                      }
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setBookSelected(EMPTY_RESPONSETYPE_OBJECT);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Book Number"
                      InputLabelProps={{ shrink: true }}
                      autoFocus
                      fullWidth
                      classes={{ root: listTextClasses.formControlRoot }}
                    />
                  )}
                />
              ) : null}
              {instDropdown.length > 0 &&
                searchBy === BookReturnSearchBy.STUDENT && (
                  <Autocomplete
                    classes={listClasses}
                    options={instDropdown!}
                    ref={admNoRef}
                    isOptionEqualToValue={(option) => option === instSelected}
                    forcePopupIcon
                    onKeyDown={(e) => {
                      if (e.key === Keys.ENTER) {
                        // bookIdInputRef?.focus();
                      }
                    }}
                    value={instSelected}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setInstSelected(newValue);
                        dispatch({
                          type: payloadTypes.SET_INST_ID_FOR_CAMPUS,
                          payload: {
                            instIdForCampus: newValue.value,
                          },
                        });
                      } else {
                        setInstSelected(null);
                        dispatch({
                          type: payloadTypes.SET_INST_ID_FOR_CAMPUS,
                          payload: {
                            instIdForCampus: 0,
                          },
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Institutuion"
                        // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        //   if (BorrowerType === UserType.STUDENT)
                        //     setSearchAdmNo(e.target.value);
                        // }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        autoFocus
                        classes={{ root: listTextClasses.formControlRoot }}
                      />
                    )}
                  />
                )}
              {searchBy === BookReturnSearchBy.STUDENT &&
              type === PageFor.GENERAL ? (
                <Autocomplete
                  classes={listClasses}
                  options={studentAddmissionNumber}
                  ref={admNoRef}
                  value={
                    studentAddmissionNumber?.find(
                      ({ label }) => label === studentFormData?.adm_no ?? admNo
                    )! ?? null
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: (newValue as responseType)?.value,
                        },
                      });
                    } else {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === Keys.BACKSPACE) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: 0,
                        },
                      });
                    }
                  }}
                  popupIcon={
                    <img
                      src={DownArrow}
                      alt="/"
                      className="down-arrow-autocomplete"
                    />
                  }
                  openOnFocus
                  forcePopupIcon
                  freeSolo
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      onChange={(e) => setAdmNo(e.target.value)}
                      label="Admission No."
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: listTextClasses.formControlRoot }}
                    />
                  )}
                />
              ) : null}
              {searchBy === BookReturnSearchBy.BOOK_ID &&
              type === PageFor.GENERAL ? (
                <img
                  className="data-fetch-icon"
                  src={Edit}
                  alt="/"
                  onClick={() => setBookSearchModal(!bookSearchModal)}
                />
              ) : searchBy === BookReturnSearchBy.STUDENT &&
                type === PageFor.GENERAL ? (
                <img
                  className="data-fetch-icon"
                  src={Edit}
                  alt="/"
                  onClick={() => setStudentModal(!studentModal)}
                />
              ) : null}
            </div>
            <div className="book-return__details--textflex">
              <Label>Max. Issue Limit</Label>
              <Input
                id={
                  BOOKS_ISSUE_LIMIT === IssueBookData?.nodes?.length
                    ? "bg-red"
                    : "bg-green"
                }
                value={IssueBookData?.nodes?.length ?? 0}
                disabled
              />
              <span> / </span>
              <Input id="bg-red" value={BOOKS_ISSUE_LIMIT ?? 0} disabled />
            </div>
          </div>
          <div className="col book-return__details--colspan">
            {state.studentId ? (
              LibraryFormLabels.Book_Transaction.Book_Issue_Return.map(
                (label: LibraryLabelNameProps, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      <TextField
                        className="book-return__details--textfield"
                        label={label.LabelName}
                        InputLabelProps={{ shrink: true }}
                        value={
                          studentFormData && studentFormData[label.inputName]
                        }
                        disabled
                      />
                    </React.Fragment>
                  );
                }
              )
            ) : (
              <>
                <TextField
                  className="book-return__details--textfield"
                  label="Name"
                  InputLabelProps={{ shrink: true }}
                  value={
                    stdDataByBookId
                      ? stdDataByBookId.node.student_details.first_name +
                        " " +
                        stdDataByBookId.node.student_details.middle_name +
                        " " +
                        stdDataByBookId.node.student_details.last_name
                      : EMPTY_STRING
                  }
                  disabled
                />
                <TextField
                  className="book-return__details--textfield"
                  label="Admission Number"
                  InputLabelProps={{ shrink: true }}
                  value={
                    stdDataByBookId
                      ? stdDataByBookId.node.student_details.std_adm_no
                      : EMPTY_STRING
                  }
                  disabled
                />
                <TextField
                  className="book-return__details--textfield"
                  label={branchLabel}
                  InputLabelProps={{ shrink: true }}
                  value={
                    stdDataByBookId
                      ? stdDataByBookId.node.student_details.branch.branch_desc
                      : EMPTY_STRING
                  }
                  disabled
                />
                <TextField
                  className="book-return__details--textfield"
                  label={classLabel}
                  InputLabelProps={{ shrink: true }}
                  value={
                    stdDataByBookId
                      ? stdDataByBookId.node.student_details.class.class_desc
                      : EMPTY_STRING
                  }
                  disabled
                />
              </>
            )}
          </div>
          <div className="col-1 book-return__details--image">
            {imageString === EMPTY_STRING ? (
              <img src={Avatar} alt="/" />
            ) : (
              <img src={imageString} alt="/" />
            )}
          </div>
        </div>
        <Title variant="subtitle1">
          {Library.BookTransaction.Titles.map(
            (title: BookTransactionTitleProps, index: React.Key) => {
              return (
                <React.Fragment key={index}>{title.IssuedBook}</React.Fragment>
              );
            }
          )}
        </Title>
        {state.studentId && !IssueBookData?.nodes.length ? (
          <b className="nodata">
            Sorry, No Books Issued to This particular Student
          </b>
        ) : (
          <div className="book-return__tableblock">
            <TableContainer className="book-return__tableblock--issued-books-table">
              <Table stickyHeader>
                <EnhancedTableHead
                  onSelectAllClick={handleSelectAllClick}
                  numSelected={BooksReturn.length}
                  rowCount={IssueBookData?.nodes?.length!}
                />

                {state.studentId ? (
                  <TableBody>
                    {IssueBookData && state.studentId
                      ? IssueBookData?.nodes?.map((label, index) => {
                          const isItemBooksReturn =
                            BooksReturn.indexOf(
                              BooksReturn.find((data) => data.id === label.id)!
                            ) !== -1;
                          return (
                            <TableRow
                              role="checkbox"
                              aria-checked={isItemBooksReturn}
                              key={label.id}
                              selected={isItemBooksReturn}
                            >
                              <TableCell
                                padding="checkbox"
                                id="td-center"
                                className="book-return__tableblock--issued-books-table--checkbox"
                              >
                                <Checkbox
                                  checked={isItemBooksReturn}
                                  inputRef={checkboxRef!}
                                  onClick={(event) => {
                                    handleClick(event, label);
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="book-return__tableblock--issued-books-table--slno"
                              >
                                {state.studentId ? index + 1 : null}
                              </TableCell>
                              <TableCell className="book-return__tableblock--issued-books-table--accno">
                                {state.studentId ? label.book_access_no : null}
                              </TableCell>
                              <TableCell>
                                {state.studentId
                                  ? label.classification.classification_desc
                                  : null}
                              </TableCell>
                              <TableCell className="book-return__tableblock--issued-books-table--publication">
                                {state.studentId
                                  ? label.classification.publisher
                                      .publisher_name
                                  : null}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="book-return__tableblock--issued-books-table--date"
                              >
                                {state.studentId
                                  ? toStandardDate(
                                      label.issue_details.issue_date
                                    )
                                  : null}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="book-return__tableblock--issued-books-table--date"
                              >
                                {state.studentId
                                  ? toStandardDate(
                                      label.issue_details.issue_return_date
                                    )
                                  : null}
                              </TableCell>

                              <TableCell
                                id="td-center"
                                className="book-return__tableblock--issued-books-table--date"
                              >
                                {state.studentId
                                  ? GetNoOfDaysDelayed(
                                      getDueDate(
                                        label.book_last_issued_date,
                                        DELAY_DAYS_LIMIT
                                      )
                                    )
                                  : null}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className={
                                  label.issue_details.issue_return_fine ===
                                  "0.00"
                                    ? "font-green book-return__tableblock--issued-books-table--amount"
                                    : "font-red book-return__tableblock--issued-books-table--amount"
                                }
                              >
                                {state.studentId
                                  ? getFine(
                                      GetNoOfDaysDelayed(
                                        getDueDate(
                                          label.book_last_issued_date,
                                          DELAY_DAYS_LIMIT
                                        )
                                      )
                                    ) *
                                    GetNoOfDaysDelayed(
                                      getDueDate(
                                        label.book_last_issued_date,
                                        DELAY_DAYS_LIMIT
                                      )
                                    )
                                  : null}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="book-return__tableblock--issued-books-table--actions"
                              >
                                {BooksReturn.indexOf(
                                  BooksReturn.find(
                                    (data) => data.id === label.id
                                  )!
                                ) !== -1 && (
                                  <div>
                                    {tooltipVisibility(label.id) ? (
                                      <Tooltip
                                        classes={{
                                          tooltip:
                                            tooltipclasses.studentOptions,
                                        }}
                                        placement="left-start"
                                        title={
                                          <ul className="book-return__tableblock--issued-books-table--tooltip">
                                            <li
                                              onClick={() => {
                                                setBookId(label.id);
                                                setBookLostDamagedModal(
                                                  BookConditionType.BOOK_DAMAGED
                                                );
                                              }}
                                            >
                                              <Label>Book Damage</Label>
                                            </li>

                                            <li
                                              onClick={() => {
                                                setBookId(label.id);
                                                setBookLostDamagedModal(
                                                  BookConditionType.BOOK_LOST
                                                );
                                              }}
                                            >
                                              <Label>Book Lost</Label>
                                            </li>
                                          </ul>
                                        }
                                      >
                                        <img src={More} alt="/" />
                                      </Tooltip>
                                    ) : null}
                                  </div>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : null}
                  </TableBody>
                ) : (
                  <TableBody>
                    {data && data.GetLibBookIssued.edges.length
                      ? data.GetLibBookIssued.edges.map((label, index) => {
                          const isItemBooksReturn =
                            BooksReturn.indexOf(
                              BooksReturn.find(
                                (data) => data.id === label.node.id
                              )!
                            ) !== -1;
                          return (
                            <TableRow
                              role="checkbox"
                              aria-checked={isItemBooksReturn}
                              key={label.node.id}
                              selected={isItemBooksReturn}
                            >
                              <TableCell
                                padding="checkbox"
                                id="td-center"
                                className="book-return__tableblock--issued-books-table--checkbox"
                              >
                                <Checkbox
                                  checked={isItemBooksReturn}
                                  inputRef={checkboxRef!}
                                  onClick={(event) => {
                                    handleClickByBookId(event, label.node);
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="book-return__tableblock--issued-books-table--slno"
                              >
                                {state.bookId ? index + 1 : null}
                              </TableCell>
                              <TableCell className="book-return__tableblock--issued-books-table--accno">
                                {state.bookId
                                  ? label.node.book_master.book_access_no
                                  : null}
                              </TableCell>
                              <TableCell>
                                {state.bookId
                                  ? label.node.book_master.classification
                                      .classification_desc
                                  : null}
                              </TableCell>
                              <TableCell className="book-return__tableblock--issued-books-table--publication">
                                {state.bookId
                                  ? label.node.book_master.classification
                                      .publisher.publisher_name
                                  : null}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="book-return__tableblock--issued-books-table--date"
                              >
                                {state.bookId
                                  ? toStandardDate(label.node.issue_date)
                                  : null}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="book-return__tableblock--issued-books-table--date"
                              >
                                {state.bookId
                                  ? toStandardDate(label.node.issue_return_date)
                                  : null}
                              </TableCell>

                              <TableCell
                                id="td-center"
                                className="book-return__tableblock--issued-books-table--date"
                              >
                                {state.bookId
                                  ? GetNoOfDaysDelayed(
                                      getDueDate(
                                        label.node.issue_return_date,
                                        DELAY_DAYS_LIMIT
                                      )
                                    )
                                  : null}
                              </TableCell>
                              <TableCell
                                id="td-right"
                                className={
                                  label.node.issue_return_fine === "0.00"
                                    ? "font-green book-return__tableblock--issued-books-table--amount"
                                    : "font-red book-return__tableblock--issued-books-table--amount"
                                }
                              >
                                {state.bookId
                                  ? getFine(
                                      GetNoOfDaysDelayed(
                                        getDueDate(
                                          label.node.issue_return_date,
                                          DELAY_DAYS_LIMIT
                                        )
                                      )
                                    ) *
                                    GetNoOfDaysDelayed(
                                      getDueDate(
                                        label.node.issue_return_date,
                                        DELAY_DAYS_LIMIT
                                      )
                                    )
                                  : null}
                              </TableCell>
                              <TableCell
                                id="td-center"
                                className="book-return__tableblock--issued-books-table--actions"
                              >
                                {BooksReturn.indexOf(
                                  BooksReturn.find(
                                    (data) => data.id === label.node.id
                                  )!
                                ) !== -1 && (
                                  <div>
                                    {tooltipVisibility(label.node.id) ? (
                                      <Tooltip
                                        classes={{
                                          tooltip:
                                            tooltipclasses.studentOptions,
                                        }}
                                        placement="left-start"
                                        title={
                                          <ul className="book-return__tableblock--issued-books-table--tooltip">
                                            <li
                                              onClick={() => {
                                                setBookId(label.node.id);
                                                setBookLostDamagedModal(
                                                  BookConditionType.BOOK_DAMAGED
                                                );
                                              }}
                                            >
                                              <Label>Book Damage</Label>
                                            </li>

                                            <li
                                              onClick={() => {
                                                setBookId(label.node.id);
                                                setBookLostDamagedModal(
                                                  BookConditionType.BOOK_LOST
                                                );
                                              }}
                                            >
                                              <Label>Book Lost</Label>
                                            </li>
                                          </ul>
                                        }
                                      >
                                        <img src={More} alt="/" />
                                      </Tooltip>
                                    ) : null}
                                  </div>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : null}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <div className="row  book-return__tableblock--damaged-books--tableblock">
              {damagedBooksList.length > 0 &&
              (state.studentId || state.employeeId) ? (
                <div className="col-6 h100">
                  <div className="book-return__tableblock--damaged-books--frame">
                    <Title variant="subtitle1">
                      {Library.BookTransaction.BookReturn.DamagedBooks.map(
                        (
                          title: BookTransactionTitleProps,
                          index: React.Key
                        ) => {
                          return (
                            <React.Fragment key={index}>
                              {title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                    <TableContainer className="book-return__tableblock--damaged-books--table">
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            {Library_Table.BookTransaction.BookReturn.DamagedBooks.Table_Headers.map(
                              (th: TableHeaderProps, index: React.Key) => {
                                return (
                                  <TableCell key={index}>
                                    {th.labelName}
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {damagedBooksList &&
                            damagedBooksList.map((book, index: number) => {
                              return (
                                <TableRow>
                                  <TableCell
                                    id="td-center"
                                    className="book-return__tableblock--damaged-books--table--slno"
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell className="book-return__tableblock--damaged-books--table--accno">
                                    {book.issue_book_access_no}
                                  </TableCell>

                                  <TableCell>
                                    {book.issue_book_remarks}
                                  </TableCell>
                                  <TableCell
                                    id="td-right"
                                    className="book-return__tableblock--damaged-books--table--amount"
                                  >
                                    {book.damaged_fine_amt} &nbsp;
                                    <Tooltip
                                      classes={{
                                        tooltip: tooltipclasses.studentOptions,
                                      }}
                                      placement="left-start"
                                      title={
                                        <ul className="book-return__tableblock--issued-books-table--tooltip">
                                          <li
                                            onClick={() => {
                                              setBookId(book.id);
                                              setBookLostDamagedModal(
                                                BookConditionType.BOOK_DAMAGED
                                              );
                                            }}
                                          >
                                            <Label>Edit</Label>
                                          </li>
                                          <li
                                            onClick={() =>
                                              RemoveDamagedOrLostBook(book)
                                            }
                                          >
                                            <Label>Remove</Label>
                                          </li>
                                        </ul>
                                      }
                                    >
                                      <img src={More} alt="/" />
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell colSpan={3} className="total">
                              Total:
                            </TableCell>
                            <TableCell className="totalcount">
                              {totalDamageFine}
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              ) : null}
              {bookLostList.length > 0 &&
              (state.studentId || state.employeeId) ? (
                <div className="col-6 h100">
                  <div className="book-return__tableblock--damaged-books--frame ">
                    <Title variant="subtitle1">
                      {Library.BookTransaction.BookReturn.LostBooks.map(
                        (
                          title: BookTransactionTitleProps,
                          index: React.Key
                        ) => {
                          return (
                            <React.Fragment key={index}>
                              {title.Add}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Title>
                    <TableContainer className="book-return__tableblock--damaged-books--table">
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            {Library_Table.BookTransaction.BookReturn.LostBooks.Table_Headers.map(
                              (th: TableHeaderProps, index: React.Key) => {
                                return (
                                  <TableCell key={index}>
                                    {th.labelName}
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {bookLostList?.map((book, index: number) => {
                            return (
                              <TableRow>
                                <TableCell
                                  id="td-center"
                                  className="book-return__tableblock--damaged-books--table--slno"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell className="book-return__tableblock--damaged-books--table--accno">
                                  {book.issue_book_access_no}
                                </TableCell>

                                <TableCell>{book.issue_book_remarks}</TableCell>
                                <TableCell
                                  id="td-right"
                                  className="book-return__tableblock--damaged-books--table--amount"
                                >
                                  {book.lost_fine_amt} &nbsp;
                                  <Tooltip
                                    classes={{
                                      tooltip: tooltipclasses.studentOptions,
                                    }}
                                    placement="left-start"
                                    title={
                                      <ul className="book-return__tableblock--issued-books-table--tooltip">
                                        <li
                                          onClick={() => {
                                            setBookId(book.id);
                                            setBookLostDamagedModal(
                                              BookConditionType.BOOK_LOST
                                            );
                                          }}
                                        >
                                          <Label>Edit</Label>
                                        </li>
                                        <li
                                          onClick={() =>
                                            RemoveDamagedOrLostBook(book)
                                          }
                                        >
                                          <Label>Remove</Label>
                                        </li>
                                      </ul>
                                    }
                                  >
                                    <img src={More} alt="/" />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell colSpan={3} className="total">
                              Total:
                            </TableCell>
                            <TableCell className="totalcount">
                              {totalLostFine}
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row g-0">
              {BooksReturn.length > 0 && totalDelayedDays ? (
                <div className="col-3 label-grid book-return__tableblock--damaged-books--fines">
                  <Label>Fines</Label>
                  <Autocomplete
                    classes={classes}
                    options={finesDropdown}
                    openOnFocus
                    forcePopupIcon
                    onChange={(e, newValue) => {
                      setFine(newValue!);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === Keys.BACKSPACE) {
                        setFine(EMPTY_RESPONSETYPE_OBJECT);
                      }
                    }}
                    isOptionEqualToValue={(option) =>
                      option.value === fine?.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        classes={{ root: textClasses.formControlRoot }}
                      />
                    )}
                  />
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className="col">
                <div className="book-return__tableblock--damaged-books--fineblock">
                  <div>
                    <Label>Lost Fine</Label>
                    <Input
                      size="small"
                      id="fine-amount"
                      value={totalLostFine}
                      disabled
                    />
                  </div>
                  <span>+</span>
                  <div>
                    <Label>Damage Fine</Label>
                    <Input
                      size="small"
                      id="fine-amount"
                      value={totalDamageFine}
                      disabled
                    />
                  </div>
                  <span>+</span>
                  <div>
                    <Label>Delayed Fine</Label>
                    <Input
                      size="small"
                      id="fine-amount"
                      value={totalDelayedFine}
                      disabled
                    />
                  </div>
                  <span>-</span>

                  <div>
                    <Label>Waive Off</Label>
                    <Input
                      size="small"
                      id="waive-off-amount"
                      value={waiveOffAmt}
                      disabled={!enableWaiveOff}
                    />
                  </div>
                  <span>=</span>
                  <div>
                    <Label>Grand Total</Label>
                    <Input
                      size="small"
                      id="bg-green"
                      value={totalFine}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="book-return__tableblock--damaged-books--fineblock--buttons">
                  <Button
                    mode="waive-off"
                    onClick={() => setEnableWaiveOff(!enableWaiveOff)}
                    disabled={
                      totalDelayedFine + totalLostFine + totalDamageFine === 0
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="book-return__buttons">
        <div className={type === PageFor.GENERAL ? "button-left" : ""}>
          <>
            {type === PageFor.GENERAL ? (
              totalFine && ENABLE_LIB_FEE_RECEIPT ? (
                <Button
                  mode="collect-fine"
                  onClick={() => setNonDemandModal(!nonDemandModal)}
                />
              ) : (
                <>
                  <Button
                    mode="return-book"
                    onClick={() =>
                      handleBookReturnRenewal(BookReturnType.RETURN, 0)
                    }
                  />
                  <Button
                    mode="renewal-book"
                    onClick={() =>
                      handleBookReturnRenewal(BookReturnType.RENEWAL, 0)
                    }
                  />
                </>
              )
            ) : null}

            {type === PageFor.MODAL && (
              <Button
                mode="cancel"
                type="button"
                onClick={() => setModalFlag?.(false)}
              />
            )}
          </>

          {/* {type === PageFor.GENERAL ? (
            BorrowerType === UserType.EMPLOYEE ? (
              <Button
                onClick={() =>
                  navigate(`/${InstId}/library/bookreturn/student`)
                }
              >
                <img src={Student} alt="/" />
                For Student
              </Button>
            ) : (
              <Button
                onClick={() => navigate(`/${InstId}/library/bookreturn/staff`)}
              >
                <img src={Staff} alt="/" />
                For Staff
              </Button>
            )
          ) : null} */}
          {type === PageFor.GENERAL ? (
            <Button mode="clear" type="button" onClick={handleClear} />
          ) : null}
          {type === PageFor.GENERAL ? (
            <Button mode="back" onClick={() => navigate(-1)} />
          ) : null}
        </div>

        {state.studentId ? (
          <Label
            variant="LabelBold"
            onClick={() => {
              setBookHistory(!bookHistory);
            }}
          >
            History &nbsp; <img src={History} alt="/" />
          </Label>
        ) : null}
      </div>

      {/* studentmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal && BorrowerType === UserType.STUDENT}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              setStudentModal={setStudentModal}
              studentModal={studentModal}
              queryType={StudentReportType.LIB_BOOK_CLEARANCE}
              instSelected={instSelected}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={bookSearchModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <BookSearch
              pageType={PageFor.MODAL}
              bookListType={BookListType.ISSUED_BY_CLASSIFICATION}
              setClassBookModal={setBookSearchModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setBookSearchModal(!bookSearchModal)}
            />
          </div>
        </div>
      </Modal>
      {/* staffmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal && BorrowerType === UserType.EMPLOYEE}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <EmpList pageType={PageFor.MODAL} setModalFlag={setStudentModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>

      {/* book-lost */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={bookLostDamagedModal.length > 0}
        style={DamagedBooksCustomStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>
              {bookLostDamagedModal === BookConditionType.BOOK_LOST
                ? Library.BookTransaction.BookReturn.LostBooks.map(
                    (title: BookTransactionTitleProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>{title.Add}</React.Fragment>
                      );
                    }
                  )
                : Library.BookTransaction.BookReturn.DamagedBooks.map(
                    (title: BookTransactionTitleProps, index: React.Key) => {
                      return (
                        <React.Fragment key={index}>{title.Add}</React.Fragment>
                      );
                    }
                  )}
            </Title>
            <br />
            <div className="row">
              <div className="col-4">
                <TextField
                  id="outlined-uncontrolled"
                  label="Accession Id"
                  className="book-return__tableblock--damaged-books--textfield"
                  InputLabelProps={{ shrink: true }}
                  value={bookDetailsForm.book_accesion_no}
                  disabled
                />
              </div>
              <div className="col">
                <TextField
                  id="outlined-uncontrolled"
                  label="Title"
                  className="book-return__tableblock--damaged-books--textfield"
                  InputLabelProps={{ shrink: true }}
                  value={bookDetailsForm.book_title}
                  disabled
                />
              </div>
            </div>

            <div className="row g-0 book-return__tableblock--damaged-books--percentage">
              <div className="col">
                <TextField
                  id="outlined-uncontrolled"
                  label="Percentage"
                  className="book-return__tableblock--damaged-books--textfield"
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === Keys.ENTER) {
                      e.preventDefault();
                      accRef?.current?.focus();
                    }
                  }}
                  onChange={(e) => {
                    setBookDetailsForm((prevValues) => ({
                      ...prevValues,
                      percentage: Number(e.target.value),
                      percentage_amt:
                        (Number(e.target.value) / 100) *
                        Number(bookDetailsForm.book_price),
                    }));
                  }}
                  value={
                    bookDetailsForm.percentage !== 0
                      ? bookDetailsForm.percentage
                      : ""
                  }
                  InputLabelProps={{ shrink: true }}
                  placeholder="%"
                  autoFocus
                />
              </div>
              <div className="col">
                <TextField
                  id="outlined-uncontrolled"
                  label="Book Price"
                  className="book-return__tableblock--damaged-books--textfield"
                  InputLabelProps={{ shrink: true }}
                  placeholder="%"
                  value={bookDetailsForm.book_price}
                  disabled
                />
              </div>

              <div className="col">
                <TextField
                  type="number"
                  id="outlined-uncontrolled"
                  label="Percentage Amount"
                  className="book-return__tableblock--damaged-books--textfield"
                  onChange={(e) => {
                    setBookDetailsForm((prevValues) => ({
                      ...prevValues,
                      percentage_amt: Number(e.target.value),
                    }));
                  }}
                  value={bookDetailsForm.percentage_amt}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </div>
            </div>

            <div className="row g-0">
              <div className="col">
                <div className="book-return__tableblock--damaged-books--label-grid">
                  <Label>Remarks</Label>
                  <TextArea
                    onChange={(e) => {
                      setBookDetailsForm((prevValues) => ({
                        ...prevValues,
                        remarks: e.target.value,
                      }));
                    }}
                    value={bookDetailsForm.remarks}
                    textAreaRef={accRef!}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === Keys.ENTER) {
                        e.preventDefault();
                        saveRef?.current?.focus();
                      }
                    }}
                    rows={3}
                  />
                </div>
              </div>
            </div>
            <Button
              mode="save"
              buttonref={saveRef!}
              onClick={() => {
                setBookLostDamagedModal("");
                handleLostDamagedBooks();
              }}
            />
            <Button
              mode="cancel"
              onClick={() => {
                setBookDetailsForm((rest) => ({
                  ...rest,
                  id: 0,
                  remarks: "",
                  percentage: 0,
                  book_price: 0,
                  percentage_amt: 0,
                }));
                setBookLostDamagedModal("");
              }}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                setBookDetailsForm((rest) => ({
                  ...rest,
                  id: 0,
                  remarks: "",
                  percentage: 0,
                  book_price: 0,
                  percentage_amt: 0,
                }));
                setBookLostDamagedModal("");
              }}
            />
          </div>
        </div>
      </Modal>

      {/* NondemandFeePayable */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={nonDemandModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <CollectLibraryFine
              setModalFlag={setNonDemandModal}
              hanldeBookReturn={handleBookReturnRenewal}
              totalFineAmt={totalDamageFine + totalLostFine + totalDelayedFine}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setNonDemandModal(!nonDemandModal)}
            />
          </div>
        </div>
      </Modal>
      {/* book-history modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={bookHistory}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <TransactionHistory issued_books={IssueBookData!} />
            <Button
              mode="cancel"
              onClick={() => setBookHistory(!bookHistory)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setBookHistory(!bookHistory)}
            />
          </div>
        </div>
      </Modal>
      {/* waiveoff-modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={enableWaiveOff}
        style={WaiveOffModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Waive Off</Title>
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setWaiveOffAmt(Number(e.target.value));
              }}
              value={waiveOffAmt}
            />
            <span>
              {waiveOffAmt >
              totalDelayedFine + totalLostFine + totalDamageFine ? (
                <b className="nodata">
                  Waive-Off Amount Cannot Be Greator Than Total Fine
                </b>
              ) : null}
            </span>
            <Button
              mode="save"
              onClick={() => {
                setWaiveOffAmt(waiveOffAmt);
                setEnableWaiveOff(!enableWaiveOff);
              }}
            />
            <Button
              mode="cancel"
              onClick={() => {
                setWaiveOffAmt(0);
                setEnableWaiveOff(!enableWaiveOff);
              }}
            />
          </div>

          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => {
                setWaiveOffAmt(0);
                setEnableWaiveOff(!enableWaiveOff);
              }}
            />
          </div>
        </div>
      </Modal>
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.LIB_BOOK_RETURN_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal
        flag={BookRemarksLoading ?? BookReturnLoading ?? BookRenewalLoading}
      />
    </>
  );
};

export default Index;
