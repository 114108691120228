import {
  Autocomplete,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import { TableHeaderProps } from "../../../../Types/Tables";
import { StudentTitles } from "../../../../Types/Titles";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import {
  Operation,
  StudentAcctReportType,
  StudentSearchField,
  StudentStatus,
  StudentsWhoLeftInstitutionTypes,
} from "../../../../utils/Enum.types";
import MastersHome from "../../../Master/Home/Index";
import AccountsHome from "../../Home/Index";
import Modal from "react-modal";
import { EditModalCustomStyles } from "../../../../styles/ModalStyles";
import {
  formatter,
  getModifiedScrollHeight,
} from "../../../../utils/UtilFunctions";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../../stories/Input/Input";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { Keys } from "../../../../utils/Enum.keys";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { msgType, responseType } from "../../../../utils/Form.types";
import useDropdownData from "../../../../customhooks/useDropdownData";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import { useMutation } from "@apollo/client";
import { DeleteAcctStdDemand } from "../../queries/demands/mutation";
import useToken from "../../../../customhooks/useToken";
import MessageModal from "../../../../pages/MessageModal";
import LoadingModal from "../../../../pages/LoadingModal";
import UseStudentsbyDemandAmount, {
  StudentDemandEdges,
} from "../../hooks/useStudentsbyDemandAmount";
import useMasterTableJson from "../../../../json/useMasterTableJson";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";

const { Students } = require("../../../../json/title.json");

const enum TableHeaderTypes {
  Concession = "Concession",
  Demand = "Demand",
}

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

interface Props {
  pageType: StudentsWhoLeftInstitutionTypes;
}

const SearchFields = [
  StudentSearchField.ADMISSION_NUMBER,
  StudentSearchField.NAME,
];
const DeleteStudent = ({ pageType }: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const navigate = useNavigate();
  const { token } = useToken();
  const { format } = formatter;
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchData, setSearchData] = useState("");
  const { InstId } = useParams();
  const { Masters_Table } = useMasterTableJson();

  const [searchType, setSearchType] = useState(StudentSearchField.NAME);
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [studentsList, setStudentsList] = useState<StudentDemandEdges[]>([]);

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [searchAdm, setSearchAdm] = useState("");

  const [departmentSelected, setDepartmentSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [branchSelected, setBranchSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [classSelected, setClassSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [semesterSelected, setSemesterSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [sectionSelected, setSectionSelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const [categorySelected, setCategorySelected] = useState<responseType>(
    EMPTY_RESPONSETYPE_OBJECT
  );
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected.value,
    branchSelected.value,
    classSelected.value,
    semesterSelected.value
  );

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const departmentRef = useRef<HTMLSelectElement>(null);
  const departmentInputRef = departmentRef?.current?.childNodes[0]
    ?.childNodes[1]?.childNodes[0] as HTMLInputElement;
  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInputRef = branchRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const semRef = useRef<HTMLSelectElement>(null);
  const semInputRef = semRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const sectionRef = useRef<HTMLSelectElement>(null);
  const sectionInputRef = sectionRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const { user_details } = useLoggedInUserDetails();

  const { categoryDropDown } = useInstMasterDataByInstId();

  //mutations
  const [DeleteRaisedFeeDemand, { loading }] = useMutation(
    DeleteAcctStdDemand,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleUpdate = () => {
    DeleteRaisedFeeDemand({
      variables: {
        token,
        student_ids: selected,
        inst_id: InstId!,
        user_details,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "SucessFully Updated data",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };

  const { students } = UseStudentsbyDemandAmount(
    departmentSelected.value,
    branchSelected.value,
    classSelected.value,
    semesterSelected.value,
    sectionSelected.value,
    categorySelected.value,
    searchData,
    ROWS_PER_PAGE,
    StudentAcctReportType.DEMAND_RAISED
  );
  const totalCount = students?.data?.GetAcctStdDemand?.totalCount;

  //select All
  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {Masters_Table.Students.DeleteStudentWhoLeftInstitution.Table_Headers.filter(
            (th: TableHeaderProps) =>
              (th.labelName !== TableHeaderTypes.Concession &&
                pageType === StudentsWhoLeftInstitutionTypes.DELETE_DEMAND) ||
              (th.labelName !== TableHeaderTypes.Demand &&
                pageType === StudentsWhoLeftInstitutionTypes.DELETE_STUDENT)
          ).map((th: TableHeaderProps, index: React.Key) => {
            return (
              <TableCell key={index} className={th.className}>
                {th.labelName}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = students.data?.GetAcctStdDemand.edges.map((n) =>
        n.node.mst_student.id.toString()
      );
      setSelected(newSelecteds!);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop + 1;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        students?.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctStdDemand.edges;
            const pageInfo = fetchMoreResult.GetAcctStdDemand.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcctStdDemand.edges.filter(
              ({ node }) => {
                const existingIds = prevResult.GetAcctStdDemand.edges.map(
                  ({ node }) => node.mst_student.id
                );
                return existingIds.includes(node.mst_student.id);
              }
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctStdDemand: {
                edges: [...studentsList, ...newEdges],
                pageInfo,
                totalCount: students?.data?.GetAcctStdDemand.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(
    () => {
      if (students?.data && !students?.loading) {
        const newData = students?.data?.GetAcctStdDemand.edges.map((edge) => {
          return {
            ...edge,
            node: {
              ...edge.node,
              isChecked: true,
              // set default value of isChecked to true
            },
          };
        });

        if (endCursor) {
          // If we have already fetched some data, we need to check if there
          // are any duplicates in the new data, and update their isChecked
          // property based on the existing data.
          // const filteredStudents = students.filter(
          //   (student) => !student.node.isChecked
          // );

          const updatedNewData = newData.map((newStudent) => {
            const filteredStudent = studentsList.find(
              (student) =>
                student.node.mst_student.id === newStudent.node.mst_student.id
            );
            if (filteredStudent) {
              return {
                ...newStudent,
                node: {
                  ...newStudent.node,
                  // isChecked: filteredStudent.node.isChecked,
                },
              };
            }
            return newStudent;
          });
          setStudentsList(updatedNewData);
        } else {
          setStudentsList(newData);
        }
        setEndCursor(students?.data.GetAcctStdDemand.pageInfo.endCursor);
      }
    },
    // eslint-disable-next-line
    [students?.data, students?.loading, students?.fetchMore]
  );

  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel
  } = useInstLabels();

  return (
    <>
      {pageType === StudentsWhoLeftInstitutionTypes.DELETE_STUDENT ? (
        <MastersHome DashBoardRequired={false} />
      ) : (
        <AccountsHome DashBoardRequired={false} />
      )}
      <Title>
        {Students.Titles.map((title: StudentTitles, index: React.Key) => {
          return (
            <React.Fragment key={index}>
              {pageType === StudentsWhoLeftInstitutionTypes.DELETE_DEMAND
                ? title.Delete_Demand
                : title.Delete_Student}
            </React.Fragment>
          );
        })}
      </Title>
      <div className="delete-student">
        <div className="row g-0 delete-student__select-options">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search..."
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (searchType === StudentSearchField.ADMISSION_NUMBER) {
                  setSearchAdm(e.target.value);
                  setSearchData("");
                } else {
                  setSearchData(e.target.value);
                  setSearchAdm("");
                }
              }}
              value={searchData !== "" ? searchData : searchAdm}
            />
          </div>
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={SearchFields}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  departmentInputRef?.focus();
                }
              }}
              onChange={(e, newValue) => setSearchType(newValue!)}
              value={searchType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search By"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={departmentDropDown}
                ref={departmentRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected) {
                      branchInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={branchDropDown}
                ref={branchRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      classInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue);
                  } else {
                    setBranchSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={classDropDown}
                ref={classRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      semInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue);
                  } else {
                    setClassSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={semesterDropDown}
                ref={semRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      sectionInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue);
                  } else {
                    setSemesterSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={sectionDropDown}
                ref={sectionRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (sectionSelected) {
                      categoryInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue);
                  } else {
                    setSectionSelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                  setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CATEGORY_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={categoryDropDown}
                ref={categoryRef!}
                openOnFocus
                value={categorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategorySelected(newValue);
                  } else {
                    setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setCategorySelected(EMPTY_RESPONSETYPE_OBJECT);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={categoryLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="delete-student__tableblock">
          <TableContainer
            className="delete-student__table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={totalCount ? totalCount : 0}
              />
              <TableBody>
                {studentsList?.map((data, index) => {
                  const isItemSelected = isSelected(
                    data.node.mst_student.id.toString()
                  );
                  return (
                    <TableRow key={index}>
                      <TableCell
                        padding="checkbox"
                        id="td-center"
                        className="delete-student__table--slno"
                      >
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) =>
                            handleClick(
                              event,
                              data.node.mst_student.id.toString()
                            )
                          }
                        />
                      </TableCell>
                      <TableCell
                        id="td-center"
                        className="delete-student__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="delete-student__table--admno">
                        {data.node.mst_student.std_adm_no}
                      </TableCell>
                      <TableCell className="delete-student__table--admno">
                        {data.node.mst_student.std_reg_no}
                      </TableCell>
                      <TableCell>
                        {data.node.mst_student.first_name +
                          " " +
                          data.node.mst_student.middle_name +
                          " " +
                          data.node.mst_student.last_name}
                      </TableCell>
                      <TableCell
                        className={
                          data.node.mst_student.std_status === StudentStatus.TC
                            ? "std_status delete-student__table--desc"
                            : "delete-student__table--desc"
                        }
                        id="td-center"
                      >
                        {data.node.mst_student.std_status}
                      </TableCell>
                      <TableCell
                        className="delete-student__table--amount"
                        id="td-right"
                      >
                        {format(data.node.std_demand_ob)}
                      </TableCell>
                      <TableCell
                        className="delete-student__table--amount"
                        id="td-right"
                      >
                        {format(data.node.std_demand_concession)}
                      </TableCell>
                      <TableCell
                        className="delete-student__table--amount"
                        id="td-right"
                      >
                        {format(data.node.std_demand_amt)}
                      </TableCell>
                      <TableCell
                        className="delete-student__table--amount"
                        id="td-right"
                      >
                        {format(data.node.std_demand_received)}
                      </TableCell>
                      <TableCell
                        className={
                          data.node.std_demand_bal === 0
                            ? "font-green delete-student__table--amount "
                            : "font-red delete-student__table--amount"
                        }
                        id="td-right"
                      >
                        {format(data.node.std_demand_bal)}
                      </TableCell>
                      <TableCell
                        className="delete-student__table--amount"
                        id="td-right"
                      >
                        {format(data.node.std_demand_refunds)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="row g-0 ">
        <div className="col button-left">
          <Button mode="delete" onClick={() => setDeleteModal(!deleteModal)} />
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
        <div className="col delete-student__footer">
          <div className="student-total-count">
            Total No. of Students : &nbsp;<b>{totalCount}</b>
          </div>
          <Label variant="LabelPrimary">Selected :</Label>
          <div className="delete-student__footer--total-count">
            {selected.length}
          </div>
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={deleteModal}
        ariaHideApp={false}
        style={EditModalCustomStyles}
      >
        <Title variant="subtitle1">Delete Student</Title>
        <Label>
          Are You Sure You Want to Delete This Receipt, Can’t Undo Changes
        </Label>
        <div>
          <Button mode="yes" onClick={handleUpdate} />
          <Button mode="cancel" onClick={() => setDeleteModal(!deleteModal)} />
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />

      <LoadingModal flag={loading} />
    </>
  );
};

export default DeleteStudent;
