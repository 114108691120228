import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pointer from "../../../../images/Pointer.svg";
import Input from "../../../../stories/Input/Input";
import { EMPTY_STRING } from "../../../../utils/constants";
import { ExcelAcctQueryType, ExcelAdmQueryType } from "./useExportMutation";
import { ExportProps } from "./Index";
import { useNavigate, useParams } from "react-router-dom";
import { ExportModuleType, LicenseTypes } from "../../../../utils/Enum.types";

const ReportsList = ({ reportType }: ExportProps) => {
  const [searchText, setSearchText] = useState("");
  const { InstId } = useParams();

  const navigate = useNavigate();
  const ReportList = [
    {
      label: "Student List",
      moduleType: ExportModuleType.ADMISSIONS,
      value: ExcelAdmQueryType.ADM_STUDENT_LIST,
      navigate: `/${InstId}/admissions/reports/instlevel/student/adm_list`,
    },
    {
      label: "Religion Wise Student List",
      moduleType: ExportModuleType.ADMISSIONS,
      value: ExcelAdmQueryType.RELIGION_STUDENT_LIST,
      navigate: `/${InstId}/admissions/reports/instlevel/student/religion`,
    },
    {
      label: "Age Student List",
      moduleType: ExportModuleType.ADMISSIONS,
      value: ExcelAdmQueryType.AGE_STUDENT_LIST,
      navigate: `/${InstId}/admissions/reports/instlevel/student/age`,
    },
    {
      label: "Caste Wise List",
      moduleType: ExportModuleType.ADMISSIONS,
      value: ExcelAdmQueryType.CASTE_STUDENT_LIST,
      navigate: `/${InstId}/admissions/reports/instlevel/student/caste`,
    },

    {
      label: "SC / ST List",
      moduleType: ExportModuleType.ADMISSIONS,
      value: ExcelAdmQueryType.SC_ST_STUDENT_LIST,
      navigate: `/${InstId}/admissions/reports/instlevel/student/sc_st`,
    },

    //Accounts

    {
      label: "Fee Balance Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: ExcelAcctQueryType.ACCT_STD_FEE_BALANCE_REPORT,
      navigate: `/${InstId}/accounts/reports/fee/balance`,
    },
    {
      label: "Fee Completely Paid Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: ExcelAcctQueryType.ACCT_STD_FEE_COMPLETELY_PAID_REPORT,
      navigate: `/${InstId}/accounts/reports/fee/completely_paid`,
    },
    {
      label: "Fee Partially Paid Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: ExcelAcctQueryType.ACCT_STD_FEE_PARTIALLY_PAID_REPORT,
      navigate: `/${InstId}/accounts/reports/fee/partially_paid`,
    },
    {
      label: "Fee Not Paid Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: ExcelAcctQueryType.ACCT_STD_FEE_NOT_PAID_REPORT,
      navigate: `/${InstId}/accounts/reports/fee/not_paid`,
    },
    {
      label: "Fee by Agent Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: ExcelAcctQueryType.ACCT_STD_FEE_BY_AGENT_REPORT,
      navigate: `/${InstId}/accounts/reports/fee/by_agent`,
    },
    {
      label: "Deposit Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: ExcelAcctQueryType.ACCT_STD_DEPOSIT_REPORT,
      navigate: `/${InstId}/accounts/reports/deposit`,
    },
    {
      label: "Deposit Balance Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: ExcelAcctQueryType.ACCT_STD_DEPOSIT_BALANCE_REPORT,
      navigate: `/${InstId}/accounts/reports/deposit/balance`,
    },
    {
      label: "Refund Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: ExcelAcctQueryType.ACCT_STD_REFUND_REPORT,
      navigate: `/${InstId}/accounts/reports/refund`,
    },
    {
      label: "Concession Report",
      moduleType: ExportModuleType.ACCOUNTS,
      value: ExcelAcctQueryType.ACCT_STD_CONCESSION_REPORT,
      navigate: `/${InstId}/accounts/reports/concession`,
    },
  ];

  const moduleWiseFilter = ReportList.filter((rep) =>
    window.location.pathname.includes(rep.moduleType)
  );

  return (
    <>
      <TableContainer className="inst-level-report__lt--table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Report Types</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="fee-receipt-book__typesblock--table--input">
                <Input
                  size="small"
                  id="search"
                  placeholder="Search..."
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </TableCell>
            </TableRow>
            {moduleWiseFilter
              .filter(
                (text) =>
                  searchText
                    .toLocaleLowerCase()
                    .includes(text.value.toLocaleLowerCase()) ||
                  searchText === EMPTY_STRING
              )
              .map((data) => {
                return (
                  <TableRow
                    key={data.value}
                    className={
                      reportType === data.value ? "selected-row" : EMPTY_STRING
                    }
                    selected={reportType === data.value}
                  >
                    <TableCell
                      onClick={() => {
                        navigate(data.navigate);
                      }}
                      className="bank-book__table--ledger"
                    >
                      {reportType === data.value ? (
                        <img src={Pointer} alt="/" className="pointer-icon" />
                      ) : (
                        EMPTY_STRING
                      )}
                      {data.label}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReportsList;
