import React, { useState } from "react";
import Input from "../../../stories/Input/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import Compare from "../../../images/Compare.svg";
import { Button } from "../../../stories/Button/Button";
import Avatar from "../../../images/Avatar.svg";
import { Operation, UserType } from "../../../utils/Enum.types";
import { Title } from "../../../stories/Title/Title";
import useLogsTableJson from "../json/useLogsTableJson";
import { TableHeaderProps } from "../../../Types/Tables";

import { VoucherQueryTypes } from "../../Accounts/common/Enum.types";
import { EMPTY_STRING } from "../../../utils/constants";
import {
  toInputStandardDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import { Titles } from "../ModuleWiseDetails/Accounts";
import useAcctVoucherDetailsForLogs from "../../Accounts/hooks/useAcctVoucherDetailsForLogs";
interface Props {
  operation: Operation;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  userid: number;
  endDate: string;
  startDate: string;
  empName: string;
  titleName: string;
}
const FeeReceiptDetails = ({
  operation,
  setModal,
  userid,
  endDate,
  startDate,
  empName,
  titleName,
}: Props) => {
  const { Logs_Table } = useLogsTableJson();
  const [name, setname] = useState("");

  let queryType;
  switch (titleName) {
    case Titles.Student_Receipt_entries:
      queryType =
        operation === Operation.CREATE
          ? VoucherQueryTypes.ADD_STD_RECEIPT_USER_LOG
          : VoucherQueryTypes.EDIT_STD_RECEIPT_USER_LOG;
      break;
    case Titles.Refund_Fee:
      queryType =
        operation === Operation.CREATE
          ? VoucherQueryTypes.ADD_STD_REFUND_USER_LOG
          : VoucherQueryTypes.EDIT_STD_REFUND_USER_LOG;
      break;

    default:
      queryType =
        operation === Operation.CREATE
          ? VoucherQueryTypes.ADD_STD_RECEIPT_USER_LOG
          : VoucherQueryTypes.EDIT_STD_RECEIPT_USER_LOG;
      break;
  }

  const { VoucherDetails } = useAcctVoucherDetailsForLogs(
    200,
    endDate,
    startDate,
    queryType,
    EMPTY_STRING,
    false,
    EMPTY_STRING,
    EMPTY_STRING,
    EMPTY_STRING,
    EMPTY_STRING,
    EMPTY_STRING,
    0,
    UserType.EMPLOYEE,
    userid
  );

  return (
    <>
      <Title>
        {operation === Operation.UPDATE
          ? `Edited ${titleName} Details`
          : operation === Operation.DELETE
          ? `Deleted ${titleName} Details`
          : operation === Operation.CREATE
          ? `Added ${titleName} Details`
          : `Added ${titleName} Details`}
      </Title>
      <div className="emp-logs__filtered-data">
        <div className="emp-logs__filtered-data--select row g-0">
          <div className="col-2">
            <Input
              id="search"
              placeholder="Search..."
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
          </div>

          <div className="col-4">
            <div className="emp-logs__filtered-data--select--flex">
              <TextField
                value={toInputStandardDate(startDate)}
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                className="emp-logs__filtered-data--textfield"
              />
              <img src={Compare} alt="" />
              <TextField
                value={endDate}
                label="End Date"
                InputLabelProps={{ shrink: true }}
                className="emp-logs__filtered-data--textfield"
              />
            </div>
          </div>
          <div className="col"></div>
          <div className="col-3">
            <div className="emp-logs__filtered-data--profile">
              <img src={Avatar} alt="" />
              <div className="emp-logs__filtered-data--profile--details">
                <span>{empName}</span>
                {/* <b>Manager</b> */}
              </div>
            </div>
          </div>
        </div>
        <div className="emp-logs__filtered-data--tableblock">
          <TableContainer className="emp-logs__filtered-data--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Logs_Table.FeeReceiptDetails.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {VoucherDetails.data?.GetAcctVouchers.edges.map(
                  (res, index) => {
                    const utcTimestamp = new Date(res.node.created_at);
                    const utcTimestampUpdated = new Date(res.node.updated_at);
                    return (
                      <TableRow>
                        <TableCell
                          className="emp-logs__filtered-data--table--slno"
                          id="td-center">
                          {index + 1}
                        </TableCell>
                        <TableCell className="emp-logs__filtered-data--table--number">
                          {res.node.v_no}
                        </TableCell>
                        <TableCell
                          className="emp-logs__filtered-data--table--date"
                          id="td-center">
                          {toStandardDate(res.node.v_date)}
                        </TableCell>
                        <TableCell className="emp-logs__filtered-data--table--number">
                          {res.node.mst_student.std_adm_no}
                        </TableCell>
                        <TableCell className="emp-logs__filtered-data--table--number">
                          {res.node.mst_student.std_reg_no}
                        </TableCell>
                        <TableCell>
                          {res.node.mst_student.first_name +
                            " " +
                            res.node.mst_student.middle_name +
                            " " +
                            res.node.mst_student.last_name}
                        </TableCell>
                        <TableCell className="emp-logs__filtered-data--table--desc">
                          {res.node.mst_student.branch.branch_desc}
                        </TableCell>
                        <TableCell className="emp-logs__filtered-data--table--desc">
                          {res.node.mst_student.class.class_desc}
                        </TableCell>
                        <TableCell
                          className="emp-logs__filtered-data--table--amount"
                          id="td-right">
                          {res.node.v_std_amt_total}
                        </TableCell>
                        <TableCell>
                          {res.node.v_transcation_cash_or_bank === "C"
                            ? "CASH"
                            : "B"
                            ? "BANK"
                            : EMPTY_STRING}
                        </TableCell>
                        <TableCell
                          className="emp-logs__filtered-data--table--date"
                          id="td-center">
                          {operation === Operation.CREATE
                            ? utcTimestamp.toLocaleString()
                            : utcTimestampUpdated.toLocaleString()}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
    </>
  );
};

export default FeeReceiptDetails;
