import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  GetChannelMessagesByData,
  GetChannelMessagesByVars,
  GetMessageData,
  GetMessageDataVariables,
  MessageEdge,
} from "../../../../Channel/Types";
import {
  GetChannelMessages,
  GetMessageDataById,
} from "../../../../../queries/chatapplication/list";
import { EMPTY_STRING, FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../../../utils/constants";
import dayjs from "dayjs";
import { Chip, Divider } from "@mui/material";
import { AppContext } from "../../../../../context/context";
import { MessageData } from "../../../../Channel/MessageList";
import Avatar from "../../../../../images/Avatar.svg";
import { Label } from "../../../../../stories/Label/Label";
import { getFileName } from "../../../../../utils/UtilFunctions";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import {
  Direction,
  MsgChannelType,
  SortBy,
} from "../../../../../utils/Enum.types";
import axios from "axios";
import useChannelDetailByNodeId from "../Chat/customHooks/useChannelDetailByNodeId";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";

interface Props {
  channelId: number;
}
const AssignmentsChatView = ({ channelId }: Props) => {
  const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [allMessages, setAllMessages] = useState<MessageEdge[]>([]);
  const [downLoadUrl, setDownloadUrl] = useState<MessageData[]>([]);
  const [userList, setUserList] = useState<Map<number, string>>(new Map());
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { InstId } = useParams();
  const { channelData } = useChannelDetailByNodeId(channelId);
  const messageListRef = useRef<HTMLUListElement>(null);
  const { InstFormData } = useInstDetails(1);
  const [GetMessageUser, { data: SenderData }] = useLazyQuery<
    GetMessageData,
    GetMessageDataVariables
  >(GetMessageDataById, {
    variables: {
      token,
      ids: allMessages.map((res) => res.node.sent_user_id),
    },
  });

  const byDate: { [key: string]: MessageEdge[] } | undefined =
    allMessages &&
    allMessages?.reduce(
      (obj: { [key: string]: MessageEdge[] }, item: MessageEdge) => {
        const date = new Date(item?.node?.created_at);
        const dateString = dayjs(date).format("DD/MM/YY");
        if (obj[dateString]) {
          obj[dateString].push(item);
          return obj;
        }

        obj[dateString] = [{ ...item }];
        return obj;
      },
      {}
    );
  const parseMessageContent = (content: string): string => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };
  const [GetMessages, { data: MessagesData, fetchMore, loading }] =
    useLazyQuery<GetChannelMessagesByData, GetChannelMessagesByVars>(
      GetChannelMessages
    );
  const getDownloadUrl = (messageId: number, mediaContent: string) => {
    const downloadBaseUrl = `https://ckhuef9sg0.execute-api.ap-south-1.amazonaws.com/dev/downloadObject?file_name=${mediaContent}&access_type=${process.env.React_App_access_type}`;
    axios
      .post(downloadBaseUrl, null, {
        headers: {
          "Content-Type":
            "application/x-www-form-urlencoded; charset=UTF-8;application/json",
        },
      })
      .then((response) => {
        axios.get(response.data, { responseType: "blob" }).then(() => {
          setDownloadUrl((prevDownloadUrl) =>
            prevDownloadUrl.map((messageData) => {
              if (getFileName(mediaContent) === messageData.downloadKey) {
                return { ...messageData, url: response.data };
              } else return messageData;
            })
          );
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (channelId && token) {
      GetMessages({
        variables: {
          token,
          inst_id: InstId ? InstId : state.InstId.toString(),
          input: {
            query_type: MsgChannelType.MSGS_BY_CHANNEL_ID,
            channel_id: channelId,
          },
          direction: Direction.ASC,
          last: 10,
          after: null,
          sortBy: SortBy.CREATED_AT,
          mediaContent: EMPTY_STRING,
        },
      }).then(({ data }) => {
        if (data) {
          setAllMessages(data.GetChannelMessages.edges);
        }
      });
    } // eslint-disable-next-line
  }, [channelId, token, MessagesData, GetMessages]);
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    if (scrollTop === 0 && !loading && hasPreviousPage) {
      fetchMore({
        variables: {
          last: FETCH_MORE_DATA,
          before: startCursor,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;

          const newEdges = fetchMoreResult.GetChannelMessages.edges;
          const pageInfo = fetchMoreResult.GetChannelMessages.pageInfo;
          setStartCursor(pageInfo.startCursor);
          setHasPreviousPage(pageInfo.hasPreviousPage);

          const duplicateCheck = prevResult.GetChannelMessages.edges.filter(
            ({ node: { id } }) =>
              newEdges.findIndex(({ node: { id: newId } }) => newId === id) !==
              -1
          );

          if (duplicateCheck.length > 0) return prevResult;

          return {
            GetChannelMessages: {
              edges: [...newEdges, ...allMessages],
              pageInfo,
              totalCount: MessagesData?.GetChannelMessages.totalCount!,
            },
          };
        },
      });
    }
  };
  const handleFileDownload = (url: string) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.click();
    }
  };
  useEffect(() => {
    const messageDataArray: MessageData[] = allMessages
      .filter((mess) => mess.node.msg_media_content !== "")
      .flatMap((message) => {
        const mediaContentArray = message.node.msg_media_content.split(",");
        if (mediaContentArray.length > 1) {
          return mediaContentArray.map((content, index) => {
            const mediaContent = `${InstFormData.inst_name}/channels/${
              message.node.msg_channel_id
            }/${message.node.id}_${index}.${
              content.split(".")[content.split(".").length - 1]
            }`;

            return {
              id: message.node.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.node.created_at,

              sent_user_id: message.node.sent_user_id,
              downloadKey: getFileName(mediaContent),
            };
          });
        } else {
          return mediaContentArray.map((content) => {
            const mediaContent = `${InstFormData.inst_name}/channels/${
              message.node.msg_channel_id
            }/${message.node.id}.${
              content.split(".")[content.split(".").length - 1]
            }`;

            return {
              id: message.node.id,
              mediaContent: mediaContent,
              url: "",
              createdTime: message.node.created_at,

              sent_user_id: message.node.sent_user_id,
              downloadKey: getFileName(mediaContent),
            };
          });
        }
      });
    setDownloadUrl(messageDataArray);
    const fetchDownloadUrls = () => {
      messageDataArray.forEach((message) => {
        const mediaContent = message.mediaContent;
        getDownloadUrl(message.id, mediaContent);
      });
    };

    // if (messageDataArray.some((message) => message.mediaContent !== ""))
    fetchDownloadUrls();
  }, [allMessages]);
  useEffect(() => {
    if (channelId && token) {
      GetMessageUser().then(({ data }) => {
        if (data) {
          setUserList(
            new Map(
              data.nodes.map((res) => {
                if (res.first_name)
                  return [
                    res.id,
                    `${
                      res.first_name +
                      " " +
                      res.middle_name +
                      " " +
                      res.last_name
                    }`,
                  ];
                else
                  return [
                    res.id,
                    `${
                      res.emp_first_name +
                      " " +
                      res.emp_middle_name +
                      " " +
                      res.emp_last_name
                    }`,
                  ];
              })
            )
          );
        }
      });
    }
  }, [channelId, token, SenderData, GetMessageUser]);
  return (
    <div className="h-100">
      <div className="assignments__view-details--data-block">
        <div className="assignments__view-details--data-block--header row g-0">
          <div className="col-5">
            <span className="assignments__view-details--channel-name">
              {channelData ? channelData.node.channel_name : ""}
            </span>
          </div>
          {/* <div className="col-2">
                  <Input id="search" placeholder="Search..." />
                </div> */}
          {/* <div className="col assignments__view-details--flex">
                  <img src={Compare} alt="" />
                  <TextField
                    className="assignments__view-details--textfield"
                    label="Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                  />
                  <span className="assignments__view-details--total">
                    0 Memebers
                  </span>
                </div> */}
        </div>
        <div className="assignments__view-details--body">
          <div
            className="row g-0 justify-content-center"
            onScroll={handleScroll}
          >
            <div className="col">
              <ul ref={messageListRef}>
                <div />
                <div
                  className="channel__message-block--messages-list--messages"
                  onScroll={handleScroll}
                >
                  <ul ref={messageListRef}>
                    {Object.entries(byDate)?.map(([date, messages]) => (
                      <React.Fragment key={date}>
                        <Divider>
                          <Chip label={date} />
                        </Divider>
                        {messages.map((message, index) => {
                          const parsedContent = parseMessageContent(
                            message.node.msg_content
                          );
                          const parsedContentHeader = parseMessageContent(
                            message.node.msg_header
                          );
                          const isImage =
                            message.node.msg_media_content.includes(".png") ||
                            message.node.msg_media_content.includes(".jpeg");
                          const isPdf =
                            message.node.msg_media_content.includes(".pdf");
                          const isExcel =
                            message.node.msg_media_content.includes(".xls") ||
                            message.node.msg_media_content.includes(".xlsx");
                          const isVideo =
                            message.node.msg_media_content.includes(".mp4");
                          const id = state.claims?.EMPLOYEE
                            ? state.empLoginId
                            : state.studentId;
                          const isSentUser = id === message.node.sent_user_id;

                          const combinedById = downLoadUrl.reduce(
                            (result, item) => {
                              if (!result[item.id]) {
                                result[item.id] = [item];
                              } else {
                                result[item.id].push(item);
                              }
                              return result;
                            },
                            {} as { [id: number]: MessageData[] }
                          );

                          return (
                            <li
                              key={index}
                              className={`channel__message-block--messages-list--messages--list${
                                isSentUser ? "--sender" : ""
                              }--li`}
                            >
                              <div
                                className={`channel__message-block--messages-list--messages--list${
                                  isSentUser ? "--sender" : ""
                                }`}
                              >
                                {!isSentUser && (
                                  <img
                                    src={Avatar}
                                    alt="/"
                                    className="channel__message-block--messages-list--image"
                                  />
                                )}
                                <div>
                                  {!isSentUser && (
                                    <Label variant="LabelBold">
                                      {userList.get(message.node.sent_user_id)}
                                    </Label>
                                  )}
                                  <span className="channel__message-block--messages-list--time">
                                    {dayjs(message.node.created_at).format(
                                      "LT"
                                    )}
                                  </span>
                                  {message.node.msg_header && (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: parsedContentHeader,
                                      }}
                                    />
                                  )}
                                  {message.node.msg_content && (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: parsedContent,
                                      }}
                                    />
                                  )}
                                  {combinedById[message.node.id]?.map(
                                    ({ mediaContent, url }) => {
                                      if (isImage) {
                                        return (
                                          <a key={url} href={url}>
                                            <img
                                              src={url}
                                              alt="Loading...."
                                              className="channel__message-block--messages-list--messages--file-image"
                                            />
                                          </a>
                                        );
                                      } else if (isPdf || isExcel) {
                                        return (
                                          <a
                                            key={url}
                                            href={url}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleFileDownload(url);
                                            }}
                                          >
                                            {getFileName(mediaContent)}
                                          </a>
                                        );
                                      } else if (isVideo) {
                                        return (
                                          <video
                                            key={url}
                                            width="320"
                                            height="240"
                                            controls
                                            src={url}
                                          >
                                            {mediaContent}
                                            Your browser does not support the
                                            video tag.
                                          </video>
                                        );
                                      } else {
                                        return null;
                                      }
                                    }
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentsChatView;
