import React, { Key, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../stories/Button/Button";
import {
  ParentLocationDetails,
  previewType,
} from "../../../../utils/Form.types";
import { TextField } from "@mui/material";
import Home from "../../Home/Index";
import { useLazyQuery } from "@apollo/client";
import useToken from "../../../../customhooks/useToken";
import { ParentByNodeId } from "../../../../queries/parent/list";
import ParentPreviewTabs from "./parentPreviewTabs";
import ParentDetails from "./parentDetails";
import { ParentNode } from "../../../../customhooks/useParentsData";
import { singleNodeVars } from "../../../../Types/Accounting";
const { StudentPreviewDetails } = require("../../../../json/config.json");

const ParentBasicData = () => {
  const { InstId, parentId } = useParams();

  const { token } = useToken();

  const navigate = useNavigate();

  const [GetParentByNodeId, { data: ParentData, loading: ParentLoading }] =
    useLazyQuery<{ node: ParentNode }, singleNodeVars>(ParentByNodeId, {
      variables: {
        id: parentId!,
        token,
      },
    });

  const [formData, setFormData] = useState<ParentLocationDetails>({
    parent_address: "",
    parent_place: "",
    parent_state: "",
    parent_pin: "",
  });

  useEffect(() => {
    if (token) {
      GetParentByNodeId().then(({ data }) => {
        if (data && data.node) {
          const { parent_address, parent_pin, parent_place, parent_state } =
            data.node;
          setFormData({
            parent_address: parent_address,
            parent_place: parent_place,
            parent_state: parent_state,
            parent_pin: parent_pin,
          });
        }
      });
    }
  }, [ParentData, ParentLoading, token, GetParentByNodeId]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <ParentPreviewTabs />
      <div className="parent-preview">
        <ParentDetails />
        <div className="parent-preview__frame row g-0">
          <div className="parent-preview__frame--span-3">
            {StudentPreviewDetails.ParentBasicDetails.map(
              (label: previewType, index: Key) => {
                return (
                  <React.Fragment key={index}>
                    <TextField
                      id="outlined-uncontrolled"
                      className="parent-preview__textfield"
                      label={label.LabelName}
                      InputLabelProps={{ shrink: true }}
                      value={formData[label.inputName]}
                      disabled
                    />
                  </React.Fragment>
                );
              }
            )}
          </div>
        </div>
        <Button
          mode="edit"
          autoFocus
          onClick={() =>
            navigate(
              `/${InstId}/admissions/parents/${parentId!}/updatebasicdata`
            )
          }
        />

        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default ParentBasicData;
