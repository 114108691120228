import React, { useContext, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Close from "../../../../images/Close.svg";
import { Button } from "../../../../stories/Button/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StatesContext } from "./GlobalStates/StatesProvider";
import { AppContext } from "../../../../context/context";
import useExportMutation from "./useExportMutation";
import { ExportProps } from "./Index";
import MessageModal from "../../../../pages/MessageModal";
import { emptyMessageType } from "../../../../utils/constants";
import LoadingModal from "../../../../pages/LoadingModal";
interface Props extends ExportProps {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const Preview = ({ reportType, setModalFlag }: Props) => {
  const { state } = useContext(StatesContext);

  const [message, setMessage] = useState(emptyMessageType);
  const [loading, setLoading] = useState(false);

  const { handleSubmit } = useExportMutation(reportType);

  return (
    <>
      <div className="inst-level-report__preview">
        <div className="inst-level-report__preview--title">
          <Title>Priview Order</Title>
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <div className="inst-level-report__preview--tableblock">
          <TableContainer className="inst-level-report__preview--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Sl No</TableCell>
                  {state.selectedFields.map((item, index) => {
                    return <TableCell key={index}>{item.label}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="inst-level-report__preview--table--slno"></TableCell>
                  {new Array(state.selectedFields.length)
                    .fill("")
                    .map((_, index) => {
                      return <TableCell key={index}></TableCell>;
                    })}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button
          mode="excel"
          onClick={async () => {
            setLoading(true);
            const res = await handleSubmit();
            if (res.flag) {
              setLoading(false);
              setMessage(message);
            }
          }}
        >
          Download
        </Button>
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={loading} />
    </>
  );
};

export default Preview;
