import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import { GetAcctFineSlabByInstId } from "../queries/Fines/query";
import { useEffect } from "react";
export interface GetAcctFineSlabByInstIdList {
  id: number;
  acct_fine_desc: string;
  acct_fine_type: string;
  acct_slab1_from: number;
  acct_slab1_to: number;
  acct_slab1_fine: number;
  acct_slab2_from: number;
  acct_slab2_to: number;
  acct_slab2_fine: number;
  acct_slab3_from: number;
  acct_slab3_to: number;
  acct_slab3_fine: number;
  acct_slab4_from: number;
  acct_slab4_to: number;
  acct_slab4_fine: number;
  acct_slab5_from: number;
  acct_slab5_to: number;
  acct_slab5_fine: number;
  inst_id: number;
}
export interface GetAcctFineSlabByInstIdData {
  GetAcctFineSlabByInstId: GetAcctFineSlabByInstIdList;
}
export interface GetAcctFineSlabByInstIdVars {
  token: string;
  inst_id: string | number;
}
export interface GetFineSlabByIdData {
  node: GetAcctFineSlabByInstIdList;
}
export interface GetFineSlabByIdVars {
  token: string;
  id: number;
}
const useFineSlab = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const [GetFineSlab, { data, error, loading }] = useLazyQuery<
    GetAcctFineSlabByInstIdData,
    GetAcctFineSlabByInstIdVars
  >(GetAcctFineSlabByInstId, {
    variables: {
      token,
      inst_id: InstId!,
    },
  });
  useEffect(() => {
    if (token) {
      GetFineSlab();
    }
  }, [token, GetFineSlab, InstId]);
  return { finesData: { data, error, loading } };
};

export default useFineSlab;
