import Home from "../../Home/Index";
import PreviewTabs from "./PreviewTabs";
import EmployeeDetails from "../Registration/EmployeeDetails";
import React, { useEffect, useState } from "react";
import { employeeAcademicDetailsType } from "../../Types/formTypes";
import { TextField } from "@mui/material";
import { LabelNameProps } from "../../../../Types/Labels";
import useEmpDetailsById from "../../hooks/useEmpDetailsById";
import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { EMPTY_STRING } from "../../../../utils/constants";

const { EmployeePreview } = require("../../json/form.json");
const EmpAcdContribution = () => {
  const {
    empolyeeData: { data, loading },
  } = useEmpDetailsById();
  const { InstId, empId } = useParams();
  const navigate = useNavigate();

  const [EmpOtherDetailsFormData, setEmpOtherDetailsFormData] =
    useState<employeeAcademicDetailsType>({
      research_papers_published: 0,
      patents_no: 0,
      books_Authored_no: 0,
      certification_no: 0,
      workshops_no: 0,
      consultany_service: 0,
      projects_no: 0,
      awards_no: 0,
      resource_person: 0,
      remarks: EMPTY_STRING,
    });
  useEffect(() => {
    if (data && !loading) {
      setEmpOtherDetailsFormData({
        ...data.node.academics_prof_details,
      });
    }
  }, [data, loading]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <PreviewTabs />
      <div className="emp-preview">
        <EmployeeDetails />
        <div className="emp-preview__frame--personal">
          <div className="emp-preview__frame--data row">
            <div className="col emp-preview__span-4">
              {EmployeePreview.AcdContributions.Input1?.map(
                (label: LabelNameProps, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <TextField
                        id="outlined-uncontrolled"
                        className="emp-preview__textfield"
                        label={label.LabelName}
                        InputLabelProps={{ shrink: true }}
                        value={EmpOtherDetailsFormData[
                          label.inputName
                        ]?.toString()}
                        disabled
                        name={label.inputName}
                      />
                    </React.Fragment>
                  );
                }
              )}
            </div>
          </div>
          <Button
            mode="edit"
            onClick={() =>
              navigate(
                `/${InstId}/hr/employee/3/${empId}/update/academicContribution`
              )
            }
          />
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
      </div>
    </>
  );
};

export default EmpAcdContribution;
