import React, { useContext, useEffect, useRef, useState } from "react";
import Home from "../../Home/Index";

import { Autocomplete, TextField } from "@mui/material";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Enter from "../../../../images/Enter.svg";
import Edit from "../../../../images/EditProfile.svg";

import Delete from "../../../../images/Delete.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import {
  AcdSubjectType,
  Direction,
  Operation,
  PageFor,
  SortBy,
} from "../../../../utils/Enum.types";
import { Title } from "../../../../stories/Title/Title";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import { TableHeaderProps } from "../../../../Types/Tables";
import useAcademicSubjects from "../../hooks/useAcademicSubjects";
import {
  AutoCloseButton,
  EMPTY_STRING,
  ROWS_PER_PAGE,
  emptyMessageType,
} from "../../../../utils/constants";
import { subjectOptions } from "../../../../utils/types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddAcdSubjectGroupAndDetails,
  UpdateAcdSubjectGroup,
} from "../../../../queries/customallocation/groups/mutations";
import {
  AddAcdSubjectGroupAndDetailsData,
  AddAcdSubjectGroupAndDetailsVars,
} from "../../../../Types/Groups/mutations";
import useToken from "../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import { msgType } from "../../../../utils/Form.types";
import { Keys } from "../../../../utils/Enum.keys";
import { RefsByTagName } from "../../../../utils/UtilFunctions";
import MessageModal from "../../../../pages/MessageModal";
import {
  GetAcdSubjectGroups,
  GetSubjectGroupByNodeId,
} from "../../../../queries/customallocation/groups/queries";
import { GetSubjectGroupByNodeIdData } from "../../../../Types/Groups/queries";
import { singleNodeVars } from "../../../../Types/Accounting";
import { AppContext } from "../../../../context/context";
import { payloadTypes } from "../../../../context/reducer";

const { Academics_Table } = require("../../json/table.json");
interface props {
  pageType: PageFor;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const Add = ({ pageType, setModal }: props) => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const navigate = useNavigate();
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const { state, dispatch } = useContext(AppContext);

  const subRef = useRef<HTMLSelectElement>(null);
  const subInputRef = RefsByTagName(subRef);

  const autoClearBtn = subRef.current
    ? (subRef.current.getElementsByClassName(
        AutoCloseButton
      )[0] as HTMLButtonElement)
    : null;

  const [group_name, setGroupName] = useState<string>("");
  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const [selectedSubject, setSelectedSubject] = useState<subjectOptions | null>(
    null
  );
  const [items, setItems] = useState<subjectOptions[]>([]);
  const grpRef = useRef<HTMLInputElement>(null);
  const [AddSubjGroupDetails] = useMutation<
    AddAcdSubjectGroupAndDetailsData,
    AddAcdSubjectGroupAndDetailsVars
  >(AddAcdSubjectGroupAndDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateSubjGroupDetails] = useMutation(UpdateAcdSubjectGroup, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [GetGroupDetail, { data: nodeData }] = useLazyQuery<
    GetSubjectGroupByNodeIdData,
    singleNodeVars
  >(GetSubjectGroupByNodeId, {
    variables: {
      id: state.acdGrpId,
      token,
    },
  });

  const {
    subjects: { data: subjectData },
  } = useAcademicSubjects(0, 0, 0, EMPTY_STRING, 100, AcdSubjectType.GENERAL);

  useEffect(() => {
    if (token && state.acdGrpId) {
      GetGroupDetail().then(({ data }) => {
        if (data && data.node) {
          setGroupName(data.node.group_name);
          setItems(
            data.node.subj_group_details.map((sub) => ({
              label: sub.subject_details.subj_desc,
              subj_code: sub.subject_details.subj_code,
              value: sub.subject_details.id,
            }))
          );
        }
      });
    }
  }, [token, GetGroupDetail, nodeData, state.acdGrpId]);

  const handleItems = () => {
    const foundItem = items.find(
      (item) => selectedSubject && item.value === selectedSubject.value
    );
    if (selectedSubject !== null) {
      if (foundItem) {
        alert("Subject already exists");
      } else {
        setItems((prevItems) => {
          return [...prevItems, selectedSubject];
        });
        setSelectedSubject(null);
        if (autoClearBtn) {
          autoClearBtn.click();
        }
      }
    } else {
      alert("Subject is empty");
    }
  };
  const handleDeleteItem = (id: number) => {
    setItems(items.filter((item) => item.value !== id));
  };
  const handleClear = () => {
    dispatch({
      payload: { acdGrpId: 0 },
      type: payloadTypes.SET_ACD_SUB_GROUP_ID,
    });
    setGroupName("");
    setItems([]);
    setSelectedSubject(null);
    if (grpRef.current) grpRef.current.focus();
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE) {
      handleClear();
    }
    setMessage(emptyMessageType);
  };
  const itemsSet = new Set(items.map((item) => item.value));
  const handleSubmit = () => {
    if (items.length === 0) {
      setMessage({
        flag: true,
        message: "Subjects not found",
        operation: Operation.NONE,
      });
      return;
    }
    AddSubjGroupDetails({
      variables: {
        token,
        group_name,
        inst_id: InstId!,
        user_details,
        input: items.map((item, index) => ({
          idx: index + 1,
          subj_master_id: item.value,
        })),
      },
      refetchQueries: [
        {
          query: GetAcdSubjectGroups,
          variables: {
            token,
            after: null,
            first: ROWS_PER_PAGE,
            inst_id: InstId!,
            name: EMPTY_STRING,
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.GROUP_NAME,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.AddAcdSubjectGroupAndDetails) {
        setMessage({
          flag: true,
          message: `${group_name} added successfully`,
          operation: Operation.CREATE,
        });
      }
    });
  };

  return (
    <>
      {pageType === PageFor.GENERAL && <Home DashBoardRequired={false} />}
      <Title>
        {pageType === PageFor.GENERAL
          ? "Associate Subjects to Groups"
          : "Edit Grouping for Subject Allocation"}
      </Title>

      <div
        className={
          pageType === PageFor.GENERAL ? "add-groups" : "add-groups__modal"
        }
      >
        <div className="add-groups__row row g-0">
          <div className="col-4 add-groups__block">
            <div className="label-grid">
              <Label>Enter Group Name</Label>
              <Input
                autoFocus
                value={group_name}
                onChange={(e) => setGroupName(e.target.value)}
                required
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    if (subInputRef) {
                      subInputRef.focus();
                    }
                  }
                }}
                inputRef={grpRef}
                error={group_name ? "" : "Group name is required"}
              />
            </div>
          </div>
        </div>
        <img src={Edit} alt="" onClick={handleItems} />

        <div className="add-groups__tableblock">
          <TableContainer className="add-groups__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Academics_Table.CustomAllocation.AssociateSub.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="add-groups__table--row">
                  <TableCell
                    className="add-groups__table--slno"
                    id="td-center"
                  ></TableCell>
                  <TableCell className="add-groups__table--select">
                    <Autocomplete
                      classes={classes}
                      value={selectedSubject}
                      options={
                        subjectData
                          ? subjectData.GetAcdSubject.edges
                              .map((sub) => ({
                                label: sub.node.subj_desc,
                                value: sub.node.id,
                                subj_code: sub.node.subj_code,
                              }))
                              .filter(
                                ({ value }) => itemsSet.has(value) === false
                              )
                          : []
                      }
                      ref={subRef}
                      openOnFocus
                      forcePopupIcon
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          if (
                            selectedSubject &&
                            itemsSet.has(selectedSubject.value) === false
                          )
                            handleItems();
                        }
                      }}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setSelectedSubject(newValue);
                        } else {
                          setSelectedSubject(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          classes={{ root: textClasses.formControlRoot }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell className="add-groups__table--subcode">
                    {selectedSubject ? selectedSubject.subj_code : EMPTY_STRING}
                  </TableCell>
                  <TableCell
                    id="td-center"
                    className="add-groups__table--actions"
                  >
                    <img src={Enter} alt="" onClick={handleItems} />
                  </TableCell>
                </TableRow>
                {items.map((res, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className="add-groups__table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>{res.label}</TableCell>
                      <TableCell className="add-groups__table--subcode">
                        {res.subj_code}
                      </TableCell>
                      <TableCell
                        className="add-groups__table--actions"
                        id="td-center"
                      >
                        <img
                          src={Delete}
                          alt=""
                          onClick={() => handleDeleteItem(res.value)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="save" onClick={handleSubmit} />
        <Button mode="clear" onClick={handleClear} />
        {pageType === PageFor.GENERAL ? (
          <Button mode="back" onClick={() => navigate(-1)} />
        ) : (
          <Button
            mode="cancel"
            onClick={() => {
              handleClear();
              setModal(false);
            }}
          />
        )}
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Add;
