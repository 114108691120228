import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import TableView from "../../../../images/TableView.svg";
import DataView from "../../../../images/DataView.svg";
import More from "../../../../images/More.svg";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  AcdSubjectAllocationData,
  InstDetailsTypeName,
  InstitutionConfigurationTypes,
  TableHeaders,
} from "../../../../utils/Enum.types";
import View from "../../../../images/EyeWhite.svg";
import Modal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import Close from "../../../../images/Close.svg";
import Assignments from "./Assignments";
import Attendance from "./Attendance";
import MarksEntry from "./MarksEntry";
import { useStyles } from "../../../../styles/TooltipStyles";
import { useLazyQuery } from "@apollo/client";
import {
  AcdTeacherClassQueryType,
  GetAcdTeacherClassAssociationsData,
  GetAcdTeacherClassAssociationsVars,
} from "../../types/subject/Subject";
import { GetAcdTeacherClassAssociations } from "../../queries/subjects/List.tsx/Index";
import { useNavigate, useParams } from "react-router-dom";
import useToken from "../../../../customhooks/useToken";
import useActiveAcademicYear from "../../hooks/useActiveAcademicYear";
import { AppContext } from "../../../../context/context";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { GetAcdInstDetailsByIdForteacherDashBoard } from "../../queries/instdetails/section/byId";
import {} from "../../DailyActivities/Index";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { EMPTY_STRING } from "../../../../utils/constants";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import DailyDiary from "./DailyDiary";
import UpcomingTest from "./UpcomingTest";
// eslint-disable-next-line
import BirthdayList from "../Admin/BirthdayList";
import usePollIntervalTime from "../../../../customhooks/usePollIntervalTime";
interface ClassDetails {
  class_desc: string;
}
interface BranchDetails {
  branch_desc: string;
}
interface DeptDetails {
  dept_desc: string;
}
interface SemDetails {
  sem_desc: string;
  acd_class_details: ClassDetails;
}
interface AcdSectionNode {
  section_desc: string;
  acd_semester_details: SemDetails;
  acd_class_details: ClassDetails;
  acd_branch_details: BranchDetails;
  acd_dept_details: DeptDetails;
  class_desc: string;
  sem_desc: string;
  branch_desc: string;
  dept_desc: string;
}
export interface GetAcdInstDetailsByNodeIdData {
  nodes: AcdSectionNode[];
}
export interface GetAcdInstDetailsByNodeIdVars {
  token: string;
  ids: number[];
}

export interface CustomModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  contentComponent: React.ReactNode;
}
const Teacher = () => {
  const tooltipClasses = useStyles();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { entry_level } = useInstitutionConfiguration();
  const { InstId } = useParams();
  const [assignmentModal, setAssignmentModal] = useState(false);
  const [tableViewActive, setTableViewActive] = useState(true);

  const [allotedIds, setAllotedIds] = useState<number[]>([]);
  const [entryIds, setEntryIds] = useState<number[]>([]);

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_PER_SESSION_ATTENDANCE
  );

  const enablePerSessionAtt =
    configData.data &&
    configData.data.GetSwConfigVariables[0].config_boolean_value;

  const CustomModal: React.FC<CustomModalProps> = ({
    isOpen,
    onRequestClose,
    contentComponent,
  }) => {
    return (
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">{contentComponent}</div>
          <div className="modal-flex__image">
            <img src={Close} alt="Close" onClick={onRequestClose} />
          </div>
        </div>
      </Modal>
    );
  };
  const pollIntervalTime = usePollIntervalTime();

  const [GetSubjectsTeachersAssociated, { data }] = useLazyQuery<
    GetAcdTeacherClassAssociationsData,
    GetAcdTeacherClassAssociationsVars
  >(GetAcdTeacherClassAssociations, {
    variables: {
      acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
      inst_id: InstId!,
      token,
      per_std_subj_allocation: false,
      input: {
        query_type: AcdTeacherClassQueryType.ALL_ENTRIES_BY_TEACHER_ID,
        entry_id: 0,
        entry_level: entry_level,
        pr_emp_id: state.empLoginId,
        subj_master_id: 0,
      },
    },
    pollInterval: pollIntervalTime,
  });

  const { configData: SubjectAllocationLevel } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const { configData: EnableDailyDiary } = useSwConfigData(
    InstitutionConfigurationTypes.ENABLE_TEACHER_DAILY_DIARY
  );
  const subjectAllocLevel =
    SubjectAllocationLevel.data &&
    SubjectAllocationLevel.data.GetSwConfigVariables.length
      ? SubjectAllocationLevel.data.GetSwConfigVariables[0].config_string_value
      : EMPTY_STRING;
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();
  const dailyDiaryFlag =
    EnableDailyDiary &&
    EnableDailyDiary.data &&
    EnableDailyDiary.data.GetSwConfigVariables[0].config_boolean_value;
  const [GetInstMasterDetails, { data: nodeData }] = useLazyQuery<
    GetAcdInstDetailsByNodeIdData,
    GetAcdInstDetailsByNodeIdVars
  >(GetAcdInstDetailsByIdForteacherDashBoard, {
    variables: {
      token,
      ids: [...new Set(entryIds), ...new Set(allotedIds)],
    },
    pollInterval: pollIntervalTime,
  });

  useEffect(() => {
    if (token) {
      GetInstMasterDetails();
    }
  }, [token, GetInstMasterDetails, entryIds, allotedIds]);

  useEffect(() => {
    if (
      token &&
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      state.ActiveAcdYr &&
      SubjectAllocationLevel.data
    ) {
      GetSubjectsTeachersAssociated().then(({ data }) => {
        if (data && data.GetAcdTeacherClassAssociations) {
          let entry_ids: number[] = [];
          let alloted_ids: number[] = [];
          data.GetAcdTeacherClassAssociations.forEach((teac) => {
            if (teac.per_std_subj_allocation) {
              switch (subjectAllocLevel) {
                case AcdSubjectAllocationData.CLASS:
                  alloted_ids = [...alloted_ids, teac.acd_class_id];
                  break;
                case AcdSubjectAllocationData.SEMESTER:
                  alloted_ids = [...alloted_ids, teac.acd_semester_id];
                  break;
                case AcdSubjectAllocationData.SECTION:
                  alloted_ids = [...alloted_ids, teac.acd_section_id];
                  break;
                default:
                  break;
              }
            } else {
              switch (entry_level) {
                case AcdSubjectAllocationData.CLASS:
                  entry_ids.push(teac.acd_class_id);
                  break;
                case AcdSubjectAllocationData.SEMESTER:
                  entry_ids.push(teac.acd_semester_id);
                  break;
                case AcdSubjectAllocationData.SECTION:
                  entry_ids.push(teac.acd_section_id);
                  break;
                default:
                  break;
              }
            }
          });

          setEntryIds(entry_ids);
          setAllotedIds(alloted_ids);
        }
      });
    }
  }, [
    GetSubjectsTeachersAssociated,
    token,
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    state.ActiveAcdYr,
    entry_level,
    subjectAllocLevel,
    data,
    state.empLoginId,
    SubjectAllocationLevel.data,
  ]);
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();

  return (
    <>
      <Title>Teacher Dashboard</Title>
      <div className="teacher-dashboard">
        <div className="teacher-dashboard__grids">
          <div className="teacher-dashboard__grids--cards">
            <Attendance />
          </div>
          <div className="teacher-dashboard__grids--cards">
            <MarksEntry />
          </div>
          <div className="teacher-dashboard__grids--cards">
            <UpcomingTest />
          </div>
          {dailyDiaryFlag && (
            <div className="teacher-dashboard__grids--cards">
              <DailyDiary />
            </div>
          )}
        </div>
        <div className="teacher-dashboard__bottom-block row g-0 ">
          {/* <div className="col-3 h100 teacher-dashboard__grids--cards">
            <BirthdayList userType={UserType.TEACHER} />
          </div> */}
          <div className="teacher-dashboard__block col">
            <div className="teacher-dashboard__block--flex">
              <b className="teacher-dashboard__block--title">
                Allocated Classes
              </b>
              <div className="teacher-dashboard__block--images">
                {!tableViewActive ? (
                  <Tooltip
                    title="Card View"
                    placement="top"
                    classes={{
                      tooltip: tooltipClasses.customTooltipGrey,
                      arrow: tooltipClasses.customArrowGrey,
                    }}
                    arrow
                  >
                    <img
                      src={DataView}
                      alt=""
                      onClick={() => setTableViewActive(!tableViewActive)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Table View"
                    placement="top"
                    classes={{
                      tooltip: tooltipClasses.customTooltipGrey,
                      arrow: tooltipClasses.customArrowGrey,
                    }}
                    arrow
                  >
                    <img
                      src={TableView}
                      alt=""
                      onClick={() => setTableViewActive(!tableViewActive)}
                    />
                  </Tooltip>
                )}
                <img src={More} alt="" />
              </div>
            </div>
            {!tableViewActive ? (
              <div className="teacher-dashboard__tableblock">
                <TableContainer className="teacher-dashboard__table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>{TableHeaders.SLNO}</TableCell>
                        {USE_DEPARTMENT_KEY && (
                          <TableCell>{departmentLabel}</TableCell>
                        )}
                        {USE_BRANCH_KEY && <TableCell>{branchLabel}</TableCell>}
                        {USE_CLASS_KEY && <TableCell>{classLabel}</TableCell>}
                        {USE_SEMESTER_KEY && (
                          <TableCell>{semesterLabel}</TableCell>
                        )}
                        {USE_SECTION_KEY && (
                          <TableCell>{sectionLabel}</TableCell>
                        )}
                        <TableCell>{TableHeaders.STATUS}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {nodeData &&
                        nodeData.nodes.map((data: any, index: number) => {
                          let deptDesc,
                            branchDesc,
                            classDesc,
                            semDesc,
                            sectionDesc,
                            entryId: string | number;

                          switch (data.__typename) {
                            case InstDetailsTypeName.CLASS:
                              deptDesc =
                                data.acd_branch_details.acd_dept_details
                                  .dept_desc;
                              branchDesc = data.acd_branch_details.branch_desc;
                              classDesc = data.class_desc;
                              entryId = data.id;
                              break;
                            case InstDetailsTypeName.SEMESTER:
                              deptDesc =
                                data.acd_class_details.acd_branch_details
                                  .acd_dept_details.dept_desc;
                              branchDesc =
                                data.acd_class_details.acd_branch_details
                                  .branch_desc;
                              classDesc = data.acd_class_details.class_desc;
                              semDesc = data.sem_desc;
                              entryId = data.id;

                              break;
                            case InstDetailsTypeName.SECTION:
                              deptDesc =
                                data.acd_semester_details.acd_class_details
                                  .acd_branch_details.acd_dept_details
                                  .dept_desc;
                              branchDesc =
                                data.acd_semester_details.acd_class_details
                                  .acd_branch_details.branch_desc;
                              classDesc =
                                data.acd_semester_details.acd_class_details
                                  .class_desc;
                              semDesc = data.acd_semester_details.sem_desc;
                              sectionDesc = data.section_desc;
                              entryId = data.id;
                              break;
                            default:
                              break;
                          }

                          return (
                            <TableRow key={index}>
                              <TableCell
                                className="teacher-dashboard__table--slno"
                                id="td-center"
                              >
                                {index + 1}
                              </TableCell>
                              {deptDesc !== "---" && (
                                <TableCell>{deptDesc}</TableCell>
                              )}
                              <TableCell>{branchDesc}</TableCell>
                              <TableCell>{classDesc}</TableCell>
                              {semDesc !== "---" && (
                                <TableCell>{semDesc}</TableCell>
                              )}
                              {sectionDesc !== "---" && (
                                <TableCell>{sectionDesc}</TableCell>
                              )}
                              <TableCell
                                className="teacher-dashboard__table--actions"
                                id="td-center"
                              >
                                <button className="teacher-dashboard__table--view">
                                  View Status{" "}
                                  <img
                                    src={View}
                                    alt=""
                                    onClick={() => {
                                      if (enablePerSessionAtt !== undefined)
                                        if (enablePerSessionAtt) {
                                          navigate(
                                            `/${InstId}/academics/dailyactivities/${entryId}/persessiondetails`
                                          );
                                        } else {
                                          navigate(
                                            `/${InstId}/academics/dailyactivities/${entryId}/0/classdetails`
                                          );
                                        }
                                    }}
                                  />
                                </button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : (
              <div className="teacher-dashboard__grid-view">
                <div className="teacher-dashboard__grid-view--list">
                  {nodeData &&
                    nodeData.nodes.map((data: any, index: number) => {
                      let deptDesc,
                        branchDesc,
                        classDesc,
                        semDesc,
                        sectionDesc,
                        entryId: string | number;

                      switch (data.__typename) {
                        case InstDetailsTypeName.CLASS:
                          deptDesc =
                            data.acd_branch_details.acd_dept_details.dept_desc;
                          branchDesc = data.acd_branch_details.branch_desc;
                          classDesc = data.class_desc;
                          entryId = data.id;
                          break;
                        case InstDetailsTypeName.SEMESTER:
                          deptDesc =
                            data.acd_class_details.acd_branch_details
                              .acd_dept_details.dept_desc;
                          branchDesc =
                            data.acd_class_details.acd_branch_details
                              .branch_desc;
                          classDesc = data.acd_class_details.class_desc;
                          semDesc = data.sem_desc;
                          entryId = data.id;

                          break;
                        case InstDetailsTypeName.SECTION:
                          deptDesc =
                            data.acd_semester_details.acd_class_details
                              .acd_branch_details.acd_dept_details.dept_desc;
                          branchDesc =
                            data.acd_semester_details.acd_class_details
                              .acd_branch_details.branch_desc;
                          classDesc =
                            data.acd_semester_details.acd_class_details
                              .class_desc;
                          semDesc = data.acd_semester_details.sem_desc;
                          sectionDesc = data.section_desc;
                          entryId = data.id;
                          break;
                        default:
                          break;
                      }

                      return (
                        <div
                          className="teacher-dashboard__grid-view--list--card"
                          key={index}
                        >
                          {entry_level === AcdSubjectAllocationData.CLASS ? (
                            <>
                              <span className="teacher-dashboard__grid-view--list--title">
                                {deptDesc === "---" ? EMPTY_STRING : deptDesc}
                              </span>
                              <span className="teacher-dashboard__grid-view--list--title">
                                {branchDesc}
                              </span>
                              <b className="teacher-dashboard__grid-view--list--title">
                                {classDesc}
                              </b>
                            </>
                          ) : entry_level ===
                            AcdSubjectAllocationData.SEMESTER ? (
                            <>
                              <span className="teacher-dashboard__grid-view--list--title">
                                {branchDesc}
                              </span>
                              <span className="teacher-dashboard__grid-view--list--title">
                                {classDesc}
                              </span>
                              <b className="teacher-dashboard__grid-view--list--title">
                                {semDesc}
                              </b>
                            </>
                          ) : (
                            <>
                              <span className="teacher-dashboard__grid-view--list--title">
                                {semDesc === "---" ? branchDesc : classDesc}
                              </span>
                              <span className="teacher-dashboard__grid-view--list--title">
                                {semDesc === "---" ? classDesc : semDesc}
                              </span>
                              <b className="teacher-dashboard__grid-view--list--title">
                                {sectionDesc}
                              </b>
                            </>
                          )}
                          <div className="teacher-dashboard__grid-view--list--button">
                            <button
                              className="teacher-dashboard__grid-view--list--view"
                              onClick={() => {
                                if (enablePerSessionAtt !== undefined)
                                  if (enablePerSessionAtt) {
                                    navigate(
                                      `/${InstId}/academics/dailyactivities/${entryId}/persessiondetails`
                                    );
                                  } else {
                                    navigate(
                                      `/${InstId}/academics/dailyactivities/${entryId}/0/classdetails`
                                    );
                                  }
                              }}
                            >
                              View <img src={View} alt="" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={assignmentModal}
        onRequestClose={() => setAssignmentModal(!assignmentModal)}
        contentComponent={<Assignments setModalFlag={setAssignmentModal} />}
      />
    </>
  );
};

export default Teacher;
