import {
  DataGridPro,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import React, { useContext, useEffect, useState } from "react";
import {
  Direction,
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  InstitutionConfigurationTypes,
  SortBy,
  TableHeaders,
} from "../../../../../../utils/Enum.types";
import Eduate from "../../../../../../images/Eduate_Logo_image.png";
import {
  HEADER_TEXT_ALIGN,
  MARKS_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../../../styles/DataGridTableStyles";
import Input from "../../../../../../stories/Input/Input";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  getPercentageColor,
  getTextPercentageColor,
} from "../../../../../../utils/constants";

import { useParams } from "react-router-dom";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  toStandardDate,
} from "../../../../../../utils/UtilFunctions";
import { responseType } from "../../../../../../utils/Form.types";
import { useLazyQuery } from "@apollo/client";
import {
  GetStdTestMarksBySubjIdData,
  GetStdTestMarksBySubjIdEdges,
  GetStdTestMarksBySubjIdVars,
} from "../../../../DailyActivities/Exam/UpdateMarks";
import { GetStdTestMarksBySubjId } from "../../../../queries/test/query";
import useActiveAcademicYear from "../../../../hooks/useActiveAcademicYear";
import { AppContext } from "../../../../../../context/context";
import useToken from "../../../../../../customhooks/useToken";
import useSwConfigData from "../../../../../../customhooks/useSwConfigData";
import LoadingModal from "../../../../../../pages/LoadingModal";
import { Button } from "../../../../../../stories/Button/Button";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  E4_CELL,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_LEFT,
  HEADER_ALIGNMENT_RIGHT,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../../../../Library/Constants";
import useServerDateandTime from "../../../../../Library/customHooks/useServerDateandTime";
import useInstDetails from "../../../../../../customhooks/general/useInstDetails";
import useInstLogoDetails from "../../../../../../customhooks/useInstLogoDetails";
import { setFlagsFromString } from "v8";
import useCheckAllocationType from "../../../../hooks/useCheckAllocationType";
interface props {
  selectedSubject: responseType | null;
}
const SubjectwiseList = ({ selectedSubject }: props) => {
  const { serverDate } = useServerDateandTime();
  const { InstDetails } = useInstDetails(1);
  const { LogoOrSign } = useInstLogoDetails({filetype:FileUploadParams.INST_LOGO});

  const { activeAcademicYearData } = useActiveAcademicYear();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { allotedID, testId, testClassId, InstId } = useParams();
  const classes = useDataGridStyles();
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<GetStdTestMarksBySubjIdEdges[]>([]);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const { flag } = useCheckAllocationType();
  const alloted_level = configData.data
    ? configData.data?.GetSwConfigVariables[0].config_string_value
    : EMPTY_STRING;
  const StudentHeaders = [
    "SlNo",
    "Admisiion No",
    "Reg No",
    "Student Name",
    "Total Marks Scored",
    "Percentage",
  ];
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [GetStdTestMarks, { data, loading, fetchMore }] = useLazyQuery<
    GetStdTestMarksBySubjIdData,
    GetStdTestMarksBySubjIdVars
  >(GetStdTestMarksBySubjId);

  useEffect(() => {
    if (
      activeAcademicYearData.data &&
      !activeAcademicYearData.loading &&
      selectedSubject &&
      selectedSubject.value &&
      token &&
      state.ActiveAcdYr &&
      flag !== undefined
    ) {
      GetStdTestMarks({
        variables: {
          token,
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          acd_test_class_id: testClassId! || testId!,
          after: null,
          entry_id: allotedID!,
          entry_level: alloted_level,
          first: null,
          inst_id: InstId!,
          subj_master_id: selectedSubject ? selectedSubject.value : 0,
          per_std_subj_allocation: flag,
          orderBy: {
            direction: Direction.ASC,
            field: SortBy.STUDENT_ID,
          },
        },
      });
    }
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    selectedSubject,
    token,
    testClassId,
    testId,
    InstId,
    GetStdTestMarks,
    allotedID,
    state.ActiveAcdYr,
    alloted_level,
    flag,
  ]);

  const columns: GridColDef[] = [
    {
      headerName: "Sl No.",
      field: "id",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-sl-no",

      align: SLNO_TEXT_ALIGN,
    },

    {
      field: "std_adm_no",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
      headerName: TableHeaders.ADMISSION_NUMBER,
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "std_reg_no",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-adm-no",
      headerName: TableHeaders.REGISTER_NUMBER,
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "std_name",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-name",
      headerName: TableHeaders.STUDENT_NAME,
      flex: 1,
    },
    {
      field: "total_marks",
      headerName: "Total Scored Marks ",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-marks font-green",
      align: SLNO_TEXT_ALIGN,
    },
    {
      field: "total_perc",
      headerName: "Percentage ",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-att-percentage",
      align: MARKS_TEXT_ALIGN,
      renderCell: (params) => {
        const percentage = parseFloat(params.value);
        const cellBackgroundColor = getPercentageColor(percentage);
        const textPercentageColor = getTextPercentageColor(percentage);


        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              color: textPercentageColor,
              backgroundColor: `${cellBackgroundColor}`,
            }}
          >
            {params.value}%
          </div>
        );
      },
    },
  ];
  const tableData =
    data &&
    data.GetStdTestMarksBySubjId.edges.map((res, index) => ({
      slNo: index + 1,
      AdmNo: res.node.student_details.std_adm_no,
      regNo: res.node.student_details.std_reg_no,
      Name:
        res.node.student_details.first_name +
        " " +
        res.node.student_details.middle_name +
        " " +
        res.node.student_details.last_name,
      total_marks: res.node.marks_scored_tot,
      total_perc: `${res.node.marks_num_to_100}`,
    }));

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Academics Test Reports");

    const dataRows = tableData
      ? tableData.map((data) => [
          data.slNo,
          data.AdmNo,
          data.regNo,
          data.Name,
          data.total_marks,
          data.total_perc,
        ])
      : [];

    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 15;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");
                  worksheet.mergeCells(1, 1, 1, 6);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = `Subject Wise Test Reports : ${
                    selectedSubject ? selectedSubject.label : ""
                  }`;
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_RIGHT;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_RIGHT;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < StudentHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = StudentHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);

                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                    row.getCell(6).alignment = {
                      horizontal: ExcelAlignment.RIGHT,
                    };
                    row.getCell(5).alignment = {
                      horizontal: ExcelAlignment.RIGHT,
                    };
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(
                      DOWNLOAD,
                      InstDetails.data?.nodes[0]?.inst_name!
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };
  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: FETCH_MORE_DATA,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetStdTestMarksBySubjId.edges;
                const pageInfo =
                  fetchMoreResult.GetStdTestMarksBySubjId.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck =
                  prevResult.GetStdTestMarksBySubjId.edges.filter(
                    ({ node: { id } }) =>
                      newEdges.findIndex(
                        ({ node: { id: newId } }) => newId === id
                      ) !== -1
                  );

                if (duplicateCheck?.length > 0) return prevResult;

                return {
                  GetStdTestMarksBySubjId: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data
                      ? data.GetStdTestMarksBySubjId.totalCount!
                      : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows && rows?.length > 0)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetStdTestMarksBySubjId.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = students.find(
            (row) => row.node.id && row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData?.map(({ node }, index) => ({
            id: `${index + 1} `,
            std_adm_no: node.student_details.std_adm_no,
            std_reg_no: node.student_details.std_reg_no,
            std_name: node.student_details.first_name,
            std_id: node.student_details.id,
            total_marks: node.marks_scored_tot,
            total_perc: `${node.marks_num_to_100}`,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData?.map(({ node }, index) => ({
            id: `${index + 1}  `,
            std_adm_no: node.student_details.std_adm_no,
            std_reg_no: node.student_details.std_reg_no,
            std_name: node.student_details.first_name,
            std_id: node.student_details.id,
            total_marks: node.marks_scored_tot,
            total_perc: `${node.marks_num_to_100}`,
          }))
        );
      }
      setEndCursor(data.GetStdTestMarksBySubjId.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  return (
    <>
      <div className="class-wise-rank__t-view">
        <div className="class-wise-rank__t-view--select row g-0">
          <div className="col-4">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col flex-end">
            <div className="student-total-count">
              Total Students :{" "}
              <b>{data ? data.GetStdTestMarksBySubjId.totalCount : 0}</b>
            </div>
          </div>
        </div>
        <div className={`class-wise-rank__sub-wise--tableblock ${classes.root}`}>
          <>
            <DataGridPro
              columns={columns}
              rows={rows}
              rowHeight={TABLE_ROW_HEIGHT}
              hideFooter
              disableRowSelectionOnClick
              slotProps={{
                columnsPanel: { disableHideAllButton: true },
              }}
            />
          </>
        </div>
        <Button mode="pdf" />
        <Button mode="export" onClick={downloadExcel} />
      </div>
      <LoadingModal flag={loading} />
    </>
  );
};

export default SubjectwiseList;
