import React, { useEffect, useState } from "react";
import { Title } from "../../../stories/Title/Title";
import {
  Autocomplete,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../Types/Tables";
import Close from "../../../images/Close.svg";
import Input from "../../../stories/Input/Input";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import { Button } from "../../../stories/Button/Button";
import { SubjDrawerDetails, TeachersAssociationPageFrom } from "./Index";
import usePayRollMasters from "../../HR/hooks/usePayRollMasters";
import { PayRollMasters } from "../../HR/enums/Enum.types";
import usePayRollMastersConfig from "../../HR/hooks/usePayRollMastersConfig";
import useEmployee, {
  PayRollEmpEdges,
  empQueryTypes,
} from "../../HR/hooks/useEmployee";
import { EMPTY_STRING, FETCH_MORE_DATA, ROWS_PER_PAGE } from "../../../utils/constants";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import { useMutation } from "@apollo/client";
import { AddAcdSubjectTeacherAssoci } from "../queries/teacher_association/mutations";
import { Direction, Operation, SortBy } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import { useParams } from "react-router-dom";
import useToken from "../../../customhooks/useToken";
import MessageModal from "../../../pages/MessageModal";
import { GetAcdSubject } from "../queries/subjects/List.tsx/Index";
import { SubjectsByAcdYear } from "../../../utils/studentAcdEnum.types";
import { getModifiedScrollHeight } from "../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AccountentType } from "../../Accounts/common/Enum.types";
const { Academics_Table } = require("../json/table.json");
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  subjectDetails: SubjDrawerDetails;
}

const Assign = ({ setModal, subjectDetails }: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { user_details } = useLoggedInUserDetails();
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const { subj_code, subj_id, subj_name, pageFrom } = subjectDetails;
  const [searchGrade, setSearchGrade] = useState("");
  const [searchEmp, setSearchEmp] = useState("");
  const { InstId } = useParams();
  const { token } = useToken();
  const { dropDown } = usePayRollMasters(
    PayRollMasters.GRADE,
    searchGrade,
    null
  );
  // const [pr_emp_id, setPrEmpId] = useState(0);
  const [pr_emp_ids, setPrEmpIds] = useState<Set<number>>(new Set());

  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });

  const [AssociateTeachersWithSubject] = useMutation(
    AddAcdSubjectTeacherAssoci,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const { activeAcademicYearData } = useActiveAcademicYear();
  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;
  const {
    empDetails: { data, loading, fetchMore },
  } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.TEACHERS_UNASSIGNED_FOR_SUBJECT,
    searchEmp,
    0,
    0,
    0,
    0,
    0,
    0,
    [AccountentType.TEACHER]
  );

  const TeachersTotalCount = data ? data.GetPayRollEmp.totalCount : 0;
  const { USE_HR_GRADE } = usePayRollMastersConfig();
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setModal(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetPayRollEmp.edges;
            const pageInfo = fetchMoreResult.GetPayRollEmp.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetPayRollEmp.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetPayRollEmp: {
                edges: [...employees, ...newEdges],
                pageInfo,
                totalCount: data?.GetPayRollEmp.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  const handleSubjectAssoci = () => {
    AssociateTeachersWithSubject({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcdId,
        pr_emp_ids: Array.from(pr_emp_ids),
        subj_master_id: subj_id,
      },
      refetchQueries: [
        {
          query: GetAcdSubject,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            after: null,
            direction: Direction.ASC,
            first: ROWS_PER_PAGE,
            input: {
              acd_subj_query_type: SubjectsByAcdYear.ACD_SUBJ_BY_INST_ID,
              ids: [Number(InstId)],
            },
            name: EMPTY_STRING,
            token,
            sortBy: SortBy.SUBJ_CODE,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.AddAcdSubjectTeacherAssoci) {
        setMessage({
          flag: true,
          message: " Successfully Teacher with Subject",
          operation: Operation.CREATE,
        });
      }
    });
  };
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetPayRollEmp.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        const updatedNewData = newData.map((newEmployee) => {
          const filteredEmployee = employees.find(
            (employee) => employee.node.id === newEmployee.node.id
          );
          if (filteredEmployee) {
            return {
              ...newEmployee,
              node: {
                ...newEmployee.node,
              },
            };
          }
          return newEmployee;
        });
        setEmployees(updatedNewData);
      } else {
        setEmployees(newData);
      }
      setEndCursor(data.GetPayRollEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  return (
    <>
      <div className="allocate-teacher__assign">
        <div className="allocate-teacher__assign--title">
          <Title>Assign Teachers</Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
        <div className="allocate-teacher__assign--sub-details">
          <div className="allocate-teacher__assign--sub-details--grid">
            <span>Subject Code</span>
            <span>:</span>
            <span className="allocate-teacher__assign--sub-details--code">
              {subj_code}
            </span>
          </div>
          <div className="allocate-teacher__assign--sub-details--grid">
            <span>Subject Name</span>
            <span>:</span>
            <span className="allocate-teacher__assign--sub-details--code">
              {subj_name}
            </span>
          </div>
        </div>
        <Title variant="subtitle1">Unassigned Teachers List</Title>
        <div className="allocate-teacher__assign--filters row g-0">
          <div className="col-5">
            <Input
              placeholder="Search..."
              id="search"
              onChange={(e) => setSearchEmp(e.target.value)}
            />
          </div>
          <div className="col"></div>
          {USE_HR_GRADE ? (
            <div className="col-3">
              <Autocomplete
                classes={classes}
                options={dropDown}
                openOnFocus
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                    label="Employee Grade"
                    onChange={(e) => setSearchGrade(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="allocate-teacher__assign--tableblock">
          <TableContainer
            className="allocate-teacher__assign--table"
            onScroll={handleScroll}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Academics_Table.AllocateTeachers.TeachersAllocationList.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return <TableCell key={index}>{th.labelName}</TableCell>;
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {employees.map((emp, index) => {
                  return (
                    <TableRow>
                      <TableCell className="allocate-teacher__assign--table--slno">
                        {pageFrom ===
                        TeachersAssociationPageFrom.SUBJECT_TO_TEACHERS ? (
                          <Checkbox
                            checked={pr_emp_ids.has(emp.node.id)}
                            onChange={(e) => {
                              const { checked } = e.target;
                              const newSet = new Set(pr_emp_ids);
                              if (checked) {
                                newSet.add(emp.node.id);
                                setPrEmpIds(newSet);
                              } else {
                                newSet.delete(emp.node.id);
                                setPrEmpIds(newSet);
                              }
                            }}
                          />
                        ) : null}
                        &nbsp;{index + 1}
                      </TableCell>
                      <TableCell className="allocate-teacher__assign--table--id">
                        {emp.node.emp_id}
                      </TableCell>
                      <TableCell>
                        {`${emp.node.emp_first_name} ${emp.node.emp_middle_name} ${emp.node.emp_last_name}`}
                      </TableCell>
                      <TableCell>
                        {emp.node.pr_designation_details.designation_desc}
                      </TableCell>
                      <TableCell
                        className="allocate-teacher__assign--table--count"
                        id="td-center"
                      >
                        {emp.node.emp_experience
                          ? emp.node.emp_experience
                          : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="row g-0">
          <div className="col">
            {pageFrom === TeachersAssociationPageFrom.SUBJECT_TO_TEACHERS ? (
              <Button mode="save" onClick={handleSubjectAssoci} />
            ) : null}
            <Button mode="cancel" onClick={() => setModal(false)} />
          </div>
          <div className="col">
            <div className="student-total-count">
              Total Teachers : <b>{TeachersTotalCount}</b>
            </div>
          </div>
        </div>
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Assign;
