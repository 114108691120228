import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../context/context";
import {
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
} from "../../../../utils/Enum.types";
import { msgType, responseType } from "../../../../utils/Form.types";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import { useMutation } from "@apollo/client";
import EmployeeList from "../../Employee/List/Index";
import Home from "../../Home/Index";
import Modal from "react-modal";
import Avatar from "../../../../images/Avatar.svg";
import Close from "../../../../images/Close.svg";
import { ResetEmpPassword } from "../../queries/employee/mutation";
import { Title } from "../../../../stories/Title/Title";
import { Keys } from "../../../../utils/Enum.keys";
import { Button } from "../../../../stories/Button/Button";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

import LoadingModal from "../../../../pages/LoadingModal";
import MessageModal from "../../../../pages/MessageModal";
import useEmployee, { empQueryTypes } from "../../hooks/useEmployee";
import DataFetch from "../../../../images/Edit.svg";

import useToken from "../../../../customhooks/useToken";
import { payloadTypes } from "../../../../context/reducer";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import {
  EMPTY_RESPONSETYPE_OBJECT,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import usePayRollMastersConfig from "../../hooks/usePayRollMastersConfig";
import useEmpDetailsById from "../../hooks/useEmpDetailsById";
import { toInputStandardDate } from "../../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";

const EmpResetPassword = () => {
  const listClasses = ListAutoCompleteStyles();
  const listTextClasses = ListAutoCompleteTextStyles();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { user_details } = useLoggedInUserDetails();

  const [showPassword, setShowPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState("");
  const { dispatch, state } = useContext(AppContext);
  const [empModal, setEmpModal] = useState(false);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [searchEmployee, setSearchEmployee] = useState("");

  const {
    USE_HR_CATEGORY,
    USE_HR_DEPARTMENT,
    USE_HR_DESIGNATION,
    USE_HR_JOBTYPE,
  } = usePayRollMastersConfig();
  const { token } = useToken();
  const { empDetails } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.EMPS_WITH_ASSIGNED_LEAVES,
    searchEmployee,
    state.employeeId
  );
  const { employeeFormData } = useEmpDetailsById();

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.DEFAULT_PASSWORD
  );

  const [passwordReset, { loading: updationLoading }] =
    useMutation(ResetEmpPassword);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const resetForm = async () => {
    await passwordReset({
      variables: {
        token,
        pr_emp_id: state.employeeId,
        inst_id: InstId,
        user_details,
        password: resetPassword,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Password reset Successfull",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      //   handleClear(clearType.CLEAR_ALL);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (configData.data && !configData.loading) {
      setResetPassword(
        configData.data?.GetSwConfigVariables[0].config_string_value
      );
    }
  }, [configData.data, configData.loading]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Reset Employee Password</Title>

      <div className="student-reset-password">
        <div className="row g-0 student-reset-password__details">
          <div className="col">
            <div className="student-reset-password__details--imagefield">
              <Autocomplete
                classes={listClasses}
                options={empDetails.responseType}
                openOnFocus
                value={
                  empDetails.responseType.find(
                    ({ value }) => value === state.employeeId
                  ) ?? EMPTY_RESPONSETYPE_OBJECT
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: (newValue as responseType).value },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: 0 },
                    });
                    setSearchEmployee("");
                    // handleClear(clearType.CLEAR_ALL);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: 0 },
                    });
                  }
                  if (e.key === Keys.ENTER) {
                    // leaveLedgerInputRef.focus();
                  }
                }}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Employee ID"
                    autoFocus
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchEmployee(e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: listTextClasses.formControlRoot }}
                  />
                )}
              />
              <img
                className="data-fetch-icon"
                src={DataFetch}
                alt="/"
                onClick={() => setEmpModal(!empModal)}
              />
            </div>
            {USE_HR_DESIGNATION && (
              <TextField
                label="Designation"
                InputLabelProps={{ shrink: true }}
                disabled
                value={employeeFormData?.designation}
                className="emp-assign-leave-ledger__textfield"
              />
            )}
            {USE_HR_JOBTYPE && (
              <TextField
                label="Job Type"
                InputLabelProps={{ shrink: true }}
                disabled
                value={employeeFormData?.job_type}
                className="emp-assign-leave-ledger__textfield"
              />
            )}
          </div>
          <div className="col">
            <TextField
              label="Name"
              InputLabelProps={{ shrink: true }}
              disabled
              value={employeeFormData?.emp_name}
              className="emp-assign-leave-ledger__textfield"
            />
            {USE_HR_DEPARTMENT && (
              <TextField
                label="Department"
                InputLabelProps={{ shrink: true }}
                disabled
                value={employeeFormData?.department}
                className="emp-assign-leave-ledger__textfield"
              />
            )}
            <TextField
              label="Qualification"
              InputLabelProps={{ shrink: true }}
              disabled
              value={employeeFormData?.emp_qualification}
              className="emp-assign-leave-ledger__textfield"
            />
          </div>
          <div className="col">
            {USE_HR_CATEGORY && (
              <TextField
                 label="Category"
                InputLabelProps={{ shrink: true }}
                value={employeeFormData?.category}
                disabled
                className="emp-assign-leave-ledger__textfield"
              />
            )}
            <TextField
              label="Year of Experience"
              InputLabelProps={{ shrink: true }}
              value={employeeFormData?.emp_experience}
              disabled
              className="emp-assign-leave-ledger__textfield"
            />
            <TextField
              className="emp-assign-leave-ledger__textfield"
              label="Email"
              value={employeeFormData?.emp_email}
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <TextField
              label="Date of Join"
              InputLabelProps={{ shrink: true }}
              value={toInputStandardDate(employeeFormData?.emp_doj!)}
              disabled
              type="date"
              className="emp-assign-leave-ledger__textfield--date"
            />
          </div>
          <div className="col-1 h-100 emp-assign-leave-ledger__profile">
            <img src={Avatar} alt="/" />
          </div>
        </div>
        <div className="student-reset-password__form">
          <div className="student-reset-password__form--details row g-0">
            <div className="col-4">
              <TextField
                className="student-reset-password__form--details--pwd"
                label="Password"
                value={resetPassword}
                disabled
                onChange={(e) => setResetPassword(e.target.value)}
                InputLabelProps={{ shrink: true }}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <Button mode="save" onClick={resetForm} />
          <Button mode="back" onClick={() => navigate(-1)} />

          {/* student-modal */}
          <Modal
            shouldCloseOnOverlayClick={true}
            isOpen={empModal}
            style={StudentModalStyles}
            ariaHideApp={false}
          >
            <div className="modal-flex h-100">
              <div className="modal-flex__data h-100">
                <EmployeeList
                  pageType={PageFor.MODAL}
                  setModalFlag={setEmpModal}
                  queryType={empQueryTypes.GENERAL}
                />
              </div>
              <div className="modal-flex__image">
                <img
                  onClick={() => setEmpModal(!empModal)}
                  src={Close}
                  alt="/"
                  className="modal-close-icon"
                />
              </div>
            </div>
          </Modal>
        </div>
      </div>
      <LoadingModal flag={updationLoading} />

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default EmpResetPassword;
