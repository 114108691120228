import {
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";

import { Button } from "../../../stories/Button/Button";
import Input from "../../../stories/Input/Input";
import { Title } from "../../../stories/Title/Title";

import {
  StudentsTitlesType,
  StudentTablesTitleType,
} from "../../../Types/Student/json";
import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
  width,
} from "../../../utils/constants";
import {
  StudentsTableType,
  StudentReportType,
  StudentListType,
  PageNumbers,
  Operation,
  SortBy,
  Direction,
  SeparatorTypes,
  ExcelPageHeader,
  ExcelAlignment,
  InstitutionConfigurationTypes,
  FileUploadParams,
} from "../../../utils/Enum.types";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Eduate from "../../../images/Eduate_Logo_image.png";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleFormEvent,
  isOptionEqualToValue,
  removeMoreSpace,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import Home from "../Home/Index";
import { useMutation } from "@apollo/client";
import { UpdateStudentsRegNum } from "../../../queries/students/mutations/update";

import LoadingModal from "../../../pages/LoadingModal";
import ConfigurationSettings from "../configurations/general/Index";
import {
  ConfigurationsModalStyles,
  EditModalCustomStyles,
} from "../../../styles/ModalStyles";
import { Label } from "../../../stories/Label/Label";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";

import closeImg from "../../../images/Close.svg";
import Settings from "../../../images/Settings.svg";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import useToken from "../../../customhooks/useToken";
import { Keys } from "../../../utils/Enum.keys";
import useDropdownData from "../../../customhooks/useDropdownData";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../../pages/MessageModal";
import { msgType, responseType } from "../../../utils/Form.types";
import { GetStudents } from "../../../queries/students/list/newApi";
import { studentQueryTypes } from "../../../utils/studentqueryEnum.types";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { SectionsBySemIds } from "../../../queries/sections/list/byId";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  F4_CELL,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../Library/Constants";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import useServerDateandTime from "../../Library/customHooks/useServerDateandTime";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import StudentTotalCount from "./Components/StudentTotalCount";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useMasterTableJson from "../../../json/useMasterTableJson";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import useAcdDropDownData from "../../Academics/hooks/useAcdDropDownData";
import useAcdStudentsForAdmission, {
  StudentAcdNode,
  StudentEdges,
} from "../../Academics/hooks/useAcdStudentsForAdmission";

const { Students } = require("../../../json/title.json");
export enum NewPage {
  NEXTPAGE = "nextPage",
}
const AllotRegNo = () => {
  const { InstId } = useParams();
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const formClasses = formAutoCompleteStyles();
  const formTextClasses = formAutoCompleteTextStyles();
  const { token } = useToken();
  const { Masters_Table } = useMasterTableJson();
  const { state } = useContext(AppContext);

  const navigate = useNavigate();
  const [prefix, setPrefix] = useState("");

  const [suffix, setSuffix] = useState("");
  const [seperator, setSeparator] = useState("");
  const [widths, setWidths] = useState(0);
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [searchData, setSearchData] = useState("");
  const [registerNumber, setRegisterNumber] = useState("");
  const [studentReportType, setStudentReportType] = useState(
    StudentReportType.STDS_WITHOUT_REGISTER_NUMBER
  );
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [saveModal, setSaveModal] = useState(false);
  const [configurationModal, setConfigurationModal] = useState(false);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [EditMode, setEditMode] = useState({
    status: false,
    rowKey: 0,
  });
  const separatorType = Object.values(SeparatorTypes).map((value) => {
    return value;
  });

  const { user_details } = useLoggedInUserDetails();
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.STD_REG_NUMBER_AUTO_INCREMENT
  );
  const excelPageHeader =
    studentReportType === StudentReportType.NOT_REGISTERED
      ? "UnRegistered Students"
      : StudentReportType.GENERAL
      ? " All Students RegisterNo"
      : "Students Register Number";
  const { InstDetails } = useInstDetails(1);
  const { config_boolean_value: registerNumberIncrementFlag } =
    configData.data?.GetSwConfigVariables[0] || {};
  const [UpdateStudentRegNums, { loading: updationLoading }] = useMutation(
    UpdateStudentsRegNum,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    ROWS_PER_PAGE,
    0,
    searchData,
    studentReportType,
    EMPTY_STRING,
    []
  );
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.ALLOT_STUDENT_REGISTER_NO
  );
  const { configData: ConfigLogo } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    ConfigLogo?.data?.GetSwConfigVariables[0].config_boolean_value;

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setCategorySelected(null);
    setSearchData("");
  };

  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
  } = useInstLabels();

  const onEdit = (item: StudentAcdNode) => {
    setEditMode({
      status: true,
      rowKey: item.id,
    });

    if (item.inc_no) {
      setRegisterNumber(item.inc_no);
    }
  };
  const incrementNumber = (item: StudentAcdNode) => {
    onEdit(item);
    if (registerNumberIncrementFlag && !item.inc_no) {
      setRegisterNumber((Number(registerNumber) + 1).toString());
    }
    if (!registerNumberIncrementFlag && !item.std_reg_no) {
      setRegisterNumber(EMPTY_STRING);
    }
  };

  const HandleSubmit = (type: NewPage) => {
    const std_reg_data = students?.map(({ node }) => ({
      student_id: node.id,
      std_reg_no: node.std_reg_no,
    }));
    UpdateStudentRegNums({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        std_reg_data,
      },
      refetchQueries: [
        {
          query: GetStudents,
          variables: {
            admNo: EMPTY_STRING,
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,

            first: ROWS_PER_PAGE,
            name: EMPTY_STRING,
            token,
            direction: Direction.ASC,
            sortBy: SortBy.FIRST_NAME,
            input: {
              std_query_type: studentQueryTypes.NOT_REGISTERED_BY_INST_ID,
              ids: [Number(InstId)],
            },
          },
        },
        {
          query: GetStudents,
          variables: {
            admNo: EMPTY_STRING,
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            name: EMPTY_STRING,
            token,
            direction: Direction.ASC,
            sortBy: SortBy.FIRST_NAME,
            input: {
              std_query_type: studentQueryTypes.BY_INST_ID,
              ids: [Number(InstId)],
            },
          },
        },
        {
          query: SectionsBySemIds,
          variables: {
            sem_ids: [Number(semesterSelected?.value)],
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        if (type === NewPage.NEXTPAGE) {
          setSaveModal(!saveModal);
        } else
          setMessage({
            message: "Sucessfully Alloted Register Number",
            flag: true,
            operation: Operation.UPDATE,
          });
      }
    });
  };
  const RegisterNoHeaders = [
    "SlNo",
    "Admission No",
    "Name",
    USE_DEPARTMENT_KEY ? `${departmentLabel}` : null,
    USE_BRANCH_KEY ? `${branchLabel}` : null,
    USE_CLASS_KEY ? `${classLabel}` : null,
    "Register No",
  ];
  const studentCol = [
    { title: "Sl", field: "SlNo" },
    { title: "Admission No", field: "AdmNo" },
    { title: "Name", field: "Name" },
    USE_DEPARTMENT_KEY ? { title: `${departmentLabel}`, field: "Dept" } : null,
    USE_BRANCH_KEY ? { title: `${branchLabel}`, field: "Branch" } : null,
    USE_CLASS_KEY ? { title: `${classLabel}`, field: "Class" } : null,
    { title: "Register No", field: "RegNo" },
  ];

  const stdData = students.map(({ node }, index) => ({
    SlNo: index + 1,
    AdmNo: node.std_adm_no,
    Name: `${node.first_name} ${node.middle_name} ${node.last_name}`,
    Dept: USE_DEPARTMENT_KEY ? node.acd_dept.dept_desc : null,
    Branch: USE_BRANCH_KEY ? node.acd_branch.branch_desc : null,
    Class: USE_CLASS_KEY ? node.acd_class.class_desc : null,
    RegNo: node.std_reg_no,
  }));

  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(Eduate)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const doc = new jsPDF("landscape", "mm", "a4");
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(18);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(240, 240, 240);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0);
                  doc.text(
                    `${InstDetails?.data?.nodes[0].inst_name}`,
                    80,
                    startY
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_address}`,
                    120,
                    startY + 7
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_place},${InstDetails?.data?.nodes[0]?.inst_pin}`,
                    120,
                    startY + 12
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `Fin-year -${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    45,
                    startY + 21
                  );
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text(excelPageHeader, 120, startY + 21);
                  doc.setFontSize(13);
                  doc.text(
                    `As-on-date:${toStandardDate(serverDate)}`,
                    230,
                    startY + 21
                  );
                  doc.addImage(result, "JPEG", 15, 5, 22, 22);
                  if (configLogo) {
                    doc.addImage(result2, "JPEG", 250, 5, 20, 20);
                  }
                }

                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: studentCol.map((col) => ({
                    ...col,
                    dataKey: col?.field,
                    styles: { fontSize: 18 },
                  })),

                  body: stdData,
                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: function (data) {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);

                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                });

                doc.save(
                  `${InstDetails?.data?.nodes[0]?.inst_name} ${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  } `
                );
              });
            });
        });
      });
  };
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(excelPageHeader);

    const dataRows = stdData
      ? stdData?.map((item) => [
          item.SlNo,
          item.AdmNo,
          item.Name,
          item.Dept,
          item.Branch,
          item.Class,
          item.RegNo,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 20;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });
          if (configLogo) {
            worksheet.addImage(imageV, "G1:G3");
          }
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");
                  worksheet.mergeCells(1, 1, 1, 7);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails?.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails?.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:G2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails?.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails?.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:G3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = excelPageHeader;
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:E4");
                  const mergedDate: Cell = worksheet.getCell(F4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("F4:G4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < RegisterNoHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = RegisterNoHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, excelPageHeader);
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };
  const handleClose = () => {
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const addPrefixSuffix = (inc_no: string) => {
    let finalPrefix = "";
    let finalSuffix = "";
    if (prefix.length > 0) {
      finalPrefix += prefix + seperator;
    }
    if (suffix.length > 0) {
      finalSuffix += seperator + suffix;
    }
    let number = "";
    if (widths && inc_no.length < widths) {
      const width = widths - inc_no.length;
      number = "0".repeat(width) + inc_no;
    } else {
      number = inc_no;
    }
    return removeMoreSpace(finalPrefix + number + finalSuffix);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop + 1;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight + 1 >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !AcademicsStudentData.loading) {
        AcademicsStudentData.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: AcademicsStudentData.data
                  ? AcademicsStudentData.data.GetAcdStudents.totalCount!
                  : 0,
              },
            };
          },
        });
      }
    }
  };

  useEffect(
    () => {
      if (AcademicsStudentData.data && !AcademicsStudentData.loading) {
        const newData = AcademicsStudentData.data.GetAcdStudents.edges.map(
          (edge) => {
            return {
              ...edge,
              node: {
                ...edge.node,
                isChecked: true,
                // set default value of isChecked to true
              },
            };
          }
        );

        if (endCursor) {
          // If we have already fetched some data, we need to check if there
          // are any duplicates in the new data, and update their isChecked
          // property based on the existing data.
          // const filteredStudents = students.filter(
          //   (student) => !student.node.isChecked
          // );

          const updatedNewData = newData.map((newStudent) => {
            const filteredStudent = students.find(
              (student) => student.node.id === newStudent.node.id
            );
            if (filteredStudent) {
              return {
                ...newStudent,
                node: {
                  ...newStudent.node,
                  // isChecked: filteredStudent.node.isChecked,
                },
              };
            }
            return newStudent;
          });
          setStudents(updatedNewData);
        } else {
          setStudents(newData);
        }
        setEndCursor(
          AcademicsStudentData.data.GetAcdStudents.pageInfo.endCursor
        );
        setHasNextPage(true);
      }
    },
    // eslint-disable-next-line
    [
      AcademicsStudentData.data,
      AcademicsStudentData.loading,
      AcademicsStudentData.fetchMore,
    ]
  );
  const itemsSet = new Set(students.map(({ node }) => node.inc_no));
  const clearRegNos = () => {
    setStudents(
      students.map((item) => ({
        ...item,
        node: {
          ...item.node,
          inc_no: "",
          std_reg_no: "",
        },
      }))
    );
    setRegisterNumber(EMPTY_STRING);
  };
  const setRegNoAndIncrementNo = (index: number) => {
    if (
      !itemsSet.has(registerNumber) ||
      registerNumber === students[index].node.inc_no
    ) {
      students[index].node.inc_no = registerNumber;
      students[index].node.std_reg_no = addPrefixSuffix(registerNumber);
      students[index].node.visited = true;
      if (index === students.length - 1) {
        setEditMode({
          rowKey: -1,
          status: false,
        });
      } else if (index !== students.length - 1) {
        incrementNumber(students[index + 1].node);
      }
    } else {
      setMessage({
        message: `Duplicate Register Number  at ${index + 1} for ${
          registerNumber ===
          `${students[index].node.first_name} ${students[index].node.middle_name} ${students[index].node.last_name}`
        }`,
        flag: true,
        operation: Operation.NONE,
      });
    }
  };
  const filterInstConfigHeaders = (title: StudentTablesTitleType) => {
    const departmentCheck =
      title.labelName === departmentLabel && USE_DEPARTMENT_KEY;
    const branchCheck = title.labelName === branchLabel && USE_BRANCH_KEY;
    const classCheck = title.labelName === classLabel && USE_CLASS_KEY;

    const otherThanInstMasters =
      title.labelName !== departmentLabel &&
      title.labelName !== branchLabel &&
      title.labelName !== classLabel &&
      title.labelName !== "Roll Number";

    return (
      title.type === StudentsTableType.ALLOT_REGISTER_NUMBER &&
      (departmentCheck || branchCheck || classCheck || otherThanInstMasters)
    );
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col">
          <Title>
            {Students.Titles.map(
              (title: StudentsTitlesType, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {title.Allot_RegistrationNo_For_Students}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>

        <div className="configuration-settings">
          {USE_CONFIG_KEY && (
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          )}
        </div>
      </div>
      <div className="allot-regno">
        <form className="row g-0 allot-regno__options">
          <div className="col-1 allot-regno__search">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>

          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={departmentDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentSelected)
                }
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={branchDropDown!}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, branchSelected)
                }
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={classDropDown!}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, classSelected)
                }
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, semesterSelected)
                }
                options={semesterDropDown!}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={sectionDropDown!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, sectionSelected)
                }
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue);
                  } else {
                    setSectionSelected(null);
                  }
                  setCategorySelected(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          <div className="col"></div>
          <div className="col-1">
            <Button
              mode="clear"
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                clear();
              }}
              className="allot-regno__options--button"
            />
          </div>
        </form>

        <div className="allot-regno__radio-buttons row g-0">
          <div className="col allot-regno__radio-buttons--group">
            <RadioGroup
              row
              defaultValue="unregistered_students"
              name="radio-buttons-group"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value === StudentListType.UNREGISTERED_STUDENTS) {
                  setStudentReportType(
                    StudentReportType.STDS_WITHOUT_REGISTER_NUMBER
                  );
                } else {
                  setStudentReportType(StudentReportType.ACD_STD_GENERAL);
                }
              }}
            >
              <FormControlLabel
                className="userneeds__text"
                value="unregistered_students"
                control={<Radio />}
                label="Unregistered Students"
              />
              <FormControlLabel
                className="userneeds__text"
                value="all_students"
                control={<Radio />}
                label="All Students"
              />
            </RadioGroup>
          </div>
          <div className="col-1">
            <Label>Prefix</Label>
            <Input
              name="prefix"
              value={prefix}
              onChange={(e) => setPrefix(e.target.value)}
            />
          </div>
          <div className="col-1 ">
            <Label>Seperator</Label>
            <Autocomplete
              classes={formClasses}
              options={separatorType}
              openOnFocus
              forcePopupIcon
              value={seperator}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSeparator(newValue);
                } else {
                  setSeparator(EMPTY_STRING);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setSeparator(EMPTY_STRING);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  classes={{
                    root: formTextClasses.formControlRoot,
                  }}
                />
              )}
            />
          </div>
          <div className="col-1">
            <Label>Suffix</Label>
            <Input
              name="suffix"
              value={suffix}
              onChange={(e) => setSuffix(e.target.value)}
            />
          </div>
          <div className="col-1">
            <Label>Width</Label>
            <Autocomplete
              classes={formClasses}
              options={width}
              openOnFocus
              forcePopupIcon
              value={widths}
              onChange={(e, newValue) => {
                if (newValue) {
                  setWidths(newValue);
                } else {
                  setWidths(0);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setWidths(0);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  classes={{
                    root: formTextClasses.formControlRoot,
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="allot-regno__tableblock">
          {!students?.length ? (
            <b className="nodata">Sorry, No Students Found</b>
          ) : (
            <TableContainer
              className="allot-regno__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Masters_Table.Students.Table_Headers.filter(
                      (title: StudentTablesTitleType) =>
                        filterInstConfigHeaders(title)
                    )?.map(
                      (title: StudentTablesTitleType, index: React.Key) => {
                        return (
                          <TableCell key={index}>{title.labelName}</TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students?.map(({ node }, index: number) => (
                    <TableRow key={index}>
                      <TableCell
                        id="td-center"
                        className="allot-regno__table--slno"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell className="allot-regno__table--admno">
                        {node.std_adm_no}
                      </TableCell>
                      <TableCell>{`${node.first_name} ${node.middle_name} ${node.last_name}`}</TableCell>
                      {USE_DEPARTMENT_KEY ? (
                        <TableCell className="allot-regno__table--desc">
                          {node.acd_dept.dept_desc}
                        </TableCell>
                      ) : null}
                      {USE_BRANCH_KEY ? (
                        <TableCell className="allot-regno__table--desc">
                          {node.acd_branch.branch_desc}
                        </TableCell>
                      ) : null}
                      {USE_CLASS_KEY ? (
                        <TableCell className="allot-regno__table--desc">
                          {node.acd_class.class_desc}
                        </TableCell>
                      ) : null}
                      <TableCell
                        className="allot-regno__table--input editCell"
                        onClick={() => onEdit(node)}
                      >
                        {EditMode.status && EditMode.rowKey === node.id ? (
                          <input
                            type="text"
                            value={registerNumber}
                            autoFocus={
                              EditMode.status && EditMode.rowKey === node.id
                            }
                            onFocus={(e) => e.target.select()}
                            onBlur={() => {
                              setEditMode({
                                rowKey: -1,
                                status: false,
                              });
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (!isNaN(Number(e.target.value)))
                                setRegisterNumber(e.target.value);
                            }}
                            onKeyDown={(e: React.KeyboardEvent) => {
                              if (e.key === Keys.ENTER) {
                                setRegNoAndIncrementNo(index);
                              }
                            }}
                          />
                        ) : (
                          <>{node.std_reg_no}</>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div className="row g-0">
          <div className="col">
            {students.length > 0 && (
              <>
                <Button
                  mode="save"
                  type="submit"
                  disabled={!registerNumber}
                  onClick={HandleSubmit}
                />
                <Button mode="clear" type="button" onClick={clearRegNos} />
              </>
            )}
            <Button onClick={downloadPdf} mode="pdf" />
            <Button onClick={downloadExcel} mode="export" />

            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col-2 allot-regno__total">
            <StudentTotalCount
              totalCount={
                AcademicsStudentData.data
                  ? AcademicsStudentData.data.GetAcdStudents.totalCount!
                  : 0
              }
            />
          </div>
        </div>
      </div>

      <LoadingModal flag={updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={saveModal}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <Label>
          Navigating to next page will save the current Register Numbers
        </Label>
        <div className="row">
          <div className="col">
            <Button mode="yes" onClick={() => HandleSubmit(NewPage.NEXTPAGE)} />
            <Button mode="cancel" onClick={() => setSaveModal(false)} />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.ALLOT_STUDENT_REGISTER_NO}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={closeImg}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AllotRegNo;
