import React, { useContext, useEffect, useRef, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import Close from "../../../../../images/Close.svg";
import { Autocomplete, Drawer, TextField } from "@mui/material";
import {
  EMPTY_STRING,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../../utils/constants";
import useStudentDatabyId from "../../../../../customhooks/useStudentDatabyId";
import { getDownloadUrl } from "../../../../../utils/DownloadFile";
import Avatar from "../../../../../images/Avatar.svg";
import { Button } from "../../../../../stories/Button/Button";
import useInstLabels from "../../../../../customhooks/general/useInstLabels";
import Posting from "../../../../../images/Pending_Student_Posting.svg";
import Compare from "../../../../../images/Compare.svg";

import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../../styles/AutocompleteListStyles";
import {
  handleMUISelectEvent,
  isOptionEqualToValue,
  toIsoDate,
} from "../../../../../utils/UtilFunctions";
import { Keys } from "../../../../../utils/Enum.keys";
import { AppContext } from "../../../../../context/context";
import { attendanceOverViewStyles } from "../../../../../styles/DrawerStyles";
import { Label } from "../../../../../stories/Label/Label";
import {
  Direction,
  Operation,
  SortBy,
  StdStatusQueryType,
  StudentStatus,
} from "../../../../../utils/Enum.types";
import {
  msgType,
  optionsType,
  responseType,
} from "../../../../../utils/Form.types";
import {
  formAutoCompleteTextStyles,
  requiredAutoCompleteStyles,
} from "../../../../../styles/AutocompleteStyles";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ChangeStudentStatusToNE,
  StudentNEStatusCancel,
} from "../../../../../queries/students/mutations/update";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../../../Accounts/hooks/useLoggedInUserDetails";
import {
  GetStudentNEActiveByStudentId,
  GetStudentsNeByInstId,
} from "../../../../../queries/students/list/byId";
import { GetStudentsAdmno } from "../../../../../queries/students/list/newApi";
import { studentQueryTypes } from "../../../../../utils/studentqueryEnum.types";
import TextArea from "../../../../../stories/TextArea/TextArea";
import useServerDateandTime from "../../../../Library/customHooks/useServerDateandTime";
import { studentNeData, studentNeVars } from "../../../../../Types/Student";
import MessageModal from "../../../../../pages/MessageModal";
import { AcdYrPostingForStudent } from "../../../../Accounts/queries/FinYearPost/mutation";

import LoadingModal from "../../../../../pages/LoadingModal";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useDropdownData from "../../../../../customhooks/useDropdownData";
import useNotAlloactedIdFromInst from "../../../../../customhooks/useNotAlloactedIdFromInst";
import useInstMasterDataByInstId from "../../../../../customhooks/useInstMasterDataByInstId";
import useActiveAcademicYear from "../../../../Academics/hooks/useActiveAcademicYear";
import { GetAcdYrsFuture } from "../../../../../queries/academicyear/list/byid";
import { GetStudentDetails } from "../../../../../queries/common";
import ConfirmationModal from "../../../../Accounts/FinYrPost/ConfirmationModal";
import { GetAcdStudents } from "../../../../Academics/queries/students/Index";
import { StudentAcdType } from "../../../../../utils/studentAcdEnum.types";
import { studentStatusDropDown } from "../../../../Accounts/Reports/BalanceReports/Reports";
import useInstDetails from "../../../../../customhooks/general/useInstDetails";
export interface FinYrType {
  label: string;
  value: number;
}
interface GetAcdYrsFutureList {
  id: number;
  acd_yr: string;
  acd_st_date: string;
  acd_end_date: string;
  acd_is_curr_yr: boolean;
  inst_id: number;
}
interface GetAcdYrsFutureData {
  GetAcdYrsFuture: GetAcdYrsFutureList[];
}
interface GetAcdYrsFutureVars {
  token: string;
  inst_id: number | string;
  acd_yr_id: number;
}
interface props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const AcdStudentInfo = ({ setModalFlag }: props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const [confirModal, setConfirmModal] = useState(false);

  const requiredClasses = requiredAutoCompleteStyles();
  const formTextClasses = formAutoCompleteTextStyles();

  const drawerClasses = attendanceOverViewStyles();
  const { studentFormData, studentData } = useStudentDatabyId();

  const { InstDetails } = useInstDetails(1);
  const [imageString, setImageString] = useState("");
  const { state } = useContext(AppContext);
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const [selectedAcdYr, setSelectedAcdYr] = useState<FinYrType | null>(null);
  const acdYearRef = useRef<HTMLSelectElement>(null);
  const [status, setStatus] = useState<optionsType | null>(null);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
  } = useInstitutionConfiguration();
  const [remarks, setRemarks] = useState("");
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel
  } = useInstLabels();

  const { activeAcademicYearData } = useActiveAcademicYear();
  const [statusStdModal, setStatusStdModal] = useState(false);
  const { serverDate } = useServerDateandTime();
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const { NotAllocatedDeptId, NotAllocatedCategoryId } =
    useNotAlloactedIdFromInst();

  const statusRef = useRef<HTMLSelectElement>(null);
  // const statusInputRef = statusRef?.current?.childNodes[0]?.childNodes[0]
  //   ?.childNodes[0] as HTMLInputElement;

  const { categoryDropDown } = useInstMasterDataByInstId();
  const [GetFutureAcdYears, { data: AcdYearsDropDown }] = useLazyQuery<
    GetAcdYrsFutureData,
    GetAcdYrsFutureVars
  >(GetAcdYrsFuture);

  const acdYearDropdown =
    AcdYearsDropDown &&
    AcdYearsDropDown.GetAcdYrsFuture.map((res) => ({
      label: res.acd_yr,
      value: res.id,
    }));
  const [GetStudentNeData, { data }] = useLazyQuery<
    studentNeData,
    studentNeVars
  >(GetStudentNEActiveByStudentId, {
    variables: { token, studentId: state.studentId },
  });
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
    masterIds,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0
  );
  const [AcdYearPost, { loading: FinYearLoading }] = useMutation(
    AcdYrPostingForStudent,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [UpdateStudentStatus, { loading: updationloading }] = useMutation(
    ChangeStudentStatusToNE,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [CancelNEstudentStatus, { loading }] = useMutation(
    StudentNEStatusCancel,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handlePostingForStudent = () => {
    AcdYearPost({
      variables: {
        token,
        inst_id: InstId!,
        user_details,
        input: {
          new_dept_id: USE_DEPARTMENT_KEY
            ? departmentSelected && departmentSelected.value
            : NotAllocatedDeptId,
          new_branch_id: masterIds.branchId,
          new_class_id: masterIds.classId,
          new_semester_id: masterIds.semesterId,
          new_section_id: masterIds.sectionId,
          new_category_id: USE_CATEGORY_KEY
            ? categorySelected && categorySelected.value
            : NotAllocatedCategoryId,
          from_acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          to_acd_yr_id: selectedAcdYr ? selectedAcdYr.value : 0,
          student_id: state.studentId,
          std_status: studentData.data
            ? studentData.data.nodes[0].std_status
            : EMPTY_STRING,
        },
      },
      refetchQueries: [
        {
          query: GetAcdStudents,
          variables: {
            acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
            after: null,
            first: ROWS_PER_PAGE,
            input: {
              acd_std_query_type: StudentAcdType.BY_ACD_INST_ID,
              ids: [Number(InstId)],
              str_data: [],
            },
            name: "",
            orderBy: [
              {
                direction: Direction.ASC,
                field: SortBy.STD_ROLL_NO,
              },
            ],
            token,
          },
        },
      ],
    }).then(({ data }) => {
      // handleClear();
      if (data)
        setMessage({
          message: "Academic Year Posting Successfull",
          flag: true,
          operation: Operation.CREATE,
        });
    });
  };
  const handleUpdate = () => {
    if (status && status.label !== "Eligible") {
      UpdateStudentStatus({
        variables: {
          token,
          inst_id: InstId!,
          user_details,
          input: {
            std_still_ne: false,
            std_ne_type: status.value,
            std_ne_reason: remarks,
            std_ne_date: toIsoDate(TODAY_DATE),
            std_ne_acd_year:
              studentData.data && studentData.data.nodes[0].acd_yr.acd_yr,
            std_ne_made_by_staff_id: state.empLoginId,
            student_id: studentData.data?.nodes[0].id,
            inst_id: InstId,
          },
        },
        refetchQueries: [
          {
            query: GetStudentsNeByInstId,
            variables: {
              token,
              inst_id: InstId!,
              query_type: StdStatusQueryType.NE_STATUS_FALSE,
              name: EMPTY_STRING,
              first: ROWS_PER_PAGE,
              after: null,
              orderBy: SortBy.STD_NE_DATE,
              direction: Direction.ASC,
            },
          },
          {
            query: GetStudentDetails,
            variables: { token, ids: [state.studentId] },
          },

          {
            query: GetStudentsAdmno,
            variables: {
              after: null,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              name: EMPTY_STRING,
              token,
              direction: Direction.ASC,
              sortBy: SortBy.FIRST_NAME,
              input: {
                ids: [Number(InstId)!],
                std_query_type: studentQueryTypes.STATUS_EQ_CUR_BY_INST_ID,
              },
            },
          },
          {
            query: GetStudentsAdmno,
            variables: {
              after: null,
              fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
              first: ROWS_PER_PAGE,
              name: EMPTY_STRING,
              token,
              direction: Direction.ASC,
              sortBy: SortBy.FIRST_NAME,
              input: {
                ids: [Number(InstId)!],
                std_query_type: studentQueryTypes.STATUS_NEQ_CUR_BY_INST_ID,
              },
            },
          },
        ],
      }).then(({ data }) => {
        // handleClear();
        if (data)
          setMessage({
            message: "Successfully made Student Not Eligible",
            flag: true,
            operation: Operation.CREATE,
          });
      });

      // handleClear();
    } else {
      CancelNEstudentStatus({
        variables: {
          token,
          id: data ? data.GetStudentNEActiveByStudentId.id : 0,
          inst_id: InstId!,
          user_details,
          input: {
            std_still_ne: true,
            std_ne_type: StudentStatus.CUR,
            std_ne_cancel_reason: remarks,
            std_ne_cancel_date: toIsoDate(serverDate),
            std_ne_cancel_acd_year:
              studentData.data && studentData.data.nodes[0].acd_yr.acd_yr,
            std_ne_cancel_made_by_staff_id: state.empLoginId,
          },
        },
        refetchQueries: [
          {
            query: GetStudentsNeByInstId,
            variables: {
              token,
              inst_id: InstId!,
              query_type: StdStatusQueryType.NE_STATUS_TRUE,
              name: EMPTY_STRING,
              first: ROWS_PER_PAGE,
              after: null,
              orderBy: SortBy.STD_NE_DATE,
              direction: Direction.ASC,
            },
          },
          {
            query: GetStudentDetails,
            variables: { token, ids: [state.studentId] },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            message: "Successfully made Student Eligible",
            flag: true,
            operation: Operation.CREATE,
          });
        }
      });
      // handleClear();
    }
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      statusStdModal ? setStatusStdModal(false) : setModalFlag(false);
      setRemarks(EMPTY_STRING);
      setStatus(null);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      // eslint-disable-next-line
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  useEffect(() => {
    if (state.studentId && token) {
      GetStudentNeData();
    }
  }, [GetStudentNeData, token, state.studentId]);
  useEffect(() => {
    if (token && InstId && state.ActiveAcdYr) {
      GetFutureAcdYears({
        variables: {
          token,
          inst_id: InstId!,
          acd_yr_id: state.ActiveAcdYr.id,
        },
      });
    }
  }, [GetFutureAcdYears, token, state.ActiveAcdYr, InstId]);

  const renderTextField = (label: string, value: string) => (
    <TextField
      label={label}
      value={value}
      className="pending-data-std-posting__std-info--textfield"
      disabled
      InputLabelProps={{ shrink: true }}
    />
  );

  return (
    <>
      <div className="pending-data-std-posting__std-info">
        <div className="pending-data-std-posting__std-info--title">
          <Title>Student Info for Posting</Title>
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <div className="pending-data-std-posting__std-info--select row g-0">
          <div className="col pending-data-std-posting__std-info--row-span">
            {renderTextField("Adm no.", studentFormData.adm_no)}
            {renderTextField("Reg No.", studentFormData.reg_number)}
            {renderTextField(`${branchLabel}`, studentFormData.branch)}
            {renderTextField(
              "Name",
              `${studentFormData.first_name} ${studentFormData.middle_name} ${studentFormData.last_name}`
            )}
            {renderTextField("Father Name", studentFormData.father_name)}
            {renderTextField(`${classLabel}`, studentFormData.class)}
          </div>
          <div className="col-3 student-profile">
            {imageString ? (
              <img src={imageString} alt="/" className="" />
            ) : (
              <img src={Avatar} alt="/" />
            )}
          </div>
        </div>
        <h6 className="pending-data-std-posting__std-info--sub-title">
          Postings Permissible Only in the Subsequent Years
        </h6>
        <div className="pending-data-std-posting__std-info--data">
          <h4>Academic Year Posting</h4>
          <div className="row g-0 justify-content-center">
            <TextField
              label="Current Academic Year"
              className="pending-data-std-posting__std-info--year"
              disabled
              value={
                activeAcademicYearData.data &&
                activeAcademicYearData.data.GetAcdYrActiveByInstId.acd_yr
              }
              InputLabelProps={{ shrink: true }}
              type="year"
            />
          </div>

          <div className="pending-data-std-posting__std-info--flex">
            <TextField
              label="Posting from"
              className="pending-data-std-posting__std-info--year"
              disabled
              value={state.ActiveAcdYr ? state.ActiveAcdYr.acd_yr : 0}
              InputLabelProps={{ shrink: true }}
            />
            <img src={Compare} alt="" />
            <Autocomplete
              classes={classes}
              ref={acdYearRef!}
              options={acdYearDropdown ? acdYearDropdown : []}
              value={selectedAcdYr}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSelectedAcdYr(newValue);
                  // setEndDate(
                  //   dayjs(newValue.value.startDate)
                  //     .add(364, "day")
                  //     .format("YYYY-MM-DD")
                  // );
                } else {
                  setSelectedAcdYr(null);
                }
              }}
              // isOptionEqualToValue={(option) =>
              //   selectedAcdYr &&
              //   option.value.startDate === selectedAcdYr.value.startDate &&
              //   option.value.endDate === selectedAcdYr.value.endDate
              //     ? true
              //     : false
              // }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  if (selectedAcdYr) {
                    handleMUISelectEvent(e);
                  }
                }
                if (e.key === Keys.BACKSPACE) {
                  setSelectedAcdYr(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="To Academic Year"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          {USE_DEPARTMENT_KEY && (
            <div className="pending-data-std-posting__std-info--flex">
              <TextField
                label={`From ${departmentLabel}`}
                className="pending-data-std-posting__std-info--year"
                disabled
                value={studentFormData.dept}
                InputLabelProps={{ shrink: true }}
              />
              <img src={Compare} alt="" />
              <Autocomplete
                classes={classes}
                ref={acdYearRef!}
                options={departmentDropDown ? departmentDropDown : []}
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue);

                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                    setBranchSelected(null);
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`To ${departmentLabel}`}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          )}
          {USE_BRANCH_KEY && (
            <div className="pending-data-std-posting__std-info--flex">
              <TextField
                label={`From ${branchLabel}`}
                className="pending-data-std-posting__std-info--year"
                disabled
                value={studentFormData.branch}
                InputLabelProps={{ shrink: true }}
              />
              <img src={Compare} alt="" />
              <Autocomplete
                classes={classes}
                ref={acdYearRef!}
                options={branchDropDown ? branchDropDown : []}
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`To ${branchLabel}`}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          )}
          {USE_CLASS_KEY && (
            <div className="pending-data-std-posting__std-info--flex">
              <TextField
                label={`From ${classLabel}`}
                className="pending-data-std-posting__std-info--year"
                disabled
                value={studentFormData.class}
                InputLabelProps={{ shrink: true }}
              />
              <img src={Compare} alt="" />
              <Autocomplete
                classes={classes}
                ref={acdYearRef!}
                options={classDropDown ? classDropDown : []}
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`To ${classLabel}`}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          )}
          {USE_SEMESTER_KEY && (
            <div className="pending-data-std-posting__std-info--flex">
              <TextField
                label={`From ${semesterLabel}`}
                className="pending-data-std-posting__std-info--year"
                disabled
                value={studentFormData.semester}
                InputLabelProps={{ shrink: true }}
              />
              <img src={Compare} alt="" />
              <Autocomplete
                classes={classes}
                ref={acdYearRef!}
                options={semesterDropDown ? semesterDropDown : []}
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`To ${semesterLabel}`}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          )}
          {USE_SECTION_KEY && (
            <div className="pending-data-std-posting__std-info--flex">
              <TextField
                label={`From ${sectionLabel}`}
                className="pending-data-std-posting__std-info--year"
                disabled
                value={studentFormData.section}
                InputLabelProps={{ shrink: true }}
              />
              <img src={Compare} alt="" />
              <Autocomplete
                classes={classes}
                ref={acdYearRef!}
                options={sectionDropDown ? sectionDropDown : []}
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue);
                  } else {
                    setSectionSelected(null);
                  }
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, sectionSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`To ${sectionLabel}`}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          )}
          {USE_CATEGORY_KEY && (
            <div className="pending-data-std-posting__std-info--flex">
              <TextField
                label={`From ${categoryLabel}`}
                className="pending-data-std-posting__std-info--year"
                disabled
                value={studentFormData.category}
                InputLabelProps={{ shrink: true }}
              />
              <img src={Compare} alt="" />
              <Autocomplete
                classes={classes}
                ref={acdYearRef!}
                options={categoryDropDown ? categoryDropDown : []}
                value={categorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategorySelected(newValue);
                  } else {
                    setCategorySelected(null);
                  }
                }}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, categorySelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`To ${categoryLabel}`}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          )}

          {/* <div className="pending-data-std-posting__std-info--class-info">
            <div className="pending-data-std-posting__std-info--class-info--from">
              <b>Posting from {classLabel}</b>
              <span>TE First Year</span>
            </div>
            <img src={Transfer} alt="" />
            <div className="pending-data-std-posting__std-info--class-info--to">
              <b>Posting from {classLabel}</b>
              <span>TE First Year</span>
            </div>
          </div> */}

          <div className="pending-data-std-posting__std-info--status">
            <b>Status</b>
            <div>
              <Button>
                {state.studentId && studentData.data
                  ? studentData.data.nodes[0].std_status
                  : EMPTY_STRING}
              </Button>
              <label onClick={() => setStatusStdModal(!statusStdModal)}>
                Change Status to Next Financial Year
              </label>
            </div>
          </div>
          <div className="pending-data-std-posting__std-info--button">
            <Button onClick={handlePostingForStudent}>
              <img src={Posting} alt="" />
              Post Academic Year & Save
            </Button>
          </div>
        </div>
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>

      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={statusStdModal}
        onClose={() => setStatusStdModal(!statusStdModal)}
      >
        <Title>Change Student Status</Title>

        <div>
          <div className="label-grid">
            <Label> Student Status</Label>
            <Autocomplete
              classes={requiredClasses}
              options={studentStatusDropDown}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, status)
              }
              ref={statusRef!}
              value={status}
              openOnFocus
              onChange={(e, newValue) => {
                if (newValue) {
                  setStatus(newValue);
                } else {
                  setStatus(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER) {
                  e.preventDefault();
                  // remarksRef?.current?.focus();
                }
                if (e.key === Keys.BACKSPACE) {
                  setStatus(null);
                }
              }}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  classes={{ root: formTextClasses.formControlRoot }}
                  {...params}
                  fullWidth
                  required
                />
              )}
            />
          </div>
          <br></br>
          <div className="label-grid">
            <Label>Remarks</Label>
            <TextArea
              // textAreaRef={remarksRef!}
              value={remarks}
              // onKeyDown={(e: React.KeyboardEvent) => {
              //   if (e.key === Keys.ENTER && !e.shiftKey) {
              //     e.preventDefault();
              //     eligiblePagetype === StudentReportType.ELIGIBLE
              //       ? eligibleRef?.current?.focus()
              //       : saveRef?.current?.focus();
              //   }
              // }}
              required
              onChange={(e) => {
                setRemarks(e.target.value);
              }}
              rows={3}
            />
          </div>
          <Button mode="save" onClick={handleUpdate} />
          <Button
            mode="cancel"
            onClick={() => setStatusStdModal(!statusStdModal)}
          />
        </div>
      </Drawer>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <ConfirmationModal
        handleFinYearPost={handlePostingForStudent}
        isOpen={confirModal}
        setOpenModal={setConfirmModal}
      />
      <LoadingModal flag={FinYearLoading || loading || updationloading} />
    </>
  );
};

export default AcdStudentInfo;
