import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";

import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";

import { TableHeaderProps } from "../../../../Types/Tables";
import {
  DEFAULT_TIME,
  EMPTY_STRING,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";
import {
  BankOrCash,
  DebitOrCredit,
  Direction,
  ModuleName,
  Operation,
  PageFor,
  SortBy,
  transactionTypeEnum,
  VoucherBookKey,
  VoucherPageType,
} from "../../../../utils/Enum.types";
import {
  DateRange,
  getFileIcon,
  MonthName,
  toIsoDate,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import NewVoucher from "../NewVoucher";
import Close from "../../../../images/Close.svg";
import ViewStatus from "../../../../images/ViewStatus.svg";
import {
  ApprovedStatusModal,
  EditModalCustomStyles,
  PrintConfigModalStyles,
  PrintModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import { useStyles } from "../../../../styles/TooltipStyles";
import Status from "./Status";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import {
  MkCkQueryType,
  VoucherApprovalStatus,
  VoucherQueryTypes,
} from "../../common/Enum.types";
import {
  AddAcctVoucherMasterAndDetails,
  UpdateAcctVoucherMkCkAssignedStatusMutation,
} from "../../queries/receipts/mutation/Index";
import { msgType, responseType } from "../../../../utils/Form.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import useEmployee, { empQueryTypes } from "../../../HR/hooks/useEmployee";
import useEmpDetailsById from "../../../HR/hooks/useEmpDetailsById";
import {
  formatter,
  isOptionEqualToValue,
  toInputStandardDate,
  toStandardCase,
} from "../../../../utils/UtilFunctions";
import MessageModal from "../../../../pages/MessageModal";
import { AccountMkckDetailsByMasterVoucherId } from "../../../../queries/common";
import {
  AcctVoucherMasterMkCk,
  MkckNodeData,
} from "../../hooks/useMkckVoucherData";
import { singleNodeVars } from "../../../../Types/Accounting";
import { AppContext } from "../../../../context/context";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { payloadTypes } from "../../../../context/reducer";
import {
  GetAcctVouchers,
  GetAcctVouchersMkCk,
  GetVoucherNumber,
} from "../../queries/Vouchers/query";
import dayjs from "dayjs";
import useVoucherNumber from "../../hooks/useVoucherNumber";
import VoucherReceiptPrint from "../../../Print/Accounts/Vouchers/VoucherPrint";
import PerModuleConfiguration from "../../../Configurations/PerModuleConfiguration";
import { SwConfigQueryType } from "../../../HR/enums/Enum.types";
import Settings from "../../../../images/Settings.svg";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import Download from "../../../../images/DocumentsDownload.svg";
import TextArea from "../../../../stories/TextArea/TextArea";
import useAcctTableJson from "../../json/useAcctTableJson";
import useLoggedInUserDetails from "../../hooks/useLoggedInUserDetails";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import {
  AddAcctVoucherMasterAndDetailsData,
  AddAcctVoucherMasterAndDetailsVars,
} from "../../../../Types/Accounting/mutations";

export const enum PendingStatusFor {
  CHECKER = "CHECKER",
  MAKER = "MAKER",
}

interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  pageType: MkCkQueryType;
  type: PendingStatusFor;
}

const PaymentVoucherModal = ({ setModalFlag, pageType, type }: Props) => {
  const [statusModal, setStatusModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const tooltipClasses = useStyles();
  const [approvalRemarks, setApprovalRemarks] = useState("");
  const { Accounts_Table } = useAcctTableJson();
  const { token } = useToken();
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();

  const { state, dispatch } = useContext(AppContext);
  const [approveModal, setApproveModal] = useState(false);
  const [forwardModal, setForwardModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [imageString, setImageString] = useState("");
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { InstId } = useParams();
  const { firstDay, lastDay } = DateRange(new Date().toString()) || {};
  const { serverDate } = useServerDateandTime();
  const { format } = formatter;
  const handleToggleApprove = () => setApproveModal(!approveModal);
  const handleToggleforward = () => setForwardModal(!forwardModal);
  const handleToggleReject = () => setRejectModal(!rejectModal);
  const [printModal, setPrintModal] = useState(false);
  const [printConfig, setPrintConfigModal] = useState(false);

  const [reportingmanagerId, setReportingManagerId] =
    useState<responseType | null>(null);

  const [mkckData, setMkckData] = useState<AcctVoucherMasterMkCk>();
  const { user_details } = useLoggedInUserDetails();
  const [updateMkckVoucher] = useMutation(
    UpdateAcctVoucherMkCkAssignedStatusMutation,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GenerateVoucherReceipts] = useMutation<
    AddAcctVoucherMasterAndDetailsData,
    AddAcctVoucherMasterAndDetailsVars
  >(AddAcctVoucherMasterAndDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const { empolyeeData } = useEmpDetailsById(false, true);

  const [GetMkckVData] = useLazyQuery<MkckNodeData, singleNodeVars>(
    AccountMkckDetailsByMasterVoucherId
  );

  const { empDetails } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.REPORTING_MANAGER,
    "",
    0,
    empolyeeData.data?.node.pr_dept_details.id!,
    empolyeeData.data?.node.pr_designation_details.id!
  );

  let totalDebitAmount = 0;
  let totalCreditAmount = 0;
  // eslint-disable-next-line
  totalCreditAmount = mkckData?.acct_voucher_details_mkck.filter(
    (acct) => acct.vo_cr_db === DebitOrCredit.CREDIT
  )[0]?.vo_cr_total!;
  totalDebitAmount = mkckData?.acct_voucher_details_mkck.filter(
    (acct) => acct.vo_cr_db === DebitOrCredit.DEBIT
  )[0]?.vo_db_total!;

  const handleApprovalRequest = () => {
    updateMkckVoucher({
      variables: {
        token,
        inst_id: InstId,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        user_details,
        mkck_voucher_id: state.mkckId,
        input: {
          remarks: approvalRemarks,
          status: approveModal
            ? VoucherApprovalStatus.Approved
            : forwardModal
            ? VoucherApprovalStatus.Forwarded
            : VoucherApprovalStatus.Rejected,
          pr_emp_id_assigned: approveModal
            ? mkckData?.created_emp_details.id
            : forwardModal
            ? reportingmanagerId?.value
            : 0,
        },
      },
      refetchQueries: [
        {
          query: GetAcctVouchersMkCk,
          variables: {
            finYrId: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            input: {
              pr_emp_id: state.empLoginId,
              query_type: MkCkQueryType.MK_CK_PENDING,
            },
            instId: InstId!,
            orderBy: { direction: Direction.ASC },
            token,
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: approveModal
            ? "Approved"
            : forwardModal
            ? "Forwarded"
            : "Rejected",
          operation: Operation.CREATE,
        });
      }
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      // handleClear();
      setModalFlag(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const { voucherNumber } = useVoucherNumber(
    VoucherBookKey.PAYMENT_VOUCHER_NUMBER
  );

  const handleStudentReceipts = () => {
    GenerateVoucherReceipts({
      variables: {
        token,
        inst_id: InstId!,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        user_details,
        input: [
          {
            acct_voucher_master: {
              fin_yr: state.ActiveFinYr
                ? state.ActiveFinYr.fin_yr
                : EMPTY_STRING,
              v_type: VoucherPageType.PAYMENT_VOUCHER,
              v_no: voucherNumber.data?.GetVoucherNumber.vo_number,
              v_date: toIsoDate(serverDate),
              v_total_cr_amt: totalCreditAmount,
              v_total_db_amt: totalDebitAmount,
              v_reconciled_date: toIsoDate(DEFAULT_TIME),
              v_reconciled: false,
              v_std_receipt: false,
              v_std_refund: false,
              v_std_deposit_adjusted: false,
              v_std_deposit: false,
              v_std_demand_receipt: false,
              v_std_scholarship_deposit: false,
              v_std_receipt_ob: false,
              mkck_voucher_id: state.mkckId,
              v_std_passout_receipt: false,
              v_transcation_cash_or_bank:
                mkckData?.acct_voucher_details_mkck.find(
                  (d) => d.vo_cr_db === DebitOrCredit.DEBIT
                )?.acct_ldgr.ldgr_cash_bank_other === BankOrCash.BANK
                  ? BankOrCash.BANK
                  : BankOrCash.CASH,
              v_std_non_demand_receipt: false,
              v_book_type: VoucherBookKey.PAYMENT_VOUCHER_NUMBER,
              student_id: 0,
              v_std_anonymous_deposit_adjusted: false,

              class_id: 0,
              v_std_receipt_anonymous: false,
              semester_id: 0,
              v_std_amt_receipt: 0,
              v_std_amt_deposit: 0,
              v_std_amt_fine: 0,
              v_std_amt_total: 0,
              v_std_amt_refunded: 0,
              v_std_amt_adjusted: 0,
              v_std_enquiry: false,
              enquiry_student_id: 0,
              v_transcation_type: "receiptType",
              v_transcation_no: mkckData?.transcation_no,
              v_transcation_date: toIsoDate(TODAY_DATE),
              v_transcation_narration: mkckData?.remarks,
              v_std_refund_deposit: false,
              // paid_party_id: "",
              party_bill_no: mkckData?.party_bill_no,
              party_bill_date: toIsoDate(mkckData?.party_bill_date!),
              // don't remove extra it is being used in printing vouchers
              party_name: mkckData?.party_name,
              annx_yesno: false,
              // annx_id: Math.random() * 1000,
              is_vouch_multi_entry: false,
              acct_ldgr_id_cr: mkckData?.acct_voucher_details_mkck.filter(
                (f) => f.vo_cr_db === DebitOrCredit.CREDIT
              )[0].acct_ldgr.id,
              acct_ldgr_id_db: mkckData?.acct_voucher_details_mkck.filter(
                (f) => f.vo_cr_db === DebitOrCredit.DEBIT
              )[0].acct_ldgr.id,
            },
            acct_voucher_db: mkckData?.acct_voucher_details_mkck
              .filter((item) => item.vo_cr_db === DebitOrCredit.DEBIT)
              .map((item, index) => ({
                vo_cr_db: DebitOrCredit.DEBIT,
                vo_sl_no: index + 1,
                vo_cr: 0,
                vo_db: item.vo_db,
                vo_cr_total: 0,
                vo_db_total: totalDebitAmount,
                acct_ldgr_id: item.acct_ldgr.id,
              })),
            acct_voucher_cr: mkckData?.acct_voucher_details_mkck
              .filter((item) => item.vo_cr_db === DebitOrCredit.CREDIT)
              .map((item, index) => ({
                vo_sl_no: index + 1,
                vo_cr_db: DebitOrCredit.CREDIT,
                vo_cr: item.vo_cr,
                vo_db: 0,
                vo_cr_total: totalCreditAmount,
                vo_db_total: 0,
                acct_ldgr_id: item.acct_ldgr.id,
              })),
          },
        ],
      },
      refetchQueries: [
        {
          query: GetAcctVouchers,
          variables: {
            after: null,
            direction: Direction.ASC,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: null,
            sortBy: SortBy.V_DATE,
            token,
            v_no: "",
            deposit: false,
            vTranscationCashOrBank: null,
            partyName: EMPTY_STRING,
            vTranscationNo: EMPTY_STRING,
            vTranscationNarration: EMPTY_STRING,
            ldgrDesc: EMPTY_STRING,
            amount: null,
            input: {
              inst_id: InstId!,
              voucher_query_type: VoucherQueryTypes.BY_DATES,
              vo_end_date: dayjs(lastDay).format(),
              acct_ldgr_id: 0,
              vo_start_date: dayjs(firstDay).format(),
              vo_type: EMPTY_STRING,
              student_id: 0,
            },
          },
        },
        {
          query: GetAcctVouchersMkCk,
          variables: {
            finYrId: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            input: {
              pr_emp_id: state.empLoginId,
              query_type: MkCkQueryType.MK_CK_APPROVED,
            },
            instId: InstId!,
            orderBy: { direction: Direction.ASC },
            token,
            after: null,
          },
        },
        {
          query: GetVoucherNumber,
          variables: {
            token,
            vo_book_key: VoucherBookKey.PAYMENT_VOUCHER_NUMBER,
            inst_id: InstId!,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        dispatch({
          type: payloadTypes.SET_RECEPIT_ID,
          payload: { receiptId: data.AddAcctVoucherMasterAndDetails[0].id },
        });
        setMessage({
          message: "Successfully Generated",
          flag: true,
          operation: Operation.CREATE,
        });
        setPrintModal(!printModal);
      }
    });
  };

  useEffect(() => {
    if (state.mkckId) {
      GetMkckVData({ variables: { token, id: state.mkckId } }).then(
        ({ data }) => {
          if (data) {
            setMkckData(data.node);
          }
        }
      );
    }
  }, [state.mkckId, token, GetMkckVData]);
  const fileNameArray =
    mkckData && mkckData.payment_invoice_filename
      ? mkckData.payment_invoice_filename.split("/")
      : [];
  const fileName = fileNameArray[fileNameArray.length - 1];

  useEffect(() => {
    if (mkckData && mkckData.payment_invoice_filename !== EMPTY_STRING) {
      // eslint-disable-next-line
      const ers = getDownloadUrl(
        mkckData?.payment_invoice_filename!,
        false,
        setImageString
      );
    }
  }, [mkckData?.payment_invoice_filename, mkckData]);

  const renderModal = (
    pageType: MkCkQueryType,
    isOpen: boolean,
    handleClose: () => void
  ) => (
    <Modal
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      style={EditModalCustomStyles}
    >
      <div className="modal-flex h-100">
        <div className="modal-flex__data h-100">
          {pageType === MkCkQueryType.MK_CK_APPROVED ? (
            <>
              <Title>Approval Entry</Title>
              <div className="label-grid">
                <Label>Remarks</Label>
                <TextArea
                  rows={3}
                  onChange={(e) => setApprovalRemarks(e.target.value)}
                />
              </div>
            </>
          ) : pageType === MkCkQueryType.MK_CK_FORWARDED ? (
            <>
              <Title>ForWard </Title>
              <div className="label-grid">
                <Label>Move to</Label>
                <Autocomplete
                  classes={classes}
                  options={empDetails.responseType}
                  value={reportingmanagerId}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, reportingmanagerId)
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setReportingManagerId(newValue);
                    } else {
                      setReportingManagerId(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      classes={{
                        root: textClasses.formControlRoot,
                      }}
                    />
                  )}
                />
                <Label>Remarks</Label>
                <TextArea rows={3} />
              </div>
            </>
          ) : (
            <>
              <Title>Reject</Title>
              <div className="label-grid">
                <Label>Remarks</Label>
                <TextArea rows={3} />
              </div>
            </>
          )}

          <Button mode="save" onClick={handleApprovalRequest} />
          <Button mode="clear" />
          <Button mode="cancel" onClick={() => handleClose()} />
        </div>
        <div className="modal-flex__image">
          <img src={Close} alt="/" onClick={handleClose} />
        </div>
      </div>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </Modal>
  );

  return (
    <>
      <Title>
        {pageType === MkCkQueryType.MK_CK_APPROVED
          ? "View Approved Status"
          : pageType === MkCkQueryType.MK_CK_REJECTED
          ? "View Rejected Status"
          : "View Pending Status"}
      </Title>
      <div className="payment-voucher-modal">
        <div className="row g-0 payment-voucher-modal__block">
          <div className="col-3 payment-voucher-modal__block--payment-number">
            <Label>Receipt No.</Label>
            <Input
              disabled
              type="text"
              value={mkckData?.mkck_no || EMPTY_STRING}
            />
          </div>
          <div className="col"></div>
          <div className="col-4 payment-voucher-modal__block--date">
            <div className="label-grid">
              <Label>Date</Label>
              <Input
                type="date"
                disabled
                value={
                  toInputStandardDate(mkckData?.mkck_date || "") || EMPTY_STRING
                }
              />
            </div>
            <div className="label-grid">
              <Label>Day</Label>
              <Input
                disabled
                value={MonthName(mkckData?.mkck_date || TODAY_DATE) || ""}
              />
            </div>
          </div>
        </div>
        <div className="payment-voucher-modal__tableblock">
          <TableContainer className="payment-voucher__table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.PaymentVoucher.Table_Headers.filter(
                    (data: TableHeaderProps) => data.labelName !== "Actions"
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return (
                      <TableCell className={th.className} key={index}>
                        {th.labelName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {mkckData?.acct_voucher_details_mkck &&
                  [...mkckData.acct_voucher_details_mkck]
                    .sort((a, b) => a.vo_sl_no - b.vo_sl_no)
                    .map((data, index) => (
                      <TableRow key={index}>
                        <TableCell
                          className="payment-voucher__table--select"
                          id="td-center"
                        >
                          {data.vo_cr_db === DebitOrCredit.DEBIT
                            ? toStandardCase(transactionTypeEnum.BY)
                            : toStandardCase(transactionTypeEnum.TO)}
                        </TableCell>
                        <TableCell>{data.acct_ldgr.ldgr_desc}</TableCell>
                        <TableCell
                          id="td-right"
                          className="payment-voucher__table--amount"
                        >
                          {format(data.vo_db)}
                        </TableCell>
                        <TableCell
                          id="td-right"
                          className="payment-voucher__table--amount"
                        >
                          {format(data.vo_cr)}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2} className="total">
                    Total:
                  </TableCell>
                  <TableCell className="totalcount" align="right">
                    {format(totalDebitAmount)}
                  </TableCell>
                  <TableCell className="totalcount" align="right">
                    {format(totalCreditAmount)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <div className="payment-voucher-modal__lableblock">
          <div className="row g-0 payment-voucher-modal__lableblock--details h-100">
            <div className="col payment-voucher-modal__lableblock--frames h-100">
              <div className="row g-0 ">
                <div className="col">
                  <div className="label-grid">
                    <Label>Pay to</Label>
                    <Input
                      disabled
                      value={mkckData?.party_name || EMPTY_STRING}
                    />
                    <Label>Bill No.</Label>
                    <Input disabled value={mkckData?.party_bill_no} />
                    <Label>Cheque No.</Label>
                    <Input disabled value={mkckData?.transcation_no} />
                  </div>
                </div>
                <div className="col">
                  <div className="label-grid payment-voucher-modal__lableblock--top">
                    <Label>Bill Date</Label>
                    <Input
                      value={toStandardDate(
                        mkckData?.party_bill_date! || TODAY_DATE
                      )}
                      disabled
                    />
                    <Label>Cheque Date</Label>
                    <Input
                      disabled
                      value={toStandardDate(
                        mkckData?.party_bill_date! || TODAY_DATE
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col payment-voucher__lableblock--frames h-100">
              <div className="payment-voucher__label-grid2">
                <Label>Remarks:</Label>
                <TextArea
                  disabled
                  value={mkckData?.remarks ?? EMPTY_STRING}
                  rows={3}
                />
              </div>
              {mkckData?.payment_invoice_filename ? (
                <>
                  <div className="documents-upload--filename">
                    <>
                      <img
                        src={getFileIcon(
                          fileNameArray[fileNameArray.length - 1]
                        )}
                        alt=""
                      />

                      <span>{fileNameArray[fileNameArray.length - 1]}</span>
                      <Tooltip
                        title={
                          <>
                            {imageString ? (
                              <>
                                <div className="registration__data--logo-grid--image">
                                  {fileName.split(".")[
                                    fileName.split(".").length - 1
                                  ] === "pdf" ? (
                                    <span>
                                      <img src={getFileIcon(fileName)} alt="" />
                                      <a download href={imageString}>
                                        {fileName}
                                      </a>
                                    </span>
                                  ) : (
                                    <img
                                      src={imageString}
                                      alt="/"
                                      className="image-preview"
                                    />
                                  )}
                                </div>

                                {/* <div className="label-grid">
                <Label>Uploading In Progress</Label>
                <progress value={progress}></progress>
              </div> */}
                              </>
                            ) : null}
                          </>
                        }
                        classes={{
                          tooltip: classes.studentOptions,
                        }}
                        placement="top"
                      >
                        <img src={Download} alt="/" />
                      </Tooltip>
                    </>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col">
            {type === PendingStatusFor.CHECKER ? (
              <>
                <Button
                  mode="approve"
                  type="button"
                  disabled={state.createdMkckId === state.empLoginId}
                  onClick={() => setApproveModal(!approveModal)}
                />
                <Button
                  mode="forward"
                  disabled={state.createdMkckId === state.empLoginId}
                  onClick={() => setForwardModal(!forwardModal)}
                />
                <Button
                  mode="reject"
                  disabled={state.createdMkckId === state.empLoginId}
                  onClick={() => setRejectModal(!rejectModal)}
                />
              </>
            ) : (
              <>
                {pageType === MkCkQueryType.MK_CK_APPROVED ? (
                  <Button mode="save" onClick={handleStudentReceipts} />
                ) : (
                  <Button
                    mode="edit"
                    onClick={() => setEditModal(!editModal)}
                  />
                )}
              </>
            )}

            <Button mode="cancel" onClick={() => setModalFlag(false)} />
          </div>
          <div className="col payment-voucher-modal__icon">
            <Tooltip
              title="View Status"
              placement="top"
              classes={{
                tooltip: tooltipClasses.customTooltipGrey,
                arrow: tooltipClasses.customArrowGrey,
              }}
              arrow
            >
              <img
                src={ViewStatus}
                alt="/"
                onClick={() => setStatusModal(!statusModal)}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={editModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <NewVoucher
              type={VoucherPageType.PAYMENT_VOUCHER}
              operation={Operation.UPDATE}
              pageType={PageFor.MODAL}
              setModalFlag={setEditModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setEditModal(!editModal)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={statusModal}
        style={ApprovedStatusModal}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Status />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStatusModal(!statusModal)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <VoucherReceiptPrint setModalFlag={setPrintModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => {
                setStatusModal(false);
                setPrintModal(!printModal);
                setApproveModal(false);
              }}
              className="modal-close-icon"
            />
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={printConfig}
        style={PrintConfigModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <PerModuleConfiguration
              config_query_type={SwConfigQueryType.INST_BY_MODULE}
              str_value={ModuleName.VOUCHER_PRINT}
              int_value={0}
              setModalFlag={setPrintConfigModal}
              pageType={PageFor.MODAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPrintConfigModal(!printConfig)}
            />
          </div>
        </div>
        <MessageModal
          modalFlag={message.flag!}
          value={message.message!}
          handleClose={handleClose}
          operation={message.operation!}
        />
      </Modal>
      {renderModal(
        MkCkQueryType.MK_CK_APPROVED,
        approveModal,
        handleToggleApprove
      )}
      {renderModal(
        MkCkQueryType.MK_CK_REJECTED,
        rejectModal,
        handleToggleReject
      )}
      {renderModal(
        MkCkQueryType.MK_CK_FORWARDED,
        forwardModal,
        handleToggleforward
      )}
    </>
  );
};

export default PaymentVoucherModal;
