import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import Home from "../../Home/Index";
import { Button } from "../../../../stories/Button/Button";
import Input from "../../../../stories/Input/Input";
import { Label } from "../../../../stories/Label/Label";
import { Title } from "../../../../stories/Title/Title";
import { BookTransactionTitleProps } from "../../../../Types/Titles";

//Images
import Avatar from "../../../../images/Avatar.svg";
import History from "../../../../images/Refresh.svg";
import Edit from "../../../../images/Edit.svg";
import Close from "../../../../images/Close.svg";
import Delete from "../../../../images/Delete.svg";
import Settings from "../../../../images/Settings.svg";
import Staff from "../../../../images/Staff.svg";
import Student from "../../../../images/Student.svg";

import { TableHeaderProps } from "../../../../Types/Tables";
import {
  ConfigurationsModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import TransactionHistory from "../../Modals/BookTransactions/Index";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { LibraryLabelNameProps } from "../../../../Types/Labels";
import StudentList from "../../students/List";
import EmpList from "../../../HR/Employee/List/Index";

import {
  BookConditionType,
  BookListType,
  BooksReportType,
  Direction,
  LibBookIssueQueryType,
  LibBookMasterQueryType,
  LibraryConfigKey,
  Operation,
  PageFor,
  PageNumbers,
  SortBy,
  StudentReportType,
  UserType,
} from "../../../../utils/Enum.types";

import {
  AUTOCOMPLETE_CLEAR_CLASSNAME,
  DEFAULT_TIME,
  EMPTY_RESPONSETYPE_OBJECT,
  EMPTY_STRING,
  LIBRARY_ROWS_PER_PAGE,
  NumberOfAdmissionNumberToBeDisplayed,
  ROWS_PER_PAGE,
  TODAY_DATE,
} from "../../../../utils/constants";
import { msgType, responseType } from "../../../../utils/Form.types";
import { AppContext } from "../../../../context/context";
import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";
import { Keys } from "../../../../utils/Enum.keys";
import useBookEntryOptions from "../../customHooks/useBookEntryOptions";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AddLibBookIssue } from "../../../../queries/Library/BookIssue/mutation/new";
import useToken from "../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDueDate,
  GetNoOfDaysDelayed,
  toInputStandardDate,
  toIsoDate,
  toStandardCase,
  toStandardDate,
} from "../../../../utils/UtilFunctions";

import { ByNode as BookIssueDetails } from "../../../../queries/Library/Node/index";

import MessageModal from "../../../../pages/MessageModal";
import LoadingModal from "../../../../pages/LoadingModal";
import useDisplayConfigIcon from "../../../../customhooks/useDisplayConfigIcon";

//Modals
import ConfigurationSettings from "../../../Master/configurations/general/Index";
import { GetLibBookIssued } from "../../../../queries/Library/BookIssue/list/byIds";
import useSwConfigData from "../../../../customhooks/useSwConfigData";
import useAcdStudentsAdmNumber from "../../../Academics/hooks/useAcdStudentsAdmNumber";
import { getDownloadUrl } from "../../../../utils/DownloadFile";
import BookSearch from "../../BooksSearch/Index";
import { payloadTypes } from "../../../../context/reducer";
import useLoggedInUserDetails from "../../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryTableJson from "../../json/useLibraryTableJson";
import useLibraryformJson from "../../json/useLibraryformJson";
import {
  GetAllInstsOfCampus,
  GetLibBookMaster,
} from "../../../../queries/Library/BookEntry/list/byInstId";
import {
  GetLibBookMasterData,
  GetLibBookMasterVars,
} from "../../../../Types/Library/BookEntry/paginationTypes";
import useInstDetails from "../../../../customhooks/general/useInstDetails";
const { Library } = require("../../../../json/title.json");
export interface GetAllInstsOfCampusList {
  id: number;
  is_primary_inst: boolean;
  campus_id: number;
  inst_id: number | string;
  inst_details: {
    id: number;
    inst_name: string;
  };
  campus_details: {
    id: number;
    campus_name: string;
  };
}
export interface GetAllInstsOfCampusData {
  GetAllInstsOfCampus: GetAllInstsOfCampusList[];
}
export interface GetAllInstsOfCampusVars {
  token: string;
  inst_id: number | string;
}

export interface booksIssueType {
  id: number;
  access_no: string;
  classification_desc: string;
  publication: string;
  author: string;
  edition: string;
  volume: string;
  isChecked?: boolean;
}

interface Props {
  BorrowerType: UserType;
  id?: number;
  userType?: UserType | null;
}

const Index = ({ BorrowerType, userType, id }: Props) => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const { Library_Table } = useLibraryTableJson();
  const { LibraryFormLabels } = useLibraryformJson();
  const { state, dispatch } = useContext(AppContext);
  const { user_details } = useLoggedInUserDetails();
  const [bookHistory, setBookHistory] = useState(false);
  const listClasses = ListAutoCompleteStyles();
  const listTextClasses = ListAutoCompleteTextStyles();
  const [studentModal, setStudentModal] = useState(false);
  // const [bookDetails, setBookDetails] = useState(false);
  const [configurationModal, setConfigurationModal] = useState(false);
  const [searchBook, setSearchBook] = useState("");
  const [searchAdmNo, setSearchAdmNo] = useState("");
  const [instSelected, setInstSelected] = useState<responseType | null>(null);
  const [bookSearchModal, setBookSearchModal] = useState(false);
  const { InstDetails } = useInstDetails(1);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [selectedBookEntry, setSelectedBookEntry] =
    useState<booksIssueType | null>();

  const [booksDropDown, setBooksDropDown] = useState<booksIssueType[]>([]);

  const [fromDate, setFromDate] = useState(TODAY_DATE);

  // image string for avatar
  const [imageString, setImageString] = useState("");

  //UserRefs
  const admNoRef = useRef<HTMLSelectElement>(null);
  const admNoInputRef = admNoRef?.current?.childNodes[0].childNodes[1]
    .childNodes[0] as HTMLInputElement;
  const admNoClearRef = admNoRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;
  const bookIdRef = useRef<HTMLSelectElement>(null);
  const bookIdInputRef = bookIdRef?.current?.childNodes[0].childNodes[1]
    .childNodes[0] as HTMLInputElement;
  const BookIdClearRef = bookIdRef?.current?.getElementsByClassName(
    AUTOCOMPLETE_CLEAR_CLASSNAME
  )[0] as HTMLButtonElement;
  //Queries

  //Mutations
  const [AddBookIssue, { loading: issueLoading }] = useMutation(
    AddLibBookIssue,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  //Custom Hooks
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.LIB_BOOK_ISSUE_PAGE
  );

  const { configData } = useSwConfigData(
    LibraryConfigKey.LIB_BOOK_NO_OF_BORROWING_DAYS
  );
  const { configData: IssueLimit } = useSwConfigData(
    LibraryConfigKey.LIB_BOOKS_ISSUE_LIMIT
  );
  const { configData: EditTransactionDate } = useSwConfigData(
    LibraryConfigKey.LIB_BOOKS_TRANSACTION_DATE_EDITABLE
  );
  const { configData: CampusFlag } = useSwConfigData(
    LibraryConfigKey.ENABLE_LIB_CAMPUS_MODE
  );

  const EDIT_TRANSACTION_DATE =
    EditTransactionDate.data?.GetSwConfigVariables[0].config_boolean_value!;
  const BOOKS_ISSUE_LIMIT =
    IssueLimit.data?.GetSwConfigVariables[0].config_integer_value!;
  const DELAY_DAYS_LIMIT =
    configData.data?.GetSwConfigVariables[0].config_integer_value!;

  const { studentAddmissionNumber } = useAcdStudentsAdmNumber(
    searchAdmNo,
    NumberOfAdmissionNumberToBeDisplayed,
    StudentReportType.GENERAL
  );
  const [GetCampusData, { data: CampusInstData }] = useLazyQuery<
    GetAllInstsOfCampusData,
    GetAllInstsOfCampusVars
  >(GetAllInstsOfCampus);
  const instDropdown = CampusInstData
    ? CampusInstData.GetAllInstsOfCampus.map((res) => ({
        label: res.inst_details.inst_name,
        value: res.inst_details.id,
      }))
    : [];

  const { studentFormData, studentData } = useStudentDatabyId();
  // const {
  //   bookEntries: {
  //     queryData: { data: bookEntryData, loading: bookEntryLoading },
  //   },
  // } = useBookEntryOptions(
  //   searchBook,
  //   null,
  //   BooksReportType.BOOKS_TO_BE_ISSUED,
  //   BorrowerType
  // );
  const [GetBooks, { data, loading }] = useLazyQuery<
    GetLibBookMasterData,
    GetLibBookMasterVars
  >(GetLibBookMaster, {
    variables: {
      token,
      input: {
        book_query_type: LibBookMasterQueryType.BOOKS_TO_ISSUE,
        ids: [Number(InstId)],
      },
      first: ROWS_PER_PAGE,
      sortBy: SortBy.ACCESS_NO,
      direction: Direction.ASC,
      access_no: searchBook,
      after: null,
    },
  });
  useEffect(() => {
    if (token && InstId) {
      GetBooks();
    }
  }, [GetBooks, token, InstId, state.staffId, state.studentId]);

  const {
    bookEntries: {
      issuedBooksData: { data: IssueBookData },
    },
  } = useBookEntryOptions(
    EMPTY_STRING,
    ROWS_PER_PAGE,
    BooksReportType.BOOKS_ISSUED,
    BorrowerType
  );

  const HandleSubmit = () => {
    AddBookIssue({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        lib_book_master_ids: booksDropDown
          ?.filter(({ isChecked }) => isChecked)
          ?.map(({ id }) => id),
        input: {
          issue_for_student_or_staff: BorrowerType,
          issue_date: toIsoDate(fromDate),
          issue_return_date: toIsoDate(
            getDueDate(fromDate, DELAY_DAYS_LIMIT).toString()
          ),
          issue_return_type: BookConditionType.GOOD,
          librarian_staff_id: state.empLoginId,
          student_id: BorrowerType === UserType.STUDENT ? state.studentId : 0,
          staff_id: BorrowerType === UserType.EMPLOYEE ? state.staffId : 0,
        },
      },
      refetchQueries: [
        {
          query: GetLibBookIssued,
          variables: {
            first: LIBRARY_ROWS_PER_PAGE,
            after: null,
            input: {
              book_issue_query_type:
                userType === UserType.EMPLOYEE
                  ? LibBookIssueQueryType.BOOKS_ISSUED_BY_STAFF_ID
                  : LibBookIssueQueryType.BOOKS_ISSUED_BY_STD_ID,
              book_issue_from_date: toIsoDate(DEFAULT_TIME),
              book_issue_to_date: toIsoDate(DEFAULT_TIME),
              ids: [
                Number(InstId),
                userType === UserType.EMPLOYEE
                  ? state.staffId
                  : state.studentId,
              ],
            },
            sortBy: SortBy.ISSUE_DATE,
            direction: Direction.ASC,
            token,
          },
        },
        {
          query: BookIssueDetails,
          variables: {
            token,
            ids: [0],
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        dispatch({
          type: payloadTypes.SET_STUDENT_ID,
          payload: {
            studentId: 0,
          },
        });
        setMessage({
          message: "Book Issued SuccessFully",
          flag: true,
          operation: Operation.CREATE,
        });
        booksDropDown?.filter(({ isChecked }) => isChecked === false);
      }
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const pushIssuedBooks = () => {
    const updatedData = booksDropDown?.map((id) => {
      if (id.id === selectedBookEntry?.id) {
        const newData = {
          ...id,
          id: id.id,
          access_no: id.access_no,
          publication: id.publication,
          author: id.author,
          edition: id.edition,
          volume: id.volume,
          isChecked: !id.isChecked,
        };
        return newData;
      }
      return id;
    });
    setBooksDropDown(updatedData!);
    setSelectedBookEntry(null);
  };

  const handleClear = () => {
    setImageString(EMPTY_STRING);
    dispatch({
      type: payloadTypes.SET_STUDENT_ID,
      payload: {
        studentId: 0,
      },
    });
    admNoClearRef?.click();
    BookIdClearRef?.click();
    admNoInputRef?.focus();
    setBooksDropDown([]);
  };
  useEffect(() => {
    if (InstId && instDropdown) {
      const selectedInst =
        instDropdown?.find((res) => res.value === Number(InstId)) || null;
      setInstSelected(selectedInst);
    }
  }, [InstId]);
  useEffect(() => {
    if (data && !loading) {
      setBooksDropDown(
        data &&
          data.GetLibBookMaster?.edges?.map(({ node }) => ({
            id: node.id,
            access_no: node.book_access_no,
            publication: node.classification.publisher.publisher_name,
            classification_desc: node.classification.classification_desc,
            author: node.classification.author.author_name,
            edition: node.classification.book_edition,
            volume: node.classification.book_volume,
            isChecked: false,
          }))
      );
    }
  }, [data, loading, state.studentId]);

  useEffect(() => {
    if (
      studentData.data &&
      studentData.data?.nodes[0].std_profile_filename !== "" &&
      InstDetails.data
    ) {
      // eslint-disable-next-line
      const studentProfiePicUrl = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;
      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfiePicUrl, false, setImageString);
    }
  }, [studentData.data, InstDetails.data, state.studentId]);
  useEffect(() => {
    if (state.bookId) {
      setSelectedBookEntry(
        booksDropDown.find(({ id }) => id === state.bookId)!
      );
      bookIdInputRef?.focus();
    }
  }, [state.bookId, booksDropDown, bookIdInputRef]);
  useEffect(() => {
    if (token && InstId) {
      GetCampusData({
        variables: {
          token,
          inst_id: InstId!,
        },
      });
    }
  }, [token, InstId, GetCampusData]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col-4"></div>
        <div className="col">
          <Title>
            {toStandardCase(BorrowerType) + EMPTY_STRING}
            &nbsp;
            {Library.BookTransaction.BookIssue.Titles.map(
              (title: BookTransactionTitleProps, index: React.Key) => (
                <React.Fragment key={index}>{title.BookIssue}</React.Fragment>
              )
            )}
          </Title>
        </div>
        <div className="col-3 book-issue__date">
          <TextField
            type="date"
            className="book-issue__details--textfield--date"
            label="From Date"
            InputLabelProps={{ shrink: true }}
            disabled={!EDIT_TRANSACTION_DATE}
            value={fromDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFromDate(e.target.value)
            }
          />
          <TextField
            className="book-issue__details--textfield--date"
            type="date"
            label="Due Date"
            InputLabelProps={{ shrink: true }}
            disabled
            value={
              getDueDate(fromDate, DELAY_DAYS_LIMIT).toString() !==
              "Invalid Date"
                ? toInputStandardDate(
                    getDueDate(fromDate, DELAY_DAYS_LIMIT).toString()
                  )
                : EMPTY_STRING
            }
          />
        </div>
        <div className="col-1"></div>
        <div className="configuration-settings">
          {USE_CONFIG_KEY && (
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          )}
        </div>
      </div>
      <div className="book-issue">
        <div className="row g-0 book-issue__details">
          <div className="col-4">
            <div className="book-issue__details--imageflex">
              {instDropdown.length > 0 && (
                <Autocomplete
                  classes={listClasses}
                  options={instDropdown!}
                  ref={admNoRef}
                  forcePopupIcon
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      bookIdInputRef?.focus();
                    }
                  }}
                  value={instSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setInstSelected(newValue);
                      dispatch({
                        type: payloadTypes.SET_INST_ID_FOR_CAMPUS,
                        payload: {
                          instIdForCampus: newValue.value,
                        },
                      });
                    } else {
                      setInstSelected(null);
                      dispatch({
                        type: payloadTypes.SET_INST_ID_FOR_CAMPUS,
                        payload: {
                          instIdForCampus: 0,
                        },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Institutuion"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      autoFocus
                      classes={{ root: listTextClasses.formControlRoot }}
                    />
                  )}
                />
              )}
              <Autocomplete
                classes={listClasses}
                options={studentAddmissionNumber ?? EMPTY_RESPONSETYPE_OBJECT}
                ref={admNoRef}
                isOptionEqualToValue={(option) =>
                  option?.value === state.studentId
                }
                openOnFocus
                forcePopupIcon
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    bookIdInputRef?.focus();
                  }
                }}
                onChange={(e, newValue) => {
                  if (BorrowerType === UserType.STUDENT) {
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: (newValue as responseType)?.value,
                      },
                    });
                    dispatch({
                      type: payloadTypes.SET_STAFF_ID,
                      payload: {
                        staffId: 0,
                      },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_STAFF_ID,
                      payload: {
                        staffId: (newValue as responseType)?.value,
                      },
                    });
                    dispatch({
                      type: payloadTypes.SET_STUDENT_ID,
                      payload: {
                        studentId: 0,
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      BorrowerType === UserType.EMPLOYEE
                        ? "Staff."
                        : "Admission No."
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (BorrowerType === UserType.STUDENT)
                        setSearchAdmNo(e.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    autoFocus
                    classes={{ root: listTextClasses.formControlRoot }}
                  />
                )}
              />

              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() => setStudentModal(!studentModal)}
              />
            </div>
            <div className="book-issue__details--imageflex">
              <Autocomplete
                classes={listClasses}
                options={
                  booksDropDown.filter(({ isChecked }) => isChecked === false)!
                }
                openOnFocus
                getOptionLabel={(option) => option.access_no.toString()}
                isOptionEqualToValue={(option) =>
                  option.id === selectedBookEntry?.id
                }
                value={selectedBookEntry! ?? null}
                ref={bookIdRef}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {`${option.access_no} 
                        (${option.classification_desc})`}
                    </li>
                  );
                }}
                disabled={
                  IssueBookData?.nodes?.length! +
                    booksDropDown?.filter(({ isChecked }) => isChecked)
                      .length ===
                  BOOKS_ISSUE_LIMIT
                }
                onChange={(e, newValue) => setSelectedBookEntry(newValue!)}
                onKeyDown={(e) => {
                  if (
                    e.key === Keys.ENTER &&
                    selectedBookEntry &&
                    IssueBookData?.nodes?.length! +
                      booksDropDown?.filter(({ isChecked }) => isChecked)
                        ?.length <
                      BOOKS_ISSUE_LIMIT
                  ) {
                    pushIssuedBooks();
                    setSelectedBookEntry(null);
                    bookIdInputRef?.focus();
                  }
                }}
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Book ID"
                    onChange={(e) => setSearchBook(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: listTextClasses.formControlRoot }}
                  />
                )}
              />

              <img
                className="data-fetch-icon"
                src={Edit}
                alt="/"
                onClick={() => setBookSearchModal(!bookSearchModal)}
              />
            </div>
            <div className="book-issue__details--textflex">
              <Label>Max. Issue Limit</Label>
              <Input
                id={
                  IssueBookData?.nodes?.length! +
                    booksDropDown?.filter(({ isChecked }) => isChecked)
                      .length >=
                  BOOKS_ISSUE_LIMIT
                    ? "bg-red"
                    : "bg-green"
                }
                value={
                  IssueBookData?.nodes?.length
                    ? IssueBookData?.nodes?.length +
                      booksDropDown?.filter(({ isChecked }) => isChecked).length
                    : booksDropDown?.filter(({ isChecked }) => isChecked).length
                }
                disabled
              />
              <span> / </span>
              <Input id="bg-red" value={BOOKS_ISSUE_LIMIT ?? 0} disabled />
            </div>
            <span>
              {IssueBookData?.nodes?.length! +
                booksDropDown?.filter(({ isChecked }) => isChecked).length ===
              BOOKS_ISSUE_LIMIT ? (
                <b className="nodata">Book Issue limit Reached</b>
              ) : null}
            </span>
          </div>

          <div className="col book-issue__details--colspan">
            {LibraryFormLabels.Book_Transaction.Book_Issue_Return.map(
              (label: LibraryLabelNameProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    <TextField
                      className="book-issue__details--textfield"
                      label={label.LabelName}
                      InputLabelProps={{ shrink: true }}
                      value={studentFormData[label.inputName]}
                      disabled
                    />
                  </React.Fragment>
                );
              }
            )}
          </div>
          <div className="col-1 book-issue__details--image h-100">
            {imageString === EMPTY_STRING ? (
              <img src={Avatar} alt="/" />
            ) : (
              <img src={imageString} alt="/" />
            )}
          </div>
        </div>
        <div className="book-issue__tableblock">
          <Title variant="subtitle1">
            {Library.BookTransaction.BookIssue.Titles.map(
              (title: BookTransactionTitleProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {title.ToBeIssued}
                  </React.Fragment>
                );
              }
            )}
          </Title>
          <TableContainer className="book-issue__tableblock--books-table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Library_Table.BookTransaction.BooksToBeIssued.Table_Headers.map(
                    (label: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={label.className}>
                          {label.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {booksDropDown
                  ?.filter(({ isChecked }) => isChecked)
                  ?.map((book, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          id="td-center"
                          className="book-issue__tableblock--books-table--slno"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className="book-issue__tableblock--books-table--accno">
                          {book?.access_no}
                        </TableCell>
                        <TableCell>{book?.classification_desc}</TableCell>

                        <TableCell className="book-issue__tableblock--books-table--publication">
                          {book?.author}
                        </TableCell>
                        <TableCell className="book-issue__tableblock--books-table--publication">
                          {book?.edition}
                        </TableCell>
                        <TableCell className="book-issue__tableblock--books-table--actions">
                          {book?.volume}
                        </TableCell>
                        <TableCell
                          id="td-center"
                          className="book-issue__tableblock--books-table--actions"
                        >
                          <img
                            src={Delete}
                            alt="/"
                            onClick={() => {
                              setBooksDropDown(
                                booksDropDown?.map((data) => {
                                  if (data.id === book.id) {
                                    return {
                                      ...data,
                                      isChecked: false,
                                    };
                                  } else {
                                    return data;
                                  }
                                })
                              );
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Title variant="subtitle1">
            {Library.BookTransaction.BookIssue.Titles.map(
              (title: BookTransactionTitleProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {title.IssuedBook}
                  </React.Fragment>
                );
              }
            )}
          </Title>
          <TableContainer className="book-issue__tableblock--books-table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Library_Table.BookTransaction.IssuedBooks.Table_Headers.map(
                    (label: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={label.className}>
                          {label.labelName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {state.studentId && IssueBookData
                  ? IssueBookData.nodes.map((label, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            id="td-center"
                            className="book-issue__tableblock--books-table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="book-issue__tableblock--books-table--accno">
                            {label.book_access_no}
                          </TableCell>
                          <TableCell className="book-issue__tableblock--books-table--publication">
                            {label.classification.classification_desc}
                          </TableCell>
                          <TableCell className="book-issue__tableblock--books-table--publication">
                            {label.classification.author.author_name}
                          </TableCell>
                          <TableCell
                            id="td-center"
                            className="book-issue__tableblock--books-table--date"
                          >
                            {toStandardDate(label.book_last_issued_date)}
                          </TableCell>
                          <TableCell
                            id="td-center"
                            className="book-issue__tableblock--books-table--date"
                          >
                            {toStandardDate(
                              getDueDate(
                                label.book_last_issued_date,
                                DELAY_DAYS_LIMIT
                              ).toString()
                            )}
                          </TableCell>

                          <TableCell
                            id="td-center"
                            className="book-issue__tableblock--books-table--date"
                          >
                            {GetNoOfDaysDelayed(
                              getDueDate(
                                label.book_last_issued_date,
                                DELAY_DAYS_LIMIT
                              )
                            )}
                          </TableCell>
                          <TableCell
                            id="td-right"
                            className={
                              label?.issue_details?.issue_return_fine_amt ===
                              "0.00"
                                ? "font-green book-issue__tableblock--books-table--amount"
                                : "font-red book-issue__tableblock--books-table--amount"
                            }
                          >
                            {label?.issue_details?.issue_return_fine_amt}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="book-issue__buttons">
        <div className="button-left">
          <Button
            mode="issue-book"
            disabled={state.studentId === 0}
            onClick={HandleSubmit}
          />
          {BorrowerType === UserType.EMPLOYEE ? (
            <Button
              onClick={() => navigate(`/${InstId}/library/bookissue/student`)}
            >
              <img src={Student} alt="/" />
              For Student
            </Button>
          ) : (
            <Button
              onClick={() => navigate(`/${InstId}/library/bookissue/staff`)}
            >
              <img src={Staff} alt="/" />
              For Staff
            </Button>
          )}
          <Button mode="clear" onClick={handleClear} />
          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
        {studentFormData && (
          <Label
            variant="LabelBold"
            onClick={() => setBookHistory(!bookHistory)}
          >
            History &nbsp; <img src={History} alt="/" />
          </Label>
        )}
      </div>
      {/* book-history modal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={bookHistory}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <TransactionHistory issued_books={IssueBookData!} />
            <Button
              mode="cancel"
              onClick={() => setBookHistory(!bookHistory)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setBookHistory(!bookHistory)}
            />
          </div>
        </div>
      </Modal>
      {/* studentmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal && BorrowerType === UserType.STUDENT}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              setModal={setStudentModal}
              queryType={StudentReportType.GENERAL}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      {/* staffmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={
          studentModal && studentModal && BorrowerType === UserType.EMPLOYEE
        }
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <EmpList pageType={PageFor.MODAL} setModalFlag={setStudentModal} />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.LIB_BOOK_ISSUE_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={bookSearchModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <BookSearch
              pageType={PageFor.MODAL}
              bookListType={BookListType.ON_SHELF}
              setClassBookModal={setBookSearchModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setBookSearchModal(!bookSearchModal)}
            />
          </div>
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={issueLoading} />
    </>
  );
};

export default Index;
