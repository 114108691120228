import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../../../../stories/Button/Button";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Eduate from "../../../../images/Eduate_Logo_image.png";

import {
  EMPTY_STRING,
  FETCH_MORE_DATA,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import {
  Direction,
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  PageFor,
  SortByFilterType,
  StudentDetailedReportType,
  StudentOrderFields,
  StudentReportType,
  TableHeaders,
} from "../../../../utils/Enum.types";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleMUISelectEvent,
  toStandardDate,
} from "../../../../utils/UtilFunctions";
import jsPDF from "jspdf";
import Input from "../../../../stories/Input/Input";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  D4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  I4_CELL,
  TABLE_HEADER_CSS,
} from "../../../Library/Constants";
import useServerDateandTime from "../../../Library/customHooks/useServerDateandTime";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { isOptionEqualToValue } from "../../../../utils/UtilFunctions";
import { Keys } from "../../../../utils/Enum.keys";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import useInstMasterDataByInstId from "../../../../customhooks/useInstMasterDataByInstId";
import useDropdownData from "../../../../customhooks/useDropdownData";
import { optionsType, responseType } from "../../../../utils/Form.types";
import useInstDetails from "../../../../customhooks/general/useInstDetails";

import { StudentDetailedReportStyles } from "../../../../styles/StickyTableStyles";
import { Title } from "../../../../stories/Title/Title";
import StudentTotalCount from "../Components/StudentTotalCount";
import useInstLabels from "../../../../customhooks/general/useInstLabels";
import { AppContext } from "../../../../context/context";
import useAcdDropDownData from "../../../Academics/hooks/useAcdDropDownData";
import useAcdStudentsForAdmission, {
  StudentEdges,
} from "../../../Academics/hooks/useAcdStudentsForAdmission";
import StatusAutocomplete from "../../../../components/common/Autocompletes/StatusAutocomplete";
import SortByAutocomplete from "../../../../components/common/Autocompletes/SortByAutocomplete";
interface Props {
  pageType: PageFor;
  type: StudentDetailedReportType;
  setBioDataModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setMailingModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const StdBioData = ({
  setBioDataModal,
  setMailingModal,
  type,
  pageType,
}: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const tableClasses = StudentDetailedReportStyles();
  const {
    branchLabel,
    classLabel,
    departmentLabel,
    sectionLabel,
    semesterLabel,
    categoryLabel
  } = useInstLabels();
  const { serverDate } = useServerDateandTime();
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { state } = useContext(AppContext);
  const [searchData, setSearchData] = useState("");
  const { InstDetails } = useInstDetails(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );

  const [statusSelected, setStatusSelected] = useState<optionsType | null>(
    null
  );
  const [sortBySelected, setSortBySelected] = useState<optionsType | null>(
    null
  );

  const [pdfFlag, setPdfFlag] = useState(false);
  const [excelFlag, setExcelFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInputRef = branchRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const semRef = useRef<HTMLSelectElement>(null);
  const semInputRef = semRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  const categoryRef = useRef<HTMLSelectElement>(null);
  const categoryInputRef = categoryRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    semesterDropDown,
    sectionDropDown,
  } = useAcdDropDownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  const { AcademicsStudentData } = useAcdStudentsForAdmission(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    categorySelected ? categorySelected.value : 0,
    ROWS_PER_PAGE,
    0,
    searchData,
    StudentReportType.ACD_STD_GENERAL,
    statusSelected ? statusSelected.value : EMPTY_STRING,
    [],
    [
      {
        field: sortBySelected
          ? sortBySelected.value
          : StudentOrderFields.FIRST_NAME,
        direction: Direction.ASC,
      },
    ]
  );

  const stdData = AcademicsStudentData.data
    ? AcademicsStudentData.data.GetAcdStudents.edges.map((data, index) => ({
        SlNo: index + 1,
        AdmNo: data.node.std_adm_no,
        RegNo: data.node.std_reg_no,
        Name:
          data.node.first_name +
          " " +
          data.node.middle_name +
          " " +
          data.node.last_name,
        Mobile: data.node.std_mobile,
        Telephone: data.node.std_details.std_phone,
        Dob: data.node.std_dob,
        Gender: data.node.std_sex,
        Father: data.node.std_father_name,
        Mother: data.node.std_mother_name,
        Branch: data.node.acd_branch.branch_desc,
        Class: data.node.acd_class.class_desc,
        Section: data.node.acd_section.section_desc,
        Toungue: data.node.std_details.std_mother_tongue,
        Religion: data.node.std_details.std_religion,
        Address: data.node.std_details.std_address,
        Place: data.node.std_details.std_place,
      }))
    : [];

  const { categoryDropDown } = useInstMasterDataByInstId();
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !AcademicsStudentData.loading) {
        AcademicsStudentData.fetchMore({
          variables: {
            first: FETCH_MORE_DATA,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcdStudents.edges;
            const pageInfo = fetchMoreResult.GetAcdStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetAcdStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcdStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount:
                  AcademicsStudentData.data?.GetAcdStudents.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  const studentBioDataHeaders = [
    "SlNo",
    "Admission No",
    "Reg No",
    "Name",
    "Father Name",
    "Mother Name",
    "Gender",
    "Mobile",
    "Alternate Mobile",
    branchLabel,
    classLabel,
    sectionLabel,
    "DOB",
    "Mother Toungue",
    "Religion",
  ];
  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };

    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(Eduate)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const pageWidth = 210;
                const pageHeight = 297;

                const doc = new jsPDF("portrait", "mm", [
                  pageWidth,
                  pageHeight,
                ]);
                doc.setFont("Helvetica", "bold");

                const mailingDataForPdf = AcademicsStudentData.data
                  ? AcademicsStudentData.data.GetAcdStudents.edges.map(
                      (data, index) => ({
                        Info: `${data.node.first_name} ${
                          data.node.middle_name
                        } ${data.node.last_name} ${
                          data.node.std_reg_no.length
                            ? data.node.std_reg_no
                            : data.node.std_adm_no
                        }\n${data.node.std_details.std_address}\n ${
                          data.node.std_details.std_place
                        } ${data.node.std_details.std_district}\n${
                          data.node.std_details.std_state
                        }`,
                      })
                    )
                  : [];
                const itemsPerPage = 10;
                const totalPages = Math.ceil(
                  mailingDataForPdf?.length! / itemsPerPage
                );

                let startY = 10;

                for (let i = 0; i < totalPages; i++) {
                  if (i > 0) {
                    doc.addPage();
                    startY = 10;
                  }

                  doc.setFillColor(255, 255, 255);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setFont("Helvetica", "bold");
                  doc.setFontSize(18);
                  doc.setTextColor(0, 0, 0);
                  doc.text(
                    `${InstDetails?.data?.nodes[0].inst_name}`,
                    pageWidth / 2,
                    startY,
                    { align: "center" }
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(13);
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_address}`,
                    pageWidth / 2,
                    startY + 7,
                    { align: "center" }
                  );
                  doc.text(
                    `${InstDetails?.data?.nodes[0]?.inst_place},${InstDetails?.data?.nodes[0]?.inst_pin}`,
                    pageWidth / 2,
                    startY + 12,
                    { align: "center" }
                  );
                  doc.setFontSize(13);
                  doc.text(
                    `FY:${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    45,
                    startY + 19
                  );
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text(`Mailing Students List`, 120, startY + 19);
                  doc.setFontSize(13);
                  doc.text(
                    `As-on-date:${toStandardDate(serverDate)}`,
                    230,
                    startY + 19
                  );
                  doc.addImage(result, "JPEG", 15, 5, 22, 22);

                  const startItem = i * itemsPerPage;
                  const endItem = startItem + itemsPerPage;
                  const pageItems = mailingDataForPdf?.slice(
                    startItem,
                    endItem
                  );
                  // eslint-disable-next-line
                  pageItems?.forEach((data, index) => {
                    const startX = 15;
                    const lineHeight = 30;
                    const info = data.Info;
                    const y = startY + 33 + index * lineHeight;
                    doc.setFontSize(12);
                    doc.text(info, startX, y);
                  });

                  startY += itemsPerPage * 30 + 40;
                }

                doc.save(
                  `${InstDetails?.data?.nodes[0]?.inst_name} ${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  }`
                );
              });
            });
        });
      });
    setPdfFlag(false);
    setRowsPerPage(ROWS_PER_PAGE);
  };
  // eslint-disable-next-line
  const downloadBioDataPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };

    fetch(LogoOrSign.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(Eduate)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const pageWidth = 210; // A4 width in mm
                const pageHeight = 297; // A4 height in mm

                const doc = new jsPDF("portrait", "mm", [
                  pageWidth,
                  pageHeight,
                ]);
                doc.setFont("Helvetica", "bold");

                const studentBioDataPdf = AcademicsStudentData.data
                  ? AcademicsStudentData.data.GetAcdStudents.edges.map(
                      (data, index) => ({
                        AdmNo: data?.node.std_adm_no,
                        Name: `${data.node.first_name} ${data?.node.middle_name} ${data?.node.last_name}`,
                        RegNo: data.node.std_reg_no,
                        Branch: data.node.acd_branch.branch_desc,
                        Class: data.node.acd_class.class_desc,
                        Address: data.node.std_details.std_address,
                        City: data.node.std_details.std_place,
                        Gender: data.node.std_sex,
                        Father: data.node.std_father_name,
                        Mother: data.node.std_mother_name,
                        MotherToungue: data.node.std_details.std_mother_tongue,
                        Caste: data.node.std_details.std_caste,
                        Phone: data.node.std_mobile,
                        DOB: toStandardDate(data.node.std_dob),
                        Religion: data.node.std_details.std_religion,
                        CasteGroup: data.node.std_details.std_caste_group,
                      })
                    )
                  : [];
                const itemsPerPage = 4;
                const totalPages = Math.ceil(
                  studentBioDataPdf?.length! / itemsPerPage
                );

                let startY = 10;

                doc.setFillColor(255, 255, 255);
                doc.rect(0, 0, pageWidth, pageHeight, "F");

                doc.setFont("Helvetica", "bold");
                doc.setFontSize(15);
                doc.setTextColor(0, 0, 0);
                doc.text(
                  `${InstDetails?.data?.nodes[0].inst_name}`,
                  pageWidth / 2,
                  startY,
                  { align: "center" }
                );

                doc.setFont("Times New Roman", "normal");
                doc.setFontSize(11);
                doc.text(
                  `${InstDetails?.data?.nodes[0]?.inst_address}`,
                  pageWidth / 2,
                  startY + 7,
                  { align: "center" }
                );
                doc.text(
                  `${InstDetails?.data?.nodes[0]?.inst_place} ${InstDetails?.data?.nodes[0]?.inst_pin}`,
                  pageWidth / 2,
                  startY + 12,
                  { align: "center" }
                );
                doc.setFontSize(12);
                doc.text(
                  `FY:${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  }`,
                  15,
                  startY + 21
                );
                doc.setFontSize(12);
                doc.setFont("Times New Roman", "normal");
                doc.text(`Students Bio Data List`, pageWidth / 2, startY + 21, {
                  align: "center",
                });
                doc.setFontSize(12);
                doc.text(
                  `As on date:${toStandardDate(serverDate)}`,
                  160,
                  startY + 21
                );
                doc.addImage(result, "JPEG", 15, 5, 21, 21);
                for (let i = 0; i < totalPages; i++) {
                  if (i > 0) {
                    doc.addPage();
                    startY = 0;
                  }
                  const startItem = i * itemsPerPage;
                  const endItem = startItem + itemsPerPage;
                  const pageItems = studentBioDataPdf?.slice(
                    startItem,
                    endItem
                  );
                  // eslint-disable-next-line
                  pageItems?.forEach((data, index) => {
                    const startX = 15;
                    const startXRegNo = 135;

                    const lineHeight = 60;
                    const {
                      AdmNo,
                      Name,
                      RegNo,
                      Branch,
                      Class,
                      Address,
                      City,
                      Gender,
                      Father,
                      Mother,
                      MotherToungue,
                      Caste,
                      Phone,
                      DOB,
                      Religion,
                      CasteGroup,
                    } = data;
                    const maxAddressLength = 40;
                    const address =
                      Address.length > maxAddressLength
                        ? Address.substring(0, maxAddressLength)
                        : Address;
                    const remainingInfo =
                      Address.length > maxAddressLength
                        ? Address.substring(maxAddressLength)
                        : "";
                    const remainingInfoLine = remainingInfo
                      ? `\n${" ".repeat(16)}${remainingInfo}`
                      : "";
                    const info = `Adm No.: ${AdmNo} \nName:  ${Name} \n${classLabel} : ${Class} \nAddress : ${address}${remainingInfoLine} \nTown/City : ${City} \nSex : ${Gender} \nFather : ${Father}  \nMother : ${Mother} \nMother Tongue : ${MotherToungue} \nCaste : ${Caste}`;

                    const registerNo = `Reg No : ${RegNo}`;
                    const branchDesc = `${branchLabel} : ${Branch}`;
                    const phone = `Phone : ${Phone} `;
                    const dob = `Date of birth : ${DOB} `;
                    const religion = `Religion : ${Religion} `;
                    const casteGroup = `Caste Group : ${CasteGroup} `;
                    const y = startY + 33 + index * lineHeight;
                    const rectX = 12;
                    const rectY = y - 8;
                    const rectWidth = 185;
                    const rectHeight = 54;
                    doc.rect(rectX, rectY, rectWidth, rectHeight);

                    doc.setFontSize(11);
                    doc.text(info, startX, y);
                    doc.text(registerNo, startXRegNo, y);
                    doc.text(branchDesc, startXRegNo, y + 5);
                    doc.text(phone, startXRegNo, y + 20);
                    doc.text(dob, startXRegNo, y + 25);
                    doc.text("Occupation :", startXRegNo, y + 30);
                    doc.text("Occupation :", startXRegNo, y + 35);

                    doc.text(religion, startXRegNo, y + 40);
                    doc.text(casteGroup, startXRegNo, y + 45);
                  });

                  startY += itemsPerPage * 30 + 40;
                }
                doc.save(
                  `${InstDetails?.data?.nodes[0]?.inst_name} ${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  }`
                );
              });
            });
        });
      });
    setPdfFlag(false);
    setRowsPerPage(ROWS_PER_PAGE);
  };

  // eslint-disable-next-line
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Students");
    const dataRows = stdData
      ? stdData?.map((item) => [
          item.SlNo,
          item.AdmNo,
          item.RegNo,
          item.Name,
          item.Father,
          item.Mother,
          item.Gender,
          item.Mobile,
          item.Telephone,
          item.Branch,
          item.Class,
          item.Section,
          toStandardDate(item.Dob),
          item.Toungue,
          item.Religion,
        ])
      : [];
    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 6;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 25;
    worksheet.getColumn(7).width = 22;
    worksheet.getColumn(8).width = 22;
    worksheet.getColumn(9).width = 22;
    worksheet.getColumn(10).width = 22;
    worksheet.getColumn(11).width = 22;
    worksheet.getColumn(12).width = 22;
    worksheet.getColumn(13).width = 22;
    worksheet.getColumn(14).width = 22;
    worksheet.getColumn(15).width = 22;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "O1:O3");

          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "B1:B3");
                  worksheet.mergeCells(1, 1, 1, dataRows[0]!.length);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails?.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails?.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:O2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails?.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails?.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:O3");

                  const mergedHeader: Cell = worksheet.getCell(D4_CELL);
                  mergedHeader.value =
                    type === StudentDetailedReportType.STUDENT_BIO_DATA
                      ? "Students Bio Data"
                      : "Students Mailing Label";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("D4:H4");
                  const mergedDate: Cell = worksheet.getCell(I4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("I4:O4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:C4");

                  let Char = FIRST_INDEX;

                  for (let i = 0; i < studentBioDataHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = studentBioDataHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(
                      DOWNLOAD,
                      InstDetails?.data?.nodes[0]?.inst_name!
                    );
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(ROWS_PER_PAGE);
                });
              });
          });
        });
      });
  };
  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !AcademicsStudentData.loading)
      downloadExcel();
    if (
      pdfFlag &&
      rowsPerPage === null &&
      !AcademicsStudentData.loading &&
      type === StudentDetailedReportType.MAILING_LABEL
    ) {
      downloadPdf();
    }
    if (
      pdfFlag &&
      rowsPerPage === null &&
      !AcademicsStudentData.loading &&
      type === StudentDetailedReportType.STUDENT_BIO_DATA
    ) {
      downloadBioDataPdf();
    }
    // eslint-disable-next-line
  }, [excelFlag, pdfFlag, rowsPerPage, AcademicsStudentData.loading]);
  useEffect(() => {
    if (AcademicsStudentData.data && !AcademicsStudentData.loading) {
      const newData = AcademicsStudentData.data
        ? AcademicsStudentData?.data.GetAcdStudents.edges.map((edge) => ({
            ...edge,
            node: {
              ...edge.node,
              isChecked: true, // set default value of isChecked to true
            },
          }))
        : [];

      if (endCursor) {
        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = students.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setHasNextPage(
        AcademicsStudentData.data.GetAcdStudents.pageInfo.hasNextPage
      );
      setEndCursor(AcademicsStudentData.data.GetAcdStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [AcademicsStudentData.data, AcademicsStudentData.loading]);
  useEffect(() => {
    if (type) {
      setDepartmentSelected(null);
      setBranchSelected(null);
      setClassSelected(null);
      setSemesterSelected(null);
      setSectionSelected(null);
      setSortBySelected(null);
      setStatusSelected(null);
    }
  }, [type]);
  return (
    <>
      {pageType === PageFor.MODAL && (
        <div className="row g-0">
          <div className="col">
            <Title>
              {type === StudentDetailedReportType.STUDENT_BIO_DATA
                ? "Students Bio Data"
                : "Student Mailing Label List"}
            </Title>
          </div>
        </div>
      )}

      <div
        className={
          pageType === PageFor.GENERAL
            ? "std-reservation__modal"
            : "std-reservation__modal--modal"
        }
      >
        <div className="row g-0 std-reservation__options">
          <div className="col-1 studentlist__select-flex studentlist__search">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
            />
          </div>
          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={departmentDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (departmentSelected) {
                      branchInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={branchDropDown}
                ref={branchRef!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchSelected) {
                      classInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, classSelected)
                }
                ref={classRef!}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (classSelected) {
                      semInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                    setSectionSelected(null);
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setCategorySelected(null);
                  setSectionSelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={semesterDropDown}
                ref={semRef!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (semesterSelected) {
                      categoryInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue);
                  } else {
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_SECTION_KEY ? (
            <div className="col-1">
              <Autocomplete
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, sectionSelected)
                }
                classes={classes}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && sectionSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue);
                  } else {
                    setSectionSelected(null);
                  }
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          {USE_CATEGORY_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={categoryDropDown}
                ref={categoryRef!}
                openOnFocus
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, categorySelected)
                }
                value={categorySelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setCategorySelected(newValue);
                  } else {
                    setCategorySelected(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    setCategorySelected(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={categoryLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col"></div>
        </div>

        {type === StudentDetailedReportType.STUDENT_BIO_DATA ||
        type === StudentDetailedReportType.MAILING_LABEL ? (
          <div className="std-detailed-report__options row g-0">
            <div className="col-1">
              <SortByAutocomplete
                label="Sort By"
                onChange={(newValue) => setSortBySelected(newValue)}
                sortByType={SortByFilterType.STUDENT}
                sortByValue={
                  sortBySelected ? sortBySelected.value : EMPTY_STRING
                }
              />
            </div>
            <div className="col-1">
              <StatusAutocomplete
                label="Status"
                onChange={(newValue) => {
                  setStatusSelected(newValue);
                }}
                statusValue={
                  statusSelected ? statusSelected.value : EMPTY_STRING
                }
              />
            </div>
            <div className="col"></div>
          </div>
        ) : null}
        <div className="std-reservation__tableblock">
          {!students?.length ? (
            <b className="nodata"> No students Found</b>
          ) : (
            <TableContainer
              className={tableClasses.table}
              onScroll={handleScroll}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={tableClasses.stickyHeaderSl}
                    >
                      {TableHeaders.SLNO}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={tableClasses.stickyHeaderAdmNo}
                    >
                      {TableHeaders.ADMISSION_NUMBER}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={tableClasses.stickyHeaderRegNo}
                    >
                      {TableHeaders.REGISTER_NUMBER}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={tableClasses.stickyHeaderName}
                    >
                      {TableHeaders.NAME}
                    </TableCell>
                    {type === StudentDetailedReportType.STUDENT_BIO_DATA && (
                      <>
                        <TableCell
                          align="center"
                          className={tableClasses.stickyHeader}
                        >
                          {TableHeaders.MOBILE}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={tableClasses.stickyHeader}
                        >
                          {TableHeaders.DOB}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={tableClasses.stickyHeader}
                        >
                          {TableHeaders.GENDER}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={tableClasses.stickyHeaderFName}
                        >
                          {TableHeaders.FATHER_NAME}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={tableClasses.stickyHeaderFName}
                        >
                          {TableHeaders.MOTHER_NAME}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={tableClasses.stickyHeader}
                        >
                          {TableHeaders.MOTHER_TONGUE}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={tableClasses.stickyHeader}
                        >
                          {TableHeaders.RELIGION}
                        </TableCell>
                      </>
                    )}
                    {type === StudentDetailedReportType.MAILING_LABEL && (
                      <>
                        <TableCell
                          align="center"
                          className={tableClasses.stickyHeader}
                        >
                          {TableHeaders.ADDRESS}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={tableClasses.stickyHeader}
                        >
                          {TableHeaders.PLACE}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students?.map((res, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className={tableClasses.stickyColumnSl}
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className={tableClasses.stickyColumnAdmno}>
                          {res.node.std_adm_no}
                        </TableCell>
                        <TableCell className={tableClasses.stickyColumnRegno}>
                          {res.node.std_reg_no}
                        </TableCell>
                        <TableCell className={tableClasses.stickyColumnName}>
                          {`${res.node.first_name} ${res.node.middle_name} ${res.node.last_name}`}
                        </TableCell>
                        {type ===
                          StudentDetailedReportType.STUDENT_BIO_DATA && (
                          <>
                            <TableCell className={tableClasses.stickyColumn}>
                              {res.node.std_mobile}
                            </TableCell>
                            <TableCell className={tableClasses.stickyColumn}>
                              {toStandardDate(res.node.std_dob)}
                            </TableCell>
                            <TableCell className={tableClasses.stickyColumn}>
                              {res.node.std_sex}
                            </TableCell>
                            <TableCell
                              className={tableClasses.stickyColumnFname}
                            >
                              {res.node.std_father_name}
                            </TableCell>
                            <TableCell
                              className={tableClasses.stickyColumnFname}
                            >
                              {res.node.std_mother_name}
                            </TableCell>
                            <TableCell className={tableClasses.stickyColumn}>
                              {res.node.std_details.std_mother_tongue}
                            </TableCell>
                            <TableCell className={tableClasses.stickyColumn}>
                              {res.node.std_details.std_religion}
                            </TableCell>
                          </>
                        )}
                        {type === StudentDetailedReportType.MAILING_LABEL && (
                          <>
                            <TableCell className={tableClasses.stickyColumn}>
                              {res.node.std_details.std_address}
                            </TableCell>
                            <TableCell className={tableClasses.stickyColumn}>
                              {res.node.std_details.std_place}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        <div className="row g-0">
          <div className="col">
            {pageType === PageFor.GENERAL &&
            type === StudentDetailedReportType.STUDENT_BIO_DATA ? (
              <>
                <Button mode="clear" />
                {/* <Button mode="print" /> */}
                <Button
                  onClick={() => {
                    setRowsPerPage(null);
                    setPdfFlag(true);
                  }}
                  mode="pdf"
                />
                <Button
                  onClick={() => {
                    setRowsPerPage(null);
                    setExcelFlag(true);
                  }}
                  mode="export"
                />
              </>
            ) : pageType === PageFor.GENERAL &&
              type === StudentDetailedReportType.MAILING_LABEL ? (
              <>
                <Button mode="clear" />
                {/* <Button mode="print" /> */}
                <Button
                  onClick={() => {
                    setRowsPerPage(null);
                    setPdfFlag(true);
                  }}
                  mode="pdf"
                />
                <Button
                  onClick={() => {
                    setRowsPerPage(null);
                    setExcelFlag(true);
                  }}
                  mode="export"
                />
              </>
            ) : pageType === PageFor.MODAL &&
              type === StudentDetailedReportType.STUDENT_BIO_DATA ? (
              <>
                <Button
                  onClick={() => {
                    setRowsPerPage(null);
                    setPdfFlag(true);
                  }}
                  mode="pdf"
                />
                <Button
                  onClick={() => {
                    setRowsPerPage(null);
                    setExcelFlag(true);
                  }}
                  mode="export"
                />
                <Button
                  mode="cancel"
                  onClick={() => {
                    setBioDataModal?.(false);
                    setMailingModal?.(false);
                  }}
                />
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setRowsPerPage(null);
                    setPdfFlag(true);
                  }}
                  mode="pdf"
                />
                <Button onClick={downloadExcel} mode="export" />
                <Button
                  mode="cancel"
                  onClick={() => {
                    setBioDataModal?.(false);
                    setMailingModal?.(false);
                  }}
                />
              </>
            )}
          </div>
          <div className="col-3 std-reservation__total">
            <StudentTotalCount
              totalCount={
                AcademicsStudentData?.data
                  ? AcademicsStudentData?.data?.GetAcdStudents.totalCount!
                  : 0
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default StdBioData;
