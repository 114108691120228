import React, { useContext, useEffect, useState } from "react";
import Home from "../Home/Index";
import FileAttach from "../../../images/BrowseFiles.svg";
import TotalBooks from "../../../images/TotalBooks.svg";
import ReadyToImport from "../../../images/Present.svg";
import ContainsError from "../../../images/Absent.svg";
import Close from "../../../images/Close.svg";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import Eduate from "../../../images/Eduate_Logo_image.png";
import * as XLSX from "xlsx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  ExcelAlignment,
  ExcelPageHeader,
  FileUploadParams,
  InstitutionConfigurationTypes,
  Operation,
} from "../../../utils/Enum.types";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  HEADER_ALIGNMENT_LEFT,
  FILENAME,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS_1,
  FROZEN_CELLS_INST,
  G4_CELL,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_CSS,
  HEADER_ALIGNMENT_RIGHT,
  TABLE_HEADER_CSS,
} from "../Constants";
import {
  convertToDateObject_MMDDYYYYFormat,
  getHeaderRowStyle,
  toIsoDate,
  toStandardDate,
} from "../../../utils/UtilFunctions";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import useServerDateandTime from "../customHooks/useServerDateandTime";
import useLibMasterDataOptions, {
  LibMasterData,
} from "../customHooks/useLibMasterDataOptions";
import Modal from "react-modal";
import { DEFAULT_TIME, EMPTY_STRING } from "../../../utils/constants";
import { StudentExcelTableStyles } from "../../../styles/StickyTableStyles";
import { useMutation } from "@apollo/client";
import { ImportLibBooks } from "../../../queries/Library/Import/new";
import MessageModal from "../../../pages/MessageModal";
import { msgType } from "../../../utils/Form.types";
import { useNavigate, useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";
import ExcelLoading from "../../../pages/ExcelLoading";
import { LoadingStyles } from "../../../styles/ModalStyles";
import useRefreshToken from "../../../customhooks/useRefreshToken";

const Import = () => {
  const classes = StudentExcelTableStyles();
  const { token: initialToken, refreshToken } = useRefreshToken();
  const { InstId } = useParams();
  const navigate = useNavigate();
  const [records, setRecords] = useState<any[]>([]);
  const [successCount, setSuccessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const { user_details } = useLoggedInUserDetails();
  const [expanded, setExpanded] = useState(false);
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.USE_EDUATE_LOGO_IN_FILES
  );
  const configLogo =
    configData?.data?.GetSwConfigVariables[0].config_boolean_value;
  const displayFileName = (): void => {
    const inputElement = document.getElementById("input") as HTMLInputElement;
    const fileName = inputElement.value;
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = fileName.split("\\").pop()!;
  };
  const [importModal, setImportModal] = useState(false);
  const { medias } = useLibMasterDataOptions(LibMasterData.MEDIA, EMPTY_STRING);
  const mediaList = medias?.map((res) => res.label);
  const [AddBooks, { loading }] = useMutation(ImportLibBooks, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const { attributes } = useLibMasterDataOptions(
    LibMasterData.ATTRIBUTE,
    EMPTY_STRING
  );
  const attributeList = attributes?.map((res) => res.label);

  const { subjects } = useLibMasterDataOptions(
    LibMasterData.SUBJECT,
    EMPTY_STRING
  );
  const totalRecords = records.filter(
    (record) => !record.isValidatedAndError
  ).length;
  const subjectList = subjects?.map((res) => res.label);

  const { publishers } = useLibMasterDataOptions(
    LibMasterData.PUBLISHERS,
    EMPTY_STRING
  );
  const publishersList = publishers?.map((res) => res.label);

  const { authors } = useLibMasterDataOptions(
    LibMasterData.AUTHORS,
    EMPTY_STRING
  );
  const authorsList = authors?.map((res) => res.label);

  const { bookCategories } = useLibMasterDataOptions(
    LibMasterData.CATEGORY,
    EMPTY_STRING
  );
  const bookCategoriesList = bookCategories?.map((res) => res.label);

  const { languages } = useLibMasterDataOptions(
    LibMasterData.LANGUAGES,
    EMPTY_STRING
  );
  const languagesList = languages?.map((res) => res.label);
  const { vendors } = useLibMasterDataOptions(
    LibMasterData.VENDORS,
    EMPTY_STRING
  );
  const [message, setMessage] = useState<msgType>({
    message: EMPTY_STRING,
    flag: false,
    operation: Operation.NONE,
  });
  const { state } = useContext(AppContext);
  // eslint-disable-next-line
  const vendorList = vendors?.map((res) => res.label);
  const { InstDetails } = useInstDetails(1);
  const { LogoOrSign } = useInstLogoDetails({
    filetype: FileUploadParams.INST_LOGO,
  });
  const { serverDate } = useServerDateandTime();

  const library_schema = {
    Classification_Name: {
      name: "classification_desc",
    },
    Accession_Number: {
      name: "book_access_no",
    },
    Classification_Date: {
      name: "classification_date",
    },
    Reference: {
      name: "reference",
    },

    BookVolume: {
      name: "BookVolume",
    },
    KeyWord: {
      name: "KeyWord",
    },
    Media: {
      name: "media_desc",
    },
    Attribute: {
      name: "attribute_desc",
    },
    Subject: {
      name: "subject_desc",
    },
    Publisher: {
      name: "publisher_name",
    },
    Author: {
      name: "author_name",
    },
    Book_Category: {
      name: "book_category",
    },
    Language: {
      name: "language",
    },
    Year: {
      name: "year",
    },
    No_of_Pages: {
      name: "pages",
    },
    Edition: {
      name: "edition",
    },
    Place: {
      name: "place",
    },
    Vendor: {
      name: "vendor_name",
    },
    Bill_Number: {
      name: "bill_no",
    },
    Date_of_Purchase: {
      name: "dop",
    },
    No_of_Books: {
      name: "no_of_books",
    },
    Purchase_Cost: {
      name: "purchase_cost",
    },
    Actual_Cost: {
      name: "actual_cost",
    },
    Move_To_Shelf: {
      name: "shelf",
    },
    Department: {
      name: "dept_name",
    },
  };
  const handleButtonClick = async () => {
    try {
      await Promise.all(records.map((record) => handleClick(record.id)));
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (id: number) => {
    const filteredData = records.find((record) => record.id === id);
    if (filteredData) {
      const newData = records.map((record, index) => {
        if (id === record.id) {
          return {
            ...record,
            isChecked: !record?.isChecked,
          };
        }
        return record;
      });
      setRecords(newData);
    }
  };

  const LibraryBookHeader = [
    {
      Headers: "Classification_Name",
      key: "classification_desc",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Accession_Number",
      key: "book_access_no",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Classification_Date",
      key: "classification_date",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Media",
      key: "media_desc",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Attribute",
      key: "attribute_desc",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Subject",
      key: "subject_desc",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Publisher",
      key: "publisher_name",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Author",
      key: "author_name",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Book_Category",
      key: "book_category",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Language",
      key: "language",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Year",
      key: "year",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "No_of_Pages",
      key: "pages",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Edition",
      key: "edition",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Place",
      key: "place",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Vendor",
      key: "vendor_name",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Bill_Number",
      key: "bill_no",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Date_of_Purchase",
      key: "dop",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "No_of_Books",
      key: "no_of_books",
      width: 25,
      required: false,
      type: Number,
    },
    {
      Headers: "Purchase_Cost",
      key: "purchase_cost",
      width: 25,
      required: false,
      type: Number,
    },
    {
      Headers: "Actual_Cost",
      key: "actual_cost",
      width: 25,
      required: false,
      type: Number,
    },
    {
      Headers: "Move_To_Shelf",
      key: "shelf",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Department",
      key: "dept_name",
      width: 25,
      required: false,
      type: String,
    },
    {
      Headers: "Reference",
      key: "reference",
      width: 25,
      required: false,
      type: Boolean,
    },
    {
      Headers: "BookVolume",
      key: "BookVolume",
      width: 25,
      required: false,
      type: Number,
    },
    {
      Headers: "KeyWord",
      key: "KeyWord",
      width: 25,
      required: false,
      type: String,
    },
  ];

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Complete Library ConfigData");
    const worksheet1 = workbook.addWorksheet("Import Books");
    const dataRows = ["Book Name", "number", "dd/mm/yyyy"];
    worksheet.views = FROZEN_CELLS_INST;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 22;
    worksheet.getColumn(2).width = 18;
    worksheet.getColumn(3).width = 18;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(8).width = 30;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 18;
    worksheet.getColumn(11).width = 18;
    worksheet.getColumn(12).width = 18;
    worksheet.getColumn(13).width = 18;
    worksheet.getColumn(14).width = 18;
    worksheet.getColumn(15).width = 18;
    worksheet.getColumn(16).width = 18;
    worksheet.getColumn(17).width = 18;
    worksheet.getColumn(18).width = 18;
    worksheet.getColumn(19).width = 18;
    worksheet.getColumn(20).width = 18;
    worksheet.getColumn(21).width = 18;

    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });

    worksheet1.views = FROZEN_CELLS_1;
    worksheet1.getColumn(1).width = 30;
    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });
          if (configLogo) {
            worksheet.addImage(imageV, "U1:U3");
          }
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });
                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, LibraryBookHeader?.length!);
                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:U2");
                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:U3");

                  const mergedHeader: Cell = worksheet.getCell(G4_CELL);
                  mergedHeader.value = "Complete Library Data";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("G4:Q4");
                  const mergedDate: Cell = worksheet.getCell("R4");
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_RIGHT;
                  worksheet.mergeCells("R4:U4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:F4");
                  let Char = FIRST_INDEX;

                  let libraryInfoHeader = LibraryBookHeader?.map(
                    (f) => f.Headers
                  );
                  for (let i = 0; i < LibraryBookHeader.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = libraryInfoHeader[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  worksheet.addRow(dataRows);
                  for (
                    let i = 0;
                    i <
                    Math.max(
                      mediaList.length,
                      attributeList.length,
                      subjectList.length,
                      publishersList.length,
                      authorsList.length,
                      bookCategoriesList.length,
                      languagesList.length
                    );
                    i++
                  ) {
                    const row = worksheet.addRow([]);
                    if (i < mediaList.length) {
                      row.getCell(4).value = mediaList[i];
                      row.getCell(4).alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };
                    }
                    if (i < attributeList.length) {
                      row.getCell(5).value = attributeList[i];
                      row.getCell(5).alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };
                    }
                    if (i < subjectList.length) {
                      row.getCell(6).value = subjectList[i];
                      row.getCell(6).alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };
                    }
                    if (i < publishersList.length) {
                      row.getCell(7).value = publishersList[i];
                      row.getCell(7).alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };
                    }

                    if (i < authorsList.length) {
                      row.getCell(8).value = authorsList[i];
                      row.getCell(8).alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };
                    }

                    if (i < bookCategoriesList.length) {
                      row.getCell(9).value = bookCategoriesList[i];
                      row.getCell(9).alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };
                    }

                    if (i < languagesList.length) {
                      row.getCell(10).value = languagesList[i];
                      row.getCell(10).alignment = {
                        horizontal: ExcelAlignment.LEFT,
                      };
                    }
                  }
                  for (let i = 0; i < LibraryBookHeader.length; i++) {
                    const Char = String.fromCharCode(65 + i);

                    const rowData2: Cell = worksheet1.getCell(Char + 1);
                    rowData2.value = libraryInfoHeader[i];
                    rowData2.fill = TABLE_HEADER_CSS;
                    rowData2.border = BORDER_DATA;
                    rowData2.font = ACC_HEADER_FONT;
                    rowData2.alignment = { horizontal: ExcelAlignment.CENTER };
                  }
                  worksheet1.columns.forEach((column) => {
                    column.width = 20;
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, "Books Import");
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      flag: false,
      message: EMPTY_STRING,
      operation: Operation.NONE,
    });
  };
  const processExcel = (data: any) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[1];

    var records = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    // eslint-disable-next-line
    records.map((record: any) => {
      let errors: any = [];
      library_schema &&
        // eslint-disable-next-line
        Object.keys(library_schema).map((key) => {
          // @ts-ignore
          let keySchema = library_schema[key];
          let keyValue = record[key];
          if (
            keySchema.required &&
            (keyValue === null || keyValue === undefined)
          ) {
            let errorObj = {
              column: key,
              error: "Required",
            };
            errors.push(errorObj);
          }
        });
      if (errors.length > 0) {
        record.errors = errors;
      }
    });
    return records;
  };
  const handleClear = () => {
    setRecords([]);
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = "";
  };

  const handleAddBooks = async () => {
    setSuccessCount(0);
    setErrorCount(0);
    setImportModal(true);

    let currentToken = initialToken;

    const executeMutation = async (record: any, token: string) => {
      try {
        const keyWordsArray = record.KeyWord
          ? record.KeyWord.split(",").map((keyword: string) => keyword.trim())
          : [];

        await AddBooks({
          variables: {
            token,
            inst_id: InstId!,
            user_details,
            lib_flags: {
              use_accession_num_as_is: !!record.Accession_Number,
            },
            input: {
              book_access_no: record.Accession_Number || EMPTY_STRING,
              classification_desc: record.Classification_Name,
              classification_date: record.Classification_Date
                ? toIsoDate(
                    convertToDateObject_MMDDYYYYFormat(
                      record.Classification_Date
                    )
                  )
                : toIsoDate(DEFAULT_TIME),
              reference: record.Reference?.toLowerCase() === "yes",
              book_published_year: record.Year || EMPTY_STRING,
              book_pages: record.No_of_Pages || EMPTY_STRING,
              book_edition: record.Edition || EMPTY_STRING,
              book_volume: record.BookVolume || 0,
              book_place: record.Place || EMPTY_STRING,
              media_desc: record.Media || EMPTY_STRING,
              category_desc: record.Book_Category || EMPTY_STRING,
              subject_desc: record.Subject || EMPTY_STRING,
              publisher_name: record.Publisher || EMPTY_STRING,
              language: record.Language || EMPTY_STRING,
              attribute_desc: record.Attribute || EMPTY_STRING,
              author_name: record.Author || EMPTY_STRING,
              pur_bill_no: record.Bill_Number || EMPTY_STRING,
              pur_total_books: record.No_of_Books || 0,
              pur_date: record.Date_of_Purchase
                ? toIsoDate(
                    convertToDateObject_MMDDYYYYFormat(record.Date_of_Purchase)
                  )
                : toIsoDate(DEFAULT_TIME),
              pur_value: record.Purchase_Cost || 0,
              pur_actual_value: record.Actual_Cost || 0,
              rack_name: record.Move_To_Shelf || EMPTY_STRING,
              vendor_name: record.Vendor || EMPTY_STRING,
              dept_name: record.Department || EMPTY_STRING,
              key_word: keyWordsArray,
            },
          },
          onError: (err) => {
            if (err.message.includes("token expired")) {
              throw new Error("token expired");
            }
            setMessage({
              flag: true,
              message: err.message,
              operation: Operation.NONE,
            });
            setErrorCount((prev) => prev + 1);
          },
        }).then(({ data }) => {
          if (data) {
            setSuccessCount((prev) => prev + 1);
          }
        });
      } catch (error) {
        console.error(error);
        //@ts-ignore
        if (error.message === "token expired") {
          currentToken = await refreshToken();
          await executeMutation(record, currentToken);
        } else {
          setErrorCount((prev) => prev + 1);
        }
      }
    };
    for (const record of records.filter(
      (record) => !record.isValidatedAndError
    )) {
      await executeMutation(record, currentToken);
    }
  };

  useEffect(() => {
    const input = document.getElementById("input") as HTMLInputElement;
    if (input) {
      input.addEventListener("change", () => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const res = processExcel(reader.result);
          setRecords(
            res.map((f: any, index: number) => {
              return {
                ...f,
                id: index + 1,
                isChecked: false,
              };
            })
          );
        };
        reader.readAsBinaryString(input.files![0]);
      });
    }
  });

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Books Import</Title>
      <div className="import-books-data">
        <div className="row g-0 import-books-data__blocks">
          <div className="col-6">
            <div className="import-books-data__file-upload">
              <div className="import-books-data__file-upload--file-name">
                <span id="file-name"></span>
              </div>

              <div className="import-books-data__file-upload--browse">
                <label htmlFor="input">
                  <img src={FileAttach} alt="/" /> Choose Excel File
                </label>
                <input
                  id="input"
                  type="file"
                  name="file"
                  onChange={() => displayFileName()}
                  className="import-books-data__file-upload--input"
                />
              </div>
            </div>
          </div>
          <div className="col import-books-data__blocks--cards">
            <b>
              <img src={TotalBooks} alt="/" />
              Total Books
            </b>
            <span className="import-books-data__blocks--cards--total-students">
              {records.length}
            </span>
          </div>

          <div
            className="col import-books-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ReadyToImport} alt="/" />
              Ready to Import
            </b>
            <span className="import-books-data__blocks--cards--ready-to-import">
              {records.filter((record) => !record.isValidatedAndError).length}
            </span>
          </div>

          <div
            className="col import-books-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ContainsError} alt="/" />
              Contains Error
            </b>
            <span className="import-books-data__blocks--cards--contains-error">
              {records.filter((record) => record.isValidatedAndError).length}
            </span>
          </div>
        </div>
        <div className="import-books-data__tableblock">
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.stickyHeaderSl} id="td-center">
                    Sl
                  </TableCell>
                  {library_schema &&
                    Object.keys(library_schema)?.map((key, index) => {
                      // @ts-ignore
                      return (
                        <TableCell
                          key={index}
                          className={classes.stickyHeader}
                          id="td-center"
                        >
                          {key}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>

              <TableBody>
                {records
                  .filter((record) => !record?.errors)
                  .map((record, index) => (
                    <TableRow key={index}>
                      <TableCell
                        className={classes.stickyColumnSl}
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      {library_schema && // eslint-disable-next-line
                        Object.keys(library_schema).map((key, index) => {
                          // @ts-ignore
                          const fieldSchema = library_schema[key];

                          if (
                            fieldSchema.required ||
                            fieldSchema.required === undefined
                          )
                            return (
                              <TableCell key={index}>{record[key]}</TableCell>
                            );
                        })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="row ">
          <div className="col">
            <Button onClick={handleAddBooks}>Import Books</Button>
            <Button onClick={handleButtonClick}>Validate</Button>
            {/* <Button onClick={handleClear} mode="clear" /> */}

            {/* <Button
                onClick={downloadExcelContainsError}
              mode="excel"
            >
              Export error Data
            </Button> */}
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col import-books-data__button">
            <Button mode="excel" onClick={downloadExcel}>
              Generate Template XLS File
            </Button>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={LoadingStyles}
        ariaHideApp={false}
      >
        <ExcelLoading
          total={totalRecords}
          success={successCount}
          errors={errorCount}
          loading={loading}
        />
        <div className="modal-flex__image">
          <img
            onClick={() => setImportModal(!importModal)}
            src={Close}
            alt="/"
            className="modal-close-icon"
          />
        </div>
      </Modal>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default Import;
