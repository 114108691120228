import React, { useContext } from "react";
import NavBar from "../../../components/common/Navbar/Index";
import { LicenseTypes, UserType } from "../../../utils/Enum.types";
import Teacher from "../Dashboard/Teacher/Index";

import useAssignedInstbyEmpId, {
  InstUserAccessQueryType,
} from "../../UserRights/hooks/useAssignedInstbyEmpId";
import { AppContext } from "../../../context/context";
import AdminDashboard from "../Dashboard/Admin/Index";
import useUserRightsByEmpId from "../../UserRights/hooks/useUserRightsByEmpId";
interface DashBoardProps {
  DashBoardRequired: boolean;
}
const Index = ({ DashBoardRequired }: DashBoardProps) => {
  const { state } = useContext(AppContext);

  const { MultipleInstitutions } = useAssignedInstbyEmpId(
    InstUserAccessQueryType.USER_ACCESS_BY_EMP_ID,
    state.empLoginId
  );
  const isTeacher =
    MultipleInstitutions?.data?.GetInstUserAccess.edges[0]?.node?.pr_emp_details
      .emp_type === UserType.TEACHER;
  const isAdmin =
    MultipleInstitutions?.data?.GetInstUserAccess.edges[0]?.node?.pr_emp_details
      .emp_type === UserType.SYS_ADMIN;

  const { USE_ACADEMICS } = useUserRightsByEmpId();

  return (
    <>
      <NavBar
        NavType={LicenseTypes.ACADEMIC}
        TeacherDashboard={
          isTeacher &&
          ((USE_ACADEMICS.details &&
            USE_ACADEMICS.details.module_rights_type !== "1") ||
            USE_ACADEMICS.details === null)
            ? true
            : false
        }
        AdminDashboard={isAdmin ? true : false}
      />
      {isTeacher ? <> {DashBoardRequired && <Teacher />}</> : null}

      {isAdmin ? <> {DashBoardRequired && <AdminDashboard />}</> : null}
    </>
  );
};

export default Index;
