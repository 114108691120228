import {
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ThemeMenuProps } from "../../../styles/Accordion";
import { useStyles } from "../../../styles/TooltipStyles";
import Teal from "../../../images/Teal.svg";

import SkyBlue from "../../../images/SkyBlue.svg";
import Purple from "../../../images/Purple.svg";
import Pink from "../../../images/Pink.svg";
import Lime from "../../../images/Lime.svg";
import Green from "../../../images/Green.svg";
import Fuchsia from "../../../images/Fuchsia.svg";
import Grey from "../../../images/Grey.svg";
import Blue from "../../../images/Blue.svg";
import Amber from "../../../images/Amber.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UpdateUserRightsTheme } from "../../../Modules/UserRights/queries/mutation";
import useToken from "../../../customhooks/useToken";
import { AppContext } from "../../../context/context";
import { GetUserRightsByEmpId } from "../../../Modules/UserRights/queries/List";
import useLoggedInUserDetails from "../../../Modules/Accounts/hooks/useLoggedInUserDetails";

const Skins = () => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [theme, setThem] = useState("");
  const { user_details } = useLoggedInUserDetails();

  const [GetTheme, { data }] = useLazyQuery(GetUserRightsByEmpId);
  const [UpdateTheme] = useMutation(UpdateUserRightsTheme);

  const setTheme = (theme: string) => {
    UpdateTheme({
      variables: {
        token,
        inst_id: state.InstId,
        user_details,
        pr_emp_id: state.empLoginId,
        theme,
      },
      refetchQueries: [
        {
          query: GetUserRightsByEmpId,
          variables: {
            inst_id: state.InstId,
            pr_emp_id: state.empLoginId,
            token,
          },
        },
      ],
    });
  };

  const [themeOptions, setThemeOptions] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    if (token && state.InstId && state.empLoginId) {
      GetTheme({
        variables: {
          inst_id: state.InstId,
          pr_emp_id: state.empLoginId,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          document.documentElement.className =
            data?.GetUserRightsByEmpId.default_theme!;
          setThem(data?.GetUserRightsByEmpId.default_theme!);
        }
      });
    }
  }, [token, state.InstId, state.empLoginId, GetTheme, data]);
  const colorOption = [
    {
      label: "Teal",
      value: "teal",
      image: `${Teal}`,
      tooltip: classes.customTooltipTeal,
      arrow: classes.customArrowTeal,
    },

    {
      label: "Sky-Blue",
      value: "sky",
      image: `${SkyBlue}`,
      tooltip: classes.customTooltipSky,
      arrow: classes.customArrowSky,
    },

    {
      label: "Purple",
      value: "purple",
      image: `${Purple}`,
      tooltip: classes.customTooltipPurple,
      arrow: classes.customArrowPurple,
    },
    {
      label: "Pink",
      value: "pink",
      image: `${Pink}`,
      tooltip: classes.customTooltipPink,
      arrow: classes.customArrowPink,
    },

    {
      label: "Lime",
      value: "lime",
      image: `${Lime}`,
      tooltip: classes.customTooltipLime,
      arrow: classes.customArrowLime,
    },

    {
      label: "Green",
      value: "Green",
      image: `${Green}`,
      tooltip: classes.customTooltipGreen,
      arrow: classes.customArrowGreen,
    },
    {
      label: "Fuchsia",
      value: "fuchsia",
      image: `${Fuchsia}`,
      tooltip: classes.customTooltipFuchsia,
      arrow: classes.customArrowFuchsia,
    },

    {
      label: "Grey",
      value: "grey",
      image: `${Grey}`,
      tooltip: classes.customTooltipGrey,
      arrow: classes.customArrowGrey,
    },
    {
      label: "Blue",
      value: "blue",
      image: `${Blue}`,
      tooltip: classes.customTooltipBlue,
      arrow: classes.customArrowBlue,
    },
    {
      label: "Amber",
      value: "amber",
      image: `${Amber}`,
      tooltip: classes.customTooltipAmber,
      arrow: classes.customArrowAmber,
    },
  ];

  return (
    <Select
      onChange={(e: SelectChangeEvent) => {
        setTheme(e.target.value);
        setThemeOptions(!themeOptions);
      }}
      className="theme-option"
      value={theme}
      MenuProps={ThemeMenuProps}
    >
      {colorOption.map((option, index: React.Key) => (
        <MenuItem key={index} value={option.value} className="theme-menu">
          <ListItemText>
            <Tooltip
              title={option.label}
              arrow
              placement="bottom"
              classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
              }}
              className="tooltip-hide"
            >
              <img src={option.image} alt="/" className="theme-image" />
            </Tooltip>
          </ListItemText>
        </MenuItem>
      ))}
    </Select>
  );
};

export default Skins;
