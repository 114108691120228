import React, { useState } from "react";
import Home from "../../Home/Index";
import { Title } from "../../../../stories/Title/Title";
import Input from "../../../../stories/Input/Input";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteListStyles";
import { Autocomplete, TextField } from "@mui/material";

import { Button } from "../../../../stories/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Topper from "../../../../images/MakeAsGlobalUser.svg";
import SecondTopper from "../../../../images/SecondTopper.svg";
import ThirdTopper from "../../../../images/ThirdTopper.svg";
import ViewButton from "../../../../images/EyeWhite.svg";
import useTests from "../../hooks/useTests";
import { responseType } from "../../../../utils/Form.types";
import useAcdTestTypes from "../../hooks/useAcdTestTypes";
import { PageFor } from "../../../../utils/Enum.types";

interface Props {
  pageType: PageFor;
}
const TestWiseReports = ({ pageType }: Props) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const navigate = useNavigate();
  const { InstId } = useParams();

  const [testType, setTestType] = useState<responseType | null>(null);
  const { acdTestTypes } = useAcdTestTypes();

  const { acdTests } = useTests(false, testType ? testType.value : 0);
  const testTypeDropDown = acdTestTypes.responseType;
  const [testId, setTestId] = useState(0);
  const handleClick = (id: number) => {
    setTestId(testId);
    if (pageType === PageFor.REPORT) {
      navigate(`/${InstId}/academics/reports/testwise/${id}/view`);
    } else {
      navigate(`/${InstId}/academics/dailyactivities/testwise/${id}/view`);
    }
  };

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>
        {pageType === PageFor.REPORT ? "Test Wise Reports" : "Test List"}
      </Title>
      <div className="test-wise-report">
        <div className="test-wise-report__select row g-0">
          <div className="col-2">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={testTypeDropDown}
              value={testType}
              onChange={(e, newValue) => {
                if (newValue) {
                  setTestType(newValue);
                } else {
                  setTestType(null);
                }
              }}
              openOnFocus
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Test Type"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        </div>
        <div className="test-wise-report__datablock">
          <div className="test-wise-report__datablock--cards">
            {acdTests.data?.GetAcdTestName.map((res, index) => {
              // const isderivative = res.is_marks_derived;
              // const isNonAcademic = res.is_non_academic;

              return (
                <div className="test-wise-report__datablock--card" key={index}>
                  <b className="test-wise-report__datablock--card--title">
                    {res.test_name}
                  </b>

                  <span className="test-wise-report__datablock--card--sub-title">
                    {res.test_type_details.test_type_name}
                  </span>

                  {pageType == PageFor.REPORT && (
                    <div className="test-wise-report__datablock--card--flex">
                      <img src={Topper} alt="" />
                      <img src={SecondTopper} alt="" />
                      <img src={ThirdTopper} alt="" />
                    </div>
                  )}

                  <button
                    className="test-wise-report__datablock--card--view"
                    onClick={() => handleClick(res.id)}
                  >
                    Detailed View <img src={ViewButton} alt="" />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <Button mode="clear" />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
    </>
  );
};

export default TestWiseReports;
