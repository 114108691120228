import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import * as Excel from "exceljs";

import useToken from "../../../customhooks/useToken";
import { Button } from "../../../stories/Button/Button";
import Home from "../Home/Index";
import TotalStudents from "../../../images/TotalMale.svg";
import ReadyToImport from "../../../images/Present.svg";
import ContainsError from "../../../images/Absent.svg";
import Info from "../../../images/Information.svg";
import DownArrow from "../../../images/DownArrow.svg";
import FileAttach from "../../../images/BrowseFiles.svg";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";

import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";

import Eduate from "../../../images/Eduate_Logo_image.png";
import {
  ACC_HEADER_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  DOWNLOAD,
  ELEMENT,
  FILENAME,
  TABLE_HEADER_CSS,
} from "../../Library/Constants";
import { DebitOrCredit, ExcelAlignment, FileUploadParams } from "../../../utils/Enum.types";
import useInstDetails from "../../../customhooks/general/useInstDetails";
import useInstLogoDetails from "../../../customhooks/useInstLogoDetails";
import {
  handleClear,
  isOptionEqualToValue,
} from "../../../utils/UtilFunctions";
import { ImportStudentDataTableStyles } from "../../../styles/StickyTableStyles";
import { useStyles } from "../../../styles/TooltipStyles";
import { Title } from "../../../stories/Title/Title";
import { ValidateAcctLdgrName } from "../queries/FeeLedgers/query/Byid";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import { responseType } from "../../../utils/Form.types";
import useFinancialYears from "../../Academics/hooks/useFinancialYears";

const studentData = [
  {
    Headers: "voNo",
    key: "voNo",
    width: 50,
    required: true,
    type: String,
    use_in_sheet: true,
  },
  {
    Headers: "voDate",
    key: "voDate",
    width: 50,
    required: true,
    type: String,
    use_in_sheet: true,
  },
  {
    Headers: "slNo",
    key: "slNo",
    width: 50,
    required: true,
    type: Number,
    use_in_sheet: true,
  },
  {
    Headers: "AdmNO",
    key: "AdmNO",
    width: 50,
    required: true,
    type: String,
    use_in_sheet: true,
  },
  {
    Headers: "ldgrName",
    key: "ldgrName",
    width: 50,
    required: true,
    type: String,
    use_in_sheet: true,
  },
  {
    Headers: "CrAmount",
    key: "CrAmount",
    width: 50,
    required: true,
    type: Number,
    use_in_sheet: true,
  },
  {
    Headers: "DbAmount",
    key: "DbAmount",
    width: 50,
    required: true,
    type: Number,
    use_in_sheet: true,
  },
];

const student_schema = {
  voNo: {
    name: "voNo",
  },
  voDate: { name: "voDate" },
  slNo: {
    name: "slNo",
  },
  AdmNO: {
    name: "AdmNO",
  },
  ldgrName: {
    name: "ldgrName",
  },
  CrAmount: { name: "CrAmount" },
  DbAmount: { name: "DbAmount" },
};

interface ValidateStudentDataForImportVars {
  token: string;
  inst_id: string;
  acct_ldgr_name: string;
}
interface ValidateStudentDataForImportData {
  ValidateAcctLdgrName: boolean;
}
interface Record {
  AdmNO: string;
  CrAmount: number;
  DbAmount: number;
  crorDb: string;
  id: number;
  voDate: string;
  isChecked: boolean;
  isValidated: boolean;
  isValidatedAndError: boolean;
  ldgrName: string;
  slNo: number;
  voNo: string;
  errors?: any[];
}
const ImportStudentReceipt = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const navigate = useNavigate();

  const classes = ImportStudentDataTableStyles();
  const autoCompleteclasses = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();

  const { InstDetails } = useInstDetails(1);
  const [selectedFinancialYear, setSelectedFinancialYear] =
    useState<responseType | null>(null);

  const tooltipClasses = useStyles();
  const { LogoOrSign } = useInstLogoDetails({filetype:FileUploadParams.INST_LOGO});

  const [expanded, setExpanded] = useState(false);

  const [records, setRecords] = useState<Record[]>([]);

  const [ValidateEachRow] = useLazyQuery<
    ValidateStudentDataForImportData,
    ValidateStudentDataForImportVars
  >(ValidateAcctLdgrName);

  const { financialYearResponse } = useFinancialYears();
  const schemaKeys = Object.keys(student_schema);

  const filteredKeys = schemaKeys.filter((key) => {
    // @ts-ignore
    const fieldSchema = student_schema[key];
    if (fieldSchema.required || fieldSchema.required === undefined) {
      const hasData = records.some(
        // @ts-ignore
        (record) => record[key] !== undefined && record[key] !== ""
      );
      return hasData;
    }
    return false;
  });

  const InstName = InstDetails.data?.nodes[0]?.inst_name!;

  const groupedData: {
    [voNo: string]: {
      db: Record[];
      cr: Record[];
      dbTotal: number;
      crTotal: number;
    };
  } = {};

  records.forEach((record) => {
    if (!groupedData[record.voNo]) {
      groupedData[record.voNo] = { db: [], cr: [], dbTotal: 0, crTotal: 0 };
    }

    const group = groupedData[record.voNo];
    const existingRecord =
      group.db.find((existing) => existing.voDate !== record.voDate) ||
      group.cr.find((existing) => existing.voDate !== record.voDate);

    if (existingRecord) {
      if (!existingRecord.errors) {
        existingRecord.errors = [];
      }
      existingRecord.errors.push({
        column: "voDate",
        error: "Different voDate for the same voNo",
      });
      record.errors = existingRecord.errors; // Share the error array reference
    }

    if (record.crorDb === DebitOrCredit.DEBIT) {
      group.db.push(record);
      group.dbTotal += record.DbAmount;
    } else {
      group.cr.push(record);
      group.crTotal += record.CrAmount;
    }
  });

  // records.forEach((record) => {
  //   if (!groupedData[record.voNo]) {
  //     groupedData[record.voNo] = { db: [], cr: [], dbTotal: 0, crTotal: 0 };
  //   }

  //   if (record.crorDb === DebitOrCredit.DEBIT) {
  //     groupedData[record.voNo].db.push(record);
  //     groupedData[record.voNo].dbTotal += record.DbAmount;
  //   } else {
  //     groupedData[record.voNo].cr.push(record);
  //     groupedData[record.voNo].crTotal += record.CrAmount;
  //   }
  // });

  const isValidDateAndRange = (voDate: string): boolean => {
    if (!selectedFinancialYear || !financialYearResponse.data) {
      return false;
    }

    const { fin_end_date, fin_st_date } =
      financialYearResponse.data.GetFinYrsByInstId.find(
        ({ id }) => id === selectedFinancialYear.value
      ) || {};

    const date = new Date(voDate);
    const finStartDate = new Date(fin_st_date!);
    const finEndDate = new Date(fin_end_date!);

    return !isNaN(date.getTime()) && date >= finStartDate && date <= finEndDate;
  };
  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("StudentInfo");

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const headerRow = worksheet1.getRow(1);
                  headerRow.height = 25;
                  headerRow.font = ACC_HEADER_FONT;
                  headerRow.alignment = { horizontal: ExcelAlignment.CENTER };
                  const filteredStudentExcelData = studentData.filter(
                    (column) => column.use_in_sheet
                  );
                  let studentInfoHeader = filteredStudentExcelData
                    .filter((student) => student.use_in_sheet)
                    .map((f) => f.Headers);
                  for (let i = 0; i < filteredStudentExcelData.length; i++) {
                    const cell = headerRow.getCell(i + 1);

                    cell.value = studentInfoHeader[i];
                    cell.fill = TABLE_HEADER_CSS;
                    cell.border = BORDER_DATA;

                    cell.alignment = { horizontal: ExcelAlignment.CENTER };
                    worksheet1.columns.forEach((column) => {
                      column.width = 20;
                    });
                  }

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, InstName);
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };
  const downloadExcelContainsError = () => {
    const workbook = new Excel.Workbook();
    const worksheet1 = workbook.addWorksheet("StudentInfo");

    worksheet1.getColumn(1).width = 15;
    worksheet1.getColumn(2).width = 15;
    worksheet1.getColumn(3).width = 15;
    worksheet1.getColumn(4).width = 15;
    worksheet1.getColumn(5).width = 15;
    worksheet1.getColumn(6).width = 15;
    worksheet1.getColumn(7).width = 15;
    worksheet1.getColumn(8).width = 15;
    worksheet1.getColumn(9).width = 15;
    worksheet1.getColumn(10).width = 15;
    worksheet1.getColumn(11).width = 15;
    worksheet1.getColumn(12).width = 15;
    worksheet1.getColumn(13).width = 15;
    worksheet1.getColumn(14).width = 15;
    worksheet1.getColumn(15).width = 15;
    worksheet1.getColumn(16).width = 15;
    worksheet1.getColumn(17).width = 15;
    worksheet1.getColumn(18).width = 15;

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          workbook.xlsx.writeBuffer().then(() => {
            fetch(LogoOrSign.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  let headers2 = studentData
                    .filter((student) => student.use_in_sheet)
                    .map((f) => f.Headers);

                  for (let i = 0; i < headers2.length; i++) {
                    const Char = String.fromCharCode(65 + i);

                    const rowData2: Cell = worksheet1.getCell(Char + 1);
                    rowData2.value = headers2[i];
                    rowData2.fill = TABLE_HEADER_CSS;
                    rowData2.border = BORDER_DATA;
                    rowData2.font = ACC_HEADER_FONT;
                    rowData2.alignment = { horizontal: ExcelAlignment.CENTER };
                  }

                  worksheet1.columns = studentData.filter(
                    (d) => d.use_in_sheet
                  );
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, InstName + "error");
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  const processExcel = (data: any) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[0];

    var records = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    // eslint-disable-next-line
    records.map((record: any) => {
      let errors: any = [];
      student_schema &&
        // eslint-disable-next-line
        Object.keys(student_schema).map((key) => {
          // @ts-ignore
          let keySchema = student_schema[key];
          let keyValue = record[key];

          if (
            keySchema.required &&
            (keyValue === null || keyValue === undefined)
          ) {
            let errorObj = {
              column: key,
              error: "Required",
            };
            errors.push(errorObj);
          }
          if (key === "voDate" && !isValidDateAndRange(keyValue)) {
            let errorObj = {
              column: key,
              error: "Invalid voDate, the format should be DD/MM/YYYY",
            };
            errors.push(errorObj);
          }
        });
      if (errors.length > 0) {
        record.errors = errors;
      }
    });
    return records;
  };
  const handleClick = (id: number) => {
    const filteredData = records.find((record) => record.id === id);
    if (filteredData) {
      ValidateEachRow({
        variables: {
          acct_ldgr_name: filteredData.ldgrName,
          inst_id: InstId!,
          token,
        },
      }).then(({ data, error }) => {
        if (data) {
          const newData = records.map((record, index) => {
            if (id === record.id) {
              return {
                ...record,
                isChecked: !record?.isChecked,
                isValidated: !record.isValidated,
                isValidatedAndError: false,
              };
            }
            return record;
          });
          setRecords(newData);
        }
        if (error) {
          const newData = records.map((record, index) => {
            if (record.id === id) {
              record.errors = record.errors ? record.errors : [];
              record.errors.push(error?.message);
              return {
                ...record,
                isChecked: !record?.isChecked,
                isValidated: true,
                isValidatedAndError: true,
              };
            }
            return record;
          });
          setRecords(newData);
        }
      });
    }
  };

  const displayFileName = (): void => {
    const inputElement = document.getElementById("input") as HTMLInputElement;
    const fileName = inputElement.value;
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = fileName.split("\\").pop()!;
  };
  useEffect(() => {
    const input = document.getElementById("input") as HTMLInputElement;
    if (input) {
      input.addEventListener("change", () => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const res = processExcel(reader.result);
          setRecords(
            res.map((f: any, index: number) => {
              return {
                ...f,
                id: index + 1,
                isChecked: false,
                isValidated: false,
                isValidatedAndError: false,
                crorDb:
                  f.CrAmount > 0 ? DebitOrCredit.CREDIT : DebitOrCredit.DEBIT,
              };
            })
          );
        };
        reader.readAsBinaryString(input.files![0]);
      });
    }
  });

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>Import Student Receipt</Title>

      <div className="import-excel-data">
        <div className="row g-0 import-excel-data__blocks">
          <div className="col-6">
            <div className="import-excel-data__file-upload">
              <div className="import-excel-data__file-upload--file-name">
                <span id="file-name"></span>
              </div>
              <div className="import-excel-data__file-upload--browse">
                <label htmlFor="input">
                  <img src={FileAttach} alt="/" /> Choose Excel File
                </label>
                <input
                  id="input"
                  disabled={!selectedFinancialYear}
                  type="file"
                  name="file"
                  onChange={() => displayFileName()}
                  className="import-excel-data__file-upload--input"
                />
              </div>
            </div>
          </div>
          <div className="col">
            <Autocomplete
              classes={autoCompleteclasses}
              options={financialYearResponse.responseType}
              openOnFocus
              forcePopupIcon
              value={selectedFinancialYear}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, selectedFinancialYear)
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setSelectedFinancialYear(newValue);
                } else {
                  setSelectedFinancialYear(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col import-excel-data__blocks--cards">
            <b>
              <img src={TotalStudents} alt="/" />
              Total Students
            </b>
            <span className="import-excel-data__blocks--cards--total-students">
              {records.length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ReadyToImport} alt="/" />
              Ready to Import
            </b>
            <span className="import-excel-data__blocks--cards--ready-to-import">
              {records.filter((record) => !record.isValidatedAndError).length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ContainsError} alt="/" />
              Contains Error
            </b>
            <span className="import-excel-data__blocks--cards--contains-error">
              {records.filter((record) => record.isValidatedAndError).length}
            </span>
          </div>
        </div>
        <div className="import-excel-data__datablock">
          {records.length > 0 && student_schema ? (
            <>
              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--records-count">
                  <span>
                    {
                      records.filter((record) => !record.isValidatedAndError)
                        .length
                    }
                  </span>
                  <b> Records Found to Import</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {!expanded && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className={classes.table}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {/* <TableCell
                            className={classes.stickyHeaderCheckbox}
                            id="td-center"
                          >
                           <Checkbox /> 
                          </TableCell>   */}
                          <TableCell
                            className={classes.stickyHeaderSl}
                            id="td-center"
                          >
                            Sl
                          </TableCell>

                          {filteredKeys?.map((key, index) => {
                            // @ts-ignore
                            return (
                              <TableCell
                                key={index}
                                className={
                                  key === "slNo"
                                    ? classes.stickyHeaderName
                                    : key === "AdmissionNumber"
                                    ? classes.stickyHeaderAdmNo
                                    : classes.stickyHeader
                                }
                                id="td-center"
                              >
                                {key}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records
                          .filter((record) => !record?.errors)
                          .map((record, index) => (
                            <TableRow key={index}>
                              {/* <TableCell
                                className={classes.stickyColumnCheckbox}
                                id="td-center"
                              >
                                <Checkbox
                                  onClick={() => handleClick(record.id)}
                                  checked={record?.isChecked}
                                />
                              </TableCell> */}
                              <TableCell
                                className={classes.stickyColumnSl}
                                id="td-center"
                              >
                                {index + 1}
                              </TableCell>
                              {/* @ts-ignore */}
                              {/* eslint-disable-next-line */}
                              {filteredKeys.map((key, index) => {
                                // @ts-ignore
                                const fieldSchema = student_schema[key];

                                if (
                                  fieldSchema.required ||
                                  fieldSchema.required === undefined
                                )
                                  return (
                                    <TableCell
                                      key={index}
                                      className={
                                        fieldSchema?.name === "voNo"
                                          ? classes.stickyColumnAdmno
                                          : fieldSchema?.name === "slNo"
                                          ? classes.stickyColumnName
                                          : classes.stickyColumn
                                      }
                                    >
                                      {/* @ts-ignore */}
                                      {record[key]}
                                    </TableCell>
                                  );
                              })}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--errors-count">
                  <span>{records.filter((d) => d?.errors).length}</span>
                  <b>Records Which Contains error(s)</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              {expanded && (
                <div className="import-excel-data__datablock--tableblock">
                  <TableContainer className={classes.table}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {/* <TableCell
                            className={classes.stickyHeaderCheckbox}
                            id="td-center"
                          ></TableCell> */}
                          <TableCell
                            className={classes.stickyHeaderSl}
                            id="td-center"
                          >
                            Sl
                          </TableCell>
                          {/* eslint-disable-next-line */}
                          {filteredKeys.map((key, index) => {
                            // @ts-ignore
                            const fieldSchema = student_schema[key];
                            if (
                              fieldSchema.required ||
                              fieldSchema.required === undefined
                            )
                              return (
                                <TableCell
                                  key={index}
                                  className={
                                    key === "slNo"
                                      ? classes.stickyHeaderName
                                      : key === "AdmissionNumber"
                                      ? classes.stickyHeaderAdmNo
                                      : classes.stickyHeader
                                  }
                                  id="td-center"
                                >
                                  {key}
                                </TableCell>
                              );
                          })}
                          <TableCell
                            className={classes.stickyHeaderStatus}
                            id="td-center"
                          >
                            Error Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records
                          .filter((record) => record?.errors)
                          .map((record, index) => {
                            const filteredHeaders: string[] = [];
                            return (
                              <TableRow key={index}>
                                {/* <TableCell
                                  className={classes.stickyColumnCheckbox}
                                  id="td-center"
                                >
                                  {!record?.errors ? (
                                    <Checkbox
                                      onClick={() => handleClick(index)}
                                      checked={record?.isChecked}
                                    />
                                  ) : null}
                                </TableCell> */}

                                <TableCell
                                  className={classes.stickyColumnSl}
                                  id="td-center"
                                >
                                  {index + 1}
                                </TableCell>
                                {Object.keys(student_schema).map(
                                  (key, index) => {
                                    // @ts-ignore
                                    const fieldSchema = student_schema[key];
                                    if (
                                      fieldSchema.required ||
                                      fieldSchema.required === undefined
                                    ) {
                                      filteredHeaders.push(key);
                                      return (
                                        <TableCell
                                          key={index}
                                          className={
                                            fieldSchema?.name === "voNo"
                                              ? classes.stickyColumnAdmno
                                              : fieldSchema?.name === "slNo"
                                              ? classes.stickyColumnName
                                              : classes.stickyColumn
                                          }
                                        >
                                          {/* @ts-ignore */}
                                          {record[key]}
                                        </TableCell>
                                      );
                                    }
                                    return null;
                                  }
                                )}

                                <TableCell
                                  className={classes.stickyColumnStatus}
                                >
                                  <Tooltip
                                    title={
                                      <ul>
                                        {record?.errors
                                          ? record?.errors?.map(
                                              (error: any, index: number) => {
                                                return (
                                                  <li key={index}>
                                                    {index + 1})&nbsp;
                                                    {typeof error === "string"
                                                      ? error
                                                      : "Error message = " +
                                                        error.error +
                                                        ", column = " +
                                                        error.column}
                                                  </li>
                                                );
                                              }
                                            )
                                          : null}
                                      </ul>
                                    }
                                    arrow
                                    placement="bottom-start"
                                    classes={{
                                      tooltip:
                                        tooltipClasses.customTooltipError,
                                      arrow: tooltipClasses.customArrowError,
                                    }}
                                  >
                                    <div className="import-excel-data__datablock--tableblock--error-message">
                                      <span>Errors</span>
                                      <img src={Info} alt="/" />
                                    </div>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </>
          ) : null}
        </div>
        <div className="row ">
          <div className="col">
            <Button
              mode="save"
              disabled={
                !records.filter((record) => record?.isChecked).length ||
                !records.length
                  ? true
                  : false
              }
            />
            <Button onClick={handleClear} mode="clear" />

            <Button onClick={downloadExcelContainsError} mode="excel">
              Export error Data
            </Button>
            <Button mode="back" onClick={() => navigate(-1)} />
          </div>
          <div className="col import-excel-data__button">
            <Button mode="excel" onClick={downloadExcel}>
              Generate Template XLS File
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportStudentReceipt;
