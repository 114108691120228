import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Home from "../Home/Index";
import Warning from "../../../images/Warning.svg";
import Close from "../../../images/Close.svg";
import { Button } from "../../../stories/Button/Button";
import { TableHeaderProps } from "../../../Types/Tables";
import Modal from "react-modal";
import { WaiveOffModalStyles } from "../../../styles/ModalStyles";
import { useNavigate, useParams } from "react-router-dom";
import { msgType } from "../../../utils/Form.types";
import {
  Direction,
  InstitutionConfigurationTypes,
  Operation,
  SortBy,
} from "../../../utils/Enum.types";
import {
  toInputStandardDate,
  toIsoDate,
  toStandardCase,
} from "../../../utils/UtilFunctions";
import useSwConfigData from "../../../customhooks/useSwConfigData";
import { useLazyQuery, useMutation } from "@apollo/client";

import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import useToken from "../../../customhooks/useToken";
import {
  AddAcdTestClassAndSubjects,
  UpdateAcdTestClassesAndSubjects,
} from "../queries/test/mutation";
import LoadingModal from "../../../pages/LoadingModal";
import MessageModal from "../../../pages/MessageModal";
import useSubjectAllotedForClass from "../hooks/useSubjectAllotedForClass";
import {
  GetAcdTestClass,
  GetAcdTestMarksStatus,
  GetAcdTestName,
  GetAcdTestNameById,
  GetAcdTestTypeDetailsById,
  GetElectiveSubjDetailsForTest,
} from "../queries/test/query";
import { AcdTestMarksStatusQueryType } from "../hooks/useTestStatus";
import {
  AcdTestConductQueryType,
  TestConductData,
  TestConductVars,
} from "../hooks/useTestClassData";
import { EMPTY_STRING, TODAY_DATE } from "../../../utils/constants";
import { CustomisedInputFor } from "../../../utils/CalendarForInput";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import { Title } from "../../../stories/Title/Title";
import { Label } from "../../../stories/Label/Label";
import {
  GetAcdTestNameByIdData,
  GetAcdTestTypeByIdData,
} from "../Test/TestCreation/Index";
import { singleNodeVars } from "../../../Types/Accounting";
import { GetAcdInstDetailsByNodeIdVars } from "../Dashboard/Teacher/Index";
import { AcdSubjectMaster } from "../types/test";
import AllotedLevelConfigs from "../Components/AllotedLevelConfigs";
import { AppContext } from "../../../context/context";
import InputNumber from "../../../components/common/Input/InputNumber";
import useCheckAllocationType from "../hooks/useCheckAllocationType";
import CalendarForExam from "../../../utils/CalendarForExam";
import useHolidayListForExam from "../../../customhooks/useHolidayListForExam";
const { Academics_Table } = require("../json/table.json");
export interface GetElectiveSubjData {
  nodes: AcdSubjectMaster[];
}
export interface Isubjects {
  id: number;
  subj_total_max_marks: number;
  subj_total_min_marks: number;
  subj_code: string;
  subj_desc: string;
  subj_is_lab: boolean;
  subj_is_elective: boolean;
  subj_date: string;
  isChecked: boolean;
  subj_sl: number;
  subj_consider_int: boolean;
  main_elective_subj_desc: string;
  elective_sl_no: number;
  elective_main_subj_master_id: number;
  subj_is_core_subject?: boolean;
  subj_is_lang?: boolean;
}
interface Props {
  operation: Operation;
  studentWiseSubAllocation: boolean;
}
const CreateTest = ({ operation, studentWiseSubAllocation }: Props) => {
  const navigate = useNavigate();
  const { InstId, allotedID, testId } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );
  const { user_details } = useLoggedInUserDetails();
  const [warningModal, setWarningModal] = useState(false);
  const [testClassId, setTestClassId] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [items, setItems] = useState<Isubjects[]>([]);

  const [itemDates, setItemDates] = useState<string[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [theory_max_marks] = useState(0);
  const [theory_min_marks] = useState(0);
  const saveRef = useRef<HTMLButtonElement>(null);
  const [GetTestTypesByTestId, { data: testConductDetails, loading }] =
    useLazyQuery<TestConductData, TestConductVars>(GetAcdTestClass, {
      fetchPolicy: "no-cache",
    });
  const { flag } = useCheckAllocationType();
  const [GetTestTypeMarks, { data: TestTypeDetails }] = useLazyQuery<
    GetAcdTestTypeByIdData,
    singleNodeVars
  >(GetAcdTestTypeDetailsById);

  const { activeAcademicYearData } = useActiveAcademicYear();
  const dates = useHolidayListForExam(
    selectedDate,
    activeAcademicYearData?.data?.GetAcdYrActiveByInstId.id!
  );

  const { subjectsForClass } = useSubjectAllotedForClass(Number(allotedID));
  const electiveMasterIds = useMemo(
    () =>
      testConductDetails?.GetAcdTestClass.edges
        .flatMap((res) =>
          res.node.class_subject_details.map(
            (data) => data.elective_main_subj_master_id
          )
        )
        ?.filter((id) => id !== 0) ?? [],
    [testConductDetails]
  );

  const [GetElectiveSubjDetails, { data: ElectiveSubjDetails }] = useLazyQuery<
    GetElectiveSubjData,
    GetAcdInstDetailsByNodeIdVars
  >(GetElectiveSubjDetailsForTest);

  const [AssociateTestandClass, { loading: testConductLoading }] = useMutation(
    AddAcdTestClassAndSubjects,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [UpdateTestandClass] = useMutation(UpdateAcdTestClassesAndSubjects, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const handleSelectionOfUnmappedClass = (
    e: React.ChangeEvent<HTMLInputElement>,
    description: string // Add description parameter
  ) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempClass = items.map((user) => ({
        ...user,
        isChecked: checked,
      }));
      setItems(tempClass);
    } else if (name === "mainElectiveSelect") {
      let tempClass = items.map((user) => ({
        ...user,
        isChecked:
          user.main_elective_subj_desc === description
            ? checked
            : user.isChecked,
      }));
      setItems(tempClass);
    } else {
      let tempClass = items.map((user) =>
        user.id.toString() === name
          ? {
              ...user,
              subj_total_max_marks: user.subj_is_lab ? 0 : theory_max_marks,
              subj_total_min_marks: user.subj_is_lab ? 0 : theory_min_marks,
              isChecked: checked,
            }
          : user
      );
      setItems(tempClass);
    }
    return null;
  };

  const tableItems = Array.from(
    new Set(items.map((item) => item.main_elective_subj_desc))
  );

  const setDate = (date: Date, index: number) => {
    setItemDates((prevDates) => {
      const newDates = [...prevDates];
      newDates[index] = date.toString();
      return newDates;
    });
    setSelectedDate(date);
  };

  const totalMarks = items.reduce(
    (acc, item) => {
      acc.subj_total_max_marks += item.subj_total_max_marks;
      acc.subj_total_min_marks += item.subj_total_min_marks;
      acc.subj_total_max_marks += item.subj_total_max_marks;
      acc.subj_total_min_marks += item.subj_total_min_marks;
      return acc;
    },
    {
      subj_total_max_marks: 0,
      subj_total_min_marks: 0,
    }
  );

  const handleAllocationOfTestandSubjects = () => {
    if (operation === Operation.UPDATE) {
      UpdateTestandClass({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
          test_class_id: testClassId,
          input: {
            per_std_subj_allocation: studentWiseSubAllocation,
            test_name_id: Number(testId),
            test_class_marks: {
              subj_total_max_marks: totalMarks.subj_total_max_marks,
              subj_total_min_marks: totalMarks.subj_total_min_marks,
            },
            allotted_level:
              configData.data?.GetSwConfigVariables[0].config_string_value!,
            allotted_id: Number(allotedID),
            assoicated_subj_data: items
              .filter((item) => item.isChecked)
              .map((item, index) => ({
                test_date: toIsoDate(itemDates[index]),
                subj_total_max_marks: item.subj_total_max_marks,
                subj_total_min_marks: item.subj_total_min_marks,
                subj_master_id: item.id,
                subj_sl: item.subj_sl,
                elective_subj_sl: item.elective_sl_no ? item.elective_sl_no : 0,
                elective_main_subj_master_id: item.elective_main_subj_master_id
                  ? item.elective_main_subj_master_id
                  : 0,
                subj_is_elective: item.subj_is_elective ? true : false,
                enable_internal_test: item.subj_consider_int,
              })),
            enable_teachers_comments: false,
          },
        },
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Updated Test Details",
            operation: Operation.UPDATE,
          });
        }
      });
    } else {
      AssociateTestandClass({
        variables: {
          token,
          inst_id: InstId,
          user_details,
          acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id,
          allotted_level:
            configData.data?.GetSwConfigVariables[0].config_string_value!,
          allotted_id: allotedID,
          test_name_id: testId,
          per_std_subj_allocation: studentWiseSubAllocation,
          test_class_marks: {
            subj_total_max_marks: theory_max_marks,
            subj_total_min_marks: theory_min_marks,
          },
          enable_teachers_comments: false,
          assoicated_subj_data: items
            .filter((item) => item.isChecked)
            .map((item, index) => {
              return {
                test_date: itemDates[index]
                  ? toIsoDate(itemDates[index])
                  : toIsoDate(TODAY_DATE),
                subj_sl: item.subj_sl,
                elective_subj_sl: item.elective_sl_no ? item.elective_sl_no : 0,
                subj_total_max_marks: item.subj_total_max_marks,
                subj_total_min_marks: item.subj_total_min_marks,
                subj_is_elective: item.subj_is_elective ? true : false,
                enable_internal_test: item.subj_consider_int,
                elective_main_subj_master_id: item.elective_main_subj_master_id
                  ? item.elective_main_subj_master_id
                  : 0,
                subj_master_id: item.id,
              };
            }),
        },
        refetchQueries: [
          {
            query: GetAcdTestMarksStatus,
            variables: {
              acd_yr_id:
                activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
              input: {
                acd_test_class_id: 0,
                entry_id: 0,
                entry_level: "",
                inst_id: InstId!,
                test_type_id: 0,
              },
              per_std_subj_allocation: flag,
              token,
              query_type: AcdTestMarksStatusQueryType.ALL_TESTS,
            },
          },
          {
            query: GetAcdTestName,
            variables: {
              inst_id: InstId!,
              for_planning: false,
              acd_yr_id:
                activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
              alloted_level: EMPTY_STRING,
              allotted_id: 0,
              token,
            },
          },
          {
            query: GetAcdTestName,
            variables: {
              inst_id: InstId!,
              for_planning: true,
              acd_yr_id:
                activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
              alloted_level:
                configData.data?.GetSwConfigVariables[0].config_string_value!,
              allotted_id: Number(allotedID!),
              token,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Test Details added successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      navigate(`/${InstId}/academics/${testId}/examplanner/view`);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (
      testId &&
      allotedID &&
      InstId &&
      state.ActiveAcdYr &&
      token &&
      !configData.loading &&
      configData.data &&
      studentWiseSubAllocation === false &&
      operation === Operation.UPDATE
    ) {
      GetTestTypesByTestId({
        variables: {
          acd_yr_id: state.ActiveAcdYr ? state.ActiveAcdYr.id : 0,
          input: {
            alloted_level:
              configData.data?.GetSwConfigVariables[0].config_string_value!,
            ids: [Number(InstId), Number(testId), Number(allotedID)],
            acd_test_class_query: AcdTestConductQueryType.BY_ACD_TEST_NAME,
          },
          token,
          inst_id: InstId!,
          after: null,
          first: null,
          orderBy: {
            direction: Direction.ASC,
            field: SortBy.TEST_START_DATE,
          },
        },
      }).then(({ data, loading }) => {
        if (data && !loading && operation === Operation.UPDATE) {
          const firstEdge = data.GetAcdTestClass.edges[0];
          const subjects = firstEdge.node.class_subject_details.map(
            (subject) => ({
              id: subject.subject_master_details.id!,
              isChecked: true,
              subj_code: subject.subject_master_details.subj_code,
              subj_desc: subject.subject_master_details.subj_desc,
              subj_is_lab: subject.subject_master_details.subj_is_lab,
              subj_total_max_marks: subject.subj_total_max_marks,
              subj_date: toInputStandardDate(subject.test_date),
              subj_total_min_marks: subject.subj_total_min_marks,
              subj_sl: subject.subj_sl,
              subj_is_elective: subject.subj_is_elective,
              subj_is_core_subject:
                subject.subject_master_details.subj_is_core_subject,
              subj_is_lang: subject.subject_master_details.subj_is_lang,
              subj_consider_int: false,
              main_elective_subj_desc: "",
              elective_sl_no: subject.elective_subj_sl,
              elective_main_subj_master_id:
                subject.elective_main_subj_master_id,
            })
          );
          setTestClassId(firstEdge.node.id);
          setItems(subjects);
          setItemDates(
            firstEdge.node.class_subject_details.map((res) => res.test_date)
          );
        }
      });
    }
  }, [
    GetTestTypesByTestId,
    testId,
    token,
    operation,
    allotedID,
    InstId,
    state.ActiveAcdYr,
    configData.data,
    configData.loading,
    studentWiseSubAllocation,
  ]);

  useEffect(() => {
    if (token && electiveMasterIds.length > 0) {
      GetElectiveSubjDetails({
        variables: {
          token,
          ids: electiveMasterIds,
        },
      });
    }
  }, [token, GetElectiveSubjDetails, electiveMasterIds]);
  useEffect(() => {
    if (
      subjectsForClass.data &&
      !subjectsForClass.loading &&
      operation === Operation.CREATE
    ) {
      const subjects =
        subjectsForClass.data.GetAcdSubjectAllotedForClass.filter(
          (data) => !data.subject_details.subj_is_non_academic
        ).flatMap(
          // @ts-ignore
          (subject) => {
            if (subject.subj_is_elective) {
              // For elective subjects
              return subject.elective_details.map((res) => ({
                id: res.subject_details.id,
                elective_main_subj_desc: subject.subject_details.subj_desc,
                isChecked: true,
                subj_code: res.subject_details.subj_code,
                subj_desc: res.subject_details.subj_desc,
                subj_is_lab: res.subject_details.subj_is_lab,

                main_elective_subj_desc: subject.subject_details.subj_desc,

                subj_total_max_marks: TestTypeDetails?.node
                  ? TestTypeDetails.node.ext_max_marks
                  : 0,
                subj_total_min_marks: TestTypeDetails?.node
                  ? TestTypeDetails.node.ext_min_marks
                  : 0,
                subj_date: "",
                subj_sl: subject.subj_sl,
                elective_sl_no: res.elective_sl,
                elective_main_subj_master_id: subject.subj_master_id,
                subj_is_elective: true,
                subj_consider_int: TestTypeDetails?.node.int_max_marks
                  ? true
                  : false,
              }));
            } else {
              return [
                {
                  id: subject.subject_details.id!,
                  elective_main_subj_desc: subject.subject_details.subj_desc,
                  isChecked: true,
                  subj_code: subject.subject_details.subj_code,
                  subj_desc: subject.subject_details.subj_desc,
                  subj_is_lab: subject.subject_details.subj_is_lab,
                  subj_total_max_marks: TestTypeDetails?.node
                    ? TestTypeDetails.node.ext_max_marks
                    : 0,
                  subj_total_min_marks: TestTypeDetails?.node
                    ? TestTypeDetails.node.ext_min_marks
                    : 0,
                  subj_date: "",
                  subj_sl: subject.subj_sl,
                  subj_is_elective: false,
                  subj_consider_int: TestTypeDetails?.node.int_max_marks
                    ? true
                    : false,
                },
              ];
            }
          }
        ); // @ts-ignore
      setItems(subjects);
    }
  }, [
    subjectsForClass.data,
    subjectsForClass.loading,
    operation,
    TestTypeDetails,
  ]);

  const disableSaveButton = (item: Isubjects) => {
    if (item.subj_is_elective) {
      return false;
    } else
      return (
        item.isChecked &&
        (item.subj_total_max_marks === 0 ||
          item.subj_total_min_marks === 0 ||
          item.subj_total_max_marks < item.subj_total_min_marks)
      );
  };

  const [GetTestTypeDetails, { data: TestName }] = useLazyQuery<
    GetAcdTestNameByIdData,
    singleNodeVars
  >(GetAcdTestNameById);

  const testTypeId = TestName?.node.test_type_id;

  useEffect(() => {
    if (testId && token) {
      GetTestTypeDetails({
        variables: { id: testId ? Number(testId) : 0, token },
      });
    }
  }, [testId, GetTestTypeDetails, token]);
  useEffect(() => {
    if (testTypeId && token && operation === Operation.CREATE) {
      GetTestTypeMarks({
        variables: { id: testTypeId ? testTypeId : 0, token },
      });
    }
  }, [testTypeId, GetTestTypeMarks, token, operation]);

  return (
    <>
      <Home DashBoardRequired={false} />
      <Title>{toStandardCase(operation)} Test Plan</Title>
      <Title variant="subtitle1">{TestName?.node.test_name}</Title>

      <div className="test-planner__details--create-test">
        <AllotedLevelConfigs />

        <div className="row g-0 test-planner__details--create-test--tableblock">
          <TableContainer className="test-planner__details--create-test--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow className="test-planner__details--create-test--table--groupheader">
                  {Academics_Table.ExamPlanner.TestPlan.map(
                    (th: TableHeaderProps, index: React.Key) => (
                      <TableCell
                        key={index}
                        colSpan={th.colSpan}
                        rowSpan={th.rowSpan}
                      >
                        {th.labelName}
                      </TableCell>
                    )
                  )}
                </TableRow>
                <TableRow className="test-planner__details--create-test--table--subheader">
                  <TableCell rowSpan={2}>Max</TableCell>
                  <TableCell rowSpan={2}>Min</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {tableItems.map((description, index) => {
                  const filteredItems = items.filter(
                    (item) => item.main_elective_subj_desc === description
                  );

                  const allChecked = filteredItems.every(
                    (item) => item.isChecked
                  );

                  return (
                    <React.Fragment key={index}>
                      <TableRow className="test-planner__details--create-test--table--open-elective">
                        <TableCell
                          colSpan={
                            TestTypeDetails &&
                            TestTypeDetails.node.int_max_marks > 0
                              ? 11
                              : 9
                          }
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {description ? (
                              <Checkbox
                                checked={allChecked}
                                name="mainElectiveSelect"
                                onChange={(e) =>
                                  handleSelectionOfUnmappedClass(e, description)
                                }
                              />
                            ) : null}
                            &nbsp;
                            {description ? `${index + 1}.` : EMPTY_STRING}
                            &nbsp;
                            {description
                              ? `${description} (Elective)`
                              : EMPTY_STRING}
                          </div>
                        </TableCell>
                      </TableRow>
                      {filteredItems.map((res, resIndex) => {
                        const element: Isubjects[] = items.filter(
                          (data) => data.id === res.id
                        );

                        const elementIndex = items.indexOf(element[0]);

                        return (
                          <TableRow key={res.id}>
                            <TableCell
                              className="test-planner__details--create-test--table--slno"
                              id="td-center"
                            >
                              {res.main_elective_subj_desc
                                ? EMPTY_STRING
                                : `${resIndex + 1}.`}
                              &nbsp;
                              <Checkbox
                                checked={res.isChecked}
                                name={res.id.toString()}
                                onChange={(e) =>
                                  handleSelectionOfUnmappedClass(e, "")
                                }
                                disabled={
                                  res.main_elective_subj_desc ? true : false
                                }
                              />
                            </TableCell>
                            <TableCell className="test-planner__details--create-test--table--code">
                              {res.subj_code}
                            </TableCell>
                            <TableCell>
                              {operation === Operation.UPDATE &&
                              res.subj_is_elective
                                ? `${res.subj_desc}  (${
                                    ElectiveSubjDetails &&
                                    ElectiveSubjDetails.nodes.find(
                                      (data) =>
                                        data.id ===
                                        res.elective_main_subj_master_id
                                    )?.subj_desc
                                  })`
                                : res.subj_desc}
                            </TableCell>
                            <TableCell>
                              {res.subj_is_elective
                                ? "Elective"
                                : res.subj_is_lab
                                ? "Lab"
                                : res.subj_is_lang
                                ? "Language"
                                : res.subj_is_core_subject
                                ? "Core"
                                : EMPTY_STRING}
                            </TableCell>
                            <TableCell className="test-planner__details--create-test--table--date">
                              <CalendarForExam
                                date={
                                  new Date(
                                    itemDates[elementIndex]
                                      ? itemDates[elementIndex]
                                      : TODAY_DATE
                                  )
                                }
                                dates={dates}
                                setDate={(date) => setDate(date, elementIndex)}
                                allowFutureDatesAccess={true}
                                i={elementIndex}
                                inputFor={CustomisedInputFor.GENERAL}
                                label=""
                              />
                            </TableCell>
                            <TableCell
                              className="test-planner__details--create-test--table--input"
                              id="td-center"
                            >
                              <InputNumber
                                id={`max_${resIndex}`}
                                type="number"
                                className={
                                  res.subj_total_max_marks <
                                  res.subj_total_min_marks
                                    ? "max_error"
                                    : ""
                                }
                                disabled={!res.isChecked}
                                value={res.subj_total_max_marks}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setItems((prevState) =>
                                    prevState.map((prevItem, i) =>
                                      prevItem.id === res.id
                                        ? {
                                            ...prevItem,
                                            subj_total_max_marks: Number(
                                              e.target.value
                                            ),
                                          }
                                        : prevItem
                                    )
                                  );
                                }}
                              />
                            </TableCell>

                            <TableCell
                              className="test-planner__details--create-test--table--input"
                              id="td-center"
                            >
                              <InputNumber
                                id={`min_${resIndex}`}
                                type="number"
                                disabled={!res.isChecked}
                                value={res.subj_total_min_marks}
                                className={
                                  res.subj_total_max_marks <
                                  res.subj_total_min_marks
                                    ? "max_error"
                                    : ""
                                }
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  setItems((prevState) =>
                                    prevState.map((prevItem) =>
                                      prevItem.id === res.id
                                        ? {
                                            ...prevItem,
                                            subj_total_min_marks: Number(
                                              e.target.value
                                            ),
                                          }
                                        : prevItem
                                    )
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <Button
          mode="save"
          onClick={handleAllocationOfTestandSubjects}
          buttonref={saveRef!}
          disabled={items.filter((item) => disableSaveButton(item)).length > 0}
        />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={warningModal}
        style={WaiveOffModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="test-planner__details--create-test--warning">
              <Title variant="subtitle1">
                <img src={Warning} alt="/" />
                Are You Sure ?
              </Title>
              <div>
                <Label>
                  Subjects in Marks Report Will be Dispaly in Same order. Would
                  you like Re-order Subject
                </Label>
              </div>
              <Button
                mode="reorder"
                onClick={() => setWarningModal(!warningModal)}
              />
              <Button mode="save" onClick={handleAllocationOfTestandSubjects} />
            </div>
          </div>
          <div className="modal-flex__image ">
            <img
              src={Close}
              alt="/"
              onClick={() => setWarningModal(!warningModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={testConductLoading || loading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default CreateTest;
