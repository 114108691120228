import { InputAdornment, TextField, Tooltip } from "@mui/material";
import Calendar from "../images/Calendar.svg";
import Next from "../images/CalenderNextButton.svg";
import Previous from "../images/CalenderPreviousButton.svg";
import { useState } from "react";
import { useStyles } from "../styles/TooltipStyles";
import { Title } from "../stories/Title/Title";
import {
  MonthName,
  toInputStandardDate,
  toStandardDate,
} from "./UtilFunctions";

import { msgType } from "./Form.types";
import useServerDateandTime from "../Modules/Library/customHooks/useServerDateandTime";
import { INCREMENT_DECREMENT } from "../Modules/Enquiry/Dashboard/Index";
import useActiveAcademicYear from "../Modules/Academics/hooks/useActiveAcademicYear";
import { Operation } from "./Enum.types";
import dayjs from "dayjs";
import { monthDaysType } from "../Modules/Academics/DailyActivities/Attendance/MonthlyOverview";
import MessageModal from "../pages/MessageModal";
import { EMPTY_STRING, TODAY_DATE, days } from "./constants";

export enum CustomisedInputFor {
  GENERAL = "GENERAL",
  TABLE = "TABLE",
}

interface Props {
  date: Date;
  setDate: (date: Date, i?: number) => void;
  dates: monthDaysType[];
  allowFutureDatesAccess: boolean;
  i?: number;
  inputFor: CustomisedInputFor;
  label: string;
}
const CalendarForExam = ({
  date,
  setDate,
  allowFutureDatesAccess,
  dates,
  i,
  inputFor,
  label,
}: Props) => {
  const classes = useStyles();
  const { serverDate } = useServerDateandTime();

  const { activeAcademicYearData } = useActiveAcademicYear();
  const [selectMonth, setSelectMonth] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  function isValidDateFormat(dateString: string) {
    // Regular expression pattern for "YYYY-MM-DD" format
    var pattern = /^\d{2}-\d{2}-\d{4}$/;

    return pattern.test(dateString);
  }

  function validateDateInput(value: string) {
    if (!isValidDateFormat(value)) {
      alert("Please enter a valid date in the format YYYY-MM-DD.");
      value = ""; // Clear the invalid input
    } else {
      setDate(new Date(value), i);
    }
  }
  const handleDate = (date: Date, type: INCREMENT_DECREMENT) => {
    const academicYearEndDate = new Date(
      activeAcademicYearData.data?.GetAcdYrActiveByInstId.acd_end_date!
    );
    const academicYearStartDate = new Date(
      activeAcademicYearData.data?.GetAcdYrActiveByInstId.acd_st_date!
    );
    if (type === INCREMENT_DECREMENT.INCREMENT) {
      const nextDate = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
      );

      if (nextDate.getTime() < academicYearEndDate.getTime()) {
        setDate(nextDate, i);
      } else {
        setDate(nextDate, i);
      }
    } else {
      const prevDate = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        date.getDate()
      );

      if (academicYearStartDate.getTime() < prevDate.getTime()) {
        setDate(prevDate, i);
      } else {
        setDate(prevDate, i);
      }
    }
  };

  return (
    <>
      {inputFor === CustomisedInputFor.GENERAL ? (
        <TextField
          InputLabelProps={{ shrink: true }}
          value={
            // toInputStandardDate(date.toString()) ===
            // toInputStandardDate(DEFAULT_TIME)
            //   ? "dd-mm-yyyy"
            //   : toStandardDate(date.toString())
            toStandardDate(date.toString())
          }
          onChange={(e) => {
            validateDateInput(e.target.value);
          }}
          InputProps={{
            inputProps: {
              min: activeAcademicYearData.data
                ? toInputStandardDate(
                    activeAcademicYearData.data.GetAcdYrActiveByInstId
                      .acd_st_date
                  )
                : EMPTY_STRING,
              max: activeAcademicYearData.data
                ? toInputStandardDate(
                    activeAcademicYearData.data.GetAcdYrActiveByInstId
                      .acd_end_date
                  )
                : EMPTY_STRING,
            },
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltipCalendar,
                  }}
                  title={
                    <>
                      <div className="calendar-for-input">
                        {selectMonth ? (
                          <div className="months-list">
                            {activeAcademicYearData.dateOptions
                              .filter(
                                ({ value }) =>
                                  dayjs(value).isBefore(serverDate) ||
                                  allowFutureDatesAccess
                              )
                              .map((month, index) => {
                                return (
                                  <span
                                    className="month_name"
                                    onClick={() => {
                                      setDate(new Date(month.value), i);
                                      setSelectMonth(!selectMonth);
                                    }}
                                    key={index}
                                  >
                                    {month.label}
                                  </span>
                                );
                              })}
                          </div>
                        ) : (
                          <>
                            <div className="calendar-for-input--title">
                              <img
                                src={Previous}
                                alt=""
                                onClick={() => {
                                  handleDate(
                                    date,
                                    INCREMENT_DECREMENT.DECREMENT
                                  );
                                }}
                              />
                              <Title variant="subtitle2">
                                <span
                                  onClick={() => setSelectMonth(!selectMonth)}
                                >
                                  {toInputStandardDate(date.toString()) ===
                                  toInputStandardDate(TODAY_DATE)
                                    ? MonthName(serverDate.toString())
                                    : MonthName(date.toString())}
                                </span>
                              </Title>

                              <img
                                src={Next}
                                alt=""
                                onClick={() => {
                                  if (
                                    dayjs(date).isBefore(
                                      dayjs(serverDate),
                                      "month"
                                    ) ||
                                    dayjs(date).isBefore(
                                      dayjs(serverDate),
                                      "year"
                                    ) ||
                                    allowFutureDatesAccess
                                  ) {
                                    handleDate(
                                      date,
                                      INCREMENT_DECREMENT.INCREMENT
                                    );
                                  } else {
                                    setMessage({
                                      flag: true,
                                      operation: Operation.NONE,
                                      message: "Cannot Access Future Dates",
                                    });
                                  }
                                }}
                              />
                            </div>
                            <ul className="calendar-for-input--grid-container">
                              {days.map((day, index) => {
                                return (
                                  <li
                                    className="calendar-for-input--day"
                                    key={index}
                                  >
                                    {day}
                                  </li>
                                );
                              })}
                              {dates.map((month_date, index) => {
                                if (month_date.day > 0) {
                                  return (
                                    <li
                                      className="calendar-for-input--date"
                                      key={index}
                                      onClick={() => {
                                        if (
                                          dayjs(month_date.date).isBefore(
                                            dayjs(serverDate)
                                          ) ||
                                          allowFutureDatesAccess
                                        ) {
                                          setDate(month_date.date, i);
                                        } else {
                                          setMessage({
                                            flag: true,
                                            message:
                                              "Cannot Access future dates",
                                            operation: Operation.NONE,
                                          });
                                        }
                                      }}
                                    >
                                      {month_date.day}
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li
                                      className="calendar-for-input--date"
                                      key={index}
                                    ></li>
                                  );
                                }
                              })}
                            </ul>
                          </>
                        )}
                      </div>
                    </>
                  }
                  placement="bottom"
                >
                  <img src={Calendar} alt="/" />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          label={label}
          className="calendar-for-input--textfield"
        />
      ) : (
        <TextField
          value={
            toInputStandardDate(date.toString()) ===
            toInputStandardDate(TODAY_DATE)
              ? "dd-mm-yyyy"
              : toStandardDate(date.toString())
          }
          onChange={(e) => {
            validateDateInput(e.target.value);
          }}
          InputProps={{
            inputProps: {
              min: activeAcademicYearData.data
                ? toInputStandardDate(
                    activeAcademicYearData.data.GetAcdYrActiveByInstId
                      .acd_st_date
                  )
                : EMPTY_STRING,
              max: activeAcademicYearData.data
                ? toInputStandardDate(
                    activeAcademicYearData.data.GetAcdYrActiveByInstId
                      .acd_end_date
                  )
                : EMPTY_STRING,
            },
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltipCalendar,
                  }}
                  title={
                    <>
                      <div className="calendar-for-input">
                        {selectMonth ? (
                          <div className="months-list">
                            {activeAcademicYearData.dateOptions
                              .filter(
                                ({ value }) =>
                                  dayjs(value).isBefore(serverDate) ||
                                  allowFutureDatesAccess
                              )
                              .map((month, index) => {
                                return (
                                  <span
                                    className="month_name"
                                    onClick={() => {
                                      setDate(new Date(month.value), i);
                                      setSelectMonth(!selectMonth);
                                    }}
                                    key={index}
                                  >
                                    {month.label}
                                  </span>
                                );
                              })}
                          </div>
                        ) : (
                          <>
                            <div className="calendar-for-input--title">
                              <img
                                src={Previous}
                                alt=""
                                onClick={() => {
                                  handleDate(
                                    date,
                                    INCREMENT_DECREMENT.DECREMENT
                                  );
                                }}
                              />
                              <Title variant="subtitle2">
                                <span
                                  onClick={() => setSelectMonth(!selectMonth)}
                                >
                                  {toInputStandardDate(date.toString()) ===
                                  toInputStandardDate(TODAY_DATE)
                                    ? MonthName(serverDate.toString())
                                    : MonthName(date.toString())}
                                </span>
                              </Title>

                              <img
                                src={Next}
                                alt=""
                                onClick={() => {
                                  if (
                                    dayjs(date).isBefore(
                                      dayjs(serverDate),
                                      "month"
                                    ) ||
                                    dayjs(date).isBefore(
                                      dayjs(serverDate),
                                      "year"
                                    ) ||
                                    allowFutureDatesAccess
                                  ) {
                                    handleDate(
                                      date,
                                      INCREMENT_DECREMENT.INCREMENT
                                    );
                                  } else {
                                    setMessage({
                                      flag: true,
                                      operation: Operation.NONE,
                                      message: "Cannot Access Future Dates",
                                    });
                                  }
                                }}
                              />
                            </div>
                            <ul className="calendar-for-input--grid-container">
                              {days.map((day, index) => {
                                return (
                                  <li
                                    className="calendar-for-input--day"
                                    key={index}
                                  >
                                    {day}
                                  </li>
                                );
                              })}
                              {dates.map((month_date, index) => {
                                if (month_date.day > 0) {
                                  return (
                                    <li
                                      className="calendar-for-input--date"
                                      key={index}
                                      onClick={() => {
                                        if (
                                          dayjs(month_date.date).isBefore(
                                            dayjs(serverDate)
                                          ) ||
                                          allowFutureDatesAccess
                                        ) {
                                          if (!month_date.status)
                                            setDate(month_date.date, i);
                                        } else {
                                          setMessage({
                                            flag: true,
                                            message:
                                              "Cannot Access future dates",
                                            operation: Operation.NONE,
                                          });
                                        }
                                      }}
                                    >
                                      {month_date.day}
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li
                                      className="calendar-for-input--date"
                                      key={index}
                                    ></li>
                                  );
                                }
                              })}
                            </ul>
                          </>
                        )}
                      </div>
                    </>
                  }
                  placement="bottom"
                >
                  <img src={Calendar} alt="/" />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          className="calendar-for-input--textfield"
        />
      )}

      <MessageModal
        handleClose={() =>
          setMessage({
            flag: false,
            message: "",
            operation: Operation.NONE,
          })
        }
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default CalendarForExam;
