import { makeStyles } from "@mui/styles";

export const TEXT_FONT_FAMILY = "Josefin Sans !important";
export const HEADER_TEXT_ALIGN = "center";
export const SLNO_TEXT_ALIGN = "center";
export const MARKS_TEXT_ALIGN = "center";

export const AMOUNT_TEXT_ALIGN = "right";
export const NUMBER_FONT_FAMILY = "Spline Sans Mono";
export const TABLE_ROW_HEIGHT = 30;
export const TABLE_FONT_SIZE = 14;
interface slnoProps {
  width: Number;
  fontFamily: String;
  align: String;
}
export const getSlNoStyles = ({
  width,
  fontFamily,
  align = "center",
}: slnoProps) => ({
  width: width || 60,
  fontFamily: fontFamily || NUMBER_FONT_FAMILY,
  textAlign: align,
});
export const useDataGridStyles = makeStyles({
  root: {
    "& .MuiDataGrid-root": {
      borderStyle: "none !important",
      "& .MuiDataGrid-columnHeaders": {
        minHeight: "35px !important",
        maxHeight: "35px !important",
        lineHeight: "35px !important",
        fontFamily: TEXT_FONT_FAMILY,
        fontSize: 16,
        color: "white",
        fontWeight: 700,
      },

      "& .MuiDataGrid-columnHeader": {
        minHeight: "35px !important",
        maxHeight: "35px !important",
        lineHeight: "35px !important",
        fontFamily: TEXT_FONT_FAMILY,
        fontSize: 16,
        color: "white",
        fontWeight: 700,
        backgroundColor: "var(--level-7)",
        textAlign: "center !important",
        borderRight: "1px solid white",
        "& .MuiSvgIcon-root": {
          color: "white",
        },
      },

      // "& .MuiDataGrid-withBorderColor": {
      //   display: "none !important",
      // },

      "& .MuiDataGrid-virtualScrollerContent": {
        "& .MuiDataGrid-cell": {
          borderBottom: "1px solid var(--border-color)",
          borderRight: "1px solid var(--border-color)",
          backgroundColor: "var(--level-1)",
          color: "var(--text-color)",
          fontFamily: TEXT_FONT_FAMILY,
          fontSize: TABLE_FONT_SIZE,
        },
        "& .MuiDataGrid-row": {
          // "&:nth-of-type(odd)": {
          //   backgroundColor: "#f9f9f9",
          // },
          "&:hover  .MuiDataGrid-cell": {
            backgroundColor: "var(--level-2) !important",
            color: "var(--level-5)",
          },
          "&:hover .td-onClick": {
            textDecoration: "underline !important",
          },

          "& .Mui-selected": {
            backgroundColor: "var(--level-4)",
            color: "var(--level-5)",
          },
        },
      },
      "& .MuiDataGrid-pinnedRows":{
        position:"-webkit-sticky ",
        zIndex: 4,
        backgroundColor:" #fff",
        boxShadow: "0px -3px 4px -2px rgba(0, 0, 0, 0.21)",
        bottom: 0
       },
      "& .MuiDataGrid-footerContainer": {
        minHeight: TABLE_ROW_HEIGHT,
      },
      "& .MuiDataGrid-pagination": {
        backgroundColor: "var(--bg-color)", // Change pagination background color
        fontWeight: 700,
      },
      "& .MuiDataGrid-rowCount": {
        color: "var(--text-color)",
        fontFamily: TEXT_FONT_FAMILY,
        fontSize: TABLE_FONT_SIZE,
      },
      "& .MuiList-root": {
        paddingTop: "5px !important",
      },
      "& .MuiButtonBase-root": {
        fontFamily: TEXT_FONT_FAMILY,
        fontSize: TABLE_FONT_SIZE,
        "&:hover": {
          backgroundColor: "var(--level-2)",
          color: "var(--level-5)",
        },
      },
      "& .MuiDataGrid-selectedRowCount": {
        fontFamily: TEXT_FONT_FAMILY,
        fontSize: TABLE_FONT_SIZE,
      },
    },
  },
});
