import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import {
  Autocomplete,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { Title } from "../../../stories/Title/Title";
import { BooksAllocationTitleProps } from "../../../Types/Titles";
import Home from "../Home/Index";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";

import { Button } from "../../../stories/Button/Button";
import { TableHeaderProps } from "../../../Types/Tables";
import {
  DEBOUNCE_TIME_INTERVAL,
  DEFAULT_NUMBER_VALUE,
  LIBRARY_ROWS_PER_PAGE,
  PAGINATION_ARRAY,
  TABLE_DATA_PER_PAGE,
} from "../../../utils/constants";
import { TablePaginationActions } from "../../../pages/CustomTablePagination";
import {
  debounce,
  defaultLabelDisplayedRows,
} from "../../../utils/UtilFunctions";
import { Label } from "../../../stories/Label/Label";
import ArrowRed from "../../../images/ArrowRed.svg";
import ArrowGreen from "../../../images/ArrowGreen.svg";
import Settings from "../../../images/Settings.svg";
import useShelfOptions from "../customHooks/useShelfOptions";
import useBookEntryOptions from "../customHooks/useBookEntryOptions";
import {
  BooksReportType,
  Direction,
  LibBookMasterQueryType,
  MoveDirection,
  Operation,
  PageNumbers,
  SortBy,
  UserType,
} from "../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UpdateLibBooksRackDetails } from "../../../queries/Library/MasterData/Shelf/mutations/update";
import useToken from "../../../customhooks/useToken";
import MessageModal from "../../../pages/MessageModal";
import { bookDetails, msgType } from "../../../utils/Form.types";
import LoadingModal from "../../../pages/LoadingModal";
import {
  GetLibBookMaster,
  GetLibBookNotAllocatedRack,
} from "../../../queries/Library/BookEntry/list/byInstId";
import { GetLibBookCountByRackId } from "../../../queries/Library/MasterData/Shelf/list/byId";
import {
  ConfigurationsModalStyles,
  StudentModalStyles,
} from "../../../styles/ModalStyles";
//Modals
import ShelfBooks from "../Modals/ShelfBooks/Index";
import ConfigurationSettings from "../../Master/configurations/general/Index";

import BookDetails from "../Modals/BookDetails/Index";
import useDisplayConfigIcon from "../../../customhooks/useDisplayConfigIcon";
import Close from "../../../images/Close.svg";
import {
  GetLibBookNotAllocationData,
  GetLibBookNotAllocationVars,
} from "../../../Types/Library/BookEntry/paginationTypes";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useLibraryTableJson from "../json/useLibraryTableJson";
const { Library } = require("../../../json/title.json");

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  type: MoveDirection;
}
enum TableHeaders {
  Previous = "Previous Shelf",
  Next = "Move to Shelf",
}

export interface bookDetailsModal {
  flag: boolean;
  bookDetails?: bookDetails;
}
const BooksDeptNotAllocation = () => {
  //Styles
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  //Params
  const { InstId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const navigate = useNavigate();
  //useStates
  const [searchShelf, setSearchShelf] = useState("");
  const [searchToShelf, setToSearchShelf] = useState("");
  const [searchBook, setSearchBook] = useState("");

  const [viewShelfBooksModal, setViewShelfBooksModal] = useState(false);

  const [configurationModal, setConfigurationModal] = useState(false);

  const [bookDetailsFlag, setBookDetailsFlag] = useState<bookDetailsModal>();
  const [LeftShelfBooks, setLeftShelfBooks] = useState<bookDetails[]>([]);
  const [RightShelfBooks, setRightShelfBooks] = useState<bookDetails[]>([]);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { Library_Table } = useLibraryTableJson();

  //refs
  const shelfRef = useRef<HTMLSelectElement>(null);
  const shelfInputRef = shelfRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  //Pagination states
  const [rowsPerPage, setRowsPerPage] = useState(LIBRARY_ROWS_PER_PAGE);
  const [page, setPage] = useState(0);

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount, type } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell
            padding="checkbox"
            className="submission-details__table--actions"
          >
            {type === MoveDirection.RIGHT ? (
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                disabled={
                  LeftShelfBooks?.filter((data) => data.isChecked).length > 0
                }
              />
            ) : (
              <Checkbox
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                disabled={
                  RightShelfBooks?.filter((data) => data.isChecked).length > 0
                }
              />
            )}
          </TableCell>

          {Library_Table.BooksShelfAllocation.Table_Headers.filter(
            (th: TableHeaderProps) =>
              (th.labelName !== TableHeaders.Previous &&
                type === MoveDirection.RIGHT) ||
              (th.labelName !== TableHeaders.Next &&
                type === MoveDirection.LEFT)
          ).map((th: TableHeaderProps, index: React.Key) => {
            return (
              <TableCell key={index} className={th.className}>
                {th.labelName}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };
  //useQueries
  const [GetBooksCountByRack, { data }] = useLazyQuery(GetLibBookCountByRackId);
  //useMutations
  const [MoveBooksToRack, { loading: MoveBooksToRackLoading }] = useMutation(
    UpdateLibBooksRackDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  //customHooks
  const { token } = useToken();
  const { shelves } = useShelfOptions(searchShelf);
  const { shelves: toShelves } = useShelfOptions(searchToShelf);
  const [toShelfId, setToShelfId] = useState(DEFAULT_NUMBER_VALUE);
  const { USE_CONFIG_KEY } = useDisplayConfigIcon(
    PageNumbers.LIB_BOOK_SHELF_ALLOCATION_PAGE
  );

  const [GetLibBookData, { data: LibBooksNotAllocation }] = useLazyQuery<
    GetLibBookNotAllocationData,
    GetLibBookNotAllocationVars
  >(GetLibBookNotAllocatedRack, {
    variables: { token, inst_id: InstId! },
  });

  const {
    bookEntries: {
      queryData: {
        data: booksFromSelectedShelf,
        loading: booksFromSelectedShelfLoading,
        fetchMore,
      },
    },
  } = useBookEntryOptions(
    searchBook,
    rowsPerPage,
    BooksReportType.BOOKS_IN_NOT_ALLOCATED_DEPT,
    UserType.STUDENT
  );

  const handleClick = (
    event: React.MouseEvent<unknown>,
    data: bookDetails,
    direction: MoveDirection
  ) => {
    const { checked } = event.target as HTMLInputElement;
    switch (direction) {
      case MoveDirection.LEFT:
        const mappedDataLeft = LeftShelfBooks?.map((row) => {
          if (row.id === data.id) {
            return {
              ...row,
              isChecked: checked ? true : false,
            };
          }
          return row;
        });
        setLeftShelfBooks(mappedDataLeft);
        break;
      case MoveDirection.RIGHT:
        const mappedDataRight = RightShelfBooks?.map((row) => {
          if (row.id === data.id) {
            return {
              ...row,
              isChecked: checked ? true : false,
            };
          }
          return row;
        });
        setRightShelfBooks(mappedDataRight);
        break;
      default:
        break;
    }
  };
  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    direction: MoveDirection
  ) => {
    if (direction === MoveDirection.LEFT) {
      const newSelecteds = LeftShelfBooks?.map((data) => ({
        ...data,
        isChecked: event.target.checked,
      }));
      setLeftShelfBooks([...newSelecteds]);
    } else {
      const newSelecteds = RightShelfBooks?.map((data) => ({
        ...data,
        isChecked: event.target.checked,
      }));
      setRightShelfBooks([...newSelecteds]);
    }
  };

  //pagination
  const totalCount = booksFromSelectedShelf?.GetLibBookMaster?.totalCount;
  const endCursor =
    booksFromSelectedShelf?.GetLibBookMaster?.pageInfo?.endCursor;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setTimeout(() => {
      setPage(newPage);
      updateDebounceText();
    }, DEBOUNCE_TIME_INTERVAL);
  };
  const updateDebounceText = debounce(() => {
    handleMore();
  });
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleMore = () => {
    fetchMore({
      variables: {
        after: endCursor,
      },

      updateQuery: (prevResult, { fetchMoreResult }) => {
        fetchMoreResult.GetLibBookMaster.edges = [
          ...prevResult.GetLibBookMaster.edges,
          ...fetchMoreResult.GetLibBookMaster.edges,
        ];

        return fetchMoreResult;
      },
    });
  };
  // eslint-disable-next-line
  const handleClear = () => {
    setToShelfId(0);
    setLeftShelfBooks([]);
    setRightShelfBooks([]);
  };
  useEffect(() => {
    if (booksFromSelectedShelf && !booksFromSelectedShelfLoading) {
      setLeftShelfBooks(
        booksFromSelectedShelf?.GetLibBookMaster?.edges
          ?.map(({ node }) => ({
            id: node.id,
            access_no: node.book_access_no,
            publisher: node.classification.publisher.publisher_name,
            volume: node.classification.book_volume,
            subject: node.classification.subject.subject_desc,
            status: node.book_status,
            title: node.classification.classification_desc,
            book_category: node.classification.category.category_desc,
            author: node.classification.author.author_name,
            edition: node.classification.book_edition,
            shelf:
              node?.rack_details?.lib_rack_desc +
              " " +
              node?.rack_details?.lib_rack_no,
            last_shelf: shelves?.find((data) => data.id === node.last_rack_id)
              ?.lib_rack_desc!,
            classification_no: node.classification.classification_no,
            price: Number(node.classification.last_purchased_rate),
            no_of_pages: Number(node.classification.book_pages),
            //Checking it was previously checked and checking on every render
            isChecked: LeftShelfBooks?.find(
              (data) => data.id === node.id && data.isChecked
            )
              ? true
              : false,
          }))
          //filtering it so that the data moved should not appear again while changing pages
          .filter(
            ({ id }) => !RightShelfBooks?.map(({ id }) => id)?.includes(id)
          )
      );
    } else {
      setLeftShelfBooks([]);
    }
    // eslint-disable-next-line
  }, [
    booksFromSelectedShelf,
    booksFromSelectedShelfLoading,
    toShelfId,
    message,
  ]);
  const moveBooks = (direction: MoveDirection) => {
    switch (direction) {
      case MoveDirection.RIGHT:
        setRightShelfBooks((prevValues) => [
          ...LeftShelfBooks?.filter((data) => data.isChecked)?.map((data) => ({
            ...data,
            isChecked: false,
          })),
          ...prevValues,
        ]);
        setLeftShelfBooks(
          LeftShelfBooks?.filter((data) => data.isChecked === false)
        );
        break;
      case MoveDirection.LEFT:
        setLeftShelfBooks((prevValues) => [
          ...RightShelfBooks?.filter((data) => data.isChecked)?.map((data) => ({
            ...data,
            isChecked: false,
          })),
          ...prevValues,
        ]);
        setRightShelfBooks(
          RightShelfBooks?.filter((data) => data.isChecked === false)
        );
        break;
      default:
        break;
    }
  };
  const updateRacks = () => {
    MoveBooksToRack({
      variables: {
        token,
        book_ids: RightShelfBooks?.map(({ id }) => id),
        rack_id: toShelfId,
        inst_id: InstId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetLibBookMaster,
          variables: {
            token,
            input: {
              book_query_type: LibBookMasterQueryType.BOOKS_BY_RACK_ID,
              ids: [
                Number(InstId),
                LibBooksNotAllocation?.GetLibBookNotAllocatedRack.id,
              ],
            },
            first: rowsPerPage,
            sortBy: SortBy.ACCESS_NO,
            direction: Direction.ASC,
            access_no: searchBook,
            after: null,
          },
        },
        {
          query: GetLibBookMaster,
          variables: {
            token,
            input: {
              book_query_type: LibBookMasterQueryType.BOOKS_BY_RACK_ID,
              ids: [Number(InstId), toShelfId],
            },
            first: rowsPerPage,
            sortBy: SortBy.ACCESS_NO,
            direction: Direction.ASC,
            access_no: searchBook,
            after: null,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: `Books moved to shelf ${
            toShelves.find((data) => data.id === toShelfId)?.lib_rack_desc
          } ${
            toShelves.find((data) => data.id === toShelfId)?.lib_rack_no
          } Successfully`,
          flag: true,
          operation: Operation.UPDATE,
        });
        handleClear();
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setToShelfId(0);
      setLeftShelfBooks([]);
      setRightShelfBooks([]);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (token) {
      GetLibBookData();
    }
  }, [token, GetLibBookData]);
  return (
    <>
      <Home DashBoardRequired={false} />
      <div className="row g-0">
        <div className="col">
          <Title>
            {Library.BooksAllocation.Titles.map(
              (title: BooksAllocationTitleProps, index: React.Key) => {
                return (
                  <React.Fragment key={index}>
                    {title.BooksNotAllocation}
                  </React.Fragment>
                );
              }
            )}
          </Title>
        </div>
        {USE_CONFIG_KEY && (
          <div className="configuration-settings">
            <img
              src={Settings}
              alt="/"
              id="settings-icon"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        )}
      </div>

      <div className="books-allocation">
        <div className="row g-0 books-allocation__details">
          <div className="col-2">
            <TextField
              id="outlined-uncontrolled"
              label="Search By Title"
              className="books-allocation__details--textfield"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchBook(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          {/*  functionality is yet to be implemented */}
          {/* <div className="col-2">
            <Autocomplete
              classes={classes}
              options={options}
              openOnFocus
              onChange={(e) => {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Options"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div> */}
        </div>
        <div className="row g-0 books-allocation__details">
          {/* functionality is not done  */}
          {/* <div className="col-2">
            <Autocomplete
              classes={classes}
              options={options}
              openOnFocus
              onChange={(e) => {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="From Accession No."
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={options}
              openOnFocus
              onChange={(e) => {}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="To Accession No."
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div> */}
          <div className="col-2">
            <Autocomplete
              classes={classes}
              ref={shelfRef!}
              options={
                toShelves?.filter(
                  (data) =>
                    data.id !==
                    LibBooksNotAllocation?.GetLibBookNotAllocatedRack.id
                )! ?? [
                  {
                    lib_rack_desc: "",
                    lib_rack_no: "",
                    id: 0,
                  },
                ]
              }
              getOptionLabel={(option) =>
                (option.lib_rack_desc + " " + option.lib_rack_no).toString()
              }
              value={toShelves.find((data) => data.id === toShelfId) ?? null}
              openOnFocus
              onChange={(e, newValue) => {
                if (newValue) {
                  setToShelfId(newValue?.id! ?? DEFAULT_NUMBER_VALUE);
                } else {
                  setToShelfId(DEFAULT_NUMBER_VALUE);
                }
                if (newValue?.id) {
                  GetBooksCountByRack({
                    variables: {
                      token,
                      inst_id: InstId,
                      rack_id: newValue?.id,
                    },
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select to Shelf"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={(e) => {
                    setToSearchShelf(e.target.value);
                  }}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        </div>
        <div className="row g-0 books-allocation__tableblock">
          <div className="col booktype-left h-100">
            <div className="books-allocation__tableblock--books-count">
              <div></div>
              <Title variant="subtitle1">
                Not Allocated Shelf &nbsp;
                {shelves
                  .find(
                    (data) =>
                      data.id ===
                      LibBooksNotAllocation?.GetLibBookNotAllocatedRack.id
                  )
                  ?.lib_rack_desc.concat(
                    " ",
                    shelves.find(
                      (data) =>
                        data.id ===
                        LibBooksNotAllocation?.GetLibBookNotAllocatedRack.id
                    )?.lib_rack_no!
                  )}
              </Title>
              <div className="books-allocation__tableblock--books-count--total">
                <Label> Total Books Selected :</Label>
                <div className="books-allocation__tableblock--books-count--totalcount">
                  {LeftShelfBooks?.filter((data) => data.isChecked).length}
                </div>
              </div>
            </div>
            <TableContainer className="books-allocation__tableblock--table">
              <Table stickyHeader>
                <EnhancedTableHead
                  numSelected={
                    LeftShelfBooks?.filter((data) => data.isChecked).length
                  }
                  onSelectAllClick={(e) => {
                    handleSelectAllClick(e, MoveDirection.LEFT);
                  }}
                  rowCount={LeftShelfBooks?.length}
                  type={MoveDirection.LEFT}
                />
                <TableBody>
                  {LeftShelfBooks &&
                    LeftShelfBooks?.length > 0 &&
                    (rowsPerPage > 0 &&
                    LeftShelfBooks.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    ).length > 0
                      ? LeftShelfBooks.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : LeftShelfBooks
                    ).map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={
                            LeftShelfBooks[page * rowsPerPage + index]
                              ?.isChecked
                          }
                          tabIndex={-1}
                          key={row.id}
                          selected={
                            LeftShelfBooks[page * rowsPerPage + index]
                              ?.isChecked
                          }
                        >
                          <TableCell
                            padding="checkbox"
                            id="td-center"
                            className="books-allocation__tableblock--table--slno"
                          >
                            <Checkbox
                              checked={
                                LeftShelfBooks[page * rowsPerPage + index]
                                  ?.isChecked ?? false
                              }
                              onClick={(event) =>
                                handleClick(event, row, MoveDirection.LEFT)
                              }
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              disabled={
                                RightShelfBooks?.filter(
                                  (data) => data.isChecked
                                ).length > 0
                              }
                            />
                          </TableCell>
                          <TableCell
                            id="td-center"
                            className="books-allocation__tableblock--table--slno"
                          >
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell className="books-allocation__tableblock--table--accessno">
                            {row.access_no}
                          </TableCell>
                          <TableCell
                            className="books-allocation__tableblock--table--title"
                            onClick={() => {
                              setBookDetailsFlag({
                                flag: !bookDetailsFlag?.flag,
                                bookDetails: row,
                              });
                            }}
                          >
                            {row.title}
                          </TableCell>
                          <TableCell>{row.author}</TableCell>
                          <TableCell className="books-allocation__tableblock--table--desc">
                            {row.last_shelf}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={PAGINATION_ARRAY}
                      count={totalCount ?? 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelDisplayedRows={defaultLabelDisplayedRows}
                      labelRowsPerPage={TABLE_DATA_PER_PAGE}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
          <div className="books-allocation__tableblock--swap-image">
            <div>
              <img
                src={ArrowGreen}
                alt="/"
                onClick={() => {
                  if (
                    LeftShelfBooks?.filter((data) => data.isChecked).length >
                      0 &&
                    toShelfId !== DEFAULT_NUMBER_VALUE
                  )
                    moveBooks(MoveDirection.RIGHT);
                  else {
                    if (
                      LeftShelfBooks?.filter((data) => data.isChecked).length >
                        0 &&
                      toShelfId === DEFAULT_NUMBER_VALUE
                    )
                      shelfInputRef.focus();
                  }
                }}
                className={
                  LeftShelfBooks?.filter((data) => data.isChecked).length > 0
                    ? ""
                    : "opacity"
                }
              />
            </div>
            <br />
            <div>
              <img
                src={ArrowRed}
                alt="/"
                onClick={() => {
                  moveBooks(MoveDirection.LEFT);
                }}
                className={
                  RightShelfBooks?.filter((data) => data.isChecked).length > 0
                    ? ""
                    : "opacity"
                }
              />
            </div>
          </div>

          <div className="col booktype-right h-100">
            <div className="books-allocation__tableblock--books-count">
              <div></div>
              <Title variant="subtitle1">
                Selected &nbsp;
                {toShelves
                  .find((data) => data.id === toShelfId)
                  ?.lib_rack_desc.concat(
                    " ",
                    toShelves.find((data) => data.id === toShelfId)
                      ?.lib_rack_no!
                  )}
              </Title>
              <div className="books-allocation__tableblock--books-count--total">
                <Label
                  onClick={() => {
                    toShelfId && setViewShelfBooksModal(!viewShelfBooksModal);
                  }}
                >
                  Books In Shelf :
                </Label>
                <div
                  className="books-allocation__tableblock--books-count--totalcount"
                  onClick={() => {
                    toShelfId && setViewShelfBooksModal(!viewShelfBooksModal);
                  }}
                >
                  {data?.GetLibBookCountByRackId}
                </div>
              </div>
            </div>
            <TableContainer className="books-allocation__tableblock--table">
              <Table stickyHeader>
                <EnhancedTableHead
                  numSelected={
                    RightShelfBooks?.filter((data) => data.isChecked).length
                  }
                  onSelectAllClick={(e) => {
                    handleSelectAllClick(e, MoveDirection.RIGHT);
                  }}
                  rowCount={RightShelfBooks?.length}
                  type={MoveDirection.RIGHT}
                />
                <TableBody>
                  {RightShelfBooks &&
                    RightShelfBooks.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, row, MoveDirection.RIGHT)
                          }
                          role="checkbox"
                          aria-checked={row.isChecked}
                          tabIndex={-1}
                          key={row.id}
                          selected={row.isChecked}
                        >
                          <TableCell padding="checkbox" id="td-center">
                            <Checkbox
                              checked={row.isChecked}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              disabled={
                                LeftShelfBooks?.filter((data) => data.isChecked)
                                  .length > 0
                              }
                            />
                          </TableCell>
                          <TableCell id="td-center">{index + 1}</TableCell>
                          <TableCell>{row.access_no}</TableCell>
                          <TableCell>{row.title}</TableCell>
                          <TableCell id="td-center">{row.author}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <div className="button-left">
        <Button
          mode="save"
          onClick={updateRacks}
          disabled={RightShelfBooks?.length === 0}
        />
        <Button mode="clear" onClick={handleClear} />
        <Button mode="back" onClick={() => navigate(-1)} />
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewShelfBooksModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ShelfBooks
              shelfId={toShelfId}
              shelfName={
                toShelves.find((data) => data.id === toShelfId)?.lib_rack_desc!
              }
              shelves={shelves}
              setSearchShelf={setSearchShelf}
            />
            <Button
              mode="cancel"
              onClick={() => setViewShelfBooksModal(!viewShelfBooksModal)}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setViewShelfBooksModal(!viewShelfBooksModal)}
            />
          </div>
        </div>
      </Modal>
      {/* configurationModal */}
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={configurationModal}
        style={ConfigurationsModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ConfigurationSettings
              pageNumber={PageNumbers.LIB_BOOK_RETURN_PAGE}
              setModalFlag={setConfigurationModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setConfigurationModal(!configurationModal)}
            />
          </div>
        </div>
      </Modal>
      <BookDetails
        modalFlag={bookDetailsFlag!}
        setModalFlag={setBookDetailsFlag!}
      />
      <LoadingModal flag={MoveBooksToRackLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default BooksDeptNotAllocation;
