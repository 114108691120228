import React, { useState } from "react";
import { Title } from "../../../stories/Title/Title";
import Close from "../../../images/Close.svg";
import Input from "../../../stories/Input/Input";
import {
  Autocomplete,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../styles/AutocompleteListStyles";
import { TableHeaderProps } from "../../../Types/Tables";
import { Button } from "../../../stories/Button/Button";

import { useMutation } from "@apollo/client";
import { UpdateAcdTeacherClassAssoci } from "../queries/teacher_association/mutations";
import { Operation } from "../../../utils/Enum.types";
import { msgType } from "../../../utils/Form.types";
import MessageModal from "../../../pages/MessageModal";
import useToken from "../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../Accounts/hooks/useLoggedInUserDetails";
import useActiveAcademicYear from "../hooks/useActiveAcademicYear";
import {
  GetAcdTeacherClassAssociations,
  GetAcdTeacherSubjectClassAssociations,
} from "../queries/subjects/List.tsx/Index";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import { AcdTeacherClassQueryType } from "../types/subject/Subject";
import useEmployee, { empQueryTypes } from "../../HR/hooks/useEmployee";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../utils/constants";
import { AccountentType } from "../../Accounts/common/Enum.types";
import useCheckAllocationType from "../hooks/useCheckAllocationType";
const { Academics_Table } = require("../json/table.json");
export const enum listFor {
  COMPLETE_DETAILS = "COMPLETE_DETAILS",
  PER_SUBJECT = "PER_SUBJECT",
}
export interface teacherDetails {
  is_class_teacher: boolean;
  pr_emp_id: number;
  subj_master_id: number;
}
interface StaffListProps {
  setClassTeacherModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const ClassTeachersList = ({ setClassTeacherModal }: StaffListProps) => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const { token } = useToken();
  const { InstId, entryId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { entry_level } = useInstitutionConfiguration();
  const [empId, setEmpId] = useState(0);
  const [message, setMessage] = useState<msgType>({
    flag: false,
    message: "",
    operation: Operation.NONE,
  });
  const { activeAcademicYearData } = useActiveAcademicYear();

  const activeAcdId = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;

  const {
    empDetails: { data: EmpData },
  } = useEmployee(
    ROWS_PER_PAGE,
    empQueryTypes.TEACHERS_ASSIGNED_FOR_CLASS,
    EMPTY_STRING,
    0,
    0,
    0,
    0,
    0,
    0,
    [AccountentType.TEACHER],
    entry_level,
    Number(entryId)
  );

  const { flag } = useCheckAllocationType();
  const [AssociateClassTeachers] = useMutation(UpdateAcdTeacherClassAssoci, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const handleSubjectAssoci = () => {
    AssociateClassTeachers({
      variables: {
        token,
        inst_id: InstId,
        user_details,
        acd_yr_id: activeAcdId,
        entry_level: entry_level,
        entry_id: entryId,
        pr_emp_id: empId,
        is_class_teacher: true,
        per_std_subj_allocation: flag,
      },
      refetchQueries: [
        {
          query: GetAcdTeacherSubjectClassAssociations,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            entry_id: Number(entryId)!,
            entry_level: entry_level,
            inst_id: InstId!,
            per_std_subj_allocation: flag,
            token,
          },
        },
        {
          query: GetAcdTeacherClassAssociations,
          variables: {
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            inst_id: InstId!,
            token,
            per_std_subj_allocation: flag,
            input: {
              query_type: AcdTeacherClassQueryType.ALL_TEACHERS_BY_SUBJECT,
              entry_id: Number(entryId)!,
              entry_level: entry_level,
              pr_emp_id: 0,
              subj_master_id: 0,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Successfully Allocated Teacher with Subject",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setClassTeacherModal?.(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <div className="assign-class-teacher__list">
        <div className="assign-class-teacher__list--title">
          <Title>Add Class Teacher </Title>
          <img
            src={Close}
            alt=""
            onClick={() => setClassTeacherModal?.(false)}
          />
        </div>
        <div className="row g-0 assign-class-teacher__list--filter">
          <div className="col-4">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col"></div>
          <div className="col-4">
            <Autocomplete
              classes={classes}
              options={["A", "B"]}
              openOnFocus
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  classes={{ root: textClasses.formControlRoot }}
                  label="Employee Grade"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
        </div>
        <div className="assign-class-teacher__list--tableblock">
          <TableContainer className="assign-class-teacher__list--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Academics_Table.StaffList.filter(
                    (data: TableHeaderProps) =>
                      data.labelName !== "Actions" &&
                      data.labelName !== "Years of Experience"
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {EmpData
                  ? EmpData.GetPayRollEmp.edges.map((teacher, index) => {
                      return (
                        <TableRow>
                          <TableCell
                            className="assign-class-teacher__list--table--slno"
                            id="td-center"
                          >
                            <Checkbox
                              checked={empId === teacher.node.id}
                              onChange={(e) => {
                                const { checked } = e.target;
                                if (checked) {
                                  setEmpId(teacher.node.id);
                                } else {
                                  setEmpId(0);
                                }
                              }}
                            />

                            {index + 1}
                            <img src="" alt="" />
                          </TableCell>
                          <TableCell className="assign-class-teacher__list--table--id">
                            {teacher?.node.emp_id}
                          </TableCell>
                          <TableCell className="assign-class-teacher__list--table--name">
                            {teacher?.node.emp_first_name +
                              " " +
                              teacher?.node.emp_middle_name +
                              " " +
                              teacher?.node.emp_last_name}
                          </TableCell>
                          <TableCell className="assign-class-teacher__list--table--grade">
                            {
                              teacher?.node.pr_designation_details
                                .designation_desc
                            }
                          </TableCell>
                          <TableCell
                            className="assign-class-teacher__list--table--exp"
                            id="td-center"
                          >
                            {teacher?.node.emp_experience}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button mode="save" onClick={handleSubjectAssoci} />

        <Button mode="cancel" onClick={() => setClassTeacherModal?.(false)} />
      </div>

      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default ClassTeachersList;
