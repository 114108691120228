import React from "react";
import { Title } from "../../../../stories/Title/Title";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { TableHeaderProps } from "../../../../Types/Tables";
import { Button } from "../../../../stories/Button/Button";
import useAcctTableJson from "../../json/useAcctTableJson";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompleteDetails = ({ setModalFlag }: Props) => {
  const { Accounts_Table } = useAcctTableJson();

  return (
    <>
      <Title>View Complete Details</Title>
      <div className="purchase-entry-view__details">
        <div className="row g-0 purchase-entry-view__details--filters">
          <div className="col-4">
            <TextField
              className="purchase-entry-view__details--filters--textfield"
              label="Ledger Name"
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <div className="purchase-entry-view__details--filters--flex">
              <TextField
                className="purchase-entry-view__details--filters--textfield"
                label="GST No."
                InputLabelProps={{ shrink: true }}
                disabled
              />
              <TextField
                className="purchase-entry-view__details--filters--textfield"
                label="Mobile No."
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </div>
            <TextField
              className="purchase-entry-view__details--filters--textfield"
              label="Address"
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </div>
          <div className="col-4">
            <div className="purchase-entry-view__details--filters--flex">
              <TextField
                className="purchase-entry-view__details--filters--textfield"
                label="Invoice No."
                InputLabelProps={{ shrink: true }}
                disabled
              />
              <TextField
                className="purchase-entry-view__details--filters--date"
                label="Invoice Date"
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </div>
            <div className="purchase-entry-view__details--filters--flex">
              <TextField
                className="purchase-entry-view__details--filters--textfield"
                label="Purchase Order No."
                InputLabelProps={{ shrink: true }}
                disabled
              />
              <TextField
                className="purchase-entry-view__details--filters--date"
                label="Purchase Order Date"
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </div>
            <TextField
              className="purchase-entry-view__details--filters--textfield"
              label="Procured for"
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </div>
        </div>
        <div className="purchase-entry-view__details--tableblock">
          <TableContainer className="purchase-entry-view__details--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Accounts_Table.PurchaseEntry.Invoice.filter(
                    (th: TableHeaderProps) => {
                      return th.labelName !== "Actions";
                    }
                  ).map((th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    className="purchase-entry__table--slno"
                    id="td-center"
                  >
                    1
                  </TableCell>
                  <TableCell>Furniture</TableCell>
                  <TableCell>PCS</TableCell>
                  <TableCell id="td-center">3</TableCell>
                  <TableCell id="td-right">1000</TableCell>
                  <TableCell id="td-right">3000</TableCell>
                  <TableCell id="td-center">5%</TableCell>
                  <TableCell id="td-right">150</TableCell>
                  <TableCell id="td-right" className="font-green">
                    3150
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={3} className="total">
                    Total :
                  </TableCell>
                  <TableCell className="totalcount">3</TableCell>
                  <TableCell></TableCell>
                  <TableCell className="totalcount" id="td-right">
                    3000
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell className="totalcount" id="td-right">
                    150
                  </TableCell>
                  <TableCell className="totalcount" id="td-right">
                    3150
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
    </>
  );
};

export default CompleteDetails;
