import React, { useState } from "react";
import { Title } from "../../../stories/Title/Title";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Modal from "react-modal";
import Edit from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import Close from "../../../images/Close.svg";
import { TableHeaderProps } from "../../../Types/Tables";
import { WaiveOffModalStyles } from "../../../styles/ModalStyles";
import { Label } from "../../../stories/Label/Label";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import { PageFor } from "../../../utils/Enum.types";
const { VMS_Table } = require("../../json/table.json");
interface Props {
  pageType: PageFor;
}
const ManufacturerModel = ({ pageType }: Props) => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const [addModal, setAddModal] = useState(false);
  return (
    <>
      <div className="transport-masters__right--header">
        <Title variant="subtitle1">List of Manufacturer Model</Title>
        <div className="row g-0 transport-masters__right--input">
          <div className="col">
            <Input id="search" placeholder="Search..." />
          </div>
          <div className="col-4">
            <Button
              mode="addnew"
              className="container__list--addnew"
              onClick={() => setAddModal(!addModal)}
            />
          </div>
        </div>
      </div>
      <div
        className={
          pageType === PageFor.MODAL
            ? "transport-masters__tableblock--modal"
            : "transport-masters__tableblock"
        }
      >
        <TableContainer className="transport-masters__table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {VMS_Table.Masters.ManufacturerModel.map(
                  (th: TableHeaderProps, index: React.Key) => {
                    return <TableCell key={index}>{th.labelName}</TableCell>;
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="transport-masters__table--model">
                <TableCell colSpan={3} id="td-center">
                  Tata
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className="transport-masters__table--slno"
                  id="td-center"
                >
                  1
                </TableCell>
                <TableCell>Petrol</TableCell>
                <TableCell
                  className="transport-masters__table--actions"
                  id="td-center"
                >
                  <img src={Edit} alt="" />
                  <img src={Delete} alt="" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={addModal}
        ariaHideApp={false}
        style={WaiveOffModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>Add Manufacturer Model</Title>
            <div className="label-grid">
              <Label>Manufacturer</Label>
              <Autocomplete
                classes={classes}
                options={["Tata"]}
                openOnFocus
                forcePopupIcon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
              <Label>Description</Label>
              <Input />
            </div>
            <Button mode="save" />
            <Button mode="clear" />
            <Button mode="cancel" onClick={() => setAddModal(!addModal)} />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="" onClick={() => setAddModal(!addModal)} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ManufacturerModel;
