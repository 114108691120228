/**
 * React functional component for rendering a form to raise student fee demands.
 *
 * @component
 * @example
 * <RaiseStudentDemand raiseStudentDemandModal={true} setRaiseStudentDemandModal={setRaiseStudentDemandModal} />
 *
 * @param {boolean} raiseStudentDemandModal - A boolean value indicating whether the raise student demand modal is open or not.
 * @param {function} setRaiseStudentDemandModal - A function to update the state of raiseStudentDemandModal.
 *
 * @returns {JSX.Element} The rendered form and UI elements.
 *
 * @throws {Error} If there is an error in performing API calls.
 */
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Title } from "../../../stories/Title/Title";
import { Button } from "../../../stories/Button/Button";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Table,
  TableHead,
  Autocomplete,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Close from "../../../images/Close.svg";
import AllTransaction from "../../../images/AllTransaction.svg";
import {
  FeeDetails,
  ListDemandDetailsData,
  ListDemandDetailsVars,
  studentDemand,
  studentDemandData,
  studentDemandVars,
  GetStudentPendingDemandCountData,
} from "../../../Types/Accounting";
import { useLazyQuery, useMutation } from "@apollo/client";

import {
  GetAcctDemandMainStudentDemand,
  GetStudentPendingDemandCount,
} from "../queries/demands/query";

import Modal from "react-modal";
import { GetAcctDemandDetails } from "../queries/FeeLedgers/query/Byid";

import { StudentModalStyles } from "../../../styles/ModalStyles";
import { AcctRaiseStudentDemandDetails } from "../queries/demands/mutation/index";

import { formatter, isOptionEqualToValue } from "../../../utils/UtilFunctions";
import { FeeDemandTitleProps } from "../../../Types/Titles";
import { TableHeaderProps } from "../../../Types/Tables";
import LoadingModal from "../../../pages/LoadingModal";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import useToken from "../../../customhooks/useToken";
import MessageModal from "../../../pages/MessageModal";
import { msgType, responseType } from "../../../utils/Form.types";
import useDropdownData from "../../../customhooks/useDropdownData";
import useInstMasterDataByInstId from "../../../customhooks/useInstMasterDataByInstId";
import { Keys } from "../../../utils/Enum.keys";
import { DepartmentListVarsByInstId } from "../../../Types/Student";
import useInstitutionConfiguration from "../../../customhooks/useInstitutionConfiguration";
import {
  AcdSubjectAllocationData,
  PageFor,
  StudentListFor,
  StudentAcctReportType,
  Operation,
} from "../../../utils/Enum.types";
import ViewRaisedFeeDemandDetails from "./ViewRaisedFeeDemandDetails";
import StudentList from "../common/StudentList";
import StudentTotalCount from "../../Master/Student/Components/StudentTotalCount";
import useAcctTableJson from "../json/useAcctTableJson";
import useInstLabels from "../../../customhooks/general/useInstLabels";
import useLoggedInUserDetails from "../hooks/useLoggedInUserDetails";
import { AppContext } from "../../../context/context";

const { AccountsTitles } = require("../json/title.json");
interface Props {
  raiseStudentDemandModal: boolean;
  setRaiseStudentDemandModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const RaiseStudentDemand = ({
  raiseStudentDemandModal,
  setRaiseStudentDemandModal,
}: Props) => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const { format } = formatter;
  const { Accounts_Table } = useAcctTableJson();
  const { InstId } = useParams<{ InstId: string }>();

  const { state } = useContext(AppContext);
  const { token } = useToken();
  //usestate for modal screens
  const [viewRaisedStudentsDemand, setViewRaisedSudentDemand] = useState(false);
  const [studentModal, setStudentModal] = useState(false);
  //use states for selectTag
  const [branchId, setBranchId] = useState<responseType | null>(null);
  const [classId, setClassId] = useState<responseType | null>(null);
  const [studentReportType, setStudentReportType] = useState(
    AcdSubjectAllocationData.BRANCH
  );
  //use state for view data

  const [studentDemands, setStudentDemands] = useState<studentDemand[]>([]);
  const [items, setItems] = useState<FeeDetails[]>([]);

  //use state for success modal
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  let existingStudentData: FeeDetails[] = [];
  let NewStudentData: FeeDetails[] = [];
  const { branchDropDown } = useInstMasterDataByInstId();

  const { classDropDown, masterIds } = useDropdownData(
    0,
    branchId ? branchId.value : 0,
    classId ? classId.value : 0,
    0
  );

  const { USE_CATEGORY_KEY } = useInstitutionConfiguration();

  const { user_details } = useLoggedInUserDetails();
  //lazy queries
  const [GetStudentDemands, { loading: studentsloading }] = useLazyQuery<
    studentDemandData,
    studentDemandVars
  >(GetAcctDemandMainStudentDemand, { fetchPolicy: "network-only" });

  const [AccountDemandDetails] = useLazyQuery<
    ListDemandDetailsData,
    ListDemandDetailsVars
  >(GetAcctDemandDetails);
  const [GetPendingDemand, { data, loading }] = useLazyQuery<
    GetStudentPendingDemandCountData,
    // same vars as department so reusing it, token and instId
    DepartmentListVarsByInstId
  >(GetStudentPendingDemandCount, {
    variables: {
      inst_id: InstId!,
      token,
      fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
    },
    fetchPolicy: "network-only",
  });
  //mutations
  const [AddStudentDemandDetails, { loading: creationLoading }] = useMutation(
    AcctRaiseStudentDemandDetails,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  items?.map(
    ({
      fee_for_all,
      fee_for_exs_std,
      fee_for_new_std,
      fee_due_date,
      sl_no,
      acct_ldgr_id,
      // eslint-disable-next-line
    }) => {
      if (fee_for_all !== 0) {
        existingStudentData.push({
          fee_for_all,
          fee_for_exs_std,
          fee_for_new_std,
          fee_due_date,
          sl_no,
          acct_ldgr_id,
        });
        NewStudentData.push({
          fee_for_all,
          fee_for_exs_std,
          fee_for_new_std,
          fee_due_date,
          sl_no,
          acct_ldgr_id,
        });
      }
      if (fee_for_exs_std !== 0) {
        existingStudentData.push({
          fee_for_all,
          fee_for_exs_std,
          fee_for_new_std,
          fee_due_date,
          sl_no,
          acct_ldgr_id,
        });
      }
      if (fee_for_new_std !== 0) {
        NewStudentData.push({
          fee_for_all,
          fee_for_exs_std,
          fee_for_new_std,
          fee_due_date,
          sl_no,
          acct_ldgr_id,
        });
      }
    }
  );

  const branchRef = useRef<HTMLSelectElement>(null);
  const branchInputRef = branchRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;
  const classRef = useRef<HTMLSelectElement>(null);
  const classInputRef = classRef?.current?.childNodes[0]?.childNodes[1]
    ?.childNodes[0] as HTMLInputElement;

  const handleRaiseStudentDemands = () => {
    if (!studentDemands.length) {
      alert("No students found to raise demands");
      return;
    }
    AddStudentDemandDetails({
      variables: {
        token,
        inst_id: InstId,
        branch_id: masterIds.branchId,
        class_id: masterIds.classId,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
        user_details,
      },
      refetchQueries: [
        {
          query: GetStudentPendingDemandCount,
          variables: { inst_id: InstId!, token },
        },
        {
          query: GetAcctDemandMainStudentDemand,
          variables: {
            token,
            inst_id: InstId!,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0!,
            branch_id: branchId?.value,
            class_id: classId?.value,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Raised Student Fee Demand Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleViewData = async () => {
    const response = await AccountDemandDetails({
      variables: {
        token,
        branch_id: branchId?.value!,
        class_id: classId?.value!,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0!,
        inst_id: InstId!,
      },
    });

    setItems(response.data?.GetAcctDemandDetails!);
    setViewRaisedSudentDemand(!viewRaisedStudentsDemand);
  };
  const handlePendingDemands = () => {
    GetStudentDemands({
      variables: {
        token,
        inst_id: InstId!,
        fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0!,
        branch_id: branchId ? branchId.value : 0,
        class_id: classId ? classId.value : 0,
      },
    }).then(({ data }) => {
      if (data && data.GetAcctDemandMainStudentDemand)
        setStudentDemands(data.GetAcctDemandMainStudentDemand!);
    });
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (message.operation !== Operation.NONE && message.flag) {
      setRaiseStudentDemandModal(!raiseStudentDemandModal);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (raiseStudentDemandModal) {
      branchInputRef?.focus();
    }
  }, [branchInputRef, raiseStudentDemandModal]);

  const totalNonRaisedStudentsCount = data
    ? data.GetStudentPendingDemandCount.filter(
        (d) => d !== null && d.count > 0
      ).reduce((acc, item) => {
        acc += item.count;
        return acc;
      }, 0)
    : 0;
  const { branchLabel, classLabel } = useInstLabels();
  useEffect(() => {
    if (token && InstId && state.ActiveFinYr) {
      GetPendingDemand();
    }
  }, [token, InstId, GetPendingDemand, state.ActiveFinYr]);

  return (
    <>
      <Title>
        {AccountsTitles.FeeDemand.Titles.map(
          (title: FeeDemandTitleProps, index: React.Key) => {
            return (
              <React.Fragment key={index}>
                {title.Raise_Student_Demand}
              </React.Fragment>
            );
          }
        )}
      </Title>
      <div className="student-demand">
        <div className="row g-0 student-demand__details--options">
          <div className="col-8">
            <div className="student-demand__details--autocomplete">
              <Autocomplete
                classes={classes}
                options={branchDropDown}
                ref={branchRef!}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, branchId)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER) {
                    e.preventDefault();
                    if (branchId) {
                      classInputRef?.focus();
                    }
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchId(null);
                  }
                }}
                openOnFocus
                value={branchId}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchId({
                      label: newValue.label,
                      value: newValue.value,
                    });
                  } else {
                    setBranchId(null);
                    setStudentDemands([]);
                  }
                  setClassId(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />

              <FormControl>
                <RadioGroup
                  defaultValue={AcdSubjectAllocationData.BRANCH}
                  row
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value === AcdSubjectAllocationData.BRANCH) {
                      setStudentReportType(AcdSubjectAllocationData.BRANCH);
                      setClassId(null);
                    } else {
                      setStudentReportType(AcdSubjectAllocationData.CLASS);
                    }
                  }}
                >
                  <FormControlLabel
                    control={<Radio value={AcdSubjectAllocationData.BRANCH} />}
                    label={`${branchLabel} Wise`}
                  />
                  <FormControlLabel
                    value={`${classLabel} Wise`}
                    control={<Radio value={AcdSubjectAllocationData.CLASS} />}
                    label={`${classLabel} Wise`}
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="student-demand__details--autocomplete">
              <Autocomplete
                classes={classes}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, classId)
                }
                disabled={studentReportType === AcdSubjectAllocationData.BRANCH}
                ref={classRef!}
                // onKeyDown={(e) => {
                //   if (e.key === Keys.ENTER) {
                //     e.preventDefault();
                //     if (classId) {
                //       semInputRef?.focus();
                //     }
                //   }
                //   if (e.key === Keys.BACKSPACE) {
                //     setClassId(null);
                //   }
                // }}
                openOnFocus
                value={classId}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassId({
                      label: newValue.label,
                      value: newValue.value,
                    });
                  } else {
                    setClassId(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />

              <div className="student-demand__details--autocomplete--buttons">
                <Button
                  onClick={handlePendingDemands}
                  mode="fetch"
                  disabled={!branchId}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="student-demand__details row g-0">
          <div className="col-8 student-demand__details--frames">
            <div className="row g-0 student-demand__tableblock">
              <Title variant="subtitle1">
                {AccountsTitles.FeeDemand.Titles.map(
                  (title: FeeDemandTitleProps, index: React.Key) => {
                    return (
                      <React.Fragment key={index}>
                        {title.Student_List}
                      </React.Fragment>
                    );
                  }
                )}
              </Title>

              <TableContainer className="student-demand__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Accounts_Table.FeeDemand.RaiseStudentDemand.Table_Headers.filter(
                        (d: TableHeaderProps) =>
                          (d.labelName !== "Category" && !USE_CATEGORY_KEY) ||
                          USE_CATEGORY_KEY
                      ).map((th: TableHeaderProps, index: React.Key) => {
                        return (
                          <React.Fragment key={index}>
                            <TableCell className={th.className}>
                              {th.labelName}
                            </TableCell>
                          </React.Fragment>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentDemands.map((studentDemand, index) => {
                      return (
                        <Fragment key={index}>
                          <TableRow>
                            <TableCell
                              id="td-center"
                              className="student-demand__table--slno"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell className="student-demand__table--amt">
                              {studentDemand.std_adm_no}
                            </TableCell>
                            <TableCell>
                              {studentDemand.first_name +
                                " " +
                                studentDemand.middle_name +
                                " " +
                                studentDemand.last_name}
                            </TableCell>
                            <TableCell className="student-demand__table--desc">
                              {studentDemand.class_desc}
                            </TableCell>
                            <TableCell className="student-demand__table--status">
                              {studentDemand.std_fresher ? "Fresher" : "Old"}
                            </TableCell>
                            <TableCell className="student-demand__table--desc">
                              {studentDemand.cat_desc}
                            </TableCell>

                            <TableCell
                              id="td-right"
                              className="student-demand__table--amt"
                            >
                              {format(
                                studentDemand.std_fresher
                                  ? studentDemand.total_demand_new_std +
                                      studentDemand.total_demand_all_std
                                  : studentDemand.total_demand_exs_std +
                                      studentDemand.total_demand_all_std
                              )}
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="col student-demand__details--frames ">
            <Title variant="subtitle1">
              Unraised Demand Student Count {branchLabel} & {classLabel} Wise
            </Title>
            <div className="student-demand__tabledata-unraised">
              <TableContainer className="feedemandview__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl</TableCell>
                      <TableCell>{branchLabel} </TableCell>
                      <TableCell>{classLabel} </TableCell>
                      <TableCell>Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.GetStudentPendingDemandCount.length > 0 &&
                      data.GetStudentPendingDemandCount.filter(
                        (d) => d !== null && d.count > 0
                      ).map((data, index) => {
                        return (
                          <TableRow key={index + 1}>
                            <TableCell
                              id="td-center"
                              className="feedemandview__table--slno"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell>
                              {data.class_details.branch_details.branch_desc}
                            </TableCell>
                            <TableCell>
                              {data.class_details.class_desc}
                            </TableCell>
                            <TableCell
                              className="feedemandview__table--slno"
                              id="td-right"
                            >
                              {data.count}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-6">
            <Button
              mode="save"
              onClick={() => {
                setMessage({
                  message: "",
                  flag: false,
                  operation: Operation.NONE,
                });

                handleRaiseStudentDemands();
              }}
            />

            <Button mode="view" onClick={handleViewData} />
            <Button
              mode="clear"
              onClick={() => {
                setBranchId(null);
                setClassId(null);
                branchRef.current?.focus();
                setStudentDemands([]);
              }}
            />
            <Button onClick={() => setStudentModal(!studentModal)}>
              <img src={AllTransaction} alt="/" />
              Demand Rasied Students
            </Button>
            <Button
              mode="cancel"
              onClick={() =>
                setRaiseStudentDemandModal(!raiseStudentDemandModal)
              }
            />
          </div>
          <div className="col-2 feedemandview__total">
            <StudentTotalCount totalCount={studentDemands.length!} />
          </div>
          <div className="col feedemandview__total">
            <StudentTotalCount
              totalCount={
                data
                  ? data.GetStudentPendingDemandCount.filter(
                      (d) => d !== null && d.count > 0
                    ).length
                  : 0
              }
            />
          </div>
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewRaisedStudentsDemand}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ViewRaisedFeeDemandDetails
              setViewDemandStructureModal={setViewRaisedSudentDemand}
              viewDemandStructureModal={viewRaisedStudentsDemand}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() =>
                setViewRaisedSudentDemand(!viewRaisedStudentsDemand)
              }
            />
          </div>
        </div>
      </Modal>

      {/* studentmodal */}
      <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={studentModal}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <StudentList
              pageType={PageFor.MODAL}
              studentListFor={StudentListFor.ACCOUNTS}
              queryType={StudentAcctReportType.DEMAND_RAISED}
              setStudentModal={setStudentModal}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setStudentModal(!studentModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={creationLoading || loading || studentsloading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default RaiseStudentDemand;
